<template>
  <v-container class="mt-4 ml-2" fluid>
  	<v-row>
  		<v-col cols="12">
  			<v-card class="shadowCard">
  			  <v-card-title primary-title>
  			    Todos los prospectos
  			    <v-spacer></v-spacer>
  			    <v-btn color="green"   small @click="exportExcel()" dark class="mr-2"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
  			    <v-btn color="primary" small @click="initialize()" rounden>Actualizar</v-btn>
  			  </v-card-title>
  			  <v-card-text>
  			  	<v-row>
  			  		<v-col cols="12" md="4" lg="3" xl="2">
  			  			<v-text-field
			            v-model="fechaini"
			            dense
			            filled
			            type="date"
			            label="Fecha inicio"
			            clearable
                  hide-details
			          ></v-text-field>
  			  		</v-col>

  			  		<v-col cols="12" md="4" lg="3" xl="2">
			          <v-text-field
			            v-model="fechafin"
			            dense
			            hide-details
			            filled
			            type="date"
			            label="Fecha final"
			            clearable
                  hide-details
			          ></v-text-field>
  			  		</v-col>

  			  		<v-col cols="12" sm="4" md="4" lg="4" class="pb-0">
		            <v-autocomplete
		              filled
		              dense
		              clearable
		              v-model="vendedoraFiltro"
		              :items="vendedoras"
		              label="Selecciona la vendedora"
		              persistent-hint
		              hide-details
		              return-object
		              item-text="nombre"
		              item-value="iderp"
                  hide-details
		            >
		            </v-autocomplete>
		          </v-col>
              
              <v-col cols="12">
                <v-radio-group
                  v-model="filtroEscuela"
                  row
                  hide-details
                >
                  <v-radio
                    label="FAST"
                    :value="2"
                  ></v-radio>
                  <v-radio
                    label="INBI"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="TODO"
                    :value="3"
                  ></v-radio>
                </v-radio-group>  
              </v-col>

  			  		<v-col cols="12">
				  			<v-data-table
							    :headers="headers"
							    :items="filterProspectos"
							    class="elevation-0"
							  >
							  	<template v-slot:item.finalizo="{ item }">
							      <v-chip small hide-details color="red" dark v-if="item.finalizo == 1">Cerrado</v-chip>
							      <v-chip small hide-details v-else color="green" dark>Abierto</v-chip>
							    </template>
								</v-data-table>
  			  		</v-col>

  			  	</v-row>
  			  </v-card-text>
  			</v-card>
  		</v-col>
  	</v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'


  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
      filtroEscuela:null,
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

    	headers: [
        { text: 'Folio',     value: 'folio' },
        { text: 'Nombre',    value: 'nombre_completo' },
        { text: 'Vendedora', value: 'vendedora' },
        { text: 'Registró',  value: 'recluta' },
        { text: 'Puesto',    value: 'puesto' },
        { text: 'Fecha',     value: 'fecha_creacion' },
        { text: 'Estatus',   value: 'finalizo' },
      ],
      contactos:[],
      fechaini:null,
			fechafin:null,
			vendedoraFiltro:null,
			vendedoras:[],
			prospectos:[],
    }),

    async created() {
      await this.initialize()
      await this.getVendedoras()
    },

    computed: {
      filterProspectos(){
        if(this.prospectos){

          let prospectos = this.prospectos 

          if(this.filtroEscuela == 1 ){
            prospectos = prospectos.filter( el => { return el.escuela == 1 })
          }

          if(this.filtroEscuela == 2 ){
            prospectos = prospectos.filter( el => { return el.escuela == 2 })
          }

          // return this.prospectos
          return prospectos.filter((prospecto)=>{
            if(this.vendedoraFiltro){
              if(this.vendedoraFiltro.iderp == prospecto.usuario_asignado){
                // Sin fecha seleccionada
                if(!this.fechaini && !this.fechafin){
                  return true
                }

                // Solo fecha de inicio seleccionada
                if(this.fechaini && !this.fechafin){
                  if(prospecto.fecha == this.fechaini){
                     return true
                  }
                }

                // Solo fecha de inicio seleccionada
                if(this.fechaini && this.fechafin){
                  if(prospecto.fecha >= this.fechaini && prospecto.fecha <= this.fechafin ){
                    return true
                  }
                }
              }else{
                return false
              }
            }else{
              // Sin fecha seleccionada
              if(!this.fechaini && !this.fechafin){
                return true
              }

              // Solo fecha de inicio seleccionada
              if(this.fechaini && !this.fechafin){
                if( prospecto.fecha == this.fechaini){
                  return true
                }
              }

              // Solo fecha de inicio seleccionada
              if(this.fechaini && this.fechafin){
                if(prospecto.fecha >= this.fechaini && prospecto.fecha <= this.fechafin ){
                  return true
                }
              }
            }

          })
        }
      },
    },

    methods: {
      // Inicio de todo
      initialize() {
        // Abrimos modal de carga
        this.cargar = true
        // Limpiamos los prospectos
        this.prospectos  = []
        return this.$http.get('prospectos.vendedora.cantidad').then((response) => {
        	console.log(response.data)
        	this.prospectos = response.data
          this.cargar    = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getVendedoras() {
        this.cargar = true
        this.vendedoras  = []
        return this.$http.get('vendedoras.list').then((response) => {
          // Llenamos la etapa incial
          this.vendedoras = response.data
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let data = XLSX.utils.json_to_sheet(this.filterProspectos)
        const workbook = XLSX.utils.book_new()
        const filename = 'contactos_vendedora'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });
      },
    },
  };
</script>
<style scoped>

  #eventos::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  #eventos::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  #eventos::-webkit-scrollbar:vertical{
    width: 6px !important;
  }

  #eventos::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  #eventos::-webkit-scrollbar:horizontal{
    height: 6px !important;
  }

  #eventos::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  #notas::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  #notas::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  #notas::-webkit-scrollbar:vertical{
    width: 6px !important;
  }

  #notas::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  #notas::-webkit-scrollbar:horizontal{
    height: 6px !important;
  }

  #notas::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>

