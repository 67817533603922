<template>
    <v-container class="ma-0 pa-0">
        <v-row justify="end" class="mt-2" v-if="respuestas.respuesta">
            <v-col cols="6" class="d-flex justify-end px-0">
                <p class="ma-0" >Respondió: <strong> {{ respuestas.jefe }} </strong> </p>
            </v-col>
        </v-row>
        <v-row justify="end" class="mt-1" v-if="respuestas.respuesta">
            <v-col cols="8" class="d-flex justify-end pa-0">
                <p class="pa-2 ma-0 mb-4 mensajeDer">
                <span class="d-flex justify-end">
                    {{ respuestas.respuesta }}
                </span>
                <span style="font-size: small;">{{ respuestas.fecharespuesta }}</span>
                </p>
            </v-col>
        </v-row>
        <v-row justify="end" class="mt-1" v-if="respuestas.evidenciasRespuesta.length && respuestas.respuesta">
            <v-col cols="3" v-for="(img, i) in respuestas.evidenciasRespuesta" :key="i">
                <v-card class="py-4 shadowCard" @click="verImagen( img.foto, img.es_solucion )">
                <v-img v-if="!img.video" :src="url + img.foto" contain aspect-ratio="2"></v-img>
                <video v-else :src="url + img.foto" contain aspect-ratio="2" style="width: 100%; height: 100%;" class="d-flex"></video>
                </v-card>
            </v-col>
        </v-row>

        <!-- Visualizar imagenes -->
        <Imagen @close="closeImagen" :respuestaImagen="respuestaImagen" :isOpen="dialogImagen" :imagen="imagen" :ticket="ticket"/>
    </v-container>
</template>
<script>
import axios from 'axios';
import Imagen                from '@/components/tickets/Imagen.vue'
export default {
    props: [
        'ticket',
        'respuestas'
    ],
    components:{
        Imagen
    },
    data: () => ({
        url:'',
        urlINBI:'',
        urlFAST:'',
        dialogImagen:false,
        respuestaImagen: false,
        imagen: '',
    }),
    created () {
        this.url = axios.defaults.baseURL + 'evidencia-tickets/'
        this.urlINBI = 'https://lmsinbi.club/evidencia-tickets/'
        this.urlFAST = 'https://lmsfast.club/evidencia-tickets/'
    },
    methods:{
        verImagen( imagen, respuestaImagen ){
            this.imagen = imagen
            this.respuestaImagen = respuestaImagen
            this.dialogImagen = true
        },
        closeImagen(){
            this.dialogImagen = false
        },
    },
}
</script>
<style>
  .mensajeIzq{
    white-space: pre-line;
    background-color: rgb(238, 235, 235);
    border-radius: 0px 10px 10px 10px;
  }
  .mensajeDer{
    white-space: pre-line;
    background-color: rgb(193, 231, 243);
    border-radius: 10px 0px 10px 10px;
  }
</style>