<template>
  <v-container class="mt-5 ml-2">
    <v-row>
      <v-col cols="8">
        <v-card>
          <v-card-title>
            <span class="headline">Total de tickets en cada area</span>
            <div class="ml-2">
              <v-btn color="blue" dark @click="cargar_tickets_cada_area()">
                <v-icon>mdi-reload</v-icon> Volver a cargar
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div id="app">
                    <div id="content">
                      <canvas ref="chartUN" style="max-height: 600px"></canvas>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import Vue from "vue";
import Chart from "@/plugins/chart";

export default {
  name: "app",
  data: () => ({
    data: [],
  }),

  created() {
    this.cargar_tickets_cada_area();
  },

  methods: {
    cargar_tickets_cada_area() {
      this.$http
        .get("reportes.total_ticket_por_area")
        .then((response) => {

          console.log(response.data[0])
          var chartUN = this.$refs.chartUN;
          var ctx = chartUN.getContext("2d");
          if (this.myChart != null) {
            this.myChart.destroy();
          }
          this.myChart = new Chart(ctx, {
            type: "doughnut",
            data: {
              labels: [
                response.data[0].area + ": " + response.data[0].Total,
                response.data[1].area + ": " + response.data[1].Total,
                response.data[2].area + ": " + response.data[2].Total,
                response.data[3].area + ": " + response.data[3].Total,
                response.data[4].area + ": " + response.data[4].Total,
                response.data[5].area + ": " + response.data[5].Total,
                response.data[6].area + ": " + response.data[6].Total,
              ],
              datasets: [
                {
                  data: [
                    response.data[0].Total,
                    response.data[1].Total,
                    response.data[2].Total,
                    response.data[3].Total,
                    response.data[4].Total,
                    response.data[5].Total,
                    response.data[6].Total,
                  ],
                  backgroundColor: [
                    "orange",
                    "blue",
                    "red",
                    "grey",
                    "green",
                    "yellow",
                    "black",
                  ],
                  labels: [],
                },
              ],
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {},
};
</script>