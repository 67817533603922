<template>
	<v-container class="mt-4 px-5" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1 mr-4"><v-icon>mdi-cog-outline</v-icon>Mis tickets</span>

            <v-text-field v-model="search" label="Buscar" dense hide-details outlined></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="green" @click="dialogAdd = true" small rounded dark class="me-2" tile>
              Subir Ticket
            </v-btn>
            <v-btn color="primary" @click="initialize" small rounded tile>
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>

            <v-radio-group v-model="estatusFiltro" row dense small>
              <v-radio color="orange" label="Abierto"    :value="1"></v-radio>
              <v-radio color="blue"   label="Asignado"   :value="2"></v-radio>
              <v-radio color="red"    label="Pendiente"  :value="3"></v-radio>
              <v-radio color="grey"   label="Revisión"   :value="4"></v-radio>
              <v-radio color="green"  label="Respondido" :value="5"></v-radio>
              <v-radio color="yellow" label="Cerrado"    :value="6"></v-radio>
              <v-radio color="black"  label="Rechazado"  :value="7"></v-radio>
              <v-radio                label="Todos"      :value="8"></v-radio>
            </v-radio-group>

    			  <v-data-table :headers="headers" :items="filterTickets" sort-by="calories" dense class="elevation-0" :search="search">
            	<template v-slot:item.estatus="{ item }">
    			      <v-chip small :color="getColor(item.estatus)" dark v-if="item.estatus==1"> Abierto </v-chip>
                <v-chip small :color="getColor(item.estatus)" dark v-if="item.estatus==2"> Asignado </v-chip>
                <v-chip small :color="getColor(item.estatus)" dark v-if="item.estatus==3"> Pendiente </v-chip>
                <v-chip small color="grey"                    dark v-if="item.estatus==4"> Revisión </v-chip>
                <v-chip small color="green"                   dark v-if="item.estatus==5"> Respondido </v-chip>
                <v-chip small color="yellow"                       v-if="item.estatus==6"> Cerrado </v-chip>
                <v-chip small color="black"                   dark v-if="item.estatus==7"> Rechazado </v-chip>
    			    </template>

    			    <template v-slot:item.respuestas="{ item }">
    			      <v-btn color="primary" x-small tile @click="verMotivo(item)">
                  <v-icon small>mdi-magnify</v-icon>
                </v-btn>
    			    </template>

    			    <template v-slot:no-data>
    			      <v-btn color="primary" @click="initialize" small rounded>
    			        Actualizar
    			      </v-btn>
    			    </template>
    			  </v-data-table>
          </v-card-text>
        </v-card>
			</v-col>
		</v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <!-- Visualizar historial -->
    <historial @close="closeModal" @recargar="obtenerTickets" :isOpen="dialogHistorial" :historial="historial" :ticket="ticket" :vista="vista" :alumnos="alumnos"/>

    <addTicket @close="closeModal" @recargar="obtenerTickets" :isOpen="dialogAdd" :temas="temas" :subtemas="subtemas"/>

	</v-container>
</template>
<script>

  import axios from 'axios';
  var moment = require('moment');
  moment.locale('es');

  import {mapGetters, mapActions} from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue'
  import historial             from '@/components/tickets/historial.vue'
  import Imagen                from '@/components/tickets/Imagen.vue'
  import addTicket             from '@/components/tickets/addTicket.vue'

  import validarErrores  from '@/mixins/validarErrores'

  export default {
    components:{
      Alerta,
      carga,
      historial,
      Imagen,
      addTicket,
    },

    mixins: [ validarErrores ],

    data: () => ({
      respuestaAlerta:false,
      cargar: false,

      search:'',
    	respuestaRechazo:'',
    	rechazar: false,
    	historial:{},
    	ticket:{},

      headers: [
        { text: 'Folio'            , value: 'idticket', sortable: false },
        { text: 'Fecha de creación', value: 'fecha_apertura'  },
        { text: 'Tema'             , value: 'tema' },
        { text: 'Subtema'          , value: 'subtema' },
        { text: 'Motivo'           , value: 'motivocorto'     },
        { text: 'Estatus'          , value: 'estatus'         },
        { text: 'Encargado'        , value: 'nombre_completo' },
        { text: 'Respuestas'       , value: 'respuestas', sortable: false },
      ],
      tickets: [],
      files: [],
      vistaPrevias:[],
      estatusFiltro:8,

      url:'',
      es_solucion:false,
      dialogAdd:false,
      dialogImagen:false,
      dialogHistorial:false,
      dialogCerrarTicket: false,
      
      imagen: '',
      vista: 'MisTickets',
      temas: [],
      subtemas:[],
      alumnos: [],
      headersAlumnos:[
        { text: 'matricula', value: "matricula" },
        { text: 'alumno'   , value: "alumno" },
        { text: 'grupo'    , value: "grupo" },
      ],
    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),

      filterTickets () {
        if(this.tickets){
          return this.tickets.filter((ticket)=>{
            var est = ticket.estatus;
            if(this.estatusFiltro == 8){
              return true
            }else{
              if(est == this.estatusFiltro){return true}
            }
          })
        }
      },
    },

    watch: {

    },

    created () {
      this.url = axios.defaults.baseURL + 'evidencia-tickets/'
      this.initialize()
      this.getTeamas()
    },

    methods: {
      initialize () {
        this.cargar = true
        this.obtenerTickets()
      },

      obtenerTickets(){
        this.tickets = []
        this.$http.get('tickets.get.usuario.erp/'+this.getdatosUsuario.iderp).then(response=>{
        	this.tickets = response.data
          this.tickets.forEach(element => {
            if(element.motivo.length > 500){
              element.motivocorto = element.motivo.substring(0, 500) + "..."
            } else {
              element.motivocorto = element.motivo
            }
          });
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getTeamas(){
        this.temas = []
        this.subtemas = []
        this.$http.get('tickets.get.temas').then(response=>{
          this.temas = response.data.temas

          this.subtemas = response.data.subtemas
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      closeModal(){
        this.dialogHistorial = false
        this.dialogAdd = false
      },

      verMotivo(value){
        this.cargar = true
      	this.ticket = value
        this.historial = []
        this.$http.get('historial_tickets.estatus/'+this.ticket.idticket).then(response=>{
          for(const i in response.data.HistorialTicket){
            response.data.HistorialTicket[i].fecha = moment(response.data.HistorialTicket[i].fecha).format('MMMM Do YYYY, h:mm:ss a');
            response.data.HistorialTicket[i].fecharespuesta = moment(response.data.HistorialTicket[i].fecharespuesta).format('MMMM Do YYYY, h:mm:ss a');
            //esta es la fecha y hora en la que se comenzo el registro de respuestas
            if(response.data.HistorialTicket[i].fecharespuesta == 'marzo 4º 2024, 3:31:27 pm'){
              response.data.HistorialTicket[i].fecharespuesta = ''
            }
            this.historial.push(response.data.HistorialTicket[i])
          }
          this.alumnos = response.data.alumnos
          console.log( this.alumnos )
          this.dialogHistorial = true
          this.ver = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getColor (estatus) {
        if (estatus == 3) return 'red'
        else if (estatus == 1) return 'orange'
        else if (estatus == 2) return 'blue'
      },
    },
  }
</script>