<template>
	<v-dialog
    v-model="dialogNotifica.estatus"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ dialogNotifica.notificacion.titulo }}
      </v-card-title>
      <v-card-text>
  			{{ dialogNotifica.notificacion.mensaje }}
      </v-card-text>
      <v-card-actions>
      	<v-btn
          color="black"
          tile
          dark
          block
          @click="cerrar()"
        >
        	<v-icon small left>mdi-cancel</v-icon>
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'dialogNotifica',
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      motivo: '',

    }),

    created(){
    	this.cambiarEstatusNoti()
    },

    methods:{
      cerrar(){
      	this.dialogNotifica.estatus = false
      	this.$emit('getNotificaciones')
      },

      cambiarEstatusNoti( ){

        console.log( this.dialogNotifica.notificacion.idnotificaciones )

    		this.cargar = true
        // Obtener todos los ciclos
        this.$http.put('update.notificacion/' + this.dialogNotifica.notificacion.idnotificaciones ).then(response=>{
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })


      },



    }
	}
</script>
