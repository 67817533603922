<template>
  <v-container class="mt-5 ml-2">
  	<v-card>
  	  
	    <v-snackbar
	      v-model="snackbar"
	      top
	      :timeout="3000"
	      color="error"
	    >
	      Selecciona los ciclos porfavor 
	      <v-btn text color="white" @click.native="snackbar = false">Close</v-btn>
	    </v-snackbar>

	    <v-row>
	      <v-col cols="12">
          <v-toolbar
            flat
          >
            <v-toolbar-title>Prospectos </v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="registrar()" small v-if="cicloInf && fecha">Generar</v-btn>
          </v-toolbar>
          <v-card-text class="pb-6">
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  outlined
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-text-field
                  outlined
                  dense
                  clearable
                  v-model="fecha"
                  type="date"
                  label="Fecha"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
              	<v-data-table
							    dense
							    :headers="headers"
							    :items="alumnos.alumnosFast"
							    class="elevation-1"
							  >
							  	<template v-slot:top>
							      <v-toolbar
							        flat
							      >	
							      	<div class="text-subtitle-1">FAST</div> 
							      	<v-spacer></v-spacer>
							      	<v-btn 
				                class="mr-2" 
				                small dark color="green"
				                @click="exportarFast()">
				                <v-icon>mdi-microsoft-excel</v-icon>
				              </v-btn>
								    </v-toolbar>
								  </template>
							  </v-data-table>
              </v-col>

              <v-col cols="12" md="6">
              	<v-data-table
							    dense
							    :headers="headers"
							    :items="alumnos.alumnosInbi"
							    class="elevation-1"
							  >
							  	<template v-slot:top>
							      <v-toolbar
							        flat
							      >
							      	<div class="text-subtitle-1">INBI</div> 
							      	<v-spacer></v-spacer>
							      	<v-btn 
				                class="mr-2" 
				                small dark color="green"
				                @click="exportarInbi()">
				                <v-icon>mdi-microsoft-excel</v-icon>
				              </v-btn>
								    </v-toolbar>
								  </template>
							  </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
	      </v-col>
	    </v-row>
	  </v-card>

	  <!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="200"
        color="primary"
        dark
      >
        <v-card-text align="center" class="text-h6">
          Espera porfavor
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card
        color="green"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Datos grabados
        </v-card-title>
        <div class="pl-6 pb-6">
          <br/>
          NOTA: {{successMensaje}}
        </div>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogSuccess = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
	  
    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card
        color="error"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Error al recuperar los datos
        </v-card-title>
        <v-card-text>
          <br/>
          NOTA: {{error}}
        </v-card-text>
        <v-btn 
        	color="transparent elevation-0"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogError = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'

  export default {
    data: () => ({
      snackbar: 		false,
      cicloInf: 		null,
      ciclos:  			[],
      fecha:        null,
      cicloInf: 		null,
      cicloSup: 		null,
      dialogError: 	false,
			dialogSuccess:false,
			error: 				'',
			successMensaje:'',
			overlay: 			false,
			alumnos: {},
			headers: [
        { text: 'nombres',    value: 'nombres' },
        { text: 'apellidos',  value: 'apellidos' },
        { text: 'folio',      value: 'folio' },
      ],
    }),

    watch: {
      cicloInf () {
        if(this.cicloInf && this.fecha){
          this.registrar()
        }
      },

      fecha () {
        if(this.cicloInf && this.fecha){
          this.registrar()
        }
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
      	this.overlay = true
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError('Error al recuperar los ciclos')
        })
      },

      registrar () {
      	this.overlay = true
      	let payload = {
      		cicloFast: this.cicloInf.id_ciclo_relacionado, 
      		cicloInbi: this.cicloInf.id_ciclo,
      		fecha: this.fecha
      	}

      	this.$http.post('lms.prospectos.add', payload).then(response=>{
          this.alumnos = response.data
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError('Error al recuperar los ciclos')
        })
      },

      openDialogError (mensaje) {
      	this.error       = mensaje
      	this.dialogError = true
      },

      openDialogSuccess ( mensaje ){
      	this.successMensaje = mensaje
      	this.dialogSuccess  = true
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportarFast(){
        let data = XLSX.utils.json_to_sheet(this.alumnos.alumnosFast)
        const workbook = XLSX.utils.book_new()
        const filename = 'folios_fast'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });
      },

      exportarInbi(){
        let data = XLSX.utils.json_to_sheet(this.alumnos.alumnosInbi)
        const workbook = XLSX.utils.book_new()
        const filename = 'folios_Inbi'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });
      },

    },
  }
</script>