<template>
	<v-container class="mt-4 ml-2">
	  <v-row>
	  	<v-col cols="12" md="6" lg="5">
			  <v-card v-if="vacantes">
			  	<!-- Actividades de la semana -->
			  	<v-card-title primary-title>
			  		<v-spacer></v-spacer>
			  	  Vacantes disponibles
			  		<v-spacer></v-spacer>
			  	</v-card-title>
			  	<v-card-text class="text-center text-h3">
			  	  {{ vacantes.vacantes_disponibles }} de {{ vacantes.vacantes }}
			  	</v-card-text>

			  	<!-- Progreso de las vacantes disponibles -->
			  	<v-card-text class="text-center text-h3">
			  	  <v-progress-linear
			  	  	class="mt-2" 
			  	  	height="24"
			  	  	:value="(100 - parseFloat(vacantes.porcentaje))"
			  	  	rounded
			  	  	color="primary"
			  	  >
			  	  	<strong
			  	  		class="text-caption white--text"
			  	  	>{{ (100 - parseFloat(vacantes.porcentaje)) }}% PROGRESO</strong>
			  	  </v-progress-linear>
			  	</v-card-text>
			  </v-card>
	  	</v-col>

	  	<v-col cols="12" md="6" lg="4">
			  <v-card v-if="vacantes">
			  	<v-card-title primary-title>
			  		<v-spacer></v-spacer>
			  	  Registrar vacantes de hoy
			  		<v-spacer></v-spacer>
			  	</v-card-title>
			  	<v-card-text class="text-center text-h3">
			  		<v-text-field
		    			label="# Vacantes"
		    			dense
		    			v-model.number="vacantesTotales"
		    			filled
		    		>
		    		</v-text-field>

		    		<v-text-field
		    			label="# Disponibles"
		    			dense
		    			v-model.number="vacantesDisponibles"
		    			filled
		    		>
		    		</v-text-field>
			  	</v-card-text>

			  	<v-card-actions>
			  	  <v-btn 
			  	  	class="text-capitalize" 
			  	  	color="primary" 
			  	  	block 
			  	  	small 
			  	  	:disabled="isDisbaledRegistrar"
			  	  	@click="registrar()"
			  	  >Registrar</v-btn>
			  	</v-card-actions>
			  </v-card>
	  	</v-col>
	  </v-row>

		<!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="" style="display: table-cell; vertical-align: middle;">
		      				<strong>¡Bien!</strong> Datos grabados correctamente
			      			<v-card-actions class="pt-6">
			      				<v-spacer></v-spacer>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="green" dark class="elevation-6" large @click="dialogSuccess = false">Ok</v-btn>
			      				<v-spacer></v-spacer>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/correcto.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="300"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      persistent
      max-width="400"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="7">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div style="display: table-cell; vertical-align: middle;">
		      				<span v-if="errorBack">
		      					<strong>ERROR</strong> Da clic en "COPIAR" para guardar el error y enviarlo a sistemas
		      				</span>
		      				<span v-else>
		      					<strong>ERROR</strong> {{error}}
		      				</span>
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text large class="mr-2" @click="dialogError = false">Cerrar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="copiarPortapapeles()" v-if="errorBack">{{textoCopiado}}</v-btn>
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="dialogError = false" v-else>Ok</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/eliminar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  export default {
    data: () => ({
    	search:'',
      dialog: false,
      dialogDelete: false,
      
      vacantes: null,
      isDisbaledRegistrar:false,

      numero_actividades:0,
			fecha_inicio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      dialogSuccess:false,
      overlay: false,
      dialogError: 	false,
			error: 				'',
			successMensaje:'',
			errorBack:false,
      textoCopiado:'COPIAR',
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
    },

    async created () {
    	console.log(this.getdatosUsuario)
      await this.initialize()
    },

    methods: {
      initialize () {
        this.vacantes = null
        this.overlay = true
        return this.$http.get('vacantes.disponibles').then(response=>{
        	this.vacantes = response.data
        	this.vacantesTotales     = this.vacantes.vacantes
					this.vacantesDisponibles = 0
        	this.overlay = false
        }).catch(error=>{
        	// INdicamos que hay un error en el back
        	if(error.body.message){
        		this.error = error.body.message
        	}else{
        		this.error = error.body
        	}
        	this.errorBack = true
        	this.overlay = false
        	this.dialogError = true
        })
      },

      registrar(){
      	if(this.vacantesTotales > 0){
	      	this.isDisbaledRegistrar = true
	      	const payload = {
		      	vacantes             : this.vacantesTotales,
		      	vacantes_disponibles : this.vacantesDisponibles,
		      	usuario_creo         : this.getdatosUsuario.iderp
		      }
		      // Lo mandapos por el EP
		      this.$http.post('registro.vacantes', payload).then(response=>{
		      	this.dialogSuccess       = true
						this.vacantesDisponibles = 0
		      	this.initialize()
		      	this.overlay = false
		      	this.isDisbaledRegistrar = false
		      }).catch(error=>{
		      	// INdicamos que hay un error en el back
		      	if(error.body.message){
		      		this.error = error.body.message
		      	}else{
		      		this.error = error.body
		      	}
		      	this.errorBack = true
		      	this.overlay = false
		      	this.dialogError = true
		      	this.isDisbaledRegistrar = false
		      })
      	}
      },

      copiarPortapapeles () {
        navigator.clipboard.writeText(this.error).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      },


    },
  }
</script>


