<template>
  <!-- Dialog para eliminar tarea-->
  <v-dialog v-model="parametros.estatus" max-width="400px">
    <v-card>
      <v-card-text>
        <v-row justify="space-between">
          <v-col cols="6">
            <v-card class="elevation-0" height="100%" style="display: table;">
              <div style="display: table-cell; vertical-align: middle;">
                <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar la información?
                <v-card-actions class="pt-6">
                  <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                  <v-btn color="error" text large class="mr-2 text-capitalize" @click="parametros.estatus = false">No, cancelar</v-btn>
                  <!-- Guardar la información  -->
                  <!-- <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="deleteItemConfirm()">Sí</v-btn> -->
                  <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="$emit('click'), parametros.estatus = false">Sí</v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-img src="@/assets/borrar.png" ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
	export default {
	  props: ['parametros'],
	}
</script>