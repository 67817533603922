<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Examen Calidad</span>
            <v-spacer></v-spacer>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true, recurso = null, grupo = null"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize(), getGrupos()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>            
              <v-col cols="12" md="6">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="grupos_examenes"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                >

                  <template v-slot:item.id_recurso="{ item }">
                    <v-chip color="green" small dark v-if="item.id_recurso == 1">Examen Calidad 1</v-chip>
                    <v-chip color="green" small dark v-if="item.id_recurso == 2">Examen Calidad 2</v-chip>
                    <v-chip color="green" small dark v-if="item.id_recurso == 3">Examen Calidad 3</v-chip>
                    <v-chip color="green" small dark v-if="item.id_recurso == 4">Examen Calidad 4</v-chip>
                  </template>

                  <template v-slot:item.id_unidad_negocio="{ item }">
                    <v-chip color="blue" small dark v-if="item.id_unidad_negocio == 1">INBI</v-chip>
                    <v-chip color="red" small dark v-if="item.id_unidad_negocio == 2">FAST</v-chip>
                  </template>

                  <template v-slot:item.ver="{ item }">
                    <v-icon small @click="dialogCalificaciones = true, (infoGrupos = item), getCalificaciones()" color="blue">
                      mdi-eye
                    </v-icon>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon small @click="deleteItem(item), (infoGrupos = item)" color="error">
                      mdi-delete
                    </v-icon>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
            <v-col cols="12">
                <v-autocomplete
                label="Grupos"
                id="id"
                filled
                dense
                hide-details
                v-model="grupo"
                :items="grupos"
                item-text="grupo"
                item-value="id_grupo"
                clearable
                @change="handleAutocompleteChange"
                ></v-autocomplete>
            </v-col>

              <v-col cols="12" >
                <v-autocomplete
                  label="Examen Calidad"
                  id="id"
                  filled
                  dense
                  hide-details
                  v-model="recurso"
                  :items="[
                    { id_recurso: 1, nombre: 'Examen Calidad 1' },
                    { id_recurso: 2, nombre: 'Examen Calidad 2' },
                    { id_recurso: 3, nombre: 'Examen Calidad 3' },        
                    { id_recurso: 4, nombre: 'Examen Calidad 4' },          
                  ]"
                  item-text="nombre"
                  item-value="id_recurso"
                  clearable
                  disabled
                ></v-autocomplete>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
          <v-btn color="blue darken-1" class="elevation-6" dark @click=" addGrupoExamen()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCalificaciones" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Calificaciones - Alumnos</span>
        </v-card-title>

         <v-card-text>
            <v-row>            
              <v-col cols="12" md="6">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headersCalificaciones"
                  :items="calificaciones"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                >

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogCalificaciones = false"> Cancelar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el examen del grupo?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      large
                      class="mr-2"
                      @click="closeDelete()"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      large
                      @click="deleteItemConfirm()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,
    dialogCalificaciones: false,
    headers: [
      { text: "ID", align: "start", value: "id" },
      { text: "Grupo", value: "nombre" },
      { text: "Examen", value: "id_recurso" },
      { text: "Escuela", value: "id_unidad_negocio" },
      { text: "Ver", value: "ver", sortable: false },
      { text: "Eliminar", value: "actions", sortable: false },
    ],

    headersCalificaciones: [
      { text: "ID", align: "start", value: "id" },
      { text: "Nombre", value: "nombre_completo" },
      { text: "Calificacion", value: "calificacion" },
    ],
    recurso: '',
    grupo: '',
    calificaciones: [],
    horasextras: [],
    grupos: [],
    grupos_examenes: [],
    infoGrupos: [],
    editedIndex: -1,
    editedItem: {
      idhoras_extras: "",
      fecha: "",
      hora_inicio: "",
      hora_fin: "",
      cantidad_horas: 0,
      cantidad_minutos: 0,
      notas: "",
      estatus: 0,
      id_creo: ""
    },
    defaultItem: {
      idhoras_extras: "",
      fecha: "",
      hora_inicio: "",
      hora_fin: "",
      cantidad_horas: 0,
      cantidad_minutos: 0,
      notas: "",
      estatus: 0,
      id_creo: ""
    },

    dialogSuccess: false,
    overlay: false,
    dialogError: false,
    error: "",
    successMensaje: "",
    errorBack: false,
    textoCopiado: "COPIAR",
    idcurso: 0,
    info: 0,
    dialog_mensaje_rechazo: false,
    idhistorial: [],
    fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    editedItem2: {
      mensaje: "",
    },
    mensajes: [],

    ciclos: [],
    ciclo: null,
    estatus: null,
    alumnos: [],
    reglas: [],
    cursosEscuela: [],
    horasExtras: "",
    // tabla
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
    formTitle() {
      return this.editedIndex === -1
        ? "Agregar Grupos-Examen"
        : "Editar Grupos-Examen";
    },

    

  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "editedItem.escuela"(val) {
      this.cursosEscuela = this.cursos.filter((el) => {
        return el.escuela == val;
      });
    },
  },

  created() {
    this.getGrupos();
    this.initialize();
    // this.getCiclos();
    // this.getAlumnos();
    // this.getReglas();
  },

  methods: {
    initialize() {
      this.grupos_examenes = [];
      this.overlay = true;

      this.$http
        .post("examencalidad.get.grupos.examenes")
        .then((response) => {
          this.grupos_examenes = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    getGrupos() {
      this.grupos = [];
      this.overlay = true;

      const payload = {
      fecha: this.fecha
      };

      this.$http
        .post("examencalidad.get.grupos", payload)
        .then((response) => {
          this.grupos = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    getCalificaciones() {
      this.overlay = true;
      const payload = {
      id_grupo: this.infoGrupos.id_grupo,
      unidad_negocio: this.infoGrupos.id_unidad_negocio  
      };

      this.$http
        .post("examencalidad.get.calificaciones", payload)
        .then((response) => {
          this.calificaciones = response.data;
          console.log('calificaciones',this.calificaciones)
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },
  
    addGrupoExamen() {
      const payload = {
        id_recurso: this.recurso,
        iderp: this.grupo
       };
      this.$http
        .post("examencalidad.add.grupos.examenes", payload)
        .then((response) => {
          this.dialogSuccess = true;
          this.initialize();
          this.dialog = false;  
        })
        .catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
    },

    editItem(item) {
      this.editedIndex = this.incidencias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.horasextras.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {

      const payload = {
        id_grupo: this.infoGrupos.id_grupo,
        unidad_negocio: this.infoGrupos.id_unidad_negocio  
       };

      this.$http
        .post("examencalidad.delete.grupos.examenes",payload)
        .then((response) => {
          this.dialogSuccess = true;
          this.overlay = false;
          this.dialogDelete = false;
          this.initialize();
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    copiarPortapapeles() {
      navigator.clipboard
        .writeText(this.error)
        .then(() => {
          this.textoCopiado = "COPIADO";
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });
    },

    //Trae la informacion de toda la fila de alumnos en "Agregar Incidencia"
    infoAlumnos() {
      const alumnoSeleccionado = this.alumnos.find(
        (alumno) => alumno.iderp === this.editedItem.idusuarioerp
      );

      this.info = alumnoSeleccionado.tipo_curso;
     
    },

    handleAutocompleteChange() {
      let selectedItem = this.grupos.find(item => item.id_grupo === this.grupo);
      let nombre_grupo = selectedItem ? selectedItem.grupo : '';

      let FAST = nombre_grupo.includes('FAST');

      let regex = /NIVEL (\d+)/;
      let match = regex.exec(nombre_grupo);

      if (match && match[1]) {
        let nivel = parseInt(match[1])

        if(FAST){
          if( nivel == 1 || nivel ==  2 || nivel == 3 || nivel == 4 ){ this.recurso = 1}
          else if ( nivel == 5 || nivel ==  6 || nivel == 7 || nivel == 8 || nivel == 9 ){ this.recurso = 2}
          else if ( nivel ==  10 || nivel == 11 || nivel == 12 || nivel == 13 || nivel ==  14 ){ this.recurso = 3}
        } else {
          if( nivel == 1 || nivel ==  2 || nivel == 3 || nivel == 4 ){ this.recurso = 1}
          else if ( nivel == 5 || nivel ==  6 || nivel == 7 || nivel == 8 ){ this.recurso = 2}
          else if ( nivel == 9 || nivel ==  10 || nivel == 11 || nivel == 12 ){ this.recurso = 3}
          else if ( nivel == 13 || nivel ==  14 ){ this.recurso = 4}
        }
      } else { this.recurso = null; }
    },

  },
};
</script>
