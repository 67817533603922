<template>
  <v-tooltip bottom color="primary">
    <template v-slot:activator="{ on, attrs }">
      <v-card
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        @click="$emit('click')"
        class="text-center mr-2"
        style="border-radius: 200px;"
        max-width="160"
      >
        <v-card-text class="py-1">
          <div class="white--text text-h5">
            <v-icon
              size="22"
              color="white"
              v-text="'mdi-account-switch'"
            >
            </v-icon>
          </div>
          <sub class="text-caption white--text ">Reasignar</sub>
        </v-card-text>
      </v-card>
    </template>
    <span>Reasignar el prospecto a otra vendedora</span>
  </v-tooltip>
</template>


<script>
import axios from 'axios';
	export default {
		props:[
			'comentarios'
	  ],
	}
</script>
