<template>
  <v-container >
    <v-dialog
      v-model="paramsDialogHermanos.estatus"
      persistent
      max-width="800"
    >
      <v-card v-if="alumno">
        <v-card-title class="text-h5">
          Solicitud: Desligar hermanos
        </v-card-title>

        <v-card-text class="black--text">
        	Aquí son los hermanos que se desean deslogar
          <v-row>
            <v-col cols="12" md="8" >
              <v-divider></v-divider>
                <v-data-table
							    :headers="headers"
							    :items="hermanos"
							    dense
							  ></v-data-table>

                <v-row>
                	<v-col cols="12">
                		<v-textarea
                			readonly
                			v-model="alumno.motivo"
                			filled
                		>
                		</v-textarea>	
                	</v-col>

                  <v-col cols="4" v-for="(img, i) in alumno.fotos" :key="i">
                    <v-card class="py-4 shadowCard" @click="verImagen( img.imagen )">
                      <v-img :src="url + img.imagen"  contain aspect-ratio="2"></v-img>
                    </v-card>
                  </v-col>
                </v-row>

              </div>
            </v-col>

          </v-row>
          <v-divider></v-divider>
          <br/>


          <v-checkbox
          	v-if="alumno.estatus == 2"
			      v-model="alumno.whatsapp"
			      label="Ya se le agregó al alumno al grupo de WhatsApp"
			      hide-details
			    ></v-checkbox>

			    <v-checkbox
			    	v-if="alumno.estatus == 2"
			      v-model="alumno.lms"
			      label="Ya se le agrego al alumno en el LMS"
			      hide-details
			    ></v-checkbox>

			    <v-checkbox
			    	v-if="alumno.estatus == 2"
			      v-model="alumno.zoom"
			      label="Ya se le envió el codigo de zoom al alumno"
			      hide-details
			    ></v-checkbox>
        </v-card-text>
        <v-card-actions>

          <v-btn
            color="green"
            dark
            tile
            @click="idsolicitud_cambio = alumno.idsolicitud_cambio, paramsHistorial.estatus = true"
            small
          >
            Historial
            <v-icon small right>mdi-file-document-multiple</v-icon>
          </v-btn>

          <v-btn
            color="black"
            small
            tile
            dark
            @click="paramsDialogHermanos.estatus = false"
            absolute
            top
            right
            text
          >
            <v-icon >mdi-close</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            v-if="![4].includes( alumno.estatus ) "
            class="mr-2"
            color="black"
            dark
            tile
            @click="idsolicitud_cambio = alumno.idsolicitud_cambio, paramsRechazo.estatus = true"
            small
          >
            <v-icon small left>mdi-close</v-icon>
            Rechazar
          </v-btn>
    
          <v-btn
            v-if="[28, 334, 707 ].includes( getdatosUsuario.iderp ) && [1,3].includes( alumno.estatus ) "
            color="green"
            dark
            tile
            @click="addCambioFinanzas()"
            :loading="loading"
            :disabled="disabled" 
            small
          >
            <v-icon small left>mdi-check</v-icon>
            Aceptar
          </v-btn>

          <v-btn
            v-else-if="[2].includes( alumno.estatus ) "
            color="green"
            dark
            tile
            @click="updateCambio()"
            :loading="loading"
            :disabled="disabled" 
            small
          >
            <v-icon small left>mdi-check</v-icon>
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Ver evidencias -->
    <v-dialog v-model="dialogImagen" max-width="1000px">
      <v-card class="elevation-0">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogImagen = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-img
            :src=" url + imagen" 
            contain
            max-height="80vh"
          >
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <dialogHistorial
      v-if="paramsHistorial.estatus"
      :paramsHistorial="paramsHistorial"
      :idsolicitud_cambio="idsolicitud_cambio"
      :alumno="alumno"
    />

    <dialogRechazar
      v-if="paramsRechazo.estatus"
      :paramsRechazo="paramsRechazo"
      :idsolicitud_cambio="idsolicitud_cambio"
      @rechazado="rechazado"
    />

  </v-container>
</template>
<script>
  import axios from 'axios';
  var moment = require('moment');
  moment.locale();
  import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import dialogHistorial       from '@/components/cambios/dialogHistorial.vue';
  import dialogRechazar        from '@/components/cambios/dialogRechazar.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    components:{
      Alerta,
      carga,
      dialogHistorial,
      dialogRechazar
    },

    mixins: [ validarErrores, funcionesExcel ],

    props: ['reporteTexto','alumno','paramsDialogHermanos'],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      

      tipociclo:'',
      grupos:[],
      nuevoGrupo:null,
      tipocambios:[],
      tipoCambio:null,
      alumnos:[],

      dialogUpdateCambio: false,
      alumno:null,
      dialogSuccess:false,
      successMensaje:'',
      dialogRechazar:false,
      mensajeRechazo:'',
      dialogMensajes: false,
      mensajes:[],
      dialogAddCambios: false,

      teachers: [],

      grupoAlumnos:   [],
      grupoAnterior:  null,
      
      dialogAddCambio:false,
      loading:        false,
      disabled:       false,

      total_a_pagar: 0,
      precios:[],

      dataGrupoActual: null,
      dataNuevoGrupo:null,
      precioSeleccionado2: null,
      descuentoHermanos:0,

      url:'',

      imagen: null,
      dialogImagen: false,
      

      paramsHistorial: {
        estatus: false
      },

      paramsRechazo: {
        estatus: false
      },

      headers: [
        { text: 'Matricula'  , value: 'matricula' },
        { text: 'Alumno'     , value: 'alumno'    },
      ],

      hermanos:[],

    }),

    watch:{
      
    },

    async created () {
      console.clear()

      this.url = axios.defaults.baseURL + 'evidencia-cambios/'

      // Ahora traemos los alumnos por escuela
      await this.initialize()

    },

    methods: {

      initialize () {
        const payload = {
          idsolicitud_cambio: this.alumno.idsolicitud_cambio,
        }

        this.cargar = true

        this.$http.post('cambios.hermanos', payload).then(response=>{
        	
        	this.cargar = false
          this.hermanos = response.data

          console.log( response.data )

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      updateCambio () {
      	let { whatsapp, lms, zoom, estatus } = this.alumno
      	if( estatus != 2 ) {
      		this.error = 'No es posible actualizar los datos ya que la solicutd aún no ah sido respondida'
          this.dialogError = true
          this.cargar = false
          return 
      	}

      	if(whatsapp && zoom) {
	      	whatsapp = whatsapp ? 1 : 0
	      	zoom     = zoom     ? 1 : 0

	      	this.cargar = true

	        let payload = {
	          idsolicitud_cambio: this.alumno.idsolicitud_cambio,
	          estatus:            4,
						lms,
	          whatsapp,
						zoom,
						fecha_aceptacion:   (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
	          fecha_cambio:       this.alumno.fecha_cambio,
	          realizo_cambio:     this.getdatosUsuario.iderp,
	          hermanos
	        }
	        
	        this.$http.put('cambios.update/' + payload.idsolicitud_cambio,payload).then(response=>{
	          this.cargar        = false
	          this.successMensaje = 'Datos grabados correctamente'
	          this.dialogSuccess  = true
	          this.$emit('getAlumnosxEscuela')
	        }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
	        this.dialogUpdateCambio = false
      	}else{
      		this.error = 'Para aceptar el cambio, necesitas aceptar primero que ya el alumno está en su nuevo grupo y que ya se le envió su código de zoom'
          this.dialogError = true
          this.cargar = false
      	}
      },

      rechazar () {
      	let { whatsapp, lms, zoom, estatus } = this.alumno
      	if( estatus != 2 ) {
      		this.error = 'No es posible actualizar los datos ya que la solicutd aún no ah sido respondida'
          this.dialogError = true
          this.cargar = false
          return 
      	}
      	this.cargar = true

        let payload = {
          idsolicitud_cambio: this.alumno.idsolicitud_cambio,
          estatus:   3,
          whatsapp:  0,
					lms:       0,
					zoom:      0
        }
	        
        this.$http.put('cambios.update/' + payload.idsolicitud_cambio,payload).then(response=>{
          this.cargar        = false
          this.successMensaje = 'Datos grabados correctamente'
          this.dialogSuccess  = true
          this.getAlumnosxEscuela()
        }).catch(error=>{
          this.error = error.body.message
          this.dialogError = true
          this.cargar = false
        })
        this.dialogUpdateCambio = false
      },

      enviarMensaje () {
        if(this.mensajeRechazo != '' && this.mensajeRechazo != null){
        	this.cargar = true
          let payload = {
            idsolicitud_cambio: this.alumno.idsolicitud_cambio,
            mensaje:            '',
            respuesta:          this.mensajeRechazo,
            envia:              0,
          }

          this.$http.post('cambios.mensaje',payload).then(response=>{
          	this.dialogRechazar = false
          	this.dialogMensajes = false
          	this.mensajeRechazo = ''
          	this.mensajes       = []
          	this.rechazar()
          	this.cargar = false
          }).catch(error=>{
            this.error = error.body.message
            this.dialogError = true
            this.cargar = false
          	this.dialogRechazar = false
          })
        }else{
          this.error = 'Ingresa un mensaje'
          this.dialogError = true
          this.dialogRechazar = false
        }
      },

      cargarMensajes () {
      	this.$http.get('cambios.mensaje/' + this.alumno.idsolicitud_cambio).then(response=>{
        	this.mensajes = response.data
        	this.dialogMensajes = true
        	this.dialogUpdateCambio = false
        	this.cargar = false
        }).catch(error=>{
          this.error = error.body.message
          this.dialogError = true
          this.cargar = false
        	this.dialogRechazar = false
        })
      },

      getAhora () {
      	let fecha = new Date().toLocaleString();
    		fecha = moment(fecha).format().replace('T',' ').substr(0,19)
    		return fecha
      },

      async addDialogCambio (alumno) {
        await this.getGrupoUsuario(alumno.id_alumno)
        this.alumno = alumno
        this.step += 1
        // this.dialogAddCambio = true
      },

      getGrupoUsuario ( idalumno ){
        this.cargar = true
        this.grupoAlumnos = []
        return this.$http.get('cambios.grupo.usuario/' + idalumno).then(response=>{
          this.grupoAlumnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      addCambioFinanzas() {

        // Validar que esten llenos los campos
        if(this.alumno ){

          // Activar el disabled del boton para que no le den doble click
          this.loading  = true
          this.disabled = true
          this.overlay  = true

          // Desesestructuración de alumno 
          const { id_alumno, idtipo_cambio, solicito_cambio,idsolicitud_cambio } = this.alumno

          // Generar el payload a mandar
          let payload = {
            id_alumno,
            idtipo_cambio,
            solicito_cambio,
            estatus:                2,
            iderp:                  this.getdatosUsuario.iderp, 
            acepto:                 this.getdatosUsuario.iderp,
            idsolicitud_cambio,
            hermanos: this.hermanos
            // Datos para el grupo con saldo a favor
          }

          // Enviar los datos 
          this.$http.post('eliminar.hermanos.cambios',payload).then(response=>{
            this.validarSuccess('Cambio realizado con exito')
            // this.updatePrecioAlumno()
            this.$emit('getAlumnosxEscuela')
          }).catch( error =>{
            this.loading  = false
            this.disabled = false
            this.overlay  = false
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }else{
          this.estatusError('Favor de ingresar el grupo y el tipo de cambio')
        }
      },

      verImagen( imagen, es_solucion ){
        this.imagen       = imagen
        this.dialogImagen = true
      },

      rechazado( ){
        this.paramsRechazo.estatus = false
        this.$emit('getAlumnosxEscuela')

      }
    },

    computed:{
      ...mapGetters ('login',['getdatosUsuario']),

      costoCurso( ){
        
        if( this.precioSeleccionado ){

          let existeCosto = this.precios.find( el => el.id_curso == this.precioSeleccionado )

          return existeCosto ? existeCosto.precio_lista : 0

        }else{
          return 0
        }

      },
    }
  }
</script>

<style>

  .v-menu__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-menu__content::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-button:increment,.v-menu__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-menu__content::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>