import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';

export default {

	data: () =>({
		verGraficaFast: false,
    seriesContactosFast: [],
    chartOptionsFast: {
      chart: {
        type: 'bar',
        height: 450
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: 'Cantidad'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " Alumnos"
          }
        }
      }
    },


    verGraficaInbi: false,
    seriesContactosInbi: [],
    chartOptionsInbi: {
      chart: {
        height: 500,
        type: 'area'
      },

      dataLabels: {
        enabled: true
      },

      stroke: {
        curve: 'smooth'
      },

      xaxis: {
        // type: 'caracter',
        // categories: [],
        categories: [],
        
        axisBorder: {
          show: false,
        },

        axisTicks: {
          show: false,
        },
        tickPlacement: 'on',
        labels: {
          show: true,
          style: {
            fontSize: '12px',
          },
        },
      },
    },

    headersTabla: [
      { text: 'Vendedora'    , value: 'vendedora' },
      { text: 'Con adeudo'   , value: 'con_adeudo' },
      { text: 'I s/adeudo'   , value: 'sin_adeudo' },
      { text: 'I completo'   , value: 'completos' },
      { text: 'Total'        , value: 'total' },
    ],
		tablaFast:[],
    tablaInbi:[],

    dataTablaInscritos:[],

	}),

	methods: {
		consultar () {
      this.verGraficaInbi = false
      var payload = {
        fecha_inicio: this.fecha_inicio,
        fecha_final:  this.fecha_final ? this.fecha_final : this.fecha_inicio
      }

      this.$http.post('marketing.inscritos',payload).then(response=>{
        // Guardar los datos 
        const fechasUnicas        = response.data.fechasUnicas
        this.dataTablaInscritos   = response.data.dataTablaInscritos


        this.chartOptionsInbi.xaxis.categories = []
        for( const i in fechasUnicas ){
          const { fecha_creacion } = fechasUnicas[i]
          this.chartOptionsInbi.xaxis.categories.push(fecha_creacion)
        }

        this.seriesContactosInbi =  [{
          name: 'FAST',
          data: response.data.datosFast
        }, {
          name: 'INBI',
          data: response.data.datosInbi
        }]

        this.verGraficaInbi = true
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
		}     
  }
}