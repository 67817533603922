<template>
  <v-container fluid class="mt-4">
  	<v-row>
  		<v-col cols="12">
	  		<v-card class="shadowCard">
	  			<v-card-title primary-title>
	  			  Contactos 
	  			  <v-spacer></v-spacer>
	  		    <v-btn color="primary" @click="initialize()" tile small>Buscar</v-btn>
	  			</v-card-title>

	  			<v-card-text>
	  				<v-row>
	  					<v-col cols="12" md="6" lg="3">
			  		    <v-text-field
			  		      label="Fecha de inicio"
			  		      filled
			  		      hide-details
			  		      dense
			  		      type="date"
			  		      v-model="fecha_inicio"
			  		    ></v-text-field>
	  					</v-col>
	  					<v-col cols="12" md="6" lg="3">
			  		    <v-text-field
			  		      label="Fecha final"
			  		      filled
			  		      hide-details
			  		      dense
			  		      type="date"
			  		      v-model="fecha_final"
			  		    ></v-text-field>
	  					</v-col>

	  				</v-row>

	  				<v-row>

	  					<v-col cols="12" md="6">
	  						<v-card class="shadowCard">
	  							<v-card-title primary-title class="text-subtitle-1">
	  							  Inbi
	  							</v-card-title>
							    <v-data-table
							      :headers="headersCursoInteres"
							      :items="planteles_list_inbi"
							      dense
							    >
							    </v-data-table>
	  						</v-card>
	  					</v-col>

	  					<v-col cols="12" md="6">
	  						<v-card class="shadowCard">
	  							<v-card-title primary-title class="text-subtitle-1">
	  							  Fast
	  							</v-card-title>
							    <v-data-table
							      :headers="headersCursoInteres"
							      :items="planteles_list_fast"
							      dense
							    >
							    </v-data-table>
	  						</v-card>
	  					</v-col>

	  				</v-row>
	  			</v-card-text>
	  		</v-card>
  		</v-col>
  	</v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>

<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


export default {
  components:{
    Alerta,
    carga,
  },

  mixins: [ validarErrores, funcionesExcel ],

  data(){
  	return{
  		fecha_inicio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fecha_final:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			overlay: false,
			planteles:[],
			planteles_list_inbi:[],
			planteles_list_fast:[],

			headersCursoInteres: [
        { text: 'SUCURSAL'         , value: 'plantel' },
        { text: 'Contactos MKT'    , value: 'contactosmkt' },
        { text: 'Contactos Suc'    , value: 'contactossuc' },
        { text: 'Total'            , value: 'contactos' },
      ],

  	}
  },

  async created(){
  	await this.initialize()
  },

  methods:{
  	initialize(){
  		this.cargar = true
  		const payload = {
  			fecha_inicio: this.fecha_inicio,
  			fecha_final:  this.fecha_final,
  			escuela:      1
  		}
			this.$http.post('data.dashboard.contactos', payload).then((response) => {
				this.planteles_list_inbi  = response.data.planteles_list.filter( el => el.escuela == 1 )
				this.planteles_list_fast  = response.data.planteles_list.filter( el => el.escuela == 2 )
				this.cargar = false
        return
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
  	},

  	abrirModalDesgloce( data ){
  		this.fuente         = data
  		this.desglose       = data.detalles
  		this.dialogDesglose = true
  	}
  },

  computed:{
    tamanioPantalla () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        return 'auto';
          // return this.$vuetify.breakpoint.height -110
        break;
        case 'sm': 
          return this.$vuetify.breakpoint.height - 65
        break;
        case 'md':
          return this.$vuetify.breakpoint.height - 65
        break;
        case 'lg':
          return this.$vuetify.breakpoint.height - 65
        break;
        case 'xl':
          return this.$vuetify.breakpoint.height - 65
        break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>