<template>
  <v-container class="mt-4 ml-2">

	  <v-snackbar v-model="alerta.activo" multi-line vertical top right :color="alerta.color" > 
	    <strong> {{alerta.texto}} </strong>
	    <template v-slot:action="{ attrs }">
	      <v-btn color="white" text @click="alerta.activo = false" v-bind="attrs"> Cerrar </v-btn>
	    </template>
	  </v-snackbar>

	  <v-row justify="center">
	    <v-col cols="12">
	    	<v-card class="shadowCard">
	    	  <v-card-title primary-title>
	    	    Alumnos Cambridge
	    	    <v-spacer></v-spacer>
	    	    <v-btn color="primary" small @click="getCodigos()">Actualizar</v-btn>
	    	  </v-card-title>
      		<v-card-text>
      			<v-row justify="end">
      				<v-col cols="12" md="6" lg="4">
		      		  <v-text-field
		      		    label="Buscar"
		      		    v-model="search"
		      		    filled
		      		  ></v-text-field>
      				</v-col>
      			</v-row>

            <v-data-table
              dense
              :headers="tableHeader"
              :items="alumnos"
              item-key="name"
					    @page-count="pageCount = $event"
					    :search="search"
            >

					    <template v-slot:item.estatus="{ item }">
	              <v-chip v-if="item.estatus == 0 "  small color="red"                   dark> En proceso      </v-chip>
	              <v-chip v-if="item.estatus == 1 "  small color="pink accent-4"         dark> Nombre completo </v-chip>
	              <v-chip v-if="item.estatus == 2 "  small color="deep-purple darken-3"  dark> Solicitado      </v-chip>
	              <v-chip v-if="item.estatus == 3 "  small color="blue darken-3"         dark> Generado        </v-chip>
	              <v-chip v-if="item.estatus == 4 "  small color="cyan darken-3"         dark> Impreso         </v-chip>
	              <v-chip v-if="item.estatus == 5 "  small color="teal accent-3"         dark> Eniviado        </v-chip>
	              <v-chip v-if="item.estatus == 6 "  small color="orange darken-3"       dark> Recibido        </v-chip>
	              <v-chip v-if="item.estatus == 7 "  small color="green"                 dark> Entregado       </v-chip>
	            </template>

	            <template v-slot:item.actions="{ item }">
	              <v-chip v-if="item.estatus == 5 "  small color="orange darken-3"  dark class="elevation-12"       @click="actualizar( item, 6)"> Recibir   </v-chip>
	              <v-chip v-if="item.estatus == 6 "  small color="green"            dark class="elevation-12"  dark @click="entregar( item, 7)">   Entregar  </v-chip>
	              <v-chip v-if="item.estatus == 7 "  small color="green"            dark class="elevation-12"  dark @click="ver( item, 7)">        VER       </v-chip>
	            </template>

            </v-data-table>
      		</v-card-text>
	    	</v-card>
	    </v-col>
	  </v-row>

	  <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-h6" style="display: table-cell; vertical-align: middle;">
		      				<strong>¡Bien!</strong> Datos grabados correctamente
			      			<v-card-actions class="pt-6">
			      				<v-spacer></v-spacer>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="green" dark class="elevation-6" large @click="dialogSuccess = false">Ok</v-btn>
			      				<v-spacer></v-spacer>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/correcto.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="300"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      persistent
      max-width="600"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-h6" style="display: table-cell; vertical-align: middle;">
		      				<span v-if="errorBack">
		      					<strong>ERROR</strong> Da clic en "COPIAR" para guardar el error y enviarlo a sistemas
		      				</span>
		      				<span v-else>
		      					<strong>ERROR</strong> {{error}}
		      				</span>
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text large class="mr-2" @click="dialogError = false">Cerrar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="copiarPortapapeles()" v-if="errorBack">{{textoCopiado}}</v-btn>
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="dialogError = false" v-else>Ok</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/eliminar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="600px" v-if="alumno" persistent>
      <v-card class="elevation-0">
        <v-card-title primary-title>
          <strong>Por favor, agrega una evidencia, Gracias!</strong>
        </v-card-title>
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <input id="fileUpload" type="file" hidden @change="cargarFoto" accept="image/*" v-if="!file">
              <v-card height="100%" class="elevation-0" align="center" outlined @click="chooseFiles()" v-if="!file">
                <v-img src="@/assets/agregar_foto.jpg" contain aspect-ratio="2" max-width="450"></v-img>
              </v-card>
            </input>

            <v-card height="100%" class="elevation-0" align="center" outlined v-if="file">
              <v-img :src="fotoCargada.foto" contain aspect-ratio="2" max-width="450" v-if="fotoCargada">
                <v-btn color="red" fab dark small absolute button right @click="file = null"><v-icon>mdi-delete</v-icon></v-btn>
              </v-img>
            </v-card>
          </v-col>
        </v-card-text>
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn color="error" text class="mr-2" @click="dialog = false, alumno = null">Cancelar</v-btn>
          <!-- Guardar la información  -->
          <v-btn
            color="primary" 
            dark 
            class="elevation-6" 
            @click="confirmar()"
          >Entregar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVer" max-width="600px" v-if="alumno" persistent :fullscreen="fullscreen">
      <v-card class="elevation-0">
        <v-card-title primary-title>
          <strong>{{ alumno.nombre }}</strong>
        </v-card-title>
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-card height="100%" class="elevation-0" align="center" outlined >
              <v-img :src=" foto + alumno.url" contain aspect-ratio="2" v-if="fullscreen">
              </v-img>

              <v-img :src=" foto + alumno.url" contain aspect-ratio="2" max-width="450" v-else >
              </v-img>
            </v-card>
          </v-col>
        </v-card-text>
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn color="error" text class="mr-2" @click="fullscreen = true" v-if="!fullscreen">Zoom</v-btn>
          <v-btn color="error" text class="mr-2" @click="fullscreen = false" v-else>Sin, Zoom</v-btn>
          <!-- Guardar la información  -->
          <v-btn
            color="primary" 
            dark 
            class="elevation-6" 
            @click="dialogVer = false, alumno = null"
          >Okey</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 

     
	</v-container>
</template>

<script>
import axios from 'axios';
	import XLSX from 'xlsx'
	import UploadExcelComponent from '@/components/UploadExcel.vue'

	import { mapGetters} from 'vuex'
	export default {
	  components: { 
	    UploadExcelComponent
	  }, 
	  data() {
	    return {
	    	alumnos:[],
	      page: 1,
	      pageCount: 0,
	      itemsPerPage: 20,
	      search:'',

	      conceptos:[{ idconcepto:1 , nombre: 'PRESUPUESTO'}, {idconcepto:2, nombre:'PAGO'}, {idconcepto:3, nombre:'REPORTE'},],
	      concepto:{},

	      municipio:{},

	      tableHeader: [ 
					{ text:'codigo'     , value:'codigo'},
	        // { text:'login'      , value:'login'},
	        // { text:'password'   , value:'password'},
	        // { text:'nombre'     , value:'nombre'},
	        // { text:'status'     , value:'status'},
	        { text:'id alumno'  , value:'id_alumno'},
	        { text:'alumno'     , value:'alumno'},
	        { text:'Nivel'      , value:'nivel'},
	        { text:'plantel'    , value:'plantel'},
	        { text:'Estatus'    , value:'estatus'},
	        { text:'Pasar a:'   , value:'actions'},
	      ],   

	      tableData: [],  // DATOS A MOSTRAR 
	      reportes : [],  // TABLA REPORTES

	      alerta: { activo: false, texto:'', color:'error'},
	      overlay: false,
	      
	      codigos:[],
	      alumno: null,
	      dialogSuccess:false,
	      overlay: false,
	      dialogError: 	false,
				error: 				'',
				successMensaje:'',
				errorBack:false,
	      textoCopiado:'COPIAR',
	      
	      fotoCargada:null,
        file: null,

        dialog: false,
        dialogVer: false,
        foto: '',
        fullscreen:false,

	    }
	  },

	  async created(){
	  	this.foto = axios.defaults.baseURL + 'imagenes-recepcion-cambridge/'
	  	await this.getCodigos()
	  },

	  computed:{
	    ...mapGetters('login',['getdatosUsuario']),
	    tamanioPantalla () {
	      return this.$vuetify.breakpoint.height -380
	    },
	  },

	  methods: {

	  	getCodigos () {
	  		this.overlay = true
	  		this.alumnos = []
	  		return this.$http.get('academico.alumnos.codigos').then( response =>{
	  			// for(const i in response.data){
	  			// 	if(response.data[i].estatus >= 5){
	  			// 		this.alumnos.push(response.data[i])
	  			// 	}
	  			// }
	  			this.alumnos = response.data
	    		this.overlay = false
	      }).catch( error =>{
	        this.alerta = { activo: true, texto: error.body, color:'error' };
	        this.overlay = false
	      }).finally(()=>{
	        this.overlay = false;
	      })
	  	},

	    validarCodigos () {
	    	this.tableData = []
	    	let payload = { codigos: this.codigos }
	    	this.$http.post('academico.getcodigos', payload).then( response =>{
	    		this.tableData = response.data
	      }).catch( error =>{
	        this.alerta = { activo: true, texto: error.body, color:'error' };
	      }).finally(()=>{
	        this.overlay = false;
	      })
	    },

	    async save (item) {
	      const { idcertificacion, nivel, id_plantel, status } = item
	      await this.updateCodigo( idcertificacion, nivel, id_plantel, status )

	      this.snack = true
	      this.snackColor = 'success'
	      this.snackText = 'Data saved'
	    },

	    updateCodigo ( idcertificacion, nivel, id_plantel, status ) {
	      let payload = {
	      	idcertificacion,
	        nivel,
	        id_plantel,
	        status
	      }
	      return this.$http.put(`academico.codigo.nivel`, payload).then(response=>{
	        this.swalSuccess(response.data.mensaje)
	      }).catch(error=>{
	        this.overlay = false
	        this.swalError(error)
	      })
	    },

	    cancel () {
	      this.snack = true
	      this.snackColor = 'error'
	      this.snackText = 'Canceled'
	    },

	    open () {
	      this.snack = true
	      this.snackColor = 'info'
	      this.snackText = 'Dialog opened'
	    },
	    close () {
	      console.log('Dialog closed')
	    },

	    swalError(text) {
	    	this.$swal({
				  icon: 'error',
				  title: 'Oops...',
				  text,
				});
	    },

	    swalSuccess(text){
	      this.$swal({
	        position: 'top-end',
	        icon: 'success',
	        title: text,
	        showConfirmButton: false,
	        timer: 1500
	      })
	    },

	    actualizar (alumno, estatus) {
        this.overlay = true
        alumno.estatus = estatus

        this.$http.post('academico.update.certificado.cabridge/', alumno).then(response=>{
          this.overlay =       false
          this.dialogSuccess = true
          this.getCodigos()
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

	    ver(item){
        this.alumno = item
        this.dialogVer = true
      },

      crear( item, estatus ){
        this.alumno  = item
        this.overlay = true

        this.$http.post('academico.crear.certificado.cabridge', this.alumno).then(response=>{
          this.overlay       = false
          this.dialogSuccess = true
          this.getCodigos()
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

	    s2ab(s) {
	      var buf = new ArrayBuffer(s.length);
	      var view = new Uint8Array(buf);
	      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
	      return buf;
	    },

	    exportar(){
	      let data = XLSX.utils.json_to_sheet(this.grupoRolesPerfecto)
	      const workbook = XLSX.utils.book_new()
	      const filename = 'rolesPerfecto'
	      XLSX.utils.book_append_sheet(workbook, data, filename)

	      var wbout = XLSX.write(workbook, {
	        bookType: 'xls',
	        bookSST: false,
	        type: 'binary'
	      });

	      const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
	      
	      let formData = new FormData();
	      // //se crea el objeto y se le agrega como un apendice el archivo 
	      formData.append('file',file);
	      // /*getDatosRiesgo the form data*/

	      this.$http.post('pdfs',formData).then(response=> {
	        window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
	      }).catch(error=> {
	        console.log(error);
	      });
	    },

	    copiarPortapapeles () {
        navigator.clipboard.writeText(this.error).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      },

      entregar(alumno, estatus){
        this.alumno = alumno
        this.dialog = true
      },

      chooseFiles() {
        document.getElementById("fileUpload").click()
      },

      async confirmar(){
        if(this.fotoCargada){
          try{
            this.overlay = true
            const grabarImage  = await this.guardarImagen(this.alumno.idcertificacion).then(response=> response)
            this.dialog        = false
            this.overlay       = false
            this.dialogSuccess = true
            this.fotoCargada   = null
            this.file          = null
            this.getCodigos()
          }catch (e) {
            this.disabled = false
            this.openDialogError(e)
          }
        }else{
          this.error = 'Por favor, agrega una imagen'
          this.errorBack   = false
          this.overlay     = false
          this.dialogError = true
        }
      },

      guardarImagen ( id ) {
        return new Promise((resolve,reject)=>{
          // obtener la extensión del archivo
          const ext = this.getFileExtension1(this.file.name)
          // Hacemos la petición
          this.$http.post(`academico.cambridge.imagen/${ id }/${ ext }/${ this.alumno.escuela }`, this.fotoCargada.formdata ).then(response=>{
            resolve( response )
          }).catch(error=>{
            this.disabled = false
            // INdicamos que hay un error en el back
            if(error.body.bodyText){
              this.error = error.body.bodyText
            }else{
              this.error = error.body
            }
            this.errorBack = true
            this.overlay = false
            this.dialogError = true
          })
        })
      },


       // Cargar tofo
      cargarFoto(e){
        // Verificamos que haya un archivo
        if(e.target.files.length > 0){
          // guardamos el archivo seleccionado
          this.file = e.target.files[0]
          // creamos una variable tipo FormData
          let formData = new FormData();
          //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', this.file);
          //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          this.getBase64(this.file, formData, this.file.name)
        }
      },

      getBase64(archivo,formData,name) {
        var me = this
        // Creamos la variable de lectura
        var reader = new FileReader();
        // leemos el archivos
        reader.readAsDataURL(archivo);
        reader.onload =  function(){
          // Agregamos la foto cargada al arreglo que vamos a mostrar arriba en la pantalla
          me.fotoCargada = {
            foto: reader.result,
            formdata: formData,
          }
        }
      },

      getFileExtension1(filename) {
        return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
      },

      ver(item){
        this.alumno = item
        this.dialogVer = true
      }

	  }
	}
</script>