var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadowCard"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Registros")]),_c('v-spacer'),_c('v-chip',{staticClass:"mr-2",attrs:{"color":"grey","dark":"","small":""},on:{"click":function($event){return _vm.subirMatriculas( )}}},[_vm._v("LMS")]),_c('v-chip',{staticClass:"mr-2",attrs:{"color":"orange","dark":"","small":""},on:{"click":function($event){return _vm.initialize( )}}},[_vm._v("Activar Cuentas")]),_c('v-chip',{staticClass:"mr-2",attrs:{"color":"green","dark":"","small":""},on:{"click":function($event){return _vm.exportar( )}}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1),_c('v-chip',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.initialize( )}}},[_vm._v("Actualizar")])],1),_c('v-card-text',{staticClass:"pt-0"},[_c('v-row',{staticClass:"pt-0",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"label":"Buscar","id":"id","outlined":"","dense":"","rounded":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.registrosExci,"sort-by":"calories","search":_vm.search},scopedSlots:_vm._u([{key:"item.pago_realizado",fn:function(ref){
var item = ref.item;
return [(item.pago_realizado >= 1399 )?_c('v-chip',{attrs:{"color":"green","dark":"","small":""}},[_vm._v(_vm._s(item.pago_realizado)+" ")]):(item.pago_realizado >= 700   )?_c('v-chip',{attrs:{"color":"orange","dark":"","small":""}},[_vm._v(_vm._s(item.pago_realizado)+" ")]):_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v(_vm._s(item.pago_realizado)+" ")])]}},{key:"item.matricula",fn:function(ref){
var item = ref.item;
return [(item.pago_realizado >= 1399 && item.matricula != '')?_c('v-chip',{attrs:{"color":"green","dark":"","small":""}},[_vm._v(_vm._s(item.matricula))]):(item.matricula != '')?_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v(_vm._s(item.matricula))]):_vm._e()]}},{key:"item.lms",fn:function(ref){
var item = ref.item;
return [(item.lms == 1)?_c('v-chip',{attrs:{"color":"green","dark":"","small":""}},[_vm._v("si")]):_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("no")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.matricula != '')?_c('v-chip',{staticClass:"mr-2",attrs:{"color":"red","dark":"","small":""},on:{"click":function($event){return _vm.sendMatricula( item )}}},[_vm._v("Matri.")]):_vm._e(),(item.pago_realizado >= 700)?_c('v-chip',{staticClass:"mr-2",attrs:{"color":"blue","dark":"","small":""},on:{"click":function($event){return _vm.senZoom( item )}}},[_vm._v("Zoom")]):_vm._e(),_c('v-chip',{attrs:{"color":"orange","dark":"","small":""},on:{"click":function($event){return _vm.recordarPago( item )}}},[_vm._v("recordar Pago")])]}},{key:"item.correo",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.correo,"large":"","save-text":"Guardar","cancel-text":"Cancelar"},on:{"update:returnValue":function($event){return _vm.$set(props.item, "correo", $event)},"update:return-value":function($event){return _vm.$set(props.item, "correo", $event)},"save":function($event){return _vm.save( props.item )},"cancel":_vm.cancel,"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Editar correo","single-line":"","counter":""},model:{value:(props.item.correo),callback:function ($$v) {_vm.$set(props.item, "correo", $$v)},expression:"props.item.correo"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.correo)+" ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}])})],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")]),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar)?_c('carga'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }