<template>
  <v-container class="mt-4 px-5" fluid>
    <v-row>
      <v-col cols="12">
	      <v-card class="shadowCard">
	      	<v-card-title primary-title class="text-subtitle-1">
		        <v-toolbar-title>Calificaciones</v-toolbar-title>
		        <v-divider
		          class="mx-4"
		          inset
		          vertical
		        ></v-divider>
		        <v-spacer></v-spacer>
            <v-btn color="green" dark @click="exportarExcel()" small class="mr-2"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
		        <v-btn color="primary" @click="getGruposAlumnos()" small>Consultar</v-btn>
	      	</v-card-title>

	        <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
	              <v-autocomplete
	                filled
	                dense
	                clearable
	                v-model="cicloInf"
	                :items="ciclos"
	                label="Selecciona ciclo actual"
	                persistent-hint
	                hide-details
	                return-object
	                item-text="ciclo"
	                item-value="id_ciclo"
	              >
	              </v-autocomplete>
	            </v-col>
	          </v-row>

		        <!-- Apartado de las secciones de los tabs -->
            <v-row>
	            <v-col cols="12" lg="12">
	              <v-card class="elevation-0">
	                <v-row justify="end">
	                  <v-col cols="12" md="6" lg="4">
	                    <v-text-field
	                      label="Buscar"
	                      v-model="search1"
	                      filled
	                    ></v-text-field>
	                  </v-col>
	                </v-row>

	              	<v-data-table
	                  :search="search1"
								    dense
								    :headers="headers"
								    :items="gruposAlumnos"
								    item-key="id"
								    class="elevation-0"
								  >
								  	
								  	<template v-slot:item.ver="{ item }">
								  		<v-chip color="green" dark small @click="dialogAbrirPlataforma( item )">
									      <v-icon
									        small
									      >
									        mdi-magnify
									      </v-icon>
								  		</v-chip>
								    </template>

								    <template v-slot:item.kardexHabil="{ item }">
								  		<v-chip color="red"   v-if="item.kardexHabil == 1" dark small>A</v-chip>
								  		<v-chip color="green" v-else dark small>C</v-chip>
								    </template>
								  </v-data-table>
	              </v-card>
	            </v-col>
            </v-row>
	        </v-card-text>
	      </v-card>		
      </v-col>
    </v-row>

    <v-dialog
    	v-if="alumno"
      v-model="dialogAlumno"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Alumno
        </v-card-title>
        <v-card-text>
        	<v-text-field
        	  name="name"
        	  label="Primera"
        	  filled
        	  v-model="alumno.calificacion_final_primera_oportunidad"
        	  :readonly=" alumno.kardexHabil == 0 ? true : false"
        	></v-text-field>

        	<v-text-field
        	  name="name"
        	  label="Segunda"
        	  filled
        	  v-model="alumno.calificacion_final_segunda_oportunidad"
        	  :readonly=" alumno.kardexHabil == 0 ? true : false"
        	></v-text-field>

        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red" rounded small dark
            @click="dialogAlumno = false"
            class="text-capitalize"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
          	v-if="alumno.kardexHabil == 0"
            color="primary"
            rounded small
            @click="habilitar()"
            class="text-capitalize"
          >
            Habilitar plataforma
          </v-btn>

          <v-btn
          	v-else
            color="primary"
            rounded small
            @click="cerrarKardex()"
            class="text-capitalize"
          >
            Actualizar Calificación
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
  </v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
  import XLSX from 'xlsx'

  // Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'

  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      search1:'',

      headers: [
        { text: 'Matricula'  , value: 'matricula'                                    , width: '10%'},
        { text: 'Alumno'     , value: 'nombre'                                       , width: '25%'},
        { text: 'grupo'      , value: 'grupo'                                        , width: '50%'},
        { text: 'Kardex'     , value: 'kardexHabil'                                  , width: '5%'},
        { text: '1era'       , value: 'calificacion_final_primera_oportunidad'       , width: '5%'},
        { text: '2da'        , value: 'calificacion_final_segunda_oportunidad'       , width: '5%'},
        { text: 'ver'        , value: 'ver'                                          , width: '5%'},
      ],



      gruposAlumnos:[],
			horarios:null,
			cicloInf: 		null,
      ciclos:  			[],

      // Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
      dialogAlumno:false,
      calificaciones:{},
      verCalificaciones: false,

	  	asistencias:[],

	  	show: false,
	  	alumno: null,

    	respuestaAlerta: false,

      parametros:{
      	dialogError: false,
      	mensaje: ''
     	},
    }),

    watch: {
      cicloInf () {
        if(this.cicloInf){
          this.getGruposAlumnos()
        }
      },
    },

   
    created () {
      this.initialize()
    },

    methods: {
    	initialize () {
      	this.cargar = true
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      exportarExcel( ){
        this.exportExcel(  this.gruposAlumnos , 'plataformas_activas')
      },

      getGruposAlumnos () {
      	if(this.cicloInf){
	      	// vaciamos el horario
	      	this.gruposAlumnos = []
	      	// Activamos el loader
	    		this.cargar = true
	    		// Hacemos la peticios
	    		this.$http.get(`lms.catalogos.gruposalumnos.all/${ this.cicloInf.id_ciclo }/${ this.cicloInf.id_ciclo_relacionado }`).then(response=>{
	    			// Guardamos la respuesta
	          this.gruposAlumnos = []
	          // Quitamos el loader
	          for( const i in response.data.gruposAlumnosFast){
	          	response.data.gruposAlumnosFast[i]['escuela'] = 2
	          }

	          for( const i in response.data.gruposAlumnosInbi){
	          	response.data.gruposAlumnosInbi[i]['escuela'] = 1
	          }

	          const alumnos = response.data.gruposAlumnosFast.concat( response.data.gruposAlumnosInbi )

	          if(this.getdatosUsuario.idpuesto == 19 ){ // Administrador
	          	let mapIdPlanteles  = this.getdatosUsuario.planteles.map((registro) => registro.idplantel);
	          	this.gruposAlumnos = alumnos.filter( el => { return mapIdPlanteles.includes( el.id_plantel )})
			      }else{
			      	this.gruposAlumnos = alumnos
			      }

	          this.cargar = false
	        }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
      	}else{
      		this.validarErrorDirecto('Favor de seleccionar un ciclo')
      	}
      },

    	dialogAbrirPlataforma( item ){
    		this.alumno = item
    		this.dialogAlumno = true
    	},

    	habilitar( ){
    		this.cargar = true
    		this.$http.post(`habilitar.plataforma`, this.alumno).then(response=>{
	    		this.dialogAlumno = false
	    		this.alumno = null
	    		this.validarSuccess( response.data.message )
	    		this.getGruposAlumnos()
          this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
    	},
    	
    	cerrarKardex( ){
    		this.cargar = true
    		this.$http.post(`actualizar.calificacion`, this.alumno).then(response=>{
	    		this.dialogAlumno = false
	    		this.alumno = null
	    		this.validarSuccess( response.data.message )
	    		this.getGruposAlumnos()
          this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
    	},

    },

    computed:{
    	...mapGetters('login',['getdatosUsuario','getEscuela'])
    }
  }
</script>

<style>
	.v-snack__content{
		padding: 0px !important;
	}
</style>