<template>
  <v-container >
    <v-dialog
      v-model="paramsDialogCambio.estatus"
      persistent
      max-width="800"
    >
      <v-card v-if="alumno">
        <v-card-title class="text-h5">
          Solicitud: cambio de grupo
        </v-card-title>

        <v-card-text class="black--text">

          <v-row>
            <v-col cols="12" md="6" >
              <b>Matricula:</b> {{ alumno.matricula }}
              <br/>
              <b>Nombre:</b>    {{ alumno.alumno }}
              <br/>
              <b>Grupo:</b>     {{ alumno.grupo }}
              <br/>

              <v-divider></v-divider>
              <div v-if="dataGrupoActual" class="mb-10 black--text">
                <v-row>
                  <v-col cols="6" class="text-right pb-0">
                    <b>Costo curso:</b>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    {{ dataGrupoActual.precio_grupo }}
                  </v-col>

                  <v-col cols="6" class="text-right pb-0">
                    <b>Pagado:</b>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    {{ dataGrupoActual.pagado }}
                  </v-col>

                  <v-col cols="12">
                    <b>Concentrado de cambios</b>
                    <br/>
                    <p 
                      style="white-space: pre-line"
                    >
                      {{ alumno.texto_cambio }}
                    </p>

                  </v-col>
                </v-row>
              </div>
            </v-col>

            <v-col cols="12" md="6" >
              <b>Matricula:</b> {{ alumno.matricula }}
              <br/>
              <b>Nombre:</b>    {{ alumno.alumno }}
              <br/>
              <b>Grupo:</b>     {{ alumno.nuevo_grupo }}
              <br/>
              
              <v-divider></v-divider>

              <div v-if="dataNuevoGrupo && dataGrupoActual" class="mb-10 black--text">
                <v-row>
                  <v-col cols="6" class="text-right pb-0">
                    <b>Costo curso:</b>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    {{ costoCurso }}
                  </v-col>

                  <v-col cols="6" class="text-right pb-0">
                    <b>Pagado:</b>
                  </v-col>

                  <v-col cols="6" class="pb-0">
                    {{ dataGrupoActual.pagado }}
                  </v-col>

                  <v-col cols="6" class="text-right pb-0" v-if="dataGrupoActual.pagado - total_a_pagar > 0">
                    <b>Total a pagar:</b>
                  </v-col>

                  <v-col cols="6" class="pb-0" v-if="dataGrupoActual.pagado - total_a_pagar > 0">
                    {{ total_a_pagar }}
                  </v-col>

                  <v-col cols="6" class="text-right pb-0" v-if="dataGrupoActual.pagado - total_a_pagar > 0">
                    <b>Saldo a Favor:</b>
                  </v-col>
                  <v-col cols="6" class="pb-0" v-if="dataGrupoActual.pagado - total_a_pagar > 0">
                    {{ dataGrupoActual.pagado - total_a_pagar }} 
                  </v-col>

                  <v-col cols="12" class="pb-0">
                    <v-autocomplete
                      v-model="precioSeleccionado2"
                      :items="precios"
                      item-text="curso"
                      item-value="id_curso"
                      filled
                      dense
                    ></v-autocomplete>
                  </v-col>


                  <b>Grupo a saldo a favor </b>
                  {{ alumno.grupo_saldo }}

                  <v-col cols="6" class="text-right pb-0" v-if="dataGrupoActual.pagado - total_a_pagar > 0">
                    <b>Saldo a mover:</b>
                  </v-col>

                  <v-col cols="6" class="pb-0" v-if="dataGrupoActual.pagado - total_a_pagar > 0">
                    {{ dataGrupoActual.pagado - total_a_pagar }} 
                  </v-col>

                  <br/>
                  <br/>
                  <p 
                    style="white-space: pre-line"
                  >
                    <b>Motivo de cambio:</b> {{ alumno.motivo }}
                  </p>

                  <v-row>
                    <v-col cols="2" v-for="(img, i) in alumno.fotos" :key="i">
                      <v-card class="py-4 shadowCard" @click="verImagen( img.imagen )">
                        <v-img :src="url + img.imagen"  contain aspect-ratio="2"></v-img>
                      </v-card>
                    </v-col>
                  </v-row>

                </v-row>
              </div>
            </v-col>

          </v-row>
          <v-divider></v-divider>
          <br/>


          <v-checkbox
          	v-if="alumno.estatus == 2"
			      v-model="alumno.whatsapp"
			      label="Ya se le agregó al alumno al grupo de WhatsApp"
			      hide-details
			    ></v-checkbox>

			    <v-checkbox
			    	v-if="alumno.estatus == 2"
			      v-model="alumno.lms"
			      label="Ya se le agrego al alumno en el LMS"
			      hide-details
			    ></v-checkbox>

			    <v-checkbox
			    	v-if="alumno.estatus == 2"
			      v-model="alumno.zoom"
			      label="Ya se le envió el codigo de zoom al alumno"
			      hide-details
			    ></v-checkbox>
        </v-card-text>
        <v-card-actions>

          <v-btn
            color="green"
            dark
            tile
            @click="idsolicitud_cambio = alumno.idsolicitud_cambio, paramsHistorial.estatus = true"
            small
          >
            Historial
            <v-icon small right>mdi-file-document-multiple</v-icon>
          </v-btn>

          <v-btn
            color="black"
            small
            tile
            dark
            @click="paramsDialogCambio.estatus = false"
            absolute
            top
            right
            text
          >
            <v-icon >mdi-close</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            v-if="![4].includes( alumno.estatus ) "
            class="mr-2"
            color="black"
            dark
            tile
            @click="idsolicitud_cambio = alumno.idsolicitud_cambio, paramsRechazo.estatus = true"
            small
          >
            <v-icon small left>mdi-close</v-icon>
            Rechazar
          </v-btn>
    
          <v-btn
            v-if="[28, 334, 707 ].includes( getdatosUsuario.iderp ) && [1,3].includes( alumno.estatus ) "
            color="green"
            dark
            tile
            @click="addCambioFinanzas()"
            :loading="loading"
            :disabled="disabled" 
            small
          >
            <v-icon small left>mdi-check</v-icon>
            Aceptar
          </v-btn>

          <v-btn
            v-else-if="[2].includes( alumno.estatus ) "
            color="green"
            dark
            tile
            @click="updateCambio()"
            :loading="loading"
            :disabled="disabled" 
            small
          >
            <v-icon small left>mdi-check</v-icon>
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Ver evidencias -->
    <v-dialog v-model="dialogImagen" max-width="1000px">
      <v-card class="elevation-0">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogImagen = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-img
            :src=" url + imagen" 
            contain
            max-height="80vh"
          >
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <dialogHistorial
      v-if="paramsHistorial.estatus"
      :paramsHistorial="paramsHistorial"
      :idsolicitud_cambio="idsolicitud_cambio"
      :alumno="alumno"
    />

    <dialogRechazar
      v-if="paramsRechazo.estatus"
      :paramsRechazo="paramsRechazo"
      :idsolicitud_cambio="idsolicitud_cambio"
      @rechazado="rechazado"
    />

  </v-container>
</template>
<script>
  import axios from 'axios';
  var moment = require('moment');
  moment.locale();
  import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import dialogHistorial       from '@/components/cambios/dialogHistorial.vue';
  import dialogRechazar        from '@/components/cambios/dialogRechazar.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    components:{
      Alerta,
      carga,
      dialogHistorial,
      dialogRechazar
    },

    mixins: [ validarErrores, funcionesExcel ],

    props: ['reporteTexto','alumno','paramsDialogCambio'],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      

      tipociclo:'',
      grupos:[],
      nuevoGrupo:null,
      tipocambios:[],
      tipoCambio:null,
      alumnos:[],

      dialogUpdateCambio: false,
      alumno:null,
      dialogSuccess:false,
      successMensaje:'',
      dialogRechazar:false,
      mensajeRechazo:'',
      dialogMensajes: false,
      mensajes:[],
      dialogAddCambios: false,

      teachers: [],

      grupoAlumnos:   [],
      grupoAnterior:  null,
      
      dialogAddCambio:false,
      loading:        false,
      disabled:       false,

      total_a_pagar: 0,
      precios:[],

      dataGrupoActual: null,
      dataNuevoGrupo:null,
      precioSeleccionado2: null,
      descuentoHermanos:0,

      url:'',

      imagen: null,
      dialogImagen: false,
      

      paramsHistorial: {
        estatus: false
      },

      paramsRechazo: {
        estatus: false
      },

    }),

    watch:{
      grupoAnterior () {
        if(this.grupoAnterior){
          this.getNuevoGrupoAlumno ()
        }else{
          this.grupos = []
        }
      },

      nuevoGrupo( value ){
        this.precioSeleccionado = value ? value.id_curso : null
      },

      dataNuevoGrupo( value ){
        this.precioSeleccionado = value ? value.id_curso : null
      },

      precioSeleccionado( value ){
      	if( this.alumno && this.alumno.estatus > 1 ){

      	}else{
        	if( value && this.alumno){ this.getPrecioPagar() }
      	}
      },

      precioSeleccionado2( value ){
      	if( this.alumno && this.alumno.estatus > 1 ){

      	}else{
        	if( value && this.alumno){ this.getPrecioPagar2() }
      	}

      },

      tipo_reporte( val ){
        if( val ){ this.step += 1 }
      }
    },

    async created () {
      console.clear()

      this.url = axios.defaults.baseURL + 'evidencia-cambios/'

      // Ahora traemos los alumnos por escuela
      await this.initialize()

      // Primero necesitamos consultar el tipo de usuario y de donde viene
      await this.getTipoUsuarioEscuela()

      // Ahora si consultamos los grupos por tipo de escuela
      // Ahora traemos los tipos de cambio
      await this.getTitpoCambios()
      
      // Cargar los cursos actuales
      await this.getCursos()

    },

    methods: {

      initialize () {
        return this.$http.post('cambios.obtener.ajuste',this.alumno).then(response=>{
        	console.log( response.data )

        	if( this.alumno.estatus > 1 ){
        		this.dataGrupoActual = {
        			precio_grupo                       : this.alumno.precio_grupo,
        			descuento                          : this.alumno.descuento,
        			precio_grupo_con_descuento_x_alumno: this.alumno.precio_grupo_con_descuento_x_alumno,
        			pagado                             : this.alumno.pagado,
        			id_grupo                           : this.alumno.id_grupo_actual,
        		}
        	}else{
          	this.dataGrupoActual     = response.data.grupoActual
        	}
          this.dataNuevoGrupo      = response.data.nuevoGrupo
          this.precioSeleccionado2 = response.data.nuevoGrupo.id_curso
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCursos( ){
        this.cargar = true
        this.precios = []
        this.$http.get("all.precios").then((response) => {
          this.cargar = false
          this.precios = response.data.filter( el => el.curso.split("-")[1].match("3") );
        }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });
      },

      getPrecioPagar( ) {
      	console.log('0')

        const payload = {
          id_alumno: this.alumno.id_alumno,
          id_grupo:  this.alumno.id_grupo,
          escuela:   this.alumno.grupo.match('FAST') ? 2 : 1,
          id_curso:  this.precioSeleccionado,
          id_ciclo:  this.alumno.id_ciclo,
          alumno_ni: false
        }

        return this.$http.post('pago.grupo.nuevo.descuento', payload).then(response=>{

          this.total_a_pagar = response.data[0].precio_lista

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPrecioPagar2( ) {
      	console.log('1')

        const payload = {
          id_alumno: this.alumno.id_alumno,
          id_grupo:  this.dataGrupoActual.id_grupo,
          escuela:   this.dataNuevoGrupo.grupo.match('FAST') ? 2 : 1,
          id_curso:  this.precioSeleccionado2,
          id_ciclo:  this.dataNuevoGrupo.id_ciclo,
          alumno_ni: false
        }

        return this.$http.post('pago.grupo.nuevo.descuento', payload).then(response=>{

          this.total_a_pagar      = response.data[0].precio_lista
          this.descuentoHermanos  = response.data[0].descuentoHermanos

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getTipoUsuarioEscuela () {
        if([1,2,3,4,5,6,17,19,21,22,23,24,25,26].includes( this.getdatosUsuario.id_plantel ) ){
          this.tipociclo = `grupo NOT LIKE '%FE%'`
        }else{ this.tipociclo = `grupo LIKE '%FE%'` }

        console.log( this.tipociclo )
        return 
      },

      getNuevoGrupoAlumno () {
        if(this.grupoAnterior){
          this.cargar = true
          const payload =  { tipociclo: this.tipociclo, id_ciclo: this.grupoAnterior.id_ciclo }
          return this.$http.post('cambios.nuevo.grupos.ciclo',payload).then(response=>{
            this.grupos = response.data
            console.log( this.grupos )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }else{

        }
      },

      getTitpoCambios () {
        this.cargar = true
        return this.$http.get('cambios.tipo.cambio').then(response=>{
          this.tipocambios = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      updateCambio () {
      	let { whatsapp, lms, zoom, estatus } = this.alumno
      	if( estatus != 2 ) {
      		this.error = 'No es posible actualizar los datos ya que la solicutd aún no ah sido respondida'
          this.dialogError = true
          this.cargar = false
          return 
      	}

      	if(whatsapp && zoom) {
	      	whatsapp = whatsapp ? 1 : 0
	      	zoom     = zoom     ? 1 : 0

	      	this.cargar = true

	        let payload = {
	          idsolicitud_cambio: this.alumno.idsolicitud_cambio,
	          estatus:            4,
						lms,
	          whatsapp,
						zoom,
						fecha_aceptacion:   (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
	          fecha_cambio:       this.alumno.fecha_cambio,
	          realizo_cambio:     this.getdatosUsuario.iderp,
	        }
	        
	        this.$http.put('cambios.update/' + payload.idsolicitud_cambio,payload).then(response=>{
	          this.cargar        = false
	          this.successMensaje = 'Datos grabados correctamente'
	          this.dialogSuccess  = true
	          this.$emit('getAlumnosxEscuela')
	        }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
	        this.dialogUpdateCambio = false
      	}else{
      		this.error = 'Para aceptar el cambio, necesitas aceptar primero que ya el alumno está en su nuevo grupo y que ya se le envió su código de zoom'
          this.dialogError = true
          this.cargar = false
      	}
      },

      rechazar () {
      	let { whatsapp, lms, zoom, estatus } = this.alumno
      	if( estatus != 2 ) {
      		this.error = 'No es posible actualizar los datos ya que la solicutd aún no ah sido respondida'
          this.dialogError = true
          this.cargar = false
          return 
      	}
      	this.cargar = true

        let payload = {
          idsolicitud_cambio: this.alumno.idsolicitud_cambio,
          estatus:   3,
          whatsapp:  0,
					lms:       0,
					zoom:      0
        }
	        
        this.$http.put('cambios.update/' + payload.idsolicitud_cambio,payload).then(response=>{
          this.cargar        = false
          this.successMensaje = 'Datos grabados correctamente'
          this.dialogSuccess  = true
          this.getAlumnosxEscuela()
        }).catch(error=>{
          this.error = error.body.message
          this.dialogError = true
          this.cargar = false
        })
        this.dialogUpdateCambio = false
      },

      enviarMensaje () {
        if(this.mensajeRechazo != '' && this.mensajeRechazo != null){
        	this.cargar = true
          let payload = {
            idsolicitud_cambio: this.alumno.idsolicitud_cambio,
            mensaje:            '',
            respuesta:          this.mensajeRechazo,
            envia:              0,
          }

          this.$http.post('cambios.mensaje',payload).then(response=>{
          	this.dialogRechazar = false
          	this.dialogMensajes = false
          	this.mensajeRechazo = ''
          	this.mensajes       = []
          	this.rechazar()
          	this.cargar = false
          }).catch(error=>{
            this.error = error.body.message
            this.dialogError = true
            this.cargar = false
          	this.dialogRechazar = false
          })
        }else{
          this.error = 'Ingresa un mensaje'
          this.dialogError = true
          this.dialogRechazar = false
        }
      },

      cargarMensajes () {
      	this.$http.get('cambios.mensaje/' + this.alumno.idsolicitud_cambio).then(response=>{
        	this.mensajes = response.data
        	this.dialogMensajes = true
        	this.dialogUpdateCambio = false
        	this.cargar = false
        }).catch(error=>{
          this.error = error.body.message
          this.dialogError = true
          this.cargar = false
        	this.dialogRechazar = false
        })
      },

      getAhora () {
      	let fecha = new Date().toLocaleString();
    		fecha = moment(fecha).format().replace('T',' ').substr(0,19)
    		return fecha
      },

      async addDialogCambio (alumno) {
        await this.getGrupoUsuario(alumno.id_alumno)
        this.alumno = alumno
        this.step += 1
        // this.dialogAddCambio = true
      },

      getGrupoUsuario ( idalumno ){
        this.cargar = true
        this.grupoAlumnos = []
        return this.$http.get('cambios.grupo.usuario/' + idalumno).then(response=>{
          this.grupoAlumnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      addCambioFinanzas() {

        // Validar que esten llenos los campos
        if(this.alumno && this.dataGrupoActual && this.dataNuevoGrupo){

          // Activar el disabled del boton para que no le den doble click
          this.loading  = true
          this.disabled = true
          this.overlay  = true

          // Desesestructuración de alumno 
          const { id_alumno, id_plantel, monto_pagado_total, idtipo_cambio, solicito_cambio, id_grupo_actual, id_grupo_nuevo, idsolicitud_cambio } = this.alumno
          const { pagado, id_ciclo, descuento, precio_grupo, precio_grupo_con_descuento_x_alumno } = this.dataGrupoActual

          // Generar el payload a mandar
          let payload = {
            id_alumno,
            idtipo_cambio,
            solicito_cambio,
            id_plantel,
            id_grupo:               id_grupo_actual,
            id_grupo_nuevo:         id_grupo_nuevo,
            estatus:                2,
            diferencia:             pagado,
            id_ciclo_nuevo_grupo:   id_ciclo,
            pago_grupo_actual:      pagado,
            descuento_grupo_actual: descuento,
            precio_grupo:           precio_grupo,
            precio_descuento:       precio_grupo_con_descuento_x_alumno,
            iderp:                  this.getdatosUsuario.iderp, 

            acepto:                 this.getdatosUsuario.iderp,
            idsolicitud_cambio,

            adeudo                    : ( this.total_a_pagar - this.dataGrupoActual.pagado ) < 0 ? 0 : parseFloat(this.total_a_pagar - this.dataGrupoActual.pagado),
            precio_grupo_con_descuento:  this.total_a_pagar,
            id_curso                  : this.precioSeleccionado,

            // Datos para el grupo con saldo a favor
            id_curso_2    : this.precioSeleccionado2,
            saldo_favor   : this.dataGrupoActual.pagado - this.total_a_pagar,
            id_grupo_saldo: this.alumno.id_grupo_favor,
            total_a_pagar : this.total_a_pagar
          }

          // Enviar los datos 
          this.$http.post('cambios.grupo',payload).then(response=>{
            this.validarSuccess('Cambio realizado con exito')
            // this.updatePrecioAlumno()
            this.$emit('getAlumnosxEscuela')
          }).catch( error =>{
            this.loading  = false
            this.disabled = false
            this.overlay  = false
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }else{
          this.estatusError('Favor de ingresar el grupo y el tipo de cambio')
        }
      },

      verImagen( imagen, es_solucion ){
        this.imagen       = imagen
        this.dialogImagen = true
      },

      rechazado( ){
        this.paramsRechazo.estatus = false
        this.$emit('getAlumnosxEscuela')

      }
    },

    computed:{
      ...mapGetters ('login',['getdatosUsuario']),

      costoCurso( ){
        
        if( this.precioSeleccionado ){

          let existeCosto = this.precios.find( el => el.id_curso == this.precioSeleccionado )

          return existeCosto ? existeCosto.precio_lista : 0

        }else{
          return 0
        }

      },
    }
  }
</script>

<style>

  .v-menu__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-menu__content::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-button:increment,.v-menu__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-menu__content::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>