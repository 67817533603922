<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Modalidades</span>
	  		    <v-spacer></v-spacer>

            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( modalidades  , 'MODALIDADES_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

	  		    <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
	  		  </v-card-title>
          
	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="6">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="modalidades"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            dense
							  >

							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.estatus="{ item }">
							      <v-chip 
							      	v-if="item.estatus == 1"
							      	small
							      	color="green"
							      	dark
							      >Activo</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >Inactivo</v-chip>
							    </template>

							    <template v-slot:item.escuela="{ item }">
							      <v-chip small color="colorinbi" dark v-if="item.escuela == 1">INBI</v-chip>
							      <v-chip small color="colorfast" dark v-if="item.escuela == 2">FAST</v-chip>
							    </template>

							    <template v-slot:item.actions="{ item }">
							      <v-icon
							        small
							        class="mr-2"
							        color="primary"
							        @click="editItem(item)"
							      >
							        mdi-pencil
							      </v-icon>
							      <v-icon
							        small
							        @click="deleteItem(item)"
							        color="error"
							      >
							        mdi-delete
							      </v-icon>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>
							  </v-data-table>
				  	</v-col>
				  </v-row>
				</v-card-text>
				<v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.modalidad"
                  label="modalidad"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                	:items="[{id:1, escuela:'INBI'},{id:2, escuela:'FAST'}]"
                  v-model="editedItem.escuela"
                  label="Escuela"
                  outlined
                  hide-details
                  dense
                  item-text="escuela"
                  item-value="id"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-select
                	:items="cursosEscuela"
                  v-model="editedItem.idcursos_escuela"
                  label="Cursos"
                  outlined
                  hide-details
                  dense
                  item-text="curso"
                  item-value="idcursos_escuela"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6">
              	<v-switch 
              		label="Activo"
                  hide-details
              		v-model="editedItem.estatus">
              	</v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="save"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />

	</v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex'
// Componentes
import Alerta                from '@/components/alertas/Alerta.vue'
import carga                 from '@/components/alertas/carga.vue';

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";


export default {

  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
  	// Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'ID'        , align: 'start', value: 'idmodalidad_cursos' },
      { text: 'modalidad' , value: 'modalidad'   },
      { text: 'Escuela'   , value: 'escuela'   },
      { text: 'Curso'     , value: 'curso' },
      { text: 'Estatus'   , value: 'estatus' },
      { text: 'Actions'   , value: 'actions', sortable: false },
    ],
    modalidades: [],
    editedIndex: -1,
    editedItem: {
      idmodalidad_cursos: '',
      modalidad: '',
      idcursos_escuela:'',
      estatus: '',
      escuela:0,
    },
    defaultItem: {
      idmodalidad_cursos: '',
      modalidad: '',
      idcursos_escuela:'',
      estatus: '',
      escuela:0,
    },

    dialogSuccess:false,
    overlay: false,
    dialogError: 	false,
		error: 				'',
		successMensaje:'',
		errorBack:false,
    textoCopiado:'COPIAR',

    cursos:[],
    curso: null,
    cursosEscuela:[],
    // tabla
		search:'',
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Agregar modalidad' : 'Editar modalidad'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },

    "editedItem.escuela"( val){
    	this.cursosEscuela = this.cursos.filter(el=> { return el.escuela == val})
    }
  },

  created () {
    this.initialize()
    this.getCursos()
  },

  methods: {
    initialize () {
      this.modalidades = []
      this.overlay = true
      this.$http.get('escuela.modalidades.list').then(response=>{
      	this.modalidades = response.data
      	this.overlay = false
      }).catch(error=>{
      	// INdicamos que hay un error en el back
      	if(error.body.message){
      		this.error = error.body.message
      	}else{
      		this.error = error.body
      	}
      	this.errorBack = true
      	this.overlay = false
      	this.dialogError = true
      })
    },

    getCursos(){
    	this.cursos = []
      this.overlay = true
      this.$http.get('escuela.cursos.activos').then(response=>{
      	this.cursos = response.data
      	this.overlay = false
      }).catch(error=>{
      	// INdicamos que hay un error en el back
      	if(error.body.message){
      		this.error = error.body.message
      	}else{
      		this.error = error.body
      	}
      	this.errorBack = true
      	this.overlay = false
      	this.dialogError = true
      })
    },

    editItem (item) {
      this.editedIndex = this.modalidades.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.modalidades.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      // Lo mandapos por el EP
      this.editedItem.deleted = 1
      this.$http.put('escuela.modalidades.update/' + this.editedItem.idmodalidad_cursos, this.editedItem).then(response=>{
      	this.dialogSuccess = true
      	this.overlay = false
      	this.closeDelete()
      	this.initialize()
      }).catch((error) => {
          this.validarError(error);
      }).finally(() => {
        this.cargar = false;
      });
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
    	if(this.editedItem.modalidad == ''){
    		this.error     		= 'Favor de llenar todos los campos'
      	this.errorBack 		= false
      	this.overlay   		= false
        this.dialogError 	= true
        return
    	}
      if (this.editedIndex > -1) {
        // Activamos el loader
        this.overlay = true
        // Lo mandapos por el EP
        this.$http.put('escuela.modalidades.update/' + this.editedItem.idmodalidad_cursos, this.editedItem).then(response=>{
        	this.dialogSuccess = true
        	this.initialize()
        	this.overlay = false
        }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });

      } else {
      	// Activamos el loader
        this.overlay = true
        // Lo mandapos por el EP
        this.$http.post('escuela.modalidades.add', this.editedItem).then(response=>{
        	this.dialogSuccess = true
        	this.initialize()
        	this.overlay = false
        }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });
      }
      this.close()
    },
  },
}
</script>


