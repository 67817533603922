var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c('v-tooltip',{attrs:{"bottom":"","color":"#558B2F"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"#558B2F","small":"","dark":"","hide-details":""}},'v-chip',attrs,false),on),[_vm._v("E. | "+_vm._s(_vm.prospecto.cantEventos)+" |")])]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Eventos generales: "+_vm._s(_vm.prospecto.cantEventos))])]),_c('v-tooltip',{attrs:{"bottom":"","color":"#00C853"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"#00C853","small":"","dark":"","hide-details":""}},'v-chip',attrs,false),on),[_vm._v("E.H. | "+_vm._s(_vm.prospecto.cantEventosHoy)+" |")])]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Eventos de hoy: "+_vm._s(_vm.prospecto.cantEventosHoy))])]),_c('v-tooltip',{attrs:{"bottom":"","color":"green"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"green","small":"","dark":"","hide-details":""}},'v-chip',attrs,false),on),[_vm._v(_vm._s(_vm.prospecto.tiempo_transcurrido)+" días")])]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Ingresó hace: "+_vm._s(_vm.prospecto.tiempo_transcurrido)+" días")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }