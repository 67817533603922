const onlineInbi = [
	{ 
		titulo: '¿Por qué estudiar inglés?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Hoy en día el saber inglés no es un plus, sino una obligación para todos aquellos que queramos tener mejores oportunidades de vida.',
			},
		]
	},

	{ 
		titulo: '¿cómo le vamos a enseñar el inglés? ',
		check: false,
		subtemas:[
			{ 
				titulo: 'El método utilizado es el de “inmersión”, el cual consiste en aprender o a través de situaciones cotidianas de manera natural, tal como aprendimos español',
			},
		]
	},

	{ 
		titulo: '¿Cómo son las clases?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Son dinámicos y 100% en inglés, al final de cada clase podemos preguntas las dudas en español de ser necesario. La clave del aprendizaje es la práctica constante dentro de la clase.',
			},
		]
	},

	{ 
		titulo: '¿Cómo saben qué nivel de inglés tengo?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Primero con un examen de ubicación el cual podemos realizar en línea, después con ese resultado, contamos con un curso de inducción el cual nos sirve para normalizar y trabajar su nivel de inglés',
			},
		]
	},

	{ 
		titulo: '¿Qué horarios tienen?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Intensivo entre semana',
				subsubtemas:[
					{ titulo: '1 hora 20 minutos Lunes, Miércoles y Viernes (Diferentes horarios entre las 9 am y 9 pm)'},
				]
			},
		]
	},

	{ 
		titulo: '¿Cuánto dura el curso? ',
		check: false,
		subtemas:[
			{ titulo: 'El curso consta de 14 niveles' },

			{ 
				titulo: 'Intensivo',
				subsubtemas:[
					{ titulo: 'Cada nivel consta de 4 semanas efectivas de clases. Esto quiere decir que pagas por clases y no por vaciones ni festivos'},
				]
			},
		]
	},

	{ 
		titulo: '¿Cómo me aseguran que aprenderé inglés?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Para asegurarnos que el aprendizaje del alumno fue correcto lo evaluamos por nivel, al finalizar los 14 niveles el alumno se someterá a una certificación en base al Marco Común Europeo de Referencia para las lenguas (MCER)',
			},
		]
	},

	{ 
		titulo: '¿Cuánto cuesta el curso?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Es importante mencionar que no manejamos costo de inscripción ni de material didáctico',
				subsubtemas:[
					{ 
						titulo: 'Se necesita una inversión de 1,930 por nivel, como descuento para su primer nivel la escuela le brindará un 20% de descuento, el costo quedaría en 1,540 por nivel, sin costo de inscripción y material didáctico'
					},
				]
			},

			{ 
				titulo: 'Intesivo entre semana',
				subsubtemas:[
					{ 
						titulo: 'Se necesita una inversión de $1,325 por nivel, como descuento para su primer nivel la escuela le brindará un 20% de descuento, el costo quedaría en $1,060 por nivel'
					},
				]
			},

			{ 
				titulo: 'Para iniciar el curso, se debe de tomar el curso de inducción de 2 días (1:30 minutos por día) con un costo de $199, los cuales serán tomados en cuenta en su primer nivel',
			},

			{ 
				titulo: 'Contamos con un sistema de descuentos de pronto pago el cual permitirá mantener el mismo costo de su primer nivel',
			},
		]
	},

	{ 
		titulo: '¿Qué incluye?',
		check: false,
		subtemas:[
			{ titulo:'4 semanas efectivas de clases en vivo' },
			{ titulo:'Material didáctico que se ocupe para el correcto aprendizaje.' },
			{ titulo:'Acceso a nuestra plataforma virtual' },
			{ titulo:'Acceso a grupo WhatsApp con contenido exclusivo' },
			{ titulo:'Curso de preparación para examen EXCI (revisar disponibilidad de horarios) ' },
			{ titulo:'Certificado' },
		]
	},

	{ 
		titulo: 'Cierre',
		check: false,
		subtemas:[
			{ titulo: 'Resumen de información'},
			{ titulo: 'Pregunta de cierre'},
		]
	},
]

export { onlineInbi }