<template>
  <v-container class="mt-5 ml-2 pr-6" fluid>
    <v-row>
    	<v-col cols="12">
    		<v-card>
		      <v-card-title
		        flat
		      >
		        R. todos los teachers
		        <v-divider
		          class="mx-4"
		          inset
		          vertical
		        ></v-divider>
		        <v-spacer></v-spacer>
		        <v-btn 
	            class="mr-2" 
	            small dark color="green"
	            @click="exportar()">
	            <v-icon>mdi-microsoft-excel</v-icon>
	          </v-btn>
		        <v-btn color="primary" small rounded @click="getGrupos()">Consultar</v-btn>
		      </v-card-title>
          <v-card-text>
          	<v-row>
              <v-col cols="12" md="4" lg="3">
                <v-autocomplete
                  outlined
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona un ciclo"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
            
              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  v-model="dia"
                  dense
                  label="Fecha de inicio"
                  outlined
                  hide-details
                  clearable
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  v-model="dia2"
                  dense
                  label="Fecha final"
                  outlined
                  hide-details
                  clearable
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row justify="end">
            	<v-col cols="12" md="6" lg="4">
            		<v-text-field
            			label="buscar"
            			v-model="search"
            			dense
            			hide-details
            			outlined
            		></v-text-field>
            	</v-col>
	            <v-col cols="12" >
                <v-data-table
                  class="elevation-0"
                  :search="search"
                  :headers="headers"
                  :items="clasesEvaluadas"
                  dense
                ></v-data-table>
				      </v-col>
            </v-row>
          </v-card-text>
    		</v-card>
    	</v-col>
    </v-row>

    <!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="300"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card
        color="green"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Datos grabados
        </v-card-title>
        <div class="pl-6 pb-6">
          <br/>
          NOTA: {{successMensaje}}
        </div>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogSuccess = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
	  
    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card
        color="error"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Error al recuperar los datos
        </v-card-title>
        <v-card-text>
          <br/>
          NOTA: {{error}}
        </v-card-text>
        <v-btn 
        	color="transparent elevation-0"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogError = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import axios from 'axios';
	import XLSX from 'xlsx'
	import VueApexCharts from 'vue-apexcharts'
  export default {
  	components: {
      VueApexCharts,
    },

    data () {
      return {
      	search:'',
      	cicloInf: 		null,
      	ciclos:  			[],
      	dialogError: 	false,
				dialogSuccess:false,
				error: 				'',
				successMensaje:'',
				overlay: 			false,

				clasesEvaluadas:[],

        search: '',
        headers: [
          { text: 'Nombre'       , value: 'nombre_completo' },
          { text: 'Total clases' , value: 'total_clases' },
          { text: 'Buenas'       , value: 'clases_buenas' },
          { text: 'Malas'        , value: 'clases_malas' },
          { text: 'Sin evaluar'  , value: 'sin_evaluar' },
          { text: '% Buenas'     , value: 'porcentaje' },
        ],

        overlay:false,
	      dialogError: false,
	      error:'',

	      dia:null,
	      dia2:null,
        horas:[],
        clases:[],
      }
    },

    async created () {
    	await this.getCiclos()
    },

    methods: {
    	getCiclos () {
        this.overlay = true
        this.ciclos = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError(error.body.message)
        })
      },

    	getGrupos () {
    		if(!this.cicloInf){
    			this.openDialogError('Por favor, ingresa un ciclo o una fecha de inicio o un rango de fechas o un teacher')
    		}else{
	    		this.verGrafica = false
	    		if(this.cicloInf && this.dia && !this.dia2){
	    			this.getClases(1)
	    		}

	    		if(this.cicloInf && this.dia && this.dia2){
	    			if(this.dia > this.dia2){
	    				this.openDialogError('La fecha de inicio no puede ser mayor a la fecha final')
	    			}else{
	    				this.getClases(2)
	    			}
	    		}

	    		if(this.cicloInf && !this.dia && this.dia2){
	    			this.openDialogError('Por favor, ingresa una fecha de inicio')
	    		}

	    		if(this.cicloInf && !this.dia && !this.dia2){
	    			this.getClases(3)
	    		}
    		}
    	},


    	getClases (item) {

        let payload = {}
        // Por ciclo y dia
        if(item == 1){
      		payload = {
      			cicloFast:   this.cicloInf.id_ciclo_relacionado,
      			cicloInbi:   this.cicloInf.id_ciclo,
      			fecha:       this.dia,
      			fecha2:      this.dia,
      			tipo:        1
      		}
        }

        // Por ciclo y rango de fechas
        if(item == 2){
          payload = {
            cicloFast:   this.cicloInf.id_ciclo_relacionado,
            cicloInbi:   this.cicloInf.id_ciclo,
            fecha:       this.dia,
            fecha2:      this.dia2,
            tipo:        2
          }
        }

        // Solo ciclo
        if(item == 3){
          payload = {
            cicloFast:   this.cicloInf.id_ciclo_relacionado,
            cicloInbi:   this.cicloInf.id_ciclo,
            fecha:       "0000-00-00",
            fecha2:      "9999-12-31",
            tipo:        3
          }
        }
    		this.overlay = true
    		this.clases  = []
    		this.$http.post('kpi.clases.teachers', payload).then(response=>{
          this.clasesEvaluadas = response.data
        	this.overlay = false
        }).catch(error=>{
        	this.error = error.body.message
        	this.dialogError = true
        	this.overlay = false
        })
    	},

    	openDialogError (mensaje) {
      	this.error       = mensaje
      	this.dialogError = true
      },

      openDialogSuccess ( mensaje ){
      	this.successMensaje = mensaje
      	this.dialogSuccess  = true
      },

      update ( item ) {
      	this.$http.post('kpi.clases.hora.dia.update', item).then(response=>{
        	this.overlay = false
      		this.getGrupos()
        }).catch(error=>{
        	this.error = error.body.message
        	this.dialogError = true
        	this.overlay = false
        })
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportar(){
        let data = XLSX.utils.json_to_sheet(this.clasesEvaluadas)
        const workbook = XLSX.utils.book_new()
        const filename = 'kpi_teachers'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });

      },
    }
  }
</script>