<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="transparent elevation-0">
          <v-card-title primary-title class="text-subtitle-1">
            Estado de Resultados

            <v-spacer></v-spacer>

            <!-- DIFERENCIAS  -->
            <v-btn
              color="secondary"
              class="mr-2"
              @click="dialogCifras = true"
              small
              dark
              tile
              ><v-icon left>mdi-currency-usd</v-icon>Concetrado</v-btn
            >

            <!-- ACTUALIZAR LA INFO -->
            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="consultar()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
            
          </v-card-title>

          <!-- CONTENIDO -->
          <v-card-text class="">

            <!-- FILTROS  -->
            <v-row>

              <v-radio-group
                class="ml-2"
                v-model="escuela"
                row
                hide-details
              >
                <v-radio
                  label="INBI"
                  :value="1"
                ></v-radio>
                <v-radio
                  label="FAST"
                  :value="2"
                ></v-radio>
              </v-radio-group>

              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="ciclo"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  v-model="plantel"
                  :items="filterPlanteles"
                  label="Selecciona un plantel"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="plantel"
                  item-value="plantel"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row align="center">

              <!-- DESGLOSE DE LOS PAGOS; SOLO PARA VENDEDORAS -->
              <v-col cols="12"class="pa-0">
                <v-card
							    class="shadowCard"
							    max-width="600"
							  >
							    <v-list-item two-line>
							      <v-list-item-content>
							        <v-list-item-title class="text-h5 align-center" align="center">
							        	<v-spacer></v-spacer>
							          {{ plantel ? plantel.plantel : 'SIN PLANTEL' }}
							        	<v-spacer></v-spacer>
							        </v-list-item-title>
							        <v-list-item-subtitle align="center"> {{ ciclo ? ciclo.ciclo : 'SIN CICLO' }} </v-list-item-subtitle>
							      </v-list-item-content>
							    </v-list-item>

							    <v-card-text class="white--text primary pa-2 align-center" align="center">
							    	DATOS GENERALES
							    </v-card-text>
							    
							    <v-list class="transparent">
							      <v-list-item>
							        <v-list-item-title>Ingresos</v-list-item-title>

							        <v-list-item-subtitle class="text-right">
							          <v-chip color color="primary" dark small @click="dialogIngresos = true">{{ ingresos }}</v-chip>
							          <v-tooltip bottom color="blue">
										      <template v-slot:activator="{ on, attrs }">
										        <v-icon
										          color="blue"
										          dark
										          small
										          v-bind="attrs"
										          v-on="on"
										        >
										          mdi-information
										        </v-icon>
										      </template>
										      <span>Ingresos</span>
										    </v-tooltip>
							        </v-list-item-subtitle>
							      </v-list-item>

							      <v-list-item>
							        <v-list-item-title>Egresos</v-list-item-title>

							        <v-list-item-subtitle class="text-right">
                        <v-chip color :color="color" dark small @click="dialogEgresos = true">{{ egresos }}</v-chip>
							          <v-tooltip bottom :color="color">
										      <template v-slot:activator="{ on, attrs }">
										        <v-icon
										          color="blue"
										          dark
										          small
										          v-bind="attrs"
										          v-on="on"
										        >
										          mdi-information
										        </v-icon>
										      </template>
										      <span>Egresos</span>
										    </v-tooltip>
							        </v-list-item-subtitle>
							      </v-list-item>

							      <v-list-item>
							        <v-list-item-title>Margen</v-list-item-title>

							        <v-list-item-subtitle class="text-right">
							          {{ margen }}
							          <v-tooltip bottom color="blue">
										      <template v-slot:activator="{ on, attrs }">
										        <v-icon
										          color="blue"
										          dark
										          small
										          v-bind="attrs"
										          v-on="on"
										        >
										          mdi-information
										        </v-icon>
										      </template>
										      <span> {{ ingresos }} - {{ egresos }}  </span>
										    </v-tooltip>
							        </v-list-item-subtitle>
							      </v-list-item>


							      <v-list-item>
							        <v-list-item-title>Margen de Ciclo Ant.</v-list-item-title>

							        <v-list-item-subtitle class="text-right">
							          {{ ciclo_ant }}
							          <v-tooltip bottom color="blue">
										      <template v-slot:activator="{ on, attrs }">
										        <v-icon
										          color="blue"
										          dark
										          small
										          v-bind="attrs"
										          v-on="on"
										        >
										          mdi-information
										        </v-icon>
										      </template>
										      <span>{{ ciclo_ant_i }} - {{ ciclo_ant_e }}</span>
										    </v-tooltip>
							        </v-list-item-subtitle>
							      </v-list-item>

							      <v-list-item>
							        <v-list-item-title>Margen del mismo ciclo del año ant.</v-list-item-title>

							        <v-list-item-subtitle class="text-right">
							          {{ anio_ant }}
							          <v-tooltip bottom color="blue">
										      <template v-slot:activator="{ on, attrs }">
										        <v-icon
										          color="blue"
										          dark
										          small
										          v-bind="attrs"
										          v-on="on"
										        >
										          mdi-information
										        </v-icon>
										      </template>
										      <span>{{ anio_ant_i }} - {{ anio_ant_e }}</span>
										    </v-tooltip>
							        </v-list-item-subtitle>
							      </v-list-item>
							    </v-list>


							    <v-card-text class="white--text primary pa-2 align-center" align="center">
							    	OTROS DATOS
							    </v-card-text>

							    <v-list class="transparent">
							      <v-list-item>
							        <v-list-item-title>Rentabilidad</v-list-item-title>

							        <v-list-item-subtitle class="text-right">
							          {{ rentabilidad }}
							          <v-tooltip bottom color="blue">
										      <template v-slot:activator="{ on, attrs }">
										        <v-icon
										          color="blue"
										          dark
										          small
										          v-bind="attrs"
										          v-on="on"
										        >
										          mdi-information
										        </v-icon>
										      </template>
										      <span>(( {{ ingresos }} - {{ egresos }} ) / {{ ingresos }} ) * 100 </span>
										    </v-tooltip>
							        </v-list-item-subtitle>
							      </v-list-item>

							      <v-list-item>
							        <v-list-item-title>ROI</v-list-item-title>

							        <v-list-item-subtitle class="text-right">
							          {{ roi }}
							          <v-tooltip bottom color="blue">
										      <template v-slot:activator="{ on, attrs }">
										        <v-icon
										          color="blue"
										          dark
										          small
										          v-bind="attrs"
										          v-on="on"
										        >
										          mdi-information
										        </v-icon>
										      </template>
										      <span>( ({{ margen }} / {{ egresos }} ) * 100 </span>
										    </v-tooltip>
							        </v-list-item-subtitle>
							      </v-list-item>
							    </v-list>

							    <v-divider></v-divider>

							    <v-card-actions>
							      <v-btn text @click="dialogReporteFull = true">
							        Full Report
							      </v-btn>
							    </v-card-actions>
							  </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- dialog para diferencias -->
    <v-dialog
      v-model="dialogCifras"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <v-card>
        <v-card-title primary-title>
          Reporte de resultados
          <v-spacer></v-spacer>
          <v-btn small dark tile color="green" @click="exportar(planteles)">
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-card-title>
        <!-- Datos principales del usuario -->
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="ciclo"
                :items="ciclos"
                label="Selecciona el ciclo"
                persistent-hint
                hide-details
                item-text="ciclo"
                item-value="id_ciclo"
              >
              </v-autocomplete>
            </v-col>
            <v-col>
            	<v-radio-group v-model="escuela" row class="ml-2">
	              <v-radio label="Fast" :value="2"></v-radio>
	              <v-radio label="Inbi" :value="1"></v-radio>
	            </v-radio-group>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headersCifras"
            :items="plantelesConcentrado"
            class="elevation-0 mt-4"
            dense
            mobile-breakpoint="200"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogCifras = false" small tile  color="black" dark>
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog para diferencias -->
    <v-dialog
      v-model="dialogIngresos"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title primary-title>
          Reporte de ingresos
          <v-spacer></v-spacer>
          <v-btn small dark tile color="green" @click="exportar(planteles)">
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-card-title>
        <!-- Datos principales del usuario -->
        <v-card-text>
          <v-data-table
            :headers="headersIngresos"
            :items="ingresosDesglose"
            class="elevation-0 mt-4"
            dense
            mobile-breakpoint="200"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogIngresos = false" small tile  color="black" dark>
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- dialog para diferencias -->
    <v-dialog
      v-model="dialogEgresos"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title primary-title>
          Reporte de egresos
          <v-spacer></v-spacer>
          <v-btn small dark tile color="green" @click="exportar(planteles)">
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-card-title>
        <!-- Datos principales del usuario -->
        <v-card-text>
          <v-data-table
            :headers="headersEgresos"
            :items="egresosDesglose"
            class="elevation-0 mt-4"
            dense
            mobile-breakpoint="200"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogEgresos = false" small tile  color="black" dark>
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo con reporte FULL de Datos -->
    <v-dialog
      v-model="dialogReporteFull"
      persistent
      transition="dialog-bottom-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title primary-title>
          Reporte de egresos
          <v-spacer></v-spacer>
          <v-btn small dark tile color="green" @click="exportar(planteles)">
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-card-title>
        <!-- Datos principales del usuario -->
        <v-card-text>
          <v-expansion-panels
            v-model="panel"
            multiple
          >
            <v-expansion-panel v-for="( reporte, i) in reporteFull" :key="i">
              <v-expansion-panel-header>{{ reporte.tipo_requisicion }} <v-spacer></v-spacer> {{  reporte.monto }} </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="headersEgresos"
                  :items="reporte.elementos"
                  class="elevation-0 mt-4"
                  dense
                  mobile-breakpoint="200"
                >
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogReporteFull = false" small tile  color="black" dark>
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />

  </v-container>
</template>
<script>
import axios from 'axios';

import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga  from "@/components/alertas/carga.vue";

import validarErrores           from "@/mixins/validarErrores";

import XLSX from "xlsx";

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default {
  components: {
    Alerta,
    carga,
  },

  mixins: [ validarErrores ],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    ciclos: [],
    ciclo: null,

    plantelesConcentrado: [],
    plantel: null,

    dialogCifras: false,
    dialogDiferencias:false,

    snackbar: false,

    headersCifras: [
      { text: "Plantel"      , value: "plantel"       },
      { text: "ingreso"      , value: "ingreso"       },
      { text: "egreso"       , value: "egreso"        },
      { text: "margen"       , value: "margen"        },
      { text: "Rentabilidad" , value: "rentabilidad"  },
    ],

    ingresos: 0,
    egresos: 0,
    margen:0,
    margen_moneda:0,
    ciclo_ant:0,
    anio_ant: 0,
    rentabilidad: 0,
    roi: 0,
    ciclo_ant_i:0,
    ciclo_ant_e:0,
    anio_ant_i:0,
    anio_ant_e:0,

    dialogIngresos: false,
    dialogEgresos: false,

    headersIngresos:[
      { text: "Matricula"   , value: "matricula"     },
      { text: "alumno"      , value: "alumno"        },
      { text: "monto"       , value: "monto_pagado"  },
      { text: "grupo"       , value: "grupo"         },
    ],
    ingresosDesglose:[],
    headersEgresos:[
      { text: "idrequi"   , value: "id_requisicion_compra"  },
      { text: "Concepto"  , value: "concepto"               },
      { text: "Monto"     , value: "montototal"             },
      { text: "Egresos"   , value: "monto_egresos"          },
    ],
    egresosDesglose:[],

    planteles:[],

    escuela: 1,
    color:'',
    dialogReporteFull: false,

    reporteFull: [],
    panel:[],
  }),

  watch: {
    ciclo ( value ) {
      if( value && this.plantel ) { this.consultar() }
    },

    plantel ( value ) {
      if( value && this.ciclo ) { this.consultar() }
    },

    escuela( value ){
      this.consultar( )
    }
  },

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),

    filterPlanteles( ){

    	let planteles = this.planteles.filter( el => { return el.idunidad_negocio == this.escuela })

    	return planteles
    }
  },

  async created() {
    await this.initialize();
    await this.getCiclosActivos( )

  },

  methods: {
    initialize() {
      this.cargar = true
      this.planteles = []
      return this.$http.get('planteles.oficiales').then(response=>{
      	this.planteles = response.data.filter( el => el.plantel != 'SIN PLANTEL' )

        this.planteles.push({
          idplantel: 13,
          plantel: 'COORPORATIVO FAST',
          escuela: 2,
          idunidad_negocio: 2
        })

        this.planteles.push({
          idplantel: 13,
          plantel: 'COORPORATIVO INBI',
          escuela: 1,
          idunidad_negocio: 1
        })

        this.planteles.push({
          idplantel: 13,
          oficinas: true,
          plantel: 'OFICINAS',
          escuela: 1,
          idunidad_negocio: 2
        })

        this.planteles.push({
          idplantel: 13,
          oficinas: true,
          plantel: 'OFICINAS',
          escuela: 1,
          idunidad_negocio: 1
        })
      	this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    getCiclosActivos( ){
      this.cargar    = true
      this.ciclos    = []
      return this.$http.get('reporte.anios.ciclos' ).then(response=>{
        this.ciclos  = response.data.ciclosAll

        this.ciclo = this.ciclos.find( el => el.actual )

        this.plantel = this.filterPlanteles.find( el => el.idplantel == 13 && el.idunidad_negocio == this.escuela )

      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    consultar() {
      this.cargar = true 

      var payload = {
        id_ciclo  : this.plantel.oficinas ? [this.ciclo.id_ciclo,this.ciclo.id_ciclo_relacionado] : this.escuela == 2 ?  this.ciclo.id_ciclo_relacionado :  this.ciclo.id_ciclo ,
        escuela   : this.escuela,
        id_plantel: this.plantel.idplantel,
        planteles : this.filterPlanteles,
        id_ciclo_concentrado: this.escuela == 1 ?  this.ciclo.id_ciclo :  this.ciclo.id_ciclo_relacionado ,
        ciclos    : this.plantel.oficinas ? [this.ciclo.id_ciclo,this.ciclo.id_ciclo_relacionado] : null,
        oficinas  : this.plantel.oficinas ? true : false
      };

      this.$http.post("estado.resultados", payload).then((response) => {
        this.ingresos     = response.data.ingresos
        this.egresos      = this.plantel.idplantel == 13 ? response.data.egresoGeneral : response.data.egresos
        this.margen       = response.data.margen
        this.margen_moneda= response.data.margen_moneda
        this.ciclo_ant    = response.data.ciclo_ant
        this.ciclo_ant_i  = response.data.ciclo_ant_i
        this.ciclo_ant_e  = response.data.ciclo_ant_e
        this.anio_ant     = response.data.anio_ant
        this.anio_ant_i   = response.data.anio_ant_i
        this.anio_ant_e   = response.data.anio_ant_e
        this.rentabilidad = response.data.rentabilidad
        this.color        = response.data.color         
        this.roi          = response.data.roi
        this.plantelesConcentrado   = response.data.planteles
        this.ingresosDesglose       = response.data.ingresosDesglose
        this.egresosDesglose        = this.plantel.idplantel == 13 ? response.data.egresosDesgloseCiclo : response.data.egresosDesglose
        this.reporteFull            = response.data.reporteFull


        if( this.plantel.idplantel == 13 && !this.plantel.oficinas ){
          let ultimoPlantel = response.data.planteles.find( el => el.plantel == 'Total')

          if( ultimoPlantel ){

            const { egreso, ingreso, margen, rentabilidad, roi, ciclo_ant, anio_ant } = ultimoPlantel

            this.rentabilidad = rentabilidad
            this.margen       = margen
            this.ingresos     = ingreso
            this.egresos      = response.data.egresoGeneral
            this.roi          = (( parseFloat(margen.replace('$','').replace(',','')) / parseFloat(this.egresos.replace('$','').replace(',','')) ) * 100 ).toFixed( 2 )
            this.ciclo_ant    = ciclo_ant
            this.anio_ant     = anio_ant

          }
        }
        
        this.cargar       = false
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => { this.cargar = false; });
    },


    getDiferenciaCiclos() {

      var payload = {
        id_ciclo: this.plantel.match('FAST') ?  this.ciclo.id_ciclo_relacionado :  this.ciclo.id_ciclo ,
      };

      this.cargar = true

      this.diferencias = []
      this.$http.post("inscripciones.comparaciones.ciclo", payload).then((response) => {
        this.cargar = false
        this.diferencias = response.data;
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => { this.cargar = false; });
    },

    abrirDialog(value) {
      console.log(value);
      this.alumnos = value;
      this.dialogCifras = true;
    },

    exportar(value) {
      const alumnos = values.map(({ plantel, ingreso, egreso, rentabilidad }) => ({ plantel, ingreso, egreso, rentabilidad }));

      this.exportExcel(alumnos);
    },

    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },

    exportExcel(value) {
      let data = XLSX.utils.json_to_sheet(value);
      const workbook = XLSX.utils.book_new();
      const filename = "cifras_" + this.plantel;
      XLSX.utils.book_append_sheet(workbook, data, filename);

      var wbout = XLSX.write(workbook, {
        bookType: "xls",
        bookSST: false,
        type: "binary",
      });

      const file = new File([new Blob([this.s2ab(wbout)])], filename + ".xls");

      let formData = new FormData();
      // //se crea el objeto y se le agrega como un apendice el archivo
      formData.append("file", file);
      // /*getDatosRiesgo the form data*/

      this.$http
        .post("pdfs", formData)
        .then((response) => {
          window.location =
            axios.defaults.baseURL + "pdfs/" + filename + ".xls";
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    verImagen(value) {            //Angel Rodriguez              
      this.imagen = value;
      this.dialgImagen = true;
    },
  },
};
</script>