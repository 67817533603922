<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <div class="text-subtitle-1"><strong>Precio Actual</strong></div>
        <v-divider class="mb-4"></v-divider>
        <p>Curso: {{ alumno.curso }} - Precio de lista: ${{ alumno.precio_nivel }}</p>

        <div class="text-subtitle-1"><strong>Grupo Actual</strong></div>
        <v-divider class="mb-4"></v-divider>
        <p>{{ editedItem.grupo }}</p>

        <div v-if="guardar">
          <div class="text-subtitle-1"><strong>Precio Nuevo</strong></div>
          <v-divider class="mb-4"></v-divider>
          <v-autocomplete
            v-model="precioSeleccionado"
            :items="precios"
            item-text="curso"
            item-value="id_curso"
            filled
            dense
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col cols="6" v-if="guardar">
        <v-row>
          <v-col>
            <!-- <v-checkbox class="mt-0" v-model="actualizarAdeudo" id="label"
            :label="`Actualizar ultimo adeudo**`" ></v-checkbox> -->
            <label for="label">
              <b>Pagado: </b> {{ editedItem.pagado }} <br>
              <b>A pagar:</b> {{ total_a_pagar }} <br>
              <b>Adeudo anterior: </b> {{ editedItem.adeudo }} <br>
              <b>Nuevo adeudo: </b> {{ total_a_pagar - editedItem.pagado }} <br>
            </label>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" align="right" v-if="guardar">
        <v-btn color="primary" @click="updatePrecioAlumno()" >Actualizar</v-btn>
      </v-col>
    </v-row>

    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

// Componentes
export default {
  components: {
    Alerta,
    carga,
  },

  props: ["editedItem"],
  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    cargar: false,
    guardar:false,
    actualizarAdeudo:false,
    actualizarAdeudoVista:false,
    id_alumno: 0,
    alumno: [],
    precios: [],
    precio: "",
    grupo:[],
    dialogActualizar: false,
    precioSeleccionado: null,
    idCursoAsociado: null,
    headers: [
      { text: "Curso", value: "curso" },
      { text: "Costo", value: "precio_nivel" },
    ],

    total_a_pagar : 0,
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),

  },

  watch: {
    editedItem() {
      this.initialize();
    },

    precioSeleccionado( val ){
      console.log( val )
      this.getPrecioPagar()      
    },
  },

  created() {
    console.log( this.editedItem )
    this.id_alumno = this.editedItem.id_alumno;
    this.ciclo = this.editedItem.id_ciclo;
    this.getPrecios();
    this.getPrecioAlumno();
    this.initialize();
    const idPuestoValido = [12, 11, 58, 50, 51, 39, 56, 43];
    this.guardar = idPuestoValido.includes(this.getdatosUsuario.idpuesto);
  },

  methods: {
    initialize() {
      this.$http
        .get("precios.ultimogrupo/" + this.id_alumno)
        .then((response) => {
          this.grupo = response.data;
          console.log( 'grupo', this.grupo )
          if( this.editedItem.adeudo > 0 ){
            this.actualizarAdeudoVista = true;
            // this.getPrecioPagar()
          }

          console.log( this.grupo )
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getPrecioPagar( ) {
      const payload = {
        id_alumno: this.id_alumno,
        id_grupo:  this.grupo.id_grupo,
        escuela:   this.editedItem.grupo.match('FAST') ? 2 : 1,
        id_curso:  this.precioSeleccionado,
        id_ciclo:  this.editedItem.id_ciclo,
        alumno_ni: this.editedItem.alumno_ni
      }

      return this.$http.post('pago.grupo.nuevo.descuento', payload).then(response=>{

        this.total_a_pagar = response.data[0].precio_lista

        this.cargar = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },
    
    getPrecioAlumno(){
      this.$http
        .get("precios.alumno/" + this.id_alumno)
        .then((response) => {
          this.alumno = response.data;
          // this.precioSeleccionado.id = this.precios.find((precio) => precio.id_curso == this.alumno.id_curso).iddescuentos_cursos;
          // this.precioSeleccionado.precio_lista = this.precios.find((precio) => precio.id_curso == this.alumno.id_curso).precio_lista;
          // //this.precioSeleccionado.precio = this.precios.find((precio) => precio.id_curso == this.alumno.id_curso).iddescuentos_cursos;
          // this.idCursoAsociado = this.alumno.id_curso;
          // this.precio = this.alumno.precio_lista;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getPrecios() {
      this.$http.get("all.precios").then((response) => {
        this.precios = response.data.filter( el => el.curso.split("-")[1].match("3") );
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => {
        this.cargar = false;
      });
    },

    async updatePrecioAlumno() {

      await this.updateAdeudo()

      if(this.guardar && this.precioSeleccionado != null && this.id_alumno != null){
      this.cargar = true;

      const payload = {
        id_curso: this.precioSeleccionado,
        id_alumno: this.id_alumno,
        id: this.id_alumno
      };

      this.$http
        .post("update.precio.alumno", payload)
        .then(response => {
          console.log(response.data.message);
          this.validarSuccess(response.data.message);
          this.initialize();
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
      } else {
          console.log("No tiene permiso para editar")
          this.validarError( "No tiene permiso para editar")
        }
    },

    calcularAdeudo() {
      // this.actualizarAdeudoVista = true;
      // this.precioSeleccionado.precio_lista = this.precios.find((precio) => precio.iddescuentos_cursos == this.precioSeleccionado.id).precio_lista;
    },

    obtenerIdCurso() {
      // if (this.precioSeleccionado.id) {
      //   // Si hay un precio seleccionado, busca el id_curso correspondiente en la lista de precios
      //   const precioEncontrado = this.precios.find(
      //     (precio) => precio.iddescuentos_cursos === this.precioSeleccionado.id
      //   );
      //   if (precioEncontrado) {
      //     // Si se encontró el precio, actualiza el idCursoAsociado con el id_curso correspondiente
      //     this.idCursoAsociado = precioEncontrado.id_curso;
      //   }
      // } else {
      //   // Si no hay un precio seleccionado, establece idCursoAsociado como null o un valor predeterminado
      //   this.idCursoAsociado = null; // O algún otro valor predeterminado
      // }
    },

    validarSuccess(mensaje) {
      this.cargar = false;
      this.parametros.mensaje = mensaje;
      this.parametros.dialogError = true;
      this.parametros.color = "success";
      this.respuestaAlerta = true;
    },

    validarError(value) {
      this.cargar = false;
      if (value.status == 400 || value.status == 500) {
        this.parametros.mensaje = value.body.message;
        this.parametros.dialogError = true;
        this.parametros.color = "#FF2C2C";
        this.respuestaAlerta = true;
      }
    },

    validarErrorDirecto(mensaje) {
      this.cargar = false;
      this.parametros.mensaje = mensaje;
      this.parametros.dialogError = true;
      this.parametros.color = "#FF2C2C";
      this.respuestaAlerta = true;
    },

    updateAdeudo( ){
      this.cargar = true

      const payload = {
        adeudo              : ( this.total_a_pagar - this.editedItem.pagado ) < 0 ? 0 : parseFloat(this.total_a_pagar - this.editedItem.pagado),
        id_alumno           : this.id_alumno,
        id_grupo            : this.editedItem.id_grupo,
        precio_grupo_con_descuento:  this.total_a_pagar
      }

      this.$http.post('reporte.montopago.2', payload ).then( response => {
        this.validarSuccess( response.data.message )
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

  },
};
</script>


