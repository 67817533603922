<template>
	<v-dialog max-width="400px" v-model="fechas.estatus">
    <v-card>
      <v-card-title primary-title>
        Agregar filtro de fechas
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="fechas.fechaini"
          dense
          outlined
          type="date"
          label="Fecha inicio"
          clearable
        ></v-text-field>
        <v-text-field
          v-model="fechas.fechafin"
          dense
          hide-details
          outlined
          type="date"
          label="Fecha final"
          clearable
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="pt-6">
        <v-spacer></v-spacer>
        <!-- Guardar la información  -->
        <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="fechas.estatus = false">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
	export default {
	  props: ['fechas'],
	}
</script>