<!--VUE-->

<template>
	<v-container class="mt-4" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  			<v-card-title primary-title>
	  			  <span class="text-subtitle-1">Activar Prospectos</span>
	  			  <v-spacer></v-spacer>
	  			  <v-chip color="info" small @click="initialize( )">Actualizar</v-chip>
	  			</v-card-title>

	  			<v-card-text class="pt-0">
	  			  <v-row justify="end" class="pt-0">
	  			  	<v-col cols="12" md="6" lg="4" class="pt-0">
	  			  		<v-text-field
	  			  		  label="Buscar"
	  			  		  id="id"
	  			  		  v-model="search"
	  			  		  outlined
	  			  		  dense
	  			  		  rounded
	  			  		  hide-details
	  			  		></v-text-field>
	  			  	</v-col>
	  			  </v-row>  
	  			</v-card-text>


	  			<v-card-text>
		  			<v-data-table
					    :headers="headers"
					    :items="filtroBecas"
					    sort-by="calories"
					    class="elevation-0"
					    :search="search"
					  >
      <!--BOTON VER -->
              <template v-slot:item.ver="{ item }">
                <v-chip color="success" dark small @click="prospecto = item, dialogActivarProspectos = true"> VER </v-chip>
              </template>
      <!------------------------------------------------------------------>
					  </v-data-table>
	  			</v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

    <v-dialog
      v-if="prospecto"
      v-model="dialogActivarProspectos"
      persistent
      max-width="300"
    >
  
      <v-card>

        <v-card-title class="text-h5">
          ¿Activar Prospecto?
        </v-card-title>
        
        <v-card-actions>
          <v-btn
            color="error"
            dark
            small
            rounded
            @click="dialogActivarProspectos = false, prospecto = null "
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            small
            rounded
            @click="actualizarProspecto()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>					
</template>

<!-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->


<script>
import axios from 'axios';

//----------IMPORTS--------------//
  import {mapGetters, mapActions} from 'vuex'
  // Componentes
  import Alerta             from '@/components/alertas/Alerta.vue'
  import carga              from '@/components/alertas/carga.vue';
  import funcionesExcel     from '@/mixins/funcionesExcel'


  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ funcionesExcel ],


//----------DATA--------------//
    data: () => ({
    	search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Folio'     , value: 'folio'                 },
        { text: 'Nombre'        , value: 'nombre_completo'   },
        { text: 'Telefono'         , value: 'telefono'       },
        { text: 'Nota Rechazo'      , value: 'nota_rechazo'  },
        { text: 'Ver'           , value: 'ver'      },
      ],

      registrosProspectos: [],
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      
      alumnos:[],
      prospecto: null,  
      filtro:3,
    }),


//----------COMPUTED--------------//
    computed: {
      ...mapGetters('login',['getdatosUsuario']),

      filtroBecas( ){
        if( this.filtro && this.filtro == 3){
          return this.registrosProspectos
        }

        if( this.filtro && this.filtro == 1){
          return this.registrosProspectos.filter( el=> { return !el.beca && el.id_grupo })
        }

        if( this.filtro && this.filtro == 2){
          return this.registrosProspectos.filter( el=> { return !el.inscrito && el.id_grupo })
        }
      }
    },

//-------
    async created () {
      
      await this.initialize()
      
    },


//----------METHODS--------------//
    methods: {
    
      initialize () {
        this.registrosProspectos = []
        return this.$http.get('activar.prospectos.obtener.prospectos').then( response=> {
        	this.registrosProspectos = response.data
        }).catch( error => {
        	console.log( error )
        }).finally( ( ) => {  })
      },

      

      validarErrores ( value ) {
        this.cargar = false
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

       actualizarProspecto(){
        console.log( this.prospecto )
        return this.$http.post('activar.prospectos.update.prospectos', this.prospecto).then( response=> {
          this.dialogActivarProspectos = false
          this.prospecto = null
          this.initialize()
        }).catch( error => {
          console.log( error )
        }).finally( ( ) => {  })

      },

    },
  }
</script>