<template>
	<v-container class="mt-4 ml-2" fluid>
		<v-row>
			<v-col cols="12">
				<v-card class="shadowCard">

					<!-- Titulo -->
					<v-card-title primary-title>
						<span class="text-subtitle-1"><v-icon left>mdi-fingerprint</v-icon>Mi Nómina</span>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							dark
							@click="initialize()"
							small
							tile
						>
							Actualizar
						</v-btn>
					</v-card-title>

					<!-- FILTROS -->
					<v-card-text>
						<v-row>
							<v-col cols="12" md="3" lg="2">
                <v-autocomplete
                  filled
                  dense
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo"
                  hide-details
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
					      	<!-- :items="[{ bloque: 'Semana 1', value: 1},{ bloque: 'Semana 2', value: 2},{ bloque: 'Semana Vacaciones', value: 3},{ bloque: 'Semana 3', value: 4},{ bloque: 'Semana 4', value: 5}]" -->

				      <v-col cols="12" md="3" lg="2">
				      	<v-select
					      	:items="[{ bloque: 'Semana 2', value: 2}]"
					      	v-model="selectedHeaders"
					      	label="# Semana"
					      	filled
                  dense
					      	hide-details
					      	item-text="bloque"
					      	item-value="value"
				      	></v-select>
				      </v-col>
						</v-row>
						<br />
	          <v-row>

	          	<v-col cols="12">
		          	<v-alert
						      outlined
						      type="info"
						      prominent
						      border="left"
						    >
						      Hola, {{ getdatosUsuario.nombre_completo }}! 
						      <br/>Para ver un desglose de cada concepto de tú nómina, da clic sobre el icono de: <v-chip color="blue" dark small >Detalle</v-chip>
						    </v-alert>
	          	</v-col>


	          	<v-col cols="12">
	          		<v-data-table
	          			v-if="liberada"
	            		:headers="headers"
	            		:items="filterEntradas"
	            		class="elevation-0"
	            		:search="search"
	            		:page.sync="page"
	            		@page-count="pageCount = $event"
	            		:mobile-breakpoint="100"
	            		dense
	            		item-key="idchecador"
	          		>
	            		<!-- Valores que ocupan botons, chips, iconos, etc -->
	            		<template v-slot:item.tipo_pago="{ item }">
	            			<v-chip v-if="item.tipo_pago == 2" small color="green" dark >Hora</v-chip>
	            			<v-chip v-if="item.tipo_pago == 1" small color="blue" dark >Dia</v-chip>
	            			<v-chip v-if="item.tipo_pago == null || item.tipo_pago == 0" small color="orange" dark >Sin Tipo Pago</v-chip>
	            		</template>

	            		<template v-slot:item.confirmacion_comisiones="{ item }">
	            			<v-chip v-if="(item.confirmacion_comisiones == 3)" small color="green" dark >Aceptadas</v-chip>
	            			<v-chip v-if="(item.confirmacion_comisiones == 4)" small color="blue" dark >Rechazadas</v-chip>
	            			<v-chip v-if="item.puesto == 'Encargada de sucursal' && (item.confirmacion_comisiones == null || item.tipo_pago == 0)" small color="orange" dark >Pendiente</v-chip>
	            		</template>

	            		<template v-slot:item.monto_laborado="{ item }">
	            			{{ item.monto_laborado }} <v-chip right class="ml-2" color="blue" dark small @click="formulaMontoLaborado(item), dialogMontoLaborado = true">Detalle</v-chip>
	            		</template>

	            		<template v-slot:item.monto_horas_extras="{ item }">
	            			{{ item.monto_horas_extras }} <v-chip right class="ml-2" color="blue" dark small @click="formulaMontoHorasExtras(item), dialogHorasExtras = true">Detalle</v-chip> 
	            		</template>

	            		<template v-slot:item.comisiones_semana="{ item }">
	            			{{ item.comisiones_semana }} <v-chip right class="ml-2" color="blue" dark small @click="dialogComisiones = true">Detalle</v-chip>
	            		</template>

	            		<template v-slot:item.monto_total_rebajes="{ item }">
	            			{{ item.monto_total_rebajes }} <v-chip right class="ml-2" color="blue" dark small @click="formulaMontoRebajes(item), dialogMontoRebajes = true">Detalle</v-chip>
	            		</template>

	            		<template v-slot:item.monto_total_bonos="{ item }">
	            			{{ item.monto_total_bonos }} <v-chip right class="ml-2" color="blue" dark small @click="formulaMontoBonos(item ), dialogMontoBonos = true">Detalle</v-chip>
	            		</template>

	            		<template v-slot:item.monto_total="{ item }">
	            			{{ item.monto_total }}
	            		</template>

	            		<template v-slot:item.asistencias="{ item }">
	            			<v-icon
		            			small
		            			class="mr-2"
		            			color="blue"
		            			@click="mostrarAsistencias(item)"
		            			>
		            			mdi-file-document
		            		</v-icon>
	            		</template>

		            	<template v-slot:no-data>
		            		<v-btn
			            		color="primary"
			            		@click="initialize"
			            		small
		            		>
		            		Actualizar
		            		</v-btn>
		            	</template>
		          	</v-data-table>
		          	<v-alert
		          		v-else
						      dense
						      outlined
						      type="error"
						    >
						      Hola, la nómina aún no ha sido liberada, saludos!
						    </v-alert>

						    <div v-if="liberada" class="black--text">
						    	<v-row>
						    		<v-col cols="12" md="8" lg="4">
						    			<v-card outlined>
						    				<v-card-text class="black--text">
					        				<b>Día de descanso: </b>{{ this.entradassalidas.length ? getDiaDescando( this.entradassalidas[0].dia_descanso ) : 'NO CONFIGURADO' }}
					        				<br/>
					        				<b>Horario fin de semana: </b>{{ this.entradassalidas.length ? this.entradassalidas[0].hora_entrada[0] : '' }} a {{ this.entradassalidas.length ? this.entradassalidas[0].hora_salida[0] : '' }}
					        				<br/>
					        				<b>Horario entre semana: </b>{{ this.entradassalidas.length ? this.entradassalidas[0].hora_entrada[4] : '' }} a {{ this.entradassalidas.length ? this.entradassalidas[0].hora_salida[4] : '' }}
						    				</v-card-text>
						    			</v-card>
						    		</v-col>
						    	</v-row>
						    </div>

						    <br/>
						    <br/>
						    <br/>

						    <v-alert
						    	v-if="estatus_nomina == 1"
						      dense
						      text
						      type="success"
						    >
						      Felicidades, tu nómina ha sido aceptada con éxito!
						    </v-alert>

						    <v-alert
						    	v-if="estatus_nomina == 2"
						      dense
						      text
						      type="error"
						    >
						      Lo sentimos, estamos reviando tu rechazo, en un momento podrás revisarla nuevamente!
						    </v-alert>

						    
						    <v-btn 
						    	v-if="liberada && !rechazado && ![1,2].includes( estatus_nomina )"
						    	color="success"
						    	dark
						    	tile
						    	class="mr-2"
						    	@click="dialogAceptar = true"
						    >
						  		Aceptar
						  		<v-icon small right>mdi-check</v-icon>
						  	</v-btn>


						  	<v-btn 
						  		v-if="liberada && !rechazado && ![1,2].includes( estatus_nomina )"
						    	color="red darken-3"
						    	dark
						    	tile
						    	@click="rechazado = true"
						    >
						  		Rechazar
						  		<v-icon small right>mdi-close</v-icon>
						  	</v-btn>
	        		</v-col>

	        		<v-col cols="12" md="6" lg="4" v-if="liberada && rechazado">
	        			<v-textarea
	        				v-model="motivo"
	        				:rows="3"
	        				auto-grow
	        				filled
	        				dense
	        				label="Agrega tu motivo por favor"
	        			>
	        			</v-textarea>
						  	
						  	<v-btn 
						    	color="black"
						    	dark
						    	tile
						    	class="mr-2"
						    	@click="rechazado = false"
						    >
						  		Cancelar
						  		<v-icon small right>mdi-close</v-icon>
						  	</v-btn>

	        			<v-btn 
						    	color="success"
						    	dark
						    	tile
						    	class="mr-2"
						    	@click="save(2)"
						    >
						  		Aceptar
						  		<v-icon small right>mdi-check</v-icon>
						  	</v-btn>


	        		</v-col>

	        		<v-col cols="12" md="6">
		        		<v-row>
						  		<v-col cols="12"
						  			v-for="(mensaje, i) in comentarios" :key="i"
						  		>
								  	<p 
								  		:class="`pa-2 ma-0 mb-4 ${ mensaje.id_usuario != getdatosUsuario.id_trabajador ? 'mensajeIzq' : 'mensajeDer' }`">
								    	{{ mensaje.comentarios }}
								    	<br/>
								    	{{ mensaje.nombre_completo }}
								  	</p>
						  		</v-col>
						  	</v-row>
	        		</v-col>

	      		</v-row>
		    	</v-card-text>
				</v-card> 
			</v-col>
			<v-dialog v-model="dialogAceptar" max-width="500px">
				<v-card>
					<v-card-text class="text-h6 black--text">
						¿Estás seguro que deseas guardar la nómina?
					</v-card-text>
					<v-card-actions class="pt-6">
						<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
						<v-btn
							color="black"
							small
							tile
							dark
							class="mr-2"
							@click="dialogAceptar=false"
						>
							No, cancelar
							<v-icon small right>mdi-cancel</v-icon>
						</v-btn>
						<v-spacer></v-spacer>
						<!-- Guardar la información  -->
						<v-btn
							color="green"
							small
							dark
							tile
							@click="dialogAceptar = false, save( 1 )"
						>
							Si
							<v-icon small right>mdi-content-save</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<!--Dialogs de Formulas de Montos -->
			<v-dialog
				v-model="dialogMontoLaborado"
				hide-overlay
				persistent
				max-width="500"
			>
				<v-card>
					<v-card-title class="text-h5">
						Desglose Monto Jornada Laboral
					</v-card-title>
					<v-card-text>
						<v-textarea
							v-model="formula_monto_laborado"
							rows="3"
							auto-grow
							label="Sueldo Diario  X  Cantidad Dias Laborados"
							filled
							hide-details
							readonly
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn
							color="black"
							tile
							dark
							small
							@click="dialogMontoLaborado = false"
						>
							<v-icon small left>mdi-cancel</v-icon>
							Cancelar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog
				v-model="dialogHorasExtras"
				hide-overlay
				persistent
				max-width="500"
			>
				<v-card>
					<v-card-title class="text-h5">
						Desglose Monto Horas Extras
					</v-card-title>
					<v-card-text>
						<v-textarea
							v-model="formula_horas_extras"
							rows="3"
							auto-grow
							label="Sueldo Hora  X  Cantidad Horas Extras"
							filled
							hide-details
							readonly
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn
							color="black"
							tile
							dark
							small
							@click="dialogHorasExtras = false"
						>
							<v-icon small left>mdi-cancel</v-icon>
							Cancelar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog
				v-model="dialogMontoRebajes"
				hide-overlay
				persistent
				max-width="500"
			>
				<v-card>
					<v-card-title class="text-h5">
						Desglose Monto Rebajes
					</v-card-title>
					<v-card-text>
						<v-textarea
							v-model="formula_monto_rebajes"
							rows="3"
							auto-grow
							label="Retardo + Rebaje + IMSS + Devolución + Caja Ahorro + Retención"
							filled
							hide-details
							readonly
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn
							color="black"
							tile
							dark
							small
							@click="dialogMontoRebajes = false"
						>
							<v-icon small left>mdi-cancel</v-icon>
							Cancelar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog
				v-model="dialogComisiones"
				hide-overlay
				persistent
				max-width="1200"
			>
				<v-card>
					<v-card-title class="text-h5">
						Desglose Monto Comisiones
					</v-card-title>
					<v-card-text>

						<v-row align="center" class="no-gutters">
              <v-col cols="12" md="6" lg="4">
                <span class="black--text text-h8"
                  ><b>Total Comisiones:</b>
                  {{ totalComisiones }} 
                </span>
              </v-col>
            </v-row>
            <v-row align="center" class="no-gutters">
              <v-col cols="12" md="6" lg="4">
                <span class="black--text text-h8"
                  ><b>Cantidad Comisiones:</b>
                  {{ cantidadComisiones }} 
                </span>
              </v-col>
            </v-row>

						<v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headersComisiones"
                  :items="encargadascomisiones"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                >
                </v-data-table>
              </v-col>
            </v-row>
					</v-card-text>
					<v-card-actions>
						<v-btn
							color="black"
							tile
							dark
							small
							@click="dialogComisiones = false"
						>
							<v-icon small left>mdi-cancel</v-icon>
							Cancelar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog
				v-model="dialogMontoBonos"
				hide-overlay
				persistent
				max-width="500"
			>
				<v-card>
					<v-card-title class="text-h5">
						Desglose Monto Bonos
					</v-card-title>
					<v-card-text>
						<v-textarea
							v-model="formula_monto_bonos"
							rows="3"
							auto-grow
							label="Bonos Ventas + Bonos RI + Bonos Puntualidad"
							filled
							hide-details
							readonly
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn
							color="black"
							tile
							dark
							small
							@click="dialogMontoBonos = false"
						>
							<v-icon small left>mdi-cancel</v-icon>
							Cancelar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>


			<v-dialog
				v-model="dialogMontoTotal"
				hide-overlay
				persistent
				max-width="500"
			>
				<v-card>
					<v-card-title class="text-h5">
						Desglose Monto Total
					</v-card-title>
					<v-card-text>
						<v-textarea
							v-model="formula_monto_total"
							rows="3"
							auto-grow
							label="(Monto Jornada Laboral + Monto Compensaciones + Monto Horas Extras + Monto Comisiones + Monto Bonos) - Monto Rebajes"
							filled
							hide-details
							readonly
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn
							color="black"
							tile
							dark
							small
							@click="dialogMontoTotal = false"
						>
							<v-icon small left>mdi-cancel</v-icon>
							Cancelar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>

		<!-- Dialog de carga -->
		<Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
		<carga                v-if="cargar"/>

	</v-container>

</template>
<script>
	import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
	import Alerta                from '@/components/alertas/Alerta.vue'
	import carga                 from '@/components/alertas/carga.vue';

	import botonesRoles          from '@/components/roles/botonesRoles.vue';

	import validarErrores  from '@/mixins/validarErrores'
	import XLSX            from 'xlsx'
	import funcionesExcel  from '@/mixins/funcionesExcel'


	export default {
		components:{
			Alerta,
			carga,
			botonesRoles,
		},

		mixins: [ validarErrores, funcionesExcel ],

		data: () => ({
    	// Alertas
			parametros:{
				dialogError: false,
				mensaje: '',
				color: ''
			},

			respuestaAlerta:false,
			loader: true,
			cargar: false,

			encabezados: null,
			encabezados2: null,
			dialogAceptar: false,
			dialogActualizar: false,
			dialogAsistencias: false,
			dialogMontoLaborado: false,
			dialogHorasExtras: false,
			dialogMontoRebajes: false,
			dialogMontoBonos: false,
			dialogMontoTotal: false,
			ciclos:[],
			gruposRoles: [],
			pagos: [],
			ciclo:null,
			id_ciclo: 0,

      // Filtros: 
			fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			// fecha: "2024-06-28",
			// fecha: "2024-07-26",
      // fechafin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      // tabla
			search:'',
			search2:'',
			formula_monto_laborado:'',
			formula_horas_extras:'',
			formula_monto_rebajes:'',
			formula_monto_bonos:'',
			formula_monto_total:'',
			page: 1,
			page2: 1,
			pageCount: 0,
			itemsPerPage: 10,
			entradassalidas: [],
			nomina_pasada: [],
			nominaSemanal: [],
			autorizarNomina: [],
			nominaEstatus: [],
			nominaTeachersSabatino: [],
			nominaTeachersCatorcena: [],
			asistencias: [],
			estatus: null,
			entradassalidasteachers: [],
			horasDobles:[],
			horasTotales: 0,
			headers: [
        // { text: 'ID'                    , value: 'id'                   },
				// { text: 'Empleado'              , value: 'nombre_completo'      },
				// { text: 'Puesto'                , value: 'puesto'               },
				{ text: 'Monto Jornada Laboral' , value: 'monto_laborado'       },
				{ text: 'Monto Horas Extras'    , value: 'monto_horas_extras'   },
				{ text: 'Monto Comisiones'      , value: 'comisiones_semana'    },
				{ text: 'Monto Rebajes'         , value: 'monto_total_rebajes'  },
				{ text: 'Monto Bonos'           , value: 'monto_total_bonos'    },
				{ text: 'Monto Total'           , value: 'monto_total'          },
				// { text: 'Departamento'          , value: 'departamento'         },
		    // { text: 'Plantel'               , value: 'plantel'              },
				// { text: 'Tipo pago'             , value: 'tipo_pago'            },
				// { text: 'Sueldo mensual'        , value: 'sueldo_mensual'       },
				// { text: 'Sueldo semanal'        , value: 'sueldo_semanal'       },
				// { text: 'Sueldo diario'         , value: 'sueldo_dia'           },
				// { text: 'Sueldo hora'           , value: 'sueldo_hora'          },
				// { text: 'Horas laborales'       , value: 'horas_laborales'      },
				// { text: 'Dias Laborados'        , value: 'dia_laboral'          },
		    // { text: 'Horas Extras'          , value: 'cantidad_horas'       },
				// { text: 'Monto Horas Extras'    , value: 'monto_horas_extras'   },
				// { text: 'Retardos'              , value: 'retardos'             },
				// { text: 'Comisiones'            , value: 'comisiones_semana'    },
				// { text: 'Devoluciones'          , value: 'devoluciones'         },
				// { text: 'Rebajes'               , value: 'rebajes'              },
				// { text: 'Monto Retardo'         , value: 'monto_retardo'        },
				// { text: 'Retenciones'           , value: 'retensiones'          },
        // { text: 'Compensaciones'        , value: 'compensaciones'       },
        // { text: 'Premios'               , value: 'premios'              },
				// { text: 'Bonos Ventas'          , value: 'bonos_ventas'         },
        // { text: 'Bonos RI'              , value: 'bonos_ri'             },
				// { text: 'Bonos Puntualidad'     , value: 'bonos_puntualidad'    },
				// { text: 'Seguro IMSS'           , value: 'seguroIMSS'           },
				// { text: 'Caja de Ahorro'        , value: 'cajaAhorro'           },
				// { text: 'Monto Jornada Laboral' , value: 'monto_laborado'       },
				// { text: 'Monto Total'           , value: 'monto_total'          },
				// { text: 'Monto Compensaciones'  , value: 'compensaciones'       },
			],
			headerscomparativa: [
				{ text: 'ID'                     , value: 'id_trabajador'         },
				{ text: 'Empleado'               , value: 'empleado'              },
				{ text: 'Puesto'                 , value: 'puesto'                },
				{ text: 'Departamento'           , value: 'departamento'          },
				{ text: 'Plantel'                , value: 'plantel'               },
				{ text: 'Monto Total Anterior'   , value: 'monto_total'           },
				{ text: 'Monto Total Actual'     , value: 'monto_total_actual'    },
				{ text: 'Monto Total Diferencia' , value: 'monto_total_diferencia'},
				],
			headersAsistencias: [
				{ text: "Hora Entrada"    , value: "hora_entrada"    },
				{ text: "Hora Salida"     , value: "hora_salida"     },
				{ text: "Chequeo Entrada" , value: "chequeo_entrada" },
				{ text: "Chequeo Salida"  , value: "chequeo_salida"  },
				{ text: "¿Regular?"       , value: "hora_regular"    },
				{ text: "Horas Extras"    , value: "hora_extra"      },
				{ text: "Horas Negativas" , value: "hora_negativa"   },
				{ text: "Horas Trabajo"   , value: "hora_trabajo"    },
				{ text: "Horas Trabajadas", value: "hora_trabajada"  },
				{ text: "Aceptar"         , value: "aceptar"         },
				],
			gruposRoles2:[],
			encargadascomisiones:[],
			headersComisiones: [
	      // { text: "ID", value: "id_alumno" },
	      { text: "Matrícula", value: "matricula" },
	      { text: "Alumno"   , value: "alumno"    },
	      { text: "Grupo"    , value: "grupo"     },
	      // { text: "Precio Grupo", value: "precio_grupo" },
	      // { text: "Precio con Descuento", value: "precio_grupo_con_descuento_x_alumno" },
	      // { text: "Monto Pagado", value: "pagado" },
	      // { text: "Fecha", value: "fecha_pago" },
	      // { text: "Fecha Ultimo Cambio", value: "fecha_ultimo_cambio" },
	      // { text: "Comisionista", value: "vendedora" },
	      { text: "Comisión", value: "comision"   },
	    ],

	    dialogComisiones: false,
			selectedHeaders:null,
			liberada: false,
			motivo: '',
			rechazado:false,

			estatus_nomina: 0,
			comentarios:[],
		}),

		watch:{
			selectedHeaders( value ){ if( value ){ this.initialize() } }
		},

		computed: {

			...mapGetters( 'login' ,['getdatosUsuario']),

			filterTeacher( ){
				let teachers = this.entradassalidasteachers;
				return teachers.filter(el => el.puesto === 'Teacher');
			},

			filterEntradas( ){
				let entradass = this.entradassalidas.concat(this.nominaTeachersSabatino, this.nominaTeachersCatorcena);
				return entradass.filter(el =>  el.puesto !== 'Sin puesto');
			},

			filterEntradasEncargadas( ){
				let entradass = this.entradassalidas;
				return entradass.filter(el => el.puesto !== 'Sin puesto' && el.puesto == 'Encargada de sucursal');
			},

			totalComisiones() {
	      let subtotal = this.encargadascomisiones
	        .map((item) => item.comision)
	        .reduce((prev, curr) => prev + curr, 0);
	      return new Intl.NumberFormat("en-US", {
	        style: "currency",
	        currency: "USD",
	      }).format(subtotal);
	    },
	    cantidadComisiones() {
	      let subtotal = this.encargadascomisiones.length;
	      this.cd1 = subtotal;
	      return Math.floor(subtotal);
	    },

		},


		async created () {
			// await this.initialize()
			await this.getCiclosFecha()
			await this.misComisiones()
		},

		methods: {
			initialize () {
				this.cargar = true
				this.entradassalidas = []
				this.entradassalidasteachers = []

				const payload = {
					fecha   : this.fecha,   
					id_ciclo: this.ciclo.id_ciclo,
					semana  : this.selectedHeaders
				}

				return this.$http.post('usuarios.entradas', payload).then(response=>{

					try{

						this.encabezados = response.data.encabezados;
						this.liberada    = response.data.liberada   ;
						let entradassalidas = response.data.result3.filter( el => el.id == this.getdatosUsuario.id_trabajador );

						for( const i in entradassalidas ){

							const { bonos_ventas, bonos_ri, bonos_puntualidad, monto_total, monto_total_bonos, compensaciones } = entradassalidas[i]

							let bono1           = bonos_ventas ? bonos_ventas.replace('$','').replace(',','')  : 0
							let bono2           = bonos_ri ? bonos_ri.replace('$','').replace(',','')  : 0
							let bono3           = bonos_puntualidad ? bonos_puntualidad.replace('$','').replace(',','') : 0
							let bono4           = monto_total_bonos ? monto_total_bonos.replace('$','').replace(',','') : 0
							let total           = monto_total ? monto_total.replace('$','').replace(',','') : 0
							let compensaciones2 = compensaciones ? compensaciones.replace('$','').replace(',','') : 0

							entradassalidas[i].monto_total_bonos = this.format( parseFloat( compensaciones2 ) + parseFloat( bono1 ) + parseFloat( bono2 ) + parseFloat( bono3 ) )
							// entradassalidas[i].monto_total       = this.format( total )

						}

						this.entradassalidas      = entradassalidas

						if( this.entradassalidas.length ){

							this.estatus_nomina = this.entradassalidas[0].estatus_nomina
							this.comentarios    = this.entradassalidas[0].comentarios

							this.comentarios    = this.comentarios.filter( el => [356,this.getdatosUsuario.id_trabajador,1086].includes( el.id_usuario ) ) 
							
						}
					}catch( error ){
						console.log( error )
					}


				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

			misComisiones( ){
				this.encargadascomisiones = [];
      this.overlay = true;

      const payload = {
        fecha: this.fecha,
        id_usuario: this.getdatosUsuario.iderp,
      }

      this.$http
        .post("encargadas_comisiones.get.comisionesindividuales", payload)
        .then((response) => {
          this.encargadascomisiones = response.data;
          this.overlay = false;
          // this.getComisionesRetenidasIndividuales()
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
			},

			getNominaSemanal () {
				this.cargar = true

				const payload = {
					fecha:    this.fecha,   
				}

				return this.$http.post('encargadas_comisiones.get.nominasemanal', payload).then(response=>{
					this.nominaSemanal = response.data;
					this.cargar      = false  
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

			save ( valor ) {
				this.cargar = true
				const payload = {
					id_usuario: this.getdatosUsuario.id_trabajador,  
					estatus   : valor,
					id_ciclo  : this.ciclo.id_ciclo,
					semana    : this.selectedHeaders,
					motivo    : valor == 2 ? this.motivo : ''
				}


				this.$http.post('encargadas_comisiones.estatus.nomina', payload).then(response=>{
					this.validarSuccess('Datos grabados correctamente')
					this.rechazado = false
					this.motivo    = ''
					this.cargar = false
					this.initialize( )
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })	
			},

			updateNominaSemanal () {
				this.cargar = true
				const payload = {
					fecha: this.fecha,  
					data: this.filterEntradas
				}

				this.$http.post('encargadas_comisiones.update.nominasemanal', payload).then(response=>{
					this.validarSuccess('Datos grabados correctamente')
					this.dialogActualizar = false
					this.getNominaSemanal()
					this.initialize();
					this.cargar = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })	
			},

			handleClick() {
				this.dialogAceptar = true;
			},


			getAutorizarNomina() {
				this.autorizarNomina = [];
				this.overlay = true;

				const payload = {
					fecha: this.fecha
				}

				this.$http
				.post("encargadas_comisiones.get.autorizarnomina", payload)
				.then((response) => {
					this.autorizarNomina = response.data.respuesta;
					this.overlay = false;
				})
				.catch((error) => {
					// INdicamos que hay un error en el back
					if (error.body.message) {
						this.error = error.body.message;
					} else {
						this.error = error.body;
					}
					this.errorBack = true;
					this.overlay = false;
					this.dialogError = true;
				});
			},

			mostrarAsistencias(item){
				this.dialogAsistencias = true

				for (const i in item.entradas){
					this.asistencias.push({
						chequeo_entrada: item.entradas[i],
						chequeo_salida: item.salidas[i],
						hora_regular: item.hora_regular[i],
						hora_extra: item.hora_extra[i],
						hora_negativa: item.hora_negativa[i],
						hora_entrada: i == 0 ? item.horario_ingreso_fin : item.horario_ingreso,
						hora_salida: i == 0 ? item.horario_salida_fin : item.horario_salida,
					})
				}
			},

			formulaMontoLaborado(item) {
				console.log( item )

				const Hora1 = "08:17:40";
				const Hora2 = "08:00:00";
				const toleranceMinutes = 6;

				// console.log(this.checkArrivalTime(item.entrada2, item.entradas[1], toleranceMinutes));

				let sueldo_dia = item.sueldo_dia;
				let dia_laboral = item.dia_laboral;
				this.formula_monto_laborado = `$${sueldo_dia}  X  ${dia_laboral} dias\n
				Días laborados:\n
				Sábado: 
				${ item.dia_descanso == 1    ? 'Descanso' : !item.entrada1 ? 'Sin registro' : item.entrada1 + ' a ' + item.salida1   } 
				${ item.entrada1 && item.salida1 ? item.estatus1 == 0 ? 'JORNADA COMPLETA' : item.estatus1 == 1 ? 'JORNADA CON RETARDO' : 'DESCANSO' : item.estatus1 == 2 ? '' : 'ERROR EN LA JORNADA' }\n
				Domingo: 
				${ item.dia_descanso == 2   ? 'Descanso' : !item.entrada2 ? 'Sin registro' : item.entrada2 + ' a ' + item.salida2   } 
				${ item.entrada2 && item.salida2 ? item.estatus2 == 0 ? 'JORNADA COMPLETA' : item.estatus2 == 1 ? 'JORNADA CON RETARDO' : 'DESCANSO' : item.estatus2 == 2 ? '' : 'ERROR EN LA JORNADA' }\n
				Lunes: 
				${ item.dia_descanso == 3     ? 'Descanso' : !item.entrada3 ? 'Sin registro' : item.entrada3 + ' a ' + item.salida3   } 
				${ item.entrada3 && item.salida3 ? item.estatus3 == 0 ? 'JORNADA COMPLETA' : item.estatus3 == 1 ? 'JORNADA CON RETARDO' : 'DESCANSO' : item.estatus3 == 2 ? '' : 'ERROR EN LA JORNADA' }\n
				Martes: 
				${ item.dia_descanso == 4    ? 'Descanso' : !item.entrada4 ? 'Sin registro' : item.entrada4 + ' a ' + item.salida4   } 
				${ item.entrada4 && item.salida4 ? item.estatus4 == 0 ? 'JORNADA COMPLETA' : item.estatus4 == 1 ? 'JORNADA CON RETARDO' : 'DESCANSO' : item.estatus4 == 2 ? '' : 'ERROR EN LA JORNADA' }\n
				Miércoles: 
				${ item.dia_descanso == 5 ? 'Descanso' : !item.entrada5 ? 'Sin registro' : item.entrada5 + ' a ' + item.salida5   } 
				${ item.entrada5 && item.salida5 ? item.estatus5 == 0 ? 'JORNADA COMPLETA' : item.estatus5 == 1 ? 'JORNADA CON RETARDO' : 'DESCANSO' : item.estatus5 == 2 ? '' : 'ERROR EN LA JORNADA' }\n
				Jueves: 
				${ item.dia_descanso == 6    ? 'Descanso' : !item.entrada6 ? 'Sin registro' : item.entrada6 + ' a ' + item.salida6   } 
				${ item.entrada6 && item.salida6 ? item.estatus6 == 0 ? 'JORNADA COMPLETA' : item.estatus6 == 1 ? 'JORNADA CON RETARDO' : 'DESCANSO' : item.estatus6 == 2 ? '' : 'ERROR EN LA JORNADA' }\n
				Viernes: 
				${ item.dia_descanso == 7   ? 'Descanso' : !item.entrada7 ? 'Sin registro' : item.entrada7 + ' a ' + item.salida7   } 
				${ item.entrada7 && item.salida7 ? item.estatus7 == 0 ? 'JORNADA COMPLETA' : item.estatus7 == 1 ? 'JORNADA CON RETARDO' : 'DESCANSO' : item.estatus7 == 2 ? '' : 'ERROR EN LA JORNADA' }\n`;
			},   

			formulaMontoHorasExtras(item) {
				let sueldo_hora = item.sueldo_hora;
				let cantidad_horas = item.cantidad_horas;
				this.formula_horas_extras = `${sueldo_hora}  X  ${ cantidad_horas ? cantidad_horas : 0 } horas extras`;
			},  

			formulaMontoRebajes(item) {

				let rebajes = item.rebajes !== undefined && item.rebajes !== null ? item.rebajes : 0.00;
				let seguroIMSS = item.seguroIMSS !== undefined && item.seguroIMSS !== null ? item.seguroIMSS : 0.00;
				let cajaAhorro = item.cajaAhorro !== undefined && item.cajaAhorro !== null ? item.cajaAhorro : 0.00;
				let monto_retardo = item.monto_retardo !== undefined && item.monto_retardo !== null ? item.monto_retardo : 0.00;
				let retensiones = item.retensiones !== undefined && item.retensiones !== null ? item.retensiones : 0.00;
				let devoluciones = item.devoluciones !== undefined && item.devoluciones !== null ? item.devoluciones : 0.00;

				this.formula_monto_rebajes = `
				Monto Retardo: $ ${monto_retardo}\n`;

				if( monto_retardo ){

					if( item.estatus1 == 1 ){
						this.formula_monto_rebajes +=`
						*${ this.formatFecha( item.fechas[0] ) } ${ item.entrada1 }\n`
					}
					if( item.estatus2 == 1 ){
						this.formula_monto_rebajes +=`
						*${ this.formatFecha( item.fechas[1] ) }: ${ item.entrada2 }\n`
					}
					if( item.estatus3 == 1 ){
						this.formula_monto_rebajes +=`
						*${ this.formatFecha( item.fechas[2] ) }: ${ item.entrada3 }\n`
					}
					if( item.estatus4 == 1 ){
						this.formula_monto_rebajes +=`
						*${ this.formatFecha( item.fechas[3] ) }: ${ item.entrada4 }\n`
					}
					if( item.estatus5 == 1 ){
						this.formula_monto_rebajes +=`
						*${ this.formatFecha( item.fechas[4] ) }: ${ item.entrada5 }\n`
					}
					if( item.estatus6 == 1 ){
						this.formula_monto_rebajes +=`
						*${ this.formatFecha( item.fechas[5] ) }: ${ item.entrada6 }\n`
					}
					if( item.estatus7 == 1 ){
						this.formula_monto_rebajes +=`
						*${ this.formatFecha( item.fechas[6] ) }: ${ item.entrada7 }\n`
					}

				}

				this.formula_monto_rebajes += `
				Monto Otros: $ ${rebajes}\n  
				Monto Seguro IMSS: $ ${seguroIMSS}\n  
				Monto Devoluciones: $ ${devoluciones}\n  
				Monto Caja Ahorro: $ ${cajaAhorro}\n  
				Monto Retenciones: $ ${retensiones}
				`;

			},

			formulaMontoBonos(item) {
				// let bonos_ventas = item.bonos_ventas !== undefined && item.bonos_ventas !== null ? item.bonos_ventas : 0.00;
				// let bonos_ri = item.bonos_ri !== undefined && item.bonos_ri !== null ? item.bonos_ri : 0.00;
				// let bonos_puntualidad = item.bonos_puntualidad !== undefined && item.bonos_puntualidad !== null ? item.bonos_puntualidad : 0.00;
				// let compensaciones = item.compensaciones !== undefined && item.compensaciones !== null ? item.compensaciones : 0.00;


				let bonos_ventas = item.bonos_ventas !== undefined && item.bonos_ventas !== null ? item.bonos_ventas : 0.00;
				let bonos_ri = item.bonos_ri !== undefined && item.bonos_ri !== null ? item.bonos_ri : 0.00;
				let bonos_puntualidad = item.bonos_puntualidad !== undefined && item.bonos_puntualidad !== null ? item.bonos_puntualidad : 0.00;
				let compensaciones = item.compensaciones !== undefined && item.compensaciones !== null ? item.compensaciones : 0.00;

				this.formula_monto_bonos = `
				Monto Bonos Ventas: $${bonos_ventas}\n  
				Monto Bonos RI: $${bonos_ri}\n  
				Monto Bonos Puntualidad: $${bonos_puntualidad}\n  
				Compensaciones: $${compensaciones}\n  
				`;

				console.log( this.formula_monto_bonos )
			},

			formulaMontoTotal(item) {
				let monto_laborado = item.monto_laborado !== undefined && item.monto_laborado !== null ? item.monto_laborado : 0.00;
				let compensaciones = item.compensaciones !== undefined && item.compensaciones !== null ? item.compensaciones : 0.00;
				let monto_horas_extras = item.monto_horas_extras !== undefined && item.monto_horas_extras !== null ? item.monto_horas_extras : 0.00;
				let comisiones_semana = item.comisiones_semana !== undefined && item.comisiones_semana !== null ? item.comisiones_semana : 0.00;
				let monto_total_rebajes = item.monto_total_rebajes !== undefined && item.monto_total_rebajes !== null ? item.monto_total_rebajes : 0.00;
				let monto_total_bonos = item.monto_total_bonos !== undefined && item.monto_total_bonos !== null ? item.monto_total_bonos : 0.00;

				this.formula_monto_total = `
				Monto Jornada Laboral: ${monto_laborado}\n  
				Monto Compensaciones: ${compensaciones}\n  
				Monto Horas Extras: ${monto_horas_extras}\n  
				Monto Comisiones: ${comisiones_semana}\n  
				Monto Bonos: ${monto_total_bonos}\n  
				Monto Rebajes: ${monto_total_rebajes} 
				`;
			},

			formatFecha( fechadata ){
				// Fecha en formato 'YYYY-MM-DD'
				let fechaString = fechadata;

				// Dividir la cadena de fecha en componentes
				let [year, month, day] = fechaString.split('-').map(Number);

				// Crear un objeto Date con los componentes (mes en JavaScript es 0-indexed)
				let fecha = new Date(year, month - 1, day);

				// Array de días de la semana y meses del año
				let diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
				let meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

				// Obtener el día de la semana, día del mes y mes
				let diaSemana = diasSemana[fecha.getDay()];
				let dia = fecha.getDate();
				let mes = meses[fecha.getMonth()];

				// Construir la cadena en el formato deseado
				return `${diaSemana} ${dia} de ${mes}`;
			},

			getCiclosFecha () {
				this.cargar = true
				this.ciclos = []

				const payload = {
					fecha: this.fecha
				}

				this.$http.post('roles.get.ciclofecha', payload).then(response=>{
					for(const i in response.data){
						if (!(response.data[i].ciclo.search('FE') != -1)) {
							if(response.data[i].ciclo.search('CICLO') != -1){
								this.ciclos.push(response.data[i])
								this.ciclo = this.ciclos[0];
							}
						}
					}
					this.cargar = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { })
			},

			goHorasExtras( name ){
				this.$router.push({name: "HorasExtras", params: { fecha: this.fecha, nombre: name }})
			},
			goPrenomina( name ){
				this.$router.push({name: "EntradasSalidas", params: { fecha: this.fecha, nombre: name } })
			},
			goBonos( name ){
				this.$router.push({name: "BonosVentas", params: { fecha: this.fecha, nombre: name }})
			},
			goRebajes( name ){
				this.$router.push({name: "RebajesNomina", params: { fecha: this.fecha, nombre: name }})
			},
			goComisiones( name ){
				this.$router.push({name: "EncargadasComisiones", params: { fecha: this.fecha, nombre: name }})
			},

			exportExcel(data) {
				const wb = XLSX.utils.book_new();
				data.forEach(sheetData => {
					const ws = XLSX.utils.json_to_sheet(sheetData.data);
					XLSX.utils.book_append_sheet(wb, ws, sheetData.sheetName);
				});

				// Descarga el archivo Excel
				XLSX.writeFile(wb, 'Nomina y Prenomina.xlsx');
			},

			exportarInscripciones() {
				// const empleados = this.filterEntradas.map(({ id, nombre_completo, puesto, departamento, plantel, tipo_pago, sueldo_mensual, sueldo_semanal, sueldo_dia, sueldo_hora, dia_laboral, horas_laboradas_total, cantidad_horas, monto_horas_extras, retardos, comisiones_semana, devoluciones, rebajes, monto_retardo,  retensiones, compensaciones, bonos_ventas, bonos_ri, bonos_puntualidad, seguroIMSS, cajaAhorro, monto_laborado, monto_total }) =>
				// 	({ id, nombre_completo, puesto, departamento, plantel, tipo_pago, sueldo_mensual, sueldo_semanal, sueldo_dia, sueldo_hora, dia_laboral, horas_laboradas_total, "Horas Extras": cantidad_horas, monto_horas_extras, retardos, comisiones_semana, devoluciones, rebajes, monto_retardo, retensiones, compensaciones, bonos_ventas, bonos_ri, bonos_puntualidad, seguroIMSS, cajaAhorro, monto_laborado, monto_total }));

				const empleados = this.filterEntradas.map(({ id, nombre_completo, puesto, monto_laborado, monto_horas_extras, comisiones_semana, monto_total_rebajes, monto_total_bonos, monto_total }) =>
					({ "ID": id,"Empleado":nombre_completo, "Puesto":puesto, "Monto Jornada Laboral":monto_laborado,  "Monto Horas Extras":monto_horas_extras,  "Monto Comisiones":comisiones_semana, "Monto Rebajes":monto_total_rebajes, "Monto Bonos":monto_total_bonos,  "Monto Total":monto_total }));

				const empleados2 = this.filterEntradas.map(({ id, nombre_completo, puesto, plantel, sucursal_registro, fecha_ingreso, dia_laboral, horas_laboradas_total, horas_extras, entrada1, salida1, horas_laboradas1,  entrada2, salida2, horas_laboradas2, entrada3, salida3, horas_laboradas3,  entrada4, salida4, horas_laboradas4,  entrada5, salida5, horas_laboradas5,  entrada6, salida6, horas_laboradas6, entrada7, salida7, horas_laboradas7 }) => 
					({ id, nombre_completo, puesto, plantel, sucursal_registro, fecha_ingreso, dia_laboral, horas_laboradas_total, horas_extras, "Sabado/Entrada": entrada1, "Sabado/Salida":salida1, "Sabado/Horas":horas_laboradas1,  "Domingo/Entrada":entrada2, "Domingo/Salida":salida2, "Domingo/Horas":horas_laboradas2,  "Lunes/Entrada":entrada3, "Lunes/Salida":salida3, "Lunes/Horas":horas_laboradas3,  "Martes/Entrada":entrada4, "Martes/Salida":salida4, "Martes/Horas":horas_laboradas4, "Miercoles/Entrada":entrada5, "Miercoles/Salida":salida5, "Miercoles/Horas":horas_laboradas5, "Jueves/Entrada":entrada6, "Jueves/Salida":salida6, "Jueves/Horas":horas_laboradas6, "Viernes/Entrada":entrada7, "Viernes/Salida":salida7, "Viernes/Horas":horas_laboradas7  }));

				const empleados3 = this.nomina_pasada.map(({ id_trabajador, empleado, puesto, plantel, monto_total_sin_comisiones, monto_total_actual, monto_total_diferencia}) => 
					({ id_trabajador, empleado, puesto, plantel, "Monto Anterior": monto_total_sin_comisiones, "Monto Actual": monto_total_actual, "Monto Diferencia": monto_total_diferencia }));

				const empleados4 = this.filterEntradasEncargadas.map(({ id, nombre_completo, puesto, plantel, comisiones_semana}) => 
					({ id, nombre_completo, puesto, plantel, "Comisiones": comisiones_semana }));


				// Llama a la función exportExcel para crear un archivo Excel con dos pestañas
				this.exportExcel([
					{ sheetName: 'Nomina', data: empleados },
					{ sheetName: 'Prenomina', data: empleados2 }, // Segunda pestaña con los mismos datos
					{ sheetName: 'Comparativa', data: empleados3 }, // Segunda pestaña con los mismos datos
					{ sheetName: 'Comisiones', data: empleados4 } // Segunda pestaña con los mismos datos
				]);
			},

			async subirRequi( ){

				await this.subirNominaINBI()
				await this.subirComisionesINBI()
				await this.subirNominaFAST()
				await this.subirComisionesFAST()

			},

			async subirNominaINBI( ){

				let nominaInbiOperaciones = this.filterEntradas.filter( el => el.departamento == 'Operaciones' && !el.plantel.match('FAST') )

				let detallesInbiOperaciones = []

				for( const i in nominaInbiOperaciones ){

					const { id_usuario, monto_laborado, nombre_completo, id_plantel } = nominaInbiOperaciones[i]

					detallesInbiOperaciones.push({
						id_elemento_requisicion: 17,
						id_plantel_resultados: id_plantel,
						id_plantel: id_plantel,
						concepto: nombre_completo,
						cantidad: 1,
						costo_unitario: monto_laborado.replace('$',''),
						costo_total: monto_laborado.replace('$',''),
						id_alumno: 0,
						id_grupo: 0,
						id_empleado: id_usuario
					})
				}

				var payload = {
      		registro:                      null,
      		id_usuario_solicita:           this.getdatosUsuario.iderp,
		      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
		      id_requisicion_compra_estatus: 1,
		      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
		      id_usuario_compra:             28,
		      tipo_requisicion:              1,
		      id_ciclo:                      this.ciclo.id_ciclo,
          id_proyecto:                   null,
		      comentarios:                   'Nómina',
		      detalles:                      detallesInbiOperaciones,
			    fotos:[]
	      }


	      return this.$http.post('generar.nomina.operaciones', payload ).then(response=>{
          this.validarSuccess( 'Requisición generada correctamente' )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

			},

			async subirNominaFAST( ){

				let nominaInbiOperaciones = this.filterEntradas.filter( el => el.departamento == 'Operaciones' && el.plantel.match('FAST') )

				let detallesInbiOperaciones = []

				for( const i in nominaInbiOperaciones ){

					const { id_usuario, monto_laborado, nombre_completo, id_plantel } = nominaInbiOperaciones[i]

					detallesInbiOperaciones.push({
						id_elemento_requisicion: 17,
						id_plantel_resultados: id_plantel,
						id_plantel: id_plantel,
						concepto: nombre_completo,
						cantidad: 1,
						costo_unitario: monto_laborado.replace('$',''),
						costo_total: monto_laborado.replace('$',''),
						id_alumno: 0,
						id_grupo: 0,
						id_empleado: id_usuario
					})
				}

				var payload = {
      		registro:                      null,
      		id_usuario_solicita:           this.getdatosUsuario.iderp,
		      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
		      id_requisicion_compra_estatus: 1,
		      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
		      id_usuario_compra:             28,
		      tipo_requisicion:              1,
		      id_ciclo:                      this.ciclo.id_ciclo_relacionado,
          id_proyecto:                   null,
		      comentarios:                   'Nómina',
		      detalles:                      detallesInbiOperaciones,
			    fotos:[]
	      }


	      return this.$http.post('generar.nomina.operaciones', payload ).then(response=>{
          this.validarSuccess( 'Requisición generada correctamente' )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

			},

			async subirComisionesINBI( ){

				let nominaInbiOperaciones = this.filterEntradas.filter( el => el.departamento == 'Operaciones' && !el.plantel.match('FAST') )

				let detallesInbiOperaciones = []

				for( const i in nominaInbiOperaciones ){

					const { id_usuario, comisiones_semana, nombre_completo, id_plantel } = nominaInbiOperaciones[i]

					detallesInbiOperaciones.push({
						id_elemento_requisicion: 17,
						id_plantel_resultados: id_plantel,
						id_plantel: id_plantel,
						concepto: nombre_completo + ' ( COMISIONES )',
						cantidad: 1,
						costo_unitario: comisiones_semana.replace('$',''),
						costo_total: comisiones_semana.replace('$',''),
						id_alumno: 0,
						id_grupo: 0,
						id_empleado: id_usuario
					})
				}

				var payload = {
      		registro:                      null,
      		id_usuario_solicita:           this.getdatosUsuario.iderp,
		      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
		      id_requisicion_compra_estatus: 1,
		      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
		      id_usuario_compra:             28,
		      tipo_requisicion:              1,
		      id_ciclo:                      this.ciclo.id_ciclo,
          id_proyecto:                   null,
		      comentarios:                   'Nómina',
		      detalles:                      detallesInbiOperaciones,
			    fotos:[]
	      }


	      return this.$http.post('generar.nomina.operaciones', payload ).then(response=>{
          this.validarSuccess( 'Requisición generada correctamente' )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

			},

			async subirComisionesFAST( ){

				let nominaInbiOperaciones = this.filterEntradas.filter( el => el.departamento == 'Operaciones' && el.plantel.match('FAST') )

				let detallesInbiOperaciones = []

				for( const i in nominaInbiOperaciones ){

					const { id_usuario, comisiones_semana, nombre_completo, id_plantel } = nominaInbiOperaciones[i]

					detallesInbiOperaciones.push({
						id_elemento_requisicion: 17,
						id_plantel_resultados: id_plantel,
						id_plantel: id_plantel,
						concepto: nombre_completo + ' ( COMISIONES )',
						cantidad: 1,
						costo_unitario: comisiones_semana.replace('$',''),
						costo_total: comisiones_semana.replace('$',''),
						id_alumno: 0,
						id_grupo: 0,
						id_empleado: id_usuario
					})
				}

				var payload = {
      		registro:                      null,
      		id_usuario_solicita:           this.getdatosUsuario.iderp,
		      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
		      id_requisicion_compra_estatus: 1,
		      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
		      id_usuario_compra:             28,
		      tipo_requisicion:              1,
		      id_ciclo:                      this.ciclo.id_ciclo_relacionado,
          id_proyecto:                   null,
		      comentarios:                   'Nómina',
		      detalles:                      detallesInbiOperaciones,
			    fotos:[]
	      }


	      return this.$http.post('generar.nomina.operaciones', payload ).then(response=>{
          this.validarSuccess( 'Requisición generada correctamente' )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

			},


			parseTimeString(timeString) {
			  const [hours, minutes, seconds] = timeString.split(':').map(Number);
			  return new Date(1970, 0, 1, hours, minutes, seconds);
			},

			checkArrivalTime(hora1, hora2, toleranceMinutes) {
			  const date1 = this.parseTimeString(hora1);
			  const date2 = this.parseTimeString(hora2);

			  const diffInMs = date1 - date2; // Difference in milliseconds
			  const diffInMinutes = diffInMs / 1000 / 60; // Convert to minutes

			  if (diffInMinutes <= toleranceMinutes) {
			    return "Llegó a tiempo";
			  } else {
			    return "Llegó tarde";
			  }
			},

			getDiaDescando( dia ){

				switch( dia ){

					case 1: 
						return 'Sábado' 
					break; 

					case 2: 
						return 'Domingo' 
					break; 

					case 3: 
						return 'Lunes' 
					break; 

					case 4: 
						return 'Martes' 
					break; 

					case 5: 
						return 'Miércoles' 
					break; 

					case 6: 
						return 'Jueves' 
					break; 

					case 7: 
						return 'Viernes' 
					break; 

				}

			},

			format( valor ){
			  return valor.toLocaleString('es-MX', {
			    style: 'currency',
			    currency: 'MXN'
			  });
      }

		},
	}
</script>




