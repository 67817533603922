<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-text class="pa-0">

          <div class="black--text text-subtitle-1">
            <b>Reporte generado por:</b> {{ getdatosUsuario.nombre_completo }}
            <br/>
            <v-chip>
              <b>Tipo de reporte: </b>  Cambio de curso
            </v-chip>
            <br/>
          </div>

          <v-row v-if="alumno">
            <v-col cols="12" class="pa-0">
              <v-card-text>
                <b>Matricula:</b> {{ alumno.matricula }}
                <br/>
                <b>Nombre:</b>    {{ alumno.alumno }}
                <br/>
                <v-divider></v-divider>

                <br/>
                <v-row>
                  <v-col cols="12" md="6" >
                    <div class="text-subtitle-1"><strong>Curso actual</strong></div>
					          <v-divider class="mb-4"></v-divider>
					          <v-autocomplete
					            v-model="alumno2.id_curso"
					            :items="precios"
					            item-text="curso"
					            item-value="id_curso"
					            filled
					            dense
					            readonly
					          ></v-autocomplete>
                    <br/>
                  </v-col>

                  <v-col cols="12" md="6" >
                    <div class="text-subtitle-1"><strong>Curso Nuevo</strong></div>
					          <v-divider class="mb-4"></v-divider>
					          <v-autocomplete
					            v-model="precioSeleccionado"
					            :items="precios"
					            item-text="curso"
					            item-value="id_curso"
					            filled
					            dense
					          ></v-autocomplete>
                    <br/>
                    <v-textarea
		                  label="Por favor, ingresa el motivo de tu solicitud"
		                  v-model="motivo"
		                  filled
		                ></v-textarea>

		                <v-file-input 
		                  small-chips 
		                  multiple 
		                  filled 
		                  v-model="files" 
		                  accept="image/png, image/jpeg, image/bmp"
		                  placeholder="Selecciona tus imagenes" prepend-icon="mdi-camera" label="Evidencias" @change="cargarFotos()">
		                </v-file-input>

		                <v-row v-if="vistaPrevias.length">
		                  <v-col cols="12" md="3" v-for="(img, i) in vistaPrevias" :key="i">
		                    <v-card class="py-4 shadowCard">
		                      <v-img :src="img.url" contain aspect-ratio="2"></v-img>
		                      <v-btn  color="error"  small  @click="eliminarFoto(img.url)" top right absolute fab >
		                        <v-icon>mdi-delete</v-icon>
		                      </v-btn>
		                    </v-card>
		                  </v-col>
		                </v-row>
                  </v-col>
                </v-row>
                <br/>
              </v-card-text>

              <v-card-actions>
              	<v-spacer></v-spacer>
              	<v-btn
			            v-if="precioSeleccionado && motivo"
			            color="green"
			            dark
			            tile
                  @click="confirmacion.estatus = true"
			            :loading="loading"
			            :disabled="disabled" 
			          >
			            <v-icon small left>mdi-send</v-icon>
			            Enviar solicitud
			          </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>

    <dialogConfirmacion   
      v-if="confirmacion.estatus"        
      :confirmacion="confirmacion"
      @addCambio="addCambio"
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
	var moment = require('moment');
  moment.locale();
	import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';


  import dialogConfirmacion    from '@/components/cambios/dialogConfirmacion.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    components:{
      Alerta,
      carga,
      dialogConfirmacion
    },

    mixins: [ validarErrores, funcionesExcel ],

    props: ['alumno','reporteTexto'],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search: '',
      
      tipociclo:'',
      grupos:[],
      nuevoGrupo:null,
      tipocambios:[],
      tipoCambio:null,
      alumnos:[],

      grupoAlumnos:   [],
      grupoAnterior:  null,
      
      loading:        false,
      disabled:       false,
      motivo:         null,

      total_a_pagar: 0,
      precios:[],

      dataGrupoActual: null,
      dataNuevoGrupo:null,
      precioSeleccionado2: null,
      descuentoHermanos:0,

      tipo_reporte:null,

      url:'',

      fotos:[],
      files: [],
      vistaPrevias:[],

      textoCambio: "",

      costoCurso: 0,
      id_grupo_favor:0,
      confirmacion: {
        estatus: false
      },
    	
    	grupo:[],
    	id_alumno: null,
			ciclo: null,
			precioSeleccionado: null,
    	idCursoAsociado: null,
    	alumno2: {},

    }),

    watch:{
    },

    async created () {
      // Cargar los cursos actuales
      this.id_alumno = this.alumno.id_alumno;
	    this.ciclo = this.alumno.id_ciclo;
	    this.getPrecios();
	    this.getPrecioAlumno();
    },

    methods: {

	    getPrecioAlumno(){
      this.$http
        .get("precios.alumno/" + this.id_alumno)
        .then((response) => {
          this.alumno2 = response.data;
          console.log( this.alumno2 )
          // this.precioSeleccionado.id = this.precios.find((precio) => precio.id_curso == this.alumno.id_curso).iddescuentos_cursos;
          // this.precioSeleccionado.precio_lista = this.precios.find((precio) => precio.id_curso == this.alumno.id_curso).precio_lista;
          // //this.precioSeleccionado.precio = this.precios.find((precio) => precio.id_curso == this.alumno.id_curso).iddescuentos_cursos;
          // this.idCursoAsociado = this.alumno.id_curso;
          // this.precio = this.alumno.precio_lista;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    	},

      getCursos( ){
        this.cargar = true
        this.precios = []
        this.$http.get("all.precios").then((response) => {
          this.cargar = false
          this.precios = response.data.filter( el => el.curso.split("-")[1].match("3") );
        }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });
      },

      getPrecios() {
	      this.$http.get("all.precios").then((response) => {
	        this.precios = response.data.filter( el => el.curso.split("-")[1].match("3") );
	      }).catch((error) => {
	        this.validarError(error);
	      }).finally(() => {
	        this.cargar = false;
	      });
	    },

      getTitpoCambios () {
        this.cargar = true
        return this.$http.get('cambios.tipo.cambio').then(response=>{
          this.tipocambios = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      async addCambio () {

        // Validar que esten llenos los campos
    		this.cargar = true
      	
      	let fotos = []
        if(this.vistaPrevias.length){
          let respuesta = await this.subirFotosServidor( )
          fotos = respuesta.data
        }

        // Activar el disabled del boton para que no le den doble click
        // this.loading  = true
        // this.disabled = true
        // Desesestructuración de alumno 
        const { id_alumno, id_plantel, monto_pagado_total } = this.alumno

        // Generar el payload a mandar
        let payload = {
          id_alumno,
          idtipo_cambio:   0,
          solicito_cambio: this.getdatosUsuario.iderp,
          id_plantel,
          id_grupo_actual:   0,
          id_grupo_nuevo:    0,
          estatus:           1,
          diferencia:        0,
          motivo:            this.motivo,
          fotos,
          tipo_reporte       : 10,
          id_ingreso         : null,
          nuevo_comprobante  : null,
          viejo_metodo:      0,
          nuevo_metodo:      0,
          viejo_monto:       0,
          nuevo_monto:       0,
          vieja_fecha:       null,
          nueva_fecha:       null,
          textoCambio:       this.textoCambio,
          id_grupo_favor:    null,
          id_curso_actual:   this.alumno2.id_curso,
          id_nuevo_curso :   this.precioSeleccionado
        }

        this.cargar = true

        // Enviar los datos 
        this.$http.post('cambios.add',payload).then(response=>{

          this.validarSuccess( 'Datos grabados correctamente')

          this.$emit('getAlumnosxEscuela')

        }).catch(error=>{
          this.validarError( error.response.data.message )
        })
      },

      subirFotosServidor( ){
        let formData = new FormData();

        for( const i in this.vistaPrevias ){
          formData.append('file', this.vistaPrevias[i].file )
        }

        return new Promise((resolve,reject)=>{
          // obtener la extensión del archivo
          // Hacemos la petición
          this.$http.post(`cambios.evidencia`, formData ).then(response=>{
            resolve( response )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },

      cargarFotos(){
        if(this.files.length < 1){
          return
        }

        this.files.forEach((element, index) => {
                // creamos una variable tipo FormData
          let formData = new FormData();
                //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', element);
                //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          this.getBase64(element, formData)
        })
      },

      getBase64(file, formData) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          console.log(file)
          me.vistaPrevias.push({
            url: reader.result, 
            formData: formData, 
            image_name: file.name,
            file
          })
        };
      },

      eliminarFoto(value){
        this.vistaPrevias.forEach((element, index)=>{
          if(element.url == value){
            this.vistaPrevias.splice(index,1);
          }
        })
      },
    },

    computed:{
      ...mapGetters ('login',['getdatosUsuario']),

      currentTitle () {
        switch (this.step) {
          case 1: return 'Selecciona el alumno'
          case 2: return 'Selecciona tipo de reporte'
          default: return 'Confirma tu reporte'
        }
      },
    }
  }
</script>

<style>

  .v-menu__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-menu__content::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-button:increment,.v-menu__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-menu__content::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>