<template>
	<v-container class="mt-4" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  			<v-card-title primary-title>
	  			  <span class="text-subtitle-1">Registros</span>
	  			  <v-spacer></v-spacer>
            <v-chip color="grey" dark small @click="subirMatriculas( )" class="mr-2">LMS</v-chip>
            <v-chip color="orange" dark small @click="initialize( )" class="mr-2">Activar Cuentas</v-chip>
            <v-chip color="green" dark small @click="exportar( )" class="mr-2"><v-icon>mdi-microsoft-excel</v-icon></v-chip>
	  			  <v-chip color="primary" small @click="initialize( )">Actualizar</v-chip>
	  			</v-card-title>

	  			<v-card-text class="pt-0">
	  			  <v-row justify="end" class="pt-0">
	  			  	<v-col cols="12" md="6" lg="4" class="pt-0">
	  			  		<v-text-field
	  			  		  label="Buscar"
	  			  		  id="id"
	  			  		  v-model="search"
	  			  		  outlined
	  			  		  dense
	  			  		  rounded
	  			  		  hide-details
	  			  		></v-text-field>
	  			  	</v-col>
	  			  </v-row>
	  			</v-card-text>

	  			<v-card-text>
		  			<v-data-table
					    :headers="headers"
					    :items="registrosExci"
					    sort-by="calories"
					    class="elevation-0"
					    :search="search"
					  >

					    <template v-slot:item.pago_realizado="{ item }">
					      <v-chip color="green" dark small v-if="item.pago_realizado >= 1399 ">{{ item.pago_realizado }} </v-chip>
					      <v-chip color="orange" dark small v-else-if="item.pago_realizado >= 700   ">{{ item.pago_realizado }} </v-chip>
					      <v-chip color="red" dark small v-else>{{ item.pago_realizado }} </v-chip>
					    </template>

              <template v-slot:item.matricula="{ item }">
                <v-chip color="green" dark small v-if="item.pago_realizado >= 1399 && item.matricula != ''">{{ item.matricula }}</v-chip>
                <v-chip color="red" dark small v-else-if="item.matricula != ''">{{ item.matricula }}</v-chip>
              </template>

              <template v-slot:item.lms="{ item }">
                <v-chip color="green" dark small v-if="item.lms == 1">si</v-chip>
                <v-chip color="red" dark small v-else >no</v-chip>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-chip color="red" class="mr-2" dark small v-if="item.matricula != ''" @click="sendMatricula( item )">Matri.</v-chip>
                <v-chip color="blue" class="mr-2" dark small v-if="item.pago_realizado >= 700" @click="senZoom( item )">Zoom</v-chip>
                <v-chip color="orange" dark small  @click="recordarPago( item )">recordar Pago</v-chip>
              </template>



              <template v-slot:item.correo="props">
				        <v-edit-dialog
				          :return-value.sync="props.item.correo"
				          @save="save( props.item )"
				          @cancel="cancel"
				          @open="open"
				          @close="close"
				          large
				          save-text="Guardar"
				          cancel-text="Cancelar"
				        >
				          {{ props.item.correo }}
				          <template v-slot:input>
				            <v-text-field
				              v-model="props.item.correo"
				              label="Editar correo"
				              single-line
				              counter
				            ></v-text-field>
				          </template>
				        </v-edit-dialog>
				      </template>

					    <template v-slot:no-data>
					      <v-btn
					        color="primary"
					        @click="initialize"
					      >
					        Reset
					      </v-btn>
					    </template>
					  </v-data-table>
	  			</v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>					
</template>
<script>
import axios from 'axios';
  import {mapGetters, mapActions} from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import funcionesExcel  from '@/mixins/funcionesExcel'


  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ funcionesExcel ],

    data: () => ({
    	snack: false,
      snackColor: '',
      snackText: '',

    	search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'id'                  , value: 'idexci_registro' },
        { text: 'Nombre'              , value: 'nombre' },
        { text: 'Correo'              , value: 'correo' },
        { text: 'Teléfono'            , value: 'telefono' },
        { text: 'Pago'                , value: 'pago_realizado' },
        { text: 'matricula'           , value: 'matricula' },
        { text: 'LMS'                 , value: 'lms' },
        { text: 'Enviar correo de: '  , value: 'actions' },
      ],
      registrosExci: [],
      pago:0,
      adeudo: 0,
      mediopago:null,
      alumno: {},
      dialogPago:false,

      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      alumnos:[],
      dialogAddMatricula: false,
      matricula: null,

      dialogSeguimiento:false,
      seguimiento:'',

      total:0,
      conAdeudo:0,
      liquidados:0,
      conAdeudoyPago:0,
    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      pago ( val ) {
      	if( val && val > 0 && this.alumno){
      		this.adeudo = 1399 - val - this.alumno.pago_realizado
      	}
      }
    },

    async created () {

      await this.initialize()
      await this.getAlumnosAll( )
    },

    methods: {
      initialize () {
        this.registrosExci = []
        return this.$http.get('exci.registros').then( response=> {
        	this.registrosExci = response.data

          this.total           = this.registrosExci.length
          this.conAdeudo       = this.registrosExci.filter( el=> { return el.pago_realizado < 1399 && el.pago_realizado == 0}).length
          this.conAdeudoyPago  = this.registrosExci.filter( el=> { return el.pago_realizado < 1399 && el.pago_realizado > 0 }).length
          this.liquidados      = this.registrosExci.filter( el=> { return el.pago_realizado >= 1399 }).length
        }).catch( error => {
        	console.log( error )
        }).finally( ( ) => {  })
      },

      getAlumnosAll () {
        this.alumnos = []
        return this.$http.get('exci.alumnos').then( response=> {
          this.alumnos = response.data
        }).catch( error => {
          console.log( error )
        }).finally( ( ) => {  })
      },

      exportar(){
        this.exportExcel( this.registrosExci, 'alumnos_exci')
      },

     save ( value ) {
        return this.$http.post('exci.update.correo', value ).then( response=> {
          this.snack = true
	        this.snackColor = 'success'
	        this.snackText = 'Datos guardados'
        }).catch( error => {
          this.snack = true
	        this.snackColor = 'error'
	        this.snackText = 'Datos no guardados'
        }).finally( ( ) => {  })
      },

      sendMatricula ( value ){
      	this.cargar = true
      	return this.$http.post('exci.enviar.matricula', value ).then( response=> {
      		this.cargar = false
          this.snack = true
	        this.snackColor = 'success'
	        this.snackText = 'Matricula enviada'
        }).catch( error => {
        	this.cargar = false
          this.snack = true
	        this.snackColor = 'error'
	        this.snackText = 'Datos no guardados'
        }).finally( ( ) => {  })
      },

      
      senZoom ( value ){
      	this.cargar = true
      	return this.$http.post('exci.enviar.zoom', value ).then( response=> {
      		this.cargar = false
          this.snack = true
	        this.snackColor = 'success'
	        this.snackText = 'Corredo enviado'
        }).catch( error => {
        	this.cargar = false
          this.snack = true
	        this.snackColor = 'error'
	        this.snackText = 'Datos no guardados'
        }).finally( ( ) => {  })
      },

      
      recordarPago ( value ){
      	this.cargar = true
      	return this.$http.post('exci.enviar.recordatorio.pago', value ).then( response=> {
      		this.cargar = false
          this.snack = true
	        this.snackColor = 'success'
	        this.snackText = 'Correo enviado'
        }).catch( error => {
        	this.cargar = false
          this.snack = true
	        this.snackColor = 'error'
	        this.snackText = 'Datos no guardados'
        }).finally( ( ) => {  })
      },

      subirMatriculas ( ){
      	this.cargar = true
      	return this.$http.get('lms.catalogos.usuarios.exci/201' ).then( response=> {
      		this.cargar = false
          this.snack = true
	        this.snackColor = 'success'
	        this.snackText = 'Cuentas activas'
        }).catch( error => {
        	this.cargar = false
          this.snack = true
	        this.snackColor = 'error'
	        this.snackText = 'Datos no guardados'
        }).finally( ( ) => {  })
      },

      cancel () {
        this.snack = true
        this.snackColor = 'error'
        this.snackText = 'Canceled'
      },
      open () {
        this.snack = true
        this.snackColor = 'info'
        this.snackText = 'Dialog opened'
      },
      close () {
        console.log('Dialog closed')
      },

      validarErrores ( value ) {
        this.cargar = false
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

    },
  }
</script>