import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';

export default {
	methods: {
		validarrespuestaAlerta( error ){ 
			return new Promise( (resolve, reject) => {
				if( error.status == 400){
					resolve( error.body.message )
				}else{
					reject( error )
				}
			})
		},

		mEliminarTarea( id ){
      this.$http.delete(`prospectos.tarea/${ id }`).then((response) => {
        this.dialogDelete = false
        this.dialogSuccess = true
        // funcion de la vista actual
        this.verProspecto(this.prospecto, this.idetapa)
      }).catch(error=>{
        // INdicamos que hay un error en el back
        if(error.body.message){
          this.error = error.body.message
        }else{
          this.error = error.body
        }
        this.errorBack = true
        this.overlay = false
        this.dialogError = true
      })
		},

    // Agregar un comentario
    addComentario ( mensaje ) {
      let payload = {
        idprospectos: this.prospecto.idprospectos,
        idusuarioerp: this.getdatosUsuario.iderp,
        comentario:   mensaje,
      }

      this.overlay = true

      this.$http.post('prospectos.comentarios', payload).then((response) => {
        // funcion de la vista actual
        this.overlay = true
        this.verProspecto( this.prospecto, this.idetapa );
      }).catch(error=>{
        this.validError( error )
      }).finally(()=> { this.overlay = false })
      this.newComentario = ''
    },

  }
}