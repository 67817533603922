<template>
  <v-container class="mt-4 ml-2">
    <v-row>
      <v-col cols="12">
	      <v-card>
	      	<v-card-title primary-title>
		        <v-toolbar-title>Ciclos</v-toolbar-title>
		        <v-divider
		          class="mx-4"
		          inset
		          vertical
		        ></v-divider>
		        <v-spacer></v-spacer>
		        <v-btn color="primary" @click="getCiclos()" small class="mr-2">Consultar</v-btn>
		        <v-btn color="secondary" @click="getCiclosERP()" small>Cargar del ERP</v-btn>
	      	</v-card-title>
	        <v-card-text>
	        	<!-- Menu superior de tabs -->

		        <v-tabs
		        	v-if="ciclos"
			        dense
			        class="mt-4"
			        v-model="tab"
			        background-color="white"
			      >
			        <v-tabs-slider color="primary"></v-tabs-slider>


			        <v-tab
			          v-for="(item,i) in items"
			          :key="i"
			        >
			          <v-icon v-text="item.icon" left></v-icon>{{ item.name }}
			        </v-tab>


			        <!-- Apartado de las secciones de los tabs -->
			        <v-tabs-items v-model="tab">
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">
			                <v-card-title primary-title class="pt-0 ">

			                </v-card-title>

			                <v-card-text>	
			                	<v-data-table
											    dense
											    :headers="headers"
											    :items="ciclos.ciclosFast"
											    item-key="id"
											    class="elevation-1"
											  ></v-data-table>
			                </v-card-text>	
			              </v-card>
			            </v-col>
			          </v-tab-item>
			        

			          <!-- horarios de inbi -->
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">
			              	<v-card-title primary-title class="pt-0 ">

			                </v-card-title>

			                <v-card-text >
			                  <v-data-table
											    dense
											    :headers="headers"
											    :items="ciclos.ciclosInbi"
											    item-key="id"
											    class="elevation-1"
											  ></v-data-table>
			                </v-card-text>	
			              </v-card>
			            </v-col>
			          </v-tab-item>


			          <!-- Resultados -->
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">

			              	
			              </v-card>
			            </v-col>
			          </v-tab-item>

			        </v-tabs-items>
			      </v-tabs>
	        </v-card-text>
	      </v-card>		
      </v-col>
    </v-row>

    
  	<!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="500"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Espera porfavor
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card
        color="green"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Datos grabados
        </v-card-title>
        <div class="pl-6 pb-6">
          <br/>
          NOTA: {{successMensaje}}
        </div>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogSuccess = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
	  
    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card
        color="error"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Error al recuperar los datos
        </v-card-title>
        <v-card-text>
          <br/>
          NOTA: {{error}}
        </v-card-text>
        <v-btn 
        	color="transparent elevation-0"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogError = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
  import XLSX from 'xlsx'

  export default {
    data: () => ({
    	row:null,
    	tab: {name:'Ciclos FAST'   , icon:'mdi-file' }, 
      items: [
        {name:'Ciclos FAST'    , icon:'mdi-file' }, 
        {name:'Ciclos INBI'    , icon:'mdi-file' }, 
      ],

      headers: [
        { text: 'id'          				, value: 'id' },
        { text: 'Nombre'      				, value: 'nombre' },
        { text: 'fecha inicio'				, value: 'fecha_inicio' },
        { text: 'fecha fin'   				, value: 'fecha_fin' },
        { text: 'fecha inicio excluir', value: 'fecha_inicio_excluir' },
        { text: 'fecha fin excluir'		, value: 'fecha_fin_excluir' },
        { text: 'estatus'     				, value: 'estatus' },
        { text: 'iderp'       				, value: 'iderp' },
      ],

      ciclos:[],
      dialogError: false,
			dialogSuccess:false,
			error:'',
			successMensaje:'',
			overlay:true,
			horarios:null
    }),

   
    created () {
      this.getCiclos()
    },

    methods: {
      getCiclos () {
      	// vaciamos el horario
      	this.ciclos = null
      	// Activamos el loader
    		this.overlay = true
    		// Hacemos la peticios
    		this.$http.get('lms.catalogos.ciclos.all').then(response=>{
    			// Guardamos la respuesta
          this.ciclos = response.data
          // Quitamos el loader
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError(error.body.message)
        })
      },

      getCiclosERP () {
      	// Activamos el loader
    		this.overlay = true
    		// Hacemos la peticion
    		this.$http.get('lms.catalogos.ciclos.cron').then(response=>{
    			// Mandamos a llamar a la funcion principal para que cargue los ciclos
          this.getCiclos()
          // Quitamos el loader
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError(error.body.message)
        })
      },

      openDialogError (mensaje) {
      	this.error       = mensaje
      	this.dialogError = true
      },

      openDialogSuccess ( mensaje ){
      	this.successMensaje = mensaje
      	this.dialogSuccess  = true
      },

    },

    computed:{
    	...mapGetters('login',['getdatosUsuario'])
    }
  }
</script>

<style>
	.v-snack__content{
		padding: 0px !important;
	}
</style>