<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1"><v-icon left>mdi-cash-refund</v-icon>Devoluciones </span>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="3" >
                <v-text-field
                  filled          
                  label="Fecha"
                  type="date"
                  hide-details
                  v-model="fecha"
                  dense
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>             
              <v-col cols="12" md="6">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="devoluciones"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                >
                  <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize" small>
                      Actualizar
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,
    dialogAceptar: false,
    headers: [
      { text: "ID", align: "start", value: "id" },
      { text: "Requisicion", value: "id_requisicion_compra" },
      { text: "Plantel", value: "plantel" },
      { text: "Concepto", value: "concepto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Costo Unitario", value: "costo_unitario" },
      { text: "Costo Total", value: "costo_total" },
      { text: "Nombre", value: "nombre_completo" },
      { text: "Grupo", value: "grupo" },
      { text: "Fecha", value: "fecha" },
      // { text: "Estatus", value: "estatus" },
      // { text: "Aceptar", value: "aceptar" },
      // { text: "Rechazar", value: "rechazar" },
    ],
    devoluciones: [],
    editedIndex: -1,
    editedItem: {
      idhoras_extras: "",
      fecha: "",
      hora_inicio: "",
      hora_fin: "",
      cantidad_horas: "",
      notas: "",
      estatus: 0,
      id_creo: ""
    },
    defaultItem: {
      idhoras_extras: "",
      fecha: "",
      hora_inicio: "",
      hora_fin: "",
      cantidad_horas: "",
      notas: "",
      estatus: 0,
      id_creo: ""
    },

    dialogSuccess: false,
    overlay: false,
    dialogError: false,
    error: "",
    successMensaje: "",
    errorBack: false,
    textoCopiado: "COPIAR",
    idcurso: 0,
    info: 0,
    dialog_mensaje_rechazo: false,
    idhistorial: [],
    fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    horaextramensaje: null,
    editedItem2: {
      mensaje: "",
    },
    mensajes: [],

    ciclos: [],
    ciclo: null,
    estatus: null,
    alumnos: [],
    reglas: [],
    cursosEscuela: [],
    devoluciones: "",
    // tabla
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
    formTitle() {
      return this.editedIndex === -1
        ? "Agregar Horas Extras"
        : "Editar Horas Extras";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "editedItem.escuela"(val) {
      this.cursosEscuela = this.cursos.filter((el) => {
        return el.escuela == val;
      });
    },
  },

  created() {
    this.initialize();
    // this.getCiclos();
    // this.getAlumnos();
    // this.getReglas();
  },

  methods: {
    initialize() {
      this.devoluciones = [];
      this.overlay = true;
      const payload = {
      id_creo: this.getdatosUsuario.iderp,
      fecha:  this.fecha
      };

      this.$http
        .post("devoluciones.get.devolucionesall", payload)
        .then((response) => {
          this.devoluciones = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    editItem(item) {
      this.editedIndex = this.incidencias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.horasextras.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },


    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    copiarPortapapeles() {
      navigator.clipboard
        .writeText(this.error)
        .then(() => {
          this.textoCopiado = "COPIADO";
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });
    },

   
  






  },
};
</script>