<template>
  <v-container class="mt-4 ml-2" fluid>
  	
  	<!-- Datos iniciales del prospecto -->
  	<v-row v-if="prospecto">
  		<v-col cols="12">
  			<v-card>
	        <!-- Datos principales del usuario -->
	        <v-card-text>
	          <v-row>
	            <!-- Datos del usuario -->
	            <v-col cols="12" lg="6">
	              <!-- Eventos en timeline -->
	              <v-card-text class="pt-4 pb-0">
	                <v-subheader class="pl-0">Eventos</v-subheader>
	                <div v-if="!eventosTimeline.length">Sin eventos</div>
	                <span v-for="(evento, i) in eventosTimeline">
	                  <v-tooltip bottom :color="evento.color" >
	                    <template v-slot:activator="{ on, attrs }">
	                      <v-btn
	                        dark
	                        fab 
	                        x-small
	                        class="mr-2"
	                        :color="evento.color" 
	                        v-bind="attrs"
	                        v-on="on"
	                      >
	                        <v-icon v-text="evento.icono"></v-icon>
	                      </v-btn>
	                    </template>
	                    <span>{{ evento.fecha_creacion }}<br/>{{ evento.evento }}</span>
	                  </v-tooltip>
	                </span>
	              </v-card-text>

	              <v-card-text class="pt-12">
	                <v-row>
	                  <!-- Datos personales -->
	                  <v-col cols="12" sm="6" class="pb-0">
	                    <v-text-field
	                      label="Nombre"
	                      v-model="prospecto.nombre_completo"
	                      dense
	                    ></v-text-field>

	                    <v-text-field
	                      label="Teléfono"
	                      v-model="prospecto.telefono"
	                      dense
	                    ></v-text-field>

	                    <v-text-field
	                      label="Folio"
	                      v-model="prospecto.folio"
	                      readonly
	                      dense
	                    ></v-text-field>

	                    <v-text-field
	                      label="Correo"
	                      v-model="prospecto.correo"
	                      dense
	                    ></v-text-field>

	                    <v-text-field
	                      label="Nota"
	                      v-model="prospecto.nota_inicial"
	                      readonly
	                      dense
	                      hide-details
	                    ></v-text-field>

	                    <v-switch
	                      v-model="prospecto.induccion"
	                      color="primary"
	                      label="En Inducción"
	                    ></v-switch>

	                    <v-text-field
	                      v-if="prospecto.induccion"
	                      label="¿Cuándo tomará inducción?"
	                      v-model="prospecto.fecha_induccion"
	                      dense
	                      type="date"
	                      width="200"
	                    ></v-text-field>
	                    <v-chip color="red"    dark hide-details v-if="idetapa == 1">Atrasado</v-chip>
	                    <v-chip color="green"  dark hide-details v-if="idetapa == 2">Nuevo</v-chip>
	                    <v-chip color="pink"   dark hide-details v-if="idetapa == 3">Inducción</v-chip>
	                    <v-chip color="orange" dark hide-details v-if="idetapa == 4">Seg. con Info</v-chip>
	                    <v-chip color="brown"  dark hide-details v-if="idetapa == 5">Seg. sin Info</v-chip>

	                    <div class="mt-2">
	                      <v-tooltip bottom color="#558B2F">
	                        <template v-slot:activator="{ on, attrs }">
	                          <v-chip color="#558B2F"  dark hide-details v-bind="attrs" v-on="on" class="mr-1">E. | {{ prospecto.cantEventos }} |</v-chip>
	                        </template>
	                        <span class="white--text">Eventos generales: {{ prospecto.cantEventos }}</span>
	                      </v-tooltip>

	                      <v-tooltip bottom color="#00C853">
	                        <template v-slot:activator="{ on, attrs }">
	                          <v-chip color="#00C853"  dark hide-details v-bind="attrs" v-on="on" class="mr-1">E.H. | {{ prospecto.cantEventosHoy }} |</v-chip>
	                        </template>
	                        <span class="white--text">Eventos de hoy: {{ prospecto.cantEventosHoy }}</span>
	                      </v-tooltip>

	                      <v-tooltip bottom color="green">
	                        <template v-slot:activator="{ on, attrs }">
	                          <v-chip color="green"  dark hide-details v-bind="attrs" v-on="on" class="mr-1">{{ prospecto.tiempo_transcurrido }} días</v-chip>
	                        </template>
	                        <span class="white--text">Ingresó hace: {{ prospecto.tiempo_transcurrido }} días</span>
	                      </v-tooltip>
	                    </div>
	                  </v-col>

	                  <!-- Comentarios -->
	                  <v-col cols="12" sm="6" class="pb-0 pt-0">
	                    <div class="text-h6">Comentarios | {{ comentarios.length }} |</div>
	                    <div id="eventos" :height="200" style="overflow-y: scroll; overflow-x: hidden;">
	                      <v-row>
	                        <v-col v-if="comentarios.length > 0" cols="12">
	                          <div class="head"
	                            v-for="(comentario, i) in comentarios"
	                            :key="i"
	                          >
	                            <small>
	                              <strong class="user">
	                                {{ comentario.nombre_completo }}
	                              </strong>
	                                {{ comentario.fecha_creacion }}
	                            </small>
	                            <p>
	                              {{ comentario.comentario }}
	                            </p>
	                          </div>
	                          <hr />
	                        </v-col>
	                      </v-row>
	                    </div>
	                    <v-text-field
	                      outlined
	                      rounded
	                      label="Escribir un comentario"
	                      v-model="newComentario"
	                      hide-details
	                      dense
	                      :append-icon="newComentario ? 'mdi-send' : ''"
	                      @click:append="addComentario()"
	                    ></v-text-field>
	                  </v-col>
	                </v-row>
	              </v-card-text>

	              <v-divider></v-divider>

	              <!-- Eventos -->
	              <v-card class="elevation-0" id="eventos" v-if="mostrarEventos">
	                <v-card-title primary-title>
	                  EVENTOS | {{eventos.length}} |
	                </v-card-title>
	                <v-card-text>
	                  <v-row>
	                    <v-col>
	                      <v-card
	                        :color="idetapa ==  3 || idetapa == 4 ? 'blue' : 'grey lighten-2'"
	                        class="text-center"
	                        style="border-radius: 200px;"
	                        max-width="100"
	                      >
	                        <v-card-text class="py-1">
	                          <div class="white--text text-h5">
	                            <v-icon
	                              size="22"
	                              color="white"
	                              v-text=" idetapa ==  3 || idetapa == 4 ? 'mdi-account-check' : 'mdi-account-alert'"
	                            >
	                            </v-icon>
	                          </div>
	                          <sub class="text-caption white--text ">Perfil</sub>
	                        </v-card-text>
	                      </v-card>
	                    </v-col>

	                    <v-col>
	                      <v-card
	                        :color="llamadasList.length > 0 ? 'warning' : 'grey lighten-2'"
	                        class="text-center"
	                        style="border-radius: 200px;"
	                        max-width="100"
	                      >
	                        <v-card-text class="py-1">
	                          <div class="white--text text-h5">
	                            {{ llamadasList.length }}
	                            <v-icon
	                              size="22"
	                              color="white"
	                              right
	                              v-text="'mdi-phone'"
	                            > 
	                            </v-icon>
	                          </div>
	                          <sub class="text-caption white--text ">Llamadas</sub>
	                        </v-card-text>
	                      </v-card>
	                    </v-col>

	                    <v-col>
	                      <v-card
	                        :color="countWhatsApp > 0 ? 'green' : 'grey lighten-2'"
	                        class="text-center"
	                        style="border-radius: 200px;"
	                        max-width="100"
	                      >
	                        <v-card-text class="py-1">
	                          <div class="white--text text-h5">
	                            {{ countWhatsApp }}
	                            <v-icon
	                              size="22"
	                              color="white"
	                              right
	                            >
	                              mdi-whatsapp
	                            </v-icon>
	                          </div>
	                          <sub class="text-caption white--text ">WhatsApp</sub>
	                        </v-card-text>
	                      </v-card>
	                    </v-col>

	                    <v-col>
	                      <v-card
	                        :color="prospecto.induccion == 1 ? 'pink' : 'grey lighten-2'"
	                        class="text-center"
	                        style="border-radius: 200px;"
	                        max-width="100"
	                      >
	                        <v-card-text class="py-1">
	                          <div class="white--text text-h5">
	                            <v-icon
	                              size="22"
	                              color="white"
	                              v-text="prospecto.induccion == 1 ? 'mdi-emoticon-happy' : 'mdi-emoticon-sad'"
	                            >
	                            </v-icon>
	                          </div>
	                          <sub class="text-caption white--text ">Inudcción</sub>
	                        </v-card-text>
	                      </v-card>
	                    </v-col>

	                    <v-col>
	                      <v-card
	                        :color="prospecto.tareasProgramadas > 0 ? 'orange' : 'grey lighten-2'"
	                        class="text-center"
	                        style="border-radius: 200px;"
	                        max-width="100"
	                      >
	                        <v-card-text class="py-1">
	                          <div class="white--text text-h5">
	                            {{ prospecto.tareasProgramadas }}
	                            <v-icon
	                              size="22"
	                              color="white"
	                              right
	                            >
	                              mdi-clipboard-text
	                            </v-icon>
	                          </div>
	                          <sub class="text-caption white--text ">Tareas</sub>
	                        </v-card-text>
	                      </v-card>
	                    </v-col>

	                  </v-row>
	                </v-card-text>      
	              </v-card>

	              <!-- Botones de accion -->
	              <v-card class="elevation-0">
	                <v-card-title primary-title>
	                  BOTONES DE ACCIÓN
	                </v-card-title>
	                <v-card-text>
	                  <v-row>
	                    <!-- Boton para reasignar prospecto -->
	                    <v-col>
	                      <v-tooltip bottom color="primary">
	                        <template v-slot:activator="{ on, attrs }">
	                          <v-card
	                            color="primary"
	                            dark
	                            v-bind="attrs"
	                            v-on="on"
	                            @click="abrirDialogReasignacion()"
	                            class="text-center mr-2"
	                            style="border-radius: 200px;"
	                            max-width="160"
	                          >
	                            <v-card-text class="py-1">
	                              <div class="white--text text-h5">
	                                <v-icon
	                                  size="22"
	                                  color="white"
	                                  v-text="'mdi-account-switch'"
	                                >
	                                </v-icon>
	                              </div>
	                              <sub class="text-caption white--text ">Reasignar</sub>
	                            </v-card-text>
	                          </v-card>
	                        </template>
	                        <span>Reasignar el prospecto a otra vendedora</span>
	                      </v-tooltip>
	                    </v-col>

	                    <!-- Boton para reasignar prospecto -->
	                    <v-col>
	                      <v-tooltip bottom color="red">
	                        <template v-slot:activator="{ on, attrs }">
	                          <v-card
	                            color="red"
	                            dark
	                            v-bind="attrs"
	                            v-on="on"
	                            @click="dialogFinalizacion = true, getGrupos()"
	                            class="text-center mr-2"
	                            style="border-radius: 200px;"
	                            max-width="160"
	                          >
	                            <v-card-text class="py-1">
	                              <div class="white--text text-h5">
	                                <v-icon
	                                  size="22"
	                                  color="white"
	                                  v-text="'mdi-account-check'"
	                                >
	                                </v-icon>
	                              </div>
	                              <sub class="text-caption white--text ">Finalizar</sub>
	                            </v-card-text>
	                          </v-card>
	                        </template>
	                        <span>Finalizar el prospecto</span>
	                      </v-tooltip>
	                    </v-col>

	                    <!-- Boton para guardar los datos -->
	                    <v-col>
	                      <v-tooltip bottom color="grey">
	                        <template v-slot:activator="{ on, attrs }">
	                          <v-card
	                            color="grey"
	                            dark
	                            v-bind="attrs"
	                            v-on="on"
	                            @click="actualizarProspecto()"
	                            class="text-center mr-2"
	                            style="border-radius: 200px;"
	                            max-width="160"
	                          >
	                            <v-card-text class="py-1">
	                              <div class="white--text text-h5">
	                                <v-icon
	                                  size="22"
	                                  color="white"
	                                  v-text="'mdi-content-save'"
	                                >
	                                </v-icon>
	                              </div>
	                              <sub class="text-caption white--text ">Guardar datos</sub>
	                            </v-card-text>
	                          </v-card>
	                        </template>
	                        <span>Guardar datos</span>
	                      </v-tooltip>
	                    </v-col>

	                    <!-- Boton para llego a sucursal -->
	                    <v-col>
	                      <v-tooltip bottom color="orange">
	                        <template v-slot:activator="{ on, attrs }">
	                          <v-card
	                            color="orange"
	                            dark
	                            v-bind="attrs"
	                            v-on="on"
	                            @click="inciarSeguimientoEnSucursal()"
	                            class="text-center mr-2"
	                            style="border-radius: 200px;"
	                            max-width="160"
	                          >
	                            <v-card-text class="py-1">
	                              <div class="white--text text-h5">
	                                <v-icon
	                                  size="22"
	                                  color="white"
	                                  v-text="'mdi-store'"
	                                >
	                                </v-icon>
	                              </div>
	                              <sub class="text-caption white--text ">Llego a sucursal</sub>
	                            </v-card-text>
	                          </v-card>
	                        </template>
	                        <span>Llego a sucursal y llenar perfil</span>
	                      </v-tooltip>
	                    </v-col>

	                  </v-row>
	                </v-card-text>
	              </v-card>

	              <v-divider></v-divider>
	            </v-col>

	            <v-col cols="12" md="12" lg="6">
	              <v-tabs
	                color="secondary"
	                right
	                dense
	              >
	                <v-tab class="text-capitalize" v-if="idetapa ==  3 || idetapa == 4">Información</v-tab>
	                <v-tab class="text-capitalize">Tareas | {{ tareas_programadas.length }} |</v-tab>
	                <v-tab class="text-capitalize">Llamadas | {{ llamadasList.length }} |</v-tab>

	                <!-- Primer TAB INFROMACIÓN -->
	                <!-- Este tab solo se habilitará si no es primer contacto -->
	                <v-tab-item v-if="idetapa ==  3 || idetapa == 4" class="pt-0">
	                  <v-row class="mt-4">

	                    <!-- Pregunta #1 -->
	                    <v-col cols="12">
	                      <div>¿El curso es para ti, o para alguien más?</div>
	                      <v-radio-group 
	                        v-model="paraquien_el_curso" 
	                        row
	                        hide-details
	                        class="mt-0"
	                      >
	                        <v-radio :value="1" row>
	                          <template v-slot:label>
	                            <div>Para mi</div>
	                          </template>
	                        </v-radio>
	                        <v-radio :value="0">
	                          <template v-slot:label>
	                            <div>Alguién más</div> 
	                          </template>
	                        </v-radio>
	                        <v-radio :value="2">
	                          <template v-slot:label>
	                            <div>Hermanos</div> 
	                          </template>
	                        </v-radio>
	                      </v-radio-group>
	                    </v-col>

	                    <!-- Agregar familiares -->
                      <v-col cols="12" v-if="showFamiliares">
	                      <div><v-btn color="primary" class="text-capitalize" @click="addPersonas = true">Agregar personas</v-btn></div>
	                      <div v-if="addPersonas" class="pt-6">
	                        <v-card>
	                          <v-card-text>
	                            <v-row>
	                              <v-col cols="12" md="6" lg="7">
	                                <v-text-field
	                                  label="Nombre completo"
	                                  dense
	                                  v-model="familiar"
	                                  outlined
	                                  hide-details
	                                ></v-text-field>
	                              </v-col>

	                              <v-col cols="12" md="5" lg="4">
	                                <v-text-field
	                                  label="Edad"
	                                  dense
	                                  v-model="edadFamiliar"
	                                  outlined
	                                  hide-details
	                                ></v-text-field>
	                              </v-col>

	                              <v-col cols="12" md="6">
	                                <v-text-field
	                                  label="Correo"
	                                  dense
	                                  v-model="correoFamiliar"
	                                  outlined
	                                  hide-details
	                                ></v-text-field>
	                              </v-col>

	                              <v-col cols="12" md="6">
	                                <v-text-field
	                                  label="Teléfono"
	                                  dense
	                                  v-model="telefonoFamiliar"
	                                  outlined
	                                  hide-details
	                                ></v-text-field>
	                              </v-col>
	                            </v-row>
	                          </v-card-text>
	                          <v-card-actions>
	                            <v-spacer></v-spacer>
	                            <v-btn color="success" fab small @click="addFamiliar()"><v-icon>mdi-check</v-icon></v-btn>
	                          </v-card-actions>
	                        </v-card>
	                      </div>

	                      <div class="pt-2"><small>Persoas añadidas</small></div>
	                      <div v-if="familiares.length">
	                        <v-data-table
	                          :headers="headersFamiliares"
	                          :items="familiares"
	                          class="elevation-1"
	                          fixed-header
	                          hide-default-footer
	                          :items-per-page="familiares.length"
	                          dense
	                        >
	                          <template v-slot:item.actions="{ item }">
	                            <v-icon
	                              small
	                              @click="deletFamiliar(item)"
	                              color="grey"
	                              class="mr-2"
	                              v-if="item.finalizo == 0"
	                            >
	                              mdi-delete
	                            </v-icon>

	                            <v-icon
	                              small
	                              @click="dialogFinalizarFamiliar = true, familiarAFinalizar = item, getGrupos()"
	                              color="red"
	                              v-if="item.finalizo == 0"
	                            >
	                              mdi-account-check
	                            </v-icon>

	                            <v-chip v-if="item.finalizo == 1" color="green" dark small>Finalizado</v-chip>
	                          </template>
	                        </v-data-table>
	                      </div>
	                    </v-col>

	                    <!-- Pregunta #2 -->
	                    <v-col cols="12">
	                      <div>¿Anteriormente ha estudiado inglés en alguna otra escuela o colegio?</div>
	                      <v-radio-group 
	                        v-model="estudioIngles" 
	                        row
	                        hide-details
	                        class="mt-0"
	                      >
	                        <v-radio :value="1" row>
	                          <template v-slot:label>
	                            <div>Si</div>
	                          </template>
	                        </v-radio>
	                        <v-radio :value="0">
	                          <template v-slot:label>
	                            <div>No</div> 
	                          </template>
	                        </v-radio>
	                      </v-radio-group>
	                    </v-col>

	                    <!-- Pregunta #3 -->
	                    <v-col cols="12" sm="6" lg="4">
	                      <v-select
	                        :items="['Acádemico','Trabajo','Ocio']"
	                        v-model="porqueEstudiarIngles"
	                        label="¿Por qué estudiar inglés?"
	                        hide-details
	                        dense
	                      ></v-select>
	                    </v-col>

	                    <!-- pregunta #4 -->
	                    <v-col cols="12" sm="6" lg="4">
	                      <v-select
	                        :items="['Mañana','Tarde','Noche','Fin de semana']"
	                        v-model="dispoHorario"
	                        label="Disponibilidad horario"
	                        hide-details
	                        dense
	                      ></v-select>
	                    </v-col>

	                    <!-- pregunta #5 -->
	                    <v-col cols="12" sm="6" lg="4">
	                      <v-text-field
	                        v-model="edad"
	                        dense
	                        hide-details
	                        type="number"
	                        label="Edad"
	                        clearable
	                      ></v-text-field>
	                    </v-col>

	                    <v-col cols="12" sm="6" lg="4">
	                      <v-select
	                        :items="filterSucursales"
	                        v-model="sucursal"
	                        return-object
	                        item-text="plantel"
	                        item-value="idplantel"
	                        label="Sucursal"
	                        hide-details
	                        dense
	                      ></v-select>
	                    </v-col>

	                    <v-col cols="12" sm="6" lg="4">
	                      <v-select
	                        :items="anuncios"
	                        v-model="anuncio"
	                        return-object
	                        item-text="anuncio"
	                        item-value="idanuncios"
	                        label="¿Cómo nos conocio?"
	                        hide-details
	                        dense
	                      ></v-select>
	                    </v-col>

	                    <v-col cols="12" sm="6" lg="4">
	                      <v-select
	                        :items="cursos"
	                        v-model="curso"
	                        return-object
	                        item-text="curso"
	                        item-value="idcursos_escuela"
	                        label="Curso"
	                        hide-details
	                        dense
	                      ></v-select>
	                    </v-col>

	                    <v-col cols="12" sm="6" lg="4">
	                      <v-select
	                        :items="modalidadesEscuela"
	                        v-model="modalidad"
	                        return-object
	                        item-text="modalidad"
	                        item-value="idmodalidad_cursos"
	                        label="Modalidad"
	                        hide-details
	                        dense
	                      ></v-select>
	                    </v-col>

	                    <v-col cols="12" sm="6" lg="4">
	                      <v-select
	                        :items="frecuenciasEscuela"
	                        v-model="frecuencia"
	                        return-object
	                        item-text="frecuencia"
	                        item-value="idfrecuencias"
	                        label="Frecuencia"
	                        hide-details
	                        dense
	                      ></v-select>
	                    </v-col>

	                    <v-col cols="12" sm="6" lg="4">
	                      <v-select
	                        :items="ciclos_plantel"
	                        v-model="ciclo"
	                        return-object
	                        item-text="ciclo"
	                        item-value="id_ciclo"
	                        label="Ciclo interes"
	                        hide-details
	                        dense
	                      ></v-select>
	                    </v-col>

	                    <v-col cols="12" sm="6" lg="4">
	                      <v-select
	                        disabled
	                        :items="vendedoras_plantel"
	                        v-model="vendedora"
	                        return-object
	                        item-text="nombre"
	                        item-value="iderp"
	                        label="Vendedora"
	                        hide-details
	                        dense
	                        readonly
	                      ></v-select>
	                    </v-col>

	                    <v-col cols="12">
	                      <v-textarea
	                        v-model="notas"
	                        dense
	                        rows="3"
	                        label="comentarios"
	                        outlined
	                        hide-details
	                      ></v-textarea>
	                    </v-col>

	                    <v-col cols="12">
	                      <v-btn 
	                        color="primary" 
	                        block 
	                        class="text-capitalize"
	                        @click="updatePerfil()"
	                      >
	                        Actualizar Perfil
	                      </v-btn>
	                    </v-col>
	                  </v-row>
	                </v-tab-item>

	                <!-- Tab de tareas programados -->
	                <v-tab-item class="pt-0">
	                  <v-row>
	                    <v-col cols="12">
	                      <v-card class="elevation-0">
	                        <v-card-title primary-title>
	                          <v-spacer></v-spacer>
	                          <v-btn color="secondary" fab small @click="opcionAddTarea = !opcionAddTarea, banderaTarea = 0,disableBtnTarea = false" v-if="tareas_programadas.length == 0">
	                            <v-icon v-if="!opcionAddTarea">mdi-plus</v-icon>
	                            <v-icon v-else>mdi-window-minimize</v-icon>
	                          </v-btn>
	                        </v-card-title>
	                        <v-card-text v-if="opcionAddTarea">
	                          <v-row>
			                        <v-col cols="12" lg="4">
			                          <v-select
			                            :items="tareas"
			                            v-model="tarea"
			                            label="Tarea"
			                            dense
			                            hide-details
			                            item-value="idtareas"
			                            item-text="tarea"
			                            return-object
			                          ></v-select>
			                        </v-col>

			                        <v-col cols="12"  md="6" lg="4">
			                          <v-text-field
			                            v-model="dia"
			                            label="Día"
			                            dense
			                            type="date"
			                            hide-details
			                          ></v-text-field>
			                        </v-col>

			                        <v-col cols="12" md="6" lg="4">
			                          <v-select
			                            :items="[{id: 1, tipo:'Agendada'},{id: 2, tipo:'Nota'}]"
			                            v-model="tipo_tarea"
			                            label="Tipo de tarea"
			                            dense
			                            hide-details
			                            item-value="id"
			                            item-text="tipo"
			                            return-object
			                          ></v-select>
			                        </v-col>

			                        <v-col cols="12"  md="6" lg="4" v-if="tipo_tarea && tipo_tarea.id == 2">
			                          <v-select
			                            :items="horaPrecargada"
			                            v-model="horaCargada"
			                            label="Hora"
			                            dense
			                            hide-details
			                            item-value="id"
			                            item-text="hora"
			                            return-object
			                            clearable
			                          ></v-select>
			                        </v-col>

			                        <v-col cols="12"  md="6" lg="4" v-if="horaCargada && horaCargada.id == 5 || tipo_tarea && tipo_tarea.id == 1">
			                          <v-text-field
			                            v-model="hora"
			                            label="Hora"
			                            dense
			                            type="time"
			                            hide-details
			                          ></v-text-field>
			                        </v-col>

			                        <v-col cols="12" md="6" lg="4">
			                          <v-select
			                            :items="motivos"
			                            v-model="motivo"
			                            label="Motivo"
			                            dense
			                            hide-details
			                            item-value="idmotivos"
			                            item-text="motivo"
			                            return-object
			                          ></v-select>
			                        </v-col>

			                        <v-col cols="12" md="6" lg="4" v-if="motivo && motivo.motivo == 'Otro'">
			                          <v-text-field
			                            v-model="descrip_motivo"
			                            label="Descripción"
			                            dense
			                            hide-details
			                          ></v-text-field>
			                        </v-col>
			                        
			                        <v-col cols="12">
			                          <!-- :disabled="disableBtnTarea" -->
			                          <v-btn color="primary" block @click="programarTarea()"   v-if="banderaTarea == 0 && motivo && tipo_tarea">Promagar tarea</v-btn>
			                          <v-btn color="primary" block @click="reprogramarTarea()" v-if="banderaTarea == 1">Reprogramar tarea</v-btn>
			                        </v-col>
			                      </v-row>
	                        </v-card-text>

	                        <v-card-text>
	                          <div v-for="(tarea, i) in tareas_programadas" :key="i">
	                            <div class="text-body-1">
	                              <v-icon :color="tarea.cumplida == 1 ? 'red' : 'green'">mdi mdi-circle-medium theme--light</v-icon>
	                              {{ tarea.motivo }}<br/>
                              	<span v-if="tarea.idmotivos == 7">{{ tarea.descrip_motivo }}</span>
	                            </div>
	                            <div class="text-caption">{{ tarea.fecha + ' ' + tarea.tiempo }}</div>

	                            <v-chip-group
	                              column
	                            >
	                              <!-- Copiar el telefono -->
	                              <v-tooltip
	                                v-if="tarea.tarea_tipo == 1"
	                                top
	                              >
	                                <template v-slot:activator="{ on, attrs }">
	                                  <v-chip color="primary"
	                                    dark
	                                    v-bind="attrs"
	                                    v-on="on"
	                                    @click="dialogTareasProgramadas = false, intentoLlamada = 1, dialogSeguimiento = true"
	                                    rounded
	                                    small><v-icon>mdi-phone</v-icon></v-chip>
	                                  </v-btn>
	                                </template>
	                                <span>Click para iniciar</span>
	                              </v-tooltip>

	                              <!-- Mensaje de whatsapp -->
	                              <v-tooltip
	                                v-if="tarea.tarea_tipo == 2"
	                                top
	                              >
	                                <template v-slot:activator="{ on, attrs }">
	                                  <v-chip color="primary"
	                                    dark
	                                    v-bind="attrs"
	                                    v-on="on"
	                                    @click="dialogTareasProgramadas = false, intentoLlamada = 3, dialogSeguimiento = true"  
	                                    rounded
	                                    small><v-icon>mdi-whatsapp</v-icon></v-chip>
	                                  </v-btn>
	                                </template>
	                                <span>Click para iniciar</span>
	                              </v-tooltip>

	                              <!-- Eliminar -->
	                              <v-tooltip
	                                top
	                              >
	                                <template v-slot:activator="{ on, attrs }">
	                                  <v-chip color="error"
	                                    dark
	                                    v-bind="attrs"
	                                    v-on="on"
	                                    @click="eliminarTarea(tarea.idtareas_prospectos)" 
	                                    rounded
	                                    small><v-icon>mdi-delete</v-icon></v-chip>
	                                  </v-btn>
	                                </template>
	                                <span>Eliminar tarea</span>
	                              </v-tooltip>

	                              <!-- Reprogramar -->
	                              <v-tooltip
	                                top
	                              >
	                                <template v-slot:activator="{ on, attrs }">
	                                  <v-chip color="warning"
	                                    dark
	                                    v-bind="attrs"
	                                    v-on="on"
	                                    @click="actualizarTarea(tarea),disableBtnTarea = false" 
	                                    rounded
	                                    small><v-icon>mdi-refresh</v-icon></v-chip>
	                                  </v-btn>
	                                </template>
	                                <span>Reprogramar</span>
	                              </v-tooltip>

	                            </v-chip-group>
	                            <v-divider></v-divider>
	                          </div>

	                          <v-subheader>Tareas realizadas</v-subheader>
	                          <div v-for="tarea in tareas_realizadas">
	                            <div class="text-body-1">
	                              <v-icon :color="tarea.cumplida == 1 ? 'green' : 'green'">mdi mdi-circle-medium theme--light</v-icon>
	                              {{ tarea.motivo }}<br/>
	                              <span v-if="tarea.idmotivos == 7">{{ tarea.descrip_motivo }}</span>
	                            </div>
	                            <div class="text-caption">{{ tarea.fecha + ' ' + tarea.tiempo }}</div>
	                            <v-divider></v-divider>
	                          </div>
	                        </v-card-text>
	                      </v-card>
	                    </v-col>
	                  </v-row>
	                </v-tab-item>

	                <!-- Tab de llamadas -->
	                <v-tab-item class="pt-0">
	                  <v-row>
	                    <v-col v-if="llamadasList.length > 0">
	                      <div class="head"
	                        v-for="(llamada, i) in llamadasList"
	                        :key="i"
	                      >
	                        <small>
	                          <strong class="user">
	                            {{ llamada.nombre_completo }}
	                          </strong>
	                            <v-icon small left>mdi-clock</v-icon>{{ llamada.duracion }}
	                        </small>
	                        <div 
	                          class="head"
	                          v-for="(notaLlamada, j) in llamada.notas"
	                          :key="j"
	                        >
	                          <p >
	                            {{ notaLlamada.nota }}
	                          </p>
	                        </div>
	                        <hr />
	                      </div>
	                    </v-col>
	                  </v-row>
	                </v-tab-item>
	              </v-tabs>
	            </v-col>
	          </v-row>
	        </v-card-text>

	        <!-- Boton para agregar mensajes al seguimiento -->
	        <v-btn
	          @click="abrirDialogNotaLlamada()"
	          fixed
	          bottom
	          right
	          fab
	          dark
	          color="primary"
	          style="margin-bottom: 110px;"
	          v-if="estatusLlamada"
	        >
	          <v-icon >mdi-message</v-icon>
	        </v-btn>

	        <!-- Boton de seguimiento -->
	        <v-btn
	          @click="iniciarSeguimiento(), dialoguNevaTarea = false"
	          fixed
	          bottom
	          right
	          dark
	          :color="getEstatusLlamada == 1 ? 'red' : 'green' "
	          v-if="!estatusLlamada"
	          class="mb-16"
	        >
	          <span class="text-capitalize" >Iniciar</span>
	        </v-btn>

	        <!-- Finalizar seguimiento -->
	        <v-btn
	          v-else
	          @click="finalizarLlamada()"
	          fixed
	          bottom
	          right
	          dark
	          :color="getEstatusLlamada == 1 ? 'red' : 'green' "
	          class="mb-16"
	        >
	          <span class="text-capitalize" >Finalizar</span>
	        </v-btn>

	        <!-- Boton para poder ver el tiempo transcurrido -->
	        <v-btn
	          @click="abrirDialogNotaLlamada()"
	          fixed
	          top
	          right
	          fab
	          dark
	          :color="alertaTiempo ? 'red' : 'primary' "
	          style="margin-top: 100px;"
	          v-if="showTimer"
	        >
	          {{ tiempo }}
	        </v-btn>
	      </v-card>
  		</v-col>
  	</v-row>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="" style="display: table-cell; vertical-align: middle;">
		      				<strong>¡Bien!</strong> Datos grabados correctamente
			      			<v-card-actions class="pt-6">
			      				<v-spacer></v-spacer>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="green" dark class="elevation-6" large @click="dialogSuccess = false">Ok</v-btn>
			      				<v-spacer></v-spacer>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/correcto.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-text>
          <v-row justify="space-between">
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div style="display: table-cell; vertical-align: middle;">
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar la información?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text large class="mr-2 text-capitalize" @click="dialogDelete = false">No, cancelar</v-btn>
                    <!-- Guardar la información  -->
                    <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="deleteItemConfirm()">Sí</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-img src="@/assets/borrar.png" ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="300"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      persistent
      max-width="400"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="" style="display: table-cell; vertical-align: middle;">
		      				<span v-if="errorBack">
		      					<strong>ERROR</strong> Da clic en "COPIAR" para guardar el error y enviarlo a sistemas
		      				</span>
		      				<span v-else>
		      					<strong>ERROR</strong> {{error}}
		      				</span>
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text large class="mr-2" @click="dialogError = false">Cerrar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="copiarPortapapeles()" v-if="errorBack">{{textoCopiado}}</v-btn>
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="dialogError = false" v-else>Ok</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/eliminar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de seguimiento dialogSeguimiento -->
    <v-dialog
      v-model="dialogSeguimiento"
      persistent
      max-width="800"
    >
      <v-card class="pt-4">
        <v-card-text>
          <v-row justify="space-between">

            <v-col cols="12" sm="4" lg="5">
              <v-card class="elevation-0">
                <v-img src="@/assets/primerContacto.png">
                </v-img>
              </v-card>
            </v-col>

            <v-col cols="12" sm="8" lg="6">
              <v-card class="elevation-0 transparent" height="100%" style="display: table;" align="center">
                <div style="display: table-cell; vertical-align: middle; width: 100%;">
                  <div class="text-subtitle-1">
                    <strong class="text-h6" v-if="intentoLlamada == 0">Empecemos....</strong><br/> 
                    <br/>
                    <!-- Aquí validamos si la segunda llamada y el whatsapp están des habilidatos -->
                    <strong class="text-h6" v-if="intentoLlamada == 1">Realizar llamada por teléfono</strong>
                    <!-- Aquí validamos si la primer llamada  y el whatsapp están des habilidatos -->
                    <strong class="text-h6" v-if="intentoLlamada == 2">Realizar llamada por whatsApp</strong>
                    <!-- Aquí validamos si la primer llamada  y el whatsapp están des habilidatos -->
                    <strong class="text-h6" v-if="intentoLlamada == 3">Por favor, envía un mensaje de WhatsApp</strong>
                  </div>

                  <!-- Botones iniciales de primer llamada -->
                  <v-card-actions class="pt-6" v-if="intentoLlamada == 0">
                    <v-spacer></v-spacer>
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" dar large class="mr-2 text-capitalize" @click="intentoLlamada++">Iniciar Llamada</v-btn>
                    <v-spacer></v-spacer>
                    <!-- Guardar la información  -->
                    <v-tooltip
                      v-model="showTooltipTelefono"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="copiarTelefono(prospecto.telefono), showTooltipTelefono = !showTooltipTelefono">Copiar teléfono</v-btn>
                      </template>
                      <span>Teléfono copiado</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                  </v-card-actions>

                  <!-- si el intento de llamada es 1 o 2, se puede visualizar esta sección -->
                  <span v-if="intentoLlamada == 1 || intentoLlamada == 2" class="pt-6">
                    <br/>
                    <!-- Este titulo solo debe aparecer en las primeras dos llamadas -->
                    <div class="text-title" v-if="intentoLlamada < 3"><strong>¿Contestó y esta disponible para atenderte?</strong></div>
                    <!-- Este también solo debe aparecer en las primeras dos llamadas -->
                    <v-card-actions  class="pt-6" v-if="intentoLlamada < 3">
                      <v-spacer></v-spacer>
                      <!-- Si es si, cerramos este dialogo y abrimos el dialogo de info del usuario -->
                      <v-btn
                        color="error"
                        @click="dialogSeguimiento = false, updateEstatusLlamada(), addIntentoLlamada(11)"
                        class="text-capitalize"
                        v-if="intentoLlamada < 3 && intentoLlamada == 1"
                      >
                        Si
                      </v-btn>

                      <v-btn
                        color="error"
                        @click="dialogSeguimiento = false, updateEstatusLlamada(), addIntentoLlamada(12)"
                        class="text-capitalize"
                        v-if="intentoLlamada < 3 && intentoLlamada == 2"
                      >
                        Si
                      </v-btn>


                      <v-spacer></v-spacer>

                      <!-- No contesto llamada, llamar por whatsApp -->
                      <v-btn
                        color="#5C5C5C"
                        dark
                        @click="intentoLlamada++" 
                        v-if="intentoLlamada == 1"
                        class="text-capitalize"
                      >
                        No
                      </v-btn>

                      <!-- No contesto por Llamada de WhatsApp, mandar mensaje de WhatsApp -->
                      <v-btn
                        color="#5C5C5C"
                        dark
                        @click="intentoLlamada++"
                        v-if="intentoLlamada == 2"
                        class="text-capitalize"
                      >
                        No
                      </v-btn>

                      <v-spacer></v-spacer>
                    </v-card-actions>
                    
                    <!-- Boton para copiar el teléfono, solo estará activo si es primerLlamada o segunda -->
                    <v-card-actions v-if="intentoLlamada > 0">
                      <!-- Guardar la información  -->
                      <v-tooltip
                        v-model="showTooltipTelefono"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn block color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="copiarTelefono(prospecto.telefono), showTooltipTelefono = !showTooltipTelefono">Copiar teléfono</v-btn>
                        </template>
                        <span>Teléfono copiado</span>
                      </v-tooltip>
                    </v-card-actions>
                  </span>

                  <!-- Mensaje de whatsApp -->
                  <span v-if="intentoLlamada == 3">
                    <!-- Abrir campo para whatsapp -->
                    <v-card-text>
                      <v-textarea
                        v-model="mensajeWhatsapp"
                        outlined
                        dense
                        rows="3"
                        hide-details
                        label="Escribir mensaje"
                      >
                      </v-textarea>
                    </v-card-text>

                    <!-- Botondes de WhatsApp -->
                    <v-card-actions v-if="mensajeWhatsapp != ''">
                      <v-spacer></v-spacer>
                      <!-- Enviar mensaje -->
                      <v-btn color="primary" 
                        @click="enviarWhatsApp()" 
                      >Enviar</v-btn>
                    </v-card-actions>
                  </span>

                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-btn 
          absolute
          fab
          text
          small
          top
          right
          class="mt-6"
          v-if="intentoLlamada < 3"
          @click="dialogSeguimiento = false, intentoLlamada = 0"
        ><v-icon>mdi-close</v-icon></v-btn>

        <v-btn 
          absolute
          fab
          text
          small
          top
          left
          class="mt-6"
          v-if="intentoLlamada > 0"
          @click="intentoLlamada--"
        ><v-icon>mdi-arrow-left-bold</v-icon></v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialog para tareas programadas -->
    <v-dialog
      v-model="dialogTareasProgramadas"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="text-h5">
          <span>Tareas pendientes</span>
        </v-card-title>
        <!-- Prospecto con pendientes -->
        <v-card-text>
          <div v-for="(tarea, i) in tareas_programadas" :key="i">
            <div class="text-body-1">
              <v-icon :color="tarea.cumplida == 1 ? 'red' : 'green'">mdi mdi-circle-medium theme--light</v-icon>
              {{ tarea.motivo }}
              <br/>
							<span v-if="tarea.idmotivos == 7">{{ tarea.descrip_motivo }}</span>
            </div>
            <div class="text-caption">{{ tarea.fecha + ' ' + tarea.tiempo }}</div>

            <!-- Tareas programadas -->
            <v-chip-group column>

              <!-- Iniciar llamada -->
              <v-tooltip v-if="tarea.tarea_tipo == 1" top>
                <!-- Este es llamada por teléfono, entonces, lo que haremos es abrir el dialogo de seguimiento, cerrar este dialogo yyyy abrirlo directamente en llamada -->
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogTareasProgramadas = false, intentoLlamada = 1, dialogSeguimiento = true"  
                    rounded
                    small><v-icon>mdi-phone</v-icon>
                  </v-chip>
                </template>
                <span>Click para inciar</span>
              </v-tooltip>

              <!-- Mensaje de whatsapp -->
              <v-tooltip v-if="tarea.tarea_tipo == 2" top>
                <!-- Este es llamada por teléfono, entonces, lo que haremos es abrir el dialogo de seguimiento, cerrar este dialogo yyyy abrirlo directamente en whatsApp -->
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="green"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogTareasProgramadas = false, intentoLlamada = 3, dialogSeguimiento = true"  
                    rounded
                    small><v-icon>mdi-whatsapp</v-icon>
                  </v-chip>
                </template>
                <span>Enviar mensaje</span>
              </v-tooltip>

              <!-- Eliminar -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="error"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="eliminarTarea(tarea.idtareas_prospectos)" 
                    rounded
                    small><v-icon>mdi-delete</v-icon>
                  </v-chip>
                </template>
                <span>Eliminar tarea</span>
              </v-tooltip>

              <!-- Reprogramar -->
              <v-tooltip top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="warning"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="actualizarTarea(tarea),disableBtnTarea = false" 
                    rounded
                    small><v-icon>mdi-refresh</v-icon>
                  </v-chip>
                </template>
                <span>Reprogramar</span>
              </v-tooltip>
            </v-chip-group>
          </div>
        </v-card-text>

        <v-btn 
          absolute
          fab
          text
          small
          top
          right
          class="mt-6"
          @click="dialogTareasProgramadas = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

      </v-card>
    </v-dialog>

    <!-- Dialog para agregar notas a la llamada -->
    <v-dialog
      v-model="dialogNotaLlamada"
      hide-overlay
      persistent
      max-width="800"
      max-height="450"
    >
      <v-card>
        <v-card-title primary-title >
          Notas de la llamada
        </v-card-title>
        <v-card-text style="overflow-y: scroll;position: relative; max-height: 350px;" id="notas">
          <span v-for="(mensaje, i) in notasLlamada">
            <v-row v-if="mensaje.idusuarioerp != getdatosUsuario.iderp">
              <v-col cols="12">
                <v-card class="ml-6 pa-3 mt-2" max-width="400" color="#f2f2f2" style="border-radius: 15px; border-top-left-radius: 0">
                  <div class="text-subtitle-1">{{ mensaje.nota }} </div>
                  <v-card-actions class="pa-0">
                    <div class="text-caption blue--text">{{ mensaje.nombre_completo }}</div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="mensaje.idusuarioerp == getdatosUsuario.iderp">
              <v-col cols="12" align="right">
                <v-card class="mr-6 mt-2 pa-3" max-width="400" dark color="#0465ac" shaped style="border-radius: 15px; border-top-right-radius: 0;">
                  <div class="text-subtitle-1">{{ mensaje.nota }}</div>
                </v-card>
              </v-col>
            </v-row>
          </span>
        </v-card-text>

        <v-card-text class="pb-0 pt-6">
          <v-text-field
            v-model="notaLlamada"
            :append-outer-icon="notaLlamada ? 'mdi-send' : ''"
            outlined
            clearable
            label="Message"
            type="text"
            @click:append-outer="grabarNotaLlamada()"
            hide-details
            dense
            class="pb-4"
          ></v-text-field>
        </v-card-text>
        
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="notaLlamada='', dialogNotaLlamada = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialog para reasignar un prospecto -->
    <v-dialog
      v-model="dialogReasignar"
      hide-overlay
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title primary-title >
          Reasignar prospecto
        </v-card-title>
        <v-card-text >
          <v-select
            :items="filterVendedoras"
            v-model="nuevaSucursal"
            return-object
            item-text="plantel"
            item-value="idusuario"
            dense
            :hint="nombreVendedora"
            outlined
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error"   dark @click="cancelarReasignacion()">Cancelar</v-btn>
          <v-btn color="primary" dark @click="reasignar()">Reasignar</v-btn>
        </v-card-actions>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogReasignar = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialog de Finalización -->
    <v-dialog
      v-model="dialogFinalizacion"
      hide-overlay
      persistent
      max-width="800"
    >
      <v-card class="pt-4">
        <v-card-text>
          <v-row justify="space-between" v-if="!banderaFinalizo && !banderaRechazo">
            <v-col cols="12" lg="6">
              <v-img src="@/assets/finalizo.png">
              </v-img>
            </v-col>
            <v-col cols="12" lg="6">
              <v-card class="elevation-0" height="100%" style="display: table;" align="center">
                <div style="display: table-cell; vertical-align: middle;">
                  <div class="text-subtitle-1"><strong class="text-h6">Que no cunda el pánico</strong><br/> ¿El prospecto se registró en algún grupo?</div>
                  <v-card-actions class="pt-6">
                  	<v-spacer></v-spacer>
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text large class="mr-2 text-capitalize" @click="banderaRechazo = true">No, finalizar</v-btn>
                  	<v-spacer></v-spacer>
                    <!-- Guardar la información  -->
                    <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="banderaFinalizo = true">Sí</v-btn>
                  	<v-spacer></v-spacer>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <!-- Finalizar, todo Ok -->
          <v-card-text v-if="banderaFinalizo">
          	<div class="text-body-1 mb-2" align="center">Ingresa los datos del alumno </div>
            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field
                  label="Matricula"
                  dense
                  outlined
                  v-model="matricula"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="6">
                <v-autocomplete
                  :items="grupos"
                  v-model="grupo"
                  label="Grupo"
                  dense
                  outlined
                  hide-details
                  item-value="idgrupo"
                  item-text="grupo"
                  return-object
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" lg="6">
                <v-text-field
                  label="Código de la clase"
                  dense
                  outlined
                  v-model="codigo_clase"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="6">
                <v-text-field
                  label="Contraseña"
                  dense
                  outlined
                  v-model="contra"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="banderaFinalizo">
          	<v-spacer></v-spacer>
            <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
            <v-btn color="error" text large class="mr-2 text-capitalize" @click="banderaFinalizo = false">Cancelar</v-btn>
            <!-- Guardar la información  -->
            <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="finalizarProspecto()">Guardar</v-btn>
          </v-card-actions>

          <!-- Rechazar con motivo -->
          <v-card-text v-if="banderaRechazo">
          	<div class="text-body-1 mb-2" align="center">Ingresa los datos del alumno </div>
            <v-textarea
		    		  v-model="notaRechazo"
		    		  label="Motivo"
		    		  dense
		    		  outlined
		    		  hide-details
		    		></v-textarea>
          </v-card-text>
          <v-card-actions v-if="banderaRechazo">
          	<v-spacer></v-spacer>
            <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
            <v-btn color="error" text large class="mr-2 text-capitalize" @click="banderaRechazo = false">Cancelar</v-btn>
            <!-- Guardar la información  -->
            <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="rechazoProspecto()">Guardar</v-btn>
          </v-card-actions>
        </v-card-text>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogFinalizacion = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialgo whatsapp dialogWhatsapp -->
    <v-dialog
      v-model="dialogWhatsapp"
      hide-overlay
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title primary-title>
          Mandar mensaje de whatsApp
        </v-card-title>
        <v-card-text>
        <v-textarea
          v-model="mensajeWhatsapp"
          outlined
          dense
          rows="3"
          hide-details
          label="Escribir mensaje"
        >
        </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogWhatsapp = false">Cancelar</v-btn>
          <v-btn color="primary"   @click="dialogWhatsapp = false, dialogSeguimiento = false, enviarWhatsApp(), dialoguNevaTarea = true" v-if="mensajeWhatsapp != ''">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog info del usuario dialogInfoUsuario -->
    <v-dialog
      v-model="dialogInfoUsuario"
      persistent
      max-width="800"
    >
      <v-card class="pt-4">
        <v-card-text>
          <v-row justify="space-between">
            <v-col cols="12">
              <v-stepper
                v-model="pasosInfoUsuario"
                vertical
                class="elevation-0"
              >
                <v-stepper-step
                  :complete="pasosInfoUsuario > 1"
                  step="1"
                >
                  Perfil
                  <small>Realiza las siguientes preguntas</small>
                </v-stepper-step>

                <v-stepper-content step="1">
                  <v-card class="mb-12 elevation-0">
                    <v-row class="mt-4">
                      <!-- Pregunta #1 -->
                      <v-col cols="12">
                        <div>¿El curso es para ti, o para alguien más?</div>
                        <v-radio-group 
                          v-model="paraquien_el_curso" 
                          row
                          hide-details
                          class="mt-0"
                        >
                          <v-radio :value="1" row>
                            <template v-slot:label>
                              <div>Para mi</div>
                            </template>
                          </v-radio>

                          <v-radio :value="0">
                            <template v-slot:label>
                              <div>Alguién más</div> 
                            </template>
                          </v-radio>

                          <v-radio :value="2">
                            <template v-slot:label>
                              <div>Hermanos</div> 
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-col>

                      <!-- Agregar familiares -->
                      <v-col cols="12" v-if="showFamiliares">
                        <div><v-btn color="primary" class="text-capitalize" @click="addPersonas = true">Agregar personas</v-btn></div>
                        <div v-if="addPersonas" class="pt-6">
                          <v-card>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12" md="6" lg="7">
                                  <v-text-field
                                    label="Nombre completo"
                                    dense
                                    v-model="familiar"
                                    outlined
                                    hide-details
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="5" lg="4">
                                  <v-text-field
                                    label="Edad"
                                    dense
                                    v-model="edadFamiliar"
                                    outlined
                                    hide-details
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                  <v-text-field
                                    label="Correo"
                                    dense
                                    v-model="correoFamiliar"
                                    outlined
                                    hide-details
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                  <v-text-field
                                    label="Teléfono"
                                    dense
                                    v-model="telefonoFamiliar"
                                    outlined
                                    hide-details
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="success" fab small @click="addFamiliar()"><v-icon>mdi-check</v-icon></v-btn>
                            </v-card-actions>
                          </v-card>
                        </div>
                        <div class="pt-2"><small>Persoas añadidas</small></div>
                        <div v-if="familiares.length">
                          <v-data-table
                            :headers="headersFamiliares"
                            :items="familiares"
                            class="elevation-1"
                            fixed-header
                            hide-default-footer
                            :items-per-page="familiares.length"
                          >
                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                small
                                @click="deletFamiliar(item)"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                        </div>
                      </v-col>

                      <!-- Pregunta #2 -->
                      <v-col cols="12">
                        <div>¿Anteriormente ha estudiado inglés en alguna otra escuela o colegio?</div>
                        <v-radio-group 
                          v-model="estudioIngles" 
                          row
                          hide-details
                          class="mt-0"
                        >
                          <v-radio :value="1" row>
                            <template v-slot:label>
                              <div>Si</div>
                            </template>
                          </v-radio>
                          <v-radio :value="0">
                            <template v-slot:label>
                              <div>No</div> 
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-col>

                      <!-- Pregunta #3 -->
                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="['Acádemico','Trabajo','Ocio']"
                          v-model="porqueEstudiarIngles"
                          label="¿Por qué estudiar inglés?"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <!-- pregunta #4 -->
                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="['Mañana','Tarde','Noche']"
                          v-model="dispoHorario"
                          label="Disponibilidad horario"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <!-- pregunta #5 -->
                      <v-col cols="12" sm="6" lg="4">
                        <v-text-field
                          v-model="edad"
                          dense
                          hide-details
                          type="number"
                          label="Edad"
                          clearable
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="filterSucursales"
                          v-model="sucursal"
                          return-object
                          item-text="plantel"
                          item-value="idplantel"
                          label="Sucursal"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="anuncios"
                          v-model="anuncio"
                          return-object
                          item-text="anuncio"
                          item-value="idanuncios"
                          label="¿Cómo nos conocio?"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="cursos"
                          v-model="curso"
                          return-object
                          item-text="curso"
                          item-value="idcursos_escuela"
                          label="Curso"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="modalidadesEscuela"
                          v-model="modalidad"
                          return-object
                          item-text="modalidad"
                          item-value="idmodalidad_cursos"
                          label="Modalidad"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="frecuenciasEscuela"
                          v-model="frecuencia"
                          return-object
                          item-text="frecuencia"
                          item-value="idfrecuencias"
                          label="Frecuencia"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="ciclos_plantel"
                          v-model="ciclo"
                          return-object
                          item-text="ciclo"
                          item-value="id_ciclo"
                          label="Ciclo interes"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          disabled
                          :items="vendedoras_plantel"
                          v-model="vendedora"
                          return-object
                          item-text="nombre"
                          item-value="iderp"
                          label="Vendedora"
                          hide-details
                          dense
                          readonly
                        ></v-select>
                      </v-col>

                      <v-col cols="12">
                        <v-textarea
                          v-model="notas"
                          dense
                          rows="3"
                          label="comentarios"
                          outlined
                        ></v-textarea>
                      </v-col>

                      <v-col cols="12" class="ml-2">
                        <v-checkbox label="El prospecto no brindo información" v-model="prospectoNoDioInfo"></v-checkbox>
                      </v-col>

                      <!-- <v-col cols="12">
                        <v-btn 
                          color="primary" 
                          block 
                          class="text-capitalize"
                          @click="updatePerfil()"
                        >
                          Actualizar Perfil
                        </v-btn>
                      </v-col> -->
                    </v-row>

                    <v-card-actions class="pl-0 pt-4">
                      <v-btn
                        color="grey"
                        dark
                        class="text-capitalize"
                        @click="dialogInfoUsuario = false, dialogSeguimiento = true"
                      >
                        Cancelar
                      </v-btn>

                      <v-btn
                        color="red"
                        dark
                        class="text-capitalize"
                        @click="pasosInfoUsuario = 2,updatePerfil()"
                      >
                        Siguiente
                      </v-btn>
                    </v-card-actions>  
                  </v-card>
                </v-stepper-content>

                <v-stepper-step
                  :complete="pasosInfoUsuario > 2"
                  step="2"
                >
                  Información del curso
                </v-stepper-step>

                <v-stepper-content step="2">
                  <v-card class="mb-12 elevation-0" v-if="getEscuela == 2">
                    <div v-for="(puntos, i ) in puntosLlamada" :key="i" class="mt-4">
                      <!-- TITULOS --> 
                      <span ><strong>{{ puntos.titulo }}</strong></span>
                      <!-- Ahora ponemos los subtemas -->
                      <div 
                        v-for="(subtema, j) in puntos.subtemas"
                        :key="j"
                      >
                        <v-checkbox
                          v-model="subtema.check"
                          :label="subtema.titulo"
                          hide-details
                          class="ml-4"
                        >
                        </v-checkbox>
                        <div v-if="subtema.subsubtemas" v-for="(subsub, l ) in subtema.subsubtemas" :key="l" class="mt-2 ml-12">
                          <!-- TITULOS --> 
                          <span >{{ subsub.titulo }}</span>
                        </div>
                        
                      </div>
                    </div>
                    <v-card-actions class="mt-6">
                      <v-btn
                        color="red"
                        dark
                        @click="grabarInfoEscuela()"
                      >

                        Grabar información
                      </v-btn>
                      <v-btn text @click="pasosInfoUsuario = 1" class="ml-4">
                        Regresar
                      </v-btn>
                    </v-card-actions>
                  </v-card>

                  <v-card class="mb-12 elevation-0" v-else>
                    <v-radio-group
                      v-model="tipoInfoCurso"
                      row
                    >
                      <v-radio
                        label="Intensivo"
                        :value="1"
                      ></v-radio>
                      <v-radio
                        label="Online"
                        :value="2"
                      ></v-radio>
                      <v-radio
                        label="Teens"
                        :value="3"
                      ></v-radio>
                    </v-radio-group>

                    <div v-for="(puntos, i ) in puntosLlamada" :key="i" class="mt-4">
                      <!-- Titulos -->
                      <v-checkbox
                        v-model="puntos.check"
                        :label="puntos.titulo"
                        hide-details
                        class="text-body-1"
                      >
                      </v-checkbox>

                      <!-- Ahora ponemos los subtemas -->
                      <div 
                        v-for="(subtema, j) in puntos.subtemas"
                        :key="j"
                        class="ml-6"
                      >
                        <v-icon color="primary"> mdi mdi-circle-medium theme--light </v-icon>{{ subtema.titulo }}

                        <div v-if="subtema.subsubtemas" v-for="(subsub, l ) in subtema.subsubtemas" :key="l" class="mt-2 ml-8">
                          <span ><v-icon color="error"> mdi mdi-circle-medium theme--light </v-icon>{{ subsub.titulo }}</span>
                        </div>
                      </div>
                    </div>
                    <v-card-actions class="mt-6">
                      <v-btn
                        color="red"
                        dark
                        @click="grabarInfoEscuela()"
                      >

                        Grabar información
                      </v-btn>
                      <v-btn text @click="pasosInfoUsuario = 1" class="ml-4">
                        Regresar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

              </v-stepper>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de cierre -->
    <v-dialog
      v-model="dialogCierre"
      persistent
      max-width="800"
    >
      <v-card class="pt-4">
        <v-card-text>
          <v-row justify="space-between">
            <!-- Imagen -->
             <v-col cols="12" sm="4" md="4" lg="5">
              <v-card class="elevation-0">
                <v-img src="@/assets/notevayas.png">
                </v-img>
              </v-card>
            </v-col>

            <!-- Detalles -->
            <v-col cols="12" sm="8" md="7" lg="6">
              <v-card class="elevation-0 transparent" height="100%" style="display: table;" align="center">
                <div style="display: table-cell; vertical-align: middle; width: 100%;">
                  <div class="text-subtitle-1" v-if="opCierre == 0">
                    <strong class="text-h6">NO TE VAYASSSS</strong><br/> 
                    a olvidar de eligir una opción 🤪
                  </div>

                  <!-- opciones -->
                  <v-card-actions class="pt-6" v-if="opCierre == 0">
                    <v-spacer></v-spacer>
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" dar large class="mr-2 text-capitalize" @click="opCierre = 1, disableBtnTarea = false" >Nueva Tarea</v-btn>
                    <v-spacer></v-spacer>
                    <!-- Guardar la información  -->
                    <v-btn color="#5C5C5C" dark class="text-capitalize" large @click="opCierre = 2, getGrupos()">Finalizar prospecto</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>

                  <!-- Se agendará un atarea -->
                  <div v-if="opCierre == 1">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" lg="6">
                          <v-select
                            :items="tareas"
                            v-model="tarea"
                            label="Tarea"
                            dense
                            hide-details
                            item-value="idtareas"
                            item-text="tarea"
                            return-object
                          ></v-select>
                        </v-col>

                        <v-col cols="12"  md="6">
                          <v-text-field
                            v-model="dia"
                            label="Día"
                            dense
                            type="date"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-select
                            :items="[{id: 1, tipo:'Agendada'},{id: 2, tipo:'Nota'}]"
                            v-model="tipo_tarea"
                            label="Tipo de tarea"
                            dense
                            hide-details
                            item-value="id"
                            item-text="tipo"
                            return-object
                          ></v-select>
                        </v-col>

                        <v-col cols="12"  md="6" v-if="tipo_tarea && tipo_tarea.id == 2">
                          <v-select
                            :items="horaPrecargada"
                            v-model="horaCargada"
                            label="Hora"
                            dense
                            hide-details
                            item-value="id"
                            item-text="hora"
                            return-object
                            clearable
                          ></v-select>
                        </v-col>

                        <v-col cols="12"  md="6" v-if="horaCargada && horaCargada.id == 5 || tipo_tarea && tipo_tarea.id == 1">
                          <v-text-field
                            v-model="hora"
                            label="Hora"
                            dense
                            type="time"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-select
                            :items="motivos"
                            v-model="motivo"
                            label="Motivo"
                            dense
                            hide-details
                            item-value="idmotivos"
                            item-text="motivo"
                            return-object
                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="6" v-if="motivo && motivo.motivo == 'Otro'">
                          <v-text-field
                            v-model="descrip_motivo"
                            label="Descripción"
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        
                        <v-col cols="12">
                          <!-- :disabled="disableBtnTarea" -->
                          <v-btn color="primary" block @click="programarTarea()"   v-if="banderaTarea == 0 && motivo && tipo_tarea">Promagar tarea</v-btn>
                          <v-btn color="primary" block @click="reprogramarTarea()" v-if="banderaTarea == 1">Reprogramar tarea</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </div>

                  <!-- hay que ver si se registro o no a un grupo -->
                  <div v-if="opCierre == 2">
                    <!-- Validar que el prospecto haya finalizado correctamente -->
                    <div class="text-subtitle-1 mt-6" v-if="!banderaFinalizo && !banderaRechazo">¿El prospecto se registró en algún grupo?</div>
                    <!-- Botones de si, no, cancelar -->
                    <v-card-actions class="pt-6" v-if="!banderaFinalizo && !banderaRechazo">
                      <!-- Guardar la información  -->
                      <v-btn color="error" dark class="text-capitalize" @click="banderaFinalizo = true, getGrupos()">Sí</v-btn>
                      <v-spacer></v-spacer>
                      <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                      <v-btn color="error"   dark class="text-capitalize" @click="banderaRechazo = true" text>No, finalizar</v-btn>
                      <v-spacer></v-spacer>
                      <!-- Cancelar -->
                      <v-btn color="#5C5C5C"   dark class="text-capitalize" @click="opCierre = 0">Cancelar</v-btn>
                    </v-card-actions>

                    <!-- Finalizó y se inscribió -->
                    <div v-if="banderaFinalizo">
                      <v-card-text >
                        <div class="text-body-1 mb-2" align="center">Ingresa los datos del alumno </div>
                        <v-text-field
                          label="Matricula"
                          dense
                          outlined
                          v-model="matricula"
                        ></v-text-field>

                        <v-autocomplete
                          :items="grupos"
                          v-model="grupo"
                          label="Grupo"
                          dense
                          outlined
                          item-value="idgrupo"
                          item-text="grupo"
                          return-object
                        ></v-autocomplete>

                        <v-text-field
                          label="Código de la clase"
                          dense
                          outlined
                          v-model="codigo_clase"
                        ></v-text-field>

                        <v-text-field
                          label="Contraseña"
                          dense
                          outlined
                          v-model="contra"
                        ></v-text-field>
                      </v-card-text>

                      <v-card-actions >
                        <v-spacer></v-spacer>
                        <!-- Guardar la información  -->
                        <v-btn color="#5C5C5C" dark class="text-capitalize"  @click="finalizarProspecto()">Guardar</v-btn>
                        <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                        <v-btn color="error" class="text-capitalize" @click="banderaFinalizo = false, grupo = null, matricula = ''">Cancelar</v-btn>
                      </v-card-actions>
                    </div>

                    <!-- Rechazar con motivo -->
                    <div v-if="banderaRechazo">
                      <v-textarea
                        v-model="notaRechazo"
                        label="¿Por qué no se inscribió?"
                        dense
                        outlined
                        hide-details
                      ></v-textarea>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <!-- Guardar la información  -->
                        <v-btn color="#5C5C5C" dark class="text-capitalize"  @click="rechazoProspecto()">Guardar</v-btn>
                        <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                        <v-btn color="error" class="text-capitalize" @click="banderaRechazo = false, notaRechazo = ''">Cancelar</v-btn>
                      </v-card-actions>
                    </div>
                  </div>

                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-btn 
          absolute
          fab
          text
          small
          top
          right
          class="mt-6"
          @click="cerrarDialogCierre()"
        ><v-icon>mdi-close</v-icon></v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialog de Finalización del Familiar -->
    <v-dialog
      v-model="dialogFinalizarFamiliar"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card class="pt-4">
        <v-card-text>
          <v-row justify="space-between" v-if="!banderaFinalizo && !banderaRechazo">
            <v-col cols="12" lg="5">
              <v-img src="@/assets/finalizo.png">
              </v-img>
            </v-col>
            <v-col cols="12" lg="7">
              <v-card class="elevation-0" height="100%" style="display: table;" align="center">
                <div style="display: table-cell; vertical-align: middle;">
                  <div class="text-subtitle-1"><strong class="text-h6">Que no cunda el pánico</strong><br/> ¿El prospecto se registró en algún grupo?</div>
                  <v-card-actions class="pt-6">
                    <v-spacer></v-spacer>
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text large class="mr-2 text-capitalize" @click="banderaRechazo = true">No, finalizar</v-btn>
                    <v-spacer></v-spacer>
                    <!-- Guardar la información  -->
                    <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="banderaFinalizo = true">Sí</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <!-- Finalizar, todo Ok -->
          <v-card-text v-if="banderaFinalizo">
            <div class="text-body-1 mb-2" align="center">Ingresa los datos del alumno </div>
            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field
                  label="Matricula"
                  dense
                  outlined
                  v-model="matricula"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="6">
                <v-autocomplete
                  :items="grupos"
                  v-model="grupo"
                  label="Grupo"
                  dense
                  outlined
                  hide-details
                  item-value="idgrupo"
                  item-text="grupo"
                  return-object
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" lg="6">
                <v-text-field
                  label="Código de la clase"
                  dense
                  outlined
                  v-model="codigo_clase"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="6">
                <v-text-field
                  label="Contraseña"
                  dense
                  outlined
                  v-model="contra"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions v-if="banderaFinalizo">
            <v-spacer></v-spacer>
            <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
            <v-btn color="error" text large class="mr-2 text-capitalize" @click="banderaFinalizo = false">Cancelar</v-btn>
            <!-- Guardar la información  -->
            <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="finalizarProspectoFamiliar()">Guardar</v-btn>
          </v-card-actions>

          <!-- Rechazar con motivo -->
          <v-card-text v-if="banderaRechazo">
            <div class="text-body-1 mb-2" align="center">Ingresa los datos del alumno </div>
            <v-textarea
              v-model="notaRechazo"
              label="Motivo"
              dense
              outlined
              hide-details
            ></v-textarea>
          </v-card-text>
          <v-card-actions v-if="banderaRechazo">
            <v-spacer></v-spacer>
            <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
            <v-btn color="error" text large class="mr-2 text-capitalize" @click="banderaRechazo = false">Cancelar</v-btn>
            <!-- Guardar la información  -->
            <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="rechazoProspectoFamiliar()">Guardar</v-btn>
          </v-card-actions>
        </v-card-text>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogFinalizarFamiliar = false, familiarAFinalizar = null, banderaRechazo = false,banderaFinalizo = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import axios from 'axios';
	import { infoFast }        from '@/informativos/infoFastOnline'
  import { intensivoInbi }   from '@/informativos/intensivoInbi'
  import { onlineInbi }      from '@/informativos/onlineInbi'
  import { teensInbi }       from '@/informativos/teensInbi'
  var moment  = require('moment');
  moment.locale(); 
  import { mapGetters, mapActions } from 'vuex';
  export default {
    data: () => ({

    	// herramientas visuales
      cantNuevosProspectos:0,

      // Cuadros de dialogo
      dialogPreview:           false,
      dialogTareasProgramadas: false,
      dialogReasignar:         false,
      dialogFinalizacion:      false,
      dialogCierre:            false,
      dialogInfoUsuario:       false,
      dialogSeguimiento:       false,  // dialogo para iniciar seguimiento 
      dialogSuccess:           false,
      dialogProspecto:         false,
      dialogError:             false,
      dialogDelete:            false,
      dialoguNevaTarea:        false,
      dialogFinalizarFamiliar: false,

    	// Prospectos
      prospectos:     [], //arreglo
      prospecto:      null,
      intentoLlamada: 0,

      addPersonas:             false,
      familiar:                '',
      edadFamiliar:            0,
      correoFamiliar:          '',
      telefonoFamiliar:        '',
      headersFamiliares:[
        { text: 'Nombre',      value: 'familiar', },
        { text: 'Edad',        value: 'edad', },
        { text: 'Correo',      value: 'correo', },
        { text: 'Teléfono',    value: 'telefono', },
        { text: 'Actions',     value: 'actions', sortable: false },
      ],
      familiares:              [],
      familiarAFinalizar:     null,

      /*
        1-. Atrasado
        2-. Nuevo
        3-. Inducción
        4-. Seg. con Info
        5-. Seg. sin Info
      */
      idetapa:    null, 
      opCierre: 0,
      
      // 
      sucursales:[],
      sucursal:null,
      anuncios:[],
      anuncio:null,
      cursos:[],
      curso:null,
      ciclos:[],
      ciclos_plantel:[],
      ciclo:null,
      vendedoras:[],
      vendedoras_plantel:[],
      vendedora:null,
      tareas:[],
      tarea:null,
      campanias:[],
      campania:null,
      motivos:[],
      motivo:null,
      descrip_motivo:'',

      // Alertas 
      overlay: false,
      error:        '',
      successMensaje:'',
      errorBack:false,
      textoCopiado:'COPIAR',
      search:'',

      // Estatus de la llamada
      estatusLlamada: false,

      // Programar tarea
      opcionAddTarea: false,
      dia:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      hora:null,
      horaPrecargada:[
        {id: 1, hora:'1 hora'},
        {id: 2, hora:'2 horas'},
        {id: 3, hora:'4 horas'},
        {id: 4, hora:'24 horas'},
        {id: 5, hora:'Otra'},
      ],
      horaCargada:null,
      nota:null,

      tareas_programadas:[],

      tipoDelete: null,
      notaAnterior:'',
      idtareas_prospectos: null,
      eventos:[],
      fechaini:null,
      fechafin:null,
      banderaTarea: 0, // sirve para saber si se esta actualizando la tarea, o agregando una nueva

      // Preguntas
      paraquien_el_curso:null, // Para quien es el curso
      estudioIngles:null, // Estudió inglés
      porqueEstudiarIngles:'Trabajo', // Para que quieres estudiar inglés
      dispoHorario:null, // disponibilidad de horario
      edad:null, //  edad


      dialogNotaLlamada: false,
      notaLlamada:'',
      notasLlamada:[],
      llamadasList:[],
      idestatus_llamada: null,
      
      primerContacto:false,
      nuevaSucursal: null, 

      nombreVendedora:'',

      banderaFinalizo:false,
      matricula:'',
      grupo:null,
      grupos:[],

      banderaRechazo: false,
      notaRechazo:'',
      dialogWhatsapp:false,
      mensajeWhatsapp:'',

      cantProspectosPrimeraEtapa:0,

      prospectoFinalizo: false,
      tareaLlamada:      false,
      
      showTooltipTelefono:   false,
      pasosInfoUsuario: 1,
      puntosLlamada: [],

      modalidades:[],
      modalidad:null,
      frecuencias:[],
      frecuencia: null,
      modalidadesEscuela:[],
      frecuenciasEscuela:[],

      tipoInfoCurso: 0,
      
      notas:'',

      datosLlamada: null,
      newComentario:'',
      comentarios:[],

      eventosTimeline:[],
			countWhatsApp:0,
			mostrarEventos:false,
			tipo_tarea:null,

			disableBtnTarea: false,
      codigo_clase:'',
      contra:'',

      tiempo:'00:00',
      sec: 0,
      min: 0,
      hrs: 0,
      t: null,
      tiempoTotalSegundos:0,
      alertaTiempo: false,
      conteoAlertaTiempo: 0,
      showTimer: false,
      showFamiliares: false,
      medio_informacion: 0,
      tareas_realizadas:[],
      prospectoNoDioInfo:0,
    }),

		setup(){

		},

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','getProspecto','getEstatusLlamada']),

      formTitle() { 
      	return this.editedIndex === -1 ? 'Agregar un prospecto' : 'Editar prospecto'
      },

      titulo (){
      	return this.getEscuela == 1 ? 'Prospectos INBI' : 'Prospectos FAST'
      },

      filterProspectos(){
      	if(this.prospectos){
          return this.prospectos.filter((prospecto)=>{
            var est = prospecto.idetapa;
            if(this.getEscuela == prospecto.escuela){
	            if(this.estatusFiltro == this.etapas[this.etapas.length - 1].idetapas){
	              return true
	            }else{
	              if(est == this.estatusFiltro){return true}
	            }
            }
          })
        }
      },

      filterSucursales(){
      	if(this.sucursales){
          return this.sucursales.filter( el=> { return el.escuela == this.getEscuela})
        }
      },

      filterVendedoras(){
        return this.vendedoras.filter( el=> { return el.escuela == this.getEscuela})
      }
    },

    watch: {
      nuevaSucursal(){
    		this.nombreVendedora = this.nuevaSucursal.nombre
    	},

      async getProspecto (){
      	this.prospecto = this.getProspecto
      	// if(this.getEstatusLlamada){
      	// 	await this.getInfoLlamada()
      	// }
      	await this.limpiar()
      	await this.verProspecto(this.prospecto)
      },

      curso(val){
        this.modalidadesEscuela = this.modalidades.filter( el => {
          return el.idcursos_escuela == val.idcursos_escuela
        })

      },

      modalidad(val){
        this.frecuenciasEscuela = this.frecuencias.filter( el => {
          return el.idmodalidad_cursos == val.idmodalidad_cursos
        })
      },

      tipoInfoCurso(val){
        switch(val){
          case 1: 
            this.puntosLlamada = intensivoInbi
          break;

          case 2: 
            this.puntosLlamada = onlineInbi
          break;

          case 3: 
            this.puntosLlamada = teensInbi
          break;
        }
      }
    },

    async created() {
    	this.prospecto = this.getProspecto
    	await this.getMotivos()
      await this.getSucursales()
      await this.getAnuncios()
      await this.getCursos()
      await this.getCiclos()
      await this.getTareas()
      await this.getVendedoras()
      await this.obtenerEstatusLlamada()
      await this.getModalidades()
      await this.getFrecuencias()
    	await this.verProspecto(this.prospecto)
      this.puntosLlamada = this.getEscuela == 1 ? [] : infoFast
      if(this.getEstatusLlamada){
        await this.getInfoLlamada()
      }
      this.puntosLlamada = this.getEscuela == 1 ? [] : infoFast
    },

    methods: {
      ...mapActions('login', ['cambiarDrawer','cambiarEstatusLlamada','addTareas','abrirProspecto','traerProspectosNuevos']),

      getInfoLlamada(){
        return this.$http.get('prospecto.llamada.activa/' + this.getdatosUsuario.iderp).then(async(response) => {
          await this.verProspecto(response.data)
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },
      
      // Obtener todas las sucursales
      getSucursales() {
        this.overlay = true
        this.sucursales  = []
        return this.$http.get('planteles.all').then((response) => {
          // Llenamos la etapa incial
          this.sucursales = response.data
          this.sucursal   = response.data[0]
          this.overlay = false
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      getMotivos(){
        this.overlay = true
        this.motivos  = []
        return this.$http.get('motivos.activos').then((response) => {
          // Llenamos la etapa incial
          this.motivos = response.data
          this.overlay = false
        }).catch(error=>{
          // Indicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      // Obtener todos los anuncios
      getAnuncios() {
        this.overlay = true
        this.anuncios  = []
        return this.$http.get('anuncios.activos').then((response) => {
          // Llenamos la etapa incial
          this.anuncios = response.data
          this.anuncio  = this.anuncios[0]
          this.overlay = false
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      // Obtener todos los cursos
      getCursos() {
        this.overlay = true
        this.cursos  = []
        return this.$http.get('escuela.cursos.list').then((response) => {
          // Llenamos la etapa incial
          this.cursos = response.data.filter(el=> { return el.escuela == this.getEscuela})
          this.overlay = false
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      // Obtener todas las modalidades
      getModalidades() {
        this.overlay = true
        this.modalidades  = []
        return this.$http.get('escuela.modalidades.activos').then((response) => {
          // Llenamos la etapa incial
          this.modalidades = response.data.filter(el=> { return el.escuela == this.getEscuela})
          this.overlay = false
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      // Obtener todos los cursos
      getFrecuencias() {
        this.overlay = true
        this.frecuencias  = []
        return this.$http.get('escuela.frecuencias.activos').then((response) => {
          // Llenamos la etapa incial
          this.frecuencias = response.data.filter(el=> { return el.escuela == this.getEscuela})
          this.overlay = false
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      // Obtener los ciclos más recientes
      getCiclos() {
        this.overlay = true
        this.ciclos  = []
         this.$http.get('ciclos.activos').then((response) => {
          // Llenamos la etapa incial
          this.ciclos = response.data
          this.ciclos_plantel = this.ciclos.filter(el=> { return el.unidad_negocio == this.getEscuela})
          this.overlay = false
          return
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      getVendedoras() {
        this.overlay = true
        this.vendedoras  = []
        return this.$http.get('vendedoras.list').then((response) => {
          // Llenamos la etapa incial
          this.vendedoras = response.data
          this.vendedoras_plantel = this.vendedoras.filter(el=> { return el.escuela == this.getEscuela})
          this.vendedoras_plantel.push({idplantel: 0, plantel:'SIN PLANTEL', nombre:'NA'})
          this.overlay = false
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      iniciarCronometro() {
        this.t = setTimeout(this.add, 1000);
      },

      add() {
        this.tick();
        this.tiempo = /*(this.hrs > 9 ? this.hrs : "0" + this.hrs) 
               + ":" +*/ (this.min > 9 ? this.min : "0" + this.min)
               + ":" + (this.sec > 9 ? this.sec : "0" + this.sec);
        // Acumulamos el tiempo
        this.tiempoTotalSegundos ++
        // Verificamos si es divisible entre 5 segundos
        if(this.tiempoTotalSegundos%300 == 0){
          // Acvtivamos el color rojo
          this.alertaTiempo = true
        }
        // Verificamos en que conteo va 
        if(this.conteoAlertaTiempo == 5){
          // Reiniciamos alerta y conteo
          this.alertaTiempo = false
          this.conteoAlertaTiempo = 0
        }
        // Mientras esta actica la alerta se usa el acumulador, si no esta activa, no se debe acumular
        if(this.alertaTiempo) { this.conteoAlertaTiempo++ }
        

        this.iniciarCronometro();
      },

      tick(){
        this.sec++;
        if (this.sec >= 60) {
            this.sec = 0;
            this.min++;
            if (this.min >= 60) {
                this.min = 0;
                this.hrs++;
            }
        }
      },

      resetCronometro(){
        this.conteoAlertaTiempo  = 0
        this.tiempoTotalSegundos = 0
        this.tiempo              = '00:00'
        this.sec                 = 0
        this.min                 = 0
        this.hrs                 = 0
        this.alertaTiempo        = false
        this.conteoAlertaTiempo  = 0
        this.showTimer           = false
        clearTimeout(this.t);
      },

      /*
        **************** FUNCIONES PARA LOS PROSPECTOS ********************
      */

      // Abrir modal que ayuda a visualizar al prospecto
      async verProspecto(prospecto) {
      	console.log(prospecto)
      	this.traerProspectosNuevos(this.getdatosUsuario.iderp)
      	this.overlay = true
        this.prospecto = prospecto
        // Limpiamos los datos
        this.comentarios          = []
        this.tareas_programadas   = []
        this.eventos              = []
        this.paraquien_el_curso   = ''
        this.estudioIngles        = ''
        this.porqueEstudiarIngles = ''
        this.dispoHorario         = ''
        this.edad                 = ''
        this.notas                = ''

        // Mandamos a llamar la información del usuario
        this.$http.get('prospectos.datos/' + this.prospecto.idprospectos).then((response) => {
        	// Vemos la etapa en la que esta en ese momento
          if(response.data.datosGenerales){
            this.idetapa = response.data.datosGenerales.idetapa
          }

          // Agregamos los puntos inciales
          this.comentarios        = response.data.comentarios
          this.tareas_programadas = response.data.tareas
          this.eventos            = response.data.eventos
          this.llamadasList       = response.data.llamadas
          this.datosLlamada       = response.data.datosLlamada
          this.eventosTimeline    = response.data.timeLine
          this.familiares         = response.data.getFamiliares

          // Contador de whatsApp's
          this.countWhatsApp = 0
          const contadorWhats = this.eventos.filter(el=> { return el.idtipo_evento == 5})
          this.countWhatsApp = contadorWhats.length

          // Guardamos la vendedora
          this.vendedora = {iderp:      parseInt(this.prospecto.usuario_asignado), nombre:  this.prospecto.vendedora}

          // Verificamos que haya datos en la llamada
          if(this.datosLlamada){
            this.paraquien_el_curso   = this.datosLlamada.pregunta1
            this.estudioIngles        = this.datosLlamada.pregunta2
            this.porqueEstudiarIngles = this.datosLlamada.pregunta3
            this.dispoHorario         = this.datosLlamada.pregunta4
            this.edad                 = this.datosLlamada.edad
            this.notas                = this.datosLlamada.notas
            // Llenamos los selectores

            this.anuncio              = this.datosLlamada.idanuncios         ? { idanuncios:         this.datosLlamada.idanuncios,     anuncio:    this.datosLlamada.anuncio      } : null
            this.curso                = this.datosLlamada.idcurso            ? { idcursos_escuela:   this.datosLlamada.curso.id_curso, curso:      this.datosLlamada.curso.curso  } : null
            this.modalidad            = this.datosLlamada.idmodalidad        ? { idmodalidad_cursos: this.datosLlamada.idmodalidad,    modalidad:  this.datosLlamada.modalidad    } : null
            this.frecuencia           = this.datosLlamada.idfrecuencias      ? { idfrecuencias:      this.datosLlamada.idfrecuencias,  frecuencia: this.datosLlamada.frecuencia   } : null
            if(this.datosLlamada.idplantel){
              const existeSucursal = this.sucursales.find( el => el.idplantel == this.datosLlamada.idplantel)

              if(existeSucursal){
                this.sucursal = existeSucursal
              }
            }

            // Validamos si el ciclo esta en la lista de los ciclos activos
            if(this.datosLlamada.idciclo){
              const existeCiclo = this.ciclos_plantel.find(el=> el.id_ciclo == this.datosLlamada.idciclo)
              // Si no existe, lo agregamos
              if(!existeCiclo){
                // Se lleno el ciclo
                this.ciclos_plantel.push(this.datosLlamada.ciclo)
              }
              // Y ahora si ponemos el ciclo
              this.ciclo = { id_ciclo: this.datosLlamada.idciclo, ciclo: this.datosLlamada.ciclo} 
            }
          }

          this.overlay = false
          // Abirmos la modal para poder visualizar la información del prospecto
          this.dialogProspecto = true

          // Validamos si existe una llamada activa
          if(this.getEstatusLlamada){
            // validar si no tiene datos grabados, si no hay, hay que cerrar el dialogo de seguimiento y abrir el de info usuario
            if(!this.datosLlamada){
              setTimeout(() => { 
                // Y abrimos la información del usuario y cerrramos el seguimiento
                this.dialogSeguimiento = false
                this.dialogInfoUsuario = true
              }, 1000);
            }
          }

        }).catch(error=>{
          if(error.body){
            // INdicamos que hay un error en el back
            if(error.body.message){
              this.error     = error.body.message
              this.errorBack = true
            }else{
              this.error     = error.body
              this.errorBack = false
            }
          }else{
            this.error     = error
            this.errorBack = false
          }
          this.overlay     = false
          this.dialogError = true
        })
      },

      // Validar la etapa del prospecto
      validEtapa( idetapa, etapa ){
        const payload = {
          idetapa,
          etapa,
          usuario_creo: this.getdatosUsuario.iderp
        }
        this.$http.put('prospectos.etapa/' + this.prospecto.idprospectos, payload).then((response) => {
          return
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      addFamiliar(){
        const payload = {
          familiar    : this.familiar, 
          edad        : this.edadFamiliar,
          idprospectos: this.prospecto.idprospectos,
          correo      : this.correoFamiliar,
					telefono    : this.telefonoFamiliar,
        }

        this.$http.post('familair.add', payload).then((response) => {
          this.familiares = response.data
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })


        this.addPersonas    = false
        this.familiar       = ''
        this.edadFamiliar   = ''
      },

      deletFamiliar(familiar){
        const payload = {
          idfamiliares:   familiar.idfamiliares, 
          idprospectos:   this.prospecto.idprospectos
        }
        this.$http.post('familair.delete', payload).then((response) => {
          this.familiares = response.data
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })


        this.addPersonas    = false
        this.familiar       = ''
        this.edadFamiliar   = ''
      },

      // Actualizar los datos del prospecto, datos generales
      actualizarProspecto (){
        if(this.prospecto.nombre_completo != '' && this.prospecto.telefono != '' && this.prospecto.nota_inicial != '' ){
          //recibe los campos del form
          //guardo en una variable los datos a guardar id = this.editedItem.idcurso;
          var datos = {
            idprospecto:      this.prospecto.idprospectos,
            nombre_completo:  this.prospecto.nombre_completo,
            telefono:         this.prospecto.telefono,
            correo:           this.prospecto.correo,
            usuario_creo:     this.getdatosUsuario.iderp,
            usuario_asignado: this.prospecto.usuario_asignado,
            escuela:          this.getEscuela,
            induccion:        this.prospecto.induccion,
            fecha_induccion:  this.prospecto.fecha_induccion,
          }

          this.$http.put('prospectos.update/' + this.prospecto.idprospectos, datos).then( async (response) => {
            if(this.prospecto.induccion){
              if(this.idetapa != 3){ await this.validEtapa( 3, 'Contacto en inducción' ) }
            }
            this.dialog = false
            this.dialogSuccess = true
            this.verProspecto(this.prospecto);
          }).catch(error=>{
            // INdicamos que hay un error en el back
            if(error.body.message){
              this.error = error.body.message
            }else{
              this.error = error.body
            }
            this.errorBack = true
            this.overlay = false
            this.dialogError = true
          })
          
        }else{
          // INdicamos que hay un error en el back
          this.error = 'Por favor llena todos los datos'
          this.errorBack = false
          this.overlay = false
          this.dialogError = true
        }
      },

      grabarInfoEscuela(){
        let payload = {
          idprospectos: this.prospecto.idprospectos,
          iderp:        this.getdatosUsuario.iderp,
          informacion:  JSON.stringify(this.puntosLlamada)
        }
        this.overlay = true
        this.$http.post('prospectos.add.info.escuela', payload).then((response) => {
          this.dialogSuccess     = true
          this.overlay           = false
          this.dialogInfoUsuario = false
          this.verProspecto(this.prospecto)
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      // Obtener el listado de actividesdes de la vendedora
      getTareas(){
        this.overlay = true
        this.tareas  = []
        return this.$http.get('tareas.activos').then((response) => {
          // Llenamos la etapa incial
          this.tareas = response.data
          this.tarea  = this.tareas[0]
          this.overlay = false
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      obtenerEstatusLlamada () {
        this.$http.get('prospectos.llamadas/' + this.getdatosUsuario.iderp).then((response) => {
          if(!response.data.message){
            this.estatusLlamada = response.data.estatus
            this.idestatus_llamada = response.data.idestatus_llamada
            this.cambiarEstatusLlamada(response.data.estatus)
          }
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      // Actualizar el perfil del prospecto
      updatePerfil () {
      	// Validar si el prospecto brindo información
        if(!this.prospectoNoDioInfo){
          // Validar que los campos esten llenos
          if(!this.sucursal || !this.porqueEstudiarIngles || !this.curso || !this.dispoHorario){
            this.pasosInfoUsuario = 1
            this.errorBack = false
            this.error = 'Favor de llenar todos los datos'
            this.dialogError = true
            return
          }
        }
      	
        let payload = {
          idprospectos:  this.prospecto.idprospectos,
          pregunta1:     this.paraquien_el_curso   ? this.paraquien_el_curso   : 0,
          pregunta2:     this.estudioIngles        ? this.estudioIngles        : 0,
          pregunta3:     this.porqueEstudiarIngles ? this.porqueEstudiarIngles : 0,
          pregunta4:     this.dispoHorario         ? this.dispoHorario         : 0,
          idusuarioerp:  this.getdatosUsuario.iderp,
          edad:          this.edad       ? this.edad   : 0,
          notas:         this.notas      ? this.notas  : 0,
          idplantel:     this.sucursal   ? this.sucursal.idplantel           : 0,
          idanuncios:    this.anuncio    ? this.anuncio.idanuncios           : 0,
          idcurso:       this.curso      ? this.curso.idcursos_escuela       : 0,
          idciclo:       this.ciclo      ? this.ciclo.id_ciclo               : 0,
          idmodalidad:   this.modalidad  ? this.modalidad.idmodalidad_cursos : 0,
          idfrecuencias: this.frecuencia ? this.frecuencia.idfrecuencias     : 0,
          medio_informacion: this.medio_informacion
        }

        this.$http.post('prospectos.puntos_llamada',payload).then(async (response) => {
          if(this.idetapa == 1 || this.idetapa == 2 || this.idetapa == 5 ){ await this.validEtapa( 4, 'Contacto con información' ) }
          this.dialogSuccess = true
          this.verProspecto(this.prospecto)
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },
  	
      /*
        **************** FUNCIONES PARA LAS TAREAS ********************
      */

      // Programar una nueva tarea
      programarTarea () {
        this.disableBtnTarea = true
        let payload = {
          idtareas:     this.tarea.idtareas,
          tarea:        this.tarea.tarea,
          idmotivos:    this.motivo.idmotivos,
          dia:          this.dia,
          hora:         this.hora,
          idprospectos: this.prospecto.idprospectos,
          idusuarioerp: this.getdatosUsuario.iderp,
          estatus:      0,
          medio:        this.tarea.tipo_tarea == 1 ? this.prospecto.telefono : this.tarea.tipo_tarea == 2 ? this.prospecto.telefono : this.prospecto.email,
          tipo_tarea:   this.tipo_tarea.id,
          descrip_motivo:this.descrip_motivo
        }

        const fehaHoy = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

        if(moment(fehaHoy).isAfter(moment(this.dia))){
          this.errorBack = false
          this.error     = 'No puedes agendar llamadas antes de hoy'
          this.dialogError = true
          return
        }

        if(!this.horaCargada){
          this.horaCargada = { id: 5 }
        }
        // Revisemo la horaCargada
        switch(this.horaCargada.id){
          case 1:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 1,':',MINUTE(now()),':00'))` 
            payload.dia  = `'${this.dia}'`
          break;
          case 2:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 2,':',MINUTE(now()),':00'))` 
            payload.dia  = `'${this.dia}'`
          break;
          case 3:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 4,':',MINUTE(now()),':00'))` 
            payload.dia  = `'${this.dia}'`
          break;
          case 4:
            payload.hora = `(SELECT TIME(NOW()))` 
            payload.dia  = `(SELECT DATE_ADD(DATE(NOW()), INTERVAL 1 DAY))` 
          break;
          case 5:
            payload.hora = `'${this.hora}'`
            payload.dia  = `'${this.dia}'`
          break;
        }


        this.$http.post('prospectos.tarea.add', payload).then( async (response) => {
          this.opcionAddTarea     = false
          this.motivo             = null
          this.horaCargada        = null
          this.dialogCierre       = false
          this.banderaTarea       = 0
          this.dialoguNevaTarea   = false
          if(this.idetapa == 1 || this.idetapa == 2){ await this.validEtapa( 5, 'Contacto sin información' ) } // sin información

          await this.getTareasProgramadas()
          await this.verProspecto(this.prospecto, this.idetapa)
          this.disableBtnTarea = false
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
            this.errorBack = false
          }else{
            this.error = error.body
            this.errorBack = true
          }
          
          this.overlay = false
          this.dialogError = true
          this.disableBtnTarea = false
        })
      },

      getTareasProgramadas ( ) {
        this.addTareas([])
        this.$http.get('prospectos.tarea.usuario/' + this.getdatosUsuario.iderp).then((response) => {
          // Vamos a agregar el campo de tarea programada
          for(const i in response.data){
            response.data[i].notificada = false
          }
          this.addTareas(response.data)
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      }, 

      // actualizar una tarea
      actualizarTarea ( tarea ){
        this.banderaTarea        = 1
        this.idtareas_prospectos = tarea.idtareas_prospectos 
        this.motivo              = {idmotivos: tarea.idmotivos, motivo: tarea.motivo}
        this.notaAnterior        = {idmotivos: tarea.idmotivos, motivo: tarea.motivo}
        this.dia                 = tarea.dia
        this.hora                = tarea.hora
        this.tarea               = this.tareas.find(el => el.idtareas == tarea.idtareas)
        this.horaCargada         = {id: 5, hora: 'Otra'}
        this.tipo_tarea          = tarea.tipo_tarea == 1 ? {id: 1, tipo:'Agendada'}:{id: 2, tipo:'Nota'} 
        this.descrip_motivo      = tarea.descrip_motivo
        this.opcionAddTarea      = true
        this.disableBtnTarea     = false
      },

      // Reprogramar la tarea
      reprogramarTarea () {
        this.disableBtnTarea = true
        let payload = {
          idtareas_prospectos: this.idtareas_prospectos,
          idtareas:     this.tarea.idtareas,
          tarea:        this.tarea.tarea,
          idmotivos:    this.motivo.idmotivos,
          dia:          this.dia,
          hora:         this.hora,
          idprospectos: this.prospecto.idprospectos,
          idusuarioerp: this.getdatosUsuario.iderp,
          notaAnterior: this.notaAnterior,
          estatus:      0,
          medio:        this.tarea.tipo_tarea == 1 ? this.prospecto.telefono : this.tarea.tipo_tarea == 2 ? this.prospecto.telefono : this.prospecto.email,
          tipo_tarea:   this.tipo_tarea.id,
          descrip_motivo:this.descrip_motivo
        }

        const fehaHoy = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

        if(moment(fehaHoy).isAfter(moment(this.dia))){
          this.errorBack = false
          this.error     = ' No puedes agendar llamadas antes de hoy'
          this.dialogError = true
          return
        }

        // Revisemo la horaCargada
        switch(this.horaCargada.id){
          case 1:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 1,':',MINUTE(now()),':00'))`
            payload.dia  = `'${this.dia}'`
          break;
          case 2:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 2,':',MINUTE(now()),':00'))`
            payload.dia  = `'${this.dia}'`
          break;
          case 3:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 4,':',MINUTE(now()),':00'))`
            payload.dia  = `'${this.dia}'`
          break;
          case 4:
            payload.hora = `(SELECT TIME(NOW()))` 
            payload.dia  = `(SELECT DATE_ADD(DATE(NOW()), INTERVAL 1 DAY))` 
          break;
          case 5:
            payload.hora = `'${this.hora}'`
            payload.dia  = `'${this.dia}'`
          break;
          default:
            payload.hora   = `'${this.tarea.hora}'`
            payload.dia    = `'${this.tarea.dia}'`
          break;
        }

        console.log(payload)

        this.$http.put('prospectos.tarea.update', payload).then((response) => {
          this.opcionAddTarea = !this.opcionAddTarea
          this.nota = ''
          this.dia  = null
          this.hora = null
          this.getTareasProgramadas()
          this.verProspecto(this.prospecto, this.idetapa)
          this.disableBtnTarea = false
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
          this.disableBtnTarea = false
        })
      },

      // Eliminar la tarea
      eliminarTarea(id){
        this.tipoDelete          = 1 //  Tarea
        this.idtareas_prospectos = id
        this.dialogDelete        = true
      },

      // confirmación de que se eliminiará la tarea
      deleteItemConfirm() {
        switch(this.tipoDelete){
          case 1:
            this.$http.delete(`prospectos.tarea/${this.idtareas_prospectos}`).then((response) => {
              this.dialogDelete = false
              this.dialogSuccess = true
              this.verProspecto(this.prospecto)
            }).catch(error=>{
              // INdicamos que hay un error en el back
              if(error.body.message){
                this.error = error.body.message
              }else{
                this.error = error.body
              }
              this.errorBack = true
              this.overlay = false
              this.dialogError = true
            })
          break;
        }
      },

      // Verificar que la tarea se realizo
      tareaRealizada( id ) {
        this.$http.put(`prospectos.tarea.check/${id}`).then((response) => {
          this.dialogDelete  = false
          this.dialogSuccess = true
          this.tareaLlamada  = false
          this.verProspecto(this.prospecto)
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      /*
        *********************************************
      */

    	abrirDialogNotaLlamada(){
        this.notasLlamada = []
        this.$http.get(`prospectos.notallamada/${this.prospecto.idprospectos}`).then((response) => {
          this.notasLlamada = response.data
          this.dialogNotaLlamada = true
          // INdicamos que hay un error en el back
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      grabarNotaLlamada(){
        let payload = {
          idprospectos: this.prospecto.idprospectos,
          idusuarioerp: this.getdatosUsuario.iderp,
          nota:         this.notaLlamada,
          idestatus_llamada: this.idestatus_llamada
        }
        this.$http.post(`prospectos.notallamada.add`,payload).then((response) => {
          this.notaLlamada = ''
          this.abrirDialogNotaLlamada()
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      // Agregar un comentario
      addComentario () {
        let payload = {
          idprospectos: this.prospecto.idprospectos,
          idusuarioerp: this.getdatosUsuario.iderp,
          comentario:   this.newComentario,
        }

        this.$http.post('prospectos.comentarios', payload).then((response) => {
          this.newComentario = ''
          this.verProspecto(this.prospecto);
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
        this.newComentario = ''
      },

      limpiar ( ) {
        this.sucursal                = null
        this.anuncio                 = null
        this.curso                   = null
        this.ciclo                   = null
        this.vendedora               = null
        this.datosLlamada            = null
        this.banderaFinalizo         = false
        this.prospectoFinalizo       = false
        this.tareaLlamada            = false
        this.pasosInfoUsuario        = 1
        this.intentoLlamada          = 0
        this.dialogDelete            = false
        this.dialogTareasProgramadas = false
        this.dialogReasignar         = false
        this.dialogFinalizacion      = false
        this.dialogCierre            = false
        this.dialogInfoUsuario       = false
        this.dialogSeguimiento       = false  // dialogo para iniciar seguimiento 
        this.dialogEtapa             = false
        this.dialogSuccess           = false
        this.dialogProspecto         = false
        this.dialogError             = false
        this.dialoguNevaTarea        = false
        this.opCierre                = 0
        this.puntosLlamada           = this.getEscuela == 1 ? [] : infoFast
        this.descrip_motivo          = ''
        this.disableBtnTarea         = false
        this.codigo_clase            = ''
        this.contra                  = ''
        this.opcionAddTarea          = false
        this.familiarAFinalizar     = null
        this.dialogFinalizarFamiliar = false
        this.matricula               = ''
        this.grupo                   = null
        this.notaRechazo             = ''
        this.banderaTarea            = 0
        this.prospectoNoDioInfo      = 0
        this.banderaTarea            = 0
      },

      getColorEvento (tipo) {
        switch(tipo){
          // Nota
          case 1: 
            return 'green'
          break;

          // Actualización
          case 2: 
            return 'primary'
          break;

          // Tarea
          case 3: 
            return 'warning'
          break;

          // Tarea
          case 4: 
            return 'purple darken-4'
          break;

          // Mensaje de WhatsApp
          case 5: 
            return 'blue-grey darken-4'
          break;

          // En inducción
          case 6: 
            return 'black'
          break;

        }
      },

      iniciarSeguimiento () {
        // Primero validamos que estatus tiene el prospecto
        // El prospecto tiene una tarea programada?
        if(this.tareas_programadas.length == 0){
          // Si no tiene, abrimos el dialogSeguimiento
          this.dialogSeguimiento = true
        }else{
          // Si tiene abrimos el dialog de tareas programadas
          this.dialogTareasProgramadas = true
        }
      },

      inciarSeguimientoEnSucursal(){
      	// Indicamos que es por sucursal
        this.medio_informacion = 1
        // Abrimos seguimiento
        this.dialogSeguimiento = true
        // Actualizamos estatus llamada, osea, abrimos la llamada
        this.updateEstatusLlamada()
      },

      updateEstatusLlamada(){
        // verificamos si tiene datos osea un perfil, si no lo tiene hay que abrir primero el dialog de primer contacto
        if(!this.datosLlamada){
          // Y abrimos la información del usuario y cerrramos el seguimiento
          this.dialogSeguimiento = false
          this.dialogInfoUsuario = true
        }

        // validamos si tiene tareas
        if(this.tareas_programadas.length > 0){
          // Si tiene se eliminia y punto
          this.tareaRealizada(this.tareas_programadas[0].idtareas_prospectos)
        }

        // Abrimos la llamada
        this.showTimer = true
        this.iniciarCronometro()
        this.abrirCerrarLlamada(true) 
      },

      abrirCerrarLlamada(estatusLlamada){
        let payload = {
          idusuarioerp: this.getdatosUsuario.iderp,
          estatus:      estatusLlamada,
          idprospectos: this.prospecto.idprospectos
        }

        this.$http.post('prospectos.llamadas.add', payload).then((response) => {
          this.estatusLlamada = estatusLlamada
          this.idestatus_llamada = response.data.id
          this.cambiarEstatusLlamada(estatusLlamada)
          // Al cambiar el estatus de la llamada, es necesario indicar si desea agregar una tarea
          this.verProspecto(this.prospecto)
          // Abrimos lo de la tarea
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      finalizarLlamada(){
        // Validamos si estaba en una tarea de llamada
        if(this.tareaLlamada){
          this.tipoDelete = 1
          if(this.tareas_programadas.length > 0){
            this.tareaRealizada(this.tareas_programadas[0].idtareas_prospectos)
          }
        }

        this.getTareasProgramadas()
        // Abrimos para agendar una nueva tarea
        this.dialogCierre = true
        // Cerramos la llamada
        this.resetCronometro()
        this.abrirCerrarLlamada(false)
      },

      cerrarDialogCierre(){
        this.dialogCierre       = false
        this.opCierre           = 0
        this.banderaRechazo     = false
        this.banderaFinalizo    = false
        this.notaRechazo        = ''
        this.matricula          = null
        this.grupo              = null
      },

      copiarPortapapeles () {
        navigator.clipboard.writeText(this.error).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          // console.log('Something went wrong', err);
        })
      },

      copiarTelefono ( item ) {
        navigator.clipboard.writeText(item).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          // console.log('Something went wrong', err);
        })
      },

      /*
        *************************************
      */

      abrirDialogReasignacion(){
        this.nuevaSucursal = this.sucursales[0]
        this.vendedora = null
        this.vendedoras_plantel = []
        this.dialogReasignar = true
      },

      cancelarReasignacion(){
        this.nuevaSucursal = this.sucursales[0]
        this.vendedora = null
        this.vendedoras_plantel = []
        this.dialogReasignar = false
      },

      reasignar(){
        // Reasignación del prospecto
        if(this.nuevaSucursal){
          this.overlay = true
          const payload = {
            idprospectos:     this.prospecto.idprospectos,
            reasignado:       1,
            usuario_asignado: this.nuevaSucursal.iderp,
            idusuarioerp:     this.getdatosUsuario.iderp
          }

          this.$http.post('prospectos.reasignar', payload).then((response) => {
            this.dialogSuccess = true
            this.vendedora = {iderp:      parseInt(this.nuevaSucursal.iderp), nombre:  this.nuevaSucursal.nombre}
            this.cancelarReasignacion()
          }).catch(error=>{
            // INdicamos que hay un error en el back
            if(error.body.message){
              this.error = error.body.message
            }else{
              this.error = error.body
            }
            this.errorBack = true
            this.overlay = false
            this.dialogError = true
          })

        }else{
          // Error
          this.errorBack = false
          this.error = 'Necesitas asignar una sucursal'
          this.overlay = false
          this.dialogError = true
        }
      },

      /*
        *************************************
      */

      finalizarProspecto (){
        if(this.matricula != '' && this.grupo && this.codigo_clase != '' && this.codigo_clase != ''){
          const payload = {
            idprospectos: this.prospecto.idprospectos,
            matricula:    this.matricula,
            idusuarioerp: this.getdatosUsuario.iderp,
            idgrupo:      this.grupo.id_grupo,
            codigo_clase: this.codigo_clase
          }

          // Hacer la peticion de los datos
          this.$http.post('prospectos.finalizar.ok', payload).then( async (response) => {
            this.dialogSuccess = true
            this.dialogProspecto = false
            this.dialogFinalizacion = false
            this.dialogTarea        = false
            await this.enviarCodigo()
            this.limpiar()
            this.initialize()
          }).catch(error=>{
            // INdicamos que hay un error en el back
            if(error.body.message){
              this.error = error.body.message
            }else{
              this.error = error.body
            }
            this.errorBack = true
            this.overlay = false
            this.dialogError = true
          })


        }else{  
          // Error
          this.errorBack = false
          this.error = 'Por favor, ingresa los datos correctamente'
          this.overlay = false
          this.dialogError = true
        }
      },

      enviarCodigo () {
        let payload = {
          idusuarioerp: this.getdatosUsuario.iderp,
          idprospectos: this.prospecto.idprospectos,
          nota:         `Hola, te comparto tu código para poder acceder a tu clase
          %0ACODIGO:          ${ this.codigo_clase } 
          %0ACONTRASEÑA: ${ this.contra       }
          `,
        }

        this.$http.post('prospectos.whatsapp.add', payload).then((response) => {
          // Abrimo la modal para grabar una tarea
          let url = 'https://api.whatsapp.com/send?phone=+52' + this.prospecto.telefono + '&text=' + payload.nota
          window.open(url, '_blank');
          return
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      getGrupos(){
        this.grupos = []
        this.$http.get('prospectos.grupos/' + this.getEscuela).then((response) => {
          this.grupos = response.data
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      rechazoProspecto(){
        if(this.notaRechazo != ''){
          const payload = {
            idprospectos: this.prospecto.idprospectos,
            idusuarioerp: this.getdatosUsuario.iderp,
            notaRechazo:  this.notaRechazo
          }

          // Hacer la peticion de los datos
          this.$http.post('prospectos.finalizar.nok', payload).then((response) => {
            this.dialogSuccess      = true
            this.dialogProspecto    = false
            this.notaRechazo        = ''
            this.banderaFinalizo    = false
            this.banderaRechazo     = false
            this.cerrarDialogCierre()
            this.limpiar()
            this.verProspecto(this.prospecto)
          }).catch(error=>{
            // INdicamos que hay un error en el back
            if(error.body.message){
              this.error = error.body.message
            }else{
              this.error = error.body
            }
            this.errorBack = true
            this.overlay = false
            this.dialogError = true
          })
        }else{  
          // Error
          this.errorBack = false
          this.error = 'Por favor, ingresa los datos correctamente'
          this.overlay = false
          this.dialogError = true
        }
      },

      /*
        ************* FINALIZAR PROSPECTO FAMILIAR
      */

      finalizarProspectoFamiliar (){
        if(this.matricula != '' && this.grupo && this.codigo_clase != '' && this.codigo_clase != ''){
          const payload = {
            idfamiliares: this.familiarAFinalizar.idfamiliares,
            matricula:    this.matricula,
            idusuarioerp: this.getdatosUsuario.iderp,
            idgrupo:      this.grupo.id_grupo,
            codigo_clase: this.codigo_clase,
            idprospectos: this.prospecto.idprospectos
          }

          // Hacer la peticion de los datos
          this.$http.post('familiar.finalizar.ok', payload).then( async (response) => {
            await this.enviarCodigoFamiliar()
            this.dialogSuccess           = true
            this.notaRechazo             = ''
            this.banderaFinalizo         = false
            this.banderaRechazo          = false
            this.familiar                = null
            this.dialogFinalizarFamiliar = false
            this.codigo_clase            = ''
            this.grupo                   = null
            this.verProspecto(this.prospecto)
          }).catch(error=>{
            // INdicamos que hay un error en el back
            if(error.body.message){
              this.error = error.body.message
            }else{
              this.error = error.body
            }
            this.errorBack = true
            this.overlay = false
            this.dialogError = true
          })


        }else{  
          // Error
          this.errorBack = false
          this.error = 'Por favor, ingresa los datos correctamente'
          this.overlay = false
          this.dialogError = true
        }
      },

      enviarCodigoFamiliar () {
        let payload = {
          idusuarioerp: this.getdatosUsuario.iderp,
          idprospectos: this.prospecto.idprospectos,
          nota:         `Hola, te comparto tu código para poder acceder a tu clase
          %0ACODIGO:          ${ this.codigo_clase } 
          %0ACONTRASEÑA: ${ this.contra       }
          `,
        }

        this.$http.post('prospectos.whatsapp.add', payload).then((response) => {
          // Abrimo la modal para grabar una tarea
          if(this.familiar && this.familiar.telefono){
            let url = 'https://api.whatsapp.com/send?phone=+52' + this.familiar.telefono + '&text=' + payload.nota
            window.open(url, '_blank');
            return
          }else{
            let url = 'https://api.whatsapp.com/send?phone=+52' + this.prospecto.telefono + '&text=' + payload.nota
            window.open(url, '_blank');
            return
          }
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      rechazoProspectoFamiliar(){
        if(this.notaRechazo != ''){
          const payload = {
            idfamiliares: this.familiarAFinalizar.idfamiliares,
            idusuarioerp: this.getdatosUsuario.iderp,
            notaRechazo:  this.notaRechazo,
            idprospectos: this.prospecto.idprospectos
          }

          // Hacer la peticion de los datos
          this.$http.post('familiar.finalizar.nok', payload).then((response) => {
            this.dialogSuccess           = true
            this.notaRechazo             = ''
            this.banderaFinalizo         = false
            this.banderaRechazo          = false
            this.familiar                = null
            this.dialogFinalizarFamiliar = false
            this.verProspecto(this.prospecto)
          }).catch(error=>{
            // INdicamos que hay un error en el back
            if(error.body.message){
              this.error = error.body.message
            }else{
              this.error = error.body
            }
            this.errorBack = true
            this.overlay = false
            this.dialogError = true
          })
        }else{  
          // Error
          this.errorBack = false
          this.error = 'Por favor, ingresa los datos correctamente'
          this.overlay = false
          this.dialogError = true
        }
      },

      /*
        *************************************
      */

      enviarWhatsApp () {
      	if(this.intentoLlamada == 3){
          this.addIntentoLlamada(9)
          this.addIntentoLlamada(10)
        }
      	// validamos si tiene tareas
        if(this.tareas_programadas.length > 0){
          // Si tiene se eliminia y punto
          this.tareaRealizada(this.tareas_programadas[0].idtareas_prospectos)
        }
        
        const payload = {
          idusuarioerp: this.getdatosUsuario.iderp,
          idprospectos: this.prospecto.idprospectos,
          nota:         this.mensajeWhatsapp,
        }
        this.$http.post('prospectos.whatsapp.add', payload).then((response) => {
          this.dialogSeguimiento     = false // Cerramos el dialogo de primer contacto
          /* Deshabilitamos todo lo que se utilzo para esa ventana */
          this.dialogInfoUsuario     = false
          this.dialogSeguimiento     = false
          this.showTooltipTelefono   = false
          this.dialogCierre          = true
          // Abrimo la modal para grabar una tarea
          let url = 'https://api.whatsapp.com/send?phone=+52' + this.prospecto.telefono + '&text=' + this.mensajeWhatsapp
          window.open(url, '_blank');
          this.mensajeWhatsapp = ''
          this.verProspecto(this.prospecto);
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      abrirNotaMental(position){
        // nota mental
        this.eventos[position].abrirMensaje = true 
        const eventos = this.eventos
        this.eventos = []
        this.eventos = eventos
      },

      cerrarNotaMental(position){
        // nota mental
        this.eventos[position].abrirMensaje = false 
        const eventos = this.eventos
        this.eventos = []
        this.eventos = eventos
      },

      /* add intento de la llamada */
      addIntentoLlamada(tipo_evento){
        const payload = {
          idusuarioerp: this.getdatosUsuario.iderp,
          idprospectos: this.prospecto.idprospectos,
          tipo_evento:  tipo_evento,
        }
        this.$http.post('prospectos.add.evento.llamada', payload).then(async (response) => {
          if(this.idetapa == 1 || this.idetapa == 2){ await this.validEtapa( 5, 'Contacto sin información' ) } // sin información
          await this.verProspecto(this.prospecto)
          // Abrimo la modal para grabar una tarea
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      }

    },
  };
</script>
<style scoped>

  #eventos::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  #eventos::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  #eventos::-webkit-scrollbar:vertical{
    width: 6px !important;
  }

  #eventos::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  #eventos::-webkit-scrollbar:horizontal{
    height: 6px !important;
  }

  #eventos::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  #notas::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  #notas::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  #notas::-webkit-scrollbar:vertical{
    width: 6px !important;
  }

  #notas::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  #notas::-webkit-scrollbar:horizontal{
    height: 6px !important;
  }

  #notas::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>

