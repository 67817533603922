var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-4 ml-2",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadowCard"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Bonos RI")]),_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","dark":"","small":"","tile":""},on:{"click":function($event){return _vm.initialize()}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-refresh")]),_vm._v(" Actualizar ")],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"filled":"","label":"Fecha","type":"date","hide-details":"","dense":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.initialize()}},model:{value:(_vm.fecha),callback:function ($$v) {_vm.fecha=$$v},expression:"fecha"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"name":"name","label":"Buscar","id":"id","filled":"","dense":"","hide-details":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.bonospuntualidad,"search":_vm.search,"page":_vm.page,"mobile-breakpoint":100,"dense":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.estatus_entradas_validas",fn:function(ref){
var item = ref.item;
return [(item.estatus_entradas_validas == 1)?_c('v-chip',{attrs:{"small":"","color":"blue","dark":""}},[_vm._v("Meta alcanzada")]):_vm._e(),(item.estatus_entradas_validas == 0)?_c('v-chip',{attrs:{"small":"","color":"black","dark":""}},[_vm._v("Meta no alcanzada")]):_vm._e()]}},{key:"item.estatus_entradas_laboradas",fn:function(ref){
var item = ref.item;
return [(item.estatus_entradas_laboradas == 1)?_c('v-chip',{attrs:{"small":"","color":"blue","dark":""}},[_vm._v("Meta alcanzada")]):_vm._e(),(item.estatus_entradas_laboradas == 0)?_c('v-chip',{attrs:{"small":"","color":"black","dark":""}},[_vm._v("Meta no alcanzada")]):_vm._e()]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [((item.estatus == 0 || item.estatus == '' || item.estatus == null) && (item.bonori == 0 || item.bonori == '' || !item.bonori))?_c('v-chip',{attrs:{"small":"","color":"black","dark":""}},[_vm._v("Objetivo No Alcanzado")]):_vm._e(),((item.estatus == 0 || item.estatus == '' || item.estatus == null) && ((item.bonori != 0 || item.bonori != '') && item.bonori))?_c('v-chip',{attrs:{"small":"","color":"blue","dark":""}},[_vm._v("Objetivo Alcanzado")]):_vm._e(),(item.estatus == 1)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v("Aceptada")]):_vm._e()]}},{key:"item.aceptar",fn:function(ref){
var item = ref.item;
return [((item.estatus == 0 || item.estatus == '' || item.estatus == null) && ((item.bonori != 0 || item.bonori != '') && item.bonori))?_c('v-btn',{attrs:{"color":"primary","dark":"","small":""},on:{"click":function($event){(_vm.item_bonopuntualidad = item), (_vm.dialogAceptar = true)}}},[_vm._v(" ACEPTAR ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.initialize}},[_vm._v(" Actualizar ")])]},proxy:true}])})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogAceptar),callback:function ($$v) {_vm.dialogAceptar=$$v},expression:"dialogAceptar"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"elevation-0",staticStyle:{"display":"table"},attrs:{"height":"100%"}},[_c('div',{staticClass:"text-h6",staticStyle:{"display":"table-cell","vertical-align":"middle"}},[_c('strong',[_vm._v("Aceptar:")]),_vm._v(" ¿Estás seguro que deseas aceptar el bono de RI? "),_c('v-card-actions',{staticClass:"pt-6"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","text":"","large":""},on:{"click":function($event){_vm.dialogAceptar=false}}},[_vm._v("No, cancelar")]),_c('v-btn',{staticClass:"elevation-6",attrs:{"color":"#5C5C5C","dark":"","large":""},on:{"click":function($event){return _vm.addNominaBonoPuntualidad()}}},[_vm._v("Si")])],1)],1)])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-img',{attrs:{"src":require("@/assets/contrato.jpg")}})],1)],1)],1)],1)],1),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar)?_c('carga'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }