<template>
  <v-container class="mt-4 ml-2">
    <v-snackbar
      v-model="snackbar"
      top
      :timeout="3000"
      color="error"
    >
      Selecciona los ciclos porfavor 
      <v-btn text color="white" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="resultado"
          sort-by="calories"
          class="elevation-1"
          hide-default-footer
          :items-per-page="20"
          dense
          mobile-breakpoint="200"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>RI</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-btn 
                class="mr-2" 
                small dark color="green"
                @click="exportar()">
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-btn>
              <v-btn color="primary" @click="consultar()" small>Consultar</v-btn>
            </v-toolbar>
            <v-card-text class="pb-6">
              <v-row>
                <v-col cols="12" sm="6" lg="4" xl="3">
                  <v-autocomplete
                    outlined
                    dense
                    clearable
                    v-model="cicloInf"
                    :items="ciclos"
                    label="Selecciona ciclo actual"
                    persistent-hint
                    hide-details
                    return-object
                    item-text="ciclo"
                    item-value="id_ciclo"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" xl="3">
                  <v-autocomplete
                    outlined
                    dense
                    clearable
                    v-model="cicloSup"
                    :items="ciclos"
                    label="Selecciona siguiente ciclo"
                    persistent-hint
                    hide-details
                    return-object
                    item-text="ciclo"
                    item-value="id_ciclo"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </template>

          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
              small
            >
              Actualizar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'

  export default {
    data: () => ({
      snackbar:false,
      headers: [
        { text: 'Plantel'                  , value: 'plantel' },
        { text: 'Alumnos Ciclo Actual'     , value: 'alumnosActual' },
        { text: 'Alumnos siguiente ciclo'  , value: 'alumnosSiguiente' },
        { text: 'Faltantes'                , value: 'faltan' },
        { text: '%faltantes'               , value: 'porcentaje' },
      ],
      desserts: [],
      ciclos:[],
      cicloInf:null,
      cicloSup:null,
      planteles:[],
      plantel:null,
      cicloAnterior:[],
      cicloSiguiente:[],
      resultado:[],
      total:0,
      faltantes:0,
      siguientes:0,
      porcentaje:0,
      idcicloERPinf:0,
      idcicloERPSup:0
    }),

    watch: {
      cicloInf () {
        if(this.cicloInf){
          var num_search = parseInt(this.cicloInf.ciclo.substr(5,3)) + 1
          for(const i in this.ciclos){
            var cadena_search = this.ciclos[i].ciclo.substr(0,8)
            if (cadena_search.search(num_search) != -1) {
              this.cicloSup = this.ciclos[i]
            }
          }

          if(this.cicloSup){
            this.consultar()
          }
        }
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.planteles.all').then(response=>{
          for(const i in response.data){
            var payload = {
              plantel: response.data[i].plantel,
              alumnosActual: 0,
              alumnosSiguiente: 0,
              faltan: 0,
              porcentaje: 0,
            }
            this.planteles.push(payload)
          }
          this.$http.get('kpi.ciclos.all').then(response=>{
            for(const i in response.data){
              if (!(response.data[i].ciclo.search('FE') != -1)) {
                if(response.data[i].ciclo.search('CICLO') != -1){
                  this.ciclos.push(response.data[i])
                }
              }
            }
          }).catch(error=>{console.log(error)})
        }).catch(error=>{console.log(error)})
      },

      consultar () {
        this.resultado = []
        this.total = 0
        this.faltantes = 0
        this.siguientes = 0
        this.porcentaje = 0
        this.totalFinal      = 0
        this.faltantesFinal  = 0
        this.siguientesFinal = 0
        this.porcentajeFinal = 0

        for(const i in this.planteles){
          this.planteles[i].plantel =  this.planteles[i].plantel
          this.planteles[i].alumnosActual =  0
          this.planteles[i].alumnosSiguiente = 0
          this.planteles[i].faltan = 0
          this.planteles[i].porcentaje = 0
        }

        if(this.cicloInf && this.cicloSup){

          this.resultado = []
          this.total = 0
          this.faltantes = 0
          this.siguientes = 0
          this.porcentaje = 0
          this.$http.get('kpi.cantactual/' + this.cicloInf.id_ciclo + '/' + this.cicloInf.id_ciclo_relacionado).then(response=>{
            this.cicloAnterior = response.data
            this.$http.get('kpi.cantsiguiente/' + this.cicloSup.id_ciclo + '/' + this.cicloSup.id_ciclo_relacionado).then(response=>{
              this.cicloSiguiente = response.data

              // validamos primero que el alumno del ciclo anterior este en el nuevo ciclo
              // Sacamos el total para inbi
              for(const i in this.cicloAnterior){
                // inicializamos las banderas
                var existeAlumno = false
                for(const j in this.cicloSiguiente){
                  // Sacar si son los mismos alumnos, de ser así, esto significa que esta inscrito en el siguiente ciclo
                  if(this.cicloAnterior[i].id_alumno == this.cicloSiguiente[j].id_alumno) {
                    existeAlumno = true
                    // exista o no, se le suma uno al plantel
                    // vemos a que plantel pertenece
                  }
                }

                // Aquí llenamos los del ciclo acutal
                for(const k in this.planteles){
                  if(this.planteles[k].plantel == this.cicloAnterior[i].plantel){
                    this.planteles[k].alumnosActual += 1 
                  }
                }
              }


              for(const i in this.cicloAnterior){
                // inicializamos las banderas
                var existeAlumno = false
                for(const j in this.cicloSiguiente){
                  // Sacar si son los mismos alumnos, de ser así, esto significa que esta inscrito en el siguiente ciclo
                  if(this.cicloAnterior[i].id_alumno == this.cicloSiguiente[j].id_alumno) {
                    existeAlumno = true
                    // exista o no, se le suma uno al plantel
                    // vemos a que plantel pertenece
                    // Aquí llenamos los del ciclo acutal
                    for(const k in this.planteles){
                      if(this.planteles[k].plantel == this.cicloAnterior[i].plantel){
                        this.planteles[k].alumnosSiguiente += 1 
                      }
                    }
                  }
                }

              }


              this.total = 0
              this.faltantes = 0
              this.siguientes = 0
              this.porcentaje = 0
              var resultadoPre = this.planteles

              var inbi = []
              for(const i in resultadoPre){
                if (!(resultadoPre[i].plantel.search('FAST') != -1)) {
                  if(resultadoPre[i].alumnosActual > 0){
                    resultadoPre[i].faltan      = resultadoPre[i].alumnosActual - resultadoPre[i].alumnosSiguiente
                    resultadoPre[i].porcentaje  = (((resultadoPre[i].faltan) / resultadoPre[i].alumnosActual) * 100).toFixed(2)
                    this.total      += resultadoPre[i].alumnosActual
                    this.faltantes  += resultadoPre[i].faltan
                    this.siguientes += resultadoPre[i].alumnosSiguiente
                    inbi.push(resultadoPre[i])
                  }
                }
              }

              // Final de inbiii
              var finalData = {
                plantel: 'Total',
                alumnosActual: this.total,
                alumnosSiguiente: this.siguientes,
                faltan: this.faltantes,
                porcentaje: (((this.faltantes) / this.total) * 100).toFixed(2),
              }
              this.resultado = inbi
              this.resultado.push(finalData)

              // Agregamos un espacio en blanco a la tabla
              var payload = {
                plantel: '',
                alumnosActual: '',
                alumnosSiguiente: '',
                faltan: '',
                porcentaje:'',
              }
              this.resultado.push(payload)

              this.totalFinal      = this.total
              this.faltantesFinal  = this.faltantes
              this.siguientesFinal = this.siguientes
              this.porcentajeFinal = this.porcentaje

              this.total = 0
              this.faltantes = 0
              this.siguientes = 0
              this.porcentaje = 0
              var resultadoPre = this.planteles

              var fast = []
              for(const i in resultadoPre){
                if (resultadoPre[i].plantel.search('FAST') != -1) {
                  if(resultadoPre[i].alumnosActual > 0){
                    resultadoPre[i].faltan      = resultadoPre[i].alumnosActual - resultadoPre[i].alumnosSiguiente
                    resultadoPre[i].porcentaje  = (((resultadoPre[i].faltan) / resultadoPre[i].alumnosActual) * 100).toFixed(2)
                    this.total      += resultadoPre[i].alumnosActual
                    this.faltantes  += resultadoPre[i].faltan
                    this.siguientes += resultadoPre[i].alumnosSiguiente
                    this.resultado.push(resultadoPre[i])
                  }
                }
              }

              // Final de inbiii
              var finalData = {
                plantel: 'Total',
                alumnosActual: this.total,
                alumnosSiguiente: this.siguientes,
                faltan: this.faltantes,
                porcentaje: (((this.faltantes) / this.total) * 100).toFixed(2),
              }

              // this.resultado.concat(fast)

              this.resultado.push(finalData)

              // // Agregamos un espacio en blanco a la tabla
              var payload = {
                plantel: '',
                alumnosActual: '',
                alumnosSiguiente: '',
                faltan: '',
                porcentaje:'',
              }
              this.resultado.push(payload)

              this.totalFinal      += this.total
              this.faltantesFinal  += this.faltantes
              this.siguientesFinal += this.siguientes

              var finalData = {
                plantel: 'Total',
                alumnosActual: this.totalFinal,
                alumnosSiguiente: this.siguientesFinal,
                faltan: this.faltantesFinal,
                porcentaje: (((this.faltantesFinal) / this.totalFinal) * 100).toFixed(2),
              }

              // this.resultado.concat(fast)

              this.resultado.push(finalData)


            }).catch(error=>{console.log(error)})
          }).catch(error=>{console.log(error)})
        }else{
          this.snackbar = true
        }
      },

      exportar(){
        let xls = {
          data:      this.resultado,
          tHeaders : ['Codigo','Nombre', 'Sal', 'Labortorio'],
          filterVal: ['codigo','nomart', 'sal', 'lab'],
          nomfile :  "Productos"
        }
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let data = XLSX.utils.json_to_sheet(this.resultado)
        const workbook = XLSX.utils.book_new()
        const filename = 'KPI'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },

      
    },
  }
</script>