<template>
  <v-container fluid class="mt-2 pa-6">

  	<!-- Barra superior -->
  	<v-row>
  		<v-col cols="12">
	  		<v-card class="back-dashboard">
          <v-img
            class="white--text align-end"
          />
          <v-card-text class="position-relative">
          	<div class="d-flex flex-no-wrap ">
          		<v-avatar
	              size="150"
	              color="transparent"
	              class="avatar-center"
	            >
	              <v-img src="@/assets/asistente/kpi.png"></v-img>
	            </v-avatar>

              <div>
                <v-card-title
                  class="text-h5 white--text"
                >
              		<!-- Hola, {{ getdatosUsuario.nombre_usuario + " " + getdatosUsuario.apellido_usuario }}  -->
              		Hola, {{ getdatosUsuario.nombre_usuario }} !! 
              	</v-card-title>
                <v-card-subtitle class="text-h6 white--text pb-0">Bienvenido a tu panel de control</v-card-subtitle>
                <v-card-title primary-title class="py-0">
                  <v-autocomplete
                    :items="filterSucursales"
                    v-model="sucursal"
                    return-object
                    item-text="plantel"
                    item-value="idplantel"
                    label="Sucursal"
                    hide-details
                    dense
                    dark
                    filled
                    class="mr-2"
                  ></v-autocomplete>

                  <v-select
                    :items="[{escuela:1, nombre:'INBI'},{escuela:2, nombre:'FAST'}]"
                    v-model="escuela"
                    item-text="nombre"
                    item-value="escuela"
                    label="Escuela"
                    hide-details
                    dense
                    dark
                    filled
                  ></v-select>
                </v-card-title>
              </div>
            </div>
          </v-card-text>
        </v-card>
  		</v-col>
  	</v-row>

  	<!-- Desglose dle Dashboard -->
    <v-row>
      <!-- RI reingreso -->
      <v-col cols="12" md="6" lg="4" class="py-0">
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text">% RI (Reingreso)</span>
			      <v-spacer></v-spacer>
			      <v-btn icon small class="me-n3 mt-n2">
			        <v-icon>
			          mdi-download
			        </v-icon>
			      </v-btn>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
		      	<v-row>
		      		<v-col cols="8" class="pt-0">
		      			<!-- Porcentaje y meta actual -->
					      <v-subheader class="pl-0">Actualidad</v-subheader>
		      			<div class="d-flex align-center">
					        <h1 class="text-4xl font-weight-semibold red--text" v-if="filterRi.porcentajeRi < filterMetas.ri">
					          {{ filterRi.porcentajeRi }} %
					        </h1>

					        <h1 class="text-4xl font-weight-semibold green--text" v-else>
					          {{ filterRi.porcentajeRi }} %
					        </h1>

					        <div class="d-flex align-center mb-n3">
					          <v-icon size="40" color="green" v-if="filterRi.diferenciaPorcentaje > 0"> mdi-menu-up </v-icon>
					          <v-icon size="40" color="red"   v-else> mdi-menu-down</v-icon>
					          <span class="text-base font-weight-medium green--text ms-n2" v-if="filterRi.diferenciaPorcentaje > 0">{{ filterRi.diferenciaPorcentaje }}%</span>
					          <span class="text-base font-weight-medium red--text ms-n2" v-else>{{ filterRi.diferenciaPorcentaje * -1 }}%</span>
					        </div>
					      </div>
		      		</v-col>

		      		<!-- Meta -->
		      		<v-col cols="4" class="pt-0">
		      			<v-subheader class="pl-0">Meta</v-subheader>
				        <h1 class="text-4xl font-weight-semibold grey--text">
				          {{ filterMetas.ri }}%
				        </h1>
		      		</v-col>
		      	</v-row>

			      <h4 class="mt-2 font-weight-medium">
			        Comparado al {{ filterRi.porcentajeRiAnt }}% del ciclo anterior 
			      </h4>
			    </v-card-text>
			  </v-card>
  		</v-col>

  		<!-- Número de ventas -->
  		<v-col cols="12" md="6" lg="4" class="py-0">
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text"># VENTAS</span>
			      <v-spacer></v-spacer>
			      <v-btn icon small class="me-n3 mt-n2">
			        <v-icon>
			          mdi-download
			        </v-icon>
			      </v-btn>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
		      	<v-row>
		      		<v-col cols="8" class="pt-0">
		      			<!-- Porcentaje y meta actual -->
					      <v-subheader class="pl-0">Actualidad</v-subheader>
		      			<div class="d-flex align-center">
					        <h1 class="text-4xl font-weight-semibold red--text" v-if="filterVentas.inscritos < filterMetas.ventas">
					          #{{ filterVentas.inscritos }}
					        </h1>

					        <h1 class="text-4xl font-weight-semibold green--text" v-else>
					          #{{ filterVentas.inscritos }}
					        </h1>

					        <div class="d-flex align-center mb-n3">
					          <v-icon size="40" color="green" v-if="filterVentas.inscritos > filterVentas.inscritosAnteriores"> mdi-menu-up </v-icon>
					          <v-icon size="40" color="red"   v-else> mdi-menu-down</v-icon>
					          <span class="text-base font-weight-medium green--text ms-n2" v-if="filterVentas.inscritos > filterVentas.inscritosAnteriores"># {{ filterVentas.inscritos  - filterVentas.inscritosAnteriores}}</span>
					          <span class="text-base font-weight-medium red--text ms-n2" v-else># {{ (filterVentas.inscritosAnteriores - filterVentas.inscritos) }}</span>
					        </div>
					      </div>
		      		</v-col>

		      		<!-- Meta -->
		      		<v-col cols="4" class="pt-0">
		      			<v-subheader class="pl-0">Meta</v-subheader>
				        <h1 class="text-4xl font-weight-semibold grey--text">
				          {{ filterMetas.ventas }}#
				        </h1>
		      		</v-col>
		      	</v-row>

			      <h4 class="mt-2 font-weight-medium">
			        Comparado al #{{ filterVentas.inscritosAnteriores }} de ventas del ciclo anterior 
			      </h4>
			    </v-card-text>
			  </v-card>
  		</v-col>

  		<!-- Cantidad de contactos -->
  		<v-col cols="12" md="6" lg="4" class="py-0">
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text"># Contactos</span>
			      <v-spacer></v-spacer>
			      <v-btn icon small class="me-n3 mt-n2">
			        <v-icon>
			          mdi-download
			        </v-icon>
			      </v-btn>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
		      	<v-row>
		      		<v-col cols="8" class="pt-0">
		      			<!-- Porcentaje y meta actual -->
					      <v-subheader class="pl-0">Actualidad</v-subheader>
		      			<div class="d-flex align-center">
					        <h1 class="text-4xl font-weight-semibold red--text" v-if="filterContactos.contactos < filterMetas.contactos">
					          #{{ filterContactos.contactos }}
					        </h1>

					        <h1 class="text-4xl font-weight-semibold green--text" v-else>
					          #{{ filterContactos.contactos }}
					        </h1>

					        <div class="d-flex align-center mb-n3">
					          <v-icon size="40" color="green" v-if="filterContactos.contactos > filterContactos.contactosAnteriores"> mdi-menu-up </v-icon>
					          <v-icon size="40" color="red"   v-else> mdi-menu-down</v-icon>
					          <span class="text-base font-weight-medium green--text ms-n2" v-if="filterContactos.contactos > filterContactos.contactosAnteriores"># {{ filterContactos.contactos - filterContactos.contactosAnteriores }}</span>
					          <span class="text-base font-weight-medium red--text ms-n2" v-else># {{ (filterContactos.contactosAnteriores - filterContactos.contactos) }}</span>
					        </div>
					      </div>
		      		</v-col>

		      		<!-- Meta -->
		      		<v-col cols="4" class="pt-0">
		      			<v-subheader class="pl-0">Meta</v-subheader>
				        <h1 class="text-4xl font-weight-semibold grey--text">
				          {{ filterMetas.contactos }}#
				        </h1>
		      		</v-col>
		      	</v-row>

			      <h4 class="mt-2 font-weight-medium">
			        Comparado al #{{ filterContactos.contactosAnteriores }} de contactos del ciclo anterior 
			      </h4>
			    </v-card-text>
			  </v-card>
  		</v-col>

  		<!-- Calidad Clase -->
  		<v-col cols="12" md="6" lg="4" class="py-0">
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text">Calidad clase</span>
			      <v-spacer></v-spacer>
			      <v-btn icon small class="me-n3 mt-n2">
			        <v-icon>
			          mdi-download
			        </v-icon>
			      </v-btn>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
		      	<v-row>
		      		<v-col cols="8" class="pt-0">
		      			<!-- Porcentaje y meta actual -->
					      <v-subheader class="pl-0">Actualidad</v-subheader>
		      			<div class="d-flex align-center">
					        <h1 class="text-4xl font-weight-semibold red--text">
					          % {{ filterClases.porcentaje }}
					        </h1>
					      </div>
		      		</v-col>

		      		<!-- Meta -->
		      		<v-col cols="4" class="pt-0">
		      			<v-subheader class="pl-0">Meta</v-subheader>
				        <h1 class="text-4xl font-weight-semibold grey--text">
				          {{ filterMetas.calidad_clase }}%
				        </h1>
		      		</v-col>
		      	</v-row>
			    </v-card-text>
			  </v-card>
  		</v-col>

  		<!-- Calidad Clase -->
  		<v-col cols="12" md="6" lg="4" class="py-0">
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text">Calidad teacher</span>
			      <v-spacer></v-spacer>
			      <v-btn icon small class="me-n3 mt-n2">
			        <v-icon>
			          mdi-download
			        </v-icon>
			      </v-btn>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
		      	<v-row>
		      		<v-col cols="8" class="pt-0">
		      			<!-- Porcentaje y meta actual -->
					      <v-subheader class="pl-0">Actualidad</v-subheader>
		      			<div class="d-flex align-center">
					        <h1 class="text-4xl font-weight-semibold red--text" v-if="filterClasesTeacher.porcentaje < filterMetas.calidad_teacher">
					          %{{ filterClasesTeacher.porcentaje }}
					        </h1>

					        <h1 class="text-4xl font-weight-semibold green--text" v-else>
					          %{{ filterClasesTeacher.porcentaje }}
					        </h1>
					      </div>
		      		</v-col>

		      		<!-- Meta -->
		      		<v-col cols="4" class="pt-0">
		      			<v-subheader class="pl-0">Meta</v-subheader>
				        <h1 class="text-4xl font-weight-semibold grey--text">
				          {{ filterMetas.calidad_teacher }}%
				        </h1>
		      		</v-col>
		      	</v-row>
			    </v-card-text>
			  </v-card>
  		</v-col>

  		<!-- Vacantes -->
  		<v-col cols="12" md="6" lg="4" class="py-0">
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text">Vacantes</span>
			      <v-spacer></v-spacer>
			      <v-btn icon small class="me-n3 mt-n2">
			        <v-icon>
			          mdi-download
			        </v-icon>
			      </v-btn>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
		      	<v-row>
		      		<v-col cols="8" class="pt-0">
		      			<!-- Porcentaje y meta actual -->
					      <v-subheader class="pl-0">Actualidad</v-subheader>
		      			<div class="d-flex align-center">
					        <h1 class="text-4xl font-weight-semibold red--text" v-if="vacantes.porcentaje > filterMetas.vacantes">
					          % {{ vacantes.porcentaje }}
					        </h1>

					        <h1 class="text-4xl font-weight-semibold green--text" v-else>
					          % {{ vacantes.porcentaje }}
					        </h1>
					      </div>
		      		</v-col>

		      		<!-- Meta -->
		      		<v-col cols="4" class="pt-0">
		      			<v-subheader class="pl-0">Meta</v-subheader>
				        <h1 class="text-4xl font-weight-semibold grey--text">
				          {{ filterMetas.vacantes }}%
				        </h1>
		      		</v-col>
		      	</v-row>
			    </v-card-text>
			  </v-card>
  		</v-col>

  		<!-- Ingresos, egresos -->
  		<v-col cols="12" class="py-0">
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text">Ingresos - Egresos</span>
			      <v-spacer></v-spacer>
			      <v-btn icon small class="me-n3 mt-n2">
			        <v-icon>
			          mdi-download
			        </v-icon>
			      </v-btn>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
		      	<v-row>
		      		<v-col cols="12" class="pt-0">
		      			<!-- Porcentaje y meta actual -->
					      <v-subheader class="pl-0">Ingreso {{ filterRgresosGenerales.ingreso }}</v-subheader>
		      			<v-progress-linear
						      color="amber"
						      height="25"
						      value="100"
						      rounded
						    ></v-progress-linear>
		      		</v-col>

		      		<!-- Meta -->
		      		<v-col cols="12" class="pt-0">
		      			<v-subheader class="pl-0">Egreso {{ filterRgresosGenerales.egreso }}</v-subheader>
				        <v-progress-linear
						      color="amber"
						      height="25"
						      :value="filterRgresosGenerales.porcentaje"
						      rounded
						    ><strong>{{ Math.ceil(filterRgresosGenerales.porcentaje) }}% Gastado -- META {{ filterMetas.ingreso_egreso }}%</strong></v-progress-linear>
		      		</v-col>
		      	</v-row>
			    </v-card-text>
			  </v-card>
  		</v-col>
    </v-row>

    <v-dialog
			v-model="overlay"
			persistent
			max-width="350px"
		>
			<v-card color="primary" dark>
				<v-card-text class="pt-4">
					Cargando...
					<v-progress-linear
						indeterminate
						color="white"
						class="mb-0"
					></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>

  </v-container>
</template>

<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'

	export default {
	  data(){
	  	return{
		    sucursales:[],
		    sucursal:null,
		    escuela: 1,

		    porcentajeRi:[],
		    ventas:[],
		    contactos:[],
		    clases:[],
		    clasesTeacher:[],
		    vacantes:{},

		    headersIngresos: [
	        { text: 'id_ciclo'  , value: 'id_ciclo' },
	        { text: 'ciclo'     , value: 'ciclo' },
	        { text: 'Ingreso'   , value: 'sumaingreso' , align:'end'},
	        { text: 'Egreso'    , value: 'sumaegreso'  , align:'end'},
	      ],
	      ingresos:[],
	      egresos:[],
	      egresosInbi:[],

	      egresosGenerales:[],

	      metas:[],
	  	}
	  },

	  async created(){
	  	await this.getSucursales()
	  	await this.initialize()
	  	await this.getMetas()
	  	// await this.getDashboard()
	  },

	  computed:{
	  	...mapGetters('login',['getdatosUsuario']),

	  	filterSucursales(){
        if(this.sucursales){
          return this.sucursales.filter( el=> { return el.escuela == this.escuela})
        }
      },

      filterRi( ){
      	const ri = this.porcentajeRi.find( el=> el.id_plantel == this.sucursal.idplantel )
      	if( ri ){
      		return ri
      	}else{
      		return {}
      	}
      },

      filterVentas( ){
      	const venta = this.ventas.find( el=> el.id_plantel == this.sucursal.idplantel )
      	if( venta ){
      		return venta
      	}else{
      		return {}
      	}
      },

      filterContactos( ){
      	const contactos = this.contactos.find( el=> el.id_plantel == this.sucursal.idplantel )
      	if( contactos ){
      		return contactos
      	}else{
      		return {}
      	}
      },

      filterClases( ){
      	const clases = this.clases.find( el=> el.id_plantel == this.sucursal.idplantel )
      	if( clases ){
      		return clases
      	}else{
      		return {}
      	}
      },

      filterClasesTeacher( ){
      	const clasesTeacher = this.clasesTeacher.find( el=> el.id_plantel == this.sucursal.idplantel )
      	if( clasesTeacher ){
      		return clasesTeacher
      	}else{
      		return {}
      	}
      },

      filterRgresosGenerales( ){
      	const egresosGenerales = this.egresosGenerales.find( el=> el.id_plantel == this.sucursal.idplantel )
      	if( egresosGenerales ){
      		return egresosGenerales
      	}else{
      		return {}
      	}
      },

      filterMetas(){
      	const sucursal = this.sucursal.idplantel == 100 ? 0 : this.sucursal.idplantel
      	const meta = this.metas.find( el=> el.id_plantel == sucursal  )
      	if( meta ){
      		return meta
      	}else{
      		return {}
      	}
      },

	  },

	  methods:{
	  	getSucursales() {
        this.overlay = true
        this.sucursales  = []
        return this.$http.get('planteles.all').then((response) => {
          // Llenamos la etapa incial
          this.sucursales = response.data
          this.sucursal   = response.data[0]
          for(const i in this.sucursales){
          	if(this.sucursales[i].idplantel == 0){
          		this.sucursales[i].idplantel = 100
          		this.sucursales[i].plantel = 'TODOS'
          		console.log(this.sucursales[i])
          	}
          }

          this.sucursales.push({idplantel: 0, escuela: 2, plantel:'TODOS'})

          this.overlay = false
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      getMetas() {
      	this.metas = []
        this.$http.get('metas.list').then((response) => {
          this.metas = response.data
          this.overlay = false
        }).catch(error=>{
          this.overlay = false
        }).finally(()=>{
        	this.overlay = false
        })
      },


	  	initialize() {
	  		this.overlay = true
        this.$http.get('data.dashboard.kpi').then((response) => {
          this.porcentajeRi     = response.data.porcentajeRi
          this.ventas           = response.data.ventas
          this.contactos        = response.data.contactos
          this.clases           = response.data.clases
          this.clasesTeacher    = response.data.clasesTeacher
          this.vacantes         = response.data.vacantes
          this.egresosGenerales = response.data.montosEgresos

          this.overlay = false
        }).catch(error=>{
          this.overlay = false
        })
      },
	  },

	}
</script>
<style type="text/css">
	.back-dashboard{
		/*background-image: linear-gradient(120deg, #8fd3f4 0%, #84fab0 100%);*/
		background-image: linear-gradient(to top, #37ecba 0%, #72afd3 100%);
		border-radius: 20px !important;
	}
</style>
<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}


</style>