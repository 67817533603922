<template>
  <v-container fluid>

    <v-row>
      <v-col cols="12">
        <v-card class="transparent elevation-0">
          <v-card-text>

            <v-carousel cycle :height="tamanioPantalla" hide-delimiter-background hide-delimiters show-arrows-on-hover :interval="100000">

              <template v-slot:prev="{ on, attrs }">
                <v-btn icon large color="white" v-bind="attrs" v-on="on" >  <v-icon> mdi-chevron-left </v-icon> </v-btn>  
              </template>

              <v-carousel-item :contain="$vuetify.breakpoint.name === 'xs'? true : false">
                <v-img src="@/assets/images/fondo/k1.jpg" contain aspect-ratio="1.8"></v-img>
              </v-carousel-item>

              <v-carousel-item :contain="$vuetify.breakpoint.name === 'xs'? true : false">
                <v-img src="@/assets/images/fondo/k2.jpg" contain aspect-ratio="1.8"></v-img>
              </v-carousel-item>

              <v-carousel-item :contain="$vuetify.breakpoint.name === 'xs'? true : false">
                <v-img src="@/assets/images/fondo/k3.jpg" contain aspect-ratio="1.8"></v-img>
              </v-carousel-item>

            </v-carousel>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import axios from 'axios';

  import { mapGetters } from 'vuex'

  export default {
    data(){
      return{
        socket: null,
      }
    },

    computed:{

      ...mapGetters('login', ['getdatosUsuario', 'getEscuela']),
      tamanioPantalla () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return 'auto';
          break;
          default:
            return this.$vuetify.breakpoint.height - 160
          break;
        }
      },
    },
  }

</script>

<style scoped>
</style>