<template>
  <v-container class="mt-5 ml-2">
    <v-card>
      
      <v-snackbar
        v-model="snackbar"
        top
        :timeout="3000"
        color="error"
      >
        {{text}}
        <v-btn text color="white" @click.native="snackbar = false">Close</v-btn>
      </v-snackbar>

      <v-row>
        <v-col cols="12">
          <v-toolbar
            flat
          >
            <v-toolbar-title>NI por vendedora</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn color="primary" @click="consultar()" small>Consultar</v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4" lg="4" xl="3">
                <v-autocomplete
                  outlined
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha de incio"
                  outlined
                  dense
                  clearable
                  v-model="fechaini"
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha final"
                  outlined
                  dense
                  clearable
                  v-model="fechafin"
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          
          <!-- Totales -->
          <v-card-text class="pt-0">
            <v-row align="center">
            	<!-- Total de fast -->
            	<v-col cols="12" md="5" lg="3">
            		<v-card color="blue darken-4">
            		  <v-card-title primary-title class="py-1">
            		    <div class="white--text">Inscritos Fast: {{totalFast}}</div>
            		  </v-card-title>
            		</v-card>
            	</v-col>

            	<!-- total de inbi -->
            	<v-col cols="12" md="5" lg="3">
            		<v-card color="blue lighten-1">
            		  <v-card-title primary-title class="py-1">
            		    <div class="white--text">Inscritos Inbi: {{totalInbi}}</div>
            		  </v-card-title>
            		</v-card>
            	</v-col>
            </v-row>
          </v-card-text>
        </v-col>

        <v-col cols="12">
        	<v-card class="elevation-0">
        		<v-card-title primary-title class="py-1">
        		  Inscritos Fast
        		</v-card-title>
        	  <v-card-text>
  		      	<v-data-table
  		          :headers="headers"
  		          :items="tablaFast"
  		          class="elevation-1"
  		          dense
  		          mobile-breakpoint="200"
  		        >
  		          <template v-slot:no-data>
  		            <v-btn
  		              color="primary"
  		              @click="initialize"
  		              small
  		            >
  		              Actualizar
  		            </v-btn>
  		          </template>
  		        </v-data-table>
        	  </v-card-text>
        	</v-card>
        </v-col>

        <v-col cols="12">
        	<v-card class="elevation-0">
        		<v-card-title primary-title class="py-1">
        		  Inscritos Inbi
        		</v-card-title>
        	  <v-card-text>
  		      	<v-data-table
  		          :headers="headers"
  		          :items="tablaInbi"
  		          class="elevation-1"
  		          dense
  		          mobile-breakpoint="200"
  		        >
  		          <template v-slot:no-data>
  		            <v-btn
  		              color="primary"
  		              @click="initialize"
  		              small
  		            >
  		              Actualizar
  		            </v-btn>
  		          </template>
  		        </v-data-table>
        	  </v-card-text>
        	</v-card>
        </v-col>

      </v-row>
    </v-card>

    <!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="300"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      persistent
      max-width="500"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div style="display: table-cell; vertical-align: middle;">
		      				<span v-if="errorBack">
		      					<strong>ERROR</strong> Da clic en "COPIAR" para guardar el error y enviarlo a sistemas
		      				</span>
		      				<span v-else>
		      					<strong>ERROR</strong> {{error}}
		      				</span>
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text large class="mr-2" @click="dialogError = false">Cerrar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="copiarPortapapeles()" v-if="errorBack">{{textoCopiado}}</v-btn>
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="dialogError = false" v-else>Ok</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/eliminar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'

  export default {
    data: () => ({
      snackbar:false,
      headers: [
        { text: 'Vendedora'    , value: 'vendedora' },
        { text: 'Eventos'      , value: 'eventosVendedra' },
        { text: 'Total'        , value: 'total' },
      ],

      desserts: [],
      ciclos:[],
      cicloInf:null,
      cicloSup:null,

      cicloAnterior:[],
      cicloSiguiente:[],

      tablaFast:[],
      tablaInbi:[],
      
      fechaini:null,
      fechafin:null,

      text:'',
      dialogCarga:false,
      totalFast:0,
      totalInbi:0,

      dialogSuccess:false,
      overlay: false,
      dialogError: 	false,
			error: 				'',
			successMensaje:'',
			errorBack:false,
      textoCopiado:'COPIAR',

    }),

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
      	this.overlay = true
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.overlay = false
        }).catch(error=>{
        	// INdicamos que hay un error en el back
        	if(error.body.message){
        		this.error = error.body.message
        	}else{
        		this.error = error.body
        	}
        	this.errorBack = true
        	this.overlay = false
        	this.dialogError = true
        })
      },

      async consultar () {
        if(this.cicloInf != null && this.fechafin == null && this.fechaini == null){
        	let payload = {
	        	escuela: 2, 
	        	idciclo: this.cicloInf.id_ciclo_relacionado,
	        	fechaini:'1000-01-01',
	        	fechafin:'9999-01-01'
	        }
          await this.getInscritosFast(payload)
          // Hacemos cambio de escuela para inbi
          payload.escuela = 1
          await this.getInscritosInbi(payload)
        }else if(this.cicloInf != null && this.fechafin != null && this.fechaini != null){
          let payload = {
	        	escuela: 2, 
	        	idciclo: this.cicloInf.id_ciclo_relacionado,
	        	fechaini:this.fechaini,
	        	fechafin:this.fechafin
	        }
          await this.getInscritosFast(payload)
          // Hacemos cambio de escuela para inbi
          payload.escuela = 1
          await this.getInscritosInbi(payload)
        }else if(this.cicloInf != null && this.fechaini != null && this.fechafin == null){
          let payload = {
	        	escuela: 2, 
	        	idciclo: this.cicloInf.id_ciclo_relacionado,
	        	fechaini:this.fechaini,
	        	fechafin:this.fechaini
	        }
          await this.getInscritosFast(payload)
          // Hacemos cambio de escuela para inbi
          payload.escuela = 1
          await this.getInscritosInbi(payload)
        }else{
          this.error = 'Selecciona los ciclos porfavor'
          this.errorBack = false
          this.dialogError = true
        }
      },


      getInscritosFast ( payload ) {
        this.overlay = true
        this.$http.post('prospectos.inscritos', payload).then(response=>{
        	this.totalFast = response.data.total
        	this.tablaFast = response.data.vendedoras
        	console.log(response.data)
        	this.overlay = false
        }).catch(error=>{
        	// INdicamos que hay un error en el back
        	if(error.body.message){
        		this.error = error.body.message
        	}else{
        		this.error = error.body
        	}
        	this.errorBack = true
        	this.overlay = false
        	this.dialogError = true
        })
      },

      getInscritosInbi ( payload ) {
        this.overlay = true
        this.$http.post('prospectos.inscritos', payload).then(response=>{
        	this.totalInbi = response.data.total
        	this.tablaInbi = response.data.vendedoras
        	console.log(response.data)
        	this.overlay = false
        }).catch(error=>{
        	// INdicamos que hay un error en el back
        	if(error.body.message){
        		this.error = error.body.message
        	}else{
        		this.error = error.body
        	}
        	this.errorBack = true
        	this.overlay = false
        	this.dialogError = true
        })
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportar(){

      	let arrayConcat = this.tablaFast.concat(this.tablaInbi);

      	let excel = []
      	for(const i in arrayConcat){
      		let payload = {
      			iderp: arrayConcat[i].iderp,
      			vendedora: arrayConcat[i].vendedora,
      			inscritos: arrayConcat[i].total,
      			escuela: arrayConcat[i].escuela == 2 ? 'FAST ENGLISH': 'INBI SCHOOL'
      		}
      		excel.push(payload)
      	}

        let data = XLSX.utils.json_to_sheet(excel)
        const workbook = XLSX.utils.book_new()
        const filename = 'KPI'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/
        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },

      copiarPortapapeles () {
	      navigator.clipboard.writeText(this.error).then(() => {
	        this.textoCopiado = 'COPIADO'
	      }).catch(err => {
	      	console.log(err)
	      })
	    }
      
    },
  }
</script>