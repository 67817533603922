<template>
	<v-dialog
    v-model="dialogCalidadProspectos.estatus"
    hide-overlay
    persistent
    max-width="800"
  >
    <v-card>
      <v-card-title primary-title >
        Prospectos buenos
      </v-card-title>

      <v-card-text>
      	<v-row>
      		<v-col cols="12" md="6" lg="4">
		        <v-text-field
		          v-model="fechaini"
		          dense
		          hide-details
		          filled
		          type="date"
		          label="Fecha inicio"
		          clearable
		        ></v-text-field>
      		</v-col>

      		<v-col cols="12" md="6" lg="4">
		        <v-text-field
		          v-model="fechafin"
		          dense
		          hide-details
		          filled
		          type="date"
		          label="Fecha final"
		          clearable
		        ></v-text-field>
      		</v-col>
      	</v-row>
      </v-card-text>

      <v-card-text>
        <v-data-table
          :headers="headersCerrados"
          :items="reporteFast"
          class="elevation-0"
          dense
          :search="search"
        >
	        <template v-slot:item.buenos="{ item }">
	          <v-chip small @click="dialogProspectos = true, prospectos = item.buenos">{{ item.buenos.length }}</v-chip>
	        </template> 

	        <template v-slot:item.malos="{ item }">
	          <v-chip small @click="dialogProspectos = true, prospectos = item.malos">{{ item.malos.length }}</v-chip>
	        </template> 

	        <template v-slot:item.nulos="{ item }">
	          <v-chip small @click="dialogProspectos = true, prospectos = item.nulos">{{ item.nulos.length }}</v-chip>
	        </template> 

	        <template v-slot:item.rechazados="{ item }">
	          <v-chip small @click="dialogProspectos = true, prospectos = item.rechazados">{{ item.rechazados.length }}</v-chip>
	        </template>

	        <template v-slot:item.total="{ item }">
	          <v-chip small @click="dialogProspectos = true, prospectos = item.total">{{ item.total.length }}</v-chip>
	        </template> 
                    
        </v-data-table>
      </v-card-text>

      <v-card-text >
        <v-data-table
          :headers="headersCerrados"
          :items="reporteInbi"
          class="elevation-0"
          dense
          :search="search"
        >
	        <template v-slot:item.buenos="{ item }">
	          <v-chip small @click="dialogProspectos = true, prospectos = item.buenos">{{ item.buenos.length }}</v-chip>
	        </template> 

	        <template v-slot:item.malos="{ item }">
	          <v-chip small @click="dialogProspectos = true, prospectos = item.malos">{{ item.malos.length }}</v-chip>
	        </template> 

	        <template v-slot:item.nulos="{ item }">
	          <v-chip small @click="dialogProspectos = true, prospectos = item.nulos">{{ item.nulos.length }}</v-chip>
	        </template> 

	        <template v-slot:item.rechazados="{ item }">
	          <v-chip small @click="dialogProspectos = true, prospectos = item.rechazados">{{ item.rechazados.length }}</v-chip>
	        </template>

	        <template v-slot:item.total="{ item }">
	          <v-chip small @click="dialogProspectos = true, prospectos = item.total">{{ item.total.length }}</v-chip>
	        </template> 
                    
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error"   dark @click="dialogCalidadProspectos.estatus = false">Cancelar</v-btn>
      </v-card-actions>

      <v-btn 
        color="transparent elevation-0"
        x-small
        absolute
        top
        right
        fab
        class="mt-5 mr-0"
        @click="dialogCalidadProspectos.estatus = false"
       ><v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>


    <!-- Dialog para ver a los prospectos -->	
    <v-dialog
	    v-model="dialogProspectos"
	    hide-overlay
	    persistent
	    max-width="1200"
	  >
	    <v-card>
	      <v-card-title primary-title >
	        Prospectos
	      </v-card-title>

	      <v-card-text>
	        <v-data-table
	          :headers="headersProspectos"
	          :items="prospectos"
	          class="elevation-0"
	          dense
	          :search="search"
	        >

	        	<template v-slot:item.justificacion2="props" v-if="getdatosUsuario.idpuesto !== 18">
			        <v-edit-dialog

			          :return-value.sync="props.item.justificacion2"
			          @save="save(props.item)"
			          @close="close"
			          large
			          save-text="Guardar"
			          cancel-text="Cancelar"
			        >
			          {{ props.item.justificacion2 }}
			          <template v-slot:input>
			            <v-textarea
			            	class="mt-2"
			              v-model="props.item.justificacion2"
			              label="Justificación"
			              filled
			            ></v-textarea>
			          </template>
			        </v-edit-dialog>
			      </template>

	        </v-data-table>
	      </v-card-text>

	      <v-card-actions>
	        <v-spacer></v-spacer>
	        <v-btn color="error"   dark @click="dialogProspectos = false">Cancelar</v-btn>
	      </v-card-actions>

	      <v-btn 
	        color="transparent elevation-0"
	        x-small
	        absolute
	        top
	        right
	        fab
	        class="mt-5 mr-0"
	        @click="dialogProspectos = false"
	       ><v-icon>mdi-close</v-icon>
	      </v-btn>
	    </v-card>

	    <!-- Dialog de carga -->
	    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
	    <carga                v-if="cargar"/>


	  </v-dialog>


    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>


  </v-dialog>
</template>
<script>
import axios from 'axios';

	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'dialogCalidadProspectos',
	  ],

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

			reporteFast:[],      
			reporteInbi:[],      

      headersCerrados: [
			  { text: 'Vendedora',   value: 'vendedora' },
        { text: 'Buenos',      value: 'buenos'    },
			  { text: 'Malos',       value: 'malos'     },
			  { text: 'Nulos',       value: 'nulos'     },
			  { text: 'Rechazados',  value: 'rechazados'},
			  { text: 'Total',       value: 'total'     },
      ],
      search:'',

      fechaini: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fechafin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

			dialogProspectos: false,
			prospectos:[],
			headersProspectos: [
			  { text: 'Folio',     value: 'folio'           },
        { text: 'Prospecto', value: 'nombre_completo' },
			  { text: 'Teléfono' , value: 'telefono'        },
			  { text: 'com. Vend', value: 'justificacion'   },
			  { text: 'com. Mkt' , value: 'justificacion2'  },
      ],
    }),

    watch:{
    	fechafin ( value ){ if( value ){ this.initialize() } },
    	fechaini ( value ){ if( value ){ this.initialize() } }
    },


    async created () {
      await this.initialize()
    },

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    methods:{

    	initialize () {
    		this.reporteFast = []
				this.reporteInbi = []
      	this.cargar = true

      	if( !this.fechaini || !this.fechafin ){ return this.validarErrorDirecto('Favor de seleccionar una fecha') }

      	let tipo = 0 // null
        if(this.getdatosUsuario.idpuesto == 18){
          tipo = 1 // vendedora
        }else{
          tipo = 2 // Director
        }
      	
      	const payload = {
      		fechaini: this.fechaini,
      		fechafin: this.fechafin,
      		id:       this.getdatosUsuario.iderp,
      		tipo,
      	}

        return this.$http.post('reporte.calidad.prospectos', payload ).then(response=>{
        	this.reporteFast = response.data.reporteFast
					this.reporteInbi = response.data.reporteInbi
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      save ( item ) {
        return this.$http.post('prospectos.respuesta.mkt', item ).then(response=>{
        	this.validarSuccess( response.data.message )
        	this.initialize()
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
      
      close () {
        console.log('Dialog closed')
      },

    }
	}
</script>
