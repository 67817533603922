var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{staticClass:"mr-6",attrs:{"bordered":"","color":"green","overlap":"","content":_vm.cantNuevos}},[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"text":"","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)],1)]}}])},[_c('span',[_vm._v("Nuevos prospectos")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"bordered":"","color":"grey","overlap":"","content":_vm.prospectosBasura}},[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"text":"","icon":"","small":""},on:{"click":function($event){_vm.paramResagados.estatus = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}])},[_c('span',[_vm._v("Papelera")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"bordered":"","color":"orange","overlap":"","content":_vm.prospectosCerrados.total}},[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-7",attrs:{"text":"","icon":"","small":""},on:{"click":function($event){_vm.paramCerrados.estatus= true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cloud")])],1)],1)]}}])},[_c('span',[_vm._v("Cerrados o finalizados")])]),_c('v-tooltip',{attrs:{"top":"","color":"green"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"bordered":"","color":"green","overlap":"","content":_vm.buenos.length}},[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-12",attrs:{"text":"","icon":"","small":""},on:{"click":function($event){_vm.paramBuenos.estatus= true}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("@/assets/corazon.png"),"width":"20"}})],1)],1)]}}])},[_c('span',[_vm._v("Prospectos clasificados como buenos")])]),_c('v-tooltip',{attrs:{"top":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"bordered":"","color":"red","overlap":"","content":_vm.malos.length}},[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-7",attrs:{"text":"","icon":"","small":""},on:{"click":function($event){_vm.paramMalos.estatus= true}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("@/assets/dislike.png"),"width":"20"}})],1)],1)]}}])},[_c('span',[_vm._v("Prospectos clasificados como malos")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-8",attrs:{"text":"","icon":"","small":""},on:{"click":function($event){_vm.fechas.estatus = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v("Agregar filtro")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }