var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadowCard"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Becas de inglés")]),_c('v-spacer'),_c('v-chip',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.initialize( )}}},[_vm._v("Actualizar")])],1),_c('v-card-text',{staticClass:"pt-0"},[_c('v-row',{staticClass:"pt-0",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"label":"Buscar","id":"id","outlined":"","dense":"","rounded":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}},[_c('v-radio',{attrs:{"label":"Sin beca","value":1}}),_c('v-radio',{attrs:{"label":"No inscrito","value":2}}),_c('v-radio',{attrs:{"label":"Todos","value":3}})],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filtroBecas,"sort-by":"calories","search":_vm.search},scopedSlots:_vm._u([{key:"item.pago_realizado",fn:function(ref){
var item = ref.item;
return [(item.pago_realizado >= 1399 )?_c('v-chip',{attrs:{"color":"green","dark":"","small":""}},[_vm._v(_vm._s(item.pago_realizado)+" ")]):(item.pago_realizado >= 700   )?_c('v-chip',{attrs:{"color":"orange","dark":"","small":""}},[_vm._v(_vm._s(item.pago_realizado)+" ")]):_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v(_vm._s(item.pago_realizado)+" ")])]}},{key:"item.agregargrupo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","dark":"","small":""},on:{"click":function($event){_vm.alumno = item, _vm.dialogGrupo = true}}},[_vm._v(" Agregar grupo ")])]}},{key:"item.beca",fn:function(ref){
var item = ref.item;
return [(item.beca)?_c('v-chip',{attrs:{"color":"#00D69C","dark":"","small":""}},[_vm._v(" Con beca ")]):(item.id_grupo)?_c('v-chip',{attrs:{"color":"red","dark":"","small":""},on:{"click":function($event){return _vm.solicitarBeca( item )}}},[_vm._v(" Sin beca ")]):_vm._e()]}},{key:"item.inscrito",fn:function(ref){
var item = ref.item;
return [(item.inscrito)?_c('v-chip',{attrs:{"color":"#00D69C","dark":"","small":""}},[_vm._v(" Listo ")]):(item.id_grupo)?_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v(" Aún no ")]):_vm._e()]}},{key:"item.persona_beca",fn:function(ref){
var item = ref.item;
return [(item.persona_beca == 'Yo')?_c('v-chip',{attrs:{"color":"green","dark":"","small":""}},[_vm._v(_vm._s(item.persona_beca))]):_c('v-chip',{attrs:{"color":"green","dark":"","small":""}},[_vm._v(_vm._s(item.alguien_mas))])]}},{key:"item.grupo_lms",fn:function(ref){
var item = ref.item;
return [(item.grupo_lms)?_c('v-chip',{attrs:{"color":"green","dark":"","small":""}},[_vm._v("Sí")]):(item.id_grupo)?_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("NO")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}])})],1)],1)],1)],1),(_vm.alumno)?_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialogGrupo),callback:function ($$v) {_vm.dialogGrupo=$$v},expression:"dialogGrupo"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Agregar grupo ")]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.grupos,"label":"Grupo","item-text":"grupo","item-value":"id_grupo","filled":"","clearable":"","dense":""},model:{value:(_vm.alumno.id_grupo),callback:function ($$v) {_vm.$set(_vm.alumno, "id_grupo", $$v)},expression:"alumno.id_grupo"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","dark":"","small":"","rounded":""},on:{"click":function($event){_vm.dialogGrupo = false, _vm.alumno = null}}},[_vm._v(" Cancelar ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":"","small":"","rounded":""},on:{"click":function($event){return _vm.guardar()}}},[_vm._v(" Agree ")])],1)],1)],1):_vm._e(),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar)?_c('carga'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }