<template>
	<v-container class="mt-5">
	  <v-row>
	  	<v-col cols="12">
	  		<v-card>
	  		  <div class="wrapper">
					  <canvas id="signature-pad" class="signature-pad" :width="width" :height="height"></canvas>
					</div>
					<v-card-actions>
					  <v-btn id="save-png" @click="savePNG()">Save as PNG</v-btn>
						<v-btn id="clear" @click="limpiar()">Clear</v-btn>
					</v-card-actions>				
	  		</v-card>			

	  	</v-col>
	  </v-row>
	</v-container>
</template>
<script>
import axios from 'axios';
	import SignaturePad from "signature_pad"; 

	export default {
    data: () => ({
    	signaturePad:null,
    	file:'',
    }),

    mounted () {
			let canvas = document.getElementById('signature-pad');
			this.signaturePad = new SignaturePad(canvas);
    },

    methods:{
    	async savePNG (){
    		alert(this.signaturePad.isEmpty())

    		let url = this.signaturePad.toDataURL();

    		// Archivo blob binario
				let blobBin = atob(url.split(',')[1]);
				let array = [];
				// Recorremos el resultado
				for(let i = 0; i < blobBin.length; i++) {
			    array.push(blobBin.charCodeAt(i));
				}

				// Convertimos a bloc
				const file = new Blob([new Uint8Array(array)], {type: 'image/png'});
				// Lo convertimos a archivo para poder agregarle una imagen
				const file2 = new File([file], "imagen.png",{ type: "image/png" })


    		// Creamos el documento
    		let formData = new FormData();
    		// agregamos el documento
    		formData.append("file", file2);
    		// Guardamos el documento
    		this.$http.post('imagen-contrato', formData).then(response=>{
    			console.log(response)
    		})
    	},

    	limpiar (){
				this.signaturePad.clear();
    	}
    },

    computed:{
    	width(){
    		return this.$vuetify.breakpoint.width
    	},
    	height(){
    		return this.$vuetify.breakpoint.height - 200
    	}
    }
  }
</script>

<style>
	/*.wrapper {
	  position: relative;
	  width: 400px;
	  height: 200px;
	  -moz-user-select: none;
	  -webkit-user-select: none;
	  -ms-user-select: none;
	  user-select: none;
	}*/

/*	.signature-pad {
	  position: absolute;
	  left: 0;
	  top: 0;
	  width:400px;
	  height:200px;
	  background-color: white;
	}*/
</style>