<template>
  <v-container fluid class="mt-5 pl-6 pr-6">
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-body-1">Inscripciones Marketing Sucursal</span>
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn color="primary" @click="initialize()" small>Consultar</v-btn>
          </v-card-title>

          <v-card-text>
          	<span class="text-h6">FAST</span>
            <v-data-table
              :headers="headers"
              :items="tablaFast"
              sort-by="calories"
              class="elevation-0 mt-4"
              hide-default-footer
              :items-per-page="20"
              dense
              mobile-breakpoint="200"
              id="kpi"
            >
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                  small
                >
                  Actualizar
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>


          <v-card-text>
          	<span class="text-h6">INBI</span>
            <v-data-table
              :headers="headers"
              :items="tablaInbi"
              sort-by="calories"
              class="elevation-0 mt-4"
              hide-default-footer
              :items-per-page="20"
              dense
              mobile-breakpoint="200"
              id="kpi"
            >
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                  small
                >
                  Actualizar
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'
  // Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'


  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],


    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      snackbar:false,
      headers: [
        { text: 'Ciclo'       , value: 'ciclo' },
        { text: 'Total'       , value: 'total' },
        { text: 'Marketing'   , value: 'marketing' },
        { text: 'Sucursal'    , value: 'ventas' },
      ],

      tablaFast: [],
      tablaInbi:[],

    }),

    async created () {
      await this.initialize()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.tablaFast = []
        this.tablaInbi = []
        this.$http.post('inscritos.marketing.recpcionista').then(response=>{
          this.tablaFast = response.data.fast
          this.tablaInbi = response.data.inbi
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      exportar(){
       
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){

        let dataAnt = XLSX.utils.json_to_sheet(this.tablaFast)
        let dataACt = XLSX.utils.json_to_sheet(this.tablaInbi)

        const workbook = XLSX.utils.book_new()
        const filename = 'inscripciones'
        XLSX.utils.book_append_sheet(workbook, dataAnt, 'inscritos_fast')
        XLSX.utils.book_append_sheet(workbook, dataACt, 'inscritos_inbi')

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });
      },

      
    },
  }
</script>
