<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pa-0">
          <div>
            <div class="text-subtitle-1"><strong>Hermanos</strong></div>
            <v-divider class="mb-4"></v-divider>
            <div  v-if ="hermanos.length">
                <p v-for="hermano in hermanos"> {{hermano.matricula}} - {{hermano.nombre_completo}}
                  <v-icon @click="eliminar(hermano.matricula)" class="ms-5" color="secondary">
                      mdi-close
                  </v-icon>
                </p>
                
            </div>
            <div v-else>
                <p>{{mensaje}}</p>
            </div>
          </div>
          <div v-if="guardar">
          <div class="text-subtitle-1">
            <strong>Agregar Hermanos</strong>
            
            </div>
          <v-divider class="mb-4"></v-divider>
          <v-row justify="center" align="center" >
            <v-col cols="10" class="pe-0">
                <v-text-field dense v-model="hermano.matricula"
                label="Matricula" filled hide-details
                 width="25"></v-text-field>
            </v-col>
            <v-col cols="2" class="pe-0">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" @click="buscar()" color="primary">
                          mdi-magnify
                      </v-icon>
                        <!-- <v-btn fab x-small color="primary" v-bind="attrs" v-on="on" dark @click="buscar()">
                            <v-icon dark>
                                mdi-magnify
                            </v-icon>
                        </v-btn> -->
                    </template>
                <span>Buscar alumno</span>
                </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="this.hermano.id">
            <v-col cols="8" class="p2-0">
              <p v-if="hermano.nombre" class="ma-0"><strong>{{ hermano.nombre }}</strong></p>
              <p v-else class="ma-0">No se encontro alumno con esta matricula</p>
            </v-col>
            <v-col cols="2" class="pe-0" align="end">
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn fab x-small color="secondary" v-bind="attrs" v-on="on" dark @click="cancelar()">
                        <v-icon dark>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </template>
                <span>Cancelar</span>
                </v-tooltip>
              </v-col>
            <v-col cols="2" class="pe-0">
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn fab x-small color="primary" v-bind="attrs" v-on="on" dark @click="nuevo()">
                        <v-icon dark>
                            mdi-check
                        </v-icon>
                    </v-btn>
                </template>
                <span>Confirmar</span>
                </v-tooltip>
              </v-col>
          </v-row>
             
        </div>
        </v-col>
      </v-row>
  
      <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
      <carga                v-if="cargar"/>
    </v-container>
  </template>
  <script>
  import axios from 'axios';
  import { mapGetters } from "vuex";
  // Componentes
  import Alerta from "@/components/alertas/Alerta.vue";
  import carga from "@/components/alertas/carga.vue";
  
  // Componentes
  export default {
    components: {
      Alerta,
      carga,
    },
  
    props: ["editedItem"],
    data: () => ({
      // Alertas
      parametros: {
        dialogError: false,
        mensaje: "",
        color: "",
      },
      respuestaAlerta: false,
      loader: true,
      cargar: false,
      guardar:false,

      id_alumno: 0,
      hermano: [],
      mensaje: "",
      hermanos: []
    }),
  
    computed: {
      ...mapGetters("login", ["getdatosUsuario"]),
    },
  
    watch: {
      editedItem() {
        this.initialize();
      },
    },
  
    created() {
      this.id_alumno = this.editedItem.id_alumno;
      this.initialize();
      const idPuestoValido = [12, 11, 58, 50, 51, 39, 56, 43];
      this.guardar = idPuestoValido.includes(this.getdatosUsuario.idpuesto);
    },
  
    methods: {
      initialize() {
        this.cargar = true
        this.$http
        .get("inscripciones.hermanos/" + this.id_alumno)
        .then((response) => {
            if(!response.data.id_tutor)
                this.guardar = false
            if(response.data.message){
                this.mensaje = response.data.message
            } else {
                console.log(response.data.datosHermanos)
                this.hermanos = response.data.datosHermanos
            }
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
      },

      nuevo(){
        if(!this.hermano.id){
          this.validarErrorDirecto("Buscar al alumno primero")
          console.log("Buscar al alumno primero")
        }else {
          this.cargar = true;
          const payload = {
            idalumno: this.id_alumno,
            idhermano: this.hermano.id,
            idusuario : this.getdatosUsuario.idusuario
          };
          this.$http
          .post("inscripciones.agregarHermano", payload)
          .then((response) => {
            console.log(response.data)
            this.validarSuccess(response.data)
            this.hermano = []
            this.initialize()
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
        }
      },
      buscar(){
        let string = this.hermano.matricula;
        let partes = string.split("-");
        string = partes[1];
        this.hermano.id = string
        if(this.hermano.id == this.id_alumno){
          this.validarErrorDirecto("La matricula es la misma que la del alumno")
          this.hermano.id= 0
        } else {
          this.cargar = true;
          this.$http
          .get("inscripciones.hermanosGetAlumno/" + this.hermano.id)
          .then((response) => {
            this.hermano.nombre = response.data.nombre
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
        }
      },
      cancelar(){
        this.hermano = []
      },
      eliminar(id){
        let string = id;
        let partes = string.split("-");
        string = partes[1];
        const payload = {
          idhermano: string
        }
        this.$http
          .post("inscripciones.eliminarHermano", payload)
          .then((response) => {
            this.initialize()
            this.validarSuccess(response.data);
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      },

      validarSuccess(mensaje) {
        this.cargar = false;
        this.parametros.mensaje = mensaje;
        this.parametros.dialogError = true;
        this.parametros.color = "success";
        this.respuestaAlerta = true;
      },
  
      validarError(value) {
        this.cargar = false;
        if (value.status == 400 || value.status == 500) {
          this.parametros.mensaje = value.body.message;
          this.parametros.dialogError = true;
          this.parametros.color = "#FF2C2C";
          this.respuestaAlerta = true;
        }
      },
  
      validarErrorDirecto(mensaje) {
        this.cargar = false;
        this.parametros.mensaje = mensaje;
        this.parametros.dialogError = true;
        this.parametros.color = "#FF2C2C";
        this.respuestaAlerta = true;
      },
    },
  };
  </script>
  
  