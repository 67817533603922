<template>
  <v-container class="mt-4 ml-2">

	  <v-row justify="center">
	    <v-col cols="12">
	    	<v-card class="shadowCard">
	    	  <v-card-title primary-title>
	    	    Codigos Asignados
	    	    <v-spacer></v-spacer>

	    	    <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( alumnos  , 'CODIGOS_ASIGNADOS')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="getCodigos()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	    	  </v-card-title>

	        <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="6" lg="5">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">

	              <v-data-table
							    :headers="tableHeader"
							    :items="alumnos"
	                item-key="name"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            dense
	              >

	              	<template v-slot:item.status="props">
							      <v-edit-dialog
							        :return-value.sync="props.item.status"
							        @save="save(props.item)"
							        @cancel="cancel"
							        @open="open"
							        @close="close"
							      >
							   			<span v-if="!props.item.status"> Clic para agregar un status </span>
							        {{ props.item.status }}
							        <template v-slot:input>
							        	<v-select
				                  outlined
				                  dense
				                  clearable
				                  v-model="props.item.status"
				                  :items="['Completado']"
				                  label="Selecciona el alumno"
				                  persistent-hint
				                  class="mt-4"
				                >
				                </v-select>
							        </template>
							      </v-edit-dialog>
							    </template>

	              	<template v-slot:item.nivel="props">
							      <v-edit-dialog
							        :return-value.sync="props.item.nivel"
							        @save="save(props.item)"
							        @cancel="cancel"
							        @open="open"
							        @close="close"
							      >
							   			<span v-if="!props.item.nivel"> Clic para agregar un nivel </span>
							        {{ props.item.nivel }}
							        <template v-slot:input>
				                <v-autocomplete
				                  outlined
				                  dense
				                  clearable
				                  v-model="props.item.nivel"
				                  :items="['A1','A2','B1','B2','C1','C2']"
				                  label="Selecciona el alumno"
				                  persistent-hint
				                  item-text="nombre_completo"
				                  item-value="nombre_completo"
				                  class="mt-4"
				                >
				                </v-autocomplete>
							        </template>
							      </v-edit-dialog>
							    </template>

	              </v-data-table>
	            </v-col>
	          </v-row>

	        </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
	import UploadExcelComponent from '@/components/UploadExcel.vue'

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
      UploadExcelComponent
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

    	alumnos:[],
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      search:'',

      conceptos:[{ idconcepto:1 , nombre: 'PRESUPUESTO'}, {idconcepto:2, nombre:'PAGO'}, {idconcepto:3, nombre:'REPORTE'},],
      concepto:{},

      municipio:{},

      tableHeader: [ 
				{ text:'codigo'     , value:'codigo'},
        { text:'nombre'     , value:'nombre'},
        { text:'status'     , value:'status'},
        { text:'id alumno'  , value:'id_alumno'},
        { text:'Nivel'      , value:'nivel'},
      ],   

      tableData: [],  // DATOS A MOSTRAR 
      reportes : [],  // TABLA REPORTES

      alerta: { activo: false, texto:'', color:'error'},
      overlay: false,
      
      codigos:[]
	  }),

	  async created(){
	  	await this.getCodigos()
	  },

	  computed:{
	    ...mapGetters('login',['getdatosUsuario']),
	    tamanioPantalla () {
	      return this.$vuetify.breakpoint.height -380
	    },
	  },

	  methods: {

	  	getCodigos () {
	  		this.alumnos = []
	  		return this.$http.get('academico.alumnos.codigos').then( response =>{
	    		this.alumnos = response.data
	      }).catch( error =>{
	        this.alerta = { activo: true, texto: error.body, color:'error' };
	      }).finally(()=>{
	        this.overlay = false;
	      })
	  	},

	    validarCodigos () {
	    	this.tableData = []
	    	let payload = { codigos: this.codigos }
	    	this.$http.post('academico.getcodigos', payload).then( response =>{
	    		this.tableData = response.data
	      }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
	    },

	    async save (item) {
	      const { idcertificacion, nivel, id_plantel, status } = item
	      await this.updateCodigo( idcertificacion, nivel, id_plantel, status )

	      this.snack = true
	      this.snackColor = 'success'
	      this.snackText = 'Data saved'
	    },

	    updateCodigo ( idcertificacion, nivel, id_plantel, status ) {
	      let payload = {
	      	idcertificacion,
	        nivel,
	        id_plantel,
	        status
	      }
	      return this.$http.put(`academico.codigo.nivel`, payload).then(response=>{
	        this.validarSuccess(response.data.mensaje)
	      }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
	    },

	    cancel () {
	      this.snack = true
	      this.snackColor = 'error'
	      this.snackText = 'Canceled'
	    },

	    open () {
	      this.snack = true
	      this.snackColor = 'info'
	      this.snackText = 'Dialog opened'
	    },

	    close () {
	      console.log('Dialog closed')
	    },

	  }
	}
</script>