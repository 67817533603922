<template>
  <v-container class="mt-4 ml-2">
    <v-row>
      <v-col cols="12">
	      <v-card class="shadowCard">
	      	<v-card-title class="text-subtitle-1">
		        <v-toolbar-title>Calificaciones</v-toolbar-title>
		        
		        <v-spacer></v-spacer>

		        <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( filterAlumnos  , 'CALIFICACIONES')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

		        <v-btn 
		        	color="red darken-4" 
		        	dark 
		        	@click="dialogSoporte = true" 
		        	small 
		        	class="mr-2"
		        	tile
		        >
		        	<v-icon left small>mdi-phone-log</v-icon>
		      		Soporte
		      	</v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="getGruposAlumnos()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>


	      	</v-card-title>

	        <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
	              <v-autocomplete
	                filled
	                dense
	                clearable
	                v-model="cicloInf"
	                :items="ciclos"
	                label="Selecciona ciclo"
	                persistent-hint
	                hide-details
	                return-object
	                item-text="ciclo"
	                item-value="id_ciclo"
	              >
	              </v-autocomplete>
	            </v-col>
	          </v-row>

		        <!-- Apartado de las secciones de los tabs -->
            <v-row>
	            <v-col cols="12" lg="12">
	              <v-card class="elevation-0">
	                <v-row justify="space-between">
	                	<v-col cols="12" md="6" lg="4">
		                	<v-radio-group
									      v-model="option"
									      row
									    >
									      <v-radio
									        label="Todos"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="Reprobados"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>
	                	</v-col>

	                  <v-col cols="12" md="6" lg="5">
	                    <v-text-field
	                      label="Buscar"
	                      v-model="search1"
	                      filled
	                      dense
	                      append-icon="mdi-magnify"
	                    ></v-text-field>
	                  </v-col>
	                </v-row>

	              	<v-data-table
	                  :search="search1"
								    dense
								    :headers="headers"
								    :items="filterAlumnos"
								    item-key="id"
								    class="elevation-0"
								  >

								  	<template v-slot:item.matricula="{ item }">
								  		{{ item.matricula }}
								    </template>

								  	<template v-slot:item.calificacion_final_primera_oportunidad="{ item }">
								  		<v-chip color="red" dark small v-if="item.calificacion_final_primera_oportunidad && item.calificacion_final_primera_oportunidad < 70">
									      <span>{{ item.calificacion_final_primera_oportunidad }}</span>
								  		</v-chip>

								  		<span v-else>{{ item.calificacion_final_primera_oportunidad }}</span>
								    </template>

								    <template v-slot:item.calificacion_final_segunda_oportunidad="{ item }">
								  		<v-chip color="red" dark small v-if="item.calificacion_final_segunda_oportunidad && item.calificacion_final_segunda_oportunidad < 70">
									      <span >{{ item.calificacion_final_segunda_oportunidad }}</span>
								  		</v-chip>

								  		<span v-else>{{ item.calificacion_final_segunda_oportunidad }}</span>
								    </template>
								  	
								  	<template v-slot:item.ver="{ item }">
								  		<v-chip color="green" dark small @click="buscarCalificacion( item )">
									      <v-icon
									        small
									      >
									        mdi-magnify
									      </v-icon>
								  		</v-chip>
								    </template>
								  </v-data-table>
	              </v-card>
	            </v-col>
            </v-row>
	        </v-card-text>
	      </v-card>		
      </v-col>
    </v-row>

    <v-dialog
    	v-if="verCalificaciones"
      v-model="dialogAlumno"
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h5">
          Calificaciones
        </v-card-title>
        <v-card-text>
        	<v-tabs
			      v-model="tab"
			      background-color="transparent"
			      color="basil"
			      grow
			    >
			      <v-tab
			        v-for="item in items"
			        :key="item"
			      >
			        {{ item }}
			      </v-tab>
			    </v-tabs>

			    <v-tabs-items v-model="tab">

			    	<!-- Asistencias -->
			      <v-tab-item>
			        <v-card
			          color="basil"
			          flat
			        >
			        	<v-card-title primary-title>
			        	  {{ calificaciones.asistencias.pts_total_asistencia }} pts de {{ calificaciones.asistencias.limite }} pts
			        	  <v-progress-linear
							      color="orange"
							      height="25"
							      style="border-radius: 50px;"
							      :value="((calificaciones.asistencias.pts_total_asistencia / calificaciones.asistencias.limite ) * 100).toFixed( 1 )"
							    >
							      <template v-slot:default="{ value }">
							        <strong>{{ ((calificaciones.asistencias.pts_total_asistencia / calificaciones.asistencias.limite )*100).toFixed( 1 ) }}%</strong>
							      </template>
							    </v-progress-linear>
			        	</v-card-title>		
			          <v-card-text>
			          	<v-data-table
			        			width="100%"
								    :headers="headersAsistencias"
								    :items="calificaciones.asistencias.desgloce"
								    :items-per-page="calificaciones.asistencias.desgloce.length"
								    class="elevation-0"
								    :mobile-breakpoint="100"
								    hide-default-footer
								    fixed-header
								    dense
								    height="400"
								  >
					    			<template v-slot:item.valor_text="{ item }">
					    				<v-chip small rounded :color="item.color">{{item.valor_text}}</v-chip>
					    			</template>
								  </v-data-table>
			          </v-card-text>
			        </v-card>
			      </v-tab-item>

			      <!-- Ejercicios -->
			      <v-tab-item>
			        <v-card
			          color="basil"
			          flat
			        >
			        	<v-card-title primary-title>
			        	  {{ calificaciones.ejercicios.pts_total_ejercicios }} pts de {{ calificaciones.ejercicios.limite }} pts

			        	  <v-progress-linear
							      color="orange"
							      height="25"
							      style="border-radius: 50px;"
							      :value="((calificaciones.ejercicios.pts_total_ejercicios / calificaciones.ejercicios.limite ) * 100).toFixed( 1 )"
							    >
							      <template v-slot:default="{ value }">
							        <strong>{{ ((calificaciones.ejercicios.pts_total_ejercicios / calificaciones.ejercicios.limite)*100).toFixed( 1 ) }}%</strong>
							      </template>
							    </v-progress-linear>
			        	</v-card-title>	

			          <v-card-text>
			          	<v-data-table
			        			width="100%"
								    :headers="headersEjercicios"
								    :items="calificaciones.ejercicios.desgloce"
								    :items-per-page="calificaciones.ejercicios.desgloce.length"
								    class="elevation-0"
								    :mobile-breakpoint="100"
								    hide-default-footer
								    fixed-header
								    dense
								    height="400"
								  >
					    			<template v-slot:item.valor_text="{ item }">
					    				<v-chip small rounded :color="item.color">{{item.valor_text}}</v-chip>
					    			</template>
								  </v-data-table>
			          </v-card-text>
			        </v-card>
			      </v-tab-item>

			      <!-- Examenes -->
			      <v-tab-item>
			        <v-card
			          color="basil"
			          flat
			        >
			        	<v-card-title primary-title>
			        	  {{ calificaciones.examenes.pts_total_examenes }} pts de {{ calificaciones.examenes.limite }} pts
			        	  <v-progress-linear
							      color="orange"
							      height="25"
							      style="border-radius: 50px;"
							      :value="((calificaciones.examenes.pts_total_examenes / calificaciones.examenes.limite ) * 100).toFixed( 1 )"
							    >
							      <template v-slot:default="{ value }">
							        <strong>{{ ((calificaciones.examenes.pts_total_examenes / calificaciones.examenes.limite )*100).toFixed( 1 ) }}%</strong>
							      </template>
							    </v-progress-linear>
			        	</v-card-title>	
			          <v-card-text>
			          	<v-data-table
			        			width="100%"
								    :headers="headersEjercicios"
								    :items="calificaciones.examenes.desgloce"
								    :items-per-page="calificaciones.examenes.desgloce.length"
								    class="elevation-0"
								    :mobile-breakpoint="100"
								    hide-default-footer
								  >
					    			<template v-slot:item.valor_text="{ item }">
					    				<v-chip small rounded :color="item.color">{{item.valor_text}}</v-chip>
					    			</template>
								  </v-data-table>
			          </v-card-text>
			        </v-card>
			      </v-tab-item>

			      <v-tab-item>
			        <v-card
			          color="basil"
			          flat
			        >
			          <v-card-text align="center" class="text-center">
			          	<v-progress-circular
			          		align="center"
							      :rotate="90"
							      :size="220"
							      :width="25"
							      :value="calificaciones.calificacion_total"
							      color="pink"
							    >
							      <span class="text-h6">{{ calificaciones.calificacion_total }} pts de 100</span>
							    </v-progress-circular>
			          </v-card-text>
			        </v-card>
			      </v-tab-item>
    			</v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogAlumno = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialogAlumno = false"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de soporte -->
    <v-dialog
      v-model="dialogSoporte"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Botón de soporte automático
        </v-card-title>

        <v-card-text class="black--text">
        	1-. Eliminar<br/>
			      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) Participaciones repetidas<br/>
			      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) Calificaciones repetidas<br/>
			      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c) Alumnos repetidos<br/>
			      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d) Datos repetidos de los alumnos<br/>
			      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e) Avatares repetidos<br/>
			    2-. Actualizar<br/>
			      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) Alumno no aparece en grupo<br/>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="red"
            dark
            small
            rounded
            @click="dialogSoporte = false"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            small
            rounded
            @click="ejecutarLimpieza()"
          >
            Activar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
  </v-container>
</template>
<script>
import axios from 'axios';
	import VueApexCharts from 'vue-apexcharts'

	import { mapGetters } from 'vuex'
  import XLSX from 'xlsx'

  // Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {
  	components:{
      Alerta,
      carga,
      VueApexCharts
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	dialogSoporte: false,
    	tab: null,
      items: [
        'Participaciones', 'Ejercicios', 'Exámanes', 'Total',
      ],

      search1:'',

      headers: [
        { text: 'Matricula'  , value: 'matricula'             , width: '25%'},
        { text: 'grupo'      , value: 'grupo'                 , width: '30%'},
        { text: 'Pro. Pago'  , value: 'fecha_proximo_pago'    , width: '15%%'},
        { text: 'Ulti. Movi' , value: 'ultimo_movimiento'     , width: '15%%'},
        { text: '1era'       , value: 'calificacion_final_primera_oportunidad'     , width: '6%'},
        { text: '2da'        , value: 'calificacion_final_segunda_oportunidad'     , width: '6%'},
        { text: 'ver'        , value: 'ver'                   , width: '5%'},
      ],

      gruposAlumnos:[],
			horarios:null,
			cicloInf: 		null,
      ciclos:  			[],

      // Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
      dialogAlumno:false,
      calificaciones:{},
      verCalificaciones: false,

	  	cardsCalificaciones:[
	  		{ text: 'Participaciones', series: [0], chartOptions: null, icono: 'mdi-account', clase: 'avataresAsistencias' },
	  		{ text: 'Ejercicios',  series: [0], chartOptions: null, icono: 'mdi-file-document', clase: 'avataresEjercicios' },
	  		{ text: 'Exámenes',    series: [0], chartOptions: null, icono: 'mdi-file-check', clase: 'avataresExamenes' },
	  		{ text: 'Total',       series: [0], chartOptions: null, icono: 'mdi-chart-arc', clase: 'avataresTotal' },
	  	],

	  	headersAsistencias: [
        { text: 'Fecha',  value: 'fecha_formato'  , class: 'text-uppercase grey--text text-caption' },
        { text: 'Valor',  value: 'valor_text'     , class: 'text-uppercase grey--text text-caption text-center', align: 'center' },
      ],

      headersEjercicios: [
        { text: 'Lección'     ,  value: 'session'          , class: 'text-uppercase grey--text text-caption' },
        { text: 'Calificación',  value: 'calificacion'     , class: 'text-uppercase grey--text text-caption text-center', align: 'center' },
      ],

	  	asistencias:[],

	  	show: false,

    	respuestaAlerta: false,

      parametros:{
      	dialogError: false,
      	mensaje: ''
     	},

     	option: 1
    }),

    watch: {
      cicloInf () {
        if(this.cicloInf){
          this.getGruposAlumnos()
        }
      },
    },

   
    created () {
      this.initialize()
    },

    methods: {
    	initialize () {
      	this.cargar = true
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getGruposAlumnos () {
      	if(this.cicloInf){
	      	// vaciamos el horario
	      	this.gruposAlumnos = []
	      	// Activamos el loader
	    		this.cargar = true
	    		// Hacemos la peticios
	    		this.$http.get(`lms.catalogos.gruposalumnos.all/${ this.cicloInf.id_ciclo }/${ this.cicloInf.id_ciclo_relacionado }`).then(response=>{
	    			// Guardamos la respuesta
	          this.gruposAlumnos = []
	          // Quitamos el loader
	          for( const i in response.data.gruposAlumnosFast){
	          	response.data.gruposAlumnosFast[i]['escuela'] = 2
	          }

	          for( const i in response.data.gruposAlumnosInbi){
	          	response.data.gruposAlumnosInbi[i]['escuela'] = 1
	          }

	          let alumnos = response.data.gruposAlumnosFast.concat( response.data.gruposAlumnosInbi )

	          for( const i in alumnos ){
	          	const { matricula, nombre } = alumnos[i]

	          	alumnos[i].matricula = `[ ${matricula} ] 
	          	${nombre}`
	          }

	          if(this.getdatosUsuario.idpuesto == 19 ){ // Administrador
	          	let mapIdPlanteles  = this.getdatosUsuario.planteles.map((registro) => registro.idplantel);
	          	this.gruposAlumnos = alumnos.filter( el => { return mapIdPlanteles.includes( el.id_plantel )})
			      }else{
			      	this.gruposAlumnos = alumnos
			      }

	          this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
      	}else{
      		this.validarErrorDirecto('Favor de seleccionar un ciclo')
      	}
      },

      buscarCalificacion ( alumno ){
      	this.cargar = true
      	// Hacemos la peticios
    		this.$http.post(`calificaciones.alumno`, alumno).then(response=>{
    			this.calificaciones = response.data

    			const califAsistencias   = this.calificaciones.asistencias.pts_total_asistencia
			  	const califAsistenciasL  = this.calificaciones.asistencias.limite
			  	const califEjercicios    = this.calificaciones.ejercicios.pts_total_ejercicios
			  	const califEjerciciosL   = this.calificaciones.ejercicios.limite
			  	const califExamenes      = this.calificaciones.examenes.pts_total_examenes
			  	const califExamenesL     = this.calificaciones.examenes.limite
			  	const calificacion_total = this.calificaciones.calificacion_total

			  	this.verCalificaciones = true
    			this.dialogAlumno = true
          this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
      },

      async ejecutarLimpieza( ){
      	if(this.cicloInf){
      		this.dialogSoporte = false
	      	this.cargar = true
	      	await this.limpiarDatosRepetidos()
	      	this.cargar = true
	      	await this.actualizarGrupoAlumno()
	      	
    			this.getGruposAlumnos()
      	}else{
      		this.validarErrorDirecto('Favor de seleccionar un ciclo')
      	}
      },

      limpiarDatosRepetidos ( alumno ){
      	this.cargar = true
      	// Hacemos la peticios
    		return this.$http.post(`lms.soporte.limpieza` ).then(response=>{
    			this.validarSuccess( response.data.message )
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
      },

      actualizarGrupoAlumno ( alumno ){
      	this.cargar = true
      	// Hacemos la peticios
    		return this.$http.get(`lms.catalogos.gruposalumnos.cron/${ this.cicloInf.id_ciclo }/${ this.cicloInf.id_ciclo_relacionado }`).then(response=>{
    			this.validarSuccess( response.data.message )
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
      },


    },

    computed:{
    	...mapGetters('login',['getdatosUsuario','getEscuela']),

    	filterAlumnos(){
    		if( this.option == 2 ){

    			// Alumnos sin primera oportunidad 
    			return this.gruposAlumnos.filter( el => { 

    				if( el.calificacion_final_primera_oportunidad < 70 && el.calificacion_final_segunda_oportunidad < 70 && !el.grupo.match('CERTI') ){
    					return true 
    				}else{
							    					
    				}
    			})


    		}else{
    			return this.gruposAlumnos
    		}
    	}
    }
  }
</script>

<style>
	.v-snack__content{
		padding: 0px !important;
	}
</style>