<template>
	<v-dialog
    v-model="parametros.estatus"
    hide-overlay
    persistent
    max-width="400"
  >
    <v-card>
      <v-card-title primary-title >
        Reasignar prospecto
      </v-card-title>
      <v-card-text >
        <v-select
          :items="vendedoras"
          v-model="nuevaSucursal"
          return-object
          item-text="plantel"
          item-value="idusuario"
          dense
          :hint="nombreVendedora"
          outlined
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error"   dark @click="parametros.estatus = false">Cancelar</v-btn>
        <v-btn color="primary" dark @click="$emit('click', nuevaSucursal)">Reasignar</v-btn>
      </v-card-actions>
      <v-btn 
        color="transparent elevation-0"
        x-small
        absolute
        top
        right
        fab
        class="mt-5 mr-0"
        @click="parametros.estatus = false"
       ><v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
	export default {
		props:[
			'parametros','vendedoras'
	  ],

    watch:{
      nuevaSucursal(){
        this.nombreVendedora = this.nuevaSucursal.nombre
      },
    },

    data: () => ({
      nuevaSucursal: null,
      nombreVendedora:''
    }),
	}
</script>
