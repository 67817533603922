<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterTickets"
          :item-class="itemRowBackground"
          sort-by="calories"
          class="elevation-6"
          :search="search"
          multi-sort
          >
          
          <template v-slot:item.estatus="{ item }">
            <v-chip small :color="getColor(item.estatus)" dark v-if="item.estatus==1"> Abierto </v-chip>
            <v-chip small :color="getColor(item.estatus)" dark v-if="item.estatus==2"> Asignado </v-chip>
            <v-chip small :color="getColor(item.estatus)" dark v-if="item.estatus==3"> Pendiente </v-chip>
            <v-chip small color="grey" dark v-if="item.estatus==4"> Revisión </v-chip>
            <v-chip small color="green" dark v-if="item.estatus==5"> Respondido </v-chip>
            <v-chip small color="yellow"  v-if="item.estatus==6"> Cerrado </v-chip>
            <v-chip small color="black" dark v-if="item.estatus==7"> Rechazado </v-chip>
          </template>
          
          <template v-slot:item.fecha_apertura="{ item }">
            <span>{{ item.fecha_apertura_correccion }}</span>
          </template>

          <template v-slot:item.id_unidad_negocio="{ item }">
            <v-chip small v-if="item.id_unidad_negocio==1" color="#2EDAD5"> INBI </v-chip>
            <v-chip small v-else color="#000D4A" dark> FAST </v-chip>
          </template>

          <template v-slot:item.historial="{ item }">
            <v-btn color="primary" small @click="historialTicket(item)" icon><v-icon>mdi-file-document</v-icon></v-btn>
          </template>
          
          <template v-slot:item.actions="{ item }">
            <v-btn text small @click="abrirDialogResponder(item)" v-if="item.estatus != 6 && item.estatus != 3 && item.estatus != 5">Responder</v-btn>
          </template>

          <template v-slot:item.fecha_entrega="{ item }">
            <v-chip 
              v-if="item.fecha_entrega" 
              small 
              dark
              :color="item.diferencia_dias < 0 && item.estatus != 6 ? 'red' : 'grey' "
            >
              {{ item.fecha_entrega }}
            </v-chip>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Tickets por Auxi</v-toolbar-title>
              <v-divider class="mx-2" inset vertical></v-divider>
              <v-radio-group v-model="escuela" row class="pt-5">
                <v-radio label="Todos" :value="0"></v-radio>
                <v-radio label="Fast" :value="2"></v-radio>
                <v-radio label="Inbi" :value="1"></v-radio>
              </v-radio-group>
              <v-divider class="mx-2" inset vertical></v-divider>
              <v-checkbox class="pt-5" v-model="urgente" :label="`Urgente`"></v-checkbox>
              <v-divider class="mx-2" inset vertical></v-divider>
              <v-text-field v-model="search" label="Buscar" dense hide-details outlined></v-text-field>

              <v-spacer></v-spacer>
              <v-btn class="mr-2" small dark color="green" @click="exportar()">
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-btn>
              <v-btn color="primary" @click="initialize" small>
                Actualizar
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-radio-group v-model="estatusFiltro" row dense hide-details small>
                <v-radio color="orange" label="Abierto" :value="1"></v-radio>
                <v-radio color="blue" label="Asignado" :value="2"></v-radio>
                <v-radio color="red" label="Pendiente" :value="3"></v-radio>
                <v-radio color="grey" label="Revisión" :value="4"></v-radio>
                <v-radio color="green" label="Respondido" :value="5"></v-radio>
                <v-radio color="yellow" label="Cerrado" :value="6"></v-radio>
                <v-radio color="black" label="Rechazado" :value="7"></v-radio>
                <v-radio label="Todos" :value="8"></v-radio>
              </v-radio-group>
            </v-card-text>    
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" @click="obtenerTickets()" small >
              Actualizar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogMensajes" persistent max-width="800" @click:outside="dialogMensajes = false" @keydown.esc="dialogMensajes = false">
      <v-card style="position: relative">
        <v-card-title primary-title>
          Agregar nota
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogMensajes = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <span v-for="(mensaje, i) in mensajes" :key="i">

            <!-- <label class="black--text" v-if="alumnos.length && i==0"><b>Alumnos</b></label>
            <v-data-table
              v-if="alumnos.length && i==0"
              :headers="headersAlumnos"
              :items="alumnos"
              class="elevation-0 mt-4 mb-4"
              :mobile-breakpoint="100"
              dense
              hide-default-footer
            >
            </v-data-table> -->

            <v-row v-if="mensaje.id_usuario != getdatosUsuario.iderp">
              <v-col cols="12">
                <v-card class="ml-6 pa-3 mt-2" max-width="400" color="#f2f2f2" style="border-radius: 15px; border-top-left-radius: 0">
                  <div class="text-subtitle-1">{{ mensaje.nota }} </div>
                  <v-card-actions class="pa-0">
                    <div class="text-caption blue--text">{{ mensaje.nombre_completo }}</div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="mensaje.id_usuario == getdatosUsuario.iderp">
              <v-col cols="12" align="right">
                <v-card class="mr-6 mt-2 pa-3" max-width="400" dark color="#0465ac" shaped style="border-radius: 15px; border-top-right-radius: 0;">
                  <div class="text-subtitle-1">{{ mensaje.nota }}</div>
                </v-card>
              </v-col>
            </v-row>
          </span>
        </v-card-text>

        <v-card-text class="pb-0 pt-6">
          <v-text-field
            v-model="mensajeRechazo"
            :append-outer-icon="mensajeRechazo ? 'mdi-send' : ''"
            outlined
            clearable
            label="Message"
            type="text"
            @click:append-outer="enviarMensaje()"
            hide-details
            dense
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialog = true,dialogMensajes = false">Cancelar</v-btn>
          <v-btn color="success" text @click="enviarMensaje()">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Visualizar historial -->
    <verTicketAux @close="closeModal" :isOpen="dialogVerTicketAux" :historial="historial" :ticket="ticket" :alumnos="alumnos"></verTicketAux>
    <responderAuxTicket @close="closeModal" @recargar="obtenerTickets" @notas="cargarNotas" :isOpen="dialogResponderAuxTicket" :historial="historial" :notas="mensajes" :ticket="ticket" :alumnos="alumnos"></responderAuxTicket>
    
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
  </v-container>
</template>
<script>
import axios from 'axios';
  var moment = require('moment');
  moment.locale('es');
  import {mapGetters, mapActions} from 'vuex'
  import funcionesExcel  from '@/mixins/funcionesExcel'
  import validarErrores  from '@/mixins/validarErrores'

  import responderAuxTicket     from '@/components/tickets/responderAuxTicket.vue'
  import verTicketAux           from '@/components/tickets/verTicketAux.vue'

  import Alerta                 from '@/components/alertas/Alerta.vue'
  import carga                  from '@/components/alertas/carga.vue'
  
  export default {
    components:{
      verTicketAux,
      responderAuxTicket,
      Alerta,
      carga,
    },

    mixins: [ funcionesExcel, validarErrores ],
    
    data: () => ({
      respuestaAlerta:false,
      cargar: false,

      dialogVerTicketAux:false,
      dialogResponderAuxTicket: false,
      dialogMensajes:false,

      tickets: [],
      ticket:{},
      historial:{},

      search:'',

      headers: [
        { text: 'Folio', align: 'start', sortable: false, value: 'idticket', },
        { text: 'Usuario', value: 'nombre_completo' },
        { text: 'Escuela',           value: 'id_unidad_negocio' },
        { text: 'Sucursal',          value: 'plantel' },
        { text: 'Fecha de creación', value: 'fecha_apertura' },
        { text: 'F. entrega'       , value: 'fecha_entrega'     },
        { text: 'Estatus', value: 'estatus' },
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Historial', value: 'historial', sortable: false },
      ],
      
      mensajes:[],
      mensajeRechazo:'',
      
      error:'',
      estatusFiltro:4,

      url:'',
      urlINBI:'',
      urlFAST:'',

      escuela: 0,
      urgente: 0,
      vistaPrevias: [],
      fotos:[],
      files: [],
      alumnos: []

    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),

      formTitle () {
        return this.editedIndex === -1 ? 'Solución al ticket' : 'Solución al ticket'
      },

      filterTickets () {
        if(this.tickets){
          let newtickets = this.tickets;
          if(this.estatusFiltro != 8){
             newtickets = newtickets.filter(el => el.estatus == this.estatusFiltro)
          }
          if (this.escuela) {
            newtickets = newtickets.filter(el => el.id_unidad_negocio == this.escuela)
          }
          if(this.urgente){
            newtickets = newtickets.filter(el => el.urgente == this.urgente)
          }
          return newtickets
        }
      },
    },

    created () {
      this.url = axios.defaults.baseURL + 'evidencia-tickets/'
      this.urlINBI = 'https://lmsinbi.club/evidencia-tickets/'
      this.urlFAST = 'https://lmsfast.club/evidencia-tickets/'
      this.initialize()
    },

    methods: {
      initialize () {
        this.obtenerTickets()
      },

      obtenerTickets(){
        // Cargar los ticket del auxiliar
        this.cargar = true
        this.tickets = []
        if(this.getdatosUsuario.cargo == 'Auxiliar'){
          this.$http.get('tickets.auxiliar/'+ this.getdatosUsuario.iderp).then(response=>{
            for(const i in response.data){
              response.data[i].fecha_apertura_correccion = moment(response.data[i].fecha_apertura).format('ll, h:mm a');
              this.tickets.push(response.data[i])
            }
            this.$emit('recalcular')
            
          }).catch(error=>{
            console.log(error)
          }).finally( () => { 
            this.cargar = false
          })
        }
      },

      closeModal(){
        this.dialogVerTicketAux = false
        this.dialogResponderAuxTicket = false
      },
      
      itemRowBackground: function (item) {
        return item.urgente ? 'style-1' : ''
      },

      // Sirve para cargar las notas del ticket
      cargarNotas () {
        this.cargar = true
        this.$http.get('tickets.notas/' + this.ticket.idticket).then(response=>{
          this.mensajes = response.data
          this.dialogMensajes = true
        }).catch(error=>{
          this.validarError(error.response.data.message)
        }).finally(()=>{
          this.cargar = false
        })
      },

      exportar(){
        this.exportExcel( this.filterTickets, 'tickets_list')
      },

      // Subir la nota del ticket
      enviarMensaje () {
        if(this.mensajeRechazo != '' && this.mensajeRechazo != null){
          this.cargar = true
          let payload = {
            idticket:    this.ticket.idticket,
            id_usuario:  this.getdatosUsuario.iderp,
            nota:        this.mensajeRechazo,
          }
          this.$http.post('tickets.nota',payload).then(response=>{
            this.mensajeRechazo = ''
            this.dialogMensajes = true
            this.mensajes       = []
            this.cargarNotas()
          }).catch(error=>{
            this.validarError(error)
            }).finally(()=>{
            this.cargar = false
          })
        }else{
          this.validarErrorDirecto('Ingresa un mensaje')
        }
      },  

      // Responder el ticket
      abrirDialogResponder(value){
        this.cargar = true
        this.ticket = value
        this.historial = []
        this.$http.get('historial_tickets.estatus/'+this.ticket.idticket).then(response=>{
          for(const i in response.data.HistorialTicket){
            response.data.HistorialTicket[i].fecha = moment(response.data.HistorialTicket[i].fecha).format('MMMM Do YYYY, h:mm:ss a');
            response.data.HistorialTicket[i].fecharespuesta = moment(response.data.HistorialTicket[i].fecharespuesta).format('MMMM Do YYYY, h:mm:ss a');
            //esta es la fecha y hora en la que se comenzo el registro de respuestas
            if(response.data.HistorialTicket[i].fecharespuesta == 'marzo 4º 2024, 3:31:27 pm'){
              response.data.HistorialTicket[i].fecharespuesta = ''
            }
            this.historial.push(response.data.HistorialTicket[i])
          }
          this.alumnos = response.data.alumnos

          this.mensajes = response.data.notas
          this.dialogResponderAuxTicket = true
        }).catch(error=>{
          this.validarError(error.response.data.message)
        }).finally(()=>{
          this.cargar = false
        })
      },

      // Cargar el historial del ticket
      historialTicket(value){
        this.ticket = value
        this.historial = []
        this.$http.get('historial_tickets.estatus/'+this.ticket.idticket).then(response=>{
          for(const i in response.data.HistorialTicket){
            response.data.HistorialTicket[i].fecha = moment(response.data.HistorialTicket[i].fecha).format('MMMM Do YYYY, h:mm:ss a');
            response.data.HistorialTicket[i].fecharespuesta = moment(response.data.HistorialTicket[i].fecharespuesta).format('MMMM Do YYYY, h:mm:ss a');
            //esta es la fecha y hora en la que se comenzo el registro de respuestas
            if(response.data.HistorialTicket[i].fecharespuesta == 'marzo 4º 2024, 3:31:27 pm'){
              response.data.HistorialTicket[i].fecharespuesta = ''
            }
            this.historial.push(response.data.HistorialTicket[i])
          }
          this.mensajes = response.data.notas
          this.dialogVerTicketAux = true
        }).catch(error=>{
          this.validarError(error.response.data.message)
        }).finally(()=>{
          this.cargar = false
        })
      },

      getColor (estatus) {
        if (estatus == 3) return 'red'
        else if (estatus == 1) return 'orange'
        else if (estatus == 2) return 'blue'
      },

    },
  }
</script>