<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Autorización Nómina</span>
            <v-spacer></v-spacer>
	  		<v-btn 
              class="mr-2" 
              small 
              dark 
              color="green"
              @click="exportarInscripciones()">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="green"
              dark
              class="text-capitalize mr-2"
              @click="dialogAceptar = true"
              small
              tile
            >
              Aceptar Nómina
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize(), comision_estatus()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="3" >
                <v-text-field
                  filled
                  single-line
                  label="Fecha"
                  type="date"
                  hide-details
                  v-model="fecha"
                  dense
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="py-0">
					<div class="grey--text"><b>Rango de fechas</b></div>
					<div>
					<span class="font-weight-bold text-h6">{{
						encabezados.fecha_inicio_format
					}}</span>
					<span class="font-weight-bold mx-4">al</span>
					<span class="font-weight-bold text-h6">{{
						encabezados.fecha_final_format
					}}</span>
					</div>
				</v-col> 

              <v-col cols="12" md="6">
                <label></label>
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <br />
            <v-row align="center" class="no-gutters">
              <v-col cols="12" md="6" lg="4">
                <span class="black--text text-h8">
                    <b>Estatus Nómina: </b>
                    <v-chip
                    color="black"
                    small
                    dark
                    v-if="nominaEstatus.length == 0 || nominaEstatus.length == null"
                    >En revisión</v-chip>
                    <v-chip
                    color="orange"
                    small
                    dark
                    v-if="estatus == 0"
                    >Preautorizada</v-chip>
                   <v-chip
                    color="green"
                    small
                    dark
                    v-if="estatus == 1"
                    >Aceptada</v-chip>
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="autorizarNomina"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                >
                <template v-slot:item.aceptar="{ item }">
                  <v-btn color="green" dark small @click="(horaextramensaje = item), (dialogAceptar = true)"> ACEPTAR</v-btn>
                </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-dialog v-model="dialogAceptar" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Aceptar:</strong> ¿Estás seguro que deseas aceptar esta nómina?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      large
                      class="mr-2"
                      @click="dialogAceptar=false"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      large
                      @click="dialogAceptar=false, save()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/contrato.jpg"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      </v-dialog>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

import XLSX            from 'xlsx'


export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "ID", value: "id_trabajador" },
      { text: "Empleado", value: "empleado" },
      { text: "Puesto", value: "puesto" },
      { text: "Departamento", value: "departamento" },
      { text: "Plantel", value: "plantel" },
      { text: "Tipo Pago", value: "tipo_pago" },
      { text: "Sueldo Mensual", value: "sueldo_mensual" },
      { text: "Sueldo Semanal", value: "sueldo_semanal" },
      { text: "Sueldo Diario", value: "sueldo_diario" },
      { text: "Sueldo Hora", value: "sueldo_hora" },
      { text: "Dias Laborados", value: "dias_laborados" },
      { text: "Horas Laboradas", value: "horas_laborados" },
      { text: "Horas Extras", value: "horas_extras" },
      { text: "Monto Horas Extras", value: "monto_horas_extras" },
      { text: "Comisiones", value: "comisiones" },
      { text: "Devoluciones", value: "devoluciones" },
      { text: "Rebajes", value: "rebajes" },
      { text: "Retensiones", value: "retensiones" },
      { text: "Compensaciones", value: "compensaciones" },
      { text: "Premios", value: "premios" },
      { text: "Seguro IMSS", value: "seguro_imss" },
      { text: "Caja de Ahorro", value: "caja_ahorro" },
      { text: "Monto Laborado", value: "monto_laborado" },
      { text: "Monto Total", value: "monto_total" },
    ],
    autorizarNomina: [],
    nominaEstatus: [],
    editedIndex: -1,
    editedItem: {
      idincidencias_bonos: "",
      fecha: "",
      rebajes: "",
      comisiones: "",
      premios: "",
      iderp: "",
      deleted: 0,
      compensaciones: ""
    },
    defaultItem: {
      idincidencias_bonos: "",
      fecha: "",
      rebajes: "",
      comisiones: "",
      premios: "",
      iderp: "",
      deleted: 0,
      compensaciones: ""
    },

    dialogSuccess: false,
    dialogAceptar: false,
    overlay: false,
    dialogError: false,
    error: "",
    successMensaje: "",
    errorBack: false,
    textoCopiado: "COPIAR",
    idcurso: 0,
    info: 0,
    dialog_mensaje_rechazo: false,
    idhistorial: [],
    fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    editedItem2: {
      mensaje: "",
    },
    mensajes: [],
    usuarios: [],

    ciclos: [],
    encabezados: null,
    ciclo: null,
    estatus: null,
    alumnos: [],
    reglas: [],
    cursosEscuela: [],
    horasExtras: "",
    // tabla
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),

    totalComisiones() {
      let subtotal = this.autorizarNomina
        .map((item) => item.comision)
        .reduce((prev, curr) => prev + curr, 0);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(subtotal);
    },
    cantidadComisiones() {
      let subtotal = this.autorizarNomina.length;
      this.cd1 = subtotal;
      return Math.floor(subtotal);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

  },

  created() {
    this.initialize();
    this.comision_estatus();
  },

  methods: {
    initialize() {
      this.autorizarNomina = [];
      this.overlay = true;

      const payload = {
        fecha: this.fecha
      }

      this.$http
        .post("encargadas_comisiones.get.autorizarnomina", payload)
        .then((response) => {
          this.autorizarNomina = response.data.respuesta;
          this.encabezados = response.data.encabezados;
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    comision_estatus() {
      this.nominaEstatus = [{estatus:null}];
      this.overlay = true;
      const payload = {
        fecha: this.fecha,
        id_usuario: this.getdatosUsuario.iderp,
      }

      this.$http
        .post("encargadas_comisiones.get.nominaestatus", payload)
        .then((response) => {
          this.nominaEstatus = response.data;
           if ( this.nominaEstatus.length > 0){
            this.estatus = this.nominaEstatus[0].estatus
            } else {
            this.estatus = null
            }
        console.log(this.estatus)
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },
    
    save () {
		this.cargar = true
		const payload = {data: this.autorizarNomina, fecha: this.fecha, id: 1}
		this.$http.post('encargadas_comisiones.update.nominaestatus', payload).then(response=>{
		this.validarSuccess('Datos grabados correctamente')
		this.dialogAceptar = false
		this.initialize();
        this.comision_estatus();
		this.cargar = false
		}).catch( error =>{
			this.validarError( error.response.data.message )
		}).finally( () => { this.cargar = false })	
	},



    exportarInscripciones( ){

    		const comisiones = this.autorizarNomina.map(({ id_trabajador, empleado, puesto, departamento, plantel, tipo_pago, sueldo_mensual, sueldo_semanal, sueldo_diario, sueldo_hora, dias_laborados, horas_laborados, horas_extras, monto_horas_extras, comisiones, devoluciones, rebajes, retensiones, compensaciones, premios, seguro_imss, caja_ahorro, monto_laborado, monto_total }) => 
			({ id_trabajador, empleado, puesto, departamento, plantel, tipo_pago, sueldo_mensual, sueldo_semanal, sueldo_diario, sueldo_hora, dias_laborados, horas_laborados, horas_extras, monto_horas_extras, comisiones, devoluciones, rebajes, retensiones, compensaciones, premios, seguro_imss, caja_ahorro, monto_laborado, monto_total   }));

				this.exportExcel( comisiones, 'Comisiones')

    }
  },
};
</script>
