<template>
  <v-container class="mt-4 ml-2">
  	<v-row>
  		<v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="alumnos"
          dense
          class="elevation-1"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>Alumnos lms</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <!-- <v-btn 
                class="mr-2" 
                small dark color="green"
                @click="exportar()">
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-btn> -->
              <v-btn color="primary" @click="consultar()" small>Consultar</v-btn>
            </v-toolbar>
            <v-card-text class="pb-6">
              <v-row>
                <v-col cols="12" sm="6" lg="4" xl="3">
                  <v-autocomplete
                    outlined
                    dense
                    clearable
                    v-model="cicloInf"
                    :items="ciclos"
                    label="Selecciona ciclo actual"
                    persistent-hint
                    hide-details
                    return-object
                    item-text="ciclo"
                    item-value="id_ciclo"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                	<v-text-field
                	  v-model="search"
                	  label="Buscar"
                	  dense
                	  outlined
                	></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </template>

          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
              small
            >
              Actualizar
            </v-btn>
          </template>
        </v-data-table>

			  <div class="text-center">
		      <v-dialog
		        v-model="dialogCarga"
		        hide-overlay
		        persistent
		        width="500"
		      >
		        <v-card
		          color="primary"
		          dark
		        >
		          <v-card-text class="pt-2">
		            Cargando, porfavor espera...
		            <v-progress-linear
		              indeterminate
		              color="white"
		              class="mb-0"
		            ></v-progress-linear>
		          </v-card-text>
		        </v-card>
		      </v-dialog>
		    </div>
  		</v-col>
  	</v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'

  export default {
    data: () => ({
      snackbar:false,
      headers: [
        { text: 'Alumno'    , value: 'alumno' },
        { text: 'Grupo'     , value: 'grupo' },
      ],

      alumnos: [],
      ciclos:[],
      cicloInf:null,
      resultado:[],
      search:'',
      dialogCarga: false,
    }),

    watch: {
      cicloInf () {
        if(this.cicloInf){
        	console.log(this.cicloInf)
          this.consultar()
        }
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
      	this.dialogCarga = true
        this.ciclos = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.dialogCarga = false
        }).catch(error=>{
        	this.dialogCarga = true
        	this.swalError(error)
        })
      },

      async consultar () {
      	if(this.cicloInf){
      		const { id_ciclo, id_ciclo_relacionado } = this.cicloInf
      		this.$http.get(`lms.alumnos/${ id_ciclo }/${ id_ciclo_relacionado }`).then(response=>{
	          this.alumnos = response.data
	          this.dialogCarga = false
	        }).catch(error=>{
	        	this.dialogCarga = true
	        	this.swalError(error)
	        })
      	}else{
      		this.swalError('Selecciona el ciclo por favor')
      	}
      },


      exportar(){
       
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let data     = XLSX.utils.json_to_sheet(this.resultado)
        let dataSuc  = XLSX.utils.json_to_sheet(aSuc)
        let dataVend = XLSX.utils.json_to_sheet(aVend)

        const workbook = XLSX.utils.book_new()
        const filename = 'Alumnos LMS'
        XLSX.utils.book_append_sheet(workbook, data, 'KPI')

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },

      swalError(text) {
      	this.$swal({
				  icon: 'error',
				  title: 'Oops...',
				  text,
				});
      },

      swalSuccess(text){
        this.$swal({
          position: 'top-end',
          icon: 'success',
          title: text,
          showConfirmButton: false,
          timer: 1500
        })
      },

      
    },
  }
</script>