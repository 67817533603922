<template>
  <v-container class="mt-5 ml-2">
    <v-row v-if="( getdatosUsuario.idpuesto == 18 && [568, 7, 161].includes( getdatosUsuario.iderp ) ) || getdatosUsuario.idpuesto != 18">
      <v-col cols="12">
        <v-card class="transparent elevation-0">
          <v-card-title primary-title>
            REPORTE DE TICKETS POR ESTATUS
          </v-card-title>
          <v-card-text>

            <v-row>
              <v-col cols="12" md="6">
                <v-card class="shadowCard">
                  <v-card-title class="text-subtitle-1">
                    Estatus de tickets de desarrollo
                    <v-spacer></v-spacer>
                    <v-btn color="blue" small tile dark @click="cargar_ticket_desarrollo()">
                      <v-icon small>mdi-reload</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <div class="ml-2">
                    </div>
                    <div id="content">
                      <canvas ref="chartDesarrollo" style="max-height: 300px"></canvas>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="6">
                <v-card class="shadowCard">
                  <v-card-title class="text-subtitle-1">
                    Estatus de tickets de Operaciones
                    <v-spacer></v-spacer>
                    <v-btn color="blue" small tile dark @click="cargar_ticket_operaciones()">
                      <v-icon small>mdi-reload</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <div class="ml-2">
                      <div id="content">
                        <canvas ref="chartVentas" style="max-height: 300px"></canvas>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="6">
                <v-card class="shadowCard">
                  <v-card-title class="text-subtitle-1">
                    Estatus de tickets de comercial
                    <v-spacer></v-spacer>
                    <v-btn color="blue" small tile dark @click="cargar_ticket_comercial()">
                      <v-icon small>mdi-reload</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <div class="ml-2">
                    </div>
                    <div id="content">
                      <canvas ref="chartAcademico" style="max-height: 300px"></canvas>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="6">
                <v-card class="shadowCard">
                  <v-card-title class="text-subtitle-1">
                    Estatus de tickets de RH
                    <v-spacer></v-spacer>
                    <v-btn color="blue" small tile dark @click="cargar_ticket_rh()">
                      <v-icon small>mdi-reload</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <div class="ml-2">
                    </div>
                    <div id="content">
                      <canvas ref="chartRH" style="max-height: 300px"></canvas>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="6">
                <v-card class="shadowCard">
                  <v-card-title class="text-subtitle-1">
                    Estatus de tickets de Finanzas
                    <v-spacer></v-spacer>ón
                    <v-btn color="blue" small tile dark @click="cargar_ticket_finanzas()">
                      <v-icon small>mdi-reload</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <div class="ml-2">
                    </div>
                    <div id="content">
                      <canvas ref="chartFinanzas" style="max-height: 300px"></canvas>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

            </v-row>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  import Vue from "vue";
  import Chart from "@/plugins/chart";

export default {
  name: "app",
  data: () => ({
    data: [],
    tickets_totales_estatus:    { cantidad_tickets: 0, id_area: 0 },
    tickets_totales_Abierto:    { cantidad_tickets: 0, id_area: 0 },
    tickets_totales_Asignado:   { cantidad_tickets: 0, id_area: 0 },
    tickets_totales_Pendiente:  { cantidad_tickets: 0, id_area: 0 },
    tickets_totales_Revision:   { cantidad_tickets: 0, id_area: 0 },
    tickets_totales_Respondido: { cantidad_tickets: 0, id_area: 0 },
    tickets_totales_Cerrado:    { cantidad_tickets: 0, id_area: 0 },
    tickets_totales_Rechazado:  { cantidad_tickets: 0, id_area: 0 },
  }),

  computed: {
    ...mapGetters( 'login' , ['getdatosUsuario'] ),
  },

  created() {
    this.cargar_ticket_desarrollo();
    this.cargar_ticket_operaciones();
    this.cargar_ticket_comercial();
    this.cargar_ticket_rh();
    this.cargar_ticket_finanzas();
  },

  methods: {
    cargar_ticket_desarrollo() {
      const payload = {
        idpuestos: [12,11,58]
      }
      this.$http.post("reportes.total_tickets_estatus1_area", payload ).then((response) => {
        this.tickets_totales_Abierto = response.data;

        var chart = this.$refs.chartDesarrollo;
        var ctx = chart.getContext("2d");
        if(this.myChartD != null) {
          this.myChartD.destroy();
        }

        this.myChartD = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: [
              "Tickets Asignados: " + response.data.filter( el => el.estatus == 2 ).length,
              "Tickets Pendientes: " + response.data.filter( el => el.estatus == 3 ).length,
              "Tickets en Revisión: " + response.data.filter( el => el.estatus == 4 ).length,
              "Tickets Contestados: " + response.data.filter( el => el.estatus == 5 ).length,
              "Tickets cerrados: " + response.data.filter( el => el.estatus == 6 ).length,
              "Tickets Rechazados: " + response.data.filter( el => el.estatus == 7 ).length,
            ],
            datasets: [
              {
                data: [
                  response.data.filter( el => el.estatus == 2).length ,
                  response.data.filter( el => el.estatus == 3).length ,
                  response.data.filter( el => el.estatus == 4).length ,
                  response.data.filter( el => el.estatus == 5).length ,
                  response.data.filter( el => el.estatus == 6).length ,
                  response.data.filter( el => el.estatus == 7).length ,
                ],
                backgroundColor: ["blue", "red", "grey", "green", "yellow", "black",],
                labels: [ "Tickets Asignados", "Tickets Pendientes", "Tickets en Revisión", "Tickets Contestados", "Tickets cerrados", "Tickets Rechazados",
                ],
              },
            ],
          },
        });
      }).catch((error) => {
        console.log(error);
      });
    },

    cargar_ticket_operaciones() {
      const payload = {
        idpuestos: [57,65,53,67,66,18,40,52,19,10]
      }

      this.$http.post("reportes.total_tickets_estatus1_area", payload).then((response) => {
        var chart = this.$refs.chartVentas;
        var ctx = chart.getContext("2d");
        if(this.myChartV != null) {
          this.myChartV.destroy();
        }

        this.myChartV = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: [
              "Tickets Asignados: " + response.data.filter( el => el.estatus == 2 ).length,
              "Tickets Pendientes: " + response.data.filter( el => el.estatus == 3 ).length,
              "Tickets en Revisión: " + response.data.filter( el => el.estatus == 4 ).length,
              "Tickets Contestados: " + response.data.filter( el => el.estatus == 5 ).length,
              "Tickets cerrados: " + response.data.filter( el => el.estatus == 6 ).length,
              "Tickets Rechazados: " + response.data.filter( el => el.estatus == 7 ).length,
            ],
            datasets: [
              {
                data: [
                  response.data.filter( el => el.estatus == 2).length ,
                  response.data.filter( el => el.estatus == 3).length ,
                  response.data.filter( el => el.estatus == 4).length ,
                  response.data.filter( el => el.estatus == 5).length ,
                  response.data.filter( el => el.estatus == 6).length ,
                  response.data.filter( el => el.estatus == 7).length ,
                ],
                backgroundColor: ["blue", "red", "grey", "green", "yellow", "black",],
                labels: [ "Tickets Asignados", "Tickets Pendientes", "Tickets en Revisión", "Tickets Contestados", "Tickets cerrados", "Tickets Rechazados",
                ],
              },
            ],
          },
        });
      }).catch((error) => {
        console.log(error);
      });
    },

    cargar_ticket_comercial() {

      const payload = {
        idpuestos: [25,48,49,47,62,54,70]
      }

      this.$http.post("reportes.total_tickets_estatus1_area", payload).then((response) => {

        this.tickets_totales_Abierto = response.data;

        var chart = this.$refs.chartAcademico;
        var ctx = chart.getContext("2d");
        if(this.myChartA != null) {
          this.myChartA.destroy();
        }

        this.myChartA = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: [
              "Tickets Asignados: " + response.data.filter( el => el.estatus == 2 ).length,
              "Tickets Pendientes: " + response.data.filter( el => el.estatus == 3 ).length,
              "Tickets en Revisión: " + response.data.filter( el => el.estatus == 4 ).length,
              "Tickets Contestados: " + response.data.filter( el => el.estatus == 5 ).length,
              "Tickets cerrados: " + response.data.filter( el => el.estatus == 6 ).length,
              "Tickets Rechazados: " + response.data.filter( el => el.estatus == 7 ).length,
            ],
            datasets: [
              {
                data: [
                  response.data.filter( el => el.estatus == 2).length ,
                  response.data.filter( el => el.estatus == 3).length ,
                  response.data.filter( el => el.estatus == 4).length ,
                  response.data.filter( el => el.estatus == 5).length ,
                  response.data.filter( el => el.estatus == 6).length ,
                  response.data.filter( el => el.estatus == 7).length ,
                ],
                backgroundColor: ["blue", "red", "grey", "green", "yellow", "black",],
                labels: [ "Tickets Asignados", "Tickets Pendientes", "Tickets en Revisión", "Tickets Contestados", "Tickets cerrados", "Tickets Rechazados",
                ],
              },
            ],
          },
        });
      }).catch((error) => {
        console.log(error);
      });
    },

    cargar_ticket_rh() {

      const payload = {
        idpuestos: [71,72,64,68,69,63,31]
      }

      this.$http.post("reportes.total_tickets_estatus1_area", payload).then((response) => {

        this.tickets_totales_Abierto = response.data;

        var chart = this.$refs.chartRH;
        var ctx = chart.getContext("2d");
        if(this.myChartRH != null) {
          this.myChartRH.destroy();
        }

        this.myChartRH = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: [
              "Tickets Asignados: " + response.data.filter( el => el.estatus == 2 ).length,
              "Tickets Pendientes: " + response.data.filter( el => el.estatus == 3 ).length,
              "Tickets en Revisión: " + response.data.filter( el => el.estatus == 4 ).length,
              "Tickets Contestados: " + response.data.filter( el => el.estatus == 5 ).length,
              "Tickets cerrados: " + response.data.filter( el => el.estatus == 6 ).length,
              "Tickets Rechazados: " + response.data.filter( el => el.estatus == 7 ).length,
            ],
            datasets: [
              {
                data: [
                  response.data.filter( el => el.estatus == 2).length ,
                  response.data.filter( el => el.estatus == 3).length ,
                  response.data.filter( el => el.estatus == 4).length ,
                  response.data.filter( el => el.estatus == 5).length ,
                  response.data.filter( el => el.estatus == 6).length ,
                  response.data.filter( el => el.estatus == 7).length ,
                ],
                backgroundColor: ["blue", "red", "grey", "green", "yellow", "black",],
                labels: [ "Tickets Asignados", "Tickets Pendientes", "Tickets en Revisión", "Tickets Contestados", "Tickets cerrados", "Tickets Rechazados",
                ],
              },
            ],
          },
        });
      }).catch((error) => {
        console.log(error);
      });
    },

    cargar_ticket_finanzas() {

      const payload = {
        idpuestos: [50,51,39,56]
      }

      this.$http.post("reportes.total_tickets_estatus1_area", payload).then((response) => {

        this.tickets_totales_Abierto = response.data;

        var chart = this.$refs.chartFinanzas;
        var ctx = chart.getContext("2d");
        if(this.myChartFinanzas != null) {
          this.myChartFinanzas.destroy();
        }

        this.myChartFinanzas = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: [
              "Tickets Asignados: " + response.data.filter( el => el.estatus == 2 ).length,
              "Tickets Pendientes: " + response.data.filter( el => el.estatus == 3 ).length,
              "Tickets en Revisión: " + response.data.filter( el => el.estatus == 4 ).length,
              "Tickets Contestados: " + response.data.filter( el => el.estatus == 5 ).length,
              "Tickets cerrados: " + response.data.filter( el => el.estatus == 6 ).length,
              "Tickets Rechazados: " + response.data.filter( el => el.estatus == 7 ).length,
            ],
            datasets: [
              {
                data: [
                  response.data.filter( el => el.estatus == 2).length ,
                  response.data.filter( el => el.estatus == 3).length ,
                  response.data.filter( el => el.estatus == 4).length ,
                  response.data.filter( el => el.estatus == 5).length ,
                  response.data.filter( el => el.estatus == 6).length ,
                  response.data.filter( el => el.estatus == 7).length ,
                ],
                backgroundColor: ["blue", "red", "grey", "green", "yellow", "black",],
                labels: [ "Tickets Asignados", "Tickets Pendientes", "Tickets en Revisión", "Tickets Contestados", "Tickets cerrados", "Tickets Rechazados",
                ],
              },
            ],
          },
        });
      }).catch((error) => {
        console.log(error);
      });
    },
  },

  mounted() {},
};
</script>