<template>
  <v-dialog 
  	persistent 
  	v-model="paramsTickets.estatus = true" 
  	max-width="1800"
  >
  	<v-card>
  		<v-card-text>
  			<span v-if="this.getdatosUsuario.cargo == 'Jefe'">
					<TicketArea @recalcular="recalcular( )"/>
				</span>

				<span v-if="this.getdatosUsuario.cargo == 'Auxiliar'">
					<TicketAuxi @recalcular="recalcular( )"/>
				</span>
  		</v-card-text>	  
        
    </v-card>
	</v-dialog>
</template>
<script>
	import axios from 'axios';
  var moment = require('moment');
  moment.locale('es');
  
  import {mapGetters, mapActions} from 'vuex'
  import TicketArea from '@/views/tickets/operaciones/TicketArea'
  import TicketAuxi from '@/views/tickets/operaciones/TicketAuxi'

  export default {
  	components:{
  		TicketArea,
			TicketAuxi
  	},

	  props: [
	  	'paramsTickets'
	  ],

	  data: () => ({
     	paramsCantidadTickets:{
     		cantidad: 10
     	}
    }),

	  watch:{

	  },


    computed: {
      ...mapGetters('login',['getdatosUsuario']),
    },

    created ( ){
      console.log( this.getdatosUsuario )
    },

    methods:{

    	recalcular( ){
    		
    		this.overlay = true;

	      this.$http.get("tickets.usuario.erp/" + this.getdatosUsuario.iderp ).then((response) => {
	          
	        if( response.data.length < 5 ){
	          
	          this.paramsTickets.estatus = false

	        }


	      }).catch((error) => {

	        console.log( error )
	        // // INdicamos que hay un error en el back
	        // if (error.data.message) {
	        //   this.error = error.data;
	        // } else {
	        //   this.error = error.data;
	        // }
	        // this.errorBack = true;
	        // this.overlay = false;
	        // this.dialogError = true;
	      });

    	}

    }
	};
</script>

<style>
  .v-data-table__wrapper{

  }

  .v-data-table__wrapper::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-data-table__wrapper::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  .v-data-table__wrapper::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  .mensajeIzq{
    white-space: pre-line;
    background-color: rgb(238, 235, 235);
    border-radius: 0px 10px 10px 10px;
  }
  .mensajeDer{
    white-space: pre-line;
    background-color: rgb(193, 231, 243);
    border-radius: 10px 0px 10px 10px;
  }

  .style-1 {
    background-color: rgb(253, 169, 169);
  }
  .style-1:hover {
    background-color: rgb(238, 131, 131) !important;
  }


</style>