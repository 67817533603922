<template>
  <v-dialog 
  	persistent 
  	v-model="paramsComisiones.estatus = true" 
  	max-width="1100"
  >
  	<v-card>
      <v-card-title primary-title>
        <span class="text-subtitle-1">Mis Comisiones</span>
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          dark
          class="text-capitalize mr-2"
          @click="dialogAceptar = true"
          small
          tile
          v-if="estatus == 0 || estatus == 4"
        >
         Aceptar / Enviar Comisiones
        </v-btn>

        <v-btn
          color="black"
          dark
          class="text-capitalize mr-2"
          @click="dialog_mensaje_rechazo = true, getMensajesRechazo()"
          small
          tile
          v-if="estatus == 0 || estatus == 4"
        >
          Rechazar / Enviar Mensaje
        </v-btn>

        <v-btn
          color="primary"
          dark
          class="text-capitalize"
          @click="initialize(), comision_estatus(), getComisionesRetenidasIndividuales()"
          small
          tile
        >
          <v-icon left small>mdi-refresh</v-icon>
          Actualizar
        </v-btn>
      </v-card-title>
  		<v-card-text>
  		  
        <v-row>
          <v-col cols="12" md="6">
            <label></label>
            <v-text-field
              name="name"
              label="Buscar"
              id="id"
              filled
              dense
              single-line
              hide-details
              append-icon="mdi-magnify"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>

        <br />

        <v-row align="center" class="no-gutters">
          <v-col cols="12" md="6" lg="4">
            <span class="black--text text-h8"
              ><b>Total Comisiones:</b>
              {{ totalComisiones }} 
            </span>
          </v-col>
        </v-row>
        <v-row align="center" class="no-gutters">
          <v-col cols="12" md="6" lg="4">
            <span class="black--text text-h8"
              ><b>Cantidad Comisiones:</b>
              {{ cantidadComisiones }} 
            </span>
          </v-col>
        </v-row>
        <v-row align="center" class="no-gutters">
          <v-col cols="12" md="6" lg="4">
            <span class="black--text text-h8">
                <b>Estatus Comisiones: </b>
                <v-chip
                color="orange"
                small
                dark
                v-if="estatus == 0"
                >Pendiente</v-chip>
               <v-chip
                color="blue"
                small
                dark
                v-if="estatus == 1"
                >Comisiones Aprobadas/En Revisión</v-chip>
               <v-chip
                color="red"
                small
                dark
                v-if="estatus == 2"
              >Comisiones Denegadas/En Revisión</v-chip>
              <v-chip
                color="green"
                small
                dark
                v-if="estatus == 3"
              >Aceptadas</v-chip>
               <v-chip
                color="balck"
                small
                dark
                v-if="estatus == 4"
              >Rechazadas/Revisar Mensajes</v-chip>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="encargadascomisiones"
              class="elevation-0"
              :search="search"
              :page.sync="page"
              @page-count="pageCount = $event"
              :mobile-breakpoint="100"
              dense
            >
            <template v-slot:item.aceptar="{ item }">
              <v-btn color="green" dark small @click="(horaextramensaje = item), (dialogAceptar = true)"> ACEPTAR</v-btn>
            </template>
            </v-data-table>
          </v-col>
        </v-row>

		    <v-row>
		      <v-col cols="12">
		        <v-card class="shadowCard">
		          <v-card-title primary-title>
		            <span class="text-subtitle-1">Comisiones Retenidas</span>
		            <v-spacer></v-spacer>
		            <v-btn
		              color="primary"
		              dark
		              class="text-capitalize"
		              @click="initialize(), getComisionesRetenidasIndividuales()"
		              small
		              tile
		            >
		              <v-icon left small>mdi-refresh</v-icon>
		              Actualizar
		            </v-btn>
		          </v-card-title>

		          <v-card-text>
		            <v-row>
		              <v-col cols="12" md="6">
		                <label></label>
		                <v-text-field
		                  name="name"
		                  label="Buscar"
		                  id="id"
		                  filled
		                  dense
		                  single-line
		                  hide-details
		                  append-icon="mdi-magnify"
		                  v-model="search"
		                ></v-text-field>
		              </v-col>
		            </v-row>
		            <v-row>
		              <v-col cols="12">
		                <v-data-table
		                  :headers="headers4"
		                  :items="comisionesretenidasindividuales"
		                  class="elevation-0"
		                  :search="search"
		                  :page.sync="page"
		                  @page-count="pageCount = $event"
		                  :mobile-breakpoint="100"
		                  dense
		                >
		                </v-data-table>
		              </v-col>
		            </v-row>

		          </v-card-text>
		        </v-card>
		      </v-col>
		    </v-row>

		    <!-- Dialog de carga -->
		    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
		    <carga v-if="cargar" />
  		</v-card-text>
  	</v-card>

  	<v-dialog v-model="dialogAceptar" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Aceptar:</strong> ¿Estás segura que deseas aceptar tu comisiones?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      large
                      class="mr-2"
                      @click="dialogAceptar=false"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      large
                      @click="save()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/contrato.jpg"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="dialog_mensaje_rechazo" max-width="1000px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1"><b>Favor de aclarar el motivo del rechazo de sus comisiones(Comisiones faltantes, Comisiones de mas, Error en los montos, etc.)</b></span>
          <span class="text-subtitle-1">*Detallar el problema lo maximo posible</span>
        </v-card-title>

        <v-card-text>
          <v-row v-for="(item, index) in this.mensajes" :key="index">
            <v-col cols="12">
              <v-textarea
                v-model="item.mensaje"
                :id="'motivo_salida_' + index"
                label="Motivo del rechazo"
                outlined
                hide-details
                dense
                auto-grow
                readonly
                style="max-width: 900px; margin-right: auto"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="item.mensaje_respuesta"
                :id="'motivo_salida_' + index"
                label="Respuesta..."
                outlined
                hide-details
                dense
                auto-grow
                readonly
                style="max-width: 900px; margin-left: auto"
                :class="{ 'd-none': !item.mensaje_respuesta }" 
              ></v-textarea>
            </v-col>
          </v-row><br />
          <v-row
            style="border: 1px solid gray; padding: 2px; border-radius: 5px"
             v-if="estatus == 0 || estatus == 3 || estatus == 4"
          >
            <v-col cols="12" :style="{ paddingTop: '10px' }">
              <v-textarea
                v-model="editedItem2.mensaje"
                label="Escriba su mensaje..."
                outlined
                hide-details
                dense
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (dialog_mensaje_rechazo = false), (editedItem2.mensaje = '')
            "
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
             v-if="estatus == 0 || estatus == 3 || estatus == 4"
            @click="enviarMensajeRechazo()"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

	</v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

import XLSX            from 'xlsx'


export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  props: [
  	'paramsComisiones'
  ],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "ID", value: "id_alumno" },
      { text: "Matrícula", value: "matricula" },
      { text: "Alumno", value: "alumno" },
      { text: "Grupo", value: "grupo" },
      { text: "Precio Grupo", value: "precio_grupo" },
      { text: "Precio con Descuento", value: "precio_grupo_con_descuento_x_alumno" },
      { text: "Monto Pagado", value: "pagado" },
      { text: "Fecha", value: "fecha_pago" },
      { text: "Fecha Ultimo Cambio", value: "fecha_ultimo_cambio" },
      { text: "Comisionista", value: "vendedora" },
      { text: "Comisión", value: "comision" },
    ],

     headers4: [
      { text: "ID", value: "id_alumno" },
      { text: "Matrícula", value: "matricula" },
      { text: "Alumno", value: "alumno" },
      { text: "Grupo", value: "grupo" },
      { text: "Precio Grupo", value: "precio_grupo" },
      { text: "Precio con Descuento", value: "precio_grupo_con_descuento_x_alumno" },
      { text: "Monto Pagado", value: "pagado" },
      { text: "Vendedora", value: "vendedora" },
      { text: "Fecha", value: "fecha_pago" },
      { text: "Comisión", value: "comision" },
    ],

    encargadascomisiones: [],
    comisionEstatus: [],
    comisionesretenidasindividuales: [],
    editedIndex: -1,
    editedItem: {
      idincidencias_bonos: "",
      fecha: "",
      rebajes: "",
      comisiones: "",
      premios: "",
      iderp: "",
      deleted: 0,
      compensaciones: ""
    },
    defaultItem: {
      idincidencias_bonos: "",
      fecha: "",
      rebajes: "",
      comisiones: "",
      premios: "",
      iderp: "",
      deleted: 0,
      compensaciones: ""
    },

    editedItem2: {
      mensaje: "",
    },

    dialogSuccess: false,
    dialogAceptar: false,
    overlay: false,
    dialogError: false,
    error: "",
    successMensaje: "",
    errorBack: false,
    textoCopiado: "COPIAR",
    idcurso: 0,
    info: 0,
    dialog_mensaje_rechazo: false,
    idhistorial: [],
    fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    editedItem2: {
      mensaje: "",
    },
    mensajes: [],
    usuarios: [],

    ciclos: [],
    ciclo: null,
    estatus: 0,
    alumnos: [],
    reglas: [],
    cursosEscuela: [],
    horasExtras: "",
    // tabla
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),

    totalComisiones() {
      let subtotal = this.encargadascomisiones
        .map((item) => item.comision)
        .reduce((prev, curr) => prev + curr, 0);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(subtotal);
    },
    cantidadComisiones() {
      let subtotal = this.encargadascomisiones.length;
      this.cd1 = subtotal;
      return Math.floor(subtotal);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

  },

  created() {
    this.initialize();
    this.comision_estatus();
  },

  methods: {
    initialize() {
      this.encargadascomisiones = [];
      this.overlay = true;

      const payload = {
        fecha: this.fecha,
        id_usuario: this.getdatosUsuario.iderp,
      }

      this.$http
        .post("encargadas_comisiones.get.comisionesindividuales", payload)
        .then((response) => {
          this.encargadascomisiones = response.data;
          this.overlay = false;
          this.getComisionesRetenidasIndividuales()
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    comision_estatus() {
      this.comisionEstatus = [{estatus:0}];
      this.overlay = true;
      const payload = {
        fecha: this.fecha,
        id_usuario: this.getdatosUsuario.iderp,
      }

      this.$http
        .post("encargadas_comisiones.get.comisionesestatus", payload)
        .then((response) => {
          this.comisionEstatus = response.data;

           if ( this.comisionEstatus.length > 0){
            this.estatus = this.comisionEstatus[0].estatus
            } else {
                this.estatus = 0
            }

          if( ![0,4].includes( this.estatus ) ){ 

            this.paramsComisiones.estatus = false


          }
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },
    
    save () {
    this.cargar = true
    const payload = {
    fecha: this.fecha,
    idusuario: this.getdatosUsuario.iderp,
    estatus: 1
    }
    // Lo mandapos por el EP
    this.$http.post('encargadas_comisiones.add.comisionesestatus', payload).then(response=>{
    this.validarSuccess('Datos grabados correctamente')
    this.dialogAceptar = false
    this.cargar = false
    this.initialize();
    this.comision_estatus();
    }).catch( error =>{
        this.validarError( error.response.data.message )
    }).finally( () => { this.cargar = false })
        
    },

    getMensajesRechazo() {
      this.cargar = true;
      this.mensajes = [];

      const payload = {
        iderp: this.getdatosUsuario.iderp,
        fecha: this.fecha
      };

      return this.$http
        .post("consultar.mensajes.rechazo.comisiones", payload)
        .then((response) => {
          this.mensajes = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },


    enviarMensajeRechazo() {
      this.cargar = true;
      const payload = {
        fecha: this.fecha,
        idusuario: this.getdatosUsuario.iderp,
        estatus: 2,

        mensaje: this.editedItem2.mensaje,
        iderp: this.getdatosUsuario.iderp,
      };

      return this.$http
        .post("encargadas_comisiones.add.comisionesestatus", payload)
        .then((response) => {
          this.$http
            .post("add.comisiones.mensaje", payload)
            .then((response) => {
              this.validarSuccess(response.data.message);
              this.cargar = false;
              this.dialog_mensaje_rechazo = false;
              this.editedItem2.mensaje = null;
              this.initialize();
              this.comision_estatus();
            })
            .catch((error) => {
              this.validarError(error);
            })
            .finally(() => {
              this.cargar = false;
            });
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getComisionesRetenidasIndividuales() {
      this.comisionesretenidasindividuales = [];
      this.overlay = true;

       const payload = {
        id_usuario: this.getdatosUsuario.iderp,
      };
     
      this.$http
        .post("encargadas_comisiones.get.comisionesretenidasindividuales", payload)
        .then((response) => {
          this.comisionesretenidasindividuales = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;

        }).finally( () => {  })
    },

    exportarInscripciones( ){

    		const comisiones = this.encargadascomisiones.map(({ id_alumno, matricula, alumno, grupo, precio_grupo, precio_grupo_con_descuento_x_alumno, pagado, fecha_pago, fecha_ultimo_cambio, vendedora, comision }) => 
			({ id_alumno, matricula, alumno, grupo, precio_grupo, precio_grupo_con_descuento_x_alumno, pagado, fecha_pago, fecha_ultimo_cambio,  vendedora, comision }));

				this.exportExcel( comisiones, 'Comisiones')

    }
  },
};
</script>
