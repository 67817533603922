<template>
  <v-container fluid class="mt-2 pa-6">

  	<!-- Barra superior -->
  	<v-row>
  		<v-col cols="12">
	  		<v-card class="back-color">
          <v-img
            class="white--text align-end"
          />
          <v-card-text class="position-relative">
          	<div class="d-flex flex-no-wrap ">
          		<v-avatar
	              size="100"
	              color="transparent"
	              class="avatar-center"
	            >
	              <v-img src="@/assets/asistente/secretaria2.png"></v-img>
	            </v-avatar>

              <div>
                <v-card-title
                  class="text-h5 white--text"
                >
              		<!-- Hola, {{ getdatosUsuario.nombre_usuario + " " + getdatosUsuario.apellido_usuario }}  -->
              		Hola, {{ getdatosUsuario.nombre_usuario }} !! 
              	</v-card-title>
                <v-card-subtitle class="text-h6 white--text">Bienvenida a tu panel de control</v-card-subtitle>
              </div>
            </div>
          </v-card-text>
        </v-card>
  		</v-col>
  	</v-row>

  	<!-- Desglose dle Dashboard -->
    <v-row>
    	<!-- Cantidad de Contactos -->
      <v-col cols="12">
        <v-card class="transparent elevation-0">
			    <v-card-title class="align-start pt-0">
			      <span class="font-weight-semibold">Contactos hoy | {{ totalContactos }} |</span>
			      <v-spacer></v-spacer>
			    </v-card-title>

			    <v-card-text>
			      <v-row>
			        <v-col
			          v-for="data in dataEtapas"
			          :key="data.etapa"
			          cols="6"
			          md="3"
			          class="d-flex align-center"
			        >
			          <v-avatar
			            size="44"
			            :color="data.color"
			            rounded
			            class="elevation-1"
			          >
			            <v-icon
			              dark
			              color="white"
			              size="30"
			            >
			              {{ data.icon }}
			            </v-icon>
			          </v-avatar>
			          <div class="ms-3">
			            <p class="text-xs mb-0">
			              {{ data.etapa }}
			            </p>
			            <h3 class="text-xl font-weight-semibold">
			              {{ data.total }}
			            </h3>
			          </div>
			        </v-col>
			      </v-row>
			    </v-card-text>
			  </v-card>
      </v-col>

      <!-- Bien hecho -->
      <v-col cols="12" md="6" lg="4">
	  		<v-card class="greeting-card">
		  		<v-row class="ma-0 pa-0">
			      <v-col cols="8">
			        <v-card-title class="text-no-wrap pt-1 ps-2">
			          <span v-if="seguimientos <= 40">Hey! Vamosss!! si se puedeee!</span>
			          <span v-else-if="seguimientos <= 59">Wow! Vamosss!! otro poquito!</span>
			          <span v-else-if="seguimientos <= 90">Jiji estas a nadaaa!</span>
			          <span v-else>Te lo merecesss</span>
			        </v-card-title>
			        <v-card-subtitle class="text-no-wrap ps-2">
			          Le haz brindado informacion al:
			        </v-card-subtitle>
			        <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
			          <div>
			          	<br/>
			            <p class="text-h6 font-weight-semibold primary--text mb-2">
			              {{ seguimientos }}% de contactos
			            </p>
			          </div>
			        </v-card-text>
			      </v-col>

			      <v-col cols="4">
			        <v-img
			          contain
			          height="90"
			          width="159"
			          :src="require(`@/assets/asistente/triangle-light.png`)"
			          class="greeting-card-bg"
			        ></v-img>
			        <v-img
			        	v-if="seguimientos <= 40"
			          contain
			          height="90"
			          max-width="83"
			          class="greeting-card-trophy"
			          src="@/assets/asistente/estatus_mal.png"
			        ></v-img>

			        <v-img
			        	v-else-if="seguimientos <= 59"
			          contain
			          height="90"
			          max-width="83"
			          class="greeting-card-trophy"
			          src="@/assets/asistente/estatus_regular.png"
			        ></v-img>

			        <v-img
			        	v-else-if="seguimientos <= 90"
			          contain
			          height="90"
			          max-width="83"
			          class="greeting-card-trophy"
			          src="@/assets/asistente/estatus_bien.png"
			        ></v-img>

			        <v-img
			        	v-else
			          contain
			          height="90"
			          max-width="83"
			          class="greeting-card-trophy"
			          src="@/assets/asistente/estatus_excelente.png"
			        ></v-img>
			      </v-col>
			      <!-- <v-col cols="12">
			        <div><v-progress-linear :indeterminate="true"></v-progress-linear></div>
			      </v-col> -->

			     <!--  <v-col cols="12">
					    <v-slider
			          v-model="seguimientos"
			          :thumb-size="24"
			          thumb-label="always"
			          hide-details
			          class="pb-0"
			          readonly
			        >
			          <template v-slot:thumb-label="{ value }">
			            {{ satisfactionEmojis[Math.min(Math.floor(value / 10), 9)] }}
			          </template>

		        	</v-slider>

			      </v-col> -->
			    </v-row>
			  </v-card>
  		</v-col>

  		<!-- Tareas -->
  		<v-col cols="12" md="6" lg="4">
	  		<v-card class="greeting-card">
		  		<v-row class="ma-0 pa-0">
			      <v-col cols="8">
			        <v-card-title class="text-no-wrap pt-1 ps-2">
			          psst!! 👀
			        </v-card-title>
			        <v-card-subtitle class="text-no-wrap ps-2">
			          Tienes tareas pendientes..! 
			        </v-card-subtitle>
			        <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
			          <div>
			          	<br/>
			            <p class="text-h6 font-weight-semibold primary--text mb-2">
			            	{{ tareasHechas }} tareas de {{ tareasTotales }}
			            </p>
			          </div>
			        </v-card-text>
			      </v-col>

			      <v-col cols="4">
			        <v-img
			          contain
			          height="180"
			          width="159"
			          :src="require(`@/assets/asistente/triangle-light.png`)"
			          class="greeting-card-bg"
			        ></v-img>
			        <v-img
			          contain
			          height="108"
			          max-width="83"
			          class="greeting-card-trophy"
			          src="@/assets/asistente/tareas.png"
			        ></v-img>
			      </v-col>
			    </v-row>
			  </v-card>
  		</v-col>

  		<!-- Inscritos -->
  		<v-col cols="12" md="6" lg="4">
	  		<v-card class="greeting-card">
		  		<v-row class="ma-0 pa-0">
			      <v-col cols="8">
			        <v-card-title class="text-no-wrap pt-1 ps-2">
			          ¡Inscritos! 
			        </v-card-title>
			        <v-card-subtitle class="text-no-wrap ps-2">
			          el día de hoy
			        </v-card-subtitle>
			        <v-card-text class="d-flex align-center mt-2 pb-0 ps-2">
			          <div>
			            <p class="text-h6 font-weight-semibold primary--text mb-2">
			              {{ inscritosHoy }} Hoy <br/>
			              {{ inscritosTotal }} Totales
			            </p>
			          </div>
			        </v-card-text>
			      </v-col>

			      <v-col cols="4">
			        <v-img
			          contain
			          height="180"
			          width="159"
			          :src="require(`@/assets/asistente/triangle-light.png`)"
			          class="greeting-card-bg"
			        ></v-img>
			        <v-img
			          contain
			          height="108"
			          max-width="83"
			          class="greeting-card-trophy"
			          src="@/assets/asistente/inscritos.png"
			        ></v-img>
			      </v-col>
			    </v-row>
			  </v-card>
  		</v-col>

      <!-- Desglose de llamadas -->
      <!-- <v-col cols="12" md="6" lg="4">
        <v-card>
			    <v-card-title class="align-start pb-0">
			      <v-icon color="blue" left >mdi-phone</v-icon> <span>Llamadas | {{ totalEventos }} | </span>
			      <v-spacer></v-spacer>
			    </v-card-title>

			    <v-card-text class="pt-0">
			      <v-list class="pb-0">
			        <v-list-item
			          v-for="(data,index) in dataLlamadas"
			          :key="data.country"
			          :class="`d-flex align-center px-0 ${index > 0 ? 'mt-1':''}`"
			        >
			          <v-avatar
			            :color="data.color"
			            size="20"
			            class="me-3"
			          >
			          </v-avatar>

			          <div class="d-flex align-center flex-grow-1 flex-wrap">
			            <div class="me-2">
			              <div class="font-weight-semibold">
			                <span class="text--primary text-base me-1">{{ data.evento }}</span>
			              </div>
			            </div>

			            <v-spacer></v-spacer>

			            <div>
			              <h4 class="font-weight-semibold">
			                {{ data.total }}
			              </h4>
			            </div>

		              <v-list-item-subtitle class="text-xs">
		                <v-progress-linear :color="data.color" :value="data.value"></v-progress-linear>
		              </v-list-item-subtitle>
			          </div>
			        </v-list-item>
			      </v-list>
			    </v-card-text>
			  </v-card>
      </v-col> -->

      <!-- Comisiones -->
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="4"
      >
        <v-card class="greeting-card" height="100%">
		  		<v-row class="ma-0 pa-0">
			      <v-col cols="8">
			        <v-card-title class="text-no-wrap pt-1 ps-2">
			          Comisiones del ciclo
			        </v-card-title>
			        <v-card-subtitle class="text-no-wrap ps-2">
			           
			        </v-card-subtitle>
			        <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
			          <div>
			          	<br/>
			            <p class="text-h2 font-weight-semibold primary--text mb-2">
			            	${{ convencimiento }}
			            </p>
			          	<br/>
			          	<br/>
			          	<br/>
			            <p class="text-body font-weight-semibold primary--text mb-2">
			            	#UnPesitoPalDesarrollador
			            </p>
			          </div>
			        </v-card-text>
			      </v-col>

			      <v-col cols="4">
			        <v-img
			          contain
			          height="250"
			          width="210"
			          :src="require(`@/assets/asistente/triangle-light.png`)"
			          class="greeting-card-bg"
			        ></v-img>
			        <v-img
			          contain
			          height="120"
			          max-width="120"
			          class="greeting-card-trophy"
			          src="@/assets/asistente/coin.png"
			        ></v-img>

			        <!-- <v-img
			          contain
			          height="200"
			          max-width="160"
			          class="greeting-card-trophy"
			          src="@/assets/asistente/secretaria.png"
			        ></v-img>
 							-->
			      </v-col>
			    </v-row>
			  </v-card>
      </v-col>

      <!-- Conversiones -->
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="4"
      >
        <v-card>
			    <v-card-title class="align-start">
			      <span>Desglose seguimiento</span>
			      <v-spacer></v-spacer>
			    </v-card-title>
			    <v-card-text>
			      <div
			        v-for="(earning,index) in totalEarning"
			        :key="earning.avatar"
			        :class="`d-flex align-start ${index >0 ? 'mt-8':''}`"
			      >
			        <v-avatar
			          rounded
			          size="38"
			          color="#5e56690a"
			          class="me-4"
			        >
			          <v-img
			            contain
			            :src="earning.avatar"
			            height="20"
			          ></v-img>
			        </v-avatar>

			        <div class="d-flex align-center flex-grow-1 flex-wrap">
			          <div>
			            <h4 class="font-weight-medium">
			              {{ earning.title }}
			            </h4>
			            <span class="text-xs text-no-wrap">{{ earning.subtitle }}</span>
			          </div>

			          <v-spacer></v-spacer>

			          <div class="ms-1">
			            <p class="text--primary font-weight-medium mb-1">
			              {{ earning.earning }} contactos
			            </p>
			            <v-progress-linear
			              :value="earning.progress"
			              :color="earning.color"
			            ></v-progress-linear>
			          </div>
			        </div>
			      </div>
			    </v-card-text>
			  </v-card>
      </v-col>

      <!-- Desglose llamadas -->
      <!-- <v-col
        cols="12"
        sm="6"
        md="4"
        lg="4"
      >
        <v-card class="greeting-card" height="100%">
		  		<v-row class="ma-0 pa-0">
			      <v-col cols="8">
			        <v-card-title class="text-no-wrap pt-1 ps-2">
			          % de convencimiento
			        </v-card-title>
			        <v-card-subtitle class="text-no-wrap ps-2">
			           
			        </v-card-subtitle>
			        <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
			          <div>
			          	<br/>
			            <p class="text-h2 font-weight-semibold primary--text mb-2">
			            	20%
			            </p>
			          </div>
			        </v-card-text>
			      </v-col>

			      <v-col cols="4">
			        <v-img
			          contain
			          height="250"
			          width="210"
			          :src="require(`@/assets/asistente/triangle-light.png`)"
			          class="greeting-card-bg"
			        ></v-img>
			        <v-img
			          contain
			          height="200"
			          max-width="160"
			          class="greeting-card-trophy"
			          src="@/assets/asistente/secretaria.png"
			        ></v-img>
			      </v-col>
			    </v-row>
			  </v-card>
      </v-col> -->

      <!-- Convencimiento -->
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="4"
      >
        <v-card class="greeting-card" height="100%">
		  		<v-row class="ma-0 pa-0">
			      <v-col cols="8">
			        <v-card-title class="text-no-wrap pt-1 ps-2">
			          % de convencimiento
			        </v-card-title>
			        <v-card-subtitle class="text-no-wrap ps-2">
			           
			        </v-card-subtitle>
			        <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
			          <div>
			          	<br/>
			            <p class="text-h2 font-weight-semibold primary--text mb-2">
			            	% {{ convencimiento }}
			            </p>
			          </div>
			        </v-card-text>
			      </v-col>

			      <v-col cols="4">
			        <v-img
			          contain
			          height="250"
			          width="210"
			          :src="require(`@/assets/asistente/triangle-light.png`)"
			          class="greeting-card-bg"
			        ></v-img>
			        <!-- <v-img
			          contain
			          height="120"
			          max-width="100"
			          class="greeting-card-trophy"
			          src="@/assets/asistente/coin.png"
			        ></v-img> -->

			        <v-img
			          contain
			          height="120"
			          max-width="160"
			          class="greeting-card-trophy"
			          src="@/assets/asistente/secretaria.png"
			        ></v-img>

			      </v-col>
			    </v-row>
			  </v-card>
      </v-col>
    </v-row>

    <v-dialog
			v-model="overlay"
			persistent
			max-width="350px"
		>
			<v-card color="primary" dark>
				<v-card-text class="pt-4">
					Cargando...
					<v-progress-linear
						indeterminate
						color="white"
						class="mb-0"
					></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>

  </v-container>
</template>

<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
	// eslint-disable-next-line object-curly-newline
	import { mdiPoll, mdiLabelVariantOutline, mdiHelpCircleOutline, mdiChevronUp, mdiChevronDown } from '@mdi/js'
	// import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'
	// import data from '@/views/datatable-data'

	// demos
	import DashboardCardTotalEarning       from '@/views/dashboard/DashboardCardTotalEarning.vue'
	import DashboardCardDepositAndWithdraw from '@/views/dashboard/DashboardCardDepositAndWithdraw.vue'
	import DashboardWeeklyOverview         from '@/views/dashboard/DashboardWeeklyOverview.vue'
	import DashboardDatatable              from '@/views/dashboard/DashboardDatatable.vue'

	import { mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, mdiDotsVertical, mdiLabelOutline, mdiMenuUp } from '@mdi/js'

	export default {
	  components: {
	    // StatisticsCardVertical,
	    DashboardCardTotalEarning,
	    DashboardCardDepositAndWithdraw,
	    DashboardWeeklyOverview,
	    DashboardDatatable,
	  },

	  data(){
	  	return{

	  		satisfactionEmojis: ['😢', '😢', '😢', '😢', '😮', '😮', '😆', '😆', '😆', '❤️'],
	  		overlay: true,

	  		ticksLabels: [
	  			'😢',
          '😢',
          '😮',
          '😆',
          '❤️',
        ],

	  		totalContactos: 0,
	  		dataEtapas: [],
		    conInformacion : 0,
		    tareasHechas   : 0,
				tareasTotales  : 0,
				inscritosHoy   : 0,
				inscritosTotal : 0,
				totalEventos   : 0,
				seguimientos    : 0,

		    // Datos de las llamadas
		    dataLlamadas: [],

				prospectos:[],

				canAtrasados:0,
				cantNuevos:0,
				cantInduccion:0,
				cantSinInfo:0,
				cantConInfo:0,
				cantSeguimiento:0,
				cantTodas:0,
				convencimiento:0,

	      totalEarning: [
		      {
		        avatar: require('@/assets/images/logos/zipcar.png'),
		        title: 'Bajo',
		        subtitle: 'De 0 a 5 seguimientos',
		        earning: '0',
		        progress: '0',
		        color: 'blue',
		      },
		      {
		        avatar: require('@/assets/images/logos/bitbank.png'),
		        title: 'Medio',
		        subtitle: 'De 6 a 15 seguimientos',
		        earning: '0',
		        progress: '0',
		        color: 'orange',
		      },
		      {
		        avatar: require('@/assets/images/logos/aviato.png'),
		        title: 'Alto',
		        subtitle: '+ 16 seguimientos',
		        earning: '0',
		        progress: '0',
		        color: 'red',
		      },
		    ]
	  	}
	  },

	  async created(){
	  	await this.initialize()
	  	// await this.getDashboard()
	  },

	  computed:{
	  	...mapGetters('login',['getdatosUsuario']),

	  },

	  methods:{
	  	season (val) {
        return this.icons[val]
      },

	  	initialize() {
	  		this.overlay = true
        // Limpiamos los prospectos
        this.prospectos  = []

        // Ver el tipo de usuario que entrara
        let tipo = 0 // null
        if(this.getdatosUsuario.idpuesto == 18){
          tipo = 1 // vendedora
        }else{
          tipo = 2 // Director
        }

        this.$http.get('prospectos.vendedora/' + tipo + '/' + this.getdatosUsuario.iderp).then((response) => {
          this.prospectos = response.data

          this.totalContactos = this.prospectos.length

          this.totalEarning[0].earning  = this.prospectos.filter(el => { return el.cantEventos <= 5 }).length
          this.totalEarning[0].progress = (( this.prospectos.filter(el => { return el.cantEventos <= 5 }).length / this.totalContactos ) * 100).toFixed(0) 

          this.totalEarning[1].earning  = this.prospectos.filter(el => { return el.cantEventos > 5 && el.cantEventos <= 15 }).length
          this.totalEarning[1].progress = (( this.prospectos.filter(el => { return el.cantEventos > 5 && el.cantEventos <= 15 }).length / this.totalContactos ) * 100).toFixed(0) 

          this.totalEarning[2].earning  = this.prospectos.filter(el => { return el.cantEventos > 15 }).length
          this.totalEarning[2].progress = (( this.prospectos.filter(el => { return el.cantEventos > 15 }).length / this.totalContactos ) * 100).toFixed(0) 

          this.overlay = false

          this.getDashboard()
        }).catch(error=>{
          this.overlay = false
        })
      },

      getDashboard(){
      	this.overlay = true

				this.$http.get('data.dashboard/' + this.getdatosUsuario.iderp).then((response) => {
        	this.dataEtapas     = response.data.dataEtapas
        	this.conInformacion = response.data.conInformacion
        	this.tareasHechas   = response.data.tareasHechas
					this.tareasTotales  = response.data.tareasTotales
					this.inscritosHoy   = response.data.inscritosHoy
					this.inscritosTotal = response.data.inscritosTotal
					this.dataLlamadas   = response.data.dataLlamadas
					this.totalEventos   = response.data.totalEventos

					this.convencimiento = (( this.inscritosTotal / this.totalContactos ) * 100).toFixed(0) 
					this.overlay = false


					// Prospectos sin seguimiento hoy y sin tareas programadas
          const prospectosSeguimiento  = this.prospectos.filter(el =>{

            /*
              condiciones
              1-. No ser de induccion y tener 0 evenos hoy ( tareas -> solo si tiene progamada para hoy tareaParaHoy == 1)
              2-. Ser de inducción, tener tarea programada para hoy y 0 ventos hoy tareaParaHoy == 1
            */

            if(el.cantEventosHoy == 0){ 
              // Si no es inducción
              if(el.idetapa != 3){
                // Tiene tareas asignadas
                if(el.tareasP.length > 0){
                  /*
                    Agendada, no importa el día
                    Programada, solo si es para hoy o antes de hoy
                  */
                  if(el.tareasP[0].tipo_tarea == 1 && el.tareasP[0].tareaParaHoy == 1 || el.tareasP[0].tipo_tarea == 2){
                    return true
                  }
                }else{
                  return true
                }
              }else{
                // Si es inducción y tiene tareas
                if(el.tareasP.length > 0){
                  //  y la tarea es agendada y es para hoy
                  if(el.tareasP[0].tipo_tarea == 1 && el.tareasP[0].tareaParaHoy == 1){
                    return true
                  }
                }
              }
            }
          }).length

          // Prospectos con seguimiento hoy y sin tareas programadas
          const prospectosConSeguimiento  = this.prospectos.filter(el =>{
            if(el.cantEventosHoy > 0){ 
              // Si no es inducción
              if(el.idetapa != 3){
                // Tiene tareas asignadas
                if(el.tareasP.length > 0){
                  /*
                    Agendada, no importa el día
                    Programada, solo si es para hoy o antes de hoy
                  */
                  if(el.tareasP[0].tipo_tarea == 1 && el.tareasP[0].tareaParaHoy == 1 || el.tareasP[0].tipo_tarea == 2){
                    return true
                  }
                }else{
                  return true
                }
              }else{
                // Si es inducción y tiene tareas
                if(el.tareasP.length > 0){
                  //  y la tarea es agendada y es para hoy
                  if(el.tareasP[0].tipo_tarea == 1 && el.tareasP[0].tareaParaHoy == 1){
                    return true
                  }
                }
              }
            }
          }).length

          this.seguimientos   = (( response.data.seguimientos / (prospectosSeguimiento + prospectosConSeguimiento)) * 100).toFixed(1)

          console.log(prospectosSeguimiento + prospectosConSeguimiento)

          return
        }).catch(error=>{
         	this.overlay = false
        })
      },

      rechazoProspectoBasura(idprospectos){
        const payload = {
          idprospectos: idprospectos,
          idusuarioerp: this.getdatosUsuario.iderp,
          notaRechazo:  'Prospecto rezagado'
        }
        // Hacer la peticion de los datos
        this.$http.post('prospectos.finalizar.nok', payload).then((response) => {
          // Ya termino de realizar su función
          for(const i in this.prospectosBasura){
            if(this.prospectosBasura[i].idprospectos == idprospectos){
              this.prospectosBasura.splice(i,1)
            }
          }
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

	  },

	  setup() {
	    return {
	      // icons
	      icons: {
	        mdiDotsVertical,
	        mdiTrendingUp,
	        mdiAccountOutline,
	        mdiLabelOutline,
	        mdiCurrencyUsd,
	        mdiChevronUp,
	        mdiChevronDown,
	        mdiMenuUp
	      },
	    }
	  },

	}
</script>
<style type="text/css">
	.back-color{
		background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
		border-radius: 20px !important;
	}

	.lds-ellipsis {
	  display: inline-block;
	  position: relative;
	  width: 80px;
	  height: 80px;
	}
	.lds-ellipsis div {
	  position: absolute;
	  top: 33px;
	  width: 13px;
	  height: 13px;
	  border-radius: 50%;
	  background: #fff;
	  animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}
	.lds-ellipsis div:nth-child(1) {
	  left: 8px;
	  animation: lds-ellipsis1 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(2) {
	  left: 8px;
	  animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(3) {
	  left: 32px;
	  animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(4) {
	  left: 56px;
	  animation: lds-ellipsis3 0.6s infinite;
	}
	@keyframes lds-ellipsis1 {
	  0% {
	    transform: scale(0);
	  }
	  100% {
	    transform: scale(1);
	  }
	}
	@keyframes lds-ellipsis3 {
	  0% {
	    transform: scale(1);
	  }
	  100% {
	    transform: scale(0);
	  }
	}
	@keyframes lds-ellipsis2 {
	  0% {
	    transform: translate(0, 0);
	  }
	  100% {
	    transform: translate(24px, 0);
	  }
	}

</style>

<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}


</style>