<template>
	<v-card class="elevation-0">
		<v-card-text>
			

			<label class="black--text"><strong>Selecciona el tipo de Alumno</strong></label>
			<v-radio-group
	      v-model="seleccionTipoAlumno.opTipoAlumno"
	      row
	    >
	      <v-radio
	        label="RI"
	        :value="1"
	      ></v-radio>
	      <v-radio
	        label="NI"
	        :value="2"
	      ></v-radio>
	    </v-radio-group>

	    <v-divider class="my-4"></v-divider>

			<label class="black--text" v-if="seleccionTipoAlumno.opTipoAlumno"><strong>Selecciona una opción</strong></label>
			<v-radio-group
	      v-model="seleccionTipoAlumno.opAlumno"
	      v-if="seleccionTipoAlumno.opTipoAlumno"
	      row
	    >
	      <v-radio
	        label="Único"
	        :value="1"
	      ></v-radio>
	      <v-radio
	        label="Hermanos"
	        :value="2"
	      ></v-radio>
	    </v-radio-group>

	    <v-alert
	    	v-if="seleccionTipoAlumno.opTipoAlumno == 2 && seleccionTipoAlumno.opAlumno == 2"
	      border="bottom"
	      type="info"
	      text
	      dark
	    >
	      Recuerda que deberás tomar en cuenta lo siguiente:
	      <br/>
	      <br/>
	      1-. Los alumnos ingresados deberán contar con los mismos apellidos.
	      <br/>
	      2-. Los alumnos ingresados deberán contar con el mismo curso.
	    </v-alert>
			<br/>

			<!-- Alumno unico NI -->
	    <AlumnoUnico 
	    	v-if="seleccionTipoAlumno.opTipoAlumno == 2 && seleccionTipoAlumno.opAlumno == 1"
	    	:steppers="steppers"
	    	:hermanos="hermanos"
	    	:tutor="tutor"
	    	:alumno="alumno"
			  :dialogAddAlumno="dialogAddAlumno"
	    />

			<!-- Alumno hermanos NI -->
	    <AlumnoHermanos
	    	v-if="seleccionTipoAlumno.opTipoAlumno == 2 && seleccionTipoAlumno.opAlumno == 2"
	    	:steppers="steppers"
	    	:hermanos="hermanos"
	    	:tutor="tutor"
	    	:alumno="alumno"
			  :dialogAddAlumno="dialogAddAlumno"
	    />

	    <!-- agregar grupo a hermnaos RI -->
      <AlumnoHermanosRI
	    	v-if="seleccionTipoAlumno.opTipoAlumno == 1 && seleccionTipoAlumno.opAlumno == 2 "
	    	:steppers="steppers"
	    	:hermanos="hermanos"
	    	:tutor="tutor"
	    	:alumno="alumno"
			  :dialogAddAlumno="dialogAddAlumno"
	    />
      </div>

      <!-- agregar grupo a alumno RI -->
      <AlumnoUnicoRI
	    	v-if="seleccionTipoAlumno.opTipoAlumno == 1 && seleccionTipoAlumno.opAlumno == 1"
	    	:steppers="steppers"
	    	:hermanos="hermanos"
	    	:tutor="tutor"
	    	:alumno="alumno"
			  :dialogAddAlumno="dialogAddAlumno"
	    />


		</v-card-text>

		<v-card-actions v-if="!seleccionTipoAlumno.opAlumno && !seleccionTipoAlumno.opTipoAlumno">
      <v-btn text @click="dialogAddAlumno.estatus = false">
        Cancelar
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions> 
	</v-card>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import AlumnoUnico      from '@/components/inscripciones/steppers/AlumnoUnico.vue'
  import AlumnoUnicoRI    from '@/components/inscripciones/steppers/AlumnoUnicoRI.vue'
  import AlumnoHermanos   from '@/components/inscripciones/steppers/AlumnoHermanos.vue'
  import AlumnoHermanosRI from '@/components/inscripciones/steppers/AlumnoHermanosRI.vue'

  import validarErrores  from '@/mixins/validarErrores'

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
      AlumnoUnico,
			AlumnoHermanos,
			AlumnoUnicoRI,
			AlumnoHermanosRI
    },

    mixins: [ validarErrores ],

  	props:[
			'grupo',
			'alumno',
			'steppers',
			'hermanos',
			'tutor',
			'dialogAddAlumno',
			'seleccionTipoAlumno'
	  ],

    data: () => ({
    	escuela: 2,
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

			step:1,
			isTutor:true,

    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),
    },

    watch:{
    	hermanos(){
    		console.log( this.hermanos )
    	}
    },

    async created () {
    	
    },

    methods: {

    },
  }
</script>
