<template>
	<v-dialog
    v-model="paramResagados.estatus"
    hide-overlay
    persistent
    max-width="800"
  >
    <v-card>
      <v-card-title primary-title >
        <v-icon left>mdi-delete</v-icon> Papelera
      </v-card-title>
      <v-card-text>
        <v-alert
          border="left"
          colored-border
          type="error"
          elevation="2"
        >
          Los prospectos a continuación son prospectos que:<br/>
          1-. Llevan más de 7 días y no se les ha brindado información<br/>
          2-. Llevan más de 10 días, se les brindo información más no hubo respuesta<br/>
        </v-alert>
        <v-data-table
          :headers="headersRezagados"
          :items="prospectosBasura"
          class="elevation-0"
          dense
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              @click="rechazoProspectoBasura(item.idprospectos)"
              color="error"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:item.idetapa="{ item }">
            <!-- chip para prospectos atrasados -->
            <v-tooltip bottom color="blue" v-if="item.idetapa == 1">
              <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 1">
                <v-icon v-if="item.idetapa == 1" v-bind="attrs" v-on="on" color="blue">mdi-alert-circle</v-icon>
              </template>
              <span>El proespecto esta esperando que se contacten con el</span>
            </v-tooltip>

            <!-- chip para prospectos nuevos -->
            <v-tooltip bottom color="blue" v-if="item.idetapa == 2">
              <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 2">
                <v-icon v-if="item.idetapa == 2" v-bind="attrs" v-on="on" color="blue">mdi-alert-circle</v-icon>
              </template>
              <span>Prospecto llego hoy y no tiene información</span>
            </v-tooltip>

            <!-- chip para prospectos en inducción -->
            <v-tooltip bottom color="blue" v-if="item.idetapa == 3">
              <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 3">
                <v-icon v-if="item.idetapa == 3" v-bind="attrs" v-on="on" color="blue" left>mdi-bell</v-icon>
              </template>
              <span>El prospecto se encuentra en inducción</span>
            </v-tooltip>

            <!-- chip para prospectos en seguimiento con información -->
            <v-tooltip bottom color="blue" v-if="item.idetapa == 4">
              <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 4">
                <v-icon v-if="item.idetapa == 4" v-bind="attrs" v-on="on" color="blue">mdi-alert-circle</v-icon>
              </template>
              <span>Con información<br/>más de 4 intentos de contacto<br/>más de 10 días que ingresó</span>
            </v-tooltip>

            <!-- chip para prospectos sin información -->
            <v-tooltip bottom color="blue" v-if="item.idetapa == 5">
              <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 5">
                <v-icon v-if="item.idetapa == 5" v-bind="attrs" v-on="on" color="blue">mdi-alert-circle</v-icon>
              </template>
              <span>Sin información<br/>más de 4 intentos de contacto<br/>más de 7 días que ingresó</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error"   dark @click="cerrar()">Cancelar</v-btn>
      </v-card-actions>
      <v-btn 
        color="transparent elevation-0"
        x-small
        absolute
        top
        right
        fab
        class="mt-5 mr-0"
        @click="cerrar()"
       ><v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

		props:[
			'paramResagados',
			'prospectosBasura'
	  ],

	  computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      headersRezagados: [
        { text: 'Folio',    value: 'folio' },
        { text: 'Nombre',   value: 'nombre_completo', },
        { text: 'Etapa',    value: 'idetapa', },
        { text: 'Actions',  value: 'actions', sortable: false },
      ],
      search:'',
    }),


    methods:{
    	//Boton Basura
      rechazoProspectoBasura(idprospectos){
        if( this.getdatosUsuario.idpuesto != 18 ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        const payload = {
          idprospectos: idprospectos,
          idusuarioerp: this.getdatosUsuario.iderp,
          notaRechazo:  'Prospecto rezagado'
        }

        // Hacer la peticion de los datos
        this.$http.post('prospectos.finalizar.nok', payload).then((response) => {
          // Ya termino de realizar su función
          for(const i in this.prospectosBasura){
            if(this.prospectosBasura[i].idprospectos == idprospectos){
              this.prospectosBasura.splice(i,1)
            }
          }

          this.dialogSuccess = true
        }).catch(error=>{
          this.validError( error )
        }).finally(()=> { this.overlay = false })
      },

      cerrar(){
      	this.paramResagados.estatus = false
      	this.$emit('initialize')
      }
    }
	}
</script>
