const infoFast = [
	{ 
		titulo: 'Metodología',
		mostrar: true,
		subtemas:[
			{ titulo: 'DAS WAY',                         check: false},
			{ titulo: 'Diviértete, aprende y socializa', check: false}
		]
	},

	{ 
		titulo: '¿Por qué somos la mejor escuela?',
		mostrar: true,
		subtemas:[
			{ titulo: 'Método exclusivo DAS Way (Diviértete, aprende y socializa)'       , check: false},
			{ titulo: 'Mayor contenido en menor tiempo'                                  , check: false},
			{ titulo: 'Clases 100% en inglés'                                            , check: false},
			{ titulo: 'Plataforma virtual'                                               , check: false},
			{ titulo: 'Aulas inteligentes'                                               , check: false},
			{ titulo: 'Certificación en base al Marco Común Europeo de Referencia (MCER)', check: false},
			{ titulo: 'Costo único y sin cargos fantasmas'                               , check: false},
		]
	},

	{ 
		titulo: 'Cursos',
		mostrar: true,
		subtemas:[
			{ titulo: 'Online'      , check: false},
			{ titulo: 'Intensio'    , check: false},
		]
	},

	{ 
		titulo: 'Curso de inducción',
		mostrar: true,
		subtemas:[
			{ titulo: 'Determinar, normalizar y trabajar tu nivel actual de inglés'      , check: false},
		]
	},

	{ 
		titulo: 'Plan de estudios',
		mostrar: true,
		subtemas:[
			{ titulo: '14 niveles'                                     , check: false},
			{ titulo: 'Cada nivel con una duración de 4 semanas '      , check: false},
		]
	},

	{ 
		titulo: 'Plan de inversión',
		mostrar: true,
		subtemas:[
			{ 
				titulo: 'Online',
				check: false,
				subsubtemas:[
					{titulo:'Material: 2,500'},
					{titulo:'Nivel: 1,325'},
					{titulo:'Inscripción: 800'},
					{titulo:'Total: 4,625.00'},
				]
			},
			{ 
				titulo: 'Intensivo',
				check: false,
				subsubtemas:[
					{titulo:'Material: $2,500'},
					{titulo:'Nivel: $1,999'},
					{titulo:'Inscripción: $800'},
					{titulo:'Total: $5,299'},
				]
			},
		]
	},

	{ 
		titulo: 'Cierre',
		mostrar: true,
		subtemas:[
			{ titulo: 'Resumen de información'   , check: false},
			{ titulo: 'Pregunta de cierre'       , check: false},
		]
	},
]

export { infoFast }