<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Rebajes Nómina</span>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              dark
              class="mr-2" 
              @click="goNomina()"
              small
              tile
            >
              Nómina
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize(), seguroIMSS(), cajaAhorro(),  allretardos()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="3" >
                <v-text-field
                  filled
                  single-line
                  label="Fecha inicio"
                  type="date"
                  hide-details
                  v-model="fecha"
                  dense
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <label></label>
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="rebajesnomina"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                >

                  <template v-slot:item.tipo_rebaje="{ item }">
                    <v-chip v-if="item.tipo_rebaje == 1" small color="green" dark >Retención</v-chip>
							      <v-chip v-if="item.tipo_rebaje == 2" small color="pink" dark >Retardo</v-chip>
                    <v-chip v-if="item.tipo_rebaje == 4" small color="red" dark >Compensación</v-chip>
                    <v-chip v-if="item.tipo_rebaje == 5" small color="black" dark >Otros</v-chip>
                    <v-chip v-if="item.tipo_rebaje == 6" small color="blue" dark >Seguro IMSS</v-chip>
                    <v-chip v-if="item.tipo_rebaje == 7" small color="green" dark >Caja de Ahorro</v-chip>
                    <v-chip v-if="item.tipo_rebaje == 8" small color="orange" dark >Devoluciones</v-chip>
                    <v-chip v-if="item.tipo_rebaje == 9" small color="orange" dark >Celular</v-chip>
                    <v-chip v-if="item.tipo_rebaje == 10" small color="orange" dark >Falta en corte</v-chip>
                    <v-chip v-if="item.tipo_rebaje == 11" small color="orange" dark >Llaves</v-chip>
                    <v-chip v-if="item.tipo_rebaje == null || item.tipo_rebaje == 0" small color="gray" dark >Sin Tipo Rebaje</v-chip>
							    </template>

                  <template v-slot:item.update="{ item }">
                    <v-icon small @click="editItem(item)" color="blue">
                      mdi-magnify
                    </v-icon>
                  </template>

                  <template v-slot:item.eliminar="{ item }">
                    <v-icon small @click="deleteItem(item)" color="error">
                      mdi-delete
                    </v-icon>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
            <v-col cols="12">
            <v-autocomplete
                :items="usuarios"
                v-model="editedItem.iderp"
                label="Empleado"
                outlined
                hide-details
                dense
                item-text="nombre_completo"
                item-value="id_usuario"
            ></v-autocomplete>
            </v-col>

            <v-col cols="12">
            <v-autocomplete
               :items="[
               {tipo_rebaje:4, descripcion:'Compensación'},{tipo_rebaje:5, descripcion:'Otros Rebajes'}, 
               {tipo_rebaje:6, descripcion:'Seguro IMSS'},{tipo_rebaje:7, descripcion:'Caja Ahorro'},
               {tipo_rebaje:9, descripcion:'Celular'},{tipo_rebaje:10, descripcion:'Falta en Corte'},
               {tipo_rebaje:11, descripcion:'Llaves'}]"
                v-model="editedItem.tipo_rebaje"
                label="Tipo Rebaje/Compensación"
                outlined
                hide-details
                dense
                item-text="descripcion"
                item-value="tipo_rebaje"
            ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
            <v-text-field
              v-model="editedItem.fecha"
              label="Fecha"
              filled
              hide-details
              dense
              type="date"
            ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field 
                v-model="editedItem.cantidad"
                label="Cantidad"
                filled
                hide-details
                dense
                type = "number"
              ></v-text-field >
            </v-col>

           <v-col cols="12" md="12">
              <v-textarea
                v-model="editedItem.comentario"
                label="Comentarios"
                outlined
                hide-details
                dense
                auto-grow
                style="max-width: 900px; margin-right: auto"
              ></v-textarea >
            </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
          <v-btn color="blue darken-1" class="elevation-6" dark @click="save">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar esta fila?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      large
                      class="mr-2"
                      @click="closeDelete()"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      large
                      @click="deleteItemConfirm()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,
    headers: [
      // { text: "ID", align: "start", value: "idrebajes_nomina" },
      { text: "Nombre", value: "nombre_completo" },
      { text: "Fecha", value: "fecha" },
      { text: "Tipo Rebaje/Compensación", value: "tipo_rebaje" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Comentarios", value: "comentario" },
      // { text: "Actualizar", value: "update", sortable: false },
      { text: "Eliminar", value: "eliminar", sortable: false },
    ],
    rebajesnomina: [],
    editedIndex: -1,
    editedItem: {
      idrebajes_nomina: "",
      tipo_rebaje: "",
      cantidad: "",
      comentario: "",
      iderp: "",
      deleted: 0,
      fecha: ""
    },
    defaultItem: {
     idrebajes_nomina: "",
      tipo_rebaje: "",
      cantidad: "",
      comentario: "",
      iderp: "",
      deleted: 0,
      fecha: ""
    },

    dialogSuccess: false,
    overlay: false,
    dialogError: false,
    error: "",
    successMensaje: "",
    errorBack: false,
    textoCopiado: "COPIAR",
    idcurso: 0,
    info: 0,
    dialog_mensaje_rechazo: false,
    idhistorial: [],
    editedItem2: {
      mensaje: "",
    },
    mensajes: [],
    usuarios: [],
    seguromedicoimss: [],
    devoluciones: [],
    cajaahorro: [],
    retardos: [],
		fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

    ciclos: [],
    ciclo: null,
    estatus: null,
    alumnos: [],
    reglas: [],
    cursosEscuela: [],
    horasExtras: "",
    // tabla
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
    formTitle() {
      return this.editedIndex === -1
        ? "Agregar Rebajes Nómina"
        : "Editar Rebajes Nómina";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "editedItem.escuela"(val) {
      this.cursosEscuela = this.cursos.filter((el) => {
        return el.escuela == val;
      });
    },
  },

  created() {

    this.fecha = this.$route.params.fecha ? this.$route.params.fecha : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    this.search = this.$route.params.nombre ? this.$route.params.nombre : ''
    
    this.initialize();
    this.seguroIMSS();
    this.cajaAhorro();
    this.allretardos();
    this.getUsuarios();
  },

  methods: {
    initialize() {
      this.rebajesnomina = [];
      this.overlay = true;

      const payload = {
        fecha: this.fecha,
      }

      this.$http
        .post("rebajes_nomina.get.rebajes_nomina", payload)
        .then((response) => {
          this.rebajesnomina = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    seguroIMSS() {
      this.seguromedicoimss = [];
      this.overlay = true;

      this.$http
        .post("seguro_medico_imss.get.seguromedicoimss")
        .then((response) => {
          this.seguromedicoimss = response.data;

          for (const objeto of this.seguromedicoimss) {  
            objeto.tipo_rebaje = 6;
            objeto.comentario = "Seguro Medico IMSS";
          }

          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    cajaAhorro() {
      this.cajaahorro = [];
      this.overlay = true;

      this.$http
        .post("caja_ahorro.get.cajaahorro")
        .then((response) => {
          this.cajaahorro = response.data;
          for (const objeto of this.cajaahorro) {  
            objeto.tipo_rebaje = 7;
            objeto.comentario = 'Caja de Ahorro';
          }
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    allretardos () {
      this.cargar = true
      this.retardos = []

      const payload = {
        fecha:    this.fecha,   
      }
      return this.$http.post('usuarios.entradas', payload).then(response=>{

        this.retardos = response.data.result3.filter(item => item.hasOwnProperty('monto_retardo'));

        this.devoluciones = response.data.result3.filter(item => item.hasOwnProperty('devoluciones') && item.devoluciones !== '0.00');

         for (const objeto of this.retardos) {  
            objeto.tipo_rebaje = 2;
            objeto.comentario = 'Rebaje por Retardo';
            objeto.cantidad = objeto.monto_retardo;
            objeto.fecha = this.fecha;
          }

          for (const objeto of this.devoluciones) { 
            objeto.tipo_rebaje = 8;
            objeto.comentario = 'Devolucion';
            objeto.cantidad = objeto.devoluciones;
            objeto.fecha = this.fecha;
          }

          this.rebajesnomina = this.rebajesnomina.concat(this.seguromedicoimss, this.cajaahorro, this.retardos, this.devoluciones);

      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    getUsuarios() {
      this.usuarios = [];
      this.overlay = true;

      this.$http
        .post("rebajes_nomina.get.usuarios")
        .then((response) => {
          this.usuarios = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    editItem(item) {
      this.editedIndex = this.rebajesnomina.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.rebajesnomina.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // Lo mandapos por el EP
      this.editedItem.deleted = 1;

      if( this.editedItem.tipo_rebaje != 6 && this.editedItem.tipo_rebaje != 7 ){
        this.$http.put("rebajes_nomina.update/" + this.editedItem.idrebajes_nomina,
        this.editedItem)
        .then((response) => {
          this.dialogSuccess = true;
          this.overlay = false;
        })
        .catch((error) => {
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
      } else if(this.editedItem.tipo_rebaje == 6){
        this.$http.put("seguro_medico_imss.update/" + this.editedItem.idseguro_medico_imss,
        this.editedItem)
        .then((response) => {
          this.dialogSuccess = true;
          this.overlay = false;
        })
        .catch((error) => {
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
      } else if(this.editedItem.tipo_rebaje == 7){
        this.$http.put("caja_ahorro.update/" + this.editedItem.idcaja_ahorro,
        this.editedItem)
        .then((response) => {
          this.dialogSuccess = true;
          this.overlay = false;
        })
        .catch((error) => {
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
      }
      this.closeDelete();
      this.initialize();
      this.seguroIMSS();
      this.cajaAhorro();
      this.allretardos();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      
      if (this.editedIndex > -1) {
        // Activamos el loader
        this.overlay = true;
        this.editedItem.deleted = 0;
        // Lo mandapos por el EP
        this.$http
          .put(
            "rebajes_nomina.update/" + this.editedItem.idrebajes_nomina,
            this.editedItem
          )
          .then((response) => {
            this.dialogSuccess = true;
            this.initialize();
            this.overlay = false;
          })
          .catch((error) => {
            // INdicamos que hay un error en el back
            if (error.body.message) {
              this.error = error.body.message;
            } else {
              this.error = error.body;
            }
            this.errorBack = true;
            this.overlay = false;
            this.dialogError = true;
          });
      } else {
        // Activamos el loader
        this.overlay = true;
        // Lo mandapos por el EP

        if (this.editedItem.cantidad == "" || this.editedItem.comentario == "") {
        return this.validarErrorDirecto("Favor de llenar las casillas de cantidad y/o comentario");
        }

        this.$http
          .post("rebajes_nomina.add.rebajes_nomina", this.editedItem)
          .then((response) => {
            this.dialogSuccess = true;
            this.initialize()
            this.overlay = false;
          })
          .catch((error) => {
            // INdicamos que hay un error en el back
            if (error.body.message) {
              this.error = error.body.message;
            } else {
              this.error = error.body;
            }
            this.errorBack = true;
            this.overlay = false;
            this.dialogError = true;
          });
      }
      this.initialize();
      this.seguroIMSS();
      this.cajaAhorro();
      this.allretardos();
      this.close();
    },

    goNomina(){
			this.$router.push({name: "Nomina"})
		},

    copiarPortapapeles() {
      navigator.clipboard
        .writeText(this.error)
        .then(() => {
          this.textoCopiado = "COPIADO";
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });
    },

  },
};
</script>
