<template>
	<v-container class="mt-4" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  			<v-card-title primary-title>
	  			  <span class="text-subtitle-1">Becas de inglés</span>
	  			  <v-spacer></v-spacer>
	  			  <v-chip color="primary" small @click="initialize( )">Actualizar</v-chip>
	  			</v-card-title>

	  			<v-card-text class="pt-0">
	  			  <v-row justify="end" class="pt-0">
	  			  	<v-col cols="12" md="6" lg="4" class="pt-0">
	  			  		<v-text-field
	  			  		  label="Buscar"
	  			  		  id="id"
	  			  		  v-model="search"
	  			  		  outlined
	  			  		  dense
	  			  		  rounded
	  			  		  hide-details
	  			  		></v-text-field>
	  			  	</v-col>
	  			  </v-row>
          

            <v-radio-group
              v-model="filtro"
              row
            >
              <v-radio
                label="Sin beca"
                :value="1"
              ></v-radio>
              <v-radio
                label="No inscrito"
                :value="2"
              ></v-radio>

              <v-radio
                label="Todos"
                :value="3"
              ></v-radio>
            </v-radio-group>
	  			</v-card-text>


	  			<v-card-text>
		  			<v-data-table
					    :headers="headers"
					    :items="filtroBecas"
					    sort-by="calories"
					    class="elevation-0"
					    :search="search"
					  >

					    <template v-slot:item.pago_realizado="{ item }">
					      <v-chip color="green" dark small v-if="item.pago_realizado >= 1399 ">{{ item.pago_realizado }} </v-chip>
					      <v-chip color="orange" dark small v-else-if="item.pago_realizado >= 700   ">{{ item.pago_realizado }} </v-chip>
					      <v-chip color="red" dark small v-else>{{ item.pago_realizado }} </v-chip>
					    </template>

              <template v-slot:item.agregargrupo="{ item }">
                <v-chip color="primary" dark small @click="alumno = item, dialogGrupo = true"> Agregar grupo </v-chip>
              </template>

              <template v-slot:item.beca="{ item }">
                <v-chip color="#00D69C"  dark v-if="item.beca" small> Con beca </v-chip>
                <v-chip color="red"      dark v-else-if="item.id_grupo" @click="solicitarBeca( item ) " small> Sin beca </v-chip>
              </template>

              <template v-slot:item.inscrito="{ item }">
                <v-chip color="#00D69C"  dark v-if="item.inscrito" small> Listo </v-chip>
                <v-chip color="red"      dark v-else-if="item.id_grupo" small> Aún no </v-chip>
              </template>

              <template v-slot:item.persona_beca="{ item }">
                <v-chip color="green" dark small v-if="item.persona_beca == 'Yo'">{{ item.persona_beca }}</v-chip>
                <v-chip color="green" dark small v-else>{{ item.alguien_mas }}</v-chip>
              </template>

              <template v-slot:item.grupo_lms="{ item }">
                <v-chip color="green" dark small v-if="item.grupo_lms">Sí</v-chip>
                <v-chip color="red" dark small v-else-if="item.id_grupo">NO</v-chip>
              </template>

					    <template v-slot:no-data>
					      <v-btn
					        color="primary"
					        @click="initialize"
					      >
					        Reset
					      </v-btn>
					    </template>
					  </v-data-table>
	  			</v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

    <v-dialog
      v-if="alumno"
      v-model="dialogGrupo"
      persistent
      max-width="500"
    >
  
      <v-card>
        <v-card-title class="text-h5">
          Agregar grupo
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            :items="grupos"
            v-model="alumno.id_grupo"
            label="Grupo"
            item-text="grupo"
            item-value="id_grupo"
            filled
            clearable
            dense
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            dark
            small
            rounded
            @click="dialogGrupo = false, alumno = null "
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            small
            rounded
            @click="guardar()"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>					
</template>
<script>
import axios from 'axios';
  import {mapGetters, mapActions} from 'vuex'
  // Componentes
  import Alerta             from '@/components/alertas/Alerta.vue'
  import carga              from '@/components/alertas/carga.vue';
  import funcionesExcel     from '@/mixins/funcionesExcel'


  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ funcionesExcel ],

    data: () => ({
    	search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'matricula'     , value: 'matricula'       },
        { text: 'Nombre'        , value: 'nombre'          },
        { text: 'Para quién?'   , value: 'persona_beca'    },
        { text: 'Beca'          , value: 'beca'            },
        { text: '¿Inscrito?'    , value: 'inscrito'        },
        { text: 'Grupo'         , value: 'grupo'           },
        { text: 'Acciones'      , value: 'agregargrupo'    },
        { text: 'LMS'           , value: 'grupo_lms'       },
      ],
      registrosExci: [],
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      alumnos:[],
      grupos:[],
      dialogGrupo:false,
      alumno: null,
      grupo: null,
      filtro:3,
    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),

      filtroBecas( ){
        if( this.filtro && this.filtro == 3){
          return this.registrosExci
        }

        if( this.filtro && this.filtro == 1){
          return this.registrosExci.filter( el=> { return !el.beca && el.id_grupo })
        }

        if( this.filtro && this.filtro == 2){
          return this.registrosExci.filter( el=> { return !el.inscrito && el.id_grupo })
        }
      }
    },

    async created () {
      await this.getGrupos()
      await this.initialize()
    },

    methods: {
      initialize () {
        this.registrosExci = []
        return this.$http.get('exci.becas.ingles').then( response=> {
        	this.registrosExci = response.data
        }).catch( error => {
        	console.log( error )
        }).finally( ( ) => {  })
      },

      solicitarBeca( item ){
        return this.$http.post('exci.solicitar.beca', item).then( response=> {
          this.getGrupos()
          this.initialize()
        }).catch( error => {
          console.log( error )
        }).finally( ( ) => {  })
      },


      getGrupos () {
        this.grupos = []
        return this.$http.get('exci.grupos.acitivos').then( response=> {
          this.grupos = response.data
        }).catch( error => {
          console.log( error )
        }).finally( ( ) => {  })
      },

      guardar ( ){
        console.log( this.alumno )
        return this.$http.post('exci.grupos.agregar', this.alumno).then( response=> {
          this.dialogGrupo = false
          this.alumno      = null
          this.initialize()
        }).catch( error => {
          console.log( error )
        }).finally( ( ) => {  })

      },

      exportar(){
        this.exportExcel( this.registrosExci, 'alumnos_exci')
      },

      validarErrores ( value ) {
        this.cargar = false
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

    },
  }
</script>