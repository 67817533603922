<template>
  <v-container class="mt-4 px-5" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title class="text-subtitle-1">
            Reasignar prospecto/vendedora
            <v-spacer></v-spacer>
            <v-btn color="primary" small @click="initialize()">Actualizar</v-btn>
          </v-card-title>

          <v-card-text>
            <v-row >
              <v-col cols="12" sm="4" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="fechaini"
                  hide-details
                  dense
                  filled
                  type="date"
                  label="Fecha inicio"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="fechafin"
                  hide-details
                  dense
                  filled
                  type="date"
                  label="Fecha final"
                  clearable
                ></v-text-field>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12" md="6" lg="5">
                <v-text-field
                label="Buscar Prospecto"
                dense
                v-model="search"
                filled
                append-icon="mdi-magnify"
                dense
                ></v-text-field>
              </v-col>
            </v-row>


            <!--Lista de prospectos-->
            <v-data-table
              :headers="headers"
              :items="filterProspectos"
              class="elevation-0"
              :search="search"
              dense
              >

              <template v-slot:item.ver="{ item }">
                <v-icon small class="mr-2" @click="(prospecto = item), (dialogUsuario = true)" color="primary"> mdi-pencil </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <v-dialog 
      v-model="dialogUsuario" 
      max-width="500px"
      >

      <!-- Lista de vendedoras -->

      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Reasignar prospecto/vendedora</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                :items="usuarios"
                v-model="usuario"
                label="Selecciona vendedora"
                item-value="id_usuario"
                item-text="nombre_completo"
                filled
                dense
                hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn 
            color="red"
            dark
            small
            rounded
            @click="close"
          > 
            Cancelar 
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn 
            color="primary"
            dark
            small
            rounded 
            @click="actualizar()">
            Guardar
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>


<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],
    
    //Data
    data: () => ({

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      //datos que se desplegaran en las 2 tablas
      prospectos: [],
      usuarios: [],
      usuario: null,
      dialogUsuario: false,

      headers: [
        { text: "Folio"    , value: "folio" },
        { text: "Telefono" , value: "telefono" },
        { text: "Nombre"   , value: "nombre_completo" },
        { text: "Vendedora", value: "name" },
        { text: "Fecha"    , value: "fecha_creacion" },
        { text: "Reasignar a...", value: "ver", sortable: false },
      ],

      fechaini: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechafin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),


      search: "",
    }),

    //Computed
    computed: {

      filterProspectos(){
        if(this.prospectos){
          return this.prospectos.filter((prospecto)=>{
            // Sin fecha, poner todos
            if(!this.fechaini && !this.fechafin){
              return true
            }

            // Solo fecha de inicio seleccionada
            if(this.fechaini && !this.fechafin){
              if( prospecto.fecha_creacion == this.fechaini){
                return true
              }
            }

            // Solo fecha de inicio seleccionada
            if(this.fechaini && this.fechafin){
              if(prospecto.fecha_creacion >= this.fechaini && prospecto.fecha_creacion <= this.fechafin ){
                return true
              }
            }
          })
        }
      },
    },

    watch: {
      dialogUsuario(val) {
        val || this.close();
      },
    },

    async created() {
      this.initialize();
    },

    //Methods
    methods: {

      //Obtener prospectos y usuarios
      async initialize() {
        this.cargar = true
        await this.getProspectosAllCambiar();
        await this.getVendedoras();
        this.cargar = false
      },

      getProspectosAllCambiar(){
        return this.$http.get("cambiar.prospecto.obtener.prospecto").then((response) => {
          this.prospectos = response.data;
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getVendedoras() {
        this.usuarios = []
        return this.$http.get("cambiar.prospecto.obtener.vendedora").then((response) => {
          this.usuarios = response.data;
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
      
      //Cerrar Dialog Vendedora
      close() {
        this.dialogUsuario = false;
      },

      //Modificar este para ver si funciona, esta traido de ActivarProspectos
      actualizar() {
        this.prospecto.usuario_asignado = this.usuario;
        this.cargar = true
        return this.$http.post("cambiar.prospecto.updateprospectovendedora", this.prospecto).then((response) => {
          this.dialogUsuario = false;
          this.cargar = false
          this.prospecto = null;
          this.initialize();
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
    },
  };
</script>