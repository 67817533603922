<template>
  <v-container class="mt-5 ml-2">
    <v-row>
    	<v-col cols="12">
    		<v-card class="shadowCard">
          <v-card-title class="text-subtitle-1">
            Clase iniciada
            <v-spacer></v-spacer>
		        <v-btn 
	            class="mr-2" 
	            small dark color="green"
	            @click="exportar()">
	            <v-icon>mdi-microsoft-excel</v-icon>
	          </v-btn>
		        <v-btn color="primary" small rounded @click="getGrupos()">Actualizar</v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4" lg="3" class="pb-0">
                <v-autocomplete
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                  filled
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" lg="3" class="pb-0">
                <v-text-field
                  name="name"
                  label="label"
                  id="id"
                  v-model="dia"
                  filled
                  hide-details
                  clearable
                  type="date"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="2" class="pb-0">
                <v-autocomplete
                  v-model="hora_inicio"
                  :items="horas"
                  dense
                  label="Hora"
                  filled
                  hide-details
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="12" md="6">
              	<v-text-field
				          label="Buscar"
				          v-model="search"
				          dense
				          filled
				        ></v-text-field>
              </v-col>
            </v-row>

  			    <v-data-table
  			      :headers="headers"
  			      :items="clases"
  			      :search="search"
  			    >
  				    <template v-slot:item.inicio_tiempo="{ item }">
  					    <v-checkbox
  					    	class="ma-0 pa-0"
  					      v-model="item.inicio_tiempo"
  					      hide-details
  					      dense
  					      @change="update(item)"
  					    ></v-checkbox>
  				    </template>

  				    <template v-slot:item.maestro_asignado="{ item }">
  					    <v-checkbox
  					    	class="ma-0 pa-0"
  					      v-model="item.maestro_asignado"
  					      hide-details
  					      dense
  					      @change="update(item)"
  					    ></v-checkbox>
  				    </template>

  				    <template v-slot:item.contenido_correcto="{ item }">
  					    <v-checkbox
  					    	class="ma-0 pa-0"
  					      v-model="item.contenido_correcto"
  					      hide-details
  					      dense
  					      @change="update(item)"
  					    ></v-checkbox>
  				    </template>

  				    <template v-slot:item.finaliza_tiempo="{ item }">
  					    <v-checkbox
  					    	class="ma-0 pa-0"
  					      v-model="item.finaliza_tiempo"
  					      hide-details
  					      dense
  					      @change="update(item)"
  					    ></v-checkbox>
  				    </template>

              <template v-slot:item.ninguna="{ item }">
                <v-checkbox
                  class="ma-0 pa-0"
                  v-model="item.ninguna"
                  hide-details
                  dense
                  @change="update(item)"
                ></v-checkbox>
              </template>
  			    </v-data-table>
          </v-card-text>
    		</v-card>
    	</v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

	import XLSX from 'xlsx'

  export default {
   components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

    	cicloInf: 		null,
    	ciclos:  			[],
    	dialogError: 	false,
			dialogSuccess:false,
			error: 				'',
			successMensaje:'',
			overlay: 			false,

      search: '',
      headers: [
        { text: 'Id grupo'          , value: 'id_grupo' },
        { text: 'Grupo'             , value: 'grupo' },
        { text: 'Escuela'           , value: 'escuela' },
        { text: 'Inició a tiempo'   , value: 'inicio_tiempo' },
        { text: 'Maestro asignado'  , value: 'maestro_asignado' },
        { text: 'Contenido Correcto', value: 'contenido_correcto' },
        { text: 'Finalizó a tiempo' , value: 'finaliza_tiempo' },
        { text: 'Ninguna'           , value: 'ninguna' },
      ],

      teachers: [],

      overlay:false,
      dialogError: false,
      error:'',

      hora_inicio:null,
      horas:[],
      clases:[],

      dia: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }),

    watch:{
	    cicloInf () {
        if(this.cicloInf){
          this.getHoras()
        }
      },

	    hora_inicio () {
	      if(this.hora_inicio && this.cicloInf){ 
	      	this.getGrupos() 
	      }
	    }
	  },

    async created () {
    	await this.getCiclos()
    },

    methods: {
    	getCiclos () {
        this.overlay = true
        this.ciclos = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.overlay = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getHoras () {
      	this.overlay = true
        this.$http.get(`kpi.horarios/${ this.cicloInf.id_ciclo_relacionado }/${ this.cicloInf.id_ciclo }`).then(response=>{
          for(const i in response.data){
            if(response.data[i].hora_inicio){
              this.horas.push(response.data[i].hora_inicio)
            }
          }
          this.overlay = false
          return
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    	getGrupos () {
    		if(this.cicloInf && this.hora_inicio){
	    	 	this.overlay = true
	    		this.clases  = []
	    		
	    		const payload = {
	    			cicloFast:   this.cicloInf.id_ciclo_relacionado,
	    			cicloInbi:   this.cicloInf.id_ciclo,
	    			hora_inicio: this.hora_inicio,
            fecha:       this.dia
	    		}

	    		this.$http.post('kpi.clases.hora.dia', payload).then(response=>{
	        	this.clases  = response.data
	        	this.overlay = false
	        }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
    		}else{
          this.validarErrorDirecto( 'Por favor, elige una hora y un ciclo' )
    		}
    	},

    	openDialogError (mensaje) {
      	this.error       = mensaje
      	this.dialogError = true
      },

      openDialogSuccess ( mensaje ){
      	this.successMensaje = mensaje
      	this.dialogSuccess  = true
      },

      update ( item ) {
      	this.$http.post('kpi.clases.hora.dia.update', item).then(response=>{
        	this.overlay = false
      		this.getGrupos()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportar(){
        let data = XLSX.utils.json_to_sheet(this.teachers)
        const workbook = XLSX.utils.book_new()
        const filename = 'clase_iniciada_fast'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });

      },
    }
  }
</script>