<template>
  <v-container class="mt-8">

    <v-row>
      <!-- Información básica del grupo -->
      <v-col cols="12" class="pb-4">
        <v-card class="mx-auto transparent elevation-0">
          <v-card-text class="pb-0">
            <div class="mb-0 font-weight-bold text-h5 colorCurso text-center">
              Calendario
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card class="elevation-0 transparent">

          <!-- los diplomas y certificados -->
          <v-row justify="space-around">
            <!-- Cambridge -->
            <v-col cols="12" >
              <v-card class="elevation-0 cardShow">
                <v-card-text>
                    <v-img src="@/assets/calendario.jpeg"></v-img>
                </v-card-text>

              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <Alerta    v-if="respuestaAlerta" :parametros="parametros"/>

  </v-container>
</template>

<script>
  // importar librerías
  import { mapGetters, mapActions } from 'vuex'

  // Componentes
  import Alerta     from '@/components/alertas/Alerta.vue'

  export default {
    components:{
      Alerta,
    },

    data: () => ({
      urlReglamentoInbi: 'https://www.inbi.mx/public/images/reglamento_inbi.png',
      urlReglamentoFast:'https://fastenglish.com.mx/public/images/reglamento_fast.png',

      respuestaAlerta: false,
      loader: true,
      cargar: false,
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },
    }),

    computed:{
      ...mapGetters('login',['getdatosUsuario','getLogeado', 'getEscuela']),
    },

    async created(){
    },

    methods:{
      async descargarCopia( url ) {
        window.open( url );
      },
    },
  }
</script>
<style scoped>
  .cardShow{
    border-radius: 15px !important; 
    box-shadow: 0 20px 27px 0 rgba(0,0,0,0.2)  !important;
  }

  .colorCurso{
    color: #006093;
  }
</style>