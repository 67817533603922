<template>
	<v-container fluid>
	  <v-row>

	  	<v-col cols="12" class="pt-0">
	  		<div class="text-subtitle-1"><strong>Matricula:</strong> {{ alumno.matricula }}</div>
	  	</v-col>
	  </v-row>

	  <v-subheader class="pa-0"><strong>Datos personales</strong></v-subheader>
	  <v-divider class="mb-4"></v-divider>
	  <v-row>

    	<v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.nombre"
          label="Nombre"
          filled
          hide-details
          dense
          :readonly="!guardar"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.apellido_paterno"
          label="Apellido paterno"
          filled
          hide-details
          dense
          :readonly="!guardar"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.apellido_materno"
          label="Apellido materno"
          filled
          hide-details
          dense
          :readonly="!guardar"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.edad"
          label="Edad"
          filled
          hide-details
          dense
          :readonly="!guardar"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-subheader class="pa-0 mt-4"><strong>Datos de contacto</strong></v-subheader>
	  <v-divider class="mb-4"></v-divider>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.calle"
          label="Calle"
          filled
          hide-details
          dense
          :readonly="!guardar"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.numero"
          label="Número"
          filled
          hide-details
          dense
          :readonly="!guardar"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.colonia"
          label="Colonia"
          filled
          hide-details
          dense
          :readonly="!guardar"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.estado"
          label="Estado"
          filled
          hide-details
          dense
          :readonly="!guardar"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.telefono"
          label="Teléfono"
          filled
          hide-details
          dense
          :readonly="!guardar"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.celular"
          label="Celular"
          filled
          hide-details
          dense
          :readonly="!guardar"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.email"
          label="Correo"
          filled
          hide-details
          dense
          :readonly="!guardar"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.ocupacion"
          label="Ocupación"
          filled
          hide-details
          dense
          :readonly="!guardar"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="4" class="pt-0">
      	<v-switch 
      		label="¿Activo Si/No?"
          hide-details
      		v-model="alumno.activo_sn">
      	</v-switch>
      </v-col>

      <v-col cols="12" sm="6" class="pt-0">
      	<div class="text-subtitle-1"><b>Fecha alta:</b> {{ alumno.fecha_alta }}</div>
      	<div class="text-subtitle-1"><b>Fecha baja:</b> {{ alumno.fecha_baja }}</div>
      </v-col>

      <v-col cols="12" align="right">
        <v-btn color="primary" @click="save" v-if="guardar">Guardar</v-btn>
      </v-col>
    </v-row>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

	// Componentes
  export default {

  	components:{
      Alerta,
      carga,
    },

  	props:[
			'editedItem'
	  ],
    data: () => ({
      // Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
      guardar: false,

      id_alumno: 0,
      alumno: {}
      
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
    },

    watch:{
      editedItem( ){
        this.initialize()
      }
    },

    created( ){
    	this.id_alumno = this.editedItem.id_alumno
    	this.initialize()
      const idPuestoValido = [12, 11, 58, 50, 51, 39, 56, 43];
      this.guardar = idPuestoValido.includes(this.getdatosUsuario.idpuesto);
    },

    methods: {
    	initialize ( ){
    		this.$http.get('inscripciones.alumno/' + this.id_alumno ).then(response=>{
    			console.log( response.data )
        	this.alumno = response.data
          console.log( this.alumno )
        }).catch( error =>{
        	this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
    	},

    	save () {
        if(this.guardar){
          // Cargamos al usuario que hizo el cambio
          this.alumno['id_usuario']  = this.getdatosUsuario.iderp
          this.$http.put('alumnos.update/' + this.alumno.id_alumno, this.alumno).then(response=>{
	        	this.validarSuccess( "Datos del alumno actualizados" )
	        	this.$emit('click')
	        	this.initialize()
	        }).catch( error =>{
	        	this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
        } else {
          console.log("No tiene permiso para editar")
          this.validarError( "No tiene permiso para editar")
        }
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      validarErrorDirecto ( mensaje ) {
        this.cargar = false 
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
    	},
    },
  }
</script>


