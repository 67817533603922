<template>
  <v-container class="mt-5 ml-2">
  	<v-card>
  	  
	    <v-snackbar
	      v-model="snackbar"
	      top
	      :timeout="3000"
	      color="error"
	    >
	      Selecciona los ciclos porfavor 
	      <v-btn text color="white" @click.native="snackbar = false">Close</v-btn>
	    </v-snackbar>

	    <v-row>
	      <v-col cols="12">
	        <v-data-table
	          :headers="headers"
	          :items="resultadoFinal"
	          sort-by="calories"
	          class="elevation-0"
	          hide-default-footer
	          :items-per-page="20"
	          dense
	          mobile-breakpoint="200"
	        >
	          <template v-slot:top>
	            <v-toolbar
	              flat
	            >
	              <v-toolbar-title>Kpi General Teens</v-toolbar-title>
	              <v-divider
	                class="mx-4"
	                inset
	                vertical
	              ></v-divider>
	              <v-spacer></v-spacer>
	              <v-btn 
	                class="mr-2" 
	                small dark color="green"
	                @click="exportar()">
	                <v-icon>mdi-microsoft-excel</v-icon>
	              </v-btn>
	              <v-btn color="primary" @click="consultar()" small>Consultar</v-btn>
	            </v-toolbar>
	            <v-card-text class="pb-6">
	              <v-row>
	                <v-col cols="12" sm="6" lg="4" xl="3">
	                  <v-autocomplete
	                    outlined
	                    dense
	                    clearable
	                    v-model="cicloInf"
	                    :items="ciclos"
	                    label="Selecciona ciclo actual"
	                    persistent-hint
	                    hide-details
	                    return-object
	                    item-text="ciclo"
	                    item-value="id_ciclo"
	                  >
	                  </v-autocomplete>
	                </v-col>
	                <v-col cols="12" sm="6" lg="4" xl="3">
	                  <v-autocomplete
	                    outlined
	                    dense
	                    clearable
	                    v-model="cicloSup"
	                    :items="ciclos"
	                    label="Selecciona siguiente ciclo"
	                    persistent-hint
	                    hide-details
	                    return-object
	                    item-text="ciclo"
	                    item-value="id_ciclo"
	                  >
	                  </v-autocomplete>
	                </v-col>
	              </v-row>
	            </v-card-text>
	          </template>

	          <template v-slot:no-data>
	            <v-btn
	              color="primary"
	              @click="initialize"
	              small
	            >
	              Actualizar
	            </v-btn>
	          </template>
	        </v-data-table>
	      </v-col>

	      <v-col cols="12">
	      	<v-divider></v-divider>
	      	<v-card class="elevation-0">
	      	  <v-card-title primary-title class="pb-0 mt-2">
	      			<span>NI por sucursal</span>
	      	  </v-card-title>
	      	</v-card>
	      </v-col>


	      <v-col cols="12">
	      	<v-card-text class="pt-0">
	          <v-row align="center">
	          	<v-col cols="12" md="5" lg="3">
	          		<v-card color="blue lighten-1">
	          		  <v-card-title primary-title class="py-1">
	          		    <div class="white--text">Inscritos Inbi: {{totalInbi}}</div>
	          		  </v-card-title>
	          		</v-card>
	          	</v-col>
	          </v-row>
	        </v-card-text>
	      </v-col>

	      <v-col cols="12">
	      	<v-card class="elevation-0">
	      		<v-card-title primary-title class="py-1">
	      		  Inscritos Inbi
	      		</v-card-title>
	      	  <v-card-text>
			      	<v-data-table
			          :headers="headersNiSucursal"
			          :items="dataTablaInbiUno.tabla"
			          class="elevation-1"
			          hide-default-footer
			          :items-per-page="20"
			          dense
			          mobile-breakpoint="200"
			        >
			          <template v-slot:no-data>
			            <v-btn
			              color="primary"
			              @click="initialize"
			              small
			            >
			              Actualizar
			            </v-btn>
			          </template>
			        </v-data-table>
	      	  </v-card-text>
	      	</v-card>
	      </v-col>


	      <v-col cols="12">
	      	<v-divider></v-divider>
	      	<v-card class="elevation-0">
	      	  <v-card-title primary-title class="pb-0 mt-2">
	      			<span>NI por vendedora</span>
	      	  </v-card-title>
	      	</v-card>
	      </v-col>

	      <v-col cols="12">
	      	<v-card class="elevation-0">
	      		<v-card-title primary-title class="py-1">
	      		  Inscritos Inbi
	      		</v-card-title>
	      	  <v-card-text>
			      	<v-data-table
			          :headers="headersVend"
			          :items="dataTablaInbiUnoVend.tabla"
			          class="elevation-1"
			          dense
			          mobile-breakpoint="200"
			        >
			          <template v-slot:no-data>
			            <v-btn
			              color="primary"
			              @click="initialize"
			              small
			            >
			              Actualizar
			            </v-btn>
			          </template>
			        </v-data-table>
	      	  </v-card-text>
	      	</v-card>
	      </v-col>

	    </v-row>
	  </v-card>

	  <div class="text-center">
      <v-dialog
        v-model="dialogCarga"
        hide-overlay
        persistent
        width="500"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text class="pt-2">
            Cargando, porfavor espera...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'

  export default {
    data: () => ({
      snackbar:false,
      headers: [
        { text: 'Plantel'                  , value: 'plantel' },
        { text: 'Alumnos Ciclo Actual'     , value: 'alumnosActual' },
        { text: 'Acumulado'                , value: 'alumnosSiguiente' },
        { text: 'Avance'                   , value: 'avance' },
        { text: 'Faltantes'                , value: 'faltan' },
      ],

      desserts: [],
      ciclos:[],
      cicloInf:null,
      cicloSup:null,
      planteles:[],
      plantel:null,
      cicloAnterior:[],
      cicloSiguiente:[],
      resultado:[],
      total:0,
      faltantes:0,
      siguientes:0,
      porcentaje:0,
      idcicloERPinf:0,
      idcicloERPSup:0,
      cicloSiguienteAvance:[],
      resultadoFinal:[],

      headersNiSucursal: [
        { text: 'Sucursal'     , value: 'plantel' },
        { text: 'Acumulado'    , value: 'acumulado' },
        { text: 'Avance'       , value: 'avance' },
      ],
      fast:{},
      inbi:{},
			totalFast: 0,
			dataTablaFastUno:[],
			totalInbi: 0,
			dataTablaInbiUno:[],
			dialogCarga: false,

			headersVend: [
        { text: 'Vendedora'    , value: 'nombre_completo' },
        { text: 'Acumulado'    , value: 'total' },
        { text: 'Avance'       , value: 'avance' },
      ],

      dataTablaFastUnoVend:[],
			dataTablaInbiUnoVend:[],
			avance:0,
			acumulado:0,
			avanceFinal:0,
			acumuladoFinal:0
    }),

    watch: {
      cicloInf () {
        if(this.cicloInf){
          var num_search = parseInt(this.cicloInf.ciclo.substr(5,3)) + 1
          for(const i in this.ciclos){
            var cadena_search = this.ciclos[i].ciclo.substr(0,8)
            if (cadena_search.search(num_search) != -1) {
              this.cicloSup = this.ciclos[i]
            }
          }

          if(this.cicloSup){
            this.consultar()
          }
        }
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.planteles.all').then(response=>{
          for(const i in response.data){
            var payload = {
              plantel: response.data[i].plantel,
              alumnosActual: 0,
              alumnosSiguiente: 0,
              faltan: 0,
              porcentaje: 0,
            }
            this.planteles.push(payload)
          }
          this.$http.get('kpi.ciclos.all').then(response=>{
            for(const i in response.data){
              if (!(response.data[i].ciclo.search('FE') != -1)) {
                if(response.data[i].ciclo.search('CICLO') != -1){
                  this.ciclos.push(response.data[i])
                }
              }
            }
          }).catch(error=>{console.log(error)})
        }).catch(error=>{console.log(error)})
      },

      async consultar () {
        this.resultado = []
        this.total = 0
        this.faltantes = 0
        this.siguientes = 0
        this.porcentaje = 0
        this.totalFinal      = 0
        this.faltantesFinal  = 0
        this.siguientesFinal = 0
        this.porcentajeFinal = 0

        for(const i in this.planteles){
          this.planteles[i].plantel =  this.planteles[i].plantel
          this.planteles[i].alumnosActual =  0
          this.planteles[i].alumnosSiguiente = 0
          this.planteles[i].faltan = 0
          this.planteles[i].porcentaje = 0
          this.planteles[i].avance = 0
          this.planteles[i].acumulado = 0
        }

        if(this.cicloInf && this.cicloSup){

          this.resultado       = []
          this.resultadoFinal  = []
          this.total           = 0
          this.faltantes       = 0
          this.siguientes      = 0
          this.porcentaje      = 0
          this.acumulado       = 0
          this.avance          = 0

          await this.riFast()
          await this.avanceF()
          await this.niSucursal()
          await this.niVend()
        }else{
          this.snackbar = true
        }
      },

      riFast () {
      	return this.$http.get('kpi.cantactual.teens/' + this.cicloInf.id_ciclo + '/' + this.cicloInf.id_ciclo_relacionado).then(response=>{
          this.cicloAnterior = response.data
          this.$http.get('kpi.cantsiguiente.teens/' + this.cicloSup.id_ciclo + '/' + this.cicloSup.id_ciclo_relacionado).then(response=>{
            this.cicloSiguiente = response.data
          }).catch(error=>{console.log(error)})
        }).catch(error=>{console.log(error)})
      },

      avanceF () {
        return this.$http.get('kpi.cantsiguiente.avance/' + this.cicloSup.id_ciclo + '/' + this.cicloSup.id_ciclo_relacionado).then(response=>{
        	this.cicloSiguienteAvance = response.data
         	// validamos primero que el alumno del ciclo anterior este en el nuevo ciclo
          // Sacamos el total para inbi

          for(const i in this.cicloAnterior){
            // Aquí llenamos los del ciclo acutal
            for(const k in this.planteles){
              if(this.planteles[k].plantel == this.cicloAnterior[i].plantel){
                this.planteles[k].alumnosActual += 1 
              }
            }
          }


          for(const i in this.cicloAnterior){
            // inicializamos las banderas
            for(const j in this.cicloSiguiente){
              // Sacar si son los mismos alumnos, de ser así, esto significa que esta inscrito en el siguiente ciclo
              if(this.cicloAnterior[i].id_alumno == this.cicloSiguiente[j].id_alumno) {
                // exista o no, se le suma uno al plantel
                // vemos a que plantel pertenece
                // Aquí llenamos los del ciclo acutal
                for(const k in this.planteles){
                  if(this.planteles[k].plantel == this.cicloAnterior[i].plantel){
                    this.planteles[k].alumnosSiguiente += 1 
                  }
                }
              }
            }
          }

          // Calcular el avance de todas las sucursales
          for(const i in this.cicloAnterior){
            // inicializamos las banderas
            for(const j in this.cicloSiguienteAvance){
              // Sacar si son los mismos alumnos, de ser así, esto significa que esta inscrito en el siguiente ciclo
              if(this.cicloAnterior[i].id_alumno == this.cicloSiguienteAvance[j].id_alumno) {
                // Aquí llenamos los del ciclo acutal
                for(const k in this.planteles){
                  if(this.planteles[k].plantel == this.cicloAnterior[i].plantel){
                    this.planteles[k].avance += 1 
                  }
                }
              }
            }
          }

          // Empezamos a hacer los calculos para inbi
          this.total = 0
          this.faltantes = 0
          this.siguientes = 0
          this.porcentaje = 0
          this.avance = 0
          this.acumulado = 0
          var resultadoPre = this.planteles

          var inbi = []
          for(const i in resultadoPre){
            if (!(resultadoPre[i].plantel.search('FAST') != -1)) {
              if(resultadoPre[i].alumnosActual > 0){
                resultadoPre[i].faltan      = resultadoPre[i].alumnosActual - resultadoPre[i].alumnosSiguiente
                resultadoPre[i].porcentaje  = (((resultadoPre[i].faltan) / resultadoPre[i].alumnosActual) * 100).toFixed(2)
                this.total      += resultadoPre[i].alumnosActual
                this.faltantes  += resultadoPre[i].faltan
                this.siguientes += resultadoPre[i].alumnosSiguiente
                this.avance     += resultadoPre[i].avance 
                inbi.push(resultadoPre[i])
              }
            }
          }

          // Calculamos ahora el avance para inbi
          for(const i in this.planteles){
          	for(const j in inbi){
          		if(this.planteles[i].plantel == inbi[j].plantel){
          			inbi[j].avance = this.planteles[i].avance
          		}
          	}
          }


          // Final de inbi
          var finalData = {
            plantel         : 'Total',
            alumnosActual   : this.total,
            alumnosSiguiente: this.siguientes,
            faltan          : this.faltantes,
            avance          :this.avance,
            acumulado       :0
          }

          this.resultado = inbi
          this.resultado.push(finalData)
          this.resultadoFinal = this.resultado

        }).catch(error=>{console.log(error)})
      },

      niSucursal(){
        this.dialogCarga = true
        this.inbi = {}
        this.$http.get('kpi.ni.porciclo.general.TEENS/' + this.cicloSup.id_ciclo).then(response=>{
          this.dataTablaInbiUno = response.data
          var payload = {
            plantel: 'Total',
            avance:0,
            acumulado:0,
          }
          for(const i in this.dataTablaInbiUno.tabla){
            payload.avance += this.dataTablaInbiUno.tabla[i].avance
            payload.acumulado += this.dataTablaInbiUno.tabla[i].acumulado
          }
          this.totalInbi =  this.dataTablaInbiUno.totalINBI
          this.dataTablaInbiUno.tabla.push(payload)
          this.dialogCarga = false
        }).catch(error=>{console.log(error)})
      },

      niVend () {
        this.dialogCarga = true
        this.inbi = {}
        
        this.$http.get('kpi.ni.vendedora.ciclo.teens/' + this.cicloSup.id_ciclo).then(response=>{
          this.dataTablaInbiUnoVend = response.data
          var payload = {
            nombre_completo: 'Total',
            avance:0,
            total:0,
          }
          for(const i in this.dataTablaInbiUnoVend.tabla){
            payload.avance += this.dataTablaInbiUnoVend.tabla[i].avance
            payload.total += this.dataTablaInbiUnoVend.tabla[i].total
          }
          this.dataTablaInbiUnoVend.tabla.push(payload)
          this.dialogCarga = false
        }).catch(error=>{console.log(error)})
      },


      exportar(){
       
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let data     = XLSX.utils.json_to_sheet(this.resultado)
        let dataSuc  = XLSX.utils.json_to_sheet(this.dataTablaInbiUno.tabla)
        let dataVend = XLSX.utils.json_to_sheet(this.dataTablaInbiUno.tabla)

        const workbook = XLSX.utils.book_new()
        const filename = 'RI_TEENS'
        XLSX.utils.book_append_sheet(workbook, data, 'RI TEENS')
        XLSX.utils.book_append_sheet(workbook, dataSuc, 'NI sucursal')
        XLSX.utils.book_append_sheet(workbook, dataVend, 'NI vendedora')

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },

      
    },
  }
</script>