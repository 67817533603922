<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title class="text-subtitle-1">
            Reporte de Ventas General
            <v-spacer></v-spacer>
            <!-- <v-btn
              class="mr-2"
              small
              dark
              color="green"
              @click="exportExcel(modalidades, 'MODALIDADES_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn> -->

             <v-btn 
              class="mr-2" 
              small dark color="red"
              @click="crearPdf()"
              tile
            >
              <v-icon small>mdi-folder-zip</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text class="black--text">
            <v-row>
              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  clearable
                  v-model="fechaini"
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-radio-group
                  v-if="[12, 11, 40, 43, 25].includes(getdatosUsuario.idpuesto)"
                  v-model="escuela"
                  row
                  class="ml-4"
                >
                  <v-radio label="Fast" :value="2"></v-radio>
                  <v-radio label="Inbi" :value="1"></v-radio>
                </v-radio-group>
              </v-col>

              <v-spacer></v-spacer>
            </v-row>

            <v-row v-if="encabezados && getdatosUsuario.idpuesto != 25">
              <v-col cols="12" class="py-0">
                <div class="grey--text"><b>Rango de fechas</b></div>
                <div>
                  <span class="font-weight-bold text-h6">{{
                    encabezados.fecha_inicio_format
                  }}</span>
                  <span class="font-weight-bold mx-4">al</span>
                  <span class="font-weight-bold text-h6">{{
                    encabezados.fecha_final_format
                  }}</span>
                </div>
              </v-col>

              <v-col cols="12" md="8">
                <v-card class="elevation-0">
                  <v-card-text class="black--text">
                    <v-data-table
                      :headers="headers5"
                      :items="datosTablaHeaders5"
                      class="elevation-0"
                      dense
                      mobile-breakpoint="200"
                      hide-default-footer
                      :items-per-page="datosTablaHeaders5.length"
                    >
                      <template v-slot:item.columna4="{ item }">
                        <v-icon
                          color="green"
                          v-if="item.columna6 > item.columna5"
                        >
                          mdi-arrow-up-bold
                        </v-icon>
                        <v-icon
                          color="red"
                          v-if="item.columna6 < item.columna5"
                        >
                          mdi-arrow-down-bold
                        </v-icon>
                        <v-icon
                          color="orange"
                          v-if="item.columna6 == item.columna5"
                        >
                          mdi-equal
                        </v-icon>
                        <span
                          v-if="
                            item.columna6 > 0 && item.columna6 < item.columna5
                          "
                        >
                          {{
                            ((1 - item.columna6 / item.columna5) * 100).toFixed(
                              2
                            )
                          }}
                          %
                        </span>

                        <span
                          v-if="
                            item.columna6 > 0 && item.columna6 >= item.columna5
                          "
                        >
                          {{
                            ((item.columna6 / item.columna5 - 1) * 100).toFixed(
                              2
                            )
                          }}
                          %
                        </span>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="4" v-if="encabezados_ant">
                <v-card outlined>
                  <v-card-text class="black--text">
                    <div>
                      Liq. Mat. Anteriores:
                      <b
                        >{{ encabezados_ant.matriculas_liquidadas_ant }} =>
                        {{
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(
                            encabezados_ant.matriculas_liquidadas_ant_monto
                          )
                        }}
                      </b>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6">
                <v-card class="elevation-0">
                  <v-card-title primary-title class="py-1">
                    Ventas por vendedora
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :headers="headers2"
                      :items="vendedorasActuales"
                      class="elevation-0"
                      dense
                      mobile-breakpoint="200"
                      hide-default-footer
                      :items-per-page="vendedorasActuales.length"
                    >
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
            <v-row>
              <v-col cols="12" v-if="verGrafica">
                <v-card >
                  <v-card-title primary-title>
                    Gráfica por escuela y contactos
                  </v-card-title>

                  <!-- GRÁFICA CONTACTOS -->
                  <v-card-text>
                    <vue-apex-charts
                      ref="grafica"
                      v-if="verGrafica"
                      type="area"
                      height="350"
                      :options="chartOptions"
                      :series="series"
                    ></vue-apex-charts>
                  </v-card-text>

                  <v-divider></v-divider>

                  <!-- TABLA DE CONTACTOS -->
                  <v-card-text>
                    <v-data-table
                      :headers="headersContacto"
                      :items="filterdataTabla"
                      class=""
                      hide-default-footer
                      fixed-header
                      :items-per-page="filterdataTabla.length"
                      dense
                      mobile-breakpoint="200"
                    >
                      <template v-slot:top>
                        <v-card-title primary-title>
                          Contactos por vendedora
                        </v-card-title>
                      </template>
                      <template v-slot:no-data>
                        <v-btn color="primary" @click="getContactos()" small>
                          Actualizar
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-divider></v-divider>
           
              <v-col cols="12" v-if="verGrafica2">
                  <v-card class="elevation-0">
                    <v-card-title primary-title>
                      Gráfica ventas General
                    </v-card-title>
                    <v-card-text class="black--text">
                      <v-data-table
                        :headers="headerVentaGeneral"
                        :items="datosVentaGeneral"
                        class="elevation-0"
                        dense
                        mobile-breakpoint="200"
                        hide-default-footer
                        :items-per-page="datosVentaGeneral.length"
                      >
                        
                      </v-data-table>
                    </v-card-text>
                  </v-card>

                <v-divider></v-divider>
              
                  <v-card class="shadowCard">
                    <!-- GRÁFICa venta general -->
                    <v-card-text>
                      <vue-apex-charts
                        ref="grafica2"
                        v-if="verGrafica2"
                        type="area"
                        height="350"
                        :options="chartOptionsVentas"
                        :series="series2"
                      ></vue-apex-charts>
                    </v-card-text>
                  </v-card>
                </v-col>
            </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";
import graficaContactosMarketing from "@/mixins/graficaContactosMarketing";
import VueApexCharts from 'vue-apexcharts'
import html2canvas from 'html2canvas';

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export default {
  components: {
    VueApexCharts,
    Alerta,
    carga,
  },

  mixins: [ validarErrores, funcionesExcel, graficaContactosMarketing ],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    headers2: [
      { text: "Vendedora", value: "nombre_completo" },
      { text: "Mat. Ant. Liq.", value: "matriculas_liquidadasSemAnt" },
      { text: "Mat. Nvas. Liq.", value: "matriculas_liquidadas" },
    ],

    headers5: [
      { text: "Campo", value: "columna1" },
      { text: "Semana Ant", value: "columna2" },
      { text: "Semana Act", value: "columna3" },
      { text: "Avance", value: "columna4" },
    ],

     headerVentaGeneral: [
      { text: "Semana Ant", value: "columna7" },
      { text: "Semana Ant", value: "columna6" },
      { text: "Semana Ant", value: "columna5" },
      { text: "Semana Ant", value: "columna4" },
      { text: "Semana Ant", value: "columna2" },
      { text: "Semana Act", value: "columna3" }
    ],

    // fechaini: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    //   .toISOString()
    //   .substr(0, 10),
    fechaini: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
    .toISOString()
    .substr(0, 10),
    nuevasMatriculas: [],
    plantelesActivos: [],
    vendedorasActuales: [],
    encabezados: null,
    encabezados_ant: null,
    escuela: 1,
    infoVentaGeneral: null,
    datosVentaGeneral: [],

    datosTablaHeaders5: [],

    fechasVentasGenerales: [],
    datosVentasGenerales: [],

    imageData: null,
    imageData2: null,
    

    ///Graficas

    fecha_inicio: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    fecha_final: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    fechasUnicas: [],
    data: [],

    headerstablacontacto: [],
    headersContacto: [
      { text: "Vendedora", value: "vendedora" },
      { text: "Semana Anterior", value: "conteo_semana_anterior" },
      { text: "Semana Actual", value: "conteo_semana_actual" },
      { text: "Diferencia", value: "diferencia" },
    ],
    verGrafica: false,
    verGrafica2: false,

    alumnos: [],
    dialogAlumnos: false,

    dataTabla: [],

    series: [
      {
        name: "FAST",
        data: [],
      },
      {
        name: "INBI",
        data: [],
      },
    ],

     series2: [
      {
        name: "Venta General",
        data: []
      },
    ],

    chartOptions: {
      chart: {
        height: 500,
        type: "area",
      },

      dataLabels: {
        enabled: true,
      },

      stroke: {
        curve: "smooth",
      },

      xaxis: {
        // type: 'caracter',
        // categories: [],
        categories: [],

        axisBorder: {
          show: false,
        },

        axisTicks: {
          show: false,
        },
        tickPlacement: "on",
        labels: {
          show: true,
          style: {
            fontSize: "12px",
          },
        },
      },
    },

     chartOptionsVentas: {
      chart: {
        height: 500,
        type: "area",
      },

      dataLabels: {
        enabled: true,
      },

      stroke: {
        curve: "smooth",
      },

      xaxis: {
        // type: 'caracter',
        // categories: [],
        categories: [],

        axisBorder: {
          show: false,
        },

        axisTicks: {
          show: false,
        },
        tickPlacement: "on",
        labels: {
          show: true,
          style: {
            fontSize: "12px",
          },
        },
      },
    },
  }),

  computed: {
    ...mapGetters("login", [
      "getdatosUsuario",
      "getLogeado",
      "getEscuela",
      "drawer2",
      "getEstatusLlamada",
      "getNuevosProspectos",
    ]),

    filterdataTabla() {
      if (this.escuela == 1) {
       return this.dataTabla.filter((el) => {
          return (
            el.escuela == 1  || el.escuela == 'Sin escuela' || el.vendedora == 'Total INBI' 
          );
        });
      }
      if (this.escuela == 2) {
       return this.dataTabla.filter((el) => {
          return (
            el.escuela == 2 || el.vendedora == 'Total FAST' 
          );
        });
      }
    },
  
  },

  watch: {
    fecha_inicio(value) {
      if (value) {
        this.getContactos();
        this.initialize();
      }
    },
    fechaini(value) {
      if (value) {
        this.getContactos();
        this.initialize();
      }
    },
    // fecha_final(value) {
    //   if (value) {
    //     this.getContactos();
    //   }
    // },
  },

  created() {
    this.initialize();
    this.getContactos();
  },

  methods: {
    initialize() {
      this.cargar = true;
      this.ciclos = [];
      this.verGrafica2 = false;

      this.series2 = null;

      this.series2 = [
        {
          name: "Ventas",
          data: []
        }
      ];

      this.chartOptionsVentas.xaxis.categories = [];

      if (!this.fechaini) {
        return this.validarErrorDirecto("Favor de seleccionar un ciclo");
      }
      var payload = { fechaini: this.fechaini, escuela: this.escuela };

      
      this.$http
        .post("reporte_modelo_ventas.semanal.ventas", payload)
        .then((response) => {
          this.encabezados = response.data.encabezados;
          this.encabezados_ant = response.data.encabezados;
          this.plantelesActivos = response.data.plantelesActivos;
          this.vendedorasActuales = response.data.vendedorasActuales;
          this.infoVentaGeneral = response.data.infoVentaGeneral;

          this.datosVentaGeneral = [
             {
              columna7: `${this.infoVentaGeneral.fecha_inicio_formatAnterior4} al ${this.infoVentaGeneral.fecha_final_formatAnterior4}`,
              columna6: `${this.infoVentaGeneral.fecha_inicio_formatAnterior3} al ${this.infoVentaGeneral.fecha_final_formatAnterior3}`,
              columna5: `${this.infoVentaGeneral.fecha_inicio_formatAnterior2} al ${this.infoVentaGeneral.fecha_final_formatAnterior2}`,
              columna4: `${this.infoVentaGeneral.fecha_inicio_formatAnterior1} al ${this.infoVentaGeneral.fecha_final_formatAnterior1}`,
              columna2: `${this.infoVentaGeneral.fecha_inicio_formatAnterior} al ${this.infoVentaGeneral.fecha_final_formatAnterior}`,
              columna3: `${this.infoVentaGeneral.fecha_inicio_format} al ${this.infoVentaGeneral.fecha_final_format}`,
            },

            {
              columna7: ` ${
                this.infoVentaGeneral.matriculas_liquidadasSemAnt4
              }`,
              columna6: ` ${
                this.infoVentaGeneral.matriculas_liquidadasSemAnt3
              }`,
              columna5: ` ${
                this.infoVentaGeneral.matriculas_liquidadasSemAnt2
              }`,
              columna4: ` ${
                this.infoVentaGeneral.matriculas_liquidadasSemAnt1
              }`,
              columna2: ` ${
                this.infoVentaGeneral.matriculas_liquidadasSemAnt
              }`,
              columna3: ` ${
                this.infoVentaGeneral.matriculas_liquidadas
              }`,
            }
          ],

          this.fechasVentasGenerales = [
            {fecha_creacion: `${this.infoVentaGeneral.fecha_inicio_formatAnterior4} al ${this.infoVentaGeneral.fecha_final_formatAnterior4}`},
            {fecha_creacion: `${this.infoVentaGeneral.fecha_inicio_formatAnterior3} al ${this.infoVentaGeneral.fecha_final_formatAnterior3}`},
            {fecha_creacion: `${this.infoVentaGeneral.fecha_inicio_formatAnterior2} al ${this.infoVentaGeneral.fecha_final_formatAnterior2}`},
            {fecha_creacion: `${this.infoVentaGeneral.fecha_inicio_formatAnterior1} al ${this.infoVentaGeneral.fecha_final_formatAnterior1}` },
            {fecha_creacion: `${this.infoVentaGeneral.fecha_inicio_formatAnterior} al ${this.infoVentaGeneral.fecha_final_formatAnterior}` },
            {fecha_creacion: `${this.infoVentaGeneral.fecha_inicio_format} al ${this.infoVentaGeneral.fecha_final_format}`}
          ],

          this.datosVentasGenerales = [
            {conteo:this.infoVentaGeneral.matriculas_liquidadasSemAnt4, fecha_creacion: `${this.infoVentaGeneral.fecha_inicio_formatAnterior4} al ${this.infoVentaGeneral.fecha_final_formatAnterior4}`},
            {conteo:this.infoVentaGeneral.matriculas_liquidadasSemAnt3,fecha_creacion: `${this.infoVentaGeneral.fecha_inicio_formatAnterior3} al ${this.infoVentaGeneral.fecha_final_formatAnterior3}`},
            {conteo:this.infoVentaGeneral.matriculas_liquidadasSemAnt2,fecha_creacion: `${this.infoVentaGeneral.fecha_inicio_formatAnterior2} al ${this.infoVentaGeneral.fecha_final_formatAnterior2}`},
            {conteo:this.infoVentaGeneral.matriculas_liquidadasSemAnt1,fecha_creacion: `${this.infoVentaGeneral.fecha_inicio_formatAnterior1} al ${this.infoVentaGeneral.fecha_final_formatAnterior1}` },
            {conteo:this.infoVentaGeneral.matriculas_liquidadasSemAnt,fecha_creacion: `${this.infoVentaGeneral.fecha_inicio_formatAnterior} al ${this.infoVentaGeneral.fecha_final_formatAnterior}` },
            {conteo:this.infoVentaGeneral.matriculas_liquidadas,fecha_creacion: `${this.infoVentaGeneral.fecha_inicio_format} al ${this.infoVentaGeneral.fecha_final_format}`}
          ]


           for (const i in this.fechasVentasGenerales) {
            const { fecha_creacion } = this.fechasVentasGenerales[i];
            this.chartOptionsVentas.xaxis.categories.push(fecha_creacion);


            const existeFechaVentas = this.datosVentasGenerales.find(
              (el) => el.fecha_creacion == fecha_creacion
            );

            if (existeFechaVentas) {
              this.series2[0].data.push(existeFechaVentas.conteo);

            } else {
              this.series2[0].data.push(0);
            }

          }

          this.verGrafica2 = true;

          this.datosTablaHeaders5 = [
            {
              columna1: "Semena",
              columna2: `${this.encabezados.fecha_inicio_formatAnterior} al ${this.encabezados.fecha_final_formatAnterior}`,
              columna3: `${this.encabezados.fecha_inicio_format} al ${this.encabezados.fecha_final_format}`,
              columna5: 0,
            },
            {
              columna1: "Mat. Nuevas",
              columna2: ` ${
                this.encabezados.matriculas_nuevasSemAnt
              } => ${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(this.encabezados.matriculas_nuevas_montoSemAnt)}`,
              columna3: ` ${
                this.encabezados.matriculas_nuevas
              } => ${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(this.encabezados.matriculas_nuevas_monto)}`,
              columna5: this.encabezados.matriculas_nuevasSemAnt,
              columna6: this.encabezados.matriculas_nuevas,
            },
            {
              columna1: "Mat. Nvas. Liq.",
              columna2: ` ${
                this.encabezados.matriculas_liquidadasSemAnt
              } => ${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(this.encabezados.matriculas_liquidadas_montoSemAnt)}`,
              columna3: ` ${
                this.encabezados.matriculas_liquidadas
              } => ${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(this.encabezados.matriculas_liquidadas_monto)}`,
              columna5: this.encabezados.matriculas_liquidadasSemAnt,
              columna6: this.encabezados.matriculas_liquidadas,
            },
            {
              columna1: "Mat. Nvas. Par.",
              columna2: ` ${
                this.encabezados.matriculas_parcialesSemAnt
              } => ${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(this.encabezados.matriculas_parciales_montoSemAnt)}`,
              columna3: ` ${
                this.encabezados.matriculas_parciales
              } => ${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(this.encabezados.matriculas_parciales_monto)}`,
              columna5: this.encabezados.matriculas_parcialesSemAnt,
              columna6: this.encabezados.matriculas_parciales,
            },

            {
              columna1: "Contactos",
              columna2: ` ${this.encabezados.contasctosAnt}`,
              columna3: ` ${this.encabezados.contasctosAct}`,
              columna5: this.encabezados.contasctosAnt,
              columna6: this.encabezados.contasctosAct,
            },
          ];
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getContactos() {
      this.series = null;

      this.series = [
        {
          name: "FAST",
          data: [],
        },
        {
          name: "INBI",
          data: [],
        },
      ];

      this.chartOptions.xaxis.categories = [];

      this.cargar = true;
      this.verGrafica = false;

      const payload = {
        // fecha_inicio: this.fecha_inicio,
        fecha_inicio: this. fechaini,
        fecha_final: this.fecha_final,
      };
      // Mandamos a llamar a la api
      this.$http
        .post("reporte_modelo_ventas.marketing.contactos", payload)
        .then((response) => {
          this.fechasUnicas = response.data.getFechasUnicas;
          this.data = response.data.graficaContactos;
          this.dataTabla = response.data.dataTabla;
          this.headerstablacontacto = response.data.headerstablacontacto;

          const fechasFAST = this.data.filter((el) => {
            return el.escuela == "FAST";
          });
          const fechasINBI = this.data.filter((el) => {
            return el.escuela == "INBI";
          });


          for (const i in this.fechasUnicas) {
            const { fecha_creacion } = this.fechasUnicas[i];
            this.chartOptions.xaxis.categories.push(fecha_creacion);

            const existeFechaContactosFast = fechasFAST.find(
              (el) => el.fecha_creacion == fecha_creacion
            );

            if (existeFechaContactosFast) {
              this.series[0].data.push(existeFechaContactosFast.conteo);
            } else {
              this.series[0].data.push(0);
            }

            const existeFechaContactosInbi = fechasINBI.find(
              (el) => el.fecha_creacion == fecha_creacion
            );

            if (existeFechaContactosInbi) {
              this.series[1].data.push(existeFechaContactosInbi.conteo);

            } else {
              this.series[1].data.push(0);
            }
          }

          //Trea la info para la Tabla de inscritos
      

          this.verGrafica = true;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },


    downloadZip(){
        this.cargar    = true

        const payload = {
        infoTabla1: this.datosTablaHeaders5,
        infoTabla2: this.vendedorasActuales,
        infoTexto: "Liq. Mat. Anteriores:"+" "+this.encabezados_ant.matriculas_liquidadas_ant + " " + "=>" + " " +"$"+this.encabezados_ant.matriculas_liquidadas_ant_monto,
        infoTabla3: this.filterdataTabla,
        infoTabla4: this.dataTablaInscritos,
        imageData: this.imageData,
        imageData2: this.imageData2,
        ventaGeneral: this.datosVentaGeneral,
        infoTablaEspecial: this.dataTabla,
        escuela: this.escuela
        };

        this.$http.post('reporte_modelo_ventas.reporte', payload).then(response=>{
        window.open( axios.defaults.baseURL + 'recibo-pago/NombrePdf.pdf')
        this.cargar    = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false });
      },

      //Funcion para tomar la foto a la grafica
      async exportarGrafica() {
      // await this.$nextTick();
      const graficaElement = this.$refs.grafica.$el;
      const graficaElement2 = this.$refs.grafica2.$el;

      if (graficaElement) {
        try {
          const canvas = await html2canvas(graficaElement);
          this.imageData = canvas.toDataURL('image/png');

          const canvas2 = await html2canvas(graficaElement2);
          this.imageData2 = canvas2.toDataURL('image/png');

        } catch (error) {
          console.error('Error al exportar la gráfica:', error);
        }
      }
      },

      async crearPdf() {
        this.cargar    = true
          await this.exportarGrafica();
          this.downloadZip();
        this.cargar    = false
      },

    exportar() {
      this.exportarData();
    },
  },
};
</script>