<template>
	<v-container class="mt-4 px-5" fluid>
	  <v-row justify="center"> 
	  	<v-col cols="12" sm="8">
			<!--ICONO DE AVATAR-->
	  		<v-card class="mx-auto px-4 mb-n16  transparent elevation-0 " align="center" style="z-index: 1;">
          		<v-avatar size="180" color="transparent">
				    <avataaars ></avataaars>
          		</v-avatar>
          
				<!-- <v-btn absolute bottom color="primary" small @click="avatarParamentro.editar = true" fab class="text-capitalize mb-4">
					<v-icon small>mdi-pencil</v-icon>
				</v-btn> -->
			</v-card>

			<!--INFO USUARIO-->
			<v-card  class="shadowCard  separacionAlta text-center pb-4">
				<div>
					<div class="mb-0 font-weight-bold colorCurso text-body-1" >
						<strong>{{ getdatosUsuario.nombre_completo }}</strong>
					</div>

					<p class="mb-0 font-weight-bold">
						<strong>{{ getdatosUsuario.email }}</strong>
					</p>

					<v-btn color="orange" @click="irCapacitacion()" dark tile small>Ir a capacitación</v-btn>
				</div>
				<div v-if="faltaInfoDispo || faltaNiveles || faltaSucursal">
					<br>
					<v-alert border="top" colored-border color="primary"  elevation="2" type="info">
						¡Falta informacion de llenar! Haz clic en la pestaña: {{ Infotabs }}  para revisar la informacion.

						<br/>

						Una vez llena tu información, favor de actualizar tu plataforma con F5, saludos!
					</v-alert>
				</div>
				<v-divider class="ma-0 mt-4 mb-6"></v-divider>
				<v-tabs	v-model="tab" background-color="transparent" centered> 
					<v-tab v-for="tab in tabs" :key="tab">
						{{ tab }}
					</v-tab>
				</v-tabs>
				<br>
				<v-tabs-items v-model="tab">
					<v-tab-item href="#tab-1">
						<div class="" v-if="datosPersonales">
							<!-- <label class="grey--text">Nombre completo</label>
							<br/>
							<b class="text-subtitle-1">{{ getdatosUsuario.nombre_completo }}</b>

							<br/> <br/> -->

							<label class="grey--text">Tarjeta</label>
							<br/>
							<b class="text-subtitle-1">{{ datosPersonales.numero_tarjeta }}</b>

							<br/> <br/>

							<label class="grey--text">Banco</label>
							<br/>
							<b class="text-subtitle-1">{{ datosPersonales.banco }}</b>

							<br/> <br/>

							<label class="grey--text">Teléfono</label>
							<br/>
							<b class="text-subtitle-1">{{ datosPersonales.telefono1 }}</b>

							<br/> <br/>
							
							<label class="grey--text">Ciclo Actual</label>
							<br/>
							<b class="text-subtitle-1">{{ cicloActual.ciclo }}</b>

							<br/> <br/>

							<label class="grey--text">Categoría</label>
							<br/>
							<b class="text-subtitle-1">{{ datosPersonales.categoria }}</b>

							<br/> <br/>

							<label class="grey--text">Estatus</label>
							<br/>
							<v-chip color="green"  small dark v-if="aceptado == 1">Aceptado</v-chip>
							<v-chip color="black"  small dark v-if="aceptado == 2">Rechazado</v-chip>
							<v-chip color="orange"  small dark v-if="aceptado == 0">Pendiente</v-chip>

							<br/> <br/>

						</div>
						<v-card-text v-if="aceptado == 0 || aceptado == 2">
							<v-btn color="red" dark tile small class="mr-2" @click="updateDatos(2)">
								Rechazar
							</v-btn>	  	

							<v-btn color="success" tile small @click="updateDatos(1)" >
								Aceptar
							</v-btn>
						</v-card-text>
					</v-tab-item>
					<v-tab-item href="#tab-2">
						<!--PLANTELES USUARIO-->
						<v-card>
							<v-alert border="top" colored-border color="primary"  elevation="2" type="info">
								Selecciona las sucursales donde puedes impartir clases. Recuerda seleccionarlas en el orden de prioridad que prefieras, siendo el 1 la sucursal con mayor prioridad para ti.
							</v-alert>
							<v-card-title primary-title class="pb-0">
								Sucursales
							</v-card-title>
							<v-card-text>
								<v-row>
									<v-col>
										Todas las sucursales:
										<v-data-table v-model="selectSucursal" :headers="headersSuc" :items="sucursales" item-key="idplantel" show-select color="primary"
											class="elevation-1" dense mobile-breakpoint="200" fixed-header height="290" :items-per-page="sucursales.length" hide-default-footer>
										</v-data-table>
									</v-col>
									<v-col>
										Prioridad:
										<v-data-table :headers="headersSucPr" :items="selectSucursal" item-key="idplantel" color="primary" class="elevation-1" 
											dense mobile-breakpoint="200" fixed-header height="290" :items-per-page="sucursales.length" hide-default-footer>
										</v-data-table>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<v-tab-item href="#tab-3">
						<!--NIVELES USUARIO-->
						<v-card class="my-3">
							<v-alert border="top" colored-border color="primary"  elevation="2" type="info">
								Selecciona los niveles que puedes impartir. Asegurate de seleccionar los niveles correspondientes a cada escuela, ya sea INBI o FAST.
							</v-alert>
							<v-card-title primary-title class="pb-0">
								Niveles
							</v-card-title>
							<v-card-text>
								<v-data-table v-model="selectNivel" :headers="headersNivel" :items="niveles" item-key="idniveles" show-select color="primary" 
									class="elevation-1" dense mobile-breakpoint="200" fixed-header height="290" :items-per-page="niveles.length" hide-default-footer>
									<template v-slot:item.curso="{ item }">
										<v-chip small dark color="blue lighten-2" v-if="item.esTeens==1">Teens</v-chip>
										<v-chip small dark color="blue lighten-2" v-else-if="item.esKids==1">Kids</v-chip>
										<v-chip small dark color="blue lighten-2" v-else>Adultos</v-chip>
									</template>
									<template v-slot:item.unidad_negocio="{ item }">
										<v-chip small dark color="blue lighten-2" v-if="item.unidad_negocio==1">INBI</v-chip>
										<v-chip small dark color="blue darken-2" v-else>FAST</v-chip>
									</template>
									<template v-slot:item.nivel="{ item }">
										<span>{{ item.nombrenivel }}</span>
									</template>
								</v-data-table>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<v-tab-item href="#tab-4">
						<!--DISPONIBILIDAD USUARIO-->
						<v-card class="my-3">
							<v-alert border="top" colored-border color="primary"  elevation="2" type="info">
								Utiliza el botón de 'Agregar Horario' para añadir las horas en las que estás disponible para dar clases. En el panel de abajo, podrás consultar y eliminar la información que vayas agregando.
							</v-alert>
							<v-card-title primary-title class="pb-0">
								Disponibilidad
								<v-spacer></v-spacer>
								<v-btn color="orange" dark tile small  @click="dialogHoraAdd()" >
									<v-icon small left>mdi-calendar-clock</v-icon>
									Agregar horario
								</v-btn>
							</v-card-title>

							<v-card-text class="mt-6">
								<v-row>
									<v-col cols="12" sm="6" lg="4" xl="3" v-for="(dia, i) in dias" :key="i">
										<v-card class="shadowCard">
											<v-card-title primary-title>
												{{ dia.dia }}
												<v-spacer></v-spacer>
											</v-card-title>
											<v-card-text>
												<v-container class="ma-0 pa-0">
													<v-data-table dense
														:headers="headers" :items="dispUsuarioFiltrada[dia.valor]"
														item-key="name" class="elevation-0"
														hide-default-footer :items-per-page="100"
														:mobile-breakpoint="200"
													>
														<template v-slot:item.actions="{ item }">
															<!-- <v-icon small class="mr-2" @click="editarHoraF(item)"> mdi-pencil </v-icon> -->
															<v-icon small @click="deleteItem(item)" color="primary"> mdi-delete </v-icon>
														</template>
													</v-data-table>	 
												</v-container>	  
											</v-card-text>
										</v-card>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-card>
	  	</v-col>
	  </v-row>

	 <!-- Agregar la hora -->
	<v-dialog v-model="dialogHora" max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          Nuevo horario
        </v-card-title>
        <v-card-text>
        	<v-row>
        		<v-col cols="12">
        			<v-text-field name="name" label="Inicio" id="id" type="time" step="1500" v-model="horaini"></v-text-field>
        		</v-col>
        		<v-col cols="12">
        			<v-text-field name="name" label="Fin" id="id" type="time" v-model="horafin" step="1500"></v-text-field>
        		</v-col>
				<v-col cols="12" class="pb-0">
					Selecciona los dias:
				</v-col>
        		<v-col cols="6" class="py-0">
        			<v-checkbox v-model="diasSeleccionados" label="Lunes" :value="1" hide-details></v-checkbox>
				</v-col>
				<v-col cols="6" class="py-0">
					<v-checkbox v-model="diasSeleccionados" label="Martes" :value="2" hide-details></v-checkbox>
				</v-col>
				<v-col cols="6" class="py-0">
					<v-checkbox v-model="diasSeleccionados" label="Miercoles" :value="3" hide-details></v-checkbox>
				</v-col>
				<v-col cols="6" class="py-0">
					<v-checkbox v-model="diasSeleccionados" label="Jueves" :value="4" hide-details></v-checkbox>
				</v-col>
				<v-col cols="6" class="py-0">
					<v-checkbox v-model="diasSeleccionados" label="Viernes" :value="5" hide-details></v-checkbox>
				</v-col>
				<v-col cols="6" class="py-0">
					<v-checkbox v-model="diasSeleccionados" label="Sabado" :value="6" hide-details></v-checkbox>
				</v-col>
				<v-col cols="6" class="py-0">
					<v-checkbox v-model="diasSeleccionados" label="Domingo" :value="7" hide-details></v-checkbox>
        		</v-col>
        	</v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="addHora()">
            Aceptar
          </v-btn>
          <v-btn color="green darken-1" text @click="dialogHora = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 

	<!-- Dialogo para editar la hora -->
    <!-- <v-dialog v-model="dialogHoraEditar" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5" v-if="horaEditar">
          {{dia}}
        </v-card-title>
        <v-card-text>
        	<v-row>
        		<v-col cols="12" md="6">
        			<v-text-field
        			  name="name"
        			  label="Inicio"
        			  id="id"
        			  type="time"
        			  step="1500"
        			  v-model="horaEditar.inicio"
        			></v-text-field>
        		</v-col>
        		<v-col cols="12" md="6">
        			<v-text-field
        			  name="name"
        			  label="Inicio"
        			  id="id"
        			  type="time"
        			  v-model="horaEditar.fin"
        			  step="1500"
        			></v-text-field>
        		</v-col>
        	</v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="saveHora()"
          >
            Aceptar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialogHoraEditar = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- Eliminar la hora -->
    <v-dialog v-model="dialogDeleteHora" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Seguro que deseas eliminar esta hora?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDeleteHora = false">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="deleteHora()">SI</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
		
	<!-- Dialog de carga -->
	<Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
	<carga                v-if="cargar"/>

  	</v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

	import Avataaars from 'vuejs-avataaars'

  export default {
  	components:{
  		Avataaars,
  		Alerta,
			carga
  	},

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
		// revisar para que sirve cada dato
		avatar:'',
		avatarParamentro: {
		editar: false
		},

		aceptado: 0,

		ciclos:[],
        cicloVer:{},
		cicloAdd:{},
		cicloActual:{},
		
		categoria_ciclo: [],
		fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

		datosPersonales: {},
		usuarios: [],
		usuario: {},
		usuarioRH: {},

		tabs: ['Informacion Personal'],
		tab:{},

		//tabla de sucursales
		sucursales:[],
      	sucursalesUsuario:[],
		selectSucursal: [],
		headersSuc: [
        	{ text: 'Sucursal',  value: 'plantel' },
		],
		headersSucPr: [
        	{ text: 'Orden',  	value: 'prioridad' },
			{ text: 'Sucursal',  value: 'plantel' },
		],
		//tabla de niveles
		niveles:[],
		nivelesUsuario:[],
		selectNivel:[],
    	headersNivel: [
			{ text: 'Nivel',      value: 'nivel' },
			{ text: 'Escuela',    value: 'unidad_negocio' },
			{ text: 'Curso',      value: 'curso' },
		],

		//tabla disponibilidad
		dias:[{dia:'Lunes', valor: 0},{dia:'Martes', valor: 1},{dia:'Miércoles', valor: 2},{dia:'Jueves', valor: 3},{dia:'Viernes', valor: 4},{dia:'Sábado', valor: 5},{dia:'Domingo', valor: 6}],
		dispUsuario: [],
		dispUsuarioFiltrada: {},
		dialogHora: false,
		dialogHoraEditar: false,
		dialogDeleteHora: false,
		diasSeleccionados : [],
		horaini:'',
		horafin:'',
		headers: [
			{ text: 'Inicio', value: 'inicio' },
			{ text: 'Fin'   , value: 'fin' },
			{ text: 'Acciones'      , value: 'actions' },
		],
		faltaNiveles:false,
		faltaSucursal:false,
		faltaInfoDispo:false,
		Infotabs: '',
    }),

    computed:{
    	...mapGetters( 'login', [ 'getdatosUsuario', 'getDatosUsuarioLMS', 'getAvatar']),

    },

	watch: {
		cicloVer () {
			if(this.cicloVer){
				this.getDisponibilidadUsuario()
			}
		},

		selectSucursal () {
			this.guardarSuc()
    	},

		selectNivel () {
			this.guardarNiv()
    	},
	},

    created() {
    	this.initialize()
		this.getCicloActual()
		this.getCiclos()
    },

    methods: {
    	
    	initialize( ){
			//las siguientes pestañas estan habilitadas para teachers, desarrolladores y jefe de TI
			if(this.getdatosUsuario.idpuesto==10 || this.getdatosUsuario.idpuesto==12 || this.getdatosUsuario.idpuesto==11){
				this.tabs.push('Sucursales')
				this.tabs.push('Niveles')
				this.tabs.push('Disponibilidad')
			}
			// Vaciamos todo
			this.cargar = true
			this.datosPersonales = {}
			// Consultamos
			this.$http.get('usuarios.trabajador/' + this.getdatosUsuario.iderp ).then(response=>{
				this.datosPersonales = response.data   	
				this.getCicloActual()
				this.getSucursalesUsuario()
				this.getNivelesUsuario()
				this.getDisponibilidadUsuario()
			}).catch( error =>{
				this.validarError( error.response.data.message )
			}).finally( () => { 
				
			 })

			this.$http.get('planteles.all').then(response=>{
    			for(const j in response.data){
					response.data[j].fecha_creacion  = response.data[j].fecha_creacion.substr(0,10)
					response.data[j].fecha_actualizo = response.data[j].fecha_actualizo.substr(0,10)
				}
    			this.sucursales = response.data.filter(el=>el.idplantel != 0)
    		}).catch( error =>{
				this.validarError( error.response.data.message )
			}).finally( () => { this.cargar = false })

			this.$http.get('niveles.all').then(response=>{
    			for(const j in response.data){
					response.data[j].fecha_registro  = response.data[j].fecha_registro.substr(0,10)
					response.data[j].fecha_actualizo = response.data[j].fecha_actualizo.substr(0,10)
				}
    			this.niveles = response.data
    		}).catch( error =>{
				this.validarError( error.response.data.message )
			}).finally( () => { this.cargar = false })
			
    	},

		getCiclos(){
			this.$http.get('roles.ciclos.all').then(response=>{
				// hacer un ciclo ya que los ciclos vienen varios como inscripciones y todo eso
				// y solo queremos los ciclos que en su nombre dicen ciclo 
				for(const i in response.data){
					//  si es diferente a FE
					if (!(response.data[i].ciclo.search('FE') != -1)) {
					// Y es igual a FE, se agrega a ciclos
					// por qué es diferente a FE? por que es por estetica, mostrar solo los ciclos normales, ya que los ciclos tienen ligado el ciclo FE
					if(response.data[i].ciclo.search('CICLO') != -1){
						this.ciclos.push(response.data[i])
					}
					}
				}
			}).catch( error =>{
				this.validarError( error.response.data.message )
			}).finally( () => { this.cargar = false })
		},

		updateTextoFaltaInfo(){
			this.Infotabs= '';
			if(this.faltaNiveles){
				this.Infotabs = 'Niveles'
			}
			if(this.faltaSucursal){
				if(this.Infotabs != ''){
					this.Infotabs = this.Infotabs + ' | Sucursales'
				} else {
					this.Infotabs = 'Sucursales'
				}
			}
			if(this.faltaInfoDispo){
				if(this.Infotabs != ''){
					this.Infotabs = this.Infotabs + ' | Disponibilidad'
				} else {
					this.Infotabs = 'Disponibilidad'
				}
			}
		},

		getSucursalesUsuario () {
			// Vaciamos los arreglos
			this.sucursalesUsuario = []
			this.selectSucursal = []
			// this.getdatosUsuario.idusuario este id es el usuario en la base de datos erp_db
			this.$http.get('planteles_usuario.usuario/' + this.getdatosUsuario.idusuario).then(response=>{
				// Hacemos un ciclo para obtener los datos de los departamentos
				for(const j in response.data){
					response.data[j].fecha_creacion  = response.data[j].fecha_creacion.substr(0,10)
					response.data[j].fecha_actualizo = response.data[j].fecha_actualizo.substr(0,10)
				}
				this.sucursalesUsuario = response.data
				//Generar dos for, para saber cual nivel ya esta dando y poder agregarlo a la tabla de nieveles como activo
				for(const i in this.sucursales){
					for(const j in this.sucursalesUsuario){
						// Cpmapramos que si sea el nivel que esta dando el usuario
						if(this.sucursales[i].idplantel == this.sucursalesUsuario[j].idplantel){
							// Le asignamos la prioridad a la sucursal
							this.sucursales[i].prioridad = this.sucursalesUsuario[j].prioridad
							// Lo metemos al arreglo de usuarios sucursales
							this.selectSucursal.push(this.sucursales[i])
						}
					}
				}
				if(this.sucursalesUsuario.length == 0){
					this.faltaSucursal = true;
					this.updateTextoFaltaInfo()
				} else {
					this.faltaSucursal = false;
					this.updateTextoFaltaInfo()
				}
			}).catch( error =>{
				this.validarError( error.response.data.message )
			}).finally( () => { })
		},
		getNivelesUsuario () {
    		// Vaciamos los arreglos
    		this.nivelesUsuario = []
    		this.selectNivel = []

    		this.$http.get('niveles_usuario.usuario/' + this.getdatosUsuario.idusuario).then(response=>{
			// Hacemos un ciclo para obtener los datos de los departamentos
			for(const j in response.data){
				response.data[j].fecha_creacion  = response.data[j].fecha_creacion.substr(0,10)
				response.data[j].fecha_actualizo = response.data[j].fecha_actualizo.substr(0,10)
			}
			this.nivelesUsuario = response.data
          // Generar dos for, para saber cual nivel ya esta dando y poder agregarlo a la tabla de nieveles como activo
          for(const i in this.niveles){
          	for(const j in this.nivelesUsuario){
          		// Cpmapramos que si sea el nivel que esta dando el usuario
          		if(this.niveles[i].idniveles == this.nivelesUsuario[j].idnivel){
          			// Lo metemos al arreglo de usuarios niveles
          			this.selectNivel.push(this.niveles[i])
          		}
          	}
          }

			if(this.nivelesUsuario.length == 0){
				this.faltaNiveles = true;
				this.updateTextoFaltaInfo()
			} else {
				this.faltaNiveles = false;
				this.updateTextoFaltaInfo()
			}
          // Cerramos la carga
          this.dialog = false
        }).catch(error=>{console.log(error)})
    	},
		getDisponibilidadUsuario(){
			this.dispUsuario = []
			this.$http.get('disponibilidad.usuario/' + this.getdatosUsuario.idusuario).then(response=>{
				this.dispUsuario = response.data;
				console.log(this.dispUsuario)
				this.dispUsuarioFiltrada = []
				this.dispUsuarioFiltrada[0] = this.dispUsuario.filter(el=>el.dia == 1);
				this.dispUsuarioFiltrada[1] = this.dispUsuario.filter(el=>el.dia == 2);
				this.dispUsuarioFiltrada[2] = this.dispUsuario.filter(el=>el.dia == 3);
				this.dispUsuarioFiltrada[3] = this.dispUsuario.filter(el=>el.dia == 4);
				this.dispUsuarioFiltrada[4] = this.dispUsuario.filter(el=>el.dia == 5);
				this.dispUsuarioFiltrada[5] = this.dispUsuario.filter(el=>el.dia == 6);
				this.dispUsuarioFiltrada[6] = this.dispUsuario.filter(el=>el.dia == 7);

				if(this.dispUsuario.length == 0){
					this.faltaInfoDispo = true;
					this.updateTextoFaltaInfo()
				} else {
					this.faltaInfoDispo = false;
					this.updateTextoFaltaInfo()
				}

			}).catch(error=>{
				console.log(error)
			}).finally( () => { 
				
			})
		},

		addHora () {
			if(this.horaini != '' && this.horafin != ''){
				for(const i in this.diasSeleccionados){
				var payload = {
					idusuario:        this.getdatosUsuario.idusuario,
					inicio:           this.horaini,
					fin:              this.horafin,
					usuario_registro: this.getdatosUsuario.iderp,
					deleted:          0,
					dia:              this.diasSeleccionados[i]
				}
				console.log(payload);
				this.$http.post('disponibilidad.add', payload).then(response=>{
					this.dialogHora = false
					this.snack = true
					this.snackColor = 'success'
					this.snackText = 'Datos guardados'
					this.getDisponibilidadUsuario()
				}).catch(error=>{console.log(error)})
				}
			}
		},

		guardarSuc () {
			//si se eliminan hay que updatear la prioridad de las otras
  			for(const i in this.selectSucursal){
    			const resultado = this.sucursalesUsuario.find( plantel => plantel.idplantel == this.selectSucursal[i].idplantel );
    			if(!resultado){
    				var payload = {
						idusuario: this.getdatosUsuario.idusuario,
						idplantel: this.selectSucursal[i].idplantel,
						usuario_registro:this.getdatosUsuario.iderp,
						fecha_creacion: this.fecha,
						fecha_actualizo: this.fecha,
						prioridad: this.selectSucursal.length,
					}
					console.log(payload)
					this.$http.post('planteles_usuario.add' , payload).then(response=>{
						this.snack = true
						this.snackColor = 'success'
						this.snackText = 'Datos guardados'
						this.getSucursalesUsuario()
					}).catch(error=>{console.log(error)})
    			}
    		}
    		// Ahora hay que eliminar todas esas que se deseleccionaron
			var prioridadSeElimino = 0;
    		for(const i in this.sucursalesUsuario){
    			const resultado = this.selectSucursal.find( plantel => plantel.idplantel == this.sucursalesUsuario[i].idplantel );
    			if(!resultado){
					prioridadSeElimino = this.sucursalesUsuario[i].prioridad
					this.$http.delete('planteles_usuario.delete/' + this.sucursalesUsuario[i].idplanteles_usuarios).then(response=>{
						this.snack = true
						this.snackColor = 'success'
						this.snackText = 'Datos guardados'
						this.getSucursalesUsuario()
					}).catch(error=>{console.log(error)})
    			} else {
					if(prioridadSeElimino > 0){
						//aqui hacemos el update de prioridad
						console.log(prioridadSeElimino)
						if(this.sucursalesUsuario[i].prioridad > prioridadSeElimino){
							var prioridad = this.sucursalesUsuario[i].prioridad -1
							console.log(prioridad)
							this.$http.put('planteles_usuario.update.prioridad/' + this.sucursalesUsuario[i].idplanteles_usuarios + '/' + prioridad).then(response=>{
								//this.getSucursalesUsuario()
							}).catch(error=>{console.log(error)})
						}
					}
				}
    		}
		},
		guardarNiv(){
			for(const i in this.selectNivel){
				const nivelUsuario = this.nivelesUsuario.find( nivel => nivel.idnivel == this.selectNivel[i].idniveles );
				//se busca el nivel seleccionado entre los que tiene el usuario
				if(!nivelUsuario){
					//si no se encuentra se inserta
					var payload = {
						idusuario:this.getdatosUsuario.idusuario,
						idnivel: this.selectNivel[i].idniveles,
						usuario_registro: this.getdatosUsuario.iderp,
						fecha_creacion:  this.fecha,
					    fecha_actualizo: this.fecha,
						deleted:         0,
					}
					this.$http.post('niveles_usuario.add' , payload).then(response=>{
						this.getNivelesUsuario()
					}).catch(error=>{console.log(error)})
				}
			}

			// Ahora hay que eliminar todas esas que se deseleccionaron
    		for(const i in this.nivelesUsuario){
    			const nivelUsuario = this.selectNivel.find( nivel => nivel.idniveles == this.nivelesUsuario[i].idnivel );
    			if(!nivelUsuario){
					this.$http.delete('niveles_usuario.delete/' + this.nivelesUsuario[i].idniveles_usuario).then(response=>{
						this.getNivelesUsuario()
					}).catch(error=>{console.log(error)})
    			}
    		}
		},

		dialogHoraAdd(){
			this.dialogHora = true
		},

		getCicloActual( ){
			this.cargar = true
			const payload = {
				fecha: this.fecha,
			};
			this.$http.post('usuarios.get.cicloactual', payload).then(response=>{
				this.cicloActual = response.data[0]
				this.cicloVer = this.cicloActual.id_ciclo
				this.cicloAdd = this.cicloActual.id_ciclo
				this.getCategoriaCiclo()
			}).catch( error =>{
				this.validarError( error.response.data.message )
			}).finally( () => { })
    	},

		getCategoriaCiclo( ){
			this.cargar = true
			const payload = {
				id_trabajador: this.datosPersonales.id,
				id_ciclo: this.cicloActual.id_ciclo
			};

			this.$http.post('usuarios.get.categoriaciclo', payload).then(response=>{
				this.categoria_ciclo = response.data    
				console.log(this.categoria_ciclo)
				if(this.categoria_ciclo[0]){
					this.aceptado = this.categoria_ciclo[0].estatus;
				}
			}).catch( error =>{
				this.validarError( error.response.data.message )
			}).finally( () => { this.cargar = false })

    	},

    	updateDatos( value ){
			// inicia pantalla de carga
			this.cargar = true
			// guardamos la info para el back
			const payload = {
				id_trabajador: this.datosPersonales.id,
				nombre_completo: this.getdatosUsuario.nombre_completo,
				id_ciclo: this.cicloActual.id_ciclo,
				ciclo: this.cicloActual.ciclo,
				fecha: this.fecha,
				estatus: value
			}
			// llamamos al back
			this.$http.post('usuarios.add.categoriaciclo', payload  ).then(response=>{
				//recargamos la info
				this.initialize()
			}).catch( error =>{
				//mensaje de error
				this.validarError( error.response.data.message )
			}).finally( () => { this.cargar = false })
    	},

    	irCapacitacion( ){
    		window.open( 'https://capacitacion.schoolofenglishadmin.com/#/login/' + this.getdatosUsuario.iderp, "_blank" )
    	},

		/*Eliminar disponibilidad*/
		deleteItem (value) {
			this.iddisponibilidad_usuarioDelete = value.iddisponibilidad_usuario
			this.dialogDeleteHora = true
		},

		deleteHora () {
			this.$http.delete('disponibilidad.delete/' + this.iddisponibilidad_usuarioDelete).then(response=>{
				this.dialogDeleteHora = false
				this.snack = true
				this.snackColor = 'success'
				this.snackText = 'Datos guardados'
				this.getDisponibilidadUsuario()
			}).catch(error=>{console.log(error)})
		},
    },
  }
</script>
<style scoped>
	.separacionAlta{
		padding-top: 100px !important;
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

	.bordesCard{
		border-radius: 14px !important;
	}

	.shadowCard{
		border-radius: 15px !important; 
		box-shadow: 0 20px 27px 0 rgba(0,0,0,0.15)  !important;
	}

	.cardUsuario{
		border-radius: 15px !important; 
		box-shadow: 0 20px 27px 0 rgba(0,0,0,0.1)  !important;
	}

  .colorCurso{
  	color:  #5b5b5b;
  }
</style>

