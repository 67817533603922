<template>
  <!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="confirmacion.estatus"
    max-width="400"
  >
    <v-card>
    	<v-card-text class="pt-4">
  			<v-img src="@/assets/seguro.jpeg"></v-img>
  			<v-card class="elevation-0 text-center pt-4">
  				<span class="text-h5" style="white-space: pre-line">
  				 	de que los datos están correctos.....
  				</span>
  			</v-card>
    	</v-card-text>
    	<v-card-actions>
		    <v-btn 
		    	color="dark" 
		    	tile 
		    	dark
		    	small 
		    	@click="confirmacion.estatus = false"
		    >
		  		Mejor reviso
		  		<v-icon small right>mdi-cancel</v-icon>
		  	</v-btn>
		    <v-spacer></v-spacer>
		    <v-btn 
		    	color="green" 
		    	tile 
		    	dark 
		    	small
		    	@click="confirmar()"
		    >
					Sí, muy seguro
		  		<v-icon small right>mdi-content-save</v-icon>
		  	</v-btn>
    	</v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import axios from 'axios';
	export default {
		props:[
			'confirmacion'
	  ],

	  methods:{

	  	confirmar( ){
	  		this.$emit('addCambio')
	  		this.confirmacion.estatus = false
	  	}
	  
	  }
	}
</script>