<template>
	<v-card>
    <v-card-title
      dark
      dense
    >
      Nuevo prospecto
    </v-card-title>

    <v-card-text class="pt-4">
      <v-row>
        <v-col cols="12" sm="4" md="4" lg="2">
          <v-img src="@/assets/logo_fast.png" max-width="200" v-if="escuela == 2"></v-img>
          <v-img src="@/assets/logo_inbi.png" max-width="200" v-else></v-img>
        </v-col>

        <v-col cols="12" sm="8" md="8" lg="10">
          <v-form ref="form" lazy-validation>
            <v-text-field
              v-model="prospecto.nombre_completo"
              label="Nombre completo"
              outlined
              dense
            ></v-text-field>

            <v-text-field
              v-model.number="prospecto.telefono"
              label="Telefono"
              outlined
              dense
              type="number"
              :counter="10"
            ></v-text-field>

            <v-select
              :items="vendedoras_plantel"
              v-model="prospecto.sucursal"
              return-object
              item-text="plantel"
              item-value="idplantel"
              label="Sucursal"
              outlined
              dense
            ></v-select>

            <v-textarea
              v-model="prospecto.nota_inicial"
              outlined
              dense
              rows="3"
              hide-details
              label="Nota inicial"
            > 
            </v-textarea>
          </v-form>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-btn 
          color="black" small dark @click="cerarDialog()"
          tile
        >
          <v-icon small left>mdi-cancel</v-icon>
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="grabarProspecto()" tile small v-if="!cargar">
          <v-icon small left>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from 'axios';
	export default {
		props:[
			'prospecto',
			'editedItem',
			'vendedoras_plantel',
			'escuela',
			'cargar'
	  ],

	  methods:{
		  grabarProspecto( ){
		  	this.$emit('grabarProspecto' )
		  },

		  cerarDialog( ){
		  	this.$emit('cerarDialog')		  	
		  }
	  }
	}
</script>