<template>
  <v-container class="mt-4 ml-2">
    <v-row>
      <v-col cols="12">
	      <v-card>
	      	<v-card-title primary-title>
		        <v-toolbar-title>Teachers</v-toolbar-title>
		        <v-divider
		          class="mx-4"
		          inset
		          vertical
		        ></v-divider>
		        <v-spacer></v-spacer>
		        <v-btn color="primary" @click="getTeachers()" small class="mr-2">Consultar</v-btn>
		        <v-btn color="secondary" @click="getTeachersERP()" small>Cargar del ERP</v-btn>
	      	</v-card-title>
	        <v-card-text>
	        	<!-- Menu superior de tabs -->
		        <v-tabs
		        	v-if="teachers"
			        dense
			        class="mt-4"
			        v-model="tab"
			        background-color="white"
			      >
			        <v-tabs-slider color="primary"></v-tabs-slider>


			        <v-tab
			          v-for="(item,i) in items"
			          :key="i"
			        >
			          <v-icon v-text="item.icon" left></v-icon>{{ item.name }}
			        </v-tab>


			        <!-- Apartado de las secciones de los tabs -->
			        <v-tabs-items v-model="tab">
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">
			                <v-card-title primary-title class="pt-0 ">

			                </v-card-title>

			                <v-card-text>	
			                	<v-data-table
											    dense
											    :headers="headers"
											    :items="teachers.teachersFast"
											    item-key="id"
											    class="elevation-1"
											  ></v-data-table>
			                </v-card-text>	
			              </v-card>
			            </v-col>
			          </v-tab-item>
			        

			          <!-- horarios de inbi -->
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">
			              	<v-card-title primary-title class="pt-0 ">

			                </v-card-title>

			                <v-card-text >
			                  <v-data-table
											    dense
											    :headers="headers"
											    :items="teachers.teachersInbi"
											    item-key="id"
											    class="elevation-1"
											  ></v-data-table>
			                </v-card-text>	
			              </v-card>
			            </v-col>
			          </v-tab-item>


			          <!-- Resultados -->
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">

			              	
			              </v-card>
			            </v-col>
			          </v-tab-item>

			        </v-tabs-items>
			      </v-tabs>
	        </v-card-text>
	      </v-card>		
      </v-col>
    </v-row>

    
  	<!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="500"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Espera porfavor
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card
        color="green"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Datos grabados
        </v-card-title>
        <div class="pl-6 pb-6">
          <br/>
          NOTA: {{successMensaje}}
        </div>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogSuccess = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
	  
    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card
        color="error"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Error al recuperar los datos
        </v-card-title>
        <v-card-text>
          <br/>
          NOTA: {{error}}
        </v-card-text>
        <v-btn 
        	color="transparent elevation-0"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogError = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios';
	// NOTA: el ciclo solo se utiliza para mandar a traer los teachers del ERP no los del LMS
	import { mapGetters } from 'vuex'
  import XLSX from 'xlsx'

  export default {
    data: () => ({
    	row:null,
    	tab: {name:'teachers FAST'   , icon:'mdi-file' }, 
      items: [
        {name:'teachers FAST'    , icon:'mdi-file' }, 
        {name:'teachers INBI'    , icon:'mdi-file' }, 
      ],

      headers: [
        { text: 'id'          , value: 'id' },
        { text: 'Nombre'      , value: 'nombre' },
        { text: 'A. Paterno'  , value: 'apellio_paterno' },
        { text: 'A. Materno'  , value: 'apellido_materno' },
        { text: 'Usuario'     , value: 'usuario' },
        { text: 'Email'       , value: 'email' },
        { text: 'iderp'       , value: 'iderp' },
      ],

      teachers:[],
      dialogError: false,
			dialogSuccess:false,
			error:'',
			successMensaje:'',
			overlay:true,
			horarios:null,
			cicloInf: 		null,
      ciclos:  			[],
    }),

   
    created () {
      this.getTeachers()
    },

    methods: {
      getTeachers () {
      	// vaciamos el horario
      	this.teachers = null
      	// Activamos el loader
    		this.overlay = true
    		// Hacemos la peticios
    		this.$http.get(`lms.catalogos.teachers.all`).then(response=>{
    			// Guardamos la respuesta
          this.teachers = response.data
          // Quitamos el loader
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError(error.body.message)
        })
      },

      getTeachersERP () {
      	// Activamos el loader
    		this.overlay = true
    		// Hacemos la peticion
    		this.$http.get(`lms.catalogos.teachers.cron`).then(response=>{
    			// Mandamos a llamar a la funcion principal para que cargue los teachers
          this.getTeachers()
          // Quitamos el loader
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError(error.body.message)
        })
      },

      openDialogError (mensaje) {
      	this.error       = mensaje
      	this.dialogError = true
      },

      openDialogSuccess ( mensaje ){
      	this.successMensaje = mensaje
      	this.dialogSuccess  = true
      },

    },

    computed:{
    	...mapGetters('login',['getdatosUsuario'])
    }
  }
</script>

<style>
	.v-snack__content{
		padding: 0px !important;
	}
</style>