<template>
  <v-container >
    <v-dialog
      v-model="paramsDialogMonto.estatus"
      persistent
      max-width="800"
    >
      <v-card v-if="alumno">
        <v-card-title class="text-h5">
          <b>Tipo de reporte: </b> Cambio de monto
        </v-card-title>

        <v-card-text>
          <div class="black--text text-subtitle-1">
            <b>Reporte generado por:</b> {{ getdatosUsuario.nombre_completo }}
            <br/>
          </div>

          <v-row v-if="alumno">
            <v-col cols="12">
              <v-card-text>
                <b>Matricula:</b> {{ alumno.matricula }}
                <br/>
                <b>Nombre:</b>    {{ alumno.alumno }}
                <br/>
                <v-divider></v-divider>

                <br/>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
									    :headers="headers"
									    :items="movimientos"
									    class="elevation-0"
									    :search="search"
									    :mobile-breakpoint="100"
					            dense
					            id="grupos"
					            item-class="clase_row"
					            item-key="id_ingreso"
									  >
									  	<!-- Valores que ocupan botons, chips, iconos, etc -->
									    <template v-slot:item.baja="{ item }">
									      <v-chip 
									      	small
									      	:color="item.baja == 1 ? 'green': 'red'"
									      	dark
									      >{{ item.baja == 1 ? 'SI': 'NO'  }}</v-chip>
									    </template>

									    <template v-slot:no-data>
									      <v-btn
									        color="primary"
									        @click="getMovimientos"
									        small
									      >
									        Actualizar
									      </v-btn>
									    </template>

									  </v-data-table>
                  </v-col>

                </v-row>

                <v-row v-if="pago">
                  <v-col cols="12" md="6">
					          <label>Monto pagado</label>
					          <v-text-field
					            filled
					            dense
					            single-line
					            v-model="alumno.viejo_monto"
					            readonly
					          ></v-text-field>
					          <v-alert
					            dense
					            outlined
					            type="error"
					            v-if="nuevoAdeudo < 0"
					          >
					            No puedes agregar un pago mayor al adeudo, el restante se debe ingresar en el siguiente grupo
					          </v-alert>
                  </v-col>

                  <v-col cols="12" md="6">

                    <label>Nuevo monto.</label>
                    <v-text-field
                      filled
                      dense
                      single-line
                      v-model="pago.nuevo_monto"
                      readonly
                    ></v-text-field>

                    <!-- Motivo del cambio -->
                    <p 
                      style="white-space: pre-line"
                      class="mt-4"
                    >
                      <b>Motivo de cambio:</b> {{ alumno.motivo }}
                    </p>

                    <v-row>
                      <v-col cols="12" md="4" v-for="(img, i) in alumno.fotos" :key="i">
                        <v-card class="py-4 shadowCard" @click="verImagen( img.imagen )">
                          <v-img :src="url + img.imagen"  contain aspect-ratio="2"></v-img>
                        </v-card>
                      </v-col>
                    </v-row>

                  </v-col>

                </v-row>
              </v-card-text>

              <v-card-actions>

                <v-btn
                  color="green"
                  dark
                  tile
                  @click="idsolicitud_cambio = alumno.idsolicitud_cambio, paramsHistorial.estatus = true"
                  small
                >
                  Historial
                  <v-icon small right>mdi-file-document-multiple</v-icon>
                </v-btn>

                <v-btn
                  color="black"
                  small
                  tile
                  dark
                  @click="paramsDialogMonto.estatus = false"
                  absolute
                  top
                  right
                  text
                >
                  <v-icon >mdi-close</v-icon>
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn
                  v-if="![4].includes( alumno.estatus ) "
                  class="mr-2"
                  color="black"
                  dark
                  tile
                  @click="idsolicitud_cambio = alumno.idsolicitud_cambio, paramsRechazo.estatus = true"
                  small
                >
                  <v-icon small left>mdi-close</v-icon>
                  Rechazar
                </v-btn>
          
                <v-btn
                  v-if="[28, 334, 707 ].includes( getdatosUsuario.iderp ) && [1,3].includes( alumno.estatus ) "
                  color="green"
                  dark
                  tile
                  @click="addCambioFinanzas()"
                  :loading="loading"
                  :disabled="disabled" 
                  small
                >
                  <v-icon small left>mdi-check</v-icon>
                  Aceptar
                </v-btn>

                <v-btn
                  v-else-if="[2].includes( alumno.estatus ) "
                  color="green"
                  dark
                  tile
                  @click="updateCambio()"
                  :loading="loading"
                  :disabled="disabled" 
                  small
                >
                  <v-icon small left>mdi-check</v-icon>
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- Ver evidencias -->
    <v-dialog v-model="dialogImagen" max-width="1000px">
      <v-card class="elevation-0">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogImagen = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-img
            :src=" url2 + imagen" 
            contain
            max-height="80vh"
          >
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <dialogHistorial
      v-if="paramsHistorial.estatus"
      :paramsHistorial="paramsHistorial"
      :idsolicitud_cambio="idsolicitud_cambio"
      :alumno="alumno"
    />

    <dialogRechazar
      v-if="paramsRechazo.estatus"
      :paramsRechazo="paramsRechazo"
      :idsolicitud_cambio="idsolicitud_cambio"
      @rechazado="rechazado"
    />

  </v-container>
</template>
<script>
  import axios from 'axios';
  var moment = require('moment');
  moment.locale();
  import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import dialogHistorial       from '@/components/cambios/dialogHistorial.vue';
  import dialogRechazar        from '@/components/cambios/dialogRechazar.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    components:{
      Alerta,
      carga,
      dialogHistorial,
      dialogRechazar
    },

    mixins: [ validarErrores, funcionesExcel ],

    props: ['reporteTexto','alumno', 'tipo_reporte', 'paramsDialogMonto'],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search: '',
      
      tipociclo:'',
      grupos:[],
      nuevoGrupo:null,
      tipocambios:[],
      tipoCambio:null,
      alumnos:[],

      grupoAlumnos:   [],
      grupoAnterior:  null,
      
      loading:        false,
      disabled:       false,
      motivo:         null,

      total_a_pagar: 0,
      precios:[],

      dataGrupoActual: null,
      dataNuevoGrupo:null,
      precioSeleccionado2: null,
      descuentoHermanos:0,

      movimientos:[],

      viejo_metodo: 0,
      nuevo_metodo: 0,

      url:'',
      url2:'',

      fotos:[],
      files: [],
      vistaPrevias:[],

      selected:[],


      headers: [
        { text: 'id_ingreso'    , value: 'id_ingreso'      },
        { text: 'Grupo'         , value: 'grupo'           },
        { text: 'Monto pagado'  , value: 'pagado'          },
        { text: 'Fecha'         , value: 'fecha_alta'      },
        { text: 'Forma pago'    , value: 'forma_pago'      },
      ],

      pago: null,

      comprobanteOficial:'',

      pagocompleto: null,
      imagen:'',
      dialogImagen: false,

      paramsHistorial: {
        estatus: false
      },

      paramsRechazo: {
        estatus: false
      },


    }),

    watch:{
      selected( value ){
      	if( value.length ){ 
      		this.pago = value[0]
      		this.dialogModificaMonto()
      	}
      }
    },

    async created () {
      this.url = axios.defaults.baseURL + 'evidencia-cambios/'
      // Cargar los cursos actuales
      await this.getMovimientos()
    },

    methods: {

    	getMovimientos () {
        this.movimientos = []
        return this.$http.get('inscripciones.alumno.grupos/' + this.alumno.id_alumno).then(response=>{

        	this.movimientos = response.data.filter( el => el.id_ingreso == this.alumno.id_ingreso )

        	this.pago = this.movimientos[0]

        	this.pago.nuevo_monto = this.alumno.nuevo_monto

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      addCambioFinanzas() {
        this.updatePago()
        // Activar el disabled del boton para que no le den doble click
        this.loading  = true
        this.disabled = true
        this.overlay  = true

        // Generar el payload a mandar
        let payload = {
          acepto:                 this.getdatosUsuario.iderp,
          idsolicitud_cambio:     this.alumno.idsolicitud_cambio ,
          id_ingreso:             this.alumno.id_ingreso,
          nuevo_metodo:           this.alumno.nuevo_metodo,
          estatus:                2,
        }

        // Enviar los datos 
        this.$http.post('cambios.metodo.pago',payload).then(response=>{
          this.validarSuccess('Cambio realizado con exito')
          // this.updatePrecioAlumno()
          this.$emit('getAlumnosxEscuela')
        }).catch( error =>{
          this.loading  = false
          this.disabled = false
          this.overlay  = false
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      updatePago( ){
        let pagado = this.pago.nuevo_monto ? this.pago.nuevo_monto : 0

        const payload2 = {
          // id_carga: this.pagocompleto.id,
          pagado  : this.pago.nuevo_monto - this.alumno.viejo_monto,
          adeudo  : this.alumno.viejo_monto - this.pago.nuevo_monto,

          id_ingreso: this.pago.id_ingreso,
          monto_pagado: parseFloat( this.pago.nuevo_monto )
        }

        return this.$http.post("actualizar.monto.pagado", payload2 ).then((response) => {

          this.dialogEditPago = false
          this.pago = null
          this.pagocompleto = null
          this.validarSuccess(response.data.message)

          this.cargar = false;

        }).catch((error) => {

          this.validarError(error);

        }).finally(() => {

          this.cargar = false;

        });
      },

      dialogModificaMonto(  ){
        const payload = {
          id_alumno: this.pago.id_alumno,
          id_grupo:  this.pago.id_grupo
        }

        this.$http.post("pago.completo" , payload ).then((response) => {
          this.pagocompleto = response.data;
          this.cargar = false;
        }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });

      },

      subirFotosServidor( ){
        let formData = new FormData();

        for( const i in this.vistaPrevias ){
          formData.append('file', this.vistaPrevias[i].file )
        }

        return new Promise((resolve,reject)=>{
          // obtener la extensión del archivo
          // Hacemos la petición
          this.$http.post(`cambios.ingreso.foto`, formData ).then(response=>{
            resolve( response )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },

      cargarFotos(){
        if(this.files.length < 1){
          return
        }

        this.files.forEach((element, index) => {
                // creamos una variable tipo FormData
          let formData = new FormData();
                //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', element);
                //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          this.getBase64(element, formData)
        })
      },

      getBase64(file, formData) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          console.log(file)
          me.vistaPrevias.push({
            url: reader.result, 
            formData: formData, 
            image_name: file.name,
            file
          })
        };
      },

      eliminarFoto(value){
        this.vistaPrevias.forEach((element, index)=>{
          if(element.url == value){
            this.vistaPrevias.splice(index,1);
          }
        })
      },

      verImagen( imagen ){
        this.imagen       = imagen
        this.dialogImagen = true
      },

      rechazado( ){
        this.paramsRechazo.estatus = false
        this.$emit('getAlumnosxEscuela')
      }
    },

    computed:{
      ...mapGetters ('login',['getdatosUsuario']),

      costoCurso( ){
        
        if( this.precioSeleccionado ){

          let existeCosto = this.precios.find( el => el.id_curso == this.precioSeleccionado )

          return existeCosto ? existeCosto.precio_lista : 0

        }else{
          return 0
        }

      },

      currentTitle () {
        switch (this.step) {
          case 1: return 'Selecciona el alumno'
          case 2: return 'Selecciona tipo de reporte'
          default: return 'Confirma tu reporte'
        }
      },

      filterAlumnos( ){

        const alumnos = this.movimientos

        if( this.plantel ){
          return alumnos.filter( el=> { return el.id_plantel == this.plantel })
        }

        if( this.step == 3 ){
          return this.selected
        }
        
        return alumnos
      },

      nuevoAdeudo( ){

        if( this.pago ){

          let pagado = this.pago.nuevo_monto ? this.pago.nuevo_monto : 0

          const datoSinFormato = this.pago.pagado

          let pagoSinPagoActal = ( parseFloat( this.pago.pagado ) - parseFloat( datoSinFormato ) )


          return this.pago.pago_oficial - ( pagoSinPagoActal + parseFloat( pagado ) )


        }else{
          return 0
        }
      },

      pagoAnterior( ){
        if( this.pago ){

          const datoSinFormato = this.pago.pagado

          return ( parseFloat( this.pago.pagado ) - parseFloat( datoSinFormato ) )

        }else{
          return 0
        }
      },


    }
  }
</script>

<style>

  .v-menu__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-menu__content::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-button:increment,.v-menu__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-menu__content::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>