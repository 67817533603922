import Vue from 'vue'
import store from '@/store'
import {mapGetters, mapActions}    from 'vuex'

const axios  = require('axios');
var moment   = require('moment');

moment.locale(); 

export default {
	data: () => ({
    // Alertas
    parametros:{
      dialogError: false,
      mensaje: '',
      color: ''
    },

    respuestaAlerta:false,
    loader: true,
    cargar: false,

    headers: [
      { text: 'Plantel'         , value: 'plantel'               },
      { text: 'Mat. Nuevas'     , value: 'matriculas_nuevas'     },
      { text: 'Mat. Nvas. Liq.' , value: 'matriculas_liquidadas' },
      { text: 'Mat. Nvas. Par.' , value: 'matriculas_parciales'  },
      { text: 'Liq. Mat. Ant'   , value: 'matriculas_ant_liqu'   },
    ],

    headers2: [
      { text: 'Vendedora'       , value: 'nombre_completo'       },
      { text: 'Mat. Nuevas'     , value: 'matriculas_nuevas'     },
      { text: 'Mat. Nvas. Liq.' , value: 'matriculas_liquidadas' },
      { text: 'Mat. Nvas. Par.' , value: 'matriculas_parciales'  },
      { text: 'Liq. Mat. Ant'   , value: 'matriculas_ant_liqu'   },
    ],

    headers3: [
      { text: 'Plantel'         , value: 'plantel'                     },
      { text: 'Mat. Nuevas'     , value: 'matriculas_nuevas_monto'     },
      { text: 'Mat. Nvas. Liq.' , value: 'matriculas_liquidadas_monto' },
      { text: 'Mat. Nvas. Par.' , value: 'matriculas_parciales_monto'  },
      { text: 'Liq. Mat. Ant'   , value: 'matriculas_ant_liqu_monto'   },
    ],

    headers4: [
      { text: 'Vendedora'       , value: 'nombre_completo'             },
      { text: 'Mat. Nuevas'     , value: 'matriculas_nuevas_monto'     },
      { text: 'Mat. Nvas. Liq.' , value: 'matriculas_liquidadas_monto' },
      { text: 'Mat. Nvas. Par.' , value: 'matriculas_parciales_monto'  },
      { text: 'Liq. Mat. Ant'   , value: 'matriculas_ant_liqu_monto'   },
    ],

    fechaini:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    nuevasMatriculas:[],
    plantelesActivos:[],
    vendedorasActuales:[],
    encabezados: null,
    encabezados_ant: null,
    encabezados2: null,
    encabezados_ant2: null,
    escuela: 1,


    headers5: [
      { text: 'Campo'     , value: 'columna1' },
      { text: 'Semana Ant', value: 'columna2' },
      { text: 'Semana Act', value: 'columna3' },
      { text: 'Avance'    , value: 'columna4' },
    ],
    datosTablaHeaders5:[],
    alumnos:[],
    ciclo: null,

     headers6: [
      { text: 'Matriculas' , value: 'columna1', width: '40%', align: 'start' },
      { text: 'Semana Act' , value: 'columna3', width: '35%', align: 'start' },
    ],

    tablaFast:[],
		tablaInbi:[],
		totalFast:[],
		totalInbi:[],

    encabezadosFast: null,
    encabezadosInbi: null,

    escuela: 1,


  }),

	computed: {
		...mapGetters('Carrito', ['getCarrito']),
		...mapGetters('login', ['getdatosUsuario','getLogeado', 'getEscuela','getEstatusLlamada','getTareas','getNuevosProspectos']),
    ...mapGetters('tema', ['getTema']),
    ...mapGetters('Carrito',['getCarrito']),
	},

	created () {
    this.initialize()

    this.initialize2()


  },

  methods: {
    initialize () {
      this.cargar    = true
      this.ciclos    = []

      if( !this.fechaini ){ return this.validarErrorDirecto('Favor de seleccionar un ciclo') }


      var payload = { fechaini: this.fechaini, escuela: [18,19].includes( this.getdatosUsuario.idpuesto ) ? this.getEscuela : this.escuela }

      this.$http.post('reporte.semanal.ventas', payload ).then(response=>{
      	this.encabezados        = response.data.encabezados
      	this.encabezados_ant    = response.data.encabezados
      	this.plantelesActivos   = response.data.plantelesActivos
        this.vendedorasActuales = response.data.vendedorasActuales
      	this.alumnos            = response.data.nuevasMatriculas

      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    initialize2 () {
      this.cargar    = true
      var payload = { fechaini: this.fechaini, escuela: this.escuela }

      this.datosTablaHeaders5 = []

      this.$http.post('kpi.ventasmomento', payload ).then(response=>{

        this.tablaFast        = response.data.vendedorasFast
        this.tablaInbi        = response.data.vendedorasInbi
        this.totalFast        = response.data.totalFast 
        this.totalInbi        = response.data.totalInbi
      	
      	this.encabezadosInb   = response.data.encabezados
      	this.encabezadosFast  = response.data.encabezadosFast

        this.ciclo            = response.data.ciclo

        this.datosTablaHeaders5 = [
          { 
            columna1: 'Mat. Liq. FAST',
            columna3: ` ${ this.encabezadosFast.matriculas_liquidadas} => ${ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.encabezadosFast.matriculas_liquidadas_monto ) }`,
          },
        ]

        console.log( this.encabezadosInb )
        this.datosTablaHeaders5.push({ 
          columna1: 'Mat. Liq. INBI',
          columna3: ` ${ this.encabezadosInb.matriculas_liquidadas} => ${ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.encabezadosInb.matriculas_liquidadas_monto ) }`,
        })
        this.cargar = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },
  },
}