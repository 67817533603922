<template>
	<v-dialog
    v-model="dialogProspectosProspectos.estatus"
    hide-overlay
    persistent
    max-width="900"
  >
    <v-card>
      <v-card-title primary-title >
        PROSPECTOS POR ESTATUS
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headersCerrados"
          :items="reporteFast"
          class="elevation-0"
          dense
          :search="search"
        >
                    
        </v-data-table>
      </v-card-text>

      <v-card-text >
        <v-data-table
          :headers="headersCerrados"
          :items="reporteInbi"
          class="elevation-0"
          dense
          :search="search"
        >
                    
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error"   dark @click="dialogProspectosProspectos.estatus = false">Cancelar</v-btn>
      </v-card-actions>

      <v-btn 
        color="transparent elevation-0"
        x-small
        absolute
        top
        right
        fab
        class="mt-5 mr-0"
        @click="dialogProspectosProspectos.estatus = false"
       ><v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>


    <!-- Dialog para ver a los prospectos -->	
    <v-dialog
	    v-model="dialogProspectos"
	    hide-overlay
	    persistent
	    max-width="1200"
	  >
	    <v-card>
	      <v-card-title primary-title >
	        Prospectos
	      </v-card-title>

	      <v-card-text>
	        <v-data-table
	          :headers="headersProspectos"
	          :items="prospectos"
	          class="elevation-0"
	          dense
	          :search="search"
	        >

	        	<template v-slot:item.justificacion2="props" v-if="getdatosUsuario.idpuesto !== 18">
			        <v-edit-dialog

			          :return-value.sync="props.item.justificacion2"
			          @save="save(props.item)"
			          @close="close"
			          large
			          save-text="Guardar"
			          cancel-text="Cancelar"
			        >
			          {{ props.item.justificacion2 }}
			          <template v-slot:input>
			            <v-textarea
			            	class="mt-2"
			              v-model="props.item.justificacion2"
			              label="Justificación"
			              filled
			            ></v-textarea>
			          </template>
			        </v-edit-dialog>
			      </template>

	        </v-data-table>
	      </v-card-text>

	      <v-card-actions>
	        <v-spacer></v-spacer>
	        <v-btn color="error"   dark @click="dialogProspectos = false">Cancelar</v-btn>
	      </v-card-actions>

	      <v-btn 
	        color="transparent elevation-0"
	        x-small
	        absolute
	        top
	        right
	        fab
	        class="mt-5 mr-0"
	        @click="dialogProspectos = false"
	       ><v-icon>mdi-close</v-icon>
	      </v-btn>
	    </v-card>

	    <!-- Dialog de carga -->
	    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
	    <carga                v-if="cargar"/>


	  </v-dialog>


    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>


  </v-dialog>
</template>
<script>
import axios from 'axios';

	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'dialogProspectosProspectos',
			'prospectosSeguimiento',
			'vendedoras_plantel',
			'prospectosAgendadaInduccion'
	  ],

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

			reporteFast:[],      
			reporteInbi:[],      

      headersCerrados: [
			  { text: 'Vendedora',    value: 'vendedora'   },
        { text: 'Atrasados',    value: 'atrasados'   },
			  { text: 'Nuevos',       value: 'nuevos'      },
			  { text: 'Seguimiento',  value: 'seguimiento' },
			  { text: 'Total',        value: 'total'       },
			  { text: 'Contactos',    value: 'contactos'   },
      ],
      search:'',

      fechaini: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fechafin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

			dialogProspectos: false,
			prospectos:[],
			headersProspectos: [
			  { text: 'Folio',     value: 'folio'           },
        { text: 'Prospecto', value: 'nombre_completo' },
			  { text: 'Teléfono' , value: 'telefono'        },
			  { text: 'com. Vend', value: 'justificacion'   },
			  { text: 'com. Mkt' , value: 'justificacion2'  },
      ],
      fecha_inicio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fecha_final:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }),

    watch:{
    	fechafin ( value ){ if( value ){ this.initialize() } },
    	fechaini ( value ){ if( value ){ this.initialize() } }
    },


    async created () {
      await this.initialize()
    },

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    methods:{

    	initialize () {

	    	const payload = {
        	fecha_inicio: this.fecha_inicio, 
        	fecha_final:  this.fecha_final
        }

        // Mandamos a llamar a la api
        this.$http.post('marketing.contactos',payload).then(response=>{

        	let usuarios = response.data.usuariosSistema

	    		let objetoUnico = {};
					let arregloSinDuplicados = this.prospectosSeguimiento.filter(objeto => {
					  const propiedadUnica = objeto.vendedora;

					  // Si el objeto no ha sido agregado al objetoUnico, agrégalo y devuelve true
					  if (!objetoUnico[propiedadUnica]) {
					    objetoUnico[propiedadUnica] = true;
					    return true;
					  }

					  // Si el objeto ya está en el objetoUnico, devuelve false
					  return false;
					});

					for( const i in arregloSinDuplicados ){

						const { vendedora, usuario_asignado } = arregloSinDuplicados[i]

						const existeUsuario = usuarios.find( el => el.id_usuario == usuario_asignado && el.activo_sn == 1 )

						arregloSinDuplicados[i]['activo_sn'] = existeUsuario ? existeUsuario.activo_sn : 0

						arregloSinDuplicados[i]['atrasados'] = this.prospectosSeguimiento.filter((el)=>{ return el.idetapa == 1 && el.vendedora == vendedora }).length
						arregloSinDuplicados[i]['nuevos'] = this.prospectosSeguimiento.filter((el)=>{ return el.idetapa == 2 && el.vendedora == vendedora }).length
						arregloSinDuplicados[i]['seguimiento'] = this.prospectosSeguimiento.filter((el)=>{ return el.idetapa > 2 && el.vendedora == vendedora }).length + this.prospectosAgendadaInduccion.filter((el)=>{ return el.idetapa > 2 && el.vendedora == vendedora }).length
						arregloSinDuplicados[i]['total'] = this.prospectosSeguimiento.filter((el)=>{ return el.vendedora == vendedora }).length + this.prospectosAgendadaInduccion.filter((el)=>{ return el.idetapa > 2 && el.vendedora == vendedora }).length

						const existe = response.data.dataTabla.find( el => el.usuario_asignado == usuario_asignado )
						arregloSinDuplicados[i]['contactos'] = existe ? existe[`${this.fecha_inicio}`] : 0

					}

					console.log( arregloSinDuplicados )

					arregloSinDuplicados = arregloSinDuplicados.filter( el => el.activo_sn == 1 )

					this.reporteFast = arregloSinDuplicados.filter( el => el.escuela == 2 )
					this.reporteInbi = arregloSinDuplicados.filter( el => el.escuela == 1 )
        	
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      }

    }
	}
</script>
