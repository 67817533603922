<template>
  <v-container fluid>
    <v-subheader class="pa-0"><strong>Tutor</strong></v-subheader>
    <v-divider class="mb-4"></v-divider>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.tutor"
          label="Tutor"
          filled
          hide-details
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.telefono"
          label="Teléfono"
          filled
          hide-details
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.celular"
          label="Celular"
          filled
          hide-details
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="alumno.tipo_parentesco"
          label="Parentezco"
          filled
          hide-details
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />

  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

// Componentes
export default {
  components: {
    Alerta,
    carga,
  },

  props: ["editedItem"],
  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    id_alumno: 0,
    alumno: [],
    precios: [],
    dialogActualizar: false,
    precioSeleccionado: null,
    idCursoAsociado: null,
    tutor: ""
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
  },

  watch: {
    editedItem() {
      this.initialize();
    },
  },

  created() {
    this.id_alumno = this.editedItem.id_alumno;
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http
        .get("tutor.alumno/" + this.id_alumno)
        .then((response) => {
          this.alumno = response.data;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    validarSuccess(mensaje) {
      this.cargar = false;
      this.parametros.mensaje = mensaje;
      this.parametros.dialogError = true;
      this.parametros.color = "success";
      this.respuestaAlerta = true;
    },

    validarError(value) {
      this.cargar = false;
      if (value.status == 400 || value.status == 500) {
        this.parametros.mensaje = value.body.message;
        this.parametros.dialogError = true;
        this.parametros.color = "#FF2C2C";
        this.respuestaAlerta = true;
      }
    },

    validarErrorDirecto(mensaje) {
      this.cargar = false;
      this.parametros.mensaje = mensaje;
      this.parametros.dialogError = true;
      this.parametros.color = "#FF2C2C";
      this.respuestaAlerta = true;
    },
  },
};
</script>


