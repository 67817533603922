<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title class="text-subtitle-1">
            Validar pagos sucursal
            <v-spacer></v-spacer>

            <!-- ACTUALIZAR LA INFO -->
            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="consultar()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
            
          </v-card-title>

          <!-- CONTENIDO -->
          <v-card-text class="">

            <!-- FILTROS  -->
            <v-row>

            	<!-- <v-col cols="12" md="4" lg="2" class="pb-0">
                <label>Selecciona un ciclo</label>
                <v-autocomplete
                  label="Ciclo"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  v-model="ciclo"
                  :items="ciclos"
                  item-text="ciclo"
                  item-value="id_ciclo"
                  clearable
                ></v-autocomplete>
              </v-col> -->


              <v-col cols="12" sm="4" lg="2" xl="2">
                <label>Fecha de inicio</label>
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  clearable
                  v-model="fecha_inicio"
                  type="date"
                  single-line
                  @keyup.enter="consultar"
                ></v-text-field>
              </v-col>


              <v-col cols="12" sm="4" lg="2" xl="2">
                <label>Fecha final</label>
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  clearable
                  v-model="fecha_final"
                  type="date"
                  single-line
                  @keyup.enter="consultar"
                ></v-text-field>
              </v-col>

              <!-- Forma de pago -->
              <v-col cols="12" md="4" lg="2" xl="2">
                <label>Tipo de pago</label>
                <v-select
                  :items="tipos_pago"
                  v-model="formaPago"
                  label="Forma de pago"
                  filled
                  dense
                  item-text="tipo"
                  single-line
                  item-value="id"
                ></v-select>
              </v-col>

              <!-- Estatus de revisados y sin revisar -->
              <v-col cols="12" md="5" lg="5">
                <v-radio-group
                  v-model="opEstatus"
                  row
                >
                  <v-radio
                    label="Aceptados"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Rechazados"
                    :value="2"
                  ></v-radio>
                  <v-radio
                    label="Pendientes"
                    :value="3"
                  ></v-radio>
                  <v-radio
                    label="Todos"
                    :value="4"
                  ></v-radio>
                </v-radio-group>
              </v-col>

            </v-row>

            <v-row align="center">
              <v-col cols="12" class="pa-0">
                <v-data-table
					        :headers="headersDos"
					        :items="filterCorte"
					        class=""
					        dense
					        :search="searchMovimientos"
					        mobile-breakpoint="200"
					        item-class="cambio"
					      >

					        <template v-slot:top>
					          <v-card-title primary-title>
					            <label class="text-subtitle-1 black--text">Movimientos | {{ filterCorte.length }} |</label>
					            <v-spacer></v-spacer>
						          <v-text-field
						            filled
						            dense
						            append-icon="mdi-magnify"
						            label="Búscar"
						            v-model="searchMovimientos"
						          ></v-text-field>
					          </v-card-title>
					        </template>

					        <template v-slot:item.aceptado="{ item }">
		                <v-chip small color="green"  dark v-if="item.aceptado == 1">Aceptado</v-chip>
		                <v-chip small color="red"    dark v-else-if="item.aceptado == 2">Rechazado</v-chip>
		                <v-chip small color="blue"   dark v-else>Pendiente</v-chip>

		                <span v-if="item.aceptado == 2"><br/>{{ item.motivo }}</span>

		              </template>

					        <template v-slot:item.monto_pagado="{ item }" v-if="[334, 28].includes( getdatosUsuario.iderp )">
					          <!-- <span @click="dialogModificaMonto( item )"> -->
					            {{ item.monto_pagado }}
					          <!-- </span> -->
					        </template>

					        <template v-slot:item.aut_clave_rastreo="{ item }">
					          <span 
					            :class="item.aut_clave_rastreo ? '' : 'red--text' " 
					            @click="movimiento = item, dialogAddFolio = true" 
					            type="button" 
					          >
					            {{ item.aut_clave_rastreo ? item.aut_clave_rastreo : 'Click aquí' }}
					          </span>
					        </template>

					        <template v-slot:item.folio_operacion="{ item }">
					          <span 
					            :class="item.folio_operacion ? '' : 'red--text' " 
					            @click="movimiento = item, dialogAddFolio = true" 
					            type="button" 
					          >
					            {{ item.folio_operacion ? item.folio_operacion : 'Click aquí' }}
					          </span>
					        </template>

					        <template v-slot:item.cuenta="{ item }">
					          <span 
					            :class="item.cuenta ? '' : 'red--text' " 
					            @click="movimiento = item, dialogAddFolio = true" 
					            type="button" 
					          >
					            {{ item.cuenta ? item.cuenta : 'Click aquí' }}
					          </span>
					        </template>

					        <template v-slot:item.url_foto="{ item }">
					          <p @click="verImagen(item.url_foto)" type="button" v-if="item.forma_pago != 'EFECTIVO' ">
					            <img :src="url_servidor + '/comprobante-pago/' + item.url_foto" alt="Comprobante" width="45"/>
					          </p>
					        </template>

					        <template v-slot:item.con_adeudo="{ item }">
					          <v-chip
					            small
					            color="primary"
					            @click="abrirDialog(item.con_adeudoA)"
					          >
					            {{ item.con_adeudo }}
					          </v-chip>
					        </template>

					      </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog para ver la imagen -->
    <v-dialog
      v-model="dialgImagen"
      max-width="600px"
    >
      <v-card class="elevation-0">
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-img
              :src="url_servidor + '/comprobante-pago/' + imagen"
              contain
              max-width="600"
              height="800"
            >
            </v-img>
          </v-col>
        </v-card-text>
        <!-- Guardar la información  -->
        <v-btn
          absolute
          right
          top
          color="grey"
          dark
          fab
          class="elevation-6 mt-8"
          @click="dialgImagen = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn
        >
      </v-card>
    </v-dialog>

    <!-- Dialog para ver agregar datos de las tarjetas, folio, operación, cuenta -->
    <v-dialog
      v-model="dialogAddFolio"
      v-if="movimiento"
      max-width="350px"
    >
      <v-card class="elevation-0">
        <v-card-title primary-title class="text-subtitle-1">
          Agrgear Datos Bancarios
        </v-card-title>

        <v-card-text>
          <label>Aut. Clave Rastreo</label>
          <v-text-field
            filled
            dense
            single-line
            v-model="movimiento.aut_clave_rastreo"
          ></v-text-field>

          <label>Folio Oper.</label>
          <v-text-field
            filled
            dense
            single-line
            v-model="movimiento.folio_operacion"
          ></v-text-field>

          <label>Cuenta</label>
          <v-text-field
            filled
            dense
            single-line
            v-model="movimiento.cuenta"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          
          <!-- Guardar la información  -->
          <v-btn
            color="black"
            dark
            small
            tile
            @click="dialogAddFolio = false, movimiento = null"
          >
            <v-icon left small>mdi-close</v-icon>
            Cerrar
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            tile
            small
            @click="save()"
            color="green"
            dark
          >
            <v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>     

        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />

    <IngresosCorte v-if="dialogIngresosCorte.estatus" :dialogIngresosCorte="dialogIngresosCorte" />

  </v-container>
</template>
<script>
import axios from 'axios';

import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga  from "@/components/alertas/carga.vue";

import MisMovimientos           from "@/components/corte/MisMovimientos.vue";
import MovimientosOtraSucursal  from "@/components/corte/MovimientosOtraSucursal.vue";
import MovimientosCorte         from "@/components/corte/MovimientosCorte.vue";
import IngresosCorte            from "@/components/corte/IngresosCorte.vue";

import validarErrores           from "@/mixins/validarErrores";

import XLSX from "xlsx";

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default {
  components: {
    Alerta,
    carga,
    MisMovimientos,
    MovimientosOtraSucursal,
    MovimientosCorte,
    IngresosCorte
  },

  mixins: [ validarErrores ],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    fecha_corte: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    planteles: [],
    plantel: null,
    fullscreen: false, 
    dialogCifras: false,
    url_servidor: null,
    fullscreen: false,
    dialogDiferencias:false,

    url_servidor: null,             //Angel Rodriguez                      
    dialgImagen: false,
    imagen: null,

    headersDos: [
      { text: "id_ingreso"         , value: "id_ingreso"        },
      { text: "Alumno"             , value: "alumno"            },
      { text: "Monto pagado"       , value: "monto_pagado"      },
      { text: "Fecha"              , value: "fecha_pago"        },
      { text: "Forma pago"         , value: "forma_pago"        },
      { text: "Registró"           , value: "nombre_completo"   },
      { text: "Plantel"            , value: "plantel"           },
      { text: "Ciclo"              , value: "ciclo"             },
      { text: "Aut. Clave rastreo" , value: "aut_clave_rastreo" },
      { text: "Folio Oper."        , value: "folio_operacion"   },
      { text: "Cuenta"             , value: "cuenta"            },
      { text: "Comprobante"        , value: "url_foto"          }, //Angel Rodriguez
      { text: "Aceptado"           , value: "aceptado"          },
    ],

    diferencias:[],

    tablaPagosSucursal: [],
    desglose: [],

    ciclosUnicos: [],
    ciclo: null,
    searchMovimientos:'',

    expand: false,

    page: 1,
    pageCount: 0,
    itemsPerPage: 10,

    cicloDiferencia:null,

    tablaMovimientos:[],
    dialogIngresosCorte: {
      estatus: false
    },

    movimiento: null,
    dialogAddFolio: false,
    aut_clave_rastreo:'',
    folio_operacion:'',
    cuenta:'',
    pago: null,
    dialogEditPago: false,

    pagocompleto: null,

    tipos_pago:[
      { id: 1, tipo: 'EFECTIVO'},
      { id: 2, tipo: 'TARJETA'},
      { id: 3, tipo: 'DEPOSITO'},
      { id: 0, tipo: 'TODO' },
    ],
		formaPago: 0,
		opEstatus: 4,
		fecha_inicio:null,
    fecha_final:null,
  }),

  watch: {
    plantel(value) {
      if (value && this.fecha_corte) {
        this.consultar();
      }
    },

    fecha_corte(value) {
      if (value && this.plantel) {
        this.consultar();
      }
    },

  },

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),

    filterCorte(){

      let data = this.tablaMovimientos

      // Filtro por forma de pago
      if( this.formaPago ){
        data = data.filter( el => { return el.id_forma_pago == this.formaPago })
      }

      // Filtro por ciclo
      if( this.ciclo ){
        data = data.filter( el => { return el.id_ciclo == this.ciclo })
      }


      if( this.opEstatus == 1 ){
        data = data.filter( el => { return el.aceptado == 1 })
      }

      if( this.opEstatus == 2 ){
        data = data.filter( el => { return el.aceptado == 2 })
      }

      if( this.opEstatus == 3 ){
        data = data.filter( el => { return !el.aceptado })
      }

      // Filtro por forma de pago
      if( this.formaPago == 2){
        data = data.filter( el => { return [4].includes( el.id_forma_pago ) })
      }

      // Filtro por forma de pago
      if( this.formaPago == 3){
        data = data.filter( el => { return [2].includes( el.id_forma_pago ) })
      }

      // Filtro por forma de pago
      if( this.formaPago == 1){
        data = data.filter( el => { return [1].includes( el.id_forma_pago ) })
      }

      if( this.opEscuela == 1 ){
        data = data.filter( el => { return !el.plantel.match('FAST') })
      }

      if( this.opEscuela == 2 ){
        data = data.filter( el => { return el.plantel.match('FAST') })
      }

      return data 
    },

  },

  created() {
    if( [18, 19, 17].includes(this.getdatosUsuario.idpuesto) ){
      this.plantel = this.getdatosUsuario.id_plantel
    }
    this.initialize();
    this.url_servidor = "https://escuelakpi.club/kpi/";
  },

  methods: {
    initialize() {
      this.cargar = true;
      this.planteles = [];
      this.$http.get("kpi.planteles.all").then((response) => {
        if ([18, 19, 17].includes(this.getdatosUsuario.idpuesto)) {
          this.planteles = response.data.filter((el) => {
            return el.id_plantel == this.getdatosUsuario.id_plantel;
          });
        } else {
          this.planteles = response.data;
        }

        this.cargar = false;
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => { this.cargar = false; });
    },

    consultar() {
      this.desglose               = [];
      this.cargar                 = true;

     const payload = {
        id_ciclo: this.ciclo,
        fecha_inicio: this.fecha_inicio,
        fecha_final: this.fecha_final,
      }

      return this.$http.post("movimientos.corte", payload ).then((response) => {

        this.tablaMovimientos        = response.data

      	let puesto          = this.getdatosUsuario.idpuesto
				let mapIdPlanteles  = this.getdatosUsuario.planteles.map((registro) => registro.idplantel);

        console.log( mapIdPlanteles )
        // María Esther Segura Canales
        if( this.getdatosUsuario.iderp == 568 ){
          puesto         = 'COORDINADOR ZONA' 
          mapIdPlanteles = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,10002,10003,10004] 
        }

				// Sofía Lizeth ARIAS
				if( this.getdatosUsuario.iderp == 626 ){
					puesto         = 'COORDINADOR ZONA' 
					mapIdPlanteles = [32,21,4,6] 
				}

		    if( this.getdatosUsuario.iderp == 7 ){
					puesto         = 'COORDINADOR ZONA' 
		    	mapIdPlanteles = [29] 
		    }

		    if( this.getdatosUsuario.iderp == 1312 ){
					puesto         = 'COORDINADOR ZONA' 
		    	mapIdPlanteles = [27,5,31,17] 
		    }

		    // Patricia Salazar
		    if( this.getdatosUsuario.iderp == 161 ){
					puesto         = 'COORDINADOR ZONA' 
		    	mapIdPlanteles = [14,8,12,10,9, 16] 
		    }

		    // PIEDAD LOPEZ 
		    if( this.getdatosUsuario.iderp == 1248 ){
					puesto         = 'COORDINADOR ZONA' 
		    	mapIdPlanteles = [11,7,8,10] 
		    }

		    // María Isabel Rodríguez Mora
		    if( this.getdatosUsuario.iderp == 1196 ){
					puesto         = 'COORDINADOR ZONA' 
		    	mapIdPlanteles = [2,3,5] 
		    }

		    // fernanda oliva espinosa
		    if( this.getdatosUsuario.iderp == 1489 ){
					puesto         = 'COORDINADOR ZONA' 
		    	mapIdPlanteles = [25,28,27,31,17] 
		    }

		    // fernanda oliva espinosa
		    if( this.getdatosUsuario.iderp == 1312 ){
					puesto         = 'COORDINADOR ZONA' 
		    	mapIdPlanteles = [24,1,23,22] 
		    }

		    if( [18, 19, 17].includes(this.getdatosUsuario.idpuesto) ){

		    	this.tablaMovimientos = this.tablaMovimientos.filter( el => mapIdPlanteles.includes( el.id_plantel_ingreso ) )

		    }


        
        this.cargar                  = false;

        for( const i in this.tablaMovimientos ){
          let { monto_pagado } = this.tablaMovimientos[i]
          this.tablaMovimientos[i].monto_pagado      = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( monto_pagado )
        }

        for( const i in this.desglose ){
          let { desglose, total } = this.desglose[i]

          for( const j in desglose ){
            let { monto_pagado } = desglose[j]
            this.desglose[i].desglose[j].monto_pagado      = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( monto_pagado )
          }

          this.desglose[i].total      = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( total )
        }

      }).catch((error) => {
        this.validarError(error);
      }).finally(() => { this.cargar = false; });
    },

    abrirDialog(value) {
      this.alumnos = value;
      this.dialogCifras = true;
    },

    save ( data ) {
      this.cargar = true;

      const payload = {
        id_ingreso:        this.movimiento.id_ingreso,
        aut_clave_rastreo: this.movimiento.aut_clave_rastreo,
        folio_operacion:   this.movimiento.folio_operacion,
        cuenta:            this.movimiento.cuenta,
        aceptado: 0,
      }

      if( !this.movimiento.aut_clave_rastreo || !this.movimiento.folio_operacion || !this.movimiento.cuenta ){

        return this.validarErrorDirecto('Favor de llenar todos los datos bancarios')

      }

      return this.$http.post("ingreso.clave.rastreo", payload ).then((response) => {

        this.movimiento = null
        this.dialogAddFolio = false
        this.validarSuccess(response.data.message)

        this.$emit('consultar')

        this.cargar = false;

      }).catch((error) => {

        this.validarError(error);

      }).finally(() => {

        this.cargar = false;

      });
    },

    verImagen(value) {            //Angel Rodriguez              
      this.imagen = value;
      this.dialgImagen = true;
    },

  },
};
</script>