<template>
  <v-container class="mt-4 ml-2">
    <v-snackbar
      v-model="snackbar"
      top
      :timeout="3000"
      color="error"
    >
      Selecciona los ciclos porfavor 
      <v-btn text color="white" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-row>
      <v-col cols="12">
        <v-card class="">
          <v-card-title primary-title>
            RI MONTOS INBI
          </v-card-title>
          <v-card-text class="pb-6">
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                  filled
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- SUCURSAL MENOR % RI -->
      <v-col cols="12" md="6" lg="4">
        <v-card class="elevation-6">
          <v-card-title class="align-start pb-0">
            <span class="grey--text">% Menor Ri monto</span>
            <v-spacer></v-spacer>
            <v-btn icon small class="me-n3 mt-n2">
              <v-icon>
                mdi-download
              </v-icon>
            </v-btn>
          </v-card-title>

          <!-- Comparativa del %RI del ciclo -->
          <v-card-text class="my-7">
            <v-row>
              <v-col cols="8" class="pt-0 pb-0">
                <!-- Porcentaje y meta actual -->
                <v-subheader class="pl-0">Plantel</v-subheader>
                <div class="d-flex align-center">
                  <h1 class="text-3xl font-weight-semibold">
                    {{ riMontos.plantel }}
                  </h1>
                </div>
              </v-col>

              <!-- Meta -->
              <v-col cols="4" class="pt-0">
                <v-subheader class="pl-0">%RI</v-subheader>
                <h1 class="text-3xl font-weight-semibold green--text">
                  % {{ riMontos.riMonto }}
                </h1>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- SUCURSAL # ALUMNOS FALTANTES  -->
      <v-col cols="12" md="6" lg="4">
        <v-card class="elevation-6">
          <v-card-title class="align-start pb-0">
            <span class="grey--text">Mayor # Alum. Faltantes</span>
            <v-spacer></v-spacer>
            <v-btn icon small class="me-n3 mt-n2">
              <v-icon>
                mdi-download
              </v-icon>
            </v-btn>
          </v-card-title>

          <!-- Comparativa del %RI del ciclo -->
          <v-card-text class="my-7">
            <v-row>
              <v-col cols="8" class="pt-0 pb-0">
                <!-- Porcentaje y meta actual -->
                <v-subheader class="pl-0">Plantel</v-subheader>
                <div class="d-flex align-center">
                  <h1 class="text-4xl font-weight-semibold">
                    {{ alFaltantes.plantel }}
                  </h1>
                </div>
              </v-col>

              <!-- Meta -->
              <v-col cols="4" class="pt-0">
                <v-subheader class="pl-0">#Alumnos</v-subheader>
                <h1 class="text-4xl font-weight-semibold green--text">
                  # {{ alFaltantes.cant }}
                </h1>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- SUCURSAL % ALUMNOS FALTANTES  -->
      <v-col cols="12" md="6" lg="4">
        <v-card class="elevation-6">
          <v-card-title class="align-start pb-0">
            <span class="grey--text">Mayor % Alum. Faltantes</span>
            <v-spacer></v-spacer>
            <v-btn icon small class="me-n3 mt-n2">
              <v-icon>
                mdi-download
              </v-icon>
            </v-btn>
          </v-card-title>

          <!-- Comparativa del %RI del ciclo -->
          <v-card-text class="my-7">
            <v-row>
              <v-col cols="8" class="pt-0 pb-0">
                <!-- Porcentaje y meta actual -->
                <v-subheader class="pl-0">Plantel</v-subheader>
                <div class="d-flex align-center">
                  <h1 class="text-4xl font-weight-semibold">
                    {{ porcentajeAlumnos.plantel }}
                  </h1>
                </div>
              </v-col>

              <!-- Meta -->
              <v-col cols="4" class="pt-0">
                <v-subheader class="pl-0">%Alumnos</v-subheader>
                <h1 class="text-4xl font-weight-semibold green--text">
                  % {{ porcentajeAlumnos.cant }}
                </h1>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers"
          :items="dataTable"
          class="elevation-6"
          hide-default-footer
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'

  export default {
    data: () => ({
      snackbar:false,
      headers: [
        { text: '# AL'        , value: 'cantAlumnos' },
        { text: '# AL RI'     , value: 'cantAlumnosRI' },
        { text: '# AL FALT'   , value: 'cantAlumnosFaltan' },
        { text: '% AL FALT'   , value: 'procentajeAlFanta' },
        { text: '# AL NI'     , value: 'cantAlumnosNI' },
        { text: 'Contactos'   , value: 'contactos' },
        { text: 'Leads'       , value: 'leads' },
      ],
      ciclos:[],
      cicloInf:null,
      cicloSup:null,

      riMontos: {},
      dataTable:[],
      alFaltantes:{},
      porcentajeAlumnos:{}
    }),

    watch: {
      cicloInf () {
        if(this.cicloInf){
          var num_search = parseInt(this.cicloInf.ciclo.substr(5,3)) + 1
          for(const i in this.ciclos){
            var cadena_search = this.ciclos[i].ciclo.substr(0,8)
            if (cadena_search.search(num_search) != -1) {
              this.cicloSup = this.ciclos[i]
            }
          }

          if(this.cicloSup){
            this.consultar()
          }
        }
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.ciclos = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
        }).catch(error=>{console.log(error)})
      },

      consultar () {
        const payload = {
          id_inicio_inbi: this.cicloInf.id_ciclo,
          id_inicio_fast: this.cicloInf.id_ciclo_relacionado,
          id_fin_inbi:    this.cicloSup.id_ciclo ? this.cicloSup.id_ciclo : 178,
          id_fin_fast:    this.cicloSup.id_ciclo_relacionado ? this.cicloSup.id_ciclo_relacionado : 178,
          escuela:        1
        }

        this.$http.post('data.rimontos.kpi', payload).then(response=>{
          this.riMontos          = response.data.riMontos.inbi
          this.dataTable         = response.data.dataTable
          this.alFaltantes       = response.data.cantAlumnos.inbi
          this.porcentajeAlumnos = response.data.porcentajeAlumnos.inbi
        }).catch(error=>{console.log(error)})
      },

      exportar(){
        let xls = {
          data:      this.resultado,
          tHeaders : ['Codigo','Nombre', 'Sal', 'Labortorio'],
          filterVal: ['codigo','nomart', 'sal', 'lab'],
          nomfile :  "Productos"
        }
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let data = XLSX.utils.json_to_sheet(this.resultado)
        const workbook = XLSX.utils.book_new()
        const filename = 'KPI'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },

      
    },
  }
</script>