<template>
  <v-container class="mt-4 ml-2">
    <v-row>
    	<v-col cols="12">
    		<v-card class="shadowCard">
    			<v-card-title primary-title>
    			  <span class="text-subtitle-1">Cambios de grupo</span>
    			  <v-spacer></v-spacer>

            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( alumnos  , 'CAMBIOS_GRUPOS')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="getAlumnosxEscuela()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
            
    			</v-card-title>

    			<v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="10" lg="6">
              	<v-text-field
				          label="Buscar"
				          v-model="search"
				          dense
                  append-icon="mdi-magnify"
				          hide-details
				          filled
				        ></v-text-field>
              </v-col>
            </v-row>

				    <v-data-table
				      :headers="headers"
				      :items="alumnos"
              dense
				      :search="search"
				    >
	            <template v-slot:item.opciones="{ item }">
	              <v-btn color="orange" dark x-small @click="addDialogCambio(item)"><v-icon>mdi-swap-horizontal</v-icon></v-btn>
	            </template>
				    </v-data-table>
    			</v-card-text>
    		</v-card>
    	</v-col>
    </v-row>

    <!-- Dialogo para agregar un cambio -->
    <v-dialog
      v-model="dialogAddCambio"
      persistent
      max-width="800"
      v-if="alumno"
    >
      <v-card>
        <v-card-title class="text-h5">
          Realizar cambio de grupo
        </v-card-title>
        <v-card-text>
          <b>Matricula:</b> {{ alumno.matricula }}
          <br/>
          <b>Nombre:</b>    {{ alumno.alumno }}
          <br/>
          <v-divider></v-divider>
          <br/>
          <b>Cambio a:</b>
          <br/>
          <v-row>
            <v-col cols="12" md="6" >
              <v-autocomplete
                class="mt-2"
                outlined
                dense
                clearable
                v-model="grupoAnterior"
                :items="grupoAlumnos"
                label="Grupo actual"
                persistent-hint
                prepend-inner-icon="mdi-clipboard-outline"
                return-object
                item-text="grupo"
                item-value="id_grupo"
              >
              </v-autocomplete>
              <div v-if="grupoAnterior" class="mb-10">
              	<v-row>
              		<v-col cols="6" class="text-right pb-0">
              			<b>Costo curso:</b>
              		</v-col>
              		<v-col cols="6" class="pb-0">
              			{{ grupoAnterior.precio_grupo }}
              		</v-col>

              		<v-col cols="6" class="text-right pb-0">
              			<b>Descuento pronto pago:</b>
              		</v-col>
              		<v-col cols="6" class="pb-0">
              			{{ grupoAnterior.descuento }}
              		</v-col>

              		<v-col cols="6" class="text-right pb-0">
              			<b>Total a pagar:</b>
              		</v-col>
              		<v-col cols="6" class="pb-0">
              			{{ grupoAnterior.precio_grupo_con_descuento_x_alumno }}
              		</v-col>

              		<v-col cols="6" class="text-right pb-0">
              			<b>Pagado:</b>
              		</v-col>
              		<v-col cols="6" class="pb-0">
              			{{ grupoAnterior.pagado }}
              		</v-col>
              	</v-row>
              </div>
            </v-col>

            <v-col cols="12" md="6" >
              <v-autocomplete
                class="mt-2"
                outlined
                dense
                clearable
                v-model="nuevoGrupo"
                :items="grupos"
                label="Nuevo Grupo"
                persistent-hint
                prepend-inner-icon="mdi-clipboard-outline"
                return-object
                item-text="grupo"
                item-value="id_grupo"
              >
              </v-autocomplete>
              <div v-if="nuevoGrupo" class="mb-10">
              	<v-row>
              		<v-col cols="6" class="text-right pb-0">
              			<b>Costo curso:</b>
              		</v-col>
              		<v-col cols="6" class="pb-0">
              			{{ costoCurso }}
              		</v-col>

              		<v-col cols="6" class="text-right pb-0">
              			<b>Descuento pronto pago:</b>
              		</v-col>
              		<v-col cols="6" class="pb-0">
              			{{ costoCurso - total_a_pagar }} 
              		</v-col>

              		<v-col cols="6" class="text-right pb-0">
              			<b>Total a pagar:</b>
              		</v-col>
              		<v-col cols="6" class="pb-0">
              			{{ total_a_pagar }}
              		</v-col>

              		<v-col cols="6" class="text-right pb-0">
              			<b>Pagado:</b>
              		</v-col>
              		<v-col cols="6" class="pb-0">
              			{{ grupoAnterior.pagado }}
              		</v-col>

                  <v-col cols="6" class="text-right pb-0">
                    <b>Adeudo:</b>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    {{ ( total_a_pagar - grupoAnterior.pagado ) >= 0 ? ( total_a_pagar - grupoAnterior.pagado ) : 0 }} 
                  </v-col>

                  <v-col cols="12" class="pb-0">
                    <v-autocomplete
                      v-model="precioSeleccionado"
                      :items="precios"
                      item-text="curso"
                      item-value="id_curso"
                      filled
                      dense
                    ></v-autocomplete>
                  </v-col>

              	</v-row>
              </div>
            </v-col>
          </v-row>

          <b>Tipo de cambio:</b>
          <br/>
          <v-autocomplete
            class="mt-2"
            outlined
            dense
            clearable
            v-model="tipoCambio"
            :items="tipocambios"
            label="Selecciona tipo de cambio"
            persistent-hint
            prepend-inner-icon="mdi-clipboard-outline"
            hide-details
            return-object
            item-text="tipocambio"
            item-value="idtipocambio"
          >
          </v-autocomplete>

          <v-alert
            dense
            border="left"
            type="warning"
            class="mt-2"
          >
            Recuerda que al hacer un  <strong>CAMBIO</strong> de grupo, al alumno se le  <strong>BORRARÁN</strong> los datos, como lo son: <strong>ASISTENCIAS, EJERCICIOS y EXÁMENES</strong>
          </v-alert>
        </v-card-text>

        <!-- Botones -->
        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            @click="dialogAddCambio = false"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="nuevoGrupo && tipoCambio && grupoAnterior"
            color="green"
            @click="addCambio()"
            :loading="loading"
            :disabled="disabled" 
            dark
            tile
          >
            <v-icon small left>mdi-content-save</v-icon>
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
		
		<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search: '',
      headers: [
        { text: 'id alumno',   value: 'id_alumno' },
        { text: 'Nombre',      value: 'alumno' },
        { text: 'Matricula',   value: 'matricula' },
        // { text: 'Grupo',       value: 'grupo' },
        // { text: 'Plantel',     value: 'plantel' },
        // { text: 'Nivel',       value: 'nivel' },
        { text: 'Opciones',    value: 'opciones' },
      ],

      teachers: [],

      overlay:false,
      dialogError: false,
      error:'',

      tipociclo:      '',
      grupos:         [],
      nuevoGrupo:     null,
      grupoAlumnos:   [],
      grupoAnterior:  null,

      tipocambios:    [],
      tipoCambio:     null,
      
      alumnos:        [],
      alumno:         null,

      dialogAddCambio:false,
      dialogSuccess:  false,
      successMensaje: '',
      loading:        false,
      disabled:       false,
      precios: [],
      precioSeleccionado: null,

      total_a_pagar: 0

    }),

    watch:{
      async grupoAnterior () {
        if(this.grupoAnterior){
        	console.log( this.grupoAnterior )
          await this.getNuevoGrupoAlumno()
          // await this.getCursoAlumno()
        }else{
          this.grupos = []
        }
      },

      nuevoGrupo( value ){
        this.precioSeleccionado = value ? value.id_curso : null
      },

      precioSeleccionado( value ){
        if( value ){ this.getPrecioPagar() }
      }
    },

    computed:{
      ...mapGetters ('login',['getdatosUsuario']),

      costoCurso( ){
        
        if( this.precioSeleccionado ){

          let existeCosto = this.precios.find( el => el.id_curso == this.precioSeleccionado )

          return existeCosto ? existeCosto.precio_lista : 0

        }else{
          return 0
        }

      }
    },

    async created () {
      console.clear()
      // Primero necesitamos consultar el tipo de usuario y de donde viene
      await this.getTipoUsuarioEscuela()

      // Ahora si consultamos los grupos por tipo de escuela
      // await this.getGrupos()

      // Ahora traemos los tipos de cambio
      await this.getTitpoCambios()
    	
      // Ahora traemos los alumnos por escuela
      await this.getAlumnosxEscuela()


      // Cargar los cursos actuales
      await this.getCursos()

    },

    methods: {
      getTipoUsuarioEscuela () {
        const id_plantel = this.getdatosUsuario.id_plantel

        if([1,2,3,4,5,6,17,19].includes( id_plantel )){
          this.tipociclo = `ciclo NOT LIKE '%FE%'`
        }else{ this.tipociclo = `ciclo LIKE '%FE%'` }
        return 
      },

      getCursos( ){
        this.cargar = true
        this.precios = []
        this.$http.get("all.precios").then((response) => {
          this.cargar = false
          this.precios = response.data.filter( el => el.curso.split("-")[1].match("3") );
        }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });
      },

      getPrecioPagar( ) {

        const payload = {
          id_alumno: this.alumno.id_alumno,
          id_grupo:  this.alumno.id_grupo,
          escuela:   this.alumno.grupo.match('FAST') ? 2 : 1,
          id_curso:  this.precioSeleccionado,
          id_ciclo:  this.alumno.id_ciclo,
          alumno_ni: false
        }

        return this.$http.post('pago.grupo.nuevo.descuento', payload).then(response=>{

          this.total_a_pagar = response.data[0].precio_lista

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      updateAdeudo( ){
        this.cargar = true

        const payload = {
          adeudo                    : ( this.total_a_pagar - this.grupoAnterior.pagado ) < 0 ? 0 : parseFloat(this.total_a_pagar - this.grupoAnterior.pagado),
          id_alumno                 : this.alumno.id_alumno,
          id_grupo                  : this.alumno.id_grupo,
          precio_grupo_con_descuento:  this.total_a_pagar
        }

        this.$http.post('reporte.montopago.2', payload ).then( response => {
          this.validarSuccess( response.data.message )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getNuevoGrupoAlumno () {
        if(this.grupoAnterior){
          
          const payload =  { 
            tipociclo: this.tipociclo, 
            id_ciclo : this.grupoAnterior.id_ciclo 
          }

          return this.$http.post('cambios.nuevo.grupos.ciclo.prueba',payload).then(response=>{
            this.grupos = response.data
            console.log( this.grupos )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }
      },

      getTitpoCambios () {
        return this.$http.get('cambios.tipo.cambio').then(response=>{
          this.tipocambios = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getAlumnosxEscuela () {
        // Vaciamos el arreglo de los grupos
        this.alumnos = []
        // Activamos el loader
        this.overlay = true
        const payload =  { tipociclo: this.tipociclo }
        return this.$http.post('cambios.alumnos.grupos.prueba',payload).then(response=>{
          // Limpiamos todo en caso de ser necesario
          this.limpiar () 
          // Llenamos el arreglo de alumnos
          this.alumnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async addDialogCambio (alumno) {
        await this.getGrupoUsuario(alumno.id_alumno)
        this.alumno = alumno
        this.dialogAddCambio = true
      },

      getGrupoUsuario ( idalumno ){
        this.cargar = true
        this.grupoAlumnos = []
        return this.$http.get('cambios.grupo.usuario.prueba/' + idalumno).then(response=>{
          this.grupoAlumnos = response.data
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      addCambio () {
        // Validar que esten llenos los campos
        if(this.nuevoGrupo && this.tipoCambio && this.grupoAnterior){

          // Activar el disabled del boton para que no le den doble click
          this.loading  = true
          this.disabled = true
          this.overlay  = true

          // Desesestructuración de alumno 
          const { id_alumno, id_plantel, monto_pagado_total } = this.alumno

          // Generar el payload a mandar
          let payload = {
            id_alumno,
            idtipo_cambio:          this.tipoCambio.idtipocambio,
            solicito_cambio:        this.getdatosUsuario.iderp,
            id_plantel,
            id_grupo:               this.grupoAnterior.id_grupo,
            id_grupo_nuevo:         this.nuevoGrupo.id_grupo,
            estatus:                1,
            diferencia:             monto_pagado_total,
            id_ciclo_nuevo_grupo:   this.nuevoGrupo.id_ciclo,
            pago_grupo_actual:      this.grupoAnterior.pagado,
            descuento_grupo_actual: this.grupoAnterior.descuento,
            precio_grupo:           this.grupoAnterior.precio_grupo,
            precio_descuento:       this.grupoAnterior.precio_grupo_con_descuento_x_alumno,
            iderp:                  this.getdatosUsuario.iderp,

            adeudo                    : ( this.total_a_pagar - this.grupoAnterior.pagado ) < 0 ? 0 : parseFloat(this.total_a_pagar - this.grupoAnterior.pagado),
            precio_grupo_con_descuento:  this.total_a_pagar,
            id_curso                  : this.precioSeleccionado,
          }

          // Enviar los datos 
          this.$http.post('cambios.grupo',payload).then(response=>{
            this.validarSuccess('Cambio realizado con exito')
            this.updatePrecioAlumno()
          }).catch( error =>{
            this.loading  = false
            this.disabled = false
            this.overlay  = false
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }else{
          this.estatusError('Favor de ingresar el grupo y el tipo de cambio')
        }
      },

      async updatePrecioAlumno() {
        this.cargar = true;

        const payload = {
          id_curso: this.precioSeleccionado,
          id_alumno: this.alumno.id_alumno,
          id: this.alumno.id_alumno
        };

        this.$http.post("update.precio.alumno", payload).then(response => {
          this.validarSuccess(response.data.message);
          this.limpiar()
        }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });
      },

      limpiar () {
        this.loading         = false
        this.disabled        = false
        this.nuevoGrupo      = null
        this.tipoCambio      = null
        this.overlay         = false
        this.dialogAddCambio = false
        this.grupos          = []
        this.grupoAlumnos    = []
      },

      estatusError ( mensaje ) {
        this.error = mensaje
        this.dialogError = true
        this.overlay = false
      }
    },
  }
</script>

<style>

  .v-menu__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-menu__content::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-button:increment,.v-menu__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-menu__content::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>