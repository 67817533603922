<template>
	<div>
	  <v-text-field
      label="Nombre"
      v-model="prospecto.nombre_completo"
      dense
    ></v-text-field>

    <v-text-field
      label="Teléfono"
      v-model="prospecto.telefono"
      dense
    ></v-text-field>

    <v-text-field
      label="Folio"
      v-model="prospecto.folio"
      readonly
      dense
    ></v-text-field>

    <v-text-field
      label="Correo"
      v-model="prospecto.correo"
      dense
    ></v-text-field>

    <v-text-field
      label="Nota"
      v-model="prospecto.nota_inicial"
      readonly
      dense
      hide-details
    ></v-text-field>

    <v-switch
      v-model="prospecto.induccion"
      color="primary"
      label="En Inducción"
    ></v-switch>

    <v-text-field
      v-if="prospecto.induccion"
      label="¿Cuándo tomará inducción?"
      v-model="prospecto.fecha_induccion"
      dense
      type="date"
      width="200"
    ></v-text-field>
	</div>
</template>


<script>
import axios from 'axios';
	export default {
		props:[
			'prospecto'
	  ],
	}
</script>