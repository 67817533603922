<template>
  <v-container class="mt-4 px-5" fluid>

    <v-row v-if="( getdatosUsuario.idpuesto == 18 && [568, 7, 161, 382, 626].includes( getdatosUsuario.iderp ) ) || getdatosUsuario.idpuesto != 18">
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Tickets</span>
            <v-divider class="mx-4" inset vertical></v-divider>
              <v-radio-group v-model="escuela" row>
                <v-radio label="Todos" :value="0"></v-radio>
                <v-radio label="Fast" :value="2"></v-radio>
                <v-radio label="Inbi" :value="1"></v-radio>
              </v-radio-group>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-checkbox
              v-model="urgente"
              :label="`Urgente`"
            ></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn 
              class="mr-2" 
              small dark color="primary"
              @click="getReporteTicketsUsuario()"
              tile
            >
              <v-icon left small>mdi-file</v-icon>
              R. Total
            </v-btn>

            <v-btn 
              class="mr-2" 
              small dark color="teal accent-3"
              @click="reporteFechas.status = true"
              tile
            >
              <v-icon left small>mdi-file</v-icon>
              R. Rango Fechas
            </v-btn>

            <v-btn 
              class="mr-2" 
              small dark color="red"
              @click="getReportePorResponsable()"
              tile
            >
              <v-icon left small>mdi-file</v-icon>
              R RESPONSABLE
            </v-btn>

            <v-btn
              color="orange"
              dark
              @click="initialize"
              small
              tile
            >
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-actions>
            <v-row justify="end">
              <v-col cols="12" md="6" lg="4">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="usuario"
                  :items="usuariosTickets"
                  label="Selecciona un usuario"
                  persistent-hint
                  prepend-inner-icon="mdi-account"
                  hide-details
                  item-text="nombre_completo"
                  item-value="nombre_completo"
                >
                </v-autocomplete>
              </v-col>
              
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  label="Buscar"
                  v-model="search"
                  id="id"
                  hide-details
                  filled
                  dense
                  append-icon="mdi-magnify"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-radio-group
                  v-model="estatusFiltro"
                  row
                  dense
                  hide-details
                  small
                >
                  <v-radio
                    color="orange"
                    label="Abierto"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    color="blue"
                    label="Asignado"
                    :value="2"
                  ></v-radio>
                  <v-radio
                    color="red"
                    label="Pendiente"
                    :value="3"
                  ></v-radio>
                  <v-radio
                    color="grey"
                    label="Revisión"
                    :value="4"
                  ></v-radio>
                  <v-radio
                    color="green"
                    label="Respondido"
                    :value="5"
                  ></v-radio>
                  <v-radio
                    color="yellow"
                    label="Cerrado"
                    :value="6"
                  ></v-radio>
                  <v-radio
                    color="black"
                    label="Rechazado"
                    :value="7"
                  ></v-radio>
                  <v-radio
                    label="Todos"
                    :value="8"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-actions>

          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="filterTickets"
                  :item-class="itemRowBackground"
                  sort-by="calories"
                  class="elevation-0"
                  :search="search"
                  item-key="name"
                  fixed-header
                  :height="tamanioPantalla"
                  dense
                  multi-sort
                >
                  <template v-slot:item.estatus="{ item }">
                    <v-chip small :color="getColor(item.estatus)" dark v-if="item.estatus==1"> Abierto </v-chip>
                    <v-chip small :color="getColor(item.estatus)" dark v-if="item.estatus==2"> Asignado </v-chip>
                    <v-chip small :color="getColor(item.estatus)" dark v-if="item.estatus==3"> Pendiente </v-chip>
                    <v-chip small color="grey"   dark v-if="item.estatus==4"> Revisión </v-chip>
                    <v-chip small color="green"  dark v-if="item.estatus==5"> Respondido </v-chip>
                    <v-chip small color="yellow"      v-if="item.estatus==6"> Cerrado </v-chip>
                    <v-chip small color="black"  dark v-if="item.estatus==7"> Rechazado </v-chip>
                  </template>

                  <template v-slot:item.id_unidad_negocio="{ item }">
                    <v-chip small v-if="item.id_unidad_negocio==1" color="#2EDAD5"> INBI </v-chip>
                    <v-chip small v-else color="#000D4A" dark> FAST </v-chip>
                  </template>

                  <template v-slot:item.visorrh="{ item }">
                    <v-checkbox small dense hide-details v-model="item.visorrh" @click="updateVisorRh(item)"></v-checkbox>
                  </template>

                  <template v-slot:item.historial="{ item }">
                    <v-btn color="primary" small rounded @click="historialTicket(item)" icon><v-icon>mdi-file-document</v-icon></v-btn>
                  </template>

                  <template v-slot:item.fecha_entrega="{ item }">
                    <v-chip 
                      v-if="item.fecha_entrega" 
                      small 
                      dark
                      :color="item.diferencia_dias < 0 && item.estatus != 6 ? 'red' : 'grey' "
                    >
                      {{ item.fecha_entrega }}
                    </v-chip>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn color="success" small rounded @click="verMotivo(item)" v-if="!item.nombre_jefe">Asignar</v-btn>
                  </template>
                  <template v-slot:no-data>
                    <v-btn
                      color="primary"
                      @click="initialize"
                      rounded
                      small
                    >
                      Reset
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Asignar ticket -->
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="12">
                <v-textarea outlined v-model="ticket.motivo" label="Motivo de tu ticket" hide-details readonly></v-textarea>
              </v-col>

              <v-col cols="12" md="6">
                <v-autocomplete
                  outlined
                  dense
                  :items="areas"
                  v-model="area"
                  label="Selecciona departamento"
                  return-object
                  item-value="iddepartamento"
                  item-text="departamento"
                ></v-autocomplete>

                <v-autocomplete
                  outlined
                  dense
                  :items="puestos"
                  v-model="puesto"
                  label="Selecciona un puesto"
                  return-object
                  item-value="idpuesto"
                  item-text="puesto"
                ></v-autocomplete>

                <v-autocomplete
                  outlined
                  dense
                  :items="jefes"
                  v-model="jefe"
                  label="Selecciona un jefe"
                  return-object
                  item-value="id_usuario"
                  item-text="nombre_completo"
                ></v-autocomplete>

                <v-checkbox 
                  small
                  hide-details 
                  v-model="ticket.visorrh" 
                  @click="updateVisorRh(ticket)"
                  label="Generar copia para RH"
                ></v-checkbox>
                
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" lg="4" v-for="(img, i) in ticket.evidencias" :key="i">
                <v-card class="py-4 shadowCard">
                  <v-img :src="urlUser + img.foto" contain aspect-ratio="2"></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" dark rounded small @click="abrirRechazar">
            Rechazar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" rounded small dark @click="save">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Historial del ticket -->
    <v-dialog v-model="dialogHistorial" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Historial</span>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogHistorial=false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text>
          <div>Folio: {{ ticket.idticket }}</div>
          <v-container v-for="(respuestas, i) in historial" :key="i">
            <v-row justify="space-between">
              <v-col cols="4" class="pa-2">
                <p class="ma-0"><strong>Motivo del ticket</strong></p>
              </v-col>
              <v-col cols="4" class="pa-2 d-flex justify-center">
                <div v-if="respuestas.estatus==1" >RESPONDIDO</div>
                <div v-else>SIN RESPONDER</div><br/>
              </v-col>
              <v-col cols="4" class="d-flex justify-end pa-2">
                <p class="ma-0">{{ respuestas.fecha }}</p>
              </v-col>
            </v-row>
            <v-row  class="mt-0">
              <v-col cols="8" class="pa-2">
                <p class="pa-2 mb-0 mensajeIzq">
                  {{ respuestas.motivo }}
                  <br>
                  <span style="font-size: small;">{{ respuestas.fecha }}</span>
                </p>
              </v-col>
            </v-row>
            <v-row class="mb-4">
              <v-col cols="2" v-for="(img, i) in respuestas.evidencias" :key="i">
                <v-card class="py-4 shadowCard" @click="verImagen( img.foto, img.es_solucion )">
                  <v-img v-if="!img.video" :src="(ticket.tipousuario ? (ticket.id_unidad_negocio==1 ? urlINBI : urlFAST) : 
                  ticket.movil ? (ticket.id_unidad_negocio==1 ? urlINBI : urlFAST) : url) + img.foto"  contain aspect-ratio="2"></v-img>
                  <video v-else :src="(ticket.tipousuario ? (ticket.id_unidad_negocio==1 ? urlINBI : urlFAST) : 
                  ticket.movil ? (ticket.id_unidad_negocio==1 ? urlINBI : urlFAST) : url) + img.foto"  contain aspect-ratio="2" 
                  style="width: 100%; height: 100%;" class="d-flex"></video>
                </v-card>
              </v-col>
            </v-row>
            <v-row justify="end" class="mt-0" v-if="respuestas.respuesta">
              <v-col cols="6" class="d-flex justify-end pa-2">
              <p class="ma-0" >Respondió: <strong> {{ respuestas.jefe }} </strong> </p>
              </v-col>
            </v-row>
            <v-row justify="end" class="mt-0" v-if="respuestas.respuesta">
              <v-col cols="8" class="pa-2">
                <p class="pa-2 text-end mensajeDer">
                  {{ respuestas.respuesta }}
                  <br>
                  <span style="font-size: small;" >{{ respuestas.fecharespuesta }}</span>
                </p>
              </v-col>
            </v-row>
            <v-row class="mb-4">
              <v-col cols="2" v-for="(img, i) in respuestas.evidenciasRespuesta" :key="i">
                <v-card class="py-4 shadowCard" @click=" verImagen( img.foto, img.es_solucion )">
                  <v-img v-if="!img.video" :src="url + img.foto" contain aspect-ratio="2"></v-img>
                  <video v-else :src="url + img.foto" contain aspect-ratio="2" style="width: 100%; height: 100%;" class="d-flex"></video>
                </v-card>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- <v-row>

              <v-col cols="12" v-for="(respuestas, i) in historial" :key="i">
                <div>Folio: {{ ticket.idticket }}</div>
                <div>Fecha: {{ respuestas.fecha }}</div>
                <div v-if="respuestas.estatus==1">RESPONDIDO</div>
                <div v-else>SIN RESPONDER</div><br/>
                <v-textarea rows="3" auto-grow outlined v-model="respuestas.motivo" label="Motivo de tu ticket" readonly></v-textarea>-->

                <!-- chip para el nombre del que lo contesto -->
                <!--<v-chip small dense hide-details>
                  Respondió: <strong> {{ respuestas.jefe }} </strong> 
                </v-chip>

                <v-textarea 
                  class="mt-3"
                  rows="3" 
                  auto-grow
                  outlined 
                  v-model="respuestas.respuesta" 
                  label="Solución" 
                  v-if="ver==false" 
                  readonly
                ></v-textarea>

                <v-row>
                  <v-col cols="12" md="6" lg="4" v-for="(img, i) in respuestas.evidencias" :key="i">
                    <v-card class="py-4 shadowCard">
                      <v-img :src="url + img.foto" contain aspect-ratio="2"></v-img>
                    </v-card>
                  </v-col>
                </v-row>

              </v-col>
            </v-row> -->
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogHistorial=false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para responder -->
    <v-dialog v-model="dialogResponder" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Responder Ticket</span>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogResponder=false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text v-for="(respuestas, i) in historial" :key="i">
          <div>Folio: {{ ticket.idticket }}</div>
          <v-row justify="space-between">
              <v-col cols="4" class="pa-2">
                <p class="ma-0"><strong>Motivo del ticket</strong></p>
              </v-col>
              <v-col cols="4" class="pa-2 d-flex justify-center">
                <div v-if="respuestas.estatus==1" >RESPONDIDO</div>
                <div v-else>SIN RESPONDER</div><br/>
              </v-col>
              <v-col cols="4" class="d-flex justify-end pa-2">
                <p class="ma-0">{{ respuestas.fecha }}</p>
              </v-col>
            </v-row>
            <v-row  class="mt-0">
              <v-col cols="8" class="pa-2">
                <p class="pa-2 mb-0 mensajeIzq">
                  {{ respuestas.motivo }}
                  <br>
                  <span style="font-size: small;">{{ respuestas.fecha }}</span>
                </p>
              </v-col>
            </v-row>
            <v-row class="mb-4">
              <v-col cols="2" v-for="(img, i) in respuestas.evidencias" :key="i">
                <v-card class="py-4 shadowCard" @click="verImagen( img.foto, img.es_solucion )">
                  <v-img v-if="!img.video" :src="(ticket.tipousuario ? (ticket.id_unidad_negocio==1 ? urlINBI : urlFAST) : 
                  ticket.movil ? (ticket.id_unidad_negocio==1 ? urlINBI : urlFAST) : url) + img.foto"  contain aspect-ratio="2"></v-img>
                  <video v-else :src="(ticket.tipousuario ? (ticket.id_unidad_negocio==1 ? urlINBI : urlFAST) : 
                  ticket.movil ? (ticket.id_unidad_negocio==1 ? urlINBI : urlFAST) : url) + img.foto"  contain aspect-ratio="2" 
                  style="width: 100%; height: 100%;" class="d-flex"></video>
                </v-card>
              </v-col>
            </v-row>
            <v-row justify="end" class="mt-0" v-if="respuestas.respuesta">
              <v-col cols="8" class="pa-2">
                <p class="pa-2 text-end mensajeDer">
                  {{ respuestas.respuesta }}
                  <br>
                  <span style="font-size: small;" >{{ respuestas.fecharespuesta }}</span>
                </p>
              </v-col>
            </v-row>
            <v-row class="mb-4">
              <v-col cols="2" v-for="(img, i) in respuestas.evidenciasRespuesta" :key="i">
                <v-card class="py-4 shadowCard" @click="verImagen( img.foto, img.es_solucion )">
                  <v-img v-if="!img.video" :src="url + img.foto" contain aspect-ratio="2"></v-img>
                  <video v-else :src="url + img.foto" contain aspect-ratio="2" style="width: 100%; height: 100%;" class="d-flex"></video>
                </v-card>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            
          <!-- <div>Folio: {{ ticket.idticket }}</div>
          <div>Fecha: {{ respuestas.fecha }}</div>
          <div v-if="respuestas.estatus==1">RESPONDIDO</div>
          <div v-else>SIN RESPONDER</div><br/>
          <v-textarea rows="3" outlined v-model="respuestas.motivo" label="Motivo de tu ticket" readonly></v-textarea> -->
          <v-textarea v-if="respuestas.estatus==0" rows="3" outlined v-model="respuesta" label="Solución"></v-textarea>

          <v-file-input
              v-if="respuestas.estatus==0"
              small-chips
              multiple
              filled
              dense
              single-line
              v-model="files"
              accept="image/png, image/jpeg, image/bmp, video/mp4, video/x-m4v, video/*"
              placeholder="Selecciona tus imagenes"
              prepend-icon="mdi-camera"
              label="Imágenes"
              @change="cargarFotos()"
            ></v-file-input>

            <v-row class="mb-4" v-if="respuestas.estatus==0">
              <v-col cols="2" v-for="(img, i) in vistaPrevias" :key="i">
                <v-card class="py-4 shadowCard">
                  <v-img :src="img.url" contain aspect-ratio="2"></v-img>
                  <v-btn color="error" x-small @click="eliminarFoto(img.url)" top right absolute fab>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
          

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              dark
              small
              rounded
              @click="dialogResponder=false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              dark
              small
              rounded
              @click="saveRespuesta(respuestas)"
              v-if="ver==false"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card-text>

      </v-card>
    </v-dialog>


    <!-- Dialogo para responder -->
    <v-dialog v-model="dialogReporte" max-width="1200px">
      <v-card>
        <v-card-title>
          <span class="headline">Reporte de Tickets</span>

          <v-spacer></v-spacer>
          <v-btn 
            class="mr-2" 
            small dark color="green"
            @click="exportExcel( reporte, 'reporte_tickets')"
            tile
          >
            <v-icon small>mdi-microsoft-excel</v-icon>
          </v-btn>
          <v-btn fab small text @click="dialogReporte=false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text >
          <v-data-table
            :headers="headersReporte"
            :items="reporte"
            class="elevation-0"
            dense
            multi-sort
          >
            <template v-slot:item.Bloqueado="{ item }">
              <v-chip
                small
                :color="item.Bloqueado == 1 ? 'red' : 'green' "
                dark
              >
                {{ item.Bloqueado == 1 ? 'Bloqueado' : 'Liberado' }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            dark
            small
            tile
            @click="dialogReporte=false"
          >
            <v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para responder -->
    <v-dialog v-model="dialogResponsable" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Reporte de Tickets</span>

          <v-spacer></v-spacer>
          <v-btn 
            class="mr-2" 
            small dark color="green"
            @click="exportExcel( reporte, 'reporte_tickets')"
            tile
          >
            <v-icon small>mdi-microsoft-excel</v-icon>
          </v-btn>
          <v-btn fab small text @click="dialogResponsable=false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text >
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                label="Responsable"
                :items="reporte"
                v-model="responsable"
                item-value="nombre_completo"
                item-text="nombre_completo"
                filled
                denses
              ></v-select>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headersResponsable"
            :items="ticketsPorResponsable"
            class="elevation-0"
            dense
            multi-sort
          ></v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            dark
            small
            tile
            @click="dialogResponsable=false"
          >
            <v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- Visualizar imagenes -->
    <Imagen @close="closeImagen" :respuestaImagen="es_solucion" :isOpen="dialogImagen" :imagen="imagen" :ticket="ticket"/>

    <ticketFecha
      v-if="reporteFechas.status"
      :reporteFechas="reporteFechas"  
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';

  import { mapGetters } from 'vuex'
  var moment = require('moment');
  moment.locale(); 
  import funcionesExcel  from '@/mixins/funcionesExcel'
  import validarErrores  from '@/mixins/validarErrores'
  
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import Imagen                from '@/components/tickets/Imagen.vue'
  import ticketFecha           from '@/components/tickets/ticketFecha.vue'

  export default {
    components:{
      Alerta,
      carga,
      Imagen,
      ticketFecha
    },

    mixins: [ funcionesExcel, validarErrores ],
    
    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialogRechazo:false,
      motivoRechazo:'',

      longitud:10,
      search:'',
      respuesta:'',
      ver:false,
      dialogResponder: false,
      historial:{},
      dialogHistorial:false,
      ticket:{},

      areas:[],
      area: null,

      puestos:[],
      puesto:null,

      jefes:[],
      jefe: null,

      dialog: false,
      dialogReporte: false,
      reporte: [],
      headersReporte: [
        { text: 'idjefe_area'    , value: 'idjefe_area',    },
        { text: 'Nombre'         , value: 'nombre_completo' },
        { text: 'Asignados'      , value: 'Asignados'       },
        { text: 'Pendiente'      , value: 'Pendiente'       },
        { text: 'Revisión'       , value: 'Revisión'        },
        { text: 'Respondido'     , value: 'Respondido'      },
        { text: 'Cerrados'       , value: 'Cerrados'        },
        { text: 'Rechazados'     , value: 'Rechazados'      },
        { text: 'Por contestar'  , value: 'Total_contestar' },
        { text: 'Total'          , value: 'Total'           },
        { text: 'Bloqueado'      , value: 'Bloqueado'       },
      ],


      headers: [
        { text: 'Folio',             value: 'idticket', },
        { text: 'Usuario',           value: 'nombre_completo' },
        { text: 'Escuela',           value: 'id_unidad_negocio' },
        { text: 'Sucursal',          value: 'plantel' },
        { text: 'Fecha de creación', value: 'fecha_apertura' },
        { text: 'F. entrega'       , value: 'fecha_entrega'     },
        { text: 'Resuelto en:',      value: 'fecha_asignacion' },
        { text: 'Estatus',           value: 'estatus' },
        { text: 'Area',              value: 'area' },
        { text: 'Encargado',         value: 'nombre_jefe' },
        { text: 'Auxiliar',          value: 'nombre_auxiliar' },
        { text: 'Visor RH',          value: 'visorrh'},
        { text: 'Actions',           value: 'actions', sortable: false },
        { text: 'Historial',         value: 'historial', sortable: false },
      ],
      tickets: [],
      estatusFiltro:8,
      usuariosTickets:[],
      usuario: null,
      url:'', //url del servidor
      urlUser:'', //puede ser url de alumnos
      escuela:0,
      urgente:0,

      dialogResponsable: false,
      responsable: null,
      headersResponsable: [
        { text: 'Nombre'         , value: 'nombre_completo' },
        { text: 'Asignados'      , value: 'Asignados' },
      ],
      vistaPrevias: [],
      fotos:[],
      files: [],
      dialogImagen:false,
      es_solucion: false,
      url:'',
      urlINBI:'',
      urlFAST:'',
      imagen:'',
      reporteFechas:{
        status: false,
      }
    }),

    computed: {

      ...mapGetters('login',['getdatosUsuario','getLogeado', 'getEscuela','getEstatusLlamada','getTareas','getNuevosProspectos']),

      formTitle () {
        return this.editedIndex === -1 ? 'Agregar ticket' : 'Asignar Ticket'
      },

      tamanioPantalla () {
        return this.$vuetify.breakpoint.height - 350
      },
      
      cargarFotos(){
        if(this.files.length < 1){
          return
        }

        this.files.forEach((element, index) => {
          // creamos una variable tipo FormData
          let formData = new FormData();
          //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', element);
          //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          if(element.size > 26214400){ //26214400 = 25MB
            this.validarErrorDirecto('El archivo sobrepasa el limite del peso, max 25 MB')
          } else {
              this.getBase64(element, formData)
          }
        })
      },

      getBase64(file, formData) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
        var video = false
        const nombreSplit = file.name.split('.')
        const extension = nombreSplit[nombreSplit.length-1]
        if(['mp4', 'mov', 'wmv', 'avi'].includes(extension)){
            video = true
        }
        me.vistaPrevias.push({
                url: reader.result, 
                formData: formData, 
                image_name: file.name,
                video : video,
                file
            })
        };
      },

      eliminarFoto(value){
        this.vistaPrevias.forEach((element, index)=>{
          if(element.url == value){
            this.vistaPrevias.splice(index,1);
          }
        })
      },


      filterTickets () {
        const tickets = this.tickets
        if(tickets){
          let newtickets = this.tickets;
          if(this.usuario){
            newtickets = newtickets.filter(el => el.nombre_jefe == this.usuario || el.nombre_auxiliar == this.usuario)
          }
          if(this.estatusFiltro != 8){
             newtickets = newtickets.filter(el => el.estatus == this.estatusFiltro)
          }
          if (this.escuela) {
            newtickets = newtickets.filter(el => el.id_unidad_negocio == this.escuela)
          }
          if(this.urgente){
            newtickets = newtickets.filter(el => el.urgente == this.urgente)
          }
          return newtickets
        }
      },

      ticketsPorResponsable( ){

        let payload = this.tickets.filter( el => el.nombre_jefe == this.responsable && [2,3,4].includes( el.estatus )  )

        let propiedadesUnicas = new Set();
        let arregloSinDuplicados = payload.filter(objeto => !propiedadesUnicas.has(objeto.nombre_completo) && propiedadesUnicas.add(objeto.nombre_completo));

        for( const i in arregloSinDuplicados ){

          const { nombre_completo } = arregloSinDuplicados[i]

          arregloSinDuplicados[i]['Asignados'] = payload.filter( el => el.nombre_completo == nombre_completo ).length

        }

        return arregloSinDuplicados
      },

    },

    watch: {
      area(){
        this.puestos = this.area.puestos
      },

      puesto(){
        this.jefes = this.puesto.jefes
      }
    },

    async created () {

      this.url = axios.defaults.baseURL + 'evidencia-tickets/'
      this.urlINBI = 'https://lmsinbi.club/evidencia-tickets/'
      this.urlFAST = 'https://lmsfast.club/evidencia-tickets/'
      this.cargar = true
      await this.initialize()
      await this.getAreas()
    },

    methods: {
      getAreas () {
        this.cargar = true
        this.tickets = []
        this.$http.get('departamentos.tickets').then(response=>{
          this.areas = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      initialize () {
        this.cargar = true
        this.tickets = []
        this.$http.get('tickets.all').then(response=>{

          this.longitud = response.data.length

          this.tickets = response.data

          if( [ 7, 161, 382, 626].includes( this.getdatosUsuario.iderp )  ){

            this.tickets = this.tickets.filter( el => { return el.id_unidad_negocio == this.getEscuela }) 

          }

          for(const i in this.tickets){

            var msegMinuto = 1000 * 60; //Calcular minutos
            var msegHora = msegMinuto * 60; //Calcular Horas
            var msegDia = msegHora * 24; //Calcular Dias

            var tiempo = "";
            var dias = "";
            var horas = "";
            var minutos = "";

            if(this.tickets[i].estatus == 1){
              this.tickets[i].fecha_asignacion = "El ticket aun no ha sido asignado";
            } else if(this.tickets[i].estatus == 2 || this.tickets[i].estatus == 3 || this.tickets[i].estatus == 4 || this.tickets[i].estatus == 5 || this.tickets[i].estatus == 7){
              var fecha_asignacion_aux = new Date(this.tickets[i].fecha_asignado); //Calcula la fecha asignada
              var hoy = new Date(); //Calcula el dia de hoy
              hoy.setMonth(); //Obtiene mes
              hoy.setDate(); //Ontiene dia
              hoy.setHours(); //Obtiene las horas
              tiempo = new Date() - fecha_asignacion_aux;
              //calcular dias 
              dias = Math.floor(tiempo / msegDia)
              tiempo = tiempo - (dias * msegDia)
              //calcula horas
              horas = Math.floor(tiempo / msegHora)
              tiempo = tiempo - (horas * msegHora)
              //Calcula minutos
              minutos = Math.floor(tiempo /msegMinuto)
              tiempo = tiempo - (minutos * msegMinuto)
              //Muestra en la tabla
              this.tickets[i].fecha_asignacion = dias + " días, " + horas +" horas, y "+  minutos + " minutos";
            } else if(this.tickets[i].estatus == 6){
              var fecha_asignacion_aux = new Date(this.tickets[i].fecha_asignado); //Calcula la fecha asignada
              var hoy = new Date(); //Calcula el dia de hoy
              hoy.setMonth(); //Obtiene mes
              hoy.setDate(); //Ontiene dia
              hoy.setHours(); //Obtiene las horas
              tiempo = new Date(this.tickets[i].fecha_cierre) - fecha_asignacion_aux;
              //calcular dias 
              dias = Math.floor(tiempo / msegDia)
              tiempo = tiempo - (dias * msegDia)
              //calcula horas
              horas = Math.floor(tiempo / msegHora)
              tiempo = tiempo - (horas * msegHora)
              //Calcula minutos
              minutos = Math.floor(tiempo /msegMinuto)
              tiempo = tiempo - (minutos * msegMinuto)
              //Muestra en la tabla
              this.tickets[i].fecha_asignacion = dias + " días, " + horas +" horas, y "+  minutos + " minutos";
            }
          }

          this.getUsuarios(this.tickets)
          this.cargar = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      verMotivo(value){
        this.ticket = value
        this.dialog = true
      },

      save () {
        this.ticket.id_area     = this.puesto.idpuesto
        this.ticket.idjefe_area = this.jefe.id_usuario
        this.ticket.estatus     = 2

        this.$http.put('tickets.update.asignacion/'+this.ticket.idticket, this.ticket).then(response=>{
          this.dialog = false
          this.jefe   = null
          this.puesto = null
          this.area   = null
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getColor (estatus) {
        if (estatus == 3) return 'red'
        else if (estatus == 1) return 'orange'
        else if (estatus == 2) return 'blue'
      },

      itemRowBackground: function (item) {
        return item.urgente ? 'style-1' : ''
      },

      historialTicket(value){
        this.ticket = value
        this.historial = []
        if (value.tipousuario) {
          //si es alumno
          if (value.id_unidad_negocio == 1) {
            this.urlUser = 'https://lmsinbi.club/evidencia-tickets/'
          } else {
            this.urlUser = 'https://lmsfast.club/evidencia-tickets/'
          }
        } else {
          //si no es alumno
          if(value.movil){
            //viene de la plataforma movil
            if (value.id_unidad_negocio == 1) {
              this.urlUser = 'https://lmsinbi.club/evidencia-tickets/'
              } else {
                this.urlUser = 'https://lmsfast.club/evidencia-tickets/'
              }
          } else {
            //no viene de la plataforma movil
            this.urlUser = axios.defaults.baseURL + 'evidencia-tickets/'
          }
        }
        this.$http.get('historial_tickets.estatus/'+this.ticket.idticket).then(response=>{
          for(const i in response.data.HistorialTicket){
            response.data.HistorialTicket[i].fecha = moment(response.data.HistorialTicket[i].fecha).format('MMMM Do YYYY, h:mm:ss a');
            response.data.HistorialTicket[i].fecharespuesta = moment(response.data.HistorialTicket[i].fecharespuesta).format('MMMM Do YYYY, h:mm:ss a');
            //esta es la fecha y hora en la que se comenzo el registro de respuestas
            if(response.data.HistorialTicket[i].fecharespuesta == 'marzo 4º 2024, 3:31:27 pm'){
              response.data.HistorialTicket[i].fecharespuesta = ''
            }
            this.historial.push(response.data.HistorialTicket[i])
          }
          this.dialogHistorial = true
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
        this.ver = false
      },

      abrirRechazar( ){
        this.historial = []
        this.$http.get('historial_tickets.estatus/'+this.ticket.idticket).then(response=>{
          for(const i in response.data.HistorialTicket){
            response.data.HistorialTicket[i].fecha = moment(response.data.HistorialTicket[i].fecha).format('MMMM Do YYYY, h:mm:ss a');
            response.data.HistorialTicket[i].fecharespuesta = moment(response.data.HistorialTicket[i].fecharespuesta).format('MMMM Do YYYY, h:mm:ss a');
            //esta es la fecha y hora en la que se comenzo el registro de respuestas
            if(response.data.HistorialTicket[i].fecharespuesta == 'marzo 4º 2024, 3:31:27 pm'){
              response.data.HistorialTicket[i].fecharespuesta = ''
            }
            this.historial.push(response.data.HistorialTicket[i])
          }
          this.dialogResponder = true
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
        this.ver = false
      },

      exportar(){

        let data = this.filterTickets.map(({ idticket, estatus, motivo, plantel, nombre_jefe, nombre_auxiliar, fecha_apertura }) => ({ idticket, estatus, motivo, plantel, nombre_jefe, nombre_auxiliar, fecha_apertura }));

        for( const i in data ){
          const { estatus } = data[i]

          switch( estatus ){

            case 1:
              data[i].estatus = "Abierto"
            break; 

            case 2:
              data[i].estatus = "Asignado"
            break; 

            case 3:
              data[i].estatus = "Pendiente"
            break; 

            case 4:
              data[i].estatus = "Revisión"
            break; 

            case 5:
              data[i].estatus = "Respondido"
            break; 

            case 6:
              data[i].estatus = "Cerrado"
            break; 

            case 7:
              data[i].estatus = "Rechazado"
            break; 

          }
        }

        this.exportExcel( data, 'tickets_list')
      },

      subirFotosServidor( ){
        let formData = new FormData();

        for( const i in this.vistaPrevias ){
          formData.append('file', this.vistaPrevias[i].file )
        }

        return new Promise((resolve,reject)=>{
          // obtener la extensión del archivo
          // Hacemos la petición
          this.$http.post(`tickets.subir.evidencia`, formData ).then(response=>{
            resolve( response )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },

      async saveRespuesta (respuestas) {
        let fotos = []
        if(this.vistaPrevias.length){
            let respuesta = await this.subirFotosServidor( )
            fotos = respuesta.data
          }
        var payload = {
          idhistorial_ticket : respuestas.idhistorial_ticket,
          motivo             : this.ticket.motivo,
          respuestaauxi      : this.respuesta,
          respuesta          : this.respuesta,
          idticket           : this.ticket.idticket,
          estatus            : 0,
          fotos,
          idauxi_area:        this.lastHistorial.idauxi_area,
          idjefe_area:        this.getdatosUsuario.id_usuario,
        }
        var estatus = { estatus: 5 }

        this.$http.put('historial_tickets.update/' + respuestas.idhistorial_ticket, payload).then(response=>{
          this.$http.put('tickets.update.estatus/' + this.ticket.idticket,estatus).then(response=>{
            this.mensaje         = ''
            this.respuesta       = ''
            this.dialog          = false
            this.dialogRechazo   = false
            this.dialogResponder = false
            this.vistaPrevias = []
            this.initialize()
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getUsuarios (value) {
        this.$http.get('usuarios.all.erp').then(response=>{
          this.usuariosTickets = response.data
        }).catch(error=>{console.log(error)})
      },

      updateVisorRh( item ){
        this.cargar = true
        this.$http.post('tickets.update.visorrh', item).then(response=>{
          this.cargar = false
          this.validarSuccess( response.data.message )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getReporteTicketsUsuario(){
        this.cargar  = true
        this.reporte = []
        this.$http.get('tickets.reporte.usuario').then(response=>{
          this.reporte = response.data.filter( el => el.Asignados > 0 || el.Pendiente > 0 || el.Revisión > 0 || el.Rechazados > 0 )

          for( const i in this.reporte){

            const { Asignados , Pendiente , Revisión , Rechazados } = this.reporte[i]

            this.reporte[i]['Total_contestar'] = Asignados + Pendiente + Revisión + Rechazados

          }

          console.log(this.reporte)
          this.dialogReporte = true
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getReportePorResponsable(){
        this.cargar  = true
        this.reporte = []
        this.$http.get('tickets.reporte.usuario').then(response=>{
          this.reporte = response.data.filter( el => el.Asignados > 0 || el.Pendiente > 0 || el.Revisión > 0 || el.Rechazados > 0 )
          this.dialogResponsable = true
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      verImagen( imagen, es_solucion ){
        this.imagen       = imagen
        this.es_solucion = es_solucion
        this.dialogImagen = true
      },
      closeImagen(){
          this.dialogImagen = false
      },
    },
  }
</script>
<style>
  .v-data-table__wrapper{

  }

  .v-data-table__wrapper::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-data-table__wrapper::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  .v-data-table__wrapper::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  .mensajeIzq{
    white-space: pre-line;
    background-color: rgb(238, 235, 235);
    border-radius: 0px 10px 10px 10px;
  }
  .mensajeDer{
    white-space: pre-line;
    background-color: rgb(193, 231, 243);
    border-radius: 10px 0px 10px 10px;
  }

  .style-1 {
    background-color: rgb(253, 169, 169);
  }
  .style-1:hover {
    background-color: rgb(238, 131, 131) !important;
  }


</style>