<template>
	<v-container class="mt-5 ml-2">
	  <v-row>
	  	<v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            Grupos sin teacher
            <v-spacer></v-spacer>

            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( filterGrupos  , 'GRUPOS_TEACHER')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="consultar()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

          </v-card-title>
          <v-card-text>
    			  <v-data-table
    			    :headers="headers"
    			    :items="filterGrupos"
    			    sort-by="calories"
    			    dense
    			    fixed-header
    			    class="elevation-0"
              :search="search"
    			  >
              <!-- Primer teacher -->
    			  	<template v-slot:item.nombre_teacher1="props">
    			      <v-edit-dialog
    			        :return-value.sync="props.item.nombre_teacher1"
    			        @save="save(props.item)"
    			        @cancel="cancel"
    			        @open="open"
    			        @close="close"
    			      >
    			        {{ props.item.nombre_teacher1 }}
    			        <template v-slot:input>
                    <v-autocomplete
                      outlined
                      dense
                      clearable
                      v-model="props.item.nombre_teacher1"
                      :items="teachers"
                      label="Selecciona el teacher"
                      persistent-hint
                      item-text="nombre_completo"
                      item-value="nombre_completo"
                      class="mt-4"
                    >
                    </v-autocomplete>
    			        </template>
    			      </v-edit-dialog>
    			    </template>

              <!-- Segundo teachers -->
              <template v-slot:item.nombre_teacher2="props">
                <v-edit-dialog
                  :return-value.sync="props.item.nombre_teacher2"
                  @save="save(props.item)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  {{ props.item.nombre_teacher2 }}
                  <template v-slot:input>
                    <v-autocomplete
                      outlined
                      dense
                      clearable
                      v-model="props.item.nombre_teacher2"
                      :items="teachers"
                      label="Selecciona el teacher"
                      persistent-hint
                      item-text="nombre_completo"
                      item-value="nombre_completo"
                      class="mt-4"
                    >
                    </v-autocomplete>
                  </template>
                </v-edit-dialog>
              </template>

    			    <!-- Top -->
    			    <template v-slot:top>
                <v-card-actions class="pl-4">
                  <v-autocomplete
                    filled
                    dense
                    clearable
                    v-model="cicloInf"
                    :items="ciclos"
                    label="Selecciona ciclo actual"
                    persistent-hint
                    hide-details
                    return-object
                    item-text="ciclo"
                    item-value="id_ciclo"
                  >
                  </v-autocomplete>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="getGruposTeachersERP()" small>Efectuar cambios</v-btn>
                </v-card-actions>
                <v-card-text class="pb-6">
                  <v-row justify="end">
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="search"
                        filled
                        dense
                        hide-details
                        label="Buscar"
                      ></v-text-field>    
                    </v-col>
                  </v-row>
                  <v-radio-group
                    v-model="estatusFiltro"
                    row
                    dense
                    hide-details
                    small
                  >
                    <v-radio
                      color="primary"
                      label="Sin teacher"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      color="primary"
                      label="Con teacher"
                      :value="2"
                    ></v-radio>
                    <v-radio
                    	color="primary"
                      label="Todos"
                      :value="3"
                    ></v-radio>
                  </v-radio-group>
                </v-card-text>
              </template>
    			    <template v-slot:item.actions="{ item }">
    			      <v-icon
    			        small
    			        class="mr-2"
    			        @click="editItem(item)"
    			      >
    			        mdi-pencil
    			      </v-icon>
    			      <v-icon
    			        small
    			        @click="deleteItem(item)"
    			      >
    			        mdi-delete
    			      </v-icon>
    			    </template>
    			    <template v-slot:no-data>
    			      <v-btn
    			        color="primary"
    			        @click="getCiclos()"
    			      >
    			        Actualizar
    			      </v-btn>
    			    </template>
    			  </v-data-table>
          </v-card-text>
        </v-card>
	  	</v-col>
	  </v-row>

	  <v-overlay :value="overlay">
      <v-card
        width="200"
        color="primary"
        dark
      >
        <v-card-text align="center" class="text-h6">
          Espera porfavor
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

	</v-container>
</template>
<script>
import axios from 'axios';
	import VueSweetalert2 from 'vue-sweetalert2';

  export default {
    data () {
      return {
        search:'',
      	overlay:false,
      	cicloInf:null,
      	ciclos:[],
        snack: false,
        snackColor: '',
        snackText: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        pagination: {},
        headers: [
          { text: 'id_grupo', value: 'id_grupo' },
          { text: 'grupo'   , value: 'grupo' },
          { text: 'nivel'   , value: 'nivel' },
          { text: 'id t1'   , value: 'teacher1' },
          { text: 'nombre'  , value: 'nombre_teacher1' },
          { text: 'id t2'   , value: 'teacher2' },
          { text: 'nombre'  , value: 'nombre_teacher2' },
        ],
        grupos: [],
        estatusFiltro:1,
        teachers:[]
      }
    },

    watch: {
      cicloInf () {
        if(this.cicloInf){
        	this.consultar()
        }
      }
    },

    async created () {
		  await  this.getCiclos()
		  await  this.getTeachers()
    },


    methods: {
    	getCiclos () {
    		this.overlay = true
        this.ciclos = []
        this.$http.get('kpi.ciclos.all').then(response=>{
        	if(response.data.length > 0){
	          for(const i in response.data){
	            if (!(response.data[i].ciclo.search('FE') != -1)) {
	              if(response.data[i].ciclo.search('CICLO') != -1){
	                this.ciclos.push(response.data[i])
	              }
	            }
	          }
        	}
          this.overlay = false
          return
        }).catch(error=>{
        	this.overlay = false
        	this.swalError('Error al cargar los datos')
        })
      },

      getTeachers () {
        this.teachers = []
        return this.$http.get('academico.teachers.activos').then(response=>{
          this.teachers = response.data
        }).catch(error=>{
        	this.overlay = false
        	this.swalError('Error al cargar los datos')
        })
      },

      consultar () {
      	if(this.cicloInf){
	      	this.overlay = true
	      	this.grupos = []
	      	const { id_ciclo, id_ciclo_relacionado } = this.cicloInf
	      	this.$http.get(`academico.grupo_teachers.get/${ id_ciclo }/${ id_ciclo_relacionado }`).then(response=>{
	          this.grupos = response.data
	          this.overlay = false
	        }).catch(error=>{
	        	this.overlay = false
	        	this.swalError(error)
	        })
      	}else{
      		this.swalError('Debes elegir un ciclo')
      	}
      },

      swalError(text) {
      	this.$swal({
				  icon: 'error',
				  title: 'Oops...',
				  text,
				});
      },

      swalSuccess(text){
        this.$swal({
          position: 'top-end',
          icon: 'success',
          title: text,
          showConfirmButton: false,
          timer: 1500
        })
      },

      getGruposTeachersERP () {
        if(this.cicloInf){
          // Activamos el loader
          this.overlay = true
          // Hacemos la peticion
          this.$http.get(`lms.catalogos.gruposteachers.cron/${ this.cicloInf.id_ciclo }/${ this.cicloInf.id_ciclo_relacionado }`).then(response=>{
            // Quitamos el loader
            this.overlay = false
          }).catch(error=>{
            this.overlay = false
            this.openDialogError(error.body.message)
          })
        }
      },

      async save (item) {
        const { id_grupo } = item
        let gruposNuevos = this.grupos
        if(item){
          if(item.nombre_teacher1 != ''){
            const search = this.teachers.find( teacher => item.nombre_teacher1 == teacher.nombre_completo);
            if(search){ 
              for(const i in gruposNuevos){
                if(gruposNuevos[i].nombre_teacher1 == search.nombre_completo){
                  gruposNuevos[i].teacher1 = search.id_trabajador
                  await this.actualizaTeacher1( id_grupo, search.id_trabajador, 1,gruposNuevos[i].escuela )
                }
              }
            }
          }

          if(item.nombre_teacher2 != ''){
            const search2 = this.teachers.find( teacher => item.nombre_teacher2 == teacher.nombre_completo);
            if(search2){ 
              console.log(search2)
              for(const i in gruposNuevos){
                if(gruposNuevos[i].nombre_teacher2 == search2.nombre_completo){
                  gruposNuevos[i].teacher2 = search2.id_trabajador
                  await this.actualizaTeacher1( id_grupo, search2.id_trabajador, 2,gruposNuevos[i].escuela )
                }
              }
            }
          }
        }

        this.grupos = []
        this.grupos = gruposNuevos
        this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Data saved'
      },

      actualizaTeacher1 ( id_grupo, id_maestro, numero_teacher, escuela) {
        let payload = {
          id_maestro,
          id_grupo,
          activo_sn: 1,
          numero_teacher,
          escuela,
          ciclo: this.cicloInf
        }
        return this.$http.post(`academico.add.grupoteacher`, payload).then(response=>{
          this.swalSuccess(response.data.message)
        }).catch(error=>{
          this.overlay = false
          this.swalError(error)
        })
        
        
      },

      cancel () {
        this.snack = true
        this.snackColor = 'error'
        this.snackText = 'Canceled'
      },
      open () {
        this.snack = true
        this.snackColor = 'info'
        this.snackText = 'Dialog opened'
      },
      close () {
        console.log('Dialog closed')
      },
    },

    computed: {
      filterGrupos () {
        if(this.grupos){
          return this.grupos.filter((grupo)=>{
            var t1 = grupo.teacher1;
            var t2 = grupo.teacher2;

            // Sin teacher
            if(this.estatusFiltro == 1){
              if(t1 == '' || t2 == ''){return true}
            }

            // Con teacher
            if(this.estatusFiltro == 2){
              if(t1 != '' || t2 != ''){return true}
            }

            // Todos
            if(this.estatusFiltro == 3){
              return true
            }

          })
        }
      },
    },
  }
</script>