import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';

export default {

	data: () =>({
    conteoAlertaTiempo: 0,
    tiempoTotalSegundos:0,
		tiempo:'00:00',
    sec: 0,
    min: 0,
    hrs: 0,
    t: null,
    alertaTiempo: false,
    showTimer: false,
	}),

	methods: {
		addFamiliar(){
      const payload = {
        familiar    : this.familiar, 
        edad        : this.edadFamiliar,
        idprospectos: this.prospecto.idprospectos,
        correo      : this.correoFamiliar,
        telefono    : this.telefonoFamiliar,
        idusuarioerp: this.getdatosUsuario.iderp
      }

      this.$http.post('familiar.add', payload).then((response) => {
        this.familiares = response.data
      }).catch(error=>{
        this.validError( error )
      })

      this.addPersonas    = false
      this.familiar       = ''
      this.edadFamiliar   = ''
    },

    deletFamiliar(familiar){
      const payload = {
        idfamiliares:   familiar.idfamiliares, 
        idprospectos:   this.prospecto.idprospectos
      }
      this.$http.post('familiar.delete', payload).then((response) => {
        this.familiares = response.data
      }).catch(error=>{
        this.validError( error )
      })


      this.addPersonas    = false
      this.familiar       = ''
      this.edadFamiliar   = ''
    },

  }
}