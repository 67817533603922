<template>
  <v-container class="mt-4 ml-2">
  	<v-row>
  		<v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="alumnos"
          dense
          class="elevation-1"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>Alumnos lms</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
             
              <v-btn color="primary" @click="consultar()" small>Consultar</v-btn>
            </v-toolbar>
            <v-card-text class="pb-6">
              <v-row>
                <v-col cols="12">
                	<v-text-field
                	  v-model="search"
                	  label="Buscar"
                	  dense
                	  outlined
                	></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </template>

          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="consultar()"
              small
            >
              Actualizar
            </v-btn>
          </template>

          <template v-slot:item.id_unidad_negocio="{ item }">
	          <v-chip v-if="item.id_unidad_negocio == 1 "  small color="#2EDAD5"  dark> INBI  </v-chip>
	          <v-chip v-if="item.id_unidad_negocio == 2 "  small color="#000D4A"  dark> FAST  </v-chip>
	        </template>

	        <template v-slot:item.comentarios="{ item }">
	          <v-chip  small color="primary"  dark @click="verComentarios( item )"> comentarios  </v-chip>
	        </template>
        </v-data-table>

			  <div class="text-center">
		      <v-dialog
		        v-model="dialogCarga"
		        hide-overlay
		        persistent
		        width="500"
		      >
		        <v-card
		          color="primary"
		          dark
		        >
		          <v-card-text class="pt-2">
		            Cargando, porfavor espera...
		            <v-progress-linear
		              indeterminate
		              color="white"
		              class="mb-0"
		            ></v-progress-linear>
		          </v-card-text>
		        </v-card>
		      </v-dialog>
		    </div>
  		</v-col>
  	</v-row>

  	<!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-h6" style="display: table-cell; vertical-align: middle;">
		      				<strong>¡Bien!</strong> Datos grabados correctamente
			      			<v-card-actions class="pt-6">
			      				<v-spacer></v-spacer>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="green" dark class="elevation-6" large @click="dialogSuccess = false">Ok</v-btn>
			      				<v-spacer></v-spacer>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/correcto.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="300"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      persistent
      max-width="600"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-h6" style="display: table-cell; vertical-align: middle;">
		      				<span v-if="errorBack">
		      					<strong>ERROR</strong> Da clic en "COPIAR" para guardar el error y enviarlo a sistemas
		      				</span>
		      				<span v-else>
		      					<strong>ERROR</strong> {{error}}
		      				</span>
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text large class="mr-2" @click="dialogError = false">Cerrar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="copiarPortapapeles()" v-if="errorBack">{{textoCopiado}}</v-btn>
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="dialogError = false" v-else>Ok</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/eliminar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogcomentarios"
      persistent
      max-width="800"
    >
      <v-card
        style="position: relative"
      >
        <v-card-title primary-title >
          Comentarios
        </v-card-title>
        <v-card-text>
          <span v-for="(mensaje, i) in mensajes">
            <v-row v-if="mensaje.id_usuarioerp != getdatosUsuario.iderp">
              <v-col cols="12">
                <v-card class="ml-6 pa-3 mt-2" max-width="400" color="#f2f2f2" style="border-radius: 15px; border-top-left-radius: 0">
                  <v-card-text class="pa-0">
                    <div class="text-caption blue--text">{{ mensaje.usuario }}</div>
                    <div class="text-caption blue--text">{{ mensaje.perfil }}</div>
                  </v-card-text>
                  <div class="text-subtitle-1">{{ mensaje.comentario }} </div>
                  <v-card-text class="pa-0">
                    <div class="text-caption blue--text">{{ mensaje.grupo }}</div>
                    <div class="text-caption blue--text">{{ mensaje.fecha_creacion }}</div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="mensaje.id_usuarioerp == getdatosUsuario.iderp">
              <v-col cols="12" align="right">
                <v-card class="mr-6 mt-2 pa-2" max-width="400" dark color="#0465ac" shaped style="border-radius: 15px; border-top-right-radius: 0;">
                  <div class="text-subtitle-1">{{ mensaje.comentario }}</div>
                  <v-card-text class="pa-0">
                    <div class="text-caption white--text">{{ mensaje.grupo }}</div>
                    <div class="text-caption white--text">{{ mensaje.fecha_creacion }}</div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </span>
        </v-card-text>

        <v-card-text class="pb-6 pt-6">
          <v-text-field
            v-model="mensaje"
            :append-outer-icon="mensaje ? 'mdi-send' : ''"
            outlined
            clearable
            label="Escribir..."
            type="text"
            @click:append-outer="enviarMensaje()"
            hide-details
            dense
          ></v-text-field>
        </v-card-text>

        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogcomentarios = false, mensaje = ''"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import XLSX from 'xlsx'

  export default {
    data: () => ({
      snackbar:false,
      headers: [
        { text: 'Matricula' 		, value: 'usuario' },
        { text: 'Alumno'    		, value: 'alumno' },
        { text: 'Grupo'     		, value: 'grupo' },
        { text: 'Escuela'   		, value: 'id_unidad_negocio' },
        { text: 'Comentarios'   , value: 'comentarios' },
      ],

      alumnos: [],
      ciclos:[],
      cicloInf:null,
      resultado:[],
      search:'',
      dialogCarga: false,
      dialogSuccess:false,
      overlay: false,
      dialogError: 	false,
			error: 				'',
			successMensaje:'',
			errorBack:false,
      textoCopiado:'COPIAR',

      dialogcomentarios:false,
      mensajes:[],
      mensaje:'',
    }),


    created () {
    	console.log(this.getdatosUsuario)
      this.consultar()
    },

    methods: {
      consultar () {
      	this.overlay = true
      	this.alumnos = []
      	this.$http.get(`operaciones.alumnos`).then(response=>{
    			for(const i in response.data){
    				const plantel = this.getdatosUsuario.planteles.find(el=> el.idplantel == response.data[i].id_plantel)
    				if(plantel){
    					this.alumnos.push(response.data[i])
    				}
    			}
          this.overlay = false
        }).catch(error=>{
        	// INdicamos que hay un error en el back
        	if(error.body.message){
        		this.error = error.body.message
        	}else{
        		this.error = error.body
        	}
        	this.errorBack = true
        	this.overlay = false
        	this.dialogError = true
        })
      },

      exportar(){
       
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let data     = XLSX.utils.json_to_sheet(this.resultado)
        let dataSuc  = XLSX.utils.json_to_sheet(aSuc)
        let dataVend = XLSX.utils.json_to_sheet(aVend)

        const workbook = XLSX.utils.book_new()
        const filename = 'Alumnos LMS'
        XLSX.utils.book_append_sheet(workbook, data, 'KPI')

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },

      swalError(text) {
      	this.$swal({
				  icon: 'error',
				  title: 'Oops...',
				  text,
				});
      },

      swalSuccess(text){
        this.$swal({
          position: 'top-end',
          icon: 'success',
          title: text,
          showConfirmButton: false,
          timer: 1500
        })
      },

      copiarPortapapeles () {
        navigator.clipboard.writeText(this.error).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      },

      // Sirve para cargar las notas del ticket
      verComentarios ( payload ) {
        this.alumno = payload
        this.overlay  = true
        this.mensajes = []

        this.$http.get(`operaciones.comentario/${ payload.id_alumno }/${ payload.id_unidad_negocio }`).then(response=>{
          this.mensajes        		= response.data
          this.overlay         		= false
          this.dialogcomentarios  = true
        }).catch(error=>{
          this.error = error.body
          this.dialogError     = true
          this.overlay         = false
        })
      },

      // Subir la nota del ticket
      enviarMensaje () {
        if(this.mensaje != '' && this.mensaje != null){
          // this.overlay = true
          let payload = {
            id_alumno:          this.alumno.id_alumno,
            id_usuarioerp:      this.getdatosUsuario.iderp,
            id_grupo:           this.alumno.id_grupo,
            id_ciclo:           this.alumno.id_ciclo,
            comentario:         this.mensaje,
            idperfil:           this.getdatosUsuario.idpuesto,
            unidad_negocio:     this.alumno.id_unidad_negocio,
            grupo:              this.alumno.grupo,
            perfil:             this.getdatosUsuario.puesto
          }

          this.$http.post('operaciones.comentario',payload).then(response=>{
            this.mensaje  = ''
            this.mensajes = []
            this.verComentarios( this.alumno )
            this.overlay = false
          }).catch(error=>{
            this.error = error.body
            this.dialogError = true
            this.overlay = false
            this.dialogRechazar = false
          })
        }else{
          this.error = 'Ingresa un mensaje'
          this.dialogError = true
          this.dialogRechazar = false
        }
      }, 
    },

    computed: {
    	...mapGetters('login', ['getdatosUsuario'])
    }
  }
</script>