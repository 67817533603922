var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-5 pl-6 pr-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadowCard"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('span',{staticClass:"text-body-1"},[_vm._v("Kpi General por Montos")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","dark":"","color":"green","tile":""},on:{"click":function($event){return _vm.exportarExcelKPi()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-microsoft-excel")])],1),_c('v-btn',{attrs:{"color":"primary","small":"","tile":""},on:{"click":function($event){return _vm.riFast()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-refresh")]),_vm._v(" Consultar ")],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('v-autocomplete',{attrs:{"filled":"","dense":"","clearable":"","items":_vm.ciclos,"label":"Selecciona ciclo actual","persistent-hint":"","hide-details":"","return-object":"","item-text":"ciclo","item-value":"id_ciclo"},model:{value:(_vm.ciclo),callback:function ($$v) {_vm.ciclo=$$v},expression:"ciclo"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('v-autocomplete',{attrs:{"filled":"","dense":"","items":_vm.ciclos,"label":"Selecciona siguiente ciclo","persistent-hint":"","hide-details":"","return-object":"","item-text":"ciclo","item-value":"id_ciclo","readonly":""},model:{value:(_vm.cicloSup),callback:function ($$v) {_vm.cicloSup=$$v},expression:"cicloSup"}})],1)],1),_c('v-data-table',{staticClass:"elevation-0 mt-4",attrs:{"headers":_vm.headers,"items":_vm.resultado.riPlantelesInbi,"sort-by":"calories","hide-default-footer":"","items-per-page":25,"dense":"","mobile-breakpoint":"200"},scopedSlots:_vm._u([{key:"item.diferencia",fn:function(ref){
var item = ref.item;
return [(item.diferencia < 0 )?_c('v-chip',{attrs:{"color":"red","dark":"","small":""},on:{"click":function($event){return _vm.verIrregulares( item.alumnosIrregualres )}}},[_vm._v(_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.diferencia )))]):_c('v-chip',{attrs:{"color":"green","dark":"","small":""}},[_vm._v(_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.diferencia )))])]}},{key:"item.totalAlumnosActuales",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosActuales ))+" ")]}},{key:"item.noRI",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.noRI ))+" ")]}},{key:"item.certificacion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.certificacion ))+" ")]}},{key:"item.reinscribibles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.reinscribibles ))+" ")]}},{key:"item.totalAlumnosSiguientes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosSiguientes ))+" ")]}},{key:"item.resagados",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.resagados ))+" ")]}},{key:"item.niActual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.niActual ))+" ")]}},{key:"item.totalMonto",fn:function(ref){
var item = ref.item;
return [(item.cantPagoDoble > 1)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""},on:{"click":function($event){return _vm.verIrregulares(item.alumnosPagoDoble)}}},[_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalMonto ))+" ")]):_c('span',[_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalMonto ))+" ")])]}},{key:"item.totalAlumnosAyer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosAyer ))+" ")]}},{key:"item.faltantes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.faltantes ))+" ")]}},{key:"item.porcentajeFaltantes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.porcentajeFaltantes)+" % ")]}}])}),_c('v-divider',{staticClass:"mb-3"}),_c('v-data-table',{staticClass:"elevation-0 mt-4",attrs:{"headers":_vm.headers,"items":_vm.resultado.riPlantelesFast,"sort-by":"calories","hide-default-footer":"","items-per-page":25,"dense":"","mobile-breakpoint":"200"},scopedSlots:_vm._u([{key:"item.diferencia",fn:function(ref){
var item = ref.item;
return [(item.diferencia < 0 )?_c('v-chip',{attrs:{"color":"red","dark":"","small":""},on:{"click":function($event){return _vm.verIrregulares( item.alumnosIrregualres )}}},[_vm._v(_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.diferencia )))]):_c('v-chip',{attrs:{"color":"green","dark":"","small":""}},[_vm._v(_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.diferencia )))])]}},{key:"item.totalAlumnosActuales",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosActuales ))+" ")]}},{key:"item.noRI",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.noRI ))+" ")]}},{key:"item.certificacion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.certificacion ))+" ")]}},{key:"item.reinscribibles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.reinscribibles ))+" ")]}},{key:"item.totalAlumnosSiguientes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosSiguientes ))+" ")]}},{key:"item.resagados",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.resagados ))+" ")]}},{key:"item.niActual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.niActual ))+" ")]}},{key:"item.totalMonto",fn:function(ref){
var item = ref.item;
return [(item.cantPagoDoble > 1)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""},on:{"click":function($event){return _vm.verIrregulares(item.alumnosPagoDoble)}}},[_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalMonto ))+" ")]):_c('span',[_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalMonto ))+" ")])]}},{key:"item.totalAlumnosAyer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosAyer ))+" ")]}},{key:"item.faltantes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.faltantes ))+" ")]}},{key:"item.porcentajeFaltantes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.porcentajeFaltantes)+" % ")]}}])}),_c('v-data-table',{staticClass:"elevation-0 mt-4",attrs:{"headers":_vm.headers,"items":_vm.resultado.totales,"sort-by":"calories","hide-default-footer":"","items-per-page":20,"dense":"","mobile-breakpoint":"200"},scopedSlots:_vm._u([{key:"item.diferencia",fn:function(ref){
var item = ref.item;
return [(item.diferencia < 0 )?_c('v-chip',{attrs:{"color":"red","dark":"","small":""},on:{"click":function($event){return _vm.verIrregulares( item.alumnosIrregualres )}}},[_vm._v(_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.diferencia )))]):_c('v-chip',{attrs:{"color":"green","dark":"","small":""}},[_vm._v(_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.diferencia )))])]}},{key:"item.totalAlumnosActuales",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosActuales ))+" ")]}},{key:"item.noRI",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.noRI ))+" ")]}},{key:"item.certificacion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.certificacion ))+" ")]}},{key:"item.reinscribibles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.reinscribibles ))+" ")]}},{key:"item.totalAlumnosSiguientes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosSiguientes ))+" ")]}},{key:"item.resagados",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.resagados ))+" ")]}},{key:"item.niActual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.niActual ))+" ")]}},{key:"item.totalMonto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalMonto ))+" ")]}},{key:"item.totalAlumnosAyer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosAyer ))+" ")]}},{key:"item.faltantes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.faltantes ))+" ")]}},{key:"item.porcentajeFaltantes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.porcentajeFaltantes)+" % ")]}}])})],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.dialogIrregulares),callback:function ($$v) {_vm.dialogIrregulares=$$v},expression:"dialogIrregulares"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Alumnos pagos doblres ")]),_c('v-card-text',[_c('div',[_vm._v("Alumnos que cuentan con 2 pagos en el mismo ciclo")]),_c('v-data-table',{staticClass:"elevation-0 mt-4",attrs:{"headers":_vm.headersIrregulares,"items":_vm.alumnos,"dense":""}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","rounded":"","dark":"","small":""},on:{"click":function($event){_vm.dialogIrregulares = false, _vm.alumnos = []}}},[_vm._v(" Listo ")])],1)],1)],1),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar)?_c('carga'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }