<template>
  <v-container fluid class="mt-4">
  	<v-row>
  		<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		  	<span class="text-subtitle-1">Dashboard</span>
	  		    <v-radio-group
				      v-model="escuela"
				      class="ml-4"
				      row
				    >
				      <v-radio
				        label="FAST"
				        :value="2"
				      ></v-radio>
				      <v-radio
				        label="INBI"
				        :value="1"
				      ></v-radio>
				    </v-radio-group>
	  		    <v-spacer></v-spacer>
	  		    <v-btn color="primary" @click="initialize()">Buscar</v-btn>
	  		  </v-card-title>

	  		 	<v-card-text>
	  		 		<v-row>
	  		 			<v-col cols="12" md="6" lg="4">
			  		    <v-text-field
			  		      label="Fecha de inicio"
			  		      filled
			  		      hide-details
			  		      dense
			  		      type="date"
			  		      v-model="fecha_inicio"
			  		    ></v-text-field>
			  		   </v-col>

			  		   <v-col cols="12" md="6" lg="4">
			  		    <v-text-field
			  		      label="Fecha final"
			  		      filled
			  		      hide-details
			  		      dense
			  		      type="date"
			  		      v-model="fecha_final"
			  		    ></v-text-field>
	  		 			</v-col>
	  		 		</v-row>
	  		  </v-card-text>

	  		</v-card>
  		</v-col>
  	</v-row>

    <v-row>
    	<!-- Medio de contacto -->
      <v-col cols="12" md="8" lg="6">
        <v-card class="shadowCard">
			    <v-card-title class="align-start">
			      <span class="font-weight-semibold mr-1">Leads </span> <span class="primary--text"> | {{ totalLeads }} |</span> 
			      <span class="font-weight-semibold mr-1 ml-4">Contactos </span> <span class="primary--text"> | {{ totalContactos }} |</span> 
			      <span class="font-weight-semibold mr-1 ml-4">Inscritos </span> <span class="primary--text"> | {{ totalInscritos }} |</span> 
			      <v-spacer></v-spacer>
			    </v-card-title>

			    <v-card-text>
			      <!-- Inscritos por plantel -->
				    <v-data-table
				      :headers="headerComoNosConocio"
				      :items="comoNosConocio"
				      item-key="plantel"
				      class="table-rounded"
				      dense
				    >
				    </v-data-table>
			    </v-card-text>
			  </v-card>
      </v-col>

      <v-col cols="12" md="8" lg="6">
        <v-card class="shadowCard">
        	<v-card-title class="align-start">
			      <span class="font-weight-semibold mr-1">Medio de contacto </span> <span class="primary--text"></span> 
			      <v-spacer></v-spacer>
			    </v-card-title>
			    <v-card-text>
			      <!-- Inscritos por plantel -->
				    <v-data-table
				      :headers="headerFuentes"
				      :items="fuentes"
				      item-key="plantel"
				      class="table-rounded"
				      dense
				    >
				      <!-- Saber si es vendedora -->
				      <template v-slot:item.vendedora="{item}">
				        <v-chip small color="green"   v-if="item.vendedora" dark>Sucursal</v-chip>
				        <v-chip small color="primary" v-else>Merca</v-chip>
				      </template>
				    </v-data-table>
			    </v-card-text>
			  </v-card>
      </v-col>

      <!-- Contactados -->
      <v-col cols="12" md="8" lg="6">
        <v-card class="shadowCard">
			    <v-card-title class="align-start">
			      <span class="text-subtitle-1 mr-1">Contactados | {{ totalContactados }} |</span> 
			      <v-spacer></v-spacer>
			    </v-card-title>

			    <v-card-text>
			    	<canvas ref="myChart" style="max-height: 400px;"></canvas>

			      <!-- Inscritos por plantel -->
				    <v-data-table
				      :headers="headerContactados"
				      :items="contactados"
				      item-key="plantel"
				      class="table-rounded"
				      dense
				      hide-default-footer
				    >
				    </v-data-table>
			    </v-card-text>
			  </v-card>
      </v-col>

      <!-- Inscritos -->
      <v-col cols="12" md="8" lg="6">
        <v-card class="shadowCard">
			    <v-card-title class="align-start">
			      <span class="text-subtitle-1 mr-1">Inscritos | {{ contactadosInsc }} |</span> 
			      <v-spacer></v-spacer>
			    </v-card-title>

			    <v-card-text>
			    	<canvas ref="graficaInscritos" style="max-height: 400px;"></canvas>

			      <!-- Inscritos por plantel -->
				    <v-data-table
				      :headers="headerInscritos"
				      :items="inscritos"
				      item-key="plantel"
				      class="table-rounded"
				      dense
				      hide-default-footer
				    >
				    </v-data-table>
			    </v-card-text>
			  </v-card>
      </v-col>

    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import VueApexCharts from 'vue-apexcharts'

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'

  import Chart from "@/plugins/chart";

  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

  		fecha_inicio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fecha_final: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
  		totalLeads: 0,
  		totalInscritos:0,
  		totalContactados:0,
  		contactadosInsc:0,
  		contactados:[],
  		fuentes:[],
  		desgloseLeads:[],
  		conversionContactos:0,
			conversionInscritos:0,
			conversionConvencimiento:0,
			totalSoloInduccion:0,
			totalSoloCurso:0,
			totalInduccionCurso:0,
			totalContactos:0,
			contactosFuente:[],
			inscritosFuente:0,
			vendedoras:[],
			planteles:[],
			cursos:[],
			comoNosConocio:[],

			dialogDesglose: false,
			desglose:[],
			fuente:'',
			fuentes:[],
			escuela:2,
			inscritos:[],

      headerComoNosConocio: [
	      { text: 'Cómo nos conocio'  , value: 'medio' },
        { text: 'Leads'             , value: 'leads' },
        { text: 'Contáctos'         , value: 'contactos' },
        { text: '%'                 , value: 'porcentaje' },
      ],

      headerFuentes: [
	      { text: 'Medio de contacto' , value: 'fuente' },
        { text: 'Leads'             , value: 'leads' },
        { text: 'Contáctos'         , value: 'contactos' },
        { text: 'Vendedora'         , value: 'vendedora' },
        { text: '%'                 , value: 'porcentaje' },
      ],

      headerContactados: [
	      { text: 'Tipo'         , value: 'fuente' },
        { text: 'Contactados'  , value: 'contactados' },
        { text: 'Inscritos'    , value: 'inscritos' },
        { text: 'No inscritos' , value: 'noinscritos' },
      ],
  	
  		headerInscritos:[
	      { text: 'Tipo'            , value: 'tipo' },
        { text: 'Solo inducción'  , value: 'totalSoloInduccion' },
        { text: 'Solo curso'      , value: 'totalSoloCurso' },
        { text: 'Ambos'           , value: 'totalInduccionCurso' },
      ],
  	}),

	  async created(){
	  	await this.initialize()
	  },

	  methods:{
	  	initialize(){
	  		this.cargar = true

	  		const payload = {
	  			fecha_inicio: this.fecha_inicio,
	  			fecha_final:  this.fecha_final,
	  			escuela:      this.escuela
	  		}
				this.$http.post('dashboard.marketing', payload).then((response) => {
					this.totalLeads                 = response.data.totalLeads
					this.fuentes                    = response.data.fuentes
					this.desgloseLeads              = response.data.desgloseLeads
					this.conversionContactos        = response.data.conversionContactos
					this.conversionInscritos        = response.data.conversionInscritos
					this.totalContactos             = response.data.totalContactos
					this.contactosFuente            = response.data.contactosFuente
					this.inscritosFuente            = response.data.inscritosFuente
					this.comoNosConocio             = response.data.comoNosConocio
					this.fuentes                    = response.data.fuentesDesglosadas
					this.totalInscritos             = response.data.totalInscritos
					this.totalContactados           = response.data.totalContactados
					this.contactadosInsc            = response.data.contactadosInsc
					this.totalSoloInduccion         = response.data.totalSoloInduccion
					this.totalSoloCurso             = response.data.totalSoloCurso
					this.totalInduccionCurso        = response.data.totalInduccionCurso
					this.cargar = false
					this.crearGraficaContactados( this.totalContactados, this.contactadosInsc )
					this.crearGraficaInscritos( this.totalSoloInduccion, this.totalSoloCurso, this.totalInduccionCurso )
	        return
	      }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
	  	},

	  	abrirModalDesgloce( data ){
	  		this.fuente         = data
	  		this.desglose       = data.detalles
	  		this.dialogDesglose = true
	  	},

		  crearGraficaContactados( contactados, inscritos){
				this.contactados = [{tipo: '', contactados: this.totalContactados, inscritos: this.contactadosInsc, noinscritos: ( this.totalContactados - this.contactadosInsc)}]

	      var ctx = this.$refs.myChart.getContext("2d");

	      if (this.myChart != null) {
	        this.myChart.destroy();
	      }

	      const labels = [
			    'Contactados',
			    'Inscritos',
			    'No inscritos',
			  ];

			  const data = {
			    labels: labels,
			    datasets: [{
			      label: 'Contactados',
			      backgroundColor: [
				      'rgba(255, 99, 132, 0.2)',
				      'rgba(255, 159, 64, 0.2)',
				      'rgba(75, 192, 192, 0.2)'
				    ],
				    borderColor: [
				      'rgb(255, 99, 132)',
				      'rgb(255, 159, 64)',
				      'rgb(75, 192, 192)'
				    ],
				    borderWidth: 1,
			      data: [contactados, inscritos, ( contactados - inscritos)],
			    }]
			  };

			  const config = {
			    type: 'bar',
			    data: data,
			    options: {
				    scales: {
				      y: {
				        beginAtZero: true
				      },
				      x: {
	            	grid: {
			            offset: true
			          }
			        }
				    }
				  }
			  };

	      this.myChart = new Chart(ctx, config );
		  },

		  crearGraficaInscritos( totalSoloInduccion, totalSoloCurso, totalInduccionCurso){
				this.inscritos = [{tipo: '', totalSoloInduccion, totalSoloCurso, totalInduccionCurso}]
	      
	      var ctx = this.$refs.graficaInscritos.getContext("2d");

	      if (this.chartInscritos != null) {
	        this.chartInscritos.destroy();
	      }

	      const labels = [
			    'Solo inducción',
			    'Solo curso',
			    'Ambos',
			  ];

			  const data = {
			    labels: labels,
			    datasets: [{
			      label:'Solo inducción',
			      backgroundColor: [
				      'rgba(255, 99, 132, 0.2)',
				      'rgba(255, 159, 64, 0.2)',
				      'rgba(75, 192, 192, 0.2)'
				    ],
				    borderColor: [
				      'rgb(255, 99, 132)',
				      'rgb(255, 159, 64)',
				      'rgb(75, 192, 192)'
				    ],
				    borderWidth: 1,
			      data: [totalSoloInduccion, totalSoloCurso, totalInduccionCurso],
			    }]
			  };

			  const config = {
			    type: 'bar',
			    data: data,
			    options: {
			    	responsive: true,
				    scales: {
				      y: {
				        beginAtZero: true
				      },
				      x: {
	            	grid: {
			            offset: true
			          }
			        }
				    }
				  }
			  };

	      this.chartInscritos = new Chart(ctx, config );
		  },
	  },


	  computed:{
	    tamanioPantalla () {
	      switch (this.$vuetify.breakpoint.name) {
	        case 'xs':
	        return 'auto';
	          // return this.$vuetify.breakpoint.height -110
	        break;
	        case 'sm': 
	          return this.$vuetify.breakpoint.height - 65
	        break;
	        case 'md':
	          return this.$vuetify.breakpoint.height - 65
	        break;
	        case 'lg':
	          return this.$vuetify.breakpoint.height - 65
	        break;
	        case 'xl':
	          return this.$vuetify.breakpoint.height - 65
	        break;
	      }
	    },
	  }
	}
</script>

<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>