<template>
  <v-container class="mt-4 ml-2">
  	<v-row>
  		<v-col cols="12">
  			<v-card class="shadowCard">
  				<v-card-title primary-title>
            <span class="text-subtitle-1">Alumnos lms</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="initialize()" rounden small>Actualizar</v-btn>
  				</v-card-title>
  				<v-card-text class="pb-6">
            <v-row justify="end">
              <v-col cols="12" md="6" lg="4">
              	<v-text-field
              	  v-model="search"
              	  label="Buscar"
              	  dense
              	  filled
              	  append-icon="mdi-magnify"
              	></v-text-field>
              </v-col>
            </v-row>
		        <v-data-table
		          :headers="headers"
		          :items="alumnos"
		          dense
		          class="elevation-0"
		          :search="search"
		        >
		        	<template v-slot:item.adeudo="{ item }">
					      <v-chip color="error" v-if="item.adeudo > 0" small> ADEUDO </v-chip>
					      <v-chip color="success" v-else small> SIN ADEUDO </v-chip>
					    </template>

					    <template v-slot:item.reparar="{ item }">
					      <v-chip color="primary"  small @click="reparar( item )"> Reparar <v-icon right>mdi-cog</v-icon></v-chip>
					    </template>

		          <template v-slot:no-data>
		            <v-btn
		              color="primary"
		              @click="initialize"
		              small
		            >
		              Actualizar
		            </v-btn>
		          </template>
		        </v-data-table>
          </v-card-text>

  			</v-card>

  		</v-col>
  	</v-row>
	  

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  export default {
    components:{
      Alerta,
      carga,
    },

    data: () => ({
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      snackbar:false,
      headers: [
        { text: 'id'        , value: 'id_alumno' },
        { text: 'Alumno'    , value: 'alumno' },
        { text: 'Matricula' , value: 'matricula' },
        { text: 'celular'   , value: 'celular' },
        { text: 'adeudo'    , value: 'adeudo' },
        { text: 'id grupo'  , value: 'id_grupo' },
        { text: 'Grupo'     , value: 'grupo' },
        { text: 'Asignado'  , value: 'grupo_asignado' },
        // { text: 'Reparar'   , value: 'reparar' },
      ],

      alumnos: [],
      ciclos:[],
      cicloInf:null,
      resultado:[],
      search:'',
      dialogCarga: false,
    }),

	  async created () {
      await this.initialize()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.alumnos = []
        this.$http.get('lms.catalogos.usuarios').then(response=>{
        	this.cargar = false
        	this.alumnos = response.data.alumnos
        	this.ciclos  = response.data.cicloActual
        }).catch(error=>{
        	this.validarError( error.response.data.message )
        }).finally( ()=>{ this.cargar = false })
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      reparar ( alumno ) {
      	if( alumno.adeudo > 0 ){
      		this.parametros.mensaje      = 'El alumno cuenta con adeudo, favor de comunicarse con operaciones'
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta         = true 
          return
      	}

      	alumno['ciclo_inbi'] = this.ciclos.ciclo_inbi
      	alumno['ciclo_fast'] = this.ciclos.ciclo_fast

      	this.$http.post( 'lms.reparar.alumno', alumno ).then( response=>{
      		this.cargar                  = false 
      		this.parametros.mensaje      = response.data.message
          this.parametros.dialogError  = true 
          this.parametros.color        = 'success'
          this.respuestaAlerta         = true 
      	}).catch(error=>{
        	this.validarError( error.response.data.message )
        }).finally( ()=>{ this.cargar = false })

      }

    },
  }
</script>