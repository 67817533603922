<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Usuarios ERP</span>
	  		    <v-spacer></v-spacer>

            <v-btn
              color="green"
              dark
              class="mr-2"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-loupe</v-icon>
              Consultar
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog_agregar = true, getUsuarios()"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
	  		  </v-card-title>

	  		  <v-card-text>          
	  		  	<v-row >
            <!-- Seleccionar el plantel -->             
              <v-col cols="12" md="6" lg="5">
	  		  			<v-autocomplete
	  		  			  label="Plantel"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  v-model="plantel"
	  		  			  :items="planteles"
	  		  			  item-text="plantel"
	  		  			  item-value="id_plantel"
	  		  			  clearable
	  		  			></v-autocomplete>
	  		  		</v-col>
	  		  		<v-col cols="12" md="6" lg="5">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="filterUsuarios"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            dense
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip 
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>

                   <template v-slot:item.ajusta_pagos_sn="{ item }">
							      <v-chip 
							      	v-if="item.ajusta_pagos_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>
							  

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

                      <!-- <v-btn 
                        color="error" 
                        x-small
                        @click="deleteItem(item)"
                        class="mr-2"
                      >
                        <v-icon small> mdi-delete</v-icon>
                      </v-btn> -->
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

 <!-- Dialogo de agregar-->
<v-dialog
      v-model="dialog_agregar"
      max-width="1000px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ this.valor}}</strong>
          </span>
        </v-card-title>

        <v-card-text>
         <v-row>
    <v-col cols="12" md="6">
        <v-text-field
          v-model=" defaultItem.usuario"
          label="Usuario"
          filled
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model=" defaultItem.password"
          label="Contraseña"
          filled
          hide-details
          dense
        ></v-text-field>
      </v-col>

       <v-col cols="12" md="6">
        <v-text-field
          v-model=" defaultItem.password2"
          label="Confirmar Contraseña"
          filled
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model=" defaultItem.nombre_usuario"
          label="Nombre(s)"
          filled
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model=" defaultItem.apellido_usuario"
          label="Apellido(s)"
          filled
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model=" defaultItem.telefono"
          label="Telefono"
          filled
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model=" defaultItem.email"
          label="Email"
          filled
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
        	:items="planteles"
          v-model=" defaultItem.id_plantel"
          label="Plantel"
          outlined
          hide-details
          dense
          item-text="plantel"
          item-value="id_plantel"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
        	:items="perfiles"
          v-model=" defaultItem.id_perfil"
          label="Perfil"
          outlined
          hide-details
          dense
          item-text="perfil"
          item-value="id_perfil"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
        	:items="trabajadores"
          v-model=" defaultItem.id"
          label="Maestro"
          outlined
          hide-details
          dense
          item-text="nombre_completo"
          item-value="id"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12">
        <v-textarea
          v-model="defaultItem.comentarios"
          label="Comentarios"
          outlined
          hide-details
          dense
          auto-grow
        ></v-textarea>
      </v-col>

      <v-col cols="12" sm="6" class="pt-0">
      	<v-switch 
      		label="¿Activo Si/No?"
          hide-details
      		v-model=" defaultItem.activo_sn">
      	</v-switch>
      </v-col>

      
    </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="save"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

	  <!-- Dialogo de editar-->
	  <v-dialog
      v-model="dialog_editar"
      max-width="1000px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle2 }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.id_usuario }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
         <v-row>
    <v-col cols="12" md="6">
        <v-text-field
          v-model="editedItem.usuario"
          label="Usuario"
          filled
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="editedItem.password"
          label="Contraseña"
          filled
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model=" editedItem.password2"
          label="Confirmar Contraseña"
          filled
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="editedItem.nombre_usuario"
          label="Nombre(s)"
          filled
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="editedItem.apellido_usuario"
          label="Apellido(s)"
          filled
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="editedItem.telefono"
          label="Telefono"
          filled
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="editedItem.email"
          label="Email"
          filled
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
        	:items="planteles"
          v-model="editedItem.id_plantel"
          label="Plantel"
          outlined
          hide-details
          dense
          item-text="plantel"
          item-value="id_plantel"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
        	:items="perfiles"
          v-model=" editedItem.id_perfil"
          label="Perfil"
          outlined
          hide-details
          dense
          item-text="perfil"
          item-value="id_perfil"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
        	:items="trabajadores"
          v-model="editedItem.id"
          label="Maestro"
          outlined
          hide-details
          dense
          item-text="nombre_completo"
          item-value="id"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12">
        <v-textarea
          v-model="editedItem.comentarios"
          label="Comentarios"
          outlined
          hide-details
          dense
          auto-grow
        ></v-textarea>
      </v-col>

      <v-col cols="12" sm="6" class="pt-0">
      	<v-switch 
      		label="¿Activo Si/No?"
          hide-details
      		v-model="editedItem.activo_sn">
      	</v-switch>
      </v-col>


      <v-col cols="12" sm="6" class="pt-5">
      	<div class="text-subtitle-1"><b>Fecha alta:</b> {{ editedItem.fecha_alta }}</div>
      	<div class="text-subtitle-1"><b>Fecha baja:</b> {{ editedItem.fecha_baja }}</div>
      </v-col>

      
    </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="update"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Contraseña Incorrecta</strong> <br>Favor de revisar que ambas contraseñas sean iguales.</br>
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDialog()">Cerrar</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/eliminar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

   

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
      plantel:null,
      planteles:[],
      perfiles:[],
      trabajadores:[],
      usuarioERP:[],
      usua:[],
      valor:0,

      dialog_agregar: false,
      dialog_editar: false,
      dialogDelete: false,
      dialog: false,
      
      editedIndex: -1,

      editedItem: {
        id_usuario:'',
				usuario:'',
				nombre_completo:'',
				nombre_usuario:'',
        apellido_usuario:'',
        id_plantel:0,
				plantel:'',
				password:'',
        password2:'',    
        activo_sn: 1,     
        ajusta_pagos_sn: 0,
        fecha_alta:null,  
        fecha_baja:null,  
        telefono:'00000',  
        email:'',  
        comentarios:'',
        id_perfil: null,
        id_trabajador: null
      },

      defaultItem: {
        id_usuario:'',
				usuario:'',
				nombre_completo:'',
				nombre_usuario:'',
        apellido_usuario:'',
        id_plantel:0,
				plantel:'',
				password:'',   
        password2:'',   
        activo_sn: 1,     
        ajusta_pagos_sn: 0,
        fecha_alta:null,  
        fecha_baja:null,  
        telefono:'00000',  
        email:'',  
        comentarios:'',
        id_perfil: null,
        id: null
      },

      // Filtros: 
      incio:null,
			fin:null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      horarios: [],
      headers: [
        { text: 'ID'                    , value: 'id_usuario'  },
        { text: 'Login'                 , value: 'usuario'     },
        { text: 'Nombre'                , value: 'nombre_completo'     },
        { text: 'Plantel'               , value: 'plantel'    },
        { text: 'Puesto'                , value: 'perfil'    },
        { text: '¿Activo?'              , value: 'activo_sn'    },
        { text: '¿Puede ajustar Pagos?' , value: 'ajusta_pagos_sn'    },
        { text: 'Telefono'              , value: 'telefono'    },
        { text: 'Email'                 , value: 'email'    },
        { text: 'Acciones'              , value: 'actions', sortable: false },
      ],
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return 'Agregar Usuario'
      },
      formTitle2 () {
        return 'Editar Usuario'
      },

      filterUsuarios( ){
      	if( this.plantel ){
      		return this.usuarioERP.filter( el=> { return el.id_plantel == this.plantel })
      	}else{
      		return this.usuarioERP
        } 
      }
    },

    watch: {

     dialog_agregar (val) {
        val || this.close()
      },
      dialog_editar (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      "editedItem.usuario"( value ){
        this.editedItem.email = value
      },

      "defaultItem.usuario"( value ){
        this.defaultItem.email = value
      }

    },

    async created () {
      await this.initialize(),
      await this.getPlanteles(),
      await this.getPerfiles(),
      await this.getTrabajadores()
    },

    methods: {
      initialize () {  
      	this.cargar = true
        this.usuarioERP = []
        return this.$http.get('usuarios.erp.get.usuariosERP').then(response=>{
          this.usuarioERP = response.data
          console.log(this.usuarioERP)
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      save () {   
        let payload = {                                                              
      		usuario:            this.defaultItem.usuario,
          password:           this.defaultItem.password,
          password2:          this.defaultItem.password2,
          nombre_usuario:     this.defaultItem.nombre_usuario,
          apellido_usuario:   this.defaultItem.apellido_usuario,
          telefono:           this.defaultItem.telefono,
          email:              this.defaultItem.email,
          id_plantel:         this.defaultItem.id_plantel,
          activo_sn:          this.defaultItem.activo_sn,
          comentarios:        this.defaultItem.comentarios,
          id:                 this.defaultItem.id,
      	};

        let payload2 = {                                                              
          id_usuario:            this.valor,
          id_perfil:             this.defaultItem.id_perfil,
        }

        if (this.defaultItem.password ==  this.defaultItem.password2){
          this.$http.post('usuarios.erp.add.usuariosERP', payload).then(response=>{
            this.$http.post('usuarios.erp.add.usuarioperfilERP', payload2).then(response=>{
              this.dialog_agregar = null
              this.defaultItem.usuario  = null
              this.defaultItem.password = null
              this.defaultItem.password2 = null
              this.defaultItem.nombre_usuario = null
              this.defaultItem.apellido_usuario = null
              this.defaultItem.email = null
              this.defaultItem.id_plantel = null
              this.defaultItem.id_perfil = null
              this.defaultItem.id= null
              this.defaultItem.comentarios = null
              this.validarSuccess( response.data.message )
  	          this.initialize()
              this.close()
  	        }).catch(error=>{
              console.log(error);
              this.loading = false
            }).finally(()=>{this.loading = false})       
          }).catch(error=>{
            console.log(error);
            this.loading = false
          }).finally(()=>{this.loading = false})      
        } else {
          this.dialog = true;
        }
      },
      
      update () {
        let payload = {                                                            
      		usuario:            this.editedItem.usuario,
          password:           this.editedItem.password,
          nombre_usuario:     this.editedItem.nombre_usuario,
          apellido_usuario:   this.editedItem.apellido_usuario,
          telefono:           this.editedItem.telefono,
          email:              this.editedItem.email,
          id_plantel:         this.editedItem.id_plantel,
          activo_sn:          this.editedItem.activo_sn,
          comentarios:        this.editedItem.comentarios,
          id_trabajador:      this.editedItem.id,
          id_usuario:         this.editedItem.id_usuario
      	}

        let payload2 = {                                                            
      		id_perfil:            this.editedItem.id_perfil,
          id_usuario:           this.editedItem.id_usuario,         
      	}

        if (this.editedItem.password ==  this.editedItem.password2){
	        this.cargar = true
          this.$http.post('usuarios.erp.update.usuariosERP', payload).then(response => {
            this.$http.post('usuarios.erp.update.perfilusuariosERP', payload2).then(response => {
              this.validarSuccess( response.data.message )
              this.dialog_editar = false
              this.initialize()
              this.close()
  	        }).catch( error =>{
              this.validarError( error.response.data.message )
            }).finally( () => { this.cargar = false })
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        } else {
          this.dialog = true;
        }     
      },
         
      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPerfiles () {
      	this.cargar = true
        this.perfiles = []
        return this.$http.get('usuarios.erp.get.perfilesERP').then(response=>{
        	this.perfiles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getTrabajadores () {
      	this.cargar = true
        this.trabajadores = []
        return this.$http.get('usuarios.erp.get.trabajadoresERP').then(response=>{
        	this.trabajadores = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getUsuarios () {
      	this.cargar = true
        this.usua = []
        return this.$http.get('usuarios.erp.get.IdUsuarioERP').then(response=>{
        	this.usua = response.data
          this.valor = this.usua[0].id_agregar;
        	this.cargar  = false
          
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.horarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        console.log(this.editedItem)
        this.dialog_editar = true
      },

      deleteItem (item) {
        this.editedIndex = this.horarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('horarios.eliminar/' + this.editedItem.id_horario, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog_editar = false
        this.dialog_agregar = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDialog () {
        this.dialog = false
      },

     
    },
  }
</script>


