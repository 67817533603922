<template>
	<v-dialog
    v-model="parametrosAsignarVendedora.estatus"
    persistent
    max-width="400"
  >
    <v-card>
      <v-card-title primary-title >
        Reasignar vendedora
      </v-card-title>

      <v-card-text class="black--text">

        Reasignar: {{ chat.numero.replace('whatsapp:','') }}

        <br/>
        <br/>

        <v-autocomplete
          filled
          dense
          clearable
          v-model="usuario"
          :items="filterVendedoras"
          label="Selecciona una vendedora"
          hide-details
          item-text="nombre_completo"
          item-value="iderp"
          single-line
          prepend-inner-icon="mdi-account"
        >
        </v-autocomplete>
      </v-card-text>

      <v-card-actions>
        <v-btn 
          color="black"   
          dark 
          small 
          @click="parametrosAsignarVendedora.estatus = false"
          tile
        >
          <v-icon small left>mdi-cancel</v-icon>
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn 
          color="success" 
          dark 
          small 
          @click="asignarVendedora()"
          tile
        >
          <v-icon small left>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'parametrosAsignarVendedora',
			'alumno',
			'wha',
			'chat',
			'usuarios'
	  ],

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      usuario: null,
    }),


    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),

      filterVendedoras(){
        return this.usuarios
      }
    },

    methods:{

      asignarVendedora ( ) {
      	// Preparamos el payalos
      	const payload = {
      		id_vendedora: this.usuario,
      		id_whatsapp : this.chat.numero,
      		estatus     : 1
      	}

      	// Activamos el loader
        this.cargar = true

        // Hacemos la petición y enviamos el payload
		    return this.$http.post('whatsapp.asignar', payload ).then(response=>{

		    	this.$emit('socketAsignacion')

		    	// Abrimos alerta de que todo corrió bien
		      this.validarSuccess(response.data.message)

		      // Cerramos el loader
		      this.cargar      = false

		      // Limpiamos el usuario
		      this.usuario = null

		      // Cerramos la venta
		      this.parametrosAsignarVendedora.estatus = false
		    }).catch( error =>{
		      this.validarError( error.response.data.message )
		    }).finally( () => { this.cargar = false })

      },

    }
	}
</script>
