<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    Riesgo alumnos
            <v-radio-group
              v-if="[12,11,40,43].includes(getdatosUsuario.idpuesto)"
              v-model="escuela"
              row
              class="ml-4"
            >
              <v-radio
                label="Fast"
                :value="2"
              ></v-radio>
              <v-radio
                label="Inbi"
                :value="1"
              ></v-radio>
            </v-radio-group>
	  		    <v-spacer></v-spacer>

            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( alumnos  , 'RIESGO_ALUMNOS')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="getDatosRiesgo()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="space-between">
	  		  		<v-col cols="12" md="4" lg="3" class="pb-0">
                <v-autocomplete
	                filled
	                dense
	                clearable
	                v-model="ciclo"
	                :items="ciclos"
	                label="Selecciona ciclo actual"
	                persistent-hint
	                hide-details
	                return-object
	                item-text="ciclo"
	                item-value="id_ciclo"
	              >
	              </v-autocomplete>
              </v-col>

	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-text-field
				          label="Buscar"
				          v-model="search"
				          dense
				          filled
				          append-icon="mdi-magnify"
				        ></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
					  <v-data-table
					    :headers="headers"
					    :items="alumnos"
					    sort-by="calories"
					    class="elevation-0"
					    :search="search"
					    dense
					  >

		          <template v-slot:item.estatus="{ item }">
		            <v-chip
		              color="red"
		              dark
		              v-if="item.estatus=='ALTO'"
		              small
		              @click="abrirEstatus(item,1)"
		            >
		              ALTO
		            </v-chip>

		            <v-chip
		              color="blue"
		              v-if="item.estatus=='MEDIO'"
		              small
		              @click="abrirEstatus(item,1)"
		              dark
		            >
		              MEDIO
		            </v-chip>

		            <v-chip
		              color="green"
		              dark
		              v-if="item.estatus=='NULO'"
		              small
		              @click="abrirEstatus(item,1)"
		            >
		              NULO
		            </v-chip>
		          </template>

		          <template v-slot:item.estatus_ejercicios="{ item }">
		            <v-chip
		              color="red"
		              dark
		              v-if="item.estatus_ejercicios=='ALTO'"
		              small
		              @click="abrirEstatus(item,2)"
		            >
		              ALTO
		            </v-chip>

		            <v-chip
		              color="green"
		              dark
		              v-if="item.estatus_ejercicios=='NULO'"
		              small
		              @click="abrirEstatus(item,2)"
		            >
		              NULO
		            </v-chip>
		          </template>

		          <template v-slot:item.estatus_examenes="{ item }">
		            <v-chip
		              color="green"
		              dark
		              v-if="item.estatus_examenes=='NULO'"
		              small
		              @click="abrirEstatus(item,3)"
		            >
		              NULO
		            </v-chip>

		            <v-chip
		              color="red"
		              dark
		              v-else
		              small
		              @click="abrirEstatus(item,3)"
		            >
		              ALTO
		            </v-chip>
		          </template>

		          <template v-slot:item.estatus_calificaciones="{ item }">
		            <v-chip
		              color="red"
		              dark
		              v-if="item.estatus_calificaciones=='ALTO'"
		              small
		              @click="abrirEstatus(item,4)"
		            >
		              ALTO
		            </v-chip>

		            <v-chip
		              color="green"
		              dark
		              v-if="item.estatus_calificaciones=='NULO'"
		              small
		              @click="abrirEstatus(item,4)"
		            >
		              NULO
		            </v-chip>
		          </template>

		          <template v-slot:item.estatus_registro="{ item }">
		            <v-chip
		              color="red"
		              dark
		              v-if="item.estatus_registro=='ALTO'"
		              small
		              @click="abrirEstatus(item,5)"
		            >
		              ALTO
		            </v-chip>

		            <v-chip
		              color="green"
		              dark
		              v-if="item.estatus_registro=='NULO'"
		              small
		              @click="abrirEstatus(item,5)"
		            >
		              NULO
		            </v-chip>
		          </template>
					  </v-data-table>
	  		  </v-card-text>
	  		</v-card>
  		</v-col>
	  </v-row>

    <v-col cols="auto">
      <v-dialog
        v-model="dialogEstatus"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card>
          <v-toolbar
            color="primary"
            dark
            dense
            class="elevation-0"
          >información detallada</v-toolbar>
          <v-card-text>
            <div class="mt-2"><h3>{{alumnoEstatus.nombre}}</h3></div>
            <div class="mt-2" v-if="tipoEstatus == 'ALTO'"> <h4><b>Riesgo:</b><span class="red--text">   ALTO  </span></h4> </div>
            <div class="mt-2" v-if="tipoEstatus == 'MEDIO'"><h4><b>Riesgo:</b><span class="blue--text">  MEDIO </span></h4> </div>
            <div class="mt-2" v-if="tipoEstatus == 'NULO'"> <h4><b>Riesgo:</b><span class="green--text"> NULO  </span></h4> </div>
            <div class="mt-2"><b> Situación:</b> {{mensaje}} </div>
            <div class="mt-2"><b> Solución:</b> {{solucion}} </div>
            <v-divider></v-divider>
            <div class="mt-2" v-if="activeFaltas"><b><h4>Sin participación:</h4></b></div>
            <div v-for="(faltas , i) in alumnoEstatus.faltas" :key="i" v-if="activeFaltas">
              <div>{{faltas}}</div>
            </div>

            <div class="mt-2" v-if="activeEjercicios"><b><h4>Ejercicios:</h4></b></div>
            <div v-for="(ejercicio , i) in ejercicios" :key="i" v-if="activeEjercicios">
              <div>{{ejercicio.nombre}}</div>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialogEstatus = false"
            >Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>

<script>
import axios from 'axios';
	import XLSX from 'xlsx'
	
	import {mapGetters, mapActions} from 'vuex'
  var moment = require('moment');
  moment.locale(); 

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      activeEjercicios: true,
      ejercicios:[],

      activeFaltas: false,

      dialogEstatus:false,
      alumnoEstatus:{},
      tipoEstatus: 0,
      mensaje:'',
      solucion:'',

    	loading:false,
    	search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Matricula',      value: 'matricula' },
        {
          text: 'Alumno',
          align: 'start',
          value: 'nombre',
        },
        { text: 'Grupo',          value: 'grupo' },
        { text: 'Curso',          value: 'curso' },
        { text: 'Nivel',          value: 'nivel' },
        { text: 'Participación',  value: 'estatus' },
        { text: 'Ejercicios',     value: 'estatus_ejercicios' },
        { text: 'Examenes',       value: 'estatus_examenes' },
        { text: 'Puntos',         value: 'estatus_calificaciones'},
        { text: 'Registro',       value: 'estatus_registro'},
        { text: 'Pago anterioir', value: 'monto'},
      ],
      iderp:0,
      alumnos: [],
      calificaciones:[],
      faltantes:[],
      ciclos:[],
      ciclo:{},
      idciclo:0,
      ciclo: 		null,
      escuela:null
    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    watch:{
      ciclo () {
        if(this.ciclo && this.escuela ){
          this.getDatosRiesgo()
        }
      },


      escuela () {
        if(this.ciclo && this.escuela ){
          this.getDatosRiesgo()
        }
      },
    },


    created () {
      // Pasp 1: Obtener los ciclos
      this.getCiclos()
    },

    methods: {
      getCiclos(){
        this.cargar = true
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCalificaciones () {
        this.cargar = true
        // creamos nuestro objeto con el idciclo
        var payload = { ciclo: this.idciclo, iderp: this.iderp }
        // Mandamos a traer todas las calificaciones de los alumnos y mandamos como paramtero el idciclo
        this.$http.post('riesgo.calificaciones',payload).then(response=>{
          // Mandamos a calcular las calificaciones y mandamos como parametro las calificaciones de los alumno
          //  NOTA: las calificaciones son todas, falta caluclarlas y agruparalas para sacar el proimedio de las calificaciones
          this.calcularCalificaciones(response.data)
          this.cargar = false
        }).catch(error=>{
          this.loading = false
          this.cargar = false
        })
      },

      calcularCalificaciones(value){
        this.cargar = true
        // Creamos un arreglo de las calificaciones
        var arrayCalificaciones = value

        // Sacamos los datos repetidos
        let alumnos = value.map(item=>{
          return [item.nombre,item]
        });

        // Creamos un map de los alumnos
        var alumnosArray = new Map(alumnos); // Pares de clave y valor
        // y ahora si, extraemos los valores unicos
        let unicos = [...alumnosArray.values()]; // Conversión a un array

        // Hacemos un recorrido de todos los valores unicos
        for(const i in unicos){
          // Iniciamos todas las variables que vamos a utilizar para las sumas y conteos
          var extraordinario        = 0

          var sumaCalifEjercicio    = 0
          var cantEjercicio         = 0
          
          var sumaCalifExamen       = 0
          var cantExamen            = 0
          
          var sumaTotal             = 0
          
          var sumaAsistencia        = 0
          var cantAsistencia        = 0
          var sumaFalta             = 0
          var sumaRetardo           = 0
          var sumaJustificada       = 0
          
          var sumaCalifExamenExtra  = 0
          
          // Hacemos un recorrido para saber que datos le pertenecen a que alumno de los valores unicos
          //  Como es un recorrido, esto significa que estamos busando las calificaciones del alumno en la posición i
          for(const j in arrayCalificaciones){
            // Si el alumno es igual al que estamos buscando
            if(unicos[i].id_alumno == arrayCalificaciones[j].id_alumno){
              // Varificamos sus ejercicios
              if(arrayCalificaciones[j].tipo_evaluacion == 1 && arrayCalificaciones[j].idCategoriaEvaluacion==1){
                // Hacemos el conteo y la suma de las calificaciones
                sumaCalifEjercicio = sumaCalifEjercicio + arrayCalificaciones[j].calificacion
                cantEjercicio = cantEjercicio + 1
              }else{
                // Si no es ejercicios es examen (normal o recuperación)
                // Exmane normal o recuperación
                if(arrayCalificaciones[j].tipo_evaluacion == 2 && arrayCalificaciones[j].idCategoriaEvaluacion==2 || arrayCalificaciones[j].tipo_evaluacion == 2 && arrayCalificaciones[j].idCategoriaEvaluacion==1){
                  // Hamos la suma y el conteo
                  sumaCalifExamen = sumaCalifExamen + arrayCalificaciones[j].calificacion
                  cantExamen = cantExamen + 1
                }

                // También verificamos si es extraordianrio, por que también eso es posible, tener un examen extraordinario
                if(arrayCalificaciones[j].tipo_evaluacion == 3 && arrayCalificaciones[j].idCategoriaEvaluacion==1){
                  sumaCalifExamenExtra = sumaCalifExamenExtra + arrayCalificaciones[j].calificacion
                  extraordinario = extraordinario + 1
                }
              }

            }
          }

          // Haciendo el calculo para los puntos obtenidos de asistencias
          sumaAsistencia  = unicos[i].asistencia
          sumaFalta  = unicos[i].falta
          sumaRetardo  = unicos[i].retardo
          sumaJustificada  = unicos[i].justificada
          // Sacamos los puntos de las asistencias
          var ptsAsistencia = (unicos[i].valor_asistencia / unicos[i].total_dias_laborales)
          // Hacemos la suma de los puntos
          var sumaTotalAsistencia = (sumaAsistencia * ptsAsistencia) +  (sumaRetardo * (ptsAsistencia/2))

          // Calculamos los ejercicios y los puntos por ejercicios y los puntos finales
          var divEjercico =  (sumaCalifEjercicio/unicos[i].num_ejercicios)
          var ptsEjercicio = unicos[i].valor_ejercicios/100
          var ptsFinalesEjercicios = divEjercico * ptsEjercicio

          // Calculamos los examenes y los puntos por examenes y los puntos finales
          var divExamenes =  (sumaCalifExamen/unicos[i].num_examenes)
          var ptsExamenes = unicos[i].valor_examenes/100
          var ptsFinalesExamenes = divExamenes * ptsExamenes

          // Vemos la cantidad de asistencias
          cantAsistencia = sumaAsistencia +  sumaRetardo 

          // hacemos el objeto que vamos a insertar a buscar en nuestro arreglo inicial de los alumnos
          //  para poder ahora si a cada alumno ponerle su calificacione
          var payload = {
            id_alumno:              unicos[i].id_alumno,
            diferencia:             unicos[i].diferencia,
            total:                  (ptsFinalesEjercicios + ptsFinalesExamenes + sumaTotalAsistencia).toFixed(2)
          }
          this.calificaciones.push(payload)
        }

        // Ahora comparamos cada una de las calificaciones con la lista de alumnos que tenemos de riesgo, para ingresarle su calificación y ver si esta en riesgo o no por puntors

        for(const m in this.alumnos){
          this.alumnos[m].estatus_calificaciones = 'NULO'
        }

        for(const m in this.alumnos){
          for(const n in this.calificaciones){
            if(this.alumnos[m].id_alumno == this.calificaciones[n].id_alumno){
              this.alumnos[m].calificacion == this.calificaciones[n].total
              if(this.calificaciones[n].diferencia >= 15){
                if(this.calificaciones[n].total < 35){
                  this.alumnos[m].estatus_calificaciones = 'ALTO'
                }
              }
            }

            if(this.alumnos[m].estatus_registro == 'ALTO'){
              this.alumnos[m].estatus_calificaciones = 'ALTO'
              this.alumnos[m].estatus_examenes = 'ALTO'
            }
          }
        }

        for(const i in this.alumnos){
          for(const j in this.alumnos[i].faltas){
            this.alumnos[i].faltas[j] = moment(this.alumnos[i].faltas[j].substr(0,10)).format("LLLL")
          }
        }

        // Quitamos los loader
        this.cargar = false
      },

      // Obtener la información de los laumnos en riesgo de ese ciclo
      getDatosRiesgo () {
        this.cargar = true
        const escuela = this.escuela ? this.escuela : this.getEscuela
      	const payload = {
      		escuela
      	}
        // Primero traemos los que faltan por entrar a la plataforma
        this.$http.post('riesgo.alumnos.registro', payload).then(response=>{
          // Los guardamos
          this.faltantes  = response.data
          // Activamos el loading de la tabla, activamos el loader especial
          this.cargar = true
        	this.loading = true
          // Vaciamos nuestro arreglo de datos 
        	this.alumnos = []
          // Cramos nuestro objeto con el ciclo que vamos a traer
          var payload = { ciclo: this.ciclo, iderp: this.iderp, escuela  }
          // Mandamos a consultar la api de alumnos de riesgo en fast y mandamos como parametro el idciclo
          this.$http.post('riesgo.alumnos.pago',payload).then(response=> {
            var pagos = response.data

        		this.$http.post('riesgo.alumnos',payload).then(response=>{
              // Guardamos los alumnos obtenidos en un arreglo de objetos (alumnos)
            	this.alumnos = response.data

              // Recorremos a los usuarios
              for(const i in this.alumnos){
                // Aquí buscamos si el alumno pertenece a los alumnos que no se han registrado a la plataforma
                const alumno = this.faltantes.find(faltante => faltante.id == this.alumnos[i].id_alumno)
                // Y si si pertenece, le ponemos en estatus_registo "ALTO"
                if(alumno){this.alumnos[i].estatus_registro = 'ALTO'}

                // Ahora buscamos cuanto dinero pago el alumno o si es nuevo ingreso 
                const pago   = pagos.find(pago => pago.id_alumno == this.alumnos[i].id_alumno)
                if(pago){this.alumnos[i].monto = pago.total_pagado}
              }

            	// Ahora, hay que consultar las calificaciones de esos alumnos que están en riesgo
              this.getCalificaciones()
            }).catch( error =>{
              this.validarError( error.response.data.message )
            }).finally( () => { this.cargar = false })
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // EXCEL
      exportar(){
        let xls = {
          data:      this.alumnos,
          tHeaders : ['Codigo','Nombre', 'Sal', 'Labortorio'],
          filterVal: ['codigo','nomart', 'sal', 'lab'],
          nomfile :  "Productos"
        }
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let data = XLSX.utils.json_to_sheet(this.alumnos)
        const workbook = XLSX.utils.book_new()
        const filename = 'Riesgo_General_fast'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });

      },

      abrirEstatus(value, tipo){
        this.cargar = true
        // ver ejercicios no contestados
        var payload = {
          ciclo:      this.getEscuela == 1 ? this.ciclo.id_ciclo : this.ciclo.id_ciclo_relacionado,
          id_alumno:  value.id_alumno,
          nivel:      value.nivel,
          curso:      value.curso,
          escuela:    this.getEscuela,
          id_grupo:   value.id_grupo
        }

        this.$http.post('riesgo.ejercicios',payload).then(response=> {
       
	        this.alumnoEstatus = {}
	        this.dialogEstatus = true
	        this.alumnoEstatus = value

	        // Estatus por inasistencias
	        if(tipo == 1){
	          this.activeFaltas = true

	          this.activeEjercicios = false          
	          if(this.alumnoEstatus.estatus == 'ALTO'){
	            this.tipoEstatus = 'ALTO'
	            this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es ALTO debido a que tiene un alto porcentaje de Sin Participación'
	            this.solucion = 'Hablar con el alumno para indicarle que, a causa de sus inasistencias se encuentra en un estatus alto de reprobarlo'
	          }

	          if(this.alumnoEstatus.estatus == 'MEDIO'){
	            this.tipoEstatus = 'MEDIO'
	            this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es MEDIO debido a que tiene un porcentaje de inasistencias aceptables, pero tiende a convertirse a un riesgo ALTO'
	            this.solucion = 'Hablar con el alumno para indicarle que, a causa de sus inasistencias se encuentra en un estatus medio pero con probabilidades de pasar a un riesgo alto'
	          }

	          if(this.alumnoEstatus.estatus == 'NULO'){
	            this.tipoEstatus = 'NULO'
	            this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es NULO debido a que no a faltado ni una sola vez'
	            this.solucion = 'El alumno va perfecto en sus asistencias en la clase'
	          }
	        }

	        // Estatus por ejercicios
	        if(tipo == 2){
            this.ejercicios = response.data.ejerciciosFaltantes
	          this.activeFaltas = false
	          this.activeEjercicios = true
	          if(this.alumnoEstatus.estatus_ejercicios == 'ALTO'){
	            this.tipoEstatus = 'ALTO'
	            this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es ALTO debido a que no va al corriente con sus ejercicios'
	            this.solucion = 'Hablar con el alumno para ver en que se le puede apoyar para que se ponga al corriente con sus actividades'
	          }

	          if(this.alumnoEstatus.estatus_ejercicios == 'NULO'){
	            this.tipoEstatus = 'NULO'
	            this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es NULO debido a que va al corriente con sus actividades'
	            this.solucion = 'El alumno va perfecto en sus actividades en la clase'
	          }
	        }

	        // Estatus por examenes
	        if(tipo == 3){
            this.ejercicios = response.data.examenesFaltantes
	          this.activeFaltas = false
	          this.activeEjercicios = false
	          if(this.alumnoEstatus.estatus_examenes == 'ALTO'){
	            this.tipoEstatus = 'ALTO'
	            this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es ALTO debido a no ha realizado su examen de medio curso'
	            this.solucion = 'Hablar con el alumno para indicarle que es muy importante realizar sus examenes, ya que valen 25 pts y es importante para acreditar'
	          }

	          if(this.alumnoEstatus.estatus_examenes == 'NULO'){
	            this.tipoEstatus = 'NULO'
	            this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es NULO debido a que ya ha realizado su examen de medio termino'
	            this.solucion = 'El alumno ya realizo su examen de medio termino'
	          }
	        }


	        // Estatus por puntos
	        if(tipo == 4){
	          this.activeFaltas = false
	          this.activeEjercicios = false
	          if(this.alumnoEstatus.estatus_calificaciones == 'ALTO'){
	            this.tipoEstatus = 'ALTO'
	            this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es ALTO debido a que tiene porcentaje de puntos muy bajo y tiende a repobar el curso'
	            this.solucion = 'Hablar con el alumno para indicarle que realice todos sus ejercciios y demás si se le puede apoyar en algo para que se ponga al corriente con actividades, ejercicios y más'
	          }

	          if(this.alumnoEstatus.estatus_calificaciones == 'NULO'){
	            this.tipoEstatus = 'NULO'
	            this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es NULO debido a que su puntaje en el curso va bien'
	            this.solucion = 'El alumno va bien con sus puntos en el curso'
	          }
	        }
	        
	        // Estatus por registo
	        if(tipo == 5){
	          this.activeFaltas = false
	          this.activeEjercicios = false
	          if(this.alumnoEstatus.estatus_registro == 'ALTO'){
	            this.tipoEstatus = 'ALTO'
	            this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es ALTO debido a que no se ha registrado en la plataforma'
	            this.solucion = 'Hablar con el alumno para indicarle que tiene que ingresar a la plataforma, ya que ahí es donde realizará sus ejercicios'
	          }

	          if(this.alumnoEstatus.estatus_registro == 'NULO'){
	            this.tipoEstatus = 'NULO'
	            this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es NULO debido a que ya se ha registrado en la plataforma'
	            this.solucion = 'El alumno ya esta registrado'
	          }
	        }

          this.cargar = false
	      }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getMontos(){
        var payload = { ciclo: this.idciclo, iderp: this.iderp }
        this.$http.post('riesgo.alumnos.pago',payload).then(response=> {
          

        }).catch(error=> {
           console.log(error);
        });
      }
    },
  }
</script>
