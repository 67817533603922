<template>
  <v-container class="mt-5 ml-2">
    <v-row>
      <v-col cols="8">
        <v-card>
          <v-card-title>
            <span class="headline">Total de tickets por estatus</span>
            <div class="ml-2">
              <v-btn color="blue" dark @click="cargar_tickets_por_estatus()">
                <v-icon>mdi-reload</v-icon> Volver a cargar
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div id="app" >
                    <div id="content">
                      <canvas ref="chart" style="max-height: 600px;"></canvas>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import Vue from "vue";
import Chart from "@/plugins/chart";

export default {
  name: "app",
  data: () => ({
    data: [],
    tickets_totales_estatus: { cantidad_tickets: 0, id_area: 0 },
    tickets_totales_Abierto: { cantidad_tickets: 0, id_area: 0 },
    tickets_totales_Asignado: { cantidad_tickets: 0, id_area: 0 },
    tickets_totales_Pendiente: { cantidad_tickets: 0, id_area: 0 },
    tickets_totales_Revision: { cantidad_tickets: 0, id_area: 0 },
    tickets_totales_Respondido: { cantidad_tickets: 0, id_area: 0 },
    tickets_totales_Cerrado: { cantidad_tickets: 0, id_area: 0 },
    tickets_totales_Rechazado: { cantidad_tickets: 0, id_area: 0 },
  }),

  created() {
    this.cargar_tickets_por_estatus();
  },

  methods: {
    cargar_tickets_por_estatus() {
      this.$http.get("reportes.total_tickets_estatus1").then((response) => {
        this.tickets_totales_Abierto = response.data;
      }).catch((error) => {
        console.log(error);
      });

      this.$http.get("reportes.total_tickets_estatus2").then((response) => {
        this.tickets_totales_Asignado = response.data;
      }).catch((error) => {
        console.log(error);
      });

      this.$http.get("reportes.total_tickets_estatus3").then((response) => {
        this.tickets_totales_Pendiente = response.data;
      }).catch((error) => {
        console.log(error);
      });

      this.$http.get("reportes.total_tickets_estatus4").then((response) => {
        this.tickets_totales_Revision = response.data;
      }).catch((error) => {
        console.log(error);
      });

      this.$http.get("reportes.total_tickets_estatus5").then((response) => {
        this.tickets_totales_Respondido = response.data;
      }).catch((error) => {
        console.log(error);
      });

      this.$http.get("reportes.total_tickets_estatus6").then((response) => {
        this.tickets_totales_Cerrado = response.data;
      }).catch((error) => {
        console.log(error);
      });

      this.$http.get("reportes.total_tickets_estatus7").then((response) => {
        this.tickets_totales_Rechazado = response.data;

        var chart = this.$refs.chart;
        var ctx = chart.getContext("2d");
        if(this.myChart != null) {
          this.myChart.destroy();
        }
        this.myChart = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: [
              "Tickets Abiertos: " +
                this.tickets_totales_Abierto[0].cantidad_tickets,
              "Tickets Asignados: " +
                this.tickets_totales_Asignado[0].cantidad_tickets,
              "Tickets Pendientes: " +
                this.tickets_totales_Pendiente[0].cantidad_tickets,
              "Tickets en Revisión: " +
                this.tickets_totales_Revision[0].cantidad_tickets,
              "Tickets Contestados: " +
                this.tickets_totales_Respondido[0].cantidad_tickets,
              "Tickets Resueltos: " +
                this.tickets_totales_Cerrado[0].cantidad_tickets,
              "Tickets Rechazados: " +
                this.tickets_totales_Rechazado[0].cantidad_tickets,
            ],
            datasets: [
              {
                data: [
                  this.tickets_totales_Abierto[0].cantidad_tickets,
                  this.tickets_totales_Asignado[0].cantidad_tickets,
                  this.tickets_totales_Pendiente[0].cantidad_tickets,
                  this.tickets_totales_Revision[0].cantidad_tickets,
                  this.tickets_totales_Respondido[0].cantidad_tickets,
                  this.tickets_totales_Cerrado[0].cantidad_tickets,
                  this.tickets_totales_Rechazado[0].cantidad_tickets,
                ],
                backgroundColor: [
                  "orange",
                  "blue",
                  "red",
                  "grey",
                  "green",
                  "yellow",
                  "black",
                ],
                labels: [
                  "Tickets Abiertos",
                  "Tickets Asignados",
                  "Tickets Pendientes",
                  "Tickets en Revisión",
                  "Tickets Contestados",
                  "Tickets Resueltos",
                  "Tickets Rechazados",
                ],
              },
            ],
          },
        });
      }).catch((error) => {
        console.log(error);
      });
    },
  },

  mounted() {},
};
</script>