<template>
	<div class="mt-2">
    <v-tooltip bottom color="#558B2F">
      <template v-slot:activator="{ on, attrs }">
        <v-chip color="#558B2F"  small dark hide-details v-bind="attrs" v-on="on" class="mr-1">E. | {{ prospecto.cantEventos }} |</v-chip>
      </template>
      <span class="white--text">Eventos generales: {{ prospecto.cantEventos }}</span>
    </v-tooltip>

    <v-tooltip bottom color="#00C853">
      <template v-slot:activator="{ on, attrs }">
        <v-chip color="#00C853"  small dark hide-details v-bind="attrs" v-on="on" class="mr-1">E.H. | {{ prospecto.cantEventosHoy }} |</v-chip>
      </template>
      <span class="white--text">Eventos de hoy: {{ prospecto.cantEventosHoy }}</span>
    </v-tooltip>

    <v-tooltip bottom color="green">
      <template v-slot:activator="{ on, attrs }">
        <v-chip color="green"  small dark hide-details v-bind="attrs" v-on="on" class="mr-1">{{ prospecto.tiempo_transcurrido }} días</v-chip>
      </template>
      <span class="white--text">Ingresó hace: {{ prospecto.tiempo_transcurrido }} días</span>
    </v-tooltip>
  </div>
</template>


<script>
import axios from 'axios';
	export default {
		props:[
			'prospecto'
	  ],
	}
</script>