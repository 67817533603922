<template>
	<v-container fluid>
	  <v-row>
    	<v-col cols="12">
        <v-text-field
          v-model="editedItem.grupo"
          label="* Grupo"
          filled
          hide-details
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
        	:items="planteles"
          v-model="editedItem.id_plantel"
          label="Plantel"
          outlined
          hide-details
          dense
          item-text="plantel"
          item-value="id_plantel"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
        	:items="filterCursos"
          v-model="editedItem.id_curso"
          label="Curso"
          outlined
          hide-details
          dense
          item-text="curso"
          item-value="id_curso"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
        	:items="ciclos"
          v-model="editedItem.id_ciclo"
          label="Ciclo"
          outlined
          hide-details
          dense
          item-text="ciclo"
          item-value="id_ciclo"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
        	:items="horarios"
          v-model="editedItem.id_horario"
          label="Horario"
          outlined
          hide-details
          dense
          item-text="horario"
          item-value="id_horario"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
        	:items="salones"
          v-model="editedItem.id_salon"
          label="Salon"
          outlined
          hide-details
          dense
          item-text="salon"
          item-value="id_salon"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
        	:items="niveles"
          v-model="editedItem.id_nivel"
          label="Nivel"
          outlined
          hide-details
          dense
          item-text="nivel"
          item-value="id_nivel"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="editedItem.cupo"
          label="Cupo"
          filled
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="editedItem.precio"
          label="* precio"
          filled
          hide-details
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" class="pt-0">
      	<v-switch 
      		label="¿Activo Si/No?"
          hide-details
          v-if="editedItem.alumnos == 0"
      		v-model="editedItem.activo_sn">
      	</v-switch>
      </v-col>

      <v-col cols="12" sm="6" class="pt-0">
      	<div class="text-subtitle-1"><b>Fecha alta:</b> {{ editedItem.fecha_alta }}</div>
      	<div class="text-subtitle-1"><b>Fecha baja:</b> {{ editedItem.fecha_baja }}</div>
      </v-col>

      <v-col cols="12" align="right">
        <v-btn color="primary" @click="save">Guardar</v-btn>
      </v-col>
    </v-row>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

	// Componentes
  export default {

  	components:{
      Alerta,
      carga,
    },

  	props:[
			'editedItem'
	  ],
    data: () => ({

      // tabla
      teachers:[],
      teacher1: null,
      teacher2: null,

      // Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      ciclos:[],
			salones:[],
			cursos:[],
			planteles:[],
			horarios:[],
			niveles:[],

			editar: false,

			plantel:'',
			ciclo:'',
			curso:'',
			horario:'',
			nivel:'',
			salon:'',
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),

      filterCursos( ){

        if( this.plantel ){
          
          if( this.plantel.match('ONLINE')){
            
            return this.cursos.filter( el => el.curso.match('ONLINE') || el.curso.match('CAMBIO') || el.curso.match('EXCI') )

          }else{
            
            return this.cursos.filter( el => !el.curso.match('ONLINE') )

          }


        }else{
          return []
        }

      }

    },

    watch: {

    	'editedItem.id_plantel'( val ){
    		const existe = this.planteles.find( el=> el.id_plantel == val )
    		this.plantel = existe ? existe.plantel.trim() : ''
        this.editedItem.plantel = this.plantel
        this.editedItem.grupo = `${this.editedItem.plantel} - ${this.editedItem.ciclo} - ${this.editedItem.horario} - ${this.editedItem.nivel}`
    	},

    	'editedItem.id_ciclo'( val ){
    		const existe = this.ciclos.find( el=> el.id_ciclo == val )
    		this.ciclo = existe ? existe.ciclo.trim() : ''
        this.editedItem.ciclo = this.ciclo
        this.editedItem.grupo = `${this.editedItem.plantel} - ${this.editedItem.ciclo} - ${this.editedItem.horario} - ${this.editedItem.nivel}`
    	},

    	'editedItem.id_horario'( val ){
    		const existe = this.horarios.find( el=> el.id_horario == val )
    		this.horario = existe ? existe.horario.trim() : ''
        this.editedItem.horario = this.horario
        this.editedItem.grupo = `${this.editedItem.plantel} - ${this.editedItem.ciclo} - ${this.editedItem.horario} - ${this.editedItem.nivel}`
    	},

    	'editedItem.id_nivel'( val ){
    		const existe = this.niveles.find( el=> el.id_nivel == val )
    		this.nivel = existe ? existe.nivel.trim() : ''
        this.editedItem.nivel = this.nivel
        this.editedItem.grupo = `${this.editedItem.plantel} - ${this.editedItem.ciclo} - ${this.editedItem.horario} - ${this.editedItem.nivel}`
    	},

    	'editedItem.id_curso'( val ){
    		const existe = this.cursos.find( el=> el.id_curso == val )
    		this.curso = existe ? existe.curso.trim() : ''
    		this.editedItem.precio = existe ? existe.precio_nivel : 'NO EXISTE PRECIO'
    	}

    },

    async created () {
    	this.editar =  this.editedItem.id_grupo ? true : false


      await this.getCiclos()
      await this.getSalones()
      await this.getCursos()
      await this.getPlanteles()
      await this.getHorarios()
      await this.getNiveles()
    },

    methods: {
    	save () {

        this.editar =  this.editedItem.id_grupo ? true : false
        
    		if( this.editar ){
	      	// Cargamos al usuario que hizo el cambio
	      	this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
		      this.cargar = true
	        // Lo mandapos por el EP
	        this.$http.put('grupos.update/' + this.editedItem.id_grupo, this.editedItem).then(response=>{
	        	this.validarSuccess( response.data.message )
	        	this.$emit('click')
	        	this.initialize()
	        }).catch( error =>{
	        	this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
    		}else{
    			// Cargamos al usuario que hizo el cambio
	      	this.editedItem['id_usuario']  = this.getdatosUsuario.iderp
	      	this.editedItem['disponibles'] = this.editedItem.cupo
	      	console.log( this.editedItem )
		      this.cargar = true
	        // Lo mandapos por el EP
	        this.$http.post('grupos.add', this.editedItem).then(response=>{
	        	this.validarSuccess( response.data.message )
	        	this.$emit('click')
	        	this.initialize()
	        }).catch( error =>{
	        	this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
    		}
      },

      getCiclos () {
      	this.cargar = true
        this.ciclos = []
        return this.$http.get('ciclos.activos.erp').then(response=>{
        	this.ciclos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getSalones () {
      	this.cargar = true
        this.salones = []
        return this.$http.get('salones.activos').then(response=>{
        	this.salones = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCursos () {
      	this.cargar = true
        this.cursos = []
        return this.$http.get('cursoserp.activos').then(response=>{
        	this.cursos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getHorarios () {
      	this.cargar = true
        this.horarios = []
        return this.$http.get('horarios.activos').then(response=>{
        	this.horarios = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getNiveles () {
      	this.cargar = true
        this.niveles = []
        return this.$http.get('niveles.activos').then(response=>{
        	this.niveles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      validarErrorDirecto ( mensaje ) {
        this.cargar = false 
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
    	},
    },
  }
</script>


