<template>
  <v-container class="mt-4 ml-2">

	  <v-snackbar v-model="alerta.activo" multi-line vertical top right :color="alerta.color" > 
	    <strong> {{alerta.texto}} </strong>
	    <template v-slot:action="{ attrs }">
	      <v-btn color="white" text @click="alerta.activo = false" v-bind="attrs"> Cerrar </v-btn>
	    </template>
	  </v-snackbar>

	  <v-row justify="center">
	    <v-col cols="12">
	    	<v-card>
	    	  <v-card-title primary-title>
	    	    Carga de archivos
	    	    <v-spacer></v-spacer>
            <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload"/>
	    	  </v-card-title>
	    	  <v-card-actions>
	        	<v-btn  
	        		v-if="tableData.length"
	        		color="success" 
	        		dark 
	        		class="mb-2" 
	        		small
	            @click="procesarInformacion()"
	          > Procesar Información</v-btn> 
	    	  </v-card-actions>

	        <v-col cols="12">
	          <v-card flat>
	            <v-alert outlined type="warning"  prominent v-if="!tableData.length && !reportes.length">
	              NO SE HA CARGADO NINGUN DOCUMENTO.
	            </v-alert>

	            <v-card outlined v-if="tableData.length">
	              <v-data-table
	                dense
	                :headers="tableHeader"
	                :items="tableData"
	                item-key="name"
	                :page.sync="page"
	      			    :items-per-page="itemsPerPage"
							    @page-count="pageCount = $event"
	                fixed-header
	                hide-default-footer
	                :height="tamanioPantalla"
	              >
	              	<template v-slot:item.alumno="props">
							   		<span v-if="!props.item.alumno"> Clic para agregar un alumno </span>
							      <v-edit-dialog
							        :return-value.sync="props.item.alumno"
							        @save="save(props.item)"
							        @cancel="cancel"
							        @open="open"
							        @close="close"
							      >
							        {{ props.item.alumno }}
							        <template v-slot:input>
				                <v-autocomplete
				                  outlined
				                  dense
				                  clearable
				                  v-model="props.item.alumno"
				                  :items="alumnos"
				                  label="Selecciona el alumno"
				                  persistent-hint
				                  item-text="nombre_completo"
				                  item-value="nombre_completo"
				                  class="mt-4"
				                >
				                </v-autocomplete>
							        </template>
							      </v-edit-dialog>
							    </template>

	              </v-data-table>
	            </v-card>
	            <div class="text-center pt-2" v-if="tableData.length">
	              <v-pagination v-model="page" :length="pageCount"></v-pagination>
	            </div>
	          </v-card>
	        </v-col>
	    	</v-card>
	    </v-col>
	  </v-row>

     
	</v-container>
</template>

<script>
import axios from 'axios';
import UploadExcelComponent from '@/components/UploadExcel.vue'

import { mapGetters} from 'vuex'
export default {
  components: { 
    UploadExcelComponent
  }, 
  data() {
    return {
    	alumnos:[],
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,

      conceptos:[{ idconcepto:1 , nombre: 'PRESUPUESTO'}, {idconcepto:2, nombre:'PAGO'}, {idconcepto:3, nombre:'REPORTE'},],
      concepto:{},

      municipio:{},

      tableHeader: [ 
				{ text:'codigo'     , value:'codigo'},
        { text:'login'      , value:'login'},
        { text:'password'   , value:'password'},
        { text:'nombre'     , value:'nombre'},
        { text:'status'     , value:'status'},
        { text:'id alumno'  , value:'id_alumno'},
        { text:'alumno'     , value:'alumno'},
      ],   

      tableData: [],  // DATOS A MOSTRAR 
      reportes : [],  // TABLA REPORTES

      alerta: { activo: false, texto:'', color:'error'},
      overlay: false,
      
      codigos:[]
    }
  },

  async created(){
  	await this.getAlumnos()
  },

  computed:{
    ...mapGetters('login',['getdatosUsuario']),
    tamanioPantalla () {
      return this.$vuetify.breakpoint.height -380
    },
  },

  methods: {

  	getAlumnos () {
  		this.alumnos = []
  		return this.$http.get('academico.alumnos.certificacion').then( response =>{
    		this.alumnos = response.data
      }).catch( error =>{
        this.alerta = { activo: true, texto: error.body, color:'error' };
      }).finally(()=>{
        this.overlay = false;
      })
  	},

    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      this.$message({
        message: 'Please do not upload files larger than 1m in size.',
        type: 'warning'
      })

      // console.log('file',file)
      return false
    },

    handleSuccess({results, header }) {
      this.obtenerDatos(results);
    },

    obtenerDatos (results){
      var me = this
      let Temporal  =  results.map(obtenerValoresPresupuesto)
      function obtenerValoresPresupuesto(value, index, array) {
        
        if (value['codigo'] !== null ) {
          value.codigo = value['codigo']
        }

        if (value['login'] !== null ) {
          value.login = value['login']
        }  

        if (value['password'] !== null ) {
          value.password = value['password']
        }
        
        if (value['nombre'] !== null ) {
          value.nombre = value['nombre']
        }

        if (value['status'] !== null ) {
          value.status = value['status']
        }

        value.id_alumno = ''
        value.nombre    = ''
        value.nivel     = ''
        
        return value 
      }
      // console.log('DATOS FINALES',Temporal)
      this.codigos = Temporal
      this.validarCodigos()
    },

    validarCodigos () {
    	this.tableData = []
    	let payload = { codigos: this.codigos }
    	this.$http.post('academico.getcodigos', payload).then( response =>{
    		this.tableData = response.data
      }).catch( error =>{
        this.alerta = { activo: true, texto: error.body, color:'error' };
      }).finally(()=>{
        this.overlay = false;
      })
    },

    async save (item) {
      const { codigo } = item

      let datosNuevos = this.tableData

      if(item.alumno != ''){
        const search = this.alumnos.find( alumno => item.alumno == alumno.nombre_completo);
        if(search){ 
          for(const i in datosNuevos){
            if(datosNuevos[i].alumno == search.nombre_completo){
              datosNuevos[i].id_alumno = search.id_alumno
              datosNuevos[i].nombre    = search.nombre_completo
              await this.updateCodigo( codigo, search.id_alumno, search.id_plantel, search.nombre_completo )
            }
          }
        }
      }

      this.tableData = []
      this.tableData = datosNuevos
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Data saved'
    },

    updateCodigo ( codigo, id_alumno, id_plantel, nombre) {
      let payload = {
        codigo,
        id_alumno,
        id_plantel,
        nombre
      }
      return this.$http.post(`academico.codigo.update`, payload).then(response=>{
        this.swalSuccess(response.data.mensaje)
      }).catch(error=>{
        this.overlay = false
        this.swalError(error)
      })
      
      
    },

    cancel () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },

    open () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    close () {
      console.log('Dialog closed')
    },

    swalError(text) {
    	this.$swal({
			  icon: 'error',
			  title: 'Oops...',
			  text,
			});
    },

    swalSuccess(text){
      this.$swal({
        position: 'top-end',
        icon: 'success',
        title: text,
        showConfirmButton: false,
        timer: 1500
      })
    },
  }
}
</script>