<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Petición Tiempo Extras</span>
            <v-spacer></v-spacer>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="3" >
                <v-text-field
                  filled          
                  label="Fecha"
                  type="date"
                  hide-details
                  v-model="fecha"
                  dense
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>  
              <v-col cols="3" md="3" lg="3">
                <v-autocomplete
                  label="Estatus"
                  id="id"
                  filled
                  dense
                  hide-details
                  v-model="estatus"
                  :items="[
                    { id: 0, nombre: 'En revisión' },
                    { id: 1, nombre: 'Aceptado'  },
                    { id: 2, nombre: 'Rechazado' },             
                  ]"
                  item-text="nombre"
                  item-value="id"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="filterHorasExtras"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                > 

                  <template v-slot:item.cantidad_horas="{ item }">
                    <span v-if="item.tipo == 1">1 Día</span>
                    <span v-if="item.tipo == 2">{{ item.cantidad_horas }}</span>
                  </template>

                  <template v-slot:item.escuela="{ item }">
                    <v-chip color="blue"  small dark v-if="item.escuela == 1"
                      >INBI</v-chip
                    >
                    <v-chip color="red" small dark v-if="item.escuela == 2"
                      >FAST</v-chip
                    >
                  </template>


                  <template v-slot:item.estatus="{ item }">
                    <v-chip color="orange"  small dark v-if="item.estatus == 0"
                      >En revisión</v-chip
                    >
                    <v-chip color="green" small dark v-if="item.estatus == 1"
                      >Aceptado</v-chip
                    >
                    <v-chip color="black" small dark v-if="item.estatus == 2"
                      >Rechazado</v-chip
                    >
                  </template>

                  <template v-slot:item.historial="{ item }">
                    <v-btn
                      color="primary"
                      dark
                      class="text-capitalize rounded"
                      style="text-transform: none"
                      @click="
                        (idhistorial = item),
                          (dialog_mensaje_rechazo = true),
                          getMensajesRechazo()
                      "
                      small
                      tile
                    >
                      Responder
                    </v-btn>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon small @click="deleteItem(item)" color="error">
                      mdi-delete
                    </v-icon>
                  </template>

                  <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize" small>
                      Actualizar
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="text-subtitle-1">
          <span >{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <label>Selecciona una opción para continuar</label>
              <v-radio-group
                v-model="editedItem.tipo"
                row
              >
                <v-radio
                  label="Día extra"
                  :value="1"
                ></v-radio>
                <v-radio
                  label="Horas extra"
                  :value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>

          </v-row>

          <v-row v-if="editedItem.tipo">


            <!-- Estas opciones son de horas extra -->
            <v-col cols="12" md="6" v-if="editedItem.tipo == 2">   
              <v-text-field 
                v-model="editedItem.hora_inicio" 
                label="Hora Inicio:" 
                dense 
                type="time"
                filled
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="editedItem.tipo == 2">   
              <v-text-field 
                v-model="editedItem.hora_fin" 
                label="Hora Fin:" 
                dense 
                type="time"
                filled
                hide-details
                :disabled="editedItem.hora_inicio == null || editedItem.hora_inicio == ''"
                @input="validarHoraFin"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.fecha"
                label="Fecha"
                filled
                hide-details
                dense
                type="date"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="editedItem.tipo == 2">
              <v-text-field
                v-model="editedItem.cantidad_horas"
                label="Total Horas Extras"      
                filled
                hide-details
                dense
                type="number" 
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="editedItem.tipo == 2">
              <v-text-field
                v-model="editedItem.cantidad_minutos"
                label="Total Minutos Extras"      
                filled
                hide-details
                dense
                type="number" 
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="editedItem.notas"
                label="Nota (Opcional)"
                outlined
                hide-details
                dense
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="close"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            tile
            dark
            small
            @click="save"
          >
            <v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar tus horas extras?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      large
                      class="mr-2"
                      @click="closeDelete()"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      large
                      @click="deleteItemConfirm()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_mensaje_rechazo" max-width="1000px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1"><b>En caso de rechazo, favor de enviar mensaje de aclaración:</b></span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
            <strong>ID:</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row v-for="(item, index) in this.mensajes" :key="index">
            <v-col cols="12">
              <v-textarea
                v-model="item.nota"
                :id="'motivo_salida_' + index"
                label="Motivo del rechazo"
                outlined
                hide-details
                dense
                auto-grow
                readonly
                style="max-width: 900px; margin-right: auto"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="item.nota_respuesta"
                :id="'motivo_salida_' + index"
                label="Respuesta..."
                outlined
                hide-details
                dense
                auto-grow
                readonly
                style="max-width: 900px; margin-left: auto"
                :class="{ 'd-none': !item.nota_respuesta }"
              ></v-textarea>
            </v-col> </v-row
          ><br />
          <v-row
            style="border: 1px solid gray; padding: 2px; border-radius: 5px"
            v-if="idhistorial.estatus == 0 || idhistorial.estatus == 2"
          >
            <v-col cols="12" :style="{ paddingTop: '10px' }">
              <v-textarea
                v-model="editedItem2.mensaje"
                label="Escriba su mensaje..."
                outlined
                hide-details
                dense
                auto-grow
                :readonly="idhistorial.estatus != 2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (dialog_mensaje_rechazo = false), (editedItem2.mensaje = '')
            "
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            v-if="idhistorial.estatus == 2"
            @click="enviarMensajeRespuesta()"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "ID", align: "start", value: "idhoras_extras" },
      { text: "Nombre", value: "nombre_completo" },
      { text: "Fecha", value: "fecha" },
      { text: "Hora Inicio", value: "hora_inicio" },
      { text: "Hora Fin", value: "hora_fin" },
      { text: "Horas Extras", value: "cantidad_horas" },
      { text: "Minutos Extras", value: "cantidad_minutos" },
      { text: "Escuela", value: "escuela" },
      { text: "Plantel", value: "plantel" },
      { text: "Razón/Notas", value: "notas" },
      { text: "Estatus", value: "estatus" },
      { text: "Responder", value: "historial" },
      { text: "Eliminar", value: "actions", sortable: false },
    ],
    horasextras: [],
    editedIndex: -1,
    editedItem: {
      idhoras_extras: "",
      fecha: "",
      hora_inicio: "",
      hora_fin: "",
      cantidad_horas: 0,
      cantidad_minutos: 0,
      notas: null,
      estatus: 0,
      id_creo: "",
      tipo: null
    },
    defaultItem: {
      idhoras_extras: "",
      fecha: "",
      hora_inicio: "",
      hora_fin: "",
      cantidad_horas: 0,
      cantidad_minutos: 0,
      notas: null,
      estatus: 0,
      id_creo: "",
      tipo: null
    },

    dialogSuccess: false,
    overlay: false,
    dialogError: false,
    error: "",
    successMensaje: "",
    errorBack: false,
    textoCopiado: "COPIAR",
    idcurso: 0,
    info: 0,
    dialog_mensaje_rechazo: false,
    idhistorial: [],
    fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    editedItem2: {
      mensaje: "",
    },
    mensajes: [],

    ciclos: [],
    ciclo: null,
    estatus: null,
    alumnos: [],
    reglas: [],
    cursosEscuela: [],
    horasExtras: "",
    // tabla
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
    
    formTitle() {
      return this.editedIndex === -1
        ? "Agregar Horas Extras"
        : "Editar Horas Extras";
    },

    filterHorasExtras() {

       if (this.estatus ||  this.estatus == 0) {        
        return this.horasextras.filter((el) => {
          return el.estatus === this.estatus;
        });
      }
      else {
        return this.horasextras;
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "editedItem.escuela"(val) {
      this.cursosEscuela = this.cursos.filter((el) => {
        return el.escuela == val;
      });
    },
  },

  created() {
    this.initialize();
    // this.getCiclos();
    // this.getAlumnos();
    // this.getReglas();
  },

  methods: {
    initialize() {
      this.horasextras = [];
      this.overlay = true;
      const payload = {
      id_creo: this.getdatosUsuario.iderp,
      fecha: this.fecha
      };

      this.$http
        .post("horas_extras.get.horasextras", payload)
        .then((response) => {
          this.horasextras = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    editItem(item) {
      this.editedIndex = this.incidencias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.horasextras.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // Lo mandapos por el EP
      this.editedItem.deleted = 1;

      this.$http
        .put(
          "horas_extras.update/" + this.editedItem.idhoras_extras,
          this.editedItem
        )
        .then((response) => {
          this.dialogSuccess = true;
          this.overlay = false;
          this.closeDelete();
          this.initialize();
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      //   if (this.editedItem.regla == "") {
      //     this.error = "Favor de llenar todos los campos";
      //     this.errorBack = false;
      //     this.overlay = false;
      //     this.dialogError = true;
      //     return;
      //   }
      if (this.editedIndex > -1) {
        // Activamos el loader
        this.overlay = true;
        this.editedItem.deleted = 0;
        // Lo mandapos por el EP
        this.$http
          .put(
            "reglamento.escuela.update/" + this.editedItem.idreglamento,
            this.editedItem
          )
          .then((response) => {
            this.dialogSuccess = true;
            this.initialize();
            this.overlay = false;
          })
          .catch((error) => {
            // INdicamos que hay un error en el back
            if (error.body.message) {
              this.error = error.body.message;
            } else {
              this.error = error.body;
            }
            this.errorBack = true;
            this.overlay = false;
            this.dialogError = true;
          });
      } else {
        // Activamos el loader
        this.overlay = true;
        this.editedItem.id_creo = this.getdatosUsuario.iderp;
        // this.editedItem.cantidad_horas = this.editedItem.cantidad_horas;
        // Lo mandapos por el EP

        this.$http
          .post("horas_extras.add.horasextras", this.editedItem)
          .then((response) => {
            this.dialogSuccess = true;
            this.initialize();
            this.overlay = false;
          })
          .catch((error) => {
            // INdicamos que hay un error en el back
            if (error.body.message) {
              this.error = error.body.message;
            } else {
              this.error = error.body;
            }
            this.errorBack = true;
            this.overlay = false;
            this.dialogError = true;
          });
      }
      this.close();
    },

    copiarPortapapeles() {
      navigator.clipboard
        .writeText(this.error)
        .then(() => {
          this.textoCopiado = "COPIADO";
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });
    },

    //Trae la informacion de toda la fila de alumnos en "Agregar Incidencia"
    infoAlumnos() {
      const alumnoSeleccionado = this.alumnos.find(
        (alumno) => alumno.iderp === this.editedItem.idusuarioerp
      );

      this.info = alumnoSeleccionado.tipo_curso;
     
    },

    getMensajesRechazo() {
      this.cargar = true;
      this.mensajes = [];

      const payload = {
        idhoras_extras: this.idhistorial.idhoras_extras,
        iderp: this.idhistorial.id_creo,
      };
      return this.$http
        .post("consultar.mensajes.rechazo.horasextras", payload)
        .then((response) => {
          this.mensajes = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

  enviarMensajeRespuesta() {
      this.cargar = true;
      const payload = {
        nota_respuesta: this.editedItem2.mensaje,
        idhoras_extras: this.idhistorial.idhoras_extras,
        idhoras_extras2: this.idhistorial.idhoras_extras,
        iderp: this.idhistorial.id_creo,
        iderp2: this.idhistorial.id_creo,

        id: this.idhistorial.idhoras_extras,
        estatus: 0,
      };

    
      return this.$http
        .post("update.horasextras.mensaje.respuesta", payload)
        .then((response) => {
          this.$http
            .post("update.estatus.horasextras", payload)
            .then((response) => {
              this.validarSuccess(response.data.message);
              this.cargar = false;
              this.dialog_mensaje_rechazo = false;
              this.initialize();
              this.editedItem2.mensaje = null;
            })
            .catch((error) => {
              this.validarError(error);
            })
            .finally(() => {
              this.cargar = false;
            });
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    validarHoraFin() {
      if (
        this.editedItem.hora_inicio !== null &&
        this.editedItem.hora_fin !== null
      ) {
        // Obtener objetos de fecha y hora para hora_inicio y hora_fin
        const horaInicio = new Date(`2023-09-26T${this.editedItem.hora_inicio}`);
        const horaFin = new Date(`2023-09-26T${this.editedItem.hora_fin}`);

        // Realizar la comparación de horas
        if (horaFin <= horaInicio) {
          // Hora Fin es menor o igual a Hora Inicio, lo cual es inválido
          // Puedes manejar esto según tus necesidades, por ejemplo, mostrar un mensaje de error.
          // En este ejemplo, simplemente establecemos Hora Fin en nulo.
          this.editedItem.hora_fin = null;
        }
      }

    },  
  },
};
</script>
