var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-4 ml-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadowCard"},[_c('v-card-title',{staticClass:"text-subtitle-1"},[_vm._v(" Grupo Teacher "),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.getGruposTeachers()}}},[_vm._v("Consultar")]),_c('v-btn',{attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.getGruposTeachersERP()}}},[_vm._v("Cargar del ERP")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('v-autocomplete',{attrs:{"filled":"","dense":"","clearable":"","items":_vm.ciclos,"label":"Selecciona ciclo actual","persistent-hint":"","hide-details":"","return-object":"","item-text":"ciclo","item-value":"id_ciclo"},model:{value:(_vm.cicloInf),callback:function ($$v) {_vm.cicloInf=$$v},expression:"cicloInf"}})],1)],1),(_vm.gruposTeachers)?_c('v-tabs',{staticClass:"mt-4",attrs:{"dense":"","background-color":"white"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.items),function(item,i){return _c('v-tab',{key:i},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(item.icon)}}),_vm._v(_vm._s(item.name)+" ")],1)}),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{staticClass:"elevation-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3","md":"3","lg":"3"}},[_c('v-autocomplete',{attrs:{"label":"Grupo/Curso","id":"id","filled":"","dense":"","hide-details":"","items":[
                            { id: 'LUNES A VIERNES', nombre: 'Lunes a Viernes'  },
                            { id: 'LUNES MIERCOLES Y VIERNES', nombre: 'Lunes, Miercoles y Viernes' },       
                            { id: 'SABATINO', nombre: 'Sabatino' },    
                            { id: 'DOMINICAL', nombre: 'Dominical' },        
                            { id: 'CERTIFICACION', nombre: 'Certificacion' },    
                            { id: 'INDUCCION', nombre: 'Induccion' } ],"item-text":"nombre","item-value":"id","clearable":""},model:{value:(_vm.cursoFAST),callback:function ($$v) {_vm.cursoFAST=$$v},expression:"cursoFAST"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","filled":"","dense":"","hide-details":""},model:{value:(_vm.search1),callback:function ($$v) {_vm.search1=$$v},expression:"search1"}})],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"search":_vm.search1,"dense":"","headers":_vm.headers,"items":_vm.filterGruposFAST,"item-key":"id"},scopedSlots:_vm._u([{key:"item.curso",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.curso,"large":"","cancel-text":"Cancelar","save-text":"Guardar"},on:{"update:returnValue":function($event){return _vm.$set(props.item, "curso", $event)},"update:return-value":function($event){return _vm.$set(props.item, "curso", $event)},"save":function($event){return _vm.save( 2, props.item )}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-autocomplete',{staticClass:"mt-4",attrs:{"filled":"","dense":"","clearable":"","items":_vm.cursosFAST,"label":"Selecciona el curso","persistent-hint":"","item-text":"nombre","item-value":"id"},model:{value:(props.item.id_curso),callback:function ($$v) {_vm.$set(props.item, "id_curso", $$v)},expression:"props.item.id_curso"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.curso)+" ")])]}}],null,false,2076745219)})],1)],1)],1),_c('v-tab-item',[_c('v-card',{staticClass:"elevation-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3","md":"3","lg":"3"}},[_c('v-autocomplete',{attrs:{"label":"Grupo/Curso","id":"id","filled":"","dense":"","hide-details":"","items":[
                            { id: 'LUNES A VIERNES', nombre: 'Lunes a Viernes'  },
                            { id: 'LUNES MIERCOLES Y VIERNES', nombre: 'Lunes, Miercoles y Viernes' },       
                            { id: 'SABATINO', nombre: 'Sabatino' },    
                            { id: 'DOMINICAL', nombre: 'Dominical' },        
                            { id: 'CERTIFICACION', nombre: 'Certificacion' },    
                            { id: 'INDUCCION', nombre: 'Induccion' }, 
                            { id: 'TEENS SABATINO', nombre: 'TEENS Sabatino' }, 
                            { id: 'TEENS LUN Y MIE', nombre: 'TEENS Lunes y Miercoles' }, 
                            { id: 'TEENS MAR Y JUE', nombre: 'TEENS Martes y Jueves' }
                          ],"item-text":"nombre","item-value":"id","clearable":""},model:{value:(_vm.cursoINBI),callback:function ($$v) {_vm.cursoINBI=$$v},expression:"cursoINBI"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","filled":"","dense":"","hide-details":""},model:{value:(_vm.search2),callback:function ($$v) {_vm.search2=$$v},expression:"search2"}})],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"search":_vm.search2,"dense":"","headers":_vm.headers,"items":_vm.filterGruposINBI,"item-key":"id"},scopedSlots:_vm._u([{key:"item.curso",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.curso,"large":"","cancel-text":"Cancelar","save-text":"Guardar"},on:{"update:returnValue":function($event){return _vm.$set(props.item, "curso", $event)},"update:return-value":function($event){return _vm.$set(props.item, "curso", $event)},"save":function($event){return _vm.save( 1, props.item )}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-autocomplete',{staticClass:"mt-4",attrs:{"filled":"","dense":"","clearable":"","items":_vm.cursosINBI,"label":"Selecciona el curso","persistent-hint":"","item-text":"nombre","item-value":"id"},model:{value:(props.item.id_curso),callback:function ($$v) {_vm.$set(props.item, "id_curso", $$v)},expression:"props.item.id_curso"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.curso)+" ")])]}}],null,false,3753862892)})],1)],1)],1),_c('v-tab-item',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-card',{staticClass:"elevation-0"})],1)],1)],1)],2):_vm._e()],1)],1)],1)],1),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar)?_c('carga'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }