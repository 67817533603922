import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import vuetify from '@/plugins/vuetify';

export default{
	namespaced: true,
	state:{
		login:false,
		datosUsuario:'',
		escuela: 1,
		drawer: false,
		prospecto:null,
		estatusLlamada: 0,
		estatusLlamadaRecluta:0,
		tareas:[],
		nuevosProspectos:0
	},

	mutations:{
		LOGEADO(state, value){
			state.login = value
		},

		DATOS_USUARIO(state, datosUsuario){
      state.datosUsuario = datosUsuario
		},

		SALIR(state){
			state.login = false
			state.datosUsuario = ''
			state.clientes = []
			state.cliente = ''
			state.vendedor = ''
		},

		CAMIAR_ESCUELA(state, escuela){
      state.escuela = escuela
		},

		ABRIR_PROSPECTO(state, prospecto){
			state.prospecto = prospecto
		},

		CAMBIAR_ESTATUS_LLAMADA(state, estatusLlamada){
			state.estatusLlamada = estatusLlamada
		},

		ADD_TAREAS(state, tareas){
			state.tareas = tareas
		},

		ADD_NUEVOS_PROSPECTOS(state, nuevos){
			state.nuevosProspectos = nuevos
		},
	},

	actions:{
		// Valida si el usario existe en la BD
		validarUser({commit}, usuario){
			return new Promise((resolve, reject) => {
			 // console.log (usuario)
			  Vue.http.post('sessions', usuario).then(respuesta=>{
			  	return respuesta.json()
			  }).then(respuestaJson=>{
	         // console.log('respuestaJson',respuestaJson)
					if(respuestaJson == null){
						resolve(false) 
					}else{
						resolve(respuestaJson) 
        	}
      	}, error => {
        	reject(error)
      	})
			})
		},

		guardarInfo({commit, dispatch}, usuario){
			commit('DATOS_USUARIO',usuario)
			commit('LOGEADO',true)
		},

		GetInfoUser({commit, dispatch}, usuario){
			return new Promise((resolve, reject) => {
			  Vue.http.post('sessions', usuario).then(response=>{
					if(!response.data.length){
						resolve(false) 
					}else{
						if(response.data[0].estatus == 0){
							resolve('Tienes que validar tu correo') 
						}else if(response.data[0].estatus == 1){
							resolve('No tienes acceso lo sentimos') 
						}else{
	        		commit('DATOS_USUARIO',response.data[0])
							commit('LOGEADO', true)
							resolve(true)
						}
	      	}
	    	}, error => {
	      	resolve(false)
	    	})
			})
		},

		cambiarEstatusLlamada({commit},estatusLlamada){
  		commit('CAMBIAR_ESTATUS_LLAMADA',estatusLlamada)
		},

		salirLogin({commit}){
			commit('SALIR')
		},

		cambiarEscuela({commit}, escuela){
			commit('CAMIAR_ESCUELA', escuela)
		},

		abrirProspecto({commit},prospecto){
			return new Promise((resolve, reject) => {
			  // Vue.http.get('prospectos.prospecto/' + idprospecto).then(response=>{
			  	// console.log(response)
      		commit('ABRIR_PROSPECTO',prospecto)
      		// commit('ABRIR_PROSPECTO',response.data[0])
					resolve(prospecto)
					// resolve(response.data[0])
	    	// }, error => {
	      	// resolve(false)
	    	// })
			})
		},

		addTareas({commit}, tareas){
			commit('ADD_TAREAS', tareas)
		},

		traerProspectosNuevos({commit}, iderp){
			// Vue.http.get('prospectos.nuevos/' + iderp).then(respuesta=>{
			// 	commit('ADD_NUEVOS_PROSPECTOS', respuesta.data.nuevos)
    	// }).catch(error=>{
       
      // })
		},
	},

	getters:{
		getLogeado(state){
		  return state.login
		},

		getdatosUsuario(state){
			return state.datosUsuario
		},

		getEscuela ( state ){
			return state.escuela
		},

		getProspecto (state){
			return state.prospecto
		},

		getEstatusLlamada(state){
			return state.estatusLlamada
		},

		getEstatusLlamadaRecluta(state){
			return state.estatusLlamadaRecluta
		},

		getTareas(state){
			return state.tareas
		},

		getNuevosProspectos(state){
			return state.nuevosProspectos
		}

	}
}