import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';
import axios from 'axios';
import XLSX from 'xlsx'

export default {
	data: () => ({

		horasImpartidasQ:0,
		horasImpartidasAntQ:0,

  }),

  created(){
  },

  watch: {
  },

  computed:{
  	totalHoras(){
  		return this.totalHorasDominicales + this.totalHorasLV
  	},

  	totalHorasDominicales(){
  		return this.gruposQuincena.filter( el => { return el.deleted == 0 && el.domingo }).map(item => item.horas_catorcena ).reduce((prev, curr) => prev + curr, 0)
  	},

  	totalHorasLV(){
  		return this.gruposQuincena.filter( el => { return el.deleted == 0 && ( !el.sabado && !el.domingo ) }).map(item => item.horas_catorcena ).reduce((prev, curr) => prev + curr, 0)
  	},

  	notasQuincena ( ){

			let payload = ''

			// Comparamos ambas horas impartidas
			if( this.horasImpartidasQ > this.horasImpartidasAntQ ){

				let semanaAct = this.selectedHeaders 

				let semanaAnt = this.selectedHeaders - 1

				for( const i in this.gruposQuincenaAnt ){

					// Si es semana 3, hacemos este proceso
					if( this.selectedHeaders == 1 ){
						if( this.gruposQuincenaAnt[i][`lu${1}`] && !this.gruposQuincenaAnt[i][`lu${3}`] ){
							payload += `\n ${this.gruposQuincenaAnt[i].grupo}`
						}
						if( this.gruposQuincenaAnt[i][`ma${1}`] && !this.gruposQuincenaAnt[i][`ma${3}`] ){
							payload += `\n ${this.gruposQuincenaAnt[i].grupo}`
						}
						if( this.gruposQuincenaAnt[i][`mi${1}`] && !this.gruposQuincenaAnt[i][`mi${3}`] ){
							payload += `\n ${this.gruposQuincenaAnt[i].grupo}`
						}
						if( this.gruposQuincenaAnt[i][`ju${1}`] && !this.gruposQuincenaAnt[i][`ju${3}`] ){
							payload += `\n ${this.gruposQuincenaAnt[i].grupo}`
						}
						if( this.gruposQuincenaAnt[i][`vi${1}`] && !this.gruposQuincenaAnt[i][`vi${3}`] ){
							payload += `\n ${this.gruposQuincenaAnt[i].grupo}`
						}
						if( this.gruposQuincenaAnt[i][`lu${2}`] && !this.gruposQuincenaAnt[i][`lu${4}`] ){
							payload += `\n ${this.gruposQuincenaAnt[i].grupo}`
						}
						if( this.gruposQuincenaAnt[i][`ma${2}`] && !this.gruposQuincenaAnt[i][`ma${4}`] ){
							payload += `\n ${this.gruposQuincenaAnt[i].grupo}`
						}
						if( this.gruposQuincenaAnt[i][`mi${2}`] && !this.gruposQuincenaAnt[i][`mi${4}`] ){
							payload += `\n ${this.gruposQuincenaAnt[i].grupo}`
						}
						if( this.gruposQuincenaAnt[i][`ju${2}`] && !this.gruposQuincenaAnt[i][`ju${4}`] ){
							payload += `\n ${this.gruposQuincenaAnt[i].grupo}`
						}
						if( this.gruposQuincenaAnt[i][`vi${2}`] && !this.gruposQuincenaAnt[i][`vi${4}`] ){
							payload += `\n ${this.gruposQuincenaAnt[i].grupo}`
						}
					}


				}

				return payload

			}
		}
  },

  methods:{
    iniciarQuincena(){

    	this.limpiarGruposQuincena( )

      this.sacarTeachersQ( )

      this.sacarHorasQuincena1( )
	
    	this.sacarTotalesQ( )
    	
    	this.getTablaComparativaQ( )

    	this.getTablaComparativaAntQ( )

    	this.igualarCategoriasQ( )

    	this.motivoDiferenciasQ( )

    	this.formatMoneyQ( )
    },

    limpiarGruposQuincena ( ) {
    	for( const i in this.gruposQuincena ){
    		const { grupo } = this.gruposQuincena[i]
      	if( ( grupo.match('SABATI') && grupo.match('TEENS') ) || ( grupo.match('DOMINICAL') && grupo.match('TEENS') ) ){
    			this.gruposQuincena[i].ju1_n = null
    			this.gruposQuincena[i].ju1   = null
    			this.gruposQuincena[i].vi1_n = null
    			this.gruposQuincena[i].vi1   = null
    			this.gruposQuincena[i].ju2_n = null
    			this.gruposQuincena[i].ju2   = null
    			this.gruposQuincena[i].vi2_n = null
    			this.gruposQuincena[i].vi2   = null
    			this.gruposQuincena[i].ju3_n = null
    			this.gruposQuincena[i].ju3   = null
    			this.gruposQuincena[i].vi3_n = null
    			this.gruposQuincena[i].vi3   = null
    			this.gruposQuincena[i].ju4_n = null
    			this.gruposQuincena[i].ju4   = null
    			this.gruposQuincena[i].vi4_n = null
    			this.gruposQuincena[i].vi4   = null
    		}      		
    	}
    },

    sacarTeachersQ ( ) {

    	let bloqueInicial = [3,4].includes( this.selectedHeaders ) ? 1 : 3
    	let bloqueFinal   = [3,4].includes( this.selectedHeaders ) ? 2 : 4

      // Vamos a sacar los teachers de cada bloque
      let tlu1 = this.gruposQuincena.filter( el => { return ( el.lunes || el.domingo || el.sabado ) && el[`lu${bloqueInicial}_n`] })
      														.map((registro) => { return { id_teacher: registro[`lu${bloqueInicial}`], teacher: registro[`lu${bloqueInicial}_n`]  }})

      let tma1 = this.gruposQuincena.filter( el => { return ( el.martes || el.domingo || el.sabado ) && el[`ma${bloqueInicial}_n`] })
      														.map((registro) => { return { id_teacher: registro[`ma${bloqueInicial}`], teacher: registro[`ma${bloqueInicial}_n`]  }})

      let tmi1 = this.gruposQuincena.filter( el => { return ( el.miercoles || el.domingo || el.sabado ) && el[`mi${bloqueInicial}_n`] })
      														.map((registro) => { return { id_teacher: registro[`mi${bloqueInicial}`], teacher: registro[`mi${bloqueInicial}_n`]  }})

      let tju1 = this.gruposQuincena.filter( el => { return ( el.jueves || el.domingo || el.sabado ) && el[`ju${bloqueInicial}_n`] })
      														.map((registro) => { return { id_teacher: registro[`ju${bloqueInicial}`], teacher: registro[`ju${bloqueInicial}_n`]  }})

      let tvi1 = this.gruposQuincena.filter( el => { return ( el.viernes || el.domingo || el.sabado ) && el[`vi${bloqueInicial}_n`] })
      														.map((registro) => { return { id_teacher: registro[`vi${bloqueInicial}`], teacher: registro[`vi${bloqueInicial}_n`]  }})
      // Vamos a sacar los teachers de cada bloque
      let tlu2 = this.gruposQuincena.filter( el => { return ( el.lunes || el.domingo || el.sabado ) && el[`lu${bloqueFinal}_n`] })
      														.map((registro) => { return { id_teacher: registro[`lu${bloqueFinal}`], teacher: registro[`lu${bloqueFinal}_n`]  }})

      let tma2 = this.gruposQuincena.filter( el => { return ( el.martes || el.domingo || el.sabado ) && el[`ma${bloqueFinal}_n`] })
      														.map((registro) => { return { id_teacher: registro[`ma${bloqueFinal}`], teacher: registro[`ma${bloqueFinal}_n`]  }})

      let tmi2 = this.gruposQuincena.filter( el => { return ( el.miercoles || el.domingo || el.sabado ) && el[`mi${bloqueFinal}_n`] })
      														.map((registro) => { return { id_teacher: registro[`mi${bloqueFinal}`], teacher: registro[`mi${bloqueFinal}_n`]  }})

      let tju2 = this.gruposQuincena.filter( el => { return ( el.jueves || el.domingo || el.sabado ) && el[`ju${bloqueFinal}_n`] })
      														.map((registro) => { return { id_teacher: registro[`ju${bloqueFinal}`], teacher: registro[`ju${bloqueFinal}_n`]  }})

      let tvi2 = this.gruposQuincena.filter( el => { return ( el.viernes || el.domingo || el.sabado ) && el[`vi${bloqueFinal}_n`] })
      														.map((registro) => { return { id_teacher: registro[`vi${bloqueFinal}`], teacher: registro[`vi${bloqueFinal}_n`]  }})

      // Concatenamos a los maestros
      let arregloTeachers = tlu1.concat( tma1 ).concat( tmi1 ).concat( tju1 ).concat( tvi1 ).concat(tlu2).concat( tma2 ).concat( tmi2 ).concat( tju2 ).concat( tvi2 )

      // Quitamos los duplicados de los maestros
      this.teachersQ = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);
    },

    sacarHorasQuincena1 ( ) {

    	let semana = this.selectedHeaders - 1 == -1 ? 4  : this.selectedHeaders - 1 

    	let clasesDobles = this.horasDobles.filter( el => el.tipo == 2 )

    	let bloqueInicial = [1,2].includes( semana ) ? 1 : 3
    	let bloqueFinal   = [1,2].includes( semana ) ? 2 : 4

			// Recorremos los maestros para ir agregando las cosas
			for( const i in this.teachersQ ){

				// Sacamos el id del teacher
				const { id_teacher, teacher } = this.teachersQ[i]

				// Sacamos las clases que le pertenecen al teacher 
				const clasesTeacher = this.gruposQuincena.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																	.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher) || ['lu', 'ma', 'mi', 'ju', 'vi']
																	.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher)) && ( !el.domingo && !el.sabado && el.horas_dia != 1.5 ));

				// SACAR LAS CLASES DOMINICALES
				const clasesTeacherDomingo = this.gruposQuincena.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																			.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																			.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.domingo );

				// SACAR LAS CLASES SABATINO
				const clasesTeacherSabado = this.gruposQuincena.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																			.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																			.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.sabado );

				// CLASES DE 1.5 HORAS
				const clasesLMV = this.gruposQuincena.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																			.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																			.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.horas_dia == 1.5 );

				// Lo separamos por escuela
				const clasesFast = clasesTeacher.filter( el => { return el.id_unidad_negocio == 2})
				const clasesInbi = clasesTeacher.filter( el => { return el.id_unidad_negocio == 1})

				// CLASES FAST DOMINGO
				const clasesFastDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 2})
				const clasesInbiDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 1})

				// CLASES FAST SABADO
				const clasesFastSabado = clasesTeacherSabado.filter( el => { return el.id_unidad_negocio == 2})
				const clasesInbiSabado = clasesTeacherSabado.filter( el => { return el.id_unidad_negocio == 1})

				// CLASES LMV
				const clasesFastLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 2})
				const clasesInbiLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 1})

				let clasesFastLV = 0
				let clasesFastLM = 0
				let clasesFastDom = 0
				let clasesInbiLV = 0
				let clasesInbiLM = 0
				let clasesInbiDom = 0


				// CONTADOR ENTRE SEMANA
				let contadorBloquesFast = 0
				for( const i in clasesFast ){

					// ENTRE SEMANA
					contadorBloquesFast += clasesFast[i][`lu${bloqueInicial}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`ma${bloqueInicial}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`mi${bloqueInicial}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`ju${bloqueInicial}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`vi${bloqueInicial}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0

					// ENTRE SEMANA
					contadorBloquesFast += clasesFast[i][`lu${bloqueFinal}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`ma${bloqueFinal}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`mi${bloqueFinal}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`ju${bloqueFinal}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`vi${bloqueFinal}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0
				}

				clasesFastLV += contadorBloquesFast

				// CONTADOR FAST DOMINGOS
				let contadorBloquesFastDomingo = 0
				for( const i in clasesFastDomingo ){

					// DOMINICALES
					contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

					contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0

					// DOMINICALES
					clasesFastDom += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

					clasesFastDom += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0
				}

				// CONTADOR CLASES DE 1.5 HORAS
				let contadorBloquesFastLMV = 0
				for( const i in clasesFastLMV ){

					// ENTRE SEMANA
					contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

					contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

				}

				// INBIIIIIIIIIII CLASES ENTRE SEMANA
				// CONTADOR ENTRE SEMANA
				let contadorBloquesINBI = 0
				for( const i in clasesInbi ){

					// ENTRE SEMANA BOQUE 1 o 3
					contadorBloquesINBI += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0
					// ENTRE SEMANA BOQUE 2 o 4
					contadorBloquesINBI += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0

					// ENTRE SEMANA BOQUE 1 o 3
					clasesInbiLV += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
					clasesInbiLV += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
					clasesInbiLV += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
					clasesInbiLV += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
					clasesInbiLV += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0
					// ENTRE SEMANA BOQUE 2 o 4
					clasesInbiLV += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
					clasesInbiLV += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
					clasesInbiLV += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
					clasesInbiLV += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
					clasesInbiLV += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0

				}

				// CONTADOR ENTRE SEMANA
				let contadorBloquesInbiDomingo = 0
				for( const i in clasesInbiDomingo ){

					// DOMINICALES
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0

					clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

					clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0

				}

				// CONTADOR ENTRE SEMANA
				let contadorBloquesInbiLMV = 0
				for( const i in clasesInbiLMV ){

					// ENTRE SEMANA
					contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0

					contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0


					clasesInbiLM += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0

					clasesInbiLM += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0
				}


				const existeCategoria = this.pagos.find( el => el.id_usuario  == id_teacher )

				let hora_1   = existeCategoria ? existeCategoria.hora_1 : 0
				let hora_2   = existeCategoria ? existeCategoria.hora_2 : 0
				let hora_3   = existeCategoria ? existeCategoria.hora_3 : 0
				let aceptado = existeCategoria ? existeCategoria.aceptado : 0
				let hsbc     = existeCategoria ? existeCategoria.hsbc     : 0

				// AGREGAREMOS LOS PAGOS
				this.teachersQ[i]['categoria']  = hora_2
				this.teachersQ[i]['aceptado']   = aceptado
				this.teachersQ[i]['hsbc']       = hsbc
				// this.teachersQ[i]['existeCategoria'] = existeCategoria ? existeCategoria.hora_1 : 



				// AGREGAREMOS LOS PAGOS
				this.teachersQ[i]['categoria_lv']  = hora_1
				this.teachersQ[i]['categoria_d']   = hora_3

				/***********************************************************************/
				this.teachersQ[i]['HORAS_ENTRE_SEMANA_FAST']   = contadorBloquesFast
				this.teachersQ[i]['HORAS_DOMINGO_FAST']        = contadorBloquesFastDomingo
				this.teachersQ[i]['HORAS_LMV_FAST']            = contadorBloquesFastLMV

				/************************************************************************/
				this.teachersQ[i]['HORAS_ENTRE_SEMANA_INBI']   = contadorBloquesINBI
				this.teachersQ[i]['HORAS_DOMINGO_INBI']        = contadorBloquesInbiDomingo
				this.teachersQ[i]['HORAS_LMV_INBI']            = contadorBloquesInbiLMV
				/************************************************************************/
				this.teachersQ[i]['HORAS_TOTALES']                = contadorBloquesFast + contadorBloquesFastDomingo + contadorBloquesFastLMV + contadorBloquesINBI + contadorBloquesInbiDomingo + contadorBloquesInbiLMV 
				this.teachersQ[i]['HORAS_TOTALES_LV']             = contadorBloquesFast + contadorBloquesFastLMV + contadorBloquesINBI + contadorBloquesInbiLMV 
				this.teachersQ[i]['HORAS_TOTALES_D']              = contadorBloquesFastDomingo + contadorBloquesInbiDomingo 
				this.teachersQ[i]['PAGO_TOTAL']                = ( contadorBloquesFast * hora_1 ) + ( contadorBloquesFastDomingo * hora_3 ) + ( contadorBloquesFastLMV * hora_1 ) + ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesInbiLMV * hora_1 ) 

				/************************************************************************/

				// CLASES DOBLES
				console.log( 'horas dobles',clasesDobles )
				const pagosDoblesTeachers2 = clasesDobles.filter(el => el.tipo == 2 && [`lu${bloqueInicial}`, `ma${bloqueInicial}`, `mi${bloqueInicial}`, `ju${bloqueInicial}`, `vi${bloqueInicial}`].includes( el.bloque ) && el.id_teacher_reemplazo == id_teacher || el.tipo == 2 && [`lu${bloqueFinal}`, `ma${bloqueFinal}`, `mi${bloqueFinal}`, `ju${bloqueFinal}`, `vi${bloqueFinal}`].includes( el.bloque ) && el.id_teacher_reemplazo == id_teacher );
				const pagosDoblesTeachers1 = clasesDobles.filter(el => el.tipo == 1 && [`lu${bloqueInicial}`, `ma${bloqueInicial}`, `mi${bloqueInicial}`, `ju${bloqueInicial}`, `vi${bloqueInicial}`].includes( el.bloque ) && el.id_teacher_reemplazo == id_teacher || el.tipo == 1 && [`lu${bloqueFinal}`, `ma${bloqueFinal}`, `mi${bloqueFinal}`, `ju${bloqueFinal}`, `vi${bloqueFinal}`].includes( el.bloque ) && el.id_teacher_reemplazo == id_teacher );

				const rebajesDobles = clasesDobles.filter(el => [`lu${bloqueInicial}`, `ma${bloqueInicial}`, `mi${bloqueInicial}`, `ju${bloqueInicial}`, `vi${bloqueInicial}`].includes( el.bloque ) && el.id_teacher_falto == id_teacher || [`lu${bloqueFinal}`, `ma${bloqueFinal}`, `mi${bloqueFinal}`, `ju${bloqueFinal}`, `vi${bloqueFinal}`].includes( el.bloque ) && el.id_teacher_falto == id_teacher);

				let pagoDoble2  = pagosDoblesTeachers2.map(item => item.monto_pago).reduce((prev, curr) => prev + curr, 0)
				let pagoDoble1  = pagosDoblesTeachers1.map(item => item.monto_pago).reduce((prev, curr) => prev + curr, 0)
				let rebajeDoble = rebajesDobles.map(item => item.monto_rebaje).reduce((prev, curr) => prev + curr, 0)

				
				this.teachersQ[i]['PAGO_DOBLES'] = ( pagoDoble2  + pagoDoble1 )
				this.teachersQ[i]['REBAJES']     = rebajeDoble
				this.teachersQ[i]['PAGO_SABADO'] = this.format( 0 )

				/************************************************************************/
			}
    },

    sacarTotalesQ ( ){

    	// this.teachersQ.push({
    	// 	id_teacher: 100000,
      //   teacher   : 'TOTAL',
      //   HORAS_ENTRE_SEMANA_FAST  : this.teachersQ.map(item => item.HORAS_ENTRE_SEMANA_FAST).reduce((prev, curr) => prev + curr, 0),
      //   HORAS_DOMINGO_FAST       : this.teachersQ.map(item => item.HORAS_DOMINGO_FAST).reduce((prev, curr) => prev + curr, 0),
      //   HORAS_LMV_FAST           : this.teachersQ.map(item => item.HORAS_LMV_FAST).reduce((prev, curr) => prev + curr, 0),
      //   HORAS_ENTRE_SEMANA_INBI  : this.teachersQ.map(item => item.HORAS_ENTRE_SEMANA_INBI).reduce((prev, curr) => prev + curr, 0),
      //   HORAS_DOMINGO_INBI       : this.teachersQ.map(item => item.HORAS_DOMINGO_INBI).reduce((prev, curr) => prev + curr, 0),
      //   HORAS_LMV_INBI           : this.teachersQ.map(item => item.HORAS_LMV_INBI).reduce((prev, curr) => prev + curr, 0),
      //   HORAS_TOTALES            : this.teachersQ.map(item => item.HORAS_TOTALES).reduce((prev, curr) => prev + curr, 0),
      //   PAGO_TOTAL               : this.teachersQ.map(item => item.PAGO_TOTAL).reduce((prev, curr) => prev + curr, 0),
    	// })

    	// Primero, hay que igualar estás madres jajajaja

    	for( const i in this.teachersQ ){

    		const { id_teacher } = this.teachersQ[i]

    		const existeTeacher = this.teachers.find( el => el.id_teacher == id_teacher )

    		if( !existeTeacher ){

    			this.teachers.push( this.teachersQ[i] )

    		}

    	}



    	for( const i in this.teachers ){

    		const { id_teacher } = this.teachers[i]

    		const existeTeacher = this.teachersQ.find( el => el.id_teacher == id_teacher )

    		this.teachers[i]['pago_quincena'] = existeTeacher ? this.format( existeTeacher.PAGO_TOTAL ) : '$0.00' 
    		this.teachers[i]['PAGO_DOBLES']   = existeTeacher ? this.format( existeTeacher.PAGO_DOBLES ) : '$0.00' 
    		this.teachers[i]['REBAJES']       = existeTeacher ? this.format( existeTeacher.REBAJES ) : '$0.00' 

    		// BONO 
				if( this.selectedHeaders == 1 ){
					
					// let bonos = this.cacularBonoTeachers()
					
					// const existeBono = bonos.find( el => el.id_teacher == id_teacher )

					// let totalBono = existeBono ? this.format( existeBono.bono ) : '$0.00'


					const existeBono = this.bonosTeachers.find( el => el.id_usuario == id_teacher )

					let totalBono = existeBono ? this.format( existeBono.monto ) : '$0.00'

					this.teachers[i]['bono_teacher'] = totalBono

					if( id_teacher == 1869 ){
						this.teachers[i]['bono_teacher'] = '$500.00'
					}

					if( id_teacher == 1839 ){
						this.teachers[i]['bono_teacher'] = '$350.00'
					}

				}


				// Aquí... poner esa cosa de los comentarios
				const existeEstatusQ = this.comentariosT.find( el => el.id_usuario == id_teacher && el.semana == this.selectedHeaders && el.tipo_nomina == 2 )

				this.teachers[i]['estatus_quincena']     = existeEstatusQ ? existeEstatusQ.estatus : 0 
				this.teachers[i]['comentarios_quincena'] = existeEstatusQ ? existeEstatusQ.comentarios : [] 

				// Aquí... poner esa cosa de los comentarios
				const existeEstatusB = this.comentariosT.find( el => el.id_usuario == id_teacher && el.semana == this.selectedHeaders && el.tipo_nomina == 3 )

				this.teachers[i]['estatus_bono']     = existeEstatusB ? existeEstatusB.estatus : 0 
				this.teachers[i]['comentarios_bono'] = existeEstatusB ? existeEstatusB.comentarios : [] 

    	}

    	// AHORA LES DAMOS LOS REBAJES
    	for( const i in this.teachers ){

    		const { id_teacher, pago_quincena, PAGO_SABADO, bono_teacher, teacher, pagoDoble, rebajeDoble, PAGO_DOBLES, REBAJES } = this.teachers[i]

    		const existeRebaje = this.rebajes.find( el => el.id_teacher == id_teacher )

    		const rebaje =  REBAJES ? REBAJES.replace('$','').replace(',','') : 0
    		const doble  =  PAGO_DOBLES ? PAGO_DOBLES.replace('$','').replace(',','') : 0

    		if( PAGO_DOBLES ){ console.log( PAGO_DOBLES )}
    		// if( pagoDoble ){ console.log( pagoDoble )}

    		this.teachers[i].rebaje = this.format( rebaje ) 

    		let pagoQ = pago_quincena ? pago_quincena.replace('$','').replace(',','') : 0 

    		this.teachers[i].total_final = this.format( parseFloat( pagoQ ) - parseFloat( rebaje ) + parseFloat( doble ) )


    	}

    	this.teachers.push({
    		id_teacher               : 100000,
        teacher                  : 'TOTAL',
        HORAS_SABADO_FAST        : this.teachers.map(item => item.HORAS_SABADO_FAST).reduce((prev, curr) => prev + curr, 0),
        HORAS_SABADO_INBI        : this.teachers.map(item => item.HORAS_SABADO_INBI).reduce((prev, curr) => prev + curr, 0),
        HORAS_SABADO_TEENS       : this.teachers.map(item => item.HORAS_SABADO_TEENS).reduce((prev, curr) => prev + curr, 0),
        TOTAL_HORAS              : this.teachers.map(item => item.TOTAL_HORAS ).reduce((prev, curr) => prev + curr, 0),
        PAGO_SABADO              : this.format( this.teachers.map(item => parseFloat( item.PAGO_SABADO.replace('$','').replace(',','') )).reduce((prev, curr) => prev + curr, 0)),
        HORAS_ENTRE_SEMANA_FAST  : this.teachers.map(item => item.HORAS_ENTRE_SEMANA_FAST).reduce((prev, curr) => prev + curr, 0),
        HORAS_DOMINGO_FAST       : this.teachers.map(item => item.HORAS_DOMINGO_FAST).reduce((prev, curr) => prev + curr, 0),
        HORAS_LMV_FAST           : this.teachers.map(item => item.HORAS_LMV_FAST).reduce((prev, curr) => prev + curr, 0),
        HORAS_ENTRE_SEMANA_INBI  : this.teachers.map(item => item.HORAS_ENTRE_SEMANA_INBI).reduce((prev, curr) => prev + curr, 0),
        HORAS_DOMINGO_INBI       : this.teachers.map(item => item.HORAS_DOMINGO_INBI).reduce((prev, curr) => prev + curr, 0),
        HORAS_LMV_INBI           : this.teachers.map(item => item.HORAS_LMV_INBI).reduce((prev, curr) => prev + curr, 0),
        HORAS_TOTALES            : this.teachers.map(item => item.HORAS_TOTALES).reduce((prev, curr) => prev + curr, 0),
        rebaje                   : this.format( this.teachers.map(item => parseFloat( item.rebaje.replace('$','').replace(',','') )).reduce((prev, curr) => prev + curr, 0)),
        pago_quincena            : this.format( this.teachers.map(item => parseFloat( item.pago_quincena.replace('$','').replace(',','') )).reduce((prev, curr) => prev + curr, 0)),
        total_final              : this.format( this.teachers.map(item => parseFloat( item.total_final.replace('$','').replace(',','') )).reduce((prev, curr) => prev + curr, 0)),
        bono_teacher             : 0, // this.teachers.map(item => parseFloat( item.bono_teacher.replace('$','').replace(',','') )).reduce((prev, curr) => prev + curr, 0),
    	})

    	// Sacar el resultado de avance
			let ids = this.teachersQ.map((registro) => { return registro.id_teacher })

			let comentarios = this.comentariosT.filter( el => el.semana == this.selectedHeaders && ids.includes( el.id_usuario) && el.tipo_nomina == 2 )

			let teachersQSinCero = this.teachers.filter( el => el.pago_quincena != '$0.00' )

			this.cantTeachersQ

			this.porcentajeAceptacionQ = (( comentarios.length /	teachersQSinCero.length ) * 100 ).toFixed(2)


			let comentariosB = this.comentariosT.filter( el => el.semana == this.selectedHeaders && ids.includes( el.id_usuario) && el.tipo_nomina == 3 )
			this.porcentajeAceptacionB = (( comentariosB.length /	this.teachersQ.length ) * 100 ).toFixed(2)
    },

    formatMoneyQ ( ) {

    	for( const i in this.teachersQ ){

    		const { HORAS_ENTRE_SEMANA_FAST, HORAS_DOMINGO_FAST, HORAS_LMV_FAST, HORAS_ENTRE_SEMANA_INBI, HORAS_DOMINGO_INBI, HORAS_LMV_INBI, PAGO_TOTAL } = this.teachersQ[i]

    		this.teachersQ[i].PAGO_TOTAL       = PAGO_TOTAL                        ? this.format( PAGO_TOTAL )                        : '$0.00'
    		this.teachersQ[i]['categoria_lv']  = this.teachersQ[i]['categoria_lv'] ? this.format( this.teachersQ[i]['categoria_lv'] ) : '$0.00'
    		this.teachersQ[i]['categoria_d']   = this.teachersQ[i]['categoria_d']  ? this.format( this.teachersQ[i]['categoria_d'] )  : '$0.00'

    	}
    },

    getTablaComparativaQ( ){

    	// Mi variable de la tabla 1 comparativa = tabla1ComparativaQ

    	// Primero, sacar la semana anterior
    	let getCategoriasLv = this.teachersQ.filter( el => el.id_teacher < 100000 ).map((registro) => { return registro.categoria_lv })
    	let getCategoriasD  = this.teachersQ.filter( el => el.id_teacher < 100000 ).map((registro) => { return registro.categoria_d })

    	this.categorias1Q = [...new Set(getCategoriasLv.concat( getCategoriasD ))].sort((a, b) => b - a) 

    	for( const i in this.categorias1Q ){

    		let teachersCategoriaLv = this.teachersQ.filter( el => el.categoria_lv == this.categorias1Q[i] && el.id_teacher < 100000 )
    		let teachersCategoriaD  = this.teachersQ.filter( el => el.categoria_d == this.categorias1Q[i] && el.id_teacher < 100000 )


    		let totalHorasLv =  teachersCategoriaLv.map(item => item.HORAS_TOTALES_LV ).reduce((prev, curr) => prev + curr, 0)
    		let totalHorasD  =  teachersCategoriaD.map(item => item.HORAS_TOTALES_D ).reduce((prev, curr) => prev + curr, 0)

    		let totalHoras = totalHorasLv + totalHorasD

    		this.tabla1ComparativaQ.push({
    			valor_hora   : this.categorias1Q[i] == null ? 'TEACHER LIDER' : this.categorias1Q[i] ,
    			numero_horas : totalHoras,
    			costo        : this.format( totalHoras * this.categorias1Q[i] )
    		})

    	}

    	this.tabla1ComparativaQ.push({
  			valor_hora   : 'Horas totales',
  			numero_horas : this.gruposQuincena.filter( el => { return el.deleted == 0 }).map(item => item.horas_catorcena ).reduce((prev, curr) => prev + curr, 0),
  			costo        : ''
  		})

  		this.tabla1ComparativaQ.push({
  			valor_hora   : 'Horas impartidas',
  			numero_horas : this.teachersQ.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.HORAS_TOTALES).reduce((prev, curr) => prev + curr, 0),
  			costo        : this.format( this.teachersQ.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.PAGO_TOTAL).reduce((prev, curr) => prev + curr, 0))
  		})

  		this.horasImpartidasQ = this.teachersQ.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.HORAS_TOTALES).reduce((prev, curr) => prev + curr, 0)
  		let costoTotal      = this.teachersQ.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.PAGO_TOTAL).reduce((prev, curr) => prev + curr, 0)


  		this.costoHora2     = this.format( costoTotal / this.horasImpartidasQ )
    },

    getTablaComparativaAntQ( ){

    	let grupos = this.gruposQuincenaAnt.filter( el => { return el.deleted == 0 && !el.sabado })

    	// Limpiar los grupos de los teens
    	for( const i in grupos ){
    		const { grupo } = grupos[i]
      	if( ( grupo.match('SABATI') && grupo.match('TEENS') ) || ( grupo.match('DOMINICAL') && grupo.match('TEENS') ) ){
    			grupos[i].ju1_n = null
    			grupos[i].ju1   = null
    			grupos[i].vi1_n = null
    			grupos[i].vi1   = null
    			grupos[i].ju2_n = null
    			grupos[i].ju2   = null
    			grupos[i].vi2_n = null
    			grupos[i].vi2   = null
    			grupos[i].ju3_n = null
    			grupos[i].ju3   = null
    			grupos[i].vi3_n = null
    			grupos[i].vi3   = null
    			grupos[i].ju4_n = null
    			grupos[i].ju4   = null
    			grupos[i].vi4_n = null
    			grupos[i].vi4   = null
    		}      		
    	}

    	let clasesDobles = []

    	let bloqueInicial = [3,4].includes( this.selectedHeaders ) ? 3 : 1
    	let bloqueFinal   = [3,4].includes( this.selectedHeaders ) ? 4 : 2

      // Vamos a sacar los teachers de cada bloque
      let tlu1 = grupos.filter( el => { return ( el.lunes || el.domingo || el.sabado ) && el[`lu${bloqueInicial}_n`] })
      							.map((registro) => { return { id_teacher: registro[`lu${bloqueInicial}`], teacher: registro[`lu${bloqueInicial}_n`]  }})

      let tma1 = grupos.filter( el => { return ( el.martes || el.domingo || el.sabado ) && el[`ma${bloqueInicial}_n`] })
      							.map((registro) => { return { id_teacher: registro[`ma${bloqueInicial}`], teacher: registro[`ma${bloqueInicial}_n`]  }})

      let tmi1 = grupos.filter( el => { return ( el.miercoles || el.domingo || el.sabado ) && el[`mi${bloqueInicial}_n`] })
      							.map((registro) => { return { id_teacher: registro[`mi${bloqueInicial}`], teacher: registro[`mi${bloqueInicial}_n`]  }})

      let tju1 = grupos.filter( el => { return ( el.jueves || el.domingo || el.sabado ) && el[`ju${bloqueInicial}_n`] })
      							.map((registro) => { return { id_teacher: registro[`ju${bloqueInicial}`], teacher: registro[`ju${bloqueInicial}_n`]  }})

      let tvi1 = grupos.filter( el => { return ( el.viernes || el.domingo || el.sabado ) && el[`vi${bloqueInicial}_n`] })
      							.map((registro) => { return { id_teacher: registro[`vi${bloqueInicial}`], teacher: registro[`vi${bloqueInicial}_n`]  }})
      // Vamos a sacar los teachers de cada bloque
      let tlu2 = grupos.filter( el => { return ( el.lunes || el.domingo || el.sabado ) && el[`lu${bloqueFinal}_n`] })
      							.map((registro) => { return { id_teacher: registro[`lu${bloqueFinal}`], teacher: registro[`lu${bloqueFinal}_n`]  }})

      let tma2 = grupos.filter( el => { return ( el.martes || el.domingo || el.sabado ) && el[`ma${bloqueFinal}_n`] })
      							.map((registro) => { return { id_teacher: registro[`ma${bloqueFinal}`], teacher: registro[`ma${bloqueFinal}_n`]  }})

      let tmi2 = grupos.filter( el => { return ( el.miercoles || el.domingo || el.sabado ) && el[`mi${bloqueFinal}_n`] })
      							.map((registro) => { return { id_teacher: registro[`mi${bloqueFinal}`], teacher: registro[`mi${bloqueFinal}_n`]  }})

      let tju2 = grupos.filter( el => { return ( el.jueves || el.domingo || el.sabado ) && el[`ju${bloqueFinal}_n`] })
      							.map((registro) => { return { id_teacher: registro[`ju${bloqueFinal}`], teacher: registro[`ju${bloqueFinal}_n`]  }})

      let tvi2 = grupos.filter( el => { return ( el.viernes || el.domingo || el.sabado ) && el[`vi${bloqueFinal}_n`] })
      							.map((registro) => { return { id_teacher: registro[`vi${bloqueFinal}`], teacher: registro[`vi${bloqueFinal}_n`]  }})

      // Concatenamos a los maestros
      let arregloTeachers = tlu1.concat( tma1 ).concat( tmi1 ).concat( tju1 ).concat( tvi1 ).concat(tlu2).concat( tma2 ).concat( tmi2 ).concat( tju2 ).concat( tvi2 )

      // Quitamos los duplicados de los maestros
      let teachers = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);

			// Recorremos los maestros para ir agregando las cosas
			for( const i in teachers ){

				// Sacamos el id del teacher
				const { id_teacher, teacher } = teachers[i]

				// Sacamos las clases que le pertenecen al teacher 
				const clasesTeacher = grupos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																	.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher) || ['lu', 'ma', 'mi', 'ju', 'vi']
																	.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher)) && ( !el.domingo && !el.sabado && el.horas_dia != 1.5 ));

				// SACAR LAS CLASES DOMINICALES
				const clasesTeacherDomingo = grupos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																			.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																			.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.domingo );

				// SACAR LAS CLASES SABATINO
				const clasesTeacherSabado = grupos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																			.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																			.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.sabado );

				// CLASES DE 1.5 HORAS
				const clasesLMV = grupos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																			.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																			.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.horas_dia == 1.5 );

				// Lo separamos por escuela
				const clasesFast = clasesTeacher.filter( el => { return el.id_unidad_negocio == 2})
				const clasesInbi = clasesTeacher.filter( el => { return el.id_unidad_negocio == 1})

				// CLASES FAST DOMINGO
				const clasesFastDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 2})
				const clasesInbiDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 1})

				// CLASES FAST SABADO
				const clasesFastSabado = clasesTeacherSabado.filter( el => { return el.id_unidad_negocio == 2})
				const clasesInbiSabado = clasesTeacherSabado.filter( el => { return el.id_unidad_negocio == 1})

				// CLASES LMV
				const clasesFastLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 2})
				const clasesInbiLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 1})

				let clasesFastLV = 0
				let clasesFastLM = 0
				let clasesFastDom = 0
				let clasesInbiLV = 0
				let clasesInbiLM = 0
				let clasesInbiDom = 0


				// CONTADOR ENTRE SEMANA
				let contadorBloquesFast = 0
				for( const i in clasesFast ){

					// ENTRE SEMANA
					contadorBloquesFast += clasesFast[i][`lu${bloqueInicial}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`ma${bloqueInicial}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`mi${bloqueInicial}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`ju${bloqueInicial}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`vi${bloqueInicial}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0

					// ENTRE SEMANA
					contadorBloquesFast += clasesFast[i][`lu${bloqueFinal}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`ma${bloqueFinal}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`mi${bloqueFinal}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`ju${bloqueFinal}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
					contadorBloquesFast += clasesFast[i][`vi${bloqueFinal}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0
				}

				clasesFastLV += contadorBloquesFast

				// CONTADOR FAST DOMINGOS
				let contadorBloquesFastDomingo = 0
				for( const i in clasesFastDomingo ){

					// DOMINICALES
					contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

					contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
					contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0

					// DOMINICALES
					clasesFastDom += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

					clasesFastDom += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
					clasesFastDom += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0
				}

				// CONTADOR CLASES DE 1.5 HORAS
				let contadorBloquesFastLMV = 0
				for( const i in clasesFastLMV ){

					// ENTRE SEMANA
					contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

					contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
					contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0
				}

				// INBIIIIIIIIIII CLASES ENTRE SEMANA
				// CONTADOR ENTRE SEMANA
				let contadorBloquesINBI = 0
				for( const i in clasesInbi ){

					// ENTRE SEMANA BOQUE 1 o 3
					contadorBloquesINBI += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0
					// ENTRE SEMANA BOQUE 2 o 4
					contadorBloquesINBI += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
					contadorBloquesINBI += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0

					// ENTRE SEMANA BOQUE 1 o 3
					clasesInbiLV += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
					clasesInbiLV += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
					clasesInbiLV += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
					clasesInbiLV += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
					clasesInbiLV += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0
					// ENTRE SEMANA BOQUE 2 o 4
					clasesInbiLV += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
					clasesInbiLV += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
					clasesInbiLV += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
					clasesInbiLV += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
					clasesInbiLV += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0
				}

				// CONTADOR ENTRE SEMANA
				let contadorBloquesInbiDomingo = 0
				for( const i in clasesInbiDomingo ){

					// DOMINICALES
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
					contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0

					clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

					clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
					clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0
				}


				// CONTADOR ENTRE SEMANA
				let contadorBloquesInbiLMV = 0
				for( const i in clasesInbiLMV ){

					// ENTRE SEMANA
					contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0

					contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
					contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0


					clasesInbiLM += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0

					clasesInbiLM += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
					clasesInbiLM += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0
				}


				const existeCategoria = this.pagos.find( el => el.id_usuario  == id_teacher )

				let hora_1 = existeCategoria ? existeCategoria.hora_1 : 0
				let hora_2 = existeCategoria ? existeCategoria.hora_2 : 0
				let hora_3 = existeCategoria ? existeCategoria.hora_3 : 0
				teachers[i]['existeCategoria'] = existeCategoria

				// AGREGAREMOS LOS PAGOS
				teachers[i]['categoria_lv']  = hora_1
				teachers[i]['categoria_d']   = hora_3

				/***********************************************************************/
				teachers[i]['HORAS_ENTRE_SEMANA_FAST']   = contadorBloquesFast
				teachers[i]['HORAS_DOMINGO_FAST']        = contadorBloquesFastDomingo
				teachers[i]['HORAS_LMV_FAST']            = contadorBloquesFastLMV
				/************************************************************************/
				teachers[i]['HORAS_ENTRE_SEMANA_INBI']   = contadorBloquesINBI
				teachers[i]['HORAS_DOMINGO_INBI']        = contadorBloquesInbiDomingo
				teachers[i]['HORAS_LMV_INBI']            = contadorBloquesInbiLMV
				/************************************************************************/
				teachers[i]['HORAS_TOTALES']                = contadorBloquesFast + contadorBloquesFastDomingo + contadorBloquesFastLMV + contadorBloquesINBI + contadorBloquesInbiDomingo + contadorBloquesInbiLMV 
				teachers[i]['HORAS_TOTALES_LV']             = contadorBloquesFast + contadorBloquesFastLMV + contadorBloquesINBI + contadorBloquesInbiLMV 
				teachers[i]['HORAS_TOTALES_D']              = contadorBloquesFastDomingo + contadorBloquesInbiDomingo 

				teachers[i]['PAGO_TOTAL']                = ( contadorBloquesFast * hora_1 ) + ( contadorBloquesFastDomingo * hora_3 ) + ( contadorBloquesFastLMV * hora_1 ) + ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesInbiLMV * hora_1 ) 

				/************************************************************************/
				// CLASES DOBLES
				const pagosDoblesTeachers = clasesDobles.filter(el => [`lu${bloqueInicial}`, `ma${bloqueInicial}`, `mi${bloqueInicial}`, `ju${bloqueInicial}`, `vi${bloqueInicial}`].includes( el.bloque ) && el.id_teacher_reemplazo == id_teacher || [`lu${bloqueFinal}`, `ma${bloqueFinal}`, `mi${bloqueFinal}`, `ju${bloqueFinal}`, `vi${bloqueFinal}`].includes( el.bloque ) && el.id_teacher_reemplazo == id_teacher);

				const rebajesDobles = clasesDobles.filter(el => [`lu${bloqueInicial}`, `ma${bloqueInicial}`, `mi${bloqueInicial}`, `ju${bloqueInicial}`, `vi${bloqueInicial}`].includes( el.bloque ) && el.id_teacher_falto == id_teacher || [`lu${bloqueFinal}`, `ma${bloqueFinal}`, `mi${bloqueFinal}`, `ju${bloqueFinal}`, `vi${bloqueFinal}`].includes( el.bloque ) && el.id_teacher_falto == id_teacher);

				let pagoDoble = pagosDoblesTeachers.map(item => item.monto_pago).reduce((prev, curr) => prev + curr, 0)
				let rebajeDoble = rebajesDobles.map(item => item.monto_rebaje).reduce((prev, curr) => prev + curr, 0)
				
				teachers[i]['PAGO_DOBLES'] = pagoDoble
				teachers[i]['REBAJES']     = rebajeDoble
				/************************************************************************/
			}

    	// Mi variable de la tabla 1 comparativa = tabla1ComparativaQ

    	// Primero, sacar la semana anterior
    	let getCategoriasLv = teachers.filter( el => el.id_teacher < 100000 ).map((registro) => { return registro.categoria_lv })
    	let getCategoriasD  = teachers.filter( el => el.id_teacher < 100000 ).map((registro) => { return registro.categoria_d })

    	this.categorias2Q = [...new Set(getCategoriasLv.concat( getCategoriasD ))].sort((a, b) => b - a) 

    	for( const i in this.categorias2Q ){

    		let teachersCategoriaLv = teachers.filter( el => el.categoria_lv == this.categorias2Q[i] && el.id_teacher < 100000 )
    		let teachersCategoriaD  = teachers.filter( el => el.categoria_d == this.categorias2Q[i] && el.id_teacher < 100000 )


    		let totalHorasLv =  teachersCategoriaLv.map(item => item.HORAS_TOTALES_LV ).reduce((prev, curr) => prev + curr, 0)
    		let totalHorasD  =  teachersCategoriaD.map(item => item.HORAS_TOTALES_D ).reduce((prev, curr) => prev + curr, 0)

    		let totalHoras = totalHorasLv + totalHorasD

    		this.tabla2ComparativaQ.push({
    			valor_hora   : this.categorias2Q[i] == null ? 'TEACHER LIDER' : this.categorias2Q[i] ,
    			numero_horas : totalHoras,
    			costo        : this.format( totalHoras * this.categorias2Q[i] )
    		})

    	}

    	this.tabla2ComparativaQ.push({
  			valor_hora   : 'Horas totales',
  			numero_horas : grupos.filter( el => { return el.deleted == 0 }).map(item => item.horas_catorcena ).reduce((prev, curr) => prev + curr, 0),
  			costo        : ''
  		})

  		this.tabla2ComparativaQ.push({
  			valor_hora   : 'Horas impartidas',
  			numero_horas : teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.HORAS_TOTALES).reduce((prev, curr) => prev + curr, 0),
  			costo        : this.format( teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.PAGO_TOTAL).reduce((prev, curr) => prev + curr, 0))
  		})

  		this.horasImpartidasAntQ = teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.HORAS_TOTALES).reduce((prev, curr) => prev + curr, 0)
  		let costoTotal      = teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.PAGO_TOTAL).reduce((prev, curr) => prev + curr, 0)

  		this.costoHora1     = this.format( costoTotal / this.horasImpartidasAntQ )
    },

    igualarCategoriasQ( ){

    	if( this.tabla1ComparativaQ.length != this.tabla2ComparativaQ.length ){


    		if( this.tabla1ComparativaQ.length > this.tabla2ComparativaQ.length ){

    			// Función para encontrar el índice de un objeto por id
					const findIndexById = (arr, valor_hora) => arr.findIndex(obj => obj.valor_hora == valor_hora);

    			this.tabla1ComparativaQ.forEach((obj, index) => {
					  const foundIndex = findIndexById(this.tabla2ComparativaQ, obj.valor_hora);
					  if (foundIndex === -1) {
					    // Insertar el objeto en la misma posición que en array1
					    let objeto = {
					    	valor_hora: obj.valor_hora,
					    	numero_horas: 0,
					    	costo: '$0.00'
					    }
					    this.tabla2ComparativaQ.splice(index, 0, objeto);
					  }
					});

    		}else{

    			// Función para encontrar el índice de un objeto por id
					const findIndexById = (arr, valor_hora) => arr.findIndex(obj => obj.valor_hora == valor_hora);


					this.tabla2ComparativaQ.forEach((obj, index) => {
					  const foundIndex = findIndexById(this.tabla1ComparativaQ, obj.valor_hora);
					  if (foundIndex === -1) {
					    // Insertar el objeto en la misma posición que en array1
					    let objeto = {
					    	valor_hora: obj.valor_hora,
					    	numero_horas: 0,
					    	costo: '$0.00'
					    }
					    this.tabla1ComparativaQ.splice(index, 0, objeto);
					  }
					});

    		}

    	}
    },

    motivoDiferenciasQ ( ) {

    	for( const i in this.tabla2ComparativaQ ){

    		const { numero_horas, valor_hora, costo } = this.tabla2ComparativaQ[i]

    		let diferencia = this.tabla1ComparativaQ[i].numero_horas - numero_horas

    		let diferenciaM = parseFloat( this.tabla1ComparativaQ[i].costo.replace('$','').replace(',','')) - parseFloat( costo.replace('$','').replace(',','') )

    		this.tabla1ComparativaQ[i]['diferencia']  = this.format( diferenciaM )

    		if( numero_horas > this.tabla1ComparativaQ[i].numero_horas ){
    			
    			this.tabla1ComparativaQ[i]['motivo'] = `Menos ${ diferencia } horas de ${ valor_hora }`

    		}else if( numero_horas == this.tabla1ComparativaQ[i].numero_horas ){
    			
    			this.tabla1ComparativaQ[i]['motivo'] = 'No hay diferencia'

    		}else{

    			this.tabla1ComparativaQ[i]['motivo'] = `Más ${ diferencia } horas de ${ valor_hora }`

    		}
    		// 

    	}
			// this.tabla2ComparativaQ
    },

    cacularBonoTeachers(  ){

    	try{

				// Ordenar el arreglo de eventos por la hora de inicio
				this.gruposQuincenaAnt.sort(this.compararHorasInicio);

				this.gruposAllBono = this.gruposAllBono.filter( el => !el.grupo.match('ONLINE'))

				this.gruposAllBono.sort(this.compararHorasInicio);



				// VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
	    	// Vamos a sacar los teachers de cada bloque
	      let sat1 = this.gruposAllBono.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`lu${1}_n`] && el[`lu${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
				let sat2 = this.gruposAllBono.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`lu${2}_n`] && el[`lu${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
				let sat3 = this.gruposAllBono.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`lu${3}_n`] && el[`lu${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
				let sat4 = this.gruposAllBono.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`lu${4}_n`] && el[`lu${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})

	    	// Vamos a sacar los teachers de cada bloque
	      let sat11 = this.gruposAllBono.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`ju${1}_n`] && el[`ju${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
				let sat22 = this.gruposAllBono.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`ju${2}_n`] && el[`ju${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
				let sat33 = this.gruposAllBono.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`ju${3}_n`] && el[`ju${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
				let sat44 = this.gruposAllBono.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`ju${4}_n`] && el[`ju${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})


	      let sabadoCompletoTeens = sat1.concat( sat2 ).concat( sat3 ).concat( sat4 )
	      let sabadoTeacherDupTeens  = sat1.concat( sat11 )

	      let sabadoTeacherTeens = Array.from(new Set(sabadoTeacherDupTeens.map(JSON.stringify))).map(JSON.parse);

	      // Conteo

	      for( const i in sabadoTeacherTeens ){

	      	const { id_teacher } = sabadoTeacherTeens[i]

	      	if( sat1.find( el => el.id_teacher == id_teacher ) ){

	      		let existe1 = sat1.find( el => el.id_teacher == id_teacher )
	      		let existe2 = sat2.find( el => el.id_teacher == id_teacher )
	      		let existe3 = sat3.find( el => el.id_teacher == id_teacher )
	      		let existe4 = sat4.find( el => el.id_teacher == id_teacher )

	      		if( existe1 && existe2 && existe3 && existe4 ){
	      			sabadoTeacherTeens[i]['conteo'] = sabadoCompletoTeens.filter( el => el.id_teacher == id_teacher ).length
	      		}

	      	}

	      	sabadoTeacherTeens[i]['conteo'] = sabadoCompletoTeens.filter( el => el.id_teacher == id_teacher ).length

	      }

	      sabadoTeacherTeens = sabadoTeacherTeens.filter( el => el.conteo >= 4 )

	      let teachersBonoSabatinoTeens = []

	      for( const i in sabadoTeacherTeens ){

	      	const { id_teacher, teacher } = sabadoTeacherTeens[i]

	      	let conteoNoRetardos = 0

	      	let valida1 = this.gruposAllBono.find( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`lu${1}`] == id_teacher })
	      	let valida2 = this.gruposAllBono.find( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`lu${2}`] == id_teacher })
	      	let valida3 = this.gruposAllBono.find( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`lu${3}`] == id_teacher })
	      	let valida4 = this.gruposAllBono.find( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`lu${4}`] == id_teacher })

	      	// Validamos las horas 
	      	if( valida1 ){
	      		const { hora_inicio, lu1_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu1_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida2 ){
	      		const { hora_inicio, lu2_hora_asistencia } = valida2
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu2_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida3 ){
	      		const { hora_inicio, lu3_hora_asistencia } = valida3
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu3_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida4 ){
	      		const { hora_inicio, lu4_hora_asistencia } = valida4
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu4_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	sabadoTeacherTeens[i]['conteoNoRetardos'] = conteoNoRetardos

	      } 

	      sabadoTeacherTeens = sabadoTeacherTeens.filter( el => el.conteoNoRetardos >= 3 )

				/************************************************************/

	    	// VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
	    	// Vamos a sacar los teachers de cada bloque
	      let sa1 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.sabado && !el.grupo.match('TEENS') ) && el[`lu${1}_n`] && el[`lu${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
				let sa2 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.sabado && !el.grupo.match('TEENS') ) && el[`lu${2}_n`] && el[`lu${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
				let sa3 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.sabado && !el.grupo.match('TEENS') ) && el[`lu${3}_n`] && el[`lu${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
				let sa4 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.sabado && !el.grupo.match('TEENS') ) && el[`lu${4}_n`] && el[`lu${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})

	    	// Vamos a sacar los teachers de cada bloque
	      let sa11 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.sabado && !el.grupo.match('TEENS') ) && el[`ju${1}_n`] && el[`ju${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
				let sa22 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.sabado && !el.grupo.match('TEENS') ) && el[`ju${2}_n`] && el[`ju${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
				let sa33 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.sabado && !el.grupo.match('TEENS') ) && el[`ju${3}_n`] && el[`ju${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
				let sa44 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.sabado && !el.grupo.match('TEENS') ) && el[`ju${4}_n`] && el[`ju${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})


	      let sabadoCompleto = sa1.concat( sa2 ).concat( sa3 ).concat( sa4 ).concat( sa11 ).concat( sa22 ).concat( sa33 ).concat( sa44 )
	      let sabadoTeacherDup  = sa1.concat( sa11 )

	      let sabadoTeacher = Array.from(new Set(sabadoTeacherDup.map(JSON.stringify))).map(JSON.parse);

	      // Conteo

	      for( const i in sabadoTeacher ){

	      	const { id_teacher } = sabadoTeacher[i]

	      	if( sa1.find( el => el.id_teacher == id_teacher ) ){

	      		let existe1 = sa1.find( el => el.id_teacher == id_teacher )
	      		let existe2 = sa2.find( el => el.id_teacher == id_teacher )
	      		let existe3 = sa3.find( el => el.id_teacher == id_teacher )
	      		let existe4 = sa4.find( el => el.id_teacher == id_teacher )

	      		if( existe1 && existe2 && existe3 && existe4 ){
	      			sabadoTeacher[i]['conteo'] = sabadoCompleto.filter( el => el.id_teacher == id_teacher ).length
	      		}

	      	}


	      	if( sa11.find( el => el.id_teacher == id_teacher ) ){

	      		let existe1 = sa11.find( el => el.id_teacher == id_teacher )
	      		let existe2 = sa22.find( el => el.id_teacher == id_teacher )
	      		let existe3 = sa33.find( el => el.id_teacher == id_teacher )
	      		let existe4 = sa44.find( el => el.id_teacher == id_teacher )

	      		if( existe1 && existe2 && existe3 && existe4 ){
	      			sabadoTeacher[i]['conteo'] = sabadoCompleto.filter( el => el.id_teacher == id_teacher ).length
	      		}

	      	}


	      }




	      sabadoTeacher = sabadoTeacher.filter( el => el.conteo >= 4 )

	      let teachersBonoSabatino = []

	      for( const i in sabadoTeacher ){

	      	const { id_teacher, teacher } = sabadoTeacher[i]

	      	let conteoNoRetardos = 0

	      	let valida1 = this.gruposAllBono.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${1}`] == id_teacher })
	      	let valida2 = this.gruposAllBono.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${2}`] == id_teacher })
	      	let valida3 = this.gruposAllBono.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${3}`] == id_teacher })
	      	let valida4 = this.gruposAllBono.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${4}`] == id_teacher })

	      	// Validamos las horas 
	      	if( valida1 ){
	      		const { hora_inicio, lu1_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu1_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida2 ){
	      		const { hora_inicio, lu2_hora_asistencia } = valida2
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu2_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida3 ){
	      		const { hora_inicio, lu3_hora_asistencia } = valida3
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu3_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida4 ){
	      		const { hora_inicio, lu4_hora_asistencia } = valida4
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu4_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	sabadoTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

	      } 

	      sabadoTeacher = sabadoTeacher.filter( el => el.conteoNoRetardos >= 3 )

	      // *************************************************************************************
	      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
	    	// Vamos a sacar los teachers de cada bloque
	      let do1 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.domingo && !el.grupo.match('TEENS') ) && el[`lu${1}_n`] && el[`lu${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
				let do2 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.domingo && !el.grupo.match('TEENS') ) && el[`lu${2}_n`] && el[`lu${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
				let do3 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.domingo && !el.grupo.match('TEENS') ) && el[`lu${3}_n`] && el[`lu${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
				let do4 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.domingo && !el.grupo.match('TEENS') ) && el[`lu${4}_n`] && el[`lu${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})

	    	// Vamos a sacar los teachers de cada bloque
	      let do11 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.domingo && !el.grupo.match('TEENS') ) && el[`ju${1}_n`] && el[`ju${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
				let do22 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.domingo && !el.grupo.match('TEENS') ) && el[`ju${2}_n`] && el[`ju${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
				let do33 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.domingo && !el.grupo.match('TEENS') ) && el[`ju${3}_n`] && el[`ju${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
				let do44 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.domingo && !el.grupo.match('TEENS') ) && el[`ju${4}_n`] && el[`ju${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})


	      let domingoCompleto = do1.concat( do2 ).concat( do3 ).concat( do4 ).concat( do11 ).concat( do22 ).concat( do33 ).concat( do44 )
	      let domingoTeacherDup  = do1.concat( do11 )

	      let domingoTeacher = Array.from(new Set(domingoTeacherDup.map(JSON.stringify))).map(JSON.parse);

	      // Conteo

	      for( const i in domingoTeacher ){

	      	const { id_teacher } = domingoTeacher[i]

	      	domingoTeacher[i]['conteo'] = domingoCompleto.filter( el => el.id_teacher == id_teacher ).length

	      }

	      domingoTeacher = domingoTeacher.filter( el => el.conteo >= 4 )

	      for( const i in domingoTeacher ){

	      	const { id_teacher, teacher } = domingoTeacher[i]

	      	let conteoNoRetardos = 0

	      	let valida1 = this.gruposAllBono.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${1}`] == id_teacher ) })
	      	let valida2 = this.gruposAllBono.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${2}`] == id_teacher ) })
	      	let valida3 = this.gruposAllBono.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${3}`] == id_teacher ) })
	      	let valida4 = this.gruposAllBono.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${4}`] == id_teacher ) })

	      	// Validamos las horas 
	      	if( valida1 ){
	      		const { hora_inicio, lu1_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu1_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida2 ){
	      		const { hora_inicio, lu2_hora_asistencia } = valida2
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu2_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida3 ){
	      		const { hora_inicio, lu3_hora_asistencia } = valida3 
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu3_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida4 ){
	      		const { hora_inicio, lu4_hora_asistencia } = valida4 
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu4_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	domingoTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

	      } 

	      domingoTeacher = domingoTeacher.filter( el => el.conteoNoRetardos >= 3 )

	      // *************************************************************************************
	      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
	    	// Vamos a sacar los teachers de cada bloque
	      let lu1 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.lunes ) && el[`lu${1}_n`] && el[`lu${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
				let lu2 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.lunes ) && el[`lu${2}_n`] && el[`lu${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
				let lu3 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.lunes ) && el[`lu${3}_n`] && el[`lu${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
				let lu4 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.lunes ) && el[`lu${4}_n`] && el[`lu${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})

	      let lunesCompleto = lu1.concat( lu2 ).concat( lu3 ).concat( lu4 )
	      let lunesTeacherDup  = lu1

	      let lunesTeacher = Array.from(new Set(lunesTeacherDup.map(JSON.stringify))).map(JSON.parse);

	      // Conteo

	      for( const i in lunesTeacher ){

	      	const { id_teacher } = lunesTeacher[i]

	      	lunesTeacher[i]['conteo'] = lunesCompleto.filter( el => el.id_teacher == id_teacher ).length

	      }

	      lunesTeacher = lunesTeacher.filter( el => el.conteo >= 3 )

	      for( const i in lunesTeacher ){

	      	const { id_teacher, teacher } = lunesTeacher[i]

	      	let conteoNoRetardos = 0

	      	let valida1 = this.gruposAllBono.find( el => { return ( el.lunes ) && el[`lu${1}`] == id_teacher })
	      	let valida2 = this.gruposAllBono.find( el => { return ( el.lunes ) && el[`lu${2}`] == id_teacher })
	      	let valida3 = this.gruposAllBono.find( el => { return ( el.lunes ) && el[`lu${3}`] == id_teacher })
	      	let valida4 = this.gruposAllBono.find( el => { return ( el.lunes ) && el[`lu${4}`] == id_teacher })

	      	// Validamos las horas 
	      	if( valida1 ){
	      		const { hora_inicio, lu1_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu1_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida2 ){
	      		const { hora_inicio, lu2_hora_asistencia } = valida2
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu2_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida3 ){
	      		const { hora_inicio, lu3_hora_asistencia } = valida3
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu3_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida4 ){
	      		const { hora_inicio, lu4_hora_asistencia } = valida4
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu4_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	lunesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

	      } 

	      lunesTeacher = lunesTeacher.filter( el => el.conteoNoRetardos >= 3 )


	      // *************************************************************************************
	      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
	    	// Vamos a sacar los teachers de cada bloque
	      let ma1 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.martes ) && el[`ma${1}_n`] && el[`ma${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ma${1}`], teacher: registro[`ma${1}_n`]  }})
				let ma2 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.martes ) && el[`ma${2}_n`] && el[`ma${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ma${2}`], teacher: registro[`ma${2}_n`]  }})
				let ma3 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.martes ) && el[`ma${3}_n`] && el[`ma${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ma${3}`], teacher: registro[`ma${3}_n`]  }})
				let ma4 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.martes ) && el[`ma${4}_n`] && el[`ma${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ma${4}`], teacher: registro[`ma${4}_n`]  }})

	      let martesCompleto = ma1.concat( ma2 ).concat( ma3 ).concat( ma4 )
	      let martesTeacherDup  = ma1

	      let martesTeacher = Array.from(new Set(martesTeacherDup.map(JSON.stringify))).map(JSON.parse);

	      // Conteo

	      for( const i in martesTeacher ){

	      	const { id_teacher } = martesTeacher[i]

	      	martesTeacher[i]['conteo'] = martesCompleto.filter( el => el.id_teacher == id_teacher ).length

	      }

	      martesTeacher = martesTeacher.filter( el => el.conteo >= 4 )

	      for( const i in martesTeacher ){

	      	const { id_teacher, teacher } = martesTeacher[i]

	      	let conteoNoRetardos = 0

	      	let valida1 = this.gruposAllBono.find( el => { return ( el.martes ) && el[`ma${1}`] == id_teacher })
	      	let valida2 = this.gruposAllBono.find( el => { return ( el.martes ) && el[`ma${2}`] == id_teacher })
	      	let valida3 = this.gruposAllBono.find( el => { return ( el.martes ) && el[`ma${3}`] == id_teacher })
	      	let valida4 = this.gruposAllBono.find( el => { return ( el.martes ) && el[`ma${4}`] == id_teacher })

	      	// Validamos las horas 
	      	if( valida1 ){
	      		const { hora_inicio, ma1_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma1_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida2 ){
	      		const { hora_inicio, ma2_hora_asistencia } = valida2
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma2_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida3 ){
	      		const { hora_inicio, ma3_hora_asistencia } = valida3
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma3_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida4 ){
	      		const { hora_inicio, ma4_hora_asistencia } = valida4
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma4_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	martesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

	      } 

	      martesTeacher = martesTeacher.filter( el => el.conteoNoRetardos >= 3 )


	      // *************************************************************************************
	      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
	    	// Vamos a sacar los teachers de cada bloque
	      let mi1 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.miercoles ) && el[`mi${1}_n`] && el[`mi${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`mi${1}`], teacher: registro[`mi${1}_n`]  }})
				let mi2 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.miercoles ) && el[`mi${2}_n`] && el[`mi${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`mi${2}`], teacher: registro[`mi${2}_n`]  }})
				let mi3 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.miercoles ) && el[`mi${3}_n`] && el[`mi${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`mi${3}`], teacher: registro[`mi${3}_n`]  }})
				let mi4 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.miercoles ) && el[`mi${4}_n`] && el[`mi${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`mi${4}`], teacher: registro[`mi${4}_n`]  }})

	      let miercolesCompleto = mi1.concat( mi2 ).concat( mi3 ).concat( mi4 )
	      let miercolesTeacherDup  = mi1

	      let miercolesTeacher = Array.from(new Set(miercolesTeacherDup.map(JSON.stringify))).map(JSON.parse);

	      // Conteo

	      for( const i in miercolesTeacher ){

	      	const { id_teacher } = miercolesTeacher[i]

	      	miercolesTeacher[i]['conteo'] = miercolesCompleto.filter( el => el.id_teacher == id_teacher ).length

	      }

	      miercolesTeacher = miercolesTeacher.filter( el => el.conteo >= 4 )

	      for( const i in miercolesTeacher ){

	      	const { id_teacher, teacher } = miercolesTeacher[i]

	      	let conteoNoRetardos = 0

	      	let valida1 = this.gruposAllBono.find( el => { return ( el.miercoles ) && el[`mi${1}`] == id_teacher })
	      	let valida2 = this.gruposAllBono.find( el => { return ( el.miercoles ) && el[`mi${2}`] == id_teacher })
	      	let valida3 = this.gruposAllBono.find( el => { return ( el.miercoles ) && el[`mi${3}`] == id_teacher })
	      	let valida4 = this.gruposAllBono.find( el => { return ( el.miercoles ) && el[`mi${4}`] == id_teacher })

	      	// Validamos las horas 
	      	if( valida1 ){
	      		const { hora_inicio, mi1_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi1_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida2 ){
	      		const { hora_inicio, mi2_hora_asistencia } = valida2
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi2_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida3 ){
	      		const { hora_inicio, mi3_hora_asistencia } = valida3
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi3_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida4 ){
	      		const { hora_inicio, mi4_hora_asistencia } = valida4
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi4_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	miercolesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

	      } 

	      miercolesTeacher = miercolesTeacher.filter( el => el.conteoNoRetardos >= 3 )


	      // *************************************************************************************
	      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
	    	// Vamos a sacar los teachers de cada bloque
	      let ju1 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.jueves ) && el[`ju${1}_n`] && el[`ju${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
				let ju2 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.jueves ) && el[`ju${2}_n`] && el[`ju${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
				let ju3 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.jueves ) && el[`ju${3}_n`] && el[`ju${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
				let ju4 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.jueves ) && el[`ju${4}_n`] && el[`ju${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})

	      let juevesCompleto = ju1.concat( ju2 ).concat( ju3 ).concat( ju4 )
	      let juevesTeacherDup  = ju1

	      let juevesTeacher = Array.from(new Set(juevesTeacherDup.map(JSON.stringify))).map(JSON.parse);

	      // Conteo

	      for( const i in juevesTeacher ){

	      	const { id_teacher } = juevesTeacher[i]

	      	juevesTeacher[i]['conteo'] = juevesCompleto.filter( el => el.id_teacher == id_teacher ).length

	      }

	      juevesTeacher = juevesTeacher.filter( el => el.conteo >= 4 )

	      for( const i in juevesTeacher ){

	      	const { id_teacher, teacher } = juevesTeacher[i]

	      	let conteoNoRetardos = 0

	      	let valida1 = this.gruposAllBono.find( el => { return ( el.jueves ) && el[`ju${1}`] == id_teacher })
	      	let valida2 = this.gruposAllBono.find( el => { return ( el.jueves ) && el[`ju${2}`] == id_teacher })
	      	let valida3 = this.gruposAllBono.find( el => { return ( el.jueves ) && el[`ju${3}`] == id_teacher })
	      	let valida4 = this.gruposAllBono.find( el => { return ( el.jueves ) && el[`ju${4}`] == id_teacher })

	      	// Validamos las horas 
	      	if( valida1 ){
	      		const { hora_inicio, ju1_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju1_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida2 ){
	      		const { hora_inicio, ju2_hora_asistencia } = valida2
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju2_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida3 ){
	      		const { hora_inicio, ju3_hora_asistencia } = valida3
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju3_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida4 ){
	      		const { hora_inicio, ju4_hora_asistencia } = valida4
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju4_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	juevesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

	      } 

	      juevesTeacher = juevesTeacher.filter( el => el.conteoNoRetardos >= 3 )


	      // *************************************************************************************
	      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
	    	// Vamos a sacar los teachers de cada bloque
	      let vi1 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.viernes ) && el[`vi${1}_n`] && el[`vi${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`vi${1}`], teacher: registro[`vi${1}_n`]  }})
				let vi2 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.viernes ) && el[`vi${2}_n`] && el[`vi${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`vi${2}`], teacher: registro[`vi${2}_n`]  }})
				let vi3 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.viernes ) && el[`vi${3}_n`] && el[`vi${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`vi${3}`], teacher: registro[`vi${3}_n`]  }})
				let vi4 = this.gruposAllBono.filter( el => { return ( !el.grupo.match('ONLINE') && el.viernes ) && el[`vi${4}_n`] && el[`vi${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`vi${4}`], teacher: registro[`vi${4}_n`]  }})

	      let viernesCompleto = vi1.concat( vi2 ).concat( vi3 ).concat( vi4 )
	      let viernesTeacherDup  = vi1

	      let viernesTeacher = Array.from(new Set(viernesTeacherDup.map(JSON.stringify))).map(JSON.parse);

	      // Conteo

	      for( const i in viernesTeacher ){

	      	const { id_teacher } = viernesTeacher[i]

	      	viernesTeacher[i]['conteo'] = viernesCompleto.filter( el => el.id_teacher == id_teacher ).length

	      }

	      viernesTeacher = viernesTeacher.filter( el => el.conteo >= 4 )

	      for( const i in viernesTeacher ){

	      	const { id_teacher, teacher } = viernesTeacher[i]

	      	let conteoNoRetardos = 0

	      	let valida1 = this.gruposAllBono.find( el => { return ( el.viernes ) && el[`vi${1}`] == id_teacher })
	      	let valida2 = this.gruposAllBono.find( el => { return ( el.viernes ) && el[`vi${2}`] == id_teacher })
	      	let valida3 = this.gruposAllBono.find( el => { return ( el.viernes ) && el[`vi${3}`] == id_teacher })
	      	let valida4 = this.gruposAllBono.find( el => { return ( el.viernes ) && el[`vi${4}`] == id_teacher })

	      	// Validamos las horas 
	      	if( valida1 ){
	      		const { hora_inicio, ju1_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju1_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida2 ){
	      		const { hora_inicio, ju2_hora_asistencia } = valida2
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju2_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida3 ){
	      		const { hora_inicio, ju3_hora_asistencia } = valida3
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju3_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida4 ){
	      		const { hora_inicio, ju4_hora_asistencia } = valida4
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju4_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	viernesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

	      } 

	      viernesTeacher = viernesTeacher.filter( el => el.conteoNoRetardos >= 3 )

	      let allTeachersDu = lunesTeacher.concat( martesTeacher).concat( juevesTeacher ).concat( miercolesTeacher ).concat( viernesTeacher ).concat( sabadoTeacher ).concat( domingoTeacher ).concat( sabadoTeacherTeens )

	      // Utilizar un conjunto para mantener un registro de los id_teacher únicos
		    var idTeacherUnicos = new Set();
		    // Utilizar filter para filtrar los elementos del arreglo
		    // y mantener solo aquellos con id_teacher que no estén en el conjunto
		    this.teachersBonos = allTeachersDu.filter(function (elemento) {
		        // Si el id_teacher ya está en el conjunto, retorna false (no lo incluye)
		        // Si no está en el conjunto, lo agrega y retorna true (lo incluye)
		        if (idTeacherUnicos.has(elemento.id_teacher)) {
		          return false;
		        } else {
		          idTeacherUnicos.add(elemento.id_teacher);
		          return true;
		        }
		    });

		    // Ahora... hay que ponerle qué días, estára....
		    for( const i in this.teachersBonos ){

		    	const { id_teacher, teacher } = this.teachersBonos[i]


		    	this.teachersBonos[i]['eliminable'] = 0
		    	this.teachersBonos[i]['bono']       = 0
		    	this.teachersBonos[i]['bono_l']     = 0

		    	const existeSabado       = sabadoTeacher.find( el => el.id_teacher == id_teacher )
		    	const existeSabadoTeens  = sabadoTeacherTeens.find( el => el.id_teacher == id_teacher )
		    	const existeDomingo      = domingoTeacher.find( el => el.id_teacher == id_teacher )
		    	const existeLunes        = lunesTeacher.find( el => el.id_teacher == id_teacher )
		    	const existeMartes       = martesTeacher.find( el => el.id_teacher == id_teacher )
		    	const existeMiercoles    = juevesTeacher.find( el => el.id_teacher == id_teacher )
		    	const existeJueves       = miercolesTeacher.find( el => el.id_teacher == id_teacher )
		    	const existeViernes      = viernesTeacher.find( el => el.id_teacher == id_teacher )



		    	// Un fin de semana y entre semana medio turno
		    	if( ( existeSabado || existeDomingo ) && ( existeLunes && existeMartes && existeMiercoles && existeJueves && existeViernes )){

		    		// Significa que no debería estar rolada en nigun fin de semana
		    		let existeEntreSemana = this.gruposAllBono.filter( el => { return ( el.lunes == 1 || el.martes == 1 || el.miercoles == 1 || el.jueves == 1 || el.viernes == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

		    		let medioTurno = 0
		    		let medioTurno2 = 0

		    		for( const i in existeEntreSemana ){

		    			const { hora_inicio } = existeEntreSemana[i]

		    			if( hora_inicio.substr(0,5) < "16:00" ){ medioTurno = 1 }
		    			if( hora_inicio.substr(0,5) >= "16:00" ){ medioTurno2 = 1 }

		    		}

		    		let grupos = existeEntreSemana.map((registro) => { return registro.grupo })

		    		// FULL
		    		if( medioTurno && medioTurno2 ){
		    			this.teachersBonos[i]['categoria'] = 1
		    			this.teachersBonos[i]['bono']      = 1000
		    		}else{ // 1/2 turno + fin de semana
		    			this.teachersBonos[i]['categoria'] = 2
		    			this.teachersBonos[i]['bono']      = 500
		    		}
		    		
		    	}


		    	// Un fin de semana y entre semana medio turno
		    	if( ( existeSabado || existeDomingo ) && ( existeLunes || existeMartes || existeMiercoles || existeJueves || existeViernes )){

		    		// Significa que no debería estar rolada en nigun fin de semana
		    		let existeEntreSemana = this.gruposAllBono.filter( el => { return ( el.lunes == 1 || el.martes == 1 || el.miercoles == 1 || el.jueves == 1 || el.viernes == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

		    		let medioTurno = 0
		    		let medioTurno2 = 0

		    		for( const i in existeEntreSemana ){

		    			const { hora_inicio } = existeEntreSemana[i]

		    			if( hora_inicio.substr(0,5) <= "16:00" ){ medioTurno = 1 }
		    			if( hora_inicio.substr(0,5) >= "16:00" ){ medioTurno2 = 1 }

		    		}

		    		let grupos = existeEntreSemana.map((registro) => { return registro.grupo })

		    		// FULL
		    		if( medioTurno && medioTurno2 ){
		    			this.teachersBonos[i]['categoria'] = 1
		    			this.teachersBonos[i]['bono_l']    = 1000
		    		}else{ // 1/2 turno + fin de semana
		    			this.teachersBonos[i]['categoria'] = 2
		    			this.teachersBonos[i]['bono_l']    = 500
		    		}
		    		
		    	}


		    	// SOLO FIN DE SEMANA
		    	if( ( existeSabado || existeDomingo || existeSabadoTeens ) && ( !existeLunes && !existeMartes && !existeMiercoles && !existeJueves && !existeViernes )){

		    		// Significa que no debería estar rolada en nigun fin de semana
		    		let existeEntreSemana = this.gruposAllBono.filter( el => { return ( el.sabado == 0 && el.domingo == 0 ) && ( el[`lu${1}`] == id_teacher && el[`ma${1}`] == id_teacher && el[`mi${1}`] == id_teacher && el[`ju${1}`] == id_teacher && el[`vi${1}`] == id_teacher ) })

		    		if( !existeEntreSemana.length ){
		    			this.teachersBonos[i]['categoria'] = 3
		    			this.teachersBonos[i]['bono']      = 350
		    			this.teachersBonos[i]['bono_l']    = 350
		    		}else{
		    			this.teachersBonos[i]['eliminable'] = 1
		    			this.teachersBonos[i]['bono']       = 0
		    			this.teachersBonos[i]['bono_l']     = 0
		    		}
		    		

		    	}

		    	// SOLO ENTRE SEMANA
		    	if( ( !existeSabado && !existeDomingo ) && ( existeLunes && existeMartes && existeMiercoles && existeJueves && existeViernes )){

		    		// Significa que no debería estar rolada en nigun fin de semana
		    		let existeFinSemana = this.gruposAllBono.filter( el => { return ( el.sabado == 1 || el.domingo == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

		    		if( !existeFinSemana.length ){
		    			this.teachersBonos[i]['categoria'] = 4
	            this.teachersBonos[i]['bono']      = 500
		    			this.teachersBonos[i]['bono_l']    = 500
		    		}else{
		    			this.teachersBonos[i]['eliminable'] = 1
		    			this.teachersBonos[i]['bono']       = 0
		    			this.teachersBonos[i]['bono_l']     = 0
		    		}
		    		

		    	}

	        // SOLO ENTRE SEMANA
	        if( ( !existeSabado && !existeDomingo ) && ( existeLunes || existeMartes || existeMiercoles || existeJueves || existeViernes )){

	          // Significa que no debería estar rolada en nigun fin de semana
	          let existeFinSemana = this.gruposAllBono.filter( el => { return ( el.sabado == 1 || el.domingo == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

	          if( !existeFinSemana.length ){
	            this.teachersBonos[i]['categoria'] = 4
	            this.teachersBonos[i]['bono_l']    = 500
	          }else{
	            this.teachersBonos[i]['eliminable'] = 1
	            this.teachersBonos[i]['bono_l']     = 0
	          }
	          

	        }
		    }


		    this.teachersBonos.push({
		    	id_teacher:  10000000000000000,
					teacher:     'TOTAL',
					categoria:   0,
					bono:        this.teachersBonos.map(item => item.bono).reduce((prev, curr) => prev + curr, 0) ,
					eliminable:  0,
		    })

	    	return this.teachersBonos
	    
	    }catch( error ){

	    	this.validarErrorDirecto( error.message )

	    }
    },

    validarHoraAsistencia(horaInicio, horaAsistencia) {
	    // Convertir las horas a minutos
	    var inicioMinutos = this.obtenerMinutos(horaInicio);
  		var asistenciaMinutos = this.obtenerMinutos(horaAsistencia);
	    
	    // Verificar si la hora de asistencia es al menos 15 minutos antes de la hora de inicio
	    if (inicioMinutos - asistenciaMinutos >= 15) {
	        return true;
	    } else {
	        return false;
	    }
		},

		obtenerMinutos(hora) {
	    var partesHora = hora.split(":");
	    var horas = parseInt(partesHora[0]);
	    var minutos = parseInt(partesHora[1]);
	    return horas * 60 + minutos;
		},

		compararHorasInicio(eventoA, eventoB) {
	    // Convertir las horas de inicio a minutos
	    var minutosA = this.obtenerMinutos(eventoA.hora_inicio);
	    var minutosB = this.obtenerMinutos(eventoB.hora_inicio);
	    
	    // Comparar los minutos
	    if (minutosA < minutosB) {
	        return -1;
	    } else if (minutosA > minutosB) {
	        return 1;
	    } else {
	        return 0;
	    }
		},

  }
}