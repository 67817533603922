<template>
  <v-container fluid class="mt-4 ml-2">
    <v-row>
      <v-col cols="12">
	      <v-card>
	      	<v-card-title primary-title>
		        <v-toolbar-title class="grey--text">Calificación de clase</v-toolbar-title>
		        <v-divider
		          class="mx-4"
		          inset
		          vertical
		        ></v-divider>
		        <v-spacer></v-spacer>
            <v-btn color="success" @click="exportarExcel()" class="mr-2 text-capitalize"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
		        <v-btn color="primary" @click="getGrupos()" class="mr-2 text-capitalize"><v-icon left>mdi-magnify</v-icon> Consultar</v-btn>
	      	</v-card-title>

	        <v-card-text>

            <!-- Menu de filtros -->
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  v-model="dia"
                  dense
                  label="Fecha de inicio"
                  filled
                  hide-details
                  clearable
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  v-model="dia2"
                  dense
                  label="Fecha final"
                  filled
                  hide-details
                  clearable
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="8" lg="6" v-if="fast">
                <v-card>
                  <v-card-title primary-title>
                    FAST
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-subheader>Alumnos</v-subheader>
                        <strong>Calif. Promedio:</strong>        {{ fast.promedio.toFixed(2) }}<br/>
                        <strong>Calificaron:</strong>            {{ fast.cantidad }}<br/>
                        <strong>Debieron calificar:</strong>     {{ fast.cantAlumnosFAST }}<br/>
                      </v-col>

                      <v-col cols="12" sm="8">
                        <v-subheader>Clases</v-subheader>
                        <strong># Clases:             </strong>  {{ fast.cantClases }}<br/>
                        <strong># Clases Buenas:      </strong>  {{ fast.clases_calidad_fast }}<br/>
                        <strong># Clases Malas:       </strong>  {{ fast.clases_no_calidad_fast }}<br/>
                        <strong># Clases NO evaluadas:</strong>  {{ fast.clases_sin_calidad_fast }}<br/>

                        <span><strong>Buenas:</strong> más del 80% de alumnos calificaron</span><br/>
                        <span><strong>Malas:</strong> menos del 80% de alumnos calificaron</span><br/>
                        <span><strong>NO evaluadas:</strong> Ningún alumno califico</span>
                      </v-col>

                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="8" lg="6" v-if="inbi">
                <v-card>
                  <v-card-title primary-title>
                    INBI
                  </v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-subheader>Alumnos</v-subheader>
                        <strong>Calif. Promedio:</strong>        {{ inbi.promedio.toFixed(2) }}<br/>
                        <strong>Calificaron:</strong>            {{ inbi.cantidad }}<br/>
                        <strong>Debieron calificar:</strong>     {{ inbi.cantAlumnosINBI }}<br/>
                      </v-col>

                      <v-col cols="12" sm="8">
                        <v-subheader>Clases</v-subheader>
                        <strong># Clases:             </strong>  {{ inbi.cantClases }}<br/>
                        <strong># Clases Buenas:      </strong>  {{ inbi.clases_calidad_inbi }}<br/>
                        <strong># Clases Malas:       </strong>  {{ inbi.clases_no_calidad_inbi }}<br/>
                        <strong># Clases NO evaluadas:</strong>  {{ inbi.clases_sin_calidad_inbi }}<br/>

                        <span><strong>Buenas:</strong> más del 80% de alumnos calificaron</span><br/>
                        <span><strong>Malas:</strong> menos del 80% de alumnos calificaron</span><br/>
                        <span><strong>NO evaluadas:</strong> Ningún alumno califico</span>
                      </v-col>

                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

	        	<!-- Menu superior de tabs -->

		        <v-tabs
			        dense
			        class="mt-4"
			        v-model="tab"
			        background-color="white"
			      >
			        <v-tabs-slider color="primary"></v-tabs-slider>


			        <v-tab
			          v-for="(item,i) in items"
			          :key="i"
			        >
			          <v-icon v-text="item.icon" left></v-icon>{{ item.name }} | {{ item.promedio }} |
			        </v-tab>


			        <!-- Apartado de las secciones de los tabs -->
			        <v-tabs-items v-model="tab">
			          <v-tab-item>
                  <v-row class="pt-4" justify="end">
                    <v-col cols="12" md="8" lg="6">
                      <v-text-field
                        v-model="searchFast"
                        label="Buscar"
                        append-icon="mdi-magnify"
                        filled
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>

  			            <v-col cols="12" lg="12">
  			              <v-card class="elevation-0">
  			                <v-card-text>	
  			                	<v-data-table
  											    dense
  											    :headers="headers"
  											    :items="gruposFast"
  											    item-key="id"
  											    class="elevation-1"
                            :search="searchFast"
  											  >
                            <template v-slot:item.teachers="{ item }">
                              {{ item.teacher1 }}<br/>
                              {{ item.teacher2 }}
                            </template>
                          </v-data-table>
  			                </v-card-text>	
  			              </v-card>
  			            </v-col>
                  </v-row>
			          </v-tab-item>
			        

			          <!-- horarios de inbi -->
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">
			                <v-card-title primary-title class="pt-0 ">
                        <v-text-field
                          v-model="searchInbi"
                          label="Buscar"
                          append-icon="mdi-magnify"
                          filled
                          dense
                          hide-details
                        ></v-text-field>
                      </v-card-title>

			                <v-card-text >
			                  <v-data-table
											    dense
											    :headers="headers"
											    :items="gruposInbi"
											    item-key="id"
											    class="elevation-1"
                          :search="searchInbi"
											  >
                          <template v-slot:item.teachers="{ item }">
                            {{ item.teacher1 }}<br/>
                            {{ item.teacher2 }}
                          </template>
                        </v-data-table>
			                </v-card-text>	
			              </v-card>
			            </v-col>
			          </v-tab-item>


			          <!-- Resultados -->
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">

			              	
			              </v-card>
			            </v-col>
			          </v-tab-item>

			        </v-tabs-items>
			      </v-tabs>
	        </v-card-text>
	      </v-card>		
      </v-col>
    </v-row>

    
  	<!-- Dialog de carga -->
    <carga v-if="overlay"/>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card
        color="green"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Datos grabados
        </v-card-title>
        <div class="pl-6 pb-6">
          <br/>
          NOTA: {{successMensaje}}
        </div>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogSuccess = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
	  
    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card
        color="error"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Error al recuperar los datos
        </v-card-title>
        <v-card-text>
          <br/>
          NOTA: {{error}}
        </v-card-text>
        <v-btn 
        	color="transparent elevation-0"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogError = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
  import XLSX from 'xlsx'
  import carga     from '@/components/alertas/carga.vue';

  export default {
    components:{
      carga
    },

    data: () => ({

    	tab: {name:'FAST'   , icon:'mdi-school' }, 

      items: [
        {name:'FAST'    , icon:'mdi-school', promedio: 0 }, 
        {name:'INBI'    , icon:'mdi-school', promedio: 0 }, 
      ],

      headers: [
        { text: 'id'             , value: 'id_grupo' },
        { text: 'Grupo'          , value: 'grupo' },
        { text: 'Teachers'       , value: 'teachers' },
        { text: 'Cant. Alumnos'  , value: 'cant_alumnos' },
        { text: 'Cant. Calif'    , value: 'cant_calif' },
        { text: 'Promedio'       , value: 'promedio' },
      ],

      dialogError: false,
			dialogSuccess:false,
			error:'',
			successMensaje:'',
			overlay:false,

      ciclos:  			[],
			cicloInf: 		null,

      searchFast:'',
      searchInbi:'',

      gruposFast:[],
			gruposInbi:[],
      promedioFast:0,
      promedioInbi:0,

      fast: null,
      inbi: null,
      
      dia:null,
      dia2:null,
    }),

    created () {
      this.initialize()
    },

    methods: {
    	initialize () {
      	this.overlay = true
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError('Error al recuperar los ciclos')
        })
      },

      getGrupos () {
        if(!this.cicloInf && !this.dia && !this.dia2){
          this.openDialogError('Por favor, ingresa un ciclo o una fecha de inicio o un rango de fechas')
        }else{

          // consultar por puro ciclo
          if(this.cicloInf && this.dia && !this.dia2){
            this.getClases(1)
          }

          if(this.cicloInf && this.dia && this.dia2){
            if(this.dia > this.dia2){
              this.openDialogError('La fecha de inicio no puede ser mayor a la fecha final')
            }else{
              this.getClases(2)
            }
          }

          if(this.cicloInf && !this.dia && this.dia2){
            this.openDialogError('Por favor, ingresa una fecha de inicio')
          }

          if(this.cicloInf && !this.dia && !this.dia2){
            this.getClases(3)
          }
        }
      },

      getClases (item) {
        let payload = {}
        // Por ciclo y dia
        if(item == 1){
          payload = {
            cicloInbi: this.cicloInf.id_ciclo,
            cicloFast: this.cicloInf.id_ciclo_relacionado,
            fecha:       this.dia,
            fecha2:      this.dia,
            tipo:        1
          }
        }

        // Por ciclo y rango de fechas
        if(item == 2){
          payload = {
            cicloFast:   this.cicloInf.id_ciclo_relacionado,
            cicloInbi:   this.cicloInf.id_ciclo,
            fecha:       this.dia,
            fecha2:      this.dia2,
            tipo:        2
          }
        }

        // Solo ciclo
        if(item == 3){
          payload = {
            cicloFast:   this.cicloInf.id_ciclo_relacionado,
            cicloInbi:   this.cicloInf.id_ciclo,
            fecha:       "0000-00-00",
            fecha2:      "9999-12-31",
            tipo:        3
          }
        }
        this.overlay = true
        this.clases  = []
        

        this.$http.post(`lms.catalogos.grupos.calificacion`, payload).then(response=>{
          // Guardamos la respuesta
          this.gruposFast   = response.data.teachersActualesFast
          this.gruposInbi   = response.data.teachersActualesInbi

          this.items[0].promedio = response.data.promedioFast.promedio.toFixed(2)
          this.items[1].promedio = response.data.promedioInbi.promedio.toFixed(2)

          this.headers = response.data.headers

          this.fast = response.data.promedioFast
          console.log( response.data.promedioFast )

          this.inbi = response.data.promedioInbi
          // Quitamos el loader
          this.overlay = false
        }).catch(error=>{
          this.overlay = false
          this.openDialogError(error.body.message)
        })
      },

      openDialogError (mensaje) {
      	this.error       = mensaje
      	this.dialogError = true
      },

      openDialogSuccess ( mensaje ){
      	this.successMensaje = mensaje
      	this.dialogSuccess  = true
      },

      exportarExcel(){
        let dataFast = XLSX.utils.json_to_sheet(this.gruposFast)
        let dataInbi = XLSX.utils.json_to_sheet(this.gruposInbi)
        const workbook = XLSX.utils.book_new()
        const filename = 'calificacion_clase'
        XLSX.utils.book_append_sheet(workbook, dataFast, 'FAST')
        XLSX.utils.book_append_sheet(workbook, dataInbi, 'INBI')

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },
    },

    computed:{
    	...mapGetters('login',['getdatosUsuario'])
    }
  }
</script>

<style>
	.v-snack__content{
		padding: 0px !important;
	}
</style>