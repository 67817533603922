<template>
  <v-container class="mt-4 ml-2">
    <v-snackbar
      v-model="snackbar"
      top
      :timeout="3000"
      color="error"
    >
      Selecciona los ciclos porfavor 
      <v-btn text color="white" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

    
    <v-row>
      <v-col cols="12">
	      <v-card>
	      	<v-card-title primary-title>
		        <v-toolbar-title>Evaluaciones</v-toolbar-title>
		        <v-divider
		          class="mx-4"
		          inset
		          vertical
		        ></v-divider>
		        <v-spacer></v-spacer>
		        <v-btn color="primary" @click="getEvaluaciones()" small>Consultar</v-btn>
	      	</v-card-title>
	        <v-card-text>
	        	<v-row>
	        		<v-col cols="12" sm="6" lg="4" xl="3">
			          <v-autocomplete
                  outlined
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona el ciclo"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
	        		</v-col>
	        	</v-row>

	        	<!-- Menu superior de tabs -->

		        <v-tabs
		        	v-if="evaluaciones"
			        dense
			        class="mt-4"
			        v-model="tab"
			        background-color="white"
			      >
			        <v-tabs-slider color="primary"></v-tabs-slider>


			        <v-tab
			          v-for="(item,i) in items"
			          :key="i"
			        >
			          <v-icon v-text="item.icon" left></v-icon>{{ item.name }}
			        </v-tab>


			        <!-- Apartado de las secciones de los tabs -->
			        <v-tabs-items v-model="tab">
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-alert
								      dense
								      text
								      type="success"
								      class="mt-2"
								      v-if="evaluaciones.calificacionFast.length > 0"
								    >
								      Ya cuentas con una calificación, selecciona la opción de <strong>RESULTADOS</strong>
								    </v-alert>


			              <v-card class="elevation-0" v-else>
			                <v-card-title primary-title class="pt-0 ">

							          <v-alert
										      dense
										      text
										      type="info"
										      class="mt-2"
										    >
										      Todas las preguntas se guardan <strong>AUTOMÁTICAMENTE</strong> al finalizar, dar clic en <strong>"TERMINAR EVALUACIÓN"</strong>
										    </v-alert>
			                  <v-spacer></v-spacer>
			                </v-card-title>

			                <v-card-text>
			                  <div v-for="( pregunta , i ) in evaluaciones.preguntasFast" :key="i" class="text-subtitle-1 mb-10">
			                  	<b>{{ i + 1 }}-. Nivel: {{ pregunta.id_nivel }}</b>
													{{ pregunta.pregunta }}
													<v-radio-group
											      v-model="pregunta.eleccion"
											      row
											      @change="updatePregunta(pregunta)"
											    >
											      <v-radio
											        :label="pregunta.respuesta_1"
											        :value="1"
											      ></v-radio>
											      <v-radio
											        :label="pregunta.respuesta_2"
											        :value="2"
											      ></v-radio>
											      <v-radio
											        :label="pregunta.respuesta_3"
											        :value="3"
											      ></v-radio>
											      <v-radio
											        :label="pregunta.respuesta_4"
											        :value="4"
											      ></v-radio>
											    </v-radio-group>
											    <v-divider></v-divider>
			                  </div>
			                </v-card-text>	
			                <v-card-actions>
			                  <v-btn color="secondary" @click="terminarEvaluacionFast()" v-if="evaluaciones.preguntasFast.length > 0">TERMINAR EVALUACIÓN</v-btn>
			                </v-card-actions>	
			              </v-card>
			            </v-col>
			          </v-tab-item>
			        

			          <!-- Evaluaciones de inbi -->
			          <v-tab-item>
			            <v-col cols="12" lg="12">
					        	<v-alert
								      dense
								      text
								      type="success"
								      class="mt-2"
								      v-if="evaluaciones.calificacionInbi.length > 0"
								    >
								      Ya cuentas con una calificación, selecciona la opción de <strong>RESULTADOS</strong>
								    </v-alert>


			              <v-card class="elevation-0" v-else>
			                <v-card-title primary-title class="pt-0 ">
							          <v-alert
										      dense
										      text
										      type="info"
										      class="mt-2"
										    >
										      Todas las preguntas se guardan <strong>AUTOMÁTICAMENTE</strong> al finalizar, dar clic en <strong>"TERMINAR EVALUACIÓN"</strong>
										    </v-alert>
			                  <v-spacer></v-spacer>
			                </v-card-title>

			                <v-card-text v-if="evaluaciones.preguntasInbi.length > 0">
			                  <div v-for="( pregunta , i ) in evaluaciones.preguntasInbi" :key="i" class="text-subtitle-1 mb-10">
			                  	<b>{{ i + 1 }}-. Nivel: {{ pregunta.id_nivel }}</b>
													{{ pregunta.pregunta }}
													<v-radio-group
											      v-model="pregunta.eleccion"
											      row
											      @change="updatePregunta(pregunta)"
											    >
											      <v-radio
											        :label="pregunta.respuesta_1"
											        :value="1"
											      ></v-radio>
											      <v-radio
											        :label="pregunta.respuesta_2"
											        :value="2"
											      ></v-radio>
											      <v-radio
											        :label="pregunta.respuesta_3"
											        :value="3"
											      ></v-radio>
											      <v-radio
											        :label="pregunta.respuesta_4"
											        :value="4"
											      ></v-radio>
											    </v-radio-group>
											    <v-divider></v-divider>
			                  </div>
			                </v-card-text>	
			                <v-card-actions>
			                  <v-btn color="secondary" @click="terminarEvaluacionInbi()" v-if="evaluaciones.preguntasInbi.length > 0">TERMINAR EVALUACIÓN</v-btn>
			                </v-card-actions>	
			              </v-card>
			            </v-col>
			          </v-tab-item>


			          <!-- Resultados -->
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">

			              	<v-row justify="space-around">
			              		<v-col cols="12" md="5" lg="4" v-if="evaluaciones.calificacionFast.length > 0">
			              			<v-card color="blue lighten-1" dark>
				              			<v-card-title primary-title class="pl-2">
				              			  FAST
				              			</v-card-title>
				              			<v-card-actions>
				              			  Total de preguntas:   <v-spacer></v-spacer>{{ evaluaciones.calificacionFast[0].total_clases }}
				              			</v-card-actions>
				              			<v-card-actions>
				              			  Total de correctas:   <v-spacer></v-spacer>{{ evaluaciones.calificacionFast[0].total_correcta }}
				              			</v-card-actions>
				              			<v-card-actions>
				              			  Total de incorrectas: <v-spacer></v-spacer>{{ evaluaciones.calificacionFast[0].total_incorrecta }}
				              			</v-card-actions>
				              			<v-card-actions>
				              			  Calificación Final:   <v-spacer></v-spacer>{{ evaluaciones.calificacionFast[0].calificacion }}
				              			</v-card-actions>
			              			</v-card>
			              		</v-col>

			              		<v-col cols="12" md="5" lg="3" v-if="evaluaciones.calificacionInbi.length > 0">
			              			<v-card color="blue lighten-1" dark>
				              			<v-card-title primary-title class="pl-2">
				              			  INBI
				              			</v-card-title>
				              			<v-card-actions>
				              			  Total de preguntas:   <v-spacer></v-spacer>{{ evaluaciones.calificacionInbi[0].total_clases }}
				              			</v-card-actions>
				              			<v-card-actions>
				              			  Total de correctas:   <v-spacer></v-spacer>{{ evaluaciones.calificacionInbi[0].total_correcta }}
				              			</v-card-actions>
				              			<v-card-actions>
				              			  Total de incorrectas: <v-spacer></v-spacer>{{ evaluaciones.calificacionInbi[0].total_incorrecta }}
				              			</v-card-actions>
				              			<v-card-actions>
				              			  Calificación Final:   <v-spacer></v-spacer>{{ evaluaciones.calificacionInbi[0].calificacion }}
				              			</v-card-actions>
			              			</v-card>
			              		</v-col>
			              	</v-row>
			              </v-card>
			            </v-col>
			          </v-tab-item>

			        </v-tabs-items>
			      </v-tabs>
	        </v-card-text>
	      </v-card>		
      </v-col>
    </v-row>

    
  	<!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="200"
        color="primary"
        dark
      >
        <v-card-text align="center" class="text-h6">
          Espera porfavor
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card
        color="green"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Datos grabados
        </v-card-title>
        <div class="pl-6 pb-6">
          <br/>
          NOTA: {{successMensaje}}
        </div>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogSuccess = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
	  
    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card
        color="error"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Error al recuperar los datos
        </v-card-title>
        <v-card-text>
          <br/>
          NOTA: {{error}}
        </v-card-text>
        <v-btn 
        	color="transparent elevation-0"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogError = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
  import XLSX from 'xlsx'

  export default {
    data: () => ({
    	row:null,
    	tab: {name:'Evaluación Fast'   , icon:'mdi-file' }, 
      items: [
        {name:'Evaluación Fast'    , icon:'mdi-file' }, 
        {name:'Evaluación Inbi'    , icon:'mdi-file' }, 
        {name:'Resultados'         , icon:'mdi-check' }, 
      ],

      snackbar:false,
      desserts: [],
      ciclos:[],
      cicloInf:null,
      cicloSup:null,
      dialogError: false,
			dialogSuccess:false,
			error:'',
			successMensaje:'',
			overlay:false,
			evaluaciones:null
    }),

    watch: {
      cicloInf () {
      	// Validar que no sea nulo
        if(this.cicloInf){
        	this.getEvaluaciones()
        }
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
      	this.overlay = true
        this.ciclos = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError('Error al recuperar los ciclos')
        })
      },

      updatePregunta ( pregunta ) {
      	let id_ciclo = 0
      	if( pregunta.eleccion == pregunta.correcta ){ pregunta.valor = 1	}
      	if( pregunta.unidad_negocio == 1){ id_ciclo = this.cicloInf.id_ciclo }else{ id_ciclo = this.cicloInf.id_ciclo_relacionado }
      		
      	let payload = {
      		...pregunta,
      		id_usuario: this.getdatosUsuario.iderp,
      		id_ciclo
      	}
      	this.$http.post('academico.evaluacion.pregunta', payload).then(response=>{
    			// No hacer nada, ya que es imposible que haya un error en este punto, y si lo hay, lo atrapará el catch, entonces, si todo esta bien, realmente no se debe indicar nada al usuario
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError(error.body.message)
        })

      },

      getEvaluaciones () {
      	this.evaluaciones = []
      	if(!this.cicloInf){
      		this.openDialogError('Selecciona un ciclo por favor')
      	}else{
      		this.overlay = true
      		let payload = {
      			idcicloFast: this.cicloInf.id_ciclo_relacionado,
      			idcicloInbi: this.cicloInf.id_ciclo,
      			id_usuario: this.getdatosUsuario.iderp
      		}

      		this.$http.post('academico.evaluacion.teachers', payload).then(response=>{
      			if(response.data.message){
      				this.openDialogSuccess( response.data.message )
      			}else{
	          	this.evaluaciones = response.data
      			}
	          this.overlay = false
	        }).catch(error=>{
	        	this.overlay = false
	        	this.openDialogError(error.body.message)
	        })
      	}
      },

      openDialogError (mensaje) {
      	this.error       = mensaje
      	this.dialogError = true
      },

      openDialogSuccess ( mensaje ){
      	this.successMensaje = mensaje
      	this.dialogSuccess  = true
      },

      terminarEvaluacionInbi () {
      	for(const i in this.evaluaciones.preguntasInbi){
      		if(this.evaluaciones.preguntasInbi[i].eleccion == 0){
      			this.openDialogError('Favor de responder todas las preguntas')
      			return
      		}
      	}

      	// Sacar el total de respuestas correctas, incorrectas o el total general
      	let total_correcta     = 0
      	let total_incorrecta   = 0
      	let calificacion       = 0
      	let total_clases       = this.evaluaciones.preguntasInbi.length

      	for(const i in this.evaluaciones.preguntasInbi){
      		if(this.evaluaciones.preguntasInbi[i].valor == 0){
      			total_incorrecta += 1
      		}else{
      			total_correcta += 1
      		}
      	}
      	calificacion = parseInt( (total_correcta / total_clases  * 100))

      	let payload = {
      		id_teacher: this.getdatosUsuario.iderp,
      		id_ciclo: this.cicloInf.id_ciclo,
      		estatus: 1,
      		calificacion,
      		total_clases,
      		total_correcta,
      		total_incorrecta
      	}

      	this.$http.post('academico.evaluacion.teacher.calificacion', payload).then(response=>{
      		this.getEvaluaciones()
    			this.openDialogSuccess( 'Calificacion grabada correctamente' )
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError(error.body.message)
        })

      },

      terminarEvaluacionFast () {
      	for(const i in this.evaluaciones.preguntasFast){
      		if(this.evaluaciones.preguntasFast[i].eleccion == 0){
      			this.openDialogError('Favor de responder todas las preguntas')
      			return
      		}
      	}

      	// Sacar el total de respuestas correctas, incorrectas o el total general
      	let total_correcta     = 0
      	let total_incorrecta   = 0
      	let calificacion       = 0
      	let total_clases       = this.evaluaciones.preguntasFast.length

      	for(const i in this.evaluaciones.preguntasFast){
      		if(this.evaluaciones.preguntasFast[i].valor == 0){
      			total_incorrecta += 1
      		}else{
      			total_correcta += 1
      		}
      	}
      	calificacion = parseInt( (total_correcta / total_clases  * 100))

      	let payload = {
      		id_teacher: this.getdatosUsuario.iderp,
      		id_ciclo: this.cicloInf.id_ciclo_relacionado,
      		estatus: 1,
      		calificacion,
      		total_clases,
      		total_correcta,
      		total_incorrecta
      	}

      	this.$http.post('academico.evaluacion.teacher.calificacion', payload).then(response=>{
      		this.getEvaluaciones()
    			this.openDialogSuccess( 'Calificacion grabada correctamente' )
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError(error.body.message)
        })
      }
    },

    computed:{
    	...mapGetters('login',['getdatosUsuario'])
    }
  }
</script>

<style>
	.v-snack__content{
		padding: 0px !important;
	}
</style>