const headerAdmin =  [
  { text: 'Folio',              value: 'folio' },
  { text: 'Nombre',             value: 'nombre_completo', },
  { text: 'Teléfono',           value: 'telefono', },
  // { text: 'Eventos',            value: 'cantEventos', },
  { text: 'Sucursal',           value: 'sucursal_interes_nombre', },
  { text: 'Curso',              value: 'curso_interes_nombre', },
  { text: 'E. Hoy',             value: 'cantEventosHoy', },
  { text: 'Llamada',            value: 'intentoLlamada', },
  { text: 'Etapa',              value: 'idetapa' },
  { text: 'Clasif.',            value: 'clasificacion' },
  { text: 'Sig. Tarea',         value: 'tareasP' },
  { text: 'Fecha',              value: 'fecha_creacion' },
  // { text: 'Conmutador',         value: 'existeConmutador' },
  // { text: 'De',                 value: 'recluta' },
  { text: 'Para',               value: 'vendedora' },
  // { text: 'Agendada',           value: 'cita' },
  { text: 'T. transucrrido',    value: 'tiempo_transcurrido' },
  { text: 'WhatsApp',           value: 'whatsapp' },
  { text: 'Nivel',              value: 'nivel' },
  { text: 'Actions',            value: 'actions', sortable: false },
]

const headerVend =  [
  { text: 'Folio',              value: 'folio' },
  { text: 'Nombre',             value: 'nombre_completo', },
  { text: 'Teléfono',           value: 'telefono', },
  // { text: 'Eventos',            value: 'cantEventos', },
  { text: 'Sucursal',           value: 'sucursal_interes_nombre', },
  { text: 'Curso',              value: 'curso_interes_nombre', },
  { text: 'E. Hoy',             value: 'cantEventosHoy', },
  { text: 'Llamada',            value: 'intentoLlamada', },
  { text: 'Etapa',              value: 'idetapa' },
  { text: 'Clasif.',            value: 'clasificacion' },
  { text: 'Sig. Tarea',         value: 'tareasP' },
  { text: 'Fecha',              value: 'fecha_creacion' },
  // { text: 'De',                 value: 'recluta' },
  // { text: 'Conmutador',         value: 'existeConmutador' },
  { text: 'T. transucrrido',    value: 'tiempo_transcurrido' },
  { text: 'WhatsApp',           value: 'whatsapp' },
  { text: 'Nivel',              value: 'nivel' },
  { text: 'Actions',            value: 'actions', sortable: false },
]

const headerSeguimiento = [
  { text: 'Folio',               value: 'folio'                },
  { text: 'Etapa',               value: 'idetapa'              },
  // { text: 'Conmutador',          value: 'conmutador',          },
  { text: 'Llamadas e intentos', value: 'llamadas_intentos'    },
  { text: 'WhatsApp',            value: 'seguimiento_whatsApp' },
  { text: 'Vendedora',           value: 'vendedora'            },
  { text: 'Fecha',               value: 'fecha_creacion'       },
]


export { headerAdmin, headerVend, headerSeguimiento }