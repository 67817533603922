<template>
	<v-container class="mt-4" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  			<v-card-title primary-title>
	  			  <span class="text-subtitle-1">Registros</span>
	  			  <v-spacer></v-spacer>
            <v-chip color="green" dark small @click="exportar( )" class="mr-2"><v-icon>mdi-microsoft-excel</v-icon></v-chip>
	  			  <v-chip color="primary" small @click="initialize( )">Actualizar</v-chip>
	  			</v-card-title>

	  			<v-card-text class="pt-0">
	  			  <v-row justify="end" class="pt-0">
	  			  	<v-col cols="12" md="6" lg="4" class="pt-0">
	  			  		<v-text-field
	  			  		  label="Buscar"
	  			  		  id="id"
	  			  		  v-model="search"
	  			  		  outlined
	  			  		  dense
	  			  		  rounded
	  			  		  hide-details
	  			  		></v-text-field>
	  			  	</v-col>
	  			  </v-row>


            <v-row>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-card>
                  <v-card-text>
                    <span class="text-h6">Total:</span> <br/>
                    <span class="text-subtitle-1"><strong>{{ total }}</strong></span>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" md="3" lg="3">
                <v-card>
                  <v-card-text>
                    <span class="text-h6">Sin pago:</span> <br/>
                    <span class="text-subtitle-1"><strong>{{ conAdeudo }}</strong></span>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" md="3" lg="3">
                <v-card>
                  <v-card-text>
                    <span class="text-h6">Con pago parcial:</span> <br/>
                    <span class="text-subtitle-1"><strong>{{ conAdeudoyPago }}</strong></span>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" md="3" lg="3">
                <v-card>
                  <v-card-text>
                    <span class="text-h6">Liquidados:</span> <br/>
                    <span class="text-subtitle-1"><strong>{{ liquidados }}</strong></span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
	  			</v-card-text>

	  			<v-card-text>
		  			<v-data-table
					    :headers="headers"
					    :items="registrosExci"
					    sort-by="calories"
					    class="elevation-0"
					    :search="search"
					  >

					    <template v-slot:item.enviarinfo="{ item }">
					      <v-chip color="primary" small @click="registrarPago( item )" v-if="item.pago_realizado < 1399 ">Registrar pago</v-chip>
					    </template>

              <template v-slot:item.mediopago="{ item }">
                <v-chip color="primary" small v-if="item.mediopago == 1 ">Efectivo</v-chip>
                <v-chip color="primary" small v-if="item.mediopago == 2 ">Tarjeta</v-chip>
                <v-chip color="primary" small v-if="item.mediopago == 3 ">Depósito</v-chip>
              </template>

              <template v-slot:item.seguimiento="{ item }">
                <v-chip color="purple" dark small @click="dialogSeguimiento = true, alumno = item">seguimiento</v-chip>
              </template>

              <template v-slot:item.matricula="{ item }">
                <v-chip color="red" dark small v-if="item.matricula == ''" @click="addMatricula( item )">matricula</v-chip>
                <v-chip color="green" dark small v-else-if="item.adeudo <= 0" >{{ item.matricula }}</v-chip>
                <v-chip color="yellow" small v-else >{{ item.matricula }}</v-chip>
              </template>

					    <template v-slot:no-data>
					      <v-btn
					        color="primary"
					        @click="initialize"
					      >
					        Reset
					      </v-btn>
					    </template>
					  </v-data-table>
	  			</v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

    <!-- Dialogo para agregar pago del exci -->
	  <v-dialog
      v-model="dialogPago"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Realizar pago
        </v-card-title>
        <v-card-text>
        	<v-text-field
        	  name="name"
        	  label="Total a pagar"
        	  readonly
        	  value="$ 1,399.00"
        	  filled
        	></v-text-field>

        	<v-text-field
        	  name="name"
        	  label="Pagar"
        	  v-model="pago"
        	  filled
        	  :hint="`pago realizado anterior: ${ alumno.pago_realizado }`"
        	></v-text-field>

        	<v-text-field
        	  name="name"
        	  label="Adeudo"
        	  readonly
        	  v-model="adeudo"
        	  filled
        	></v-text-field>

          <v-radio-group
            v-model="mediopago"
            column
          >
            <v-radio
              label="Efectivo"
              :value="1"
            ></v-radio>
            <v-radio
              label="Tarjeta"
              :value="2"
            ></v-radio>
            <v-radio
              label="Despósito"
              :value="3"
            ></v-radio>
          </v-radio-group>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="dialogPago = false, pago = 0, adeudo = 0"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="registarPagoExci ( ) "
          >
            Registrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialgo para agregar la matricula -->
    <v-dialog
      v-model="dialogAddMatricula"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Registrar matricula
        </v-card-title>
        <v-card-text>
          <v-text-field
            name="name"
            label="Nombre"
            v-model="alumno.nombre"
            readonly
            filled
          ></v-text-field>

          <v-text-field
            name="name"
            label="Folio"
            v-model="alumno.folio"
            readonly
            filled
          ></v-text-field>

          <v-autocomplete
            outlined
            dense
            clearable
            v-model="matricula"
            :items="alumnos"
            label="Busca la matricula del alumno"
            persistent-hint
            hide-details
            return-object
            item-text="nombre_completo"
            item-value="id_alumno"
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="matricula = null, dialogAddMatricula = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="matricula"
            color="primary"
            @click="registrarMatricula ( ) "
          >
            Registrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialgo para agregar la matricula -->
    <v-dialog
      v-model="dialogSeguimiento"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Registrar matricula
        </v-card-title>
        <v-card-text>
          <v-textarea
            name="name"
            label="Seguimiento"
            v-model="alumno.seguimiento"
            readonly
            filled
            auto-grow
          ></v-textarea>

          <v-textarea
            name="name"
            label="Notas"
            v-model="seguimiento"
            filled
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="seguimiento = '', dialogSeguimiento = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="seguimiento"
            color="primary"
            @click="addSeguimiento ( ) "
          >
            Registrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>					
</template>
<script>
import axios from 'axios';
  import {mapGetters, mapActions} from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import funcionesExcel  from '@/mixins/funcionesExcel'


  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ funcionesExcel ],

    data: () => ({
    	search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'id'           , value: 'idexci_registro' },
        { text: 'Nombre'       , value: 'nombre' },
        { text: 'Teléfono'     , value: 'telefono' },
        { text: 'Pago'         , value: 'pago_realizado' },
        { text: 'Adeudo'       , value: 'adeudo' },
        { text: 'Folio'        , value: 'folio' },
        { text: 'Tipo de pago' , value: 'mediopago' },
        { text: 'Estatus'      , value: 'estatus' },
        { text: 'fecha'        , value: 'fecha_actualizo' },
        { text: 'matricula'    , value: 'matricula' },
        { text: 'Seguimiento'  , value: 'seguimiento' },
        { text: 'Enviar'       , value: 'enviarinfo' },
        { text: 'actions'      , value: 'addmatricula' },
      ],
      registrosExci: [],
      pago:0,
      adeudo: 0,
      mediopago:null,
      alumno: {},
      dialogPago:false,

      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      alumnos:[],
      dialogAddMatricula: false,
      matricula: null,

      dialogSeguimiento:false,
      seguimiento:'',

      total:0,
      conAdeudo:0,
      liquidados:0,
      conAdeudoyPago:0,
    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      pago ( val ) {
      	if( val && val > 0 && this.alumno){
      		this.adeudo = 1399 - val - this.alumno.pago_realizado
      	}
      }
    },

    async created () {

      await this.initialize()
      await this.getAlumnosAll( )
    },

    methods: {
      initialize () {
        this.registrosExci = []
        return this.$http.get('exci.registros').then( response=> {
        	this.registrosExci = response.data

          this.total           = this.registrosExci.length
          this.conAdeudo       = this.registrosExci.filter( el=> { return el.pago_realizado < 1399 && el.pago_realizado == 0}).length
          this.conAdeudoyPago  = this.registrosExci.filter( el=> { return el.pago_realizado < 1399 && el.pago_realizado > 0 }).length
          this.liquidados      = this.registrosExci.filter( el=> { return el.pago_realizado >= 1399 }).length
        }).catch( error => {
        	console.log( error )
        }).finally( ( ) => {  })
      },

      getAlumnosAll () {
        this.alumnos = []
        return this.$http.get('exci.alumnos').then( response=> {
          this.alumnos = response.data
        }).catch( error => {
          console.log( error )
        }).finally( ( ) => {  })
      },

      registrarPago ( alumno ) {
      	this.adeudo = 1399 - alumno.pago_realizado
      	this.alumno = alumno
      	this.dialogPago = true
      },

      exportar(){
        this.exportExcel( this.registrosExci, 'alumnos_exci')
      },

      registarPagoExci  (  ) {
        this.dialogPago = false
        this.cargar     = true
      	const payload = {
      		idexci_registro: this.alumno.idexci_registro,
      		folio          : this.alumno.folio,
      		correo         : this.alumno.correo,
          mediopago      : this.mediopago,
      		pago           : parseFloat(this.pago) + parseFloat(this.alumno.pago_realizado),
      		adeudo         : this.adeudo,
          nombre         : this.alumno.nombre,
      		matricula      : this.alumno.matricula,
      		estatus        : this.adeudo == 0 ? 'Pago completado' : 'Con adeudo'
      	}

      	this.$http.post('enviar.recibo', payload).then( response=> {
      		this.pago = 0
      		this.adeudo = 0
          this.mediopago = null
      		this.dialogPago = false
          this.cargar = false
          this.parametros.mensaje      = 'Registro correcto'
          this.parametros.dialogError  = true 
          this.parametros.color        = 'success'
          this.respuestaAlerta = true 
        	this.initialize()
        }).catch( error => {
          this.validarErrores( error )
        	console.log( error )
        }).finally( ( ) => {  this.cargar = false })
      },

      validarErrores ( value ) {
        this.cargar = false
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      async addMatricula ( value ) {
        await this.getAlumnosAll()
        this.alumno  = value
        this.dialogAddMatricula = true
      },

      async registrarMatricula ( value ) {
        this.dialogAddMatricula = false
        this.cargar     = true

        const payload = {
          idexci_registro: this.alumno.idexci_registro,
          nombre         : this.alumno.nombre,
          correo         : this.alumno.correo,
          adeudo         : this.alumno.adeudo,
          matricula      : this.matricula.matricula,
          id_alumno      : this.matricula.id_alumno
        }

        this.$http.put('exci.alumno.matricula', payload).then( response=> {
          this.matricula               = null
          this.dialogAddMatricula      = false
          this.cargar                  = false
          this.parametros.mensaje      = 'Registro correcto'
          this.parametros.dialogError  = true 
          this.parametros.color        = 'success'
          this.respuestaAlerta         = true 
          this.initialize()
        }).catch( error => {
          this.validarErrores( error )
          console.log( error )
        }).finally( ( ) => {  this.cargar = false })
      },

      addSeguimiento ( ){
        this.dialogSeguimiento = false
        this.cargar            = true

        const nota = this.getdatosUsuario ? this.getdatosUsuario.nombre_completo + ': ' + this.seguimiento : this.seguimiento

        const payload = {
          idexci_registro  : this.alumno.idexci_registro,
          seguimiento      : this.alumno.seguimiento + `

` + nota
        }

        this.$http.put('exci.alumno.seguimiento', payload).then( response=> {
          this.seguimiento             = ''
          this.dialogSeguimiento       = false
          this.cargar                  = false
          this.parametros.mensaje      = 'Registro correcto'
          this.parametros.dialogError  = true 
          this.parametros.color        = 'success'
          this.respuestaAlerta         = true 
          this.initialize()
        }).catch( error => {
          this.validarErrores( error )
          console.log( error )
        }).finally( ( ) => {  this.cargar = false })
      }
    },
  }
</script>