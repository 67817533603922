<template>
	<v-container class="mt-4 ml-2">
	  <v-row>
	  	<v-col cols="12" md="10" lg="8">
			  <v-data-table
			    :headers="headers"
			    :items="tareas"
			    class="elevation-6"
			  >
			    <template v-slot:top>
			      <v-toolbar
			        flat
			      >
			        <v-toolbar-title>Tareas</v-toolbar-title>
			        <v-divider
			          class="mx-4"
			          inset
			          vertical
			        ></v-divider>
			        <v-spacer></v-spacer>
			        <v-dialog
			          v-model="dialog"
			          max-width="500px"
			        >
			          <template v-slot:activator="{ on, attrs }">
			            <v-btn
			              color="primary"
			              dark
			              class="mb-2"
			              v-bind="attrs"
			              v-on="on"
			              small
			              rounded
			            >
			              Nuevo tarea
			            </v-btn>
			          </template>
			          <v-card>
			            <v-card-title>
			              <span class="text-h5">{{ formTitle }}</span>
			            </v-card-title>

			            <v-card-text>
			              <v-container>
			                <v-row>
			                  <v-col cols="12">
			                    <v-text-field
			                      v-model="editedItem.tarea"
			                      label="Tarea"
			                      outlined
			                      hide-details
			                      dense
			                    ></v-text-field>
			                  </v-col>

			                  <v-col cols="12" md="6">
			                    <v-select
			                      :items="[{tipo:'Llamada', id:1},{tipo:'WhatsApp', id:2},{tipo:'Correo', id:3},{tipo:'Otro', id:4}]"
			                      v-model="editedItem.tipo_tarea"
			                      label="Tipo"
			                      outlined
			                      hide-details
			                      dense
			                      item-text="tipo"
			                      item-value="id"
			                    ></v-select>
			                  </v-col>

			                  <v-col cols="12" sm="6">
			                  	<v-switch 
			                  		label="Activo"
			                      hide-details
			                  		v-model="editedItem.estatus"></v-switch>
			                  </v-col>
			                </v-row>
			              </v-container>
			            </v-card-text>

			            <v-card-actions>
			              <v-spacer></v-spacer>
			              <v-btn
			                color="blue darken-1"
			                text
			                @click="close"
			              >
			                Cancelar
			              </v-btn>
			              <v-btn
			                color="blue darken-1"
			                class="elevation-6"
			                dark
			                @click="save"
			              >
			                Guardar
			              </v-btn>
			            </v-card-actions>
			          </v-card>
			        </v-dialog>

			        <v-dialog v-model="dialogDelete" max-width="500px">
			          <v-card>
					      	<v-card-text>
						      	<v-row>
						      		<v-col cols="6">
						      			<v-card class="elevation-0" height="100%" style="display: table;">
							      			<div class="text-h6" style="display: table-cell; vertical-align: middle;">
							      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar la pregunta?
								      			<v-card-actions class="pt-6">
								      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
								      			  <v-btn color="error" text large class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
								      			  <!-- Guardar la información  -->
								      			  <v-btn color="#5C5C5C" dark class="elevation-6" large @click="deleteItemConfirm()">Si</v-btn>
								      			</v-card-actions>
							      			</div>
						      			</v-card>
						      		</v-col>
						      		<v-col cols="6">
						      			<v-img src="@/assets/borrar.png"></v-img>
						      		</v-col>
							      </v-row>
					      	</v-card-text>
					      </v-card>
			        </v-dialog>
			      </v-toolbar>
			    </template>
			    
			    <!-- Valores que ocupan botons, chips, iconos, etc -->
			    <template v-slot:item.estatus="{ item }">
			      <v-chip 
			      	v-if="item.estatus == 1"
			      	small
			      	color="green"
			      	dark
			      >Activo</v-chip>

			      <v-chip 
			      	v-else
			      	small
			      	color="error"
			      	dark
			      >Inactivo</v-chip>
			    </template>

			    <template v-slot:item.color="{ item }">
			      <v-chip 
			      	small
			      	:color="item.color"
			      	dark
			      ></v-chip>
			    </template>

			    <template v-slot:item.actions="{ item }">
			      <v-icon
			        small
			        class="mr-2"
			        color="primary"
			        @click="editItem(item)"
			      >
			        mdi-pencil
			      </v-icon>
			      <v-icon
			        small
			        @click="deleteItem(item)"
			        color="error"
			      >
			        mdi-delete
			      </v-icon>
			    </template>

			    <template v-slot:no-data>
			      <v-btn
			        color="primary"
			        @click="initialize"
			        small
			      >
			        Actualizar
			      </v-btn>
			    </template>
			  </v-data-table>
	  	</v-col>
	  </v-row>


		<!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="" style="display: table-cell; vertical-align: middle;">
		      				<strong>¡Bien!</strong> Datos grabados correctamente
			      			<v-card-actions class="pt-6">
			      				<v-spacer></v-spacer>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="green" dark class="elevation-6" large @click="dialogSuccess = false">Ok</v-btn>
			      				<v-spacer></v-spacer>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/correcto.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="300"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      persistent
      max-width="400"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div  style="display: table-cell; vertical-align: middle;">
		      				<span v-if="errorBack">
		      					<strong>ERROR</strong> Da clic en "COPIAR" para guardar el error y enviarlo a sistemas
		      				</span>
		      				<span v-else>
		      					<strong>ERROR</strong> {{error}}
		      				</span>
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text large class="mr-2" @click="dialogError = false">Cerrar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="copiarPortapapeles()" v-if="errorBack">{{textoCopiado}}</v-btn>
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="dialogError = false" v-else>Ok</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/eliminar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

	</v-container>
</template>
<script>
import axios from 'axios';
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ID'     , align: 'start', sortable: false, value: 'idtareas', },
        { text: 'tarea'  , value: 'tarea' },
        { text: 'Estatus', value: 'estatus' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      tareas: [],
      editedIndex: -1,
      editedItem: {
        idtareas: '',
        tarea: '',
        estatus: '',
        tipo_tarea:1
      },
      defaultItem: {
        idtareas: '',
        tarea: '',
        estatus: '',
        tipo_tarea:1
      },

      dialogSuccess:false,
      overlay: false,
      dialogError: 	false,
			error: 				'',
			successMensaje:'',
			errorBack:false,
      textoCopiado:'COPIAR',
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar tarea' : 'Editar tarea'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.tareas = []
        this.overlay = true
        this.$http.get('tareas.list').then(response=>{
        	this.tareas = response.data
        	this.overlay = false
        }).catch(error=>{
        	// INdicamos que hay un error en el back
        	if(error.body.message){
        		this.error = error.body.message
        	}else{
        		this.error = error.body
        	}
        	this.errorBack = true
        	this.overlay = false
        	this.dialogError = true
        })
      },

      editItem (item) {
        this.editedIndex = this.tareas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.tareas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        // Lo mandapos por el EP
        this.editedItem.deleted = 1
        this.$http.put('tareas.update/' + this.editedItem.idtareas, this.editedItem).then(response=>{
        	this.dialogSuccess = true
        	this.overlay = false
        	this.closeDelete()
        	this.initialize()
        }).catch(error=>{
        	// INdicamos que hay un error en el back
        	if(error.body.message){
        		this.error = error.body.message
        	}else{
        		this.error = error.body
        	}
        	this.errorBack = true
        	this.overlay = false
        	this.dialogError = true
        })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	if(this.editedItem.tarea == ''){
      		this.error     		= 'Favor de llenar todos los campos'
        	this.errorBack 		= false
        	this.overlay   		= false
	        this.dialogError 	= true
	        return
      	}
        if (this.editedIndex > -1) {
          // Activamos el loader
	        this.overlay = true
	        // Lo mandapos por el EP
          this.$http.put('tareas.update/' + this.editedItem.idtareas, this.editedItem).then(response=>{
          	this.dialogSuccess = true
          	this.initialize()
	        	this.overlay = false
	        }).catch(error=>{
	        	// INdicamos que hay un error en el back
	        	if(error.body.message){
	        		this.error = error.body.message
	        	}else{
	        		this.error = error.body
	        	}
	        	this.errorBack = true
	        	this.overlay = false
	        	this.dialogError = true
	        })

        } else {
        	// Activamos el loader
	        this.overlay = true
	        // Lo mandapos por el EP
          this.$http.post('tareas.add', this.editedItem).then(response=>{
          	this.dialogSuccess = true
          	this.initialize()
	        	this.overlay = false
	        }).catch(error=>{
	        	// INdicamos que hay un error en el back
	        	if(error.body.message){
	        		this.error = error.body.message
	        	}else{
	        		this.error = error.body
	        	}
	        	this.errorBack = true
	        	this.overlay = false
	        	this.dialogError = true
	        })
        }
        this.close()
      },

      copiarPortapapeles () {
        navigator.clipboard.writeText(this.error).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      }
    },
  }
</script>


