<template>
  <v-container class="mt-5 ml-2">
    <v-row>
    	<v-col cols="12">
    		<v-card>
		      <v-card-title
		        flat
		      >
		        Reporte de clases
		        <v-divider
		          class="mx-4"
		          inset
		          vertical
		        ></v-divider>
		        <v-spacer></v-spacer>
		        <v-btn 
	            class="mr-2" 
	            small dark color="green"
	            @click="exportar()">
	            <v-icon>mdi-microsoft-excel</v-icon>
	          </v-btn>
		        <v-btn color="primary" small rounded @click="getGrupos()">Buscar</v-btn>
		      </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4" lg="3">
                <v-autocomplete
                  outlined
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  v-model="dia"
                  dense
                  label="Fecha de inicio"
                  outlined
                  hide-details
                  clearable
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  v-model="dia2"
                  dense
                  label="Fecha final"
                  outlined
                  hide-details
                  clearable
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
	            <v-col cols="12" v-if="verGrafica">
				        <v-card class="elevation-0">
				          <v-card-text>
				            <vue-apex-charts
				              type="bar" height="350" :options="chartOptions" :series="series"
				            ></vue-apex-charts>
				          </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-data-table
                      class="elevation-1"
                      :headers="headers"
                      :items="clasesEvaluadas"
                      dense
                    ></v-data-table>
                    <v-spacer></v-spacer>
                  </v-card-actions>
				        </v-card>
				      </v-col>
            </v-row>
          </v-card-text>
    		</v-card>
    	</v-col>
    </v-row>

    <!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="300"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card
        color="green"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Datos grabados
        </v-card-title>
        <div class="pl-6 pb-6">
          <br/>
          NOTA: {{successMensaje}}
        </div>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogSuccess = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
	  
    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card
        color="error"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Error al recuperar los datos
        </v-card-title>
        <v-card-text>
          <br/>
          NOTA: {{error}}
        </v-card-text>
        <v-btn 
        	color="transparent elevation-0"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogError = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import axios from 'axios';
	import XLSX from 'xlsx'
	import VueApexCharts from 'vue-apexcharts'
  export default {
  	components: {
      VueApexCharts,
    },

    data () {
      return {
      	cicloInf: 		null,
      	ciclos:  			[],
      	dialogError: 	false,
				dialogSuccess:false,
				error: 				'',
				successMensaje:'',
				overlay: 			false,

        search: '',
        headers: [
          { text: 'Descripción'       , value: 'titulo' },
          { text: 'Total'             , value: 'clases_totales' },
          { text: 'Sin evaluar'       , value: 'no_evaluadas' },
          { text: 'Buenas'            , value: 'clases_buenas' },
          { text: 'Malas'             , value: 'clases_malas' },
          { text: '% Buenas'          , value: 'porcentaje' },
        ],
        teachers: [],

        overlay:false,
	      dialogError: false,
	      error:'',

	      dia:null,
	      dia2:null,
        horas:[],
        clases:[],

        verGrafica:false,
        series: [{
          name: 'MALAS',
          data: []
        }, {
          name: 'BUENAS',
          data: []
        }, {
          name: 'SIN EVALUAR',
          data: []
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 600,
            stacked: true,
            stackType: '100%'
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }],
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          xaxis: {
            categories: ['GENERAL', 'FAST', 'INBI'],
          },
          yaxis: {
            title: {
              text: '% de clases'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                // return "$ " + val + " thousands"
                return val
              }
            }
          },
          legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 50
          },

          bar: {
            dataLabels: {
              position: 'top'
            }
          },

          dataLabels: {
            style: {
              fontSize: '16px',
              fontWeight: 'bold',
            },
          },

        },
      }
    },

    async created () {
    	await this.getCiclos()
    },

    methods: {
    	getCiclos () {
        this.overlay = true
        this.ciclos = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError(error.body.message)
        })
      },

    	getGrupos () {
    		if(!this.cicloInf && !this.dia && !this.dia2){
    			this.openDialogError('Por favor, ingresa un ciclo o una fecha de inicio o un rango de fechas')
    		}else{
	    		this.verGrafica = false
	    		if(this.cicloInf && this.dia && !this.dia2){
	    			this.getClases(1)
	    		}

	    		if(this.cicloInf && this.dia && this.dia2){
	    			if(this.dia > this.dia2){
	    				this.openDialogError('La fecha de inicio no puede ser mayor a la fecha final')
	    			}else{
	    				this.getClases(2)
	    			}
	    		}

	    		if(this.cicloInf && !this.dia && this.dia2){
	    			this.openDialogError('Por favor, ingresa una fecha de inicio')
	    		}

	    		if(this.cicloInf && !this.dia && !this.dia2){
	    			this.getClases(3)
	    		}
    		}
    	},

    	getClases (item) {
        let payload = {}
        // Por ciclo y dia
        if(item == 1){
      		payload = {
      			cicloFast:   this.cicloInf.id_ciclo_relacionado,
      			cicloInbi:   this.cicloInf.id_ciclo,
      			fecha:       this.dia,
      			fecha2:      this.dia,
            tipo:        1
      		}
        }

        // Por ciclo y rango de fechas
        if(item == 2){
          payload = {
            cicloFast:   this.cicloInf.id_ciclo_relacionado,
            cicloInbi:   this.cicloInf.id_ciclo,
            fecha:       this.dia,
            fecha2:      this.dia2,
            tipo:        2
          }
        }

        // Solo ciclo
        if(item == 3){
          payload = {
            cicloFast:   this.cicloInf.id_ciclo_relacionado,
            cicloInbi:   this.cicloInf.id_ciclo,
            fecha:       "0000-00-00",
            fecha2:      "9999-12-31",
            tipo:        3
          }
        }
    		this.overlay = true
    		this.clases  = []
    		

    		this.$http.post('kpi.clases.hora.dia.reporte', payload).then(response=>{
    			this.series[0].data[0] = response.data.totales.clases_totales
    			this.series[0].data[1] = response.data.fast.clases_totales
          this.series[0].data[2] = response.data.inbi.clases_totales

    			this.series[0].data[0] = response.data.totales.clases_malas
    			this.series[0].data[1] = response.data.fast.clases_malas
          this.series[0].data[2] = response.data.inbi.clases_malas

    			this.series[1].data[0] = response.data.totales.clases_buenas
    			this.series[1].data[1] = response.data.fast.clases_buenas
          this.series[1].data[2] = response.data.inbi.clases_buenas

          this.series[2].data[0] = response.data.totales.no_evaluadas
          this.series[2].data[1] = response.data.fast.no_evaluadas
          this.series[2].data[2] = response.data.inbi.no_evaluadas

          this.clasesEvaluadas = [
            {
              titulo:'GENERAL', 
              clases_totales:response.data.totales.clases_totales, 
              no_evaluadas:  response.data.totales.no_evaluadas, 
              clases_buenas: response.data.totales.clases_buenas,
              clases_malas:  response.data.totales.clases_malas,
              porcentaje:    response.data.totales.porcentajesBuenas
            },
            {
              titulo:'FAST', 
              clases_totales:response.data.fast.clases_totales, 
              no_evaluadas:  response.data.fast.no_evaluadas, 
              clases_buenas: response.data.fast.clases_buenas,
              clases_malas:  response.data.fast.clases_malas,
              porcentaje:    response.data.fast.porcentajesBuenas
            },
            {
              titulo:'INBI', 
              clases_totales:response.data.inbi.clases_totales, 
              no_evaluadas:  response.data.inbi.no_evaluadas, 
              clases_buenas: response.data.inbi.clases_buenas,
              clases_malas:  response.data.inbi.clases_malas,
              porcentaje:    response.data.inbi.porcentajesBuenas
            },
          ]

        	this.verGrafica = true
        	this.overlay = false
        }).catch(error=>{
        	this.error = error.body.message
        	this.dialogError = true
        	this.overlay = false
        })
    	},

    	openDialogError (mensaje) {
      	this.error       = mensaje
      	this.dialogError = true
      },

      openDialogSuccess ( mensaje ){
      	this.successMensaje = mensaje
      	this.dialogSuccess  = true
      },

      update ( item ) {
      	this.$http.post('kpi.clases.hora.dia.update', item).then(response=>{
        	this.overlay = false
      		this.getGrupos()
        }).catch(error=>{
        	this.error = error.body.message
        	this.dialogError = true
        	this.overlay = false
        })
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportar(){
        let data = XLSX.utils.json_to_sheet(this.teachers)
        const workbook = XLSX.utils.book_new()
        const filename = 'clase_iniciada_fast'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });

      },
    }
  }
</script>