var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',[_c('div',{staticClass:"text-subtitle-1"},[_c('strong',[_vm._v("Hermanos")])]),_c('v-divider',{staticClass:"mb-4"}),(_vm.hermanos.length)?_c('div',_vm._l((_vm.hermanos),function(hermano){return _c('p',[_vm._v(" "+_vm._s(hermano.matricula)+" - "+_vm._s(hermano.nombre_completo)+" "),_c('v-icon',{staticClass:"ms-5",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.eliminar(hermano.matricula)}}},[_vm._v(" mdi-close ")])],1)}),0):_c('div',[_c('p',[_vm._v(_vm._s(_vm.mensaje))])])],1),(_vm.guardar)?_c('div',[_c('div',{staticClass:"text-subtitle-1"},[_c('strong',[_vm._v("Agregar Hermanos")])]),_c('v-divider',{staticClass:"mb-4"}),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"pe-0",attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"dense":"","label":"Matricula","filled":"","hide-details":"","width":"25"},model:{value:(_vm.hermano.matricula),callback:function ($$v) {_vm.$set(_vm.hermano, "matricula", $$v)},expression:"hermano.matricula"}})],1),_c('v-col',{staticClass:"pe-0",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.buscar()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-magnify ")])]}}],null,false,3200301953)},[_c('span',[_vm._v("Buscar alumno")])])],1)],1),(this.hermano.id)?_c('v-row',[_c('v-col',{staticClass:"p2-0",attrs:{"cols":"8"}},[(_vm.hermano.nombre)?_c('p',{staticClass:"ma-0"},[_c('strong',[_vm._v(_vm._s(_vm.hermano.nombre))])]):_c('p',{staticClass:"ma-0"},[_vm._v("No se encontro alumno con esta matricula")])]),_c('v-col',{staticClass:"pe-0",attrs:{"cols":"2","align":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","x-small":"","color":"secondary","dark":""},on:{"click":function($event){return _vm.cancelar()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)]}}],null,false,176987196)},[_c('span',[_vm._v("Cancelar")])])],1),_c('v-col',{staticClass:"pe-0",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","x-small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.nuevo()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-check ")])],1)]}}],null,false,2607548968)},[_c('span',[_vm._v("Confirmar")])])],1)],1):_vm._e()],1):_vm._e()])],1),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar)?_c('carga'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }