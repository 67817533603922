<template>
	<v-card class="elevation-0" >
		<v-row>

			<v-col cols="12" class="px-8">
				<v-alert
		      dense
		      outlined
		      type="error"
        	v-if="!mismoCurso"
		    >
		      Los alumnos no pueden estar en cursos diferentes, la beca solo aplica para los alumnos que tomarán el mismo curso.
		    </v-alert>
			</v-col>

			<v-col cols="12" class="px-8">
				<v-expansion-panels multiple active-class>
					<v-expansion-panel
			      v-for="(item, i ) in hermanos"
			      :key="i"
			      class="elevation-10"
			    >
			      <v-expansion-panel-header>
			        Datos del hermano {{ i + 1 }}
			      </v-expansion-panel-header>
			      <v-expansion-panel-content>
					  	<!-- BUSCAR POR MATRICULA -->
					  	
					  	<b>Nombre:</b> {{ item.nombre }} {{ item.apellido_paterno }} {{ item.apellido_materno }}
							<v-divider class="my-4"></v-divider>

							<b>Selecciona un grupo</b>
							<v-autocomplete
	              label="Grupos"
	              dense
	              v-model="item.grupo"
	              :items="filterGrupos"
	              item-text="grupo"
	              item-value="id_grupo"
	              clearable
	              filled
	              return-object
	            ></v-autocomplete>
			      </v-expansion-panel-content>
			    </v-expansion-panel>
			  </v-expansion-panels>
			</v-col>

		</v-row>

		<v-divider class="my-4"></v-divider>

		<v-card-actions>
      <v-btn text @click="steppers.estatus = false">
        Cancelar
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        v-if="mismoCurso"
        @click="steppers.paso = 3"
      >
        Continuar
      </v-btn>
  	</v-card-actions>


  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <AgregarProspecto     v-if="existeProspecto"             :parametrosProspectos="parametrosProspectos" @saveProspecto="saveProspecto"/>


	</v-card>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import AgregarProspecto   from '@/components/inscripciones/AgregarProspecto.vue';


  import validarErrores  from '@/mixins/validarErrores'

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
      AgregarProspecto
    },

    mixins: [ validarErrores ],

  	props:[
			'grupo',
			'alumno',
			'steppers',
			'hermanos',
			'tutor'
	  ],

    data: () => ({
    	escuela: 2,
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

    	prospectos:[],
    	parametrosProspectos:{
    		estatus: false
    	},

    	existeProspecto:false,
    	prospecto: null,
    	grupos:[],

    	id_ciclo: null,


    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),

    	mismoCurso( ){

    		let cursoInicial = 1 // Presencial, 
    		let cursoFinal   = 2 // Online
    		for( const i in this.hermanos ){

    			const { grupo } = this.hermanos[i]
    			
    			if( grupo ){

    				if( i == 0 ){

    					cursoInicial = grupo.grupo.match('ONLINE') ? 2 : 1

    				}else{

    					cursoFinal = grupo.grupo.match('ONLINE') ? 2 : 1

    					if( cursoFinal != cursoInicial ){
    						return false
    					}

    				}
    			}else{
    				return false
    			}

    		}

    		return true
    	},


    	filterGrupos( ){

    		let grupos = this.grupos

    		let hayGrupos = null


    		for( const i in this.hermanos ){

    			const { grupo } = this.hermanos[i]


    			if( grupo && !hayGrupos ){

    				hayGrupos = grupo

    			}


    		}

    		if( hayGrupos ){

    			this.id_ciclo = hayGrupos.id_ciclo

    		}else{
    			this.id_ciclo = null
    		}

    		return this.id_ciclo ? grupos.filter( el => el.id_ciclo == this.id_ciclo ) : grupos

    	}

    },

    watch:{
    	
    },

    async created () {
    	this.escuela = this.getEscuela
    	this.getGruposDisponibles( )
    },

    methods: {

      getGruposDisponibles( ) {
        this.cargar = true
        this.grupos = []

        return this.$http.get('grupos.disponibles/' + this.getEscuela ).then(response=>{
          this.cargar = false
          this.grupos = response.data

          console.log( this.grupos[0] )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      grabarDatos( ) {
      	this.cargar = true

      	if( !this.sonHermanos ){
	      	this.alumnoNuevo.tutor                    = this.isTutor ? this.tutor : null
	      	this.alumnoNuevo.unidad_negocio           = this.getEscuela
	      	this.alumnoNuevo.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp

	        return this.$http.post('alumnos.add', this.alumnoNuevo).then(response=>{
	        	this.alumnoNuevo = this.alumnoVacio
	        	this.tutor       = this.tutorVacio
	        	this.step        = 1
	        	this.$emit('retornarAlumno', response.data )
	        	this.validarSuccess('Alumno registrado correctamente')
	        	this.cargar = false
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
      	}else{
      		for( const i in this.hermanos ){
      			this.hermanos[i].unidad_negocio           = this.getEscuela
      			this.hermanos[i].id_usuario_ultimo_cambio = this.getdatosUsuario.iderp
      		}
      		const payload = {
      			hermanos: this.hermanos,
      			tutor:    this.tutor
      		}

	        return this.$http.post('alumnos.add.hermanos', payload).then(response=>{
	        	this.alumnoNuevo = this.alumnoVacio
	        	this.tutor       = this.tutorVacio
	        	this.step        = 1
	        	let objectHermanos  = Object.assign({}, this.alumnoVacio)
	        	let arrayHermanos   = [objectHermanos]
	        	this.hermanos       = arrayHermanos
	        	this.$emit('retornarAlumno', response.data[0] )
	        	this.validarSuccess('Alumno registrado correctamente')
	        	this.cargar = false
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
      	}
      },

      abrirDialogProspecto( ){
      	this.existeProspecto              = true
      	this.parametrosProspectos.estatus = true
      },

      saveProspecto( value ){
        this.alumnoNuevo.nombre                   = value.nombre_completo
	    	this.alumnoNuevo.apellido_paterno         = ''
	    	this.alumnoNuevo.apellido_materno         = ''
	    	this.alumnoNuevo.telefono                 = value.telefono
	    	this.alumnoNuevo.celular                  = value.telefono
	    	this.alumnoNuevo.id_tipo_alumno           = 1
	    	this.alumnoNuevo.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp
	    	this.alumnoNuevo.unidad_negocio           = this.getEscuela
	    	this.alumnoNuevo.id_prospecto             = value.idprospectos
	    	this.existeMatricula                      = true
      },
    },
  }
</script>
