<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Seguro Médico IMSS</span>
            <v-spacer></v-spacer>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>

              <v-col cols="12" md="6">
                <label></label>
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="seguromedicoimss"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                >

                  <template v-slot:item.update="{ item }">
                    <v-icon small @click="editItem(item)" color="blue">
                      mdi-magnify
                    </v-icon>
                  </template>

                  <template v-slot:item.eliminar="{ item }">
                    <v-icon small @click="deleteItem(item)" color="error">
                      mdi-delete
                    </v-icon>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>

            <v-col cols="12">
            <v-autocomplete
                :items="usuarios"
                v-model="editedItem.id_usuario"
                label="Empleado"
                outlined
                hide-details
                dense
                item-text="nombre_completo"
                item-value="id_usuario"
            ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field 
                v-model="editedItem.cantidad"
                label="Cantidad"
                outlined
                hide-details
                dense
                type = "number"
              ></v-text-field >
            </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
          <v-btn color="blue darken-1" class="elevation-6" dark @click="save">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar esta fila?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      large
                      class="mr-2"
                      @click="closeDelete()"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      large
                      @click="deleteItemConfirm()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "ID", align: "start", value: "idseguro_medico_imss" },
      { text: "Nombre", value: "nombre_completo" },
      { text: "Rebajes", value: "cantidad" },
      { text: "Actualizar", value: "update", sortable: false },
      { text: "Eliminar", value: "eliminar", sortable: false },
    ],
    seguromedicoimss: [],
    editedIndex: -1,
    editedItem: {
      idseguro_medico_imss: "",
      id_usuario: "",
      cantidad: "",
      fecha: "",
      deleted: 0
    },
    defaultItem: {
      idseguro_medico_imss: "",
      id_usuario: "",
      cantidad: "",
      fecha: "",
      deleted: 0
    },

    dialogSuccess: false,
    overlay: false,
    dialogError: false,
    error: "",
    successMensaje: "",
    errorBack: false,
    textoCopiado: "COPIAR",
    idcurso: 0,
    info: 0,
    dialog_mensaje_rechazo: false,
    idhistorial: [],
    editedItem2: {
      mensaje: "",
    },
    mensajes: [],
    usuarios: [],
    fecha_inicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
	fecha_final: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

    ciclos: [],
    ciclo: null,
    estatus: null,
    alumnos: [],
    reglas: [],
    cursosEscuela: [],
    horasExtras: "",
    // tabla
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
    formTitle() {
      return this.editedIndex === -1
        ? "Agregar Seguro Médico IMSS"
        : "Editar Seguro Médico IMSS";
    },

    filterHorasExtras() {

       if (this.estatus) {        
        return this.horasextras.filter((el) => {
          return el.estatus === this.estatus;
        });
      }
      else {
        return this.horasextras;
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "editedItem.escuela"(val) {
      this.cursosEscuela = this.cursos.filter((el) => {
        return el.escuela == val;
      });
    },
  },

  created() {
    this.initialize();
    this.getUsuarios();
  },

  methods: {
    initialize() {
      this.seguromedicoimss = [];
      this.overlay = true;

      this.$http
        .post("seguro_medico_imss.get.seguromedicoimss")
        .then((response) => {
          this.seguromedicoimss = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    getUsuarios() {
      this.usuarios = [];
      this.overlay = true;

      this.$http
        .post("seguro_medico_imss.get.usuarios")
        .then((response) => {
          this.usuarios = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    editItem(item) {
      this.editedIndex = this.seguromedicoimss.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.seguromedicoimss.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // Lo mandapos por el EP
      this.editedItem.deleted = 1;

      this.$http
        .put(
          "seguro_medico_imss.update/" + this.editedItem.idseguro_medico_imss,
          this.editedItem
        )
        .then((response) => {
          this.dialogSuccess = true;
          this.overlay = false;
          this.closeDelete();
          this.initialize();
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      
      if (this.editedIndex > -1) {
        // Activamos el loader
        this.overlay = true;
        this.editedItem.deleted = 0;
        // Lo mandapos por el EP
        this.$http
          .put(
            "seguro_medico_imss.update/" + this.editedItem.idseguro_medico_imss,
            this.editedItem
          )
          .then((response) => {
            this.dialogSuccess = true;
            this.initialize();
            this.overlay = false;
          })
          .catch((error) => {
            // INdicamos que hay un error en el back
            if (error.body.message) {
              this.error = error.body.message;
            } else {
              this.error = error.body;
            }
            this.errorBack = true;
            this.overlay = false;
            this.dialogError = true;
          });
      } else {
        // Activamos el loader
        this.overlay = true;
        // Lo mandapos por el EP

        if (this.editedItem.cantidad == "") {
        return this.validarErrorDirecto("Favor de llenar la casilla con la cantidad.");
        }

        this.$http
          .post("seguro_medico_imss.add.seguromedicoimss", this.editedItem)
          .then((response) => {
            this.dialogSuccess = true;
            this.initialize();
            this.overlay = false;
          })
          .catch((error) => {
            // INdicamos que hay un error en el back
            if (error.body.message) {
              this.error = error.body.message;
            } else {
              this.error = error.body;
            }
            this.errorBack = true;
            this.overlay = false;
            this.dialogError = true;
          });
      }
      this.close();
    },

    copiarPortapapeles() {
      navigator.clipboard
        .writeText(this.error)
        .then(() => {
          this.textoCopiado = "COPIADO";
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });
    },

  },
};
</script>
