<template>
	<v-container class="mt-4 ml-2">
	  <v-row>
	  	<v-col cols="12" md="10" lg="8">
	  		<v-card class="shadowCard">
	  			<v-card-title primary-title>
	  		    <span class="text-subtitle-1">Medio de contacto</span>
	  		    <v-spacer></v-spacer>
	  		    <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="dialog = true"
              small
              rounded
            >
              Agregar
            </v-btn>
	  		  </v-card-title>

          <tabla_basica_crud  
            :headers="headers" 
            :items="fuentes" 
            :editedItem="editedItem"
            :defaultItem="defaultItem"
            @editar="editItem"
            @eliminar="deleteItem"
            @actualizar="initialize"
          />
				</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Agregar mun registro -->
	  <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="editedItem.fuente"
            label="Fuente"
            filled
            dense
          ></v-text-field>

        	<v-switch 
        		label="Activo"
            hide-details
        		v-model="editedItem.estatus"
          ></v-switch>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="save"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Eliminar mensaje -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar la fuente?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text large class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C" dark class="elevation-6" large @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import tabla_basica_crud     from '@/components/basicos/tabla_basica_crud.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'
  import basicosTablas   from '@/mixins/basicosTablas'


  export default {

  	components:{
      Alerta,
      carga,
      tabla_basica_crud
    },

    mixins: [ validarErrores, funcionesExcel, basicosTablas ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ID'     , align: 'start', sortable: false, value: 'idfuentes', },
        { text: 'Fuente'  , value: 'fuente' },
        { text: 'Estatus' , value: 'estatus' },
        { text: 'Actions' , value: 'actions', sortable: false },
      ],
      fuentes: [],
      editedIndex: -1,
      editedItem: {
        idfuentes: '',
        fuente: '',
        estatus: '',
      },
      defaultItem: {
        idfuentes: '',
        fuente: '',
        estatus: '',
      },

    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar fuente' : 'Editar fuente'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.fuentes = []
        this.cargar = true
        this.$http.get('fuentes.list').then(response=>{
        	this.fuentes = response.data
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
      
      deleteItemConfirm () {
        // Lo mandapos por el EP
        this.editedItem.deleted = 1
        this.$http.put('fuentes.update/' + this.editedItem.idfuentes, this.editedItem).then(response=>{
          this.validarSuccess('Datos elimniados correctamente')
        	this.cargar = false
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      save () {
      	if(this.editedItem.fuente == ''){
        	this.validarErrorDirecto('Favor de llenar todos los campos')
	        return
      	}
        
        if (this.editedIndex > -1) {
          // Activamos el loader
	        this.cargar = true
	        // Lo mandapos por el EP
          this.$http.put('fuentes.update/' + this.editedItem.idfuentes, this.editedItem).then(response=>{
          	this.validarSuccess('Datos actualizados correctamente')
          	this.initialize()
            this.close()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })

        } else {
        	// Activamos el loader
	        this.cargar = true
	        // Lo mandapos por el EP
          this.$http.post('fuentes.add', this.editedItem).then(response=>{
          	this.validarSuccess('Datos agregados correctamente')
          	this.initialize()
            this.close()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },
    },
  }
</script>

