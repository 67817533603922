<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
	      <v-card class="shadowCard">
	      	<v-card-title >
		        <span class="text-subtitle-1">Alumnos Reprobados</span>

		        <v-spacer></v-spacer>

            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( alumnosFast  , 'ALUM_REPROBADOS')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="getGruposAlumnos()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>


	      	</v-card-title>
	        <v-card-text>
            <v-row justify="space-between">
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
	            
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  v-model="search1"
                  filled
                  dense
                  label="Buscar"
                  append-icon="mdi-magnify"
                >
                </v-text-field>
	            </v-col>
            </v-row>

          	<v-data-table
              :search="search1"
					    dense
					    :headers="headers"
					    :items="alumnosFast"
					    item-key="id"
					    class="elevation-0"
					  >
     
              <template v-slot:item.id_unidad_negocio="{ item }">
                <v-chip v-if="item.id_unidad_negocio == 1" small rounded color="colorinbi" dark >INBI</v-chip>
                <v-chip v-else small rounded color="colorfast" dark >FAST</v-chip>
              </template> 

              <template v-slot:item.notas="{ item }">
                <v-chip small rounded color="primary" dark @click="cargarNotas(item)">Comentarios</v-chip>
              </template> 

             <template v-slot:item.link="{ item }">
                <v-chip v-if="item.id_unidad_negocio == 1" small rounded color="colorinbi" dark href="https://www.inbi.mx/examen-reubicacion/" target="_blank">
                  Examen Reubicación
                </v-chip>
                <v-chip v-else small rounded color="colorfast" dark href="https://www.fastenglish.com.mx/examen-reubicacion/" target="_blank">
                  Examen Reubicación
                </v-chip>
              </template>

            </v-data-table>
	        </v-card-text>
	      </v-card>		
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogMensajes"
      persistent
      max-width="800"
    >
      <v-card
        style="position: relative"
      >
        <v-card-title primary-title >
          Notas
        </v-card-title>
        <v-card-text>
          <span v-for="(mensaje, i) in mensajes">
            <v-row v-if="mensaje.id_usuario != getdatosUsuario.iderp">
              <v-col cols="12">
                <v-card class="ml-6 pa-3 mt-2" max-width="400" color="#f2f2f2" style="border-radius: 15px; border-top-left-radius: 0">
                  <v-card-actions class="pa-0">
                    <div class="text-caption blue--text">{{ mensaje.usuario }}</div>
                  </v-card-actions>
                  <div class="text-subtitle-1">{{ mensaje.nota }} </div>
                  <v-card-actions class="pa-0">
                    <div class="text-caption blue--text">{{ mensaje.fecha_creacion }}</div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="mensaje.id_usuario == getdatosUsuario.iderp">
              <v-col cols="12" align="right">
                <v-card class="mr-6 mt-2 pa-2" max-width="400" dark color="#0465ac" shaped style="border-radius: 15px; border-top-right-radius: 0;">
                  <div class="text-subtitle-1">{{ mensaje.nota }}</div>
                  <v-card-actions class="pa-0">
                    <v-spacer></v-spacer>
                    <div class="text-caption white--text">{{ mensaje.fecha_creacion }}</div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </span>
        </v-card-text>

        <v-card-text class="pb-6 pt-6">
          <v-text-field
            v-model="mensaje"
            :append-outer-icon="mensaje ? 'mdi-send' : ''"
            outlined
            clearable
            label="Escribir..."
            type="text"
            @click:append-outer="enviarMensaje()"
            hide-details
            dense
          ></v-text-field>
        </v-card-text>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogMensajes = false, mensaje = ''"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search1:'',
      search2:'',
    	row:null,
    	tab: {name:'GruposAlumnos FAST'   , icon:'mdi-file' }, 
      items: [
        {name:'GruposAlumnos FAST'    , icon:'mdi-file' }, 
        {name:'GruposAlumnos INBI'    , icon:'mdi-file' }, 
      ],
      
      headers: [
        { text: 'id_grupo'   , value: 'id_grupo' },
        { text: 'grupo'      , value: 'grupo' },
        { text: 'id usuario' , value: 'usuario' },
        { text: 'Alumno'     , value: 'alumno' },
        { text: 'Escuela'    , value: 'id_unidad_negocio' },
        { text: 'Teacher 1'  , value: 'teacher' },
        { text: 'Teacher 2'  , value: 'teacher2' },
        { text: 'Nuevo Nivel'  , value: 'teacher2' },
        { text: 'Link'  , value: 'link' },
        { text: 'Comentarios', value: 'notas' },
      ],

      gruposAlumnos:[],
			cicloInf: 		null,
      ciclos:  			[],
      alumnosFast:  [],
      alumnosInbi:  [],
      dialogMensajes:false,
      mensajes: [],
      mensaje:'',
      alumno: null,
    }),

    watch: {
      cicloInf () {
        if(this.cicloInf){
          this.getGruposAlumnos()
        }
      },
    },

   
    created () {
      this.initialize()
      console.log(this.getdatosUsuario.planteles)
    },

    methods: {
    	initialize () {
      	this.cargar = true
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getGruposAlumnos () {
      	if(this.cicloInf){
	      	// vaciamos el horario
	      	this.alumnosFast = []
	      	// Activamos el loader
	    		this.cargar = true
	    		// Hacemos la peticios
	    		this.$http.get(`operaciones.reprobados.list/${ this.cicloInf.id_ciclo }/${ this.cicloInf.id_ciclo_relacionado }`).then(response=>{
	    			// Guardamos la respuesta
            for(const i in response.data){
              const existe = this.getdatosUsuario.planteles.find(el=> el.idplantel == response.data[i].id_plantel)
              if(existe){
                this.alumnosFast.push(response.data[i])
              }
            }
	          // Quitamos el loader
	          this.cargar = false
	        }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
      	}else{
      		this.validarErrorDirecto('Favor de seleccionar un ciclo')
      	}
      },

      getGruposAlumnosERP () {
      	// Activamos el loader
    		this.cargar = true
    		// Hacemos la peticion
    		this.$http.get(`lms.catalogos.gruposalumnos.cron/${ this.cicloInf.id_ciclo }/${ this.cicloInf.id_ciclo_relacionado }`).then(response=>{
    			// Mandamos a llamar a la funcion principal para que cargue los gruposAlumnos
          this.getGruposAlumnos()
          // Quitamos el loader
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // Sirve para cargar las notas del ticket
      cargarNotas ( payload ) {
        this.alumno = payload
        this.cargar  = true
        this.mensajes = []

        this.$http.post('operaciones.reprobados.notas', payload).then(response=>{
          this.mensajes        = response.data
          this.cargar         = false
          this.dialogMensajes  = true
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // Subir la nota del ticket
      enviarMensaje () {
        if(this.mensaje != '' && this.mensaje != null){
          this.cargar = true
          let payload = {
            id_usuario:         this.getdatosUsuario.iderp,
            id_grupo:           this.alumno.id_grupo,
            id_alumno:          this.alumno.id_alumno,
            id_unidad_negocio:  this.alumno.id_unidad_negocio,
            nota:               this.mensaje,
            tipo_usuario:       1
          }

          this.$http.post('operaciones.reprobados.notas.add',payload).then(response=>{
            this.mensaje  = ''
            this.mensajes = []
            this.cargarNotas( this.alumno )
            this.cargar = false
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }else{
          this.validarErrorDirecto('Ingresa un mensaje')
        }
      },

    },

    computed:{
    	...mapGetters('login',['getdatosUsuario'])
    }
  }
</script>

<style>
	.v-snack__content{
		padding: 0px !important;
	}

 .custom-link {
  text-decoration: none;
  color: white;
  }
</style>