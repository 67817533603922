<template>
  <v-container class="mt-4 px-5" fluid>
    <v-card class="pa-3">
      <v-row dense>
      	<v-col cols="12" md="8" lg="6">
      		<v-text-field
      		  label="Buscar archivo"
      		  v-model="search"
      		  outlined
      		  dense
      		></v-text-field>
      	</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="4" class="pa-4" v-for="(item, i ) in filterPDF" :key="i">
          <v-card
            color="primary"
            dark
          >
            <v-card-title class="text-h5">
              {{ item.nompdf }}
            </v-card-title>

            <div class="text-caption pl-5 pr-5">{{ item.descrip }}</div>

            <v-card-actions>
              <v-btn text @click="ver(item)">
                VER
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          max-width="1000"
          :height="tamanioPantalla"
        >
          <v-card>
            <v-card-title class="text-h5">
              {{ viewPdf.nompdf }}
              <v-spacer></v-spacer>
              <v-btn color="primary" icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
            	<embed :src="`https://web-back-inbi.club/viewer/web/viewer.html?file=`+verElPdf" width="100%" :height="tamanioPantalla" >
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
        
    </v-card>


  </v-container>
</template>
<script>
import axios from 'axios';
  export default {

    data: () => ({
    	source1: 'https://web-back-inbi.club/manuales/solicitud-cambios/Solicitar_cambios.pdf',
    	viewPdf:{},
    	pdfs: [
    		{
    			nompdf: 'Generar Tickets', 
    			descrip:'Este manual te mostrará como levantar y ver el seguimiento de un ticket', 
    			url:'manuales/tickets/Manual-tickets-general.pdf'
    		},
    		{
    			nompdf: 'Ticket - Area', 
    			descrip:'Este manual te mostrará como asignar tickets a un auxiliar, darle seguimiento y responderlo ', 
    			url:'manuales/tickets/Manual-tickets-area.pdf'
    		},
    		{
    			nompdf: 'Ticket - Auxiliar', 
    			descrip:'Este manual te mostrará como responder un ticket asignado para ti', 
    			url:'manuales/tickets/Manual-tickets-aux.pdf'
    		},
    	],

    	search:'',
    	dialog:'',
    	verElPdf: '',

    }),

    methods:{
    	ver (pdf) {
    		this.viewPdf = pdf
    		this.dialog = true
    		this.verElPdf = 'https://web-back-inbi.club/' + pdf.url
      },
    },

    computed:{
    	filterPDF() {
    		if(this.pdfs){
          return this.pdfs.filter((pdf)=>{
            var est = pdf.nompdf.toLowerCase();
            if(this.search == ''){
              return true
            }else{
              if(est.match(this.search.toLowerCase())){return true}
            }
          })
        }
    	},

    	tamanioPantalla () {
	      switch (this.$vuetify.breakpoint.name) {
	        case 'xs':
	          return this.$vuetify.breakpoint.height -185
	        break;
	        case 'sm': 
	          return this.$vuetify.breakpoint.height - 185
	        break;
	        case 'md':
	          return this.$vuetify.breakpoint.height - 185
	        break;
	        case 'lg':
	          return this.$vuetify.breakpoint.height - 185
	        break;
	        case 'xl':
	          return this.$vuetify.breakpoint.height - 185
	        break;
	      }
    	},
    }
  }
</script>

<style>
  body {
  margin: 0;
  font-family: sans-serif;
  font-size: 0.9rem;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#toolbar {
  display: flex;
  align-items: center;
  background-color: #555;
  color: #fff;
  padding: 0.5em;
}
#toolbar button,
#page-mode input {
  color: currentColor;
  background-color: transparent;
  font: inherit;
  border: 1px solid currentColor;
  border-radius: 3px;
  padding: 0.25em 0.5em;
}
#toolbar button:hover,
#toolbar button:focus,
#page-mode input:hover,
#page-mode input:focus {
  color: lightGreen;
}
#page-mode {
  display: flex;
  align-items: center;
  padding: 0.25em 0.5em;
}

#viewport-container {
  flex: 1;
  background: #eee;
  overflow: auto;
}


#viewport {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#viewport > div {
  text-align: center;
  max-width: 100%;
}
#viewport canvas {
  width: 100%;
  box-shadow: 0 2px 5px gray;
}

#viewerContainer::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

.#viewerContainer > #viewerContainer::-webkit-scrollbar:vertical {
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar:vertical{
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar-thumb{
  background-color: #BABCBF;
  border-radius: 20px;
  border: 1px solid #ededed;
}
  
</style>