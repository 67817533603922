<template> 
    <v-container class="ma-0 pa-0"  v-if="respuestas.respuestaauxi">
        <v-row justify="end" class="mt-2">
            <v-col cols="6" class="pa-2 d-flex justify-end">
                <p class="ma-0"><strong>Respuesta del auxiliar</strong></p>
            </v-col>
        </v-row>
        <v-row justify="end" class="mt-0">
            <v-col cols="8" class="pa-2">
                <p class="pa-2 mb-0 text-end mensajeDer">
                {{ respuestas.respuestaauxi }}
                </p>
            </v-col>
        </v-row>
        <v-row justify="end" class="mt-1" v-if="respuestas.evidenciasRespuesta.length">
              <v-col cols="3" v-for="(img, i) in respuestas.evidenciasRespuesta" :key="i">
                <v-card class="py-4 shadowCard" >
                  <v-card-text @click="verImagen( img.foto, img.es_solucion )" class="pa-0">
                    <v-img v-if="!img.video" :src="url + img.foto" contain aspect-ratio="2" ></v-img>
                    <video v-else :src="url + img.foto" contain aspect-ratio="2" style="width: 100%; height: 100%;" class="d-flex"></video>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-center">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn color="error" x-small @click="eliminarFotoBDD(img)"
                          absolute v-bind="attrs" v-on="on" class="mt-5 pa-3">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar imagen del auxiliar</span>
                    </v-tooltip>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
        <!-- Visualizar imagenes -->
        <Imagen @close="closeImagen" :respuestaImagen="respuestaImagen" :isOpen="dialogImagen" :imagen="imagen" :ticket="ticket"/>
    </v-container>
</template>
<script>
import axios from 'axios';
import Imagen                from '@/components/tickets/Imagen.vue'
export default {
    props: [
        'ticket',
        'respuestas'
    ],
    components:{
        Imagen
    },
    data: () => ({
        url:'',
        urlINBI:'',
        urlFAST:'',
        dialogImagen:false,
        respuestaImagen: false,
        imagen: '',
    }),
    created () {
        this.url = axios.defaults.baseURL + 'evidencia-tickets/'
        this.urlINBI = 'https://lmsinbi.club/evidencia-tickets/'
        this.urlFAST = 'https://lmsfast.club/evidencia-tickets/'
    },
    methods:{
        verImagen( imagen, respuestaImagen ){
            this.imagen = imagen
            this.respuestaImagen = respuestaImagen
            this.dialogImagen = true
        },
        closeImagen(){
            this.dialogImagen = false
        },
        eliminarFotoBDD(value){
            console.log(this.respuestas.evidenciasRespuesta)
            this.respuestas.evidenciasRespuesta.forEach((element, index)=>{
                if(element.foto == value.foto){
                    this.respuestas.evidenciasRespuesta.splice(index,1);
                }
            })
            //enviamos el nombre del archivo ya que al venir del auxiliar siempre esta en el mismo servidor
            this.$http.delete('tickets.eliminar.evidencia/' + value.foto).then(response=>{
                console.log(response)
            }).catch( error =>{
                this.validarError( error.response.data.message )
            }).finally( () => {  })

            this.$http.delete('historial_tickets.eliminar_evidencia/'+value.idevidencia_historial_tickets).then(response=>{
                console.log(response)
            }).catch( error =>{
                this.validarError( error.response.data.message )
            }).finally( () => { this.cargar = false })
        },
    },
}
</script>
<style>
  .mensajeIzq{
    white-space: pre-line;
    background-color: rgb(238, 235, 235);
    border-radius: 0px 10px 10px 10px;
  }
  .mensajeDer{
    white-space: pre-line;
    background-color: rgb(193, 231, 243);
    border-radius: 10px 0px 10px 10px;
  }
</style>