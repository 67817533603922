import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';
import axios from 'axios';
import XLSX from 'xlsx'

export default {
	data: () => ({
    headersBloque1: [
      { text: 'Grupo'        , value: 'grupo'       },
      { text: 'Salón'        , value: 'salon'       },
      { text: 'Inicio'       , value: 'hora_inicio' },
      { text: 'Final'        , value: 'hora_fin'    },
      { text: '% asist.'     , value: 'porcentaje_asistencia' },
      { text: 'Alumnos'      , value: 'cant_alumnos'},
      { text: 'Nivel'        , value: 'id_nivel'    },
      { text: 'Sucursal'     , value: 'plantel'     },
      { text: 'Modalidad'    , value: 'modalidad'   },
      { text: 'Lunes 1'      , value: 'lu1_n'       },
      { text: 'Martes 1'     , value: 'ma1_n'       },
      { text: 'Miércoles 1'  , value: 'mi1_n'       },
      { text: 'Jueves 1'     , value: 'ju1_n'       },
      { text: 'Viernes 1'    , value: 'vi1_n'       },
    ],

    headersBloque2: [
      { text: 'Grupo'        , value: 'grupo'       },
      { text: 'Salón'        , value: 'salon'       },
      { text: 'Inicio'       , value: 'hora_inicio' },
      { text: 'Final'        , value: 'hora_fin'    },
      { text: '% asist.'     , value: 'porcentaje_asistencia' },
      { text: 'Alumnos'      , value: 'cant_alumnos'},
      { text: 'Nivel'        , value: 'id_nivel'    },
      { text: 'Sucursal'     , value: 'plantel'     },
      { text: 'Modalidad'    , value: 'modalidad'   },
      { text: 'Lunes 2'      , value: 'lu2_n'       },
      { text: 'Martes 2'     , value: 'ma2_n'       },
      { text: 'Miércoles 2'  , value: 'mi2_n'       },
      { text: 'Jueves 2'     , value: 'ju2_n'       },
      { text: 'Viernes 2'    , value: 'vi2_n'       },
    ],

    headersBloque3: [
      { text: 'Grupo'        , value: 'grupo'       },
      { text: 'Salón'        , value: 'salon'       },
      { text: 'Inicio'       , value: 'hora_inicio' },
      { text: 'Final'        , value: 'hora_fin'    },
      { text: '% asist.'     , value: 'porcentaje_asistencia' },
      { text: 'Alumnos'      , value: 'cant_alumnos'},
      { text: 'Nivel'        , value: 'id_nivel'    },
      { text: 'Sucursal'     , value: 'plantel'     },
      { text: 'Modalidad'    , value: 'modalidad'   },
      { text: 'Lunes 3'      , value: 'lu3_n'       },
      { text: 'Martes 3'     , value: 'ma3_n'       },
      { text: 'Miércoles 3'  , value: 'mi3_n'       },
      { text: 'Jueves 3'     , value: 'ju3_n'       },
      { text: 'Viernes 3'    , value: 'vi3_n'       },
    ],

    headersBloque4: [
      { text: 'Grupo'        , value: 'grupo'       },
      { text: 'Salón'        , value: 'salon'       },
      { text: 'Inicio'       , value: 'hora_inicio' },
      { text: 'Final'        , value: 'hora_fin'    },
      { text: '% asist.'     , value: 'porcentaje_asistencia' },
      { text: 'Alumnos'      , value: 'cant_alumnos'},
      { text: 'Nivel'        , value: 'id_nivel'    },
      { text: 'Sucursal'     , value: 'plantel'     },
      { text: 'Modalidad'    , value: 'modalidad'   },
      { text: 'Lunes 4'      , value: 'lu4_n'       },
      { text: 'Martes 4'     , value: 'ma4_n'       },
      { text: 'Miércoles 4'  , value: 'mi4_n'       },
      { text: 'Jueves 4'     , value: 'ju4_n'       },
      { text: 'Viernes 4'    , value: 'vi4_n'       },
    ],

    headers:[],
    selectedHeaders: null,

    checkEliminados: false,
    checkFaltistas: false,
    checkOptimizados: 3,
    curso: null,

    datosDuplicados: [],
    dialogDuplicado:false,

    fechaActual: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
  }),

  created(){
  	// this.headers = this.headersBloque1
  },

  watch: {
  	selectedHeaders( val ){
  		switch( val ){
  			case 1: 
  				this.headers = this.headersBloque1
  			break;

  			case 2: 
  				this.headers = this.headersBloque2
  			break;

  			case 3: 
  				this.headers = this.headersBloque3
  			break;

  			case 4: 
  				this.headers = this.headersBloque4
  			break;
  		}
  	}
  },

  computed:{
  	filterGrupos(){

  		let grupos = []


  		if( this.checkEliminados ){
  			grupos = this.gruposRoles.filter( el => { return el.deleted == 1 })
  		}else{
  			grupos = this.gruposRoles.filter( el => { return el.deleted == 0 })
  		}

      if( this.checkFaltistas ){
        grupos = this.gruposRoles.filter( el => { return ( el.lu1_as && el.lu1_as.substr( el.lu1_as.length - 1,1 ) == 2 ) || ( el.ma1_as && el.ma1_as.substr( el.ma1_as.length - 1,1 ) == 2 ) || ( el.mi1_as && el.mi1_as.substr( el.mi1_as.length - 1,1 ) == 2 ) || ( el.ju1_as && el.ju1_as.substr( el.ju1_as.length - 1,1 ) == 2 ) || ( el.vi1_as && el.vi1_as.substr( el.vi1_as.length - 1,1 ) == 2 ) || ( el.lu2_as && el.lu2_as.substr( el.lu2_as.length - 1,1 ) == 2 ) || ( el.ma2_as && el.ma2_as.substr( el.ma2_as.length - 1,1 ) == 2 ) || ( el.mi2_as && el.mi2_as.substr( el.mi2_as.length - 1,1 ) == 2 ) || ( el.ju2_as && el.ju2_as.substr( el.ju2_as.length - 1,1 ) == 2 ) || ( el.vi2_as && el.vi2_as.substr( el.vi2_as.length - 1,1 ) == 2 ) || ( el.lu3_as && el.lu3_as.substr( el.lu3_as.length - 1,1 ) == 2 ) || ( el.ma3_as && el.ma3_as.substr( el.ma3_as.length - 1,1 ) == 2 ) || ( el.mi3_as && el.mi3_as.substr( el.mi3_as.length - 1,1 ) == 2 ) || ( el.ju3_as && el.ju3_as.substr( el.ju3_as.length - 1,1 ) == 2 ) || ( el.vi3_as && el.vi3_as.substr( el.vi3_as.length - 1,1 ) == 2 ) || ( el.lu4_as && el.lu4_as.substr( el.lu4_as.length - 1,1 ) == 2 ) || ( el.ma4_as && el.ma4_as.substr( el.ma4_as.length - 1,1 ) == 2 ) || ( el.mi4_as && el.mi4_as.substr( el.mi4_as.length - 1,1 ) == 2 ) || ( el.ju4_as && el.ju4_as.substr( el.ju4_as.length - 1,1 ) == 2 ) || ( el.vi4_as && el.vi4_as.substr( el.vi4_as.length - 1,1 ) == 2 ) })
      }
      
      if( this.escuela ){
        if(this.escuela != 3 ){
          grupos = grupos.filter( el => { return el.id_unidad_negocio == this.escuela })
        }
      }

  		if( this.nivel ){
  			grupos = grupos.filter( el => { return el.id_nivel == this.nivel })
  		}

  		if( this.plantel ){
  			grupos = grupos.filter( el => { return el.plantel == this.plantel })
  		}

  		if( this.horario ){
  			grupos = grupos.filter( el => { return el.hora_inicio == this.horario })
  		}

  		if( [0,1].includes(this.modalidad)){
  			grupos = grupos.filter( el => { return el.online == this.modalidad })
  		}

      if( this.curso == 4 ){
        grupos = grupos.filter( el => { return !el.grupo.match('SABA') })
      }

      if( this.curso == 1 ){
        grupos = grupos.filter( el => { return el.grupo.match('DOMI') })
      }

      if( this.curso == 2 ){
        grupos = grupos.filter( el => { return el.grupo.match('SABA') })
      }

      if( this.curso == 3 ){
        grupos = grupos.filter( el => { return !el.grupo.match('SABA') && !el.grupo.match('DOMI') })
      }

      if( this.curso == 5 ){
        grupos = grupos.filter( el => { return el.horas_dia == 1.5 })
      }

  		if( this.teacherFilter ){
        console.log( this.teacherFilter )
        const diasSemana = ['lu', 'ma', 'mi', 'ju', 'vi'];
        const bloques = [this.selectedHeaders]; // Puedes ajustar esto según tus necesidades

        if( this.teacherFilter == 'SIN TEACHER'){
          grupos = grupos.filter(el => 
            (el[`lu${this.selectedHeaders}_n`] === this.teacherFilter || ( !el[`lu${this.selectedHeaders}_n`] && ( el.lunes || el.sabado || el.domingo) )) ||  (el[`ma${this.selectedHeaders}_n`] === this.teacherFilter ||  ( !el[`ma${this.selectedHeaders}_n`] && ( el.martes || el.sabado || el.domingo) )) ||  (el[`mi${this.selectedHeaders}_n`] === this.teacherFilter ||  ( !el[`mi${this.selectedHeaders}_n`] && ( el.miercoles || el.sabado || el.domingo) )) || (el[`ju${this.selectedHeaders}_n`] === this.teacherFilter ||  ( !el[`ju${this.selectedHeaders}_n`] && ( el.jueves || el.sabado || el.domingo) )) ||  (el[`vi${this.selectedHeaders}_n`] === this.teacherFilter ||  ( !el[`vi${this.selectedHeaders}_n`] && ( el.viernes || el.sabado || el.domingo) )) )

          // grupos = grupos.filter(el =>
          //   diasSemana.some(dia =>
          //     bloques.some(bloque => el[`${dia}${bloque}_n`] === this.teacherFilter || ( !el[`${dia}${bloque}_n`]    ))
          //   )
          // );

        }else{
    			grupos = grupos.filter(el =>
            diasSemana.some(dia =>
              bloques.some(bloque => el[`${dia}${bloque}_n`] === this.teacherFilter)
            )
          );
        }
  		}

  		return grupos
  	},

    filterGruposSinTeacher( ){


      if( this.diaSeleccionado ){

        const gruposOficiales = this.gruposRoles.filter( el => { return el.deleted == 0 })

        const splitDia = this.diaSeleccionado.split('-')

        const dia = splitDia[0]

        const numerDia = splitDia[1]

        const dosLetras = dia.substring(0,2)

        if( dia == 'sabado' || dia == 'domingo' ){
          let gruposOficialesFiltrados = gruposOficiales.filter( el => { return el[`${dia}`] == 1 })
          console.log( gruposOficialesFiltrados.find( el => el.iderp == 13548 ) )
          return gruposOficialesFiltrados.filter( el => { return !el[`lu${numerDia}`] || !el[`ma${numerDia}`] || !el[`mi${numerDia}`] || !el[`ju${numerDia}`] || !el[`vi${numerDia}`]  })
        }else{
          // 
          let textoColumna = dosLetras+numerDia
          return gruposOficiales.filter( el => { return el[`${dia}`] == 1 && !el[`${textoColumna}`] && el.deleted == 0 })
        }

      }else{
        return []
      }
    },

    gruposCompletos( ){
      return this.filterGrupos.filter( el => { return el.lu1_n && el.ju1_n  })
    },

    gruposFaltantes( ){

      // let contador = 0

      // for( const i in this.filterGrupos ){

      //   const { lunes, martes, miercoles, jueves, viernes, sabado, domingo, lu1, ma1, mi1, ju1, vi1 } = this.filterGrupos[i]

      //   if( lunes ){
      //     contador += lu1 ? 0 : 1
      //   }else if( martes ){
      //     contador += ma1 ? 0 : 1
      //   }else if( miercoles ){
      //     contador += mi1 ? 0 : 1
      //   }else if( jueves ){
      //     contador += ju1 ? 0 : 1
      //   }else if( viernes ){
      //     contador += vi1 ? 0 : 1
      //   }else if( sabado ){
      //     contador += (lu1 || vi1) ? 0 : 1
      //   }else if( domingo ){
      //     contador += (lu1 || vi1) ? 0 : 1
      //   }

      // }

      // return contador 

      return this.filterGrupos.filter( el => { return !el.lu1_n || !el.ju1_n  })
    },

    horasSemana( ){
      return this.filterGrupos.map(item => item.horas_semana ).reduce((prev, curr) => prev + curr, 0)
    },

    horasCatorcena( ){
      return this.filterGrupos.map(item => item.horas_catorcena ).reduce((prev, curr) => prev + curr, 0)
    },

    horasCiclo( ){
      return this.filterGrupos.map(item => item.horas_ciclo ).reduce((prev, curr) => prev + curr, 0)
    },

    filterSinBitacora( ){

      // Cantidad de grupos que ya tuvieron su clase
      let gruposActivos = this.filterGrupos

      // Ahora hay que ver la fecha de hoy
      let contador = 0

      for( const i in gruposActivos ){

        const { lunes, martes, miercoles, jueves, viernes, sabado, domingo, lu1_fecha, ma1_fecha, mi1_fecha, ju1_fecha, vi1_fecha, lu2_fecha, ma2_fecha, mi2_fecha, ju2_fecha, vi2_fecha, lu3_fecha, ma3_fecha, mi3_fecha, ju3_fecha, vi3_fecha, lu4_fecha, ma4_fecha, mi4_fecha, ju4_fecha, vi4_fecha } = gruposActivos[i]

        if( lu1_fecha <= this.fechaActual && !gruposActivos[i].lu1_bitacora && ( lunes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].lu1 ) ){ 
          contador +=1 
        }

        if( lu2_fecha <= this.fechaActual && !gruposActivos[i].lu2_bitacora && ( lunes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].lu2 ) ){ 
          contador +=1 
        }

        if( lu3_fecha <= this.fechaActual && !gruposActivos[i].lu3_bitacora && ( lunes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].lu3 ) ){ 
          contador +=1 
        }

        if( lu4_fecha <= this.fechaActual && !gruposActivos[i].lu4_bitacora && ( lunes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].lu4 ) ){ 
          contador +=1 
        }


        if( ma1_fecha <= this.fechaActual && !gruposActivos[i].ma1_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ma1 ) ){ 
          contador +=1 
        }

        if( ma2_fecha <= this.fechaActual && !gruposActivos[i].ma2_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ma2 ) ){ 
          contador +=1 
        }

        if( ma3_fecha <= this.fechaActual && !gruposActivos[i].ma3_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ma3 ) ){ 
          contador +=1 
        }

        if( ma4_fecha <= this.fechaActual && !gruposActivos[i].ma4_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ma4 ) ){ 
          contador +=1 
        }


        if( mi1_fecha <= this.fechaActual && !gruposActivos[i].mi1_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].mi1 ) ){ 
          contador +=1 
        }

        if( mi2_fecha <= this.fechaActual && !gruposActivos[i].mi2_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].mi2 ) ){ 
          contador +=1 
        }

        if( mi3_fecha <= this.fechaActual && !gruposActivos[i].mi3_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].mi3 ) ){ 
          contador +=1 
        }

        if( mi4_fecha <= this.fechaActual && !gruposActivos[i].mi4_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].mi4 ) ){ 
          contador +=1 
        }

        if( ju1_fecha <= this.fechaActual && !gruposActivos[i].ju1_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ju1 ) ){ 
          contador +=1 
        }

        if( ju2_fecha <= this.fechaActual && !gruposActivos[i].ju2_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ju2 ) ){ 
          contador +=1 
        }

        if( ju3_fecha <= this.fechaActual && !gruposActivos[i].ju3_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ju3 ) ){ 
          contador +=1 
        }

        if( ju4_fecha <= this.fechaActual && !gruposActivos[i].ju4_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ju4 ) ){ 
          contador +=1 
        }

        if( vi1_fecha <= this.fechaActual && !gruposActivos[i].vi1_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].vi1 ) ){ 
          contador +=1 
        }

        if( vi2_fecha <= this.fechaActual && !gruposActivos[i].vi2_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].vi2 ) ){ 
          contador +=1 
        }

        if( vi3_fecha <= this.fechaActual && !gruposActivos[i].vi3_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].vi3 ) ){ 
          contador +=1 
        }

        if( vi4_fecha <= this.fechaActual && !gruposActivos[i].vi4_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].vi4 ) ){ 
          contador +=1 
        }


      }


      return contador

      // !item.grupo.match('ONLINE') && item.ma1_fecha <= fecha_hoy && !item.ma1_hora_asistencia ? 'yellow' : '' "
      // ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.ma1_bitacora "
    }

  },

	methods: {

    async exportExcel(dataExport, name, bloque, hoja3, hoja4, hoja5 ) {

      const datosHoja2 = await this.datosHoja2( dataExport, bloque ).then( response => response )
      // Crear un objeto de hoja de cálculo en formato JSON
      const data = XLSX.utils.json_to_sheet(dataExport);
      const data2 = XLSX.utils.json_to_sheet(datosHoja2);
      const data3 = XLSX.utils.json_to_sheet(hoja3);
      const data4 = XLSX.utils.json_to_sheet(hoja4);
      const data5 = XLSX.utils.json_to_sheet(hoja5);

      
      // Crear un libro de trabajo y agregar la hoja de cálculo
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, data2, 'REQUISICION');
      XLSX.utils.book_append_sheet(workbook, data, name);
      XLSX.utils.book_append_sheet(workbook, data3, 'SABATINO');
      XLSX.utils.book_append_sheet(workbook, data4, 'ENTRESEMANA');
      XLSX.utils.book_append_sheet(workbook, data5, 'DOMINICAL');

      
      // Escribir el libro de trabajo en formato CSV
      const wbout = XLSX.write(workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary'
      });
      
      // Convertir los datos binarios a un Blob
      const blob = new Blob([this.s2ab(wbout)]);
      
      // Crear un objeto File a partir del Blob
      const file = new File([blob], name + '.xlsx');
      
      // Crear un FormData y adjuntar el archivo
      const formData = new FormData();
      formData.append('file', file);
      
      // Enviar el FormData al servidor
      this.uploadFile(formData, name);
    },


    async exportExcel55(dataExport, name ) {

      // Crear un objeto de hoja de cálculo en formato JSON
      const data = XLSX.utils.json_to_sheet(dataExport);

      // Crear un libro de trabajo y agregar la hoja de cálculo
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, data, name);
      
      // Escribir el libro de trabajo en formato CSV
      const wbout = XLSX.write(workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary'
      });
      
      // Convertir los datos binarios a un Blob
      const blob = new Blob([this.s2ab(wbout)]);
      
      // Crear un objeto File a partir del Blob
      const file = new File([blob], name + '.xlsx');
      
      // Crear un FormData y adjuntar el archivo
      const formData = new FormData();
      formData.append('file', file);
      
      // Enviar el FormData al servidor
      this.uploadFile(formData, name);
    },

    uploadFile(formData, filename) {
      this.$http.post('pdfs', formData)
        .then(response => {
          // Redirigir al archivo descargado
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xlsx';
        })
        .catch(error => {
          console.log(error);
        });
    },

    datosHoja2( dataExport, bloque ){

      return new Promise((resolve,reject)=> {

        let planteles = [7,8,9,10,11,12,14,16,20]

        let puntero = 0

        let respuesta = []

        for( const i in dataExport){

          const { id_teacher, teacher, PAGO_TOTAL } = dataExport[i]

          // elemento, concepto, plantel, proyecto, cantidad, costo unit, total, estatus
          /*
            id_elemento_requisicion
            id_plantel
            concepto
            cantidad
            costo_unitario
            costo_total
          */

          respuesta.push({
            id_elemento_requisicion: 17,
            id_plantel: planteles[puntero],
            concepto: `${ teacher } (SEMANA ${bloque} )`,
            cantidad: 1,
            costo_unitario: PAGO_TOTAL ,
            costo_total: PAGO_TOTAL,
          })

          // Modificar el puntero... 

          if( puntero >= 8 )
            puntero = 0
          else
            puntero++

        }

        resolve( respuesta )
      })
    },



    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    },

    verificacionRol( ){

      this.datosDuplicados = []

      let gruposOficiales = this.gruposRoles.filter( el => el.deleted == 0 )

      // LUNES
      let gruposLunes = gruposOficiales.filter( el => el.lunes == 1 )
      let sacarDatosDuplicados = this.funcionValidar( 'lu1_n', 'lu1', gruposLunes, 'Lunes', 1 )

      if( sacarDatosDuplicados.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados) 
      }

      // MARTES
      let gruposMartes = gruposOficiales.filter( el => el.martes == 1 )
      let sacarDatosDuplicados2 = this.funcionValidar( 'ma1_n', 'ma1', gruposMartes, 'Martes', 1 )

      if( sacarDatosDuplicados2.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados2) 
      }

      // MIERCOLES
      let gruposMiercoles = gruposOficiales.filter( el => el.miercoles == 1 )
      let sacarDatosDuplicados3 = this.funcionValidar( 'mi1_n', 'mi1', gruposMiercoles, 'Miércoles', 1 )

      if( sacarDatosDuplicados3.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados3) 
      }

      // JUEVES
      let gruposJueves = gruposOficiales.filter( el => el.jueves == 1 )
      let sacarDatosDuplicados4 = this.funcionValidar( 'ju1_n', 'ju1', gruposJueves, 'Jueves', 1 )

      if( sacarDatosDuplicados4.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados4) 
      }

      // VIERNES
      let gruposViernes = gruposOficiales.filter( el => el.viernes == 1 )
      let sacarDatosDuplicados5 = this.funcionValidar( 'vi1_n', 'vi1', gruposViernes, 'Viernes', 1 )

      if( sacarDatosDuplicados5.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados5) 
      }

      /*****************************************************/

      // LUNES
      let gruposLunes2 = gruposOficiales.filter( el => el.lunes == 1 )
      let sacarDatosDuplicados6 = this.funcionValidar( 'lu2_n', 'lu2', gruposLunes2, 'Lunes', 2 )

      if( sacarDatosDuplicados6.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados6) 
      }

      // MARTES
      let gruposMartes2 = gruposOficiales.filter( el => el.martes == 1 )
      let sacarDatosDuplicados7 = this.funcionValidar( 'ma2_n', 'ma2', gruposMartes2, 'Martes', 2 )

      if( sacarDatosDuplicados7.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados7) 
      }

      // MIERCOLES
      let gruposMiercole2 = gruposOficiales.filter( el => el.miercoles == 1 )
      let sacarDatosDuplicados8 = this.funcionValidar( 'mi2_n', 'mi2', gruposMiercole2, 'Miércoles', 2 )

      if( sacarDatosDuplicados8.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados8) 
      }

      // JUEVES
      let gruposJueves2 = gruposOficiales.filter( el => el.jueves == 1 )
      let sacarDatosDuplicados9 = this.funcionValidar( 'ju2_n', 'ju2', gruposJueves2, 'Jueves', 2 )

      if( sacarDatosDuplicados9.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados9) 
      }

      // VIERNES
      let gruposViernes2 = gruposOficiales.filter( el => el.viernes == 1 )
      let sacarDatosDuplicados10 = this.funcionValidar( 'vi2_n', 'vi2', gruposViernes2, 'Viernes', 2 )

      if( sacarDatosDuplicados10.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados10) 
      }


      /*****************************************************/

      // LUNES
      let gruposLunes3 = gruposOficiales.filter( el => el.lunes == 1 )
      let sacarDatosDuplicados11 = this.funcionValidar( 'lu3_n', 'lu3', gruposLunes3, 'Lunes', 3 )

      if( sacarDatosDuplicados11.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados11) 
      }

      // MARTES
      let gruposMartes3 = gruposOficiales.filter( el => el.martes == 1 )
      let sacarDatosDuplicados12 = this.funcionValidar( 'ma3_n', 'ma3', gruposMartes3, 'Martes', 3 )

      if( sacarDatosDuplicados12.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados12) 
      }

      // MIERCOLES
      let gruposMiercole3 = gruposOficiales.filter( el => el.miercoles == 1 )
      let sacarDatosDuplicados13 = this.funcionValidar( 'mi3_n', 'mi3', gruposMiercole3, 'Miércoles', 3 )

      if( sacarDatosDuplicados13.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados13) 
      }

      // JUEVES
      let gruposJueves3 = gruposOficiales.filter( el => el.jueves == 1 )
      let sacarDatosDuplicados14 = this.funcionValidar( 'ju3_n', 'ju3', gruposJueves3, 'Jueves', 3 )

      if( sacarDatosDuplicados14.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados14) 
      }

      // VIERNES
      let gruposViernes3 = gruposOficiales.filter( el => el.viernes == 1 )
      let sacarDatosDuplicados15 = this.funcionValidar( 'vi3_n', 'vi3', gruposViernes3, 'Viernes', 3 )

      if( sacarDatosDuplicados15.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados15) 
      }

      /*****************************************************/

      // LUNES
      let gruposLunes4 = gruposOficiales.filter( el => el.lunes == 1 )
      let sacarDatosDuplicados16 = this.funcionValidar( 'lu3_n', 'lu3', gruposLunes4, 'Lunes', 3 )

      if( sacarDatosDuplicados16.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados16) 
      }

      // MARTES
      let gruposMartes4 = gruposOficiales.filter( el => el.martes == 1 )
      let sacarDatosDuplicados17 = this.funcionValidar( 'ma4_n', 'ma4', gruposMartes4, 'Martes', 4 )

      if( sacarDatosDuplicados17.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados17) 
      }

      // MIERCOLES
      let gruposMiercole4 = gruposOficiales.filter( el => el.miercoles == 1 )
      let sacarDatosDuplicados18 = this.funcionValidar( 'mi4_n', 'mi4', gruposMiercole4, 'Miércoles', 4 )

      if( sacarDatosDuplicados18.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados18) 
      }

      // JUEVES
      let gruposJueves4 = gruposOficiales.filter( el => el.jueves == 1 )
      let sacarDatosDuplicados19 = this.funcionValidar( 'ju4_n', 'ju4', gruposJueves4, 'Jueves', 4 )

      if( sacarDatosDuplicados19.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados19) 
      }

      // VIERNES
      let gruposViernes4 = gruposOficiales.filter( el => el.viernes == 1 )
      let sacarDatosDuplicados20 = this.funcionValidar( 'vi4_n', 'vi4', gruposViernes4, 'Viernes', 4 )

      if( sacarDatosDuplicados20.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados20) 
      }


      /*****************************************************/

      // SABATINO
      let gruposSabado1 = gruposOficiales.filter( el => el.sabado == 1 )
      let sacarDatosDuplicados21 = this.funcionValidarSabDom( gruposSabado1, 'Sabado', 1 )

      if( sacarDatosDuplicados21.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados21) 
      }

      // DOMINGO
      let gruposDomingo1 = gruposOficiales.filter( el => el.domingo == 1 )
      let sacarDatosDuplicados22 = this.funcionValidarSabDom( gruposDomingo1, 'Domingo', 1 )

      if( sacarDatosDuplicados22.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados22) 
      }

      /*****************************************************/

      // SABATINO
      let gruposSabado2 = gruposOficiales.filter( el => el.sabado == 1 )
      let sacarDatosDuplicados23 = this.funcionValidarSabDom( gruposSabado2, 'Sabado', 2 )

      if( sacarDatosDuplicados23.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados23) 
      }

      // DOMINGO
      let gruposDomingo2 = gruposOficiales.filter( el => el.domingo == 1 )
      let sacarDatosDuplicados24 = this.funcionValidarSabDom( gruposDomingo2, 'Domingo', 2 )

      if( sacarDatosDuplicados24.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados24) 
      }

      /*****************************************************/

      // SABATINO
      let gruposSabado3 = gruposOficiales.filter( el => el.sabado == 1 )
      let sacarDatosDuplicados25 = this.funcionValidarSabDom( gruposSabado3, 'Sabado', 3 )

      if( sacarDatosDuplicados25.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados25) 
      }

      // DOMINGO
      let gruposDomingo3 = gruposOficiales.filter( el => el.domingo == 1 )
      let sacarDatosDuplicados26 = this.funcionValidarSabDom( gruposDomingo3, 'Domingo', 3 )

      if( sacarDatosDuplicados26.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados26) 
      }

      /*****************************************************/

      // SABATINO
      let gruposSabado4 = gruposOficiales.filter( el => el.sabado == 1 )
      let sacarDatosDuplicados27 = this.funcionValidarSabDom( gruposSabado4, 'Sabado', 4 )

      if( sacarDatosDuplicados27.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados27) 
      }

      // DOMINGO
      let gruposDomingo4 = gruposOficiales.filter( el => el.domingo == 1 )
      let sacarDatosDuplicados28 = this.funcionValidarSabDom( gruposDomingo4, 'Domingo', 4 )

      if( sacarDatosDuplicados28.length ){ 
        this.datosDuplicados = this.datosDuplicados.concat(sacarDatosDuplicados28) 
      }


      this.dialogDuplicado = true
    },

    funcionValidar( rol, rol2, grupos, dia, bloque ){

      let datosDuplicados = []

      // Vamos a sacar los teachers de cada bloque
      let tlu1 = grupos.filter( el => { return el[rol] }).map((registro) => { return { id_teacher: registro[rol2], teacher: registro[rol]  }})

      // Quitamos los duplicados de los maestros
      let arregloSinDuplicados = Array.from(new Set(tlu1.map(JSON.stringify))).map(JSON.parse);


      // sacar los grupos de cada teacher
      for( const i in arregloSinDuplicados ){

        const { id_teacher, teacher } =  arregloSinDuplicados[i]

        let gruposTeacher = grupos.filter( el => el[rol2] == id_teacher )

        // Si solo tiene un grupo... no es necesario revisarlo
        if( gruposTeacher.length > 1 ){
          
          // Ordena los grupos
          gruposTeacher.sort((a, b) => a.hora_inicio.localeCompare(b.hora_inicio))

          for (let j = 0; j < gruposTeacher.length - 1; j++) {
            const grupoActual = gruposTeacher[j];
            const grupoSiguiente = gruposTeacher[j + 1];

            // Compara las horas de inicio y finalización
            if (grupoSiguiente.hora_inicio < grupoActual.hora_fin ) {

              let mensaje = ''

              if( grupoSiguiente.hora_inicio == grupoActual.hora_inicio ){
                mensaje = `El teacher ${teacher} está rolado a la misma hora en: ${grupoActual.plantel} a las ${grupoActual.hora_inicio} y en ${grupoSiguiente.plantel} a las ${grupoSiguiente.hora_inicio}`
              }else{
                mensaje = `Log rupos del teacher ${teacher} se empalman en la misma hora: ${grupoActual.plantel} de ${grupoActual.hora_inicio} a ${grupoActual.hora_fin} y en ${grupoSiguiente.plantel} de ${grupoSiguiente.hora_inicio} A ${grupoSiguiente.hora_fin}`
              }

              datosDuplicados.push({
                teacher,
                describe: mensaje,
                dia,
                bloque
              })
              // Si las horas se empalman, devuelve false
            }
          }
        }


      }


      return datosDuplicados
    },


    funcionValidarSabDom( grupos, dia, bloque ){

      let datosDuplicados = []

      let tlu1 = grupos.filter( el => { return el[`lu${bloque}_n`] })
                  .map((registro) => { return { id_teacher: registro[`lu${bloque}`], teacher: registro[`lu${bloque}_n`]  }})
      let tlu2 = grupos.filter( el => { return el[`ma${bloque}_n`] })
                  .map((registro) => { return { id_teacher: registro[`ma${bloque}`], teacher: registro[`ma${bloque}_n`]  }})
      let tlu3 = grupos.filter( el => { return el[`mi${bloque}_n`] })
                  .map((registro) => { return { id_teacher: registro[`mi${bloque}`], teacher: registro[`mi${bloque}_n`]  }})
      let tlu4 = grupos.filter( el => { return el[`ju$${bloque}_n`] })
                  .map((registro) => { return { id_teacher: registro[`ju$${bloque}`], teacher: registro[`ju$${bloque}_n`]  }})
      let tlu5 = grupos.filter( el => { return el[`vi${bloque}_n`] })
                  .map((registro) => { return { id_teacher: registro[`vi${bloque}`], teacher: registro[`vi${bloque}_n`]  }})

      let concatenado = tlu1.concat( tlu2 ).concat( tlu3 ).concat( tlu4 ).concat( tlu5 )

      // Quitamos los duplicados de los maestros
      let arregloSinDuplicados = Array.from(new Set(concatenado.map(JSON.stringify))).map(JSON.parse);

      // sacar los grupos de cada teacher
      for( const i in arregloSinDuplicados ){

        const { id_teacher, teacher } =  arregloSinDuplicados[i]

        const diasSemana = ['lu', 'ma', 'mi', 'ju', 'vi'];

        let gruposTeacher = grupos.filter(el => {
          for (const dia of diasSemana) {
            if (el[`${dia}${bloque}`] == id_teacher) {
              return true;
            }
          }
          return false;
        });

        // Si solo tiene un grupo... no es necesario revisarlo
        if( gruposTeacher.length > 1 ){
          
          // Ordena los grupos
          gruposTeacher.sort((a, b) => a.hora_inicio.localeCompare(b.hora_inicio))

          for (let j = 0; j < gruposTeacher.length - 1; j++) {
            const grupoActual = gruposTeacher[j];
            const grupoSiguiente = gruposTeacher[j + 1];

            // Compara las horas de inicio y finalización
            if (grupoSiguiente.hora_inicio < grupoActual.hora_fin && !grupoActual.grupo.match('TEENS') &&  ( grupoActual[`lu${bloque}`] == grupoSiguiente[`lu${bloque}`] || grupoActual[`ju${bloque}`] == grupoSiguiente[`ju${bloque}`] ) ) {

              let mensaje = ''

              if( grupoSiguiente.hora_inicio == grupoActual.hora_inicio ){
                mensaje = `El teacher ${teacher} está rolado a la misma hora en: ${grupoActual.plantel} a las ${grupoActual.hora_inicio} y en ${grupoSiguiente.plantel} a las ${grupoSiguiente.hora_inicio}`
              }else{
                mensaje = `Log rupos del teacher ${teacher} se empalman en la misma hora: ${grupoActual.plantel} de ${grupoActual.hora_inicio} a ${grupoActual.hora_fin} y en ${grupoSiguiente.plantel} de ${grupoSiguiente.hora_inicio} A ${grupoSiguiente.hora_fin}`
              }

              datosDuplicados.push({
                teacher,
                describe: mensaje,
                dia,
                bloque
              })
              // Si las horas se empalman, devuelve false
            }
          }
        }


      }


      return datosDuplicados
    }

  }
}