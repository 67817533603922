<template>
	<v-container class="mt-4" fluid>
	  <v-row>
	  	<v-col cols="12" lg="4">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    Gráfica FAST
	  		    <v-spacer></v-spacer>
	  		  </v-card-title>
	  		  <v-card-text class="align-center" align="center">
	  		  	<label>Sí quieren presencial</label>
					 	<v-progress-linear
				      :value="presencialFast"
				      color="green"
				      height="25"
				      rounded
				    >
				      <template>
				        <strong>{{ presencialFast }}% = {{ fast.filter( el=> { return el.presencial == 1 }).length }} alumnos</strong>
				      </template>
				    </v-progress-linear>

				    <label>NO quieren presencial</label>
					 	<v-progress-linear
				      :value="noPresencialFast"
				      color="red"
				      height="25"
				      rounded
				      dark
				    >
				      <template>
				        <strong>{{ noPresencialFast }}% = {{ fast.length - fast.filter( el=> { return el.presencial == 1 }).length }} alumnos</strong>
				      </template>
				    </v-progress-linear>
	  		  </v-card-text>

				  <div class="ml-4 mb-2"><strong>Total de alumno encuestados:</strong> {{ fast.length }} </div>
				  <div class="ml-4 mb-2"><strong>Deben de contestar:</strong> {{ alumnosFast }} </div>
				  <div class="ml-4 mb-4"><strong>Porcentaje:</strong> {{ ((fast.length / alumnosFast) *100).toFixed( 0 ) }} % </div>

	  		</v-card>
	  	</v-col>

	  	<v-col cols="12" lg="8">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    Encuesta en presencial FAST
	  		    <v-spacer></v-spacer>
	  		    <v-btn color="success" small @click="exportarFast()"><v-icon>mdi-microsoft-excel-box</v-icon></v-btn>
	  		  </v-card-title>
	  		  <v-card-text>
					  <v-data-table
					    :headers="headers"
					    :items="fast"
					    :items-per-page="10"
					    dense
					    class="elevation-0"
					  >
					  	<template v-slot:item.radica_nuevoleon="{ item }">
					      <v-chip v-if="item.radica_nuevoleon == 1" color="green" dark small> Sí    </v-chip>
					      <v-chip v-else color="red"   dark small> No </v-chip>
					    </template>

					    <template v-slot:item.presencial="{ item }">
					      <v-chip v-if="item.presencial == 1" color="green" dark small> Sí    </v-chip>
					      <v-chip v-else color="red"   dark small> No </v-chip>
					    </template>

					  	<template v-slot:item.dispositivo="{ item }">
					      <v-icon small color="grey"  class="mr-2" v-if="item.dispositivo == 1"> mdi-web     </v-icon>
					      <v-icon small color="green" class="mr-2" v-if="item.dispositivo == 2"> mdi-android </v-icon>
					      <v-icon small color="grey"  class="mr-2" v-if="item.dispositivo == 3"> mdi-apple-ios </v-icon>
					    </template>
					  </v-data-table>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>


	  	<v-col cols="12" lg="4">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    Gráfica INBI
	  		    <v-spacer></v-spacer>
	  		  </v-card-title>
	  		  <v-card-text class="align-center" align="center">
	  		  	<label>Sí quieren presencial</label>
					 	<v-progress-linear
				      :value="presencialInbi"
				      color="green"
				      height="25"
				      rounded
				    >
				      <template>
				        <strong>{{ presencialInbi }}% = {{ inbi.filter( el=> { return el.presencial == 1 }).length }} alumnos</strong>
				      </template>
				    </v-progress-linear>

				    <label>NO quieren presencial</label>
					 	<v-progress-linear
				      :value="noPresencialInbi"
				      color="red"
				      height="25"
				      rounded
				      dark
				    >
				      <template>
				        <strong>{{ noPresencialInbi }}% = {{ inbi.length - inbi.filter( el=> { return el.presencial == 1 }).length }} alumnos</strong>
				      </template>
				    </v-progress-linear>
	  		  </v-card-text>
	  		  <div class="ml-4 mb-2"><strong>Total de alumno encuestados:</strong> {{ inbi.length }} </div>
				  <div class="ml-4 mb-2"><strong>Deben de contestar:</strong> {{ alumnosInbi }} </div>
				  <div class="ml-4 mb-4"><strong>Porcentaje:</strong> {{ ((inbi.length / alumnosInbi) *100).toFixed( 0 ) }} % </div>
	  		</v-card>
	  	</v-col>

	  	<v-col cols="12" lg="8">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    Encuesta en presencial INBI
	  		    <v-spacer></v-spacer>
	  		    <v-btn color="success" small @click="exportarInbi()"><v-icon>mdi-microsoft-excel-box</v-icon></v-btn>
	  		  </v-card-title>
	  		  <v-card-text>
					  <v-data-table
					    :headers="headers"
					    :items="inbi"
					    :items-per-page="10"
					    dense
					    class="elevation-0"
					  >
					  	<template v-slot:item.radica_nuevoleon="{ item }">
					      <v-chip v-if="item.radica_nuevoleon == 1" color="green" dark small> Sí    </v-chip>
					      <v-chip v-else color="red"   dark small> No </v-chip>
					    </template>

					    <template v-slot:item.presencial="{ item }">
					      <v-chip v-if="item.presencial == 1" color="green" dark small> Sí    </v-chip>
					      <v-chip v-else color="red"   dark small> No </v-chip>
					    </template>

					  	<template v-slot:item.dispositivo="{ item }">
					      <v-icon small color="grey"  class="mr-2" v-if="item.dispositivo == 1"> mdi-web     </v-icon>
					      <v-icon small color="green" class="mr-2" v-if="item.dispositivo == 2"> mdi-android </v-icon>
					      <v-icon small color="grey"  class="mr-2" v-if="item.dispositivo == 3"> mdi-apple-ios </v-icon>
					    </template>
					  </v-data-table>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>
	</v-container>
</template>
<script>
import axios from 'axios';
	import XLSX from 'xlsx'
  export default {
    data () {
      return {
        headers: [
          { text: 'Alumno'      , value: 'alumno' },
          { text: 'Nuevo leon'  , value: 'radica_nuevoleon' },
          { text: 'Presencial'  , value: 'presencial' },
          { text: 'Plantel'     , value: 'plantel' },
          { text: 'Frecuencia'  , value: 'frecuencia' },
          { text: 'Inicio'      , value: 'inicio' },
          { text: 'Final'       , value: 'final' },
          { text: 'Dispositivo' , value: 'dispositivo' },
        ],
        fast: [],
        alumnosFast:0,
        presencialFast:0,
				noPresencialFast:0,

        inbi:[],
        alumnosInbi:0,
        presencialInbi:0,
				noPresencialInbi:0,
      }
    },

    created ( ){
    	this.initialize( )
    },	

    methods:{
    	initialize(){
    		this.$http.get('encuestas.presencial').then( response => {

    			// Calculando los totales de fast
    			this.fast = response.data.fast
    			const presencial = this.fast.filter( el=> { return el.presencial == 1 }).length
    			const totalEncuesta = this.fast.length
    			this.presencialFast   = (( presencial / totalEncuesta ) * 100 ).toFixed(0)
    			this.noPresencialFast = (( 1 - ( presencial / totalEncuesta ) ) * 100 ).toFixed(0)

    			// Calculando los totales de inbi
    			this.inbi = response.data.inbi
    			const presencial2 = this.inbi.filter( el=> { return el.presencial == 1 }).length
    			const totalEncuesta2 = this.inbi.length
    			this.presencialInbi   = (( presencial2 / totalEncuesta2 ) * 100 ).toFixed(0)
    			this.noPresencialInbi = (( 1 - ( presencial2 / totalEncuesta2 ) ) * 100 ).toFixed(0)

    			// 
    			this.alumnosFast = response.data.alumnosFast
					this.alumnosInbi = response.data.alumnosInbi

    		}).catch( error => {
    			console.log( error )
    		})
    	},
    	s2ab(s) {
	      var buf = new ArrayBuffer(s.length);
	      var view = new Uint8Array(buf);
	      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
	      return buf;
	    },

	    exportarFast(){
	      let data = XLSX.utils.json_to_sheet(this.fast)
	      const workbook = XLSX.utils.book_new()
	      const filename = 'presencial_fast'
	      XLSX.utils.book_append_sheet(workbook, data, filename)

	      var wbout = XLSX.write(workbook, {
	        bookType: 'xls',
	        bookSST: false,
	        type: 'binary'
	      });

	      const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
	      
	      let formData = new FormData();
	      // //se crea el objeto y se le agrega como un apendice el archivo 
	      formData.append('file',file);
	      // /*getDatosRiesgo the form data*/

	      this.$http.post('pdfs',formData).then(response=> {
	        window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
	      }).catch(error=> {
	        console.log(error);
	      });
	    },

	    exportarInbi(){
	      let data = XLSX.utils.json_to_sheet(this.inbi)
	      const workbook = XLSX.utils.book_new()
	      const filename = 'presencial_inbi'
	      XLSX.utils.book_append_sheet(workbook, data, filename)

	      var wbout = XLSX.write(workbook, {
	        bookType: 'xls',
	        bookSST: false,
	        type: 'binary'
	      });

	      const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
	      
	      let formData = new FormData();
	      // //se crea el objeto y se le agrega como un apendice el archivo 
	      formData.append('file',file);
	      // /*getDatosRiesgo the form data*/

	      this.$http.post('pdfs',formData).then(response=> {
	        window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
	      }).catch(error=> {
	        console.log(error);
	      });
	    },
    }
  }
</script>