<template>
  <v-container class="mt-4 ml-2">
    <v-row>
      <v-col cols="12">
	      <v-card class="shadowCard">
	      	<v-card-title primary-title>
		        <v-toolbar-title>Grupo Exci</v-toolbar-title>
		        <v-divider
		          class="mx-4"
		          inset
		          vertical
		        ></v-divider>
		        <v-spacer></v-spacer>
		        <v-btn color="primary" @click="getGruposAlumnos()" small class="mr-2">Consultar</v-btn>
		        <v-btn color="secondary" @click="getGruposAlumnosERP()" small>Cargar del ERP</v-btn>
	      	</v-card-title>
	        <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
	        	<!-- Menu superior de tabs -->

	        	<v-row>
	            <v-col cols="12" lg="12">
	              <v-card class="elevation-0">
                  <v-row justify="end">
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        label="Buscar"
                        v-model="search1"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>

                	<v-data-table
                    :search="search1"
								    dense
								    :headers="headers"
								    :items="gruposAlumnos"
								    item-key="id"
								    class="elevation-0"
								  >
								  	<template v-slot:item.actions="{ item }">
			                <v-chip color="red" class="mr-2" dark small   @click="sendMatricula( item )">Matri.</v-chip>
			                <v-chip color="blue" class="mr-2" dark small  @click="senZoom( item )">Zoom</v-chip>
			              </template>

			              <template v-slot:item.correo="props">
							        <v-edit-dialog
							          :return-value.sync="props.item.correo"
							          @save="save( props.item )"
							          @cancel="cancel"
							          @open="open"
							          @close="close"
							          large
							          save-text="Guardar"
							          cancel-text="Cancelar"
							        >
							          {{ props.item.correo }}
							          <template v-slot:input>
							            <v-text-field
							              v-model="props.item.correo"
							              label="Editar correo"
							              single-line
							              counter
							            ></v-text-field>
							          </template>
							        </v-edit-dialog>
							      </template>


									</v-data-table>
	              </v-card>
	            </v-col>
	        	</v-row>
	        </v-card-text>
	      </v-card>		
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'

  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search1:'',

      headers: [
        { text: 'id'                  , value: 'idexci_registro' },
        { text: 'Nombre'              , value: 'nombre' },
        { text: 'Correo'              , value: 'correo' },
        { text: 'Pago'                , value: 'pago_realizado' },
        { text: 'matricula'           , value: 'matricula' },
        { text: 'Enviar correo de: '  , value: 'actions' },
      ],

      gruposAlumnos:[],
			cargar:true,
			horarios:null,
			cicloInf: 		null,
      ciclos:  			[],

      snack: false,
      snackColor: '',
      snackText: '',

    }),

    watch: {
      cicloInf () {
        if(this.cicloInf){
          this.getGruposAlumnos()
        }
      },
    },

   
    created () {
      this.initialize()
    },

    methods: {
    	initialize () {
      	this.cargar = true
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.ciclos.all.exci').then(response=>{
          this.ciclos = response.data
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getGruposAlumnos () {
      	if(this.cicloInf){
	      	// vaciamos el horario
	      	this.gruposAlumnos = []
	      	// Activamos el loader
	    		this.cargar = true
	    		// Hacemos la peticios
	    		this.$http.post('lms.catalogos.gruposalumnos.all.exci',this.cicloInf).then(response=>{
	    			// Guardamos la respuesta
	          this.gruposAlumnos = response.data
	          // Quitamos el loader
	          this.cargar = false
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
      	}else{
      		this.validarErrorDirecto('Favor de seleccionar un ciclo')
      	}
      },

      getGruposAlumnosERP () {
      	if(this.cicloInf){
	      	// Activamos el loader
	    		this.cargar = true
	    		// Hacemos la peticion
	    		this.$http.post('lms.catalogos.gruposalumnos.cron.exci', this.cicloInf ).then(response=>{
	    			// Mandamos a llamar a la funcion principal para que cargue los gruposAlumnos
	          this.getGruposAlumnos()
	          // Quitamos el loader
	          this.cargar = false
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
      	}else{
      		this.validarErrorDirecto('Favor de seleccionar un ciclo')
      	}

      },

      save ( value ) {
        return this.$http.post('exci.update.correo', value ).then( response=> {
          this.snack = true
	        this.snackColor = 'success'
	        this.snackText = 'Datos guardados'
        }).catch( error => {
          this.snack = true
	        this.snackColor = 'error'
	        this.snackText = 'Datos no guardados'
        }).finally( ( ) => {  })
      },

      sendMatricula ( value ){
      	this.cargar = true
      	return this.$http.post('exci.enviar.matricula', value ).then( response=> {
      		this.cargar = false
          this.snack = true
	        this.snackColor = 'success'
	        this.snackText = 'Matricula enviada'
        }).catch( error => {
        	this.cargar = false
          this.snack = true
	        this.snackColor = 'error'
	        this.snackText = 'Datos no guardados'
        }).finally( ( ) => {  })
      },

      
      senZoom ( value ){
      	this.cargar = true
      	return this.$http.post('exci.enviar.zoom', value ).then( response=> {
      		this.cargar = false
          this.snack = true
	        this.snackColor = 'success'
	        this.snackText = 'Corredo enviado'
        }).catch( error => {
        	this.cargar = false
          this.snack = true
	        this.snackColor = 'error'
	        this.snackText = 'Datos no guardados'
        }).finally( ( ) => {  })
      },

      cancel () {
        this.snack = true
        this.snackColor = 'error'
        this.snackText = 'Canceled'
      },
      open () {
        this.snack = true
        this.snackColor = 'info'
        this.snackText = 'Dialog opened'
      },
      close () {
        console.log('Dialog closed')
      },

    },

    computed:{
    	...mapGetters('login',['getdatosUsuario'])
    }
  }
</script>

<style>
	.v-snack__content{
		padding: 0px !important;
	}
</style>