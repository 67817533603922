<template>
  <v-container class="mt-4 ml-2">
    <v-row>
      <v-col cols="12">
	      <v-card>
	      	<v-card-title primary-title>
		        <v-toolbar-title>Grupos</v-toolbar-title>
		        <v-divider
		          class="mx-4"
		          inset
		          vertical
		        ></v-divider>
		        <v-spacer></v-spacer>
		        <v-btn color="primary" @click="getGrupos()" small class="mr-2">Consultar</v-btn>
		        <v-btn color="secondary" @click="getGruposERP()" small>Cargar del ERP</v-btn>
	      	</v-card-title>
	        <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
	        	<!-- Menu superior de tabs -->

		        <v-tabs
		        	v-if="grupos"
			        dense
			        class="mt-4"
			        v-model="tab"
			        background-color="white"
			      >
			        <v-tabs-slider color="primary"></v-tabs-slider>


			        <v-tab
			          v-for="(item,i) in items"
			          :key="i"
			        >
			          <v-icon v-text="item.icon" left></v-icon>{{ item.name }}
			        </v-tab>


			        <!-- Apartado de las secciones de los tabs -->
			        <v-tabs-items v-model="tab">
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">
			                <v-card-title primary-title class="pt-0 ">
                        <v-text-field
                          v-model="searchFast"
                          label="Buscar"
                          append-icon="mdi-magnify"
                          filled
                          dense
                          hide-details
                        ></v-text-field>
			                </v-card-title>

			                <v-card-text>	
			                	<v-data-table
											    dense
											    :headers="headers"
											    :items="grupos.gruposFast"
											    item-key="id"
											    class="elevation-1"
                          :search="searchFast"
											  >
                          <template v-slot:item.optimizado="{ item }">
                            <v-switch v-model="item.optimizado" @change="editar( item )"></v-switch>
                          </template>

                          <template v-slot:item.deleted="{ item }">
                            <v-switch v-model="item.deleted" @change="editar( item )"></v-switch>
                          </template>     

                          <template v-slot:item.online="{ item }">
                            <v-switch v-model="item.online" @change="editar( item )"></v-switch>
                          </template>     
                        </v-data-table>
			                </v-card-text>	
			              </v-card>
			            </v-col>
			          </v-tab-item>
			        

			          <!-- horarios de inbi -->
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">
			                <v-card-title primary-title class="pt-0 ">
                        <v-text-field
                          v-model="searchInbi"
                          label="Buscar"
                          append-icon="mdi-magnify"
                          filled
                          dense
                          hide-details
                        ></v-text-field>
                      </v-card-title>

			                <v-card-text >
			                  <v-data-table
											    dense
											    :headers="headers"
											    :items="grupos.gruposInbi"
											    item-key="id"
											    class="elevation-1"
                          :search="searchInbi"
											  >
                          <template v-slot:item.optimizado="{ item }">
                            <v-switch v-model="item.optimizado" @change="editar( item )"></v-switch>
                          </template>

                          <template v-slot:item.deleted="{ item }">
                            <v-switch v-model="item.deleted" @change="editar( item )"></v-switch>
                          </template> 

                          <template v-slot:item.online="{ item }">
                            <v-switch v-model="item.online" @change="editar( item )"></v-switch>
                          </template>    
                        </v-data-table>
			                </v-card-text>	
			              </v-card>
			            </v-col>
			          </v-tab-item>


			          <!-- Resultados -->
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">

			              	
			              </v-card>
			            </v-col>
			          </v-tab-item>

			        </v-tabs-items>
			      </v-tabs>
	        </v-card-text>
	      </v-card>		
      </v-col>
    </v-row>

    
  	<!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="500"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Espera porfavor
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card
        color="green"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Datos grabados
        </v-card-title>
        <div class="pl-6 pb-6">
          <br/>
          NOTA: {{successMensaje}}
        </div>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogSuccess = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
	  
    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card
        color="error"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Error al recuperar los datos
        </v-card-title>
        <v-card-text>
          <br/>
          NOTA: {{error}}
        </v-card-text>
        <v-btn 
        	color="transparent elevation-0"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogError = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
  import XLSX from 'xlsx'

  export default {
    data: () => ({
    	row:null,
    	tab: {name:'Grupos FAST'   , icon:'mdi-file' }, 
      items: [
        {name:'Grupos FAST'    , icon:'mdi-file' }, 
        {name:'Grupos INBI'    , icon:'mdi-file' }, 
      ],

      headers: [
        { text: 'id'          , value: 'id' },
        { text: 'Nombre'      , value: 'nombre' },
        { text: 'Ciclo'		    , value: 'ciclo' },
        { text: 'Curso'       , value: 'curso' },
        { text: 'Nivel'       , value: 'id_nivel' },
        { text: 'Hora inicio' , value: 'hora_inicio' },
        { text: 'Hora fin'  	, value: 'hora_fin' },
        { text: 'iderp'       , value: 'iderp' },
        { text: 'Optimizado'  , value: 'optimizado' },
        { text: 'Eliminado'   , value: 'deleted' },
        { text: 'Online'      , value: 'online' },
      ],

      grupos:[],
      dialogError: false,
			dialogSuccess:false,
			error:'',
			successMensaje:'',
			overlay:true,
			horarios:null,
			cicloInf: 		null,
      ciclos:  			[],

      searchFast:'',
      searchInbi:''
    }),

    watch: {
      cicloInf () {
        if(this.cicloInf){
          this.getGrupos()
        }
      },
    },

   
    created () {
      this.initialize()
    },

    methods: {
    	initialize () {
      	this.overlay = true
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError('Error al recuperar los ciclos')
        })
      },

      getGrupos () {
      	if(this.cicloInf){
	      	// vaciamos el horario
	      	this.grupos = null
	      	// Activamos el loader
	    		this.overlay = true
	    		// Hacemos la peticios
	    		this.$http.get(`lms.catalogos.grupos.all/${ this.cicloInf.id_ciclo }/${ this.cicloInf.id_ciclo_relacionado }`).then(response=>{
	    			// Guardamos la respuesta
	          this.grupos = response.data
	          // Quitamos el loader
	          this.overlay = false
	        }).catch(error=>{
	        	this.overlay = false
	        	this.openDialogError(error.body.message)
	        })
      	}else{
      		this.openDialogError('Favor de seleccionar un ciclo')
      	}
      },

      getGruposERP () {
      	// Activamos el loader
    		this.overlay = true
    		// Hacemos la peticion
    		this.$http.get(`lms.catalogos.grupos.cron/${ this.cicloInf.id_ciclo }/${ this.cicloInf.id_ciclo_relacionado }`).then(response=>{
    			// Mandamos a llamar a la funcion principal para que cargue los grupos
          this.getGrupos()
          // Quitamos el loader
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError(error.body.message)
        })
      },

      openDialogError (mensaje) {
      	this.error       = mensaje
      	this.dialogError = true
      },

      openDialogSuccess ( mensaje ){
      	this.successMensaje = mensaje
      	this.dialogSuccess  = true
      },

      editar ( item ){
        // Actualización del grupo, editado y eliminado
        this.$http.put(`lms.catalogos.grupos.upudate/${ item.id }`, item).then(response=>{
          // Guardamos la respuesta
          // this.getGrupos()
          // Quitamos el loader
          this.overlay = false
        }).catch(error=>{
          this.overlay = false
          this.openDialogError(error.body.message)
        })      
      }

    },

    computed:{
    	...mapGetters('login',['getdatosUsuario'])
    }
  }
</script>

<style>
	.v-snack__content{
		padding: 0px !important;
	}
</style>