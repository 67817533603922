import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';

export default {

	data: () =>({
   
	}),

	methods: {
		abrirDialogReasignacion(){
      this.nuevaSucursal = this.sucursales[0]
      this.vendedora = null
      this.vendedoras_plantel = []
      this.paramReasignar.estatus = true
    },

    reasignar( nuevaSucursal ){
      // Reasignación del prospecto
      if( nuevaSucursal ){
        this.overlay = true
        const payload = {
          idprospectos:     this.prospecto.idprospectos,
          reasignado:       1,
          usuario_asignado: nuevaSucursal.iderp,
          idusuarioerp:     this.getdatosUsuario.iderp
        }

        this.$http.post('prospectos.reasignar', payload).then((response) => {
          this.dialogSuccess = true
          this.vendedora = { iderp:      parseInt( nuevaSucursal.iderp ), nombre:  nuevaSucursal.nombre }
          this.paramReasignar.estatus = false
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        }).finally(()=> { this.overlay = false })

      }else{
        // Error
        this.errorBack = false
        this.error = 'Necesitas asignar una sucursal'
        this.overlay = false
        this.dialogError = true
      }
    },
  }
}