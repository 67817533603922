<template>
	<!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="dialogIngresosCorte.estatus"
    persistent
    max-width="1200"
  >
		<v-card>
      <v-card-title primary-title>
        Ingresos por ciclo
        <v-spacer></v-spacer>

        <v-btn 
        	small 
        	dark 
        	color="green" 
        	class="mr-2" 
        	@click="exportExcel(filterIngresos,'ingresos_por_ciclos')"
        	tile
        >
          <v-icon small>mdi-microsoft-excel</v-icon>
        </v-btn>

        <v-btn 
        	small 
        	dark 
        	color="primary" 
        	@click="getIngresos()"
        	tile
        >
        	<v-icon left small>mdi-refresh</v-icon>
          Actualizar
        </v-btn>

      </v-card-title>

      <!-- Datos principales del usuario -->
      <v-card-text>
        <v-row>
          <!-- Seleccionar el ciclo -->
		  		<v-col cols="12" sm="6" md="4" lg="3">
		  			<v-autocomplete
		  			  label="Ciclo"
		  			  id="id"
		  			  filled
		  			  dense
		  			  hide-details
		  			  v-model="ciclo"
		  			  :items="ciclos"
		  			  item-text="ciclo"
		  			  item-value="id_ciclo"
		  			  clearable
		  			  single-line
		  			></v-autocomplete>
		  		</v-col>

		  		<v-col cols="12" sm="6" md="4" lg="3">
		  			<v-autocomplete
		  			  label="Plantel"
		  			  id="id"
		  			  filled
		  			  dense
		  			  single-line
		  			  hide-details
		  			  v-model="plantel"
		  			  :items="planteles"
		  			  item-text="plantel"
		  			  item-value="plantel"
		  			  clearable
		  			></v-autocomplete>
		  		</v-col>

          <v-col cols="12" md="6" lg="4">
            <v-text-field
              name="name"
              label="Búscar"
              filled
              dense
              v-model="search"
		  			  single-line
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
        	<!-- DATOS DEL ERP VIEJO -->
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="filterIngresos"
              class="elevation-0"
              dense
              mobile-breakpoint="200"
              :search="search"
            >
            </v-data-table>
          </v-col>
        </v-row>

      </v-card-text>


      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
        	@click="dialogIngresosCorte.estatus = false, movimientosERPViejo = []" 
        	small 
        	color="green" 
        	dark
        	tile
        >
        	<v-icon left small>mdi-close</v-icon>
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
	</v-dialog>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta              from '@/components/alertas/Alerta.vue'
  import carga               from '@/components/alertas/carga.vue';

  import validarErrores      from '@/mixins/validarErrores'
  import funcionesExcel      from '@/mixins/funcionesExcel'

  import mixinLeads          from '@/mixins/mixinLeads'

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, mixinLeads, funcionesExcel ],

  	props:[
  		'dialogIngresosCorte',
  	],

    data: () => ({
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,


      headers: [
	      { text: "Plantel"    , value: "plantel"      },
	      { text: "Alumno"     , value: "alumno"       },
	      { text: "Grupo"      , value: "grupo"        },
	      { text: "Monto"      , value: "monto_pagado" },
	      { text: "Forma pago" , value: "forma_pago"   },
	      { text: "Fecha pago" , value: "fecha_pago"   },
	    ],

	    itemsPerPage: 10,
	    search:'',

			ciclos:[],
			ciclo: null,

			ingresos:[],
			planteles:[],
			plantel: null
      
    }),

    watch:{
      ciclo( value ){
	      if(value){ this.getIngresos() }
	    },

    },

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),

    	filterIngresos(){

    		if( this.plantel ){
    			return this.ingresos.filter( el => { return el.plantel == this.plantel })
    		}else{
    			return this.ingresos
    		}
    	}

    },

    async created () {
    	await this.getCiclos()
    	await this.getPlanteles()
    },


    methods: {

   		getCiclos () {
      	this.cargar = true
        this.ciclos = []
        return this.$http.get('ciclos.activos.erp').then(response=>{
        	this.ciclos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      getIngresos () {

      	if( !this.ciclo ){ return this.validarErrorDirecto('Favor de seleccionar un ciclo') }

      	this.cargar = true
        
        return this.$http.get('ingresos.reporte.ciclo/' + this.ciclo).then(response=>{
        	this.ingresos    = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      
      },

    },
  };
</script>
<style scoped>

  #back{
    background-color: #EEEEEE;
  }

  #menuable__content__active::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  #menuable__content__active::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  #menuable__content__active::-webkit-scrollbar:vertical{
    width: 6px !important;
  }

  #menuable__content__active::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  #menuable__content__active::-webkit-scrollbar:horizontal{
    height: 6px !important;
  }

  #menuable__content__active::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

</style>