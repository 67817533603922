<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Niveles</span>
	  		    <v-spacer></v-spacer>

            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( niveles  , 'NIVELES_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

            
	  		  </v-card-title>

	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="6" lg="5">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="niveles"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            dense
							  >

							    <template v-slot:item.prioridad="{ item }">
							      <v-chip small dark color="green" v-if="item.prioridad==1">SI</v-chip>
							      <v-chip small dark color="blue" v-else>NO</v-chip>
							    </template>

							    <template v-slot:item.unidad_negocio="{ item }">
							      <v-chip small dark color="blue lighten-2" v-if="item.unidad_negocio==1">INBI</v-chip>
							      <v-chip small dark color="blue darken-2" v-else>FAST</v-chip>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							      	rounded
							      	small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip 
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>
							  

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="error" 
							    		x-small
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							      	rounded
							      	small
							      >
							        Actualizar
							      </v-btn>
							    </template>
							  </v-data-table>

							  </v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="editedItem.nombrenivel"
                  label="Nombre nivel"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="editedItem.nivel"
                  label="nivel"
                  type="number"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
              	<v-select
              	  :items="[{escuela:'INBI',id:1},{escuela:'FAST',id:2}]"
              	  v-model="escuela"
              	  label="Escuela"
              	  return-object
              	  item-value="id"
              	  item-text="escuela"
              	  outlined
              	  hide-details
              	  dense
              	></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
              	<v-checkbox label="Prioridad" v-model="editedItem.prioridad"></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

		<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

    	search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Nivel', align: 'start', value: 'nombrenivel', },
        { text: 'Valor'    , value: 'nivel' },
        { text: 'Prioridad', value: 'prioridad' },
        { text: 'Escuela'  , value: 'unidad_negocio' },
        { text: 'Actions'  , value: 'actions', sortable: false },
      ],
      niveles: [],
      editedIndex: -1,
      editedItem: {
      	idniveles:0,
        nombrenivel: '',
        nivel: 0,
        prioridad: 0,
      },
      defaultItem: {
      	idniveles:0,
        nombrenivel: '',
        nivel: 0,
        prioridad: 0,
      },
      escuela:null,
      fecha:null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar nivel' : 'Editar nivel'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
      	// Vaciamos todo
        this.niveles = []
        this.cargar = true
        // Consultamos
        this.$http.get('niveles.all').then(response=>{
        	// Llenamos
        	this.niveles = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.niveles.indexOf(item)
        this.editedItem = Object.assign({}, item)
        if(this.editedItem.unidad_negocio == 1){
        	this.escuela = {escuela:'INBI',id:1}
        }else{
        	this.escuela = {escuela:'FAST',id:2}
        }
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.niveles.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.niveles.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	this.editedItem.unidad_negocio = this.escuela.id
      	this.editedItem.fecha_registro = this.fecha
      	this.editedItem.fecha_actualizo = this.fecha
      	if(this.editedItem.prioridad){this.editedItem.prioridad == 1}else{this.editedItem.prioridad=0}
        if (this.editedIndex > -1) {
          this.$http.put('niveles.update.erpnuevo/'+this.editedItem.idniveles,this.editedItem).then(response=>{
	        	this.initialize()
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
        } else {
        	// Agregas
        	this.$http.post('niveles.add.erpnuevo',this.editedItem).then(response=>{
        		// Inicializas 
	        	this.initialize()
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
        }
        this.close()
      },
    },
  }
</script>