<template>
  <v-container class="mt-4 px-5" fluid>
    <v-row>
      <v-col cols="12" md="7" lg="6">

        <v-card class="shadowCard">

          <v-card-title primary-title>
            Agregar Ticket
            <v-spacer></v-spacer>
            <v-checkbox v-model="urgente" :label="`Urgente`"></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn 
              color="primary" 
              tile
              small
              :loading="cargar"
              :disabled="cargar" 
              @click="save()"
            >
              Agregar
            </v-btn>
          </v-card-title>

          <v-card-text>

            <!-- INFORMACION -->
            <v-row>
              <v-col cols="12">
                <v-alert
                  text
                  dense
                  color="teal"
                  icon="mdi-information"
                  border="left"
                >
                  Por favor, detalla tu problema para que se le pueda dar una solución más rápida, también, escribe que es lo que te gustaría obtener como respuesta.
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6">
                <v-select
                  :items="planteles"
                  v-model="plantel"
                  label="Selecciona un plantel"
                  item-value="id_plantel"
                  item-text="plantel"
                  filled
                  dense
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="12" lg="6">
                <v-select
                  :items="sucursales"
                  v-model="sucursal"
                  label="Selecciona una sucursal"
                  item-value="id_plantel"
                  item-text="plantel"
                  filled
                  dense
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  filled
                  v-model="motivo"
                  label="Detalle tu problema"
                  hide-details
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-file-input
                  small-chips
                  multiple
                  filled
                  v-model="files"
                  accept="image/png, image/jpeg, image/bmp, video/mp4, video/x-m4v, video/*"
                  placeholder="Selecciona tus imagenes"
                  prepend-icon="mdi-camera"
                  label="Imágenes"
                  @change="cargarFotos()"
                ></v-file-input>
              </v-col>


              <v-col cols="12" lg="6">
                <v-autocomplete
                  filled
                  dense
                  :items="areas"
                  v-model="area"
                  label="Selecciona departamento"
                  return-object
                  item-value="iddepartamento"
                  item-text="departamento"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" lg="6" v-if="puestos.length > 1">
                <v-autocomplete
                  filled
                  dense
                  :items="puestos"
                  v-model="puesto"
                  label="Selecciona un puesto"
                  return-object
                  item-value="idpuesto"
                  item-text="puesto"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" v-for="(img, i) in vistaPrevias" :key="i">
                <v-card class="py-4 shadowCard">
                  <v-img :src="img.url" contain aspect-ratio="2"></v-img>
                  <v-btn 
                    color="error" 
                    small 
                    @click="eliminarFoto(img.url)"
                    top
                    right
                    absolute
                    fab
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>


            <v-row v-if="show_folio">
              <v-col cols="12" >
                <h3>Tu numero de folio</h3>
                #{{ folio }}
                <h3>Mensaje enviado</h3>
                {{ motivo_aux }}
                <h3>Nos pondremos en contacto contigo muy pronto.</h3>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import {mapGetters, mapActions} from 'vuex'
  
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'


  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      sucursales:[],
      sucursal: null,

      snackbar: false,
      show_folio: false,
      planteles: [
        { id_plantel: 1, plantel: "INBI" },
        { id_plantel: 2, plantel: "FAST ENGLISH" },
      ],
      plantel: null,
      area: null,
      motivo: "",
      mensaje: "",
      estatus: 0,
      folio: 0,
      motivo_aux: "",
      loading5:false,

      url:'',
      fotos:[],
      files: [],
      vistaPrevias:[],

      areas:[],
      area: null,

      puestos:[],
      puesto:null,

      jefes:[],
      jefe: null,
      urgente: 0
    }),

    async created() {
      var url = axios.defaults.baseURL + 'evidencia-tickets/'
      this.initialize()
      await this.getAreas()
    },

    watch: {
      area(){
        this.puestos = this.area.puestos
        if( this.puestos.length == 1 ){
          this.puesto = this.puestos[0]
        }
      },

      puesto(){
        this.jefes = this.puesto.jefes

        if( this.puesto.puesto == "Encargada de sucursal" ){
          this.jefe = this.jefes.find( el => el.id_usuario == 568 )
        }else{
          this.jefe = this.jefes[0]
        }
      }
    },


    computed:{
      ...mapGetters('login',['getdatosUsuario']),
    },

    methods: {
      initialize () {
        this.sucursales = []
        this.cargar = true
        this.$http.get("sucursales.all").then(response=>{
          this.sucursales = response.data
          this.cargar     = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getAreas () {
        this.cargar = true
        this.areas = []
        this.$http.get('departamentos.tickets').then(response=>{
          this.areas = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async save() {

        if( this.area && this.area.iddepartamento == 9 ){
          this.puesto = { idpuesto : 43 }
          this.jefe   = { id_usuario : 1 }
        }

        if (this.plantel != 0 && this.motivo != "" && this.sucursal && this.getdatosUsuario.iderp > 0 && this.area && this.puesto ) {
          
          this.cargar = true
          let fotos = []
          if(this.vistaPrevias.length){
            let respuesta = await this.subirFotosServidor( )
            fotos = respuesta.data
          }

          var payload = {
            id_unidad_negocio: this.plantel,
            id_area          : this.puesto.idpuesto,
            idjefe_area      : this.jefe.id_usuario,
            motivo           : this.motivo,
            estatus          : 2,
            id_usuario       : this.getdatosUsuario.iderp,
            tipousuario      : 0,
            id_suc           : this.sucursal,
            urgente          : this.urgente == true ? 1 : 0,
            fotos
          };
          console.log(payload)

          this.$http.post("tickets.add", payload).then((response) => {
            this.folio               = response.data.ticket.id;
            this.idhistorial_ticket  = response.data.historial.id;
            this.motivo_aux = this.motivo;
            this.show_folio = true;
            this.plantel    = null
            this.motivo     = "";
            this.sucursal   = null;
            this.urgente    = 0;
            this.validarSuccess( response.data.message )
            this.vistaPrevias = []
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
          
        }else{
          this.validarErrorDirecto('Favor de llenar todos los datos')
        }

      },

      subirFotosServidor( ){
        let formData = new FormData();

        for( const i in this.vistaPrevias ){
          formData.append('file', this.vistaPrevias[i].file )
        }

        return new Promise((resolve,reject)=>{
          // obtener la extensión del archivo
          // Hacemos la petición
          this.$http.post(`tickets.subir.evidencia`, formData ).then(response=>{
            resolve( response )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },

      cargarFotos(){
        if(this.files.length < 1){
          return
        }

        this.files.forEach((element, index) => {
          // creamos una variable tipo FormData
          let formData = new FormData();
          //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', element);
          //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          if(element.size > 26214400){ //26214400 = 25MB
              console.log(element.size)
              this.validarErrorDirecto('El archivo sobrepasa el limite del peso, max 25 MB')
          } else {
              this.getBase64(element, formData)
          }
        })
      },

      getBase64(file, formData) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          var video = false
          const nombreSplit = file.name.split('.')
          const extension = nombreSplit[nombreSplit.length-1]
          if(['mp4', 'mov', 'wmv', 'avi'].includes(extension)){
              video = true
          }
          me.vistaPrevias.push({
                  url: reader.result, 
                  formData: formData, 
                  image_name: file.name,
                  video : video,
                  file
              })
        };
      },

      eliminarFoto(value){
        this.vistaPrevias.forEach((element, index)=>{
          if(element.url == value){
            this.vistaPrevias.splice(index,1);
          }
        })
      },

    },
  };
</script>

<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
