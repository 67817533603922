<template>
  <v-container fluid class="mt-5 ml-2">
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-toolbar
            flat
          >
            <v-toolbar-title>NI por ciclo</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn color="primary" @click="consultar()" small>Consultar</v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          

          <!-- Cantidades -->
          <v-card-text class="pt-4 mb-3">
            <v-row align="center">
              
              <!-- Conteo para fast -->
              <v-col cols="12" md="6" lg="4">
                <v-card color="blue darken-4">
                  <v-row>
                    <v-col cols="6" class="text-center">
                      <span class="white--text text-h4 text-center">{{ fast.inscritosFinales }}</span>
                      <br/>
                      <span class="white--text">Inscritos finales</span>
                    </v-col>
                    <v-col cols="6">
                      <span class="white--text text-h6">{{ fast.conInduccion }} </span>
                      <span class="white--text">c. Inducción</span>
                      <br/>
                      <span class="white--text text-h6">{{ fast.sinInduccion }} </span>
                      <span class="white--text">S. Inducción</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <!-- Conteo para inbi -->
              <v-col cols="12" md="6" lg="4">
                <v-card color="blue lighten-1">
                  <v-row>
                    <v-col cols="6" class="text-center">
                      <span class="white--text text-h4 text-center">{{ inbi.inscritosFinales }}</span>
                      <br/>
                      <span class="white--text">Inscritos finales</span>
                    </v-col>
                    <v-col cols="6">
                      <span class="white--text text-h6">{{ inbi.conInduccion }} </span>
                      <span class="white--text">c. Inducción</span>
                      <br/>
                      <span class="white--text text-h6">{{ inbi.sinInduccion }} </span>
                      <span class="white--text">S. Inducción</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

        	<v-card class="elevation-0">
        		<v-card-title primary-title class="py-1">
        		  Inscritos Fast
        		</v-card-title>
        	  <v-card-text>
  		      	<v-data-table
  		          :headers="headers"
  		          :items="fast.tabla"
  		          class="elevation-1"
  		          hide-default-footer
  		          :items-per-page="20"
  		          dense
  		          mobile-breakpoint="200"
  		        >
  		          <template v-slot:no-data>
  		            <v-btn
  		              color="primary"
  		              @click="initialize"
  		              small
  		            >
  		              Actualizar
  		            </v-btn>
  		          </template>
  		        </v-data-table>
        	  </v-card-text>
        	</v-card>

        	<v-card class="elevation-0">
        		<v-card-title primary-title class="py-1">
        		  Inscritos Inbi
        		</v-card-title>
        	  <v-card-text>
  		      	<v-data-table
  		          :headers="headers"
  		          :items="inbi.tabla"
  		          class="elevation-1"
  		          hide-default-footer
  		          :items-per-page="20"
  		          dense
  		          mobile-breakpoint="200"
  		        >
  		          <template v-slot:no-data>
  		            <v-btn
  		              color="primary"
  		              @click="initialize"
  		              small
  		            >
  		              Actualizar
  		            </v-btn>
  		          </template>
  		        </v-data-table>
        	  </v-card-text>
        	</v-card>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'
  // Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
      headers: [
        { text: 'Sucursal'                 , value: 'plantel' },
        { text: 'Alumnos dia anterior'     , value: 'ayer' },
        // { text: 'Hoy'                      , value: 'alumnoDiaHoy' },
        { text: 'Acumulado semana'         , value: 'semanal' },
        { text: 'Acumulado'                , value: 'acumulado' },
      ],

      headersDos: [
        { text: 'Nombre'                   , value: 'nombre' },
        { text: 'Sucursal'                 , value: 'plantel' },
        { text: 'Vendedora'                , value: 'vendedora' },
        // { text: 'Medio'                    , value: 'medio' },
        { text: 'Campaña'                  , value: 'campania' },
      ],
      desserts: [],
      ciclos:[],
      ciclo:null,
      cicloSup:null,
      planteles:[],
      plantel:null,
      cicloAnterior:[],
      cicloSiguiente:[],

      dataTablaFastUno:[],
      dataTablaInbiUno:[],
      dataTablaFastDos:[],
      dataTablaInbiDos:[],

      dialogCarga: false,
      fast:{},
      inbi:{},
      fastTable:[],
      inbiTable:[]
    }),

    watch: {
      ciclo () {
        if(this.ciclo){
          // Buscar la pisición en la que se encuentra el ciclo
          const positionCiclo = this.ciclos.indexOf(this.ciclo)

          // Guardar un ciclo después
          this.cicloSup = this.ciclos[ positionCiclo + 1 ]

          // Consultar la información
          this.consultar()
        }
      }
    },

    async created () {
      this.cargar = true
      await this.getCiclosActivos( )
      await this.initialize()
      this.cargar = false
    },

    methods: {
      initialize () {
        this.planteles = []
        this.$http.get('kpi.planteles.all').then(response=>{
          for(const i in response.data){
            var payload = {
              plantel: response.data[i].plantel,
              alumnosActual: 0,
              alumnosSiguiente: 0,
              faltan: 0,
              porcentaje: 0,
            }
            this.planteles.push(payload)
          }
          
        }).catch(error=>{console.log(error)})
      },

      getCiclosActivos(){
        this.ciclos = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          this.ciclos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      consultar () {
        this.cargar = true
      	this.fast = {}
      	this.inbi = {}

        this.$http.post('kpi.inscripciones.porciclo', this.ciclo ).then(response => {
          this.fast = response.data.fast
          this.inbi = response.data.inbi
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      tablaUnoFast () {
      	this.dataTablaFastUno = []
        // Sacamos los datos repetidos
        let arrayPlanteles = this.fast.alumnos.map(item=>{
          return [item.plantel,item]
        });

        // Creamos un map de los alumnos
        var planteles = new Map(arrayPlanteles); // Pares de clave y valor
        // y ahora si, extraemos los valores unicos
        let unicos = [...planteles.values()]; // Conversión a un array
        var plantelesFinal = []

        for(const i in unicos){
	        var arregloFastTablaDos = {
	        	id_plantel    :unicos[i].id_plantel,
	        	plantel    		:unicos[i].plantel,
	        	alumnodia    	:0,
						alumnosemana	:0,
						totalAcumulado:0,
	        }
        	plantelesFinal.push(arregloFastTablaDos)
        }

        for(const i in plantelesFinal){
        	for(const j in this.fast.alumnos){
        		if(this.fast.alumnos[j].id_plantel == plantelesFinal[i].id_plantel){
        			plantelesFinal[i].totalAcumulado += 1
        		}
	        }
        }

        for(const i in plantelesFinal){
        	for(const j in this.fast.alumnosDia){
        		if(this.fast.alumnosDia[j].id_plantel == plantelesFinal[i].id_plantel){
        			plantelesFinal[i].alumnodia += 1
        		}
	        }
        }

        for(const i in plantelesFinal){
        	for(const j in this.fast.alumnosSem){
        		if(this.fast.alumnosSem[j].id_plantel == plantelesFinal[i].id_plantel){
        			plantelesFinal[i].alumnosemana += 1
        		}
	        }
        }

        for(const i in plantelesFinal){
          for(const j in this.fast.alumnoDiaHoy){
            if(this.fast.alumnoDiaHoy[j].id_plantel == plantelesFinal[i].id_plantel){
              plantelesFinal[i].alumnosemana += 1
            }
          }
        }

        var arregloFastTablaDos = {
        	id_plantel    :0,
        	plantel    		:'Total',
          alumnodia     :0,
        	alumnodiahoy  :0,
					alumnosemana	:0,
					totalAcumulado:0,
        }

        for(const i in plantelesFinal){
        	arregloFastTablaDos.alumnodia += plantelesFinal[i].alumnodia
        	arregloFastTablaDos.alumnosemana += plantelesFinal[i].alumnosemana
        	arregloFastTablaDos.totalAcumulado += plantelesFinal[i].totalAcumulado
        }
      	plantelesFinal.push(arregloFastTablaDos)

        this.dataTablaFastUno = plantelesFinal
        this.dialogCarga = false

        // Obtener los acumulados del día anterior
      	this.acumuladosFastDia()
      },

      acumuladosFastDia () {

      },

      tablaUnoInbi () {
      	this.dataTablaInbiUno = []
        // Sacamos los datos repetidos
        let arrayPlanteles = this.inbi.alumnos.map(item=>{
          return [item.plantel,item]
        });

        // Creamos un map de los alumnos
        var planteles = new Map(arrayPlanteles); // Pares de clave y valor
        // y ahora si, extraemos los valores unicos
        let unicos = [...planteles.values()]; // Conversión a un array
        var plantelesFinal = []

        for(const i in unicos){
	        var arregloInbiTablaDos = {
	        	id_plantel    :unicos[i].id_plantel,
	        	plantel    		:unicos[i].plantel,
	        	alumnodia    	:0,
						alumnosemana	:0,
						totalAcumulado:0,
	        }
        	plantelesFinal.push(arregloInbiTablaDos)
        }


        for(const i in plantelesFinal){
        	for(const j in this.inbi.alumnos){
        		if(this.inbi.alumnos[j].id_plantel == plantelesFinal[i].id_plantel){
        			plantelesFinal[i].totalAcumulado += 1
        		}
	        }
        }

        for(const i in plantelesFinal){
        	for(const j in this.inbi.alumnosDia){
        		if(this.inbi.alumnosDia[j].id_plantel == plantelesFinal[i].id_plantel){
        			plantelesFinal[i].alumnodia += 1
        		}
	        }
        }

        for(const i in plantelesFinal){
        	for(const j in this.inbi.alumnosSem){
        		if(this.inbi.alumnosSem[j].id_plantel == plantelesFinal[i].id_plantel){
        			plantelesFinal[i].alumnosemana += 1
        		}
	        }
        }
        // Agregar los totales
        var arregloInbiTablaDos = {
        	id_plantel    :0,
        	plantel    		:'Total',
        	alumnodia    	:0,
					alumnosemana	:0,
					totalAcumulado:0,
        }

        for(const i in plantelesFinal){
        	arregloInbiTablaDos.alumnodia += plantelesFinal[i].alumnodia
        	arregloInbiTablaDos.alumnosemana += plantelesFinal[i].alumnosemana
        	arregloInbiTablaDos.totalAcumulado += plantelesFinal[i].totalAcumulado
        }
      	plantelesFinal.push(arregloInbiTablaDos)



        this.dataTablaInbiUno = plantelesFinal
        this.dialogCarga = false
      },

      exportar(){
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
      	var arrayEspacios = this.dataTablaFastUno
      	var arregloInbiTablaDos = {
        	id_plantel    :'',
        	plantel    		:'',
        	alumnodia    	:'',
					alumnosemana	:'',
					totalAcumulado:'',
        }
        arrayEspacios.push(arregloInbiTablaDos)
        arrayEspacios.push(arregloInbiTablaDos)
        arrayEspacios.push(arregloInbiTablaDos)

        // titulos
        var arregloInbiTablaDos = {
        	id_plantel    :'id_plantel',
        	plantel    		:'plantel',
        	alumnodia    	:'alumnodia',
					alumnosemana	:'alumnosemana',
					totalAcumulado:'totalAcumulado',
        }
        arrayEspacios.push(arregloInbiTablaDos)

      	var arrayConcat = arrayEspacios.concat(this.dataTablaInbiUno);

        let data = XLSX.utils.json_to_sheet(arrayConcat)
        const workbook = XLSX.utils.book_new()
        const filename = 'KPI'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
      		this.dataTablaFastUno.splice(this.dataTablaFastUno.length-4,4)
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },




      
    },
  }
</script>