<template>
  <!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="paramsRechazo.estatus"
    max-width="500"
  >
    <v-card>
    	<v-card-title primary-title>
    	  Rechazar solicitud
    	</v-card-title>
    	<v-card-text class="pt-4">
  			<v-textarea
  				:rows="3"
  				filled
  				label="mensaje y motivo de rechazo"
  				v-model="comentario"
  			>
  			</v-textarea>

  			<v-file-input 
          small-chips 
          multiple 
          filled 
          v-model="files" 
          accept="image/png, image/jpeg, image/bmp"
          placeholder="Selecciona tus imagenes" prepend-icon="mdi-camera" label="Evidencias" @change="cargarFotos()">
        </v-file-input>

        <v-row v-if="vistaPrevias.length">
          <v-col cols="12" md="3" v-for="(img, i) in vistaPrevias" :key="i">
            <v-card class="py-4 shadowCard">
              <v-img :src="img.url" contain aspect-ratio="2"></v-img>
              <v-btn  color="error"  small  @click="eliminarFoto(img.url)" top right absolute fab >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>

    	</v-card-text>
    	<v-card-actions>
		    <v-btn 
		    	color="dark" 
		    	tile 
		    	dark
		    	small 
		    	@click="paramsRechazo.estatus = false"
		    >
		  		Cancelar
		  		<v-icon small right>mdi-cancel</v-icon>
		  	</v-btn>
		    <v-spacer></v-spacer>
		    <v-btn 
		    	color="green" 
		    	tile 
		    	dark 
		    	small
		    	@click="confirmar()"
		    >
					Enviar
		  		<v-icon small right>mdi-content-save</v-icon>
		  	</v-btn>
    	</v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>


<script>

	import { mapGetters } from 'vuex'
	import axios from 'axios';
  import validarErrores        from '@/mixins/validarErrores'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

	export default {

		components:{
      Alerta,
      carga,
    },

		props:[
			'paramsRechazo',
			'idsolicitud_cambio'
	  ],

    mixins: [ validarErrores ],

	  data: () => ({

	  	// Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
      
	  	comentario: '',
	  	url:'',
      url2:'',
      fotos:[],
      files: [],
      files2:[],
      vistaPrevias:[],
      vistaPrevias2:[],
	  }),

	  created(){
	  	console.log('vwvrv', this.idsolicitud_cambio)
	  },

	  methods:{

	  	// confirmar( ){
	  	// 	// this.$emit('addCambio')
	  	// 	// this.paramsRechazo.estatus = false
	  	// },

	  	async confirmar () {

        // Validar que esten llenos los campos
    		this.cargar = true
      	
      	let fotos = []
        if(this.vistaPrevias.length){
          let respuesta = await this.subirFotosServidor( )
          fotos = respuesta.data
        }

        // Activar el disabled del boton para que no le den doble click
        this.loading  = true
        this.disabled = true

        // Desesestructuración de alumno 
        let payload = {
        	idusuario         : this.getdatosUsuario.iderp, 
          idsolicitud_cambio: this.idsolicitud_cambio,
          comentario        : this.comentario,
          fotos
        }

        this.cargar = true

        // Enviar los datos 
        this.$http.post('cambios.rechazar',payload).then(response=>{

          this.validarSuccess( 'Datos grabados correctamente')

          this.$emit('rechazado')

        }).catch(error=>{
          this.loading  = false
          this.disabled = false
          this.validarError(error)
        })
        
      },

	  	subirFotosServidor( ){
        let formData = new FormData();

        for( const i in this.vistaPrevias ){
          formData.append('file', this.vistaPrevias[i].file )
        }

        return new Promise((resolve,reject)=>{
          // obtener la extensión del archivo
          // Hacemos la petición
          this.$http.post(`cambios.evidencia`, formData ).then(response=>{
            resolve( response )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },

      cargarFotos(){
        if(this.files.length < 1){
          return
        }

        this.files.forEach((element, index) => {
                // creamos una variable tipo FormData
          let formData = new FormData();
                //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', element);
                //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          this.getBase64(element, formData)
        })
      },

      getBase64(file, formData) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          me.vistaPrevias.push({
            url: reader.result, 
            formData: formData, 
            image_name: file.name,
            file
          })
        };
      },

      eliminarFoto(value){
        this.vistaPrevias.forEach((element, index)=>{
          if(element.url == value){
            this.vistaPrevias.splice(index,1);
          }
        })
      },
	  
	  },

	  computed:{
      ...mapGetters ('login',['getdatosUsuario']),
    }
	}
</script>