<template>
	<v-card class="transparent elevation-0">
    <v-divider></v-divider>

    <v-card-text class="black--text">
      <v-data-table
        :headers="headers"
        :items="pagosDesdeOtraSucursal"
        class="shadowCard"
        dense
        mobile-breakpoint="200"
        :items-per-page="pagosDesdeOtraSucursal.length"
        hide-default-footer
      >
        <template v-slot:top>
          <v-card-title primary-title>
            <label class="text-subtitle-1 black--text">Pagos recibidos de otra sucursal para mi</label>
            <v-spacer></v-spacer>
          </v-card-title>
        </template>

        <template v-slot:item.plantel="{ item }">
          <div
            v-if="
              item.id_plantel == plantel ||
              item.id_plantel == 1000
            "
            class="green white--text pr-2"
          >
            {{ item.plantel }}
          </div>
          <div v-else class="pr-2">{{ item.plantel }}</div>
        </template>
        <template v-slot:item.total="{ item }">
          <div
            v-if="
              item.id_plantel == plantel ||
              item.id_plantel == 1000
            "
            class="green white--text pr-2"
          >
            {{ item.total }}
          </div>
          <div v-else class="pr-2">{{ item.total }}</div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta              from '@/components/alertas/Alerta.vue'
  import carga               from '@/components/alertas/carga.vue';

  import validarErrores      from '@/mixins/validarErrores'
  import funcionesExcel      from '@/mixins/funcionesExcel'
  
	const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

  	props:[
			'pagosDesdeOtraSucursal',
			'plantel'
  	],

    data: () => ({
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      headers: [
	      { text: "id"           , value: "id_plantel" },
	      { text: "Plantel"      , value: "plantel"      , align: "right" },
	      { text: "Efectivo"     , value: "efectivo"     , align: "right" },
	      { text: "Transferencia", value: "transferencia", align: "right" },
	      { text: "Depositos"    , value: "depositos"    , align: "right" },
	      { text: "Tarjeta"      , value: "tarjeta"      , align: "right" },
	      { text: "Total"        , value: "total"        , align: "right" },
	    ],
    }),
  };
</script>