import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';
import axios from 'axios';
var XLSX = require("xlsx");

export default {
	methods: {
		/* EXPORTAR TODOS LOS CONTACTOS */
    exportarContactos () {
      const dataExport = []
      for(const i in this.contactExport){
        dataExport.push({
          nombre             :  this.contactExport[i].folio,
          'Given Name'       : this.contactExport[i].folio,
          'Family Name'      : this.contactExport[i].nombre_completo,
          'Group Membership' : '* myContacts',
          'Phone 1 - Type'   : 'Mobile',
          'Phone 1 - Value'  : this.contactExport[i].telefono,
        })
      }

      this.exportExcel(dataExport, 'contacts_google')
    },

    exportarClasificacion () {
      const dataExport = []
      for(const i in this.filterProspectos){
        dataExport.push({
          'Folio'  : this.filterProspectos[i].folio,
          'Nombre' : this.filterProspectos[i].nombre_completo,
          'Etapa'  : this.etapa( this.filterProspectos[i].idetapa ),
          'Fecha'  : this.filterProspectos[i].fecha_creacion,
          'De'     : this.filterProspectos[i].recluta,
          'Para'   : this.filterProspectos[i].vendedora,
        })
      }

      this.exportExcel(dataExport, 'reporte_buenos_malos')
    },

    etapa( value ){
      switch( value ){
        case 1: 
          return 'Atrasado'
        break;

        case 2: 
          return 'Nuevo'
        break;

        case 3: 
          return 'Inducción'
        break;

        case 4: 
          return 'Con Info'
        break;

        case 5: 
          return 'Sin Info'
        break;

      }
    },

    exportExcel(dataExport, name){
      let data = XLSX.utils.json_to_sheet(dataExport)
      const workbook = XLSX.utils.book_new()
      const filename = name
      XLSX.utils.book_append_sheet(workbook, data, filename)

      var wbout = XLSX.write(workbook, {
        bookType: 'xls',
        bookSST: false,
        type: 'binary'
      });

      const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
      
      let formData = new FormData();
      // //se crea el objeto y se le agrega como un apendice el archivo 
      formData.append('file',file);
      // /*getDatosRiesgo the form data*/

      this.$http.post('pdfs',formData).then(response=> {
        window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
      }).catch(error=> {
        console.log(error);
      });
    },


    exportExcel2(dataExport, name){
      let data = XLSX.utils.json_to_sheet(dataExport)
      const workbook = XLSX.utils.book_new()
      const filename = name
      XLSX.utils.book_append_sheet(workbook, data, filename)

      var wbout = XLSX.write(workbook, {
        bookType: 'xls',
        bookSST: false,
        type: 'binary'
      });

      const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
      
      let formData = new FormData();
      // //se crea el objeto y se le agrega como un apendice el archivo 
      formData.append('file',file);
      // /*getDatosRiesgo the form data*/

      this.$http.post('pdfs',formData).then(response=> {
        window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
      }).catch(error=> {
        console.log(error);
      });
    },

    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    },

  }
}