var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.foraneo",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"dense":"","small":"","hide-details":"","readonly":""},model:{value:(item.foraneo),callback:function ($$v) {_vm.$set(item, "foraneo", $$v)},expression:"item.foraneo"}})]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [(item.estatus == 1)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v("Activo")]):_c('v-chip',{attrs:{"small":"","color":"error","dark":""}},[_vm._v("Inactivo")])]}},{key:"item.escuela",fn:function(ref){
var item = ref.item;
return [(item.escuela == 1)?_c('v-chip',{attrs:{"small":"","color":"#2EDAD5","dark":""}},[_vm._v(" INBI ")]):_c('v-chip',{attrs:{"small":"","color":"#000D4A","dark":""}},[_vm._v(" FAST ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.folio == 'SIN FOLIO')?_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"red","dark":"","x-small":"","fab":""},on:{"click":function($event){return _vm.preLlenarDatos(item)}}},[_c('v-icon',[_vm._v("mdi-account-box")])],1):_vm._e(),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","x-small":"","fab":""},on:{"click":function($event){return _vm.editarLead(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.respondio",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"label":""},on:{"change":function($event){return _vm.respondio(item)}},model:{value:(item.respondio),callback:function ($$v) {_vm.$set(item, "respondio", $$v)},expression:"item.respondio"}})]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.initialize}},[_vm._v(" Actualizar ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }