<!--VUE-->

<template>
  <v-container class="mt-4" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Comentarios Maestros</span>
            <v-spacer></v-spacer>
            <v-chip color="info" small @click="initialize()">Actualizar</v-chip>
          </v-card-title>
        
          <v-card-text class="pt-0">
            <v-row  class="pt-0" justify="space-between">
               <v-col cols="3" md="3" lg="3">
                <v-autocomplete
                  label="Estatus"
                  id="id"
                  filled
                  dense
                  hide-details
                  v-model="estatus"
                  :items="[
                    { id: 0, nombre: 'Nuevo' },
                    { id: 1, nombre: 'Aceptado'  },
                    { id: 2, nombre: 'Rechazado' },             
                  ]"
                  item-text="nombre"
                  item-value="id"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="pt-0">
                <v-text-field
                  label="Buscar"
                  id="id"
                  v-model="search"
                  outlined
                  dense
                  rounded
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="filterComentarios"
              sort-by="calories"
              class="elevation-0"
              :search="search"
            >
              <template v-slot:item.estado="{ item }">
                <v-chip color="blue" small dark v-if="item.estado == 0"
                  >Nuevo</v-chip
                >
                <v-chip color="green" small dark v-if="item.estado == 1"
                  >Aceptado</v-chip
                >
                <v-chip color="black" small dark v-if="item.estado == 2"
                  >Rechazado</v-chip
                >
              </template>

              <!--BOTON aceptar -->
              <template v-slot:item.aceptar="{ item }">
                <v-btn
                  color="primary"
                  dark
                  small
                  @click="
                    (maestromensaje = item), (dialogActivarMensajes = true)
                  "
                >
                  ACEPTAR
                </v-btn>
              </template>

              <!--BOTON Rechazar -->
              <template v-slot:item.rechazar="{ item }">
                <v-btn
                  color="secondary"
                  dark
                  small
                  @click="
                    (alumnoseleccionado = item),
                      (dialog_mensaje_rechazo = true),
                      getMensajesRechazo()
                  "
                >
                  RECHAZAR
                </v-btn>
              </template>
              <!------------------------------------------------------------------>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!--DIALOG ACEPTAR MENSAJE MAESTRO-->

    <v-dialog
      v-if="maestromensaje"
      v-model="dialogActivarMensajes"
      persistent
      max-width="300"
    >
      <v-card>
        <v-card-title class="text text-center">
          ¿Aceptar comentario como válido?
        </v-card-title>

        <v-card-actions>
          <v-btn
            color="error"
            dark
            small
            rounded
            @click="(dialogActivarMensajes = false), (maestromensaje = null)"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            small
            rounded
            @click="actualizarMensajeYKardex()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG RECHAZAR MENSAJE MAESTRO-->

    <v-dialog
      v-if="maestromensaje"
      v-model="dialogRechazarMensajes"
      persistent
      max-width="300"
    >
      <v-card>
        <v-card-title class="text text-center">
          ¿Rechazar Mensaje?
        </v-card-title>

        <v-card-actions>
          <v-btn
            color="error"
            dark
            small
            rounded
            @click="(dialogRechazarMensajes = false), (maestromensaje = null)"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            small
            rounded
            @click="actualizarMensajeRechazo()"
          >
            Rechazar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRechazar" persistent max-width="800">
      <v-card style="position: relative">
        <v-card-title primary-title class="pb-0">
          Comentario Rechazado
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="mensajeRechazo"
            outlined
            dense
            label="Escriba el motivo del rechazo..."
          >
          </v-textarea>
          <div class="text-right">
            <v-btn
              color="secondary"
              dark
              class="mr-2"
              @click="dialogRechazar = false"
              small
              tile
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="mr-2"
              @click="update()"
              small
              tile
            >
              Enviar
            </v-btn>
          </div>
        </v-card-text>
        <v-btn
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogRechazar = false"
          ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_mensaje_rechazo" max-width="1000px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1"><b>Enviar Comentario</b></span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-row v-for="(item, index) in this.mensajes" :key="index">
            <v-col cols="12">
              <v-textarea
                v-model="item.mensaje"
                :id="'motivo_salida_' + index"
                label="Comentario"
                outlined
                hide-details
                :rows="2"
                dense
                auto-grow
                readonly
                style="max-width: 900px; margin-right: auto"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="item.respuesta"
                :id="'motivo_salida_' + index"
                label="Respuesta..."
                outlined
                hide-details
                :rows="2"
                dense
                auto-grow
                readonly
                style="max-width: 900px; margin-left: auto"
                :class="{ 'd-none': !item.respuesta }"
              ></v-textarea>
            </v-col> </v-row
          ><br />
          <v-row
            style="border: 1px solid gray; padding: 2px; border-radius: 5px"
          >
            <v-col cols="12" :style="{ paddingTop: '10px' }">
              <v-textarea
                v-model="mensaje"
                label="Escriba su mensaje..."
                outlined
                hide-details
                dense
                auto-grow
                :readonly="alumnoseleccionado.estado == 2 || alumnoseleccionado.estado == 1"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="(dialog_mensaje_rechazo = false), (mensaje = '')"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="enviarMensajeRespuesta()"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>

<!-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->


<script>
import axios from 'axios';
//----------IMPORTS--------------//
import { mapGetters, mapActions } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";
import funcionesExcel from "@/mixins/funcionesExcel";
import validarErrores from "@/mixins/validarErrores";



export default {
  components: {
    Alerta,
    carga,
  },

  mixins: [validarErrores, funcionesExcel],

  //----------DATA--------------//
  data: () => ({
    search: "",
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "ID", value: "idmaestro_mensaje" },
      { text: "Matricula", value: "Matricula" },
      { text: "Alumno", value: "Alumno" },
      { text: "Maestro", value: "Maestro" },
      { text: "Grupo", value: "Grupo" },
      { text: "Mensaje", value: "Mensaje" },
      { text: "Estatus", value: "estado" },
      { text: "Aceptar", value: "aceptar" },
      { text: "Rechazar", value: "rechazar" },
    ],

    mensajesMaestro: [],
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    alumnos: [],
    maestromensaje: null,
    filtro: 3,

    mensajeRechazo: "",
    dialogActivarMensajes: false,
    dialogRechazarMensajes: false,
    dialogRechazar: false,
    dialog_mensaje_rechazo: false,

    alumnoseleccionado: [],
    estatus:null,
    mensaje:null,
    mensajes: []
  }),

  //----------COMPUTED--------------//
  computed: {

    filterComentarios() {
      if (this.estatus) {
        return this.mensajesMaestro.filter((el) => {
          return (
            el.estado === this.estatus
          );
        });
      } 
      else {
        return this.mensajesMaestro;
      }
    },
  },

  //-------
  async created() {
    await this.initialize();
  },

  //----------METHODS--------------//
  methods: {
    initialize() {
      this.mensajesMaestro = [];
      return this.$http
        .get("mensaje.maestro.get.mensaje")
        .then((response) => {
          this.mensajesMaestro = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    validarErrores(value) {
      this.cargar = false;
      if (value.status == 400 || value.status == 500) {
        this.parametros.mensaje = value.body.message;
        this.parametros.dialogError = true;
        this.parametros.color = "#FF2C2C";
        this.respuestaAlerta = true;
      }
    },

    // actualizarMensaje(){
    //   return this.$http.post('mensaje.maestro.update.mensaje', this.maestromensaje).then( response=> {
    //     this.dialogActivarMensajes = false
    //   }).catch( error => {
    //     console.log( error )
    //   }).finally( ( ) => {  })

    // },

    // actualizarKardex(){
    //   return this.$http.post('mensaje.maestro.update.kardex', this.maestromensaje).then( response=> {
    //     this.dialogActivarMensajes = false
    //     this.maestromensaje = null
    //     this.initialize()
    //   }).catch( error => {
    //     console.log( error )
    //   }).finally( ( ) => {  })

    // },

    actualizarMensajeYKardex() {
      console.log(this.maestromensaje)
      this.$http
        .post("mensaje.maestro.update.mensaje", this.maestromensaje)
        .then((response) => {
          this.$http
            .post("mensaje.maestro.update.kardex", this.maestromensaje)
            .then((response) => {
              this.dialogActivarMensajes = false;
              this.maestromensaje = null;
              this.initialize();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    actualizarMensajeRechazo() {
      return this.$http
        .post("mensaje.maestro.update.mensaje.rechazo", this.maestromensaje)
        .then((response) => {
          this.dialogRechazarMensajes = false;
          this.maestromensaje = null;
          this.initialize();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    getMensajesRechazo() {
      this.cargar = true;
      this.mensajes = [];


      const payload = {
        id_alumno: this.alumnoseleccionado.id_alumno,
        id_grupo: this.alumnoseleccionado.id_grupo,
        escuela: this.alumnoseleccionado.escuela
      };
      return this.$http
        .post("consultar.mensajes.rechazo", payload)
        .then((response) => {
          this.mensajes = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

     enviarMensajeRespuesta() {
      this.cargar = true;
      const payload = {
        escuela: this.alumnoseleccionado.escuela,
        respuesta: this.mensaje,
        id_alumno: this.alumnoseleccionado.id_alumno,
        id_alumno2: this.alumnoseleccionado.id_alumno,
        id_grupo: this.alumnoseleccionado.id_grupo,
        id_grupo2: this.alumnoseleccionado.id_grupo,

        id: this.alumnoseleccionado.idmaestro_mensaje,
        estatus: 2,
      };   

    
       return this.$http
        .post("update.incidencias.mensaje.respuesta", payload)
        .then((response) => {
          this.$http
             .post("update.estatus.incidencias", payload)
            .then((response) => {
              this.validarSuccess(response.data.message);
              this.cargar = false;
              this.dialog_mensaje_rechazo = false;
              this.initialize();
              this.mensaje = null;
            })
            .catch((error) => {
              this.validarError(error);
            })
            .finally(() => {
              this.cargar = false;
            });
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
        },
 
  },
};
</script>