<template>
  <v-container class="mt-4 ml-2">
	  <v-row justify="center">
	    <v-col cols="12">
	    	<v-card class="shadowCard">
	    	  <v-card-title primary-title>
	    	    Alumnos nivel 14
	    	    <v-spacer></v-spacer>

	    	    <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( alumnos  , 'ALUMNOS_14VO')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="getCodigos()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	    	  </v-card-title>
	    	  <v-card-text>
	    	    <v-row justify="end">
	    	    	<v-col cols="12" md="6" lg="5">
	    	    		<v-text-field
	    	    			dense
	    	    			hide-details
	    	    			filled
	    	    			append-icon="mdi-magnify"
	    	    			label="Buscar"
	    	    			v-model="search"
	    	    		></v-text-field>
	    	    	</v-col>
	    	    </v-row>
	    	  </v-card-text>			
	        <v-col cols="12">
            <v-data-table
              dense
              :headers="tableHeader"
              :items="alumnos"
              item-key="name"
					    @page-count="pageCount = $event"
					    :search="search"
            >
            	<template v-slot:item.escuela="{ item }">
                <v-chip small v-if="item.escuela==1" color="#2EDAD5"> INBI </v-chip>
                <v-chip small v-else color="#000D4A" dark> FAST </v-chip>
              </template>

            	<template v-slot:item.estatus="{ item }">
					      <v-chip color="secondary" rounded small v-if="item.existe">Solicitado</v-chip>
					      <v-chip color="primary"   rounded small @click="generarCopia(item)" v-else>Generar Copia</v-chip>
					    </template>
            </v-data-table>
	        </v-col>
	    	</v-card>
	    </v-col>
	  </v-row>    

	  <v-dialog v-model="dialogAddSolicitud" max-width="600px" v-if="alumno" persistent>
			<v-card class="elevation-0">
				<v-card-title primary-title>
					<strong>Solicitar certificado fisico</strong>
				</v-card-title>
				<v-card-text>
				  <v-text-field
				    label="Nombre completo del alumno"
				    outlined
				    dense
				    v-model="alumno.nombre_completo"
				    hide-details
				  ></v-text-field>
				  <v-checkbox 
				  	label="Nombre verificado" 
				  	v-model="alumno.verificacion" 
				  	value="value"
				  	hide-details
				  ></v-checkbox>
				</v-card-text>
  			<v-card-actions class="pt-0" >
  				<v-spacer></v-spacer>
  				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
  			  <v-btn color="error" text large class="mr-2" @click="dialogAddSolicitud = false, alumno = null">Cancelar</v-btn>
  			  <!-- Guardar la información  -->
  			  <v-btn
  			  	color="primary" 
  			  	dark 
  			  	class="elevation-6" 
  			  	large 
  			  	@click="generar"
  			  >Solicitar</v-btn>
  			</v-card-actions>
			</v-card>
    </v-dialog> 

		<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

    	alumnos:[],
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,

      tableHeader: [ 
        { text:'id alumno'   , value:'id_alumno' },
				{ text:'Matricula'   , value:'matricula' },
        { text:'Alumno'      , value:'alumno'  },
        { text:'Escuela'     , value:'escuela' },
        { text:'status'      , value:'estatus' },
      ],   

      alerta: { activo: false, texto:'', color:'error'},
      cargar: false,
      
      codigos:[],
      search:'',
    	dialogAddSolicitud:false,
    	alumno:null,

    }),

    async created () {
	  	await this.getCodigos()
    },

	  computed:{
	    ...mapGetters('login',['getdatosUsuario']),
	    
	    tamanioPantalla () {
	      return this.$vuetify.breakpoint.height -380
	    },
	  },

	  methods: {

	  	getCodigos () {
	  		this.alumnos = []
	  		this.cargar  = true
	  		return this.$http.get('academico.alumnos.ultimonivel').then( response =>{
	    		this.alumnos = response.data
	      	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
	  	},

	  	solicitar (item) {
	  		this.alumno = item
	  		this.dialogAddSolicitud = true
	  	},

	  	generarCopia( item ){
	  		this.cargar = true
	  		this.$http.post('academico.copia.niveles2/' + item.escuela , item).then( response =>{
	  			this.validarSuccess( 'Diploma creado correctamente' )

      		let foto_download = axios.defaults.baseURL + 'copias-certificados/'

          window.open( foto_download + response.data.nombreArchivo )

	  			this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

	  	},
	  }
	}
</script>

