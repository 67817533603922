<template>
	<v-container class="mt-5 ml-2">
	  <v-row>
	  	<v-col cols="12" lg="8">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		  	<span class="text-subtitle-1">Preguntas frecuentes</span>
	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row>
	  		  		<v-col cols="12" md="10" lg="8">
	  		  			<v-text-field
	  		  			  label="Buscar"
	  		  			  filled
	  		  			  dense
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
					  <v-expansion-panels>
					    <v-expansion-panel
					      v-for="( pregunta, i ) in filterPreguntas"
					      :key="i"
					    >
					      <v-expansion-panel-header>
					        <strong>{{ pregunta.pregunta }}</strong>
					      </v-expansion-panel-header>
					      <v-expansion-panel-content>
					        {{ pregunta.respuesta }}
                  <br/>
                  <v-chip 
                    color="success"
                    rounded
                    :href="pregunta.respuesta.substr( pregunta.respuesta.indexOf('http'), pregunta.respuesta.length -1 )"
                    target="_blank"
                    v-if="pregunta.respuesta && pregunta.respuesta.indexOf('http') > 0"
                    class="mr-6 mt-4"
                  > 
                    Ir al curso
                    <v-icon right>mdi-play-box</v-icon>
                  </v-chip>
					      </v-expansion-panel-content>
					    </v-expansion-panel>
					  </v-expansion-panels>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';


  export default {
  	components:{
      Alerta,
      carga,
    },

	  data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },
      respuestaAlerta:false,
      loader: true,
      cargar: false,

	  	search:'',
	    preguntas:[]
	  }),

		computed: {
			...mapGetters('login',['getdatosUsuario']),

      filterPreguntas () {
        if(this.preguntas){
          return this.preguntas.filter(p =>{
          	const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};

            let pregunta  = p.pregunta.toLowerCase().split('').map( letra => acentos[letra] || letra).join('').toString();
            let respuesta = p.respuesta.toLowerCase().split('').map( letra => acentos[letra] || letra).join('').toString();

            return pregunta.match(this.search.toLowerCase().split('').map( letra => acentos[letra] || letra).join('').toString()) || 
            	respuesta.match(this.search.toLowerCase().split('').map( letra => acentos[letra] || letra).join('').toString())
          })
        }
      },
    },

    created ( ){
    	this.initialize ( )
    },

    methods:{
    	initialize( ) {
        this.preguntas = []
        return this.$http.get('preguntasfre.list.frecuentes').then(response=>{
          const preguntas = response.data.filter( el=> { return el.estatus == 1 })
        	this.filtrarPreguntas( preguntas )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
    	},

    	filtrarPreguntas( preguntas ){
    		const idpuesto = this.getdatosUsuario.idpuesto
    		switch( idpuesto ){
    			// teachers
          case 10:
        		this.preguntas = preguntas.filter( el=> { return el.idpuesto == idpuesto}) 
          break;

          // Desarrollador
          case 12:
          	this.preguntas = preguntas
          break;

          // Soporte tecnico
          case 13:
          	this.preguntas = preguntas.filter( el=> { return el.idpuesto == idpuesto }) 
          break;
          
          // Supervizor de zona
          case 17:
          	this.preguntas = preguntas.filter( el=> { return el.idpuesto == idpuesto || el.idpuesto == 19 || el.idpuesto == 18 }) 
          break;

          // Ejecutivo Campo
          case 18:
          	this.preguntas = preguntas.filter( el=> { return el.idpuesto == idpuesto }) 
          break;

          // Recepcionista
          case 19:
          	this.preguntas = preguntas.filter( el=> { return el.idpuesto == idpuesto }) 
          break;

          // community manager
          case 25:
            this.preguntas = preguntas.filter( el=> { return el.idpuesto == idpuesto }) 
          break;
          
          // Relaciones publicas, vendedora
          case 28:
          	this.preguntas = preguntas.filter( el=> { return el.idpuesto == idpuesto }) 
          break;

          // Gerente de administración
          case 37:
          	this.preguntas = preguntas.filter( el=> { return el.idpuesto == idpuesto }) 
          break;

          // Gerente Operaciones
          case 40:
          	this.preguntas = preguntas.filter( el=> { return el.idpuesto == idpuesto || el.idpuesto == 10 }) 
          break;
         
          // Director general
          case 43:
          	this.preguntas = preguntas
          break;

          // Nivel yare
          case 45:
          	this.preguntas = preguntas.filter( el=> { return el.idpuesto == idpuesto }) 
          break;

          default:
            
          break;
    		}
    	},

    	validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      validarErrorDirecto ( mensaje ) {
        this.cargar = false 
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
    	},
    }

	};
</script>