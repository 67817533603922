<template>
  <v-container class="fill-height">
    <v-row justify="space-around">
      <v-snackbar v-model="snackbar" :timeout="8000" top color="orange" dark  > {{text}}
        <v-btn color="white" text @click="snackbar = false"> Cerrar </v-btn>
      </v-snackbar>
      <v-col cols="12" v-if="!acceso">
        <main>
          <div class="container">
            <div class="row">
              <div class="col-md-6 align-self-center">
                <div class="containerq">
                  <div class="coffee-header">
                    <div class="coffee-header__buttons coffee-header__button-one"></div>
                    <div class="coffee-header__buttons coffee-header__button-two"></div>
                    <div class="coffee-header__display"></div>
                    <div class="coffee-header__details"></div>
                  </div>
                  <div class="coffee-medium">
                    <div class="coffe-medium__exit"></div>
                    <div class="coffee-medium__arm"></div>
                    <div class="coffee-medium__liquid"></div>
                    <div class="coffee-medium__smoke coffee-medium__smoke-one"></div>
                    <div class="coffee-medium__smoke coffee-medium__smoke-two"></div>
                    <div class="coffee-medium__smoke coffee-medium__smoke-three"></div>
                    <div class="coffee-medium__smoke coffee-medium__smoke-for"></div>
                    <div class="coffee-medium__cup"></div>
                  </div>
                  <div class="coffee-footer"></div>
                </div>

              </div>

              <div class="col-md-5 align-self-center">
                <!-- Iniciar sesión -->
                <v-card class="elevation-12 login-card">
                  <v-card-title class="justify-center">
                    <v-img src="@/assets/logo.png"></v-img>
                  </v-card-title>
                  <v-card-text class="black--text">
                    <v-form @submit.prevent="login">

                      <label>Usuario</label>
                      <v-text-field
                        v-model="email"
                        label="usuario@schoolofenglish.com"
                        filled
                        dense
                        required
                        color="primary"
                        append-icon="mdi-account"
                        single-line
                      ></v-text-field>

                      <label>Password</label>
                      <v-text-field
                        v-model="password"
                        label="*******"
                        type="password"
                        filled
                        dense
                        required
                        color="primary"
                        single-line
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        @keyup.enter="validate()"
                      ></v-text-field>

                      <v-btn
                        type="submit"
                        block
                        color="primary"
                        class="mt-4 btn-login"
                        large
                        tile
                        @click="validate()"
                      >
                        Iniciar sesión
                      </v-btn>
                    </v-form>
                  </v-card-text>
                </v-card>
              </div>

            </div>
          </div>
        </main>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>


<script>
  import axios from 'axios';
  // import router from '@/router'
  import {mapActions , mapGetters} from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import router from '@/router'

  import validarErrores        from '@/mixins/validarErrores'


  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data(){
      return{
        acceso: false,
        cargo: "",
        datos_usuario: {},

        snackbar: false,
        y: 'top',
        x: null,
        mode: '',
        timeout: 8000,
        text: '',

        show1: false,
        error: '',

        // Alertas
        parametros:{
          dialogError: false,
          mensaje: '',
          color: ''
        },

        respuestaAlerta:false,
        loader: true,
        cargar: false,


        acceso: false,
        nombre_completo: "",
        cargo: "",
        datos_usuario: {},

        show1: false,
        error: '',

        password: '',
        email:'',

        value:'',

        valid: true,
        rules: {
          required: value => !!value || 'Requerido.'
        },
        showPassword: false,

      }
    },

    computed:{
      ...mapGetters('login',['getdatosUsuario']),
    },

    async created(){
      await this.initialize()
    },

    
    methods:{
      ...mapActions('login',['validarUser','guardarInfo','GetInfoUser','salirLogin']),
      ...mapActions('login',['GetInfoUser']),

      initialize() {
        // Aquí se envían los datos del usuario para saber que perfil tiene
        this.$http.get('usuarios.id/' + this.$route.params.id).then((response) => {

          console.log( response.data )

          if(response.data.idusuario){
            this.datos_usuario = response.data;
            this.$http.get('usuarios.all.capa/' + this.datos_usuario.iderp).then((response) => {
              if(response.data.length > 0){
                this.$http.get("tickets.usuarios.getCargo/" + this.$route.params.id)
                .then((responseR) => {
                  this.cargo = responseR.data;
                  if (this.cargo.cargo == "Sin cargo, no permitir el acceso") {
                    this.acceso = true
                    this.datos_usuario.cargo = 'Normal'
                    this.datos_usuario.permiso = {area:'Sin asignar'}
                    this.datos_usuario.nombre_completo  = response.data[0].nombre_completo
                    this.datos_usuario.nombre_usuario   = response.data[0].nombre_usuario
                    this.datos_usuario.apellido_usuario = response.data[0].apellido_usuario
                    this.datos_usuario.id_plantel       = response.data[0].id_plantel
                    this.datos_usuario.plantel          = response.data[0].plantel  
                    this.datos_usuario.email            = response.data[0].email  
                    this.datos_usuario.whatsappservidor = response.data[0].whatsappservidor  
                    this.datos_usuario.url_servidor     = response.data[0].url_servidor  
                    this.datos_usuario.id_trabajador    = response.data[0].id_trabajador  

                    this.$router.push({name: 'dashboard'})
                    this.guardarInfo(this.datos_usuario)
                    return;
                  } else {
                    this.datos_usuario.cargo   = this.cargo.cargo
                    this.datos_usuario.permiso = this.cargo.permiso

                    this.datos_usuario.nombre_completo  = response.data[0].nombre_completo
                    this.datos_usuario.nombre_usuario   = response.data[0].nombre_usuario
                    this.datos_usuario.apellido_usuario = response.data[0].apellido_usuario
                    this.datos_usuario.id_plantel       = response.data[0].id_plantel
                    this.datos_usuario.plantel          = response.data[0].plantel  
                    this.datos_usuario.email            = response.data[0].email  
                    this.datos_usuario.whatsappservidor = response.data[0].whatsappservidor  
                    this.datos_usuario.url_servidor     = response.data[0].url_servidor  
                    this.datos_usuario.id_trabajador    = response.data[0].id_trabajador  
                    this.$router.push({name: 'dashboard'})
                    this.guardarInfo(this.datos_usuario)
                  }
                })
                .catch((error) => {
                  this.salirLogin()
                });

              }else{
                this.salirLogin()
              }
              // Guardamos los datos
            }).catch((error) => {
              this.salirLogin()
            });
          }else{
            this.salirLogin()
          }
        }).catch((error) => {
          this.salirLogin()
        });
      },

      school (){
        window.open("https://schoolofenglishadmin.com/");
      },

      validate () {

        // eduardo.hdz@inbi.mx

        if( this.email && this.password ){
          this.cargar = true

          var usuario = { email : this.email, password: this.password}
          this.$http.post('usuarios.data',usuario).then((response) => {

            this.guardarInfo( response.data[0] )
            this.cargar = false
            this.acceso = true
            this.$router.push({name: 'dashboard'})
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }

      }
 
    }
  }
</script>

<style scoped>
  

  .login-card {
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 15px;
  }

  .login-card .headline {
    color: #2c3e50;
  }

  .login-card .caption {
    color: #888;
  }

  .login-card a {
    color: #1565c0;
  }

  .login-card a:hover {
    text-decoration: none;
  }

  .containerq {
    width: 40vh;
    height: 280px;
    position: absolute;
    top: calc(50% - 140px);
  }

  .coffee-header {
    width: 100%;
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ddcfcc;
    border-radius: 10px;
  }
  .coffee-header__buttons {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 25px;
    background-color: #282323;
    border-radius: 50%;
  }
  .coffee-header__buttons::after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    bottom: -8px;
    left: calc(50% - 4px);
    background-color: #615e5e;
  }
  .coffee-header__button-one {
    left: 15px;
  }
  .coffee-header__button-two {
    left: 50px;
  }
  .coffee-header__display {
    width: 50px;
    height: 50px;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    border-radius: 50%;
    background-color: #9acfc5;
    border: 5px solid #43beae;
    box-sizing: border-box;
  }
  .coffee-header__details {
    width: 8px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #9b9091;
    box-shadow: -12px 0 0 #9b9091, -24px 0 0 #9b9091;
  }
  .coffee-medium {
    width: 90%;
    height: 160px;
    position: absolute;
    top: 80px;
    left: calc(50% - 45%);
    background-color: #bcb0af;
  }
  .coffee-medium:before {
    content: "";
    width: 90%;
    height: 100px;
    background-color: #776f6e;
    position: absolute;
    bottom: 0;
    left: calc(50% - 45%);
    border-radius: 20px 20px 0 0;
  }
  .coffe-medium__exit {
    width: 60px;
    height: 20px;
    position: absolute;
    top: 0;
    left: calc(50% - 30px);
    background-color: #231f20;
  }
  .coffe-medium__exit::before {
    content: "";
    width: 50px;
    height: 20px;
    border-radius: 0 0 50% 50%;
    position: absolute;
    bottom: -20px;
    left: calc(50% - 25px);
    background-color: #231f20;
  }
  .coffe-medium__exit::after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: -30px;
    left: calc(50% - 5px);
    background-color: #231f20;
  }
  .coffee-medium__arm {
    width: 70px;
    height: 20px;
    position: absolute;
    top: 15px;
    right: 25px;
    background-color: #231f20;
  }
  .coffee-medium__arm::before {
    content: "";
    width: 15px;
    height: 5px;
    position: absolute;
    top: 7px;
    left: -15px;
    background-color: #9e9495;
  }
  .coffee-medium__cup {
    width: 80px;
    height: 47px;
    position: absolute;
    bottom: 0;
    left: calc(50% - 40px);
    background-color: #FFF;
    border-radius: 0 0 70px 70px / 0 0 110px 110px;
  }
  .coffee-medium__cup::after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    top: 6px;
    right: -13px;
    border: 5px solid #FFF;
    border-radius: 50%;
  }
  @-webkit-keyframes liquid {
    0% {
      height: 0px;  
      opacity: 1;
    }
    5% {
      height: 0px;  
      opacity: 1;
    }
    20% {
      height: 62px;  
      opacity: 1;
    }
    95% {
      height: 62px;
      opacity: 1;
    }
    100% {
      height: 62px;
      opacity: 0;
    }
  }
  @keyframes liquid {
    0% {
      height: 0px;  
      opacity: 1;
    }
    5% {
      height: 0px;  
      opacity: 1;
    }
    20% {
      height: 62px;  
      opacity: 1;
    }
    95% {
      height: 62px;
      opacity: 1;
    }
    100% {
      height: 62px;
      opacity: 0;
    }
  }
  .coffee-medium__liquid {
    width: 6px;
    height: 63px;
    opacity: 0;
    position: absolute;
    top: 50px;
    left: calc(50% - 3px);
    background-color: #74372b;
    -webkit-animation: liquid 4s 4s linear infinite;
            animation: liquid 4s 4s linear infinite;
  }
  .coffee-medium__smoke {
    width: 8px;
    height: 20px;
    position: absolute;  
    border-radius: 5px;
    background-color: #b3aeae;
  }
  @-webkit-keyframes smokeOne {
    0% {
      bottom: 20px;
      opacity: 0;
    }
    40% {
      bottom: 50px;
      opacity: .5;
    }
    80% {
      bottom: 80px;
      opacity: .3;
    }
    100% {
      bottom: 80px;
      opacity: 0;
    }
  }
  @keyframes smokeOne {
    0% {
      bottom: 20px;
      opacity: 0;
    }
    40% {
      bottom: 50px;
      opacity: .5;
    }
    80% {
      bottom: 80px;
      opacity: .3;
    }
    100% {
      bottom: 80px;
      opacity: 0;
    }
  }
  @-webkit-keyframes smokeTwo {
    0% {
      bottom: 40px;
      opacity: 0;
    }
    40% {
      bottom: 70px;
      opacity: .5;
    }
    80% {
      bottom: 80px;
      opacity: .3;
    }
    100% {
      bottom: 80px;
      opacity: 0;
    }
  }
  @keyframes smokeTwo {
    0% {
      bottom: 40px;
      opacity: 0;
    }
    40% {
      bottom: 70px;
      opacity: .5;
    }
    80% {
      bottom: 80px;
      opacity: .3;
    }
    100% {
      bottom: 80px;
      opacity: 0;
    }
  }
  .coffee-medium__smoke-one {
    opacity: 0;
    bottom: 50px;
    left: 102px;
    -webkit-animation: smokeOne 3s 4s linear infinite;
            animation: smokeOne 3s 4s linear infinite;
  }
  .coffee-medium__smoke-two {
    opacity: 0;
    bottom: 70px;
    left: 118px;
    -webkit-animation: smokeTwo 3s 5s linear infinite;
            animation: smokeTwo 3s 5s linear infinite;
  }
  .coffee-medium__smoke-three {
    opacity: 0;
    bottom: 65px;
    right: 118px;
    -webkit-animation: smokeTwo 3s 6s linear infinite;
            animation: smokeTwo 3s 6s linear infinite;
  }
  .coffee-medium__smoke-for {
    opacity: 0;
    bottom: 50px;
    right: 102px;
    -webkit-animation: smokeOne 3s 5s linear infinite;
            animation: smokeOne 3s 5s linear infinite;
  }
  .coffee-footer {
    width: 95%;
    height: 15px;
    position: absolute;
    bottom: 25px;
    left: calc(50% - 47.5%);
    background-color: #41bdad;
    border-radius: 10px;
  }
  .coffee-footer::after {
    content: "";
    width: 106%;
    height: 26px;
    position: absolute;
    bottom: -25px;
    left: -8px;
    background-color: #000;
  }
</style>

  