<template>
  <v-container fluid class="mt-4 ml-2">
    <v-row>
      <v-col cols="12">
	      <v-card>
	      	<v-card-title primary-title>
		        <v-toolbar-title class="grey--text">Calificación de clase</v-toolbar-title>
		        <v-divider
		          class="mx-4"
		          inset
		          vertical
		        ></v-divider>
		        <v-spacer></v-spacer>
            <v-btn color="success" @click="exportarExcel()" class="mr-2 text-capitalize"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
		        <v-btn color="primary" @click="getGrupos()" class="mr-2 text-capitalize"><v-icon left>mdi-magnify</v-icon> Consultar</v-btn>
	      	</v-card-title>

	        <v-card-text>

            <!-- Menu de filtros -->
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  v-model="dia"
                  dense
                  label="Fecha de inicio"
                  filled
                  hide-details
                  clearable
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  v-model="dia2"
                  dense
                  label="Fecha final"
                  filled
                  hide-details
                  clearable
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" lg="4" v-if="fast">
                <v-card>
                  <v-card-title primary-title>
                    FAST
                  </v-card-title>
                  <v-card-text>
                  	<v-list>
                  		<v-subheader>Datos generales</v-subheader>
	                  	<v-list-item>
								        <v-list-item-content>
								          <v-list-item-title>Promedio</v-list-item-title>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ fast.promedio ? fast.promedio.toFixed(2) : 0 }}</strong>
								        </v-list-item-action>
								      </v-list-item>

								      <v-list-item>
								        <v-list-item-content>
								          <v-list-item-title>Alumnos que calificaron</v-list-item-title>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ fast.cantidad }}</strong>
								        </v-list-item-action>
								      </v-list-item>

								      <v-list-item>
								        <v-list-item-content>
								          <v-list-item-title>Alumnos totales</v-list-item-title>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ fast.cantReal }}</strong>
								        </v-list-item-action>
								      </v-list-item>
								      
								    </v-list>

								    <v-divider></v-divider>

								    <v-list>
                  		<v-subheader>Datos de las clases</v-subheader>
	                  	<v-list-item @click="verClases( 1, 2)">
								        <v-list-item-content >
								          <v-list-item-title>Total de clases</v-list-item-title>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ fast.cantClases }}</strong>
								        </v-list-item-action>
								      </v-list-item>

								      <v-list-item @click="verClases( 2, 2)">
								        <v-list-item-content>
								          <v-list-item-title>Clases buenas</v-list-item-title>
								          <v-list-item-subtitle> > 80% alumnos calificaron</v-list-item-subtitle>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ fast.clases_calidad }}</strong>
								        </v-list-item-action>
								      </v-list-item>

								      <v-list-item @click="verClases( 3, 2)">
								        <v-list-item-content>
								          <v-list-item-title>Clases malas</v-list-item-title>
								          <v-list-item-subtitle> < 80% alumnos calificaron</v-list-item-subtitle>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ fast.clases_no_calidad }}</strong>
								        </v-list-item-action>
								      </v-list-item>

								      <v-list-item @click="verClases( 4, 2)">
								        <v-list-item-content >
								          <v-list-item-title>Clases evaluadas</v-list-item-title>
								          <v-list-item-subtitle>Al menos un alumno califico</v-list-item-subtitle>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ fast.cantClasesEvaluadas }}</strong>
								        </v-list-item-action>
								      </v-list-item>

								      <v-list-item @click="verClases( 5, 2)">
								        <v-list-item-content>
								          <v-list-item-title>Clases no evaluadas</v-list-item-title>
								          <v-list-item-subtitle> 0 alumnos calificaron </v-list-item-subtitle>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ fast.clases_sin_calidad }}</strong>
								        </v-list-item-action>
								      </v-list-item>
								    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" lg="4" v-if="inbi">
                <v-card>
                  <v-card-title primary-title>
                    INBI
                  </v-card-title>
                  <v-card-text>
                  	<v-list>
                  		<v-subheader>Datos generales</v-subheader>
	                  	<v-list-item>
								        <v-list-item-content>
								          <v-list-item-title>Promedio</v-list-item-title>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ inbi.promedio ? inbi.promedio.toFixed(2) : 0 }}</strong>
								        </v-list-item-action>
								      </v-list-item>

								      <v-list-item>
								        <v-list-item-content>
								          <v-list-item-title>Alumnos que calificaron</v-list-item-title>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ inbi.cantidad }}</strong>
								        </v-list-item-action>
								      </v-list-item>

								      <v-list-item>
								        <v-list-item-content>
								          <v-list-item-title>Alumnos totales</v-list-item-title>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ inbi.cantReal }}</strong>
								        </v-list-item-action>
								      </v-list-item>
								      
								    </v-list>

								    <v-divider></v-divider>
								    
								    <v-list>
                  		<v-subheader>Datos de las clases</v-subheader>
	                  	<v-list-item>
								        <v-list-item-content>
								          <v-list-item-title>Total de clases</v-list-item-title>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ inbi.cantClases }}</strong>
								        </v-list-item-action>
								      </v-list-item>

								      <v-list-item>
								        <v-list-item-content>
								          <v-list-item-title>Clases buenas</v-list-item-title>
								          <v-list-item-subtitle> > 80% alumnos calificaron</v-list-item-subtitle>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ inbi.clases_calidad }}</strong>
								        </v-list-item-action>
								      </v-list-item>

								      <v-list-item>
								        <v-list-item-content>
								          <v-list-item-title>Clases malas</v-list-item-title>
								          <v-list-item-subtitle> < 80% alumnos calificaron</v-list-item-subtitle>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ inbi.clases_no_calidad }}</strong>
								        </v-list-item-action>
								      </v-list-item>

								      <v-list-item>
								        <v-list-item-content>
								          <v-list-item-title>Clases evaluadas</v-list-item-title>
								          <v-list-item-subtitle>Al menos un alumno califico</v-list-item-subtitle>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ inbi.cantClasesEvaluadas }}</strong>
								        </v-list-item-action>
								      </v-list-item>

								      <v-list-item>
								        <v-list-item-content>
								          <v-list-item-title>Clases no evaluadas</v-list-item-title>
								          <v-list-item-subtitle>0 alumnos calificaron </v-list-item-subtitle>
								        </v-list-item-content>
								        <v-list-item-action>
								          <strong>{{ inbi.clases_sin_calidad }}</strong>
								        </v-list-item-action>
								      </v-list-item>
								    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

	        	<!-- Menu superior de tabs -->

		        <v-tabs
			        dense
			        class="mt-4"
			        v-model="tab"
			        background-color="white"
			      >
			        <v-tabs-slider color="primary"></v-tabs-slider>


			        <v-tab
			          v-for="(item,i) in items"
			          :key="i"
			        >
			          <v-icon v-text="item.icon" left></v-icon>{{ item.name }}
			        </v-tab>


			        <!-- Apartado de las secciones de los tabs -->
			        <v-tabs-items v-model="tab">
			          <v-tab-item>
                  <v-row class="pt-4" justify="end">
                    <v-col cols="12" md="8" lg="6">
                      <v-text-field
                        v-model="searchFast"
                        label="Buscar"
                        append-icon="mdi-magnify"
                        filled
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>

  			            <v-col cols="12" lg="12">
  			              <v-card class="elevation-0">
  			                <v-card-text>	
  			                	<v-data-table
  											    dense
  											    :headers="headers"
  											    :items="gruposFast"
  											    item-key="id"
  											    class="elevation-1"
                            :search="searchFast"
  											  >
                            <template v-slot:item.teachers="{ item }">
                              {{ item.teacher1 }}<br/>
                              {{ item.teacher2 }}
                            </template>
                          </v-data-table>
  			                </v-card-text>	
  			              </v-card>
  			            </v-col>
                  </v-row>
			          </v-tab-item>
			        

			          <!-- horarios de inbi -->
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">
			                <v-card-title primary-title class="pt-0 ">
                        <v-text-field
                          v-model="searchInbi"
                          label="Buscar"
                          append-icon="mdi-magnify"
                          filled
                          dense
                          hide-details
                        ></v-text-field>
                      </v-card-title>

			                <v-card-text >
			                  <v-data-table
											    dense
											    :headers="headers"
											    :items="gruposInbi"
											    item-key="id"
											    class="elevation-1"
                          :search="searchInbi"
											  >
                          <template v-slot:item.teachers="{ item }">
                            {{ item.teacher1 }}<br/>
                            {{ item.teacher2 }}
                          </template>
                        </v-data-table>
			                </v-card-text>	
			              </v-card>
			            </v-col>
			          </v-tab-item>


			          <!-- Resultados -->
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">

			              	
			              </v-card>
			            </v-col>
			          </v-tab-item>

			        </v-tabs-items>
			      </v-tabs>
	        </v-card-text>
	      </v-card>		
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta    v-if="respuestaAlerta" :parametros="parametros"/>
    <carga     v-if="overlay"/>

  </v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
  import XLSX from 'xlsx'

  // Componentes
  import Alerta    from '@/components/alertas/Alerta.vue'
  import carga     from '@/components/alertas/carga.vue';

  export default {
    components:{
    	Alerta,
      carga
    },

    data: () => ({

    	tab: {name:'FAST'   , icon:'mdi-school' }, 

      items: [
        {name:'FAST'    , icon:'mdi-school', promedio: 0 }, 
        {name:'INBI'    , icon:'mdi-school', promedio: 0 }, 
      ],

      headers: [
        { text: 'id'             , value: 'id_grupo' },
        { text: 'Grupo'          , value: 'grupo' },
        { text: 'Teachers'       , value: 'teachers' },
        { text: 'Cant. Alumnos'  , value: 'cant_alumnos' },
        { text: 'Cant. Calif'    , value: 'cant_calif' },
        { text: 'Promedio'       , value: 'promedio' },
      ],

      dialogError: false,
			dialogSuccess:false,
			error:'',
			successMensaje:'',

      ciclos:  			[],
			cicloInf: 		null,

      searchFast:'',
      searchInbi:'',

      gruposFast:[],
			gruposInbi:[],
      clasesFast:0,
      clasesInbi:0,

      fast: null,
      inbi: null,
      
      dia:null,
      dia2:null,

			respuestaAlerta: false,
	    overlay: false,
			parametros:{
	    	dialogError: false,
	    	mensaje: '',
	    	color: ''
	   	},
    }),

    created () {
      this.initialize()
    },

    methods: {
    	initialize () {
      	this.overlay   = true
        this.ciclos    = []
        this.planteles = []

        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.overlay = false
        }).catch( error => {
      		this.validarErroresBack ( error )
      	}).finally( () => { this.overlay = false })
      },

      getGrupos () {
        if(!this.cicloInf && !this.dia){
          this.openDialogError('Por favor, ingresa un ciclo o una fecha de inicio o un rango de fechas')
          return 
        }

        if(this.cicloInf && !this.dia){
          this.openDialogError('Por favor, ingresa una fecha de inicio')
          return
        }

        // consultar por ciclo y día
        if(this.cicloInf && this.dia){
          this.getClases(1)
        }
      },

      getClases (item) {
        // Por ciclo y dia
        let payload = {
          cicloInbi:   this.cicloInf.id_ciclo,
          cicloFast:   this.cicloInf.id_ciclo_relacionado,
          fecha:       this.dia,
          fecha2:      this.dia
        }

      	if( this.dia2 && this.dia2 != this.dia ){
      		payload.fecha2 = this.dia2
      	}

        this.overlay = true
        this.clases  = []

        this.$http.post(`lms.catalogos.grupos.calificacion.desglose`, payload).then(response=>{
          // Guardamos la respuesta
          this.clasesFast   = response.data.clases_calidad[0]
          console.log( this.clasesFast )
          this.clasesInbi   = response.data.clases_calidad[1]
          this.headers = response.data.headers

          this.fast = response.data.promedioFast

          this.inbi = response.data.promedioInbi
          // Quitamos el loader
          this.overlay = false
        }).catch( error => {
      		this.validarErroresBack ( error )
      	}).finally( () => { this.overlay = false })
      },

      verClases( tipo ){
      	console.log( tipo )
      	switch( tipo ){

      		case 1:
      			this.gruposFast = this.clasesFast.todas
      			this.gruposInbi = this.clasesInbi.todas
      			console.log( this.gruposFast )
      		break;

      		case 2:
      			this.gruposFast = this.clasesFast.clases_buenas
      			this.gruposInbi = this.clasesInbi.clases_buenas
      		break;

      		case 3:
      			this.gruposFast = this.clasesFast.clases_malas
      			this.gruposInbi = this.clasesInbi.clases_malas
      		break;

      		case 4:
      			this.gruposFast = this.clasesFast.clases_evaluadas
      			this.gruposInbi = this.clasesInbi.clases_evaluadas
      		break;

      		case 5:
      			this.gruposFast = this.clasesFast.clases_no_evaluadas
      			this.gruposInbi = this.clasesInbi.clases_no_evaluadas
      		break;
      	}
      },

      openDialogError ( mensaje ) {
      	this.parametros.mensaje      = mensaje
  			this.parametros.dialogError  = true 
  			this.parametros.color        = '#FF2C2C' 
  			this.respuestaAlerta         = true 
      },

      openDialogSuccess ( mensaje ){
      	this.successMensaje = mensaje
      	this.dialogSuccess  = true
      },

      exportarExcel(){
        let dataFast = XLSX.utils.json_to_sheet(this.gruposFast)
        let dataInbi = XLSX.utils.json_to_sheet(this.gruposInbi)
        const workbook = XLSX.utils.book_new()
        const filename = 'calificacion_clase'
        XLSX.utils.book_append_sheet(workbook, dataFast, 'FAST')
        XLSX.utils.book_append_sheet(workbook, dataInbi, 'INBI')

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      validarErroresBack( error ){
      	this.overlay                    = false
    		if( error.status == 400 || error.status == 500){
    			this.parametros.mensaje      = error.body.message
    			this.parametros.dialogError  = true 
    			this.parametros.color        = '#FF2C2C' 
    			this.respuestaAlerta         = true 
    		}
      }
    },

    computed:{
    	...mapGetters('login',['getdatosUsuario'])
    }
  }
</script>

<style>
	.v-snack__content{
		padding: 0px !important;
	}
</style>