<template>
  <v-container fluid class="mt-5 pl-6 pr-6">
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-body-1">Kpi General por Montos</span>
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportarExcelKPi()"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn 
              color="primary" 
              @click="riFast()" 
              small
              tile
            >
              <v-icon small left>mdi-refresh</v-icon>
              Consultar
            </v-btn>
          </v-card-title>

          <v-card-text>

            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  v-model="cicloSup"
                  :items="ciclos"
                  label="Selecciona siguiente ciclo"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                  readonly
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headers"
              :items="resultado.riPlantelesInbi"
              sort-by="calories"
              class="elevation-0 mt-4"
              hide-default-footer
              :items-per-page="25"
              dense
              mobile-breakpoint="200"
            >

              <template v-slot:item.diferencia="{ item }" align="center" class="text-center">
                <v-chip color="red" dark small @click="verIrregulares( item.alumnosIrregualres )" v-if="item.diferencia < 0 ">{{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.diferencia ) }}</v-chip>
                <v-chip  color="green" dark small v-else>{{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.diferencia ) }}</v-chip>
              </template>

              <template v-slot:item.totalAlumnosActuales="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosActuales ) }}
              </template>

              <template v-slot:item.noRI="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.noRI ) }}
              </template>

              <template v-slot:item.certificacion="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.certificacion ) }}
              </template>

              <template v-slot:item.reinscribibles="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.reinscribibles ) }}
              </template>

              <template v-slot:item.totalAlumnosSiguientes="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosSiguientes ) }}
              </template>

              <template v-slot:item.resagados="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.resagados ) }}
              </template>

              <template v-slot:item.niActual="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.niActual ) }}
              </template>

              <template v-slot:item.totalMonto="{ item }" align="center" class="text-center">
                <v-chip v-if="item.cantPagoDoble > 1" small color="red" dark @click="verIrregulares(item.alumnosPagoDoble)">
                  {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalMonto ) }}
                </v-chip>

                <span v-else>
                  {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalMonto ) }}
                </span>
              </template>

              <template v-slot:item.totalAlumnosAyer="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosAyer ) }}
              </template>

              <template v-slot:item.faltantes="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.faltantes ) }}
              </template>

              <template v-slot:item.porcentajeFaltantes="{ item }" align="center" class="text-center">
                {{ item.porcentajeFaltantes }} %
              </template>

            </v-data-table>

            <v-divider class="mb-3"></v-divider>

            <v-data-table
              :headers="headers"
              :items="resultado.riPlantelesFast"
              sort-by="calories"
              class="elevation-0 mt-4"
              hide-default-footer
              :items-per-page="25"
              dense
              mobile-breakpoint="200"
            >

              <template v-slot:item.diferencia="{ item }" align="center" class="text-center">
                <v-chip color="red" dark small @click="verIrregulares( item.alumnosIrregualres )" v-if="item.diferencia < 0 ">{{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.diferencia ) }}</v-chip>
                <v-chip  color="green" dark small v-else>{{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.diferencia ) }}</v-chip>
              </template>

              <template v-slot:item.totalAlumnosActuales="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosActuales ) }}
              </template>

              <template v-slot:item.noRI="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.noRI ) }}
              </template>

              <template v-slot:item.certificacion="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.certificacion ) }}
              </template>

              <template v-slot:item.reinscribibles="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.reinscribibles ) }}
              </template>

              <template v-slot:item.totalAlumnosSiguientes="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosSiguientes ) }}
              </template>

              <template v-slot:item.resagados="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.resagados ) }}
              </template>

              <template v-slot:item.niActual="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.niActual ) }}
              </template>

              <template v-slot:item.totalMonto="{ item }" align="center" class="text-center">
                <v-chip v-if="item.cantPagoDoble > 1" small color="red" dark @click="verIrregulares(item.alumnosPagoDoble)">
                  {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalMonto ) }}
                </v-chip>

                <span v-else>
                  {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalMonto ) }}
                </span>
              </template>

              <template v-slot:item.totalAlumnosAyer="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosAyer ) }}
              </template>

              <template v-slot:item.faltantes="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.faltantes ) }}
              </template>

              <template v-slot:item.porcentajeFaltantes="{ item }" align="center" class="text-center">
                {{ item.porcentajeFaltantes }} %
              </template>

            </v-data-table>

            <v-data-table
              :headers="headers"
              :items="resultado.totales"
              sort-by="calories"
              class="elevation-0 mt-4"
              hide-default-footer
              :items-per-page="20"
              dense
              mobile-breakpoint="200"
            >

              <template v-slot:item.diferencia="{ item }" align="center" class="text-center">
                <v-chip color="red" dark small @click="verIrregulares( item.alumnosIrregualres )" v-if="item.diferencia < 0 ">{{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.diferencia ) }}</v-chip>
                <v-chip  color="green" dark small v-else>{{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.diferencia ) }}</v-chip>
              </template>

              <template v-slot:item.totalAlumnosActuales="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosActuales ) }}
              </template>

              <template v-slot:item.noRI="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.noRI ) }}
              </template>

              <template v-slot:item.certificacion="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.certificacion ) }}
              </template>

              <template v-slot:item.reinscribibles="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.reinscribibles ) }}
              </template>

              <template v-slot:item.totalAlumnosSiguientes="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosSiguientes ) }}
              </template>

              <template v-slot:item.resagados="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.resagados ) }}
              </template>

              <template v-slot:item.niActual="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.niActual ) }}
              </template>

              <template v-slot:item.totalMonto="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalMonto ) }}
              </template>

              <template v-slot:item.totalAlumnosAyer="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.totalAlumnosAyer ) }}
              </template>

              <template v-slot:item.faltantes="{ item }" align="center" class="text-center">
                {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.faltantes ) }}
              </template>

              <template v-slot:item.porcentajeFaltantes="{ item }" align="center" class="text-center">
                {{ item.porcentajeFaltantes }} %
              </template>

            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <v-dialog
      v-model="dialogIrregulares"
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h5">
          Alumnos pagos doblres
        </v-card-title>
        <v-card-text>
          <div>Alumnos que cuentan con 2 pagos en el mismo ciclo</div>
          <v-data-table
            :headers="headersIrregulares"
            :items="alumnos"
            class="elevation-0 mt-4"
            dense
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            rounded
            dark
            small
            @click="dialogIrregulares = false, alumnos = []"
          >
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'

  import XLSX from 'xlsx'
  // Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores   from '@/mixins/validarErrores'

  const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});


  export default {
    components:{
      Alerta,
      carga,
    },

    mixins:[ validarErrores ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      snackbar:false,
      headers: [
        { text: 'Plantel'          , value: 'plantel' },
        { text: 'A. Ciclo Actual'  , value: 'totalAlumnosActuales' },
        { text: 'NO RI'            , value: 'noRI' },
        { text: 'Certificacion'    , value: 'certificacion' },
        { text: 'Reinscribibles'   , value: 'reinscribibles' },
        { text: 'Acumulado RI'     , value: 'totalAlumnosSiguientes' },
        { text: 'Rezagados'        , value: 'resagados' },
        { text: 'Acumulado NI'     , value: 'niActual' },
        { text: 'TOTAL'            , value: 'totalMonto' },
        { text: 'Diferencia'       , value: 'diferencia' },
        { text: 'Avance'           , value: 'totalAlumnosAyer' },
        { text: 'Faltantes'        , value: 'faltantes', class: 'columna2' },
        { text: '%Avance'          , value: 'porcentajeFaltantes' },
      ],

      headersIrregulares: [
        { text: 'id_alumno'        , value: 'id_alumno' },
        { text: 'Nombre'           , value: 'nombre'    },
        { text: 'Grupo'            , value: 'grupo'     },
      ],

      desserts: [],
      ciclos:[],


      ciclo:null,
      cicloSup:null,
      cicloInf: null,

      planteles:[],
      plantel:null,
      cicloAnterior:[],
      cicloSiguiente:[],
      resultado:[],
      total:0,
      faltantes:0,
      siguientes:0,
      porcentaje:0,
      idcicloERPinf:0,
      idcicloERPSup:0,
      cicloSiguienteAvance:[],
      resultadoFinal:[],

      headersNiSucursal: [
        { text: 'Sucursal'     , value: 'plantel' },
        { text: 'Acumulado'    , value: 'acumulado' },
        { text: 'Avance'       , value: 'avance' },
      ],
      fast:{},
      inbi:{},
			totalFast: 0,
			dataTablaFastUno:[],
			totalInbi: 0,
			dataTablaInbiUno:[],
			dialogCarga: false,

			headersVend: [
        { text: 'Vendedora'    , value: 'nombre_completo' },
        { text: 'Acumulado'       , value: 'total' },
        { text: 'Avance'        , value: 'avance' },
      ],

      dataTablaFastUnoVend:[],
			dataTablaInbiUnoVend:[],
			avance:0,
			acumulado:0,
			avanceFinal:0,
			acumuladoFinal:0,
      alumnos:[],
      dialogIrregulares: false,
      dialogBecados:false
    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    watch: {
      ciclo () {
        if(this.ciclo){
          // Buscar la pisición en la que se encuentra el ciclo
          const positionCiclo = this.ciclos.indexOf(this.ciclo)

          // Validar que sea uno valido
          if(positionCiclo == 0 ){
            return this.validarErrorDirecto('El ciclo anterior esta cerrado, favor de abrir el ciclo anterior')
          }

          // Guardar un ciclo después
          this.cicloSup = this.ciclos[ positionCiclo + 1 ]

          // Guardar un ciclo anteriro
          this.cicloInf = this.ciclos[ positionCiclo - 1 ]

          // Consultar la información
          this.riFast()
        }
      }
    },

    async created () {
      this.cargar = true
      await this.getCiclosActivos( )
      this.cargar = false
    },

    methods: {
      getCiclosActivos( ){
        this.$http.get('kpi.ciclos.all').then(response=>{
          this.ciclos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      riFast () {
        this.cargar    = true
        this.resultado = []
        
        const payload = {
          cicloPre: this.cicloInf,
          cicloAct: this.ciclo,
          cicloSig: this.cicloSup,
          idpuesto: this.getdatosUsuario.idpuesto,
          iderp   : this.getdatosUsuario.iderp,
        }
        
        return this.$http.post('kpi.cantactual.montos', payload ).then(response=>{
          this.resultado = response.data
          console.log("kaaaaaa", this.resultado)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      verIrregulares( alumnos ){
        this.alumnos = alumnos
        this.dialogIrregulares = true
      },

      verBecados( alumnos ){
        this.alumnos = alumnos
        this.dialogBecados = true
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportarExcelKPi(){
    
        let dataAnt = XLSX.utils.json_to_sheet(this.resultado.riPlantelesInbi)
        let dataACt = XLSX.utils.json_to_sheet(this.resultado.riPlantelesFast)


        const workbook = XLSX.utils.book_new()
        const filename = 'KPI'
        XLSX.utils.book_append_sheet(workbook, dataAnt, 'KPI General INBI')
        XLSX.utils.book_append_sheet(workbook, dataACt, 'KPI General FAST')

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });
      },

      
    },
  }
</script>
<style>

</style>