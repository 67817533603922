<!--VUE-->
<template>
  <v-container class="mt-4" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="font-weight-semibold">Organigrama</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" rounded small dark @click="getPuestos( )">Actualizar</v-btn>
          </v-card-title>
          
          <v-card-text>

            <v-row align="center">
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-card color="green darken-4" @click="mostrardatos(puestos)">
                  <v-card-title primary-title class="py-1">
                    <div class="white--text">
                      # Puestos: {{ puestos.length }}
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" md="3" lg="3">
                <v-card color="grey" @click="dialogRequerida = true">
                  <v-card-title primary-title class="py-1">
                    <div class="white--text">
                      # Requeridos: {{ requerida.length  + vacantes.length }}
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" md="3" lg="3" >
                <v-card color="green lighten-1" @click="dialogPersonas = true">
                  <v-card-title primary-title class="py-1">
                    <div class="white--text">
                      # Activos: {{ personas.length }}
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" md="3" lg="3">
                <v-card color="#FF8017" @click="dialogVacantes = true">
                  <v-card-title primary-title class="py-1">
                    <div class="white--text">
                      # Vacantes: {{ vacantes.length }}
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- botónes de acción -->
          <v-card-actions>
            <v-btn
              class="mr-2 ml-2"
              dark
              color="green"
              @click="dialog = true"
            >
              Agregar
            </v-btn>

            <v-btn
              class="mr-2"
              dark
              color="red"
              @click="dialogEliminar = true"
            >
              Eliminar
            </v-btn>

            <v-btn
              class="mr-2"
              dark
              color="blue"
              @click="dialogActualizar = true"
            >
              Actualizar
            </v-btn>
          </v-card-actions>

          <v-card-text >
            <v-row>
              <v-col cols="12">
                <div id="tree" :style="`height: ${tamanioPantalla}px`"></div>
              </v-col>
            </v-row>
          </v-card-text>

          <!----------------------Dialog Agregar----------------------->
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Agregar nodo</span>
              </v-card-title>

              <v-card-text>
                <v-autocomplete
                  :items="node"
                  v-model="usuario"
                  label="Selecciona un Jefe"
                  item-value="id"
                  item-text="name"
                  filled
                ></v-autocomplete>
                <v-autocomplete
                  :items="usuarios"
                  v-model="subordinado"
                  label="Selecciona un Subordinado"
                  item-value="id_usuario"
                  item-text="nombre_completo"
                  filled
                  hide-details
                ></v-autocomplete>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="red"
                  dark
                  small
                  rounded
                  @click="(usuario = null), (subordinado = null), (dialog = false)"
                >
                  Cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark rounded small @click="agregarNodo">
                  Agregar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!--------------------------Dialog Eliminar------------------------------>
          <v-dialog v-model="dialogEliminar" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Eliminar</span>
              </v-card-title>

              <v-card-text>
                <v-autocomplete
                  :items="node"
                  v-model="iderpEliminar"
                  label="Selecciona un Subordinado"
                  item-value="iderp"
                  item-text="name"
                  filled
                ></v-autocomplete>
                <v-autocomplete
                  :items="jefesSubordinado"
                  v-model="idSubordinado"
                  label="Selecciona un Jefe"
                  item-value="id"
                  item-text="jefe"
                  filled
                ></v-autocomplete>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="red"
                  dark
                  small
                  rounded
                  @click="(usuario = null),(subordinado = null),(dialogEliminar = false)"
                >
                  Cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark rounded small @click="eliminarNodo">
                  Eliminar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!--------------------------Dialog Actualizar------------------------------>
          <v-dialog v-model="dialogActualizar" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Actualizar</span>
              </v-card-title>

              <v-card-text>
                <v-autocomplete
                  :items="node"
                  v-model="usuario"
                  label="Selecciona un Subordinado"
                  item-value="id"
                  item-text="name"
                  filled
                ></v-autocomplete>
                <v-autocomplete
                  :items="usuarios"
                  v-model="idActualizacion"
                  label="Selecciona la Actualización"
                  item-value="id_usuario"
                  item-text="nombre_completo"
                  filled
                ></v-autocomplete>
              </v-card-text>

              <v-card-actions>

                <v-btn
                  color="red"
                  dark
                  small
                  rounded
                  @click="(usuario = null),(subordinado = null),(dialogActualizar = false)"
                >
                  Cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark rounded small @click="actualizarNodo">
                  Actualizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!--------------------------Dialog Puestos------------------------------>
          <v-dialog
            v-model="dialogPuestos"
            max-width="800px"
            hide-overlay
            persistent
          >
            <v-card>
              <v-card-title primary-title> Puestos </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="headersPuestos"
                  :items="puestos"
                  class="elevation-0"
                  dense
                >
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" dark @click="dialogPuestos = false"
                  >Cancelar</v-btn
                >
              </v-card-actions>
              <v-btn
                color="transparent elevation-0"
                x-small
                absolute
                top
                right
                fab
                class="mt-5 mr-0"
                @click="dialogPuestos = false"
                ><v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card>
          </v-dialog>

          <!--------------------------Dialog Personas ------------------------------>
          <v-dialog
            v-model="dialogPersonas"
            max-width="800px"
            hide-overlay
            persistent
          >
            <v-card>
              <v-card-title primary-title> Personas </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="headersPersonas"
                  :items="personas"
                  class="elevation-0"
                  dense
                >
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" dark @click="dialogPersonas = false"
                  >Cancelar</v-btn
                >
              </v-card-actions>
              <v-btn
                color="transparent elevation-0"
                x-small
                absolute
                top
                right
                fab
                class="mt-5 mr-0"
                @click="dialogPersonas = false"
                ><v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card>
          </v-dialog>

          <!--------------------------Dialog Vacantes ------------------------------>
          <v-dialog
            v-model="dialogVacantes"
            max-width="1200px"
            hide-overlay
            persistent
          >
            <v-card>
              <v-card-title primary-title>
                Vacantes
                <v-spacer></v-spacer>
                <v-btn color="primary" small rounded dark @click="dialogNuevoVacante = true">Agregar</v-btn>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="headersVacantes"
                  :items="vacantes"
                  class="elevation-0"
                  dense
                >
                  <!------------------------------------------------------------------------------------->

                  <template v-slot:item.eliminar="{ item }">
                    <v-btn color="red" text small @click="eliminarVacante( item )"><v-icon>mdi-delete</v-icon></v-btn>
                  </template>

                  <template v-slot:item.fecha_entrega="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.fecha_entrega"
                      large
                      @save="save(props.item)"
                    >
                      {{ props.item.fecha_entrega }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.fecha_entrega"
                          type="date"
                          label="fecha de entrega"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:item.dias_transcurrido="{ item }">
                    {{ item.dias_transcurrido }} días
                  </template>

                  <template v-slot:item.sucursal="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.sucursal"
                      large
                      @save="save(props.item)"
                    >
                      {{ props.item.plantel }}
                      <template v-slot:input>
                        <v-autocomplete
                          v-model="props.item.sucursal"
                          :items="sucursales"
                          label="Plantel"
                          item-value="id_plantel"
                          item-text="plantel"
                        ></v-autocomplete>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:item.horario="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.horario"
                      large
                      @save="save(props.item)"
                    >
                      {{ props.item.horario }}
                      <template v-slot:input>
                        <v-select
                          v-model="props.item.horario"
                          :items="['Día', 'Tarde', 'Noche', 'Completo']"
                          label="Horario"
                        ></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:item.idpuesto="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.idpuesto"
                      large
                      @save="save(props.item)"
                    >
                      {{ props.item.puesto }}
                      <template v-slot:input>
                        <v-autocomplete
                          v-model="props.item.idpuesto"
                          :items="puestos"
                          label="Puesto"
                          item-value="idpuesto"
                          item-text="puesto"
                        ></v-autocomplete>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:item.reclutadora="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.reclutadora"
                      large
                      @save="save(props.item)"
                    >
                      {{ props.item.nombre }}
                      <template v-slot:input>
                        <v-autocomplete
                          v-model="props.item.reclutadora"
                          :items="reclutadoras"
                          label="Reclutadora"
                          item-value="iderp"
                          item-text="name"
                        ></v-autocomplete>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:item.urgencia="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.urgencia"
                      large
                      @save="save(props.item)"
                    >
                      {{ props.item.urgencia }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.urgencia"
                          type="number"
                          label="urgencia"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:item.proceso="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.proceso"
                      large
                      @save="save(props.item)"
                    >
                      {{ props.item.proceso }} p.
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.proceso"
                          type="number"
                          label="proceso"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:item.capacitacion="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.capacitacion"
                      large
                      @save="save(props.item)"
                    >
                      {{ props.item.capacitacion }} p.
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.capacitacion"
                          type="number"
                          label="capacitacion"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <!------------------------------------------------------------------------------------->
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" dark @click="dialogVacantes = false"
                  >Cancelar</v-btn
                >
              </v-card-actions>
              <v-btn
                color="transparent elevation-0"
                x-small
                absolute
                top
                right
                fab
                class="mt-5 mr-0"
                @click="dialogVacantes = false"
                ><v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card>
          </v-dialog>

          <!-- Agregar Vacante -->
          <v-dialog
            v-model="dialogNuevoVacante"
            max-width="400px"
            hide-overlay
            persistent
          >
            <v-card>
              <v-card-title primary-title>
                Nuevo vacante
              </v-card-title>
              <v-card-text>

                <v-autocomplete
                  v-model="idpuesto"
                  :items="puestos"
                  label="Puesto"
                  item-value="idpuesto"
                  item-text="puesto"
                ></v-autocomplete>
                
                <v-autocomplete
                  v-model="sucursal"
                  :items="sucursales"
                  label="Sucursal"
                  item-value="id_plantel"
                  item-text="plantel"
                ></v-autocomplete>
                
                <v-select
                  v-model="horario"
                  :items="['Día', 'Tarde', 'Noche', 'Completo']"
                  label="Horario"
                ></v-select>

                <v-autocomplete
                  v-model="reclutadora"
                  :items="reclutadoras"
                  label="Reclutadora"
                  item-value="iderp"
                  item-text="name"
                ></v-autocomplete>

                <v-text-field
                  v-model="fecha_entrega"
                  type="date"
                  label="fecha de entrega"
                ></v-text-field>

              </v-card-text>
              <v-card-actions>
                <v-btn color="error" dark @click="dialogNuevoVacante = false">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="saveVacante()"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!--------------------------Dialog Requeridos ------------------------------>
          <v-dialog
            v-model="dialogRequerida"
            max-width="800px"
            hide-overlay
            persistent
          >
            <v-card>
              <v-card-title primary-title> Requeridos </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="headersRequerida"
                  :items="puestosUnicos"
                  class="elevation-0"
                  dense
                >
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" dark @click="dialogRequerida = false"
                  >Cancelar</v-btn
                >
              </v-card-actions>
              <v-btn
                color="transparent elevation-0"
                x-small
                absolute
                top
                right
                fab
                class="mt-5 mr-0"
                @click="dialogRequerida = false"
                ><v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card>
          </v-dialog>

        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>


<!-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->

<script>
  import axios from 'axios';
  // OrgChart.templates.cool = Object.assign({}, OrgChart.templates.ana);
  // OrgChart.templates.cool.defs =
  //   '<filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="cool-shadow"><feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" /><feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" /><feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" /><feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge></filter>';
  // OrgChart.templates.cool.size = [310, 140];
  // OrgChart.templates.cool.node =
  //   '<rect filter="url(#cool-shadow)"  x="0" y="0" height="133" width="310" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect> <rect stroke="#eeeeee" fill="#ffffff" x="100" y="10" width="200" height="65" rx="10" ry="10" filter="url(#cool-shadow)"> </rect><rect stroke="#eeeeee" stroke-width="1" x="10" y="83" width="185" fill="#ffffff" rx="10" ry="10" height="40"></rect><rect stroke="#eeeeee" stroke-width="1" x="200" y="83" width="100" fill="#ffffff" rx="10" ry="10" height="40"></rect><text  style="font-size: 11px;" fill="#afafaf" x="110" y="90"></text>';
  // OrgChart.templates.cool.img =
  //   '<clipPath id="{randId}"><rect  fill="#ffffff" stroke="#039BE5" stroke-width="5" x="20" y="10" rx="10" ry="10" width="100" height="100"></rect></clipPath><image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="10" y="10"  width="80" height="60"></image><rect ></rect>';
  // OrgChart.templates.cool.name =
  //   '<text style="font-size: 18px;" fill="#0719D5" x="110" y="50">{val}</text>';
  // OrgChart.templates.cool.title =
  //   '<text  style="font-size: 10px;" fill="#F57C00" x="15" y="109">{val}</text>';
  // OrgChart.templates.cool.title2 =
  //   '<text style="font-size: 14px;" fill="#afafaf" x="20" y="165">{val}</text>';

  // OrgChart.templates.cool.points =
  //   '<text style="font-size: 12px;" fill="#F57C00" x="250" y="109" text-anchor="middle">{val}</text>';
  // //'<text style="font-size: 14px;" fill="#F57C00" x="242" y="109" text-anchor="middle">{val}</text>';

  // OrgChart.templates.cool.performance =
  //   '<text style="font-size: 24px;" fill="#F57C00" x="150" y="90" >{val}</text>';
  // OrgChart.templates.cool.svg =
  //   '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background-color:#F2F2F2;display:block;" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';

  //----------IMPORTS--------------//

  // Componentes
  // import OrgChart from "@balkangraph/orgchart.js";
  import Alerta from "@/components/alertas/Alerta.vue";
  import carga from "@/components/alertas/carga.vue";

  import validarErrores  from '@/mixins/validarErrores'

  import {mapGetters} from 'vuex'


export default {
  components: {
    Alerta,
    carga,
  },

  mixins: [ validarErrores ],

  //----------DATA--------------//
  data: () => ({
    titulo: "Organigrama",
    node: [],
    jefesSubordinado: [],

    //dialogs
    dialog: false,
    dialogEliminar: false,
    dialogActualizar: false,
    dialogPuestos: false,
    dialogTeachers: false,

    //v-model (lo que tra el front)
    subordinado: null,
    usuario: null,
    iderpEliminar: null,
    idSubordinado: null,
    idActualizacion: null,
    color: "black",
    usuarios: [],
    puestos: [],
    personas: [],
    vacantes: [],
    total: null,
    requerida: [],
    teachers: [],
    reclutadoras: [],

    headersPuestos: [
      { text: "Id"     , value: "idpuesto" },
      { text: "Nombre" , value: "puesto" },
    ],

    headersVacantes: [
      { text: "Id"           , value: "idvacantes" },
      { text: "Puesto"       , value: "idpuesto" },
      { text: "Plantel"      , value: "sucursal" },     
      { text: "Horario"      , value: "horario" },     
      { text: "Reclutadora"  , value: "reclutadora" },
      { text: "Fecha Entrega", value: "fecha_entrega" },     
      { text: "Urgencia"     , value: "urgencia" },
      { text: "Tiempo"       , value: "dias_transcurrido" },
      { text: "Proceso"      , value: "proceso" },
      { text: "Capacitación" , value: "capacitacion" },
      { text: "Eliminar"     , value: "eliminar" },
    ],

    headersRequerida: [
      { text: "Id"            , value: "idpuesto"     },
      { text: "Puesto"        , value: "puesto"       },
      { text: "Requeridos"    , value: "requeridos"   },
      { text: "Ocupados"      , value: "ocupados"     },
      { text: "Vacantes"      , value: "vacantes"     },
      { text: "Capacitación"  , value: "capacitacion" },
    ],

    dialogPersonas: false,

    headersPersonas: [
      { text: "Id"     , value: "iderp" },
      { text: "Nombre" , value: "nombre" },
      { text: "Puesto" , value: "puesto" },
    ],

    headersTeachers: [
      { text: "Id"     , value: "iderp" },
      { text: "Puesto" , value: "puesto" },
    ],

    dialogVacantes: false,
    dialogRequerida: false,

    puestosUnicos:[],
    sucursales:[],
    dialogNuevoVacante: false,

    fecha_entrega: null,
    sucursal:      null,
    horario:       null,
    idpuesto:      null,
    reclutadora:   null,
    urgencia:      null

  }),

  //----------Created--------------//
  created() {
    this.getOrganigrama();
    this.getUsuarios();
    this.getPuestos();
    this.getSucursales()
  },

  watch: {
    iderpEliminar(val) {
      if (val) {
        const jefesSubordinado = this.node.filter((el) => {
          return el.iderp == this.iderpEliminar;
        });
        this.jefesSubordinado = jefesSubordinado;
        console.log( this.jefesSubordinado )
      }
    },
  },

  //----------COMPUTED--------------//
  computed: {

    ...mapGetters('login',['getdatosUsuario']),

    tamanioPantalla() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "auto";
        break;
        default:
          return this.$vuetify.breakpoint.height - 350;
        break;
      }
    },
  },

  //----------METHODS--------------//
  methods: {
    crearOrganigrama() {
      var chart = new OrgChart(document.getElementById("tree"), {
        template: "cool",
        toolbar: {
          fullScreen: true,
          zoom: true,
          fit: true,
          expandAll: true,
        },

        collapse: {
          level: 2,
          layout: OrgChart.mixed,
          allChildren: true,
        },

        nodeBinding: {
          name: "title",
          title: "name",
          img: "img",
          points: "plantel",
        },
        layout: OrgChart.mixed,
        nodes: this.node,
      });
    },

    getSucursales () {
      this.cargar = true
      this.sucursales = []
      return this.$http.get('facturas.sucursales').then(response=>{
        this.sucursales = response.data
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    //Usuarios que estan en el organigrama, se traen los usuarios para pasarlos arriba
    getOrganigrama() {
      this.cargar = true
      this.node = [];
      this.$http.get("organigrama.all").then((response) => {
        this.node = response.data;

        for (const i in this.node) {
          const { title } = this.node[i];

          const similitud = title.toUpperCase().match("TEACH");
          if (similitud) {
            this.teachers.push(this.node[i]);
          }
        }

        for (const i in this.node) {
          const { escuela, name, title } = this.node[i];

          const similitud            = name.toUpperCase().match("VACANTE");
          const similitudNoRequerida = name.toUpperCase().match("REQUERIDA");
          const producto             = title.toUpperCase().match("PRODUCTO");
          const recluta              = title.toUpperCase().match("RECLUTA");

          if (similitud) {
            
            this.node[i].tags = ["VACANTE"];

          } else if (similitudNoRequerida) {

            this.node[i].tags = ["REQUERIDA"];

          } else {

            switch (escuela) {

              case 1:
                this.node[i].tags = ["INBI"];
              break;

              case 2:
                this.node[i].tags = ["FAST"];
              break;

            }

          }
        }
        this.cargar = false
        this.crearOrganigrama();
      }).catch((error) => {
        this.validarError( error.response.data.message )
      }).finally(() => { this.cargar = false });
    },

    //Todos los usuarios
    getUsuarios() {
      this.cargar   = true
      this.usuarios = []
      this.$http.get("usuarios.all.organigrama").then((response) => {
        this.usuarios = response.data;
        this.cargar = false
      }).catch((error) => {
        this.validarError( error.response.data.message )
      }).finally(() => { this.cargar = false });
    },

    //Traer puestos, personas, etc
    getPuestos() {
      this.cargar = true
      this.$http.get("organigrama.puestos").then((response) => {
        this.puestos       = response.data.getPuestos;
        this.personas      = response.data.getPersonas;
        this.vacantes      = response.data.getVacantes;
        this.requerida     = response.data.getRequerida;
        this.puestosUnicos = response.data.puestosUnicos;
        this.reclutadoras  = response.data.reclutadoras;
        this.totalvacantes = response.data.getVacantes.map((registro) => { return registro.Total }).sort();
        this.total         = this.totalvacantes.reduce((a, b) => a + b, 0);
        this.cargar        = false
      }).catch((error) => {
        this.validarError( error.response.data.message )
      }).finally(() => { this.cargar = false });
    },

    //Agrega usuarios
    agregarNodo() {
      this.cargar = true

      const payload = {
        pid:   this.usuario, // id del nodo
        iderp: this.subordinado, // id del erp
      };

      this.$http.post("organigrama.add", payload).then((response) => {
        this.dialog = false;
        this.usuario = null;
        this.subordinado = null;
        this.cargar = false
        this.getOrganigrama();
      }).catch((error) => {
        this.validarError( error.response.data.message )
      }).finally(() => { this.cargar = false });
    },

    //Elimina Usuarios
    eliminarNodo() {
      this.cargar = true
      const payload = {
        id: this.idSubordinado,
      };

      this.$http.post("organigrama.delete", payload).then((response) => {
        this.dialogEliminar = false;
        this.iderpEliminar = null;
        this.idSubordinado = null;
        this.cargar = false
        this.getOrganigrama();
      }).catch((error) => {
        this.validarError( error.response.data.message )
      }).finally(() => { this.cargar = false });
    },

    actualizarNodo() {
      this.cargar = true
      const payload = {
        idorganigrama: this.usuario,
        iderp: this.idActualizacion,
      };

      this.$http.post("organigrama.update", payload).then((response) => {
        this.dialogActualizar = false;
        this.usuario = null;
        this.idActualizacion = null;
        this.cargar = false
        this.getOrganigrama();
      }).catch((error) => {
        this.validarError( error.response.data.message )
      }).finally(() => { this.cargar = false });
    },
    mostrardatos(data) {
      this.dialogPuestos = true;
    },

    //--------------------------------------------------------------------------------------------------------//
    async save(item) {
      this.cargar = true
      this.$http.post(`organigrama.updatevacantes`, item).then((response) => {
        this.cargar = false
        this.getPuestos();
      }).catch((error) => {
        this.validarError( error.response.data.message )
      }).finally(() => { this.cargar = false });
    },

    eliminarVacante( item ){
      this.cargar = true
      item.deleted = 1
      this.$http.post(`organigrama.updatevacantes`, item).then((response) => {
        this.cargar = false
        this.actualizarVacantesOrden( item )
      }).catch((error) => {
        this.validarError( error.response.data.message )
      }).finally(() => { this.cargar = false });
    },

    // async actualizarVacantesOrden2( item ){
    //   let vacantes = this.vacantes.filter( el => { return el.reclutadora == item.reclutadora && el.idvacantes != item.idvacantes })
      
    //   let position = 1
    //   for( const i in vacantes ){

    //     vacantes[i].urgencia = position
    //     const grabar = await this.updateVacante( vacantes[i] ).then(response=> response)

    //     position += 1
    //   }

    //   this.getPuestos()

    // },

    async actualizarVacantesOrden( item ){
      let vacantes = this.vacantes.filter( el => { return el.reclutadora == item.reclutadora && el.idvacantes != item.idvacantes })
      
      let position = 1
      for( const i in vacantes ){

        vacantes[i].urgencia = position
        const grabar = await this.updateVacante( vacantes[i] ).then(response=> response)

        position += 1
      }

      this.getPuestos()

    },

    async saveVacante( ) {
      let vacantes = this.vacantes.filter( el => { return el.reclutadora == this.reclutadora })

      let payload = {
        fecha_entrega: this.fecha_entrega,
        sucursal:      this.sucursal,
        horario:       this.horario,
        idpuesto:      this.idpuesto,
        reclutadora:   this.reclutadora,
        urgencia:      1
      }

      if( !vacantes.length ){
        this.cargar = true
        this.$http.post(`organigrama.vacante`, payload).then((response) => {
          this.dialogNuevoVacante = false
          this.fecha_entrega = null
          this.sucursal      = null
          this.horario       = null
          this.idpuesto      = null
          this.reclutadora   = null
          this.urgencia      = null
          this.cargar        = false
          this.getPuestos();
        }).catch((error) => {
          this.validarError( error.response.data.message )
        }).finally(() => { this.cargar = false });
      }else{
        let position = 1
        for( const i in vacantes ){

          vacantes[i].urgencia = position
          const grabar = await this.updateVacante( vacantes[i] ).then(response=> response)

          position += 1
        }

        payload.urgencia = position
        this.$http.post(`organigrama.vacante`, payload).then((response) => {
          this.dialogNuevoVacante = false
          this.fecha_entrega = null
          this.sucursal      = null
          this.horario       = null
          this.idpuesto      = null
          this.reclutadora   = null
          this.urgencia      = null
          this.cargar        = false
          this.getPuestos();
        }).catch((error) => {
          this.validarError( error.response.data.message )
        }).finally(() => { this.cargar = false });
      }
    },

    updateVacante ( data ) {
      return new Promise((resolve,reject)=>{
        // obtener la extensión del archivo
        this.$http.post(`organigrama.updatevacantes`, data).then((response) => {
          resolve( response )
        }).catch((error) => {
          this.validarError( error.response.data.message )
        }).finally(() => { this.cargar = false });

      })
    },
    //--------------------------------------------------------------------------------------------------------//
  },
};
</script>

<style>
.node.INBI rect {
  fill: #5dddff !important;
}

.node.INBI text {
  fill: black !important;
}

.node.FAST rect {
  fill: #c71212 !important;
}

.node.FAST text {
  fill: rgb(238, 232, 232) !important;
}

.node.VACANTE rect {
  fill: #ff8017 !important;
}

.node.VACANTE text {
  fill: rgb(238, 232, 232) !important;
}

.node.REQUERIDA rect {
  fill: #a1a1a1 !important;
}

.node.REQUERIDA text {
  fill: rgb(238, 232, 232) !important;
}
</style>


