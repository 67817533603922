import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';

export default {

	data: () => ({
    parametros:{
      dialogError: false,
      mensaje: '',
      color: ''
    },

    respuestaAlerta:false,
    loader: true,
    cargar: false,
  }),
  
	methods: {
		validarrespuestaAlerta( error ){ 
			return new Promise( (resolve, reject) => {
				if( error.status == 400){
					resolve( error.body.message )
				}else{
					reject( error )
				}
			})
		},


		consultar_niveles_usuario(){
			return new Promise( (resolve) => {
				this.$http.get('niveles.activas').then((response)=>{
					console.log(response.data)
					resolve(response.data)
				}).catch(error =>{
					console.log('error niveles', error)
				})
			})
		},

		validarSuccess( mensaje ){
      this.cargar = false
      this.parametros.mensaje      = mensaje
      this.parametros.dialogError  = true 
      this.parametros.color        = 'success'
      this.respuestaAlerta         = true 
    },

    cerrarDialog( mensaje ){
      this.cargar = false
      this.parametros.mensaje      = ''
      this.parametros.dialogError  = false 
      this.parametros.color        = ''
      this.respuestaAlerta         = false 
    },

    validarError ( value ) {
      this.cargar = false 
      if( value.status == 400 || value.status == 500){
        this.parametros.mensaje      = value.body.message ? value.body.message : 'Error en la conexión con el servidor'
      }else if( value.status == 404 ){
        this.parametros.mensaje      = 'Ruta no encontrada, levantar un ticket, por favor'
      }else  if( value.status == 0 ){
        this.parametros.mensaje      = 'El servidor no se encuentra activo, contacta a sistemas'
      }else{
        this.parametros.mensaje      = value
      }

      this.parametros.dialogError  = true 
      this.parametros.color        = '#FF2C2C'
      this.respuestaAlerta         = true 
    },

    validarErrorDirecto ( mensaje ) {
      this.cargar = false 
      this.parametros.mensaje      = mensaje
      this.parametros.dialogError  = true 
      this.parametros.color        = '#FF2C2C'
      this.respuestaAlerta = true 
    },
  }
}