<template>
  <v-container class="mt-5 ml-2">
    <v-row>
      <v-col cols="8">
        <v-card>
          <v-card-title>
            <span class="headline">Total de tickets de cada institución</span>
            <div class="ml-2">
              <v-btn color="blue" dark @click="cargar_tickets_cada_institucion()">
                <v-icon>mdi-reload</v-icon> Volver a cargar
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div id="app">
                    <div id="content">
                      <canvas ref="chartUN" style="max-height: 600px;"></canvas>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import Vue from "vue";
import Chart from "@/plugins/chart";

export default {
  name: "app",
  data: () => ({
    data: [],
    tickets_totales_INBI: { total_tickets_INBI: 0 },
    tickets_totales_FAST: { total_tickets_FastEnglish: 0 },
    tickets_totales_AREA: { tickets_asignados_por_area: 0, area: "" },
    tickets_totales_JEFE: { tickets_asignados_por_jefe: 0, Nombre_Jefe: "" },
    tickets_totales_AUXILIAR: {
      tickets_asignados_por_auxiliar: 0,
      Nombre_Auxiliar: "",
    },
  }),

  created() {
    this.cargar_tickets_cada_institucion();
  },

  methods: {
    cargar_tickets_cada_institucion() {


      this.$http
        .get("reportes.total_tickets_inbi")
        .then((response) => {
          this.tickets_totales_INBI = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$http
        .get("reportes.total_tickets_fast_english")
        .then((response) => {
          this.tickets_totales_FAST = response.data;
          var chartUN = this.$refs.chartUN;
          var ctx = chartUN.getContext("2d");
          if(this.myChart != null) {
            this.myChart.destroy();
          }
          this.myChart = new Chart(ctx, {
            type: "doughnut",
            data: {
              labels: [
                "Tickets de INBI: " +
                  this.tickets_totales_INBI[0].total_tickets_INBI,
                "Tickets de FAST ENGLISH: " +
                  this.tickets_totales_FAST[0].total_tickets_FastEnglish,
              ],
              datasets: [
                {
                  data: [
                    this.tickets_totales_INBI[0].total_tickets_INBI,
                    this.tickets_totales_FAST[0].total_tickets_FastEnglish,
                  ],
                  backgroundColor: ["#87CEEB", "#FF0000"],
                  labels: [],
                },
              ],
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {},
};
</script>