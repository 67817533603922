<template>
  <v-container class="mt-5 ml-2">
    <v-card class="shadowCard">
      <v-row>
        <v-col cols="12">

          <v-toolbar
            flat
          >
            <v-toolbar-title>Nuevas Matriculas</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn color="primary" @click="consultar()" small>Consultar</v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  clearable
                  v-model="fechaini"
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha final"
                  filled
                  dense
                  clearable
                  v-model="fechafin"
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          

          <v-card-text class="pt-0">
            <v-row align="center">
              <v-col cols="12" md="5" lg="3">
                <v-card color="blue darken-4">
                  <v-card-title primary-title class="py-1">
                    <div class="white--text">Inscritos Fast: {{ totalFast }}</div>
                  </v-card-title>
                </v-card>
              </v-col>

              <v-col cols="12" md="5" lg="3">
                <v-card color="blue lighten-1">
                  <v-card-title primary-title class="py-1">
                    <div class="white--text">Inscritos Inbi: {{ totalInbi }}</div>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

        </v-col>

        <v-col cols="12" class="pa-8">
        	<v-card class="shadowCard">
        		<v-card-title primary-title>
        		  Matriculas FAST
        		</v-card-title>
        	  <v-card-text>
  		      	<v-data-table
  		          :headers="headers"
  		          :items="tablaFast"
  		          class="elevation-0"
  		          dense
  		          mobile-breakpoint="200"
  		        >
  		        	<template v-slot:item.ver="{ item }">
						      <v-icon
						        small
						        color="primary"
						        @click="abrirDialog( item.vendedora )"
						      >
						        mdi-magnify
						      </v-icon>
						    </template>
  		      	</v-data-table>
        	  </v-card-text>
        	</v-card>
        </v-col>

        <v-col cols="12" class="pa-8">
        	<v-card class="shadowCard">
        		<v-card-title primary-title>
        		  Matriculas INBI
        		</v-card-title>
        	  <v-card-text>
  		      	<v-data-table
  		          :headers="headers"
  		          :items="tablaInbi"
  		          class="elevation-0"
  		          dense
  		          mobile-breakpoint="200"
  		        >
  		        	<template v-slot:item.ver="{ item }">
						      <v-icon
						        small
						        color="primary"
						        @click="abrirDialog( item.vendedora )"
						      >
						        mdi-magnify
						      </v-icon>
						    </template>
  		        </v-data-table>
        	  </v-card-text>
        	</v-card>
        </v-col>
      </v-row>

    </v-card>

    <v-dialog
	    v-model="dialogAlumnos"
	    persistent
	    max-width="800"
	  >
	    <v-card>
	    	<v-card-text>
	    		<v-card-title primary-title>
						{{ vendedora }}
	    		</v-card-title>
	  			<v-data-table
	          :headers="headersDos"
	          :items="alumnosVendedora"
	          class="elevation-0"
	          dense
	          mobile-breakpoint="200"
	        >
	        </v-data-table>

	    	</v-card-text>
	    	<v-card-actions>
	    		<v-spacer></v-spacer>
			    <v-btn color="red" dark smal rounded @click="dialogAlumnos = false">Cerrar</v-btn>
	    	</v-card-actions>
	    </v-card>
	  </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,


      headers: [
        { text: 'Vendedora'     , value: 'vendedora' },
        { text: 'Total'         , value: 'total' },
        { text: 'Ver'           , value: 'ver' },
      ],

      headersDos: [
        { text: 'Matricula'     , value: 'matricula' },
        { text: 'Nombre'        , value: 'alumno' },
        { text: 'Grupo'         , value: 'grupo' },
        { text: 'Total'         , value: 'pagado' },
      ],

      desserts: [],

      tablaFast:[],
      tablaInbi:[],

      fast:{},
      inbi:{},

      fechaini:null,
      fechafin:null,
      totalFast:0,
      totalInbi:0,
      totalFast:0,
			totalInbi:0,
			nuevasMatriculas:[],

			alumnosVendedora:[],
			dialogAlumnos:false,
			vendedora:''
    }),

    computed:{
      ...mapGetters('login', [ 'getdatosUsuario', 'getEscuela' ])
    },

    methods: {
      
      consultar () {
        this.dataTablaFastUno = []
        this.dataTablaInbiUno = []
        this.cargar = true

        var payload = {
          fechaini: this.fechaini,
          fechafin: this.fechafin ? this.fechafin : this.fechaini
        }

        this.$http.post('nuevas.matriculas',payload).then(response=>{
          // Guardar los datos 
          this.tablaFast        = response.data.vendedorasFast
					this.tablaInbi        = response.data.vendedorasInbi
					this.totalFast        = response.data.totalFast 
					this.totalInbi        = response.data.totalInbi
					this.nuevasMatriculas = response.data.nuevasMatriculas
          this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
       
      },

      abrirDialog( vendedora ){
      	this.vendedora = vendedora
      	this.alumnosVendedora = this.nuevasMatriculas.filter( el=> { return el.vendedora == vendedora })
      	this.dialogAlumnos = true
      },

      exportar(){
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let resultado = this.tablaFast.concat(this.tablaInbi)

        let data = XLSX.utils.json_to_sheet(resultado)
        const workbook = XLSX.utils.book_new()
        const filename = 'KPI'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },      
    },
  }
</script>