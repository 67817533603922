<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
    	<v-col cols="12">
    		<v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Diploma finalización</span>

            <v-spacer></v-spacer>

            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( alumnos  , 'DIPLOMA_FINALIZA')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="getSolicitudes()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

          </v-card-title>

          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6" lg="5">
                <v-text-field
                  label="Buscar"
                  v-model="search"
                  dense
                  filled
                ></v-text-field>
              </v-col>
            </v-row>

  			    <v-data-table
  			      :headers="headers"
  			      :items="alumnos"
  			      :search="search"
  			    >
              <template v-slot:item.estatus="{ item }">
                <v-chip v-if="item.estatus == 0 "  small color="red"      dark> En proceso </v-chip>
                <v-chip v-if="item.estatus == 1 "  small color="orange"   dark> Creado     </v-chip>
                <v-chip v-if="item.estatus == 2 "  small color="grey"     dark> Enivado    </v-chip>
                <v-chip v-if="item.estatus == 3 "  small color="blue"     dark> Recibido   </v-chip>
                <v-chip v-if="item.estatus == 4 "  small color="green"    dark> Entregado  </v-chip>
              </template>

              <template v-slot:item.adultos="{ item }">
                <v-chip v-if="item.adultos == 1 "  small color="black"    class="elevation-12 text-capitalize" dark> Adultos </v-chip>
                <v-chip v-if="item.adultos == 0 "  small color="#2EDAD5"  class="elevation-12 text-capitalize" dark> Teens   </v-chip>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-chip v-if="item.estatus == 0 "  small color="orange" class="elevation-12" dark @click="crear( item, 1)"> Crear      </v-chip>
                <v-chip v-if="item.estatus == 1 "  small color="grey"   class="elevation-12" dark @click="actualizar( item, 2)"> Enivado     </v-chip>
                <v-chip v-if="item.estatus == 4"   small color="green"  class="elevation-12"  dark @click="ver( item, 4)"> VER     </v-chip>
              </template>

              <template v-slot:item.descargar="{ item }">
                <v-chip v-if="item.estatus >= 1" small color="primary" class="elevation-12" dark @click="descargar( item )"> <v-icon>mdi-download</v-icon>      </v-chip>
              </template>

              <template v-slot:item.unidad_negocio="{ item }">
                <v-chip small v-if="item.unidad_negocio==1" color="#2EDAD5"> INBI </v-chip>
                <v-chip small v-else color="#000D4A" dark> FAST </v-chip>
              </template>

              <template v-slot:item.opciones="{ item }">
                <v-chip small color="orange" dark @click="verCambio(item)">Ver</v-chip>
              </template>

  			    </v-data-table>
          </v-card-text>
    		</v-card>
    	</v-col>
    </v-row>

    <v-dialog v-model="dialogVer" max-width="600px" v-if="alumno" persistent :fullscreen="fullscreen">
      <v-card class="elevation-0">
        <v-card-title primary-title>
          <strong>{{ alumno.nombre_completo }}</strong>
        </v-card-title>
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-card height="100%" class="elevation-0" align="center" outlined >
              <v-img :src=" foto + alumno.url" contain aspect-ratio="2" v-if="fullscreen">
              </v-img>

              <v-img :src=" foto + alumno.url" contain aspect-ratio="2" max-width="450" v-else >
              </v-img>
            </v-card>
          </v-col>
        </v-card-text>
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn color="error" text large class="mr-2" @click="fullscreen = true" v-if="!fullscreen">Zoom</v-btn>
          <v-btn color="error" text large class="mr-2" @click="fullscreen = false" v-else>Sin, Zoom</v-btn>
          <!-- Guardar la información  -->
          <v-btn
            color="primary" 
            dark 
            class="elevation-6" 
            large 
            @click="dialogVer = false, alumno = null"
          >Okey</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search: '',
      headers: [
        { text:'Id'                  , value:'idcertificado_fisico'},
        { text:'id alumno'           , value:'iderp'},
        { text:'alumno'              , value:'nombre_completo'},
        { text:'Escuela'             , value:'unidad_negocio'},
        { text:'Plantel'             , value:'plantel'},
        { text:'Fecha de solicitud'  , value:'fecha_creacion'},
        { text:'Adultos'             , value:'adultos'},
        { text:'status'              , value:'estatus'},
        { text:'Pasar a:'            , value:'actions'},
        { text:'descargar'            , value:'descargar'},
      ],
      teachers: [],

      grupos:[],
      alumnos:[],

      dialogUpdateCambio: false,
      alumno:null,

      fullscreen:false,
      dialogVer: false,
      foto: '',
      foto_download:'',
    }),

    async created () {
      console.clear()
      this.foto = axios.defaults.baseURL + 'imagenes-recepcion/'
      this.foto_download = axios.defaults.baseURL + 'imagenes-certificados/'
      await this.getSolicitudes()
    },

    methods: {

      getSolicitudes () {
        this.cargar = true
        this.alumnos = []
        this.$http.get('academico.certificados.solicitados').then(response=>{
          this.alumnos = response.data
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      crear( item, estatus ){
        this.alumno = item
        this.cargar = true
        this.$http.post('academico.crear.certificado/' + this.alumno.idcertificado_fisico, this.alumno).then(response=>{
          this.validarSuccess( 'Diploma creado correctamente' )
          window.open( this.foto_download + this.alumno.idcertificado_fisico + '.jpg')

          this.getSolicitudes()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      descargar( value ){
        window.open( this.foto_download + value.idcertificado_fisico + '.jpg')
      }

    },

    computed:{
      ...mapGetters ('login',['getdatosUsuario'])
    }
  }
</script>

<style>

  .v-menu__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-menu__content::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-button:increment,.v-menu__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-menu__content::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>

