<!--VUE-->

<template>
	<v-container class="mt-4" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  			<v-card-title primary-title>
	  			  <span class="text-subtitle-1">Comparativa ERP</span>
            <v-radio-group            
              v-model="escuela"
              row
              class="ml-4"
            >
              <v-radio
                label="Fast"
                :value="2"
              ></v-radio>
              <v-radio
                label="Inbi"
                :value="1"
              ></v-radio>
            </v-radio-group>
	  			  <v-spacer></v-spacer>                 
	  			  <v-chip color="info" small @click="initialize( )">Actualizar</v-chip>                
	  			</v-card-title>

	  			<v-card-text>
		  			<v-data-table
					    :headers="headers"
					    :items="filtroBecas"
					    sort-by="calories"
					    class="elevation-0"
					    :search="search"
					  >

					  </v-data-table>
	  			</v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>					
</template>

<!-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->



<script>
import axios from 'axios';


//----------IMPORTS--------------//
  import {mapGetters, mapActions} from 'vuex'
  // Componentes
  import Alerta             from '@/components/alertas/Alerta.vue'
  import carga              from '@/components/alertas/carga.vue';
  import funcionesExcel     from '@/mixins/funcionesExcel'


  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ funcionesExcel ],


//----------DATA--------------//
    data: () => ({
    	search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Sucursal'     , value: 'plantel'    },
        { text: 'CO1'          , value: 'columna1'   },
        { text: 'CO2'          , value: 'columna2'   },
        { text: 'CO3'          , value: 'columna3'   },
        { text: 'CO4'          , value: 'columna4'   },
      ],

      registrosProspectos: [],
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      
      alumnos:[],
      prospecto: null,  
      filtro:3,

      escuela:null,
    }),


//----------COMPUTED--------------//
    computed: {
      ...mapGetters('login',['getdatosUsuario']),

      filtroBecas( ){
        if( this.filtro && this.filtro == 3){
          return this.registrosProspectos
        }

        if( this.filtro && this.filtro == 1){
          return this.registrosProspectos.filter( el=> { return !el.beca && el.id_grupo })
        }

        if( this.filtro && this.filtro == 2){
          return this.registrosProspectos.filter( el=> { return !el.inscrito && el.id_grupo })
        }
      }
    },

//-------
    async created () {
      
      
    },


//----------METHODS--------------//
    methods: {
    
      initialize () {
        this.cargar = true
        this.registrosProspectos = []
        const escuela = this.escuela
        const payload = {escuela}
        return this.$http.post('inscripciones.getcomparacioncicloerp', payload).then( response=> {
        	this.registrosProspectos = response.data
          this.cargar = false
        }).catch( error => {
        	console.log( error )
        }).finally( ( ) => {  })
      },

    


      validarErrores ( value ) {
        this.cargar = false
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

    },
  }
</script>