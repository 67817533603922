<template>
	<v-container fluid>
	  <v-row>
	  	<v-col cols="12" class="pa-0">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Teachers</span>
	  		    <v-spacer></v-spacer>
	  		  </v-card-title>
	  		  <v-card-text>
	  		    <v-row>
	  		    	<v-col cols="12" md="8" lg="6">
							  <v-autocomplete
							    :items="teachers"
							    v-model="teacher1"
							    label="Teacher #1"
							    item-text="nombre_completo"
							    item-value="id_trabajador"
							    filled
							  ></v-autocomplete>
	  		    	</v-col>
	  		    </v-row>

	  		    <v-row>
	  		    	<v-col cols="12" md="8" lg="6">
							  <v-autocomplete
							    :items="teachers"
							    v-model="teacher2"
							    label="Teacher #2"
							    item-text="nombre_completo"
							    item-value="id_trabajador"
							    filled
							  ></v-autocomplete>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" rounded @click="updateTeacher()">Guardar</v-btn>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

	// Componentes
  export default {

  	components:{
      Alerta,
      carga,
    },

  	props:[
			'editedItem'
	  ],
    data: () => ({

      // tabla
      teachers:[],
      teacher1: null,
      teacher2: null,

      // Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
    },

    async created () {
      await this.initialize()
      await this.getTeachers()
    },

    methods: {
      initialize () {
      	this.teacher1 = null
				this.teacher2 = null
        return this.$http.get('grupos.teachers/' + this.editedItem.id_grupo).then(response=>{
        	this.teacher1 = response.data.find( el=> el.numero_teacher == 1)
        	this.teacher2 = response.data.find( el=> el.numero_teacher == 2)
        	console.log( response.data )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getTeachers () {
        this.teachers = []
        return this.$http.get('academico.teachers.activos').then(response=>{
          this.teachers = response.data
        }).catch(error=>{
        	this.overlay = false
        	this.swalError('Error al cargar los datos')
        })
      },

      async updateTeacher ( ) {
      	this.cargar = true
      	await this.updateTeacher1( )
      	await this.updateTeacher2( )
      },

      updateTeacher1 () {
      	if( !this.teacher1 ){ return }
        let payload = {
          id_maestro: this.teacher1.id_trabajador,
          id_grupo:   this.editedItem.id_grupo,
          activo_sn:  1,
          numero_teacher: 1
        }
        return this.$http.post(`academico.add.grupoteacher`, payload).then(response=>{
          this.validarSuccess(response.data.message)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      updateTeacher2 () {
      	if( !this.teacher2 ){ return }
        let payload = {
          id_maestro: this.teacher2.id_trabajador,
          id_grupo:   this.editedItem.id_grupo,
          activo_sn:  1,
          numero_teacher: 2
        }
        return this.$http.post(`academico.add.grupoteacher`, payload).then(response=>{
          this.validarSuccess(response.data.message)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      validarErrorDirecto ( mensaje ) {
        this.cargar = false 
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
    	},
    },
  }
</script>


