<template>
	<!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="parametrosProspectos.estatus"
    persistent
    max-width="600"
  >
		<v-card>
      <v-card-title>
        <v-card width="80" class="elevation-0 transparent">
          <v-img 
          	v-if="getEscuela == 2"
            src="@/assets/logo_fast.png"
          ></v-img>

          <v-img 
          	v-else
            src="@/assets/logo_inbi.png"
          ></v-img>

        </v-card>
        <v-spacer></v-spacer>
        <span class="text-subtitle-1 font-weight-bold text-center">
          Nuevo prospecto
        </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>

            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="editedItem.nombre_completo"
                label="Nombre completo"
                filled
                hide-details
                dense
                autofocus
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.idmedio_contacto"
                :items="medios_contacto"
                label="¿Cómo nos conocio?"
                persistent-hint
                item-value="idmedio_contacto"
                item-text="medio"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.idfuentes"
                :items="fuentes"
                label="¿Cómo nos contactó?"
                item-value="idfuentes"
                item-text="fuente"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.iddetalle_fuentes"
                :items="detallesFilter"
                label="Detalle"
                persistent-hint
                item-value="iddetalle_fuentes"
                item-text="detalle_fuente"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-select
                :items="cursos_escuela"
                v-model="editedItem.idcursos_escuela"
                item-text="curso"
                item-value="idcursos_escuela"
                label="Curso interes"
                hide-details
                filled
                dense
              ></v-select>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-switch label="Foraneo" v-model="editedItem.foraneo" hide-details></v-switch>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-subheader class="mb-0 pb-0 mt-0">Información de contacto</v-subheader>
            </v-col>


            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="editedItem.telefono"
                label="Teléfono"
                filled
                hide-details
                type="number"
                dense
                @keyup.enter="buscarTelefono( editedItem.telefono )"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-select
                :items="vendedoras_plantel"
                v-model="editedItem.sucursal"
                return-object
                item-text="plantel"
                item-value="idplantel"
                label="Sucursal"
                hide-details
                filled
                dense
              ></v-select>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-textarea
                v-model="editedItem.nota_inicial"
                filled
                dense
                rows="3"
                hide-details
                label="Nota inicial"
              > 
              </v-textarea>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="red"
          dark
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          class="elevation-6"
          dark
          @click="saveDirecto"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
	</v-dialog>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta              from '@/components/alertas/Alerta.vue'
  import carga               from '@/components/alertas/carga.vue';

  import validarErrores      from '@/mixins/validarErrores'

  import mixinLeads          from '@/mixins/mixinLeads'

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, mixinLeads ],

  	props:['parametrosProspectos'],

    data: () => ({
    	// Datos del alumno inscrito
    	alumnoNuevo: false,
    	alumnos:[],
    	id_alumno: null,
    	grupoActual:null,
			grupoSiguiente:null,
			nuevoGrupo:false,

			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      step: 1,
      ventana: 1,
      perfil: null,
      valid: true,
      valid2: true,
      valid3: true,

      alumno:null,
      grupo:null,

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      valid: true,
      nameRules: [
        v => !!v || 'Teléfono es requerido',
        v => (v && !(v < 1000000000 )) || 'No son 10 números',
      ],

      ///usuarios
      dialog: false,
      // Modales
      dialogDelete: false,
      dialogValidarEscuela: false,
      dialogEditar:false,

      
      prospectos: [], //arreglo
      editedIndex: -1,
      editedItem: {
        nombre_completo:'',
        idleds:'',
        escuela: 2,
        iddetalle_fuentes:0,
        idfuentes: 0,
        fuente: '',
        estatus: '',
        foraneo: 0,
        idcursos_escuela: 0,
        telefono:'',
        sucursal:0,
        nota_inicial:'El prospecto desea información',
        idmedio_contacto:0,
        idcampanias:0,
      },
      defaultItem: {
        nombre_completo:'',
        idfuentes: '',
        idleds:'',
        escuela: 2,
        iddetalle_fuentes:0,
        fuente: 0,
        estatus: '',
        foraneo: 0,
        idcursos_escuela: 0,
        telefono:'',
        sucursal:0,
        nota_inicial:'El prospecto desea información',
        idmedio_contacto:0,
        idcampanias:0,
      },
      // Datos del prospecto
      prospecto: {
        //agrego cada campo a utlizar
        idprospectos: 0,
        nombre_completo: '',
        telefono:'',
        correo: '',
        idetapa:0,
        sucursal_interes:0,
        como_nos_conocio:0,
        ciclo_interes: 0,
        curso_interes:0,
        usuario_asignado:0,
        usuario_creo:0,
        nota_inicial:'',
        folio:'',
        idleds:0,
      },

      etapas:[],
      etapa:null,
      sucursales:[],
      sucursal:null,
      vendedoras:[],
      vendedoras_plantel:[],
      vendedora:null,
      campanias:[],
      campania:null,
     
      escuela: 2,

      sucursales:[],
      sucursal:null,
      vendedoras:[],
      vendedoras_plantel:[],
      vendedora:null,
      campanias:[],
      campania:null,

      fechaini:null,
      fechafin:null,

      cursos_escuela:[],
      medios_contacto:[],

      searchTelefono:'',
      // Consultas extras
      detalles:[],
      fuentes:[]
    }),

    watch:{
      nuevaSucursal(){
        this.nombreVendedora = this.nuevaSucursal.nombre
      },

    },

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),

    	filterSucursales(){
        if(this.sucursales){
          return this.sucursales.filter( el=> { return el.escuela == this.escuela})
        }
      },

      filterVendedoras(){
        return this.vendedoras.filter( el=> { return el.escuela == this.escuela})
      },

      detallesFilter(){
        return this.detalles.filter(el => { return el.idfuentes == this.editedItem.idfuentes })
      },

      campaniasFilter(){
        return this.campanias.filter(el => { return el.idmedio_contacto == this.editedItem.idmedio_contacto })
      },

    },

    async created () {

      this.cargar     = true

    	this.escuela    = this.getEscuela
      this.idusuario  = this.getdatosUsuario.iderp

      await this.getSucursales()
      await this.getVendedoras()
      await this.getFuntes()
      await this.getDetallesFuente()
      await this.getCampanias()
      await this.getCursosEscuela()
      await this.getComoNosConocio()

      this.cargar = false

    },


    methods: {

      // Obtener todas las sucursales
      getSucursales() {
        this.cargar      = true
        this.sucursales  = []
        return this.$http.get('planteles.all').then((response) => {
          // Llenamos la etapa incial
          this.sucursales = response.data
          this.sucursal   = response.data[0]
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getVendedoras() {
        this.cargar = true
        this.vendedoras  = []
        return this.$http.get('vendedoras.list').then((response) => {
          // Llenamos la etapa incial
          this.vendedoras = response.data
          this.vendedoras_plantel = this.vendedoras.filter(el=> { return el.escuela == this.escuela})
          // this.vendedoras_plantel.push({idplantel: 0, plantel:'SIN PLANTEL', nombre:'NA'})
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // Obtener las campañas
      getCampanias(){
        this.cargar = true
        this.campanias  = []
        return this.$http.get('campanias.activos').then((response) => {
          // Llenamos la etapa incial
          this.campanias = response.data
          this.campania  = this.campanias[0]
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
      
      // Datos para grabar el prospecto
      grabarProspecto() {
        if(!this.$refs.form.validate())
          return


        this.cargar = true 
        // Sacamos ala vendedora
        const vendedora = this.vendedoras_plantel.find(el=> el.idplantel == this.editedItem.sucursal_interes)

        // Llenamos los campos faltantes
        this.editedItem['usuario_creo']     = this.getdatosUsuario.iderp
        this.editedItem['idpuesto']         = this.getdatosUsuario.idpuesto
        this.editedItem['como_llego']       = this.getdatosUsuario.idpuesto == 25 ? this.campania.idcampanias : 0
        this.editedItem['escuela']          = this.escuela
        this.editedItem['acronimo']         = vendedora.acronimo
        this.editedItem['usuario_asignado'] = vendedora.iderp

        this.$http.post('prospectos.add', this.editedItem).then((response) => {
          this.cargar       = false
          this.close()
          this.dialogSuccess = true
          this.initialize();
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close() {
        this.editedItem           = Object.assign({}, this.defaultItem)
        this.parametrosProspectos.estatus = false
      },

      abrirDialogProspecto( ){
      	this.existeProspecto              = true
      	this.parametrosProspectos.estatus = true
      },

      saveDirecto ( item ) {
	    	if(!this.editedItem.idfuentes){
	    		return this.validarErrorDirecto( 'Favor de llenar todos los campos' )
	    	}

	      // Ponerle pendiente a su nombre completo
	      if(this.editedItem.nombre_completo == ''){ this.editedItem.nombre_completo = 'Pendiente' }

	    	// Activamos el loader
	      this.cargar = true

	      // Lo mandapos por el EP
	      this.$http.post('leds.add', this.editedItem).then(response=>{
	        if( this.editedItem.telefono ){
	          this.addContactoDirecto( this.editedItem , response.data.id )
	        }else{
	        	this.validarSuccess( 'Lead agregado correctamente ')
	          this.close()
	        }
	      	this.cargar      = false
	      }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
	    },

	    addContactoDirecto ( lead , idleds ) {
	      // Sacamos a la vendedora
	      let vendedora = 0
	      if(this.editedItem.sucursal.idplantel != 0){
	        vendedora = this.vendedoras_plantel.find(el=> el.idplantel == this.editedItem.sucursal.idplantel)
	      }

	      //recibe los campos del form
	      //guardo en una variable los datos a guardar id = this.editedItem.idcurso;
	      var payload = {
	        nombre_completo:  lead.nombre_completo,
	        telefono:         lead.telefono,
	        correo:           lead.email,
	        sucursal_interes  : this.editedItem.sucursal.idplantel ? this.editedItem.sucursal.idplantel : 0,
          sucursal_interes_2: this.editedItem.sucursal.idplantel ? this.editedItem.sucursal.idplantel : 0,
	        usuario_creo:     this.getdatosUsuario.iderp,
	        usuario_asignado: vendedora ? vendedora.iderp : 0,
	        nota_inicial:     lead.nota_inicial,
	        folio:            '',
	        acronimo:         this.editedItem.sucursal.idplantel == 0 ? '' : this.editedItem.sucursal.acronimo,
	        escuela:          this.escuela,
	        idpuesto:         this.getdatosUsuario.idpuesto,
	        como_llego:       this.getdatosUsuario.idpuesto == 25 ? this.campania.idcampanias : 0,
	        idleds:           idleds,
          curso_interes:    this.editedItem.idcursos_escuela,
          horario_interes:  0
	      }

	      this.cargar = true

	      this.$http.post('prospectos.add', payload).then(response  => {
	        this.cargar = false
	        this.editedItem           = Object.assign({}, this.defaultItem)
	        this.editedItem.sucursal  = null
	        this.anuncio              = null
	        this.vendedora            = null
	        this.validarSuccess( 'Contacto generado correctamente' )
	        payload.folio        = response.data.folio
	        payload.idprospectos = response.data.id
	        this.$emit('saveProspecto', payload )
	        this.close()
	        this.cargar      = false
	      }).catch( error =>{
	        console.log( error )
	        // Hay que eliminar el lead
	        this.eliminarLead( idleds )
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
	    },
    },
  };
</script>
<style scoped>

  #back{
    background-color: #EEEEEE;
  }

  #menuable__content__active::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  #menuable__content__active::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  #menuable__content__active::-webkit-scrollbar:vertical{
    width: 6px !important;
  }

  #menuable__content__active::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  #menuable__content__active::-webkit-scrollbar:horizontal{
    height: 6px !important;
  }

  #menuable__content__active::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

</style>