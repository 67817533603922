var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-4 ml-2",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadowCard"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Diploma finalización")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","dark":"","color":"green","tile":""},on:{"click":function($event){return _vm.exportExcel( _vm.alumnos  , 'DIPLOMA_FINALIZA')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-microsoft-excel")])],1),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","dark":"","small":"","tile":""},on:{"click":function($event){return _vm.getSolicitudes()}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-refresh")]),_vm._v(" Actualizar ")],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"5"}},[_c('v-text-field',{attrs:{"label":"Buscar","dense":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.alumnos,"search":_vm.search},scopedSlots:_vm._u([{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [(item.estatus == 0 )?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}},[_vm._v(" En proceso ")]):_vm._e(),(item.estatus == 1 )?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v(" Creado ")]):_vm._e(),(item.estatus == 2 )?_c('v-chip',{attrs:{"small":"","color":"grey","dark":""}},[_vm._v(" Enivado ")]):_vm._e(),(item.estatus == 3 )?_c('v-chip',{attrs:{"small":"","color":"blue","dark":""}},[_vm._v(" Recibido ")]):_vm._e(),(item.estatus == 4 )?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v(" Entregado ")]):_vm._e()]}},{key:"item.adultos",fn:function(ref){
var item = ref.item;
return [(item.adultos == 1 )?_c('v-chip',{staticClass:"elevation-12 text-capitalize",attrs:{"small":"","color":"black","dark":""}},[_vm._v(" Adultos ")]):_vm._e(),(item.adultos == 0 )?_c('v-chip',{staticClass:"elevation-12 text-capitalize",attrs:{"small":"","color":"#2EDAD5","dark":""}},[_vm._v(" Teens ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.estatus == 0 )?_c('v-chip',{staticClass:"elevation-12",attrs:{"small":"","color":"orange","dark":""},on:{"click":function($event){return _vm.crear( item, 1)}}},[_vm._v(" Crear ")]):_vm._e(),(item.estatus == 1 )?_c('v-chip',{staticClass:"elevation-12",attrs:{"small":"","color":"grey","dark":""},on:{"click":function($event){return _vm.actualizar( item, 2)}}},[_vm._v(" Enivado ")]):_vm._e(),(item.estatus == 4)?_c('v-chip',{staticClass:"elevation-12",attrs:{"small":"","color":"green","dark":""},on:{"click":function($event){return _vm.ver( item, 4)}}},[_vm._v(" VER ")]):_vm._e()]}},{key:"item.descargar",fn:function(ref){
var item = ref.item;
return [(item.estatus >= 1)?_c('v-chip',{staticClass:"elevation-12",attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.descargar( item )}}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}},{key:"item.unidad_negocio",fn:function(ref){
var item = ref.item;
return [(item.unidad_negocio==1)?_c('v-chip',{attrs:{"small":"","color":"#2EDAD5"}},[_vm._v(" INBI ")]):_c('v-chip',{attrs:{"small":"","color":"#000D4A","dark":""}},[_vm._v(" FAST ")])]}},{key:"item.opciones",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"orange","dark":""},on:{"click":function($event){return _vm.verCambio(item)}}},[_vm._v("Ver")])]}}])})],1)],1)],1)],1),(_vm.alumno)?_c('v-dialog',{attrs:{"max-width":"600px","persistent":"","fullscreen":_vm.fullscreen},model:{value:(_vm.dialogVer),callback:function ($$v) {_vm.dialogVer=$$v},expression:"dialogVer"}},[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('strong',[_vm._v(_vm._s(_vm.alumno.nombre_completo))])]),_c('v-card-text',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-0",attrs:{"height":"100%","align":"center","outlined":""}},[(_vm.fullscreen)?_c('v-img',{attrs:{"src":_vm.foto + _vm.alumno.url,"contain":"","aspect-ratio":"2"}}):_c('v-img',{attrs:{"src":_vm.foto + _vm.alumno.url,"contain":"","aspect-ratio":"2","max-width":"450"}})],1)],1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),(!_vm.fullscreen)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","text":"","large":""},on:{"click":function($event){_vm.fullscreen = true}}},[_vm._v("Zoom")]):_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","text":"","large":""},on:{"click":function($event){_vm.fullscreen = false}}},[_vm._v("Sin, Zoom")]),_c('v-btn',{staticClass:"elevation-6",attrs:{"color":"primary","dark":"","large":""},on:{"click":function($event){_vm.dialogVer = false, _vm.alumno = null}}},[_vm._v("Okey")])],1)],1)],1):_vm._e(),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar)?_c('carga'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }