<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-text>
          <v-row justify="end">
            <v-col cols="12" md="6">
              <v-col cols="12">
                <v-text-field
                  label="Buscar"
                  v-model="search"
                  dense
                  hide-details
                  filled
                  single-line
                  append-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers2"
            :items="alumnos2"
            :search="search"
            dense
          >
            <template v-slot:item.opciones="{ item }">
              <v-btn 
                color="orange" 
                dark 
                small
                tile 
                @click="$emit('addDialogCambio',item)"
              >
                <v-icon>mdi-swap-horizontal</v-icon>
              </v-btn>

            </template>
          </v-data-table>
        </v-card-text>
	    </v-col>
	  </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
	var moment = require('moment');
  moment.locale();
	import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search: '',
      
      headers2: [
        { text: 'Matricula',   value: 'matricula' },
        { text: 'Nombre',      value: 'alumno' },
        { text: 'Opciones',    value: 'opciones' },
      ],

      tipociclo:'',

      alumnos2:[],

    }),


    async created () {
      console.clear()
      // Primero necesitamos consultar el tipo de usuario y de donde viene
      await this.getAlumnosxEscuela2( )
    },

    methods: {
      getAlumnosxEscuela2 () {

      	if([1,2,3,4,5,6,17,19,21,22,23,24,25,26].includes( this.getdatosUsuario.id_plantel ) ){
          this.tipociclo = `grupo NOT LIKE '%FE%'`
        }else{ this.tipociclo = `grupo LIKE '%FE%'` }
        
        // Vaciamos el arreglo de los grupos
        this.alumnos2 = []
        // Activamos el loader
        this.cargar = true
        const payload =  { tipociclo: this.tipociclo }
        return this.$http.post('cambios.alumnos.grupos',payload).then(response=>{
          // Llenamos el arreglo de alumnos
          this.alumnos2 = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getNuevoGrupoAlumno () {
        if(this.grupoAnterior){
          this.cargar = true
          const payload =  { tipociclo: this.tipociclo, id_ciclo: this.grupoAnterior.id_ciclo }
          return this.$http.post('cambios.nuevo.grupos.ciclo',payload).then(response=>{
            this.grupos = response.data
            console.log( this.grupos )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }
      },
     
      cargarMensajes () {
      	this.$http.get('cambios.mensaje/' + this.alumno.idsolicitud_cambio).then(response=>{
        	this.mensajes = response.data
        	this.dialogMensajes = true
        	this.dialogUpdateCambio = false
        	this.cargar = false
        }).catch(error=>{
          this.error = error.body.message
          this.dialogError = true
          this.cargar = false
        	this.dialogRechazar = false
        })
      },
    },

    computed:{
      ...mapGetters ('login',['getdatosUsuario']),
    }
  }
</script>

<style>

  .v-menu__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-menu__content::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-button:increment,.v-menu__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-menu__content::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>