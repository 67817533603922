var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.autorizados.includes( _vm.getdatosUsuario.iderp ))?_c('v-container',{staticClass:"mt-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadowCard"},[_c('v-card-title',{staticClass:"subtitle-1",attrs:{"primary-title":""}},[_vm._v(" Vendedora vs Convencimiento "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.initialize()}}},[_vm._v("Buscar")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"2","xl":"2"}},[_c('v-text-field',{attrs:{"label":"Fecha de inicio","filled":"","hide-details":"","dense":"","type":"date"},model:{value:(_vm.fecha_inicio),callback:function ($$v) {_vm.fecha_inicio=$$v},expression:"fecha_inicio"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"2","xl":"2"}},[_c('v-text-field',{attrs:{"label":"Fecha final","filled":"","hide-details":"","dense":"","type":"date"},model:{value:(_vm.fecha_final),callback:function ($$v) {_vm.fecha_final=$$v},expression:"fecha_final"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.opEscuela),callback:function ($$v) {_vm.opEscuela=$$v},expression:"opEscuela"}},[_c('v-radio',{attrs:{"label":"Inbi","value":1}}),_c('v-radio',{attrs:{"label":"Fast","value":2}})],1)],1)],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.vendedoras,"item-key":"vendedora"},scopedSlots:_vm._u([{key:"item.vendedora",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.vendedora))])])]}},{key:"item.contactos",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","dark":"","color":"green"},on:{"click":function($event){_vm.folios = item.contactosAll, _vm.dialogFolios = true}}},[_vm._v(_vm._s(item.totalContactos))])]}},{key:"item.cantidadInscritos",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","dark":"","color":"green"},on:{"click":function($event){_vm.alumnosInscritos = item.alumnosInscritos, _vm.dialogInscritos = true}}},[_vm._v(_vm._s(item.cantidadInscritos))])]}},{key:"item.convencimiento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.convencimiento)+" % ")]}}],null,false,321812857)})],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1200px"},model:{value:(_vm.dialogFolios),callback:function ($$v) {_vm.dialogFolios=$$v},expression:"dialogFolios"}},[_c('v-card',[_c('v-card-title',{staticClass:"subtitle-1",attrs:{"primary-title":""}},[_vm._v(" Folios "),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","dark":"","color":"green","tile":""},on:{"click":function($event){return _vm.exportExcel(_vm.folios, 'folios')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-microsoft-excel")])],1),_c('v-btn',{staticClass:"me-n3 mt-n2",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialogFolios = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',{staticClass:"pt-4"},[_c('v-data-table',{attrs:{"headers":_vm.headersFolios,"items":_vm.folios,"dense":""},scopedSlots:_vm._u([([1778,334,568].includes( _vm.getdatosUsuario.iderp ))?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"green","dark":"","x-small":"","tile":""},on:{"click":function($event){item.seguimiento = 1, _vm.updateFolio( item.idprospectos, 1 )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"red","dark":"","x-small":"","tile":""},on:{"click":function($event){item.seguimiento = 2, _vm.updateFolio( item.idprospectos, 2 )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"color":"blue","dark":"","x-small":"","tile":""},on:{"click":function($event){item.seguimiento = 3, _vm.updateFolio( item.idprospectos, 3 )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-progress-question")])],1)]}}:null,{key:"item.seguimiento",fn:function(ref){
var item = ref.item;
return [(item.seguimiento == 1)?_c('span',[_vm._v("SI")]):_vm._e(),(item.seguimiento == 2)?_c('span',[_vm._v("NO")]):_vm._e(),(item.seguimiento == 3)?_c('span',[_vm._v("?")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialogInscritos),callback:function ($$v) {_vm.dialogInscritos=$$v},expression:"dialogInscritos"}},[_c('v-card',[_c('v-card-title',{staticClass:"subtitle-1",attrs:{"primary-title":""}},[_vm._v(" Inscritos "),_c('v-spacer'),_c('v-btn',{staticClass:"me-n3 mt-n2",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialogInscritos = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',{staticClass:"pt-4"},[_c('v-data-table',{attrs:{"headers":_vm.headersInscritos,"items":_vm.alumnosInscritos,"dense":""},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.nombre + ' ' + item.apellido_paterno + ' ' + item.apellido_materno))])]}}],null,false,4244179298)})],1)],1)],1),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar)?_c('carga'):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }