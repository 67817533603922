<template>
	<v-container class="mt-4 px-5" fluid>
	<v-row>
	  	<v-col cols="12" >
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1"><v-icon left>mdi-fingerprint</v-icon>Reloj Checador</span>
	  		    <v-spacer></v-spacer>
	  		    <v-btn 
              class="mr-2" 
              small 
              dark 
              color="green"
              @click="exportar()">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>

	  				<v-btn
              color="primary"
              dark
              @click="initialize"
              small
              rounded
            >
              Actualizar
            </v-btn>
	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row>
	  		  		<v-col cols="12" md="4" lg="2">
	  		  			<v-text-field
	  		  			  label="Fecha"
	  		  			  type="date"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  v-model="fecha"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  		<v-col cols="12" md="4" lg="2">
	  		  			<v-text-field
	  		  			  label="Fecha"
	  		  			  type="date"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  v-model="fechafin"
	  		  			></v-text-field>
	  		  		</v-col>

	  		  		<v-spacer></v-spacer>

	  		  		<v-col cols="12" md="6" lg="4">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="entradassalidas"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
			            item-key="identificador"
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip 
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>
							  
							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

								 <template v-slot:item.actions="{ item }">
							      <v-icon
								   		v-if="[334,966,1333].includes( getdatosUsuario.iderp )"
							        small
							        class="mr-2"
							        color="primary"
							        @click="editItem(item)"
							      >
							        mdi-pencil
							      </v-icon>
							    </template>

							    <template v-slot:item.manual="{ item }">
							      <v-chip
							      	v-if="item.manual"
							        small
							        dark
							        class="mr-2"
							        color="primary"
							      >
							        Manual
							      </v-chip>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>

		<v-dialog
		v-model="dialog"
		max-width="500px"
		>
		<v-card>
			<v-card-title>
			<span class="text-h5">Actualizar Asistencias</span>
			</v-card-title>

			<v-card-text>
			<v-container>
				<v-row>
				<v-col cols="12" md="6">   
					<v-text-field 
						v-model="editedItem.entrada" 
						label="Hora Entrada:" 
						dense 
						type="time"
						filled
						hide-details
					>
					</v-text-field>
			    </v-col>

				<v-col cols="12" md="6">   
					<v-text-field 
						v-model="editedItem.salida" 
						label="Hora Salida:" 
						dense 
						type="time"
						filled
						hide-details
						:disabled="editedItem.entrada == null || editedItem.entrada == ''"
					>
					</v-text-field>
			    </v-col>

				<v-col cols="12" md="6" >
                <label>Fecha:</label>
					<v-text-field
					filled
					single-line
					label="Fecha"
					type="date"
					hide-details
					v-model="fechainsert"
					dense
					clearable
					></v-text-field>
               </v-col>
			   <v-col cols="12" md="6" >
			   <label>Sucursal:</label>
					<v-autocomplete
						label="Plantel"
						id="id"
						filled
						dense
						single-line
						hide-details
						v-model="editedItem.id_sucursal[0]"
						:items="planteles"
						item-text="plantel"
						item-value="id_plantel"
						clearable
					></v-autocomplete>
				</v-col>

				</v-row>
			</v-container>
			</v-card-text>

			<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
				color="blue darken-1"
				text
				@click="close"
			>
				Cancelar
			</v-btn>
			<v-btn
				color="blue darken-1"
				class="elevation-6"
				dark
				@click="save"
			>
				Guardar
			</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>
	  </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import XLSX            from 'xlsx'


  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

	  planteles:[],

      // Filtros: 
      fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechafin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
	  	fechainsert: '',

		  dialog: false,
		  editedIndex: -1,

      editedItem: {
      	entrada: '',
				fecha: '',
				id: 0,
				id_sucursal: 0,
				id_usuario: 0,
				idchecador: 0,
				nombre_completo: '',
				plantel: '',
				puesto: '',
				salida: '',
                sucursal_registro: 0,
      },

      defaultItem: {
        entrada: '',
				fecha: '',
				id: 0,
				id_sucursal: 0,
				id_usuario: 0,
				idchecador: 0,
				nombre_completo: '',
				plantel: '',
				puesto: '',
				salida: '',
                sucursal_registro: 0,
      },

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      entradassalidas: [],
      headers: [
        { text: 'ID'               , value: 'id'                 },
        { text: 'Empleado'         , value: 'nombre_completo'    },
        { text: 'Registró en:'     , value: 'sucursal_registro'  },
        { text: 'Fecha'            , value: 'fecha'              },
        { text: 'Entrada'          , value: 'entrada'            },
        { text: 'Salida'           , value: 'salida'             },
        { text: 'Manual'           , value: 'manual'             },
				{ text: 'Actualizar'       , value: 'actions'            },
      ],
    }),

    async created () {
    	console.log( this.getdatosUsuario.id_plantel )
      await this.initialize()
	  	await this.getPlanteles()
    },

    computed:{
      ...mapGetters('login',['getdatosUsuario']),
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.entradassalidas = []

        const payload = {
        	fecha:    this.fecha,
        	fechafin: this.fechafin
        }

        return this.$http.post('usuarios.entradas.all', payload).then(response=>{

        	if( [11,12,68, 43, 58].includes( this.getdatosUsuario.idpuesto ) || ( [18,19].includes( this.getdatosUsuario.idpuesto ) && [568, 7, 161, 626, 382, 1196 ].includes( this.getdatosUsuario.iderp ) )){
        		this.entradassalidas = response.data
        	}else{

        		if( [10].includes( this.getdatosUsuario.idpuesto ) ){
        			this.entradassalidas = response.data.filter( el => { return el.id_usuario == this.getdatosUsuario.iderp })
        		}else{
        			this.entradassalidas = response.data.filter( el => { return el.id_usuario == this.getdatosUsuario.iderp || el.id_sucursal.includes( this.getdatosUsuario.id_plantel ) })
        		}
        	}

        	console.log( this.getdatosUsuario.id_plantel )


        	let k = 0
        	for( const i in this.entradassalidas ){
        		this.entradassalidas[i]['identificador'] = k
        		k++
        	}

        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

	   editItem (item) {
        this.editedIndex = this.entradassalidas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

	  close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportar(){
        let data = XLSX.utils.json_to_sheet(this.entradassalidas)
        const workbook = XLSX.utils.book_new()
        const filename = 'entradas_salidas'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });
      },

	   save () {
				this.editedItem.manual = 1
				this.editedItem.sucursal_registro = this.editedItem.id_sucursal
				console.log('id_sucursal', this.editedItem.id_sucursal)
				console.log('sucursal_registro', this.editedItem.sucursal_registro)
        if (this.editedIndex > -1) {

					this.editedItem.fechainsert = this.fechainsert;
		        this.cargar        = true
	          this.$http.post('update.entradassalidas',  this.editedItem).then(response=>{
	        	this.validarSuccess('Datos grabados correctamente')
	        	this.initialize()
        		this.close()
						this.fechainsert = ''
	        	this.cargar        = false
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })

        } else {
        	// Activamos el loader
	        this.cargar        = true
	        // Lo mandapos por el EP
          this.$http.post('add.proyectos', this.editedItem).then(response=>{
          	this.validarSuccess('Datos grabados correctamente')
          	this.initialize()
        		this.close()
	        	this.cargar        = false
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
        }
      },

	  getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
    },
  }
</script>


