<template>
  <v-container fluid class="mt-2 pa-6">

  	<!-- Barra superior -->
  	<v-row>
  		<v-col cols="12">
	  		<v-card class="back-dashboard-reporte">
          <v-img
            class="white--text align-end"
          />
          <v-card-text class="position-relative">
          	<div class="d-flex flex-no-wrap ">
          		<v-avatar
	              size="100"
	              color="transparent"
	              class="avatar-center"
	            >
	              <v-img src="@/assets/asistente/kpi.png"></v-img>
	            </v-avatar>

              <div>
                <v-card-title
                  class="text-h5 white--text"
                >
              		Hola, {{ getdatosUsuario.nombre_usuario }} !! 
              	</v-card-title>
                <v-card-subtitle class="text-h6 white--text pb-0">Bienvenido a tu panel de control</v-card-subtitle>
                <v-card-subtitle class="text-h6 pb-0">
                	<v-btn icon small @click="exportar()" class="white--text">
						        <v-icon color="white" left>
						          mdi-download
						        </v-icon> 
						        DESCARGAR
						      </v-btn>
                </v-card-subtitle>
              </div>
            </div>
          </v-card-text>
        </v-card>
  		</v-col>
  	</v-row>

  	<!-- Desglose dle Dashboard -->
    <v-row>
      <!-- RI reingreso -->
      <v-col cols="12">
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text">% RI (Reingreso)</span>
			      <v-spacer></v-spacer>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
		      	<v-data-table
		      		dense
				      :headers="headersRI"
				      :items="riPlanteles"
				      class="table-rounded"
				      hide-default-footer
				      disable-sort
				      :items-per-page="riPlanteles.length"
				    >
				      <!-- name -->
				      <template v-slot:item.nombre_completo="{item}">
				        <div class="d-flex flex-column">
				          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.nombre_completo }}</span>
				        </div>
				      </template>
				    </v-data-table>
			    </v-card-text>
			  </v-card>
  		</v-col>

  		<!-- Número de ventas -->
  		<v-col cols="12" >
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text"># Ventas ayer</span>
			      <v-spacer></v-spacer>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
		      	<v-data-table
		      		dense
				      :headers="headers"
				      :items="vendedorasActuales"
				      item-key="nombre_completo"
				      class="table-rounded"
				      hide-default-footer
				      disable-sort
				    >
				      <!-- name -->
				      <template v-slot:item.nombre_completo="{item}">
				        <div class="d-flex flex-column">
				          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.nombre_completo }}</span>
				          <!-- <small>{{ item.post }}</small> -->
				        </div>
				      </template>
				    </v-data-table>
			    </v-card-text>
			  </v-card>
  		</v-col>

  		<!-- ALUMNOS SIN VENDEDORA -->
  		<v-col cols="12" >
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text">Alumnos sin vendedora</span>
			      <v-spacer></v-spacer>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
		      	<v-data-table
		      		dense
				      :headers="headersAlumnos"
				      :items="alumnosSinVendedora"
				      item-key="nombre"
				      class="table-rounded"
				      hide-default-footer
				      disable-sort
				      :items-per-page="alumnosSinVendedora.length"
				    >
				      <!-- name -->
				      <template v-slot:item.nombre="{item}">
				        <div class="d-flex flex-column">
				          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.nombre }}</span>
				          <!-- <small>{{ item.post }}</small> -->
				        </div>
				      </template>
				    </v-data-table>
			    </v-card-text>
			  </v-card>
  		</v-col>
    </v-row>

    <!-- Dialog de carga -->
    <v-dialog
			v-model="overlay"
			persistent
			max-width="350px"
		>
			<v-card color="primary" dark>
				<v-card-text class="pt-4">
					Cargando...
					<v-progress-linear
						indeterminate
						color="white"
						class="mb-0"
					></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>

  </v-container>
</template>

<script>
import axios from 'axios';
  import XLSX from 'xlsx'

	import { mapGetters, mapActions } from 'vuex'

	export default {
	  data(){
	  	return{
	  		riPlanteles:[],
	      vendedorasActuales:[],
	      alumnosSinVendedora:[],

	      headers: [
	        { text: 'VENDEDORA'       , value: 'nombre_completo' },
	        { text: 'CONTACTOS'       , value: 'contactos_totales' },
	        { text: 'C. MMK'          , value: 'contactos_mmk' },
	        { text: 'C. VENTAS'       , value: 'contactos_ventas' },
	        { text: 'C. RECEPCION'    , value: 'contactos_recep' },
	        { text: 'C. SUPERVISORA'  , value: 'contactos_super' },
	        { text: 'C. OTROS'        , value: 'contactos_otros' },
	        { text: 'CON ADEUDO'      , value: 'conAdeudo' },
	        { text: 'SIN ADEUDO'      , value: 'sinAdeudo' },
	        { text: 'AYER'            , value: 'cantidad' },
	        { text: 'CICLO'           , value: 'ciclo' },
	      ],

	      headersRI: [
	        { text: 'ID'         , value: 'id_plantel' },
	        { text: 'PLANTEL'    , value: 'plantel' },
	        { text: 'ACTUALES'   , value: 'totalAlumnosActuales' },
	        { text: 'SIGUIENTES' , value: 'totalAlumnosSiguientes' },
	        { text: 'FALTANTES'  , value: 'faltantes' },
	        { text: 'AYER'       , value: 'totalAlumnosAyer' },
	        { text: '%RI'        , value: 'porcentajeRi' },
	      ],

	      headersAlumnos: [
	        { text: 'MATRICULA'   , value: 'matricula' },
	        { text: 'NOMBRE'      , value: 'nombre' },
	      ],


	  	}
	  },

	  async created(){
	  	await this.initialize()
	  },

	  computed:{
	  	...mapGetters('login',['getdatosUsuario']),
	  },

	  methods:{
	  	initialize() {
	  		this.overlay = true
        this.$http.get('kpi.diario').then((response) => {
          this.riPlanteles         = response.data.riPlanteles
          this.vendedorasActuales  = response.data.vendedorasActuales
          this.alumnosSinVendedora = response.data.alumnosSinVendedora
          this.overlay = false
        }).catch(error=>{
          this.overlay = false
        })
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportar(){
      	let data     = XLSX.utils.json_to_sheet(this.vendedorasActuales)
        let dataVend = XLSX.utils.json_to_sheet(this.riPlanteles)
        let datax = XLSX.utils.json_to_sheet(this.alumnosSinVendedora)

        const workbook = XLSX.utils.book_new()
        const filename = 'NI_VENDEDORA'

        XLSX.utils.book_append_sheet(workbook, data, 'NI_VENDEDORA_AYER')
        XLSX.utils.book_append_sheet(workbook, dataVend, 'RI_PORCENTAJES')
        XLSX.utils.book_append_sheet(workbook, datax, 'ALUMNOS_SIN_VENDEDORA')

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },

	  },


	}
</script>
<style type="text/css">
	.back-dashboard-reporte{
		background: #83a4d4;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to left, #b6fbff, #83a4d4);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to left, #b6fbff, #83a4d4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

		border-radius: 20px !important;
	}
</style>
<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}


</style>