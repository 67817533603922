<template>
	<v-dialog v-model="paramCerrados.estatus" hide-overlay persistent max-width="800">
    <v-card>
      <v-card-title primary-title >
        <v-icon left>mdi-cloud</v-icon> Prospectos Cerrados o Finalizados
        <v-spacer></v-spacer>
        <v-btn class="mr-10" small dark color="green" @click="exportar()">
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>

      	<v-alert border="left" colored-border type="error" elevation="2">
          1.- Los prospectos a continuación son prospectos que se encuentran cerrados o finalizados.<br/>
          2.- En caso de no encontrar el prospecto al escribirlo favor de utilizar el boton de BUSCAR.
        </v-alert>
        <v-row class="ma-1">
          <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pa-0 me-2">
            <v-text-field v-model="search" dense rounded filled single-line label="Buscar" append-icon="mdi-magnify"></v-text-field>  
          </v-col>
          <v-spacer></v-spacer> 
          <v-btn color="primary" dark @click="buscarProspectoCerrado()"> Buscar </v-btn>
        </v-row>
        <v-data-table
          :headers="headersCerrados"
          :items="prospectosCerrados"
          class="elevation-0"
          dense
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="activarProspectoCerrado(item.idprospectos)" color="success">
              mdi-check-outline
            </v-icon>
          </template>     
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error"   dark @click="cerrar()">Cancelar</v-btn>
      </v-card-actions>
      <v-btn color="transparent elevation-0" x-small absolute top right fab class="mt-5 mr-0" @click="cerrar()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'paramCerrados',
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      headersCerrados: [
        { text: 'Folio',            value: 'folio' },
        { text: 'Nombre',           value: 'nombre_completo', },  
        { text: 'Telefono',         value: 'telefono', },  
        { text: 'Nota Rechazo',     value: 'nota_rechazo', },  
        { text: 'fecha_creacion',   value: 'fecha_creacion' }, 
        { text: 'Re-Activar',       value: 'actions', sortable: false }, 
      ],
      search:'',
    }),

    created() {
      this.getProspectosCerrados()
    },
    methods:{

      getProspectosCerrados() {
        console.log(this.getdatosUsuario)
        // Abrimos modal de carga
        this.cargar = true
        // Limpiamos los prospectos
        this.prospectosCerrados  = []

        // Ver el tipo de usuario que entrara
        let tipo = 0 // null
        if( ([18,73].includes( this.getdatosUsuario.idpuesto))  && ![7,161].includes( this.getdatosUsuario.iderp ) ){
          tipo = 1 // vendedora
        }else{
          tipo = 2 // Director
        }

        return this.$http.get('prospectos.cerrados/' + tipo + '/' + this.getdatosUsuario.iderp).then((response) => {
          this.prospectosCerrados           = response.data.prospectosCerrados        //Angel Rodriguez
          console.log(this.prospectosCerrados)
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },
      buscarProspectoCerrado(){
        if(this.search != ''){
        this.cargar =true;

        // Ver el tipo de usuario que entrara
        let tipo = 0 // null
        if( ([18,73].includes( this.getdatosUsuario.idpuesto))  && ![7,161].includes( this.getdatosUsuario.iderp ) ){
          tipo = 1 // vendedora
        }else{
          tipo = 2 // Director
        }
        console.log(this.search)
        return this.$http.get('prospectos.cerradosbusqueda/'+ tipo +'/'+ this.getdatosUsuario.iderp +'/'+ this.search+ '/' + this.getdatosUsuario.id_plantel).then((response) => {
          this.prospectosCerrados = response.data.prospectosCerrados;
          this.search = '';
        }).catch(error=>{
          this.validarError( error )
        }).finally(()=> { this.cargar =false; })
        }
      },

    	activarProspectoCerrado(idprospectos){
        const payload = {
          idprospectos: idprospectos,
          idusuarioerp: this.getdatosUsuario.iderp
        }

        // Hacer la peticion de los datos
        this.$http.post('prospectos.reactivar.cerrado', payload).then((response) => {
          // Ya termino de realizar su función
          for(const i in this.prospectosCerrados){
            if(this.prospectosCerrados[i].idprospectos == idprospectos){
               this.prospectosCerrados.splice(i,1)
            }
          }

          this.dialogSuccess = true
        }).catch(error=>{
          this.validError( error )
        }).finally(()=> { this.overlay = false })
      },

      cerrar(){
      	this.paramCerrados.estatus = false
      	this.$emit('initialize')
      },

      exportar( ){
        this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
      }
    }
	}
</script>
