<template>
	<div>
		<v-data-table
	    :headers="headers"
	    :items="items"
	    class="elevation-0"
	  >				    
	    <!-- Valores que ocupan botons, chips, iconos, etc -->
	    <template v-slot:item.estatus="{ item }">
	      <v-chip 
	      	v-if="item.estatus == 1"
	      	small
	      	color="green"
	      	dark
	      >Activo</v-chip>

	      <v-chip 
	      	v-else
	      	small
	      	color="error"
	      	dark
	      >Inactivo</v-chip>
	    </template>


	    <template v-slot:item.actions="{ item }">
	      <v-icon
	        small
	        class="mr-2"
	        color="primary"
	        @click="editItem(item)"
	      >
	        mdi-pencil
	      </v-icon>
	      <v-icon
	        small
	        @click="deleteItem(item)"
	        color="error"
	      >
	        mdi-delete
	      </v-icon>
	    </template>

	    <template v-slot:no-data>
	      <v-btn
	        color="primary"
	        @click="initialize"
	        small
	      >
	        Actualizar
	      </v-btn>
	    </template>
	  </v-data-table>
	</div>
</template>

<script>
import axios from 'axios';
	export default {
		props:[
			'headers',
			'items',
			'editedItem',
			'defaultItem'
	  ],

	  methods:{
		  editItem( value ){
		  	this.$emit('editar', value)
		  },

		  deleteItem( value ){
		  	this.$emit('eliminar', value)
		  },

		  initialize( ){
		  	this.$emit('actualizar')
		  },


	  }



	}
</script>