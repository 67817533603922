<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Bonos</span>
            <v-spacer></v-spacer>

             <v-btn
              color="primary"
              dark
              class="mr-2" 
              @click="goNomina()"
              small
              tile
            >
              Nómina
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize(), bonosRI(), bonosPuntualidad()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="3" >
                <v-text-field
                  filled          
                  label="Fecha"
                  type="date"
                  hide-details
                  v-model="fecha"
                  dense
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>             
              
              <v-col cols="12" md="6">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="bonosventas"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                >

                <template v-slot:item.unidad_negocio="{ item }">
                    <v-chip v-if="item.unidad_negocio == 1" small color="#2EDAD5" dark >INBI</v-chip>
                    <v-chip v-if="item.unidad_negocio == 2" small color="#000D4A" dark >FAST</v-chip>
                </template>

                <template v-slot:item.estatus="{ item }">
                    <v-chip v-if="item.estatus == 0 || item.estatus == '' || item.estatus == null " small color="orange" dark >En revisión</v-chip>
                    <v-chip v-if="item.estatus == 1" small color="green" dark >Aceptada</v-chip>
                </template>

                  <template v-slot:item.aceptar="{ item }">
                        <v-btn
                        color="primary"
                        dark
                        small
                        @click="
                            (item_bonopuntualidad = item), (dialogAceptar = true)
                        "
                        >
                        ACEPTAR
                        </v-btn>
                  </template>

                  <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize" small>
                      Actualizar
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="bonosventas"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                >

                <template v-slot:item.tipo_bono="{ item }">
                    <v-chip v-if="item.tipo_bono == 1" small color="green" dark >Bono Ventas</v-chip>
                    <v-chip v-if="item.tipo_bono == 2" small color="blue" dark >Bono RI</v-chip>
                    <v-chip v-if="item.tipo_bono == 3" small color="orange" dark >Bono Puntualidad</v-chip>
                </template>

                <template v-slot:item.estatus="{ item }">
                    <v-chip v-if="item.estatus == 0 || item.estatus == '' || item.estatus == null " small color="orange" dark >En revisión</v-chip>
                    <v-chip v-if="item.estatus == 1" small color="green" dark >Aceptada</v-chip>
                </template>

                <template v-slot:item.historial="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      color="blue"
                      @click="mostrarHistorial(item)"
                    >
                      mdi-file-document
                    </v-icon>
                </template>

                <template v-slot:item.aceptar="{ item }">
                        <v-btn
                        color="primary"
                        dark
                        small
                        @click="(item_bonopuntualidad = item), addVentanas(item)"
                        >
                        ACEPTAR
                        </v-btn>
                </template>

                  <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize" small>
                      Actualizar
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

     <v-dialog v-model="dialogAceptarVentas" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Aceptar:</strong> ¿Estás seguro que deseas aceptar el bono de Ventas?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      large
                      class="mr-2"
                      @click="dialogAceptarVentas=false"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      large
                      @click="addNominaBonoVentas()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/contrato.jpg"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAceptarRI" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Aceptar:</strong> ¿Estás seguro que deseas aceptar el bono de RI?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      large
                      class="mr-2"
                      @click="dialogAceptarRI=false"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      large
                      @click="addNominaBonoRI()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/contrato.jpg"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAceptarPuntualidad" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Aceptar:</strong> ¿Estás seguro que deseas aceptar el bono de puntualidad?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      large
                      class="mr-2"
                      @click="dialogAceptarPuntualidad=false"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      large
                      @click="addNominaBonoPuntualidad()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/contrato.jpg"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogBonosVenta" max-width="500px">
      <v-card>
      <v-card-title>
        <span class="text-h6">Detalles Bono Venta:</span>
        <v-spacer></v-spacer>
        <v-btn
        color="red"
        dark
        rounded
        small
        icon
        @click="dialogBonosVenta = false "
        >
        <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="black--text">
        <v-row>
        <v-col cols="12">
        <v-data-table
        :headers="headersBonosVentas"
        :items="bonoventa"
        class="elevation-0"
        dense
        >
        <template v-slot:item.unidad_negocio="{ item }">
            <v-chip v-if="item.unidad_negocio == 1" small color="#2EDAD5" dark >INBI</v-chip>
            <v-chip v-if="item.unidad_negocio == 2" small color="#000D4A" dark >FAST</v-chip>
        </template>

        </v-data-table>
        </v-col>
        </v-row>
      </v-card-text>
      </v-card>
    </v-dialog>

     <v-dialog v-model="dialogBonosRI" max-width="700px">
      <v-card>
      <v-card-title>
        <span class="text-h6">Detalles Bono RI:</span>
        <v-spacer></v-spacer>
        <v-btn
        color="red"
        dark
        rounded
        small
        icon
        @click="dialogBonosRI = false "
        >
        <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="black--text">
        <v-row>
        <v-col cols="12">
        <v-data-table
        :headers="headersBonosRI"
        :items="bonori"
        class="elevation-0"
        dense
        >
        <template v-slot:item.hora_regular="{ item }">
          <v-chip v-if="item.hora_regular" small color="green" dark >Día Regular</v-chip>
          <v-chip v-if="item.hora_regular == null" small color="error" dark >Retardo</v-chip>
        </template>

        </v-data-table>
        </v-col>
        </v-row>
      </v-card-text>
      </v-card>
    </v-dialog>

     <v-dialog v-model="dialogBonosPuntualidad" max-width="500px">
      <v-card>
      <v-card-title>
        <span class="text-h6">Detalles Bono Puntualidad:</span>
        <v-spacer></v-spacer>
        <v-btn
        color="red"
        dark
        rounded
        small
        icon
        @click="dialogBonosPuntualidad = false "
        >
        <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="black--text">
        <v-row>
        <v-col cols="12">
        <v-data-table
        :headers="headersBonosPuntualidad"
        :items="bonopuntualidad"
        class="elevation-0"
        dense
        >
        <template v-slot:item.estatus_entradas_validas="{ item }">
          <v-chip v-if="item.estatus_entradas_validas == 1" small color="blue" dark >Meta alcanzada</v-chip>
          <v-chip v-if="item.estatus_entradas_validas == 0" small color="black" dark >Meta no alcanzada</v-chip>
        </template>

        </v-data-table>
        </v-col>
        </v-row>
      </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,
    dialogAceptar: false,
    dialogBonosVenta: false,
    dialogBonosRI: false,
    dialogBonosPuntualidad: false,
    dialogAceptarPuntualidad: false,
    dialogAceptarRI: false,
    dialogAceptarVentas: false,
    headers: [
      // { text: "ID", align: "start", value: "id_usuario_ultimo_cambio" },
      { text: "Empleado", value: "vendedora" },
      { text: "Puesto", value: "puesto" },
      // { text: "Escuela", value: "unidad_negocio" },
      { text: "Tipo Bono", value: "tipo_bono" },
      // { text: "Total Ventas", value: "total" },
      { text: "Bono", value: "bono_ventas" },
      { text: "Detalles", value: "historial" },
      { text: "Estatus", value: "estatus" },
      { text: "Aceptar", value: "aceptar" }
    ],

    headersBonosVentas: [
      { text: "Escuela", value: "unidad_negocio" },
      { text: "Total Ventas", value: "total" },
    ],

    headersBonosRI: [
      { text: "Objetivo Semana 2", value: "objetivosemana2" },
      { text: "Total Semana 2", value: "totalsemana2" },
      { text: "Objetivo Semana 5", value: "objetivosemana5" },
      { text: "Total Semana 5", value: "totalsemana5" },
      { text: "Categoría", value: "idcategorias_encargadas" },
    ],

    headersBonosPuntualidad: [
      { text: "Dias Validos", value: "numero_entradas_validas" },
      { text: "Estatus", value: "estatus_entradas_validas" },
    ],

    bonospuntualidad: [],
    bonosventas: [],
    bonosri: [],
    editedIndex: -1,
    editedItem: {
      idhoras_extras: "",
      fecha: "",
      hora_inicio: "",
      hora_fin: "",
      cantidad_horas: "",
      notas: "",
      estatus: 0,
      id_creo: ""
    },
    defaultItem: {
      idhoras_extras: "",
      fecha: "",
      hora_inicio: "",
      hora_fin: "",
      cantidad_horas: "",
      notas: "",
      estatus: 0,
      id_creo: ""
    },

    dialogSuccess: false,
    overlay: false,
    dialogError: false,
    error: "",
    successMensaje: "",
    errorBack: false,
    textoCopiado: "COPIAR",
    idcurso: 0,
    info: 0,
    dialog_mensaje_rechazo: false,
    idhistorial: [],
    fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    item_bonopuntualidad: null,
    editedItem2: {
      mensaje: "",
    },
    mensajes: [],

    ciclos: [],
    ciclo: null,
    estatus: null,
    alumnos: [],
    reglas: [],
    cursosEscuela: [],
    bonoventa: [],
    bonori: [],
    bonopuntualidad: [],
    bonospuntualidad: "",
    bonosventas: "",
    bonosri: "",
    total_dias: "",
    // tabla
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
    formTitle() {
      return this.editedIndex === -1
        ? "Agregar Horas Extras"
        : "Editar Horas Extras";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "editedItem.escuela"(val) {
      this.cursosEscuela = this.cursos.filter((el) => {
        return el.escuela == val;
      });
    },
  },

  created() {

    this.fecha = this.$route.params.fecha ? this.$route.params.fecha : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    this.search = this.$route.params.nombre ? this.$route.params.nombre : ''
    
    this.initialize();
    this.bonosPuntualidad();
    this.bonosRI();
    // this.getCiclos();
    // this.getAlumnos();
    // this.getReglas();
  },

  methods: {
    initialize() {
      this.bonosventas = [];
      this.overlay = true;
      const payload = {
      fecha:  this.fecha
      };

      this.cargar = true

      this.$http
        .post("bonosventas.get.bonosventas", payload)
        .then((response) => {
          this.bonosventas = response.data;
          for (const objeto of this.bonosventas) {  
            objeto.tipo_bono = 1;
            objeto.puesto = 'Encargada de Sucursal';
          }
          this.overlay = false;

        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    bonosRI() {
      this.bonosri = [];
      this.overlay = true;
      const payload = {
      fecha:  this.fecha
      };

      this.cargar = true

      this.$http
        .post("bonosri.get.bonosri", payload)
        .then((response) => {
          this.bonosri = response.data;

          for (const objeto of this.bonosri) {  
            objeto.id_bonori = objeto.id;
            delete objeto.id;
            objeto.vendedora = objeto.nombre_completo;
            objeto.puesto = objeto.puesto;
            objeto.tipo_bono = 2;
            objeto.bono_ventas = objeto.bonori;
          }

          this.bonosventas = this.bonosventas.concat(this.bonosri);
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    bonosPuntualidad() {
      this.bonospuntualidad = [];
      this.overlay = true;
      const payload = {
      fecha:  this.fecha
      };

      this.cargar = true

      this.$http
        .post("bonospuntualidad.get.bonospuntualidad", payload)
        .then((response) => {
          this.bonospuntualidad = response.data.respuesta;

          for (const objeto of this.bonospuntualidad) {
            objeto.id_puntualidad = objeto.id;
            delete objeto.id;  
            objeto.vendedora = objeto.nombre_completo;
            objeto.puesto = objeto.puesto;
            objeto.tipo_bono = 3;
            if(objeto.estatus_entradas_validas == 0) {  objeto.bono_puntualidad =  0  }
            objeto.bono_ventas = objeto.bono_puntualidad;
          }

          this.bonosventas = this.bonosventas.concat(this.bonospuntualidad);

          console.log('Info de la tabla',this.bonosventas)
          this.overlay = false;
          this.cargar = false
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },


    mostrarHistorial(item){

      if(item.tipo_bono == 1) {
      this.dialogBonosVenta = true
       this.bonoventa = [];
          this.bonoventa.push({
            unidad_negocio: item.unidad_negocio,
            total: item.total,
          }) 
      } else if(item.tipo_bono == 2) {
      this.dialogBonosRI = true
      this.bonori = [];
          this.bonori.push({
            objetivosemana2: item.objetivosemana2,
            totalsemana2: item.totalsemana2,
            objetivosemana5: item.objetivosemana5,
            totalsemana5: item.totalsemana5,
            idcategorias_encargadas: item.idcategorias_encargadas,
          }) 
      } else if(item.tipo_bono == 3) {
      this.dialogBonosPuntualidad = true
      this.bonopuntualidad = [];
          this.bonopuntualidad.push({
            numero_entradas_validas: item.numero_entradas_validas,
            estatus_entradas_validas: item.estatus_entradas_validas,
          })
      } 
    },


    addVentanas(item){

      if(item.tipo_bono == 1) {
      this.dialogAceptarVentas = true
     
      } else if(item.tipo_bono == 2) {
      this.dialogAceptarRI = true
          
      } else if(item.tipo_bono == 3) {
      this.dialogAceptarPuntualidad = true
      } 
    },

    addNominaBonoVentas () {
    this.cargar = true
    const payload = {
    fecha: this.fecha,
    estatus: 1,
    bono_ventas: this.item_bonopuntualidad.bono_ventas,
    idusuario: this.item_bonopuntualidad.id_usuario_ultimo_cambio,
    nombre_completo: this.item_bonopuntualidad.vendedora,
    escuela: this.item_bonopuntualidad.unidad_negocio
    }
    // Lo mandapos por el EP
    this.$http.post('bonosventas.add.bonosventas', payload).then(response=>{
    this.validarSuccess('Datos grabados correctamente')
    this.dialogAceptarVentas = false
    this.initialize();
    this.comision_estatus();
    this.cargar = false
    }).catch( error =>{
        this.validarError( error.response.data.message )
    }).finally( () => { this.cargar = false })
        
    },


    addNominaBonoRI () {
    this.cargar = true
    const payload = {
    fecha: this.fecha,
    estatus: 1,
    id_empleado: this.item_bonopuntualidad.id,
    nombre_completo: this.item_bonopuntualidad.nombre_completo,
    idusuario: this.item_bonopuntualidad.id_usuario,
    id_plantel: this.item_bonopuntualidad.id_plantel,
    plantel: this.item_bonopuntualidad.plantel,
    id_puesto: this.item_bonopuntualidad.idpuesto,
    puesto: this.item_bonopuntualidad.puesto,
    idcategorias_encargadas: this.item_bonopuntualidad.idcategorias_encargadas,
    objetivosemana2: this.item_bonopuntualidad.objetivosemana2,
    objetivosemana5: this.item_bonopuntualidad.objetivosemana5,
    totalsemana2: this.item_bonopuntualidad.totalsemana2,
    totalsemana5: this.item_bonopuntualidad.totalsemana5,
    bonori: this.item_bonopuntualidad.bonori
    }
    // Lo mandapos por el EP
    this.$http.post('bonosri.add.bonosri', payload).then(response=>{
    this.validarSuccess('Datos grabados correctamente')
    this.dialogAceptarRI = false
    this.initialize();
    this.comision_estatus();
    this.cargar = false
    }).catch( error =>{
        this.validarError( error.response.data.message )
    }).finally( () => { this.cargar = false })
        
    },


    addNominaBonoPuntualidad () {
    this.cargar = true
    const payload = {
    fecha: this.fecha,
    estatus: 1,
    id_empleado: this.item_bonopuntualidad.id,
    id_categorias_encargadas: this.item_bonopuntualidad.idcategorias_encargadas,
    entradas_validas: this.item_bonopuntualidad.numero_entradas_validas,
    entradas_laboradas: this.item_bonopuntualidad.numero_entradas_laboradas,
    estatus_entradas_validas: this.item_bonopuntualidad.estatus_entradas_validas,
    estatus_entradas_laboradas: this.item_bonopuntualidad.estatus_entradas_laboradas,
    bono_puntualidad: this.item_bonopuntualidad.bono_puntualidad,
    idusuario: this.item_bonopuntualidad.id_usuario
    }
    // Lo mandapos por el EP
    this.$http.post('bonospuntualidad.add.bonospuntualidad', payload).then(response=>{
    this.validarSuccess('Datos grabados correctamente')
    this.dialogAceptarPuntualidad = false
    this.initialize();
    this.comision_estatus();
    this.cargar = false
    }).catch( error =>{
        this.validarError( error.response.data.message )
    }).finally( () => { this.cargar = false })
        
    },

    editItem(item) {
      this.editedIndex = this.incidencias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.horasextras.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    copiarPortapapeles() {
      navigator.clipboard
        .writeText(this.error)
        .then(() => {
          this.textoCopiado = "COPIADO";
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });
    },

    //Trae la informacion de toda la fila de alumnos en "Agregar Incidencia"
    infoAlumnos() {
      const alumnoSeleccionado = this.alumnos.find(
        (alumno) => alumno.iderp === this.editedItem.idusuarioerp
      );

      this.info = alumnoSeleccionado.tipo_curso;
     
    },

    goNomina(){
			this.$router.push({name: "Nomina"})
		},

    aceptarHorasExtras() {

      this.cargar = true;
      const payload = {
        estatus: 1,
        id: this.horaextramensaje.idhoras_extras,
      };

      return this.$http
        .post("horas_extras.update.estatus", payload)
        .then((response) => {
          this.dialogAceptar = false;
          this.horaextramensaje = null;
          this.initialize();
          this.cargar = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
  },
};
</script>
