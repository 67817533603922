<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1"><v-icon left>mdi-fingerprint</v-icon>Monto Jornada Laboral </span>
	  		    <v-spacer></v-spacer>
	  		    <v-btn 
              class="mr-2" 
              small 
              dark 
              color="green"
              @click="exportarInscripciones()">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>

             <v-btn
              color="primary"
              dark
              class="mr-2" 
              @click="goNomina()"
              small
              tile
            >
              Nómina
            </v-btn>

            <v-btn
              class="mr-2"
              small
              color="primary"
              @click="dialogReporteRetardos = true"
            >
              <v-icon small>mdi-clock</v-icon>Retardos
            </v-btn>

	  		    <v-btn
              color="primary"
              dark
              @click="initialize"
              small
              rounded
            >
              Actualizar
            </v-btn>
	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row>
	  		  		<v-col cols="12" md="4" lg="2">
	  		  			<v-text-field
	  		  			  label="Fecha"
	  		  			  type="date"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  v-model="fecha"
	  		  			></v-text-field>
	  		  		</v-col>

	  		  	<v-spacer></v-spacer>

					  <!-- <v-col cols="12" class="py-0">
              <div class="grey--text"><b>Rango de fechas</b></div>
              <div>
              <span class="font-weight-bold text-h6">{{
                encabezados.fecha_inicio_format
              }}</span>
              <span class="font-weight-bold mx-4">al</span>
              <span class="font-weight-bold text-h6">{{
                encabezados.fecha_final_format
              }}</span>
              </div>
				    </v-col>  -->

	  		  		<v-col cols="12" md="6" lg="4">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="filterNoTeacher"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			           		    @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
								dense
								item-key="idchecador"	
								id="ks"					
							  >

							    <!-- Valores que ocupan botons, chips, iconos, etc -->
                  <template v-slot:item.tipo_pago="{ item }">
							      <v-chip v-if="item.tipo_pago == 2" small color="green" dark >Hora</v-chip>
							      <v-chip v-if="item.tipo_pago == 1" small color="blue" dark >Dia</v-chip>
                    <v-chip v-if="item.tipo_pago == null || item.tipo_pago == 0" small color="orange" dark >Sin Tipo Pago</v-chip>
							    </template>

							    <template v-slot:item.estatus1="{ item }">
							      <v-chip v-if="item.estatus1 == 0" small color="green" dark >Asistencia</v-chip>
							      <v-chip v-if="item.estatus1 == 1" small color="error" dark >Retardo</v-chip>
                    <v-chip v-if="item.estatus1 == 2" small color="blue" dark >Descanso</v-chip>
                    <v-chip v-if="item.estatus1 == 3" small color="purple" dark >Vacaciones</v-chip>
                    <v-chip v-if="item.estatus1 == 4" small color="pink" dark >Festivo</v-chip>
                    <v-chip v-if="item.estatus1 == null" small color="orange" dark >Sin Registro</v-chip>
							    </template>

                  <template v-slot:item.estatus2="{ item }">
							      <v-chip v-if="item.estatus2 == 0" small color="green" dark >Asistencia</v-chip>
							      <v-chip v-if="item.estatus2 == 1" small color="error" dark >Retardo</v-chip>
                    <v-chip v-if="item.estatus2 == 2" small color="blue" dark >Descanso</v-chip>
                    <v-chip v-if="item.estatus2 == 3" small color="purple" dark >Vacaciones</v-chip>
                    <v-chip v-if="item.estatus2 == 4" small color="pink" dark >Festivo</v-chip>
                    <v-chip v-if="item.estatus2 == null" small color="orange" dark >Sin Registro</v-chip>
							    </template>

                  <template v-slot:item.estatus3="{ item }">
							      <v-chip v-if="item.estatus3 == 0" small color="green" dark >Asistencia</v-chip>
							      <v-chip v-if="item.estatus3 == 1" small color="error" dark >Retardo</v-chip>
                    <v-chip v-if="item.estatus3 == 2" small color="blue" dark >Descanso</v-chip>
                    <v-chip v-if="item.estatus3 == 3" small color="purple" dark >Vacaciones</v-chip>
                    <v-chip v-if="item.estatus3 == 4" small color="pink" dark >Festivo</v-chip>
                    <v-chip v-if="item.estatus3 == null" small color="orange" dark >Sin Registro</v-chip>
							    </template>

                 <template v-slot:item.estatus4="{ item }">
							      <v-chip v-if="item.estatus4 == 0" small color="green" dark >Asistencia</v-chip>
							      <v-chip v-if="item.estatus4 == 1" small color="error" dark >Retardo</v-chip>
                    <v-chip v-if="item.estatus4 == 2" small color="blue" dark >Descanso</v-chip>
                    <v-chip v-if="item.estatus4 == 3" small color="purple" dark >Vacaciones</v-chip>
                    <v-chip v-if="item.estatus4 == 4" small color="pink" dark >Festivo</v-chip>
                    <v-chip v-if="item.estatus4 == null" small color="orange" dark >Sin Registro</v-chip>
							    </template>

                  <template v-slot:item.estatus5="{ item }">
							      <v-chip v-if="item.estatus5 == 0" small color="green" dark >Asistencia</v-chip>
							      <v-chip v-if="item.estatus5 == 1" small color="error" dark >Retardo</v-chip>
                    <v-chip v-if="item.estatus5 == 2" small color="blue" dark >Descanso</v-chip>
                    <v-chip v-if="item.estatus5 == 3" small color="purple" dark >Vacaciones</v-chip>
                    <v-chip v-if="item.estatus5 == 4" small color="pink" dark >Festivo</v-chip>
                    <v-chip v-if="item.estatus5 == null" small color="orange" dark >Sin Registro</v-chip>
							    </template>

                   <template v-slot:item.estatus6="{ item }">
							      <v-chip v-if="item.estatus6 == 0" small color="green" dark >Asistencia</v-chip>
							      <v-chip v-if="item.estatus6 == 1" small color="error" dark >Retardo</v-chip>
                    <v-chip v-if="item.estatus6 == 2" small color="blue" dark >Descanso</v-chip>
                    <v-chip v-if="item.estatus6 == 3" small color="purple" dark >Vacaciones</v-chip>
                    <v-chip v-if="item.estatus6 == 4" small color="pink" dark >Festivo</v-chip>
                    <v-chip v-if="item.estatus6 == null" small color="orange" dark >Sin Registro</v-chip>
							    </template>

                   <template v-slot:item.estatus7="{ item }">
							      <v-chip v-if="item.estatus7 == 0" small color="green" dark >Asistencia</v-chip>
							      <v-chip v-if="item.estatus7 == 1" small color="error" dark >Retardo</v-chip>
                    <v-chip v-if="item.estatus7 == 2" small color="blue" dark >Descanso</v-chip>
                    <v-chip v-if="item.estatus7 == 3" small color="purple" dark >Vacaciones</v-chip>
                    <v-chip v-if="item.estatus7 == 4" small color="pink" dark >Festivo</v-chip>
                  <v-chip v-if="item.estatus7 == null" small color="orange" dark >Sin Registro</v-chip>
							    </template>

                  
					        <template v-slot:item.asistencias="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      color="blue"
                      @click="mostrarAsistencias(item)"
                    >
                      mdi-file-document
                    </v-icon>
                  </template>
							  
							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
		</v-card>
	</v-col>
  <v-dialog
      v-model="dialogReporteRetardos"
      max-width="900"
    >
      <v-card>
        <v-card-title primary-title >
          <v-icon left>mdi-clock</v-icon> Retardos de la semana
          <v-spacer></v-spacer>
          <v-btn 
            class="mr-2" 
            small dark color="green"
            tile
            @click="exportarReporteRetrasos()"
          >
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
          <v-btn 
              class="mr-2" 
              small dark color="red"
              @click="crearPdf()"
              tile
            >
              <v-icon small>mdi-folder-zip</v-icon>
            </v-btn>
        </v-card-title>
        <v-col cols="12" md="6" lg="4">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
                  single-line
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search2"
	  		  			></v-text-field>
	  		</v-col>
        <v-card-text >

        	<!-- <v-row>
            <v-col cols="12" md="4" lg="3">
              <v-radio-group
                v-model="escuela"
                row
                class="ml-2"
              >
                <v-radio label="Fast" :value="2"></v-radio>
                <v-radio label="Inbi" :value="1"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row> -->

          <v-data-table
            :headers="headersReporteRetardos"
            :items=" filterNoTeacher"
            class="elevation-0"
            :search="search2"
            dense
          >                 
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error"   dark @click="dialogReporteRetardos = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAsistencias" max-width="1300px">
      <v-card>
      <v-card-title>
        <span class="text-h6">Asistencias:</span>
        <v-spacer></v-spacer>
        <v-btn
        color="red"
        dark
        rounded
        small
        icon
        @click="dialogAsistencias = false "
        >
        <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-col cols="12" md="6">
        <label></label>
        <v-text-field
        name="name"
        label="Buscar"
        id="id"
        filled
        dense
        single-line
        hide-details
        append-icon="mdi-magnify"
        v-model="search3"
        ></v-text-field>
      </v-col>

      <v-card-text class="black--text">
        <v-row>
        <v-col cols="12">
        <v-data-table
        :headers="headersAsistencias"
        :items="asistencias"
        :search="search3"
        class="elevation-0"
        dense
        >
        <template v-slot:item.hora_regular="{ item }">
          <v-chip v-if="item.hora_regular" small color="green" dark >Día Regular</v-chip>
          <v-chip v-if="item.hora_regular == null" small color="error" dark >Retardo</v-chip>
        </template>

        </v-data-table>
        </v-col>
        </v-row>
      </v-card-text>
      </v-card>
    </v-dialog>
	</v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import XLSX            from 'xlsx'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores,funcionesExcel  ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

	  encabezados: null,
	  encabezados2: null,

      // Filtros: 
      fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // fechafin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
     

      // tabla
	  search:'',
	  search2:'',
    search3:'',
      page: 1,
	  page2: 1,
      pageCount: 0,
      itemsPerPage: 10,
      entradassalidas: [],
	  entradassalidasteachers: [],
    asistencias: [],
    dialogReporteRetardos: false,
    dialogAsistencias: false,
      headers: [
        { text: 'ID'                    , value: 'id'                },
        { text: 'Empleado'              , value: 'nombre_completo'    },
        { text: 'Puesto'                , value: 'puesto'             },
        // { text: 'Plantel'               , value: 'plantel'            },
		    { text: 'Sucursal de Registro'  , value: 'asistencias'  },
	    	{ text: 'Fecha Ingreso'         , value: 'fecha_ingreso'  },
        { text: 'Tipo Pago'             , value: 'tipo_pago'  },
	    	{ text: 'Dias Laborados'        , value: 'dia_laboral'  },
	    	{ text: 'Total Horas Laboradas' , value: 'horas_laboradas_total'  },
        { text: 'Sueldo diario'         , value: 'sueldo_dia'           },
        { text: 'Monto Jornada Laboral' , value: 'monto_laborado'    },
        { text: 'Retardos'              , value: 'retardos'  },
	    	{ text: 'Horas Extras Lector'   , value: 'horas_extras'  },
        { text: 'Horas Extras Solicitadas'  , value: 'cantidad_horass'  },
        { text: 'Horas Extras Aceptadas'  , value: 'cantidad_horas'  },
	    	{ text: 'Sábado/Entrada'        , value: 'entrada1'          },
        { text: 'Sábado/Salida'         , value: 'salida1'            },
        { text: 'Hrs Laboradas'         , value: 'horas_laboradas1'  },
        { text: 'Estatus'               , value: 'estatus1'          },
		    { text: 'Domingo/Entrada'       , value: 'entrada2'          },
        { text: 'Domingo/Salida'        , value: 'salida2'           },
        { text: 'Hrs Laboradas'         , value: 'horas_laboradas2' },
        { text: 'Estatus'               , value: 'estatus2'         },
        { text: 'Lunes/Entrada'         , value: 'entrada3'          },
        { text: 'Lunes/Salida'          , value: 'salida3'            },
        { text: 'Hrs Laboradas'         , value: 'horas_laboradas3'   },
        { text: 'Estatus'               , value: 'estatus3'           },
        { text: 'Martes/Entrada'        , value: 'entrada4'           },
        { text: 'Martes/Salida'         , value: 'salida4'            },
        { text: 'Hrs Laboradas'         , value: 'horas_laboradas4'   },
        { text: 'Estatus'               , value: 'estatus4'           },
        { text: 'Miércoles/Entrada'     , value: 'entrada5'         },
        { text: 'Miércoles/Salida'      , value: 'salida5'           },
        { text: 'Hrs Laboradas'         , value: 'horas_laboradas5'  },
        { text: 'Estatus'               , value: 'estatus5'          },
        { text: 'Jueves/Entrada'        , value: 'entrada6'          },
        { text: 'Jueves/Salida'         , value: 'salida6'            },
        { text: 'Hrs Laboradas'         , value: 'horas_laboradas6' },
        { text: 'Estatus'               , value: 'estatus6'          },
        { text: 'Viernes/Entrada'       , value: 'entrada7'         },
        { text: 'Viernes/Salida'        , value: 'salida7'           },
        { text: 'Hrs Laboradas'         , value: 'horas_laboradas7'  },
        { text: 'Estatus'               , value: 'estatus7'          },
      ],

      headersReporteRetardos: [
        { text: 'ID'                    , value: 'id'                },
        { text: 'Empleado'              , value: 'nombre_completo'    },
        { text: 'Puesto'                , value: 'puesto'             },
        { text: 'Plantel'               , value: 'plantel'            },
	    	{ text: 'Hora Ingreso'          , value: 'horario_ingreso'  },
        { text: '# Retardos'            , value: 'retardos'  },
        { text: 'Dias Retardos'         , value: 'dias_retraso'  }
      ],

      headersAsistencias: [
      { text: "Hora Entrada", value: "hora_entrada" },
      { text: "Hora Salida", value: "hora_salida" },
      { text: "Chequeo Entrada", value: "chequeo_entrada" },
      { text: "Chequeo Salida", value: "chequeo_salida" },
      { text: "¿Regular?", value: "hora_regular" },
      { text: "Horas Extras", value: "hora_extra" },
      { text: "Horas Negativas", value: "hora_negativa" },
      { text: "Horas Trabajo", value: "hora_trabajo" },
      { text: "Horas Trabajadas", value: "hora_trabajada" },
      { text: "Aceptar", value: "aceptar" },
      ],
    }),

	computed: {

    filterTeacher( ){
      	let teachers = this.entradassalidasteachers;
        return teachers.filter(el => el.puesto === 'Teacher');
    },

	 filterNoTeacher( ){
      	let noteachers = this.entradassalidas;
        return noteachers.filter(el => el.puesto != 'Teacher' && el.puesto != 'Sin puesto');    
    }

	},

    async created () {

      console.log( this.$route.params.fecha )

      if( this.$route.params.fecha ){
        this.fecha = this.$route.params.fecha
        this.search = this.$route.params.nombre ? this.$route.params.nombre : ''
      }

      await this.initialize()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.entradassalidas = []
		    this.entradassalidasteachers = []

        const payload = {
        	fecha:    this.fecha,      
        }
        return this.$http.post('usuarios.entradas', payload).then(response=>{
        	this.entradassalidas = response.data.result3;
          this.entradassalidasteachers = response.data.result2;
          this.encabezados = response.data.encabezados;
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportar(){
        let data = XLSX.utils.json_to_sheet(this.entradassalidas)

        const workbook = XLSX.utils.book_new()
        const filename = 'entradas_salidas'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });
      },

    mostrarAsistencias(item){
          this.dialogAsistencias = true

      for (const i in item.entradas){
        this.asistencias.push({
          chequeo_entrada: item.entradas[i],
          chequeo_salida: item.salidas[i],
          hora_regular: item.hora_regular[i],
          hora_extra: item.hora_extra[i],
          hora_negativa: item.hora_negativa[i],
          hora_entrada: i == 0 ? item.horario_ingreso_fin : item.horario_ingreso,
          hora_salida: i == 0 ? item.horario_salida_fin : item.horario_salida,
        })
      }
    },

	  exportarInscripciones( ){
    	const empleados = this.filterNoTeacher.map(({ id, nombre_completo, puesto, plantel, sucursal_registro, fecha_ingreso, dia_laboral, horas_laboradas_total, horas_extras, entrada1, salida1, horas_laboradas1,  entrada2, salida2, horas_laboradas2, entrada3, salida3, horas_laboradas3,  entrada4, salida4, horas_laboradas4,  entrada5, salida5, horas_laboradas5,  entrada6, salida6, horas_laboradas6, entrada7, salida7, horas_laboradas7 }) => 
			({ id, nombre_completo, puesto, plantel, sucursal_registro, fecha_ingreso, dia_laboral, horas_laboradas_total, horas_extras, "Sabado/Entrada": entrada1, "Sabado/Salida":salida1, "Sabado/Horas":horas_laboradas1,  "Domingo/Entrada":entrada2, "Domingo/Salida":salida2, "Domingo/Horas":horas_laboradas2,  "Lunes/Entrada":entrada3, "Lunes/Salida":salida3, "Lunes/Horas":horas_laboradas3,  "Martes/Entrada":entrada4, "Martes/Salida":salida4, "Martes/Horas":horas_laboradas4, "Miercoles/Entrada":entrada5, "Miercoles/Salida":salida5, "Miercoles/Horas":horas_laboradas5, "Jueves/Entrada":entrada6, "Jueves/Salida":salida6, "Jueves/Horas":horas_laboradas6, "Viernes/Entrada":entrada7, "Viernes/Salida":salida7, "Viernes/Horas":horas_laboradas7  }));
				this.exportExcel( empleados, 'Prenomina')
    },

    exportarReporteRetrasos( ){
    	const retrasos = this.filterNoTeacher.map(({ id, nombre_completo, puesto, plantel, horario_ingreso, retardos, dias_retraso }) => 
			({ id, nombre_completo, puesto, plantel, horario_ingreso, retardos, "Dias Retraso": dias_retraso.join(', ') }));
				this.exportExcel( retrasos, 'Reporte Retrasos')
    },

    goNomina(){
			this.$router.push({name: "Nomina"})
		},

    downloadZip(){
        this.cargar    = true
        const payload = {
        infoTabla1: this.filterNoTeacher,
        encabezados: this.encabezados
        };

        this.$http.post('reporte_modelo_ventas.reporte.retardos', payload).then(response=>{
        window.open( axios.defaults.baseURL + 'recibos-retardos/NombrePdf.pdf')
        this.cargar    = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false });
      },

      async crearPdf() {
        this.cargar    = true
          this.downloadZip();
        this.cargar    = false
      },

    },
  }
</script>


<style>


  #ks td:nth-child(15) {
    background-color: #E8E8E8 !important;
  }

  #ks td:nth-child(16) {
    background-color: #E8E8E8 !important;
  }

  #ks td:nth-child(17) {
    background-color: #E8E8E8 !important;
  }

  #ks td:nth-child(18) {
    background-color: #E8E8E8 !important;
  }



  #ks td:nth-child(23) {
    background-color: #E8E8E8 !important;
  }

  #ks td:nth-child(24) {
    background-color: #E8E8E8 !important;
  }

  #ks td:nth-child(25) {
    background-color: #E8E8E8 !important;
  }

  #ks td:nth-child(26) {
    background-color: #E8E8E8 !important;
  }



  #ks td:nth-child(31) {
    background-color: #E8E8E8 !important;
  }

  #ks td:nth-child(32) {
    background-color: #E8E8E8 !important;
  }

  #ks td:nth-child(33) {
    background-color: #E8E8E8 !important;
  }

  #ks td:nth-child(34) {
    background-color: #E8E8E8 !important;
  }



  #ks td:nth-child(39) {
    background-color: #E8E8E8 !important;
  }

  #ks td:nth-child(40) {
    background-color: #E8E8E8 !important;
  }

  #ks td:nth-child(41) {
    background-color: #E8E8E8 !important;
  }

  #ks td:nth-child(42) {
    background-color: #E8E8E8 !important;
  }



  #ks3 td:nth-child(2) {
    background-color: #E8E8E8 !important;
  }

  #ks3 td:nth-child(3) {
    background-color: #E8E8E8 !important;
  }

  #ks3 td:nth-child(4) {
    background-color: #E8E8E8 !important;
  }

  #ks3 td:nth-child(5) {
    background-color: #E8E8E8 !important;
  }

  #ks3 td:nth-child(13) {
    background-color: #E8E8E8 !important;
  }

  #ks3 td:nth-child(14) {
    background-color: #E8E8E8 !important;
  }

  #ks3 td:nth-child(15) {
    background-color: #E8E8E8 !important;
  }

  #ks3 td:nth-child(16) {
    background-color: #E8E8E8 !important;
  }


</style>




