<template>
  <v-container class="mt-4 ml-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="alumnos"
            :search="search"
          >
            <template v-slot:item.estatus="{ item }">
              <v-chip v-if="item.estatus == 0 "  small color="red"      dark> En proceso </v-chip>
              <v-chip v-if="item.estatus == 1 "  small color="orange"   dark> Creado     </v-chip>
              <v-chip v-if="item.estatus == 2 "  small color="grey"     dark> Enivado    </v-chip>
              <v-chip v-if="item.estatus == 3 "  small color="blue"     dark> Recibido   </v-chip>
              <v-chip v-if="item.estatus == 4 "  small color="green"    dark> Entregado  </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-chip v-if="item.estatus == 2"  small color="blue"   class="elevation-12"  dark @click="actualizar( item, 3)"> Recibido      </v-chip>
              <v-chip v-if="item.estatus == 3"  small color="green"  class="elevation-12"  dark @click="entregar( item, 4)"> Entregado     </v-chip>
              <v-chip v-if="item.estatus == 4"  small color="green"  class="elevation-12"  dark @click="ver( item, 4)"> VER     </v-chip>
            </template>

            <template v-slot:item.eliminar="{ item }">
              <v-btn color="red" icon @click="eliminar(item)"><v-icon>mdi-delete</v-icon></v-btn>
            </template>


            <template v-slot:item.opciones="{ item }">
              <v-chip small color="orange" dark @click="verCambio(item)">Ver</v-chip>
            </template>

            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Mis solicitudes</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-btn 
                  class="mr-2" 
                  small dark color="green"
                  @click="exportar()">
                  <v-icon>mdi-microsoft-excel</v-icon>
                </v-btn>
                <v-btn color="primary" small rounded @click="getSolicitudes()">Actualizar</v-btn>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Buscar"
                      v-model="search"
                      dense
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>


    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-h6" style="display: table-cell; vertical-align: middle;">
                  <strong>¡Bien!</strong> Datos grabados correctamente
                  <v-card-actions class="pt-6">
                    <v-spacer></v-spacer>
                    <!-- Guardar la información  -->
                    <v-btn color="green" dark class="elevation-6" large @click="dialogSuccess = false">Ok</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/correcto.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
        width="300"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-h6" style="display: table-cell; vertical-align: middle;">
                  <span v-if="errorBack">
                    <strong>ERROR</strong> Da clic en "COPIAR" para guardar el error y enviarlo a sistemas
                  </span>
                  <span v-else>
                    <strong>ERROR</strong> {{error}}
                  </span>
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text large class="mr-2" @click="dialogError = false">Cerrar</v-btn>
                    <!-- Guardar la información  -->
                    <v-btn color="#D60F24" dark class="elevation-6" large @click="copiarPortapapeles()" v-if="errorBack">{{textoCopiado}}</v-btn>
                    <v-btn color="#D60F24" dark class="elevation-6" large @click="dialogError = false" v-else>Ok</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/eliminar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-h6" style="display: table-cell; vertical-align: middle;">
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar la solicitud?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text large class="mr-2" @click="dialogDelete = false, alumno = null">No, cancelar</v-btn>
                    <!-- Guardar la información  -->
                    <v-btn color="#5C5C5C" dark class="elevation-6" large @click="deleteItemConfirm()">Si</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="600px" v-if="alumno" persistent>
      <v-card class="elevation-0">
        <v-card-title primary-title>
          <strong>Por favor, agrega una evidencia, Gracias!</strong>
        </v-card-title>
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <input id="fileUpload" type="file" hidden @change="cargarFoto" accept="image/*" v-if="!file">
              <v-card height="100%" class="elevation-0" align="center" outlined @click="chooseFiles()" v-if="!file">
                <v-img src="@/assets/agregar_foto.jpg" contain aspect-ratio="2" max-width="450"></v-img>
              </v-card>
            </input>

            <v-card height="100%" class="elevation-0" align="center" outlined v-if="file">
              <v-img :src="fotoCargada.foto" contain aspect-ratio="2" max-width="450" v-if="fotoCargada">
                <v-btn color="red" fab dark small absolute button right @click="file = null"><v-icon>mdi-delete</v-icon></v-btn>
              </v-img>
            </v-card>
          </v-col>
        </v-card-text>
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn color="error" text large class="mr-2" @click="dialog = false, alumno = null">Cancelar</v-btn>
          <!-- Guardar la información  -->
          <v-btn
            color="primary" 
            dark 
            class="elevation-6" 
            large 
            @click="confirmar()"
          >Entregar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 

    <v-dialog v-model="dialogVer" max-width="600px" v-if="alumno" persistent :fullscreen="fullscreen">
      <v-card class="elevation-0">
        <v-card-title primary-title>
          <strong>{{ alumno.nombre_completo }}</strong>
        </v-card-title>
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-card height="100%" class="elevation-0" align="center" outlined >
              <v-img :src=" foto + alumno.url" contain aspect-ratio="2" v-if="fullscreen">
              </v-img>

              <v-img :src=" foto + alumno.url" contain aspect-ratio="2" max-width="450" v-else >
              </v-img>
            </v-card>
          </v-col>
        </v-card-text>
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn color="error" text large class="mr-2" @click="fullscreen = true" v-if="!fullscreen">Zoom</v-btn>
          <v-btn color="error" text large class="mr-2" @click="fullscreen = false" v-else>Sin, Zoom</v-btn>
          <!-- Guardar la información  -->
          <v-btn
            color="primary" 
            dark 
            class="elevation-6" 
            large 
            @click="dialogVer = false, alumno = null"
          >Okey</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
  </v-container>
</template>
<script>
import axios from 'axios';
  var moment = require('moment');
  moment.locale();
  import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'
  export default {
    data () {
      return {
        fullscreen:false,
        search: '',
        headers: [
          { text:'id alumno'              , value:'iderp'},
          { text:'alumno'                 , value:'nombre_completo'},
          { text:'Fecha de solicitud'     , value:'fecha_creacion'},
          { text:'Fecha de entrega'       , value:'fecha_entrega'},
          { text:'status'                 , value:'estatus'},
          { text:'Pasar a:'               , value:'actions'},
          // { text:'Eliminar'               , value:'eliminar'},
        ],
        teachers: [],

        grupos:[],
        alumnos:[],


        dialogUpdateCambio: false,
        alumno:null,

        dialogSuccess:false,
        overlay: false,
        dialogError:  false,
        error:        '',
        successMensaje:'',
        errorBack:false,
        textoCopiado:'COPIAR',
        dialogDelete: false,

        fotoCargada:null,
        file: null,

        dialog: false,
        dialogVer: false,
        foto: ''
      }
    },

    async created () {
      console.clear()
      this.foto = axios.defaults.baseURL + 'imagenes-recepcion/'
      await this.getSolicitudes()
    },

    methods: {

      getSolicitudes () {
        this.overlay = true
        this.alumnos = []
        this.$http.get('academico.certificados.solicitados').then(response=>{
          const certificados = response.data.filter( el=> {
              // return el.usuario_registro == this.getdatosUsuario.iderp
              return true
          })
          this.alumnos = certificados
          this.overlay = false
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportar(){
        let data = XLSX.utils.json_to_sheet(this.teachers)
        const workbook = XLSX.utils.book_new()
        const filename = 'Riesgo_Teachers'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });
      },

      actualizar (alumno, estatus) {
        this.overlay = true
        alumno.estatus = estatus

        this.$http.put('academico.actualizar.certificado/' + alumno.idcertificado_fisico, alumno).then(response=>{
          this.overlay =       false
          this.dialogSuccess = true
          this.getSolicitudes()
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      copiarPortapapeles () {
        navigator.clipboard.writeText(this.error).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      },

      entregar(alumno, estatus){
        this.alumno = alumno
        this.dialog = true
      },

      eliminar (alumno){
        this.alumno = alumno
        this.dialogDelete = true
      },

      deleteItemConfirm(){
        this.alumno.deleted = 1

        this.$http.put('academico.actualizar.certificado/' + this.alumno.idcertificado_fisico, this.alumno).then(response=>{
          this.dialogDelete   = false
          this.overlay        =       false
          this.dialogSuccess  = true
          this.getSolicitudes()
        }).catch(error=>{
          // INdicamos que hay un error en el back
          if(error.body.message){
            this.error = error.body.message
          }else{
            this.error = error.body
          }
          this.errorBack = true
          this.overlay = false
          this.dialogError = true
        })
      },

      chooseFiles() {
        document.getElementById("fileUpload").click()
      },

      async confirmar(){
        if(this.fotoCargada){
          try{
            this.overlay = true
            const grabarImage  = await this.guardarImagen(this.alumno.idcertificado_fisico).then(response=> response)
            this.dialog        = false
            this.overlay       = false
            this.dialogSuccess = true
            this.fotoCargada   = null
            this.file          = null
            this.getSolicitudes()
          }catch (e) {
            this.disabled = false
            this.openDialogError(e)
          }
        }else{
          this.error = 'Por favor, agrega una imagen'
          this.errorBack   = false
          this.overlay     = false
          this.dialogError = true
        }
      },

      guardarImagen ( id ) {
        return new Promise((resolve,reject)=>{
          // obtener la extensión del archivo
          const ext = this.getFileExtension1(this.file.name)
          // Hacemos la petición
          this.$http.post(`academico.certificado.imagen/${ id }/${ ext}`, this.fotoCargada.formdata ).then(response=>{
            resolve( response )
          }).catch(error=>{
            this.disabled = false
            // INdicamos que hay un error en el back
            if(error.body.bodyText){
              this.error = error.body.bodyText
            }else{
              this.error = error.body
            }
            this.errorBack = true
            this.overlay = false
            this.dialogError = true
          })
        })
      },


       // Cargar tofo
      cargarFoto(e){
        // Verificamos que haya un archivo
        if(e.target.files.length > 0){
          // guardamos el archivo seleccionado
          this.file = e.target.files[0]
          // creamos una variable tipo FormData
          let formData = new FormData();
          //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', this.file);
          //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          this.getBase64(this.file, formData, this.file.name)
        }
      },

      getBase64(archivo,formData,name) {
        var me = this
        // Creamos la variable de lectura
        var reader = new FileReader();
        // leemos el archivos
        reader.readAsDataURL(archivo);
        reader.onload =  function(){
          // Agregamos la foto cargada al arreglo que vamos a mostrar arriba en la pantalla
          me.fotoCargada = {
            foto: reader.result,
            formdata: formData,
          }
        }
      },

      getFileExtension1(filename) {
        return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
      },

      ver(item){
        this.alumno = item
        this.dialogVer = true
      }

      
    },

    computed:{
      ...mapGetters ('login',['getdatosUsuario'])
    }
  }
</script>

<style>

  .v-menu__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-menu__content::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-button:increment,.v-menu__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-menu__content::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>
