<template>
	<v-container fluid>
	  <v-row>
	  	<v-col cols="12" class="pa-0">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">descuentos</span>
	  		    <v-spacer></v-spacer>
	  		  </v-card-title>

	  		  <v-card-text>
	  		  	<v-subheader><strong>Agregar descuento</strong></v-subheader>

	  		  	<v-row>
	  		  		<v-col cols="10" sm="8" md="6">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Descripción"
	  		  			  dense
	  		  			  outlined
	  		  			  hide-details
	  		  			  v-model="descuento_pronto_pago"
	  		  			></v-text-field>
	  		  		</v-col>

	  		  		<v-col cols="2" md="6">
	  		  			<v-btn color="primary" @click="agregarDescuento()"><v-icon>mdi-plus</v-icon></v-btn>
	  		  		</v-col>
	  		  	</v-row>

	  		  	<v-row >
	  		  		<v-col cols="12" sm="4">
	  		  			<v-text-field
	  		  			  label="Fecha inicio"
	  		  			  dense
	  		  			  hide-details
	  		  			  outlined
	  		  			  v-model="fecha_inicio_descuento"
	  		  			  type="date"
	  		  			></v-text-field>
	  		  		</v-col>

	  		  		<v-col cols="12" sm="4">
	  		  			<v-text-field
	  		  			  label="Fecha final"
	  		  			  dense
	  		  			  hide-details
	  		  			  outlined
	  		  			  v-model="fecha_fin_descuento"
	  		  			  type="date"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>

	  		  	<v-row >
	  		  		<v-col cols="12" sm="4">
	  		  			<v-text-field
	  		  			  label="Tipo de descuento"
	  		  			  dense
	  		  			  hide-details
	  		  			  readonly
	  		  			  outlined
	  		  			  v-model="tipo_descuento"
	  		  			></v-text-field>
	  		  		</v-col>

	  		  		<v-col cols="12" sm="4">
	  		  			<v-text-field
	  		  			  label="Valor de descuento"
	  		  			  dense
	  		  			  hide-details
	  		  			  outlined
	  		  			  v-model="valor_descuento"
	  		  			  type="number"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		  </v-card-text>

	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="6">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="descuentos"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
			            :items-per-page="5"
			            dense
							  >
							    <template v-slot:item.eliminar="{ item }">
							      <v-chip 
							      	small
							      	color="red"
							      	dark
							      	@click="eliminarDescuento( item.id_descuento_pronto_pago )"
							      ><v-icon>mdi-delete</v-icon></v-chip>
							    </template>
							    
							    <template v-slot:item.total_a_pagar="{ item }">
							      {{ editedItem.precio - item.valor_descuento }} 
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';
	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

  	props:[
			'editedItem'
	  ],
    data: () => ({

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      descuentos: [],
      headers: [
        { text: 'ID'               , value: 'id_descuento_pronto_pago' },
        { text: 'Descuento'        , value: 'descuento_pronto_pago'    },
        { text: 'Tipo'             , value: 'tipo_descuento'           },
        { text: 'Cantidad'         , value: 'valor_descuento'          },
        { text: 'Inicio'           , value: 'fecha_inicio_descuento'   },
        { text: 'Final'            , value: 'fecha_fin_descuento'      },
        { text: 'Cost. con desc.'  , value: 'total_a_pagar'            },
        { text: 'Eliminar'         , value: 'eliminar'                 },
      ],

      descuento_pronto_pago:'',
      tipo_descuento: 'POR MONTO FIJO',
      valor_descuento:'',
      fecha_inicio_descuento:null,
			fecha_fin_descuento:null,

			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
    },

    async created () {
    	console.log ( this.editedItem ) 
      await this.initialize()
    },

    methods: {
      initialize () {
        this.descuentos = []
        return this.$http.get('descprontopago.grupo/' + this.editedItem.id_grupo).then(response=>{
        	this.descuentos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      agregarDescuento ( ) {
      	if(!this.fecha_inicio_descuento || !this.fecha_fin_descuento || !this.valor_descuento || this.descuento_pronto_pago == ''){
      		this.validarErrorDirecto( 'Ingresa los datos completos por favor')
      		return 
      	}

      	const payload = {
      		id_grupo:                 this.editedItem.id_grupo,
      		descuento_pronto_pago:    this.descuento_pronto_pago,
		      id_tipo_descuento:        1,
		      valor_descuento:          this.valor_descuento,
		      fecha_inicio_descuento:   this.fecha_inicio_descuento,
					fecha_fin_descuento:      this.fecha_fin_descuento,
					id_usuario_ultimo_cambio: this.getdatosUsuario.iderp,
					fecha_limite_descuento:   0,
					comentarios:              `Descuento aplicado al grupo: ${ this.editedItem.grupo } con descuento de: ${ this.valor_descuento } en un periodo de: ${ this.fecha_inicio_descuento } a ${ this.fecha_fin_descuento }`
      	}

      	return this.$http.post(`descprontopago.grupo.add`, payload).then(response=>{
      		this.valor_descuento        = ''
      		this.fecha_inicio_descuento = null
					this.fecha_fin_descuento    = null
					this.descuento_pronto_pago  = ''
          this.validarSuccess(response.data.message)
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      eliminarDescuento( id ){
      	const payload = {
      		id_usuario: this.getdatosUsuario.iderp,
      	}
      	return this.$http.put(`descprontopago.grupo.eliminar/${ id }`, payload).then(response=>{
          this.validarSuccess(response.data.message)
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      validarErrorDirecto ( mensaje ) {
        this.cargar = false 
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
    	},
    },
  }
</script>


