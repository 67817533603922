<template>
  <v-container class="mt-5 ml-2">
    <v-row>
    	<v-col cols="12">
	  		<v-data-table
		      :headers="headers"
		      :items="filterGrupos"
		      :search="search"
		      class="elevation-6"
		    >
		    	<template v-slot:top>
			      <v-toolbar
			        flat
			      >
			        <v-toolbar-title>Todas las evaluaciones</v-toolbar-title>
			        <v-divider
			          class="mx-4"
			          inset
			          vertical
			        ></v-divider>
			        <v-spacer></v-spacer>
			        <v-btn 
                class="mr-2" 
                small dark color="green"
                @click="exportar()">
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-btn>
			        <v-btn color="primary" small rounded @click="getGrupos()">Actualizar</v-btn>
			      </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4" lg="3" class="pb-0">
                  <v-autocomplete
	                  outlined
	                  dense
	                  clearable
	                  v-model="cicloInf"
	                  :items="ciclos"
	                  label="Selecciona ciclo actual"
	                  persistent-hint
	                  hide-details
	                  return-object
	                  item-text="ciclo"
	                  item-value="id_ciclo"
	                >
	                </v-autocomplete>
                </v-col>

                <v-col cols="12" md="4" lg="3" class="pb-0">
                  <v-text-field
                    label="Fecha inicio"
                    v-model="fecha1"
                    outlined
                    dense
                    hide-details
                    type="date"
                    clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" lg="3" class="pb-0">
                  <v-text-field
                    label="Fecha final"
                    v-model="fecha2"
                    outlined
                    dense
                    hide-details
                    type="date"
                    clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                	<v-text-field
					          label="Buscar"
					          v-model="search"
					          dense
					          hide-details
					          outlined
					        ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
			    </template>

			    <template v-slot:item.estatus="{ item }">
			    	<v-chip small color="red"    dark v-if="item.estatus == 0">EVALUADA</v-chip>
			    	<v-chip small color="orange" dark v-if="item.estatus == 1" class="elevation-2">REVISADA</v-chip>
			    	<v-chip small color="green"  dark v-if="item.estatus == 2">ACEPTADA</v-chip>
			    </template>

			    <template v-slot:item.revisar="{ item }">
				    <v-btn rounded color="primary" small @click="abrirDialogClase( item )">VER</v-btn>
			    </template>

		    </v-data-table>
	  	</v-col>
	  </v-row>

	  <v-row justify="center" v-if="grupo">
	    <v-dialog
	      v-model="dialogClase"
	      max-width="800"
	      transition="dialog-bottom-transition"
	      persistent
	    >
	      <v-card>
	        <v-toolbar
	          dark
	          dense
	          color="primary"
	          class="elevation-0"
	          style="border-radius: 0px !important;"
	        >
	          <v-spacer></v-spacer>

	          <!-- boton para cerrar -->
	          <v-btn
	            icon
	            dark
	            @click="dialogClase = false"
	          >
	            <v-icon>mdi-close</v-icon>
	          </v-btn>

	        </v-toolbar>

	        <v-card-text class="pt-2">
		        <v-row justify="space-around">
		        	<!-- descripción del grupo -->
		        	<v-col cols="12" md="6" class="pt-12">
		        		<div>Grupo</div>
		        		<div class="text-body-1"><strong>{{ grupo.grupo }}</strong></div>
		        		<div class="mt-3">Teacher</div>
		        		<div class="text-body-1"><strong>{{ grupo.teacher }}</strong></div>
		        		<div class="mt-3">Calificación</div>
		        		<div class="text-h4 green--text"><strong>{{ grupo.calificacion }}</strong></div>
		        	</v-col>

		        	<!-- Imagen -->
		        	<v-col cols="12" md="6" lg="4">
	        			<v-card height="100%" class="elevation-0" align="center" outlined  v-if="!foto">
	        		  	<v-img src="@/assets/agregar_foto.jpg" contain aspect-ratio="2" max-width="380"></v-img>
	        			</v-card>

				   		 	<v-card height="100%" class="elevation-0" align="center" outlined v-if="foto" @click="verFoto()">
	        		  	<v-img :src="foto" contain aspect-ratio="2" max-width="380">
	        		  	</v-img>
	        			</v-card>


		        	</v-col>
		        </v-row>
	        </v-card-text>

	        <v-divider></v-divider>

	        <v-card-text class="pt-2">
		        <v-row v-for="(pregunta, i) in preguntas" :key="i" >
		        	<v-col cols="12" class="pb-0">
		        		<v-checkbox 
	              	v-model="pregunta.estatus"
	              	:label="pregunta.pregunta"
	              	readonly
	              ></v-checkbox>
		        	
		        		<v-textarea
	              	readonly
		        			dense
		        			label="Notas:"
		        			outlined
		        			:rows="3"
		        			auto-grow
		        			v-model="pregunta.nota"
		        			hide-details
		        		>
		        		</v-textarea>

						    <div class="mt-3"><strong>¿Aceptas esta nota? </strong></div>
		        		<v-radio-group 
		        			v-model="pregunta.aceptado" 
		        			row 
		        			class="py-0" 
		        			:readonly="grupo.estatus == 0 ? false: true">
						      <v-radio :value="1" class="py-0">
						        <template v-slot:label>
						          <div><strong class="success--text">Acepto</strong></div>
						        </template>
						      </v-radio>
						      <v-radio :value="0" class="py-0">
						        <template v-slot:label>
						          <div><strong class="error--text">Rechazo</strong></div>
						        </template>
						      </v-radio>
						    </v-radio-group>

						    <v-textarea
						    	v-if="pregunta.aceptado == 0"
		        			dense
		        			label="¿Por qué?"
		        			outlined
		        			:rows="3"
		        			auto-grow
		        			v-model="pregunta.respuesta"
		        			hide-details
		        		>
		        		</v-textarea>

		        		<v-divider></v-divider>

		        	</v-col>
		        </v-row>
	        </v-card-text>

	        <v-card-actions>
	        	<v-spacer></v-spacer>
	          <v-btn
              dark
              color="primary"
              class="elevation-6"
              large
              @click="dialogClase = false, dialogConfirmacion = true"
              v-if="grupo.estatus == 1"
            >
              ACEPTAR REPORTE
            </v-btn>
	        </v-card-actions>
	        
	        
	      </v-card>
	    </v-dialog>
	  </v-row>

	  <!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="300"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      persistent
      max-width="600"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-h6" style="display: table-cell; vertical-align: middle;">
		      				<span v-if="errorBack">
		      					<strong>ERROR</strong> Da clic en "COPIAR" para guardar el error y enviarlo a sistemas
		      				</span>
		      				<span v-else>
		      					<strong>ERROR</strong> {{error}}
		      				</span>
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text large class="mr-2" @click="dialogError = false">Cerrar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="copiarPortapapeles()" v-if="errorBack">{{textoCopiado}}</v-btn>
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="dialogError = false" v-else>Ok</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/eliminar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogConfirmacion"
      persistent
      max-width="600"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-h6" style="display: table-cell; vertical-align: middle;">
		      				<strong>¿Estás seguro de que seas guardar la información?</strong>
			      			<v-card-actions>
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="secondary" text large class="mr-2" @click="dialogConfirmacion = false, dialogClase = true">No, Cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="blue lighten-2" dark class="elevation-6" large @click="capturarReporte()">Sí, Guardar</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/send.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>


    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-h6" style="display: table-cell; vertical-align: middle;">
		      				<strong>¡Bien!</strong> Datos grabados correctamente
			      			<v-card-actions class="pt-6">
			      				<v-spacer></v-spacer>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="green" dark class="elevation-6" large @click="dialogSuccess = false">Ok</v-btn>
			      				<v-spacer></v-spacer>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/correcto.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVer" max-width="600px" persistent :fullscreen="fullscreen">
      <v-card class="elevation-0">
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-card height="100%" class="elevation-0" align="center" outlined >
              <v-img :src=" foto" contain aspect-ratio="2" v-if="fullscreen">
              </v-img>

              <v-img :src=" foto" contain aspect-ratio="2" max-width="450" v-else >
              </v-img>
            </v-card>
          </v-col>
        </v-card-text>
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn color="error" text large class="mr-2" @click="fullscreen = true" v-if="!fullscreen">Zoom</v-btn>
          <v-btn color="error" text large class="mr-2" @click="fullscreen = false" v-else>Sin, Zoom</v-btn>
          <!-- Guardar la información  -->
          <v-btn
            color="primary" 
            dark 
            class="elevation-6" 
            large 
            @click="dialogVer = false, alumno = null"
          >Okey</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

	</v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters, mapActions } from "vuex";
	import XLSX from 'xlsx'
  export default {
    data () {
      return {
      	dialogConfirmacion: false,
      	dialogClase: false,

      	cicloInf: 		null,
      	ciclos:  			[],
      	dialogError: 	false,
				dialogSuccess:false,
				error: 				'',
				successMensaje:'',
				overlay: 			false,

        search: '',
        headers: [
          { text: 'Id grupo'          , value: 'id_grupo'       },
          { text: 'Grupo'             , value: 'grupo'          },
          { text: 'Teacher'           , value: 'teacher'        },
          { text: 'Calificación'      , value: 'calificacion'   },
          { text: 'Fecha'             , value: 'fecha_creacion' },
          { text: 'Usuario'           , value: 'usuario'        },
          { text: 'Estatus'           , value: 'estatus'        },
          { text: 'Revisar'           , value: 'revisar'        },
        ],

        clases:[],
        grupo: null,

        fotoCargada:null,
        file: null,
        errorBack:false,
        textoCopiado:'COPIAR',

        preguntas:[],
        evaluacion : null,
        foto:null,
        fecha1: null,
        fecha2: null,

        fullscreen:false,
        dialogVer: false
      }
    },

    watch:{
	    cicloInf () {
        if(this.cicloInf){
          this.getGrupos()
        }
      },
	  },

    async created () {
    	await this.getCiclos()
    },

    methods: {
    	getCiclos () {
    		this.textoCopiado = 'COPIAR'
        this.overlay = true
        this.ciclos = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.overlay = false
        }).catch(error=>{
        	this.errorBack = true
        	if(error.status == 0){
	        	this.overlay = false
	        	this.openDialogError('Los datos no se pudieron cargar')
        	}else{
        		this.overlay = false
	        	this.openDialogError(error.body.message)
        	}
        })
      },

      getGrupos () {
    		// validamos los campos
    		if(this.cicloInf){
	    	 	this.overlay = true
	    		this.clases  = []
	    		
	    		// Preparamos el cuerpo de datos
	    		const payload = {
	    			cicloFast         : this.cicloInf.id_ciclo_relacionado,
	    			cicloInbi         : this.cicloInf.id_ciclo,
	    		}

	    		// Hacemos la petición
	    		this.$http.post('calidad.clases.all', payload).then(response=>{
	        	// Guardamos la respuesta
	        	this.clases  = response.data
	        	this.overlay = false
	        }).catch(error=>{
	        	this.error = error.body.message
	        	this.dialogError = true
	        	this.overlay = false
	        })
    		}else{
    			// Error,no se selecciono ningun ciclo
    			this.openDialogError('Por favor, elige el ciclo')
    		}
    	},

      getPreguntas ( id ) {
        this.overlay = true
        this.preguntas = []
        this.$http.get('calidad.clases.respuestas/' + id).then(response=>{
          // Guardamos las preguntas
          this.preguntas = response.data
          this.overlay = false
        }).catch(error=>{

        	// INdicamos que hay un error en el back
        	if(error.body.message){
        		this.error = error.body.message
        	}else{
        		this.error = error.body
        	}
        	this.errorBack = true
        	this.overlay = false
        	this.dialogError = true
        })
      },

    	chooseFiles() {
        document.getElementById("fileUpload").click()
    	},

    	openDialogError (mensaje) {
      	this.error       = mensaje
      	this.errorBack   = false
      	this.dialogError = true
      },

      openDialogSuccess ( mensaje ){
      	this.successMensaje = mensaje
      	this.dialogSuccess  = true
      },

      async abrirDialogClase ( grupo ) {
      	this.overlay = true
        this.preguntas = []
        this.$http.get('calidad.clases.respuestas/' + grupo.idcalidad_eval_clase).then(response=>{
          // Guardamos las preguntas
          this.preguntas = response.data
          this.overlay = false
	      	this.grupo = grupo

	      	// Cargamos la foto
	      	if(this.grupo.url != ''){
						this.foto = axios.defaults.baseURL + 'imagenes-clases/' + this.grupo.url
	      	}else{
	      		this.foto == null
	      	}

	      	// Abrirmos la modal 
	      	this.dialogClase = true

        }).catch(error=>{

        	// INdicamos que hay un error en el back
        	if(error.body.message){
        		this.error = error.body.message
        	}else{
        		this.error = error.body
        	}
        	this.errorBack = true
        	this.overlay = false
        	this.dialogError = true
        })

      },

			copiarPortapapeles () {
        navigator.clipboard.writeText(this.error).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportar(){
        let data = XLSX.utils.json_to_sheet(this.filterGrupos)
        const workbook = XLSX.utils.book_new()
        const filename = 'Clases_evaluadas'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },

      // función para guardar la información de la minuta
			async capturarReporte () {
				// Crear el payload a guardar
				let payload  = {
					idcalidad_eval_clase : this.grupo.idcalidad_eval_clase,
					estatus              : 2, // Revisado por el teacher
					preguntas            : this.preguntas
				}

				this.$http.put('calidad.clases.evaluacion.update', payload).then(response=>{
					this.dialogSuccess = true
					this.dialogConfirmacion = false
					this.getGrupos()
        }).catch(error=>{
        	// INdicamos que hay un error en el back
        	if(error.body.bodyText){
        		this.error = error.body.bodyText
        	}else{
        		this.error = error.body
        	}
        	this.errorBack = true
        	this.overlay = false
        	this.dialogError = true
        })
				
			},

			verFoto() {
				this.dialogVer =true
			}
    },

    computed:{
	    ...mapGetters('login',['getdatosUsuario']),

	    filterGrupos () {
        if(this.clases){
          return this.clases.filter((grupo)=>{
          	if(! this.fecha1 && !this.fecha2){
          		return true
          	}

          	if(this.fecha1 && !this.fecha2){
          		if(this.fecha1 == grupo.fecha_creacion.substr(0,10)){
          			return true
          		}
          	}

          	if(this.fecha1 && this.fecha2){
          		if(grupo.fecha_creacion.substr(0,10) >= this.fecha1 && grupo.fecha_creacion.substr(0,10) <= this.fecha2){
          			return true
          		}
          	}
          })
        }
      },
	  },
  }
</script>


