<template>
	<v-container class="mt-4 ml-2">
		<v-row>
			<v-col cols="12" md="10" lg="8">
				<v-card class="shadowCard" >
				  <!-- BARRA DEL MENU  -->
				  <v-toolbar
			      color="pink"
			      dark
			      dense
			    >
			      <v-toolbar-title>Mis preguntas</v-toolbar-title>
			      <v-spacer></v-spacer>
			      <v-text-field
			        name="name"
			        label="Buscar"
			       	v-model="buscarPregunta"
			       	hide-details
			       	v-if="buscarActive"
			       	dense
			      ></v-text-field>
			      <!-- Activar el buscador -->
			      <v-btn 
			      	icon 
			      	@click="buscarActive = true" 
			      	v-if="!buscarActive"
			      >
			        <v-icon>mdi-magnify</v-icon>
			      </v-btn>
			      <!-- DEscativar el buscador -->
			      <v-btn 
			      	icon 
			      	@click="buscarActive = false" 
			      	v-else
			      >
			        <v-icon>mdi-close</v-icon>
			      </v-btn>
			    </v-toolbar>

			    <!-- Agregar y ver las preguntas -->
				  <v-card-text>
				  	<div :style="`height: ${tamanioPantalla}px; overflow: auto;`" id="preguntas">
					  	<span v-for="(pregunta, i) in filterPreguntas">
		            <v-row>
		              <v-col cols="12">
		                <v-card class="ml-6 pa-3 mt-2" max-width="500" color="#f2f2f2" style="border-radius: 15px; border-top-left-radius: 0">
		                	<!-- Pregunta del usuario -->
		                  <div class="text-subtitle-1">
		                  	<v-chip color="success" small left v-if="pregunta.respuesta">
		                  		<v-icon left >mdi-check-circle</v-icon>
		                  		Completada
		                  	</v-chip>

		                  	<v-chip color="error" small left v-else>
		                  		<v-icon left >mdi-alert-circle</v-icon>
		                  		Pendiente
		                  	</v-chip>
		                  	<br/>
		                  	<span v-html="pregunta.pregunta"  style="white-space: pre-line"></span>
		                  </div>
		                </v-card>
		              </v-col>
		            </v-row>

		            <v-row>
		              <v-col cols="12" align="right">
		                <v-card 
		                	class="mr-6 mt-2 pa-3" 
		                	max-width="500" 
		                	dark 
		                	color="#0465ac" 
		                	shaped 
		                	style="border-radius: 15px; border-top-right-radius: 0;"
		                	v-if="pregunta.respuesta"
		                >
		                  <span class="text-subtitle-1"  style="white-space: pre-line">{{ pregunta.respuesta ?  pregunta.respuesta : 'Sin Respuesta '}}</span>
		                </v-card>

		                <v-card 
		                	class="mr-6 mt-2 pa-3" 
		                	max-width="500" 
		                	dark 
		                	color="#49ABF3" 
		                	shaped 
		                	style="border-radius: 15px; border-top-right-radius: 0;"
		                	v-else
		                >
		                  <span class="text-subtitle-1"  style="white-space: pre-line">{{ pregunta.respuesta ?  pregunta.respuesta : 'Sin Respuesta '}}</span>
		                </v-card>
		              </v-col>
		            </v-row>
		          </span>
				  	</div>
				  </v-card-text>

				  <v-card-text class="pb-4 pt-6">
	          <v-textarea
	            v-model="pregunta"
	            :append-outer-icon="pregunta ? 'mdi-send' : ''"
	            filled
	            clearable
	            label="Escribe una pregunta clara y concreta"
	            type="text"
	            @click:append-outer="enviarPregunta()"
	            hide-details
	            rows="3"
	          ></v-textarea>
	        </v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters, mapActions }    from "vuex";

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  export default {
  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Arteas
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      // Toolbar
      buscarActive: false,
      buscarPregunta:'',

      preguntas:[],
      pregunta:'',
    }),

    computed:{
    	...mapGetters( 'login', ['getdatosUsuario']),

    	filterPreguntas(){
    		if( this.buscarPregunta != '' ){
    			return this.preguntas.filter((p)=>{
		        var nom = p.pregunta.toLowerCase();
		        return nom.match(this.buscarPregunta.toLowerCase());
		      })

    		}else{ return this.preguntas }
    	},

    	tamanioPantalla () {
	      switch (this.$vuetify.breakpoint.name) {
	        case 'xs':
	          return (this.$vuetify.breakpoint.height / 2)
	        break;
	        case 'sm': 
	          return this.$vuetify.breakpoint.height - 380
	        break;
	        case 'md':
	          return this.$vuetify.breakpoint.height - 380
	        break;
	        case 'lg':
	          return this.$vuetify.breakpoint.height - 380
	        break;
	        case 'xl':
	          return this.$vuetify.breakpoint.height - 380
	        break;
	      }
    	},
    },

    async created () {
    	await this.initialize( )
    },

    methods:{
    	initialize () {
      	this.cargar = true
        this.preguntas = []
        return this.$http.get('preguntasfre.mis.preguntas/' + this.getdatosUsuario.iderp ).then(response=>{
        	this.preguntas = response.data
        	console.log( this.preguntas )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
      
      enviarPregunta () {
      	this.cargar = true
      	// Construir el payload a enviar
        const payload = {
        	pregunta:       this.pregunta,
        	quien_pregunto: this.getdatosUsuario.iderp,
        	idpuesto:       this.getdatosUsuario.idpuesto
        }
        // Enviar la pregunta
        return this.$http.post('preguntasfre.add', payload ).then(response=>{
        	// Limpiar la pregunta
        	this.pregunta = ''
        	this.cargar   = false
        	// Recagar
        	this.initialize( )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      validarErrorDirecto ( mensaje ) {
        this.cargar = false 
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
    	},
    }
  }
</script>
<style  scoped>
	#preguntas::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > #preguntas::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar-button:increment,#preguntas::-webkit-scrollbar-button{
    display: none;
  }

  #preguntas::-webkit-scrollbar:horizontal{
    display: none;
  }

  #preguntas::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>