<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Incidencias y Bonos</span>
            <v-spacer></v-spacer>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="3" >
                <v-text-field
                  filled
                  single-line
                  label="Fecha inicio"
                  type="date"
                  hide-details
                  v-model="fecha_inicio"
                  dense
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3" >
                <v-text-field
                  filled
                  single-line
                  label="Fecha final"
                  type="date"
                  hide-details
                  v-model="fecha_final"
                  dense
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <label></label>
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="incidenciasbonos"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                >

                  <template v-slot:item.update="{ item }">
                    <v-icon small @click="editItem(item)" color="blue">
                      mdi-magnify
                    </v-icon>
                  </template>

                  <template v-slot:item.eliminar="{ item }">
                    <v-icon small @click="deleteItem(item)" color="error">
                      mdi-delete
                    </v-icon>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- <v-card-text>
            <v-row>
            <v-col cols="6" md="6">
              <label></label>
              <v-text-field
                name="name"
                label="Buscar"
                id="id"
                filled
                dense
                single-line
                hide-details
                append-icon="mdi-magnify"
                v-model="search"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>

            <v-col cols="3" md="2">
              <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
              dense
              >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
            </v-col>

            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="incidenciasbonos"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                >

                  <template v-slot:item.update="{ item }">
                    <v-icon small @click="editItem(item)" color="blue">
                      mdi-magnify
                    </v-icon>
                  </template>

                  <template v-slot:item.eliminar="{ item }">
                    <v-icon small @click="deleteItem(item)" color="error">
                      mdi-delete
                    </v-icon>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text> -->
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>

            <v-col cols="12">
            <v-autocomplete
                :items="usuarios"
                v-model="editedItem.iderp"
                label="Empleado"
                outlined
                hide-details
                dense
                item-text="nombre_completo"
                item-value="id_usuario"
            ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
            <v-text-field
              v-model="editedItem.fecha"
              label="Fecha"
              filled
              hide-details
              dense
              type="date"
            ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field 
                v-model="editedItem.rebajes"
                label="Rebajes"
                outlined
                hide-details
                dense
                type = "number"
              ></v-text-field >
            </v-col>

             <v-col cols="12" md="6">
              <v-text-field 
                v-model="editedItem.comisiones"
                label="Comisiones"
                outlined
                hide-details
                dense
                type = "number"
              ></v-text-field >
            </v-col>

             <v-col cols="12" md="6">
              <v-text-field 
                v-model="editedItem.premios"
                label="Premios"
                outlined
                hide-details
                dense
                type = "number"
              ></v-text-field >
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field 
                v-model="editedItem.compensaciones"
                label="Compensaciones"
                outlined
                hide-details
                dense
                type = "number"
              ></v-text-field >
            </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
          <v-btn color="blue darken-1" class="elevation-6" dark @click="save">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar esta fila?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      large
                      class="mr-2"
                      @click="closeDelete()"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      large
                      @click="deleteItemConfirm()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_mensaje_rechazo" max-width="1000px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1"><b>En caso de rechazo, favor de enviar mensaje de aclaración:</b></span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
            <strong>ID:</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row v-for="(item, index) in this.mensajes" :key="index">
            <v-col cols="12">
              <v-textarea
                v-model="item.nota"
                :id="'motivo_salida_' + index"
                label="Motivo del rechazo"
                outlined
                hide-details
                dense
                auto-grow
                readonly
                style="max-width: 900px; margin-right: auto"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="item.nota_respuesta"
                :id="'motivo_salida_' + index"
                label="Respuesta..."
                outlined
                hide-details
                dense
                auto-grow
                readonly
                style="max-width: 900px; margin-left: auto"
                :class="{ 'd-none': !item.nota_respuesta }"
              ></v-textarea>
            </v-col> </v-row
          ><br />
          <v-row
            style="border: 1px solid gray; padding: 2px; border-radius: 5px"
            v-if="idhistorial.estatus == 0 || idhistorial.estatus == 2"
          >
            <v-col cols="12" :style="{ paddingTop: '10px' }">
              <v-textarea
                v-model="editedItem2.mensaje"
                label="Escriba su mensaje..."
                outlined
                hide-details
                dense
                auto-grow
                :readonly="idhistorial.estatus != 2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (dialog_mensaje_rechazo = false), (editedItem2.mensaje = '')
            "
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            v-if="idhistorial.estatus == 2"
            @click="enviarMensajeRespuesta()"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "ID", align: "start", value: "idincidencias_bonos" },
      { text: "Nombre", value: "nombre_completo" },
      { text: "Fecha", value: "fecha" },
      { text: "Rebajes", value: "rebajes" },
      { text: "Comisiones", value: "comisiones" },
      { text: "Premios", value: "premios" },
      { text: "Compensaciones", value: "compensaciones" },
      { text: "Actualizar", value: "update", sortable: false },
      { text: "Eliminar", value: "eliminar", sortable: false },
    ],
    incidenciasbonos: [],
    editedIndex: -1,
    editedItem: {
      idincidencias_bonos: "",
      fecha: "",
      rebajes: "",
      comisiones: "",
      premios: "",
      iderp: "",
      deleted: 0,
      compensaciones: ""
    },
    defaultItem: {
      idincidencias_bonos: "",
      fecha: "",
      rebajes: "",
      comisiones: "",
      premios: "",
      iderp: "",
      deleted: 0,
      compensaciones: ""
    },

    dialogSuccess: false,
    overlay: false,
    dialogError: false,
    error: "",
    successMensaje: "",
    errorBack: false,
    textoCopiado: "COPIAR",
    idcurso: 0,
    info: 0,
    dialog_mensaje_rechazo: false,
    idhistorial: [],
    editedItem2: {
      mensaje: "",
    },
    mensajes: [],
    usuarios: [],
    fecha_inicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		fecha_final: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

    ciclos: [],
    ciclo: null,
    estatus: null,
    alumnos: [],
    reglas: [],
    cursosEscuela: [],
    horasExtras: "",
    // tabla
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
    formTitle() {
      return this.editedIndex === -1
        ? "Agregar Incidencias y Bonos"
        : "Editar Incidencias y Bonos";
    },

    filterHorasExtras() {

       if (this.estatus) {        
        return this.horasextras.filter((el) => {
          return el.estatus === this.estatus;
        });
      }
      else {
        return this.horasextras;
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "editedItem.escuela"(val) {
      this.cursosEscuela = this.cursos.filter((el) => {
        return el.escuela == val;
      });
    },
  },

  created() {
    this.initialize();
    this.getUsuarios();
  },

  methods: {
    initialize() {
      this.incidenciasbonos = [];
      this.overlay = true;

      const payload = {
        fecha_inicio: this.fecha_inicio,
        fecha_final : this.fecha_final
      }

      this.$http
        .post("incidencias_bonos.get.incidenciasbonos", payload)
        .then((response) => {
          this.incidenciasbonos = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    getUsuarios() {
      this.usuarios = [];
      this.overlay = true;

      this.$http
        .post("incidencias_bonos.get.usuarios")
        .then((response) => {
          this.usuarios = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    editItem(item) {
      this.editedIndex = this.incidenciasbonos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.incidenciasbonos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // Lo mandapos por el EP
      this.editedItem.deleted = 1;

      this.$http
        .put(
          "incidencias_bonos.update/" + this.editedItem.idincidencias_bonos,
          this.editedItem
        )
        .then((response) => {
          this.dialogSuccess = true;
          this.overlay = false;
          this.closeDelete();
          this.initialize();
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      
      if (this.editedIndex > -1) {
        // Activamos el loader
        this.overlay = true;
        this.editedItem.deleted = 0;
        // Lo mandapos por el EP
console.log(this.editedItem)
        this.$http
          .put(
            "incidencias_bonos.update/" + this.editedItem.idincidencias_bonos,
            this.editedItem
          )
          .then((response) => {
            this.dialogSuccess = true;
            this.initialize();
            this.overlay = false;
          })
          .catch((error) => {
            // INdicamos que hay un error en el back
            if (error.body.message) {
              this.error = error.body.message;
            } else {
              this.error = error.body;
            }
            this.errorBack = true;
            this.overlay = false;
            this.dialogError = true;
          });
      } else {
        // Activamos el loader
        this.overlay = true;
        // Lo mandapos por el EP

        if (this.editedItem.rebajes == "" && this.editedItem.comisiones == ""  && this.editedItem.premios == "") {
        return this.validarErrorDirecto("Favor de llenar minimo una casilla con rebajes, comisiones o premios.");
        }

        this.$http
          .post("incidencias_bonos.add.incidenciasbonos", this.editedItem)
          .then((response) => {
            this.dialogSuccess = true;
            this.initialize();
            this.overlay = false;
          })
          .catch((error) => {
            // INdicamos que hay un error en el back
            if (error.body.message) {
              this.error = error.body.message;
            } else {
              this.error = error.body;
            }
            this.errorBack = true;
            this.overlay = false;
            this.dialogError = true;
          });
      }
      this.close();
    },

    copiarPortapapeles() {
      navigator.clipboard
        .writeText(this.error)
        .then(() => {
          this.textoCopiado = "COPIADO";
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });
    },

  },
};
</script>
