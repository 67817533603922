<template>
	<v-container class="mt-4 ml-2">
	  <v-row>
	  	<v-col cols="12" md="10" lg="8">
	  		<v-card class="shadowCard">
	  			<v-card-title primary-title>
	  		    <span class="text-subtitle-1">Medio de contacto</span>
	  		    <v-spacer></v-spacer>
	  		    <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="dialog = true"
              small
              rounded
            >
              Agregar
            </v-btn>
	  		  </v-card-title>

				  <v-data-table
				    :headers="headers"
				    :items="detalles"
				    class="elevation-0"
				  >
				    
				    <!-- Valores que ocupan botons, chips, iconos, etc -->
				    <template v-slot:item.estatus="{ item }">
				      <v-chip 
				      	v-if="item.estatus == 1"
				      	small
				      	color="green"
				      	dark
				      >Activo</v-chip>

				      <v-chip 
				      	v-else
				      	small
				      	color="error"
				      	dark
				      >Inactivo</v-chip>
				    </template>


				    <template v-slot:item.actions="{ item }">
				      <v-icon
				        small
				        class="mr-2"
				        color="primary"
				        @click="editItem(item)"
				      >
				        mdi-pencil
				      </v-icon>
				      <v-icon
				        small
				        @click="deleteItem(item)"
				        color="error"
				      >
				        mdi-delete
				      </v-icon>
				    </template>

				    <template v-slot:no-data>
				      <v-btn
				        color="primary"
				        @click="initialize"
				        small
				      >
				        Actualizar
				      </v-btn>
				    </template>
				  </v-data-table>
				</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Agregar registro -->
	  <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
            	<v-col cols="12">
                <v-autocomplete
                  outlined
                  dense
                  clearable
                  v-model="editedItem.idfuentes"
                  :items="fuentes"
                  label="Selecciona la fuente"
                  persistent-hint
                  item-value="idfuentes"
                  item-text="fuente"
                  class="mt-4"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.detalle_fuente"
                  label="Detalle"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
              	<v-switch 
              		label="Activo"
                  hide-details
              		v-model="editedItem.estatus"></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="save"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Eliminar registro -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el detalle de la fuente?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text large class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C" dark class="elevation-6" large @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'


  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ID'     , align: 'start', sortable: false, value: 'iddetalle_fuentes', },
        { text: 'Fuente'   , value: 'fuente' },
        { text: 'Detalle'  , value: 'detalle_fuente' },
        { text: 'Estatus'  , value: 'estatus' },
        { text: 'Actions'  , value: 'actions', sortable: false },
      ],
      detalles: [],
      editedIndex: -1,
      editedItem: {
      	iddetalle_fuentes:'',
        idfuentes: '',
        detalle_fuente: '',
        estatus: '',
      },
      defaultItem: {
        iddetalle_fuentes:'',
        idfuentes: '',
        detalle_fuente: '',
        estatus: '',
      },

      fuentes:[],
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar fuente' : 'Editar fuente'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    async created () {
      await this.initialize()
      await this.getFuntes()
    },

    methods: {
      initialize () {
        this.detalles = []
        this.cargar = true
        return this.$http.get('fuentes.detalle.list').then(response=>{
        	this.detalles = response.data
        	this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
      },

      getFuntes () {
        this.fuentes = []
        this.cargar = true
        return this.$http.get('fuentes.activos').then(response=>{
        	this.fuentes = response.data
        	this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.detalles.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.detalles.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        // Lo mandapos por el EP
        this.editedItem.deleted = 1
        this.$http.put('fuentes.update/' + this.editedItem.iddetalle_fuentes, this.editedItem).then(response=>{
        	this.validarSuccess('Datos elimniados correctamente')
        	this.cargar = false
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	if(this.editedItem.detalle_fuente == ''){
      		this.validarErrorDirecto('Favor de llenar todos los campos')
	        return
      	}
        if (this.editedIndex > -1) {
        	console.log('update')
         //  // Activamos el loader
	        this.cargar = true
	        // Lo mandapos por el EP
          this.$http.put('fuentes.detalle.update/' + this.editedItem.iddetalle_fuentes, this.editedItem).then(response=>{
          	this.validarSuccess('Datos actualizados correctamente')
          	this.initialize()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })

        } else {
        	// Activamos el loader
	        this.cargar = true
	        // Lo mandapos por el EP
          this.$http.post('fuentes.detalle.add', this.editedItem).then(response=>{
          	this.validarSuccess('Datos agregados correctamente')
          	this.initialize()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
        this.close()
      },

      copiarPortapapeles () {
        navigator.clipboard.writeText(this.error).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      }
    },
  }
</script>

