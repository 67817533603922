<template>
  <v-container>
    <v-dialog 
      persistent 
      v-model="isOpen" 
      @click:outside="closeDialog" 
      @keydown.esc="closeDialog" 
      max-width="600px" 
      @keydown.ctrl="pegar"
    >
      <v-card>
        <v-card-title class="py-2">
          <span class="headline">Agregar Ticket</span>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text class="black--text">

          <!-- Alerta aviso -->
          <v-row>
            <v-col cols="12">
              <v-alert text dense color="teal" icon="mdi-information" border="left">
                Por favor, detalla tu problema para que se le pueda dar una solución más rápida, también, escribe que es lo que te gustaría obtener como respuesta.
              </v-alert>
            </v-col>
          </v-row>

          <!-- Datos generales -->
          <v-row>
            <v-col cols="12">
              <label class="black--text"><b>¿El problema está relacionado a uno o más alumnos?</b></label>
              <v-radio-group
                v-model="opContieneAlumno"
                row
                hide-details
              >
                <v-radio
                  label="SI"
                  :value="1"
                ></v-radio>
                <v-radio
                  label="NO"
                  :value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" v-if="opContieneAlumno == 1">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :items="alumnos"
                    v-model="alumno"
                    label="Alumno"
                    filled
                    hide-details
                    dense
                    return-object
                    item-text="datos_completos"
                    item-value="id_alumno"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    :items="grupos"
                    v-model="grupo"
                    label="Grupo"
                    filled
                    hide-details
                    dense
                    return-object
                    item-text="grupo"
                    item-value="id_grupo"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-btn small dark color="primary" @click="saveResultado()" tile class="my-2">
                    <v-icon>mdi-plus</v-icon>
                    Agregar
                  </v-btn>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headersAlumnos"
                :items="alumnos_ticket"
                class="elevation-0 mt-4"
                :mobile-breakpoint="100"
                dense
              >

                <template v-slot:item.actions="{ item }">
                  <v-btn 
                    color="error" 
                    x-small
                    @click="deleteItem2(item)"
                    class="mr-2"
                  >
                    <v-icon small> mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="12" >
              <label class="black--text"><b>¿El problema es de tipo urgencia?</b></label>
              <v-checkbox hide-details v-model="urgente" :label="`Urgente`"></v-checkbox>
            </v-col>

            <v-col cols="12" lg="6">
              <v-autocomplete 
                filled 
                dense 
                hide-details 
                :items="temas" 
                v-model="idtemas_tickets" 
                label="Selecciona un tema" 
                item-value="idtemas_tickets" 
                item-text="tema"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" lg="6" v-if="idtemas_tickets != 16">
              <v-autocomplete 
                filled 
                dense 
                hide-details 
                :items="filterSubtemas" 
                v-model="idsubtemas_tickets" 
                label="Selecciona un subtema" 
                item-value="idsubtemas_tickets" 
                item-text="subtema"
              ></v-autocomplete>
            </v-col>


            <v-col cols="12" v-if="idtemas_tickets == 16">
              <v-text-field
                label="Ingresa un motivo"
                filled
                dense
                hide-details
                v-model="otro_motivo"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6">
              <v-autocomplete 
                filled 
                dense 
                hide-details 
                :items="planteles" 
                v-model="plantel" 
                label="Selecciona un plantel" 
                item-value="id_plantel" 
                item-text="plantel"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" lg="6">
              <v-autocomplete 
                filled 
                dense 
                hide-details 
                :items="sucursales" 
                v-model="sucursal" 
                label="Selecciona una sucursal" 
                item-value="id_plantel" 
                item-text="plantel"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-textarea filled v-model="motivo" label="Ingrese su problema de manera detallada y clara" hide-details :rows="3" auto-grow></v-textarea>
            </v-col>

            <v-col cols="12">
              <v-file-input small-chips multiple filled v-model="files" accept="image/png, image/jpeg, image/bmp, video/mp4, video/x-m4v, video/*"
                placeholder="Selecciona tus imagenes" prepend-icon="mdi-camera" label="Imágenes" @change="cargarFotos()">
              </v-file-input>
            </v-col>

            <v-col cols="12" lg="6">
              <v-autocomplete filled dense hide-details :items="areas" v-model="area" label="Selecciona departamento"
                return-object item-value="iddepartamento" item-text="departamento">
              </v-autocomplete>
            </v-col>

            <v-col cols="12" lg="6" v-if="puestos.length > 1">
              <v-autocomplete filled dense hide-details :items="puestos" v-model="puesto" label="Selecciona un puesto"  return-object item-value="idpuesto" item-text="puesto">
              </v-autocomplete>
            </v-col>
          </v-row>

        <v-row v-if="vistaPrevias.length">
          <v-col cols="12" md="3" v-for="(img, i) in vistaPrevias" :key="i">
            <v-card class="py-4 shadowCard">
              <v-img v-if="!img.video" :src="img.url" contain aspect-ratio="2"></v-img>
              <video v-else :src="img.url" contain aspect-ratio="2" style="width: 100%; height: 100%;" class="d-flex"></video>
              <v-btn  color="error"  small  @click="eliminarFoto(img.url)" top right absolute fab >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          color="green" 
          tile 
          :loading="cargar" 
          :disabled="cargar" 
          @click="save()" 
          dark
          small
          class="mb-2"
        >
          <v-icon small left>mdi-content-save</v-icon>
          Agregar
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>

    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
  </v-container>
</template>
<script>

  import axios from 'axios';
  import {mapGetters, mapActions} from 'vuex'
  import { Clipboard } from "@capacitor/clipboard";
  
    // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue'

  import validarErrores  from '@/mixins/validarErrores'
  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    props: [
      'isOpen',
      'temas',
      'subtemas'
    ],

    watch: {
      area(){
        this.puestos = this.area.puestos
        for( const i in this.puestos ){
          if( this.puestos[i].idpuesto == 68  ){
            this.puestos[i].puesto = 'RH'
          }
        }
        console.log( this.puestos )
        if( this.puestos.length == 1 ){
          this.puesto = this.puestos[0]
        }
      },

      puesto(){
        this.jefes = this.puesto.jefes

        console.log( this.jefes )

        if( this.puesto.puesto == "Encargada de sucursal" ){
          this.jefe = this.jefes.find( el => el.id_usuario == 568 )
        }else{
          this.jefe = this.jefes[0]
        }
      },

      alumno( ){
        if( this.alumno ){
          this.getGruposAlumno()
        }
      }

    },

    computed:{
      ...mapGetters('login',['getdatosUsuario']),

      filterSubtemas( ){

        if( this.idtemas_tickets ){

          return this.subtemas.filter( el => el.idtema == this.idtemas_tickets )

        }else{ return [] }

      },

      filterAlumnos( ){
        // Valdiar que este buscando algo 
        if(this.searchAlumno){
          // filtar los alumnos
          return this.alumnos.filter( el => {

            // normalizar los datos completos del alumno
            let datos_completos = el.datos_completos.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"")

            // Normalizar el datos buscado
            const buscar        = this.searchAlumno.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"")

            // Realizar el match
            return datos_completos.match(buscar)
          })
        }else{ return this.alumnos }
      }

    },

    data: () => ({
      respuestaAlerta:false,
      cargar: false,

      urgente: 0,
      url:'',

      fotos:[],
      files: [],
      vistaPrevias:[],

      area: null,
      motivo: "",
      motivo_aux: "",
      plantel: null,
      planteles: [
        { id_plantel: 1, plantel: "INBI" },
        { id_plantel: 2, plantel: "FAST ENGLISH" },
      ],

      sucursales:[],
      sucursal: null,

      areas:[],
      area: null,

      puestos:[],
      puesto:null,

      jefes:[],
      jefe: null,

      show_folio: false,

      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      idtemas_tickets: null,
      idsubtemas_tickets: null,
      otro_motivo:null,

      opContieneAlumno: null,

      alumno: null,
      grupo: null,
      grupos:[],
      alumnos: [],
      headersAlumnos:[
        { text: 'matricula', value: "matricula" },
        { text: 'alumno'   , value: "alumno" },
        { text: 'grupo'    , value: "grupo" },
      ],

      searchAlumno:'',
      alumnos_ticket:[]
    }),

    async created() {
      var url = axios.defaults.baseURL + 'evidencia-tickets/'
      this.initialize()
      await this.getAreas()
      await this.getAlumnos()
    },

    methods: {
      initialize () {
        this.sucursales = []
        this.cargar = true
        this.$http.get("sucursales.all").then(response=>{
          this.sucursales = response.data
          this.cargar     = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
      getAreas () {
        this.cargar = true
        this.areas = []
        this.$http.get('departamentos.tickets').then(response=>{
          this.areas = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      closeDialog(){
        this.$emit('close');        
      },

      getAlumnos( ) {
        this.cargar = true
        this.alumnos = []
        return this.$http.get('inscripciones.alumnos').then(response=>{
          this.cargar = false
          this.alumnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getGruposAlumno ( ){
        this.cargar = true
        this.grupos = []
        return this.$http.get('cambios.grupo.usuario/' + this.alumno.id_alumno).then(response=>{
          this.grupos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async save() {
        if( this.area && this.area.iddepartamento == 9 ){
          this.puesto = { idpuesto : 43 }
          this.jefe   = { id_usuario : 1 }
        }


        if(!this.opContieneAlumno){
          return this.validarErrorDirecto('Por favor, llena la primer pregunta')
        }

        if(this.opContieneAlumno == 1 && this.alumnos_ticket.length < 1 ){
          return this.validarErrorDirecto('Por favor, llena los datos del alumno')
        }


        if(!this.vistaPrevias.length){
          return this.validarErrorDirecto('Lo sentimos, pero no puedes subir un ticket sin EVIDENCIA')
        }

        // Validar que no vaya vacío el ticket
        if( !this.idtemas_tickets ){
          return this.validarErrorDirecto('Favor de llenar un motivo, si no sabes qué seleccionar, usa la opción de OTRO')
        }

        if( this.idtemas_tickets == 16 && !this.otro_motivo ){
          return this.validarErrorDirecto('Favor de llenar un motivo')
        }


        if (this.plantel != 0 && this.motivo != "" && this.sucursal && this.getdatosUsuario.iderp > 0 && this.area && this.puesto ) {

          this.cargar = true
          let fotos = []
          if(this.vistaPrevias.length){
            let respuesta = await this.subirFotosServidor( )
            fotos = respuesta.data
          }

          let usuario_id = null

          if( this.idsubtemas_tickets ){

            const existesubtema = this.subtemas.find( el => el.idsubtemas_tickets == this.idsubtemas_tickets )

            usuario_id = existesubtema ? existesubtema.id_usuario  : 0

          } 

          var payload = {
            id_unidad_negocio: this.plantel,
            id_area          : this.puesto.idpuesto,
            idjefe_area      : usuario_id ? usuario_id : this.jefe.id_usuario,
            motivo           : this.motivo,
            estatus          : 2,
            id_usuario       : this.getdatosUsuario.iderp,
            tipousuario      : 0,
            id_suc           : this.sucursal,
            urgente          : this.urgente == true ? 1 : 0,
            fotos,
            idtemas_tickets   : this.idtemas_tickets,
            idsubtemas_tickets: this.idsubtemas_tickets,
            otro_motivo       : this.otro_motivo,
            alumnos           : this.alumnos_ticket
          };

          this.$http.post("tickets.add", payload).then((response) => {
            this.folio               = response.data.ticket.id;
            this.idhistorial_ticket  = response.data.historial.id;
            this.otro_motivo         = null
            this.motivo_aux = this.motivo;
            this.show_folio = true;
            this.plantel    = null
            this.motivo     = "";
            this.sucursal   = null;
            this.urgente    = 0;
            this.vistaPrevias = []
            this.validarSuccess( response.data.message )
            this.$emit('recargar')
            this.$emit('close')
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { 
            this.cargar = false
          })

        }else{
          this.validarErrorDirecto('Favor de llenar todos los datos')
        }

      },

      saveResultado( ){
        this.alumnos_ticket.push({
          ...this.alumno,
          ...this.grupo
        })
      },

      subirFotosServidor( ){
        let formData = new FormData();

        for( const i in this.vistaPrevias ){
          formData.append('file', this.vistaPrevias[i].file )
        }

        return new Promise((resolve,reject)=>{
                // obtener la extensión del archivo
                // Hacemos la petición
          this.$http.post(`tickets.subir.evidencia`, formData ).then(response=>{
            resolve( response )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },

      cargarFotos(){
        if(this.files.length < 1){
          return
        }

        this.files.forEach((element, index) => {
                // creamos una variable tipo FormData
          let formData = new FormData();
                //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', element);
                //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
                if(element.size > 26214400){ //26214400 = 25MB
                  console.log(element.size)
                  this.validarErrorDirecto('El archivo sobrepasa el limite del peso, max 25 MB')
                } else {
                  this.getBase64(element, formData)
                }
              })
      },

      getBase64(file, formData) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          console.log(file)
          var video = false
          const nombreSplit = file.name.split('.')
          const extension = nombreSplit[nombreSplit.length-1]
          if(['mp4', 'mov', 'wmv', 'avi'].includes(extension)){
            video = true
          }
          me.vistaPrevias.push({
            url: reader.result, 
            formData: formData, 
            image_name: file.name,
            video : video,
            file
          })
        };
      },

      eliminarFoto(value){
        this.vistaPrevias.forEach((element, index)=>{
          if(element.url == value){
            this.vistaPrevias.splice(index,1);
          }
        })
      },

      convertFile(dataurl, filename) {
        var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      },

      createFile(file) {
        if (file.size > 10000000) {
          alert("please check file size no over 5 MB.");
          this.dragging = false;
          return;
        }

        this.file = file;
        this.archivos = file;
        this.getBase64(this.archivos);
        this.dragging = false;
      },

      async pegar(event) {
        if (event.code == "KeyV") {
          const { type, value } = await Clipboard.read();

                    // Validamos que sea de tipo imagen
          if (type == "image/png") {
                    // Convertimos el base64 en una imagen
            const file = this.convertFile(value, "imagen.png");
            this.createFile(file);
          }
        }
      },

    },
  }

</script>