<template>
	<v-card class="transparent elevation-0">
    <v-card-text>

      <v-data-table
        :headers="headers"
        :items="tablaPagosSucursal"
        class="shadowCard"
        dense
        mobile-breakpoint="200"
        :items-per-page="tablaPagosSucursal.length"
        hide-default-footer
      >

        <template v-slot:top>
          <v-card-title primary-title>
            <label class="text-subtitle-1 black--text">Pagos recibidos por mi sucursal</label>
            <v-spacer></v-spacer>
          </v-card-title>
        </template>

        <template v-slot:item.plantel="{ item }">
          <div
            v-if="
              item.id_plantel == plantel ||
              item.id_plantel == 1000
            "
            class="green white--text pr-2"
          >
            {{ item.plantel }}
          </div>
          <div v-else class="pr-2">{{ item.plantel }}</div>
        </template>
        <template v-slot:item.total="{ item }">
          <div
            v-if="
              item.id_plantel == plantel ||
              item.id_plantel == 1000
            "
            class="green white--text pr-2"
          >
            {{ item.total }}
          </div>
          <div v-else class="pr-2">{{ item.total }}</div>
        </template>
        <template v-slot:item.efectivo="{ item }">
          <div
            v-if="
              item.id_plantel == plantel ||
              item.id_plantel == 1000
            "
            class="green white--text pr-2"
          >
            {{ item.efectivo }}
          </div>
          <div v-else class="pr-2">{{ item.efectivo }}</div>
        </template>
        <template v-slot:item.transferencia="{ item }">
          <div
            v-if="
              item.id_plantel == plantel ||
              item.id_plantel == 1000
            "
            class="green white--text pr-2"
          >
            {{ item.transferencia }}
          </div>
          <div v-else class="pr-2">{{ item.transferencia }}</div>
        </template>
        <template v-slot:item.depositos="{ item }">
          <div
            v-if="
              item.id_plantel == plantel ||
              item.id_plantel == 1000
            "
            class="green white--text pr-2"
          >
            {{ item.depositos }}
          </div>
          <div v-else class="pr-2">{{ item.depositos }}</div>
        </template>
        <template v-slot:item.tarjeta="{ item }">
          <div
            v-if="
              item.id_plantel == plantel ||
              item.id_plantel == 1000
            "
            class="green white--text pr-2"
          >
            {{ item.tarjeta }}
          </div>
          <div v-else class="pr-2">{{ item.tarjeta }}</div>
        </template>
      </v-data-table>
    </v-card-text>

  </v-card>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta              from '@/components/alertas/Alerta.vue'
  import carga               from '@/components/alertas/carga.vue';

  import validarErrores      from '@/mixins/validarErrores'
  import funcionesExcel      from '@/mixins/funcionesExcel'
  
	const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

  	props:[
			'tablaPagosSucursal',
			'plantel',
      'url_servidor'
  	],

    data: () => ({
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      tipos_pago:[
        { id: 1, tipo: 'EFECTIVO'},
        { id: 2, tipo: 'DEPOSITO / TRANSFERENCIA'},
        { id: 4, tipo: 'TARJETA' },
        { id: 0, tipo: 'TODO' },
      ],

      formaPago: 0,

      headers: [
	      { text: "id"           , value: "id_plantel" },
	      { text: "Plantel"      , value: "plantel"      , align: "right" },
	      { text: "Efectivo"     , value: "efectivo"     , align: "right" },
	      { text: "Transferencia", value: "transferencia", align: "right" },
	      { text: "Depositos"    , value: "depositos"    , align: "right" },
	      { text: "Tarjeta"      , value: "tarjeta"      , align: "right" },
	      { text: "Total"        , value: "total"        , align: "right" },
	    ],
      dialgImagen:false,
      imagen:null,
    }),

    methods: {

      verImagen(value) {            //Angel Rodriguez              
        this.imagen = value;
        this.dialgImagen = true;
      },


    }
  };
</script>