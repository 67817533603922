<template>
  <v-container class="mt-5 ml-2">
    <v-row>
      <v-col cols="12">
        <v-card-title primary-title>
        	<!-- Buscador -->
          <v-text-field
            dense
            outlined
            rounded
            hide-details
            label="Búscar"
            v-model="search"
            append-icon="mdi-search"
          ></v-text-field>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
        </v-card-title>
      </v-col>

      <!-- Catalago en forma de carts -->
      <v-col cols="12" md="4" lg="3" v-for="(a, i) in filterZona" :key="i">
        <v-card class="ma-2 elevation-12">
          <v-row>
            <v-col cols="12">
              <v-card-text align="center" class="pa-0 pt-2">
               <h2> <b>{{a.zona}}</b><br/></h2>
              </v-card-text>
              <v-card-title primary-title>
                Sucursales 
                <v-btn 
                	color="blue" 
                	icon 
                  @click="addSucursal(a)"
                 >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <!-- sucursales de la zona -->
              <v-data-table
                :loading="loading"
                :headers="headerSucursal"
                :items="a.sucursales"
                fixed-header
                :height="200"
                hide-default-footer
                loading-text="Cargando..."
                dense
                :items-per-page="100"
                :mobile-breakpoint="250"
                >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    color="blue"
                    small
                    class="mr-2"
                    @click="editSuc(item), idSelectCatego = item.idcatego"
                  >
                    mdi-pencil
                  </v-icon>

                  <v-icon
                    color="red"
                    small
                    @click="dialog3 = true, itemDeleteSuc = item"
                  >
                    mdi-delete
                  </v-icon>

                </template>
                </v-card-title>
              </v-data-table>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="yellow" small  @click="editarZonaF(a)" rounded>Editar<v-icon right>mdi-pencil</v-icon></v-btn>
            <v-btn color="error" small @click="closeDialogDeleteZona = true, itemDelete = a" rounded>Eliminar<v-icon right>mdi-delete</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Eliminar una categoría -->
    <v-row justify="center">
      <v-dialog v-model="closeDialogDeleteZona" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Eliminar la zona</v-card-title>
          <v-card-text>¿Seguro que deseas eliminar la zona?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" small  @click="closeDialogDeleteZona = false">Cancelar</v-btn>
            <v-btn color="green darken-1" small text @click="deleteZona()">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Editar o agregar un puesto -->
    <v-row justify="center">
      <v-dialog v-model="closeDialogSuc" max-width="500px">
        <v-card>
        	<v-card-title primary-title>
        	  Sucursal
        	</v-card-title>
          <v-card-text>
            <!-- return-object -->
            <v-text-field 
            	v-model="editedDetalleSuc.plantel" 
            	label="Sucursal"
            	dense
            	outlined
            ></v-text-field>

            <v-select
              :items="unidades_negocio"
              v-model="unidad_negocio"
              label="Unidad de negocio"
              hide-details
              dense
              outlined
              item-text="negocio"
              item-value="id"
              return-object
            ></v-select>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" rounded dark text small @click="closeDialogSuc=false">cerrar</v-btn>
            <v-btn color="blue darken-1" rounded dark text small @click="guardarSuc()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Elimnar un detalle -->
    <v-row justify="center">
      <v-dialog v-model="dialog3" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Eliminar sucursal</v-card-title>
          <v-card-text>¿Deseas eliminar la sucursal?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" small  @click="dialog3 = false">Cancelar</v-btn>
            <v-btn color="green darken-1" small text @click="deletePuesto()">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </v-container>
</template>

<script>
import axios from 'axios';
	var moment = require('moment');
  moment.locale(); 

  export default {
    data: () => ({
    	// Datos del departamento
    	closeDialogZona: false,
    	idzona:0,
    	fehcaHoy:'',

    	closeDialogSuc: false,

      idSelectCatego:0,
      itemDeleteSuc:'',
      metodoDescrip: 0,


      idCatego:0,
      mensaje:'',
      dialog3: false,
      dialog4:false,
      loading:true,
      idSelectArt:0,
      closeDialogDeleteZona: false,


    	loading:true,
    	itemDelete: {},
      dialog: false,
      headerSucursal: [
        { text: 'Plantel',   align: 'start', value: 'plantel'},
        { text: 'Acciones', align: 'start', value: 'actions'},
      ],
      departamentos: [],
      editedIndex: -1,
      editarZona: {
        idzona: 0,
      	zona:'',
      	usuario_registro: 1,
      	fecha_creacion:'',
      	fecha_actualizo:'',
      	deleted: 0,
      	sucursales:[],
      	
      },

      editedDetalleSuc: {
        idplantel:0,
        plantel: '',
        idzona: '',
        usuario_registro: 1,
        fecha_creacion:'',
        fecha_actualizo:'',
        deleted: 0,
        idunidad_negocio:0,
      },


      defaultItem: {
        idplantel: 0,
        plantel: '',
        idzona: '',
        usuario_registro: 1,
        fecha_creacion:'',
        fecha_actualizo:'',
        deleted: 0,
        idunidad_negocio:0,
      },
      search:'',
      longitud: 100,
      zonas:[],
      unidades_negocio:[{id:1, negocio:'INBI'},{id:2, negocio:'FAST ENGLISH'}],
      unidad_negocio:{id:1, negocio:'INBI'},
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva clasificación' : 'Editar clasificación'
      },
    },

    watch: {
      closeDialogSuc (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    methods: {

      initialize () {
        this.zonas = []
        this.$http.get('zonas.all').then(response=>{
          // Traer todos los puestos
          this.$http.get('planteles.all').then(responsePuestos=>{
            // Hacemos un ciclo para obtener los datos de los departamentos
            for(const i in response.data){
              var data = {
                idzona:     response.data[i].idzona,
                zona:       response.data[i].zona,
                usuario_registro:   response.data[i].usuario_registro,
                fecha_creacion:     response.data[i].fecha_creacion.substr(0,10),
                fecha_actualizo:    response.data[i].fecha_actualizo.substr(0,10),
                deleted:            response.data[i].deleted,
                sucursales:         [],
              }
              // Lo agregamos a departamentos
              // Hacemos un ciclo a sucursales para validar que puesto le pertenece a que departamento
              for(const j in responsePuestos.body){
                if(response.data[i].idzona == responsePuestos.body[j].idzona){
                  responsePuestos.body[j].fecha_creacion  = responsePuestos.body[j].fecha_creacion.substr(0,10)
                  responsePuestos.body[j].fecha_actualizo = responsePuestos.body[j].fecha_actualizo.substr(0,10)
                  data.sucursales.push(responsePuestos.body[j])
                }
              }
              this.zonas.push(data)
            }
            
            this.longitud = this.zonas.length
            this.loading = false
            // Traer los sucursales
          }).catch(error=>{console.log(error)})
        }).catch(error=>{console.log(error)})
      },

      // Agregar el puesto que pertenecerá a ese departamento
      addSucursal(value){
      	this.idzona = value.idzona
      	this.closeDialogSuc = true
      },

      // Editar el departamento, recibe el parametro
      editarZonaF (item) {
      	// Generamos un indexof para buscar el objeto dentro del arreglo de los departamentos
        this.editedIndex = this.zonas.indexOf(item)
        this.editarZona = Object.assign({}, item)
        this.closeDialogZona = true // abrimos el cuadro de dialogo
      },

      // Eliminar el departament
      deleteZona () {
      	// Mandmos el id del departamento a eliminar
        // Cambiamos el estatus a eliminado
        this.itemDelete.deleted = 1
        this.$http.put('zonas.update/' + this.itemDelete.idzona,this.itemDelete).then(response=>{
          this.closeDialogDeleteZona = false
        	this.initialize()
        }).catch(error=>console.log(error))
      },

      close () {
        this.closeDialogSuc = false
      },

      addZona () {
      	// Validamos que es lo que haremos, si editar o agregar
        if (this.editedIndex > -1) {
        	// Actualizar departamento
        	this.$http.put('zonas.update/' + this.editarZona.idzona, this.editarZona).then(response=>{
        		// Reiniciamos
            this.closeDialogZona = false
	        	this.initialize()
	        }).catch(error=>{console.log(error)})

	      // Agregamos el departamento
        } else {
        	// Editamos algunos campos que vamos a utilizar que son las fechas
        	this.editarZona.fecha_creacion = this.fecha
        	this.editarZona.fecha_actualizo = this.fecha
          this.$http.post('zonas.add',this.editarZona).then(response=>{
	        	// Cerramos el cuadro de dialogo
	        	this.closeDialogZona = false
	        	this.initialize()
	        }).catch(error=>{console.log(error)})
        }
        this.close()
      },

      // Guardamos el puesto del departamento
      guardarSuc(){
        // Guarddamos la unidad de degocio
        this.editedDetalleSuc.idunidad_negocio = this.unidad_negocio.id
        
        if (this.metodoDescrip == 2) {
          this.$http.put('planteles.update/' + this.editedDetalleSuc.idplantel, this.editedDetalleSuc).then(response=>{
            this.closeDialogSuc = false
            this.editedDetalleSuc = this.defaultItem
            this.metodoDescrip = 0
            this.initialize()
          }).catch(error=>{console.log(error)})
        } else {
          // Le asignamos su id de departamento
          this.editedDetalleSuc.idzona = this.idzona
          // Actualizamos las fechas
          this.editedDetalleSuc.fecha_creacion = this.fecha
          this.editedDetalleSuc.fecha_actualizo = this.fecha
          // Agregamos
          this.$http.post('planteles.add',this.editedDetalleSuc).then(response=>{
            this.closeDialogSuc = false
            this.editedDetalleSuc = this.defaultItem
            this.metodoDescrip = 0
            this.initialize()
          }).catch(error=>{console.log(error)})
        }
      },

      editSuc(value){
      	if(value.idunidad_negocio==1){
      		this.unidad_negocio = {id:1, negocio:'INBI'}
      	}else{
      		this.unidad_negocio = {id:2, negocio:'FAST ENGLISH'}
      	}

        this.editedDetalleSuc = Object.assign({}, value)
        this.closeDialogSuc = true
        this.metodoDescrip = 2
      },

      deletePuesto(){
        console.log(this.itemDeleteSuc)
        this.itemDeleteSuc.deleted = 1
        this.$http.put('planteles.update/' + this.itemDeleteSuc.idplantel, this.itemDeleteSuc).then(response=>{
          this.dialog3 = false
          this.initialize()
        }).catch(error=>console.log(error))
      },

    },

    computed:{
      filterZona: function(){
        var me = this
        if(this.zonas){
          return this.zonas.filter((art)=>{
            var nom = art.zona.toLowerCase();
            return nom.match(this.search.toLowerCase());
          })
        }
      },
    }
  }
</script>
