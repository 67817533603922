<template>
  <v-container class="mt-5 ml-2">
  	<v-row>
  		<v-col cols="12">
		  	<v-card class="shadowCard">
          <v-card-title class="text-subtitle-1">
            Ingresos - Egresos
		        <v-spacer></v-spacer>
		      
            <v-btn 
		          class="mr-2" 
		          small dark color="green"
		          @click="exportar()"
              tile
            >
		          <v-icon small>mdi-microsoft-excel</v-icon>
		        </v-btn>

		        <v-btn 
              color="primary" 
              @click="getEgresos()" 
              small
              tile
            >
              <v-icon small left>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          
          </v-card-title>

		      <v-card-text class="pb-6">

            <!-- Tabla de ingresos y egresos de fast -->
		        <v-data-table
		          :headers="headers"
		          :items="egresos"
		          class="elevation-0"
		          hide-default-footer
              fixed-header
		          :items-per-page="egresos.length"
		          dense
		          mobile-breakpoint="200"
		        >
              <template v-slot:top>
                <v-card-title primary-title>
                  Fast English
                </v-card-title>
              </template>

              <template v-slot:item.sumaegreso="{ item }">
                <v-chip
                  small
                  @click="verDetalleEgreso(item.detalleEgreso)"
                >
                  {{ item.sumaegreso }}
                </v-chip>
              </template>
		        </v-data-table>

            <v-data-table
              :headers="headers"
              :items="egresosInbi"
              class="elevation-0"
              hide-default-footer
              fixed-header
              :items-per-page="egresosInbi.length"
              dense
              mobile-breakpoint="200"
            >
              <template v-slot:top>
                <v-card-title primary-title>
                  Inbi School
                </v-card-title>
              </template>

              <template v-slot:item.sumaegreso="{ item }">
                <v-chip
                  small
                  @click="verDetalleEgreso(item.detalleEgreso)"
                >
                  {{ item.sumaegreso }}
                </v-chip>
              </template>

            </v-data-table>

            <v-card class="elevation-0 transparent" v-if="verGrafica">
              <v-card-title primary-title>
                Ingresos - Egresos Fast English
              </v-card-title>
              <v-card-text>
                <vue-apex-charts
                  type="area" height="350" :options="chartOptions" :series="series"
                ></vue-apex-charts>
              </v-card-text>
            </v-card>

            <v-card class="elevation-0 transparent" v-if="verGraficaInbi">
              <v-card-title primary-title>
                Ingresos - Egresos Inbi School
              </v-card-title>
              <v-card-text>
                <vue-apex-charts
                  type="area" height="350" :options="chartOptionsInbi" :series="seriesInbi"
                ></vue-apex-charts>
              </v-card-text>
            </v-card>

		      </v-card-text>
			  </v-card>
  		</v-col>
  	</v-row>

    <v-dialog
      v-model="dialogDesglose" 
      overlay
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card class="shadowCard">
        <v-card-title class="text-subtitle-1">
          Desglose de egresos
          <v-spacer></v-spacer>
        
          <v-btn 
            class="mr-2" 
            small dark color="green"
            @click="exportExcel(dataDesglose,'egresos')"
            tile
          >
            <v-icon small>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>

          <v-row justify="end">
            <v-col cols="12" md="8" lg="6">
              <v-text-field
                name="name"
                label="Buscar"
                dense
                id="id"
                v-model="searchDesglose"
                filled
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Tabla de ingresos y egresos de fast -->
          <v-data-table
            :headers="headersDesglose"
            :items="dataDesglose"
            class="elevation-0"
            :search="searchDesglose"
            dense
            mobile-breakpoint="200"
          >
            <template v-slot:item.sumaegreso="{ item }">
              <v-chip
                small
                @click="verDetalleEgreso(item.detalleEgreso)"
              >
                {{ item.sumaegreso }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn 
            color="black"
            dark
            small
            tile
            @click="dialogDesglose = false"
          >
            <v-icon left small>mdi-cancel</v-icon>
            Cancelar
          </v-btn>        
        </v-card-actions>
      </v-card>
    </v-dialog>

	 
    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'
  import VueApexCharts from 'vue-apexcharts'

  import validarErrores     from '@/mixins/validarErrores'
  import funcionesExcel     from '@/mixins/funcionesExcel'

  // Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  export default {
    components: {
      VueApexCharts,
      Alerta,
      carga
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      snackbar:false,
      headers: [
        { text: 'id_ciclo'  , value: 'id_ciclo' },
        { text: 'ciclo'     , value: 'ciclo' },
        { text: 'Ingreso'   , value: 'sumaingreso' , align:'end'},
        { text: 'Egreso'    , value: 'sumaegreso'  , align:'end'},
      ],
      ciclos:[],
      ciclo:null,
      ingresos:[],
      egresos:[],
      egresosInbi:[],
      dialogError: false,
      error:'',

      verGrafica: false,
      series: [{
        name: 'Ingresos',
        data: []
      }, {
        name: 'Egresos',
        data: []
      }],

      chartOptions: {
        chart: {
          height: 500,
          type: 'area'
        },

        dataLabels: {
          enabled: true
        },

        stroke: {
          curve: 'smooth'
        },

        xaxis: {
          // type: 'caracter',
          // categories: [],
          categories: [],
          
          axisBorder: {
            show: false,
          },

          axisTicks: {
            show: false,
          },
          tickPlacement: 'on',
          labels: {
            show: true,
            style: {
              fontSize: '12px',
            },
          },
        },

        // tooltip: {
        //   x: {
        //     format: ''
        //   },
        // },
      },


      verGraficaInbi: false,
      seriesInbi: [{
        name: 'Ingresos',
        data: []
      }, {
        name: 'Egresos',
        data: []
      }],

      chartOptionsInbi: {
        chart: {
          height: 500,
          type: 'area'
        },

        dataLabels: {
          enabled: true
        },

        stroke: {
          curve: 'smooth'
        },

        xaxis: {
          // type: 'caracter',
          // categories: [],
          categories: [],
          
          axisBorder: {
            show: false,
          },

          axisTicks: {
            show: false,
          },
          tickPlacement: 'on',
          labels: {
            show: true,
            style: {
              fontSize: '12px',
            },
          },
        },

        // tooltip: {
        //   x: {
        //     format: ''
        //   },
        // },
      },

      dialogDesglose: false,
      dataDesglose  : [],
      searchDesglose: '',
      headersDesglose: [
        { text: 'Partida'     , value: 'id_partida'     },
        { text: 'Concepto'    , value: 'concepto'       },
        { text: 'Plantel'     , value: 'plantel'        },
        { text: 'Cantidad'    , value: 'cantidad'       },
        { text: 'Cost. Uni.'  , value: 'costo_unitario' },
        { text: 'Cost. Tot'   , value: 'costo_total'    },
      ],

    }),

    async created () {
    	this.cargar = true
    	try{
      	await this.getEgresos()
      	this.cargar = false
    	}catch(e){
    		this.error = e
      	this.dialogError = true
      	this.cargar = false
    	}
    },

    methods: {
      getEgresos () {
        this.cargar = true
        this.verGrafica = false
        this.egresos = []

        this.chartOptions.xaxis.categories = []
        this.chartOptionsInbi.xaxis.categories = []
        this.series[0].data     = []
        this.series[1].data     = []
        this.seriesInbi[0].data = []
        this.seriesInbi[1].data = []
        // Mandamos a llamar a la api
        this.$http.get('ingresos_egresos.egresos.ingresos').then(response=>{
          // LE damos formato de dinero a la suma
          // Creamos la grafica para fast
          let dataFast = response.data.filter((ciclo)=>{
            var nom = ciclo.ciclo.toLowerCase();
            if(nom == 'inversion 2021'){
              return true;
            }
            return nom.match('fe');
          })

          for(const i in dataFast){
            if(dataFast[i].ciclo != 'INVERSION 2021'){
              this.series[0].data.push(dataFast[i].sumaingreso.toFixed(2))
              this.series[1].data.push(dataFast[i].sumaegreso.toFixed(2))
              this.chartOptions.xaxis.categories.push(dataFast[i].ciclo)
            }
          }

          for(const i in dataFast){
            dataFast[i].sumaegreso  = new Intl.NumberFormat("en-US",{style: "currency", currency: "MXN"}).format(dataFast[i].sumaegreso)
            dataFast[i].sumaingreso = new Intl.NumberFormat("en-US",{style: "currency", currency: "MXN"}).format(dataFast[i].sumaingreso)
          }

          // Creamos la grafica para inbi
          let dataInbi = response.data.filter((ciclo)=>{
            var nom = ciclo.ciclo.toLowerCase();
            if(nom == 'inversion 2021'){
              return false;
            }
            return (!nom.match('fe') && !nom.match('total'));
          })
          
          // Insertamos los datos
          for(const i in dataInbi){
            this.seriesInbi[0].data.push(dataInbi[i].sumaingreso.toFixed(2))
            this.seriesInbi[1].data.push(dataInbi[i].sumaegreso.toFixed(2))
            this.chartOptionsInbi.xaxis.categories.push(dataInbi[i].ciclo)
          }

          // Le damos formato
          for(const i in dataInbi){
            dataInbi[i].sumaegreso  = new Intl.NumberFormat("en-US",{style: "currency", currency: "MXN"}).format(dataInbi[i].sumaegreso)
            dataInbi[i].sumaingreso = new Intl.NumberFormat("en-US",{style: "currency", currency: "MXN"}).format(dataInbi[i].sumaingreso)
          }

          console.log( dataInbi )

          this.egresos     = dataFast
          this.egresosInbi = dataInbi
          this.verGrafica = true
          this.verGraficaInbi = true
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      verDetalleEgreso( data ){
        this.dataDesglose   = data
        this.dialogDesglose = true
      }

      
    },
  }
</script>