<template>
	<!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="dialogArchivos.estatus"
    persistent
    :max-width="400"
  >
		<v-container fluid>
		  <v-row>
		  	<v-col cols="12" class="pa-0">
		  		<v-card class="mx-auto shadowCard" >
			    <v-card-title class="text-h6 font-weight-regular justify-space-between">
			      <span class="text-subtitle-1"> Agregar archivo </span>
			    </v-card-title>
			    <v-card-text align="center">
			      <div v-if="!file">
	            <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
	              <div class="dropZone-info" @drag="onChange">
	                <span class="fa fa-cloud-upload dropZone-title"></span>
	                <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
	                <div class="dropZone-upload-limit-info">
	                  <div>Tamaño máximo: 100 MB</div>
	                </div>
	              </div>
	              <input type="file" @change="onChange">
	            </div>
	          </div>
	         
	          <div v-else>
	          	<v-card
				      	width="300"
				        color="primary"
				        dark
				      >
				        <v-card-text  class="text-subtitle-1 text-center">
				          Archivo cargado
				        </v-card-text>
				      </v-card>
	          </div>
			    </v-card-text>
			    <v-card-actions>
			      <v-btn color="red" dark small rounded @click="cancelar()">Cancelar</v-btn>
			      <v-spacer></v-spacer>
			      <v-btn color="success" dark small rounded @click="agregarCarpeta()">Agregar</v-btn>
			    </v-card-actions>
			  </v-card>
		  	</v-col>
		  </v-row>

		  <!-- Dialog de carga -->
	    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
	    <carga                v-if="cargar" />
		</v-container>
	</v-dialog>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta              from '@/components/alertas/Alerta.vue'
  import carga               from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'



	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

  	props:[
			'dialogArchivos',
			'carpetaSeleccionadas',
			'editedItem'
	  ],

    data: () => ({
    	// Datos del alumno inscrito
    	alumnoNuevo: false,
    	alumnos:[],
    	id_alumno: null,
    	grupoActual:null,
			grupoSiguiente:null,
			nuevoGrupo:false,

			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      archivos:null,
      vistaPrevia: null,
      file: '',
      dragging: false,
    }),

		computed: {
      ...mapGetters(['getdatosUsuario', 'getLogeado']),
		},

    methods: {
      agregarCarpeta () {
      	this.cargar = true
      	let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append("files", this.file);

        const idcarpetas = this.carpetaSeleccionadas.length ? 
        	this.carpetaSeleccionadas[ this.carpetaSeleccionadas.length - 1 ].idcarpetas : 0 

        formData.append("idcarpetas",idcarpetas );
        formData.append("idgrupos"  ,this.editedItem.idgrupos );


        this.$http.post("subir.archivos", formData).then( response => {
        	this.file = ''
      		this.cargar = false

        	this.dialogArchivos.estatus = false
          this.validarSuccess( 'Archivo grabado correctamente ')
          this.$emit('agregarArchivoLista', response.data )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      cancelar( ){
      	this.vistaPrevia        = null
	      this.archivos           = null
	      this.file               = '';
      	this.dialogArchivos.estatus = false
      },

      getBase64(file) {
	      var me = this
	      var reader = new FileReader();
	      reader.readAsDataURL(file);
	      reader.onload = function () {
	        me.vistaPrevia = {
	          url: reader.result, 
	          image_name: file.name
	        }
	      };
	    },
   
			onChange(e) {
				console.log( e )
	      var files = e.target.files || e.dataTransfer.files;
	      
	      if (!files.length) {
	        this.dragging = false;
	        return;
	      }
	      
	      this.createFile(files[0]);
	    },

	    createFile(file) {
	      // if (!file.type.match('image.*')) {
	      //   alert('please select txt file');
	      //   this.dragging = false;
	      //   return;
	      // }
	      
	      if (file.size > 10000000) {
	        alert('please check file size no over 5 MB.')
	        this.dragging = false;
	        return;
	      }
	      
	      this.file = file;
	      this.getBase64(this.file)
	      this.dragging = false;
	    },
    },
  }
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>


