<template>
  <v-container fluid class="mt-5 pl-6 pr-6">
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-body-1">Kpi General porT Teacher</span>
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn color="primary" @click="consultar()" small>Consultar</v-btn>
          </v-card-title>

          <v-card-text>

            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  v-model="cicloSup"
                  :items="ciclos"
                  label="Selecciona siguiente ciclo"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                  readonly
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headers2"
              :items="resultado.riTeachers"
              sort-by="calories"
              class="elevation-0 mt-4"
              dense
              mobile-breakpoint="200"
              id="kpiTeacher"
            >

              <template v-slot:item.irregulares="{ item }" align="center" class="text-center">
                <v-chip color="red" dark small @click="verIrregulares( item.alumnosIrregualres )" v-if="item.irregulares > 0 ">{{ item.irregulares }}</v-chip>
                <v-chip  color="green" dark small v-else>{{ item.irregulares }}</v-chip>
              </template>

              <template v-slot:item.becados="{ item }" align="center" class="text-center">
                <v-chip color="red" dark small @click="verBecados( item.alumnosBecados )" v-if="item.becados > 0 ">{{ item.becados }}</v-chip>
                <v-chip  color="green" dark small v-else>{{ item.becados }}</v-chip>
              </template>

            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogIrregulares"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Alumnos irregulares
        </v-card-title>
        <v-card-text>
          <div>Alumno sin maestro, o con adeudo, no esta en certificación y tampoco es alumno becado </div>
          <v-data-table
            :headers="headersIrregulares"
            :items="alumnos"
            class="elevation-0 mt-4"
            dense
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            rounded
            dark
            small
            @click="dialogIrregulares = false, alumnos = []"
          >
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="dialogBecados"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Alumnos Becados
        </v-card-title>
        <v-card-text>
          <div>Alumnos que no pagaron nada :p</div>
          <v-data-table
            :headers="headersIrregulares"
            :items="alumnos"
            class="elevation-0 mt-4"
            dense
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            rounded
            dark
            small
            @click="dialogBecados = false, alumnos = []"
          >
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'
  // Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'


  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      snackbar:false,
      headers: [
        { text: 'Plantel'          , value: 'plantel' },
        { text: 'Acumulado'        , value: 'acumuladoant' },
        { text: 'Becados'          , value: 'becadosant' },
        { text: 'NI'               , value: 'niant' },
        { text: 'Rezagados'        , value: 'resagados' },

        { text: 'A. Ciclo Actual'  , value: 'totalAlumnosActuales' },
        { text: 'A. Irregulares'   , value: 'irregulares' },
        { text: 'Becados'          , value: 'becados' },
        { text: 'B. Certificacion' , value: 'becadoCertificacion' },
        { text: 'Certificacion'    , value: 'certificacion' },
        { text: 'Reinscribibles'   , value: 'reinscribibles' },
        { text: 'Acumulado'        , value: 'totalAlumnosSiguientes' },
        { text: 'Avance'           , value: 'totalAlumnosAyer' },
        { text: 'Faltantes'        , value: 'faltantes', class: 'columna2' },
        { text: '%faltantes'       , value: 'porcentajeFaltantes' },
      ],

      headers2: [
        { text: 'id'               , value: 'id_maestro' },
        { text: 'Teacher'          , value: 'nombre_completo' },
        { text: 'A. Ciclo Actual'  , value: 'totalAlumnosActuales' },
        { text: 'A. Irregulares'   , value: 'irregulares' },
        { text: 'Becados'          , value: 'becados' },
        { text: 'B. Certificacion' , value: 'becadoCertificacion' },
        { text: 'Certificacion'    , value: 'certificacion' },
        { text: 'Reinscribibles'   , value: 'reinscribibles' },
        { text: 'Acumulado'        , value: 'totalAlumnosSiguientes' },
        { text: 'Avance'           , value: 'totalAlumnosAyer' },
        { text: 'Faltantes'        , value: 'faltantes', class: 'columna2' },
        { text: '%faltantes'       , value: 'porcentajeFaltantes' },
      ],

      headersIrregulares: [
        { text: 'id_alumno'        , value: 'id_alumno' },
        { text: 'Nombre'           , value: 'nombre' },
        { text: 'id_maestro'       , value: 'id_maestro' },
        { text: 'id_maestro_2'     , value: 'id_maestro_2' },
        { text: 'Adeudo'           , value: 'adeudo' },
        { text: 'Pagado'           , value: 'pagado' },
        // { text: 'Certificacion'    , value: 'certificacion' },
      ],

      desserts: [],
      ciclos:[],


      ciclo:null,
      cicloSup:null,
      cicloInf: null,

      planteles:[],
      plantel:null,
      cicloAnterior:[],
      cicloSiguiente:[],
      resultado:[],
      total:0,
      faltantes:0,
      siguientes:0,
      porcentaje:0,
      idcicloERPinf:0,
      idcicloERPSup:0,
      cicloSiguienteAvance:[],
      resultadoFinal:[],

      headersNiSucursal: [
        { text: 'Sucursal'     , value: 'plantel' },
        { text: 'Acumulado'    , value: 'acumulado' },
        { text: 'Avance'       , value: 'avance' },
      ],
      fast:{},
      inbi:{},
      totalFast: 0,
      dataTablaFastUno:[],
      totalInbi: 0,
      dataTablaInbiUno:[],
      dialogCarga: false,

      headersVend: [
        { text: 'Vendedora'    , value: 'nombre_completo' },
        { text: 'Acumulado'    , value: 'total' },
        { text: 'Avance'       , value: 'avance' },
      ],

      dataTablaFastUnoVend:[],
      dataTablaInbiUnoVend:[],
      avance:0,
      acumulado:0,
      avanceFinal:0,
      acumuladoFinal:0,
      alumnos:[],
      dialogIrregulares: false,
      dialogBecados:false
    }),

    watch: {
      ciclo () {
        if(this.ciclo){
          // Buscar la pisición en la que se encuentra el ciclo
          const positionCiclo = this.ciclos.indexOf(this.ciclo)

          // Validar que sea uno valido
          if(positionCiclo == 0 ){
            return this.validarErrorDirecto('El ciclo anterior esta cerrado, favor de abrir el ciclo anterior')
          }

          // Guardar un ciclo después
          this.cicloSup = this.ciclos[ positionCiclo + 1 ]

          // Guardar un ciclo anteriro
          this.cicloInf = this.ciclos[ positionCiclo - 1 ]

          // Consultar la información
          this.consultar()
        }
      }
    },

    async created () {
      this.cargar = true
      await this.getCiclosActivos( )
      this.cargar = false
    },

    methods: {
      getCiclosActivos( ){
        this.$http.get('kpi.ciclos.all').then(response=>{
          this.ciclos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async consultar () {
        this.resultado = []
        this.total = 0
        this.faltantes = 0
        this.siguientes = 0
        this.porcentaje = 0
        this.totalFinal      = 0
        this.faltantesFinal  = 0
        this.siguientesFinal = 0
        this.porcentajeFinal = 0

        for(const i in this.planteles){
          this.planteles[i].plantel =  this.planteles[i].plantel
          this.planteles[i].alumnosActual =  0
          this.planteles[i].alumnosSiguiente = 0
          this.planteles[i].faltan = 0
          this.planteles[i].porcentaje = 0
          this.planteles[i].avance = 0
          this.planteles[i].acumulado = 0
        }


        this.resultado       = []
        this.resultadoFinal  = []
        this.total           = 0
        this.faltantes       = 0
        this.siguientes      = 0
        this.porcentaje      = 0
        this.acumulado       = 0
        this.avance          = 0

        await this.riFast()
      },

      riFast () {
        this.cargar = true
        const payload = {
          cicloPre:  this.cicloInf,
          cicloAct: this.ciclo,
          cicloSig:  this.cicloSup
        }
        return this.$http.post('kpi.cantactual.teachers', payload ).then(response=>{
          this.resultado = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      verIrregulares( alumnos ){
        this.alumnos = alumnos
        this.dialogIrregulares = true
      },

      verBecados( alumnos ){
        this.alumnos = alumnos
        this.dialogBecados = true
      },

      verAlumnos( alumnos ){
        console.log( alumnos )
      },

      exportar(){
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let dataAnt = XLSX.utils.json_to_sheet(this.resultado.riTeachers)

        const workbook = XLSX.utils.book_new()
        const filename = 'KPI'
        XLSX.utils.book_append_sheet(workbook, dataAnt, 'RI TEAHCERS')

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });
      },

      
    },
  }
</script>
<style>

  

  #kpiTeacher td:nth-child(2) {
    background-color: #E8E8E8 !important;
  }

  #kpiTeacher td:nth-child(10) {
    background-color: #E8E8E8 !important;
  }

  #kpiTeacher td:nth-child(11) {
    background-color: #E8E8E8 !important;
  }

  #kpiTeacher td:nth-child(12) {
    background-color: #E8E8E8 !important;
  }

  #kpiTeacher td:nth-child(13) {
    background-color: #E8E8E8 !important;
  }


</style>