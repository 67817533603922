const clasesApoyo = [
	{ 
		titulo: '¿Por qué recibir clases de apoyo?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Las clases de apoyo nos ayudan a reforzar el conocimiento de nuestros hijos en habilidades cervales, de lecto-escritura y de conocimiento lógico-matemático. Habilidades indispensables para la vida diaria y académica de todos los niños.',
			}
		]
	},

	{ 
		titulo: '¿Cómo vamos a dar clases de apoyo? ',
		check: false,
		subtemas:[
			{ 
				titulo: 'Hacemos un examen diagnóstico en la primera clase, para conocer las áreas a trabajar de forma personalizada. Una vez que conozcamos las áreas de oportunidad crearemos un plan a medida de cada uno de nuestros alumnos.',
			}
		]
	},

	{ 
		titulo: '¿Cómo son las clases?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Las clases son con grupos reducidos, para brindar un apoyo personalizado de acuerdo a las necesidades de cada alumno.',
			}
		]
	},
	{ 
		titulo: '¿Qué horarios tienen?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Contamos con diferentes horarios, siendo los principales',
				subsubtemas:[
					{ titulo: 'Lunes y miércoles: De 4:00 p.m. a 5:00 p.m. y de 5 p.m. a 6 p.m'},
					{ titulo: 'Martes y jueves: De 4:00 p.m. a 5:00 p.m. y de 5 p.m. a 6 p'},
					{ titulo: 'Aplican restricciones y disponibilidad de horario'},
				]
			},
		]
	},
	{ 
		titulo: '¿Para quiénes son las clases de apoyo? ',
		check: false,
		subtemas:[
			{ titulo: 'Para niños de preescolar y primaria, hasta tercer año' },
		]
	},
	{ 
		titulo: '¿Cuánto cuesta el curso?',
		check: false,
		subtemas:[
			{ 
				titulo: 'En las clases de apoyo solo tendrás que hacer una inversión semanal de $199 pesos'
			}
		]
	}
]

export { clasesApoyo }