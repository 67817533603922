<template>
	<v-container fluid class="mt-4">
	  <v-row>
	  	<v-col cols="12">
				<v-card class="shadowCard">

					<v-card-title primary-title>
			      <label class="text-subtitle-1 black--text">Validación de Pagos</label>
			      <v-spacer></v-spacer>

            <!-- Botón par aexportar -->
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()"
              tile
            >
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            
			      <v-btn color="primary" tile small @click="consultar()">
			        <v-icon small left>mdi-refresh</v-icon>
			        Actualizar
			      </v-btn>


					</v-card-title>
			    <v-card-text>

            <v-row>

              <v-col cols="12" md="4" lg="2" class="pb-0">
                <label>Selecciona un ciclo</label>
                <v-autocomplete
                  label="Ciclo"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  v-model="ciclo"
                  :items="ciclos"
                  item-text="ciclo"
                  item-value="id_ciclo"
                  clearable
                ></v-autocomplete>
              </v-col>


              <v-col cols="12" sm="4" lg="2" xl="2">
                <label>Fecha de inicio</label>
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  clearable
                  v-model="fecha_inicio"
                  type="date"
                  single-line
                  @keyup.enter="consultar"
                ></v-text-field>
              </v-col>


              <v-col cols="12" sm="4" lg="2" xl="2">
                <label>Fecha final</label>
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  clearable
                  v-model="fecha_final"
                  type="date"
                  single-line
                  @keyup.enter="consultar"
                ></v-text-field>
              </v-col>

              <!-- Forma de pago -->
              <v-col cols="12" md="4" lg="2" xl="2">
                <label>Tipo de pago</label>
                <v-select
                  :items="tipos_pago"
                  v-model="formaPago"
                  label="Forma de pago"
                  filled
                  dense
                  item-text="tipo"
                  single-line
                  item-value="id"
                ></v-select>
              </v-col>

              <v-col cols="12" md="5" lg="3">
                <v-radio-group
                  v-model="opEscuela"
                  row
                >
                  <v-radio
                    label="INBI"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="FAST"
                    :value="2"
                  ></v-radio>
                  <v-radio
                    label="TODOS"
                    :value="3"
                  ></v-radio>
                </v-radio-group>
              </v-col>

            </v-row>

			    	<v-row justify="space-between">

              <v-col cols="12" md="3" lg="3">
                <span class="black--text text-h8">
                  <b>Movimientos:</b> 
                   | {{ filterCorte.length }} |
                </span>
                <br/>
                <span class="black--text text-h8">
                  <b>Revisados:</b> 
                   | {{ filterCorte.filter( el => { return el.aceptado }).length }} |
                </span>
                <br/>
                <span class="black--text text-h8">
                  <b>Faltantes:</b> 
                   | {{ filterCorte.filter( el => { return !el.aceptado }).length }} |
                </span>
              </v-col>

              <!-- Estatus de revisados y sin revisar -->
              <v-col cols="12" md="5" lg="5">
                <v-radio-group
                  v-model="opEstatus"
                  row
                >
                  <v-radio
                    label="Aceptados"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Rechazados"
                    :value="2"
                  ></v-radio>
                  <v-radio
                    label="Pendientes"
                    :value="3"
                  ></v-radio>
                  <v-radio
                    label="Todos"
                    :value="4"
                  ></v-radio>
                </v-radio-group>
              </v-col>

			    		<v-col cols="12" md="6" lg="4">
					      <v-text-field
					        filled
					        dense
					        append-icon="mdi-magnify"
					        label="Búscar"
					        v-model="searchMovimientos"
					      ></v-text-field>
			    		</v-col>
			    	</v-row>

			      <v-data-table
			        :headers="headersDos"
			        :items="filterCorte"
			        dense
			        :search="searchMovimientos"
			        mobile-breakpoint="200"
			        item-class="cambio"
			      >

			        <template v-slot:item.aceptado="{ item }">
                <v-chip small color="blue"  dark v-if="item.aceptado == 1">Aceptado</v-chip>
                <v-chip small color="red"   dark v-else-if="item.aceptado == 2">Rechazado</v-chip>

                <span v-if="item.aceptado == 2"><br/>{{ item.motivo }}</span>

                <v-btn tile x-small color="green" dark v-if="!item.aceptado == 1 && ![18, 19, 17].includes(getdatosUsuario.idpuesto)" class="mt-2" @click="aceptarComprobante( item )"><v-icon small>mdi-check</v-icon></v-btn>
                <v-btn tile x-small color="red"   dark v-if="!item.aceptado == 1 && ![18, 19, 17].includes(getdatosUsuario.idpuesto)" class="mt-2" @click="confirmarRechazo( item )"><v-icon small>mdi-close</v-icon></v-btn>
			        
              </template>

			        <template v-slot:item.url_foto="{ item }">
			          <p @click="comprobarImagen(item)" type="button" v-if="item.forma_pago != 'EFECTIVO' ">
			            <img :src="url_servidor + '/comprobante-pago/' + item.url_foto" alt="Comprobante" width="45"/>
			          </p>
			        </template>

			        <template v-slot:item.con_adeudo="{ item }">
			          <v-chip
			            small
			            color="primary"
			            @click="abrirDialog(item.con_adeudoA)"
			          >
			            {{ item.con_adeudo }}
			          </v-chip>
			        </template>

			      </v-data-table>

			    </v-card-text>

			    <!-- Dialog para ver la imagen -->
			    <v-dialog
			      v-model="dialgImagen"
			      max-width="600px"
			    >
			      <v-card class="elevation-0">
			        <v-card-text>
			          <!-- Imagen -->
			          <v-col cols="12">
			            <v-img
			              :src="url_servidor + '/comprobante-pago/' + imagen"
			              contain
			              max-width="600"
			              height="800"
			            >
			            </v-img>
			          </v-col>
			        </v-card-text>
			        <!-- Guardar la información  -->
			        <v-btn
			          absolute
			          right
			          top
			          color="grey"
			          dark
			          fab
			          class="elevation-6 mt-8"
			          @click="dialgImagen = false"
			        >
		            <v-icon>mdi-close</v-icon>
		          </v-btn>
			      </v-card>
			    </v-dialog>

          <v-dialog
            v-model="dialogConfirma"
            max-width="550px"
          >
            <v-card class="elevation-0">
              <v-card-title primary-title>
                Selecciona el por qué se está rechazando el ingreso
              </v-card-title>
              <v-card-text>
                <v-autocomplete
                  v-if="datosPago"
                  :items="conceptosError"
                  v-model="datosPago.iderrores_pagos"
                  label="Motivo de rechazo"
                  dense
                  filled
                  item-value="iderrores_pagos"
                  item-text="motivo"
                  class="ma-2"
                  hide-details
                ></v-autocomplete>
              </v-card-text>
              <!-- Guardar la información  -->
              <v-btn
                absolute
                right
                top
                small
                icon
                class="elevation-6"
                @click="dialogConfirma = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                  color="green"
                  dark
                  tile
                  small
                  @click="rechazarComprobante( datosPago )"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>

          </v-dialog>

			    <!-- Dialog de carga -->
			    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
			    <carga v-if="cargar" />
			    
			  </v-card>
	  	</v-col>
	  </v-row>
	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta              from '@/components/alertas/Alerta.vue'
  import carga               from '@/components/alertas/carga.vue';

  import validarErrores      from '@/mixins/validarErrores'
  import funcionesExcel      from '@/mixins/funcionesExcel'

  import VueImageZoom from 'vue-image-zoom';
  import 'vue-image-zoom/dist/vue-image-zoom.css';
  
	const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
      VueImageZoom
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      url_servidor: '',
      tablaMovimientos:[],

      snack: false,
      snackColor: '',
      snackText: '',
      max25chars: v => v.length <= 25 || 'Input too long!',
      pagination: {},

      headersDos: [
	      { text: "id_ingreso"         , value: "id_ingreso"        },
	      { text: "Alumno"             , value: "alumno"            },
	      { text: "Monto pagado"       , value: "monto_pagado"      },
	      { text: "Fecha"              , value: "fecha_pago"        },
	      { text: "Forma pago"         , value: "forma_pago"        },
	      { text: "Registró"           , value: "nombre_completo"   },
	      { text: "Plantel"            , value: "plantel"           },
        { text: "Ciclo"              , value: "ciclo"             },
        { text: "Aut. Clave rastreo" , value: "aut_clave_rastreo" },
        { text: "Folio Oper."        , value: "folio_operacion"   },
	      { text: "Cuenta"             , value: "cuenta"            },
	      { text: "Comprobante"        , value: "url_foto"          }, //Angel Rodriguez
        { text: "Aceptado"           , value: "aceptado"          },
        { text: "Aut. Clave rastreo 2" , value: "aut_clave_rastreo_imagen" },
        { text: "Folio Oper. 2"        , value: "folio_operacion_imagen"   },
        { text: "Cuenta 2"             , value: "cuenta_imagen"            },

	    ],
			searchMovimientos: '',
			dialgImagen:false,
      imagen:null,

      headersTres: [
        { text: "id_ingreso"    , value: "id_ingreso"      },
        { text: "id_alumno"     , value: "id_alumno"       },
        { text: "Alumno"        , value: "alumno"          },
        { text: "Monto pagado"  , value: "monto_pagado"    },
        { text: "Viejo Plantel" , value: "viejoPlantel"    },
        { text: "Viejo Ciclo"   , value: "viejoCiclo"      },
        { text: "Nuevo Plantel" , value: "nuevoPlantel"    },
        { text: "Nuevo Ciclo"   , value: "nuevoCiclo"      },
      ],
      tipos_pago:[
        { id: 1, tipo: 'EFECTIVO'},
        { id: 2, tipo: 'TARJETA'},
        { id: 3, tipo: 'DEPOSITO'},
        { id: 0, tipo: 'TODO' },
      ],

      formaPago: 0,
      ciclos: [],
      ciclo: null,
      fecha_inicio:null,
      fecha_final:null,
      opEstatus: 4,
      datosPago: null,
      dialogConfirma: false,
      conceptosError:[],
      opEscuela: 3
    }),

    computed: {
      ...mapGetters("login", ["getdatosUsuario"]),

      filterCorte(){

        let data = this.tablaMovimientos

        if( this.opEstatus == 1 ){
          data = data.filter( el => { return el.aceptado == 1 })
        }

        if( this.opEstatus == 2 ){
          data = data.filter( el => { return el.aceptado == 2 })
        }

        if( this.opEstatus == 3 ){
          data = data.filter( el => { return !el.aceptado })
        }


        // Filtro por forma de pago
        if( this.formaPago == 2){
          data = data.filter( el => { return [4].includes( el.id_forma_pago ) })
        }

        // Filtro por forma de pago
        if( this.formaPago == 3){
          data = data.filter( el => { return [2].includes( el.id_forma_pago ) })
        }

        // Filtro por forma de pago
        if( this.formaPago == 1){
          data = data.filter( el => { return [1].includes( el.id_forma_pago ) })
        }

        if( this.opEscuela == 1 ){
          data = data.filter( el => { return !el.plantel.match('FAST') })
        }

        if( this.opEscuela == 2 ){
          data = data.filter( el => { return el.plantel.match('FAST') })
        }

        return data 
      }

    },

    async created() {

    	this.url_servidor = "https://escuelakpi.club/kpi/" 

      await this.getCiclos()
      await this.getConceptorError()

    },

    methods: {

      comprobarImagen(value) {            //Angel Rodriguez              
        this.imagen     = value.url_foto;
        this.datosPago  = value;
        this.dialgImagen = true;
      },

      getCiclos() {
        this.cargar = true;
        this.ciclos = [];
        return this.$http.get("ciclos.activos.erp").then((response) => {
          this.ciclos = response.data;
          this.cargar = false;
        }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });
      },

      getConceptorError() {
        this.cargar = true;
        this.conceptosError = [];
        return this.$http.get("concepto.errores").then((response) => {
          this.conceptosError = response.data;
          this.cargar = false;
        }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });
      },

      consultar(){
        this.cargar = true;

        const payload = {
          id_ciclo: this.ciclo,
          fecha_inicio: this.fecha_inicio,
          fecha_final: this.fecha_final,
        }

        return this.$http.post("movimientos.corte", payload ).then((response) => {

          this.tablaMovimientos = response.data;
          this.cargar = false;

        }).catch((error) => {

          this.validarError(error);

        }).finally(() => {

          this.cargar = false;

        });
      },

      aceptarComprobante( data ){
        this.cargar = true;

        const payload = {
          id_ingreso: data.id_ingreso,
          aceptado: 1,
        }

        data.aceptado = 1

        return this.$http.post("ingreso.aceptado", payload ).then((response) => {

          this.validarSuccess(response.data.message)
          this.cargar = false;

        }).catch((error) => {

          this.validarError(error);

        }).finally(() => {

          this.cargar = false;

        });
      },

      confirmarRechazo( data ){
        this.datosPago = data
        this.dialogConfirma = true
      },

      rechazarComprobante( data ){
        this.cargar = true;

        if( !data.iderrores_pagos ){
          return this.validarErrorDirecto('Selecciona un motivo')
        }

        const payload = {
          id_ingreso: data.id_ingreso,
          aceptado: 2,
          iderrores_pagos: data.iderrores_pagos,
        }

        data.aceptado = 2

        return this.$http.post("ingreso.rechazado", payload ).then((response) => {

          this.validarSuccess(response.data.message)
          this.dialogConfirma = false
          this.cargar = false;

        }).catch((error) => {

          this.validarError(error);

        }).finally(() => {

          this.cargar = false;

        });
      },

      save ( data ) {
        this.cargar = true;

        const payload = {
          id_ingreso: data.id_ingreso,
          aut_clave_rastreo: data.aut_clave_rastreo,
        }

        return this.$http.post("ingreso.clave.rastreo", payload ).then((response) => {

          this.validarSuccess(response.data.message)
          this.cargar = false;

        }).catch((error) => {

          this.validarError(error);

        }).finally(() => {

          this.cargar = false;

        });
      },

      exportar( ){
        this.exportExcel( this.filterCorte  , 'PAGOS')
      },

      saveFolio( data ) {
        this.cargar = true;

        const payload = {
          id_ingreso       : data.id_ingreso,
          folio_operacion: data.folio_operacion,
        }

        return this.$http.post("ingreso.folio.operacion", payload ).then((response) => {

          this.validarSuccess(response.data.message)
          this.cargar = false;

        }).catch((error) => {

          this.validarError(error);

        }).finally(() => {

          this.cargar = false;

        });
      },

      saveCuenta( data ) {
        this.cargar = true;

        const payload = {
          id_ingreso       : data.id_ingreso,
          cuenta: data.cuenta,
        }

        return this.$http.post("ingreso.cuenta", payload ).then((response) => {

          this.validarSuccess(response.data.message)
          this.cargar = false;

        }).catch((error) => {

          this.validarError(error);

        }).finally(() => {

          this.cargar = false;

        });
      },

      cancel () {
        this.snack = true
        this.snackColor = 'error'
        this.snackText = 'Canceled'
      },
      open () {
        this.snack = true
        this.snackColor = 'info'
        this.snackText = 'Dialog opened'
      },
      close () {
        console.log('Dialog closed')
      },
    }
  };
</script>


