const infoKIDS = [
	{ 
		titulo: '¿Por qué estudiar inglés?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Hoy en día el saber inglés no es un plus, sino una obligación para todos aquellos que queremos tener mejores oportunidades de vida para nuestros hijos.',
			},
		]
	},

	{ 
		titulo: '¿cómo le vamos a enseñar el inglés? ',
		check: false,
		subtemas:[
			{ 
				titulo: 'El método utilizado es “Método activo”, el cual el aprendizaje del alumno se da de manera natural mediante el juego y actividades lúdicas.',
			},
		]
	},

	{ 
		titulo: '¿Cómo son las clases?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Aprenden por medio de canciones, donde bailan, juegan, cantan y verbalizan el idioma, adaptándolo a las situaciones cotidianas de la vida.',
			},
		]
	},

	{ 
		titulo: '¿Cómo saben qué nivel de inglés tengo?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Contamos con un curso de inducción el cual nos sirve para normalizar y trabajar su nivel de inglés, en base a este curso podremos determinar las áreas de oportunidad para mejorar dentro del curso.',
			},
		]
	},

	{ 
		titulo: '¿Qué horarios tienen?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Manejamos diferentes modalidades',
				subsubtemas:[
					{ titulo: 'Intensivo: Entre semana 1 por clase, dos días a la semana'},
					{ titulo: 'Sabatino: 2 horas'},
				]
			},
		]
	},

	{ 
		titulo: '¿Cuánto dura el curso? ',
		check: false,
		subtemas:[
			{ titulo: 'El curso consta de 12 niveles' },
		]
	},

	{ 
		titulo: '¿Cómo me aseguran que aprenderé inglés?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Mediante las actividades y evaluaciones que tenemos en nuestras clases.',
			},
		]
	},

	{ 
		titulo: '¿Para qué edades es el curso?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Los cursos de inglés kids está implementado para los alumnos de 5 a 8 años.',
			},
		]
	},

	{ 
		titulo: '¿Cuánto cuesta el curso?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Es importante mencionar que no manejamos costo de inscripción ni de material didáctico',
				subsubtemas:[
					{ 
						titulo: 'El curso de inglés kids solo tendrás que hacer una inversión de $960 pesos por nivel ( 4 semanas ), además la escuela cuenta con un sistema de descuento por pronto pago.'
					},
					{ 
						titulo: 'El sistema de descuento de pronto pago, se basa en un calendario el cual si se respetan las fechas, se respeta el descuento. Para su primer pago la escuela brinda el descuento del 20% del valor del nivel ($1,079) es decir que pagaría $860 pesos.'
					},
				]
			},

		]
	},

	{ 
		titulo: '¿Qué incluye?',
		check: false,
		subtemas:[
			{ titulo:'4 semanas de 2 de clases en vivo' },
			{ titulo:'Material didáctico que se ocupe para el correcto aprendizaje.' },
			{ titulo:'Acceso a nuestra plataforma virtual' },
			{ titulo:'Acceso a grupo WhatsApp' },
		]
	},

	{ 
		titulo: 'Cierre',
		check: false,
		subtemas:[
			{ titulo: 'Resumen de información'},
			{ titulo: 'Pregunta de cierre'},
		]
	},
]

export { infoKIDS }