<template>
  <v-container class="mt-4 px-5" fluid>
    <v-row>
      <v-col cols="12">
      	
      	<v-card class="shadowCard">
      	  <v-card-title class="text-subtitle-1">
      	    Asistencia Alumnos
      	    <v-spacer></v-spacer>

      	    <!-- Botón par aexportar -->
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()"
              tile
            >
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>

      	  	<!-- Botón para consultar la iformación al sistema -->
      	    <v-btn 
              small dark color="orange"
              @click="consultar()"
              tile
            >
              Consultar
            </v-btn>

      	  </v-card-title>

      	  <!-- Tabla y filtros -->
      	  <v-card-text>

      	  	<!-- Filtro de ciclos -->
      	    <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <!-- Mostrar información por bloques -->
            <v-row justify="space-between">
              <v-col cols="12" md="6" lg="6">
                <v-text-field
                  v-model="search"
                  label="Búscar"
                  dense
                  filled
                  hide-details
                  clearable
                ></v-text-field>
              </v-col>

            	<!-- Sleleccionar un curso -->
            	<v-col cols="12" md="3" lg="2">
						    <v-autocomplete
						    	filled
						      :items="[{id: 1, texto: 'Dominical'},{id: 2, texto: 'Sabatino'},{id: 3, texto: 'Entre semana'}]"
						      v-model="curso"
						      item-text="texto"
						      item-value="id"
						      label="Frecuencia"
						      clearable
						      dense
						    ></v-autocomplete>
            	</v-col>

            	<!-- Seleccionar la modalidad -->
            	<v-col cols="12" md="3" lg="2">
						    <v-autocomplete
						    	filled
						      :items="[{id: 1, texto: 'Online'},{id: 0, texto: 'Presencial'}]"
						      v-model="modalidad"
						      item-text="texto"
						      item-value="id"
						      label="Modalidad"
						      clearable
						      dense
						    ></v-autocomplete>
            	</v-col>

            	<!-- Seleccionar un nivel -->
            	<v-col cols="12" md="3" lg="2">
						    <v-autocomplete
						    	filled
						      :items="niveles"
						      v-model="nivel"
						      label="Nivel"
						      clearable
						      dense
						    ></v-autocomplete>
            	</v-col>
            </v-row>
		        
		        <!-- Tabla con toda la información -->
		        <v-data-table
		          :headers="headers"
		          :items="filterGrupos"
		          class="elevation-0"
		          height="500"
		          dense
		          fixed-header
		          :search="search"
		          multi-sort
		          :loading="cargar"
		        > 
		        	<template v-slot:item.actions="{ item }">
		        		<v-btn 
		        			color="primary" 
		        			small
		        			@click="verGrupo( item )"
		        		>
		        			Ver
		        		</v-btn>
		          </template>

		        </v-data-table>
      	  </v-card-text>
      	</v-card>

      </v-col>
    </v-row>

    <!-- Dialogo para agregar un maestro al grupo -->
    <v-dialog
      v-model="dialogGrupo"
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          Tomar asistencia
        </v-card-title>
        <v-card-text>
  			  <v-radio-group
            row
            value="1"
          >
            <v-radio
              color="green"
              value="1"
              label="Asistencia"
            ></v-radio>
            <v-radio
              color="red"
              value="1"
              label="Falta"
            ></v-radio>
          </v-radio-group>

          <v-row>
          	<v-col cols="12" md="6" lg="4">
	            <v-menu
				        ref="menu"
				        v-model="menu"
				        :close-on-content-click="false"
				        :return-value.sync="fecha"
				        transition="scale-transition"
				        offset-y
				        min-width="auto"
				      >
				        <template v-slot:activator="{ on, attrs }">
				          <v-text-field
				            v-model="fecha"
				            label="Fecha de asistencia"
				            append-icon="mdi-calendar"
				            readonly
				            v-bind="attrs"
				            v-on="on"
				            filled
				          ></v-text-field>
				        </template>
				        <v-date-picker
				          v-model="fecha"
				          no-title
				          scrollable
				          :allowed-dates="fechasHabiles"
				          :min="fechaMin"
    							:max="fechaMax"
    							color="primary"
				        >
				          <v-spacer></v-spacer>
				          <v-btn
				            text
				            color="primary"
				            @click="menu = false"
				          >
				            Cancel
				          </v-btn>
				          <v-btn
				            text
				            color="primary"
				            @click="$refs.menu.save(fecha),getAlumnos(grupo)"
				          >
				            Ok
				          </v-btn>
				        </v-date-picker>
				      </v-menu>

          	</v-col>
          </v-row>
	  			
	  			<v-data-table
				    :headers="headersAlumnos"
				    :items="listaalumnos"
				    :items-per-page="listaalumnos.length"
				    class="elevation-0"
				    hide-default-footer
				    :mobile-breakpoint="100"
				  >
				  	<!-- Avatar -->
				  	<template v-slot:item.alumno="{ item }">
				  		<div class="d-flex  my-2">
				  			<div v-if="!item.tipo == 1">
				  			  <v-avatar
				        	  size="45"
				        	  color="transparent"
				        	>
				            <avataaars
				            	v-if="item.idavatar != null"
					          	:accessoriesType="item.accessoriesType"
						        	:isCircle="true"
								      :clotheType="item.clotheType"
						        	:clotheColor="item.clotheColor"
								      :eyebrowType="item.eyebrowType"
								      :eyeType="item.eyeType"
								      :facialHairColor="item.facialHairColor"
						        	:facialHairType="item.facialHairType"
								      :graphicType="item.graphicType"
								      :mouthType="item.mouthType"
						        	:skinColor="item.skinColor"
						        	:topType="item.topType"
						        	:topColor="item.topColor"
								      :hairColor="item.hairColor"
								      :circleColor="'#6fb8e0'">
								    </avataaars>
							    	<avataaars v-else></avataaars>
				        	</v-avatar>
				        	<br/>
		              <span class="text-caption">{{ item.alumno }}</span>
		            </div>
		            <div v-else>
		            	Valor
		            </div>
		          </div>
				    </template>

				    <!-- Valor asistencia -->
				    <template v-slot:item.asistencia="{ item }">
				    	<div v-if="!item.tipo == 1">
				    		<v-radio-group
		              row
		              v-model="item.valor_asistencia"
		            >
		              <v-radio
		                color="green"
		                :value="1"
		              ></v-radio>
		              <v-radio
		                color="red"
		                :value="2"
		              ></v-radio>
		            </v-radio-group>
				    	</div>
				    	<div v-else>
				    		<v-radio-group
		              row
		              value="1"
		            >
					    		<v-radio
		                color="green"
		                value="1"
		              ></v-radio>
					    		<v-radio
		                color="red"
		                value="1"
		              ></v-radio>
		            </v-radio-group>
				    	</div>
				    </template>

				    <template v-slot:item.fecha_pago="{ item }">
			        <v-chip color="success" small v-if="!item.fecha_pago" @click="editFechaPago( item )"> Fecha de reinscripción </v-chip>
			        <span color="success" v-else >{{ item.fecha_pago }}</span>
			      </template>
				  </v-data-table>

  			</v-card-text>

			  <v-card-actions>
			  	<v-btn
			    	color="black"
	  				dark 
	  				small
	  				@click="dialogGrupo = false" 
	  			>
	  				<v-icon small left>mdi-cancel</v-icon>
	  				<span >Cancelar</span>
	  			</v-btn>

			  	<v-spacer></v-spacer>
			    <v-btn
			    	color="green"
	  				dark 
	  				small
	  				@click="enviarAsistencias()" 
	  				:disabled="disabled"
	  				:loading="loading"
	  			>
	  				<v-icon small left>mdi-content-save</v-icon>
	  				<span >Guardar</span>
	  			</v-btn>
			  </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'
  import filtrosAsistencia     from '@/mixins/filtrosAsistencia'

  import Avataaars     from 'vuejs-avataaars'

  export default {

  	components:{
      Alerta,
      carga,
      Avataaars
    },

    mixins: [ validarErrores, funcionesExcel, filtrosAsistencia ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      grupoRolesPerfecto:[],
      search:'',
      gruposRoles: [],
      ciclos:[],
      ciclo:null,


      teacher: null,
      dialogReemplazo: false,
      teachers:[],
      teacherReemplazo: null,
      bloque: '',

      // Filtros avanzados
      niveles:[],
      nivel: null,

      planteles: [],
      plantel: null,

      horarios:[],
      horario: null,

      modalidades:[],
      modalidad:null,

      teachersFiltros:[],
			teacherFilter: null,

			dialogGrupo: false,

			escuela: 1,
			opAsignacion: null,

			listaalumnos: [],
      model: null,

      headersAlumnos: [
        { text: 'Alumno'        , value: 'alumno'    , class: 'secondaryText--text text-caption sizeFont' },
        { text: 'Asistencia'    , value: 'asistencia', class: 'secondaryText--text text-caption sizeFont' },
        // { text: 'Fecha pago'    , value: 'fecha_pago', class: 'secondaryText--text text-caption sizeFont' },
      ],

      snack: false,
      snackColor: '',
      snackText: '',

      disabled:false,
			loading:false,
			fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

			alumno: null,
			dialogFecha: false,
			fecha_pago:null,
			menu: false,
			fechaMin:null,
			fechaMax:null,
			fechas:[],

			grupo: null
    }),

    watch: {
      // Consultar cuando haya un cambio en el ciclo y este sea un ciclo seleccionado y no un ciclo vacio
      ciclo () {
        if(this.ciclo){
          this.consultar()
        }
      }
    },

    async created () {

    	console.log( this.getdatosUsuario )
      await this.initialize()
      await this.getTeachersActivos()
    	await this.getPlanteles()
    },

    computed:{

    	...mapGetters('login', ['getdatosUsuario'])

    },

    methods: {
      // Funcion para cargar datos iniciales
      initialize () {
      	this.cargar = true
        // Vaciar los ciclos
        this.ciclos = []
        // Obtener todos los ciclos
        this.$http.get('roles.ciclos.all').then(response=>{
          // hacer un ciclo ya que los ciclos vienen varios como inscripciones y todo eso
          // y solo queremos los ciclos que en su nombre dicen ciclo 
          for(const i in response.data){
            //  si es diferente a FE
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              // Y es igual a FE, se agrega a ciclos
              // por qué es diferente a FE? por que es por estetica, mostrar solo los ciclos normales, ya que los ciclos tienen ligado el ciclo FE
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }

      		this.cargar = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.oficiales').then(response=>{
        	console.log( response.data )
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getTeachersActivos( ){
      	this.cargar = true
      	this.teachers = []
        return this.$http.get('academico.teachers.activos').then(response=>{
        	console.log( response.data )
          this.teachers = response.data
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      consultar () {
      	if( !this.ciclo ){
      		return this.validarErrorDirecto('Selecciona un ciclo por favor')
      	}
      	this.gruposRoles = []
      	this.cargar      = true
        this.$http.post('rol.clases', this.ciclo ).then(response=>{
          this.gruposRoles = response.data.respuesta

          this.niveles     = response.data.respuesta.map((registro) => { return registro.id_nivel }).sort()
          
          this.horarios    = response.data.respuesta.map((registro) => { return registro.hora_inicio }).sort()

          // this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async verGrupo( item ){

      	this.grupo = item

      	await this.getAlumnos( item )
      	await this.getConsultarFechas( item )
      	this.dialogGrupo = true



      },

      getAlumnos ( item ){
	  		this.listaalumnos = []
    		this.cargar = true
  			// Preparamos el id del teacher
    		const payload = {
    			id_grupo: item.id_grupo_lms,
    			fecha   : this.fecha,
    			id_nivel: item.id_nivel,
    			escuela:  item.id_unidad_negocio
    		}

  			// Hacemos la consulta
    		this.$http.post('grupos.asistencias', payload ).then( response=> {
    			this.listaalumnos = response.data
    			this.disabled = false
    			this.listaalumnos.unshift({alumno:'Valor', asistencia:'', tipo: 1}) // para poder poner los colores de la asistencia
    		}).catch( error =>{
    			this.disabled = true
    			this.validarError( error.response.data.message )
    		}).finally( () => { this.cargar = false })
    	},

      getConsultarFechas( item ){

      	this.cargar = true
  			// Preparamos el id del teacher
    		const payload = {
    			id_grupo: item.id_grupo_lms,
    			id_nivel: item.id_nivel,
    			escuela:  item.id_unidad_negocio
    		}

  			// Hacemos la consulta
  			this.fechas = []
    		return this.$http.post('grupos.fechas.habiles', payload ).then( response=> {
    			this.fechaMin = response.data[0]
    			this.fechaMax = response.data[ response.data.length - 1]
    			this.fechas   = response.data
    			this.fecha    = this.fechaMax
    		}).catch( error =>{
    			this.disabled = true
    			this.validarError( error.response.data.message )
    		}).finally( () => { this.cargar = false })
      },



      guardarMaestro( ){

      	if( !this.teacherReemplazo || !this.opAsignacion ){
      		return this.validarErrorDirecto('Favor de llenar correctamente los datos')
      	}

      	this.cargar      = true
      
      	// Validar que teacher es....
        let numero_teacher = 0

        // Si el teacher que se modifico, fue de estos bloques... es teacher uno
        if( ['lu1','ma1','mi1','lu2','ma2','mi2','lu3','ma3','mi3','lu4','ma4','mi4'].includes( this.bloque ) ){
       		numero_teacher = 1
        }else{ 
        	numero_teacher = 2 // Si no está, es por que es teacher 2
        }

        let payload = {
          id_maestro     : this.teacherReemplazo.id_trabajador,
          id_usuario     : this.teacherReemplazo.id_usuario,
          id_grupo       : this.teacher.iderp, // ===== idgrupo
          activo_sn      : 1,
          numero_teacher,
          escuela        : this.teacher.id_unidad_negocio,
          ciclo          : this.ciclo,
          opAsignacion   : this.opAsignacion,
          bloque         : this.bloque,
        }

        console.log( payload )

        payload[`${this.bloque}`] = this.teacherReemplazo

        this.$http.post(`academico.add.grupoteacher`, payload).then(response=>{
        	this.bloque           = '' 
	      	this.teacher          = null
	      	this.teacherReemplazo = null
	      	this.dialogGrupo    = false
        	this.validarSuccess( response.data.message )
          this.consultar()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      	this.cargar      = false

      },

      exportar( ){
      	this.exportExcel( this.filterGrupos  , 'ROL_CLASES')
      },

      fechasHabiles ( val ){
	  		return this.fechas.includes( val ) 
	  	},


	  	saveFecha() {
      	this.cargar = true
    		const payload = {
    			id_asistencia: this.alumno.id_asistencia,
    			fecha_pago:    this.fecha_pago,
    		}
  			// Hacemos la consulta
    		this.$http.post('grupos.asistencias.add.fechapago', payload ).then( response=> {
    			this.dialogFecha = false
    			this.alumno = null
    			this.fecha_pago = null
    			this.cargar = false 
          this.parametros.mensaje      = response.data.message
          this.parametros.dialogError  = true 
          this.parametros.color        = 'success'
          this.respuestaAlerta         = true 
    			this.getAlumnos(this.grupo)
    		}).catch( error =>{
    			this.validarError( error.response.data.message )
    		}).finally( () => { this.cargar = false })
      },

      enviarAsistencias( ){
    		this.cargar = true
    		const payload = {
    			id_grupo:    this.grupo.id_grupo_lms,
    			fecha:       this.fecha,
    			id_nivel:    this.grupo.id_nivel,
    			asistencias: this.listaalumnos,
    			id_teacher:  0,
    			escuela:     this.grupo.id_unidad_negocio
    		}
  			// Hacemos la consulta
    		this.$http.post('grupos.asistencias.add', payload ).then( response=> {
    			this.cargar = false 
          this.parametros.mensaje      = response.data.message
          this.parametros.dialogError  = true 
          this.parametros.color        = 'success'
          this.respuestaAlerta         = true 
    			this.getAlumnos(this.grupo)
    		}).catch( error =>{
    			this.validarError( error.response.data.message )
    		}).finally( () => { this.cargar = false })
      },
    },
  }

</script>
<style>
  .v-data-table__wrapper{

  }

  .v-data-table__wrapper::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-data-table__wrapper::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  .v-data-table__wrapper::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }


</style>