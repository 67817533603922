<template>
	<v-container class="mt-4 pl-4">
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Inscritos inducción</span>
	  		    <v-radio-group
				      v-model="escuela"
				      class="ml-4"
				      row
				    >
				      <v-radio
				        label="FAST"
				        value="AND ciclo LIKE '%FE%'"
				      ></v-radio>
				      <v-radio
				        label="INBI"
				        value="AND ciclo NOT LIKE '%FE%'"
				      ></v-radio>
				    </v-radio-group>
	  		    <v-spacer></v-spacer>
	  		    <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( resultado, 'alumnos_induccion' )">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
	  		    <v-btn color="primary" small rounded @click="obtenerInscritos">Actualizar</v-btn>
	  		  </v-card-title>
	  		  <v-card-text>

	  		  	<!-- Filtro ciclo 1 y semana inicial-->
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo_inicial"
                  :items="ciclos"
                  label="Selecciona ciclo inicial"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="5" xl="4">
              	<v-radio-group
						      v-model="semana_inicial"
						      row
						    >
						      <v-radio
						        label="S1"
						        :value="1"
						      ></v-radio>
						      <v-radio
						        label="S2"
						        :value="2"
						      ></v-radio>
						      <v-radio
						        label="S3"
						        :value="3"
						      ></v-radio>
						      <v-radio
						        label="S4"
						        :value="4"
						      ></v-radio>
						    </v-radio-group>
              </v-col>
            </v-row>

            <!-- Filtro ciclo final y semana final -->
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3" class="pt-0">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo_final"
                  :items="ciclos"
                  label="Selecciona ciclo inicial"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="5" xl="4" class="pt-0">
              	<v-radio-group
						      v-model="semana_final"
						      row
						    >
						      <v-radio
						        label="S1"
						        :value="1"
						      ></v-radio>
						      <v-radio
						        label="S2"
						        :value="2"
						      ></v-radio>
						      <v-radio
						        label="S3"
						        :value="3"
						      ></v-radio>
						      <v-radio
						        label="S4"
						        :value="4"
						      ></v-radio>
						    </v-radio-group>
              </v-col>
            </v-row>

            <!-- Números -->
            <v-row align="center">
              
              <!-- Parciales -->
              <v-col cols="12" md="3" lg="2">
              	<v-tooltip bottom>
      						<template v-slot:activator="{ on, attrs }">
		              	<v-card class="text-center" 
		              		v-bind="attrs"
          						v-on="on"
          					>
		                	<span class="text-h4 text-center">{{ parciales }}</span>
		                	<br/>
		                	<span>Parciales</span>
		              	</v-card>
		              </template>
						      <span>
						      	Citerios<br/>
						      	Con adeudo<br/>
						      	Sin grupo asignado
						      </span>
						    </v-tooltip>

              </v-col>

              <!-- Finales -->
              <v-col cols="12" md="3" lg="2">
              	<v-tooltip bottom>
      						<template v-slot:activator="{ on, attrs }">
		              	<v-card class="text-center" 
		              		v-bind="attrs"
          						v-on="on"
          					>
		                	<span class="text-h4 text-center">{{ finales }}</span>
		                	<br/>
		                	<span>Finales</span>
		              	</v-card>
		              </template>
						      <span>
						      	Citerios<br/>
						      	Sin adeudo<br/>
						      	Con grupo asignado
						      </span>
						    </v-tooltip>
              </v-col>

              <!-- Becados -->
              <v-col cols="12" md="3" lg="2">
              	<v-tooltip bottom>
      						<template v-slot:activator="{ on, attrs }">
		              	<v-card class="text-center" 
		              		v-bind="attrs"
          						v-on="on"
          					>
		                	<span class="text-h4 text-center">{{ becados }}</span>
		                	<br/>
		                	<span>Becados</span>
		              	</v-card>
		              </template>
						      <span>
						      	Citerios<br/>
						      	Sin adeudo<br/>
						      	Sin pago
						      </span>
						    </v-tooltip>
              </v-col>

              <!-- Totales -->
              <v-col cols="12" md="3" lg="2">
              	<v-card class="text-center">
                	<span class="text-h4 text-center">{{ total }}</span>
                	<br/>
                	<span>Total</span>
              	</v-card>
              </v-col>
            </v-row>

            <v-row>
            	<v-col cols="12">
            		<v-data-table
		              :headers="headers"
		              :items="resultado"
		              dense
		            >

		            	<!-- Estatus -->
		              <template v-slot:item.estatus="{ item }">
		                <v-chip color="red"    dark small v-if="item.estatus == 1">Parcial</v-chip>
		                <v-chip color="green"  dark small v-if="item.estatus == 2 ">Final</v-chip>
		                <v-chip color="orange" dark small v-if="item.estatus == 3 ">Becado</v-chip>
		              </template>

		              <!-- Estatus -->
		              <template v-slot:item.motivo="{ item }">

		              	<v-tooltip bottom>
								      <template v-slot:activator="{ on, attrs }">
								        <span v-bind="attrs" v-on="on"
								        	v-if="item.motivo == 'Sin liquidación'"
								        >Sin liquidación</span>
								        <span v-bind="attrs" v-on="on"
								        	v-if="item.motivo == 'Liquidado sin grupo'"
								        >Liquido su pago, pero aún no le asignan grupo</span>
								        <span v-bind="attrs" v-on="on"
								        	v-if="item.motivo == 'Pago parcial'"
								        >Pago parcial</span>
								        <span v-bind="attrs" v-on="on"
													v-if="item.motivo == 'Todo correcto'"
								        >Todo correcto</span>
								        <span v-bind="attrs" v-on="on"
													v-if="item.motivo == 'Sin pago y sin adeudo'"
								        >Sin pago y sin adeudo</span>
								        <span v-bind="attrs" v-on="on"
													v-if="item.motivo == 'Con adeudo'"
								        >Con adeudo</span>
								      </template>
								      <span v-if="item.motivo == 'Sin liquidación'">
								      	Tiene solo el pago de inducción
								      </span>
								      <span v-if="item.motivo == 'Liquidado sin grupo'">
								      	Liquido su pago, pero aún no le asignan grupo
								      </span>
								      <span v-if="item.motivo == 'Pago parcial'">
								      	No tiene grupo, pero ya realizó un pago parcial
								      </span>
								      <span v-if="item.motivo == 'Todo correcto'">
								      	Liquido su curso y tiene grupo
								      </span>
								      <span v-if="item.motivo == 'Sin pago y sin adeudo'">
								      	El alumno es becado, no realiza ningún pago
								      </span>
								      <span v-if="item.motivo == 'Con adeudo'">
								      	El alumno tiene grupo, pero tiene un adeudo de: {{ item.adeudo }}
								      </span>
								    </v-tooltip>
		              </template>

		            </v-data-table>
            	</v-col>
            </v-row>
          </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>
		
		<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>

<script>
import axios from 'axios';
  import XLSX from 'xlsx'
  // Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'

  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      headers: [
        { text: 'ID'          , value: 'id_alumno'      },
        { text: 'Alumno'      , value: 'alumno'         },
        { text: 'Grupo'       , value: 'grupo'          },
        { text: 'Ciclo'       , value: 'ciclo'          },
        { text: 'Vendedora'   , value: 'vendedora'      },
        { text: 'Motivo'      , value: 'motivo'         },
        { text: 'Estatus'     , value: 'estatus'        },
        { text: 'Fuente'      , value: 'fuente'         },
        { text: 'detalle'     , value: 'detalle_fuente' },
        { text: 'Pagado INducció'      , value: 'pago_induccion' },
        { text: 'Pagado'      , value: 'pagado' },
        { text: 'Adeudo'      , value: 'adeudo' },
      ],

      ciclos:[],
      escuela: "AND ciclo LIKE '%FE%'",

      ciclo_inicial: null,
			semana_inicial: null,
			ciclo_final: null,
			semana_final: null,
			resultado:[],

			parciales: 0,
			becados: 0,
			finales: 0,
			total: 0,

    }),

    async created () {
      this.cargar = true
      await this.getCiclosActivos( )
      this.cargar = false
    },

    methods: {
      getCiclosActivos( ){
        this.$http.get('kpi.ciclos.induccion').then(response=>{
          this.ciclos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      obtenerInscritos () {
      	if( this.ciclo_inicial && this.ciclo_final && this.semana_inicial && this.semana_final ){
	        this.cargar = true
	        const payload = {
	        	ciclo_inicial  : this.ciclo_inicial,
	        	semana_inicial : this.semana_inicial,
	        	ciclo_final    : this.ciclo_final,
	        	semana_final   : this.semana_final,
	        	escuela        : this.escuela
	        }
	        return this.$http.post('kpi.inscritos.induccion', payload ).then(response=>{
	          this.resultado = response.data.listadoAlumnos
						this.parciales = response.data.parciales
						this.becados   = response.data.becados
						this.finales   = response.data.finales
						this.total     = response.data.total
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
      	}else{
      		this.validarErrorDirecto('Llena todos los datos por favor')
      	}
      },
    },
  }
</script>
