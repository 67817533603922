<template>
	<!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="inscribir.estatus"
    persistent
    :max-width="maxWidth"
  >
		<v-container fluid>
		  <v-row>
		  	<v-col cols="12" class="pa-0">
		  		<v-card class="mx-auto shadowCard" tile>
				    <v-card-title class="text-h6 font-weight-regular justify-space-between">
				      <span class="text-subtitle-1"> {{ tema }} </span>
				      <v-avatar
				        color="primary lighten-1"
				        class="subheading white--text"
				        size="24"
				        v-text="step"
				      ></v-avatar>
				    </v-card-title>

				    <!-- Alumno nuevo -->
				    <v-window v-model="step">
				    	<!-- Seleccionar alumno -->
				      <v-window-item :value="1">
				        <v-card-text class="mb-4 pb-0 pt-0">
				        	<BuscarAlumno 
				        		@alumnoSeleccionado="seleccionarAlumno" 
				        		@cancelar="cancelar" 
				        		@nuevoalumno="nuevoalumno"
				        	/>
				        </v-card-text>
				      </v-window-item>

				      <!-- Registrar un alumno -->
				      <v-window-item :value="5">
				        <v-card-text class="mb-4 pb-0 pt-0">
				        	<NuevoAlumno 
				        		@cancelar="cancelar" 
				        		@buscaralumno="buscaralumno" 
				        		@retornarAlumno="retornarAlumno"
				        		:alumno="alumno"
				        	/>
				        </v-card-text>
				      </v-window-item>

				      <!-- Seleccioanr grupo -->
				      <v-window-item :value="2">
				        <v-card-text class="mb-4 pb-0">
				        	<SeleccionarGrupo  :alumno="alumno" @grupoSeleccionado="grupoSeleccionado" @regresar="regresar" @seleccionaFactura="seleccionaFactura" :noHermanos="noHermanos"/>
				        </v-card-text>
				      </v-window-item>

				      <!-- Realizar pago -->
				      <v-window-item :value="3">
				        <v-card-text class="mb-4 pb-0">
				          <RealizarPago :alumno="alumno" :grupo="grupo" @regresarGrupo="regresarGrupo" @terminarProceso="terminarProceso"/>
				        </v-card-text>
				      </v-window-item>

				      <v-window-item :value="4">
				        <div class="pa-4 text-center">
				          <span class="text-caption grey--text">Gracias por registrarte</span>
				        </div>
				      </v-window-item>

				      <!-- Pagar una factura -->
				      <v-window-item :value="6">
				        <v-card-text class="mb-4 pb-0">
				          <PagarFactura :alumno="alumno" :grupo="grupo" @regresarGrupo="regresarGrupo" @terminarProceso="terminarProceso"/>
				        </v-card-text>
				      </v-window-item>
				    </v-window>
				  </v-card>
		  	</v-col>
		  </v-row>

		  <!-- Dialog de carga -->
	    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
	    <carga                v-if="cargar"/>
		</v-container>
	</v-dialog>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta              from '@/components/alertas/Alerta.vue'
  import carga               from '@/components/alertas/carga.vue';
  import GruposActivos       from '@/components/inscripciones/GruposActivos.vue';
  import BuscarAlumno        from '@/components/inscripciones/ingresarpago/BuscarAlumno.vue'
  import SeleccionarGrupo    from '@/components/inscripciones/ingresarpago/SeleccionarGrupo.vue'
  import RealizarPago        from '@/components/inscripciones/ingresarpago/RealizarPago.vue'
  import NuevoAlumno         from '@/components/inscripciones/ingresarpago/NuevoAlumno.vue'
  import PagarFactura        from '@/components/inscripciones/ingresarpago/PagarFactura.vue'


  import validarErrores  from '@/mixins/validarErrores'



	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
      GruposActivos,
      BuscarAlumno,
      SeleccionarGrupo,
      RealizarPago,
      NuevoAlumno,
      PagarFactura
    },

    mixins: [ validarErrores ],

  	props:['inscribir'],

    data: () => ({
    	// Datos del alumno inscrito
    	alumnoNuevo: false,
    	alumnos:[],
    	id_alumno: null,
    	grupoActual:null,
			grupoSiguiente:null,
			nuevoGrupo:false,

			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      step: 1,
      ventana: 1,
      perfil: null,
      valid: true,
      valid2: true,
      valid3: true,

      alumno:null,
      grupo:null,
      noHermanos: true,
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),

    	maxWidth ( ){
    		switch( this.step ){
    			case 1: 
    				return 1200
    			break;

    			case 2: 
    				return 1000
    			break;

    			default: 
    				return 600 
    			break;
    		}
    	},


    	tema ( ){
    		switch( this.step ){
    			case 1: 
    				return 'Buscar alumno'
    			break;

    			case 2: 
    				return 'Selecionar grupo'
    			break;

    			case 3: 
    				return 'Realizar pago'
    			break;

    			case 4: 
    				return 'Seleccina opción'
    			break;

    			case 5: 
    				return 'Registrar un alumno'
    			break;

    			case 6: 
    				return 'Pagar Factura'
    			break;


    			default: 
    				return 600 
    			break;
    		}
    	}
    },

    async created () {
      await this.initialize()
      await this.getAlumnos()
    },


    methods: {
      initialize () {
        this.descuentos = []
        return this.$http.get('descprontopago.grupo/' + this.inscribir.id_grupo).then(response=>{
        	this.descuentos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getAlumnos( ) {
      	this.alumnos = []
        return this.$http.get('inscripciones.alumnos').then(response=>{
        	this.alumnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      seleccionarAlumno( value ){
      	this.alumno = value.grupo
      	this.step   = 2
      	this.noHermanos = true
      },

      grupoSeleccionado( value ){
      	this.grupo = value
      	this.step  = 3
      },

      seleccionaFactura( value ){
      	this.grupo = value
      	this.step  = 6
      },

      regresar( value ){
      	this.alumno = null
      	this.step   = value
      },

      regresarGrupo( value ){
      	this.step   = value
      },

      cancelar( ){
      	this.inscribir.estatus = false
      	this.grupo             = null
      	this.grupoSiguiente    = null
      	this.alumno            = null
      },

      nuevoalumno ( value ){
      	this.alumno = value
      	this.step = 5
      },

      buscaralumno ( ){
      	this.alumno = null
      	this.step = 1
      },

      retornarAlumno( value ){
      	this.alumno = value
      	this.step   = 2
      },

      terminarProceso( ){
      	this.step              = 1
      	this.grupo             = null
      	this.grupoSiguiente    = null
      	this.alumno            = null
      	this.inscribir.estatus = false
      }
    },
  }
</script>


