var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","max-width":"800"},model:{value:(_vm.paramResagados.estatus),callback:function ($$v) {_vm.$set(_vm.paramResagados, "estatus", $$v)},expression:"paramResagados.estatus"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Papelera ")],1),_c('v-card-text',[_c('v-alert',{attrs:{"border":"left","colored-border":"","type":"error","elevation":"2"}},[_vm._v(" Los prospectos a continuación son prospectos que:"),_c('br'),_vm._v(" 1-. Llevan más de 7 días y no se les ha brindado información"),_c('br'),_vm._v(" 2-. Llevan más de 10 días, se les brindo información más no hubo respuesta"),_c('br')]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headersRezagados,"items":_vm.prospectosBasura,"dense":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.rechazoProspectoBasura(item.idprospectos)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.idetapa",fn:function(ref){
var item = ref.item;
return [(item.idetapa == 1)?_c('v-tooltip',{attrs:{"bottom":"","color":"blue"},scopedSlots:_vm._u([(item.idetapa == 1)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.idetapa == 1)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle")]):_vm._e()]}}:null],null,true)},[_c('span',[_vm._v("El proespecto esta esperando que se contacten con el")])]):_vm._e(),(item.idetapa == 2)?_c('v-tooltip',{attrs:{"bottom":"","color":"blue"},scopedSlots:_vm._u([(item.idetapa == 2)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.idetapa == 2)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle")]):_vm._e()]}}:null],null,true)},[_c('span',[_vm._v("Prospecto llego hoy y no tiene información")])]):_vm._e(),(item.idetapa == 3)?_c('v-tooltip',{attrs:{"bottom":"","color":"blue"},scopedSlots:_vm._u([(item.idetapa == 3)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.idetapa == 3)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue","left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-bell")]):_vm._e()]}}:null],null,true)},[_c('span',[_vm._v("El prospecto se encuentra en inducción")])]):_vm._e(),(item.idetapa == 4)?_c('v-tooltip',{attrs:{"bottom":"","color":"blue"},scopedSlots:_vm._u([(item.idetapa == 4)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.idetapa == 4)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle")]):_vm._e()]}}:null],null,true)},[_c('span',[_vm._v("Con información"),_c('br'),_vm._v("más de 4 intentos de contacto"),_c('br'),_vm._v("más de 10 días que ingresó")])]):_vm._e(),(item.idetapa == 5)?_c('v-tooltip',{attrs:{"bottom":"","color":"blue"},scopedSlots:_vm._u([(item.idetapa == 5)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.idetapa == 5)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle")]):_vm._e()]}}:null],null,true)},[_c('span',[_vm._v("Sin información"),_c('br'),_vm._v("más de 4 intentos de contacto"),_c('br'),_vm._v("más de 7 días que ingresó")])]):_vm._e()]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","dark":""},on:{"click":function($event){return _vm.cerrar()}}},[_vm._v("Cancelar")])],1),_c('v-btn',{staticClass:"mt-5 mr-0",attrs:{"color":"transparent elevation-0","x-small":"","absolute":"","top":"","right":"","fab":""},on:{"click":function($event){return _vm.cerrar()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar)?_c('carga'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }