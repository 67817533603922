<template>
  <v-container class="mt-4 ml-2">

	  <v-row justify="center">
	    <v-col cols="12">
	    	<v-card class="shadowCard">
	    	  <v-card-title class="text-subtitle-1">
	    	  Entradas y Salidas Corporativo
	    	    <v-spacer></v-spacer>
            <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" class="mr-2"/>
	        	<v-btn  
	        		v-if="tableData.length"
	        		color="success" 
	        		dark  
	        		small
	        		class="ml-4"
	            @click="procesarInformacion()"
	          > Guardar </v-btn> 
	    	  </v-card-title>

	        <v-col cols="12">
	          <v-card flat>
	            <v-alert outlined type="warning"  prominent v-if="!tableData.length && !reportes.length">
	              NO SE HA CARGADO NINGUN DOCUMENTO.
	            </v-alert>

	            <v-row justify="end">
	            	<v-col cols="12" md="10" lg="6">
			            <v-text-field
			              v-model="search"
			              dense
			              filled
			              label="Buscar"
			              append-icon="mdi-magnify"
			            ></v-text-field>
	            	</v-col>
	            </v-row>

	            <v-card v-if="tableData.length">
	              <v-data-table
	                dense
	                :headers="tableHeader"
	                :items="tableData"
	                item-key="name"
	                :page.sync="page"
							    @page-count="pageCount = $event"
	                fixed-header
	                hide-default-footer
	                :height="tamanioPantalla"
                  locale="es"
	              	:search="search"
	              >
	              </v-data-table>
	            </v-card>
	            <div class="text-center pt-2" v-if="tableData.length">
	              <v-pagination v-model="page" :length="pageCount"></v-pagination>
	            </div>
	          </v-card>
	        </v-col>
	    	</v-card>
	    </v-col>
	  </v-row>

     <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
	</v-container>
</template>

<script>
import axios from 'axios';
  import UploadExcelComponent from '@/components/UploadExcel.vue'

	import { mapGetters} from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'
  import filtrosRolTeachers    from '@/mixins/filtrosRolTeachers'


  export default {

  	components:{
      Alerta,
      carga,
    	UploadExcelComponent
    },

    mixins: [ validarErrores, funcionesExcel, filtrosRolTeachers ],

	  data() {
	    return {
	    	// Alertas
	    	parametros:{
	        dialogError: false,
	        mensaje: '',
	        color: ''
	      },

	      respuestaAlerta:false,
	      loader: true,
	      cargar: false,

	      page: 1,
	      pageCount: 0,
	      itemsPerPage: 20,

	      search:'',

	        tableHeader: [ 
					{ text: 'id_trabajador'             , value: 'id_trabajador' },
					{ text: 'id_plantel'                , value: 'id_plantel'},
					{ text: 'tipo_persona'              , value: 'tipo_persona'},
					{ text: 'tipo_registro'             , value: 'tipo_registro'},
					{ text: 'hora_registro'             , value: 'hora_registro'},
					{ text: 'fecha_registro'            , value: 'fecha_registro'},
					{ text: 'id_sucursal'               , value: 'id_sucursal'},
	      ],   

	      tableData: [],  // DATOS A MOSTRAR 
	      reportes : [],  // TABLA REPORTES

	      alerta: { activo: false, texto:'', color:'error'},
	      overlay: false,
	      
	      codigos:[]
	    }
	  },

	  async created(){
	  },

	  computed:{
	    ...mapGetters('login',['getdatosUsuario']),
	    tamanioPantalla () {
	      return this.$vuetify.breakpoint.height -380
	    },
	  },

	  methods: {

	    beforeUpload(file) {
	      const isLt1M = file.size / 1024 / 1024 < 1
	      if (isLt1M) {
	        return true
	      }
	      this.$message({
	        message: 'Please do not upload files larger than 1m in size.',
	        type: 'warning'
	      })

	      return false
	    },

	    handleSuccess({results, header }) {
	      this.obtenerDatos(results);
	    },

	    obtenerDatos (results){
	      var me = this
	      let Temporal  =  results.map(obtenerValoresPresupuesto)
	      let value2 = {}
	      function obtenerValoresPresupuesto(value, index, array) {
	        if (value['id_trabajador'] !== null ) {

			  let desglose = value['Nombre'].split('-')
	          value.id_trabajador = desglose[0]
	        }

	        if (value['id_plantel'] !== null ) {
	          value.id_plantel = 13
	        }  

	        if (value['tipo_persona'] !== null ) {
	          value.tipo_persona = 2
	        }
	        
	        if (value['tipo_registro'] !== null ) {
	          value.tipo_registro = 1
	        }

	       if (value['hora_registro'] !== null || value['hora_registro'] !== '') {
	          value.hora_registro = me.excelSerialToTime( value['Tiempo'] )
	        }

	        if (value['fecha_registro'] !== null || value['fecha_registro'] !== '') {
	          value.fecha_registro = me.excelDateToJSDate( value['Fecha'] )
	        }

	         if (value['id_sucursal'] !== null ) {
	          value.id_sucursal = 13
	        }

	        return value 
	      }

	      this.tableData = Temporal
	    },

        excelDateToJSDate(serial) {
        if (serial) {
            /*
            Calcular la fecha
            */
            var utc_days = Math.floor(serial - (25567 + 1));
            var utc_value = utc_days * 86400;
            var date_info = new Date(utc_value * 1000);

            const fecha = (new Date(date_info - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);

            return fecha;
        }

        return null;
        },

       excelSerialToTime(serial) {
        if (serial) {
            /*
            Calcular la hora
            */
            var fractional_day = serial - Math.floor(serial) + 0.0000001;
            var total_seconds = Math.floor(86400 * fractional_day);
            var seconds = total_seconds % 60;
            total_seconds -= seconds;

            var hours = Math.floor(total_seconds / (60 * 60));
            var minutes = Math.floor(total_seconds / 60) % 60;

            const hora = (hours < 10 ? ('0' + hours) : hours) + ':' + (minutes < 10 ? ('0' + minutes) : minutes) + ':' + (seconds < 10 ? ('0' + seconds) : seconds);

            return hora;
        }

        return null;
        },

	    procesarInformacion( ){
	    	this.cargar = true
    		this.$http.post('conmutador.cargar.datos.entradas', this.tableData ).then(response=>{
    			this.tableData = []
    			this.validarSuccess( response.data.message )
    			this.cargar = false
    		}).catch(error=>{ 
    			this.validarError( error.response.data.message )
    		}).finally( () =>{ this.cargar = false } )
	    },

	  }
	}
</script>