<template>
	<v-container fluid>
	  <v-row>
	  	<v-col cols="12" class="pa-0">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Alumnos</span>
	  		    <v-spacer></v-spacer>
	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="6">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="alumnos"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
			            :items-per-page="5"
			            dense
							  >
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip 
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>
							    
							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  export default {
  	props:[
			'editedItem'
	  ],
    data: () => ({

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      alumnos: [],
      headers: [
        { text: 'ID'        , value: 'id_alumno'        },
        { text: 'Matricula' , value: 'matricula'        },
        { text: 'Nombre'    , value: 'nombre'           },
        { text: 'Apellido'  , value: 'apellido_paterno' },
        { text: 'Apellido'  , value: 'apellido_materno' },
        { text: 'Pagado'    , value: 'pagado'           },
        { text: 'Adeudo'    , value: 'adeudo'           },
        { text: 'Total'     , value: 'total_pagado'     },
      ],
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
    },

    async created () {
      await this.initialize()
    },

    methods: {
      initialize () {
        this.alumnos = []
        return this.$http.get('grupos.alumnos/' + this.editedItem.id_grupo).then(response=>{
        	this.alumnos = response.data
        	console.log( this.alumnos )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
    },
  }
</script>


