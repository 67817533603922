<template>
	<v-card class="elevation-0" >
		<v-row>
			<v-col cols="12">
				<v-card class="shadowCard" >
		      <v-card-title>
		        Datos del alumno
		      </v-card-title>
		      <v-card-text>
				  	<!-- BUSCAR POR MATRICULA -->
				  	<label class="pl-0 black--text">Alumno</label>
						<v-row>
							<v-col cols="12" class="pt-0">
								<v-autocomplete
				          filled 
				          dense
				          clearable
				          v-model="alumnoSeleccionado"
				          :items="alumnos"
				          label="Selecciona el alumno"
				          hide-details
				          return-object
				          item-text="datos_completos"
				          item-value="id_alumno"
				          class="mt-4"
				        >
				        </v-autocomplete>
							</v-col>
						</v-row>

						<v-divider class="my-4"></v-divider>

				    <!-- Datos generales del alumno -->
				    <label class="pl-0 black--text" >Datos generales</label>
						<v-row v-if="alumnoSeleccionado">
							<v-col cols="12" sm="6">
								<v-text-field
								  filled 
								  dense
								  readonly
								  hide-details
								  v-model="alumno.nombre"
								  label="Nombre"
								></v-text-field>
							</v-col>

							<v-col cols="12" sm="6">
								<v-text-field
								  filled 
								  dense
								  readonly
								  hide-details
								  v-model="alumno.apellido_paterno"
								  label="Ap. Paterno"
								></v-text-field>
							</v-col>

							<v-col cols="12" sm="6">
								<v-text-field
								  filled 
								  dense
								  readonly
								  hide-details
								  v-model="alumno.apellido_materno"
								  label="Ap. Materno"
								></v-text-field>
							</v-col>

							<v-col cols="12" sm="6">
								<v-text-field
								  filled 
								  dense
								  readonly
								  hide-details
								  v-model="alumno.edad"
								  label="Edad"
								  type="number"
								></v-text-field>
							</v-col>
						</v-row>

						<!-- Datos del contacto -->
						<v-divider class="my-4"></v-divider>
						<label class="pl-0 black--text" >Datos de contacto</label>
						<v-row v-if="alumnoSeleccionado">
							<v-col cols="12" sm="6">
								<v-text-field
								  filled 
								  dense
								  readonly
								  hide-details
								  v-model="alumno.telefono"
								  label="Teléfono"
								  type="number"
								></v-text-field>
							</v-col>

							<v-col cols="12" sm="6">
								<v-text-field
								  filled 
								  dense
								  readonly
								  hide-details
								  v-model="alumno.celular"
								  label="Celular"
								  type="number"
								></v-text-field>
							</v-col>
						</v-row>
		      </v-card-text>
		    </v-card>
			</v-col>
		</v-row>

		<v-card-actions>
      <v-btn 
      	text 
      	@click="dialogAddAlumno.estatus = false"
      >
        Cancelar
      </v-btn>

      <!-- <v-btn 
      	text 
        @click="steppers.paso = 1"
      >
        Regresar
      </v-btn> -->

      <v-spacer></v-spacer>
      <v-btn
      	tile
        color="primary"
        @click="steppers.paso = 2"
        v-if="alumnoSeleccionado"
      >
        Siguiente
      	<v-icon right small>mdi-arrow-right</v-icon>
      </v-btn>
  	</v-card-actions>


  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <AgregarProspecto     v-if="existeProspecto"             :parametrosProspectos="parametrosProspectos" @saveProspecto="saveProspecto"/>


	</v-card>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import AgregarProspecto   from '@/components/inscripciones/AgregarProspecto.vue';


  import validarErrores  from '@/mixins/validarErrores'

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
      AgregarProspecto
    },

    mixins: [ validarErrores ],

  	props:[
			'grupo',
			'alumno',
			'steppers',
			'hermanos',
			'tutor',
			'dialogAddAlumno',
	  ],

    data: () => ({
    	escuela: 2,
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      cantidadHermanos: 1,

      respuestaAlerta:false,
      cargar: false,

			step:1,
			isTutor:true,

			opcion:1,

			posicionHermano: 0,

			empleados:[],
			sonHermanos:false,
			existeMatricula: false,
    	prospectos:[],
    	parametrosProspectos:{
    		estatus: false
    	},
    	existeProspecto:false,
    	prospecto: null,
    	alumnoSeleccionado: null,
    	alumnos:[],

    	alertaSeguridad: false,

    	headers:[
    		{ text: 'Nombre'       , value: 'nombre'       },
        { text: 'Telefono'     , value: 'telefono'     },
        { text: 'Grupo'        , value: 'grupo'        },
        { text: 'ID prospecto' , value: 'id_prospecto' },
    	]



    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),
    },

    watch:{
    	alumnoSeleccionado( value){
    		if( value ){
    			this.alumno.nombre           = value.alumno
    			this.alumno.telefono         = value.telefono
    			this.alumno.celular          = value.telefono
    			this.alumno.apellido_materno = value.apellido_materno
    			this.alumno.apellido_paterno = value.apellido_paterno
    			this.alumno.edad             = value.edad
    			this.alumno.id_alumno        = value.id_alumno
    			this.alumno.matricula        = value.matricula
    			this.alumno.unidad_negocio   = value.unidad_negocio
    		}else{
    			this.alumno.nombre           = ''
    			this.alumno.telefono         = ''
    			this.alumno.celular          = ''
    			this.alumno.apellido_materno = ''
    			this.alumno.apellido_paterno = ''
    			this.alumno.edad             = ''
    			this.alumno.id_alumno        = ''
    			this.alumno.matricula        = ''
    		}
    	},
    },

    async created () {
    	this.escuela = this.getEscuela

    	this.alumno.unidad_negocio = this.getEscuela

    	await this.getAlumnos()
    },

    methods: {

    	getAlumnos( ) {
      	this.cargar = true
      	this.alumnos = []
        return this.$http.get('inscripciones.alumnos').then(response=>{
        	this.cargar = false
        	this.alumnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      abrirDialogProspecto( ){
      	this.existeProspecto              = true
      	this.parametrosProspectos.estatus = true
      },

      saveProspecto( value ){
        this.alumno.nombre                   = value.nombre_completo
	    	this.alumno.apellido_paterno         = ''
	    	this.alumno.apellido_materno         = ''
	    	this.alumno.telefono                 = value.telefono
	    	this.alumno.celular                  = value.telefono
	    	this.alumno.id_tipo_alumno           = 1
	    	this.alumno.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp
	    	this.alumno.unidad_negocio           = this.getEscuela
	    	this.alumno.id_prospecto             = value.idprospectos
	    	this.existeMatricula                 = true
      },

    },
  }
</script>
