<template>
	<!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="dialogGrupos.estatus"
    persistent
    max-width="1200"
  >
		<v-card>
      <v-card-title>
        <span class="text-subtitle-1 font-weight-bold text-center">
          Busqueda de grupos
        </span>
        <v-spacer></v-spacer>

        <v-btn 
          class="mr-2 mb-2" 
          small dark color="green"
          @click="exportarInscripciones()"
          tile
        >
          <v-icon small>mdi-microsoft-excel</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" lg="4" class="pb-0">
            <v-select
              :items="planteles"
              v-model="plantel"
              label="Plantel"
              filled
              dense
              clearable
            ></v-select>
          </v-col>

          <v-col cols="12" md="4" lg="4" class="pb-0">
            <v-select
              :items="niveles"
              v-model="nivel"
              label="Nivel"
              filled
              dense
              clearable
            ></v-select>
          </v-col>

          <v-col cols="12" md="4" lg="4" class="pb-0">
            <v-select
              :items="horarios"
              v-model="horario"
              label="Horario"
              filled
              dense
              clearable
            ></v-select>
          </v-col>

          <v-col cols="12" md="4" lg="4" class="pb-0">
            <v-autocomplete
              :items="ciclos"
              v-model="ciclo"
              label="Ciclo"
              filled
              dense
              clearable
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="8" lg="8" class="pb-0">
            <v-text-field
              v-model="search"
              label="Buscar"
              filled
              append-icon="mdi-magnify"
              dense
              clearable
            ></v-text-field>
          </v-col>


        </v-row>

        <v-data-table
			    :headers="headers"
			    :items="filterGrupos"
			    class="elevation-0"
			    :search="search"
			    :mobile-breakpoint="100"
          dense
			  >

          <template v-slot:item.meta="props" >
            <v-edit-dialog
              :return-value.sync="props.item.meta"
              @save="guardarMeta( props.item )"
              large
              v-if="['NIVEL 1','NIVEL 2','NIVEL 3'].includes( props.item.nivel ) && [334,568,28].includes( getdatosUsuario.iderp )"
            >
              {{ props.item.meta }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.meta"
                  label="Meta"
                  type="number"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>

            <span v-if="![334,568,28].includes( getdatosUsuario.iderp )">{{ props.item.meta }}</span>
          </template>

			    <template v-slot:no-data>
			      <v-btn
			        color="primary"
			        @click="initialize"
			        small
			      >
			        Actualizar
			      </v-btn>
			    </template>

			  </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="black"
          dark
          small
          tile
          @click="dialogGrupos.estatus = false"
        >
        	<v-icon small left>mdi-cancel</v-icon>
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
	</v-dialog>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta              from '@/components/alertas/Alerta.vue'
  import carga               from '@/components/alertas/carga.vue';

  import validarErrores      from '@/mixins/validarErrores'

  import mixinLeads          from '@/mixins/mixinLeads'
  import funcionesExcel      from '@/mixins/funcionesExcel'

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, mixinLeads, funcionesExcel ],

  	props:['dialogGrupos'],

    data: () => ({
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
      
    	// Datos del alumno inscrito
    	grupos:[],

    	planteles: [],
    	plantel  : null,
    	niveles  : [],
    	nivel    : null,
    	ciclos   : [],
    	ciclo    : null,
    	horarios : [],
    	horario  : null,

    	// tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      grupos: [],
      headers: [
        { text: 'ID'          , value: 'id_grupo'      },
        { text: 'Grupo'       , value: 'grupo'         },
        { text: 'Horario'     , value: 'horario'       },
        { text: 'Cupo'        , value: 'capacidad'     },
        { text: 'Nivel'       , value: 'nivel'         },
        { text: 'Inscritos'   , value: 'inscritos'     },
        { text: 'Meta'        , value: 'meta'          },
        { text: 'Grupo Ant'   , value: 'grupo_ant'     },
        { text: 'Insc. Ant'   , value: 'inscritos_ant' },
        { text: 'Disponibles' , value: 'disponibles'   },
      ],

      
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),

    	filterGrupos(){

    		let grupoFinales = this.grupos

    		// Validamos si hay un plantel y filtramos por ese plantel
    		if( this.plantel ){ grupoFinales = grupoFinales.filter( el => { return el.plantel == this.plantel } )}

    		// Validamos si hay un horario y filtramos por ese horario
    		if( this.horario ){ grupoFinales = grupoFinales.filter( el => { return el.horario == this.horario } )}

    		// Validamos si hay un ciclo y filtramos por ese ciclo
    		if( this.ciclo ){ grupoFinales = grupoFinales.filter( el => { return el.ciclo == this.ciclo } )}

    		// Validamos si hay un nivel y filtramos por ese nivel
    		if( this.nivel ){ grupoFinales = grupoFinales.filter( el => { return el.nivel == this.nivel } )}

        return grupoFinales
      },
    },

    async created () {
      await this.initialize()
    },


    methods: {

      // Obtener todas las sucursales
      initialize() {
        this.cargar  = true
        this.grupos  = []

        let ruta = 'grupos.habiles/' + this.getEscuela  
        if( this.getdatosUsuario.iderp == 7 ){
          ruta =  'grupos.habiles/'
        }

        return this.$http.get( ruta ).then((response) => {

          // Llenamos la etapa incial
          this.grupos    = response.data.grupos
          this.planteles = response.data.planteles
          this.niveles   = response.data.niveles.filter( el => el )
          this.ciclos    = response.data.ciclos
          this.horarios  = response.data.horarios
          this.cargar    = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      exportarInscripciones( ){

        console.log( this.ciclo.substring(0,11) )

        let ciclo = this.ciclo.substring(0,11)

        let grupos = this.grupos.filter( el => el.grupo.match(ciclo) )

        this.exportExcel( grupos, 'Grupos')

      },

      guardarMeta( meta ){
        this.cargar  = true

        return this.$http.post('grupos.add.meta', meta ).then((response) => {

          // Llenamos la etapa incial
          this.initialize( )

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      habilitarCambio( item ){
        if( ['NIVEL 1','NIVEL 2','NIVEL 3'].includes( item.nivel ) ){
          console.log( item.nivel )
          true
        }else{
          false
        }
      }

    },
  };
</script>
