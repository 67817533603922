<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-text>

          <div class="black--text text-subtitle-1">
            <b>Reporte generado por:</b> {{ getdatosUsuario.nombre_completo }}
            <br/>
            <v-chip>
              <b>Tipo de reporte: </b> {{ reporteTexto }}
            </v-chip>
            <br/>
          </div>

          <v-row v-if="alumno">
            <v-col cols="12">
              <v-card-text>
                <b>Matricula:</b> {{ alumno.matricula }}
                <br/>
                <b>Nombre:</b>    {{ alumno.alumno }}
                <br/>
                <v-divider></v-divider>

                <br/>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
									    :headers="headers"
									    :items="movimientos"
									    class="elevation-0"
									    :search="search"
									    :mobile-breakpoint="100"
					            dense
					            id="grupos"
					            item-class="clase_row"
					            single-select
					            v-model="selected"
					            item-key="id_ingreso"
    									show-select
									  >
									  	<!-- Valores que ocupan botons, chips, iconos, etc -->
									    <template v-slot:item.baja="{ item }">
									      <v-chip 
									      	small
									      	:color="item.baja == 1 ? 'green': 'red'"
									      	dark
									      >{{ item.baja == 1 ? 'SI': 'NO'  }}</v-chip>
									    </template>

									    <template v-slot:no-data>
									      <v-btn
									        color="primary"
									        @click="getMovimientos"
									        small
									      >
									        Actualizar
									      </v-btn>
									    </template>

									  </v-data-table>
                  </v-col>

                </v-row>

                <v-row v-if="pago">
                  <v-col cols="12" md="6">

                  	Fecha de pago original
                  	<v-text-field
										  v-model="vieja_fecha"
										  label="Fecha de pago"
										  filled
										  dense
						          readonly
						          type="date"
                  	></v-text-field>

                  	<v-alert
								      outlined
								      type="info"
								      prominent
								      border="left"
								    >
								      El cambio de fecha no modifica el corte, solo eliminará el adeudo en caso de haber uno.
								    </v-alert>


                  </v-col>

                  <v-col cols="12" md="6">

                  	Nueva fecha de pago
                  	<v-text-field
										  v-model="nueva_fecha"
										  label="Nueva fecha"
										  filled
										  dense
						          type="date"
                  	></v-text-field>

                  	<v-textarea
		                  label="Por favor, ingresa el motivo de tu solicitud"
		                  v-model="motivo"
		                  filled
		                  :rows="3"
		                  auto-grow
		                ></v-textarea>

		                <v-file-input 
		                  small-chips 
		                  multiple 
		                  filled 
		                  v-model="files" 
		                  accept="image/png, image/jpeg, image/bmp"
		                  placeholder="Selecciona tus imagenes" prepend-icon="mdi-camera" label="Imágenes" @change="cargarFotos()">
		                </v-file-input>

		                <v-row v-if="vistaPrevias.length">
		                  <v-col cols="12" md="3" v-for="(img, i) in vistaPrevias" :key="i">
		                    <v-card class="py-4 shadowCard">
		                      <v-img :src="img.url" contain aspect-ratio="2"></v-img>
		                      <v-btn  color="error"  small  @click="eliminarFoto(img.url)" top right absolute fab >
		                        <v-icon>mdi-delete</v-icon>
		                      </v-btn>
		                    </v-card>
		                  </v-col>
		                </v-row>
		              </v-col>
		            </v-row>
              </v-card-text>

              <v-card-actions>
              	<v-spacer></v-spacer>
              	<v-btn
			            v-if="nueva_fecha"
			            color="green"
			            dark
			            tile
			            @click="addCambio()"
			            :loading="loading"
			            :disabled="disabled" 
			          >
			            <v-icon small left>mdi-send</v-icon>
			            Enviar solicitud
			          </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
	var moment = require('moment');
  moment.locale();
	import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';


  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    props: ['reporteTexto','alumno', 'tipo_reporte'],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search: '',
      
      tipociclo:'',
      grupos:[],
      nuevoGrupo:null,
      tipocambios:[],
      tipoCambio:null,
      alumnos:[],

      grupoAlumnos:   [],
      grupoAnterior:  null,
      
      loading:        false,
      disabled:       false,
      motivo:         null,

      total_a_pagar: 0,
      precios:[],

      dataGrupoActual: null,
      dataNuevoGrupo:null,
      precioSeleccionado2: null,
      descuentoHermanos:0,

      movimientos:[],

      vieja_fecha: 0,
      nueva_fecha: 0,

      url:'',
      url2:'',

      fotos:[],
      files: [],
      vistaPrevias:[],

      selected:[],


      headers: [
        { text: 'id_ingreso'    , value: 'id_ingreso'      },
        { text: 'Grupo'         , value: 'grupo'           },
        { text: 'Monto pagado'  , value: 'pagado'          },
        { text: 'Fecha'         , value: 'fecha_alta'      },
        { text: 'Forma pago'    , value: 'forma_pago'      },
      ],

      pago: null,

      comprobanteOficial:'',

      tipos_pago:[
      	{ id: 1, tipo: 'EFECTIVO'},
      	{ id: 2, tipo: 'DEPOSITO / TRANSFERENCIA'},
      	{ id: 4, tipo: 'TARJETA' },
      ],

    }),

    watch:{
      selected( value ){
      	console.log( value )
      	if( value.length ){ 
      		this.pago = value[0]
      		this.vieja_fecha = this.pago.fecha_alta.substr(0,10)
      	}
      }
    },

    async created () {
      this.url2 = axios.defaults.baseURL + 'comprobante-pago/'
      this.url = axios.defaults.baseURL + 'evidencia-cambios/'
      // Cargar los cursos actuales
      await this.getMovimientos()
    },

    methods: {

    	getMovimientos () {
        this.movimientos = []
        return this.$http.get('inscripciones.alumno.grupos/' + this.alumno.id_alumno).then(response=>{

        	console.log( response.data )
        	this.movimientos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async addCambio () {

        // Validar que esten llenos los campos
    		this.cargar = true
      	
      	let fotos = []
        if(this.vistaPrevias.length){
          let respuesta = await this.subirFotosServidor( )
          fotos = respuesta.data
        }

        // Activar el disabled del boton para que no le den doble click
        this.loading  = true
        this.disabled = true
        // Desesestructuración de alumno 
        const { id_alumno, id_plantel, monto_pagado_total } = this.alumno

        // Generar el payload a mandar
        let payload = {
          id_alumno,
          idtipo_cambio:     0,
          solicito_cambio:   this.getdatosUsuario.iderp,
          id_plantel,
          id_grupo_actual:   0,
          id_grupo_nuevo:    0,
          estatus:           1,
          diferencia:        0,
          motivo:            this.motivo,
          nuevo_comprobante: fotos.length ?  fotos[0] : '',
          tipo_reporte:      this.tipo_reporte,
          id_ingreso:        this.pago.id_ingreso,
          viejo_metodo:      0,
          nuevo_metodo:      0,
          viejo_monto:       0,
          nuevo_monto:       0,
          vieja_fecha:       this.vieja_fecha,
          nueva_fecha:       this.nueva_fecha,
          fotos,
        }

        this.cargar = true

        // Enviar los datos 
        this.$http.post('cambios.add',payload).then(response=>{

          this.validarSuccess( 'Datos grabados correctamente')

          this.$emit('getAlumnosxEscuela')

        }).catch(error=>{
          this.loading  = false
          this.disabled = false
          this.validarError(error)
        })
        
      },

      

      subirFotosServidor( ){
        let formData = new FormData();

        for( const i in this.vistaPrevias ){
          formData.append('file', this.vistaPrevias[i].file )
        }

        return new Promise((resolve,reject)=>{
          // obtener la extensión del archivo
          // Hacemos la petición
          this.$http.post(`cambios.ingreso.foto`, formData ).then(response=>{
            resolve( response )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },

      cargarFotos(){
        if(this.files.length < 1){
          return
        }

        this.files.forEach((element, index) => {
                // creamos una variable tipo FormData
          let formData = new FormData();
                //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', element);
                //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          this.getBase64(element, formData)
        })
      },

      getBase64(file, formData) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          console.log(file)
          me.vistaPrevias.push({
            url: reader.result, 
            formData: formData, 
            image_name: file.name,
            file
          })
        };
      },

      eliminarFoto(value){
        this.vistaPrevias.forEach((element, index)=>{
          if(element.url == value){
            this.vistaPrevias.splice(index,1);
          }
        })
      },
    },

    computed:{
      ...mapGetters ('login',['getdatosUsuario']),

      costoCurso( ){
        
        if( this.precioSeleccionado ){

          let existeCosto = this.precios.find( el => el.id_curso == this.precioSeleccionado )

          return existeCosto ? existeCosto.precio_lista : 0

        }else{
          return 0
        }

      },

      currentTitle () {
        switch (this.step) {
          case 1: return 'Selecciona el alumno'
          case 2: return 'Selecciona tipo de reporte'
          default: return 'Confirma tu reporte'
        }
      },

      filterAlumnos( ){

        const alumnos = this.movimientos

        if( this.plantel ){
          return alumnos.filter( el=> { return el.id_plantel == this.plantel })
        }

        if( this.step == 3 ){
          return this.selected
        }
        
        return alumnos
      },
    }
  }
</script>

<style>

  .v-menu__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-menu__content::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-button:increment,.v-menu__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-menu__content::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>