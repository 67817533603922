<template>
	<v-container fluid>
	  <v-row>
	  	<v-col cols="12" class="pa-0">
	  		<v-card class="shadowCard">
	  		  <v-card-text class="pt-0">
		  		  <v-row>
	  		  		<v-col cols="12" sm="9">
			  		  	<v-card-title primary-title class="py-0">
				  		    <span class="text-subtitle-1">Solicitar beca</span>
				  		    <v-spacer></v-spacer>
				  		    <v-btn color="primary" @click="agregarDescuento()" v-if="[28,334].includes( getdatosUsuario.iderp ) ">Solicitar</v-btn>
				  		  </v-card-title>
	  		  		</v-col>
	  		  	</v-row>

	  		  	<v-row v-if="[28,334].includes( getdatosUsuario.iderp ) ">
	  		  		<v-col cols="12" sm="3">
	  		  			<v-select
	  		  			  label="% Descuento"
	  		  			  dense
	  		  			  filled
	  		  			  hide-details
	  		  			  :items="becas"
	  		  			  v-model="id_beca"
	  		  			  item-text="beca"
	  		  			  item-value="id"
	  		  			></v-select>
	  		  		</v-col>

	  		  		<v-col cols="12" sm="6">
	  		  			<v-text-field
	  		  			  label="Becas restantes"
	  		  			  id="id"
	  		  			  v-model="cantidad"
	  		  			  filled
	  		  			  hide-details
	  		  			  dense
	  		  			  type="number"
	  		  			></v-text-field>
	  		  		</v-col>

	  		  		<v-col cols="12" sm="9">
	  		  			<v-textarea
	  		  			  label="Motivo"
	  		  			  dense
	  		  			  rows="3"
	  		  			  filled
	  		  			  hide-details
	  		  			  v-model="motivo"
	  		  			></v-textarea>
	  		  		</v-col>
	  		  	</v-row>
	  		  </v-card-text>

	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="6">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="becasAlumno"
							    class="elevation-0"
							    :search="search"
			            dense
							  >	
							  	<!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.aprobadoSN="{ item }">
							      <v-chip 
							      	v-if="item.aprobadoSN == 1"
							      	small
							      	color="green"
							      	dark
							      >Aceptada</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >Rechazada</v-chip>
							    </template>


							    <template v-slot:item.eliminar="{ item }">
							      <v-chip 
							      	small
							      	color="red"
							      	dark
							      	@click="eliminarDescuento( item.id )"
							      ><v-icon>mdi-delete</v-icon></v-chip>
							    </template>
							    
							    <template v-slot:item.total_a_pagar="{ item }">
							      {{ editedItem.precio - item.valor_descuento }} 
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';
	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

  	props:[
			'editedItem'
	  ],
    data: () => ({

      // tabla
			search:'',
      becas: [],
      becasAlumno:[],
      headers: [
        { text: 'Beca'      , value: 'beca'        },
        { text: 'Motivo'    , value: 'comentarios' },
        { text: 'Estatus'   , value: 'aprobadoSN'  },
        { text: 'Alta'      , value: 'fecha_alta'  },
        { text: 'Eliminar'  , value: 'eliminar'    },
      ],

			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      becas:[],
      id_beca:0,
      grupos:[],
			id_grupo:0,
			motivo:'',
			cantidad:'',
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
    },

    async created () {
    	console.log ( this.editedItem ) 
      await this.initialize()
      await this.getBecas()
      await this.getGruposAlumnos()
    },

    methods: {
      initialize () {
        this.becasAlumno = []
        return this.$http.get('inscripciones.becas.alumno/' + this.editedItem.id_alumno).then(response=>{
        	this.becasAlumno = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getGruposAlumnos () {
        this.grupos = []
        return this.$http.get('inscripciones.alumno.grupos/' + this.editedItem.id_alumno).then(response=>{
        	this.grupos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getBecas () {
        this.becas = []
        return this.$http.get('inscripciones.becas').then(response=>{
        	this.becas = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      agregarDescuento ( ) {
      	const payload = {
      		id_alumno: this.editedItem.id_alumno,
					idbeca: this.id_beca,
					motivo: this.motivo,
					cantidad: this.cantidad
      	}

      	return this.$http.post(`becas.add`, payload).then(response=>{
          this.validarSuccess('Beca agregada correctamente')
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      eliminarDescuento( id ){
      	const payload = {
      		id_usuario: this.getdatosUsuario.iderp,
      	}
      	return this.$http.put(`beca.deleted/${ id }`, payload).then(response=>{
          this.validarSuccess(response.data.message)
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      validarErrorDirecto ( mensaje ) {
        this.cargar = false 
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
    	},
    },
  }
</script>


