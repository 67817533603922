import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';
import axios from 'axios';
import XLSX from 'xlsx'

export default {
	data: () => ({
    headersBloque1: [
      { text: 'Grupo'      , value: 'grupo'       },
      { text: 'Alumnos'    , value: 'cant_alumnos'},
      { text: 'Actions'    , value: 'actions'       },
    ],

    headers:[],
    selectedHeaders: 1,

    checkEliminados: false,
    checkOptimizados: 3,
    curso: null,
  }),

  created(){
  	this.headers = this.headersBloque1
  },

  watch: {
  	selectedHeaders( val ){
  		switch( val ){
  			case 1: 
  				this.headers = this.headersBloque1
  			break;

  			case 2: 
  				this.headers = this.headersBloque2
  			break;

  			case 3: 
  				this.headers = this.headersBloque3
  			break;

  			case 4: 
  				this.headers = this.headersBloque4
  			break;
  		}
  	}
  },

  computed:{
  	filterGrupos(){

  		let grupos = []

  		if( this.checkEliminados ){
  			grupos = this.gruposRoles.filter( el => { return el.deleted == 1 })
  		}else{
  			grupos = this.gruposRoles.filter( el => { return el.deleted == 0 })
  		}

  		let idPlanteles = this.getdatosUsuario.planteles.map(( registro ) => { return registro.idplantel })

      grupos = grupos.filter( el => { return idPlanteles.includes( el.id_plantel )  })


  		if( this.nivel ){
  			grupos = grupos.filter( el => { return el.id_nivel == this.nivel })
  		}

  		return grupos
  	},
  },

	methods: {

    exportExcel(dataExport, name){
      let data = XLSX.utils.json_to_sheet(dataExport)
      const workbook = XLSX.utils.book_new()
      const filename = name
      XLSX.utils.book_append_sheet(workbook, data, filename)

      var wbout = XLSX.write(workbook, {
        bookType: 'csv',
        bookSST: false,
        type: 'binary'
      });

      const file = new File([new Blob([this.s2ab(wbout)])], filename + '.csv')
      
      let formData = new FormData();
      // //se crea el objeto y se le agrega como un apendice el archivo 
      formData.append('file',file);
      // /*getDatosRiesgo the form data*/

      this.$http.post('pdfs',formData).then(response=> {
        window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.csv'
      }).catch(error=> {
        console.log(error);
      });
    },

    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    },

  }
}