<template>
	<v-card class="elevation-0" max-width="600">
		<!-- Alumno nuevo -->
    <v-window v-model="step">

    	<!-- Seleccionar alumno -->
      <v-window-item :value="1">
				
				<label class="pl-0 black--text">
					<v-spacer></v-spacer>
					<h4 class="text-center">Selecciona una opción</h4>
					<v-spacer></v-spacer>
				</label>

				<br/>
				
				<!--  -->
				<v-row justify="space-around">
					<v-col cols="12" sm="5">
						<v-btn
			        color="primary"
			        dark
			        x-large
			        @click="step = 2, sonHermanos = false "
			        class="shadowCard text-capitalize"
			      >
							<v-icon left>mdi-account</v-icon>
		          Alumno único
			      </v-btn>
					</v-col>

					<v-col cols="12" sm="5" class="text-end">
						<v-btn
			        color="red darken-4"
			        dark
			        x-large
			        @click="step = 4, sonHermanos = true"
			        class="shadowCard text-capitalize"
			      >
							<v-icon left>mdi-account-group</v-icon>
			        Hermanos
			      </v-btn>
					</v-col>
				</v-row>

				<br/>


				<v-card-actions class="mt-3 pl-0">
		      <v-btn
		      	color="black"
		      	dark
		      	tile
		        @click="$emit('buscaralumno')"
		        rounded
		        small
		        class="text-capitalize"
		      >
		      	<v-icon left small>mdi-cancel</v-icon>
		        cancelar
		      </v-btn>
		      <v-spacer></v-spacer>
		    </v-card-actions>
      </v-window-item>

    	<!-- Agregar Alumno único -->
      <v-window-item :value="2">
				<!-- BUSCAR POR MATRICULA -->
      	<label class="pl-0 black--text">Prospecto</label>
				<v-row>
					<v-col cols="12" class="pt-0">
						<v-autocomplete
              outlined
              dense
              clearable
              v-model="prospectoSeleccionado"
              :items="prospectos"
              label="Selecciona el Prospecto"
              hide-details
              item-text="datos_completos"
              item-value="idprospectos"
              class="mt-4"
              return-object
            >
            </v-autocomplete>
					</v-col>
				</v-row>

				<v-btn 
					color="red" 
					dark 
					small 
					class="mt-2 text-capitalize" 
					@click="abrirDialogProspecto()"
					tile
				>
					No existe el prospecto
				</v-btn>

				<v-divider class="my-4"></v-divider>

				<v-alert
		      text
		      prominent
		      type="error"
		      icon="mdi-cloud-alert"
		      v-if="!prospectoSeleccionado"
		    >
		      Para registrar un alumno, es necesario primero registrar un prospecto y luego, cerrar el prospecto ingresando su matricula y grupo, por favor
		    </v-alert>

				<label class="pl-0 black--text" >Datos generales</label>
				<v-row v-if="prospectoSeleccionado">
					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="alumnoNuevo.nombre"
						  label="Nombre"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="alumnoNuevo.apellido_paterno"
						  label="Ap. Paterno"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="alumnoNuevo.apellido_materno"
						  label="Ap. Materno"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="alumnoNuevo.edad"
						  label="Edad"
						  type="number"
						></v-text-field>
					</v-col>
				</v-row>

				<v-divider class="my-4" ></v-divider>
				<label class="pl-0 black--text" >Datos de contacto</label>
				<v-row v-if="prospectoSeleccionado">
					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="alumnoNuevo.telefono"
						  label="Teléfono"
						  type="number"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="alumnoNuevo.celular"
						  label="Celular"
						  type="number"
						></v-text-field>
					</v-col>
				</v-row>


				<v-divider class="my-4" ></v-divider>
				<label class="pl-0 black--text" >Empleado</label>
				<v-row v-if="prospectoSeleccionado">
					<v-col cols="12">
						<v-autocomplete
              :items="empleados"
              v-model="alumnoNuevo.id_empleado"
              label="Empleado que utilizará la beca"
              item-text="nombre_completo"
              item-value="id_usuario"
              outlined
              hide-details
              dense
              single-line
            ></v-autocomplete>
					</v-col>
				</v-row>

				<v-divider class="my-4" v-if="getEscuela == 1 "></v-divider>
				<label class="pl-0 black--text" v-if="getEscuela == 1 ">Hermanos de inglés</label>
				<v-row v-if="prospectoSeleccionado && getEscuela == 1">
					<v-col cols="12">
						<v-autocomplete
              :items="alumnos"
              v-model="alumnoNuevo.id_hermano"
              label="Selecciona un hermano"
              item-text="datos_completos"
              item-value="id_alumno"
              outlined
              hide-details
              dense
              single-line
            ></v-autocomplete>
					</v-col>
				</v-row>

				<!-- botones de alumno único -->
				<v-card-actions class="pl-0 mt-3">
		      <v-btn
		        color="black"
		        small
		        dark
		        @click="sterp = 1"
		        tile
		        class="text-capitalize"
		      >
		      	<v-icon left small>mdi-chevron-double-left</v-icon>
		        regresar
		      </v-btn>
		      <v-spacer></v-spacer>

		      <v-btn
		        color="secondary"
		        small
		        class="text-capitalize"
		        @click="step++"
		        tile
		        :disabled="prospectoSeleccionado ? false : true"
		      >
		      	<v-icon left small>mdi-chevron-double-right</v-icon>
		        Siguiente
		      </v-btn>
		    </v-card-actions>
      </v-window-item>

      <!-- Datos del tutor -->
      <v-window-item :value="3">
        <v-switch label="Con tutor" v-model="isTutor" :readonly="hermanos.length > 1 ? true : false "></v-switch>
				<label class="pl-0 black--text">Datos generales</label>
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="tutor.nombre"
						  label="Nombre"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="tutor.apellido_paterno"
						  label="Ap. Paterno"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="tutor.apellido_materno"
						  label="Ap. Materno"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="tutor.tipo_parentesco"
						  label="Parentezco"
						></v-text-field>
					</v-col>
				</v-row>

				<v-divider class="my-4"></v-divider>
				<label class="pl-0 black--text">Datos de contacto</label>
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="tutor.telefono"
						  label="Teléfono"
						  type="number"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="tutor.celular"
						  label="Celular"
						  type="number"
						></v-text-field>
					</v-col>
				</v-row>
        <v-card-actions class="mt-3">
		      <v-btn
		        color="black"
		        dark
		        @click="step = 2 "
		        class="text-capitalize"
		        small
		        tile
		      >
		      	<v-icon left small>mdi-chevron-double-left</v-icon>
		        regresar
		      </v-btn>
		      <v-spacer></v-spacer>

		      <v-btn
		        color="secondary"
		        class="text-capitalize"
		        @click="grabarDatos( )"
		        small
		        tile
		      >
		      	<v-icon left small>mdi-chevron-double-right</v-icon>
		        Siguiente
		      </v-btn>
		    </v-card-actions>
      </v-window-item>

      <!-- Seleccionar alumno -->
      <v-window-item :value="4">

      	<!-- BUSCAR POR MATRICULA -->
      	<label class="pl-0 black--text">Prospecto</label>
				<v-row>
					<v-col cols="12" class="pt-0">
						<v-autocomplete
              outlined
              dense
              clearable
              v-model="prospectoSeleccionado"
              :items="prospectos"
              label="Selecciona el Prospecto"
              hide-details
              item-text="datos_completos"
              item-value="idprospectos"
              class="mt-4"
              return-object
            >
            </v-autocomplete>
					</v-col>
				</v-row>

				<v-btn 
					color="red" 
					dark 
					small 
					class="mt-2 text-capitalize" 
					@click="abrirDialogProspecto()"
					tile
				>
					No existe el prospecto
				</v-btn>

				<v-divider class="my-4"></v-divider>

				<v-alert
		      text
		      prominent
		      type="error"
		      icon="mdi-cloud-alert"
		      v-if="!prospectoSeleccionado"
		    >
		      Para registrar un alumno, es necesario primero registrar un prospecto y luego, cerrar el prospecto ingresando su matricula y grupo, por favor
		    </v-alert>

		    <label class="pl-0 black--text" >Datos generales del hermano {{ posicionHermano + 1 }}</label>
				<v-row v-if="prospectoSeleccionado">
					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="hermanos[posicionHermano].nombre"
						  label="Nombre"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="hermanos[posicionHermano].apellido_paterno"
						  label="Ap. Paterno"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="hermanos[posicionHermano].apellido_materno"
						  label="Ap. Materno"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="hermanos[posicionHermano].edad"
						  label="Edad"
						  type="number"
						></v-text-field>
					</v-col>
				</v-row>

				<v-divider class="my-4"></v-divider>
				<label class="pl-0 black--text" >Datos de contacto</label>
				<v-row v-if="prospectoSeleccionado">
					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="hermanos[posicionHermano].telefono"
						  label="Teléfono"
						  type="number"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  outlined
						  dense
						  hide-details
						  v-model="hermanos[posicionHermano].celular"
						  label="Celular"
						  type="number"
						></v-text-field>
					</v-col>
				</v-row>

				<v-card-actions class="pb-0">
		      <v-btn
		        color="black"
		        small
		        dark
		        @click="returnHermano( )"
		        tile
		        class="text-capitalize"
		      >
		      	<v-icon left small>mdi-chevron-double-left</v-icon>
		        regresar
		      </v-btn>
		      <v-spacer></v-spacer>

		      <v-btn
		      	v-if="hermanos.length && posicionHermano < (hermanos.length - 1 )"
		        color="primary"
		        depressed
		        rounded
		        class="text-capitalize mr-2"
		        @click="siguienteHermano( )"
		      >
		        Siguiente Hermano
		      </v-btn>

		      <v-btn
		      	v-else
		        color="red"
		        dark
		        depressed
		        tile
		        small
		        class="text-capitalize mr-2"
		        @click="addHermano( )"
		      >
		      	<v-icon left small>mdi-account</v-icon>
		        Otro hermano
		      </v-btn>

		      <v-btn
		        color="primary"
		        depressed
		        tile
		        small
		        class="text-capitalize"
		        @click="step = 3"
		        v-if="hermanos.length > 1"
		      >
		      	<v-icon left small>mdi-chevron-double-right</v-icon>
		        Siguiente
		      </v-btn>
		    </v-card-actions>
      </v-window-item>

     </v-window>

    <v-dialog
      v-model="alertaSeguridad"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card-text primary-title class="black--text">
       Al momento de regresar se eliminará todo registro del alumno que acabas de crear y tendrás que llenar los datos nuevamente.
      </v-card-text>
      <v-card-actions>
       	<v-btn
	        color="black"
	        small
	        dark
	        @click="alertaSeguridad = false"
	        tile
	        class="text-capitalize"
	      >
	      	<v-icon left small>mdi-cacel</v-icon>
	        regresar
	      </v-btn>
       	<v-spacer></v-spacer>
       	<v-btn
	        color="primary"
	        depressed
	        tile
	        small
	        class="text-capitalize"
	        @click="eliminarDatosAlumno( )"
	      >
	        Aceptar
	      </v-btn>

      </v-card-actions>
     </v-dialog>

  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <AgregarProspecto     v-if="existeProspecto"             :parametrosProspectos="parametrosProspectos" @saveProspecto="saveProspecto"/>


	</v-card>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import AgregarProspecto   from '@/components/inscripciones/AgregarProspecto.vue';


  import validarErrores  from '@/mixins/validarErrores'

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
      AgregarProspecto
    },

    mixins: [ validarErrores ],

  	props:[
			'grupo',
			'alumno'
	  ],

    data: () => ({
    	escuela: 2,
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

			step:1,
			isTutor:true,

			opcion:1,

			hermanos:[{
				nombre:'',
		    apellido_paterno:'',
		    apellido_materno:'',
		    telefono:'',
		    celular:'',
		    id_tipo_alumno:'1',
		    id_usuario_ultimo_cambio:'0',
		    matricula:'',
		    edad:'',
		    ocupacion:'Estudiante',
		    id_contacto:0,
		    unidad_negocio:0,
		    id_prospecto:null,
		    id_empleado: null,
		    tutor: null,
		    matricula_erp:'',
		    iderp:0,
			}],

			posicionHermano: 0,

			empleados:[],
			sonHermanos:false,
			alumnoNuevo:{
				id_hermano: 0,
				nombre:'',
		    apellido_paterno:'',
		    apellido_materno:'',
		    telefono:'',
		    celular:'',
		    id_tipo_alumno:'1',
		    id_usuario_ultimo_cambio:'0',
		    matricula:'',
		    edad:'',
		    ocupacion:'Estudiante',
		    id_contacto:0,
		    unidad_negocio:0,
		    id_prospecto:null,
		    id_empleado: null,
		    tutor: null,
		    matricula_erp:'',
		    iderp:0,
			},

			alumnoVacio:{
				id_hermano: 0,
				nombre:'',
		    apellido_paterno:'',
		    apellido_materno:'',
		    telefono:'',
		    celular:'',
		    id_tipo_alumno:'1',
		    id_usuario_ultimo_cambio:'0',
		    matricula:'',
		    edad:'',
		    ocupacion:'Estudiante',
		    id_contacto:0,
		    unidad_negocio:0,
		    id_prospecto:null,
		    id_empleado: null,
		    tutor: null,
		    matricula_erp:'',
		    iderp:0,
			},

			tutor:{
				nombre:'',
				apellido_paterno:'',
				apellido_materno:'',
				telefono:'',
				celular:'',
				tipo_parentesco:0,
				id_usuario_ultimo_cambio:0
			},
			tutorVacio:{
				nombre:'',
				apellido_paterno:'',
				apellido_materno:'',
				telefono:'',
				celular:'',
				tipo_parentesco:0,
				id_usuario_ultimo_cambio:0
			},

			existeMatricula: false,
    	prospectos:[],
    	parametrosProspectos:{
    		estatus: false
    	},
    	existeProspecto:false,
    	prospecto: null,
    	prospectoSeleccionado: null,
    	alumnos:[],

    	alertaSeguridad: false

    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),
    },

    watch:{
    	prospectoSeleccionado( value){
    		if( value ){
    			console.log(value)
    			this.alumnoNuevo.folio        = value.matricula
    			this.alumnoNuevo.nombre       = value.alumno
    			this.alumnoNuevo.telefono     = value.telefono
    			this.alumnoNuevo.celular      = value.telefono
    			this.alumnoNuevo.id_contacto  = value.idprospectos
    			this.alumnoNuevo.id_prospecto = value.idprospectos
    		}
    	},

    	alumno( ){
  			this.alumnoNuevo = Object.assign({}, this.alumnoVacio)
  			this.tutor       = Object.assign({}, this.tutorVacio)

  			if( this.alumno && this.alumno.idprospectos ){
    			this.alumnoNuevo.nombre                   = this.alumno.alumno
		    	this.alumnoNuevo.apellido_paterno         = ''
		    	this.alumnoNuevo.apellido_materno         = ''
		    	this.alumnoNuevo.telefono                 = this.alumno.telefono
		    	this.alumnoNuevo.celular                  = this.alumno.telefono
		    	this.alumnoNuevo.id_tipo_alumno           = 1
		    	this.alumnoNuevo.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp
		    	this.alumnoNuevo.unidad_negocio           = this.getEscuela
		    	this.alumnoNuevo.id_prospecto             = this.alumno.idprospectos
    		}
    	},
    },

    async created () {
    	this.escuela = this.getEscuela
    	await this.getAlumnosListado()
    	await this.getProspectos()
    	await this.getAlumnos()
    },

    methods: {
    	getAlumnosListado( ){
    		this.cargar    = true
      	this.empleados = []

        return this.$http.get('usuarios.activos').then(response=>{
        	this.empleados = response.data
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
    	},

    	getProspectos( ){
    		this.cargar    = true
      	this.prospectos = []

        return this.$http.get('inscripciones.prospectos').then(response=>{
        	this.prospectos = response.data
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
    	},

      grabarDatos( ) {
      	this.cargar = true

      	if( !this.sonHermanos ){
	      	this.alumnoNuevo.tutor                    = this.isTutor ? this.tutor : null
	      	this.alumnoNuevo.unidad_negocio           = this.getEscuela
	      	this.alumnoNuevo.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp

	        return this.$http.post('alumnos.add', this.alumnoNuevo).then(response=>{
	        	this.alumnoNuevo = this.alumnoVacio
	        	this.tutor       = this.tutorVacio
	        	this.step        = 1
	        	this.$emit('retornarAlumno', response.data )
	        	this.validarSuccess('Alumno registrado correctamente')
	        	this.cargar = false
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
      	}else{
      		for( const i in this.hermanos ){
      			this.hermanos[i].unidad_negocio           = this.getEscuela
      			this.hermanos[i].id_usuario_ultimo_cambio = this.getdatosUsuario.iderp
      		}
      		const payload = {
      			hermanos: this.hermanos,
      			tutor:    this.tutor
      		}

	        return this.$http.post('alumnos.add.hermanos', payload).then(response=>{
	        	this.alumnoNuevo = this.alumnoVacio
	        	this.tutor       = this.tutorVacio
	        	this.step        = 1
	        	let objectHermanos  = Object.assign({}, this.alumnoVacio)
	        	let arrayHermanos   = [objectHermanos]
	        	this.hermanos       = arrayHermanos
	        	this.$emit('retornarAlumno', response.data[0] )
	        	this.validarSuccess('Alumno registrado correctamente')
	        	this.cargar = false
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
      	}
      },

      seleccionarAlumno( value ){
      	this.alumnoNuevo = this.alumnoVacio
        this.tutor       = this.tutorVacio

        let payload = {
      		grupo: value, 
      		hermanos: true
      	}

      	this.$emit('alumnoSeleccionado', payload )
      },

      returnHermano( ){
      	if( this.posicionHermano == 0 )
      		this.step = 1
      	else
      		this.posicionHermano -= 1
      },

      addHermano( ){
      	this.alumnoNuevo.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp

      	this.hermanos.push( this.alumnoNuevo )
      	this.posicionHermano += 1 
      	this.alumnoNuevo = Object.assign({}, this.alumnoVacio)
      },

      siguienteHermano( ){
      	this.posicionHermano += 1 
      	this.alumnoNuevo = Object.assign({}, this.hermanos[ this.posicionHermano ])
      },

      continuarProceso( ){
      	this.step = 3
      },


      buscarAlumno(  ){
      	const payload = {
      		matricula_erp: this.alumnoNuevo.matricula_erp
      	}

      	this.cargar = false

      	return this.$http.post('inscripciones.matricula', payload ).then(response=>{
        	this.alumnoNuevo.nombre           = response.data.nombre
          this.alumnoNuevo.apellido_paterno = response.data.apellido_paterno
          this.alumnoNuevo.apellido_materno = response.data.apellido_materno
          this.alumnoNuevo.edad             = response.data.edad
          this.alumnoNuevo.telefono         = response.data.telefono
          this.alumnoNuevo.celular          = response.data.celular
          this.alumnoNuevo.iderp            = response.data.id_alumno
          this.existeMatricula              = true
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      buscarHermanoMatricula( pistion ){
      	const payload = {
      		matricula_erp: this.hermanos[this.posicionHermano].matricula_erp
      	}

      	this.cargar = false

      	return this.$http.post('inscripciones.matricula', payload ).then(response=>{
        	this.hermanos[this.posicionHermano].nombre                   = response.data.nombre
          this.hermanos[this.posicionHermano].apellido_paterno         = response.data.apellido_paterno
          this.hermanos[this.posicionHermano].apellido_materno         = response.data.apellido_materno
          this.hermanos[this.posicionHermano].edad                     = response.data.edad
          this.hermanos[this.posicionHermano].telefono                 = response.data.telefono
          this.hermanos[this.posicionHermano].celular                  = response.data.celular
          this.hermanos[this.posicionHermano].iderp                    = response.data.id_alumno
	    		this.hermanos[this.posicionHermano].id_usuario_ultimo_cambio = this.getdatosUsuario.iderp
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      eliminarDatosAlumno( ){
      	this.cargar = true
				this.$http.post('matriculas.eliminar.sinpago' ).then(response=>{
        	this.step = 1
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      abrirDialogProspecto( ){
      	this.existeProspecto              = true
      	this.parametrosProspectos.estatus = true
      },

      saveProspecto( value ){
        this.alumnoNuevo.nombre                   = value.nombre_completo
	    	this.alumnoNuevo.apellido_paterno         = ''
	    	this.alumnoNuevo.apellido_materno         = ''
	    	this.alumnoNuevo.telefono                 = value.telefono
	    	this.alumnoNuevo.celular                  = value.telefono
	    	this.alumnoNuevo.id_tipo_alumno           = 1
	    	this.alumnoNuevo.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp
	    	this.alumnoNuevo.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp
	    	this.alumnoNuevo.unidad_negocio           = this.getEscuela
	    	this.alumnoNuevo.id_prospecto             = value.idprospectos
	    	this.existeMatricula                      = true
      },

      getAlumnos( ) {
      	this.cargar = true
      	this.alumnos = []
        return this.$http.get('inscripciones.alumnos').then(response=>{
        	this.cargar = false
        	this.alumnos = response.data

        	console.log( response.data[0] )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


    },
  }
</script>
