<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Reglamento de Escuela</span>
            <v-spacer></v-spacer>

            <v-btn
              class="mr-2"
              small
              dark
              color="green"
              @click="exportExcel(modalidades, 'MODALIDADES_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
           <v-row >
           <v-col cols="12" md="6" lg="5">
                <v-autocomplete
                    label="Curso"
                    id="idcurso"
                    filled
                    dense
                    hide-details
                    v-model="curso"
                    :items="[
                        { idcurso: 1, curso: 'FAST' },
                        { idcurso: 2, curso: 'INBI ADULTOS' },
                        { idcurso: 3, curso: 'INBI TEENS' },
                        { idcurso: 4, curso: 'INBI KIDS' }
                    ]"
                    item-text="curso"
                    item-value="idcurso"
                    clearable
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
            <v-text-field
                name="name"
                label="Buscar"
                id="id"
                filled
                dense
                hide-details
                append-icon="mdi-magnify"
                v-model="search"
            ></v-text-field>
            </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="filterReglas"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  hide-default-footer
                  dense
                >
                  <template v-slot:item.idcurso="{ item }">
                    <v-chip
                      small
                      color="colorinbi"
                      dark
                      v-if="item.idcurso == 3"
                      >INBI TEENS</v-chip
                    >
                    <v-chip
                      small
                      color="colorfast"
                      dark
                      v-if="item.idcurso == 4"
                      >INBI KIDS</v-chip
                    >
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      color="primary"
                      @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)" color="error">
                      mdi-delete
                    </v-icon>
                  </template>

                  <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize" small>
                      Actualizar
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.regla"
                  label="Regla"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                  :items="[
                        { idcurso: 1, curso: 'FAST' },
                        { idcurso: 2, curso: 'INBI ADULTOS' },
                        { idcurso: 3, curso: 'INBI TEENS' },
                        { idcurso: 4, curso: 'INBI KIDS' }
                    ]"
                    it
                  v-model="editedItem.idcurso"
                  label="Curso"
                  outlined
                  hide-details
                  dense
                  item-text="curso"
                  item-value="idcurso"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
          <v-btn color="blue darken-1" class="elevation-6" dark @click="save">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar la
                  regla?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      large
                      class="mr-2"
                      @click="closeDelete()"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      large
                      @click="deleteItemConfirm()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "ID", align: "start", value: "idreglamento" },
      { text: "Regla", value: "regla" },
      { text: "Curso", value: "idcurso" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    reglas: [],
    editedIndex: -1,
    editedItem: {
      idreglamento: '',
      regla: "",
      deleted:0,
      idcurso: 0,
    },
    defaultItem: {
      idreglamento: '',
      regla: "",
      deleted:0,
      idcurso: 0,
    },

    dialogSuccess: false,
    overlay: false,
    dialogError: false,
    error: "",
    successMensaje: "",
    errorBack: false,
    textoCopiado: "COPIAR",
    idcurso:0,

    cursos: [],
    curso: null,
    cursosEscuela: [],
    // tabla
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Agregar Regla" : "Editar Regla";
    },

     filterReglas( ){
      	if( this.curso ){
      		return this.reglas.filter( el=> { return el.idcurso == this.curso })
      	}else{
      		return this.reglas
        } 
      },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "editedItem.escuela"(val) {
      this.cursosEscuela = this.cursos.filter((el) => {
        return el.escuela == val;
      });
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.reglas = [];
      this.overlay = true;
      this.$http
        .get("reglamento.escuela.get.regla")
        .then((response) => {
          this.reglas = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    //   getCursos(){
    //   	this.cursos = []
    //     this.overlay = true
    //     this.$http.get('escuela.cursos.activos').then(response=>{
    //     	this.cursos = response.data
    //     	this.overlay = false
    //     }).catch(error=>{
    //     	INdicamos que hay un error en el back
    //     	if(error.body.message){
    //     		this.error = error.body.message
    //     	}else{
    //     		this.error = error.body
    //     	}
    //     	this.errorBack = true
    //     	this.overlay = false
    //     	this.dialogError = true
    //     })
    //   },

    editItem(item) {
      this.editedIndex = this.reglas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.reglas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // Lo mandapos por el EP
      this.editedItem.deleted = 1;
      this.$http
        .put(
          "reglamento.escuela.update/" + this.editedItem.idreglamento,
          this.editedItem
        )
        .then((response) => {
          this.dialogSuccess = true;
          this.overlay = false;
          this.closeDelete();
          this.initialize();
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedItem.regla == "") {
        this.error = "Favor de llenar todos los campos";
        this.errorBack = false;
        this.overlay = false;
        this.dialogError = true;
        return;
      }
      if (this.editedIndex > -1) {
        // Activamos el loader
        this.overlay = true;
        this.editedItem.deleted = 0;
        console.log(this.editedItem)
        // Lo mandapos por el EP
        this.$http
          .put(
            "reglamento.escuela.update/" + this.editedItem.idreglamento,
            this.editedItem
          )
          .then((response) => {
            this.dialogSuccess = true;
            this.initialize();
            this.overlay = false;
          })
          .catch((error) => {
            // INdicamos que hay un error en el back
            if (error.body.message) {
              this.error = error.body.message;
            } else {
              this.error = error.body;
            }
            this.errorBack = true;
            this.overlay = false;
            this.dialogError = true;
          });
      } else {
        // Activamos el loader
        this.overlay = true;
        // Lo mandapos por el EP
        this.$http
          .post("reglamento.escuela.add.regla", this.editedItem)
          .then((response) => {
            this.dialogSuccess = true;
            this.initialize();
            this.overlay = false;
          })
          .catch((error) => {
            // INdicamos que hay un error en el back
            if (error.body.message) {
              this.error = error.body.message;
            } else {
              this.error = error.body;
            }
            this.errorBack = true;
            this.overlay = false;
            this.dialogError = true;
          });
      }
      this.close();
    },

    copiarPortapapeles() {
      navigator.clipboard
        .writeText(this.error)
        .then(() => {
          this.textoCopiado = "COPIADO";
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });
    },
  },
};
</script>


