<template>
	<v-card class="transparent elevation-0">
    <v-card-text v-if="num_pagos > 0">

      <v-data-table
        :headers="headersDos"
        :items="filterCorte"
        class="shadowCard mb-4"
        dense
        :search="searchMovimientos"
        mobile-breakpoint="200"
        item-class="cambio"
      >

        <template v-slot:top>
          <v-card-title primary-title>
            <label class="text-subtitle-1 black--text">Movimientos | {{ num_pagos }} |</label>
            <v-spacer></v-spacer>
	          <v-text-field
	            filled
	            dense
	            append-icon="mdi-magnify"
	            label="Búscar"
	            v-model="searchMovimientos"
	          ></v-text-field>
          </v-card-title>
        </template>

        <template v-slot:item.monto_pagado="{ item }" v-if="[334, 28].includes( getdatosUsuario.iderp )">
          <!-- <span @click="dialogModificaMonto( item )"> -->
            {{ item.monto_pagado }}
          <!-- </span> -->
        </template>

        <template v-slot:item.aut_clave_rastreo="{ item }">
          <span 
            :class="item.aut_clave_rastreo ? '' : 'red--text' " 
            @click="movimiento = item, dialogAddFolio = true" 
            type="button" 
          >
            {{ item.aut_clave_rastreo ? item.aut_clave_rastreo : 'Click aquí' }}
          </span>
        </template>

        <template v-slot:item.folio_operacion="{ item }">
          <span 
            :class="item.folio_operacion ? '' : 'red--text' " 
            @click="movimiento = item, dialogAddFolio = true" 
            type="button" 
          >
            {{ item.folio_operacion ? item.folio_operacion : 'Click aquí' }}
          </span>
        </template>

        <template v-slot:item.cuenta="{ item }">
          <span 
            :class="item.cuenta ? '' : 'red--text' " 
            @click="movimiento = item, dialogAddFolio = true" 
            type="button" 
          >
            {{ item.cuenta ? item.cuenta : 'Click aquí' }}
          </span>
        </template>

        <template v-slot:item.url_foto="{ item }">
          <p @click="verImagen(item.url_foto)" type="button" v-if="item.forma_pago != 'EFECTIVO' ">
            <img :src="url_servidor + '/comprobante-pago/' + item.url_foto" alt="Comprobante" width="45"/>
          </p>
        </template>

        <template v-slot:item.con_adeudo="{ item }">
          <v-chip
            small
            color="primary"
            @click="abrirDialog(item.con_adeudoA)"
          >
            {{ item.con_adeudo }}
          </v-chip>
        </template>

      </v-data-table>

      <div class="mt-6"></div>
      <v-data-table
        :headers="headersTres"
        :items="cambios"
        class="shadowCard"
        dense
        :search="searchMovimientos"
        mobile-breakpoint="200"
        :items-per-page="cambios.length"
        hide-default-footer
        item-class="cambio"
        v-if="cambios.length"
      >

        <template v-slot:top>
          <v-card-title primary-title>
            <label class="text-subtitle-1 black--text">CAMBIOS</label>
            
            <v-spacer></v-spacer>
            
            <v-btn 
              color="primary"
              tile
              small
              @click="verReporteCambios()"
            >
              <v-icon left small>mdi-eye</v-icon>
              Ver reporte
            </v-btn>

          </v-card-title>
        </template>

        <template v-slot:item.url_foto="{ item }">
          <p @click="verImagen(item.url_foto)" type="button" v-if="item.forma_pago != 'EFECTIVO' ">
            <img :src="url_servidor + '/comprobante-pago/' + item.url_foto" alt="Comprobante" width="45"/>
          </p>
        </template>

        <template v-slot:item.con_adeudo="{ item }">
          <v-chip
            small
            color="primary"
            @click="abrirDialog(item.con_adeudoA)"
          >
            {{ item.con_adeudo }}
          </v-chip>
        </template>
      </v-data-table>

    </v-card-text>

    <!-- Dialog para ver la imagen -->
    <v-dialog
      v-model="dialgImagen"
      max-width="600px"
    >
      <v-card class="elevation-0">
        
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-img
              :src="url_servidor + '/comprobante-pago/' + imagen"
              contain
              max-width="600"
              height="800"
            >
            </v-img>
          </v-col>
        </v-card-text>
        <!-- Guardar la información  -->
        <v-btn
          absolute
          right
          top
          color="grey"
          dark
          fab
          class="elevation-6 mt-8"
          @click="dialgImagen = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn
        >
      </v-card>
    </v-dialog>

    <!-- Dialog para ver agregar datos de las tarjetas, folio, operación, cuenta -->
    <v-dialog
      v-model="dialogAddFolio"
      v-if="movimiento"
      max-width="350px"
    >
      <v-card class="elevation-0">
        <v-card-title primary-title class="text-subtitle-1">
          Agrgear Datos Bancarios
        </v-card-title>

        <v-card-text>
          <label>Aut. Clave Rastreo</label>
          <v-text-field
            filled
            dense
            single-line
            v-model="movimiento.aut_clave_rastreo"
          ></v-text-field>

          <label>Folio Oper.</label>
          <v-text-field
            filled
            dense
            single-line
            v-model="movimiento.folio_operacion"
          ></v-text-field>

          <label>Cuenta</label>
          <v-text-field
            filled
            dense
            single-line
            v-model="movimiento.cuenta"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          
          <!-- Guardar la información  -->
          <v-btn
            color="black"
            dark
            small
            tile
            @click="dialogAddFolio = false, movimiento = null"
          >
            <v-icon left small>mdi-close</v-icon>
            Cerrar
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            tile
            small
            @click="save()"
            color="green"
            dark
          >
            <v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>     

        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog para modificar monto pagado -->
    <v-dialog
      v-model="dialogEditPago"
      v-if="pago && pagocompleto"
      max-width="350px"
    >
      <v-card class="elevation-0">
        <v-card-title primary-title class="text-subtitle-1">
          Agrgear Datos Bancarios
        </v-card-title>

        <v-card-text>
          <label>Monto pagado</label>
          <v-text-field
            filled
            dense
            single-line
            v-model="pago.monto_pagado"
          ></v-text-field>

          <label>Nuevo monto.</label>
          <v-text-field
            filled
            dense
            single-line
            v-model="pago.nuevo_monto"
          ></v-text-field>

          <label>Pago anterior.</label>
          <v-text-field
            filled
            dense
            single-line
            v-model="pagoAnterior"
          ></v-text-field>

          <label>Total a pagar</label>
          <v-text-field
            filled
            dense
            single-line
            v-model="pagocompleto.precio_grupo_con_descuento_x_alumno"
          ></v-text-field>

          <label v-if="nuevoAdeudo >= 0">Nuevo Adeudo</label>
          <v-text-field
            v-if="nuevoAdeudo >= 0"
            filled
            dense
            single-line
            v-model="nuevoAdeudo"
          ></v-text-field>


          <v-alert
            dense
            outlined
            type="error"
            v-if="nuevoAdeudo < 0"
          >
            No puedes agregar un pago mayor al adeudo, el restante se debe ingresar en el siguiente grupo
          </v-alert>


        </v-card-text>

        <v-card-actions>
          
          <!-- Guardar la información  -->
          <v-btn
            color="black"
            dark
            small
            tile
            @click="dialogEditPago = false, pago = null"
          >
            <v-icon left small>mdi-close</v-icon>
            Cerrar
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            tile
            small
            @click="saveNuevoMonto()"
            color="green"
            dark
            v-if="nuevoAdeudo >= 0"
          >
            <v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>     

        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
    
  </v-card>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta              from '@/components/alertas/Alerta.vue'
  import carga               from '@/components/alertas/carga.vue';

  import validarErrores      from '@/mixins/validarErrores'
  import funcionesExcel      from '@/mixins/funcionesExcel'
  
	const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

  	props:[
			'num_pagos',
			'desglose',
			'cambios',
			'url_servidor',
			'tablaMovimientos',
      'plantel'
  	],

    data: () => ({
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      snack: false,
      snackColor: '',
      snackText: '',
      max25chars: v => v.length <= 25 || 'Input too long!',
      pagination: {},

      headersDos: [
	      { text: "id_ingreso"         , value: "id_ingreso"        },
	      { text: "Alumno"             , value: "alumno"            },
	      { text: "Monto pagado"       , value: "monto_pagado"      },
	      { text: "Fecha"              , value: "fecha_pago"        },
	      { text: "Forma pago"         , value: "forma_pago"        },
	      { text: "Registró"           , value: "nombre_completo"   },
	      { text: "Plantel"            , value: "plantel"           },
        { text: "Ciclo"              , value: "ciclo"             },
        { text: "Aut. Clave rastreo" , value: "aut_clave_rastreo" },
        { text: "Folio Oper."        , value: "folio_operacion"   },
	      { text: "Cuenta"             , value: "cuenta"            },
	      { text: "Comprobante"        , value: "url_foto"          }, //Angel Rodriguez
	    ],
			searchMovimientos: '',
			dialgImagen:false,
      imagen:null,

      headersTres: [
        { text: "id_ingreso"    , value: "id_ingreso"      },
        { text: "id_alumno"     , value: "id_alumno"      },
        { text: "Alumno"        , value: "alumno"          },
        { text: "Monto pagado"  , value: "monto_pagado"    },
        { text: "Viejo Plantel" , value: "viejoPlantel"    },
        { text: "Viejo Ciclo"   , value: "viejoCiclo"      },
        { text: "Nuevo Plantel" , value: "nuevoPlantel"    },
        { text: "Nuevo Ciclo"   , value: "nuevoCiclo"      },
      ],
      tipos_pago:[
        { id: 1, tipo: 'EFECTIVO'},
        { id: 2, tipo: 'DEPOSITO / TRANSFERENCIA'},
        { id: 4, tipo: 'TARJETA' },
        { id: 0, tipo: 'TODO' },
      ],

      formaPago: 0,
      ciclos: [],
      ciclo: null,
      fecha_inicio:new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      fecha_final:new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),

      movimiento: null,
      dialogAddFolio: false,
      aut_clave_rastreo:'',
      folio_operacion:'',
      cuenta:'',
      pago: null,
      dialogEditPago: false,

      pagocompleto: null,

    }),

    computed: {
      ...mapGetters("login", ["getdatosUsuario"]),

      filterCorte(){

        let data = this.tablaMovimientos

        // Filtro por forma de pago
        if( this.formaPago ){
          data = data.filter( el => { return el.id_forma_pago == this.formaPago })
        }

        // Filtro por ciclo
        if( this.ciclo ){
          data = data.filter( el => { return el.id_ciclo == this.ciclo })
        }

        return data 
      },

      nuevoAdeudo( ){

        if( this.pago ){

          let pagado = this.pago.nuevo_monto ? this.pago.nuevo_monto : 0

          const datoSinFormato = this.pago.monto_pagado.replace(/[^\d.-]/g, "");

          let pagoSinPagoActal = ( parseFloat( this.pagocompleto.pagado ) - parseFloat( datoSinFormato ) )


          return this.pagocompleto.precio_grupo_con_descuento_x_alumno - ( pagoSinPagoActal + parseFloat( pagado ) )


        }else{
          return 0
        }
      },

      pagoAnterior( ){
        if( this.pago ){

          const datoSinFormato = this.pago.monto_pagado.replace(/[^\d.-]/g, "");

          return ( parseFloat( this.pagocompleto.pagado ) - parseFloat( datoSinFormato ) )

        }else{
          return 0
        }
      }

    },

    async created() {

      await this.getCiclos()
    },

    methods: {

      verImagen(value) {            //Angel Rodriguez              
        this.imagen = value;
        this.dialgImagen = true;
      },

      getCiclos() {
        this.cargar = true;
        this.ciclos = [];
        return this.$http
          .get("ciclos.activos.erp")
          .then((response) => {
            this.ciclos = response.data;
            this.cargar = false;
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      },

      dialogModificaMonto( item ){
        this.pago = item 
        console.log( item )

        const payload = {
          id_alumno: item.id_alumno,
          id_grupo:  item.id_grupo
        }

        this.$http.post("pago.completo" , payload ).then((response) => {
          this.pagocompleto = response.data;
          console.log( this.pagocompleto )
          this.cargar = false;
          this.dialogEditPago = true
        }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });

      },

      saveNuevoMonto( ){

        let pagado = this.pago.nuevo_monto ? this.pago.nuevo_monto : 0

        const datoSinFormato = this.pago.monto_pagado.replace(/[^\d.-]/g, "");

        let pagoSinPagoActal = ( parseFloat( this.pagocompleto.pagado ) - parseFloat( datoSinFormato ) )

        const payload = {
          id_carga: this.pagocompleto.id,
          pagado  : parseFloat( pagoSinPagoActal ) + parseFloat( pagado ),
          adeudo  : this.nuevoAdeudo > 0 ? this.nuevoAdeudo : 0,

          id_ingreso: this.pago.id_ingreso,
          monto_pagado: parseFloat( this.pago.nuevo_monto )
        }

        return this.$http.post("actualizar.monto.pagado", payload ).then((response) => {

          this.dialogEditPago = false
          this.pago = null
          this.pagocompleto = null
          this.validarSuccess(response.data.message)

          this.cargar = false;

        }).catch((error) => {

          this.validarError(error);

        }).finally(() => {

          this.cargar = false;

        });

      },


      // consultar(){
      //   this.cargar = true;

      //   const payload = {
      //     id_ciclo: this.ciclo,
      //     fecha_inicio: this.fecha_inicio,
      //     fecha_final: this.fecha_final,
      //   }

      //   return this.$http.post("movimientos.corte", payload ).then((response) => {

      //     this.tablaMovimientos = response.data;
      //     this.cargar = false;

      //   }).catch((error) => {

      //     this.validarError(error);

      //   }).finally(() => {

      //     this.cargar = false;

      //   });
      // },

      save ( data ) {
        this.cargar = true;

        const payload = {
          id_ingreso:        this.movimiento.id_ingreso,
          aut_clave_rastreo: this.movimiento.aut_clave_rastreo,
          folio_operacion:   this.movimiento.folio_operacion,
          cuenta:            this.movimiento.cuenta,
        }

        return this.$http.post("ingreso.clave.rastreo", payload ).then((response) => {

          this.movimiento = null
          this.dialogAddFolio = false
          this.validarSuccess(response.data.message)

          this.$emit('consultar')

          this.cargar = false;

        }).catch((error) => {

          this.validarError(error);

        }).finally(() => {

          this.cargar = false;

        });
      },
    }
  };
</script>


