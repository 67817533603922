<template>
    <v-dialog persistent v-model="isOpen" @click:outside="closeDialog" @keydown.esc="closeDialog" min-width="600px">
      <v-card class="elevation-0">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="closeDialog" ><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-img v-if="!video"
          :src="(respuestaImagen ? url : ((ticket.tipousuario ? (ticket.id_unidad_negocio==1 ? urlINBI : urlFAST) : 
            ticket.movil ? (ticket.id_unidad_negocio==1 ? urlINBI : urlFAST) : url)) )+ imagen" 
            contain
            max-height="80vh"
          >
          </v-img>
          <video id="video" 
			    	v-if="video"
      			:src="(respuestaImagen ? url : ((ticket.tipousuario ? (ticket.id_unidad_negocio==1 ? urlINBI : urlFAST) : 
            ticket.movil ? (ticket.id_unidad_negocio==1 ? urlINBI : urlFAST) : url)) )+ imagen" 
	    			frameborder="0" 
	    			allowfullscreen
	    			controls
	    			allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
	         	style="width: 100%; height: 100%; background: #FFFFFF;" allowtransparency="true">
	        </video>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
    import axios from 'axios';
    export default {
      props: [
        'isOpen',
        'ticket',
        'imagen',
        'respuestaImagen'
      ],

      watch:{
        isOpen(){
          // si el dialogo Imagen se abre
          if(this.isOpen){
            const nombreSplit = this.imagen.split('.')
            const extension = nombreSplit[nombreSplit.length - 1]
            if(['mp4', 'mov', 'wmv', 'avi'].includes(extension)){
              this.video = true
            }
          } else {
            if(this.video){this.video =false}
          }
        }
      },


      data: () => ({
        url:'',
        urlINBI:'',
        urlFAST:'',
        video: false
      }),

      created () {
        this.url = axios.defaults.baseURL + 'evidencia-tickets/'
        this.urlINBI = 'https://lmsinbi.club/evidencia-tickets/'
        this.urlFAST = 'https://lmsfast.club/evidencia-tickets/'
      },
      methods:{
        closeDialog(){
          this.$emit('close');  
        }
      }
    }
</script>