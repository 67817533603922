<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-text class="pa-0">

          <div class="black--text text-subtitle-1">
            <b>Reporte generado por:</b> {{ getdatosUsuario.nombre_completo }}
            <br/>
            <v-chip>
              <b>Tipo de reporte: </b> Cambios multiples
            </v-chip>
            <br/>
          </div>

          <v-checkbox
            v-model="checkGrupo"
            label="Cambio de grupo"
            hide-details
          ></v-checkbox>

          <v-checkbox
            v-model="checkMetodo"
            label="Cambio de metódo de pago"
            hide-details
          ></v-checkbox>

          <v-checkbox
            v-model="checkMonto"
            label="Cambio de monto pagado"
            hide-details
          ></v-checkbox>

          <v-checkbox
            v-model="checkComprobante"
            label="Cambio de comprobante"
            hide-details
          ></v-checkbox>

          <br/>
          <br/>

          <v-row v-if="alumno">
            <v-col cols="12" class="pa-0">
              <v-card-text>
                <b>Matricula:</b> {{ alumno.matricula }}
                <br/>
                <b>Nombre:</b>    {{ alumno.alumno }}
                <br/>
                <br/>

                <v-divider v-if="checkGrupo"></v-divider>
                <label v-if="checkGrupo"><b>CAMBIO DE GRUPO</b></label>
                <v-row v-if="checkGrupo">
                  <v-col cols="12" md="6" >
                    <v-autocomplete
                      class="mt-2"
                      filled
                      dense
                      clearable
                      v-model="grupoAnterior"
                      :items="grupoAlumnos"
                      label="Grupo actual"
                      persistent-hint
                      prepend-inner-icon="mdi-clipboard-outline"
                      return-object
                      item-text="grupo"
                      item-value="id_grupo"

                    >
                    </v-autocomplete>

                    <label v-if="grupoAnterior"><b>{{ grupoAnterior.grupo }}</b></label>
                    <br/>

                    <div v-if="grupoAnterior" class="mb-10">
                      <v-row>
                        <v-col cols="6" class="text-right pb-0">
                          <b>Costo curso:</b>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          {{ grupoAnterior.precio_grupo }}
                        </v-col>

                        <v-col cols="6" class="text-right pb-0">
                          <b>Pagado:</b>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          {{ grupoAnterior.pagado }}
                        </v-col>

                        <v-col cols="12">
                          <b>Concentrado de cambios</b>
                          <br/>
                          <p 
                            style="white-space: pre-line"
                          >
                            {{ textoCambio }}
                          </p>

                        </v-col>
                      </v-row>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6" >
                    <v-autocomplete
                      class="mt-2"
                      filled
                      dense
                      clearable
                      v-model="nuevoGrupo"
                      :items="grupos"
                      label="Nuevo Grupo"
                      persistent-hint
                      prepend-inner-icon="mdi-clipboard-outline"
                      return-object
                      item-text="grupo"
                      item-value="id_grupo"
                    >
                    </v-autocomplete>
                    
                    <label v-if="nuevoGrupo"><b>{{ nuevoGrupo.grupo }}</b></label>
                    <br/>
                    
                    <div v-if="nuevoGrupo" class="mb-10">
                      <v-row>
                        <v-col cols="6" class="text-right pb-0">
                          <b>Costo curso:</b>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          {{ costoCurso }}
                        </v-col>

                        <v-col cols="6" class="text-right pb-0">
                          <b>Total a pagar:</b>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          {{ total_a_pagar }}
                        </v-col>

                        <v-col cols="6" class="text-right pb-0" v-if="grupoAnterior.pagado - total_a_pagar > 0">
                          <b>Saldo a Favor:</b>
                        </v-col>
                        <v-col cols="6" class="pb-0" v-if="grupoAnterior.pagado - total_a_pagar > 0">
                          {{ grupoAnterior.pagado - total_a_pagar }} 
                        </v-col>

                        <v-col cols="12" class="pb-0">
                          <v-autocomplete
                            v-model="precioSeleccionado"
                            :items="precios"
                            @change="getPrecioPagar"
                            item-text="curso"
                            item-value="id_curso"
                            filled
                            dense
                          ></v-autocomplete>
                        </v-col>


                        <v-autocomplete
                          v-if="grupoAnterior.pagado - total_a_pagar > 0"
                          class="mt-2"
                          filled
                          dense
                          clearable
                          v-model="id_grupo_favor"
                          :items="grupos"
                          label="Grupo a mandar el saldo a favor"
                          persistent-hint
                          prepend-inner-icon="mdi-clipboard-outline"
                          item-text="grupo"
                          item-value="id_grupo"
                        >
                        </v-autocomplete>
                      </v-row>
                    </div>
                  </v-col>
                  <br/>


                  <v-alert
                    dense
                    border="left"
                    type="warning"
                    class="mt-2"
                  >
                    Recuerda que al hacer un  <strong>CAMBIO</strong> de grupo, al alumno se le  <strong>BORRARÁN</strong> los datos, como lo son: <strong>ASISTENCIAS, EJERCICIOS y EXÁMENES</strong>
                  </v-alert>
                </v-row>


                <v-divider v-if="checkMetodo || checkMonto || checkComprobante"></v-divider>
                <label v-if="checkMetodo && pago"><b>CAMBIO DE METODO DE PAGO</b></label>
                <!-- TABLA DE MOVIMIENTOS -->
                <v-row  v-if="checkMetodo || checkMonto || checkComprobante">
                  <v-col cols="12">
                    <v-data-table
                      :headers="headers"
                      :items="movimientos"
                      class="elevation-0"
                      :search="search"
                      :mobile-breakpoint="100"
                      dense
                      id="grupos"
                      item-class="clase_row"
                      single-select
                      v-model="selected"
                      item-key="id_ingreso"
                      show-select
                    >
                      <!-- Valores que ocupan botons, chips, iconos, etc -->
                      <template v-slot:item.baja="{ item }">
                        <v-chip 
                          small
                          :color="item.baja == 1 ? 'green': 'red'"
                          dark
                        >{{ item.baja == 1 ? 'SI': 'NO'  }}</v-chip>
                      </template>

                      <template v-slot:no-data>
                        <v-btn
                          color="primary"
                          @click="getMovimientos"
                          small
                        >
                          Actualizar
                        </v-btn>
                      </template>

                    </v-data-table>
                  </v-col>
                </v-row>


                <v-divider v-if="checkMetodo"></v-divider>
                <v-row v-if="pago && checkMetodo">
                  <v-col cols="12" md="6">
                    <b>Método actual</b>
                    <v-select
                      :items="tipos_pago"
                      v-model="viejo_metodo"
                      label="Forma de pago"
                      filled
                      dense
                      single-line
                      item-text="tipo"
                      item-value="id"
                      hide-details
                      readonly
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="6">
                    <b>Nuevo Método</b>
                    <v-select
                      :items="tipos_pago2"
                      v-model="nuevo_metodo"
                      label="Forma de pago"
                      filled
                      dense
                      single-line
                      item-text="tipo"
                      item-value="id"
                    ></v-select>


                    <v-alert
                      dense
                      border="left"
                      type="info"
                      class="mt-2"
                      v-if="[2,4].includes( nuevo_metodo )"
                    >
                      Si deseas reemplazar el comprobante, selecciona uno nuevo
                    </v-alert>
                    
                    <v-file-input
                      v-if="[2,4].includes( nuevo_metodo )" 
                      small-chips 
                      multiple 
                      filled 
                      v-model="files" 
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Selecciona tus imagenes" prepend-icon="mdi-camera" label="Nuevo Comprobante" @change="cargarFotos()">
                    </v-file-input>

                    <v-row v-if="vistaPrevias.length">
                      <v-col cols="12" v-for="(img, i) in vistaPrevias" :key="i">
                        <v-card class="py-4 shadowCard">
                          <v-img :src="img.url" contain aspect-ratio="2"></v-img>
                          <v-btn  color="error"  small  @click="eliminarFoto(img.url)" top right absolute fab >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-card>
                      </v-col>
                    </v-row>
                    
                  </v-col>
                </v-row>

                <v-divider v-if="checkMonto"></v-divider>
                <label v-if="checkMonto && pago"><b>CAMBIO DE MONTO</b></label>
                <v-row v-if="pago && checkMonto">
                  <v-col cols="12" md="6">
                    <label>Monto pagado</label>
                    <v-text-field
                      filled
                      dense
                      single-line
                      v-model="pago.pagado"
                      readonly
                    ></v-text-field>

                    <v-alert
                      dense
                      outlined
                      type="error"
                      v-if="nuevoAdeudo < 0"
                    >
                      No puedes agregar un pago mayor al adeudo, el restante se debe ingresar en el siguiente grupo
                    </v-alert>
                  </v-col>

                  <v-col cols="12" md="6">

                    <label>Nuevo monto.</label>
                    <v-text-field
                      filled
                      dense
                      single-line
                      v-model="pago.nuevo_monto"
                    ></v-text-field>
                    
                  </v-col>
                </v-row>

                <label v-if="checkComprobante && pago"><b>CAMBIO DE COMPROBANTE</b></label>
                <v-row v-if="pago && checkComprobante">
                  <v-col cols="12" md="6">
                    <b>Comprante actual</b>
                    <v-card class="py-4 shadowCard">
                      <v-img :src="url2 + comprobanteOficial" contain aspect-ratio="2"></v-img>
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-alert
                      dense
                      border="left"
                      type="info"
                      class="mt-2"
                      v-if="!vistaPrevias3.length"
                    >
                      Selecciona la nueva imagen
                    </v-alert>

                    <v-file-input 
                      small-chips 
                      multiple 
                      filled 
                      v-model="files3" 
                      v-if="!vistaPrevias3.length"
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Selecciona tus imagenes" prepend-icon="mdi-camera" label="Seleccionar el nuevo comprobante" @change="cargarFotos3()">
                    </v-file-input>


                    <!--  -->
                    <v-row v-if="vistaPrevias3.length">
                      <b>Nuevo Comprobante</b>
                      <v-col cols="12" v-for="(img, i) in vistaPrevias3" :key="i">
                        <v-card class="py-4 shadowCard">
                          <v-img :src="img.url" contain aspect-ratio="2"></v-img>
                          <v-btn  color="error"  small  @click="eliminarFoto3(img.url)" top right absolute fab >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row v-if="checkGrupo || checkMetodo || checkMonto || checkComprobante">
                  <v-col cols="12" md="6">
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-textarea
                      label="Por favor, ingresa el motivo de tu solicitud"
                      v-model="motivo"
                      filled
                    ></v-textarea>

                    <v-file-input 
                      small-chips 
                      multiple 
                      filled 
                      v-model="files2" 
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Selecciona tus imagenes" prepend-icon="mdi-camera" label="Evidencias" @change="cargarFotos2()">
                    </v-file-input>

                    <v-row v-if="vistaPrevias2.length">
                      <v-col cols="12" md="6" v-for="(img, i) in vistaPrevias2" :key="i">
                        <v-card class="py-4 shadowCard">
                          <v-img :src="img.url" contain aspect-ratio="2"></v-img>
                          <v-btn  color="error"  small  @click="eliminarFoto2(img.url)" top right absolute fab >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-card>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>

              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="nuevoGrupo && grupoAnterior && motivo"
                  color="green"
                  dark
                  tile
                  @click="confirmacion.estatus = true"
                  :loading="loading"
                  :disabled="disabled" 
                >
                  <v-icon small left>mdi-send</v-icon>
                  Enviar solicitud
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>

    <dialogConfirmacion   
      v-if="confirmacion.estatus"        
      :confirmacion="confirmacion"
      @addCambio="addCambio"
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  var moment = require('moment');
  moment.locale();
  import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';


  import dialogConfirmacion    from '@/components/cambios/dialogConfirmacion.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    components:{
      Alerta,
      carga,
      dialogConfirmacion
    },

    mixins: [ validarErrores, funcionesExcel ],

    props: ['alumno','reporteTexto', 'tipo_reporte'],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search: '',
      
      tipociclo:'',
      grupos:[],
      nuevoGrupo:null,
      tipocambios:[],
      tipoCambio:null,
      alumnos:[],

      grupoAlumnos:   [],
      grupoAnterior:  null,
      
      loading:        false,
      disabled:       false,
      motivo:         null,

      total_a_pagar: 0,
      precios:[],

      dataGrupoActual: null,
      dataNuevoGrupo:null,
      precioSeleccionado2: null,
      descuentoHermanos:0,

      url:'',

      fotos:[],
      files: [],
      vistaPrevias:[],

      textoCambio: "",

      costoCurso: 0,
      id_grupo_favor:0,
      confirmacion: {
        estatus: false
      },

      checkGrupo: null,
      checkMetodo: null,
      checkMonto: null,
      checkComprobante: null,

      search: '',
      
      tipociclo:'',
      grupos:[],
      nuevoGrupo:null,
      tipocambios:[],
      tipoCambio:null,
      alumnos:[],

      grupoAlumnos:   [],
      grupoAnterior:  null,
      
      loading:        false,
      disabled:       false,
      motivo:         null,

      total_a_pagar: 0,
      precios:[],

      dataGrupoActual: null,
      dataNuevoGrupo:null,
      precioSeleccionado2: null,
      descuentoHermanos:0,

      movimientos:[],

      viejo_metodo: 0,
      nuevo_metodo: 0,

      url:'',
      url2:'',

      fotos:[],
      files: [],
      files2:[],
      files3:[],
      vistaPrevias:[],
      vistaPrevias2:[],
      vistaPrevias3:[],

      selected:[],


      headers: [
        { text: 'id_ingreso'    , value: 'id_ingreso'      },
        { text: 'Grupo'         , value: 'grupo'           },
        { text: 'Monto pagado'  , value: 'pagado'          },
        { text: 'Fecha'         , value: 'fecha_alta'      },
        { text: 'Forma pago'    , value: 'forma_pago'      },
      ],

      pago: null,

      comprobanteOficial:'',

      tipos_pago:[
        { id: 1, tipo: 'EFECTIVO'},
        { id: 2, tipo: 'DEPOSITO / TRANSFERENCIA'},
        { id: 4, tipo: 'TARJETA' },
      ],


      confirmacion: {
        estatus: false
      }
    }),

    watch:{
      grupoAnterior () {
        if(this.grupoAnterior){
          this.getNuevoGrupoAlumno ()
        }else{
          this.grupos = []
        }
      },

      selected( value ){
        if( value.length ){ 
          this.pago = value[0]
          this.viejo_metodo = this.pago.id_forma_pago
          this.comprobanteOficial = this.pago.url_foto
        }
      },

      nuevoGrupo( value ){
        console.log( value, this.grupoAnterior )
        this.precioSeleccionado = value ? value.id_curso : null

        this.textoCambio = ''

        if( value && value.id_plantel != this.grupoAnterior.id_plantel ){
          this.textoCambio = 'Cambio de Plantel'
        }

        if( value && value.id_ciclo != this.grupoAnterior.id_ciclo ){
          this.textoCambio += `
          Cambio de Ciclo`
        }

        if( value && value.id_horario != this.grupoAnterior.id_horario ){
          this.textoCambio += `
          Cambio de Horario`
        }

        if( value && value.id_nivel != this.grupoAnterior.id_nivel ){
          this.textoCambio += `
          Cambio de Nivel`
        }

        if( value ){
          this.getPrecioPagar()
        }



      },

      dataNuevoGrupo( value ){
        this.precioSeleccionado = value ? value.id_curso : null
      },

      // precioSeleccionado( value ){
        // if( value ){ this.getPrecioPagar() }
      // },

      precioSeleccionado2( value ){
        if( value ){ this.getPrecioPagar2() }
      },

    },

    async created () {
      if([1,2,3,4,5,6,17,19,21,22,23,24,25,26].includes( this.getdatosUsuario.id_plantel ) ){
        this.tipociclo = `grupo NOT LIKE '%FE%'`
      }else{ this.tipociclo = `grupo LIKE '%FE%'` }

      this.url2 = axios.defaults.baseURL + 'comprobante-pago/'
      this.url = axios.defaults.baseURL + 'evidencia-cambios/'
      
      // Cargar los cursos actuales
      await this.getCursos()

      await this.getGrupoUsuario( )

      await this.getTitpoCambios()

      await this.getMovimientos()

    },

    methods: {
      getCursos( ){
        this.cargar = true
        this.precios = []
        this.$http.get("all.precios").then((response) => {
          this.cargar = false
          this.precios = response.data.filter( el => el.curso.split("-")[1].match("3") );
        }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });
      },

      getMovimientos () {
        this.movimientos = []
        return this.$http.get('inscripciones.alumno.grupos.activos/' + this.alumno.id_alumno).then(response=>{
          this.movimientos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getTitpoCambios () {
        this.cargar = true
        return this.$http.get('cambios.tipo.cambio').then(response=>{
          this.tipocambios = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPrecioPagar( ) {
        this.total_a_pagar = 0
        const payload = {
          id_alumno: this.alumno.id_alumno,
          id_grupo:  this.alumno.id_grupo,
          escuela:   this.alumno.grupo.match('FAST') ? 2 : 1,
          id_curso:  this.precioSeleccionado,
          id_ciclo:  this.alumno.id_ciclo,
          alumno_ni: false
        }
        return this.$http.post('pago.grupo.nuevo.descuento', payload).then(response=>{

          if( this.precioSeleccionado ){
            this.total_a_pagar = response.data[0].precio_lista

            let existeCosto = this.precios.find( el => el.id_curso == this.precioSeleccionado )

            this.costoCurso = existeCosto ? existeCosto.precio_lista : 0

          }else{
            this.costoCurso = 0
          }

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPrecioPagar2( ) {

        const payload = {
          id_alumno: this.alumno.id_alumno,
          id_grupo:  this.dataGrupoActual.id_grupo,
          escuela:   this.dataNuevoGrupo.grupo.match('FAST') ? 2 : 1,
          id_curso:  this.precioSeleccionado2,
          id_ciclo:  this.dataNuevoGrupo.id_ciclo,
          alumno_ni: false
        }

        return this.$http.post('pago.grupo.nuevo.descuento', payload).then(response=>{

          this.total_a_pagar      = response.data[0].precio_lista
          this.descuentoHermanos  = response.data[0].descuentoHermanos

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getNuevoGrupoAlumno () {
        if(this.grupoAnterior){
          this.cargar = true
          const payload =  { tipociclo: this.tipociclo, id_ciclo: this.grupoAnterior.id_ciclo }
          return this.$http.post('cambios.nuevo.grupos.ciclo',payload).then(response=>{
            this.grupos = response.data
            console.log( this.grupos )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }
      },

      getGrupoUsuario ( ){
        this.cargar = true
        this.grupoAlumnos = []
        return this.$http.get('cambios.grupo.usuario/' + this.alumno.id_alumno).then(response=>{
          this.grupoAlumnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async addCambio () {

        // Validar que esten llenos los campos
        if(this.nuevoGrupo && this.grupoAnterior && this.motivo ){

          this.cargar = true

          let nuevo_comprobante = []
          if(this.vistaPrevias3.length){
            let respuesta = await this.subirFotosServidor3( )
            nuevo_comprobante = respuesta.data
          }
          
          let fotos = []
          if(this.vistaPrevias2.length){
            let respuesta = await this.subirFotosServidor2( )
            fotos = respuesta.data
          }

          // Activar el disabled del boton para que no le den doble click
          // this.loading  = true
          // this.disabled = true
          // Desesestructuración de alumno 
          const { id_alumno, id_plantel, monto_pagado_total } = this.alumno

          // Generar el payload a mandar
          let payload = {
            id_alumno,
            idtipo_cambio:   0,
            solicito_cambio: this.getdatosUsuario.iderp,
            id_plantel,
            id_grupo_actual    : this.checkGrupo ? this.grupoAnterior.id_grupo : 0,
            id_grupo_nuevo     : this.checkGrupo ? this.nuevoGrupo.id_grupo    : 0,
            estatus            : 1,
            diferencia         : monto_pagado_total,
            motivo             : this.motivo,
            fotos,
            tipo_reporte       : this.tipo_reporte,
            id_ingreso         : this.pago.id_ingreso,
            nuevo_comprobante  : nuevo_comprobante[0],
            viejo_metodo       : this.checkMetodo ? this.viejo_metodo : 0,
            nuevo_metodo       : this.checkMetodo ? this.nuevo_metodo : 0,
            viejo_monto        : this.checkMonto  ? this.pago.pagado      : 0,
            nuevo_monto        : this.checkMonto  ? this.pago.nuevo_monto : 0,
            vieja_fecha        : null,
            nueva_fecha        : null,
            textoCambio        : this.textoCambio,
            id_grupo_favor     : this.id_grupo_favor ? this.id_grupo_favor : 0
          }

          this.cargar = true

          // Enviar los datos 
          this.$http.post('cambios.add',payload).then(response=>{

            this.validarSuccess( 'Datos grabados correctamente')

            this.$emit('getAlumnosxEscuela')

          }).catch(error=>{
            this.validarError( error.response.data.message )
          })
        }else{
          this.estatusError('Favor de ingresar el grupo y el tipo de cambio')
        }
      },

      subirFotosServidor( ){
        let formData = new FormData();

        for( const i in this.vistaPrevias ){
          formData.append('file', this.vistaPrevias[i].file )
        }

        return new Promise((resolve,reject)=>{
          // obtener la extensión del archivo
          // Hacemos la petición
          this.$http.post(`cambios.ingreso.foto`, formData ).then(response=>{
            resolve( response )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },

      subirFotosServidor2( ){
        let formData = new FormData();

        for( const i in this.vistaPrevias2 ){
          formData.append('file', this.vistaPrevias2[i].file )
        }

        return new Promise((resolve,reject)=>{
          // obtener la extensión del archivo
          // Hacemos la petición
          this.$http.post(`cambios.ingreso.foto`, formData ).then(response=>{
            resolve( response )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },

      subirFotosServidor3( ){
        let formData = new FormData();

        for( const i in this.vistaPrevias3 ){
          formData.append('file', this.vistaPrevias3[i].file )
        }

        return new Promise((resolve,reject)=>{
          // obtener la extensión del archivo
          // Hacemos la petición
          this.$http.post(`cambios.ingreso.foto`, formData ).then(response=>{
            resolve( response )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },

      cargarFotos(){
        if(this.files.length < 1){
          return
        }

        this.files.forEach((element, index) => {
                // creamos una variable tipo FormData
          let formData = new FormData();
                //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', element);
                //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          this.getBase64(element, formData)
        })
      },

      cargarFotos2(){
        if(this.files2.length < 1){
          return
        }

        this.files2.forEach((element, index) => {
                // creamos una variable tipo FormData
          let formData = new FormData();
                //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', element);
                //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          this.getBase642(element, formData)
        })
      },

      cargarFotos3(){
        if(this.files3.length < 1){
          return
        }

        this.files3.forEach((element, index) => {
                // creamos una variable tipo FormData
          let formData = new FormData();
                //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', element);
                //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          this.getBase643(element, formData)
        })
      },

      getBase64(file, formData) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          me.vistaPrevias.push({
            url: reader.result, 
            formData: formData, 
            image_name: file.name,
            file
          })
        };
      },

      getBase642(file, formData) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          me.vistaPrevias2.push({
            url: reader.result, 
            formData: formData, 
            image_name: file.name,
            file
          })
        };
      },

      getBase643(file, formData) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          me.vistaPrevias3.push({
            url: reader.result, 
            formData: formData, 
            image_name: file.name,
            file
          })
        };
      },

      eliminarFoto(value){
        this.vistaPrevias.forEach((element, index)=>{
          if(element.url == value){
            this.vistaPrevias.splice(index,1);
          }
        })
      },

      eliminarFoto2(value){
        this.vistaPrevias2.forEach((element, index)=>{
          if(element.url == value){
            this.vistaPrevias.splice(index,1);
          }
        })
      },

      eliminarFoto3(value){
        this.vistaPrevias3.forEach((element, index)=>{
          if(element.url == value){
            this.vistaPrevias.splice(index,1);
          }
        })
      },
    },

    computed:{
      ...mapGetters ('login',['getdatosUsuario']),

      currentTitle () {
        switch (this.step) {
          case 1: return 'Selecciona el alumno'
          case 2: return 'Selecciona tipo de reporte'
          default: return 'Confirma tu reporte'
        }
      },

      tipos_pago2( ){
        return this.tipos_pago.filter( el => el.id != this.viejo_metodo )
      },

      filterAlumnos( ){

        const alumnos = this.movimientos

        if( this.plantel ){
          return alumnos.filter( el=> { return el.id_plantel == this.plantel })
        }

        if( this.step == 3 ){
          return this.selected
        }
        
        return alumnos
      },

      nuevoAdeudo( ){

        if( this.pago ){

          let pagado = this.pago.nuevo_monto ? this.pago.nuevo_monto : 0

          const datoSinFormato = this.pago.pagado

          let pagoSinPagoActal = ( parseFloat( this.pago.pagado ) - parseFloat( datoSinFormato ) )


          return this.pago.pago_oficial - ( pagoSinPagoActal + parseFloat( pagado ) )


        }else{
          return 0
        }
      },

      pagoAnterior( ){
        if( this.pago ){

          const datoSinFormato = this.pago.pagado

          return ( parseFloat( this.pago.pagado ) - parseFloat( datoSinFormato ) )

        }else{
          return 0
        }
      }
    }
  }
</script>

<style>

  .v-menu__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-menu__content::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-button:increment,.v-menu__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-menu__content::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>