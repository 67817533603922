<template>
	<v-dialog
    v-model="parametros.estatus"
    hide-overlay
    persistent
    max-width="800"
  >
    <v-card>
      <v-card-title primary-title >
        Prospectos buenos
      </v-card-title>
      <v-card-text >

      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
          <v-text-field
            v-model="search"
            dense
            rounded
            filled
            label="Buscar"
            append-icon="mdi-magnify"
          ></v-text-field>  
        </v-col>

        <v-data-table
          :headers="headersCerrados"
          :items="buenos"
          class="elevation-0"
          dense
          :search="search"
        >
        <template v-slot:item.actions="{ item }">
            <v-icon
              small
              @click="activarProspectoCerrado(item.idprospectos)"
              color="success"
            >
              mdi-check-outline
            </v-icon>
          </template> 
                    
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error"   dark @click="parametros.estatus = false">Cancelar</v-btn>
      </v-card-actions>
      <v-btn 
        color="transparent elevation-0"
        x-small
        absolute
        top
        right
        fab
        class="mt-5 mr-0"
        @click="parametros.estatus = false"
       ><v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
	export default {
		props:[
			'parametros',
			'buenos'
	  ],

    data: () => ({
      headersCerrados: [
        { text: 'Folio',              value: 'folio' },
			  { text: 'Nombre',             value: 'nombre_completo', },
			  { text: 'Etapa',              value: 'idetapa' },
			  { text: 'Fecha',              value: 'fecha_creacion' },
			  { text: 'De',                 value: 'recluta' },
			  { text: 'Para',               value: 'vendedora' },
      ],
      search:'',
    }),
	}
</script>
