<template>
	<v-chip small :color="getColor( idetapa )" dark hide-details class="mr-1">{{ getTexto( idetapa ) }} </v-chip>
</template>

<script>
import axios from 'axios';
	export default {
		props:[
			'idetapa'
	  ],

	  methods: {
	  	getColor ( ){
	  		switch ( this.idetapa ){
	  			case 1:
	  				return 'red'
	  			break;

	  			case 2:
	  				return 'green'
	  			break;

	  			case 3:
	  				return 'pink'
	  			break;

	  			case 4:
	  				return 'orange'
	  			break;

	  			case 5:
	  				return 'brown'
	  			break;

	  		}
	  	},

	  	getTexto ( ){
	  		switch ( this.idetapa ){
	  			case 1:
	  				return 'Atrasado'
	  			break;

	  			case 2:
	  				return 'Nuevo'
	  			break;

	  			case 3:
	  				return 'Inducción'
	  			break;

	  			case 4:
	  				return 'Seg. con Info'
	  			break;

	  			case 5:
	  				return 'Seg. sin Info'
	  			break;

	  		}
	  	}
	  }
	}
</script>