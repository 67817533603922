<template>
  <v-container class="mt-5 ml-2">
    <v-row>
      <v-col cols="12" v-if="Ticket_generado">
        <h1>Bienvenido {{ nombre_completo }}!</h1>
        <h2>Tickets</h2>
        <h3>Cargo</h3>
        {{ cargo }}
        <h3>Numero de folio</h3>
        {{ folio_ticket }}
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
var moment = require("moment");
moment.locale("es");
export default {
  data: () => ({
    folio_ticket: {},
    mensaje_ticket: "",
    motivo_aux: "",
    nombre_completo: "",
    cargo: "",
    Ticket_generado: false,
    Error: false,
    datos_usuario: {
      id_usuario: 0,
      nombre_completo: "",
      usuario: "",
      password: "",
      id_plantel: "",
      email: "",
      telefono: "",
      id_trabajador: 0,
    },
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http
        .get("tickets.usuarios.getAll/" + this.$route.params["id"])
        .then((response) => {
          this.datos_usuario = response.data;
          this.nombre_completo = this.datos_usuario[0].nombre_completo;
          this.$http
            .get("tickets.usuarios.getCargo/" + this.$route.params["id"])
            .then((response) => {
              this.cargo = response.data.cargo;
              if (this.cargo == "Sin cargo, no permitir el acceso") {
                this.Error = true;
                return;
              } else {
                this.Ticket_generado = true;
                this.mensaje_ticket = this.$route.params["mensaje"];
                var payload = {
                  id_unidad_negocio: 0,
                  motivo: this.mensaje_ticket,
                  estatus: 1,
                };

                this.$http
                  .post("tickets.add", payload)
                  .then((response) => {
                    this.folio_ticket = response.data.id;
                    this.motivo_aux = this.mensaje_ticket;

                    var historial = {
                      idticket: response.data.id,
                      resupesta: "",
                      motivo: this.mensaje_ticket,
                      respuestaauxi: "",
                    };

                    this.$http
                      .post("historial_tickets.add", historial)
                      .then((response) => {
                        this.mensaje = "Ticker insertado correctamente";
                        this.motivo = "";
                        this.plantel = { id_plantel: 0, plantel: "" };
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>