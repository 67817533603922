<template>
  <v-container class="mt-5 ml-2">
    <v-row>
      <v-col cols="12">
        <v-card-text>
          <v-card-title>
            <span class="headline">Visualizar mensajes</span>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="historial_ticket[0].motivo"
                  label="Motivo de tu ticket"
                  hide-details
                  readonly
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="historial_ticket[0].respuestaauxi"
                  label="Respuesta por parte del auxiliar"
                  hide-details
                  readonly
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="historial_ticket[0].respuesta"
                  label="Respuesta para el usuario"
                  hide-details
                  readonly
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="regresar()">
              Regresar
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
var moment = require("moment");
moment.locale("es");

export default {
  data: () => ({
    historial_ticket: {
      idhistorial_ticket: 0,
      motivo: "",
      respuestaauxi: "",
      idticket: 0,
      respuesta: "",
    },
    miRespuesta: "",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Agregar ticket" : "Responder Ticket";
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http
        .get("historial_ticket.responder_ticket/" + this.$route.params["id"])
        .then((response) => {
          this.historial_ticket = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    regresar(){
      this.$router.push('../ticketarea');
    }
  },
};
</script>