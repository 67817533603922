var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","dark":"","color":"green","tile":""},on:{"click":function($event){return _vm.$emit('exportarRol')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Descargar rol completo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","dark":"","color":"green","tile":""},on:{"click":function($event){return _vm.$emit('exportarDominical')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-currency-usd")])],1)]}}])},[_c('span',[_vm._v("Descargar Catorcena")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dark":"","small":"","dark":"","color":"green","tile":""},on:{"click":function($event){return _vm.$emit('exportarSabatino')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-currency-usd")])],1)]}}])},[_c('span',[_vm._v("Nómina Sabatino")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dark":"","small":"","color":"pink","dark":"","tile":""},on:{"click":function($event){return _vm.$emit('dialogBonos')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-account-multiple-plus")])],1)]}}])},[_c('span',[_vm._v("Bono Teachers")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dark":"","small":"","color":"yellow","tile":""},on:{"click":function($event){return _vm.$emit('dialogHorasDobles')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-account-multiple-plus")])],1)]}}])},[_c('span',[_vm._v("Horas Dobles")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dark":"","small":"","dark":"","color":"red","tile":""},on:{"click":function($event){return _vm.$emit('dialogSinTeacher')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-cancel")])],1)]}}])},[_c('span',[_vm._v("Grupos sin teacher")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dark":"","small":"","dark":"","color":"black","tile":""},on:{"click":function($event){return _vm.$emit('dialogRolados')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-convert")])],1)]}}])},[_c('span',[_vm._v("Teacher rolados")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dark":"","small":"","dark":"","color":"purple","tile":""},on:{"click":function($event){return _vm.$emit('dialogNoRolados')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-alert")])],1)]}}])},[_c('span',[_vm._v("Teachers No rolados")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dark":"","small":"","dark":"","color":"brown","tile":""},on:{"click":function($event){return _vm.$emit('dialogConfirmados')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-check")])],1)]}}])},[_c('span',[_vm._v("Teachers No Confirmados")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dark":"","small":"","dark":"","color":"cyan darken-4","tile":""},on:{"click":function($event){return _vm.$emit('cargarCatalogos')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-folder-upload")])],1)]}}])},[_c('span',[_vm._v("Cargar catálogos al LMS")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dark":"","small":"","dark":"","color":"blue","tile":""},on:{"click":function($event){return _vm.$emit('cargarBloque')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-progress-upload")])],1)]}}])},[_c('span',[_vm._v("Cargar Rol al Sistema")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dark":"","small":"","dark":"","color":"pink","tile":""},on:{"click":function($event){return _vm.$emit('enviarRol')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-lock-open")])],1)]}}])},[_c('span',[_vm._v("Enviar Rol")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dark":"","small":"","color":"lime accent-4","tile":""},on:{"click":function($event){return _vm.$emit('verificacionRol')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-close-network")])],1)]}}])},[_c('span',[_vm._v("Verificación de Rol")])]),_c('v-btn',{attrs:{"small":"","dark":"","color":"orange","tile":""},on:{"click":function($event){return _vm.$emit('consultar')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }