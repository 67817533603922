<template>
	<v-card class="elevation-0">
		<v-row>
      <v-col cols="12" v-if="alumno && !grupo.length">
        <span style="white-space:pre"><b>Alumno:</b>&emsp;&emsp; {{ alumno.alumno }} <br/></span>
        <span style="white-space:pre"><b>Matricula:</b>&emsp;&emsp; {{ alumno.matricula }} <br/></span>
        <span style="white-space:pre"><b>Grupo:</b>&emsp;&emsp; {{ grupo.grupoSiguiente.grupo }} <br/></span>
      </v-col>

      <v-col cols="12" v-if="grupo.length">
        <div v-for="(hermano, i) in grupo" :key="i">
          <span style="white-space:pre"><b>Alumno: </b>&emsp; {{ hermano.alumno }} <br/></span>
          <span style="white-space:pre"><b>Matricula:</b>&emsp;{{ hermano.matricula }} <br/></span>
          <span style="white-space:pre"><b>Grupo:  </b>&emsp;&emsp; {{ hermano.grupoSiguiente.grupo }} <br/></span>
          <v-divider></v-divider>
        </div>
      </v-col>

			<v-col cols="12" md="6">
        <v-btn 
          color="orange"
          dark
          class="mb-6 mt-2"
          tile
          small
          @click="getPrecioPagar()"
        >
          <v-icon left small>mdi-cash-lock-open</v-icon>
          Calcular costo
        </v-btn>
 
				<v-select
				  :items="tipos_pago"
				  v-model="tipo_pago"
				  label="Forma de pago"
				  filled
				  dense
          single-line
				  item-text="tipo"
				  item-value="id"
          hide-details
				></v-select>

				<v-list class="transparent" dense>
		      <v-list-item class="pa-0">
		        <v-list-item-title align="end" class="pr-2">
              Precio inicial:
            </v-list-item-title>

		        <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
		          <b>$ {{ precio_inicial }}</b>
		        </v-list-item-subtitle>
		      </v-list-item>
          <v-divider></v-divider>

		      <v-list-item class="pa-0" v-if="descuento">
		        <v-list-item-title align="end" class="pr-2">
              Descuento:
            </v-list-item-title>

		        <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
		          <b>$ {{ descuento }}</b>
		        </v-list-item-subtitle>
		      </v-list-item>
          <v-divider v-if="descuento"></v-divider>


          <v-list-item class="pa-0" v-if="pago_anterior">
            <v-list-item-title align="end" class="pr-2">
              Pago anterior:
            </v-list-item-title>
            
            <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
              <b>$ {{ pago_anterior }}</b>
            </v-list-item-subtitle>
          </v-list-item>
          <v-divider v-if="pago_anterior"></v-divider>

          <v-list-item class="pa-0" v-if="saldoFavor">
            <v-list-item-title align="end" class="pr-2">
              Saldo a favor:
            </v-list-item-title>
            
            <v-list-item-subtitle class="green--text font-weight-semibold text-body-1">
              <b>$ {{ saldoFavor }}</b>
            </v-list-item-subtitle>
          </v-list-item>
          <v-divider v-if="saldoFavor"></v-divider>

          <v-list-item class="pa-0" v-if="solcitaFactura">
            <v-list-item-title align="end" class="pr-2">
              <b>Subtotal:</b>
            </v-list-item-title>
            
            <v-list-item-subtitle class="text-h6 black--text">
              <b>$ {{ pago_anterior }} </b>
            </v-list-item-subtitle>
          </v-list-item>
          <v-divider v-if="solcitaFactura"></v-divider>

          <v-list-item class="pa-0" v-if="solcitaFactura">
            <v-list-item-title align="end" class="pr-2">
              <b>IVA:</b>
            </v-list-item-title>
            
            <v-list-item-subtitle class="text-h6 black--text">
              <b>$ {{ iva }}</b>
            </v-list-item-subtitle>
          </v-list-item>
          <v-divider v-if="solcitaFactura"></v-divider>

		      <v-list-item class="pa-0">
		        <v-list-item-title align="end" class="pr-2">
              <b>Total a pagar:</b>
            </v-list-item-title>
		        
            <v-list-item-subtitle class="text-h6 black--text">
		          <b>$ {{  total_a_pagar }}</b>
		        </v-list-item-subtitle>
		      </v-list-item>
          <v-divider></v-divider>

		      <v-list-item class="pa-0">
		        <v-list-item-title align="end" class="pr-2">
              <b>Total pagado:</b>
            </v-list-item-title>

		        <v-list-item-subtitle class="text-h4 black--text" v-if="tipo_pago != 1">
		          <!-- <b>$ {{ total_pagado }}</b> -->
              <v-text-field
                v-if="vistaPrevia"
                dense
                type="number"
                v-model="total_pagado"
              ></v-text-field>
            </v-list-item-subtitle>

		        <v-list-item-subtitle class="text-h4 black--text" v-else>
		          <v-text-field
		            dense
		            type="number"
		            v-model="total_pagado"
		          ></v-text-field>
		        </v-list-item-subtitle>
		      </v-list-item>
          <v-divider></v-divider>

		      <v-list-item class="pa-0" v-if="adeudo">
		        <v-list-item-title align="end" class="pr-2">
              <b>Adeudo:</b>
            </v-list-item-title>
		        
            <v-list-item-subtitle class="red--text font-weight-semibold text-h6 black--text">
		          <b>$ {{ adeudo }}</b>
		        </v-list-item-subtitle>
		      </v-list-item>

          <v-list-item class="pa-0" v-if="nuevoSaldoFavor">
            <v-list-item-title align="end" class="pr-2">
              Nuevo saldo a favor:
            </v-list-item-title>
            
            <v-list-item-subtitle class="green--text font-weight-semibold text-body-1">
              <b>$ {{ nuevoSaldoFavor }}</b>
            </v-list-item-subtitle>
          </v-list-item>

		    </v-list>

        <!-- SOLICITAR FACTURA -->
        <div>
          <v-checkbox 
            label="Con Factura" 
            v-model="solcitaFactura" 
            :value="1"
            small
            dense
          ></v-checkbox>
        </div>

		    <div>
		    	<v-btn 
		    		color="green" 
		    		dark 
		    		small 
		    		class="mr-2 text-capitalize" 
		    		v-if="( parseFloat( total_a_pagar ) - total_pagado ) <= 0 "
		    		tile
		    	>
		    		<v-icon small left>mdi-check-circle</v-icon>
		    		Liquidado
		    	</v-btn>
		    	
		    </div>
		  	<!-- <canvas id="pdf_renderer_cambridge" style="max-width: 100%;"></canvas> -->
			</v-col>

			<v-col cols="12" md="6" v-if="tipo_pago && tipo_pago != 1" class="pa-0">
        <v-card-text align="center" class="pa-0">
          <div v-if="!file">
            <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
              <div class="dropZone-info" @drag="onChange">
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
                <div class="dropZone-upload-limit-info">
                  <div>Extensión: png, jpg, jpeg, svg</div>
                  <div>Tamaño máximo: 10 MB</div>
                </div>
              </div>
              <input type="file" @change="onChange">
            </div>
          </div>

          <v-btn  v-else color="red" class="mb-2" dense @click="removeFile" fab small dark><v-icon>mdi-delete</v-icon></v-btn>
          <v-img :src="vistaPrevia.url" v-if="vistaPrevia" contain max-height="300"/>

          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isPracticaja">
            <strong>PRACTICAJA</strong>
          </span>
          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isTransferencia">
            <strong>TRANSFERENCIA {{ banco }}</strong>
          </span>
          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isSeven">
            <strong>7-SEVEN</strong>
          </span>
          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isOXXO">
            <strong>OXXO</strong>
          </span>
          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isWeb">
            <strong>PAGO DESDE WEB</strong>
          </span>
          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isTarjeta">
            <strong>TARJETA</strong>
          </span>
          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isDirectoBancoBBVA">
            <strong>PAGO EN BANCO BBVA</strong>
          </span>
          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isDirectoBANREGIO">
            <strong>PAGO EN BANCO BANREGIO</strong>
          </span>
        </v-card-text>
			</v-col>
		</v-row>

		<v-card-actions class="pb-0 mt-6">
      <v-btn
        color="black"
        dark
        @click="$emit('regresarGrupo', 2 )"
        tile
        small
        class="text-capitalize"
      >
        <v-icon left small>mdi-chevron-double-left</v-icon>
        regresar
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn
        color="green"
        small
        dark
        class="text-capitalize"
        @click="preProcesarPago()"
        tile
      >
        <v-icon left small>mdi-cash-check</v-icon>
        Procesar
      </v-btn>
    </v-card-actions>

    <!-- DIALOGO PARA CONFIRMAR EL CORREO DEL ALUMNO -->
    <v-dialog
      v-model="dialogConfirmaCorreo"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          Correo a donde se enviará el recibo de pago
        </v-card-title>
        <v-card-text>
          <v-text-field
            name="name"
            label="label"
            id="id"
            v-model="alumno.email"
            filled
            v-if="alumno"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            @click="dialogConfirmaCorreo = false, $emit('terminarProceso')"
            rounded
            dark
            small
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            @click="enviarComprobante()"
            rounded
            dark
            small
          >
            Actualizar y enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-card>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import leerImagenes    from '@/mixins/leerImagenes'

  const convertir = require('numero-a-letras');
  import { jsPDF } from "jspdf";
	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, leerImagenes ],

  	props:[
			'alumno',
			'grupo'
	  ],

    data: () => ({
    	escuela: 2,
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

      precio_inicial:0,
			descuento:0,
			total_a_pagar:0,

			tipo_pago:null,
			sucursal:null,
			total_pagado:0,

			label:'',
      archivos:null,
      vistaPrevia: null,
      textoImagen:'',
      monto:0,
      isPracticaja: false,
      isTransferencia:false,
      isSeven: false,
      isOXXO: false,
      isWeb:false,
      isTarjeta:false,
      isSantander: false,
      isDirectoBancoBBVA: false,
      isDirectoBANREGIO: false,
      file: '',
      dragging: false,
      pago_anterior:0,
      banco:'',

      texto_completo:'',

      saldoFavor: 0,
      tieneSaldoFavor: false,

      fechapago: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      tipos_pago:[
      	{ id: 1, tipo: 'EFECTIVO'},
      	{ id: 2, tipo: 'DEPOSITO / TRANSFERENCIA'},
      	{ id: 4, tipo: 'TARJETA' },
      ],

      dialogConfirmaCorreo:'',

      beca: null,
      pagoRealizao:0,

      pagosCursos:[],

      myState2: {
        pdf:         null,
        currentPage: 1,
        zoom:        2
      },
      solcitaFactura: 1,
      saldoFactura: 0,
      descuentoRecomendados:[],
      descRecomienda:0,
      descuentosEspeciales:[],
      iva: 0
    }),

    computed: {

    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),
      
    	extension() {
        return (this.file) ? this.file.name.split('.').pop() : '';
      },

      adeudo( ){
        const restante = (parseFloat(this.total_a_pagar) - parseFloat(this.total_pagado) )
        if( restante > 0 )
          return restante.toFixed(2)
        else
          return 0
      },

      nuevoSaldoFavor( ){
        const sobrante = parseFloat(this.total_pagado) - (parseFloat(this.total_a_pagar) )
        if( sobrante > 0 )
          return sobrante.toFixed(2)
        else
          return 0
      }
    },

    watch: {

    	grupo ( ){
    		if( this.grupo ){ this.getPrecioPagar( ) }
    	},

      archivos( val ){
        this.total_pagado       = 0
        this.isPracticaja       = false
        this.isTransferencia    = false
        this.isSeven            = false
        this.isOXXO             = false
        this.isWeb              = false
        this.isTarjeta          = false
        this.isDirectoBancoBBVA = false
        this.isDirectoBANREGIO  = false
        if( !val ){ this.vistaPrevia = null } 
      },

      tipo_pago( val ){
        this.total_pagado = 0
      }
    },

    async created () {
    	await this.getPrecioPagar()
    },


    methods: {
      getPrecioPagar( ) {
        let grupo = this.grupo

        if( this.grupo.length ){
          grupo = this.grupo[0]
        }

      	this.cargar = true
      	this.grupos = []

      	const payload = {
      		id_alumno:        this.alumno.id_alumno,
      		grupoSiguiente:   grupo.grupoSiguiente ? grupo.grupoSiguiente : grupo,
          hermanos:         this.grupo.length ? this.grupo : false ,
          factura:          true,
          unidad_negocio:   this.alumno.unidad_negocio
      	}

        return this.$http.post('inscripciones.calcular.factura', payload).then(response=>{

        	this.precio_inicial         = response.data.precio_inicial
					this.descuento              = response.data.descuento
					this.total_a_pagar          = response.data.total_a_pagar
					this.beca                   = response.data.beca 
          this.pago_anterior          = response.data.pago_anterior 
          this.saldoFavor             = response.data.saldoFavor 
          this.tieneSaldoFavor        = response.data.tieneSaldoFavor 
          this.pagosCursos            = response.data.pagosCursos
          this.descuentoRecomendados  = response.data.descuentoRecomendados
          this.descRecomienda         = response.data.descRecomienda
          this.descuentosEspeciales   = response.data.descuentosEspeciales
          this.iva                    = response.data.iva                  
        	this.cargar = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      seleccionarAlumno( value ){

        let payload = {
          grupo: value, 
          hermanos: false
        }

      	this.$emit('alumnoSeleccionado', payload )
      },

      async preProcesarPago( ){
        // HERMANOS
        if( this.grupo.length ) {
          this.pagar_hermanos()
        }else{
          // PAGO NORMAL NOOOO HERMANOS
          this.pagar_normal( )
        }
      },

      async pagar_hermanos( ){
        this.cargar = true

        // Validar para guardar el archivo de la imagen 
        let nombre_archivo = {nombre:''}

        if( this.tipo_pago != 1 ){
          try{
            nombre_archivo = await this.grabarImagen( ).then( response => response )
          }catch( error ){
            return this.validarError( error.response.data.message )
          }
        }

        const liquidado = this.total_pagado >= ( parseFloat(this.total_a_pagar) - parseFloat(this.pago_anterior)) ? true : false

        let pagos = []
        let j    = 0
        for( const i in this.grupo ){
          console.log( this.grupo[i] )
          if(typeof this.grupo[i] == 'object' ){

            // Limpiar la cadena de cáracteres
            this.texto_completo = this.texto_completo.replace(/[∀,∂,∃,∄,∅,∆,∇,∈,∉,∊,∋,∌,∍,∎,∏,∐,∑,∓,∔,∖,√,∛,∜,∝,∞,∟,∠,∡,∢,∣,∤,∥,∦,∧,∨,∩,∪,∫,∬,∭,∮,∯,∰,∱,∲,∳,∴,∵,∶,∷,∸,∹,∺,∻,∼,∽,∾,∿,≀,≁,≂,≃,≄,≅,≆,≇,≈,≉,≊,≋,≌,≍,≎,≏,≐,≑,≒,≓,≔,≕,≖,≗,≘,≙,≚,≛,≜,≝,≞,≟,≠,≡,≢,≣,≤,≥,≦,≧,≨,≩,≪,≫,≬,≭,≮,≯,≰,≱,≲,≳,≴,≵,≶,≷,≸,≹,≺,≻,≼,≽,≾,≿,•,ЗУЗ,З,У,ф,₴]/g,'')

            // Porcentaje del pago, esto es para cuando los hermanos tienen diferente curso
            let porcentajePago          = parseFloat(this.pagosCursos[j].porcentaje)

            // Nuevo saldo a favor FORMATO
            let nuevoSaldoFavor         = parseFloat(this.nuevoSaldoFavor)

            // Monto que tendrá como nuevo saldo a favor
            let monto_nuevo_saldo_favor = porcentajePago * nuevoSaldoFavor

            let total_pagado_alumno     = porcentajePago * parseFloat(this.total_pagado)

            // Monto que el alumno pagó en su grupo
            // El total pagado - su nuevo saldo a favor
            let monto_pagado = ( total_pagado_alumno -  monto_nuevo_saldo_favor ).toFixed(2)

            // Sacar el adeudo
            let adeudo  = 0

            // Sacar el pago individual de cada alumno
            let total_pago_individual = parseFloat( this.pagosCursos[j].total_a_pagar )
            
            // Si no esta liquidado, quiere decir que tiene un adeduo
            if( !liquidado ){

              // Calcular el adeudo de cada alumno
              adeudo = ( total_pago_individual - parseFloat( monto_pagado )).toFixed(2)
            }

            // Calcular el saldo a favor individual de cada alumno
            let saldoFAvorIndividual  = parseFloat( this.pagosCursos[j].saldoFavor )

            // Calcular el total a pagar de cada alumno
            const total_a_pagar_final = ( total_pago_individual - saldoFAvorIndividual )

            // Sacar si el alumno solicitó factura
            const monto_factura = this.solcitaFactura ? ( total_a_pagar_final * .16 ) : 0

            // PARA VALIDAR SI HAY QUE GUARDAR EL CURSO DEL ALUMNO, PRIMERO VEREMOS SI ES DEL EXCI, INDUCCIÓN, CAMBIOS O CERTI
            let guardarCurso = false

            // SACAR EL FORMATO DEL GRUPO, MÁS QUE NADA, PARA QUE COINCIDAN TOOS LOS NOMBRES
            let grupoFormato = this.grupo[i].grupoSiguiente.grupo.toUpperCase()

            // VALIDAMOS, SI EL GRUPO NOOO ES EXCI, INDUCCIÓN, CAMBIOS O CERTIFICACIÓN, GUARDAMOSSS EL CURSO
            if( !grupoFormato.match('EXCI') && !grupoFormato.match('INDUCCI') && !grupoFormato.match('CAMBIOS') && !grupoFormato.match('CERTI') ){

              // SI GUARDAR EL CURSO
              guardarCurso = true
            }

            let descuentoGrupoIndividual = this.pagosCursos[j].descuento


            const payload = {
              adeudo                     : liquidado ? 0 : adeudo, // SI NO ESTA LIQUIDADO, MOSTRAR ADEUDO
              base_path                  : nombre_archivo.nombre,
              comentarios                : '',
              descuentoRecomendados      : [],
              descuento                  : ( descuentoGrupoIndividual + saldoFAvorIndividual ),
              descRecomienda             : [],
              descuentosEspeciales       : [],
              guardarCurso,
              id_alumno                  : this.grupo[i].id_alumno,
              id_ciclo                   : this.grupo[i].grupoSiguiente.id_ciclo,
              id_curso                   : this.grupo[i].grupoSiguiente.id_curso,
              id_descuento_pronto_pago   : null,
              id_forma_pago              : this.tipo_pago,
              id_grupo                   : this.grupo[i].grupoSiguiente.id_grupo,
              id_plantel_ingreso         : this.getdatosUsuario.id_plantel,
              id_tipo_descuento          : null,
              id_tipo_ingreso            : 2,
              id_tipo_inscripcion        : this.total_pagado >= this.total_a_pagar ? 2 : 3,
              id_usuario_ultimo_cambio   : this.getdatosUsuario.iderp,
              idbecas                    : 0,
              monto_adeudo_grupo         : 0,
              monto_descuento_grupo      : ( descuentoGrupoIndividual + saldoFAvorIndividual ),
              monto_pagado_total         : parseFloat(monto_pagado),
              monto_saldo_favor          : saldoFAvorIndividual,
              nuevoSaldoFavor            : monto_nuevo_saldo_favor,
              pago_completado_sn         : liquidado ? 1 : 0,
              precio_curso               : this.pagosCursos[j].precio_inicial,
              precio_grupo_con_descuento : total_a_pagar_final + monto_factura,
              precio_grupo_sin_descuento : this.pagosCursos[j].precio_inicial,
              saldoFavor                 : saldoFAvorIndividual,
              solcitaFactura             : this.solcitaFactura,
              tieneSaldoFavor            : this.tieneSaldoFavor * porcentajePago,
              texto_completo             : this.texto_completo,
              tipoAlumno                 : this.grupo[i].tipoAlumno,
              total_pagado_alumno        : this.solcitaFactura ? ( this.pagosCursos[j].precio_inicial * 1.16 ) : this.pagosCursos[j].precio_inicial,
              url_foto                   : nombre_archivo.nombre,
            }

          // monto_pagado_total         : parseFloat( this.nuevoSaldoFavor ) > 0 ? ( parseFloat(this.total_a_pagar) - parseFloat(this.pago_anterior) ) : (parseFloat(this.saldoFavor) + parseFloat(this.total_pagado)) ,
          // total_pagado_alumno        : (parseFloat(this.precio_inicial) + parseFloat(this.saldoFactura)),
          // url_foto                   : nombre_archivo.nombre,

            console.log( payload )
            pagos.push( payload )
            j += 1
          }
        }

        for( const i in pagos ){
          try{
            const pago = await this.realizarPago( pagos[i] ).then( response => response )
          }catch( error ){
            return this.validarError( error.response.data.message )
          }
        }

        this.cargar = false

        this.$emit('terminarProceso')

      },

      realizarPago( data ){
        return new Promise((resolve,reject)=>{
          this.$http.post('registrar.grupo.alumno', data ).then( response => {
            // Convertir el monto en texto

            this.url = axios.defaults.baseURL + 'recibo-pago/' + response.data.ingreso.id + '.pdf'

            window.open( this.url )

            resolve('Inscrito correctamente')
          }).catch( error =>{
            reject( error )
          }).finally( () => { this.cargar = false })
        })
      },

      grabarImagen( ){
        return new Promise((resolve,reject)=>{
          let formData = new FormData();
          //se crea el objeto y se le agrega como un apendice el archivo
          formData.append("file", this.file);

          this.$http.post("grabar.comprobante", formData).then( response => {
            resolve( response.data ) 
          }).catch( error =>{
            reject( error )
          }).finally( () => { this.cargar = false })
        })
      },

      async pagar_normal( ){
      	this.cargar = true

      	// Guardamos el archivo
      	let nombre_archivo = { nombre: '' }

      	// Grabamos el archivo si es que hay
      	if( this.tipo_pago != 1 ){
      		nombre_archivo = await this.grabarImagen( )
      	}

        // Limpiar la cadena de cáracteres
        this.texto_completo = this.texto_completo.replace(/[∀,∂,∃,∄,∅,∆,∇,∈,∉,∊,∋,∌,∍,∎,∏,∐,∑,∓,∔,∖,√,∛,∜,∝,∞,∟,∠,∡,∢,∣,∤,∥,∦,∧,∨,∩,∪,∫,∬,∭,∮,∯,∰,∱,∲,∳,∴,∵,∶,∷,∸,∹,∺,∻,∼,∽,∾,∿,≀,≁,≂,≃,≄,≅,≆,≇,≈,≉,≊,≋,≌,≍,≎,≏,≐,≑,≒,≓,≔,≕,≖,≗,≘,≙,≚,≛,≜,≝,≞,≟,≠,≡,≢,≣,≤,≥,≦,≧,≨,≩,≪,≫,≬,≭,≮,≯,≰,≱,≲,≳,≴,≵,≶,≷,≸,≹,≺,≻,≼,≽,≾,≿,•,ЗУЗ,З,У,ф,₴,#,*,:,$,.,/]/g,' ')

        const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};

        this.texto_completo  = this.texto_completo.split('').map( letra => acentos[letra] || letra).join('').toString();

        // Generamos el payload del pago
      	const payload = {
          adeudo                     : parseFloat(this.adeudo),
			    base_path                  : null,
			    comentarios                : '',
          descRecomienda             : this.descRecomienda,
			    descuento                  : this.descuento,
          descuentoRecomendados      : this.descuentoRecomendados,
          descuentosEspeciales       : this.descuentosEspeciales,
          guardarCurso               : false,
			    id_alumno                  : this.alumno.id_alumno,
			    id_ciclo                   : this.grupo.grupoSiguiente.id_ciclo,
          id_curso                   : this.grupo.grupoSiguiente.id_curso,
			    id_descuento_pronto_pago   : null,
			    id_forma_pago              : this.tipo_pago,
          id_grupo                   : this.grupo.grupoSiguiente.id_grupo,
			    id_plantel_ingreso         : this.getdatosUsuario.id_plantel,
			    id_tipo_descuento          : null,
			    id_tipo_ingreso            : 2,
			    id_tipo_inscripcion        : this.total_pagado >= this.total_a_pagar ? 2 : 3,
			    id_usuario_ultimo_cambio   : this.getdatosUsuario.iderp,
          idbecas                    : this.beca ? this.beca.id : 0,
			    monto_adeudo_grupo         : 0,
			    monto_descuento_grupo      : parseFloat( this.descuento ),
			    monto_pagado_total         : parseFloat( this.total_pagado ),
			    monto_saldo_favor          : parseFloat( this.saldoFavor ),
          nuevoSaldoFavor            : parseFloat( this.nuevoSaldoFavor ),
			    pago_completado_sn         : this.total_pagado >= parseFloat( this.total_a_pagar ) ? 1 : 0,
			    precio_curso               : this.precio_inicial,
			    precio_grupo_con_descuento : ( parseFloat( this.total_a_pagar ) + parseFloat( this.pago_anterior )) ,
			    precio_grupo_sin_descuento : this.precio_inicial,
          saldoFavor                 : this.saldoFavor,
          solcitaFactura             : this.solcitaFactura,
          tieneSaldoFavor            : this.tieneSaldoFavor,
          texto_completo             : this.texto_completo,
          tipoAlumno                 : this.grupo.tipoAlumno,
          total_pagado_alumno        : (parseFloat(this.precio_inicial) + parseFloat(this.saldoFactura)),
			    url_foto                   : nombre_archivo.nombre,
				}

				this.$http.post('registrar.grupo.alumno', payload ).then( response => {
          // Convertir el monto en texto
          this.pagoRealizao = response.data.ingreso.id
          this.dialogConfirmaCorreo = true

				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
      },

      enviarComprobante( ){
        const payload = {
          folio:                  this.pagoRealizao,
          alumno:                 this.alumno.alumno,
          matricula:              this.alumno.matricula,
          plantel:                this.getdatosUsuario.plantel,
          ciclo:                  this.grupo.grupoSiguiente.ciclo,
          curso:                  this.grupo.grupoSiguiente.curso,
          cantidad_recibida:      this.total_pagado,
          cantidad_letra:         convertir.NumerosALetras(this.total_pagado).toUpperCase(),
          descuento_pronto_pago:  this.descuento,
          adeudo:                 this.adeudo,
          saldo_favor:            this.nuevoSaldoFavor,
          id_alumno:              this.alumno.id_alumno,
          email:                  this.alumno.email,
          unidad_negocio:         this.alumno.unidad_negocio,
          fecha_pago:             this.fechapago,
          recepcion:              this.getdatosUsuario.nombre_completo,
          solcitaFactura:         this.solcitaFactura
        }

        this.cargar = true
        this.$http.post('actualizar.correo.alumno', payload ).then( response => {
          this.validarSuccess(response.data.message)

          this.url = axios.defaults.baseURL + 'recibo-pago/' + this.pagoRealizao + '.pdf'

          window.open( this.url )
          this.$emit('terminarProceso')
        }).catch( error =>{
         this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
    },
  }
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>