<template>
  <v-container class="mt-4 ml-2 pr-7" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            Recursos

            <v-spacer></v-spacer>

            <v-btn
              class="mr-2"
              small
              dark
              color="green"
              @click="exportExcel(filterAlumnos, 'PUNTOS_ALUMNOS')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="subirCalifiaciones()"
              small
              v-if="getdatosUsuario.idpuesto == 11"
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="getAllRecursos()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-row>
            <v-col cols="12" md="4" lg="3">
              <v-radio-group
                v-model="escuela"
                row
                class="ml-2"
                @change="onRadioChange"
              >
                <v-radio label="Fast" :value="2"></v-radio>
                <v-radio label="Inbi" :value="1"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-card-text>
            <v-row>
              <v-col cols="2" md="2" lg="2">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="curso"
                  :items="cursos"
                  label="Selecciona un curso"
                  persistent-hint
                  hide-details
                  item-text="nombre"
                  item-value="id"
                  @change="onCursoChange"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="2" md="2" lg="2" v-if="curso">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="nivel"
                  :items="[
                    { id_nivel: 1, nombre_nivel: 'Nivel 1' },
                    { id_nivel: 2, nombre_nivel: 'Nivel 2' },
                    { id_nivel: 3, nombre_nivel: 'Nivel 3' },
                    { id_nivel: 4, nombre_nivel: 'Nivel 4' },
                    { id_nivel: 5, nombre_nivel: 'Nivel 5' },
                    { id_nivel: 6, nombre_nivel: 'Nivel 6' },
                    { id_nivel: 7, nombre_nivel: 'Nivel 7' },
                    { id_nivel: 9, nombre_nivel: 'Nivel 8' },
                    { id_nivel: 9, nombre_nivel: 'Nivel 9' },
                    { id_nivel: 10, nombre_nivel: 'Nivel 10' },
                    { id_nivel: 11, nombre_nivel: 'Nivel 11' },
                    { id_nivel: 12, nombre_nivel: 'Nivel 12' },
                    { id_nivel: 13, nombre_nivel: 'Nivel 13' },
                    { id_nivel: 14, nombre_nivel: 'Nivel 14' },
                    { id_nivel: 15, nombre_nivel: 'Nivel 15' },
                  ]"
                  label="Selecciona un nivel"
                  persistent-hint
                  hide-details
                  item-text="nombre_nivel"
                  item-value="id_nivel"
                  @change="onCursoChange"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="2" md="2" lg="2" v-if="curso && nivel">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="tipo_curso"
                  :items="[
                    { id_tipo_recurso: 1, nombre_tipo_curso: 'Examen' },
                    { id_tipo_recurso: 2, nombre_tipo_curso: 'Ejercicios' },
                    { id_tipo_recurso: 3, nombre_tipo_curso: 'Videos' },
                    { id_tipo_recurso: 4, nombre_tipo_curso: 'PDF' },
                  ]"
                  label="Selecciona un tipo de curso"
                  persistent-hint
                  hide-details
                  item-text="nombre_tipo_curso"
                  item-value="id_tipo_recurso"
                >
                </v-autocomplete>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="3" md="3" lg="3" class="pb-0">
                <v-text-field
                  label="Buscar"
                  v-model="search"
                  dense
                  filled
                  append-icon="mdi-magnify"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headers"
              :items="filterRecursos"
              class="elevation-0 pt-5"
              :search="search"
              dense
            >
              <template v-slot:item.ver="{ item }">
                <span>
                  <v-icon
                    color="primary"
                    @click="
                      (idrecurso = item),
                        getPreguntasAlumnos()     
                    "
                  >
                    mdi-file-image
                  </v-icon>
                </span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogRecurso"
      max-width="900px"
      max-height="1000px"
      :key="dialogRecurso"
    >
      <v-card class="elevation-0">
        <v-toolbar color="primary" dark>
          Recurso
          <v-spacer></v-spacer>
          <v-btn
            color="transparent elevation-0"
            x-small
            absolute
            top
            right
            fab
            class="mt-5 mr-0"
            @click="dialogRecurso = false"
            ><v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
              lg="12"
              class="mt-2 text--center"
            >
              <!--En caso de que sea un pdf-->
              <v-card
                style="width: 100%; height: 800px"
                v-if="idrecurso.tipo_recurso && idrecurso.tipo_recurso == 4"
              >
                <embed
                  :src="url_servidor + idrecurso.ruta"
                  type="application/pdf"
                  style="width: 100%; height: 100%"
                />
              </v-card>
              <!--En caso de que sea un video-->
              <v-card
                style="width: 100%; height: 800px"
                v-if="idrecurso.tipo_recurso && idrecurso.tipo_recurso == 3"
              >
                <video controls style="width: 100%; height: 100%">
                  <source
                    :src="url_servidor + idrecurso.ruta"
                    type="video/mp4"
                  />
                </video>
              </v-card>
              <!--En caso de que sea un ejercicio o examen-->
              <v-card
                v-else
                v-for="(rutaImagenExamen, index) in preguntas"
                :key="index"
                style="width: 100%; height: 670px;"
                class="mt-4 ml-2 pr-7"
              >
                <v-img
                  :src="url_servidor + rutaImagenExamen.ruta_imagen"
                  contain
                  aspect-ratio="2"
                  max-width="800"
                ></v-img><br>

                <audio controls v-if="rutaImagenExamen.ruta_audio_pregunta != null" class="center-audio" >
                  <source :src="url_servidor + rutaImagenExamen.ruta_audio_pregunta" type="audio/mpeg">
                </audio>

                <v-row justify="center" class="mx-6 mt-4">
                <v-col cols="3" md="3" lg="3" class="pb-0">
                <label style="color: #333333">Opción 1</label>
                <v-text-field
                  v-model="rutaImagenExamen.opciones.valor1"
                  single-line
                  dense
                  placeholder="Opción 1"
                ></v-text-field>
                </v-col>

                <v-col cols="3" md="3" lg="3" class="pb-0">
                <label style="color: #333333">Opción 2</label>
                <v-text-field
                  v-model="rutaImagenExamen.opciones.valor2"
                  single-line
                  dense
                  placeholder="Opción 2"
                ></v-text-field>
                </v-col>

                <v-col cols="3" md="3" lg="3" class="pb-0">
                <label style="color: #333333">Opción 3</label>
                <v-text-field
                  v-model="rutaImagenExamen.opciones.valor3"
                  single-line
                  dense
                  placeholder="Opción 3"
                ></v-text-field>
                </v-col>

                <v-col cols="3" md="3" lg="3" class="pb-0">
                <label style="color: #333333">Opción 4</label>
                <v-text-field
                  v-model="rutaImagenExamen.opciones.valor4"
                  single-line
                  dense
                  placeholder="Opción 4"
                ></v-text-field>
                </v-col>
                </v-row>

                <v-row class="mx-6">
                <v-col cols="3" md="3" lg="3" class="pb-0">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="rutaImagenExamen.opciones.opcion_correcta"
                  :items="[
                    { id_opcion: 1, opcion: rutaImagenExamen.opciones.valor1 },
                    { id_opcion: 2, opcion: rutaImagenExamen.opciones.valor2 },
                    { id_opcion: 3, opcion: rutaImagenExamen.opciones.valor3 },
                    { id_opcion: 4, opcion: rutaImagenExamen.opciones.valor4 },
                  ]"
                  label="Selecciona la opcion correcta"
                  persistent-hint
                  hide-details
                  item-text="opcion"
                  item-value="id_opcion"
                >
                </v-autocomplete>
                </v-col>

                 <v-btn
                  color="primary"
                  dark
                  @click="updateRespuestas(index)"
                  small
                  class="mt-6"
                >
                  Actualizar
                </v-btn>

                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>

<script>
import axios from 'axios';
import XLSX from "xlsx";

import { mapGetters, mapActions } from "vuex";
var moment = require("moment");
moment.locale();

// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  components: {
    Alerta,
    carga,
  },

  mixins: [validarErrores, funcionesExcel],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    gruposAlumnos: [],
    horarios: null,
    cicloInf: null,
    curso: null,
    cursos: [],
    nivel: null,
    niveles: [],
    recurso: null,
    recursos: [],
    tipo_curso: null,
    url_servidor: "https://lms.inbi.mx/",
    vistaPrevias: [],
    idrecurso: [],
    preguntas: [],
    respuestas: [],
    respuestaspreguntas: [],
    rutasImagenesExamenes: [],
    opcionesActualizar : null,

    ciclos: [],
    ciclo: {},
    idciclo: 0,
    dialogAlumno: false,
    dialogRecurso: false,
    dialogEditarRespuesta: false,
    calificaciones: {},
    verCalificaciones: false,
    tab: null,
    items: ["Participaciones", "Ejercicios", "Exámenes", "Total"],

    loading: false,
    search: "",
    dialog: false,
    dialogDelete: false,

    cleanItem: {
      opcion1: "",
      opcion2: "",
      opcion3: "",
      opcion4: "",
      opcion_correcta: 0,
    },

    // defaultItem: {
    //   opcion1: "",
    //   opcion2: "",
    //   opcion3: "",
    //   opcion4: "",
    //   opcion_correcta: 0,
    // },

    headers: [
      { text: "ID", value: "id" },
      { text: "Nombre", value: "nombre" },
      { text: "Tipo", value: "extencion" },
      { text: "Nivel", value: "id_nivel" },
      { text: "Ver", value: "ver" },
    ],
    headersRespuestas: [
      { text: "ID", value: "idPregunta" },
      { text: "Opción 1", value: "valor1" },
      { text: "Opción 2", value: "valor2" },
      { text: "Opción 3", value: "valor3" },
      { text: "Opción 4", value: "valor4" },
      { text: "Opción Correcta", value: "opcion_correcta" },
      { text: "Editar", value: "editar" },
    ],
    alumnos: [],
    escuela: 1,
    option: 1,
  }),

  watch: {
    // curso() {
    //   if (this.curso) {
    //     this.getAllCursos();
    //   }
    // },

    escuela() {
      this.url_servidor =
        this.escuela == 1
          ? "https://lms.inbi.mx/"
          : "https://lms.fastenglish.com.mx/";
    },
  },

  computed: {
    ...mapGetters("login", [
      "getdatosUsuario",
      "getLogeado",
      "getEscuela",
      "drawer2",
      "getEstatusLlamada",
      "getNuevosProspectos",
    ]),

    filterRecursos() {
      let data = this.recursos;

      if (this.curso) {
        data = data.filter((el) => {
          return el.id_curso == this.curso;
        });
      }

      if (this.nivel) {
        data = data.filter((el) => {
          return el.id_nivel == this.nivel;
        });
      }

      if (this.tipo_curso == 1) {
        data = data.filter((el) => {
          return el.tipo_recurso == this.tipo_curso && el.nombre.toLowerCase().includes('examen');
        });
      }

      if (this.tipo_curso == 2) {
        data = data.filter((el) => {
          return el.nombre.toLowerCase().includes('sesión');
        });
      }

      if (this.tipo_curso == 3) {
        data = data.filter((el) => {
          return el.tipo_recurso == 3;
        });
      }

      if (this.tipo_curso == 4) {
        data = data.filter((el) => {
         return el.tipo_recurso == 4;
        });
      }

      return data;
    },
  },

  async created() {
    await this.getCiclos();
    await this.getAllCursos();
    await this.getAllRecursos();
    await this.getPreguntasAlumnos();
  },

  methods: {
    getCiclos() {
      this.cargar = true;
      this.ciclos = [];
      this.planteles = [];
      this.$http
        .get("kpi.ciclos.all")
        .then((response) => {
          for (const i in response.data) {
            if (!(response.data[i].ciclo.search("FE") != -1)) {
              if (response.data[i].ciclo.search("CICLO") != -1) {
                this.ciclos.push(response.data[i]);
              }
            }
          }
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },
    
    // EXCEL
    exportar() {
      let xls = {
        data: this.alumnos,
        tHeaders: ["Codigo", "Nombre", "Sal", "Labortorio"],
        filterVal: ["codigo", "nomart", "sal", "lab"],
        nomfile: "Productos",
      };
      this.exportExcel();
    },

    exportExcel() {
      let data = XLSX.utils.json_to_sheet(this.alumnos);
      const workbook = XLSX.utils.book_new();
      const filename = "puntos_dispo_fast";
      XLSX.utils.book_append_sheet(workbook, data, filename);

      var wbout = XLSX.write(workbook, {
        bookType: "xls",
        bookSST: false,
        type: "binary",
      });

      const file = new File([new Blob([this.s2ab(wbout)])], filename + ".xls");

      let formData = new FormData();
      // //se crea el objeto y se le agrega como un apendice el archivo
      formData.append("file", file);
      // /*Initialize the form data*/

      this.$http
        .post("pdfs", formData)
        .then((response) => {
          window.location =
            axios.defaults.baseURL + "pdfs/" + filename + ".xls";
        })
        .catch((error) => {
          alert(error.body);
        });
    },
    //////////////////////////////////////////////////////////////////////////////////////////////
    //Consigue todos los cursos de los alumnos
    getAllCursos() {
      this.cargar = true;
      this.cursos = [];

      const payload = {
        escuela: this.escuela,
      };

      return this.$http
        .post("get.all.cursos", payload)
        .then((response) => {
          this.cursos = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getAllRecursos() {
      this.cargar = true;
      this.recursos = [];

      const payload = {
        escuela: this.escuela,
      };

      return this.$http
        .post("get.all.recursos", payload)
        .then((response) => {
          this.recursos = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getPreguntasAlumnos() {
      this.cargar = true;
      this.preguntas = [];

      const payload = {
        escuela: this.escuela,
        idEvaluacion: this.idrecurso.id_evaluacion,
      };

      return this.$http
        .post("get.all.preguntas", payload)
        .then((response) => {
          this.preguntas = response.data;
          console.log('Las preguntassssss',this.preguntas)
          const rutas = this.preguntas.map((pregunta) => pregunta.ruta_imagen);
          this.rutasImagenesExamenes = rutas;

          if (this.preguntas.length > 0){
            this.dialogRecurso = true
          }

          if (this.idrecurso.tipo_recurso == 4 || this.idrecurso.tipo_recurso == 3){
           this.dialogRecurso = true
          }

          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    // getRespuestasAlumnos() {
    //   this.cargar = true;
    //   this.respuestas = [];

    //   const payload = {
    //     escuela: this.escuela,
    //     idEvaluacion: this.idrecurso.id_evaluacion,
    //   };

    //   return this.$http
    //     .post("get.all.respuestas", payload)
    //     .then((response) => {
    //       this.respuestas = response.data;
    //       this.cargar = false;
    //     })
    //     .catch((error) => {
    //       this.validarError(error);
    //     })
    //     .finally(() => {
    //       this.cargar = false;
    //     });
    // },

    // getRespuestasAlumnosPORpregunta() {
    //   this.cargar = true;
    //   this.respuestaspreguntas = [];
      
    //   const payload = {
    //     escuela: this.escuela,
    //     idPregunta: 1,
    //   };


    //   return this.$http
    //     .post("get.all.respuestasporpregunta", payload)
    //     .then((response) => {
    //       this.respuestaspreguntas = response.data;

    //       this.cargar = false;
    //     })
    //     .catch((error) => {
    //       this.validarError(error);
    //     })
    //     .finally(() => {
    //       this.cargar = false;
    //     });
    // },


    updateRespuestas(index) {
      this.cargar = true;
      
      const payload = {
        escuela: this.escuela,
        opcion_correcta: this.preguntas[index].opciones.opcion_correcta,
        valor1: this.preguntas[index].opciones.valor1,
        valor2: this.preguntas[index].opciones.valor2,
        valor3: this.preguntas[index].opciones.valor3,
        valor4: this.preguntas[index].opciones.valor4,
        idPregunta: this.preguntas[index].opciones.idPregunta,
        idEvaluacion: this.preguntas[index].idEvaluacion
      };

        return this.$http
        .post("update.respuestas", payload)
        .then((response) => {       
          this.validarSuccess(response.data.message);
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

  
    //Al momento de elegir FAST o INBI en los botones trae los datos de tal escuela
    onRadioChange() {
      this.getAllCursos();
      this.getAllRecursos();
    },

    //Al momento de elegir un curso unicamente recarga pagina para que aparezca el nivel en caso de elegir un curso
    onCursoChange() {
      this.cargar = true;
      this.cargar = false;
    },
  },
};
</script>

<style>
.center-audio {
    display: block;
    margin: 0 auto; /* Esto centra horizontalmente */
}
</style>