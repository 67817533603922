<template>
  <v-container class="mt-4 ml-2 pr-6" fluid>
    <v-row v-if="( getdatosUsuario.idpuesto == 18 && ![568, 7, 161, 626, 382 ].includes( getdatosUsuario.iderp ) ) || getdatosUsuario.idpuesto != 18">
    	<v-col cols="12">
    		<v-card class="shadowCard">

          <v-card-title primary-title class="text-subtitle-1">
            Solicitar cambio
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( alumnos  , 'CAMBIOS')"
              tile
            >
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn 
              color="primary" 
              small 
              tile 
              @click="getAlumnosxEscuela()"
            >
              <v-icon small left>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6">
                <v-col cols="12">
                	<v-text-field
					          label="Buscar"
					          v-model="search"
					          dense
					          hide-details
                    filled
                    single-line
                    append-icon="mdi-magnify"
					        ></v-text-field>
                </v-col>
              </v-col>
            </v-row>
  			    <v-data-table
  			      :headers="headers"
  			      :items="alumnos"
  			      :search="search"
              dense
  			    >
              <template v-slot:item.opciones="{ item }">
                <v-btn 
                  color="orange" 
                  dark 
                  small
                  tile 
                  @click="addDialogCambio(item)"
                >
                  <v-icon>mdi-swap-horizontal</v-icon>
                </v-btn>

              </template>
  			    </v-data-table>
          </v-card-text>
    		</v-card>
    	</v-col>
    </v-row>

    <!-- Dialogo para agregar un cambio -->
    <v-dialog
      v-model="dialogAddCambio"
      persistent
      max-width="800"
      v-if="alumno"
    >
      <v-card>
        <v-card-title class="text-h5">
          Solicitud de cambio
        </v-card-title>
        <v-card-text>
          <b>Matricula:</b> {{ alumno.matricula }}
          <br/>
          <b>Nombre:</b>    {{ alumno.alumno }}
          <br/>
          <v-divider></v-divider>
          <br/>
          <v-row>
            <v-col cols="12" md="6" >
              <v-autocomplete
                class="mt-2"
                filled
                dense
                clearable
                v-model="grupoAnterior"
                :items="grupoAlumnos"
                label="Grupo actual"
                persistent-hint
                prepend-inner-icon="mdi-clipboard-outline"
                return-object
                item-text="grupo"
                item-value="id_grupo"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" >
              <v-autocomplete
                class="mt-2"
                filled
                dense
                clearable
                v-model="nuevoGrupo"
                :items="grupos"
                label="Nuevo Grupo"
                persistent-hint
                prepend-inner-icon="mdi-clipboard-outline"
                return-object
                item-text="grupo"
                item-value="id_grupo"
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <b>Tipo de cambio:</b>
          <br/>
          <v-autocomplete
            class="mt-2"
            filled
            dense
            clearable
            v-model="tipoCambio"
            :items="tipocambios"
            label="Selecciona tipo de cambio"
            persistent-hint
            prepend-inner-icon="mdi-clipboard-outline"
            return-object
            item-text="tipocambio"
            item-value="idtipocambio"
          >
          </v-autocomplete>

          <v-textarea
            label="Por favor, ingresa el motivo de tu solicitud"
            v-model="motivo"
            filled
          ></v-textarea>

          <v-alert
            dense
            border="left"
            type="warning"
            class="mt-2"
          >
            Recuerda que al hacer un  <strong>CAMBIO</strong> de grupo, al alumno se le  <strong>BORRARÁN</strong> los datos, como lo son: <strong>ASISTENCIAS, EJERCICIOS y EXÁMENES</strong>
          </v-alert>
        </v-card-text>

        <!-- Botones -->
        <v-card-actions>
          <v-btn
            color="black"
            dark
            small
            tile
            @click="dialogAddCambio = false"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="nuevoGrupo && tipoCambio && grupoAnterior && motivo"
            color="green"
            dark
            small
            tile
            @click="addCambio()"
            :loading="loading"
            :disabled="disabled" 
          >
            <v-icon small left>mdi-content-save</v-icon>
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
	import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'
  import validarErrores   from '@/mixins/validarErrores'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins:[ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
    },

    data () {
      return {

        // Alertas
        parametros:{
          dialogError: false,
          mensaje: '',
          color: ''
        },

        respuestaAlerta:false,
        loader: true,
        cargar: false,

        search: '',
        headers: [
          { text: 'Matricula',   value: 'matricula' },
          { text: 'Nombre',      value: 'alumno' },
          { text: 'Grupo',       value: 'grupo' },
          { text: 'Opciones',    value: 'opciones' },
        ],

        teachers: [],

        tipociclo:      '',
        grupos:         [],
        nuevoGrupo:     null,
        grupoAlumnos:   [],
        grupoAnterior:  null,

        tipocambios:    [],
        tipoCambio:     null,
        
        alumnos:        [],
        alumno:         null,

        dialogAddCambio:false,
        loading:        false,
        disabled:       false,
        motivo:         null,
      }
    },

    watch:{
      grupoAnterior () {
        if(this.grupoAnterior){
          this.getNuevoGrupoAlumno ()
        }else{
          this.grupos = []
        }
      }
    },

    async created () {
      console.clear()
      // Primero necesitamos consultar el tipo de usuario y de donde viene
      await this.getTipoUsuarioEscuela()

      // Ahora si consultamos los grupos por tipo de escuela
      // await this.getGrupos()

      // Ahora traemos los tipos de cambio
      await this.getTitpoCambios()
    	
      // Ahora traemos los alumnos por escuela
      await this.getAlumnosxEscuela()

    },

    methods: {
      getTipoUsuarioEscuela () {
        const id_plantel = this.getdatosUsuario.id_plantel
        if([1,2,3,4,5,6,17,19,21,22,23,24,25,26].includes( id_plantel ) ){
          this.tipociclo = `grupo NOT LIKE '%FE%'`
        }else{ this.tipociclo = `grupo LIKE '%FE%'` }
        return 
      },

      getNuevoGrupoAlumno () {
        if(this.grupoAnterior){
          this.cargar = true
          
          let tipociclo = ''
          if( !this.alumno.grupo.match('FAST') ){
            tipociclo = `grupo NOT LIKE '%FE%'`
          }else{ tipociclo = `grupo LIKE '%FE%'` }

          const payload =  { 
            tipociclo, 
            id_ciclo: this.grupoAnterior.id_ciclo
          }
          return this.$http.post('cambios.nuevo.grupos.ciclo',payload).then(response=>{
            this.grupos = response.data
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }else{

        }
      },

      getTitpoCambios () {
        this.cargar = true
        return this.$http.get('cambios.tipo.cambio').then(response=>{
          this.tipocambios = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getAlumnosxEscuela () {
        // Vaciamos el arreglo de los grupos
        this.alumnos = []
        // Activamos el loader
        this.cargar = true
        const payload =  { tipociclo: this.tipociclo }
        return this.$http.post('cambios.alumnos.grupos',payload).then(response=>{
          // Limpiamos todo en caso de ser necesario
          this.limpiar () 
          // Llenamos el arreglo de alumnos
          this.alumnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportar(){
        let data = XLSX.utils.json_to_sheet(this.alumnos)
        const workbook = XLSX.utils.book_new()
        const filename = 'Riesgo_Teachers'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });
      },

      async addDialogCambio (alumno) {
        await this.getGrupoUsuario(alumno.id_alumno)
        this.alumno = alumno
        this.dialogAddCambio = true
      },

      getGrupoUsuario ( idalumno ){
        this.cargar = true
        this.grupoAlumnos = []
        return this.$http.get('cambios.grupo.usuario/' + idalumno).then(response=>{
          this.grupoAlumnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      addCambio () {
        // Validar que esten llenos los campos
        if(this.nuevoGrupo && this.tipoCambio && this.grupoAnterior && this.motivo ){

          // Activar el disabled del boton para que no le den doble click
          this.loading  = true
          this.disabled = true
          // Desesestructuración de alumno 
          const { id_alumno, id_plantel, monto_pagado_total } = this.alumno

          // Generar el payload a mandar
          let payload = {
            id_alumno,
            idtipo_cambio:   this.tipoCambio.idtipocambio,
            solicito_cambio: this.getdatosUsuario.iderp,
            id_plantel,
            id_grupo_actual: this.grupoAnterior.id_grupo,
            id_grupo_nuevo:  this.nuevoGrupo.id_grupo,
            estatus:         1,
            diferencia:      monto_pagado_total,
            motivo:          this.motivo
          }

          this.cargar = true

          // Enviar los datos 
          this.$http.post('cambios.add',payload).then(response=>{
            this.validarSuccess( 'Datos grabados correctamente')
            this.limpiar()
          }).catch(error=>{
            this.loading  = false
            this.disabled = false
            this.validarError(error)
          })
        }else{
          this.estatusError('Favor de ingresar el grupo y el tipo de cambio')
        }
      },

      limpiar () {
        this.loading         = false
        this.disabled        = false
        this.nuevoGrupo      = null
        this.tipoCambio      = null
        this.overlay         = false
        this.dialogAddCambio = false
        this.grupos          = []
        this.grupoAlumnos    = []
        this.motivo          = null

      },
    },


    computed:{
      ...mapGetters ('login',['getdatosUsuario'])
    }
  }
</script>

<style>

  .v-menu__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-menu__content::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-button:increment,.v-menu__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-menu__content::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>