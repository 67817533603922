<template>
	<v-card class="elevation-0">

    <!-- Validar que haya un alumno seleccionado -->
  	<v-row v-if="alumno">

      <!-- ALUMNO NORMAL UNICO -->
  		<v-col cols="12" v-if="!hermanos.length">
		  	<div class="text-subtitle-1">
          <span v-if="grupo && grupo.tipoAlumno == 1"><b>ALUMNO NI</b></span>
          <span v-if="grupo && grupo.tipoAlumno == 2"><b>ALUMNO REGULAR</b></span>
          <span v-if="grupo && grupo.tipoAlumno == 3"><b>ALUMNO IRREGULAR</b></span>
          <span v-if="grupo && grupo.tipoAlumno == 4" class="red--text"><b>ALUMNO CON ADEUDO</b></span>
          <span v-if="grupo && grupo.tipoAlumno == 5"><b>ALUMNO PENDIENTE DE ABRIR GRUPO</b></span>
          <span v-if="grupo && grupo.tipoAlumno == 6" class="red--text"><b>ALUMNO CON DIFERENCIA DE PAGO</b></span>
          <br/>
		  		<strong>Matricula:</strong> {{ alumno.matricula }}
		  		<br/>
		  		<strong>Nombre:</strong> {{ alumno.alumno }}
		  	</div>
		  </v-col>

      <!-- SÍ, SÍ SON HERMANOS -->
      <v-col cols="12" v-else-if="hermanos.length">
        <div class="font-weight-semibold red--text">CANT. DE HERMANOS: {{ hermanos.length }}</div>

        <!-- TIPO DE ALUMNOS -->
        <span v-if="hermanos[posicionHermano].tipoAlumno == 1"><b>ALUMNO NI</b></span>
        <span v-if="hermanos[posicionHermano].tipoAlumno == 2"><b>ALUMNO REGULAR</b></span>
        <span v-if="hermanos[posicionHermano].tipoAlumno == 3"><b>ALUMNO IRREGULAR</b></span>
        <span v-if="hermanos[posicionHermano].tipoAlumno == 4" class="red--text"><b>ALUMNO CON ADEUDO</b></span>
        <span v-if="hermanos[posicionHermano].tipoAlumno == 5"><b>ALUMNO PENDIENTE DE ABRIR GRUPO</b></span>
        <span v-if="hermanos[posicionHermano].tipoAlumno == 6" class="red--text"><b>ALUMNO CON DIFERENCIA DE PAGO</b></span>
        <br/>

        <!-- INFORMACIÓN DE LOS HERMANOS -->
        <div class="text-subtitle-1 mb-4">
          <strong>Matricula:</strong> {{ hermanos[posicionHermano].matricula }}
          <br/>
          <strong>Nombre:</strong> {{ hermanos[posicionHermano].alumno }}
        </div>

        <!-- HERMANO ES NI -->
        <v-row justify="end" v-if="hermanos.length && tipoAlumno == 1">

          <v-col cols="12" md="4" lg="3">
            <v-autocomplete
              label="Ciclo"
              dense
              hide-details
              v-model="ciclo"
              :items="ciclos"
              item-text="ciclo"
              item-value="id_ciclo"
              clearable
              filled
              single-line
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" lg="6">
            <v-text-field
              v-model="search"
              label="Buscar"
              dense
              filled
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <!-- HERMANOS NI -->
        <v-data-table
          v-if="hermanos[posicionHermano].tipoAlumno == 1"
          :headers="headers"
          :items="filterGrupos"
          class="elevation-0"
          :mobile-breakpoint="100"
          dense
          :loading="cargar"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn 
              color="primary" 
              x-small
              @click="seleccionarGrupoHermanos( item )"
              class="mr-2"
            >
              <v-icon small>mdi-check</v-icon>
            </v-btn>
          </template>

          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="getGruposDisponibles()"
              small
            >
              Actualizar
            </v-btn>
          </template>
        </v-data-table>

        <!-- HERMANOS CON ADEUDO -->
        <v-col cols="12" v-if="grupos && [4].includes(tipoAlumno)">
          <div class="text-subtitle-1 red--text">
            <strong>ALUMNO CON ADEUDO</strong>
            <br/>
          </div>
        </v-col>
      </v-col>

      <!-- Alumno NI -->
		  <v-col cols="12" v-if="grupo && ![2,4,5,6].includes(grupo.tipoAlumno) && !hermanos.length">
        <v-row justify="end">
          <v-col cols="12" md="4" lg="3">
            <v-autocomplete
              label="Ciclo"
              dense
              hide-details
              v-model="ciclo"
              :items="ciclos"
              item-text="ciclo"
              item-value="id_ciclo"
              clearable
              filled
              single-line
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" lg="6">
            <v-text-field
              v-model="search"
              label="Buscar"
              dense
              filled
              single-line
              hide-details
              append-icon="mdi-magnify"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <!-- GRUPOS NI ALUMNOS -->

      <!-- Aquí hay que poner algo para los que son de tipo 06 -->
      <v-col cols="12" v-if="grupo && [6].includes(grupo.tipoAlumno) && !hermanos.length">
        <div class="text-subtitle-1 mb-4">
          <strong>GRUPO:</strong> {{ grupo.getUltimoGrupoPagado.grupo }}
          <br/>
          <strong>DIFERENCIA:</strong> $ {{ grupo.getUltimoGrupoPagado.diferencia }}
          <br/>
          <br/>

          <v-alert
            dense
            outlined
            type="error"
          >
            {{ grupo.message }}
          </v-alert>

        </div>
      </v-col>

      <!-- TIPO 1 que SON LOS NI -->
      <v-col cols="12" v-if="grupo && ![2,4,5,6].includes(grupo.tipoAlumno) && !hermanos.length">
		  	<v-data-table
          :headers="headers"
          :items="filterGrupos"
          class="elevation-0"
          :mobile-breakpoint="100"
          dense
          :loading="cargar"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn 
              color="primary" 
              x-small
              v-if="( item.cupo - item.inscritos ) > 0"
              @click="seleccionarGrupoManual( item )"
              class="mr-2"
            >
              <v-icon small>mdi-check</v-icon>
            </v-btn>
          </template>

          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="getGruposDisponibles()"
              small
            >
              Actualizar
            </v-btn>
          </template>
        </v-data-table>
		  </v-col>

		  <v-divider></v-divider>

      <!-- grupos Alumno unico -->
		  <v-col cols="12" md="6" v-if="grupo && [2,4,5].includes(grupo.tipoAlumno) && !hermanos || grupo && [2,4,5].includes(grupo.tipoAlumno) && !hermanos.length">
		  	<div class="text-subtitle-1">
		  		<strong>Grupo actual:<br/></strong> 
		  		{{ grupo.grupoActual.grupo }}
		  		<br/>

          <strong class="red--text">Adeudo: $ {{ grupo.grupoActual.adeudo }}<br/></strong> 
		  	</div>

        <!-- VER SI LA PERSONA REQUIERE FACTURA -->
        <div class="text-subtitle-1" v-if="grupo.grupoActual.adeudo <= 0">
          <strong>
            <br/>
            <v-btn 
              color="green"
              dark 
              small 
              @click="realizarFactura()"
              tile
            >
              <v-icon small left>mdi-cash</v-icon>
              Pagar Factura
            </v-btn>
          </strong>
        </div>
		  </v-col>

      <!-- Seleccionar otro grupo, alumnos NO HERMANOS -->
		  <v-col cols="12" md="6" v-if="grupo && [2,4,5].includes(grupo.tipoAlumno) && !hermanos || grupo && [2,4,5].includes(grupo.tipoAlumno) && !hermanos.length">
		  	<div class="text-subtitle-1" v-if="grupo.grupoSiguiente">
		  		<strong>Grupo siguiente:<br/></strong>
		  		<span v-if="!otroGrupo">{{ grupo.grupoSiguiente.grupo }}</span>
          <span v-if="grupo.capacidad">El siguiente grupo no cuenta con capacidad para inscribir alumnos</span>
		  	  <v-switch label="Seleccionar otro grupo" v-model="otroGrupo" hide-details v-if="grupo.grupoActual.adeudo <= 0"></v-switch>
        </div>

        <div class="text-subtitle-1" v-if="!grupo.grupoSiguiente">
          <strong>{{ errorMensaje }}
            <br/>
          </strong>
          <span v-if="!otroGrupo"></span>
          <v-switch label="Seleccionar otro grupo" v-model="otroGrupo" hide-details></v-switch>
        </div>
		  </v-col>

      <!-- SELECCIONAR UN GRUPO DIFERENTE -->
      <v-col cols="12" v-if="otroGrupo && grupo && [1,2,3,4,5].includes(grupo.tipoAlumno) && !hermanos.length">

        <v-row>
          <v-col cols="12" md="6" lg="4">
            <v-autocomplete
              label="Ciclo"
              dense
              v-model="ciclo"
              :items="ciclos"
              item-text="ciclo"
              item-value="id_ciclo"
              clearable
              filled
              single-line
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-text-field
          v-model="search"
          label="Buscar"
          dense
          filled
          single-line
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="filterGrupos"
          class="elevation-0"
          :mobile-breakpoint="100"
          dense
          :loading="cargar"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn 
              color="primary" 
              x-small
              v-if="( item.cupo - item.inscritos ) > 0"
              @click="seleccionarDiferenteGrupo( item )"
              class="mr-2"
            >
              <v-icon small>mdi-check</v-icon>
            </v-btn>
          </template>

          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="getGruposDisponibles()"
              small
            >
              Actualizar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>

      <!-- GRUPOS HERMANOS -->
      <v-col cols="12" md="6" v-if="grupos && [2,4].includes(tipoAlumno) || grupos && [2,4].includes(tipoAlumno) && !hermanos.length">
        <div class="text-subtitle-1">
          <strong>Actual:<br/></strong> 
          {{ grupos[posicionHermano].getGrupoActual.grupo }}
          <br/>
        </div>
      </v-col>

      <v-col cols="12" md="6" v-if="grupos && [2,4].includes(tipoAlumno) || grupos && [2,4].includes(tipoAlumno) && !hermanos.length">
        <div class="text-subtitle-1">
          <strong>Siguiente:<br/></strong>
          <span v-if="!otroGrupo2">{{ grupos[posicionHermano].grupoSiguiente ? grupos[posicionHermano].grupoSiguiente.grupo : `Favor de solicitar abrir el grupo que le sigue a: ${ grupos[posicionHermano].getGrupoActual.grupo }` }}</span>
          <v-switch label="Seleccionar otro grupo" v-model="otroGrupo2" hide-details></v-switch>
        </div>
      </v-col>
      <!-- FIN DE GRUPOS HERMANOS -->
    </v-row>

    <!-- SELECCIONAR UN GRUPO DIFERENTE PARA HERMANOS -->
    <v-row>
      <v-col cols="12" v-if="otroGrupo2 && hermanos.length">
        <v-autocomplete
          label="Ciclo"
          dense
          hide-details
          v-model="ciclo"
          :items="ciclos"
          item-text="ciclo"
          item-value="id_ciclo"
          clearable
          filled
          single-line
        ></v-autocomplete>
        
        <v-text-field
          v-model="search"
          label="Buscar"
          dense
          filled
          single-line
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="grupos2"
          class="elevation-0"
          :mobile-breakpoint="100"
          dense
          :loading="cargar"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn 
              color="primary" 
              x-small
              v-if="( item.cupo - item.inscritos ) > 0"
              @click="grupos[posicionHermano].grupoSiguiente = item, otroGrupo2 = false"
              class="mr-2"
            >
              <v-icon small>mdi-check</v-icon>
            </v-btn>
          </template>

          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="getGruposDisponibles()"
              small
            >
              Actualizar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-divider class="mt-6"></v-divider>

    <v-card-actions class="pb-0">
      <v-btn
        color="black"
        dark
        small
        tile
        @click="alertaSeguridad = true"
        class="text-capitalize"
      >
        <v-icon left small>mdi-chevron-double-left</v-icon>
        regresar
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn
        v-if="grupo && [2,4].includes(grupo.tipoAlumno) && !hermanos.length"
        color="green"
        dark
        small
        tile
        class="text-capitalize"
        @click="siguienteStep()"
      >
        <v-icon left small>mdi-cash</v-icon>
        Ir al pago
      </v-btn>

      <!-- BOTONES DE HERMANOS -->
      <v-btn
        v-if="hermanos && hermanos.length && posicionHermano < ( hermanos.length - 1 )"
        color="secondary"
        small
        rounded
        class="text-capitalize"
        @click="posicionHermano += 1"
      >
        <v-icon left>mdi-skip-next</v-icon> Siguiente hermano
      </v-btn>

      <v-btn
        v-if="hermanos && hermanos.length && posicionHermano == ( hermanos.length - 1 )"
        color="secondary"
        small
        rounded
        class="text-capitalize"
        @click="posicionHermano -= 1"
      >
        <v-icon left>mdi-skip-previous</v-icon> Hermano anterior
      </v-btn>

      <v-btn
        v-if="hermanos && hermanos.length"
        color="green"
        dark
        rounded
        small
        class="text-capitalize"
        @click="siguienteStepHermanos()"
      >
        <v-icon left>mdi-cash</v-icon> Ir al pago
      </v-btn>

      <!-- FIN DE LOS BOTONES DE HERMANOS -->

    </v-card-actions>

    <v-dialog
      v-model="alertaSeguridad"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
        </v-card-title>
        <v-card-text primary-title class="black--text align-center">
         Al momento de regresar se eliminará todo registro del alumno que acabas de crear y tendrás que llenar los datos nuevamente.
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="black"
            small
            dark
            @click="alertaSeguridad = false"
            tile
            class="text-capitalize"
          >
            <v-icon left small>mdi-cacel</v-icon>
            regresar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            tile
            small
            class="text-capitalize"
            @click="eliminarDatosAlumno( )"
          >
            Aceptar
          </v-btn>

        </v-card-actions>
      </v-card>
     </v-dialog>


  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-card>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

  	props:[
			'alumno',
      'noHermanos'
	  ],

    data: () => ({
    	escuela: 2,
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      grupos: [],
      headers: [
        { text: 'ID'          , value: 'id_grupo'   },
        { text: 'Grupo'       , value: 'grupo'      },
        { text: 'Nivel'       , value: 'nivel'      },
        { text: 'Cupo'        , value: 'cupo'       },
        { text: 'Inscritos'   , value: 'inscritos'  },
        { text: 'Actions'     , value: 'actions'     , sortable: false },
      ],

      grupo:null,
      grupos:[],
      grupos2:[],
      hermanos:[],
      tipoAlumno:0,
      posicionHermano:0,
      otroGrupo: false,
      otroGrupo2: false,
      errorMensaje: '',
      message: '',

      ciclos:[],
      ciclo: null,
      alertaSeguridad: false
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),

      filterGrupos( ){
        if( this.ciclo ){
          return this.grupos.filter( el => { return el.id_ciclo == this.ciclo })
        }else{
          return this.grupos
        }
      }
    },

    watch: {
      otroGrupo ( val ){ if( val ){ this.getGruposDisponibles() } },

      otroGrupo2 ( val ){ if( val ){ this.getGruposDisponibles2() } },

    	alumno ( ){ if( this.alumno ){ this.iniciarValidaciones( ) } }
    },

    async created () {
      if( this.alumno ){ await this.iniciarValidaciones() }

      this.getCiclos()
    },


    methods: {
      async iniciarValidaciones( ){
        this.cargar = true
        let hermanos = await this.validarHermano( ).then( response => response )

        hermanos = this.noHermanos ? hermanos : null

        if( hermanos && hermanos.hermanos.length ){ 
          this.hermanos    = hermanos.hermanos
          this.tipoAlumno  = hermanos.tipoAlumno
          this.grupos      = []

          for( const i in this.hermanos ){
            this.hermanos['tipoAlumno'] = this.tipoAlumno
          }

          for( const i in hermanos.hermanos){
            if( typeof(hermanos.hermanos[i]) == 'object' ){
              hermanos.hermanos[i]['tipoAlumno'] = this.tipoAlumno
              this.grupos.push( hermanos.hermanos[i] )
            }        
          }

          if( this.tipoAlumno == 1 ){ this.getGruposDisponibles( ) }
          
          this.cargar = false
          
          return
        }

        this.getGruposAlumno()
        
        this.cargar = false
      },

      getCiclos () {
        this.cargar = true
        this.ciclos = []
        return this.$http.get('ciclos.activos.erp').then(response=>{
          console.log(  response.data )
          this.ciclos = response.data.filter( el => el.activo_ventas )
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      eliminarDatosAlumno( ){
        this.cargar = true

        const payload = {
          alumno: this.alumno,
          hermanos: this.hermanos
        }

        this.$http.post('matriculas.eliminar.sinpago', payload ).then(response=>{
          this.alertaSeguridad = true
          this.$emit('regresar', 1 )
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      validarHermano( ){
        return new Promise(( resolve, reject ) => {
          const payload = {
            id_alumno: this.alumno.id_alumno,
            escuela  : this.alumno.unidad_negocio
          }

          this.$http.post('validar.hermanos', payload).then(response=>{
            resolve( response.data )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },

      async getGruposAlumno( ) {
      	this.cargar = true
      	this.grupo  = null

      	const payload = {
      		id_alumno: this.alumno.id_alumno,
      		escuela  : this.alumno.unidad_negocio
      	}

        try {
          await this.$http.post('inscripciones.grupo.alumno', payload).then(response=>{
          	this.cargar = false
          	this.grupo = response.data

            if( this.grupo && ![2,4].includes(this.grupo.tipoAlumno)){
              this.getGruposDisponibles( )
            }
          }).catch( error =>{
            if( error.response.data.grupoActual ){
              this.grupo = error.response.data
            }
            this.errorMensaje = error.response.data.message
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }catch ( error ){
          console.log( '2',error )
        }
      },

      getGruposDisponibles( ) {
        this.cargar = true
        this.grupos = []

        return this.$http.get('grupos.disponibles/' + this.alumno.unidad_negocio ).then(response=>{
          this.cargar = false
          this.grupos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getGruposDisponibles2( ) {
        this.cargar = true
        this.grupos2 = []

        return this.$http.get('grupos.disponibles/' + this.alumno.unidad_negocio ).then(response=>{
          this.cargar = false
          this.grupos2 = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      siguienteStep( ){
        this.$emit('grupoSeleccionado', this.grupo )
      },

      siguienteStepHermanos( ){
        this.$emit('grupoSeleccionado', this.hermanos )
      },

      seleccionarGrupoManual( grupo ){
        const siguienteGrupo = { tipoAlumno: this.grupo.tipoAlumno, grupoSiguiente: grupo }
        this.$emit('grupoSeleccionado', siguienteGrupo )
      },

      seleccionarDiferenteGrupo( grupo ){
        const siguienteGrupo = { tipoAlumno: this.grupo.tipoAlumno, grupoSiguiente: grupo }
        console.log( siguienteGrupo )
        this.$emit('grupoSeleccionado', siguienteGrupo )
      },

      seleccionarGrupoHermanos( grupo ){
        // Validar que sean del mismo cursooo
        this.hermanos[ this.posicionHermano ]['grupoSiguiente'] = grupo
        this.hermanos[ this.posicionHermano ]['tipoAlumno']     = this.tipoAlumno
        this.hermanos[ this.posicionHermano ]['hermanos']       = true

        if( this.posicionHermano == (this.hermanos.length - 1) ){
          this.$emit('grupoSeleccionado', this.hermanos )
        }else{
          // Validar que sean el mismo cursooo
          this.posicionHermano += 1
        }
      },

      realizarFactura( ){
        let payload = Object.assign({}, this.grupo) 
        payload.grupoSiguiente = this.grupo.grupoActual
        payload.factura        = true

        this.$emit('seleccionaFactura', payload )
      }
    },
  }
</script>