<template>
	<v-container fluid>
		<v-subheader class="pa-0"><strong>ID: {{ id_nuevo_grupo }}</strong> </v-subheader>
	  <v-row>
    	<v-col cols="12">
        <v-text-field
          v-model="nombreGrupo"
          label="* Grupo"
          filled
          hide-details
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
        	:items="planteles"
          v-model="siguienteGrupo.id_plantel"
          label="Plantel"
          outlined
          hide-details
          dense
          item-text="plantel"
          item-value="id_plantel"
          :readonly="editar"
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
        	:items="cursos"
          v-model="siguienteGrupo.id_curso"
          label="Curso"
          outlined
          hide-details
          dense
          item-text="curso"
          item-value="id_curso"
          :readonly="editar"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
        	:items="ciclos"
          v-model="siguienteGrupo.id_ciclo"
          label="Ciclo"
          outlined
          hide-details
          dense
          item-text="ciclo"
          item-value="id_ciclo"
          :readonly="editar"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
        	:items="horarios"
          v-model="siguienteGrupo.id_horario"
          label="Horario"
          outlined
          hide-details
          dense
          item-text="horario"
          item-value="id_horario"
          :readonly="editar"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
        	:items="salones"
          v-model="siguienteGrupo.id_salon"
          label="Salon"
          outlined
          hide-details
          dense
          item-text="salon"
          item-value="id_salon"
          :readonly="editar"
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
        	:items="niveles"
          v-model="siguienteGrupo.id_nivel"
          label="Nivel"
          outlined
          hide-details
          dense
          item-text="nivel"
          item-value="id_nivel"
          :readonly="editar"
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="siguienteGrupo.cupo"
          label="Cupo"
          filled
          hide-details
          dense
          :readonly="editar"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="siguienteGrupo.precio"
          label="* precio"
          filled
          hide-details
          dense
          readonly
          :readonly="editar"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" class="pt-0">
      	<v-switch 
      		label="¿Activo Si/No?"
          hide-details
      		v-model="siguienteGrupo.activo_sn"
      		:readonly="editar"
      	>
      	</v-switch>
      </v-col>

      <v-col cols="12" sm="6" class="pt-0">
      	<div class="text-subtitle-1"><b>Fecha alta:</b> {{ siguienteGrupo.fecha_alta }}</div>
      	<div class="text-subtitle-1"><b>Fecha baja:</b> {{ siguienteGrupo.fecha_baja }}</div>
      </v-col>

      <v-col cols="12" align="right" v-if="!editar">
        <v-btn color="primary" @click="save">Guardar</v-btn>
      </v-col>
    </v-row>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

	// Componentes
  export default {

  	components:{
      Alerta,
      carga,
    },

  	props:[
			'editedItem'
	  ],
    data: () => ({

      // tabla
      teachers:[],
      teacher1: null,
      teacher2: null,

      // Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      ciclos:[],
			salones:[],
			cursos:[],
			planteles:[],
			horarios:[],
			niveles:[],

			editar: false,

			plantel:'',
			ciclo:'',
			curso:'',
			horario:'',
			nivel:'',
			salon:'',

			siguienteGrupo: {},
			id_nuevo_grupo: 0
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),

    	nombreGrupo( ){
    		const existe = this.planteles.find( el=> el.id_plantel == this.siguienteGrupo.id_plantel )
    		this.plantel = existe ? existe.plantel.trim() : ''

				const existe2 = this.ciclos.find( el=> el.id_ciclo == this.siguienteGrupo.id_ciclo )
				this.ciclo = existe2 ? existe2.ciclo.trim() : ''

				const existe3 = this.horarios.find( el=> el.id_horario == this.siguienteGrupo.id_horario )
				this.horario = existe3 ? existe3.horario.trim() : ''

				const existe4 = this.niveles.find( el=> el.id_nivel == this.siguienteGrupo.id_nivel )
				this.nivel = existe4 ? existe4.nivel.trim() : ''

				const existe5 = this.cursos.find( el=> el.id_curso == this.siguienteGrupo.id_curso )
				this.curso = existe5 ? existe5.curso.trim() : ''
				this.siguienteGrupo.precio = existe5 ? existe5.precio_nivel : 'NO EXISTE PRECIO'

    		return `${this.plantel} - ${this.ciclo} - ${this.horario} - ${this.nivel}`
    	}
    },

    async created () {

    	this.editar =  this.editedItem.id_grupo_consecutivo > 0 ? true : false
    	this.id_nuevo_grupo = this.editedItem.id_grupo_consecutivo

    	this.siguienteGrupo = Object.assign({}, this.editedItem)
      await this.getCiclos()
      await this.getSalones()
      await this.getCursos()
      await this.getPlanteles()
      await this.getHorarios()
      await this.getNiveles()
    },

    methods: {

    	save () {
  			// Cargamos al usuario que hizo el cambio
      	this.siguienteGrupo['id_usuario']        = this.getdatosUsuario.iderp
      	this.siguienteGrupo['grupo']             = this.nombreGrupo
      	this.siguienteGrupo['disponibles']       = this.siguienteGrupo.cupo
      	this.siguienteGrupo['id_grupo_original'] = this.editedItem.id_grupo
	      this.cargar = true
        // Lo mandapos por el EP
        this.$http.post('grupos.add', this.siguienteGrupo).then(response=>{
        	this.id_nuevo_grupo = response.data.id
        	this.editar = true
        	this.validarSuccess( response.data.message )
        	this.$emit('click')
        	this.initialize()
        }).catch( error =>{
        	this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCiclos () {
      	this.cargar = true
        this.ciclos = []
        return this.$http.get('ciclos.activos.erp').then(response=>{
        	this.ciclos = response.data

        	// Necesitamos sacer el siguiente ciclo
        	// Lo harémos sacando el desglode del ciclo
        	const existe2 = this.ciclos.find( el=> el.id_ciclo == this.siguienteGrupo.id_ciclo )
					if( existe2 ){
						// lo divideremos en dos partes y la segunda parte en otras dos partes 
						const nombreCiclo = existe2.ciclo.split(' ')
				    // Saco la extensión del archivo
				    const numeroCiclo = nombreCiclo[1].split('_')

				    const mesCiclo  = parseInt(numeroCiclo[0]) == 12 ? 1 : ( parseInt(numeroCiclo[0]) + 1 )
				    const anioCiclo = parseInt(numeroCiclo[0]) == 12 ? (parseInt(numeroCiclo[1]) + 1)  : parseInt(numeroCiclo[1])

				    const nuevoCiclo = 'CICLO ' + ( mesCiclo < 10 ? ('0'+mesCiclo) : mesCiclo ) + '_' + anioCiclo + ( this.siguienteGrupo.escuela == 1 ? '' : ' FE')
				    // Buscar el nuevo cilo a ver si ya existe 
				    const existeNuevoCiclo = this.ciclos.find( el => el.ciclo == nuevoCiclo )
            console.log( existeNuevoCiclo )
				    if( existeNuevoCiclo ){
				    	this.siguienteGrupo.id_ciclo = existeNuevoCiclo.id_ciclo
				    }
					}


        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getSalones () {
      	this.cargar = true
        this.salones = []
        return this.$http.get('salones.activos').then(response=>{
        	this.salones = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCursos () {
      	this.cargar = true
        this.cursos = []
        return this.$http.get('cursoserp.activos').then(response=>{
        	this.cursos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getHorarios () {
      	this.cargar = true
        this.horarios = []
        return this.$http.get('horarios.activos').then(response=>{
        	this.horarios = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getNiveles () {
      	this.cargar = true
        this.niveles = []
        return this.$http.get('niveles.activos').then(response=>{
        	this.niveles = response.data
        	const nuevoNivel = this.niveles.find( el => el.id_nivel == this.siguienteGrupo.id_nivel && el.id_nivel_consecutivo > 0)
        	this.siguienteGrupo.id_nivel = nuevoNivel ? nuevoNivel.id_nivel_consecutivo : 0
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      validarErrorDirecto ( mensaje ) {
        this.cargar = false 
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
    	},
    },
  }
</script>


