<template>
	<v-dialog
    v-model="dialogCalendario.estatus"
    persistent
    max-width="1200"
  >
    <v-card>
      <v-card-title class="text-h5">
      	CALENDARIO COMPROMISO
      	<v-spacer></v-spacer>
      	<v-btn
          color="black"
          tile
          dark
          small
          @click="dialogAddTarea = true"
          class="mr-2"
        >
        	<v-icon small left>mdi-plus</v-icon>
        	Agregar
        </v-btn>

        <v-btn
          color="orange"
          tile
          dark
          small
          @click="getCalendario()"
          class="mr-2"
        >
        	<v-icon small left>mdi-refresh</v-icon>
        	Actualizar
        </v-btn>

      	<v-btn
          color="black"
          tile
          dark
          icon
          @click="cerrar()"
        >
        	<v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
      	<v-sheet height="600">
	        <v-calendar
	          ref="calendar"
	          :now="value"
	          :value="value"
	          :events="events"
	          :event-color="getEventColor"
	          color="primary"
	          type="week"
	          v-model="focus"
	          @click:event="showEvent"
	          @click:more="viewDay"
	          @click:date="viewDay"
	        >
	        	
	        	<template v-slot:day-body="{ date, week }">
	            <div
	              class="v-current-time"
	              :class="{ first: date === week[0].date }"
	              :style="{ top: nowY }"
	            ></div>
	          </template>
	        </v-calendar>

	        <v-menu
	          v-model="selectedOpen"
	          :close-on-content-click="false"
	          :activator="selectedElement"
	          offset-x
	        >
	          <v-card
	            color="grey lighten-4"
	            min-width="350px"
	            flat
	          >
	            <v-toolbar
	              :color="selectedEvent.color"
	              dark
	              dense
	            >
	              <!-- <v-btn icon>
	                <v-icon>mdi-pencil</v-icon>
	              </v-btn> -->
	              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
	              <v-spacer></v-spacer>
	            </v-toolbar>
	            <v-card-text>
	            	<label><strong>NOTA</strong></label>
	            	<br/>
	              <div  style="white-space: pre-line">{{ selectedEvent.nota }}</div>

	              <br/>
	              <br/>
	              <label><strong>Horario</strong></label>
	            	<br/>
	              <span >{{ selectedEvent.hora }} a {{ selectedEvent.hora_final }}</span>

	            </v-card-text>
	            <v-card-actions>
	              <v-btn
	                text
	                color="secondary"
	                @click="selectedOpen = false"
	              >
	                Cerrar
	              </v-btn>
	              <v-spacer></v-spacer>
	              <v-btn
	                text
	                color="secondary"
	                @click="eliminar( selectedEvent )"
	              >
	              	<v-icon>mdi-delete</v-icon>
	              </v-btn>
	            </v-card-actions>
	          </v-card>
	        </v-menu>

	      </v-sheet>
      </v-card-text>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>


    <v-dialog
      v-model="dialogAddTarea" 
			:overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          Agregar una nueva nota: {{ prospecto ? prospecto.folio : ''}}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="tarea.dia"
            label="Día"
            dense
            type="date"
            filled
          ></v-text-field>

          <v-text-field
            v-model="tarea.hora"
            label="Hora"
            dense
            type="time"
            filled
            class="mt-2"
          ></v-text-field>

          <v-text-field
            v-model="tarea.hora_final"
            label="Hora"
            dense
            type="time"
            filled
            class="mt-2"
          ></v-text-field>

          <v-radio-group v-model="tarea.accion">
			      <v-radio
			        v-for="( n, i ) in acciones"
			        :key="i"
			        :label="`${n.accion}`"
			        :value="n.idacciones_calendario"
			      ></v-radio>
			    </v-radio-group>

          <v-textarea
            filled
            auto-grow
            rows="3"
            v-model="tarea.nota"
            label="Nota"
            dense
            class="mt-2"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn 
          	color="black"
          	tile
          	small
          	dark
          	@click="dialogAddTarea = false"
          >
          	<v-icon left small>mdi-cancel</v-icon>
          	Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
          	color="success"
          	tile
          	small
          	dark
          	@click="guardar()"
          >
          	<v-icon left small>mdi-content-save</v-icon>
          	Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'dialogCalendario',
			'prospecto'
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
      cal () {
        return this.ready ? this.$refs.calendar : null
      },
      nowY () {
      	console.log( this.cal.times.now )
      	console.log( this.cal.timeToY(this.cal.times.now) )
        return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
      },
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      motivo: '',

      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      events: [],
      focus: '',
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    	
    	dialogAddTarea: false,
    	tarea:{
	    	dia: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
				hora: null,
				hora_final: null,
				nota: '',
				idusuario:0,
				idprospectos: null,
				accion: 0,
    	},
    	acciones:[],
    	value: '',
      ready: false,
    }),

    created(){
    	this.getCalendario()
    	this.getAcciones()

    	if( this.prospecto ){
    		setTimeout(() => {
          this.dialogAddTarea = true
        }, 500)
    	}
    },

    mounted () {
      this.$refs.calendar.checkChange()
      this.ready = true
      this.scrollToTime()
      this.updateTime()
    },

    methods:{

      ...mapActions('login', ['addTareas']),


      cerrar(){
      	this.dialogCalendario.estatus = false
      	this.$emit('getNotificaciones')
      },

      getAcciones( ){
    		this.cargar = true
        // Obtener todos los ciclos
        this.$http.get('micalendario.acciones' ).then(response=>{
        	this.acciones = response.data
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })


      },

      getCalendario( ){
    		this.cargar = true
        	this.addTareas([])
        // Obtener todos los ciclos
        this.$http.get('micalendario/' + this.getdatosUsuario.iderp ).then(response=>{
        	this.events = response.data
        	this.addTareas(this.events)
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      guardar( ){
      	this.tarea.idusuario = this.getdatosUsuario.iderp
    		this.cargar = true

    		if( this.prospecto ){
    			this.tarea.nota += `
Folio: ${this.prospecto.folio}
Nombre: ${this.prospecto.nombre_completo}
Teléfono: ${this.prospecto.telefono}`
    		}
        // Obtener todos los ciclos
        this.$http.post('micalendario', this.tarea ).then(response=>{
        	this.dialogAddTarea = false
        	this.tarea.hora = null
        	this.tarea.hora_final = null
        	this.tarea.nota = ''
        	this.tarea.accion = null
        	this.prospecto = null
        	this.getCalendario()
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      eliminar( data ){
    		this.cargar = true
        // Obtener todos los ciclos
        this.$http.put('micalendario.eliminar/' + data.idcalendario ).then(response=>{
        	this.selectedOpen = false
        	this.getCalendario()
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      getEventColor (event) {
        return event.color
      },

      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      getCurrentTime () {
        return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
      },
      scrollToTime () {
        const time = this.getCurrentTime()
        const first = Math.max(0, time - (time % 30) - 30)

        this.cal.scrollToTime(first)
      },
      updateTime () {
        setInterval(() => this.cal.updateTimes(), 60 * 1000)
      },
    }
	}
</script>
<style lang="scss" scoped>
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
