<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Encargados</span>
            <v-spacer></v-spacer>
            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

          </v-card-title>
          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="8" lg="6">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="desserts"
                  class="elevation-0"
                  :search="search"
                >
                  <template v-slot:item.activo="{ item }">
                    <v-chip v-if="item.activo" dense small color="green" dark>Activo</v-chip> 
                    <v-chip v-else             dense small color="red" dark>Inactivo</v-chip> 
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  
                  <template v-slot:no-data>
                    <v-btn
                      color="primary"
                      @click="initialize"
                    >
                      Reset
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-autocomplete
            :items="areas"
            v-model="editedItem.iddepartamento"
            label="Selecciona area"
            item-value="iddepartamento"
            item-text="departamento"
            filled
          ></v-autocomplete>

          <v-autocomplete
            :items="usuarios"
            v-model="editedItem.id_usuario"
            label="Selecciona usuario"
            item-value="id_usuario"
            item-text="nombre_completo"
            filled
          ></v-autocomplete>

        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            dark
            tile
            small
            @click="close"
          >
            <v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            color="green"
            dark
            tile
            small
            @click="save"
          >
            <v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
    // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores from "@/mixins/validarErrores";


  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [validarErrores ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      areas:[],
    	puestos:[],

      usuarios:[],

      dialog: false,
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: false,
          value: 'idjefe_area',
        },
        { text: 'Nombre' , value: 'nombre_completo' },
        { text: 'Area'   , value: 'departamento'},
        { text: 'Estatus', value: 'activo', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        idjefe_area:0,
        id_usuario:0,
        idareas_ticket:0,
        idpuesto: 0,
      },
      defaultItem: {
        idjefe_area:0,
        id_usuario:0,
        idareas_ticket:0,
        idpuesto: 0,
      },
      search:'',
      puestosUsuario:[],
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar encarado' : 'Editar encargado'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.$http.get('departamentos.all').then(response=>{
          this.areas = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        this.$http.get('usuarios.all.erp').then(response=>{
          this.usuarios = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        this.$http.get('jefe_areas.all').then(response=>{
          this.desserts = response.data.areas.filter( el => { return el.activo == 1 })
          this.puestos  = response.data.puestos
          this.puestosUsuario = response.data.puestosUsuario
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {

        console.log( item )
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {

        // Fatla agregar los puestos
        const existePuesto = this.puestosUsuario.find( el => el.iderp == this.editedItem.id_usuario )
        this.editedItem.idpuesto = existePuesto ? existePuesto.idpuesto : 0 

        this.cargar = true


        if (this.editedIndex > -1) {
          this.$http.put('jefe_areas.update/'+this.editedItem.idjefe_area,this.editedItem).then(response=>{
            this.validarSuccess('Dato actualizado correctamente')
            this.initialize()
            this.close()
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        } else {
          this.$http.post('jefe_areas.add',this.editedItem).then(response=>{
            this.validarSuccess('Dato agregado correctamente')
            this.initialize()
            this.close()
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }
      },
    },
  }
</script>