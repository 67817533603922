<template>
	<v-card class="elevation-0" >
		<!-- Alumno nuevo -->
  	<v-alert
      border="bottom"
      type="info"
      text
      dark
    >
      Selecciona el prospecto por el cual está ligado el alumno, recuerda que el <b>FOLIO</b> de prospecto se da al momento de darle seguimiento, en dado caso de que no exista un prospecto, se puede generar desde aquí.
    </v-alert>
		<br/>

		<label><b>Aquí podrás visualizar los datos el alumno a agregar.</b></label>

		<br/>
		<br/>

		<v-row>
			<v-col cols="12">
				<v-card class="shadowCard" >
		      <v-card-title>
		        Datos del alumno
		      </v-card-title>
		      <v-card-text>
				  	<!-- BUSCAR POR MATRICULA -->
				  	<label class="pl-0 black--text">Prospecto</label>
						<v-row>
							<v-col cols="12" class="pt-0">
								<v-autocomplete
				          filled 
				          dense
				          clearable
				          v-model="alumno.id_prospecto"
				          :items="prospectos"
				          label="Selecciona el Prospecto"
				          hide-details
				          item-text="datos_completos"
				          item-value="idprospectos"
				          class="mt-4"
				        >
				        </v-autocomplete>
							</v-col>
						</v-row>

						<v-btn 
							v-if="!alumno.id_prospecto"
							color="red" 
							dark 
							small 
							class="mt-2 text-capitalize" 
							@click="abrirDialogProspecto()"
							tile
						>
							No existe el prospecto
						</v-btn>

						<v-divider class="my-4"></v-divider>

				    <!-- Datos generales del alumno -->
				    <label class="pl-0 black--text" >Datos generales</label>
						<v-row v-if="alumno.id_prospecto">
							<v-col cols="12" sm="6">
								<v-text-field
								  filled 
								  dense
								  hide-details
								  v-model="alumno.nombre"
								  label="Nombre"
								></v-text-field>
							</v-col>

							<v-col cols="12" sm="6">
								<v-text-field
								  filled 
								  dense
								  hide-details
								  v-model="alumno.apellido_paterno"
								  label="Ap. Paterno"
								></v-text-field>
							</v-col>

							<v-col cols="12" sm="6">
								<v-text-field
								  filled 
								  dense
								  hide-details
								  v-model="alumno.apellido_materno"
								  label="Ap. Materno"
								></v-text-field>
							</v-col>

							<v-col cols="12" sm="6">
								<v-text-field
								  filled 
								  dense
								  hide-details
								  v-model="alumno.edad"
								  label="Edad"
								  type="number"
								></v-text-field>
							</v-col>
						</v-row>

						<!-- Datos del contacto -->
						<v-divider class="my-4"></v-divider>
						<label class="pl-0 black--text" >Datos de contacto</label>
						<v-row v-if="alumno.id_prospecto">
							<v-col cols="12" sm="6">
								<v-text-field
								  filled 
								  dense
								  hide-details
								  v-model="alumno.telefono"
								  label="Teléfono"
								  type="number"
								></v-text-field>
							</v-col>

							<v-col cols="12" sm="6">
								<v-text-field
								  filled 
								  dense
								  hide-details
								  v-model="alumno.celular"
								  label="Celular"
								  type="number"
								></v-text-field>
							</v-col>
						</v-row>
		      </v-card-text>
		    </v-card>
			</v-col>
		</v-row>

		<v-divider class="my-4"></v-divider>

		<v-card class="shadowCard">
			<v-card-title primary-title class="text-capitalize">
			  Datos del tutor
			</v-card-title>
		  <v-card-text>
		    <label class="pl-0 black--text">Datos generales</label>
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field
						  filled
						  dense
						  hide-details
						  v-model="tutor.nombre"
						  label="Nombre"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  filled
						  dense
						  hide-details
						  v-model="tutor.apellido_paterno"
						  label="Ap. Paterno"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  filled
						  dense
						  hide-details
						  v-model="tutor.apellido_materno"
						  label="Ap. Materno"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  filled
						  dense
						  hide-details
						  v-model="tutor.tipo_parentesco"
						  label="Parentezco"
						></v-text-field>
					</v-col>
				</v-row>

				<v-divider class="my-4"></v-divider>
				<label class="pl-0 black--text">Datos de contacto</label>
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field
						  filled
						  dense
						  hide-details
						  v-model="tutor.telefono"
						  label="Teléfono"
						  type="number"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  filled
						  dense
						  hide-details
						  v-model="tutor.celular"
						  label="Celular"
						  type="number"
						></v-text-field>
					</v-col>
				</v-row>
		  </v-card-text>
		</v-card>

		<v-card-actions>
      <v-btn 
      	text 
      	@click="dialogAddAlumno.estatus = false"
      >
        Cancelar
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
      	tile
        color="primary"
        @click="steppers.paso = 2"
      >
        Siguiente
      	<v-icon right small>mdi-arrow-right</v-icon>
      </v-btn>
  	</v-card-actions>


  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <AgregarProspecto     v-if="existeProspecto"             :parametrosProspectos="parametrosProspectos" @saveProspecto="saveProspecto"/>


	</v-card>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import AgregarProspecto   from '@/components/inscripciones/AgregarProspecto.vue';


  import validarErrores  from '@/mixins/validarErrores'

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
      AgregarProspecto
    },

    mixins: [ validarErrores ],

  	props:[
			'grupo',
			'alumno',
			'steppers',
			'hermanos',
			'tutor',
			'dialogAddAlumno',
	  ],

    data: () => ({
    	escuela: 2,
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      cantidadHermanos: 1,

      respuestaAlerta:false,
      cargar: false,

			step:1,
			isTutor:true,

			opcion:1,

			posicionHermano: 0,

			empleados:[],
			sonHermanos:false,
			existeMatricula: false,
    	prospectos:[],
    	parametrosProspectos:{
    		estatus: false
    	},
    	existeProspecto:false,
    	prospecto: null,
    	prospectoSeleccionado: null,
    	alumnos:[],

    	alertaSeguridad: false,

    	headers:[
    		{ text: 'Nombre'       , value: 'nombre'       },
        { text: 'Telefono'     , value: 'telefono'     },
        { text: 'Grupo'        , value: 'grupo'        },
        { text: 'ID prospecto' , value: 'id_prospecto' },
    	]



    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),
    },

    watch:{
    	prospectoSeleccionado( value){
    		if( value ){
    			this.alumno.folio        = value.matricula
    			this.alumno.nombre       = value.alumno
    			this.alumno.telefono     = value.telefono
    			this.alumno.celular      = value.telefono
    			this.alumno.id_contacto  = value.idprospectos
    			this.alumno.id_prospecto = value.idprospectos
    		}
    	},

    	alumno( ){
  			this.alumno = Object.assign({}, this.alumnoVacio)
  			this.tutor       = Object.assign({}, this.tutorVacio)

  			if( this.alumno && this.alumno.idprospectos ){
    			this.alumno.nombre                   = this.alumno.alumno
		    	this.alumno.apellido_paterno         = ''
		    	this.alumno.apellido_materno         = ''
		    	this.alumno.telefono                 = this.alumno.telefono
		    	this.alumno.celular                  = this.alumno.telefono
		    	this.alumno.id_tipo_alumno           = 1
		    	this.alumno.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp
		    	this.alumno.unidad_negocio           = this.getEscuela
		    	this.alumno.id_prospecto             = this.alumno.idprospectos
    		}
    	},
    },

    async created () {
    	this.escuela = this.getEscuela

    	this.alumno.unidad_negocio = this.getEscuela

    	await this.getProspectos()
    	await this.getAlumnos()
    },

    methods: {

    	getProspectos( ){
    		this.cargar    = true
      	this.prospectos = []

        return this.$http.get('inscripciones.prospectos').then(response=>{
        	this.prospectos = response.data
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
    	},

      abrirDialogProspecto( ){
      	this.existeProspecto              = true
      	this.parametrosProspectos.estatus = true
      },

      saveProspecto( value ){
        this.alumno.nombre                   = value.nombre_completo
	    	this.alumno.apellido_paterno         = ''
	    	this.alumno.apellido_materno         = ''
	    	this.alumno.telefono                 = value.telefono
	    	this.alumno.celular                  = value.telefono
	    	this.alumno.id_tipo_alumno           = 1
	    	this.alumno.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp
	    	this.alumno.unidad_negocio           = this.getEscuela
	    	this.alumno.id_prospecto             = value.idprospectos
	    	this.existeMatricula                 = false
    		this.getProspectos()

      },

      getAlumnos( ) {
      	this.cargar = true
      	this.alumnos = []
        return this.$http.get('inscripciones.alumnos').then(response=>{
        	this.cargar = false
        	this.alumnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
    },
  }
</script>
