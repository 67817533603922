<template>
	<div>
		<v-data-table
	    :headers="headers"
	    :items="items"
	    class="elevation-0"
	    :search="search"
	  >	
	  	<!-- Valores que ocupan botons, chips, iconos, etc -->
      <template v-slot:item.foraneo="{ item }">
        <v-switch dense small hide-details v-model="item.foraneo" readonly></v-switch>
      </template>
      
      <!-- Valores que ocupan botons, chips, iconos, etc -->
      <template v-slot:item.estatus="{ item }">
        <v-chip 
          v-if="item.estatus == 1"
          small
          color="green"
          dark
        >Activo</v-chip>

        <v-chip 
          v-else
          small
          color="error"
          dark
        >Inactivo</v-chip>
      </template>

      <!-- Escuela -->
      <template v-slot:item.escuela="{ item }">
        <v-chip small v-if="item.escuela == 1" color="#2EDAD5" dark> INBI </v-chip>
        <v-chip small v-else color="#000D4A" dark> FAST </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn 
        	color="red" 
        	dark 
        	x-small 
        	fab 
        	class="text-capitalize" 
        	@click="preLlenarDatos(item)" 
        	v-if="item.folio == 'SIN FOLIO'"
        >
        	<v-icon>mdi-account-box</v-icon>
        </v-btn>

        <v-btn 
        	color="primary"  
        	x-small 
        	fab 
        	class="text-capitalize" 
        	@click="editarLead(item)"
        >
        	<v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>

	  <template v-slot:item.respondio="{ item }">
        <v-checkbox v-model="item.respondio" label="" @change="respondio(item)"></v-checkbox>
      </template>

	    <!-- Valores que ocupan botons, chips, iconos, etc -->
	    <template v-slot:item.estatus="{ item }">
	      <v-chip 
	      	v-if="item.estatus == 1"
	      	small
	      	color="green"
	      	dark
	      >Activo</v-chip>

	      <v-chip 
	      	v-else
	      	small
	      	color="error"
	      	dark
	      >Inactivo</v-chip>
	    </template>

	    <template v-slot:no-data>
	      <v-btn
	        color="primary"
	        @click="initialize"
	        small
	      >
	        Actualizar
	      </v-btn>
	    </template>
	  </v-data-table>
	</div>
</template>

<script>
import axios from 'axios';
	export default {
		props:[
			'items',
			'editedItem',
			'defaultItem',
			'search'
	  ],

    data: () => ({
	  	// Datos de la tabla
      headers: [
        { text: 'ID'               , value: 'idleds',         },
        { text: 'Folio'            , value: 'folio'           },
        { text: 'Teléfono'         , value: 'telefono'        },
        { text: 'Nombre'           , value: 'nombre_completo' },
        { text: 'Cómo nos conocio' , value: 'medio'           },
        { text: 'Escuela'          , value: 'escuela'         },
        { text: 'Curso'            , value: 'curso'           },
        { text: 'Suc. Intéres'     , value: 'sucursal_interes' },
        { text: 'Actions'          , value: 'actions', sortable: false },
		{ text: 'Respondió'        , value: 'respondio', sortable: false },
      ],
	  }),

	  methods:{
		  editItem( value ){
		  	this.$emit('editar', value)
		  },

		  deleteItem( value ){
		  	this.$emit('eliminar', value)
		  },

		  initialize( ){
		  	this.$emit('actualizar')
		  },

		  preLlenarDatos( item ){
		  	this.$emit('pasarAcontacto', item )
		  },

		  editarLead( item ){
		  	this.$emit('editar', item )
		  },

		  respondio( item ){
			this.$emit('respondio', item )
			// this.$http.put('leds.update.respondio', item).then(response=>{
			// 	this.cargar      = false
			// }).catch( error =>{
			// 	console.log(error)
			// }).finally( () => { this.cargar = false })
		  }


	  }



	}
</script>