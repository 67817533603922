<template>
  <v-container class="mt-4 ml-2">
    <v-row>
      <v-col cols="12" md="10" lg="8">
        <v-data-table
          :headers="headers"
          :items="desserts"
          sort-by="calories"
          class="elevation-6"
          :search="search"
          >

          <template v-slot:item.activo="{ item }">
            <v-chip v-if="item.activo" dense small color="green"></v-chip> 
            <v-chip v-else             dense small color="red"></v-chip> 
          </template>

          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>Auxiliares</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="500px"
               >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    rounded
                    small
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Agregar auxiliar
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" >
                          <v-autocomplete
                            :items="usuarios"
                            v-model="usuario"
                            label="Selecciona usuario"
                            return-object
                            item-value="id_usuario"
                            item-text="nombre_completo"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" >
                          <v-select
                            :items="areas"
                            v-model="area"
                            label="Selecciona area"
                            return-object
                            item-value="idareas_ticket"
                            item-text="area"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                label="Buscar"
                outlined
                dense
                v-model="search"
              ></v-text-field>
            </v-card-text>

          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="getAuxiliares()"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <div class="text-center">
      <v-dialog
        v-model="overlay"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            Espera por favor
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

  </v-container>
</template>
<script>
import axios from 'axios';
  export default {
    data: () => ({
      overlay:false,
      areas:[],
      area:{idareas_ticket:0,area:''},
      usuarios:[],
      usuario:{id_usuario:0,nombre_completo:''},
      dialog: false,
      search:'',
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: false,
          value: 'idauxi_area',
        },
        { text: 'Nombre', value: 'nombre_completo' },
        { text: 'Area', value: 'area' },
        { text: 'Estatus', value: 'activo', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        idauxi_area:0,
        id_usuario:0,
        idareas_ticket:0
      },
      defaultItem: {
        idauxi_area:0,
        id_usuario:0,
        idareas_ticket:0
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar encarado' : 'Editar encargado'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    async created () {
      this.overlay = true
      await this.getAreas()
      await this.getUsuarios()
      await this.getAuxiliares()
      this.overlay = false
    },

    methods: {
      getAreas () {
        this.$http.get('areas.all').then(response=>{
          this.areas = response.data
        }).catch(error=>{
          this.overlay = false
        })
      },

      getUsuarios () {
        this.$http.get('usuarios.all.erp').then(response=>{
          this.usuarios = response.data
        }).catch(error=>{
          this.overlay = false
        })
      },

      getAuxiliares () {
        this.$http.get('auxiliares.all').then(response=>{
          this.desserts = response.data
        }).catch(error=>{
          this.overlay = false
        })
      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.area = {idareas_ticket: this.editedItem.idareas_ticket, area: this.editedItem.area}
        this.usuario = {id_usuario: this.editedItem.id_usuario, nombre_completo: this.editedItem.nombre_completo}
        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        this.editedItem.idareas_ticket = this.area.idareas_ticket
        this.editedItem.id_usuario = this.usuario.id_usuario
        if (this.editedIndex > -1) {
          this.$http.put('auxiliares.update/'+this.editedItem.idauxi_area,this.editedItem).then(response=>{
            this.swalSuccess('Agregardo correctamente')
            this.getAuxiliares()
          }).catch(error=>{console.log(error)})
        } else {
          this.$http.post('auxiliares.add',this.editedItem).then(response=>{
            this.getAuxiliares()
          }).catch(error=>{console.log(error)})
        }
        this.close()
      },

      swalSuccess(text){
        this.$swal({
          position: 'top-end',
          icon: 'success',
          title: text,
          showConfirmButton: false,
          timer: 1500
        })
      },
    },
  }
</script>