<template>
  <!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="paramsHistorial.estatus"
    max-width="400"
  >
    <v-card>
    	<v-card-title>
    		Historial
    	</v-card-title>
    	<v-card-text>
        <v-container class="py-0" v-for="(respuestas, i) in alumno.historial" :key="i" >
			    <v-row class="mt-4" v-if="getdatosUsuario.iderp != respuestas.idusuario">
			      <v-col cols="12" class="pa-0 d-flex justify-start">
			        <p class="pa-2 mb-0 mensajeIzq">
			          {{ respuestas.comentario }}
			          <br>
			        </p>
			      </v-col>
			    </v-row>
			    <v-row v-if="respuestas.fotos.length && getdatosUsuario.iderp != respuestas.idusuario">
			      <v-col cols="3" v-for="(img, i) in respuestas.fotos" :key="i">
			        <v-card class="py-4 shadowCard" @click="verImagen( img.imagen)">
			          <v-img  :src="url + img.imagen"  contain aspect-ratio="2"></v-img>
			        </v-card>
			      </v-col>
			    </v-row>

			    <v-row class="mt-4" v-if="getdatosUsuario.iderp == respuestas.idusuario">
			      <v-col cols="12" class="pa-0 d-flex justify-end">
			        <p class="pa-2 mb-0 mensajeDer">
			          {{ respuestas.comentario }}
			          <br>
			        </p>
			      </v-col>
			    </v-row>
			    <v-row v-if="respuestas.fotos.length && getdatosUsuario.iderp == respuestas.idusuario">
			      <v-col cols="4" v-for="(img, i) in respuestas.fotos" :key="i">
			        <v-card class="py-4 shadowCard" @click="verImagen( img.imagen)">
			          <v-img  :src="url + img.imagen"  contain aspect-ratio="2"></v-img>
			        </v-card>
			      </v-col>
			    </v-row>
        </v-container>
      </v-card-text>

    	<v-card-actions>
		    <v-btn 
		    	color="dark" 
		    	tile 
		    	dark
		    	small 
		    	@click="paramsHistorial.estatus = false"
		    >
		  		Cancelar
		  		<v-icon small right>mdi-cancel</v-icon>
		  	</v-btn>
		    <v-spacer></v-spacer>
    	</v-card-actions>
    </v-card>

    <!-- Ver evidencias -->
    <v-dialog v-model="dialogImagen" max-width="1000px">
      <v-card class="elevation-0">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogImagen = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-img
            :src=" url + imagen" 
            contain
            max-height="80vh"
          >
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>


<script>
	import { mapGetters } from 'vuex'
	import axios from 'axios';

	export default {
		props:[
			'paramsHistorial',
			'alumno'
	  ],

		data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
      url:'',

      imagen:'',
      dialogImagen: false
    }),

    async created () {
      this.url = axios.defaults.baseURL + 'evidencia-cambios/'
    },

	  methods:{

	  	confirmar( ){
	  		this.$emit('addCambio')
	  		this.paramsHistorial.estatus = false
	  	},

	  	verImagen( imagen ){
        this.imagen       = imagen
        this.dialogImagen = true
      },
	  
	  },

	  computed:{
      ...mapGetters ('login',['getdatosUsuario']),
    }
	}
</script>

<style>
  .mensajeIzq{
    white-space: pre-line;
    background-color: rgb(238, 235, 235);
    border-radius: 0px 10px 10px 10px;
  }
  .mensajeDer{
    white-space: pre-line;
    background-color: rgb(193, 231, 243);
    border-radius: 10px 0px 10px 10px;
  }
</style>