<template>
	<v-container class="mt-4 px-5" fluid>
	  	<v-row>
			<v-col cols="12">
				<v-card class="shadowCard">
				<v-card-title primary-title class="pl-6">
					Configuración de empleados
					<v-spacer></v-spacer>
					<v-btn color="green" dark @click="guardarConf()" small tile>
						<v-icon left small>mdi-content-save</v-icon> Guardar
					</v-btn>
				</v-card-title>
				<v-card-text class="py-0">
					<v-row justify="space-between">
						<v-col cols="12" md="5" class="pb-0 pl-6">
							<v-autocomplete filled dense clearable v-model="usuario" :items="usuarios"
								label="Selecciona un usuario" persistent-hint
								prepend-inner-icon="mdi-account" hide-details
								return-object item-text="nombre_completo" item-value="id_usuario">
							</v-autocomplete>
						</v-col>
						<v-col cols="12" md="5" class="pb-0 pl-6">
							<v-autocomplete filled dense clearable v-model="puesto" :items="puestos" 
								label="Selecciona un puesto" persistent-hint item-text="puesto"
								item-value="idpuesto" prepend-inner-icon="mdi-account-box"
								hide-details return-object >
							</v-autocomplete>
						</v-col>
						<v-col cols="12" v-if="alertaUsuarioNoExiste" class="pb-0 pl-6">
							<v-alert dismissible color="cyan" border="left" elevation="2"
								colored-border icon="mdi-account-alert" >
								El usuario seleccionado aún no cuenta con un puesto, favor de ingresar el puesto del usuario
							</v-alert>
						</v-col>
					</v-row>

					<v-container v-if="estatusUsuario">
						<!-- Datos -->
						<v-row v-if="usuario != null">
							<v-col cols="12" sm="4" md="2" >
								<v-checkbox v-model="usuarioRH.deleted" label="Eliminado" hide-details ></v-checkbox>
							</v-col>
							<v-col cols="12" sm="4" md="2" >
								<v-checkbox v-model="usuarioRH.prioridad" label="Elite" hide-details></v-checkbox>
							</v-col>
							<v-col cols="12" sm="4" md="2" >
								<v-checkbox v-model="usuarioRH.online" label="ONLINE" hide-details></v-checkbox>
							</v-col>
						</v-row>

						<!-- Disponibilidad -->
						<v-row v-if="usuario != null">
							<v-col cols="12" class="pb-0 pt-2">
								<v-card class="elevation-0">
								<v-card-title primary-title class="pb-0">
									Disponibilidad
									<!-- <v-btn color="orange" dark tile small  @click="dialogHoraAdd()" >
										<v-icon small left>mdi-calendar-clock</v-icon>
										Agregar horario
									</v-btn> -->
								</v-card-title>

								<v-card-text class="mt-6">
									<v-row>
										<v-col cols="12" sm="6" lg="4" xl="3" v-for="(dia, i) in dias" :key="i">
											<v-card class="shadowCard">
												<v-card-title primary-title>
													{{ dia.dia }}
													<v-spacer></v-spacer>
												</v-card-title>
												<v-card-text>
													<v-container class="ma-0 pa-0">
														<v-data-table dense
															:headers="headers" :items="dispUsuarioFiltrada[dia.valor]"
															item-key="name" class="elevation-0"
															hide-default-footer :items-per-page="100"
															:mobile-breakpoint="200"
														>
															<template v-slot:item.actions="{ item }">
																<!-- <v-icon small class="mr-2" @click="editarHoraF(item)"> mdi-pencil </v-icon> 
																<v-icon small @click="deleteItem(item)" color="primary"> mdi-delete </v-icon> -->
															</template>
														</v-data-table>	 
													</v-container>	  
												</v-card-text>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
								</v-card>
							</v-col>
						</v-row>

						<v-row v-if="usuario != null">
							<v-col cols="12" md="6" class="pb-0">
								<v-card class="elevation-0 transparent">
								<v-card-title primary-title class="pb-0">
									Planteles
									<v-spacer></v-spacer>
									<v-btn color="orange" dark class="mr-2" @click="dialogAddPlantel = true" small tile>
										<v-icon small>mdi-plus</v-icon>
									</v-btn>
								</v-card-title>
								<v-card-text class="py-0">
									<v-data-table :items="plantelesUsuario" :headers="headersPlanteles" dense>
										<template v-slot:item.plantel="{ item }">
											{{ planteles.find(el=>el.idplantel == item.idplantel).plantel }}
										</template>

										<template v-slot:item.actions="{ item }">
											<!-- <v-icon small class="mr-2" @click="editarHoraF(item)"> mdi-pencil </v-icon> -->
											<!-- <v-icon small @click="deletePlantel(item)" color="primary"> mdi-delete </v-icon> -->
										</template>
									</v-data-table>
								</v-card-text>
								</v-card>
							</v-col>

							<!-- <v-col cols="12" md="6" lg="6" class="pb-0">
								<v-card class="elevation-0 transparent">
								<v-card-title primary-title class="pb-0">
									Niveles
								</v-card-title>
								<v-card-text class="py-0">
									<v-data-table v-model="selectNivel" :headers="headersNivel" :items="niveles" item-key="idniveles" show-select color="primary" 
									class="elevation-1" dense mobile-breakpoint="200" fixed-header height="350" :items-per-page="niveles.length" hide-default-footer>
										<template v-slot:item.curso="{ item }">
											<v-chip small dark color="blue lighten-2" v-if="item.esTeens==1">Teens</v-chip>
											<v-chip small dark color="blue lighten-2" v-else-if="item.esKids==1">Kids</v-chip>
											<v-chip small dark color="blue lighten-2" v-else>Adultos</v-chip>
										</template>
										<template v-slot:item.unidad_negocio="{ item }">
											<v-chip small dark color="blue lighten-2" v-if="item.unidad_negocio==1">INBI</v-chip>
											<v-chip small dark color="blue darken-2" v-else>FAST</v-chip>
										</template>
										<template v-slot:item.nivel="{ item }">
											<span>{{ item.nombrenivel }}</span>
										</template>
									</v-data-table>
								</v-card-text>
								</v-card>
							</v-col> -->
						</v-row>

					</v-container>
				</v-card-text>
				<br>
				<v-card-actions>

				</v-card-actions>
				</v-card>
			</v-col>
	 	</v-row>

		<!-- Agregar Plantel -->
		<v-dialog v-model="dialogAddPlantel" persistent max-width="500">
		<v-card>
			<v-card-title class="text-h5">
			Nuevo plantel para usuario
			</v-card-title>
			<v-card-text>
				<v-autocomplete filled dense hide-details :items="planteles" v-model="plantel" label="Selecciona un plantel"
              		item-value="idplantel" item-text="plantel">
            	</v-autocomplete>
			</v-card-text>
			<v-card-actions>
				<v-btn color="error" dense @click="dialogAddPlantel = false" >
					Cancelar
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" dense @click="addPlantel()" >
					Guardar
				</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>

		<!-- Agregar la hora -->
		<v-dialog v-model="dialogHora" persistent max-width="290">
		<v-card>
			<v-card-title class="text-h5">
			Nuevo horario
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" md="6">
						<v-text-field
						name="name"
						label="Inicio"
						id="id"
						type="time"
						step="1500"
						v-model="horaini"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="6">
						<v-text-field
						name="name"
						label="Inicio"
						id="id"
						type="time"
						v-model="horafin"
						step="1500"
						></v-text-field>
					</v-col>

					<v-col cols="3">
						<v-checkbox
							v-model="diasSeleccionados"
							label="L"
							:value="1"
							hide-details
							></v-checkbox>
						</v-col>
						<v-col cols="3">
							<v-checkbox
							v-model="diasSeleccionados"
							label="M"
							:value="2"
							hide-details
							></v-checkbox>
						</v-col>
						<v-col cols="3">

							<v-checkbox
							v-model="diasSeleccionados"
							label="Mi"
							:value="3"
							hide-details
							></v-checkbox>
						</v-col>
						<v-col cols="3">

							<v-checkbox
							v-model="diasSeleccionados"
							label="J"
							:value="4"
							hide-details
							></v-checkbox>
						</v-col>
						<v-col cols="3">
							<v-checkbox
							v-model="diasSeleccionados"
							label="V"
							:value="5"
							hide-details
							></v-checkbox>
						</v-col>
						<v-col cols="3">
							<v-checkbox
							v-model="diasSeleccionados"
							label="S"
							:value="6"
							hide-details
							></v-checkbox>
						</v-col>
						<v-col cols="3">
							<v-checkbox
							v-model="diasSeleccionados"
							label="D"
							:value="7"
							hide-details
							></v-checkbox>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
				color="green darken-1"
				text
				@click="addHora()"
			>
				Aceptar
			</v-btn>
			<v-btn
				color="green darken-1"
				text
				@click="dialogHora = false"
			>
				Cancelar
			</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>

		<!-- Dialogo para editar la hora -->
		<v-dialog v-model="dialogHoraEditar" persistent max-width="290">
		<v-card>
			<v-card-title class="text-h5" v-if="horaEditar">
			{{dia}}
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" md="6">
						<v-text-field
						name="name"
						label="Inicio"
						id="id"
						type="time"
						step="1500"
						v-model="horaEditar.inicio"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="6">
						<v-text-field
						name="name"
						label="Inicio"
						id="id"
						type="time"
						v-model="horaEditar.fin"
						step="1500"
						></v-text-field>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
				color="green darken-1"
				text
				@click="saveHora()"
			>
				Aceptar
			</v-btn>
			<v-btn
				color="green darken-1"
				text
				@click="dialogHoraEditar = false"
			>
				Cancelar
			</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>

		<!-- Eliminar la hora -->
		<v-dialog v-model="dialogDeleteHora" max-width="500px">
		<v-card>
			<v-card-title class="text-h5">Seguro que deseas eliminar esta hora?</v-card-title>
			<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="dialogDeleteHora = false">Cancelar</v-btn>
			<v-btn color="blue darken-1" text @click="deleteHora()">SI</v-btn>
			<v-spacer></v-spacer>
			</v-card-actions>
		</v-card>
		</v-dialog>

		<div class="text-center">
			<v-dialog
				v-model="dialog"
				hide-overlay
				persistent
				width="300"
			>
				<v-card
				color="primary"
				dark
				>
				<v-card-text>
					Espera por favor
					<v-progress-linear
					indeterminate
					color="white"
					class="mb-0"
					></v-progress-linear>
				</v-card-text>
				</v-card>
			</v-dialog>
		</div>

		<!-- Dialog de carga -->
		<Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
		<carga                v-if="cargar"/>

	</v-container>
</template>
<script>
  import axios from 'axios';
  var moment = require('moment');
  moment.locale(); 
  import {mapGetters, mapActions} from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'


  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

	computed : {
		...mapGetters('login',['getdatosUsuario']),
	},

    data: () => ({
    	// Alertas
    	parametros:{
			dialogError: false,
			mensaje: '',
			color: ''
		},
		dialog:false,
		respuestaAlerta:false,
		cargar: false,

		dialogAddPlantel:false,

    	diasSeleccionados:[],

		ciclosall:[],
        cicloVer:{},
		cicloAdd:{},
		cicloActual:{},
		semanaVer: 0,
		semanaAdd:1,
		
		//tabla disponibilidad
		dias:[{dia:'Lunes', valor: 0},{dia:'Martes', valor: 1},{dia:'Miércoles', valor: 2},{dia:'Jueves', valor: 3},{dia:'Viernes', valor: 4},{dia:'Sábado', valor: 5},{dia:'Domingo', valor: 6}],
		dispUsuario: [],
		dispUsuarioFiltrada: [],

    	headersSuc: [
        	{ text: 'Sucursal',  value: 'plantel' },
			// { text: 'Prioridad', value: 'prioridad' },
			// { text: 'Acciones',  value: 'editar' },
		],

		//tabla de niveles
		niveles:[],
		nivelesUsuario:[],
		selectNivel:[],
    	headersNivel: [
			{ text: 'Nivel',      value: 'nivel' },
			{ text: 'Escuela',    value: 'unidad_negocio' },
			{ text: 'Curso',      value: 'curso' },
		],

    	horaini:'',
		horafin:'',
		headers: [
			{ text: 'Inicio', value: 'inicio' },
			{ text: 'Fin'   , value: 'fin' },
			{ text: 'Acciones'      , value: 'actions' },
		],
		diaHora:{},
		diaValor:0,
		dialogHora:false,
      	dias:[{dia:'Lunes', valor: 0},{dia:'Martes', valor: 1},{dia:'Miércoles', valor: 2},{dia:'Jueves', valor: 3},{dia:'Viernes', valor: 4},{dia:'Sábado', valor: 5},{dia:'Domingo', valor: 6}],

    	diaLunes:true,
		usuario: null,
		usuarios: [],
		disponibilidad:[],
		disponibilidadUsuario:[],
		tags: [
			{dia:'Lunes'},
			{dia:'Martes'},
			{dia:'Miércoles'},
			{dia:'Jueves'},
			{dia:'Viernes'},
			{dia:'Sabado'},
			{dia:'Domingo'}
		],


		prioridad:[],

		puestos:[],
		puesto:{},
		fecha:'',
		

		usuarioRH:{},

		estatusUsuario: false,
		alertaUsuarioNoExiste:false,

		snack: false,
		snackColor: '',
		snackText: '',

		horaEditar :{},
		dialogHoraEditar: false,
		dia:'',
		dialogDeleteHora:false,
		iddisponibilidad_usuarioDelete:'',


		plantel:[],
		planteles:[],
		plantelesUsuario:[],

		headersPlanteles: [
			{ text: 'Prioridad', value: 'prioridad' },
			{ text: 'Plantel'   , value: 'plantel' },
			{ text: 'Acciones'   , value: 'actions' },
		],
    }),

    watch:{

    	usuario(){
    		if(this.usuario){
	    		this.dialog = true
	    		// Primero validamos si el usuario existe ya registrado en nuestra base de datos
	    		// Validamos la información del usuario seleccionado
	    		this.getUsuarioExiste()
    		}
    	},

		cicloVer () {
			if(this.cicloVer && this.usuario){
				this.getDisponibilidadUsuario()
			}
		},
    },

    created(){
    	// Generando la fecha de hoy
    	const fecha = new Date().toLocaleDateString("en-Es", {
				timeZone: "America/Mexico_City"
			});
    	this.fecha = moment(fecha).format().substr(0,10)

    	this.initialize()
    },

    methods:{

    	initialize () {
    		this.usuarios = []
    		this.$http.get('usuarios.all.erp').then(responseUsuarios=>{
    			for(const i in responseUsuarios.data){
    				responseUsuarios.data[i].nombre_completo = responseUsuarios.data[i].nombre_completo.toUpperCase() 
    			}
    			this.usuarios = responseUsuarios.data
    		}).catch(error=>{console.log(error)})

    		this.$http.get('planteles.all').then(response=>{
    			for(const j in response.data){
            		response.data[j].fecha_creacion  = response.data[j].fecha_creacion.substr(0,10)
            		response.data[j].fecha_actualizo = response.data[j].fecha_actualizo.substr(0,10)
          		}
    			this.planteles = response.data
				this.planteles = this.planteles.filter(el=>el.idplantel != 0)
    		}).catch(error=>{console.log(error)})

    		this.$http.get('niveles.all').then(response=>{
    			for(const j in response.data){
				response.data[j].fecha_registro  = response.data[j].fecha_registro.substr(0,10)
				response.data[j].fecha_actualizo = response.data[j].fecha_actualizo.substr(0,10)
          }
    			this.niveles = response.data
    		}).catch(error=>{console.log(error)})

			this.getCiclos()
			this.getCicloActual()
  			this.getPuestos()
    	},
		getCiclos(){
			this.$http.get('roles.ciclos.all').then(response=>{
				// hacer un ciclo ya que los ciclos vienen varios como inscripciones y todo eso
				// y solo queremos los ciclos que en su nombre dicen ciclo 
				for(const i in response.data){
					//  si es diferente a FE
					if (!(response.data[i].ciclo.search('FE') != -1)) {
					// Y es igual a FE, se agrega a ciclos
					// por qué es diferente a FE? por que es por estetica, mostrar solo los ciclos normales, ya que los ciclos tienen ligado el ciclo FE
					if(response.data[i].ciclo.search('CICLO') != -1){
						this.ciclosall.push(response.data[i])
					}
					}
				}
			}).catch( error =>{
				this.validarError( error.response.data.message )
			}).finally( () => { this.cargar = false })
		},
		getCicloActual( ){
			this.cargar = true
			const payload = {
				fecha: this.fecha,
			};
			this.$http.post('usuarios.get.cicloactual', payload).then(response=>{
				this.cicloActual = response.data[0]
				this.cicloVer = this.cicloActual.id_ciclo
				this.cicloAdd = this.cicloActual.id_ciclo
			}).catch( error =>{
				this.validarError( error.response.data.message )
			}).finally( () => {  })
    	},
    	getUsuarioExiste () {
    		this.$http.get('usuarios.id/' + this.usuario.id_usuario ).then(response=>{
    			// Buscamos la información dentro del RH
    			// Si lo encontramos
				console.log(response.data)
    			if(response.data){
    				// guardar los datos del usuario
    				this.usuarioRH = response.data
					console.log(this.usuarioRH)
    				if(this.usuarioRH.prioridad == 1){this.usuarioRH.prioridad = true}else{this.usuarioRH.prioridad = false}
    				if(this.usuarioRH.online == 1){this.usuarioRH.online = true}else{this.usuarioRH.online = false}
    				// indicar que el usuario si existe
    				this.estatusUsuario = true
    				// Inidicar que la alerta de que el usuario si esta configurado
    				this.alertaUsuarioNoExiste = false
    				// Indicar el puesto del usuario
    				this.puesto = {idpuesto: this.usuarioRH.idpuesto, puesto: this.usuarioRH.puesto}
    				this.estatusUsuario = true
    				// Obtener la configuración del usuario /niveles/disponibilidad/planteles
    				this.getDisponibilidadUsuario()
					this.getNivelesUsuario()
					this.getPlantelesUsuario()
    			}else{
    				this.puesto = null
    				this.dialog = false
    				this.estatusUsuario = false
    				this.alertaUsuarioNoExiste = true
    			}
    		}).catch(error=>{
				console.log(error)
    			this.puesto = null
  				this.dialog = false
  				this.estatusUsuario = false
  				this.alertaUsuarioNoExiste = true
    		}).finally(()=>{
				this.dialog = false
			})
    	},

    	getPuestos () {
    		this.$http.get('puestos.all').then(response=>{
          // Hacemos un ciclo para obtener los datos de los departamentos
          for(const j in response.data){
            response.data[j].fecha_creacion  = response.data[j].fecha_creacion.substr(0,10)
            response.data[j].fecha_actualizo = response.data[j].fecha_actualizo.substr(0,10)
          }
          this.puestos = response.data
          this.dialog = false
          // Traer los puestos
        }).catch(error=>{console.log(error)})
    	},
    	getDisponibilidadUsuario(){
			this.dispUsuario = []
			this.$http.get('disponibilidad.usuario/' + this.usuarioRH.idusuario).then(response=>{
				this.dispUsuario = response.data;
				console.log(this.dispUsuario)
				this.dispUsuarioFiltrada=[]
				this.dispUsuarioFiltrada[0] = this.dispUsuario.filter(el=>el.dia == 1);
				this.dispUsuarioFiltrada[1] = this.dispUsuario.filter(el=>el.dia == 2);
				this.dispUsuarioFiltrada[2] = this.dispUsuario.filter(el=>el.dia == 3);
				this.dispUsuarioFiltrada[3] = this.dispUsuario.filter(el=>el.dia == 4);
				this.dispUsuarioFiltrada[4] = this.dispUsuario.filter(el=>el.dia == 5);
				this.dispUsuarioFiltrada[5] = this.dispUsuario.filter(el=>el.dia == 6);
				this.dispUsuarioFiltrada[6] = this.dispUsuario.filter(el=>el.dia == 7);

				console.log(this.dispUsuarioFiltrada[0])
			}).catch(error=>{
				console.log(error)
			})
		},
		getPlantelesUsuario () {
			// Vaciamos los arreglos
			this.plantelesUsuario = []
			// this.getdatosUsuario.idusuario este id es el usuario en la base de datos erp_db
			this.$http.get('planteles_usuario.usuario/' + this.usuarioRH.idusuario).then(response=>{
				// Hacemos un ciclo para obtener los datos de los departamentos
				for(const j in response.data){
					response.data[j].fecha_creacion  = response.data[j].fecha_creacion.substr(0,10)
					response.data[j].fecha_actualizo = response.data[j].fecha_actualizo.substr(0,10)
				}
				this.plantelesUsuario = response.data
			}).catch( error =>{
				this.validarError( error.response.data.message )
			}).finally( () => { })
		},
		getNivelesUsuario () {
    		// Vaciamos los arreglos
    		this.nivelesUsuario = []
    		this.selectNivel = []

    		this.$http.get('niveles_usuario.usuario/' + this.usuarioRH.idusuario).then(response=>{
			// Hacemos un ciclo para obtener los datos de los departamentos
			for(const j in response.data){
				response.data[j].fecha_creacion  = response.data[j].fecha_creacion.substr(0,10)
				response.data[j].fecha_actualizo = response.data[j].fecha_actualizo.substr(0,10)
			}
			this.nivelesUsuario = response.data
			// Generar dos for, para saber cual nivel ya esta dando y poder agregarlo a la tabla de nieveles como activo
			for(const i in this.niveles){
				for(const j in this.nivelesUsuario){
					// Cpmapramos que si sea el nivel que esta dando el usuario
					if(this.niveles[i].idniveles == this.nivelesUsuario[j].idnivel){
						// Lo metemos al arreglo de usuarios niveles
						this.selectNivel.push(this.niveles[i])
					}
				}
			}
          // Cerramos la carga
          this.dialog = false
        }).catch(error=>{console.log(error)})
    	},

		deletePlantel(item){
			//actualizar prioridad del resto
			this.$http.delete('planteles_usuario.delete' +'/'+ item.idplanteles_usuarios).then(response=>{
				for(const i in this.plantelesUsuario){
					
				}
				this.getPlantelesUsuario()
			}).catch(error=>{console.log(error)})
		},
		addPlantel(){
			console.log(this.plantel)
			let existePlantelUsuario = this.plantelesUsuario.find(el=>el.idplantel == this.plantel)
			if(!existePlantelUsuario){
				var payload = {
					idusuario: this.usuarioRH.idusuario,
					idplantel: this.plantel,
					usuario_registro:this.getdatosUsuario.iderp,
					fecha_creacion: this.fecha,
					fecha_actualizo: this.fecha,
					prioridad: this.plantelesUsuario.length + 1,
				}
				this.$http.post('planteles_usuario.add', payload).then(response=>{
					this.getPlantelesUsuario()
					this.dialogAddPlantel = false;
				}).catch(error=>{console.log(error)})
			} else {
				this.validarErrorDirecto("El usuario ya tiene este plantel seleccionado")
			}
		},

		guardarNiv(){
			for(const i in this.selectNivel){
				const nivelUsuario = this.nivelesUsuario.find( nivel => nivel.idnivel == this.selectNivel[i].idniveles );
				//se busca el nivel seleccionado entre los que tiene el usuario
				if(!nivelUsuario){
					//si no se encuentra se inserta
					var payload = {
						idusuario:this.getdatosUsuario.idusuario,
						idnivel: this.selectNivel[i].idniveles,
						usuario_registro: this.getdatosUsuario.iderp,
						fecha_creacion:  this.fecha,
					    fecha_actualizo: this.fecha,
						deleted:         0,
					}
					this.$http.post('niveles_usuario.add' , payload).then(response=>{
						this.getNivelesUsuario()
					}).catch(error=>{console.log(error)})
				}
			}

			// Ahora hay que eliminar todas esas que se deseleccionaron
    		for(const i in this.nivelesUsuario){
    			const nivelUsuario = this.selectNivel.find( nivel => nivel.idniveles == this.nivelesUsuario[i].idnivel );
    			if(!nivelUsuario){
					this.$http.delete('niveles_usuario.delete/' + this.nivelesUsuario[i].idniveles_usuario).then(response=>{
						this.getNivelesUsuario()
					}).catch(error=>{console.log(error)})
    			}
    		}
		},

    	guardarConf () {
    		this.dialog = true
    		if(this.usuarioRH.prioridad){this.usuarioRH.prioridad = 1}else{this.usuarioRH.prioridad = 0}
    		if(this.usuarioRH.online){this.usuarioRH.online = 1}else{this.usuarioRH.online = 0}
    		if(this.estatusUsuario ==  true){
    			// Actualizamos al usuario
    			// Agregar usario
    			console.log( this.usuarioRH.deleted )
    			var payload = {
    				iderp            : this.usuario.id_usuario,
    				idpuesto         : this.puesto ? this.puesto.idpuesto : this.usuarioRH.idpuesto,
    				prioridad        : this.usuarioRH.prioridad,
    				online           : this.usuarioRH.online,
    				usuario_registro : 1,
    				fecha_creacion   : this.usuarioRH.fecha_creacion.substr(0,10),
    				fecha_actualizo  : this.usuarioRH.fecha_actualizo.substr(0,10),
    				deleted          : this.usuarioRH.deleted
    			}

    			this.$http.put('usuarios.update/'+ this.usuarioRH.idusuario,payload).then(response=>{
    				this.dialog = false
    				this.estatusUsuario = true
    				this.dialog = false
    				this.validarSuccess('Datos grabados correctamente')
	          this.initialize()
	        }).catch(error=>{this.dialog = false})

    		}else{
    			// Agregar usario
    			var payload = {
    				iderp: this.usuario.id_usuario,
    				idpuesto: this.puesto.idpuesto,
    				usuario_registro: 1,
    				prioridad:0,
    				online: 0,
    				fecha_creacion:this.fecha,
    				fecha_actualizo:this.fecha,
    				deleted : 0
    			}
    			this.$http.post('usuarios.add',payload).then(response=>{
    				this.estatusUsuario = true
    				this.dialog = false
    				this.validarSuccess('Datos grabados correctamente')
	          this.getUsuarioExiste()
	        }).catch(error=>{this.dialog = false})
    		}
    	},

    	editarHoraF (value) {
    		this.horaEditar = value
    		this.dia = this.dias[this.horaEditar.dia -1].dia
    		this.dialogHoraEditar = true
    	},

		saveHora () {
			this.$http.put('disponibilidad.update/' + this.horaEditar.iddisponibilidad_usuario, this.horaEditar).then(response=>{
				this.dialogHoraEditar = false
				this.snack = true
		this.snackColor = 'success'
		this.snackText = 'Datos guardados'
				this.getDisponibilidad()
			}).catch(error=>{console.log(error)})
		},

		deleteHora () {
			this.$http.delete('disponibilidad.delete/' + this.iddisponibilidad_usuarioDelete).then(response=>{
				this.dialogDeleteHora = false
				this.snack = true
				this.snackColor = 'success'
				this.snackText = 'Datos guardados'
				this.getDisponibilidadUsuario()
			}).catch(error=>{console.log(error)})
		},

		dialogHoraAdd(){
			this.dialogHora = true
		},

		addHora () {
			if(this.horaini != '' && this.horafin != ''){
				for(const i in this.diasSeleccionados){
					var payload = {
						idusuario:        this.getdatosUsuario.idusuario,
						idciclo:		  this.cicloAdd,
						semana: 		  this.semanaAdd,
						inicio:           this.horaini,
						fin:              this.horafin,
						usuario_registro: this.getdatosUsuario.iderp,
						deleted:          0,
						dia:              this.diasSeleccionados[i]
					}
					console.log(payload);
					this.$http.post('disponibilidad.add', payload).then(response=>{
						this.dialogHora = false
						this.snack = true
						this.snackColor = 'success'
						this.snackText = 'Datos guardados'
						this.getDisponibilidadUsuario()
					}).catch(error=>{console.log(error)})
				}
			}
		},
    }
  }
</script>