<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Encargadas Comisiones</span>
            <v-spacer></v-spacer>
	  		    <v-btn 
              class="mr-2" 
              small 
              dark 
              color="green"
              @click="exportarInscripciones()"
              v-if="getdatosUsuario.iderp != 3"
            >
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="mr-2" 
              @click="goNomina()"
              small
              tile
              v-if="getdatosUsuario.iderp != 3"

            >
              Nómina
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize(), getAllComisionesRetenidas()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="3" >
                <v-text-field
                  filled
                  single-line
                  label="Fecha"
                  type="date"
                  hide-details
                  v-model="fecha"
                  dense
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <label></label>
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-radio-group
                  v-model="opEstatus"
                  row
                >
                  <v-radio
                    label="Todos"
                    :value="5"
                  ></v-radio>
                  <v-radio
                    label="Pendientes"
                    value="Sin estatus"
                  ></v-radio>
                  <v-radio
                    label="Aprobadas"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Denegadas"
                    :value="2"
                  ></v-radio>
                  <v-radio
                    label="Aceptadas"
                    :value="3"
                  ></v-radio>
                  <v-radio
                    label="Rechazadas"
                    :value="4"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers2"
                  :items="filterComisiones"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                >
                  <template v-slot:item.comisiones="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      color="blue"
                      v-if="getdatosUsuario.iderp != 3"
                      @click="editItem(item)"
                    >
                      mdi-file-document
                    </v-icon>
                  </template>

                  <template v-slot:item.estatus="{ item }">    
                    <v-chip color="orange"   small dark v-if="item.estatus == 0 || item.estatus == 'Sin estatus'">Pendiente</v-chip>
                    <v-chip color="blue"     small dark v-if="item.estatus == 1">Comisiones Aprobadas</v-chip> 
                    <v-chip color="red"      small dark v-if="item.estatus == 2">Comisiones Denegadas</v-chip>
                    <v-chip color="green"    small dark v-if="item.estatus == 3">Aceptadas</v-chip>
                    <v-chip color="black"    small dark v-if="item.estatus == 4">Rechazadas</v-chip>
                  </template>

                  <template v-slot:item.aceptar="{ item }">
                    <v-btn
                      color="primary"
                      dark
                      small
                      @click="(nominass = item), (dialogAceptar = true)"
                      v-if="getdatosUsuario.iderp != 3"
                    >
                      ACEPTAR
                    </v-btn>
                  </template>

                    <!--BOTON Rechazar -->
                  <template v-slot:item.rechazar="{ item }">
                    <v-btn
                      color="green"
                      dark
                      small
                      v-if="getdatosUsuario.iderp != 3"
                      @click="(idhistorial = item),(dialog_mensaje_rechazo = true),getMensajesRechazo()"
                    >
                      Responder/Rechazar
                    </v-btn>
                  </template>
                  
                </v-data-table>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-col>

    <v-dialog
      v-model="dialogComisiones"
      max-width="1300px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Comisiones:</span>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-row align="center" class="ml-2">
          <v-col cols="12" md="6" lg="4">
            <span class="black--text text-h8"
              ><b>Total Comisiones:</b>
              {{ totalComisiones }} 
            </span>
          </v-col>
        </v-row>
        <v-row align="center" class="ml-2">
          <v-col cols="12" md="6" lg="4">
            <span class="black--text text-h8"
              ><b>Cantidad Comisiones:</b>
              {{ cantidadComisiones }} 
            </span>
          </v-col>
        </v-row> 

        <v-col cols="12" md="6">
          <label></label>
          <v-text-field
            name="name"
            label="Buscar"
            id="id"
            filled
            dense
            single-line
            hide-details
            append-icon="mdi-magnify"
            v-model="search2"
          ></v-text-field>
        </v-col>

        <v-card-text class="black--text">
        	<v-row>
        		<v-col cols="12">
        			<v-data-table
						    :headers="headers3"
						    :items="editedItems.comisiones"
                :search="search2"
						    class="elevation-0"
						    dense
						  >
              <template v-slot:item.tipo="{ item }">    
                    <v-chip color="#2EDAD5" small v-if="(item.tipo == 0) && (item.comision == 40 || item.comision == 100) ">Comisión No Regular</v-chip>
                    <v-chip color="#000D4A" small dark v-if="(item.tipo == 0) && (item.comision == 80 || item.comision == 200) ">Comisión Regular</v-chip>
                    <v-chip color="green" small dark v-if="item.tipo == 1">Retención Liberada</v-chip> 
                  </template>
						  </v-data-table>
            </v-col>
        	</v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

      <v-dialog v-model="dialogAceptar" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Aceptar:</strong> ¿Estás seguro que deseas aceptar las comisiones?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      large
                      class="mr-2"
                      @click="dialogAceptar=false"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      large
                      @click="aceptarNomina()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/contrato.jpg"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_mensaje_rechazo" max-width="1000px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1"><b>Favor de ser claro con el mensaje:</b></span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-row v-for="(item, index) in this.mensajes" :key="index">
            <v-col cols="12">
              <v-textarea
                v-model="item.mensaje"
                :id="'motivo_salida_' + index"
                label="Motivo del rechazo"
                outlined
                hide-details
                dense
                auto-grow
                readonly
                style="max-width: 900px; margin-right: auto"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="item.mensaje_respuesta"
                :id="'motivo_salida_' + index"
                label="Respuesta..."
                outlined
                hide-details
                dense
                auto-grow
                readonly
                style="max-width: 900px; margin-left: auto"
                :class="{ 'd-none': !item.mensaje_respuesta }"
              ></v-textarea>
            </v-col> </v-row
          ><br />
          <v-row
            style="border: 1px solid gray; padding: 2px; border-radius: 5px"
            v-if="idhistorial.estatus == 1 || idhistorial.estatus == 2"
          >
            <v-col cols="12" :style="{ paddingTop: '10px' }">
              <v-textarea
                v-model="editedItem2.mensaje"
                label="Escriba su mensaje..."
                outlined
                hide-details
                dense
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (dialog_mensaje_rechazo = false), (editedItem2.mensaje = '')
            "
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
             v-if="idhistorial.estatus == 1 || idhistorial.estatus == 2"
            @click="enviarMensajeRechazo()"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Comisiones Retenidas</span>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize(), getAllComisionesRetenidas()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <label></label>
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers4"
                  :items="allcomisionesretenidas"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                >
                </v-data-table>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

import XLSX            from 'xlsx'


export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,
    dialogComisiones: false,
    headers: [
      { text: "ID", value: "id_alumno" },
      { text: "Matrícula", value: "matricula" },
      { text: "Alumno", value: "alumno" },
      { text: "Grupo", value: "grupo" },
      { text: "Precio Grupo", value: "precio_grupo" },
      { text: "Precio con Descuento", value: "precio_grupo_con_descuento_x_alumno" },
      { text: "Monto Pagado", value: "pagado" },
      { text: "Fecha", value: "fecha_pago" },
      { text: "Fecha Ultimo Cambio", value: "fecha_ultimo_cambio" },
      // { text: "Activo", value: "activo_sn" },
      { text: "Comisionista", value: "vendedora" },
      { text: "Comisión", value: "comision" },
    ],
    headers2: [
      { text: "ID", value: "id_usuario" },
      { text: "Vendedora", value: "nombre" },
      { text: "Puesto", value: "puesto" },
      { text: "Comisiones", value: "comisiones" },
      { text: "Estatus", value: "estatus" },
      { text: "Aceptar", value: "aceptar" },
      { text: "Responder", value: "rechazar" },
    ],

    headers3: [
      { text: "ID", value: "id_alumno" },
      { text: "Matrícula", value: "matricula" },
      { text: "Alumno", value: "alumno" },
      { text: "Grupo", value: "grupo" },
      { text: "Precio Grupo", value: "precio_grupo" },
      { text: "Precio con Descuento", value: "precio_grupo_con_descuento_x_alumno" },
      { text: "Monto Pagado", value: "pagado" },
      { text: "Fecha", value: "fecha_pago" },
      { text: "Fecha Ultimo Cambio", value: "fecha_ultimo_cambio" },
      { text: "Comisión", value: "comision" },
      { text: "Tipo", value: "tipo" },
    ],

     headers4: [
      { text: "ID", value: "id_alumno" },
      { text: "Matrícula", value: "matricula" },
      { text: "Alumno", value: "alumno" },
      { text: "Grupo", value: "grupo" },
      { text: "Precio Grupo", value: "precio_grupo" },
      { text: "Precio con Descuento", value: "precio_grupo_con_descuento_x_alumno" },
      { text: "Monto Pagado", value: "pagado" },
      { text: "Vendedora", value: "vendedora" },
      { text: "Fecha", value: "fecha_pago" },
      { text: "Comisión", value: "comision" },
    ],
    encargadascomisiones: [],
    encargadascomisiones2: [],
    allcomisionesretenidas: [],
    editedIndex: -1,

    editedItems: {
        id_usuario: '',
        nombre: '',
        puesto: '',
        comisiones: []
    },

    editedItem: {
      idincidencias_bonos: "",
      fecha: "",
      rebajes: "",
      comisiones: "",
      premios: "",
      iderp: "",
      deleted: 0,
      compensaciones: ""
    },
    defaultItem: {
      idincidencias_bonos: "",
      fecha: "",
      rebajes: "",
      comisiones: "",
      premios: "",
      iderp: "",
      deleted: 0,
      compensaciones: ""
    },

    opEstatus: 5,

    dialogSuccess: false,
    dialogAceptar: false,
    dialog_mensaje_rechazo: false,
    overlay: false,
    dialogError: false,
    error: "",
    successMensaje: "",
    errorBack: false,
    textoCopiado: "COPIAR",
    idcurso: 0,
    info: 0,
    idhistorial: [],
    nominass: [],
    editedItem2: {
      mensaje: "",
    },
    mensajes: [],
    usuarios: [],
    fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

    ciclos: [],
    ciclo: null,
    estatus: null,
    alumnos: [],
    reglas: [],
    cursosEscuela: [],
    horasExtras: "",
    // tabla
    search: "",
    search2: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),

     totalComisiones() {
      let subtotal = this.editedItems.comisiones
        .map((item) => item.comision)
        .reduce((prev, curr) => prev + curr, 0);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(subtotal);
    },
    cantidadComisiones() {
      let subtotal = this.editedItems.comisiones.length;
      this.cd1 = subtotal;
      return Math.floor(subtotal);
    },

   filterComisiones( ){
      	let data = this.encargadascomisiones2;


        if( this.opEstatus != 5 ){
          return data.filter(el => el.estatus == this.opEstatus  );
        }
        return data;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

  },

  created() {

    this.fecha = this.$route.params.fecha ? this.$route.params.fecha : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    this.search = this.$route.params.nombre ? this.$route.params.nombre : ''
    
    this.initialize();
    // this.getUsuarios();
  },

  methods: {
    initialize() {
      this.encargadascomisiones = [];
      this.encargadascomisiones2 = [];
      this.overlay = true;
      this.cargar = true;
      const payload = {
        fecha: this.fecha,
      }

      this.$http
        .post("encargadas_comisiones.get.encargadascomisiones", payload)
        .then((response) => {
          this.encargadascomisiones = response.data.respuesta;
          this.encargadascomisiones2 = response.data.resultados;
          this.getAllComisionesRetenidas()
          this.overlay = false;
          this.cargar = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;

        }).finally( () => { this.cargar = false })
    },

    getMensajesRechazo() {
      this.cargar = true;
      this.mensajes = [];

      const payload = {
        iderp: this.idhistorial.id_usuario,
        fecha: this.fecha
      };

      return this.$http
        .post("consultar.mensajes.rechazo.comisiones", payload)
        .then((response) => {
          this.mensajes = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    enviarMensajeRechazo() {
      this.cargar = true;
      const payload = {
        fecha: this.fecha,
        idusuario: this.idhistorial.id_usuario,
        estatus: 4,

        mensaje: this.editedItem2.mensaje,
        iderp: this.idhistorial.id_usuario,
      };

      return this.$http
        .post("encargadas_comisiones.add.comisionesestatus", payload)
        .then((response) => {
          this.$http
            .post("add.comisiones.mensaje", payload)
            .then((response) => {
              this.validarSuccess(response.data.message);
              this.cargar = false;
              this.dialog_mensaje_rechazo = false;
              this.initialize();
              this.editedItem2.mensaje = null;
            })
            .catch((error) => {
              this.validarError(error);
            })
            .finally(() => {
              this.cargar = false;
            });
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    aceptarNomina() {

      this.cargar = true;

      //Obtenemos el total de las comisiones para guardarlas
      const total_comisiones = this.nominass.comisiones.reduce((total, comision) => {
        return total + comision.comision;
      }, 0);

      const payload = {
        estatus: 3,
        idusuario : this.nominass.id_usuario,
        fecha: this.fecha,
        estatus_pago: 1,
        comisiones : this.nominass.comisiones,
        total_comisiones,
      };
     
      return this.$http
        .post("update.estatus.comisiones", payload)
        .then((response) => {
          this.dialogAceptar = false;
          this.initialize();
          this.cargar = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    getAllComisionesRetenidas() {
      this.allcomisionesretenidas = [];
      this.overlay = true;
     
      this.$http
        .post("encargadas_comisiones.get.allcomisionesretenidas")
        .then((response) => {
          this.allcomisionesretenidas = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;

        }).finally( () => {  })
    },

    goNomina(){
			this.$router.push({name: "Nomina"})
		},

    close () {
        this.dialogComisiones = false
    },

    editItem (item) {
        this.editedItems = Object.assign({}, item)
        this.dialogComisiones = true
      },

    exportarInscripciones( ){

    		const comisiones = this.encargadascomisiones.map(({ id_alumno, matricula, alumno, grupo, precio_grupo, precio_grupo_con_descuento_x_alumno, pagado, fecha_pago, fecha_ultimo_cambio, vendedora, comision }) => 
			({ id_alumno, matricula, alumno, grupo, precio_grupo, precio_grupo_con_descuento_x_alumno, pagado, fecha_pago, fecha_ultimo_cambio,  vendedora, comision }));

				this.exportExcel( comisiones, 'Comisiones')

    }
  },
};
</script>
