<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Recomendados</span>
	  		    <v-spacer></v-spacer>
            <v-btn color="primary" rounded @click="initialize( )" small>Actualizar</v-btn>
	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="6" lg="4">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="formaspago"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            dense
							  >
                  <template v-slot:item.inscrito="{ item }">
                    <v-checkbox 
                      hide-details 
                      small 
                      dense 
                      v-model="item.inscrito" 
                      @click="revisar( item )"
                      v-if="item.inscrito == 0 || item.inscrito == null"
                    ></v-checkbox>
                    <v-checkbox 
                      v-else
                      hide-details 
                      small 
                      dense 
                      v-model="item.inscrito" 
                      readonly
                    ></v-checkbox>
                  </template>

                  <template v-slot:item.aplicado="{ item }">
                    <v-checkbox hide-details small dense v-model="item.aplicado" readonly></v-checkbox>
                  </template>


                  <template v-slot:item.escuela="{ item }">
                    <v-chip 
                      v-if="item.escuela == 1"
                      small
                      color="#2EDAD5"
                      dark
                    >INBI</v-chip>

                    <v-chip 
                      v-else
                      small
                      color="#000D4A"
                      dark
                    >FAST</v-chip>
                  </template>
							  

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Editar información</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.folio }}</strong>
          </span>
        </v-card-title>

        <v-card-text class="black--text">
          <v-row>
          	<v-col cols="12">
              
              <div>
                <b>
                  Recomendado:
                  <br/>
                  {{ editedItem.nombre_recomendado }}
                </b>
              </div>

              <br/>
              <v-text-field
                v-model="editedItem.id_alumno_recomendado"
                label="Matricula"
                filled
                dense
              ></v-text-field>

              <v-autocomplete
                :items="grupos"
                v-model="editedItem.id_grupo_recomendado"
                label="Grupo"
                dense
                filled
                item-value="id_grupo"
                item-text="grupo"
              ></v-autocomplete>
            </v-col>

            <v-divider></v-divider>
            <v-subheader>Datos del que recomendó</v-subheader>

            <v-col cols="12">
              <div>
                <b>
                  Recomendó:
                  <br/>
                  {{ editedItem.matricula }}
                </b>
              </div>

              <v-autocomplete
                :items="grupos"
                v-model="editedItem.id_grupo"
                label="Grupo"
                dense
                filled
                item-value="id_grupo"
                item-text="grupo"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="save( )"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Dialogo de editar o agregar-->
    <v-dialog
      v-model="dialogConfirmacion"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">¿Deseas enviar a revisión este folio?</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="dialogConfirmacion = false, itemConfirma.inscrito = false"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="confirmarRevision( )"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'


  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],


    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
        id_recomienda:0,
				id_alumno:'',
				id_grupo:'',
				inscrito:null,
				monto:0,
				aplicado:null,
				nombre_recomendado:0,
        id_grupo_recomendado:null,
        id_alumno_recomendado:null,
        escuela:null,
        deleted:null,
				fecha_creacion:null,
        folio:''
      },

      defaultItem: {
        id_recomienda:0,
				id_alumno:'',
				id_grupo:'',
				inscrito:null,
				monto:0,
				aplicado:null,
				nombre_recomendado:0,
				id_grupo_recomendado:null,
        id_alumno_recomendado:null,
        escuela:null,
        deleted:null,
        fecha_creacion:null,
        folio:''
      },

      // Filtros: 
      incio:null,
			fin:null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      formaspago: [],
      headers: [
        { text: 'ID'               , value: 'folio'                },
        { text: 'Recomendado'      , value: 'nombre_recomendado'   },
        { text: 'Mat. Recomendado' , value: 'id_alumno_recomendado'},
        { text: 'Gru. Recomendado' , value: 'grupo_recomendado'    },
        { text: 'Mat. Recomendó'   , value: 'matricula'            },
        { text: 'Gru. Recomendó'   , value: 'grupo'                },
        { text: 'Escuela'          , value: 'escuela'              },
        { text: 'Revisar'          , value: 'inscrito'             },
        { text: 'Aplicado'         , value: 'aplicado'             },
        { text: 'Actions'          , value: 'actions', sortable: false },
      ],

      grupos:[],
      dialogConfirmacion: false,
      itemConfirma: null,
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    async created () {
      await this.initialize()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.formaspago = []
        return this.$http.get('recomienda.list').then(response=>{
          if( [17,18,19].includes(this.getdatosUsuario.idpuesto) ){
            this.formaspago = response.data.filter( el => { return el.escuela == this.getEscuela })
          }else{
        	  this.formaspago = response.data
          }
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.formaspago.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        this.getGrupos( item )
      },

      getGrupos( item ){
        this.cargar = true
        this.grupos = []
        this.$http.get('prospectos.grupos/' + item.escuela).then((response) => {
          this.grupos = response.data
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      deleteItem (item) {
        this.editedIndex = this.formaspago.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('formaspago.eliminar/' + this.editedItem.id_forma_pago, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save ( ) {
        this.cargar = true

        // Lo mandapos por el EP
        this.$http.put('recomienda.update', this.editedItem ).then(response=>{
          this.validarSuccess( response.data.message )
          this.initialize()
          this.close()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      revisar( item ){
        this.itemConfirma       = item
        this.dialogConfirmacion = true
      },

      confirmarRevision ( ) {
	      this.cargar = true
        // Lo mandapos por el EP
        this.$http.put('recomienda.update', this.itemConfirma).then(response=>{
        	this.validarSuccess( 'Datos actualizados correctamente' )
          this.dialogConfirmacion = false
          this.itemConfirma = null 
        	this.initialize()
          this.close()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
    },
  }
</script>


