import Vue from 'vue'
import store from '@/store'
import {mapGetters, mapActions}    from 'vuex'

export default {
	data () {
		return {
			
		}
	},
	computed: {

		...mapGetters('Carrito',['getCarrito']),

	},

	methods: {
		ver_carrito(){
			this.$store.state.Carrito.carritovisible =! this.$store.state.Carrito.carritovisible
		}	
  }
}