<template>
  <v-dialog persistent v-model="isOpen" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Folio: {{ ticket.idticket }}</span>
        <v-spacer></v-spacer>
        <v-btn fab small text @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container class="py-0" v-for="(respuestas, i) in historial" :key="i" >
          <v-row justify="space-between" v-if="i==0" >
            <v-col cols="6" class="d-flex justify-start py-2 px-0">
              <p class="ma-0"><strong>Motivos del ticket</strong></p>
            </v-col>
            <v-col cols="6" class="d-flex justify-end py-2 px-0">
              <p class="ma-0">{{ respuestas.fecha }}</p>
            </v-col>
          </v-row>
          <mensajeIzq :respuestas="respuestas" :ticket="ticket"></mensajeIzq>
          <mensajeDer :respuestas="respuestas" :ticket="ticket"></mensajeDer>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <br>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from 'vuex'
  import mensajeIzq            from '@/components/tickets/mensajeIzq.vue'
  import mensajeDer            from '@/components/tickets/mensajeDer.vue'

  export default {
    components:{
      mensajeIzq,
      mensajeDer,
    },

    props: [
      'isOpen',
      'ticket',
      'historial',
      ],
    computed: {
      ...mapGetters('login',['getdatosUsuario']),
    },

    watch:{
      isOpen(){
                // si el dialogo historial se abre
        if(this.isOpen){
          this.lastHistorial = this.historial[this.historial.length-1]
        }
      },
    },
    data: () => ({
      lastHistorial:{},

      headersAlumnos:[
        { text: 'matricula', value: "matricula" },
        { text: 'alumno'   , value: "alumno" },
        { text: 'grupo'    , value: "grupo" },
      ],

    }),

    methods:{
      closeDialog(){
        this.$emit('close');        
      },
    },
    
  }
</script>