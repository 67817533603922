<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard"> 
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="gruposRoles"
              class="elevation-0"
              dense
              fixed-header
              :search="search"
              multi-sort
              :loading="loading"
            > 
              <template v-slot:item.optimizado="{ item }">
                <v-chip v-if="item.optimizado == 1" small rounded color="green" dark >Optimizado</v-chip>
              </template>

              <template v-slot:item.id_unidad_negocio="{ item }">
                <v-chip v-if="item.id_unidad_negocio == 1" small rounded color="blue lighten-2" dark >INBI</v-chip>
                <v-chip v-else small rounded color="blue darken-2" dark >FAST</v-chip>
              </template>


              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>Rol de teachers</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn 
                    class="mr-2" 
                    small dark color="green"
                    @click="exportar()">
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>

                  <v-btn 
                    class="mr-2" 
                    small dark color="green"
                    @click="exportExcelTWO()">
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                  <v-btn color="primary" @click="consultar()" small>Consultar</v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" lg="4" xl="3">
                      <v-autocomplete
                        outlined
                        dense
                        clearable
                        v-model="ciclo"
                        :items="ciclos"
                        label="Selecciona ciclo"
                        persistent-hint
                        hide-details
                        return-object
                        item-text="ciclo"
                        item-value="id_ciclo"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" >
                      <v-text-field
                        v-model="search"
                        label="Búscar"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </template>
            </v-data-table>
            
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'

  var moment = require('moment');
  moment.locale(); 

  export default {
    data: () => ({
      grupoRolesPerfecto:[],
      search:'',
      headers: [
        { text: 'Cant Alumnos', align: 'start', sortable: false, value: 'alumnos' },
        { text: 'Escuela'     , align: 'start', value: 'id_unidad_negocio' },
        { text: 'Nivel',                        value: 'id_nivel' },
        { text: 'Horario',                      value: 'horario' },
        { text: 'Sucursal',                     value: 'plantel' },
        { text: 'Area',                         value: 'zona' },
        { text: 'Grupo',                        value: 'grupo' },
        { text: 'Optimizado',                   value: 'optimizado' },
        { text: 'Inicio'    ,                   value: 'hora_inicio' },
        { text: 'Final'     ,                   value: 'hora_fin' },
        { text: 'Curso',                        value: 'curso' },
        { text: 'Lunes',                        value: 'bloque1' },
        { text: 'Martes',                       value: 'bloque2' },
        { text: 'Miércoles',                    value: 'bloque3' },
        { text: 'Jueves',                       value: 'bloque4' },
        { text: 'Viernes',                      value: 'bloque5' },
      ],

      gruposRoles: [],
      gruposRolesFinal:[],
      ciclos:[],
      ciclo:null,

      resultado:[],
      idcicloERPinf:0,
      idcicloERPSup:0,
      configTeachers:[],

      loading: false,

      iteraciones:0,
      // Reglas:
      reglaSucursal:false,
      reglaNiveles:false,
      reglaHoras:false,

      permArr: [],
      usedChars: [],
      sucursales:[],
      gruposSabatinos:[],
      gruposSemiSabatinos:[],
      gruposDominicales:[],
      gruposMarteyJueves:[],
      gruposLJ:[],
      gruposLV:[],
    }),

    watch: {
      // Consultar cuando haya un cambio en el ciclo y este sea un ciclo seleccionado y no un ciclo vacio
      ciclo () {
        if(this.ciclo){
          this.consultar()
        }
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      permute(input) {
        var i, ch;
        for (i = 0; i < input.length; i++) {
          ch = input.splice(i, 1)[0];
          this.usedChars.push(ch);
          if (input.length == 0) {
            this.permArr.push(this.usedChars.slice());
          }
          this.permute(input);
          input.splice(i, 0, ch);
          this.usedChars.pop();
        }
        return this.permArr
      },

      // Funcion para cargar datos iniciales
      initialize () {
        // Vaciar los ciclos
        this.ciclos = []
        // Obtener todos los ciclos
        this.$http.get('roles.ciclos.all').then(response=>{
          // hacer un ciclo ya que los ciclos vienen varios como inscripciones y todo eso
          // y solo queremos los ciclos que en su nombre dicen ciclo 
          for(const i in response.data){
            //  si es diferente a FE
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              // Y es igual a FE, se agrega a ciclos
              // por qué es diferente a FE? por que es por estetica, mostrar solo los ciclos normales, ya que los ciclos tienen ligado el ciclo FE
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
        }).catch(error=>{console.log(error)})

        this.$http.get('planteles.all').then(response=>{
          for(const j in response.data){
            response.data[j].fecha_creacion  = response.data[j].fecha_creacion.substr(0,10)
            response.data[j].fecha_actualizo = response.data[j].fecha_actualizo.substr(0,10)
          }
          this.sucursales = response.data
        }).catch(error=>{console.log(error)})
      },

      consultar () {
        // Mandar a consultar a los grupos de ese ciclo
        //  empezar con inbi
        // Limpiamos el arreglo de grupos
        this.gruposRoles = []
        // Consultamos la información
        this.$http.get('roles.grupos/' + this.ciclo.id_ciclo_relacionado + '/' + this.ciclo.id_ciclo).then(response=>{
          // Guardamos la información
          // Creamos un objeto con los datos que vamos a utilizar para hacer la configuración e iteraciones

          let grupos = response.data.filter( el => { return el.id_curso > 1 && el.id_nivel < 15 && el.id_unidad_negocio == 2 })

          for(const i in grupos ){
            grupos[i].bloque1 = ''
            grupos[i].bloque2 = ''
            grupos[i].bloque3 = ''
            grupos[i].bloque4 = ''
            grupos[i].bloque5 = ''
          }
          this.gruposRoles = grupos

          this.gruposRoles.sort(function (a, b) {
            if (a.hora_inicio < b.hora_inicio) {
              return 1;
            }
            if (a.hora_inicio > b.hora_inicio) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          this.gruposRoles.sort(function (a, b) {
            if (a.id_curso > b.id_curso) {
              return 1;
            }
            if (a.id_curso < b.id_curso) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });


          // Ahora si, siguiente paso, consultar a los teachersssss
          this.getTeachers()
        }).catch(error=>{console.log(error)})
      },

      async getTeachers () {
        // obtener los datos de los teachers
        await this.$http.get('roles.teacher').then(response=>{
          this.configTeachers = response.data
        }).catch(error=>{console.log(error)})

        await this.separarCursosSab()
        await this.separarCursosSemiSab()
        await this.separarCursosMyJ()
        await this.separarCursosDom()
        await this.separarCursosLMV()
        await this.separarCursosLJ()
        await this.separarCursosLV()
        await this.iterar()

        await this.romperReglasucursal()

        await this.romperReglaniveles()
      },

      separarCursosSab () {
        return this.gruposSabatinos =     this.gruposRoles.filter(curso => curso.optimizado == 0 && curso.id_curso == 6 && curso.id_unidad_negocio == 2 || curso.optimizado == 0 && curso.id_curso == 7 && curso.id_unidad_negocio == 1)
      },

      separarCursosSemiSab () {
        return this.gruposSemiSabatinos = this.gruposRoles.filter(curso => curso.optimizado == 0 && curso.id_curso == 3 && curso.id_unidad_negocio == 1 || curso.optimizado == 0 && curso.id_curso == 8 && curso.id_unidad_negocio == 1)
      },

      separarCursosDom () {
        return this.gruposDominicales =   this.gruposRoles.filter(curso => curso.optimizado == 0 && curso.id_curso == 7 && curso.id_unidad_negocio == 2)
      },

      separarCursosMyJ () {
        return this.gruposMarteyJueves =  this.gruposRoles.filter(curso => curso.optimizado == 0 && curso.id_curso == 8 && curso.id_unidad_negocio == 2)
      },

      separarCursosLMV () {
        return this.gruposLMV =  this.gruposRoles.filter(curso => curso.optimizado == 0 && curso.id_curso == 4)
      },

      separarCursosLJ () {
        return this.gruposLJ =  this.gruposRoles.filter(curso => curso.optimizado == 0 && curso.id_curso == 9 && curso.id_unidad_negocio == 1)
      },

      separarCursosLV () {
        return this.gruposLV =  this.gruposRoles.filter(curso => curso.optimizado == 0 && curso.id_curso == 2)
      },

      sabatinoParteBMedioTurno () {
        var sucursal = false
        var zona     = false
        var niveles  = false

        var teacherSabatinosTarde = []
        for(const i in this.configTeachers){
          const dispo = this.configTeachers[i].disponibilidad.find(  dispo => parseInt(dispo.inicio.substr(0,2)) >= 12 && parseInt(dispo.fin.substr(0,2)) <= 16 && dispo.dia == 6)
          if(dispo){teacherSabatinosTarde.push(this.configTeachers[i])}
        }

        // LLENA LA PARTE B DE LOS GRUPOS, SIEMPRE Y CUANDO SEA DE MEDIO TURNO
        for(const i in this.gruposSabatinos){
          if(this.gruposSabatinos[i].optimizado == 0 && this.gruposSabatinos[i].id_curso == 6 && this.gruposSabatinos[i].id_unidad_negocio == 2 ||
            this.gruposSabatinos[i].optimizado == 0 && this.gruposSabatinos[i].id_curso == 7 && this.gruposSabatinos[i].id_unidad_negocio == 1){

            // Vamos primero a llenar a todos los teahcer que se encuentran en sabatino y que solo pueden los fines de semana
            // ya que esos no se ocupan iterar

            // Primero llenaremos la parte A
            for(const j in teacherSabatinosTarde){
              if(!this.reglaSucursal){
                zona     = teacherSabatinosTarde[j].zonas.find(       zona =>       zona.idzona          == this.gruposSabatinos[i].idareas );
                sucursal = teacherSabatinosTarde[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposSabatinos[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                  niveles     = teacherSabatinosTarde[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposSabatinos[i].id_nivel );
              }else{niveles = true;}

              const sabado = teacherSabatinosTarde[j].disponibilidad.find( dispo =>  parseInt(dispo.inicio.substr(0,2)) >= 12 &&  parseInt(dispo.fin.substr(0,2)) <= 18 && dispo.dia == 6);
              if(zona && sucursal && niveles && sabado){
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( teacherSabatinosTarde[j].nombre,this.gruposSabatinos[i].hora_inicio,
                                                            this.gruposSabatinos[i].hora_fin,this.gruposSabatinos[i].id_curso,
                                                            teacherSabatinosTarde[j].online,this.gruposSabatinos[i].id_plantel,this.gruposSabatinos[i].id_unidad_negocio)

                // No da clase
                if(yadaclase == '' || yadaclase == 'a'){

                  if(this.gruposSabatinos[i].bloque4 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposSabatinos[i].bloque1 != teacherSabatinosTarde[j].nombre){
                      this.gruposSabatinos[i].bloque4 =  teacherSabatinosTarde[j].nombre
                      this.gruposSabatinos[i].bloque5 =  teacherSabatinosTarde[j].nombre
                    }
                  }
                }
              }
            }
          }
        }
      },

      dominicalParteBMedioTurno () {
        var sucursal = false
        var zona     = false
        var niveles  = false

        var teacherDominicalTarde = []
        for(const i in this.configTeachers){
          const dispo = this.configTeachers[i].disponibilidad.find(  dispo => parseInt(dispo.inicio.substr(0,2)) >= 12 && parseInt(dispo.fin.substr(0,2)) <= 16 && dispo.dia == 7)
          if(dispo){teacherDominicalTarde.push(this.configTeachers[i])}
        }

        // LLENA LA PARTE B DE LOS GRUPOS, SIEMPRE Y CUANDO SEA DE MEDIO TURNO
        for(const i in this.gruposDominicales){
          // Primero llenaremos la parte A
          for(const j in teacherDominicalTarde){

            if(!this.reglaSucursal){
              zona     = teacherDominicalTarde[j].zonas.find(       zona =>       zona.idzona          == this.gruposDominicales[i].idareas );
              sucursal = teacherDominicalTarde[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposDominicales[i].id_plantel );
            }else{sucursal = true; zona = true}

            if(!this.reglaNiveles){
              niveles     = teacherDominicalTarde[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposDominicales[i].id_nivel );
            }else{niveles = true;}

            // Validamos que pueda el domingo medio dia
            const domingo = teacherDominicalTarde[j].disponibilidad.find( dispo => parseInt(dispo.inicio.substr(0,2)) >= 12 && parseInt(dispo.fin.substr(0,2)) <= 18 &&dispo.dia == 7);

            if(zona && sucursal && niveles && domingo){
              // Verificar si ya da clase o no
              var yadaclase = this.buscarDispoGrupoTeacher( teacherDominicalTarde[j].nombre,this.gruposDominicales[i].hora_inicio,
                                                          this.gruposDominicales[i].hora_fin,this.gruposDominicales[i].id_curso,
                                                          teacherDominicalTarde[j].online,this.gruposDominicales[i].id_plantel,this.gruposDominicales[i].id_unidad_negocio)
              // No da clase
              if(yadaclase == '' || yadaclase == 'a'){
                if(this.gruposDominicales[i].bloque4 == ''){
                  // Validar que el teacher de la parte b no sea igual al de la parte a
                  if(this.gruposDominicales[i].bloque1 != teacherDominicalTarde[j].nombre){
                    this.gruposDominicales[i].bloque4 =  teacherDominicalTarde[j].nombre
                    this.gruposDominicales[i].bloque5 =  teacherDominicalTarde[j].nombre
                  }
                }
              }
            }
          }
        }
      },

      iterar () {
        this.reglaSucursal = false
        this.reglaNiveles  = false
        var sucursal = false
        var zona     = false
        var niveles  = false
        // Características a evaluar
        /******************************************** AGREGAMOS LOS DEL CURSO DE LOS SABADOS ************************************************/
        /********************************************* id_curso = 6 y unidad de negocio = 2  ************************************************/
        /********************************************* id_curso = 7 y unidad de negocio = 1  ************************************************/
        
        // Llenar los teachers que solo pueden medios turnos en la mañana
        for(const i in this.gruposSabatinos){
          // Primero llenaremos la parte A
          for(const j in this.configTeachers){

            if(!this.reglaSucursal){
              zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposSabatinos[i].idareas );
              sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposSabatinos[i].id_plantel );
            }else{sucursal = true; zona = true}

            if(!this.reglaNiveles){
              niveles  = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposSabatinos[i].id_nivel );
            }else{niveles = true;}

            /* Validamos que pueda dar clase en la mañana */
            const sabado = this.configTeachers[j].disponibilidad.find( dispo =>  parseInt(dispo.inicio.substr(0,2)) >= 7 &&  parseInt(dispo.fin.substr(0,2)) <= 12 && dispo.dia == 6);

            if(zona && sucursal && niveles && sabado){
              // Verificar si ya da clase o no
              var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposSabatinos[i].hora_inicio,
                                                          this.gruposSabatinos[i].hora_fin,this.gruposSabatinos[i].id_curso,
                                                          this.configTeachers[j].online,this.gruposSabatinos[i].id_plantel,this.gruposSabatinos[i].id_unidad_negocio)

              // No da clase
              if(yadaclase == '' || yadaclase == 'b'){

                if(this.gruposSabatinos[i].bloque1 == ''){
                  // Validar que el teacher de la parte b no sea igual al de la parte a
                  if(this.gruposSabatinos[i].bloque4 != this.configTeachers[j].nombre){
                    this.gruposSabatinos[i].bloque1 =  this.configTeachers[j].nombre
                    this.gruposSabatinos[i].bloque2 =  this.configTeachers[j].nombre
                    this.gruposSabatinos[i].bloque3 =  this.configTeachers[j].nombre
                  }
                }
              }
            }
          }
        }

        // Se llena la parte A y que pueda durante el día
        for(const i in this.gruposSabatinos){
          if(this.gruposSabatinos[i].optimizado == 0 && this.gruposSabatinos[i].id_curso == 6 && this.gruposSabatinos[i].id_unidad_negocio == 2 ||
            this.gruposSabatinos[i].optimizado == 0 && this.gruposSabatinos[i].id_curso == 7 && this.gruposSabatinos[i].id_unidad_negocio == 1){

            // Vamos primero a llenar a todos los teahcer que se encuentran en sabatino y que solo pueden los fines de semana
            // ya que esos no se ocupan iterar

            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              var inicioClase     = moment(this.gruposSabatinos[i].hora_inicio,'HH:mm')
              var finClase        = moment(this.gruposSabatinos[i].hora_fin,'HH:mm')

              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposSabatinos[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposSabatinos[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                  niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposSabatinos[i].id_nivel );
                }else{niveles = true;}

              // Validamos primero que el teacher solo tenga disponibilidad 
              const sabado = this.configTeachers[j].disponibilidad.find( dispo =>  inicioClase >= moment(dispo.inicio,'HH:mm') && finClase <=  moment(dispo.fin,'HH:mm') && dispo.dia == 6);
              if(zona && sucursal && niveles && sabado){
                // Validamos que solo tenga como disponibilidad dos o un día
                if(this.configTeachers[j].disponibilidad.length <= 2){
                  // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                  // Verificar si ya da clase o no
                  var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposSabatinos[i].hora_inicio,
                                                              this.gruposSabatinos[i].hora_fin,this.gruposSabatinos[i].id_curso,
                                                              this.configTeachers[j].online,this.gruposSabatinos[i].id_plantel,this.gruposSabatinos[i].id_unidad_negocio)
                  // No da clase
                  if(yadaclase == '' || yadaclase == 'b'){
                    if(this.gruposSabatinos[i].bloque1 == ''){
                      // Validar que el teacher de la parte b no sea igual al de la parte a
                      if(this.gruposSabatinos[i].bloque4 != this.configTeachers[j].nombre){
                        this.gruposSabatinos[i].bloque1 =  this.configTeachers[j].nombre
                        this.gruposSabatinos[i].bloque2 =  this.configTeachers[j].nombre
                        this.gruposSabatinos[i].bloque3 =  this.configTeachers[j].nombre
                      }
                    }
                  }
                }
              }
            }
          }
        }

        for(const indexPl in this.sucursales){
          // Se llena la parte B
          var teachers = []
          for(const i in this.gruposSabatinos){
            if(this.gruposSabatinos[i].optimizado == 0 && this.gruposSabatinos[i].id_curso == 6 && this.gruposSabatinos[i].id_unidad_negocio == 2 ||
              this.gruposSabatinos[i].optimizado == 0 && this.gruposSabatinos[i].id_curso == 7 && this.gruposSabatinos[i].id_unidad_negocio == 1){
              if(this.gruposSabatinos[i].id_plantel == this.sucursales[indexPl].idplantel){
                var teacher = this.configTeachers.find(  teacher => teacher.nombre == this.gruposSabatinos[i].bloque1 );
                if(teacher){teachers.push(teacher)}
              }
            }
          }


          // Si solo hay uno, no habrá con quien combinarse, así que buscamos a todos los que pueden en ese plantel y los agregamos para combiar
          if(teachers.length == 1){
            teachers = []
            for(const i in this.configTeachers){
              const sucursal = this.configTeachers[i].sucursales.find(  sucursales => sucursales.idplantel == this.sucursales[indexPl].idplantel );
              const dispo = this.configTeachers[i].disponibilidad.find(  dispo => parseInt(dispo.inicio.substr(0,2)) >= 7 && parseInt(dispo.fin.substr(0,2)) <= 18 && dispo.dia == 6)
              if(sucursal && dispo){teachers.push(this.configTeachers[i])}
            }
          }

          this.permArr   = []
          this.usedChars = []

          // Generamos las posiciones
          var array = []
          for(const i in teachers){
            array.push(i)
          }
          var permutaciones = this.permute(array)

          var iteraciones = 0
          var espacioVacio = true
          var valorminimo = 10000
          var arrayTeachersSelectivos = []
          var contador = 0

          var teacherSabatinosDia = []
          // Sacar a los teachers que solo pueden en sabado
          for(const i in this.configTeachers){
            const dispo = this.configTeachers[i].disponibilidad.find(  dispo => parseInt(dispo.inicio.substr(0,2)) >= 7 && parseInt(dispo.fin.substr(0,2)) <= 12 && dispo.dia == 6)
            if(dispo){teacherSabatinosDia.push(this.configTeachers[i])}
          }

          

          // do {
            this.sabatinoParteBMedioTurno()
            var clasesVacias = 0
           
            for(const j in permutaciones){
              for(const k in permutaciones[j]){
                for(const i in this.gruposSabatinos){
                  if(this.gruposSabatinos[i].optimizado == 0 && this.gruposSabatinos[i].id_curso == 6 && this.gruposSabatinos[i].id_unidad_negocio == 2 && this.gruposSabatinos[i].bloque4 == '' ||
                    this.gruposSabatinos[i].optimizado == 0 && this.gruposSabatinos[i].id_curso == 7 && this.gruposSabatinos[i].id_unidad_negocio == 1 && this.gruposSabatinos[i].bloque4 == ''){
                    
                    if(this.gruposSabatinos[i].id_plantel == this.sucursales[indexPl].idplantel){
                      // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
                      // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
                      var inicio     = parseInt(this.gruposSabatinos[i].hora_inicio.substr(0,2))
                      var fin        = parseInt(this.gruposSabatinos[i].hora_fin.substr(0,2))

                      // Validamos que solo tenga como disponibilidad dos o un día
                      // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
                      // Validamos primero que el teacher solo tenga disponibilidad 
                      // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases
                      const sabado = teachers[permutaciones[j][k]].disponibilidad.find( dispo => 
                                                                                  inicio >= parseInt(dispo.inicio.substr(0,2)) && 
                                                                                  fin <=   parseInt(dispo.fin.substr(0,2)) && 
                                                                                  dispo.dia == 6);
                      if(!this.reglaSucursal){
                        zona     = teachers[permutaciones[j][k]].zonas.find(       zona =>       zona.idzona          == this.gruposSabatinos[i].idareas );
                        sucursal = teachers[permutaciones[j][k]].sucursales.find(  sucursales => sucursales.idplantel == this.gruposSabatinos[i].id_plantel );
                      }else{sucursal = true; zona = true}

                      if(!this.reglaNiveles){
                          niveles     = teachers[permutaciones[j][k]].niveles.find(     niveles =>    niveles.nivel        == this.gruposSabatinos[i].id_nivel );
                        }else{niveles = true;}

                      
                      if(zona && sucursal && niveles && sabado){
                        // Verificar si ya da clase
                        var yadaclase = this.buscarDispoGrupoTeacher( teachers[permutaciones[j][k]].nombre,this.gruposSabatinos[i].hora_inicio,
                                                                    this.gruposSabatinos[i].hora_fin,this.gruposSabatinos[i].id_curso,
                                                                    teachers[permutaciones[j][k]].online,this.gruposSabatinos[i].id_plantel,this.gruposSabatinos[i].id_unidad_negocio)
                        // Evaluar donde da la clase para ver si puede dar clase
                        if(yadaclase == '' || yadaclase == 'a'){
                          if(this.gruposSabatinos[i].bloque4.trim() === '' || !this.gruposSabatinos[i].bloque4 || this.gruposSabatinos[i].bloque4 == undefined){
                            if(this.gruposSabatinos[i].bloque1 != teachers[permutaciones[j][k]].nombre){
                              this.gruposSabatinos[i].bloque4 =  teachers[permutaciones[j][k]].nombre
                              this.gruposSabatinos[i].bloque5 =  teachers[permutaciones[j][k]].nombre
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              // iniciamos las clasese vacias
              var clasesVacias = 0
              // Recorremos para saber si hay clases vacias
              for(const i in this.gruposSabatinos){
                if(this.gruposSabatinos[i].optimizado == 0 && this.gruposSabatinos[i].id_curso == 6 && this.gruposSabatinos[i].id_unidad_negocio == 2 ||
                  this.gruposSabatinos[i].optimizado == 0 && this.gruposSabatinos[i].id_curso == 7 && this.gruposSabatinos[i].id_unidad_negocio == 1){
                  if(this.gruposSabatinos[i].id_plantel == this.sucursales[indexPl].idplantel){
                    if(this.gruposSabatinos[i].bloque4.trim() === '' || !this.gruposSabatinos[i].bloque4 || this.gruposSabatinos[i].bloque4 == undefined){
                      clasesVacias += 1
                    }
                  }
                }
              }

              // Si las clases son vacias, finalizar
              if(clasesVacias == 0){
                iteraciones = 100
                break;
              }else{
                // Validamos cuantas iteraciones lleva 
                if(iteraciones < 6){
                  // Si no están vacias, vaciamos todo por completo
                  for(const i in this.gruposSabatinos){
                    if(this.gruposSabatinos[i].optimizado == 0 && this.gruposSabatinos[i].id_curso == 6 && this.gruposSabatinos[i].id_unidad_negocio == 2 ||
                      this.gruposSabatinos[i].optimizado == 0 && this.gruposSabatinos[i].id_curso == 7 && this.gruposSabatinos[i].id_unidad_negocio == 1){
                      if(this.gruposSabatinos[i].id_plantel == this.sucursales[indexPl].idplantel){
                        this.gruposSabatinos[i].bloque4 = '' 
                        this.gruposSabatinos[i].bloque5 = '' 
                      }
                    }
                  }
                }
              }
              iteraciones += 1

              this.sabatinoParteBMedioTurno()

            }


            // Aumentamos las iteraciones
          // }while(iteraciones < 100) 
        }


        /******************************************* AGREGAMOS LOS DEL CURSO DE LOS DOMINGOS ************************************************/
        /********************************************* id_curso = 7 y unidad de negocio = 2  ************************************************/
        
        // Llenar los teachers que solo pueden medios turnos 
        for(const i in this.gruposDominicales){
          // Primero llenaremos la parte A
          for(const j in this.configTeachers){

            if(!this.reglaSucursal){
              zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposDominicales[i].idareas );
              sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposDominicales[i].id_plantel );
            }else{sucursal = true; zona = true}

            if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposDominicales[i].id_nivel );
              }else{niveles = true;}

            const dominical = this.configTeachers[j].disponibilidad.find( dispo =>  parseInt(dispo.inicio.substr(0,2)) >= 7 &&  parseInt(dispo.fin.substr(0,2)) <= 12 && dispo.dia == 7);
            
            if(zona && sucursal && niveles && dominical){
              // Verificar si ya da clase o no
              var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposDominicales[i].hora_inicio,
                                                          this.gruposDominicales[i].hora_fin,this.gruposDominicales[i].id_curso,
                                                          this.configTeachers[j].online,this.gruposDominicales[i].id_plantel,this.gruposDominicales[i].id_unidad_negocio)
              // No da clase
              if(yadaclase == '' || yadaclase == 'b'){

                if(this.gruposDominicales[i].bloque1 == ''){
                  // Validar que el teacher de la parte b no sea igual al de la parte a
                  if(this.gruposDominicales[i].bloque4 != this.configTeachers[j].nombre){
                    this.gruposDominicales[i].bloque1 =  this.configTeachers[j].nombre
                    this.gruposDominicales[i].bloque2 =  this.configTeachers[j].nombre
                    this.gruposDominicales[i].bloque3 =  this.configTeachers[j].nombre
                  }
                }
              }
            }
          }
        }

        // Se llena la parte A
        for(const i in this.gruposDominicales){
          // Primero llenaremos la parte A
          for(const j in this.configTeachers){
            // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
            // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
            var inicioClase     = moment(this.gruposDominicales[i].hora_inicio,'HH:mm')
            var finClase        = moment(this.gruposDominicales[i].hora_fin,'HH:mm')

            if(!this.reglaSucursal){
              zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposDominicales[i].idareas );
              sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposDominicales[i].id_plantel );
            }else{sucursal = true; zona = true}

            if(!this.reglaNiveles){
              niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposDominicales[i].id_nivel );
            }else{niveles = true;}

            const dominical = this.configTeachers[j].disponibilidad.find( dispo =>  inicioClase >= moment(dispo.inicio,'HH:mm') &&  finClase <=   moment(dispo.fin,'HH:mm') &&  dispo.dia == 7);

            if(zona && sucursal && niveles && dominical){
              // Validamos que solo tenga como disponibilidad dos o un día
              if(this.configTeachers[j].disponibilidad.length <= 2){
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposDominicales[i].hora_inicio,
                                                            this.gruposDominicales[i].hora_fin,this.gruposDominicales[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposDominicales[i].id_plantel,this.gruposDominicales[i].id_unidad_negocio)

                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){
                  if(this.gruposDominicales[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposDominicales[i].bloque4 != this.configTeachers[j].nombre){
                      this.gruposDominicales[i].bloque1 =  this.configTeachers[j].nombre
                      this.gruposDominicales[i].bloque2 =  this.configTeachers[j].nombre
                      this.gruposDominicales[i].bloque3 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          /************************************* AHORA AGREGAREMOS LOS TEACHER QUE PUEDEN MÁS DE 2 DÍAS ***********************************/
          for(const j in this.configTeachers){
            // Declaramos las horas inicio y fin de la clase y la convertimos a tiempo
            var inicioClase     = moment(this.gruposDominicales[i].hora_inicio,'HH:mm')
            var finClase        = moment(this.gruposDominicales[i].hora_fin,'HH:mm')

            // Verficamos que pueda dar clase en domingo y a esas horas
            const domingo = this.configTeachers[j].disponibilidad.find( dispo => inicioClase >= moment(dispo.inicio,'HH:mm') && finClase <=   moment(dispo.fin,'HH:mm') &&  dispo.dia == 7);

            if(!this.reglaSucursal){
              zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposDominicales[i].idareas );
              sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposDominicales[i].id_plantel );
            }else{sucursal = true; zona = true}

            if(!this.reglaNiveles){
              niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposDominicales[i].id_nivel );
            }else{niveles = true;}
            
            // Validamos las reglas
            if(zona && sucursal && niveles && domingo){
                  
              var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposDominicales[i].hora_inicio,
                                                          this.gruposDominicales[i].hora_fin,this.gruposDominicales[i].id_curso,
                                                          this.configTeachers[j].online,this.gruposDominicales[i].id_plantel,this.gruposDominicales[i].id_unidad_negocio)
              if(yadaclase == '' || yadaclase == 'b'){
                if(this.gruposDominicales[i].bloque1 == ''){
                  // Validar que el teacher de la parte b no sea igual al de la parte a
                  if(this.gruposDominicales[i].bloque4 != this.configTeachers[j].nombre){
                    this.gruposDominicales[i].bloque1 =  this.configTeachers[j].nombre
                    this.gruposDominicales[i].bloque2 =  this.configTeachers[j].nombre
                    this.gruposDominicales[i].bloque3 =  this.configTeachers[j].nombre
                  }
                }
              }
            }
          }
        }

        // y porfin se llena la parte B
        for(const indexPl in this.sucursales){
          // Se llena la parte B
          var teachers = []
          for(const i in this.gruposDominicales){
            if(this.gruposDominicales[i].id_plantel == this.sucursales[indexPl].idplantel){
              var teacherB = this.configTeachers.find(  teacher => teacher.nombre == this.gruposDominicales[i].bloque1 );
              if(teacherB){teachers.push(teacherB)}
            }
          }

          // Si solo hay uno, no habrá con quien combinarse, así que buscamos a todos los que pueden en ese plantel y los agregamos para combiar
          if(teachers.length == 1){
            teachers = []
            for(const i in this.configTeachers){
              const sucursal = this.configTeachers[i].sucursales.find(  sucursales => sucursales.idplantel == this.sucursales[indexPl].idplantel );
              const dispo =    this.configTeachers[i].disponibilidad.find( dispo => parseInt(dispo.inicio.substr(0,2)) >= 7 && parseInt(dispo.fin.substr(0,2)) <= 12 && dispo.dia == 7)
              if(sucursal && dispo){teachers.push(this.configTeachers[i])}
            }
          }

          this.permArr   = []
          this.usedChars = []

          // Generamos las posiciones
          var array = []
          for(const i in teachers){
            array.push(i)
          }
          var permutaciones = this.permute(array)
          var iteraciones = 0
          var espacioVacio = true
          var valorminimo = 10000
          var arrayTeachersSelectivos = []
          var contador = 0

          var teacherSabatinosDia = []
          // Sacar a los teachers que solo pueden en sabado
          for(const i in this.configTeachers){
            const dispo = this.configTeachers[i].disponibilidad.find(  dispo => parseInt(dispo.inicio.substr(0,2)) >= 7 && parseInt(dispo.fin.substr(0,2)) <= 12 && dispo.dia == 7)
            if(dispo){teacherSabatinosDia.push(this.configTeachers[i])}
          }
          
          // Iterar la parte B del dominical
          // do {
            this.dominicalParteBMedioTurno()
            var clasesVacias = 0
           
            for(const j in permutaciones){
              for(const k in permutaciones[j]){
                for(const i in this.gruposDominicales){
                  if(this.gruposDominicales[i].id_plantel == this.sucursales[indexPl].idplantel){
                    var inicioClase     = moment(this.gruposDominicales[i].hora_inicio,'HH:mm')
                    var finClase        = moment(this.gruposDominicales[i].hora_fin,'HH:mm')
                    
                    // Validamos que pueda el domingo
                    const domingo = teachers[permutaciones[j][k]].disponibilidad.find( dispo => inicioClase >= moment(dispo.inicio,'HH:mm') && finClase <= moment(dispo.fin,'HH:mm') && dispo.dia == 7);
                    
                    if(!this.reglaSucursal){
                      zona     = teachers[permutaciones[j][k]].zonas.find(       zona =>       zona.idzona          == this.gruposDominicales[i].idareas );
                      sucursal = teachers[permutaciones[j][k]].sucursales.find(  sucursales => sucursales.idplantel == this.gruposDominicales[i].id_plantel );
                    }else{sucursal = true; zona = true}

                    if(!this.reglaNiveles){
                      niveles     = teachers[permutaciones[j][k]].niveles.find(     niveles =>    niveles.nivel        == this.gruposDominicales[i].id_nivel );
                    }else{niveles = true;}

                    if(zona && sucursal && niveles && domingo){
                      // Verificar si ya da clase
                      var yadaclase = this.buscarDispoGrupoTeacher( teachers[permutaciones[j][k]].nombre,this.gruposDominicales[i].hora_inicio,
                                                                  this.gruposDominicales[i].hora_fin,this.gruposDominicales[i].id_curso,
                                                                  teachers[permutaciones[j][k]].online,this.gruposDominicales[i].id_plantel,this.gruposDominicales[i].id_unidad_negocio)
                      // Evaluar donde da la clase para ver si puede dar clase
                      if(yadaclase == '' || yadaclase == 'a'){
                        if(this.gruposDominicales[i].bloque4.trim() === '' || !this.gruposDominicales[i].bloque4 || this.gruposDominicales[i].bloque4 == undefined){
                          if(this.gruposDominicales[i].bloque1 != teachers[permutaciones[j][k]].nombre){
                            this.gruposDominicales[i].bloque4 =  teachers[permutaciones[j][k]].nombre
                            this.gruposDominicales[i].bloque5 =  teachers[permutaciones[j][k]].nombre
                          }
                        }
                      }
                    }
                  }
                }
              }
              // iniciamos las clasese vacias
              var clasesVacias = 0
              // Recorremos para saber si hay clases vacias
              for(const i in this.gruposDominicales){
                if(this.gruposDominicales[i].id_plantel == this.sucursales[indexPl].idplantel){
                  if(this.gruposDominicales[i].bloque4.trim() === '' || !this.gruposDominicales[i].bloque4 || this.gruposDominicales[i].bloque4 == undefined){
                    clasesVacias += 1
                  }
                }
              }


              // Si las clases son vacias, finalizar
              if(clasesVacias == 0){
                iteraciones = 100
                break;
              }else{
                // Validamos cuantas iteraciones lleva 
                if(iteraciones < permutaciones.length - 10){
                  // Si no están vacias, vaciamos todo por completo
                  for(const i in this.gruposDominicales){
                    if(this.gruposDominicales[i].id_plantel == this.sucursales[indexPl].idplantel){
                      this.gruposDominicales[i].bloque4 = '' 
                      this.gruposDominicales[i].bloque5 = '' 
                    }
                  }
                }
              }

              iteraciones += 1
              this.dominicalParteBMedioTurno()

            }


            // Aumentamos las iteraciones
          // }while(iteraciones < 100) 
        }


        /****************************************** AGREGAMOS LOS DEL CURSO DE SEMI SABATINO ************************************************/
        /******************************************* id_curso = 3, 8 y unidad de negocio = 1 ************************************************/

        // Llenar los teachers que solo pueden medios turnos 
        for(const i in this.gruposSemiSabatinos){
          // Primero llenaremos la parte A
          for(const j in this.configTeachers){
            if(!this.reglaSucursal){
              zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposSemiSabatinos[i].idareas );
              sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposSemiSabatinos[i].id_plantel );
            }else{sucursal = true; zona = true}

            if(!this.reglaNiveles){
              niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposSemiSabatinos[i].id_nivel );
            }else{niveles = true;}

            const semiSabado = this.configTeachers[j].disponibilidad.find( dispo => parseInt(dispo.inicio.substr(0,2)) >= 7 && parseInt(dispo.fin.substr(0,2)) <= 12 &&dispo.dia == 6);
            
            if(zona && sucursal && niveles && semiSabado){
              // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
              // Verificar si ya da clase o no
              var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposSemiSabatinos[i].hora_inicio,
                                                          this.gruposSemiSabatinos[i].hora_fin,this.gruposSemiSabatinos[i].id_curso,
                                                          this.configTeachers[j].online,this.gruposSemiSabatinos[i].id_plantel,this.gruposSemiSabatinos[i].id_unidad_negocio)
              // No da clase
              if(yadaclase == '' || yadaclase == 'b'){
                if(this.gruposSemiSabatinos[i].bloque1 == ''){
                  // Validar que el teacher de la parte b no sea igual al de la parte a
                  if(this.gruposSemiSabatinos[i].bloque4 != this.configTeachers[j].nombre){
                    this.gruposSemiSabatinos[i].bloque1 =  this.configTeachers[j].nombre
                    this.gruposSemiSabatinos[i].bloque2 =  this.configTeachers[j].nombre
                    this.gruposSemiSabatinos[i].bloque3 =  this.configTeachers[j].nombre
                  }
                }
              }
            }
          }
        }

        // Llenar los teachers que solo pueden medios turnos 
        for(const i in this.gruposSemiSabatinos){
          if(this.gruposSemiSabatinos[i].optimizado == 0 && this.gruposSemiSabatinos[i].id_curso == 3 && this.gruposSemiSabatinos[i].id_unidad_negocio == 1 ||
            this.gruposSemiSabatinos[i].optimizado == 0 && this.gruposSemiSabatinos[i].id_curso == 8 && this.gruposSemiSabatinos[i].id_unidad_negocio == 1){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposSemiSabatinos[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposSemiSabatinos[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposSemiSabatinos[i].id_nivel );
              }else{niveles = true;}

              const semiSabadoB = this.configTeachers[j].disponibilidad.find( dispo => parseInt(dispo.inicio.substr(0,2)) >= 12 && parseInt(dispo.fin.substr(0,2)) <= 16 && dispo.dia == 6);
              if(zona && sucursal && niveles){
                // Validamos que solo tenga como disponibilidad dos o un día
                // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
                // Validamos primero que el teacher solo tenga disponibilidad 
                if(semiSabadoB){
                  // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                  // Verificar si ya da clase o no
                  var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposSemiSabatinos[i].hora_inicio,
                                                              this.gruposSemiSabatinos[i].hora_fin,this.gruposSemiSabatinos[i].id_curso,
                                                              this.configTeachers[j].online,this.gruposSemiSabatinos[i].id_plantel,this.gruposSemiSabatinos[i].id_unidad_negocio)

                  // No da clase
                  if(yadaclase == '' || yadaclase == 'b'){

                    if(this.gruposSemiSabatinos[i].bloque1 == ''){
                      // Validar que el teacher de la parte b no sea igual al de la parte a
                      if(this.gruposSemiSabatinos[i].bloque4 != this.configTeachers[j].nombre){
                        this.gruposSemiSabatinos[i].bloque1 =  this.configTeachers[j].nombre
                        this.gruposSemiSabatinos[i].bloque2 =  this.configTeachers[j].nombre
                        this.gruposSemiSabatinos[i].bloque3 =  this.configTeachers[j].nombre
                      }
                    }
                  }
                }
              }
            }
          }
        }

        // Primero llenaremos la parte A
        for(const i in this.gruposSemiSabatinos){
          if(this.gruposSemiSabatinos[i].optimizado == 0 && this.gruposSemiSabatinos[i].id_curso == 3 && this.gruposSemiSabatinos[i].id_unidad_negocio == 1 ||
            this.gruposSemiSabatinos[i].optimizado == 0 && this.gruposSemiSabatinos[i].id_curso == 8 && this.gruposSemiSabatinos[i].id_unidad_negocio == 1){
            for(const j in this.configTeachers){
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposSemiSabatinos[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposSemiSabatinos[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                  niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposSemiSabatinos[i].id_nivel );
              }else{niveles = true;}
              
              const semiSabadoB = this.configTeachers[j].disponibilidad.find( dispo => parseInt(dispo.inicio.substr(0,2)) >= 7 && parseInt(dispo.fin.substr(0,2)) <= 18 && dispo.dia == 6);

              if(zona && sucursal && niveles && semiSabadoB){
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposSemiSabatinos[i].hora_inicio,
                                                            this.gruposSemiSabatinos[i].hora_fin,this.gruposSemiSabatinos[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposSemiSabatinos[i].id_plantel,this.gruposSemiSabatinos[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){
                  if(this.gruposSemiSabatinos[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposSemiSabatinos[i].bloque4 != this.configTeachers[j].nombre){
                      this.gruposSemiSabatinos[i].bloque1 =  this.configTeachers[j].nombre
                      this.gruposSemiSabatinos[i].bloque2 =  this.configTeachers[j].nombre
                      this.gruposSemiSabatinos[i].bloque3 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }
        }

        // Parte B turno completo
        for(const i in this.gruposSemiSabatinos){
          if(this.gruposSemiSabatinos[i].optimizado == 0 && this.gruposSemiSabatinos[i].id_curso == 3 && this.gruposSemiSabatinos[i].id_unidad_negocio == 1 ||
            this.gruposSemiSabatinos[i].optimizado == 0 && this.gruposSemiSabatinos[i].id_curso == 8 && this.gruposSemiSabatinos[i].id_unidad_negocio == 1){

            // Vamos primero a llenar a todos los teahcer que se encuentran en sabatino y que solo pueden los fines de semana
            // ya que esos no se ocupan iterar

            // Primero llenaremos la parte A
            for(const j in this.configTeachers){

              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposSemiSabatinos[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposSemiSabatinos[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposSemiSabatinos[i].id_nivel );
              }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const semiSabadoB = this.configTeachers[j].disponibilidad.find( dispo => parseInt(dispo.inicio.substr(0,2)) >= 7 && parseInt(dispo.fin.substr(0,2)) <= 18 && dispo.dia == 6);
              if(zona && sucursal && niveles && semiSabadoB){
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposSemiSabatinos[i].hora_inicio,
                                                            this.gruposSemiSabatinos[i].hora_fin,this.gruposSemiSabatinos[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposSemiSabatinos[i].id_plantel,this.gruposSemiSabatinos[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'a'){

                  if(this.gruposSemiSabatinos[i].bloque3 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposSemiSabatinos[i].bloque1 != this.configTeachers[j].nombre){
                      this.gruposSemiSabatinos[i].bloque3 =  this.configTeachers[j].nombre
                      this.gruposSemiSabatinos[i].bloque4 =  this.configTeachers[j].nombre
                      this.gruposSemiSabatinos[i].bloque5 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }
        }


        /****************************************** AGREGAMOS LOS DEL CURSO DE MARTES Y JUEVES **********************************************/
        /********************************************** id_curso = 8 y unidad de negocio = 2 ************************************************/

        // Llenar los teachers que solo pueden los martes
        for(const i in this.gruposMarteyJueves){
          // Primero llenaremos la parte A
          for(const j in this.configTeachers){
            // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
            // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
            var inicioClase     = moment(this.gruposMarteyJueves[i].hora_inicio,'HH:mm')
            var finClase        = moment(this.gruposMarteyJueves[i].hora_fin,'HH:mm')

            if(!this.reglaSucursal){
              zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposMarteyJueves[i].idareas );
              sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposMarteyJueves[i].id_plantel );
            }else{sucursal = true; zona = true}

            if(!this.reglaNiveles){
              niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposMarteyJueves[i].id_nivel );
            }else{niveles = true;}

            // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
            const martesYjueves = this.configTeachers[j].disponibilidad.find( dispo => inicioClase >= moment(dispo.inicio,'HH:mm') && finClase <=   moment(dispo.fin,'HH:mm') &&  dispo.dia == 2);

            if(zona && sucursal && niveles && martesYjueves){
              // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
              // Verificar si ya da clase o no
              var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposMarteyJueves[i].hora_inicio,
                                                          this.gruposMarteyJueves[i].hora_fin,this.gruposMarteyJueves[i].id_curso,
                                                          this.configTeachers[j].online,this.gruposMarteyJueves[i].id_plantel,this.gruposMarteyJueves[i].id_unidad_negocio)
              // No da clase
              if(yadaclase == '' || yadaclase == 'b'){

                if(this.gruposMarteyJueves[i].bloque1 == ''){
                  // Validar que el teacher de la parte b no sea igual al de la parte a
                  if(this.gruposMarteyJueves[i].bloque4 != this.configTeachers[j].nombre){
                    this.gruposMarteyJueves[i].bloque2 =  this.configTeachers[j].nombre
                  }
                }
              }
            }
          }
        }

        // Llenar los teachers que solo pueden los jueves
        for(const i in this.gruposMarteyJueves){
          // Primero llenaremos la parte A
          for(const j in this.configTeachers){
            // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
            // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
            var inicioClase     = moment(this.gruposMarteyJueves[i].hora_inicio,'HH:mm')
            var finClase        = moment(this.gruposMarteyJueves[i].hora_fin,'HH:mm')

            if(!this.reglaSucursal){
              zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposMarteyJueves[i].idareas );
              sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposMarteyJueves[i].id_plantel );
            }else{sucursal = true; zona = true}

            if(!this.reglaNiveles){
              niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposMarteyJueves[i].id_nivel );
            }else{niveles = true;}

            // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
            const martesYjueves = this.configTeachers[j].disponibilidad.find( dispo =>  inicioClase >= moment(dispo.inicio,'HH:mm') &&  finClase <=   moment(dispo.fin,'HH:mm') &&  dispo.dia == 4);
            
            if(zona && sucursal && niveles && martesYjueves){
              // Verificar si ya da clase o no
              var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposMarteyJueves[i].hora_inicio,
                                                          this.gruposMarteyJueves[i].hora_fin,this.gruposMarteyJueves[i].id_curso,
                                                          this.configTeachers[j].online,this.gruposMarteyJueves[i].id_plantel,this.gruposMarteyJueves[i].id_unidad_negocio)
              // No da clase
              if(yadaclase == '' || yadaclase == 'a'){
                if(this.gruposMarteyJueves[i].bloque4 == ''){
                  // Validar que el teacher de la parte b no sea igual al de la parte a
                  if(this.gruposMarteyJueves[i].bloque1 != this.configTeachers[j].nombre){
                    this.gruposMarteyJueves[i].bloque4 =  this.configTeachers[j].nombre
                  }
                }
              }
            }
          }
        }

        // Llenar los teacherees que pueden los dos días, y se colocan en el grupo A
        for(const i in this.gruposMarteyJueves){
          // Primero llenaremos la parte A
          for(const j in this.configTeachers){
            // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
            // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
            var inicioClase     = moment(this.gruposMarteyJueves[i].hora_inicio,'HH:mm')
            var finClase        = moment(this.gruposMarteyJueves[i].hora_fin,'HH:mm')

            if(!this.reglaSucursal){
              zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposMarteyJueves[i].idareas );
              sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposMarteyJueves[i].id_plantel );
            }else{sucursal = true; zona = true}

            if(!this.reglaNiveles){
              niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposMarteyJueves[i].id_nivel );
            }else{niveles = true;}

            // Verificamos que pueda martes y jueves
            const martes = this.configTeachers[j].disponibilidad.find( dispo => inicioClase >= moment(dispo.inicio,'HH:mm') && finClase <=  moment(dispo.fin,'HH:mm') && dispo.dia == 2);
            const jueves = this.configTeachers[j].disponibilidad.find( dispo => inicioClase >= moment(dispo.inicio,'HH:mm') && finClase <=  moment(dispo.fin,'HH:mm') && dispo.dia == 4);
            if(zona && sucursal && niveles && martes && jueves){
              // Verificar si ya da clase o no
              var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposMarteyJueves[i].hora_inicio,
                                                          this.gruposMarteyJueves[i].hora_fin,this.gruposMarteyJueves[i].id_curso,
                                                          this.configTeachers[j].online,this.gruposMarteyJueves[i].id_plantel,this.gruposMarteyJueves[i].id_unidad_negocio)
              // No da clase
              if(yadaclase == '' || yadaclase == 'b'){
                if(this.gruposMarteyJueves[i].bloque1 == ''){
                  // Validar que el teacher de la parte b no sea igual al de la parte a
                  if(this.gruposMarteyJueves[i].bloque4 != this.configTeachers[j].nombre){
                    this.gruposMarteyJueves[i].bloque2 =  this.configTeachers[j].nombre
                  }
                }
              }
            }
          }
        }


        /***************************************** AGREGAMOS LOS DEL LUNES, MIERCOLES Y VIERNES **********************************************/
        /********************************************* id_curso = 4 y unidad de negocio = 2,1 ************************************************/
        // Llenar los teacher que solo pueden el lunes y miercoles
        for(const i in this.gruposLMV){
          if(this.gruposLMV[i].optimizado == 0 && this.gruposLMV[i].id_curso == 4){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLMV[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLMV[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLMV[i].id_nivel );
              }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const lunes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLMV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLMV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 1);
              const miercoles = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                            moment(this.gruposLMV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                            moment(this.gruposLMV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                            dispo.dia == 3);

              if(zona && sucursal && niveles && lunes && miercoles){
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLMV[i].hora_inicio,
                                                            this.gruposLMV[i].hora_fin,this.gruposLMV[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposLMV[i].id_plantel,this.gruposLMV[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){
                  if(this.gruposLMV[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposLMV[i].bloque5 != this.configTeachers[j].nombre){
                      this.gruposLMV[i].bloque1 =  this.configTeachers[j].nombre
                      this.gruposLMV[i].bloque3 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }
        }

        /********** M A Ñ A N A *************/
        /************************************/
        for(const indexPl in this.sucursales){
          // Se llena la que tiene el bloque de día 
          var teachers = []
          for(const i in this.gruposLMV){
            if(this.gruposLMV[i].id_plantel == this.sucursales[indexPl].idplantel){
              if(moment(this.gruposLMV[i].hora_inicio,'HH:mm') >=  moment('07:00','HH:mm') && moment(this.gruposLMV[i].hora_fin,'HH:mm') <=   moment('12:59','HH:mm')){
                var teacher = this.configTeachers.find(  teacher => teacher.nombre == this.gruposLMV[i].bloque1 );
                if(teacher){teachers.push(teacher)}
              }
            }
          }

          this.permArr   = []
          this.usedChars = []

          // Generamos las posiciones
          var array = []
          for(const i in teachers){
            array.push(i)
          }

          if(array.length > 0){
            var permutaciones = this.permute(array)
          }

          var iteraciones = 0
          var espacioVacio = true
          var valorminimo = 10000
          var arrayTeachersSelectivos = []
          var contador = 0

          // do {
            var clasesVacias = 0
           
            for(const j in permutaciones){
              for(const k in permutaciones[j]){
                for(const i in this.gruposLMV){
                  if(this.gruposLMV[i].optimizado == 0 && this.gruposLMV[i].id_curso == 4){
                    if(moment(this.gruposLMV[i].hora_inicio,'HH:mm') >=  moment('07:00','HH:mm') && moment(this.gruposLMV[i].hora_fin,'HH:mm') <=   moment('12:59','HH:mm')){
                      if(this.gruposLMV[i].id_plantel == this.sucursales[indexPl].idplantel){
                        // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases
                        const viernes = teachers[permutaciones[j][k]].disponibilidad.find( dispo => 
                                                                                    moment(this.gruposLMV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                                    moment(this.gruposLMV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                                    dispo.dia == 5);
                        if(!this.reglaSucursal){
                          zona     = teachers[permutaciones[j][k]].zonas.find(       zona =>       zona.idzona          == this.gruposLMV[i].idareas );
                          sucursal = teachers[permutaciones[j][k]].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLMV[i].id_plantel );
                        }else{sucursal = true; zona = true}

                        if(!this.reglaNiveles){
                            niveles     = teachers[permutaciones[j][k]].niveles.find(     niveles =>    niveles.nivel        == this.gruposLMV[i].id_nivel );
                        }else{niveles = true;}

                        if(zona && sucursal && niveles && viernes){
                          // Verificar si ya da clase
                          var yadaclase = this.buscarDispoGrupoTeacher( teachers[permutaciones[j][k]].nombre,this.gruposLMV[i].hora_inicio,
                                                                      this.gruposLMV[i].hora_fin,this.gruposLMV[i].id_curso,
                                                                      teachers[permutaciones[j][k]].online,this.gruposLMV[i].id_plantel,this.gruposLMV[i].id_unidad_negocio)
                          // Evaluar donde da la clase para ver si puede dar clase
                          if(yadaclase == '' || yadaclase == 'a'){
                            if(this.gruposLMV[i].bloque5.trim() === '' || !this.gruposLMV[i].bloque5 || this.gruposLMV[i].bloque5 == undefined){
                              if(this.gruposLMV[i].bloque1 != teachers[permutaciones[j][k]].nombre){
                                this.gruposLMV[i].bloque5 =  teachers[permutaciones[j][k]].nombre
                              }
                            }
                          }

                        }
                      }
                    }
                  }
                }
              }

              // iniciamos las clasese vacias
              var clasesVacias = 0
              // Recorremos para saber si hay clases vacias
              for(const i in this.gruposLMV){
                if(this.gruposLMV[i].optimizado == 0 && this.gruposLMV[i].id_curso == 4){
                  if(this.gruposLMV[i].id_plantel == this.sucursales[indexPl].idplantel){
                    if(moment(this.gruposLMV[i].hora_inicio,'HH:mm') >=  moment('07:00','HH:mm') && moment(this.gruposLMV[i].hora_fin,'HH:mm') <=   moment('12:59','HH:mm')){
                      if(this.gruposLMV[i].bloque5.trim() === '' || !this.gruposLMV[i].bloque5 || this.gruposLMV[i].bloque5 == undefined){
                        clasesVacias += 1
                      }
                    }
                  }
                }
              }

              // Si las clases son vacias, finalizar
              if(clasesVacias == 0){
                iteraciones = 100
                break;
              }else{
                // Validamos cuantas iteraciones lleva 
                if(iteraciones < 12){
                  // Si no están vacias, vaciamos todo por completo
                  for(const i in this.gruposLMV){
                    if(this.gruposLMV[i].optimizado == 0 && this.gruposLMV[i].id_curso == 4){
                      if(this.gruposLMV[i].id_plantel == this.sucursales[indexPl].idplantel){
                        if(moment(this.gruposLMV[i].hora_inicio,'HH:mm') >=  moment('07:00','HH:mm') && moment(this.gruposLMV[i].hora_fin,'HH:mm') <=   moment('12:59','HH:mm')){
                          this.gruposLMV[i].bloque5 = '' 
                        }
                      }
                    }
                  }
                }
              }
            }
            // Aumentamos las iteraciones
            iteraciones += 1
          // }while(iteraciones < 100) 
        }

        /********** T A R D E ***************/
        /************************************/

        for(const indexPl in this.sucursales){
          // Se llena la que tiene el bloque de día 
          var teachers = []
          for(const i in this.gruposLMV){
            if(this.gruposLMV[i].id_plantel == this.sucursales[indexPl].idplantel){
              if(moment(this.gruposLMV[i].hora_inicio,'HH:mm') >=  moment('13:00','HH:mm') && moment(this.gruposLMV[i].hora_inicio,'HH:mm') <=   moment('18:59','HH:mm')){
                var teacher = this.configTeachers.find(  teacher => teacher.nombre == this.gruposLMV[i].bloque1 );
                if(teacher){teachers.push(teacher)}
              }
            }
          }

          this.permArr   = []
          this.usedChars = []

          // Generamos las posiciones
          var array = []
          if(teachers.length > 0){
            var teacherNum1 = teachers[0]
            teachers.shift()
            teachers.push(teacherNum1)
          }

          for(const i in teachers){
            array.push(i)
          }

          if(array.length > 0){
            var permutaciones = this.permute(array)
          }

          var iteraciones = 0
          var espacioVacio = true
          var valorminimo = 10000
          var arrayTeachersSelectivos = []
          var contador = 0

          if(teachers.length > 0){
            // do {
              var clasesVacias = 0
             
              for(const j in permutaciones){
                for(const k in permutaciones[j]){
                  for(const i in this.gruposLMV){
                    if(moment(this.gruposLMV[i].hora_inicio,'HH:mm') >=  moment('13:00','HH:mm') && moment(this.gruposLMV[i].hora_inicio,'HH:mm') <=   moment('18:59','HH:mm')){
                      if(this.gruposLMV[i].id_plantel == this.sucursales[indexPl].idplantel){
                        // Verificamos que pueda el viernes
                        const viernes = teachers[permutaciones[j][k]].disponibilidad.find( dispo => 
                                                                                    moment(this.gruposLMV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                                    moment(this.gruposLMV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                                    dispo.dia == 5);
                        if(viernes){
                          // Verificar si ya da clase
                          var yadaclase = this.buscarDispoGrupoTeacher( teachers[permutaciones[j][k]].nombre,this.gruposLMV[i].hora_inicio,
                                                                      this.gruposLMV[i].hora_fin,this.gruposLMV[i].id_curso,
                                                                      teachers[permutaciones[j][k]].online,this.gruposLMV[i].id_plantel,this.gruposLMV[i].id_unidad_negocio)
                          // Evaluar donde da la clase para ver si puede dar clase
                          if(yadaclase == '' || yadaclase == 'a'){

                            if(this.gruposLMV[i].bloque5.trim() === '' || !this.gruposLMV[i].bloque5 || this.gruposLMV[i].bloque5 == undefined){
                              if(this.gruposLMV[i].bloque1 != teachers[permutaciones[j][k]].nombre){
                                this.gruposLMV[i].bloque5 =  teachers[permutaciones[j][k]].nombre
                              }
                            }
                          }

                        }
                      }
                    }
                  }
                  // iniciamos las clasese vacias
                  var clasesVacias = 0
                  // Recorremos para saber si hay clases vacias
                  for(const i in this.gruposLMV){
                    if(this.gruposLMV[i].id_plantel == this.sucursales[indexPl].idplantel){
                      if(moment(this.gruposLMV[i].hora_inicio,'HH:mm') >=  moment('13:00','HH:mm') && moment(this.gruposLMV[i].hora_inicio,'HH:mm') <=   moment('18:59','HH:mm')){
                        if(this.gruposLMV[i].bloque5.trim() === '' || !this.gruposLMV[i].bloque5 || this.gruposLMV[i].bloque5 == undefined){
                          clasesVacias += 1
                        }
                      }
                    }
                  }

                  // Si las clases son vacias, finalizar
                  if(clasesVacias == 0){
                    iteraciones = 100
                    break;
                  }else{
                    // Validamos cuantas iteraciones lleva 
                    if(iteraciones < permutaciones.length - 1){
                      // Si no están vacias, vaciamos todo por completo
                      for(const i in this.gruposLMV){
                        if(this.gruposLMV[i].id_plantel == this.sucursales[indexPl].idplantel){
                          if(moment(this.gruposLMV[i].hora_inicio,'HH:mm') >=  moment('13:00','HH:mm') && moment(this.gruposLMV[i].hora_inicio,'HH:mm') <=   moment('18:59','HH:mm')){
                            this.gruposLMV[i].bloque5 = '' 
                          }
                        }
                      }
                    }
                  }

                  iteraciones += 1
                }
              }

              // Aumentamos las iteraciones
            // }while(iteraciones < 100) 
          }
        }

        /********** N O C H E ***************/
        /************************************/

        // Llenar los teachers que solo pueden los viernes yen la mañana
        for(const indexPl in this.sucursales){
          // Se llena la que tiene el bloque de día 
          var teachers = []
          for(const i in this.gruposLMV){
            if(this.gruposLMV[i].id_plantel == this.sucursales[indexPl].idplantel){
              if(moment(this.gruposLMV[i].hora_inicio,'HH:mm') >=  moment('19:01','HH:mm') && moment(this.gruposLMV[i].hora_inicio,'HH:mm') <=   moment('23:59','HH:mm')){
                var teacher = this.configTeachers.find(  teacher => teacher.nombre == this.gruposLMV[i].bloque1 );
                if(teacher){teachers.push(teacher)}
              }
            }
          }

          this.permArr   = []
          this.usedChars = []

          // Generamos las posiciones
          var array = []
          if(teachers.length > 0){
            var teacherNum1 = teachers[0]
            teachers.shift()
            teachers.push(teacherNum1)
          }

          for(const i in teachers){
            array.push(i)
          }

          if(array.length > 0){
            var permutaciones = this.permute(array)
          }

          var iteraciones = 0
          var espacioVacio = true
          var valorminimo = 10000
          var arrayTeachersSelectivos = []
          var contador = 0

          if(teachers.length > 0){
            // do {
              var clasesVacias = 0
             
              for(const j in permutaciones){
                for(const k in permutaciones[j]){
                  for(const i in this.gruposLMV){
                    if(moment(this.gruposLMV[i].hora_inicio,'HH:mm') >=  moment('19:01','HH:mm') && moment(this.gruposLMV[i].hora_inicio,'HH:mm') <=   moment('23:59','HH:mm')){
                      if(this.gruposLMV[i].id_plantel == this.sucursales[indexPl].idplantel){
                        // Verificamos que pueda el viernes
                        const viernes = teachers[permutaciones[j][k]].disponibilidad.find( dispo => 
                                                                                    moment(this.gruposLMV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                                    moment(this.gruposLMV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                                    dispo.dia == 5);
                        if(viernes){
                          // Verificar si ya da clase
                          var yadaclase = this.buscarDispoGrupoTeacher( teachers[permutaciones[j][k]].nombre,this.gruposLMV[i].hora_inicio,
                                                                      this.gruposLMV[i].hora_fin,this.gruposLMV[i].id_curso,
                                                                      teachers[permutaciones[j][k]].online,this.gruposLMV[i].id_plantel,this.gruposLMV[i].id_unidad_negocio)
                          // Evaluar donde da la clase para ver si puede dar clase
                          if(yadaclase == '' || yadaclase == 'a'){

                            if(this.gruposLMV[i].bloque5.trim() === '' || !this.gruposLMV[i].bloque5 || this.gruposLMV[i].bloque5 == undefined){
                              if(this.gruposLMV[i].bloque1 != teachers[permutaciones[j][k]].nombre){
                                this.gruposLMV[i].bloque5 =  teachers[permutaciones[j][k]].nombre
                              }
                            }
                          }

                        }
                      }
                    }
                  }
                  // iniciamos las clasese vacias
                  var clasesVacias = 0
                  // Recorremos para saber si hay clases vacias
                  for(const i in this.gruposLMV){
                    if(this.gruposLMV[i].id_plantel == this.sucursales[indexPl].idplantel){
                      if(moment(this.gruposLMV[i].hora_inicio,'HH:mm') >=  moment('19:01','HH:mm') && moment(this.gruposLMV[i].hora_inicio,'HH:mm') <=   moment('23:59','HH:mm')){
                        if(this.gruposLMV[i].bloque5.trim() === '' || !this.gruposLMV[i].bloque5 || this.gruposLMV[i].bloque5 == undefined){
                          clasesVacias += 1
                        }
                      }
                    }
                  }

                  // Si las clases son vacias, finalizar
                  if(clasesVacias == 0){
                    iteraciones = 100
                    break;
                  }else{
                    // Validamos cuantas iteraciones lleva 
                    if(iteraciones < permutaciones.length - 1){
                      // Si no están vacias, vaciamos todo por completo
                      for(const i in this.gruposLMV){
                        if(this.gruposLMV[i].id_plantel == this.sucursales[indexPl].idplantel){
                          if(moment(this.gruposLMV[i].hora_inicio,'HH:mm') >=  moment('19:01','HH:mm') && moment(this.gruposLMV[i].hora_inicio,'HH:mm') <=   moment('23:59','HH:mm')){
                            this.gruposLMV[i].bloque5 = '' 
                          }
                        }
                      }
                    }
                  }

                  iteraciones += 1
                }
              }

              // Aumentamos las iteraciones
            // }while(iteraciones < 100) 
          }
        }


        /************************************************ AGREGAMOS LOS DEL LUNES a JUEVES ***************************************************/
        /********************************************* id_curso = 9 y unidad de negocio = 1 **************************************************/

        // Llenar los teacher que solo pueden el lunes martes y miercoles
        for(const i in this.gruposLJ){
          if(this.gruposLJ[i].optimizado == 0 && this.gruposLJ[i].id_curso == 9 && this.gruposLJ[i].id_unidad_negocio == 1){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLJ[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLJ[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                  niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLJ[i].id_nivel );
                }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const lunes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLJ[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLJ[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 1);
              const martes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                            moment(this.gruposLJ[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                            moment(this.gruposLJ[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                            dispo.dia == 2);
              const miercoles = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                            moment(this.gruposLJ[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                            moment(this.gruposLJ[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                            dispo.dia == 3);
              if(zona && sucursal && niveles && lunes && martes && miercoles){
                // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLJ[i].hora_inicio,
                                                            this.gruposLJ[i].hora_fin,this.gruposLJ[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposLJ[i].id_plantel,this.gruposLJ[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){
                  if(this.gruposLJ[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposLJ[i].bloque5 != this.configTeachers[j].nombre){
                      this.gruposLJ[i].bloque1 =  this.configTeachers[j].nombre
                      this.gruposLJ[i].bloque2 =  this.configTeachers[j].nombre
                      this.gruposLJ[i].bloque3 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }
        }

        for(const i in this.gruposLJ){
          if(this.gruposLJ[i].optimizado == 0 && this.gruposLJ[i].id_curso == 9 && this.gruposLJ[i].id_unidad_negocio == 1){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLJ[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLJ[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                  niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLJ[i].id_nivel );
                }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const jueves = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLJ[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLJ[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 4);
              if(zona && sucursal && niveles && jueves){
                // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLJ[i].hora_inicio,
                                                            this.gruposLJ[i].hora_fin,this.gruposLJ[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposLJ[i].id_plantel,this.gruposLJ[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'a'){
                  if(this.gruposLJ[i].bloque4 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposLJ[i].bloque1 != this.configTeachers[j].nombre){
                      this.gruposLJ[i].bloque4 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }
        }


        /****************************************** AGREGAMOS LOS DEL CURSO DE LUNES A VIERNES **********************************************/
        /******************************************* id_curso = 2 y unidad de negocio = 1,2 *************************************************/
        // Llenamos la parte A de Lunes a viernes
        for(const i in this.gruposLV){
          // Primero llenaremos la parte A
          for(const j in this.configTeachers){
            // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
            // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
            if(!this.reglaSucursal){
              zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLV[i].idareas );
              sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLV[i].id_plantel );
            }else{sucursal = true; zona = true}

            if(!this.reglaNiveles){
              niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLV[i].id_nivel );
            }else{niveles = true;}

            // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
            const lunes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                        moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                        moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                        dispo.dia == 1);
            const martes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 2);
            const miercoles = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 3);
            if(zona && sucursal && niveles && lunes && martes && miercoles){
              // Verificar si ya da clase o no
              var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLV[i].hora_inicio,
                                                          this.gruposLV[i].hora_fin,this.gruposLV[i].id_curso,
                                                          this.configTeachers[j].online,this.gruposLV[i].id_plantel,this.gruposLV[i].id_unidad_negocio)
              // No da clase
              if(yadaclase == '' || yadaclase == 'b'){
                if(this.gruposLV[i].bloque1 == ''){
                  // Validar que el teacher de la parte b no sea igual al de la parte a
                  if(this.gruposLV[i].bloque5 != this.configTeachers[j].nombre){
                    this.gruposLV[i].bloque1 =  this.configTeachers[j].nombre
                    this.gruposLV[i].bloque2 =  this.configTeachers[j].nombre
                    this.gruposLV[i].bloque3 =  this.configTeachers[j].nombre
                  }
                }
              }
            }
          }
        }

        /********** M A Ñ A N A *************/
        /************************************/
        for(const indexPl in this.sucursales){
          // Se llena la que tiene el bloque de día 
          var teachers = []
          for(const i in this.gruposLV){
            if(this.gruposLV[i].id_plantel == this.sucursales[indexPl].idplantel){
              if(moment(this.gruposLV[i].hora_inicio,'HH:mm') >=  moment('07:00','HH:mm') && moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment('15:59','HH:mm')){
                var teacher = this.configTeachers.find(  teacher => teacher.nombre == this.gruposLV[i].bloque1 );
                if(teacher){teachers.push(teacher)}
              }
            }
          }

          this.permArr   = []
          this.usedChars = []

          // Generamos las posiciones
          var array = []
          for(const i in teachers){
            array.push(i)
          }

          if(array.length > 0){
            var permutaciones = this.permute(array)
          }

          var iteraciones = 0
          var espacioVacio = true
          var valorminimo = 10000
          var arrayTeachersSelectivos = []
          var contador = 0
          permutaciones = permutaciones.reverse()
          if(teachers.length > 0){

            do {
              var clasesVacias = 0
             
              for(const i in this.gruposLV){
                if(moment(this.gruposLV[i].hora_inicio,'HH:mm') >=  moment('07:00','HH:mm') && moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment('15:59','HH:mm')){
                  if(this.gruposLV[i].id_plantel == this.sucursales[indexPl].idplantel){
                    // Primero llenaremos la parte A
                    for(const j in teachers){
                      // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
                      // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
                      if(!this.reglaSucursal){
                        zona     = teachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLV[i].idareas );
                        sucursal = teachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLV[i].id_plantel );
                      }else{sucursal = true; zona = true}

                      if(!this.reglaNiveles){
                        niveles     = teachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLV[i].id_nivel );
                      }else{niveles = true;}

                      // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
                      const jueves = teachers[j].disponibilidad.find( dispo => 
                                                                                  moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                                  moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                                  dispo.dia == 4);
                      const viernes = teachers[j].disponibilidad.find( dispo => 
                                                                                    moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                                    moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                                    dispo.dia == 5);
                      if(zona && sucursal && niveles && jueves && viernes){
                        // Verificar si ya da clase o no
                        var yadaclase = this.buscarDispoGrupoTeacher( teachers[j].nombre,this.gruposLV[i].hora_inicio,
                                                                    this.gruposLV[i].hora_fin,this.gruposLV[i].id_curso,
                                                                    teachers[j].online,this.gruposLV[i].id_plantel,this.gruposLV[i].id_unidad_negocio)
                        // No da clase
                        if(yadaclase == '' || yadaclase == 'a'){
                          if(this.gruposLV[i].bloque4 == ''){
                            // Validar que el teacher de la parte b no sea igual al de la parte a
                            if(this.gruposLV[i].bloque1 != teachers[j].nombre){
                              this.gruposLV[i].bloque4 =  teachers[j].nombre
                              this.gruposLV[i].bloque5 =  teachers[j].nombre
                            }
                          }
                        }
                      }
                    }

                    var teacherNum1 = teachers[0]
                    teachers.shift()
                    teachers.push(teacherNum1)
                  }
                }
              }
              // iniciamos las clasese vacias
              var clasesVacias = 0
              // Recorremos para saber si hay clases vacias
              for(const i in this.gruposLV){
                if(this.gruposLV[i].id_plantel == this.sucursales[indexPl].idplantel){
                  if(moment(this.gruposLV[i].hora_inicio,'HH:mm') >=  moment('07:00','HH:mm') && moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment('15:59','HH:mm')){
                    if(this.gruposLV[i].bloque5.trim() === '' || !this.gruposLV[i].bloque5 || this.gruposLV[i].bloque5 == undefined){
                      clasesVacias += 1
                    }
                  }
                }
              }

              // Si las clases son vacias, finalizar
              if(clasesVacias == 0){
                iteraciones = 100
                break;
              }else{
                // Validamos cuantas iteraciones lleva 
                if(iteraciones < 12){
                  // Si no están vacias, vaciamos todo por completo
                  for(const i in this.gruposLV){
                    if(this.gruposLV[i].id_plantel == this.sucursales[indexPl].idplantel){
                      if(moment(this.gruposLV[i].hora_inicio,'HH:mm') >=  moment('07:00','HH:mm') && moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment('15:59','HH:mm')){
                        this.gruposLV[i].bloque4 = '' 
                        this.gruposLV[i].bloque5 = '' 
                      }
                    }
                  }
                }
              }
              iteraciones += 1
            }while(iteraciones < 100 || clasesVacias == 0) 
          }
        }


        /********** T A R D E ***************/
        /************************************/

        // Llenar los teachers que solo pueden los viernes yen la mañana
        for(const indexPl in this.sucursales){
          // Se llena la que tiene el bloque de día 
          var teachers = []
          for(const i in this.gruposLV){
            if(this.gruposLV[i].id_plantel == this.sucursales[indexPl].idplantel){
              if(moment(this.gruposLV[i].hora_inicio,'HH:mm') >=  moment('16:00','HH:mm') && moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment('23:59','HH:mm')){
                var teacher = this.configTeachers.find(  teacher => teacher.nombre == this.gruposLV[i].bloque1 );
                if(teacher){teachers.push(teacher)}
              }
            }
          }
          this.permArr   = []
          this.usedChars = []

          // Generamos las posiciones
          var array = []
          for(const i in teachers){
            array.push(i)
          }

          if(array.length > 0){
            var permutaciones = this.permute(array)
          }

          var iteraciones = 0
          var espacioVacio = true
          var valorminimo = 10000
          var arrayTeachersSelectivos = []
          var contador = 0

          permutaciones = permutaciones.reverse()

          var clasesVacias = 0
          if(teachers.length > 0){
            do{
              for(const i in this.gruposLV){
                if(moment(this.gruposLV[i].hora_inicio,'HH:mm') >=  moment('16:00','HH:mm') && moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment('23:59','HH:mm')){
                  if(this.gruposLV[i].id_plantel == this.sucursales[indexPl].idplantel){
                    // Primero llenaremos la parte A
                    for(const j in teachers){
                      // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
                      // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
                      if(!this.reglaSucursal){
                        zona     = teachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLV[i].idareas );
                        sucursal = teachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLV[i].id_plantel );
                      }else{sucursal = true; zona = true}

                      if(!this.reglaNiveles){
                        niveles     = teachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLV[i].id_nivel );
                      }else{niveles = true;}

                      // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
                      const jueves = teachers[j].disponibilidad.find( dispo => 
                                                                                  moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                                  moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                                  dispo.dia == 4);
                      const viernes = teachers[j].disponibilidad.find( dispo => 
                                                                                    moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                                    moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                                    dispo.dia == 5);
                      if(zona && sucursal && niveles && jueves && viernes){
                        // Verificar si ya da clase o no
                        var yadaclase = this.buscarDispoGrupoTeacher( teachers[j].nombre,this.gruposLV[i].hora_inicio,
                                                                    this.gruposLV[i].hora_fin,this.gruposLV[i].id_curso,
                                                                    teachers[j].online,this.gruposLV[i].id_plantel,this.gruposLV[i].id_unidad_negocio)
                        // No da clase
                        if(yadaclase == '' || yadaclase == 'a'){
                          if(this.gruposLV[i].bloque4 == ''){
                            // Validar que el teacher de la parte b no sea igual al de la parte a
                            if(this.gruposLV[i].bloque1 != teachers[j].nombre){
                              this.gruposLV[i].bloque4 =  teachers[j].nombre
                              this.gruposLV[i].bloque5 =  teachers[j].nombre
                            }
                          }
                        }
                      }
                    }

                    var teacherNum1 = teachers[0]
                    teachers.shift()
                    teachers.push(teacherNum1)
                  }
                }
              }

              // iniciamos las clasese vacias
              var clasesVacias = 0
              // Recorremos para saber si hay clases vacias
              for(const i in this.gruposLV){
                if(this.gruposLV[i].id_plantel == this.sucursales[indexPl].idplantel){
                  if(moment(this.gruposLV[i].hora_inicio,'HH:mm') >=  moment('16:00','HH:mm') && moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment('23:59','HH:mm')){
                    if(this.gruposLV[i].bloque5.trim() === '' || !this.gruposLV[i].bloque5 || this.gruposLV[i].bloque5 == undefined){
                      clasesVacias += 1
                    }
                  }
                }
              }

              // Si las clases son vacias, finalizar
              if(clasesVacias == 0){
                iteraciones = 100
                break;
              }else if(iteraciones < permutaciones.length - 1){
                // Validamos cuantas iteraciones lleva 
                // Si no están vacias, vaciamos todo por completo
                for(const i in this.gruposLV){
                  if(this.gruposLV[i].id_plantel == this.sucursales[indexPl].idplantel){
                    if(moment(this.gruposLV[i].hora_inicio,'HH:mm') >=  moment('16:00','HH:mm') && moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment('23:59','HH:mm')){
                      this.gruposLV[i].bloque4 = '' 
                      this.gruposLV[i].bloque5 = '' 
                    }
                  }
                }
              }
              
              iteraciones += 1
            }while(iteraciones < 50 || clasesVacias == 0)
          }
        }

        /************ PARTE B FALTANTE *************/
        /*******************************************/

        for(const i in this.gruposLV){
          if(this.gruposLV[i].optimizado == 0 && this.gruposLV[i].id_curso == 2){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLV[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLV[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLV[i].id_nivel );
              }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const jueves = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 4);
              const viernes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 5);
              if(zona && sucursal && niveles && jueves && viernes){
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLV[i].hora_inicio,
                                                              this.gruposLV[i].hora_fin,this.gruposLV[i].id_curso,
                                                              this.configTeachers[j].online,this.gruposLV[i].id_plantel,this.gruposLV[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'a'){
                  if(this.gruposLV[i].bloque4 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposLV[i].bloque1 != this.configTeachers[j].nombre){
                      this.gruposLV[i].bloque4 =  this.configTeachers[j].nombre
                      this.gruposLV[i].bloque5 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }
        }


        // Llenar los teachers que solo pueden los viernes yen la mañana

        /************************************************************************************************************************************/
        /**************************************************************** FIN ***************************************************************/
        /************************************************************************************************************************************/


        /************************************************* VALIDAR LA INFORMACIÓN DEL GRUPO *************************************************/
        this.contarClasesVacias ()
        this.grupoRolesPerfecto = this.gruposRoles
        this.exportExcel()

        return
      
      },

      romperReglasucursal () {
        var sucursal = false
        var zona     = false
        var niveles  = false

        var contadorClasesVacias = 0
        for(const i in this.gruposRoles){
          if(this.gruposRoles[i].optimizado == 0){
            switch(this.gruposRoles[i].id_curso){
              case 2 || 6 || 7: 
                if(this.gruposRoles[i].bloque1 == ''){contadorClasesVacias += 3}
                if(this.gruposRoles[i].bloque4 == ''){contadorClasesVacias += 2}
              break;

              case 4: 
                if(this.gruposRoles[i].bloque1 == ''){contadorClasesVacias += 2}
                if(this.gruposRoles[i].bloque5 == ''){contadorClasesVacias += 1}
              break;

              case 8: 
                if(this.gruposRoles[i].id_unidad_negocio == 2){
                  if(this.gruposRoles[i].bloque2 == ''){contadorClasesVacias += 1}
                  if(this.gruposRoles[i].bloque4 == ''){contadorClasesVacias += 1}
                }else{
                  if(this.gruposRoles[i].bloque1 == ''){contadorClasesVacias += 3}
                }
              break;

              case 3: 
                if(this.gruposRoles[i].bloque1 == ''){contadorClasesVacias += 3}
              break;

              case 9: 
                if(this.gruposRoles[i].bloque1 == ''){contadorClasesVacias += 3}
                if(this.gruposRoles[i].bloque4 == ''){contadorClasesVacias += 1}
              break;

            }
          }
        }

        this.iteraciones += 1

        if(contadorClasesVacias > 0 ){
          this.reglaSucursal = true

          /******************************************** AGREGAMOS LOS DEL CURSO DE LOS SABADOS ************************************************/
          /********************************************* id_curso = 6 y unidad de negocio = 2  ************************************************/
          /********************************************* id_curso = 7 y unidad de negocio = 1  ************************************************/
          
          // Llenar los teachers que solo pueden medios turnos en la mañana
          for(const i in this.gruposSabatinos){
            // Vamos primero a llenar a todos los teahcer que se encuentran en sabatino y que solo pueden los fines de semana
            // ya que esos no se ocupan iterar

            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              var inicioClase     = moment(this.gruposSabatinos[i].hora_inicio,'HH:mm')
              var finClase        = moment(this.gruposSabatinos[i].hora_fin,'HH:mm')

              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposSabatinos[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposSabatinos[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                  niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposSabatinos[i].id_nivel );
                }else{niveles = true;}

              // Validamos primero que el teacher solo tenga disponibilidad 
              const sabado = this.configTeachers[j].disponibilidad.find( dispo =>  inicioClase >= moment(dispo.inicio,'HH:mm') && finClase <=  moment(dispo.fin,'HH:mm') && dispo.dia == 6);
              if(zona && sucursal && niveles && sabado){
                // Validamos que solo tenga como disponibilidad dos o un día
                // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposSabatinos[i].hora_inicio,
                                                            this.gruposSabatinos[i].hora_fin,this.gruposSabatinos[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposSabatinos[i].id_plantel,this.gruposSabatinos[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){
                  if(this.gruposSabatinos[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposSabatinos[i].bloque4 != this.configTeachers[j].nombre){
                      this.gruposSabatinos[i].bloque1 =  this.configTeachers[j].nombre
                      this.gruposSabatinos[i].bloque2 =  this.configTeachers[j].nombre
                      this.gruposSabatinos[i].bloque3 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          // Llenar los teachers que solo pueden medios turnos en la mañana
          for(const i in this.gruposSabatinos){
            // Vamos primero a llenar a todos los teahcer que se encuentran en sabatino y que solo pueden los fines de semana
            // ya que esos no se ocupan iterar

            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              var inicioClase     = moment(this.gruposSabatinos[i].hora_inicio,'HH:mm')
              var finClase        = moment(this.gruposSabatinos[i].hora_fin,'HH:mm')

              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposSabatinos[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposSabatinos[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                  niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposSabatinos[i].id_nivel );
                }else{niveles = true;}

              // Validamos primero que el teacher solo tenga disponibilidad 
              const sabado = this.configTeachers[j].disponibilidad.find( dispo =>  inicioClase >= moment(dispo.inicio,'HH:mm') && finClase <=  moment(dispo.fin,'HH:mm') && dispo.dia == 6);
              if(zona && sucursal && niveles && sabado){
                // Validamos que solo tenga como disponibilidad dos o un día
                // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposSabatinos[i].hora_inicio,
                                                            this.gruposSabatinos[i].hora_fin,this.gruposSabatinos[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposSabatinos[i].id_plantel,this.gruposSabatinos[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'a'){
                  if(this.gruposSabatinos[i].bloque4 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposSabatinos[i].bloque1 != this.configTeachers[j].nombre){
                      this.gruposSabatinos[i].bloque4 =  this.configTeachers[j].nombre
                      this.gruposSabatinos[i].bloque5 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          /******************************************* AGREGAMOS LOS DEL CURSO DE LOS DOMINGOS ************************************************/
          /********************************************* id_curso = 7 y unidad de negocio = 2  ************************************************/
          
          // Se llena la parte A
          for(const i in this.gruposDominicales){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              var inicioClase     = moment(this.gruposDominicales[i].hora_inicio,'HH:mm')
              var finClase        = moment(this.gruposDominicales[i].hora_fin,'HH:mm')

              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposDominicales[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposDominicales[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposDominicales[i].id_nivel );
              }else{niveles = true;}

              const dominical = this.configTeachers[j].disponibilidad.find( dispo =>  inicioClase >= moment(dispo.inicio,'HH:mm') &&  finClase <=   moment(dispo.fin,'HH:mm') &&  dispo.dia == 7);

              if(zona && sucursal && niveles && dominical){
                // Validamos que solo tenga como disponibilidad dos o un día
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposDominicales[i].hora_inicio,
                                                            this.gruposDominicales[i].hora_fin,this.gruposDominicales[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposDominicales[i].id_plantel,this.gruposDominicales[i].id_unidad_negocio)

                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){
                  if(this.gruposDominicales[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposDominicales[i].bloque4 != this.configTeachers[j].nombre){
                      this.gruposDominicales[i].bloque1 =  this.configTeachers[j].nombre
                      this.gruposDominicales[i].bloque2 =  this.configTeachers[j].nombre
                      this.gruposDominicales[i].bloque3 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          for(const i in this.gruposDominicales){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              var inicioClase     = moment(this.gruposDominicales[i].hora_inicio,'HH:mm')
              var finClase        = moment(this.gruposDominicales[i].hora_fin,'HH:mm')

              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposDominicales[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposDominicales[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposDominicales[i].id_nivel );
              }else{niveles = true;}

              const dominical = this.configTeachers[j].disponibilidad.find( dispo =>  inicioClase >= moment(dispo.inicio,'HH:mm') &&  finClase <=   moment(dispo.fin,'HH:mm') &&  dispo.dia == 7);

              if(zona && sucursal && niveles && dominical){
                // Validamos que solo tenga como disponibilidad dos o un día
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposDominicales[i].hora_inicio,
                                                            this.gruposDominicales[i].hora_fin,this.gruposDominicales[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposDominicales[i].id_plantel,this.gruposDominicales[i].id_unidad_negocio)

                // No da clase
                if(yadaclase == '' || yadaclase == 'a'){
                  if(this.gruposDominicales[i].bloque4 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposDominicales[i].bloque1 != this.configTeachers[j].nombre){
                      this.gruposDominicales[i].bloque4 =  this.configTeachers[j].nombre
                      this.gruposDominicales[i].bloque5 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          /****************************************** AGREGAMOS LOS DEL CURSO DE MARTES Y JUEVES **********************************************/
          /********************************************** id_curso = 8 y unidad de negocio = 2 ************************************************/

          // Llenar los teachers que solo pueden los martes
          for(const i in this.gruposMarteyJueves){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              var inicioClase     = moment(this.gruposMarteyJueves[i].hora_inicio,'HH:mm')
              var finClase        = moment(this.gruposMarteyJueves[i].hora_fin,'HH:mm')

              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposMarteyJueves[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposMarteyJueves[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposMarteyJueves[i].id_nivel );
              }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const jueves = this.configTeachers[j].disponibilidad.find( dispo => inicioClase >= moment(dispo.inicio,'HH:mm') && finClase <=   moment(dispo.fin,'HH:mm') &&  dispo.dia == 2);

              if(zona && sucursal && niveles && jueves){
                // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposMarteyJueves[i].hora_inicio,
                                                            this.gruposMarteyJueves[i].hora_fin,this.gruposMarteyJueves[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposMarteyJueves[i].id_plantel,this.gruposMarteyJueves[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){

                  if(this.gruposMarteyJueves[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposMarteyJueves[i].bloque4 != this.configTeachers[j].nombre){
                      this.gruposMarteyJueves[i].bloque2 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          for(const i in this.gruposMarteyJueves){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              var inicioClase     = moment(this.gruposMarteyJueves[i].hora_inicio,'HH:mm')
              var finClase        = moment(this.gruposMarteyJueves[i].hora_fin,'HH:mm')

              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposMarteyJueves[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposMarteyJueves[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposMarteyJueves[i].id_nivel );
              }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const martesYjueves = this.configTeachers[j].disponibilidad.find( dispo => inicioClase >= moment(dispo.inicio,'HH:mm') && finClase <=   moment(dispo.fin,'HH:mm') &&  dispo.dia == 4);

              if(zona && sucursal && niveles && martesYjueves){
                // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposMarteyJueves[i].hora_inicio,
                                                            this.gruposMarteyJueves[i].hora_fin,this.gruposMarteyJueves[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposMarteyJueves[i].id_plantel,this.gruposMarteyJueves[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'a'){

                  if(this.gruposMarteyJueves[i].bloque4 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposMarteyJueves[i].bloque2 != this.configTeachers[j].nombre){
                      this.gruposMarteyJueves[i].bloque4 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          /***************************************** AGREGAMOS LOS DEL LUNES, MIERCOLES Y VIERNES **********************************************/
          /********************************************* id_curso = 4 y unidad de negocio = 2,1 ************************************************/
          // Llenar los teacher que solo pueden el lunes y miercoles
          for(const i in this.gruposLMV){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLMV[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLMV[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLMV[i].id_nivel );
              }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const lunes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLMV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLMV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 1);
              const miercoles = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                            moment(this.gruposLMV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                            moment(this.gruposLMV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                            dispo.dia == 3);

              if(zona && sucursal && niveles && lunes && miercoles){
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLMV[i].hora_inicio,
                                                            this.gruposLMV[i].hora_fin,this.gruposLMV[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposLMV[i].id_plantel,this.gruposLMV[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){
                  if(this.gruposLMV[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposLMV[i].bloque5 != this.configTeachers[j].nombre){
                      this.gruposLMV[i].bloque1 =  this.configTeachers[j].nombre
                      this.gruposLMV[i].bloque3 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          for(const i in this.gruposLMV){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLMV[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLMV[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLMV[i].id_nivel );
              }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const viernes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLMV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLMV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 5);
              if(zona && sucursal && niveles && viernes){
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLMV[i].hora_inicio,
                                                            this.gruposLMV[i].hora_fin,this.gruposLMV[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposLMV[i].id_plantel,this.gruposLMV[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'a'){
                  if(this.gruposLMV[i].bloque5 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposLMV[i].bloque1 != this.configTeachers[j].nombre){
                      this.gruposLMV[i].bloque5 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          /************************************************ AGREGAMOS LOS DEL LUNES a JUEVES ***************************************************/
          /********************************************* id_curso = 9 y unidad de negocio = 1 **************************************************/

          // Llenar los teacher que solo pueden el lunes martes y miercoles
          for(const i in this.gruposLJ){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLJ[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLJ[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                  niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLJ[i].id_nivel );
                }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const lunes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLJ[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLJ[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 1);
              const martes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                            moment(this.gruposLJ[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                            moment(this.gruposLJ[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                            dispo.dia == 2);
              const miercoles = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                            moment(this.gruposLJ[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                            moment(this.gruposLJ[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                            dispo.dia == 3);
              if(zona && sucursal && niveles && lunes && martes && miercoles){
                // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLJ[i].hora_inicio,
                                                            this.gruposLJ[i].hora_fin,this.gruposLJ[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposLJ[i].id_plantel,this.gruposLJ[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){
                  if(this.gruposLJ[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposLJ[i].bloque5 != this.configTeachers[j].nombre){
                      this.gruposLJ[i].bloque1 =  this.configTeachers[j].nombre
                      this.gruposLJ[i].bloque2 =  this.configTeachers[j].nombre
                      this.gruposLJ[i].bloque3 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          for(const i in this.gruposLJ){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLJ[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLJ[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                  niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLJ[i].id_nivel );
                }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const jueves = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLJ[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLJ[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 4);
              if(zona && sucursal && niveles && jueves){
                // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLJ[i].hora_inicio,
                                                            this.gruposLJ[i].hora_fin,this.gruposLJ[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposLJ[i].id_plantel,this.gruposLJ[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'a'){
                  if(this.gruposLJ[i].bloque4 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposLJ[i].bloque1 != this.configTeachers[j].nombre){
                      this.gruposLJ[i].bloque4 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          /****************************************** AGREGAMOS LOS DEL CURSO DE LUNES A VIERNES **********************************************/
          /******************************************* id_curso = 2 y unidad de negocio = 1,2 *************************************************/
          // Llenamos la parte A de Lunes a viernes
          for(const i in this.gruposLV){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLV[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLV[i].id_plantel );
              }else{sucursal = true; zona = true;}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLV[i].id_nivel );
              }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const lunes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 1);
              const martes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                            moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                            moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                            dispo.dia == 2);
              const miercoles = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                            moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                            moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                            dispo.dia == 3);
              if(zona && sucursal && niveles && lunes && martes && miercoles){
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLV[i].hora_inicio,
                                                            this.gruposLV[i].hora_fin,this.gruposLV[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposLV[i].id_plantel,this.gruposLV[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){
                  if(this.gruposLV[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposLV[i].bloque5 != this.configTeachers[j].nombre){
                      this.gruposLV[i].bloque1 =  this.configTeachers[j].nombre
                      this.gruposLV[i].bloque2 =  this.configTeachers[j].nombre
                      this.gruposLV[i].bloque3 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          // Parte b ******************************************************************************
          // Llenamos la parte A de Lunes a viernes
          for(const i in this.gruposLV){
            if(this.gruposLV[i].bloque5 == ''){
              // Primero llenaremos la parte A
              for(const j in this.configTeachers){
                // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
                // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
                if(!this.reglaSucursal){
                  zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLV[i].idareas );
                  sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLV[i].id_plantel );
                }else{sucursal = true; zona = true}

                if(!this.reglaNiveles){
                  niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLV[i].id_nivel );
                }else{niveles = true;}

                // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
                const jueves = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                            moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                            moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                            dispo.dia == 4);
                const viernes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                              moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                              moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                              dispo.dia == 5);
                if(zona && sucursal && niveles && jueves && viernes){
                  // Verificar si ya da clase o no
                  var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLV[i].hora_inicio,
                                                              this.gruposLV[i].hora_fin,this.gruposLV[i].id_curso,
                                                              this.configTeachers[j].online,this.gruposLV[i].id_plantel,this.gruposLV[i].id_unidad_negocio)
                  // No da clase
                  if(yadaclase == '' || yadaclase == 'a'){
                    if(this.gruposLV[i].bloque5 == ''){
                      // Validar que el teacher de la parte b no sea igual al de la parte a
                      if(this.gruposLV[i].bloque1 != this.configTeachers[j].nombre){
                        this.gruposLV[i].bloque4 =  this.configTeachers[j].nombre
                        this.gruposLV[i].bloque5 =  this.configTeachers[j].nombre
                      }
                    }
                  }
                }
              }
            }
          }
          this.contarClasesVacias()
          return
        }else{
          return
        }
      },

      romperReglaniveles () {
        var sucursal = false
        var zona     = false
        var niveles  = false

        var contadorClasesVacias = 0
        for(const i in this.gruposRoles){
          if(this.gruposRoles[i].optimizado == 0){
            switch(this.gruposRoles[i].id_curso){
              case 2 || 6 || 7: 
                if(this.gruposRoles[i].bloque1 == ''){contadorClasesVacias += 3}
                if(this.gruposRoles[i].bloque4 == ''){contadorClasesVacias += 2}
              break;

              case 4: 
                if(this.gruposRoles[i].bloque1 == ''){contadorClasesVacias += 2}
                if(this.gruposRoles[i].bloque5 == ''){contadorClasesVacias += 1}
              break;

              case 8: 
                if(this.gruposRoles[i].id_unidad_negocio == 2){
                  if(this.gruposRoles[i].bloque2 == ''){contadorClasesVacias += 1}
                  if(this.gruposRoles[i].bloque4 == ''){contadorClasesVacias += 1}
                }else{
                  if(this.gruposRoles[i].bloque1 == ''){contadorClasesVacias += 3}
                }
              break;

              case 3: 
                if(this.gruposRoles[i].bloque1 == ''){contadorClasesVacias += 3}
              break;

              case 9: 
                if(this.gruposRoles[i].bloque1 == ''){contadorClasesVacias += 3}
                if(this.gruposRoles[i].bloque4 == ''){contadorClasesVacias += 1}
              break;

            }
          }
        }

        this.iteraciones += 1

        if(contadorClasesVacias > 0 ){
          this.reglaNiveles  = true
          this.reglaSucursal = true

          /******************************************** AGREGAMOS LOS DEL CURSO DE LOS SABADOS ************************************************/
          /********************************************* id_curso = 6 y unidad de negocio = 2  ************************************************/
          /********************************************* id_curso = 7 y unidad de negocio = 1  ************************************************/
          
          // Llenar los teachers que solo pueden medios turnos en la mañana
          for(const i in this.gruposSabatinos){
            // Vamos primero a llenar a todos los teahcer que se encuentran en sabatino y que solo pueden los fines de semana
            // ya que esos no se ocupan iterar

            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              var inicioClase     = moment(this.gruposSabatinos[i].hora_inicio,'HH:mm')
              var finClase        = moment(this.gruposSabatinos[i].hora_fin,'HH:mm')

              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposSabatinos[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposSabatinos[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                  niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposSabatinos[i].id_nivel );
                }else{niveles = true;}

              // Validamos primero que el teacher solo tenga disponibilidad 
              const sabado = this.configTeachers[j].disponibilidad.find( dispo =>  inicioClase >= moment(dispo.inicio,'HH:mm') && finClase <=  moment(dispo.fin,'HH:mm') && dispo.dia == 6);
              if(zona && sucursal && niveles && sabado){
                // Validamos que solo tenga como disponibilidad dos o un día
                // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposSabatinos[i].hora_inicio,
                                                            this.gruposSabatinos[i].hora_fin,this.gruposSabatinos[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposSabatinos[i].id_plantel,this.gruposSabatinos[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){
                  if(this.gruposSabatinos[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposSabatinos[i].bloque4 != this.configTeachers[j].nombre){
                      this.gruposSabatinos[i].bloque1 =  this.configTeachers[j].nombre
                      this.gruposSabatinos[i].bloque2 =  this.configTeachers[j].nombre
                      this.gruposSabatinos[i].bloque3 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          // Llenar los teachers que solo pueden medios turnos en la mañana
          for(const i in this.gruposSabatinos){
            // Vamos primero a llenar a todos los teahcer que se encuentran en sabatino y que solo pueden los fines de semana
            // ya que esos no se ocupan iterar

            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              var inicioClase     = moment(this.gruposSabatinos[i].hora_inicio,'HH:mm')
              var finClase        = moment(this.gruposSabatinos[i].hora_fin,'HH:mm')

              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposSabatinos[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposSabatinos[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                  niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposSabatinos[i].id_nivel );
                }else{niveles = true;}

              // Validamos primero que el teacher solo tenga disponibilidad 
              const sabado = this.configTeachers[j].disponibilidad.find( dispo =>  inicioClase >= moment(dispo.inicio,'HH:mm') && finClase <=  moment(dispo.fin,'HH:mm') && dispo.dia == 6);
              if(zona && sucursal && niveles && sabado){
                // Validamos que solo tenga como disponibilidad dos o un día
                // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposSabatinos[i].hora_inicio,
                                                            this.gruposSabatinos[i].hora_fin,this.gruposSabatinos[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposSabatinos[i].id_plantel,this.gruposSabatinos[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'a'){
                  if(this.gruposSabatinos[i].bloque4 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposSabatinos[i].bloque1 != this.configTeachers[j].nombre){
                      this.gruposSabatinos[i].bloque4 =  this.configTeachers[j].nombre
                      this.gruposSabatinos[i].bloque5 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          /******************************************* AGREGAMOS LOS DEL CURSO DE LOS DOMINGOS ************************************************/
          /********************************************* id_curso = 7 y unidad de negocio = 2  ************************************************/
          
          // Se llena la parte A
          for(const i in this.gruposDominicales){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              var inicioClase     = moment(this.gruposDominicales[i].hora_inicio,'HH:mm')
              var finClase        = moment(this.gruposDominicales[i].hora_fin,'HH:mm')

              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposDominicales[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposDominicales[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposDominicales[i].id_nivel );
              }else{niveles = true;}

              const dominical = this.configTeachers[j].disponibilidad.find( dispo =>  inicioClase >= moment(dispo.inicio,'HH:mm') &&  finClase <=   moment(dispo.fin,'HH:mm') &&  dispo.dia == 7);

              if(zona && sucursal && niveles && dominical){
                // Validamos que solo tenga como disponibilidad dos o un día
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposDominicales[i].hora_inicio,
                                                            this.gruposDominicales[i].hora_fin,this.gruposDominicales[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposDominicales[i].id_plantel,this.gruposDominicales[i].id_unidad_negocio)

                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){
                  if(this.gruposDominicales[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposDominicales[i].bloque4 != this.configTeachers[j].nombre){
                      this.gruposDominicales[i].bloque1 =  this.configTeachers[j].nombre
                      this.gruposDominicales[i].bloque2 =  this.configTeachers[j].nombre
                      this.gruposDominicales[i].bloque3 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          for(const i in this.gruposDominicales){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              var inicioClase     = moment(this.gruposDominicales[i].hora_inicio,'HH:mm')
              var finClase        = moment(this.gruposDominicales[i].hora_fin,'HH:mm')

              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposDominicales[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposDominicales[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposDominicales[i].id_nivel );
              }else{niveles = true;}

              const dominical = this.configTeachers[j].disponibilidad.find( dispo =>  inicioClase >= moment(dispo.inicio,'HH:mm') &&  finClase <=   moment(dispo.fin,'HH:mm') &&  dispo.dia == 7);

              if(zona && sucursal && niveles && dominical){
                // Validamos que solo tenga como disponibilidad dos o un día
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposDominicales[i].hora_inicio,
                                                            this.gruposDominicales[i].hora_fin,this.gruposDominicales[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposDominicales[i].id_plantel,this.gruposDominicales[i].id_unidad_negocio)

                // No da clase
                if(yadaclase == '' || yadaclase == 'a'){
                  if(this.gruposDominicales[i].bloque4 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposDominicales[i].bloque1 != this.configTeachers[j].nombre){
                      this.gruposDominicales[i].bloque4 =  this.configTeachers[j].nombre
                      this.gruposDominicales[i].bloque5 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          /****************************************** AGREGAMOS LOS DEL CURSO DE MARTES Y JUEVES **********************************************/
          /********************************************** id_curso = 8 y unidad de negocio = 2 ************************************************/

          // Llenar los teachers que solo pueden los martes
          for(const i in this.gruposMarteyJueves){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              var inicioClase     = moment(this.gruposMarteyJueves[i].hora_inicio,'HH:mm')
              var finClase        = moment(this.gruposMarteyJueves[i].hora_fin,'HH:mm')

              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposMarteyJueves[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposMarteyJueves[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposMarteyJueves[i].id_nivel );
              }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const jueves = this.configTeachers[j].disponibilidad.find( dispo => inicioClase >= moment(dispo.inicio,'HH:mm') && finClase <=   moment(dispo.fin,'HH:mm') &&  dispo.dia == 2);

              if(zona && sucursal && niveles && jueves){
                // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposMarteyJueves[i].hora_inicio,
                                                            this.gruposMarteyJueves[i].hora_fin,this.gruposMarteyJueves[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposMarteyJueves[i].id_plantel,this.gruposMarteyJueves[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){

                  if(this.gruposMarteyJueves[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposMarteyJueves[i].bloque4 != this.configTeachers[j].nombre){
                      this.gruposMarteyJueves[i].bloque2 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          for(const i in this.gruposMarteyJueves){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              var inicioClase     = moment(this.gruposMarteyJueves[i].hora_inicio,'HH:mm')
              var finClase        = moment(this.gruposMarteyJueves[i].hora_fin,'HH:mm')

              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposMarteyJueves[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposMarteyJueves[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposMarteyJueves[i].id_nivel );
              }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const martesYjueves = this.configTeachers[j].disponibilidad.find( dispo => inicioClase >= moment(dispo.inicio,'HH:mm') && finClase <=   moment(dispo.fin,'HH:mm') &&  dispo.dia == 4);

              if(zona && sucursal && niveles && martesYjueves){
                // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposMarteyJueves[i].hora_inicio,
                                                            this.gruposMarteyJueves[i].hora_fin,this.gruposMarteyJueves[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposMarteyJueves[i].id_plantel,this.gruposMarteyJueves[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'a'){

                  if(this.gruposMarteyJueves[i].bloque4 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposMarteyJueves[i].bloque2 != this.configTeachers[j].nombre){
                      this.gruposMarteyJueves[i].bloque4 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          /***************************************** AGREGAMOS LOS DEL LUNES, MIERCOLES Y VIERNES **********************************************/
          /******************************************** id_curso = 4 y unidad de negocio = 2,1 ************************************************/
          // Llenar los teacher que solo pueden el lunes y miercoles
          for(const i in this.gruposLMV){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLMV[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLMV[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLMV[i].id_nivel );
              }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const lunes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLMV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLMV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 1);
              const miercoles = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                            moment(this.gruposLMV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                            moment(this.gruposLMV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                            dispo.dia == 3);

              if(zona && sucursal && niveles && lunes && miercoles){
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLMV[i].hora_inicio,
                                                            this.gruposLMV[i].hora_fin,this.gruposLMV[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposLMV[i].id_plantel,this.gruposLMV[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){
                  if(this.gruposLMV[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposLMV[i].bloque5 != this.configTeachers[j].nombre){
                      this.gruposLMV[i].bloque1 =  this.configTeachers[j].nombre
                      this.gruposLMV[i].bloque3 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          for(const i in this.gruposLMV){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLMV[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLMV[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLMV[i].id_nivel );
              }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const viernes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLMV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLMV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 5);
              if(zona && sucursal && niveles && viernes){
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLMV[i].hora_inicio,
                                                            this.gruposLMV[i].hora_fin,this.gruposLMV[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposLMV[i].id_plantel,this.gruposLMV[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'a'){
                  if(this.gruposLMV[i].bloque5 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposLMV[i].bloque1 != this.configTeachers[j].nombre){
                      this.gruposLMV[i].bloque5 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          /************************************************ AGREGAMOS LOS DEL LUNES a JUEVES ***************************************************/
          /********************************************* id_curso = 9 y unidad de negocio = 1 **************************************************/

          // Llenar los teacher que solo pueden el lunes martes y miercoles
          for(const i in this.gruposLJ){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLJ[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLJ[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                  niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLJ[i].id_nivel );
                }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const lunes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLJ[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLJ[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 1);
              const martes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                            moment(this.gruposLJ[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                            moment(this.gruposLJ[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                            dispo.dia == 2);
              const miercoles = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                            moment(this.gruposLJ[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                            moment(this.gruposLJ[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                            dispo.dia == 3);
              if(zona && sucursal && niveles && lunes && martes && miercoles){
                // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLJ[i].hora_inicio,
                                                            this.gruposLJ[i].hora_fin,this.gruposLJ[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposLJ[i].id_plantel,this.gruposLJ[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){
                  if(this.gruposLJ[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposLJ[i].bloque5 != this.configTeachers[j].nombre){
                      this.gruposLJ[i].bloque1 =  this.configTeachers[j].nombre
                      this.gruposLJ[i].bloque2 =  this.configTeachers[j].nombre
                      this.gruposLJ[i].bloque3 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          for(const i in this.gruposLJ){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLJ[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLJ[i].id_plantel );
              }else{sucursal = true; zona = true}

              if(!this.reglaNiveles){
                  niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLJ[i].id_nivel );
                }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const jueves = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLJ[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLJ[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 4);
              if(zona && sucursal && niveles && jueves){
                // Si cumple con la regla, significa que solo puede los sabados y hay que darle prioridad para que no se quede sin clases                    
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLJ[i].hora_inicio,
                                                            this.gruposLJ[i].hora_fin,this.gruposLJ[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposLJ[i].id_plantel,this.gruposLJ[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'a'){
                  if(this.gruposLJ[i].bloque4 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposLJ[i].bloque1 != this.configTeachers[j].nombre){
                      this.gruposLJ[i].bloque4 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          /****************************************** AGREGAMOS LOS DEL CURSO DE LUNES A VIERNES **********************************************/
          /******************************************* id_curso = 2 y unidad de negocio = 1,2 *************************************************/
          // Llenamos la parte A de Lunes a viernes
          for(const i in this.gruposLV){
            // Primero llenaremos la parte A
            for(const j in this.configTeachers){
              // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
              // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
              if(!this.reglaSucursal){
                zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLV[i].idareas );
                sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLV[i].id_plantel );
              }else{sucursal = true; zona = true;}

              if(!this.reglaNiveles){
                niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLV[i].id_nivel );
              }else{niveles = true;}

              // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
              const lunes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                          moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                          moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                          dispo.dia == 1);
              const martes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                            moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                            moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                            dispo.dia == 2);
              const miercoles = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                            moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                            moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                            dispo.dia == 3);
              if(zona && sucursal && niveles && lunes && martes && miercoles){
                // Verificar si ya da clase o no
                var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLV[i].hora_inicio,
                                                            this.gruposLV[i].hora_fin,this.gruposLV[i].id_curso,
                                                            this.configTeachers[j].online,this.gruposLV[i].id_plantel,this.gruposLV[i].id_unidad_negocio)
                // No da clase
                if(yadaclase == '' || yadaclase == 'b'){
                  if(this.gruposLV[i].bloque1 == ''){
                    // Validar que el teacher de la parte b no sea igual al de la parte a
                    if(this.gruposLV[i].bloque5 != this.configTeachers[j].nombre){
                      this.gruposLV[i].bloque1 =  this.configTeachers[j].nombre
                      this.gruposLV[i].bloque2 =  this.configTeachers[j].nombre
                      this.gruposLV[i].bloque3 =  this.configTeachers[j].nombre
                    }
                  }
                }
              }
            }
          }

          // Parte b ******************************************************************************
          // Llenamos la parte A de Lunes a viernes
          for(const i in this.gruposLV){
            if(this.gruposLV[i].bloque5 == ''){
              // Primero llenaremos la parte A
              for(const j in this.configTeachers){
                // Verificamos que el teacher sea capaz de dar clase en esa zona, en esa sucursal y que pueda dar ese nivel de la clase
                // Declaración de las variables de tiempo para saber a que hora inicia y termina la clase
                if(!this.reglaSucursal){
                  zona     = this.configTeachers[j].zonas.find(       zona =>       zona.idzona          == this.gruposLV[i].idareas );
                  sucursal = this.configTeachers[j].sucursales.find(  sucursales => sucursales.idplantel == this.gruposLV[i].id_plantel );
                }else{sucursal = true; zona = true}

                if(!this.reglaNiveles){
                  niveles     = this.configTeachers[j].niveles.find(     niveles =>    niveles.nivel        == this.gruposLV[i].id_nivel );
                }else{niveles = true;}

                // Verificamos que la disponilidad del teacer sea el sábado y en un horario de 0 a 2:30
                const jueves = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                            moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                            moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                            dispo.dia == 4);
                const viernes = this.configTeachers[j].disponibilidad.find( dispo => 
                                                                              moment(this.gruposLV[i].hora_inicio,'HH:mm') >= moment(dispo.inicio,'HH:mm') && 
                                                                              moment(this.gruposLV[i].hora_fin,'HH:mm') <=   moment(dispo.fin,'HH:mm') && 
                                                                              dispo.dia == 5);
                if(zona && sucursal && niveles && jueves && viernes){
                  // Verificar si ya da clase o no
                  var yadaclase = this.buscarDispoGrupoTeacher( this.configTeachers[j].nombre,this.gruposLV[i].hora_inicio,
                                                              this.gruposLV[i].hora_fin,this.gruposLV[i].id_curso,
                                                              this.configTeachers[j].online,this.gruposLV[i].id_plantel,this.gruposLV[i].id_unidad_negocio)
                  // No da clase
                  if(yadaclase == '' || yadaclase == 'a'){
                    if(this.gruposLV[i].bloque5 == ''){
                      // Validar que el teacher de la parte b no sea igual al de la parte a
                      if(this.gruposLV[i].bloque1 != this.configTeachers[j].nombre){
                        this.gruposLV[i].bloque4 =  this.configTeachers[j].nombre
                        this.gruposLV[i].bloque5 =  this.configTeachers[j].nombre
                      }
                    }
                  }
                }
              }
            }
          }
          this.contarClasesVacias()
          return
        }else{
          return
        }
      },

      contarClasesVacias (){
        var contadorClasesVacias = 0
        for(const i in this.gruposRoles){
          if(this.gruposRoles[i].optimizado == 0){
            switch(this.gruposRoles[i].id_curso){
              case 2 || 6 || 7: 
                if(this.gruposRoles[i].bloque1 == ''){contadorClasesVacias += 3}
                if(this.gruposRoles[i].bloque4 == ''){contadorClasesVacias += 2}
              break;

              case 4: 
                if(this.gruposRoles[i].bloque1 == ''){contadorClasesVacias += 2}
                if(this.gruposRoles[i].bloque5 == ''){contadorClasesVacias += 1}
              break;

              case 8: 
                if(this.gruposRoles[i].id_unidad_negocio == 2){
                  if(this.gruposRoles[i].bloque2 == ''){contadorClasesVacias += 1}
                  if(this.gruposRoles[i].bloque4 == ''){contadorClasesVacias += 1}
                }else{
                  if(this.gruposRoles[i].bloque1 == ''){contadorClasesVacias += 3}
                }
              break;

              case 3: 
                if(this.gruposRoles[i].bloque1 == ''){contadorClasesVacias += 3}
              break;

              case 9: 
                if(this.gruposRoles[i].bloque1 == ''){contadorClasesVacias += 3}
                if(this.gruposRoles[i].bloque4 == ''){contadorClasesVacias += 1}
              break;

            }
          }
        }
        console.log('claeses vacias', contadorClasesVacias)
      },

      buscarDispoGrupoTeacher (teacher,inicio,fin,curso,online,plantel,negocio) {
        // La hora fin y la hora son del grupo
        switch(curso){
          case 2:
            // Curso de Lunes a Viernes
            var existe = ''
            var inicioGrupoActual = moment(inicio,'HH:mm')
            var finGrupoActual    = moment(fin,'HH:mm')
            // Necesito verificar los cursos de Lunes a viernes               
            //                                  Lunes a jueves
            //                                  Lunes, Miércoles y Viernes
            //                                  Martes y Jueves

            // Estas, serán las banderas que nos indicaran en que bloque esta el usaurio
            var bloqueA = ''
            var bloqueB = ''
            // Lunes a Viernes
            var existeTeacherBloqueAcLV   = this.gruposLV.find(grupo => grupo.bloque1 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque1 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque1 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Lunes a Jueves
            var existeTeacherBloqueAcLJ   = this.gruposLJ.find(grupo => grupo.bloque1 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque1 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque1 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Martes y Jueves
            var existeTeacherBloqueAcMJ   = this.gruposMarteyJueves.find(grupo => grupo.bloque2 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque2 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque2 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Lunes a Viernes
            var existeTeacherBloqueAcLMV  = this.gruposLMV.find(grupo => grupo.bloque1 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque1 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque1 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)


            if(existeTeacherBloqueAcLV || existeTeacherBloqueAcLJ || existeTeacherBloqueAcMJ || existeTeacherBloqueAcLMV){
              bloqueA = 'a'
            }

             // Lunes a Viernes
            var existeTeacherBloqueBcLV   = this.gruposLV.find(grupo => grupo.bloque5 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque5 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque5 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Lunes a Jueves
            var existeTeacherBloqueBcLJ   = this.gruposLJ.find(grupo => grupo.bloque4 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque4 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque4 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Martes y Jueves
            var existeTeacherBloqueBcMJ   = this.gruposMarteyJueves.find(grupo => grupo.bloque4 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque4 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque4 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Lunes a Viernes
            var existeTeacherBloqueBcLMV  = this.gruposLMV.find(grupo => grupo.bloque5 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque5 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque5 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            
            if(existeTeacherBloqueBcLV || existeTeacherBloqueBcLJ || existeTeacherBloqueBcMJ || existeTeacherBloqueBcLMV){
              bloqueB = 'b'
            }


            if(bloqueA  && bloqueB){   return 'c'}
            if(bloqueA  && !bloqueB){  return 'a'}
            if(!bloqueA && bloqueB){   return 'b'}
            if(!bloqueA && !bloqueB){  return ''}

            return existe
          break;

          // Case semi intenisbo id_curso = 3 y unidad_negocio = 1
          case 3:
            var existe = ''
            var bloqueASab = this.gruposSabatinos.find(     bloque => bloque.bloque1 == teacher || bloque.bloque4 == teacher);
            var bloqueASem = this.gruposSemiSabatinos.find( bloque => bloque.bloque1 == teacher || bloque.bloque4 == teacher);
            if(bloqueASem && bloqueASab){
              existe = 'c'
            }
            
            return existe
          break; 

          case 4:
            // Curso de Lunes a Viernes
            var existe = ''
            var inicioGrupoActual = moment(inicio,'HH:mm')
            var finGrupoActual    = moment(fin,'HH:mm')
            // Necesito verificar los cursos de Lunes a viernes               
            //                                  Lunes a jueves
            //                                  Lunes, Miércoles y Viernes
            //                                  Martes y Jueves

            // Estas, serán las banderas que nos indicaran en que bloque esta el usaurio
            var bloqueA = ''
            var bloqueB = ''
            // Lunes a Viernes
            var existeTeacherBloqueAcLV   = this.gruposLV.find(grupo => grupo.bloque1 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque1 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque1 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Lunes a Jueves
            var existeTeacherBloqueAcLJ   = this.gruposLJ.find(grupo => grupo.bloque1 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque1 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque1 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Martes y Jueves
            var existeTeacherBloqueAcMJ   = this.gruposMarteyJueves.find(grupo => grupo.bloque2 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque2 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque2 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Lunes a Viernes
            var existeTeacherBloqueAcLMV  = this.gruposLMV.find(grupo => grupo.bloque1 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque1 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque1 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)


            if(existeTeacherBloqueAcLV || existeTeacherBloqueAcLJ || existeTeacherBloqueAcMJ || existeTeacherBloqueAcLMV){
              bloqueA = 'a'
            }

             // Lunes a Viernes
            var existeTeacherBloqueBcLV   = this.gruposLV.find(grupo => grupo.bloque5 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque5 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque5 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Lunes a Jueves
            var existeTeacherBloqueBcLJ   = this.gruposLJ.find(grupo => grupo.bloque4 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque4 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque4 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Martes y Jueves
            var existeTeacherBloqueBcMJ   = this.gruposMarteyJueves.find(grupo => grupo.bloque4 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque4 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque4 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Lunes a Viernes
            var existeTeacherBloqueBcLMV  = this.gruposLMV.find(grupo => grupo.bloque5 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque5 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque5 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            
            if(existeTeacherBloqueBcLV || existeTeacherBloqueBcLJ || existeTeacherBloqueBcMJ || existeTeacherBloqueBcLMV){
              bloqueB = 'b'
            }


            if(bloqueA  && bloqueB){   return 'c'}
            if(bloqueA  && !bloqueB){  return 'a'}
            if(!bloqueA && bloqueB){   return 'b'}
            if(!bloqueA && !bloqueB){  return ''}

            return existe
          break;

          // Validar a sabatino
          case 6:
            var existe = ''
            // inbi
            // case 6 y unidad negocio = 1 entonces no busca a nadie
            // case 6 y unidad negocio = 2 entonces es sabatino
            if(negocio == 2){
              var bloqueA = this.gruposSabatinos.find( bloque => bloque.bloque1 == teacher);
              var bloqueB = this.gruposSabatinos.find( bloque => bloque.bloque4 == teacher);
              if(bloqueA && bloqueB){   return 'c'}
              if(bloqueA && !bloqueB){  return 'a'}
              if(!bloqueA && bloqueB){  return 'b'}
              if(!bloqueA && !bloqueB){ return ''}
            }
          break; 

          // sabatino y dominical depende de la unidad de negocio
          case 7:
            // inbi
            // case 7 y unidad negocio = 1 entonces busca a sabatino
            if(negocio == 1){
              var bloqueA = this.gruposSabatinos.find( bloque => bloque.bloque1 == teacher);
              var bloqueB = this.gruposSabatinos.find( bloque => bloque.bloque4 == teacher);
              if(bloqueA && bloqueB){   return 'c'}
              if(bloqueA && !bloqueB){  return 'a'}
              if(!bloqueA && bloqueB){  return 'b'}
              if(!bloqueA && !bloqueB){ return ''}
            }else{
              // case 7 y unidad negocio = 2 entonces busca a dominical
              var bloqueA = this.gruposDominicales.find( bloque => bloque.bloque1 == teacher);
              var bloqueB = this.gruposDominicales.find( bloque => bloque.bloque4 == teacher);
              if(bloqueA && bloqueB){   return 'c'}
              if(bloqueA && !bloqueB){  return 'a'}
              if(!bloqueA && bloqueB){  return 'b'}
              if(!bloqueA && !bloqueB){ return ''}
            }
            // case 6 y unidad negocio = 2 entonces es sabatino
          break; 

          // Case semi intenisvo id_curso = 8 y unidad_negocio = 1
          //  Case martes y jueves id_curso 8 y unidad_negocio = 2
          case 8:
            // Declaramos una variable que nos ayudara a saber si existe o no el teacher en la malla final
            var existe = '' 
            // Martes y jueves
            if(negocio == 2){
              var existe = ''
              var inicioGrupoActual = moment(inicio,'HH:mm')
              var finGrupoActual    = moment(fin,'HH:mm')

              var existeTeacherLunes   = this.gruposMarteyJueves.find(grupo => grupo.bloque2 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                                      || grupo.bloque2 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm'))

              var existeTeacherViernes = this.gruposMarteyJueves.find(grupo => grupo.bloque4 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                                      || grupo.bloque4 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm'))

              if(existeTeacherLunes  && existeTeacherViernes){   return 'c'}
              if(existeTeacherLunes  && !existeTeacherViernes){  return 'a'}
              if(!existeTeacherLunes && existeTeacherViernes){   return 'b'}
              if(!existeTeacherLunes && !existeTeacherViernes){  return ''}

            }else{
              // Recorremos los gruo¿pos
              for(const i in this.gruposRoles){
                var inicioGrupoActual = moment(inicio,'HH:mm')
                var finGrupoActual    = moment(fin,'HH:mm')

                var inicioGrupoBusqueda = moment(this.gruposRoles[i].hora_inicio,'HH:mm')
                var finGrupoBusqueda    = moment(this.gruposRoles[i].hora_fin,'HH:mm')
                // Validamos que si sea de ese grupo
                if(this.gruposRoles[i].id_curso == 3 || this.gruposRoles[i].id_curso == 8 && this.gruposRoles[i].id_unidad_negocio == 1 
                  || this.gruposRoles[i].id_curso == 7 && this.gruposRoles[i].id_unidad_negocio == 1 || this.gruposRoles[i].id_curso == 6 && this.gruposRoles[i].id_unidad_negocio == 2){
                  // Si el teacher esta en el grupo A, hay que validar si esta en el B
                  if( this.gruposRoles[i].bloque1 == teacher){
                    for(const i in this.gruposRoles){
                      var inicioGrupoBusqueda = moment(this.gruposRoles[i].hora_inicio,'HH:mm')
                      var finGrupoBusqueda    = moment(this.gruposRoles[i].hora_fin,'HH:mm')
                      if(this.gruposRoles[i].id_curso == 3 || this.gruposRoles[i].id_curso == 8 && this.gruposRoles[i].id_unidad_negocio == 1 
                        || this.gruposRoles[i].id_curso == 7 && this.gruposRoles[i].id_unidad_negocio == 1 || this.gruposRoles[i].id_curso == 6 && this.gruposRoles[i].id_unidad_negocio == 2){
                        if(inicioGrupoActual >= inicioGrupoBusqueda  &&  inicioGrupoActual < finGrupoBusqueda || finGrupoActual > inicioGrupoBusqueda && finGrupoActual <= finGrupoBusqueda){
                          // Como si esta en el B, retornamos C, ya que ese teacher, no puede dar clase en ningun lado
                          if( this.gruposRoles[i].bloque4 == teacher){
                            // Si si existe en el bloque a, retornar que ya existe en el bloque a y b, por que es semi intensivo
                            existe = 'c'
                            return existe
                          }
                        }
                      }
                    }
                    // Si si existe en el bloque a, retornar que ya existe en el bloque a y b, por que es semi intensivo
                    existe = 'a'
                    return existe
                  }

                  if( this.gruposRoles[i].bloque4 == teacher){
                    for(const i in this.gruposRoles){
                      var inicioGrupoBusqueda = moment(this.gruposRoles[i].hora_inicio,'HH:mm')
                      var finGrupoBusqueda    = moment(this.gruposRoles[i].hora_fin,'HH:mm')
                      if(this.gruposRoles[i].id_curso == 3 || this.gruposRoles[i].id_curso == 8 && this.gruposRoles[i].id_unidad_negocio == 1 
                        || this.gruposRoles[i].id_curso == 7 && this.gruposRoles[i].id_unidad_negocio == 1 || this.gruposRoles[i].id_curso == 6 && this.gruposRoles[i].id_unidad_negocio == 2){
                        if(inicioGrupoActual >= inicioGrupoBusqueda  &&  inicioGrupoActual < finGrupoBusqueda || finGrupoActual > inicioGrupoBusqueda && finGrupoActual <= finGrupoBusqueda){
                          // Como si esta en el B, retornamos C, ya que ese teacher, no puede dar clase en ningun lado
                          if( this.gruposRoles[i].bloque1 == teacher){
                            // Si si existe en el bloque a, retornar que ya existe en el bloque a y b, por que es semi intensivo
                            existe = 'c'
                            return existe
                          }
                        }
                      }
                    }
                    // Si si existe en el bloque a, retornar que ya existe en el bloque a y b, por que es semi intensivo
                    existe = 'b'
                    return existe
                  }
                }
              }
            }

            // En caso de que no existe en ninguno de los dos, pues retornar vacio
            return existe
          break;

          case 9:
            // Curso de Lunes a Viernes
            var existe = ''
            var inicioGrupoActual = moment(inicio,'HH:mm')
            var finGrupoActual    = moment(fin,'HH:mm')
            // Necesito verificar los cursos de Lunes a viernes               
            //                                  Lunes a jueves
            //                                  Lunes, Miércoles y Viernes
            //                                  Martes y Jueves

            // Estas, serán las banderas que nos indicaran en que bloque esta el usaurio
            var bloqueA = ''
            var bloqueB = ''
            // Lunes a Viernes
            var existeTeacherBloqueAcLV   = this.gruposLV.find(grupo => grupo.bloque1 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque1 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque1 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Lunes a Jueves
            var existeTeacherBloqueAcLJ   = this.gruposLJ.find(grupo => grupo.bloque1 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque1 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque1 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Martes y Jueves
            var existeTeacherBloqueAcMJ   = this.gruposMarteyJueves.find(grupo => grupo.bloque2 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque2 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque2 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Lunes a Viernes
            var existeTeacherBloqueAcLMV  = this.gruposLMV.find(grupo => grupo.bloque1 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque1 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque1 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)


            if(existeTeacherBloqueAcLV || existeTeacherBloqueAcLJ || existeTeacherBloqueAcMJ || existeTeacherBloqueAcLMV){
              bloqueA = 'a'
            }

             // Lunes a Viernes
            var existeTeacherBloqueBcLV   = this.gruposLV.find(grupo => grupo.bloque5 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque5 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque5 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Lunes a Jueves
            var existeTeacherBloqueBcLJ   = this.gruposLJ.find(grupo => grupo.bloque4 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque4 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque4 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Martes y Jueves
            var existeTeacherBloqueBcMJ   = this.gruposMarteyJueves.find(grupo => grupo.bloque4 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque4 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque4 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            // Lunes a Viernes
            var existeTeacherBloqueBcLMV  = this.gruposLMV.find(grupo => grupo.bloque5 == teacher && inicioGrupoActual >= moment(grupo.hora_inicio,'HH:mm')  &&  inicioGrupoActual < moment(grupo.hora_fin,'HH:mm') 
                                                          || grupo.bloque5 == teacher && finGrupoActual > moment(grupo.hora_inicio,'HH:mm') && finGrupoActual <= moment(grupo.hora_fin,'HH:mm')
                                                          || grupo.bloque5 == teacher && moment(grupo.hora_inicio,'HH:mm') > inicioGrupoActual  &&  moment(grupo.hora_inicio,'HH:mm') < finGrupoActual)
            
            if(existeTeacherBloqueBcLV || existeTeacherBloqueBcLJ || existeTeacherBloqueBcMJ || existeTeacherBloqueBcLMV){
              bloqueB = 'b'
            }


            if(bloqueA  && bloqueB){   return 'c'}
            if(bloqueA  && !bloqueB){  return 'a'}
            if(!bloqueA && bloqueB){   return 'b'}
            if(!bloqueA && !bloqueB){  return ''}

            return existe
          break;
          
        }
      },

      existeEnMismaSucursal (teacher,plantel,parte,curso,nivel,horaini,prioridad,escuela) {

        // Primero que nada, validamos que el teacher, aun no de las horas que tienen como limite
        var limiteHoras = this.validarHorasTeacher(teacher,prioridad)
        if(!this.reglaSucursal){
          if(limiteHoras){
            switch(curso){
              // curso de lunes a viernes
              case 2:
                var hora_termina = ''
                var mayor = 0
                var anterior = 0
                var horas = 0

                // Probar como sería lo de si la ultima clase del teacher fue a las 12 y tiene 4 horas libres, se puede tomar clase en una sucursal diferente
                for(const i in this.gruposRoles){
                  if( this.gruposRoles[i].bloque1 == teacher && this.gruposRoles[i].id_curso == 4){
                    hora_termina = this.gruposRoles[i].hora_fin
                    anterior = parseInt(hora_termina.substr(0,2));
                    if(anterior > mayor){mayor = anterior}
                  }
                }
                if(hora_termina != ''){
                  var fin               = horaini; // hora clase que podría dar 
                  var inicioHoras       = mayor
                  var finHoras          = parseInt(fin.substr(0,2));
                  var transcurridoHoras = finHoras - inicioHoras;
                      horas             = transcurridoHoras.toString();
                }
                var apto = true
                // Recorremos el roll
                for(const i in this.gruposRoles){
                  // Validamos la parte del curso 
                  if(parte == 'b'){
                    // Como es parte b, significa que lo quiere insertar en miercoles o viernes, entonces hay que buscarrrr si es apto o no dependiendo de la sucursal
                    // Primero verificamos si el ya esta dando clase en un miercoles
                    if( this.gruposRoles[i].bloque5 == teacher){
                      // como si es el mismo teacher, ahora hay que validar que ese plantel no sea online, si es online, hay que dejarlo pasar, ya que no afecta si da en una sucursal y luego en un online
                      if(this.gruposRoles[i].id_plantel != 16){
                        if(horas<4){
                          // if(this.gruposRoles[i].id_nivel == nivel){
                            if(this.gruposRoles[i].id_plantel != plantel){
                              // Como si es diferente, ese teacher no es apto para dar esa clase
                              apto = false
                            }
                          // }
                        }
                      }
                    }
                  }

                  if(parte == 'a'){
                    // Como es parte a, significa que lo quiere insertar en lunes, martes o miercoles, entonces hay que buscarrrr si es apto o no dependiendo de la sucursal
                    // Primero verificamos si el ya esta dando clase en un lunes
                    if( this.gruposRoles[i].bloque1 == teacher){
                      // como si es el mismo teacher, ahora hay que validar que ese plantel no sea online, si es online, hay que dejarlo pasar, ya que no afecta si da en una sucursal y luego en un online
                      if(this.gruposRoles[i].id_plantel != 16){
                        if(horas<4){
                          // if(this.gruposRoles[i].id_nivel == nivel){
                            if(this.gruposRoles[i].id_plantel != plantel){
                              // Como si es diferente, ese teacher no es apto para dar esa clase
                              apto = false
                            }
                          // }
                        }
                      }
                    }
                  }
                }
                return apto
              break;

              case 3:
                // Si pasa por aquí, significa que ya existe, entonces... no hay mucho que hacer, es sabatino
                var apto = false
                return apto
              break;

              case 4:
                var hora_termina = ''
                var mayor = 0
                var anterior = 0
                var horas = 0

                // Probar como sería lo de si la ultima clase del teacher fue a las 12 y tiene 4 horas libres, se puede tomar clase en una sucursal diferente
                for(const i in this.gruposRoles){
                  if( this.gruposRoles[i].bloque1 == teacher && this.gruposRoles[i].id_curso == 4){
                    hora_termina = this.gruposRoles[i].hora_fin
                    anterior = parseInt(hora_termina.substr(0,2));
                    if(anterior > mayor){mayor = anterior}
                  }
                }
                if(hora_termina != ''){
                  var fin               = horaini; // hora clase que podría dar 
                  var inicioHoras       = mayor
                  var finHoras          = parseInt(fin.substr(0,2));
                  var transcurridoHoras = finHoras - inicioHoras;
                      horas             = transcurridoHoras.toString();
                }
                var apto = true
                // Recorremos el roll
                for(const i in this.gruposRoles){
                  // Validamos la parte del curso 
                  if(parte == 'b'){
                    // Como es parte b, significa que lo quiere insertar en miercoles o viernes, entonces hay que buscarrrr si es apto o no dependiendo de la sucursal
                    // Primero verificamos si el ya esta dando clase en un miercoles
                    if( this.gruposRoles[i].bloque5 == teacher){
                      // como si es el mismo teacher, ahora hay que validar que ese plantel no sea online, si es online, hay que dejarlo pasar, ya que no afecta si da en una sucursal y luego en un online
                      if(this.gruposRoles[i].id_plantel != 16){
                        if(horas<4){
                          // if(this.gruposRoles[i].id_nivel == nivel){
                            if(this.gruposRoles[i].id_plantel != plantel){
                              // Como si es diferente, ese teacher no es apto para dar esa clase
                              apto = false
                            }
                          // }
                        }
                      }
                    }
                  }

                  if(parte == 'a'){
                    // Como es parte a, significa que lo quiere insertar en lunes, martes o miercoles, entonces hay que buscarrrr si es apto o no dependiendo de la sucursal
                    // Primero verificamos si el ya esta dando clase en un lunes
                    if( this.gruposRoles[i].bloque1 == teacher){
                      // como si es el mismo teacher, ahora hay que validar que ese plantel no sea online, si es online, hay que dejarlo pasar, ya que no afecta si da en una sucursal y luego en un online
                      if(this.gruposRoles[i].id_plantel != 16){
                        if(horas<4){
                          // if(this.gruposRoles[i].id_nivel == nivel){
                            if(this.gruposRoles[i].id_plantel != plantel){
                              // Como si es diferente, ese teacher no es apto para dar esa clase
                              apto = false
                            }
                          // }
                        }
                      }
                    }
                  }
                }
                return apto
              break;

              case 8:
                var apto = true
                // Recorremos el roll
                for(const i in this.gruposRoles){
                  // Validamos la parte del curso 
                  if(parte == 'b'){
                    // Como es parte b, significa que lo quiere insertar en la parte A
                    // Primero verificamos si el ya esta dando clase en la parte b
                    if( this.gruposRoles[i].bloque4 == teacher){
                      // como si es el mismo teacher, ahora hay que validar que ese plantel no sea online, si es online, hay que dejarlo pasar, ya que no afecta si da en una sucursal y luego en un online
                      if(this.gruposRoles[i].id_plantel != 16){
                        if(horas < 4){
                          // if(this.gruposRoles[i].id_nivel == nivel){
                            if(this.gruposRoles[i].id_plantel != plantel){
                              // Como si es diferente, ese teacher no es apto para dar esa clase
                              apto = false
                            }
                          // }
                        }
                      }
                    }
                  }

                  if(parte == 'a'){
                    // Como es parte a, significa que lo quiere insertar en lunes, martes o miercoles, entonces hay que buscarrrr si es apto o no dependiendo de la sucursal
                    // Primero verificamos si el ya esta dando clase en un lunes
                    if( this.gruposRoles[i].bloque2 == teacher){
                      // como si es el mismo teacher, ahora hay que validar que ese plantel no sea online, si es online, hay que dejarlo pasar, ya que no afecta si da en una sucursal y luego en un online
                      if(this.gruposRoles[i].id_plantel != 16){
                        if(horas < 4){
                          // if(this.gruposRoles[i].id_nivel == nivel){
                            if(this.gruposRoles[i].id_plantel != plantel){
                              // Como si es diferente, ese teacher no es apto para dar esa clase
                              apto = false
                            }
                          // }
                        }
                      }
                    }
                  }
                }
                return apto
              break;

              case 6:
                var apto = true
                // Recorremos el roll
                for(const i in this.gruposRoles){
                  if(this.gruposRoles[i].id_curso == 6){
                    // Validamos la parte del curso 
                    if(parte == 'b'){
                      // se quire insertar en la parte b del curso, entonces hay que validar, que los planteles sean diferentes
                      if( this.gruposRoles[i].bloque1 == teacher ){
                        // Como si esta dando clase
                        if(this.gruposRoles[i].id_plantel != plantel){
                          // Como si es diferente, ese teacher no es apto para dar esa clase
                          apto = false
                        }

                      }
                    }

                    if(parte == 'a'){
                      // Como es parte a, significa que lo quiere insertar en lunes, martes o miercoles, entonces hay que buscarrrr si es apto o no dependiendo de la sucursal
                      // Primero verificamos si el ya esta dando clase en un lunes
                      if( this.gruposRoles[i].bloque4 == teacher){
                        // Como si es diferente, ese teacher no es apto para dar esa clase
                        if(this.gruposRoles[i].id_nivel == nivel){
                          if(this.gruposRoles[i].id_plantel != plantel){
                            // Como si es diferente, ese teacher no es apto para dar esa clase
                            apto = false
                          }
                        }
                      }
                    }
                  }
                }
                return apto
              break;

              case 7:
                var apto = true
                // Aqui es DOMINCIAL
                // Curso 7 y unidad de negocio 2
                if(escuela == 2){
                  // Recorremos el roll
                  for(const i in this.gruposDominicales){
                    // Validamos la parte del curso 
                    if(parte == 'b'){
                      // se quire insertar en la parte b del curso, entonces hay que validar, que los planteles sean diferentes
                      if( this.gruposDominicales[i].bloque1 == teacher ){
                        // Como si esta dando clase
                        if(this.gruposDominicales[i].id_plantel != plantel){
                          // Como si es diferente, ese teacher no es apto para dar esa clase
                          apto = false
                        }

                      }
                    }

                    if(parte == 'a'){
                      // Como es parte a, significa que lo quiere insertar en lunes, martes o miercoles, entonces hay que buscarrrr si es apto o no dependiendo de la sucursal
                      // Primero verificamos si el ya esta dando clase en un lunes
                      if( this.gruposDominicales[i].bloque4 == teacher){
                        // Como si es diferente, ese teacher no es apto para dar esa clase
                        if(this.gruposDominicales[i].id_nivel == nivel){
                          if(this.gruposDominicales[i].id_plantel != plantel){
                            // Como si es diferente, ese teacher no es apto para dar esa clase
                            apto = false
                          }
                        }
                      }
                    }
                  }
                }else{

                  // Recorremos el roll
                  for(const i in this.gruposRoles){
                    if(this.gruposRoles[i].id_curso == 7 && this.gruposRoles[i].id_unidad_negocio == 1 || this.gruposRoles[i].id_curso == 6 && this.gruposRoles[i].id_unidad_negocio == 2){
                      // Validamos la parte del curso 
                      if(parte == 'b'){
                        // se quire insertar en la parte b del curso, entonces hay que validar, que los planteles sean diferentes
                        if( this.gruposRoles[i].bloque1 == teacher ){
                          // Como si esta dando clase
                          if(this.gruposRoles[i].id_plantel != plantel){
                            // Como si es diferente, ese teacher no es apto para dar esa clase
                            apto = false
                          }

                        }
                      }

                      if(parte == 'a'){
                        // Como es parte a, significa que lo quiere insertar en lunes, martes o miercoles, entonces hay que buscarrrr si es apto o no dependiendo de la sucursal
                        // Primero verificamos si el ya esta dando clase en un lunes
                        if( this.gruposRoles[i].bloque4 == teacher){
                          // Como si es diferente, ese teacher no es apto para dar esa clase
                          if(this.gruposRoles[i].id_nivel == nivel){
                            if(this.gruposRoles[i].id_plantel != plantel){
                              // Como si es diferente, ese teacher no es apto para dar esa clase
                              apto = false
                            }
                          }
                        }
                      }
                    }
                  }
                }
                return apto
              break;

              case 9:
                var apto = true
                // Recorremos el roll
                for(const i in this.gruposRoles){
                  // Validamos la parte del curso 
                  if(parte == 'b'){
                    // Como es parte b, significa que lo quiere insertar en la parte A
                    // Primero verificamos si el ya esta dando clase en la parte b
                    if( this.gruposRoles[i].bloque4 == teacher){
                      // como si es el mismo teacher, ahora hay que validar que ese plantel no sea online, si es online, hay que dejarlo pasar, ya que no afecta si da en una sucursal y luego en un online
                      if(this.gruposRoles[i].id_plantel != 16){
                        if(horas < 4){
                          // if(this.gruposRoles[i].id_nivel == nivel){
                            if(this.gruposRoles[i].id_plantel != plantel){
                              // Como si es diferente, ese teacher no es apto para dar esa clase
                              apto = false
                            }
                          // }
                        }
                      }
                    }
                  }

                  if(parte == 'a'){
                    // Como es parte a, significa que lo quiere insertar en lunes, martes o miercoles, entonces hay que buscarrrr si es apto o no dependiendo de la sucursal
                    // Primero verificamos si el ya esta dando clase en un lunes
                    if( this.gruposRoles[i].bloque1 == teacher){
                      // como si es el mismo teacher, ahora hay que validar que ese plantel no sea online, si es online, hay que dejarlo pasar, ya que no afecta si da en una sucursal y luego en un online
                      if(this.gruposRoles[i].id_plantel != 16){
                        if(horas < 4){
                          // if(this.gruposRoles[i].id_nivel == nivel){
                            if(this.gruposRoles[i].id_plantel != plantel){
                              // Como si es diferente, ese teacher no es apto para dar esa clase
                              apto = false
                            }
                          // }
                        }
                      }
                    }
                  }
                }
                return apto
              break;
            }
          }
        }else{
          return true
        }
      },

      exportar(){
        let xls = {
          data:      this.gruposRoles,
          tHeaders : ['Codigo','Nombre', 'Sal', 'Labortorio'],
          filterVal: ['codigo','nomart', 'sal', 'lab'],
          nomfile :  "Productos"
        }
        this.exportExcel()
      },

      validarHorasTeacher (teacher ,elite) {
        // Declarar la horas que tiene el teacher
        var horas = 0
        // Declara si puede o no dar clase
        var bandera = true
        for(const i in this.gruposRoles){
          switch(this.gruposRoles[i].id_curso){
            case 2:
              // Si ya da clase un lunes, significa que da lunes martes y miércoles, por eso se agregan 3 horas
              if(this.gruposRoles[i].bloque1 == teacher){
                horas += 3
              }
              // Si ya da clase un jueves, significa que da jueves y viernes, por eso se agregan 2 horas
              if(this.gruposRoles[i].bloque4 == teacher){
                horas += 2
              }
            break;

            case 4:
              // Si ya da clase un lunes, significa que da lunes, miércoles, por eso se agregan 3 horas
              if(this.gruposRoles[i].bloque1 == teacher){
                horas += 3.3
              }
              // Si ya da clase un jueves, significa que da viernes, por eso se agregan 2 horas
              if(this.gruposRoles[i].bloque5 == teacher){
                horas += 1.7
              }
            break;

            case 8:
              // verificar que dias da clase, si da clase martes se le suman 2.5 horas
              if(this.gruposRoles[i].bloque2 == teacher){
                horas += 2.5
              }
              // verificar que dias da clase, si da clase jueves se le suman 2.5 horas
              if(this.gruposRoles[i].bloque4 == teacher){
                horas += 2.5
              }
            break;

            /*
              Los siguientes cursos es lo mismo realmente que el de lunes a viernes, se separa ya que son diferentes días, pero son las mismas validaciones
            */
            case 3:
              // Si ya da clase un lunes, significa que da lunes martes y miércoles, por eso se agregan 3 horas
              if(this.gruposRoles[i].bloque1 == teacher){
                horas += 3
              }
            break;

            case 6:
              // Si ya da clase un lunes, significa que da lunes martes y miércoles, por eso se agregan 3 horas
              if(this.gruposRoles[i].bloque1 == teacher){
                horas += 3
              }
              // Si ya da clase un jueves, significa que da jueves y viernes, por eso se agregan 2 horas
              if(this.gruposRoles[i].bloque4 == teacher){
                horas += 2
              }
            break;

            case 7:
              // Si ya da clase un lunes, significa que da lunes martes y miércoles, por eso se agregan 3 horas
              if(this.gruposRoles[i].bloque1 == teacher){
                horas += 3
              }
              // Si ya da clase un jueves, significa que da jueves y viernes, por eso se agregan 2 horas
              if(this.gruposRoles[i].bloque4 == teacher){
                horas += 2
              }
            break;

            case 9:
              // verificar que dias da clase, si da clase martes se le suman 2.5 horas
              if(this.gruposRoles[i].bloque2 == teacher){
                horas += 3.25
              }
              // verificar que dias da clase, si da clase jueves se le suman 2.5 horas
              if(this.gruposRoles[i].bloque4 == teacher){
                horas += 1.25
              }
            break;
          }
        }

        // Validmaos cuantas horas esta dando a la semana

        // Validamos la prioridad del teacher
        if(!this.reglaHoras){
          if(elite == 1){
            // Si es elite y tiene más de 20, ya no puede dar clase
            if(horas > 20){
              bandera = false
            }
          }else{
            // Si es nomral, y ya tiene más de 15 horas ya no se le pueden dar clases
            if(horas > 15 && elite == 0){
              bandera = false
            }
          }
        }else{
          if(elite == 1){
            // Si es elite y tiene más de 20, ya no puede dar clase
            if(horas > 25){
              bandera = false
            }
          }else{
            // Si es nomral, y ya tiene más de 15 horas ya no se le pueden dar clases
            if(horas > 20 && elite == 0){
              bandera = false
            }
          }
        }


        return bandera
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let data = XLSX.utils.json_to_sheet(this.grupoRolesPerfecto)
        const workbook = XLSX.utils.book_new()
        const filename = 'rolesPerfecto'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });
      },

      exportExcelTWO(){
        let data = XLSX.utils.json_to_sheet(this.gruposRoles)
        const workbook = XLSX.utils.book_new()
        const filename = 'rolesSinReglas'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });


      },
      
    },
  }

</script>
<style>
  .v-data-table__wrapper{

  }

  .v-data-table__wrapper::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-data-table__wrapper::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  .v-data-table__wrapper::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }


</style>