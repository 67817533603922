import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
  },

	methods: {
		// Obtener todas las sucursales
    getLiberaNomina() {
      this.cargar = true
     
      let payload = {
      	fecha: this.fecha
      }

      return this.$http.post('condicionales.nomina', payload).then((response) => {
				this.estatusHorasExtra    = response.data.estatusHorasExtra
				this.estatusCambioHorario = response.data.estatusCambioHorario
				this.estatusSinSueldo     = response.data.estatusSinSueldo
        // Llenamos la etapa incial
        this.getComisiones( )
        this.cargar = false
      }).catch(error=>{
        this.validarError( error )
      }).finally(()=> { this.cargar = false })
    },


    getComisiones() {
      this.cargar = true;
      const payload = {
        fecha: this.fecha,
      }

      this.$http.post("encargadas_comisiones.get.encargadascomisiones", payload).then((response) => {
        this.encargadascomisiones2 = response.data.resultados;

        let comisionesFilter = this.encargadascomisiones2.filter( el => el.estatus == "Sin estatus" )

        this.estatusComisiones = comisionesFilter.length ? false : true

        this.cargar = false;
      }).catch(error=>{
        this.validarError( error )
      }).finally(()=> { this.cargar = false })
    },

  }
}