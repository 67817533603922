<template>
	<v-container class="" fluid>
	  <v-row>
	  	<v-col cols="12" class="pa-0">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Grupos</span>
	  		    <v-spacer></v-spacer>
	  		    <v-text-field
		  			  label="Buscar"
		  			  filled
		  			  dense
		  			  hide-details
		  			  append-icon="mdi-magnify"
		  			  v-model="search"
		  			></v-text-field>
	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<!-- Seleccionar el plantel -->
	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-autocomplete
	  		  			  label="Plantel"
	  		  			  dense
	  		  			  hide-details
	  		  			  v-model="plantel"
	  		  			  :items="planteles"
	  		  			  item-text="plantel"
	  		  			  item-value="id_plantel"
	  		  			  clearable
	  		  			  outlined
	  		  			></v-autocomplete>
	  		  		</v-col>
	  		  		<!-- Seleccionar el ciclo -->
	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-autocomplete
	  		  			  label="Ciclo"
	  		  			  dense
	  		  			  hide-details
	  		  			  v-model="ciclo"
	  		  			  :items="ciclos"
	  		  			  item-text="ciclo"
	  		  			  item-value="id_ciclo"
	  		  			  clearable
	  		  			  outlined
	  		  			></v-autocomplete>
	  		  		</v-col>
	  		  		<!-- Seleccionar el ciclo -->
	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-autocomplete
	  		  			  label="Horario"
	  		  			  dense
	  		  			  hide-details
	  		  			  v-model="horario"
	  		  			  :items="horarios"
	  		  			  item-text="horario"
	  		  			  item-value="id_horario"
	  		  			  clearable
	  		  			  outlined
	  		  			></v-autocomplete>
	  		  		</v-col>

	  		  		<!-- Seleccionar el ciclo -->
	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-autocomplete
	  		  			  label="Nivel"
	  		  			  dense
	  		  			  hide-details
	  		  			  v-model="nivel"
	  		  			  :items="niveles"
	  		  			  item-text="nivel"
	  		  			  item-value="id_nivel"
	  		  			  clearable
	  		  			  outlined
	  		  			></v-autocomplete>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							  	v-model="grupoSeleccionado"
							    :headers="headers"
							    :items="filterGrupos"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            :items-per-page="5"
			            dense
			            id="grupos"
			            single-select
			            show-select
			            item-key="id_grupo"
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip 
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>
							  

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';
  import AlumnosGrupo    from '@/components/grupos/AlumnosGrupo.vue';
  import MaestrosGrupo   from '@/components/grupos/MaestrosGrupo.vue';
  import DescuentoGrupo  from '@/components/grupos/DescuentosGrupo.vue';
  import InfoGrupo       from '@/components/grupos/InfoGrupo.vue';
  import SiguienteGrupo  from '@/components/grupos/SiguienteGrupo.vue';


  export default {
  	components:{
      Alerta,
      carga,
      AlumnosGrupo,
      MaestrosGrupo,
      DescuentoGrupo,
      InfoGrupo,
      SiguienteGrupo
    },

    data: () => ({
    	grupoSeleccionado:[],
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      // Filtros: 
      incio:null,
			fin:null,
			plantel: null,
			ciclo: null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      grupos: [],
      headers: [
        { text: 'ID'           , value: 'id_grupo'    },
        { text: 'Grupo'        , value: 'grupo'       },
        { text: 'Precio'       , value: 'precio'      },
        { text: 'Cupo'         , value: 'cupo'        },
        { text: 'Inscritos'    , value: 'cupo'        },
        { text: 'Disponibles'  , value: 'cupo'        },
        { text: 'Activo'       , value: 'activo_sn'   },
      ],

      ciclos:[],
			salones:[],
			cursos:[],
			planteles:[],
			horarios:[],
			niveles:[],
			horario:null,
			nivel:null,
			horarios:[],
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Grupo' : 'Editar Grupo'
      },

      filterGrupos( ){
      	if( this.plantel && !this.ciclo ){
      		return this.grupos.filter( el=> { return el.id_plantel == this.plantel })
      	}

      	if( this.plantel && this.ciclo){
      		return this.grupos.filter( el=> { return el.id_plantel == this.plantel && el.id_ciclo == this.ciclo })
      	}

      	if( !this.plantel && this.ciclo){
      		return this.grupos.filter( el=> { return el.id_ciclo == this.ciclo })
      	}

      	if( !this.plantel && !this.ciclo){
      		return this.grupos
      	}
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      tabs( val ) { console.log( val ) }

    },

    async created () {
      await this.initialize()
      await this.getCiclos()
      await this.getHorarios()
      await this.getPlanteles()
      await this.getNiveles()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.grupos = []
        return this.$http.get('grupos.list').then(response=>{
        	this.grupos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCiclos () {
      	this.cargar = true
        this.ciclos = []
        return this.$http.get('ciclos.activos.erp').then(response=>{
        	this.ciclos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getNiveles () {
      	this.cargar = true
        this.niveles = []
        return this.$http.get('niveles.activos').then(response=>{
        	this.niveles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getHorarios () {
      	this.cargar = true
        this.horarios = []
        return this.$http.get('horarios.activos').then(response=>{
        	this.horarios = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.grupos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      validarErrorDirecto ( mensaje ) {
        this.cargar = false 
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
    	},
    },
  }
</script>


<style>
  #grupos td:nth-child(1) {
    background-color: #E8E8E8 !important;
  }

  #grupos td{
    font-size: 11px !important;
    padding-left: 5px;
    padding-right: 5px;
  }

</style>