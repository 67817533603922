<template>
	<v-row justify="center" class="ma-0">
		<v-col xs="12" v-if="getCarrito.length ===  0" >
			<v-alert type="info"> Tu carrito esta vacio. </v-alert>
		</v-col>

		<v-col xs="12" v-if="getCarrito.length">
			<v-card outlined class="pa-3">
				<v-card-title primary-title class="py-0">
					<span><v-icon left>mdi-shopping</v-icon>Artículos</span>
					<v-spacer></v-spacer> 
					<span class="text-right "><h3><strong>{{ totalArt }}</strong></h3></span>
				</v-card-title>
			</v-card>
		</v-col>

		<v-col cols="12" v-if="getCarrito.length">
			<v-card  v-for="(item , i) in getCarrito" :key="i" class="shadowCard mb-3">
				<v-list  three-line>
					<v-list-item   link>
						<v-list-item-avatar tile size="60">
							<img :src="url+item.foto" v-if="item.foto" >
							<img :src="url+photo" v-else >
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title  class="primary--text">{{ item.nomart}} </v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-card-actions class="pt-0">
					<v-btn x-small fab dark color="error " @click="remove(i)"><v-icon >mdi-minus</v-icon></v-btn>
					<v-spacer></v-spacer>
					<v-chip  fab  outlined><strong>{{ item.cantidad }}</strong></v-chip>
					<v-spacer></v-spacer>
					<v-btn x-small fab  color="primary" @click="add(i)"><v-icon >mdi-plus</v-icon></v-btn>
				</v-card-actions>
			</v-card>
		</v-col>

		<!-- Botón para pagar -->
		<v-footer absolute color="white" class="mb-2 px-2" v-if="getCarrito.length">
      <v-spacer></v-spacer>
      <v-btn dark block color="orange" @click="dialogConfirmacion = true"> 
				Solicitar artículos
			</v-btn>
    </v-footer>

    <v-dialog
	    v-model="dialogConfirmacion"
	    persistent
	    max-width="500"
	  >
	    <v-card>
	    	<v-card-text>
	  			<v-card class="elevation-0 text-center pt-4">
	  				<v-card-text>
	  				  <v-img src="@/assets/somos_ricos.jpg"></v-img>
	  				</v-card-text>
	  				<span class="text-body-1 font-weight-black" style="white-space: pre-line">
	  					<br/>
	  				 	atecreasss, confirma que la solicitud este completa por favor
	  				</span>
	  			</v-card>
	    	</v-card-text>
	    	<v-card-actions>
			    <v-btn color="grey" dark @click="dialogConfirmacion = false">NO, seguir comprando</v-btn>
			    <v-spacer></v-spacer>
			    <v-btn color="orange" dark @click="solicitarArticulos()">Sí, solicitar</v-btn>
	    	</v-card-actions>
	    </v-card>
	  </v-dialog>


    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-row>	
</template>

<script>
import axios from 'axios';
	import {mapActions, mapGetters} from 'vuex'
  import metodos from '@/mixins/metodos.js';
  import validarErrores  from '@/mixins/validarErrores'

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import Vue from 'vue'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, metodos ],
    
    data(){
			return{
	      // Alertas
	      parametros:{
	        dialogError: false,
	        mensaje: '',
	        color: ''
	      },

	      respuestaAlerta:false,
	      loader: true,
	      cargar: false,
				// getCarrito: []
				url   : axios.defaults.baseURL + 'imagenes-tienda/' ,
	      photo : 'nophoto.png',
	      dialogConfirmacion: false,
	      habil_compra:false
			}
		},

		computed: {
			...mapGetters('Carrito',['getCarrito']),
			...mapGetters('login',['getdatosUsuario']),

			totalArt () {
				let items = 0
				if(this.getCarrito.length > 0){
					for(const i in this.getCarrito){
	    			items += this.getCarrito[i].cantidad 
	    		}
	    		return items
				}else{
					return 0;
				}
			},
    },

    created(){
    	this.initialize()
    },

    methods: {
      ...mapActions('Carrito',['CerrarCarrito','updateCarrito','limpiarCarrito']),

      initialize ( ){
      	this.cargar = true
        this.habil_compra = false
        this.$http.get('articulos.compra.habil').then(response=>{
        	this.habil_compra = true
        }).catch( error =>{
        	this.habil_compra = false
          // this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    	add(position){
    		if( !this.habil_compra ){
    			this.validarErrorDirecto('El día de compras son los Martes :(')
    			return true
    		}
    		this.getCarrito[position].cantidad += 1;
    		this.updateCarrito(this.getCarrito)
    	},

    	remove(position){
    		if( !this.habil_compra ){
    			this.validarErrorDirecto('El día de compras son los Martes :(')
    			return true
    		}
    		if(this.getCarrito[position].cantidad == 1){
    			this.getCarrito.splice(position,1)
    		}else{
    			this.getCarrito[position].cantidad -= 1
    		}
    		this.updateCarrito(this.getCarrito)
    	},

    	solicitarArticulos ( ){
    		if( !this.habil_compra ){
    			this.validarErrorDirecto('El día de compras son los Martes :(')
    			return true
    		}
    		this.dialogConfirmacion = false
    		const payload = {
    			idplantel: this.getdatosUsuario.id_plantel,
    			iderp    : this.getdatosUsuario.iderp,
    			articulos: this.getCarrito
    		}

    		this.cargar = true
        this.tickets = []
        this.$http.post('articulos.compra', payload).then(response=>{
        	this.limpiarCarrito()
          this.validarSuccess('Solicitud envíada')
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
			}
    },
	};
</script>
