<template>
  <v-container class="mt-4 px-5" fluid>
    <v-card class="pa-3">
      <v-row dense>
      	<v-col cols="12">
        	<embed src="https://web-back-inbi.club/viewer/web/viewer.html?file=https://web-back-inbi.club/manuales/reglamento.pdf" width="100%" :height="tamanioPantalla" >
      	</v-col>
      </v-row>
    </v-card>


  </v-container>
</template>
<script>
import axios from 'axios';

  export default {

    data: () => ({
    	source1: 'https://web-back-inbi.club/manuales/reglamento.pdf',

    }),

    methods:{
    	ver (pdf) {
    		this.viewPdf = pdf
    		this.dialog = true
    		this.verElPdf = 'https://web-back-inbi.club/manuales/reglamento.pdf'
      },
    },

    computed:{
    	tamanioPantalla () {
        return this.$vuetify.breakpoint.height -185
    	},
    }
  }
</script>

<style>
  body {
  margin: 0;
  font-family: sans-serif;
  font-size: 0.9rem;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#toolbar {
  display: flex;
  align-items: center;
  background-color: #555;
  color: #fff;
  padding: 0.5em;
}
#toolbar button,
#page-mode input {
  color: currentColor;
  background-color: transparent;
  font: inherit;
  border: 1px solid currentColor;
  border-radius: 3px;
  padding: 0.25em 0.5em;
}
#toolbar button:hover,
#toolbar button:focus,
#page-mode input:hover,
#page-mode input:focus {
  color: lightGreen;
}
#page-mode {
  display: flex;
  align-items: center;
  padding: 0.25em 0.5em;
}

#viewport-container {
  flex: 1;
  background: #eee;
  overflow: auto;
}


#viewport {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#viewport > div {
  text-align: center;
  max-width: 100%;
}
#viewport canvas {
  width: 100%;
  box-shadow: 0 2px 5px gray;
}

#viewerContainer::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

.#viewerContainer > #viewerContainer::-webkit-scrollbar:vertical {
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar:vertical{
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar-thumb{
  background-color: #BABCBF;
  border-radius: 20px;
  border: 1px solid #ededed;
}
  
</style>