<template>
	<v-container class="mt-6 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-row justify="space-between">
            <!-- Aquí van los usuarios -->
            <v-col cols="4" class="pt-0 pr-0">
              <v-toolbar
                color="pink"
                dark
                dense
              >
                <v-toolbar-title>Usuarios</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  name="name"
                  label="Buscar"
                  v-model="buscarUsuario"
                  hide-details
                  v-if="buscarUsuarioActive"
                  dense
                  :autofocus="buscarUsuarioActive"
                ></v-text-field>
                <!-- Activar el buscador -->
                <v-btn 
                  icon 
                  @click="buscarUsuarioActive = true" 
                  v-if="!buscarUsuarioActive"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <!-- DEscativar el buscador -->
                <v-btn 
                  icon 
                  @click="buscarUsuarioActive = false" 
                  v-else
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-list nav>
                <template v-for="(usuario, id) in filterUsuarios">
                  <v-list-item
                    :key="usuario.id_usuario"
                    @click="abrirPreguntas( usuario )"
                  >
                    <v-list-item-avatar>
                      <v-img :src="avatar( usuario.nombre_completo)"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ usuario.nombre_completo }}</v-list-item-title>
                      <v-list-item-subtitle>iuboinon</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="usuario < (usuarios.length - 1)"></v-divider>
                </template>
              </v-list>
            </v-col>


            <v-divider vertical></v-divider>

            <!-- Preguntas del usuario -->
            <v-col cols="8" class="pa-0">
              <!-- Toolbar de las preguntas -->
              <v-toolbar
                color="pink"
                dark
                dense
              >
                <!-- Avatar del usuario -->
                <v-toolbar-title v-if="usuario">
                  <!-- Avatar del usuario -->
                  <v-avatar size="30" color="white" class="mr-2">
                    <v-img :src="avatarSeleccionado( usuario.nombre_completo)"></v-img>
                  </v-avatar>
                </v-toolbar-title>
                <!-- Nombre del usuario -->
                <div v-if="usuario">{{ usuario.nombre_completo }}</div>

                <v-spacer></v-spacer>
                <!-- Buscador -->
                <v-text-field
                  name="name"
                  label="Buscar"
                  v-model="buscarPregunta"
                  hide-details
                  v-if="buscarActive"
                  dense
                  :autofocus="buscarActive"
                ></v-text-field>
                <!-- Activar el buscador -->
                <v-btn 
                  icon 
                  @click="buscarActive = true" 
                  v-if="!buscarActive"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <!-- Descativar el buscador -->
                <v-btn 
                  icon 
                  @click="buscarActive = false" 
                  v-else
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-scroll-y-transition mode="out-in">
                <div style="width: 98%;">
                  <!-- Ver las preguntas que realizó el usuario -->
                  <v-card-text class="px-0">
                    <!-- Div con tamaño de la pantalla -->
                    <div :style="`height: ${tamanioPantalla}px; overflow: auto;`" id="preguntas">
                      <!-- Recorremos las preguntas filtradas -->
                      <span v-for="(pregunta, i) in filterPreguntas">

                        <!-- Preguntar si la quiere convertir en pregunta frecuente -->
                        <div v-if="pregunta.respuesta && !pregunta.idpreguntas_frecuentes" class="pl-5 mb-2">
                          <v-switch color="green" small label="Convertir en Frecuente" hide-details v-model="pregunta.frecuenteActive" @click="pasarAFrecuente( pregunta )"></v-switch>
                        </div>

                        <div v-if="pregunta.idpreguntas_frecuentes" class="pl-5 mb-2">
                          <v-chip color="green" dark small>Pregunta frecuente #{{ pregunta.idpreguntas_frecuentes }}</v-chip>
                        </div>

                        <!-- Pregunta del usuario -->
                        <v-row>
                          <v-col cols="12">
                            <v-card class="ml-6 pa-3 mt-2" max-width="500" color="#f2f2f2" style="border-radius: 15px; border-top-left-radius: 0">
                              <!-- Pregunta del usuario -->
                              <div class="text-subtitle-1">
                                <v-chip color="success" small left v-if="pregunta.respuesta">
                                  <v-icon left >mdi-check-circle</v-icon>
                                  Completada
                                </v-chip>

                                <v-chip color="error" small left v-else>
                                  <v-icon left >mdi-alert-circle</v-icon>
                                  Pendiente
                                </v-chip>
                                <div>
                                  <v-textarea
                                    v-model="pregunta.pregunta"
                                    hide-details
                                    auto-grow
                                    readonly
                                  ></v-textarea>
                                </div>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>

                        <!-- Respuesta de la pregunta -->
                        <v-row>
                          <v-col cols="12" align="right">
                            <v-card 
                              class="mr-6 mt-2 pa-3" 
                              max-width="500" 
                              dark 
                              color="#0465ac" 
                              shaped 
                              style="border-radius: 15px; border-top-right-radius: 0;"
                              v-if="pregunta.respuesta"
                            >
                              <div>
                                <span class="text-subtitle-1" style="white-space: pre-line">{{ pregunta.respuesta ?  pregunta.respuesta : 'Sin Respuesta '}}</span>
                              </div>
                            </v-card>

                            <v-card 
                              class="mr-6 mt-2 pa-3" 
                              max-width="500" 
                              dark 
                              color="#49ABF3" 
                              shaped 
                              style="border-radius: 15px; border-top-right-radius: 0;"
                              v-else
                            >
                              <v-textarea
                                v-model="respuesta"
                                :append-outer-icon="respuesta ? 'mdi-send' : ''"
                                filled
                                clearable
                                label="Escribe una respuesta concreta y clara"
                                type="text"
                                @click:append-outer="responderPregunta( pregunta.idpreguntas_usuarios )"
                                hide-details
                                auto-grow
                              ></v-textarea>
                            </v-card>

                            <v-chip 
                              color="success"
                              rounded
                              :href="pregunta.respuesta.substr( pregunta.respuesta.indexOf('http'), pregunta.respuesta.length -1 )"
                              target="_blank"
                              v-if="pregunta.respuesta && pregunta.respuesta.indexOf('http') > 0"
                              class="mr-6 mt-4"
                            > 
                              Ir al curso
                              <v-icon right>mdi-play-box</v-icon>
                            </v-chip>

                          </v-col>
                        </v-row>
                      </span>
                    </div>
                  </v-card-text>
                </div>
              </v-scroll-y-transition>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog para pregunta frecuente -->
    <v-dialog
      v-if="pregunta"
      v-model="dialogPreguntaFrecuente"
      max-width="900px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Pasar a pregunta frecuente</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
            <strong>ID: {{ pregunta.idpreguntas_usuarios }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                outlined
                dense
                clearable
                v-model="pregunta.idpuesto"
                :items="puestos"
                label="Selecciona un puesto"
                persistent-hint
                item-text="puesto"
                item-value="idpuesto"
                prepend-inner-icon="mdi-account-box"
                readonly
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-subheader>Pregunta</v-subheader>
          <v-row>
            <v-col cols="12" md="6">
              <v-textarea
                v-model="pregunta.pregunta"
                label="Pregunta"
                outlined
                dense
                auto-grow
              ></v-textarea>
            </v-col>

            <v-col cols="12" md="6">
              <v-textarea
                v-model="preguntaOficial"
                label="Pregunta"
                outlined
                dense
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>

          <v-subheader>Respuesta</v-subheader>
          <v-row>
            <v-col cols="12" md="6">
              <v-textarea
                v-model="pregunta.respuesta"
                label="Respuesta"
                outlined
                dense
                auto-grow
              ></v-textarea>
            </v-col>

            <v-col cols="12" md="6">
              <v-textarea
                v-model="respuestaOficial"
                label="Respuesta"
                outlined
                dense
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogPreguntaFrecuente = false, pregunta = null"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="save"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';


  export default {
  	components:{
      Alerta,
      carga,
    },

    data: () => ({
      buscarPregunta:'',
      buscarActive:false,

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      preguntas:[],
      usuarios:[],
      respuesta:'',
      puestos:[],

      usuario: null,
      buscarUsuario:'',
      buscarUsuarioActive:false,
      pregunta: null,
      frecuenteActive:false,
      dialogPreguntaFrecuente:false,
      preguntaOficial:'',
      respuestaOficial:''

    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),

      filterUsuarios(){
        if( this.buscarUsuario != '' ){
          return this.usuarios.filter((p)=>{
            var nom = p.nombre_completo.toLowerCase();
            return nom.match(this.buscarUsuario.toLowerCase());
          })
        }else{ return this.usuarios }
      },

      filterPreguntas(){
        if( this.buscarPregunta != '' ){
          return this.preguntas.filter((p)=>{
            var nom = p.pregunta.toLowerCase();
            return nom.match(this.buscarPregunta.toLowerCase());
          })
        }else{ return this.preguntas }
      },

      tamanioPantalla () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return (this.$vuetify.breakpoint.height / 2)
          break;
          case 'sm': 
            return this.$vuetify.breakpoint.height - 300
          break;
          case 'md':
            return this.$vuetify.breakpoint.height - 300
          break;
          case 'lg':
            return this.$vuetify.breakpoint.height - 300
          break;
          case 'xl':
            return this.$vuetify.breakpoint.height - 300
          break;
        }
      },
    },

    async created () {
      await this.initialize()
      await this.getPuestos()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.usuarios = []
        this.preguntas = []
        return this.$http.get('preguntasfre.preguntas').then(response=>{
        	this.filtrarUsuarios( response.data )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPuestos () {
        this.$http.get('puestos.all').then(response=>{
          this.puestos = response.data
          // Traer los puestos
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      filtrarUsuarios( usuarios ){
        const idpuesto = this.getdatosUsuario.idpuesto
        console.log( idpuesto )
        switch( idpuesto ){
          // teachers
          case 10:
            
          break;

          // Desarrollador
          case 12:
            this.usuarios = usuarios
          break;

          // Soporte tecnico
          case 13:

          break;
          
          // Supervizor de zona
          case 17:
            this.usuarios = usuarios.filter( el=> { return el.idpuesto == 19 || el.idpuesto == 17 || el.idpuesto == 18 }) 
          break;

          // Ejecutivo Campo
          case 18:

          break;

          // Recepcionista
          case 19:

          break;

          // community manager
          case 25:

            
          break;
          
          // Relaciones publicas, vendedora
          case 28:

          break;

          // Gerente de administración
          case 37:

          break;

          // Gerente Operaciones
          case 40:
            this.usuarios = usuarios.filter( el=> { return el.idpuesto == 10 || el.idpuesto == 40 }) 
          break;
         
          // Director general
          case 43:
            this.usuarios = usuarios
          break;

          // Nivel yare
          case 45:

          break;

          default:
            
          break;
        }
      },

      pasarAFrecuente ( pregunta ) {
        if(!pregunta.frecuenteActive){ return false }
        this.pregunta         = pregunta
        this.preguntaOficial  = pregunta.pregunta
        this.respuestaOficial = pregunta.respuesta
        this.dialogPreguntaFrecuente = true
      },  
    
      abrirPreguntas ( usuario ) {
        this.usuario   = usuario
        this.preguntas = usuario.preguntas
      },

      // Finción para responder a la pregunta
      responderPregunta ( idpreguntas_usuarios ){
        const payload = {
          idpreguntas_usuarios,
          respuesta:            this.respuesta,
          quien_respondio:      this.getdatosUsuario.iderp
        }
        // Iniciar el loader
        this.cargar = true
        // Actualizar la pregunta con la respuesta
        this.$http.put('preguntasfre.responder', payload ).then(response=>{
          this.respuesta = '' // limpiamos
          this.validarSuccess( response.data.message ) // mostramos la respuesta
          this.initialize() // inicializamos todo de new
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      save () {
        const payload = {
          pregunta:   this.preguntaOficial, 
          respuesta:  this.respuestaOficial, 
          idpuesto:   this.pregunta.idpuesto, 
          iderp:      this.getdatosUsuario.iderp, 
          idpreguntas_usuarios: this.pregunta.idpreguntas_usuarios
        }
        // Lo mandapos por el EP
        this.$http.post('preguntasfre.add.frecuente', payload).then(response=>{
        	this.preguntaOficial  = ''
          this.respuestaOficial = ''
          this.pregunta         = null
          this.initialize()
        	this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
        
      },

      avatar ( nombre ) {
        return "https://ui-avatars.com/api/?background=1976d2&color=fff&rounded=true&format=svg&name=" + nombre
      },

      avatarSeleccionado ( nombre ) {
        return "https://ui-avatars.com/api/?background=ffffff&color=e91e63&rounded=true&format=svg&name=" + nombre
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      validarErrorDirecto ( mensaje ) {
        this.cargar = false 
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
    	},
    },
  }
</script>

<style  scoped>
  #preguntas::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > #preguntas::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar-button:increment,#preguntas::-webkit-scrollbar-button{
    display: none;
  }

  #preguntas::-webkit-scrollbar:horizontal{
    display: none;
  }

  #preguntas::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>
