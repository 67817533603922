<template>
  <v-container class="mt-5 ml-2">
    <v-row>
      <v-col cols="10">
        <v-card>
          <v-card-title>
            <span class="headline">Tiempo aproximado en lo que se cierra un ticket</span>
            <div class="ml-2">
              <v-btn color="blue" dark @click="cargar_tickets_por_area()">
                <v-icon>mdi-reload</v-icon> Volver a cargar
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div id="app">
                    <div id="content">
                      <canvas ref="chartUN" style="max-height: 600px;"></canvas>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import Vue from "vue";
import Chart from "@/plugins/chart";

export default {
  name: "app",
  data: () => ({
    data: [],
    tickets_por_area: {
      idticket: 0,
      fecha_asignado: "",
      fecha_cierre: "",
      id_area: "",
    },
    menos_12_horas: 0,
    mas_12_horas: 0,
    mas_1_dia: 0,
  }),

  created() {
    this.cargar_tickets_por_area();
  },

  methods: {
    cargar_tickets_por_area() {
      if (this.myChart != null) {
        this.myChart.destroy();
        this.menos_12_horas = 0;
        this.mas_12_horas = 0;
        this.mas_1_dia = 0;
      }
      this.$http
        .get("reportes.total_tickets_area")
        .then((response) => {
          this.tickets_por_area = response.data;
          var msegMinuto = 1000 * 60; //Calcular minutos
          var msegHora = msegMinuto * 60; //Calcular Horas
          var msegDia = msegHora * 24; //Calcular Dias
          var tiempo = "";
          var dias = "";
          var horas = "";
          this.tickets_por_area.forEach((element) => {
            var fecha_asignacion_aux = new Date(element.fecha_asignado); //Calcula la fecha asignada
            var hoy = new Date(); //Calcula el dia de hoy
            hoy.setMonth(); //Obtiene mes
            hoy.setDate(); //Ontiene dia
            hoy.setHours(); //Obtiene las horas
            tiempo = new Date(element.fecha_cierre) - fecha_asignacion_aux;
            //calcular dias
            dias = Math.floor(tiempo / msegDia);
            tiempo = tiempo - dias * msegDia;
            //calcula horas
            horas = Math.floor(tiempo / msegHora);
            tiempo = tiempo - horas * msegHora;
            if (dias > 0) {
              this.mas_1_dia += 1;
            } else if (dias == 0 && horas >= 12) {
              this.mas_12_horas += 1;
            } else if (horas < 12) {
              this.menos_12_horas += 1;
            }
          });
          var chartUN = this.$refs.chartUN;
          var ctx = chartUN.getContext("2d");

          this.myChart = new Chart(ctx, {
            type: "bar",
            data: {
              labels: ["Tiempo aproximado de respuesta"],
              datasets: [
                {
                  label: ["Tickets cerrados en menos de 12 horas"],
                  data: [this.menos_12_horas],
                  backgroundColor: ["#00CC00"],
                },
                {
                  label: ["Tickets Cerrados en mas de 12 horas"],
                  data: [this.mas_12_horas],
                  backgroundColor: ["#CCCC00"],
                },
                {
                  label: ["Tickets Cerrados en mas de 1 dia"],
                  data: [this.mas_1_dia],
                  backgroundColor: ["#CC0000"],
                },
              ],
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {},
};
</script>