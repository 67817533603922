<template>
	<div>
		<!-- Icono de nuevos -->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-badge bordered color="green" overlap :content="cantNuevos" class="mr-6">
          <v-btn text icon small class="ml-2" v-bind="attrs" v-on="on"><v-icon>mdi-account</v-icon></v-btn>
        </v-badge>
      </template>
      <span>Nuevos prospectos</span>
    </v-tooltip>

    <!-- Icono de basura -->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-badge bordered color="grey" overlap :content="prospectosBasura">
          <v-btn text icon small class="ml-2" v-bind="attrs" v-on="on" @click="paramResagados.estatus = true">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-badge>
      </template>
      <span>Papelera</span>
    </v-tooltip>

    <!--Angel Rodriguez Boton Prospectos Cerrados-->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-badge bordered color="orange" overlap :content="prospectosCerrados.total">
          <v-btn text icon small class="ml-7" v-bind="attrs" v-on="on" @click="paramCerrados.estatus= true">
            <v-icon>mdi-cloud</v-icon>
          </v-btn>
            <!-- @click="dialogCerrados= true" -->
        </v-badge>
      </template>
      <span>Cerrados o finalizados</span>
    </v-tooltip>

    <!-- Prospectos buenos -->
    <v-tooltip top color="green">
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bordered
          color="green"
          overlap
          :content="buenos.length"          
        >
          <v-btn 
            text 
            icon 
            small 
            class="ml-12" 
            v-bind="attrs"
            v-on="on"
            @click="paramBuenos.estatus= true"
          ><v-img src="@/assets/corazon.png" width="20"></v-img></v-btn>
        </v-badge>
      </template>
      <span>Prospectos clasificados como buenos</span>
    </v-tooltip>


    <!-- Prospectos malos -->
    <v-tooltip top color="red">
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bordered
          color="red"
          overlap
          :content="malos.length"          
        >
          <v-btn 
            text 
            icon 
            small 
            class="ml-7" 
            v-bind="attrs"
            v-on="on"
            @click="paramMalos.estatus= true"
          ><v-img src="@/assets/dislike.png" width="20"></v-img></v-btn>
        </v-badge>
      </template>
      <span>Prospectos clasificados como malos</span>
    </v-tooltip>

    <!-- Icono de filtro -->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
          text 
          icon 
          small 
          class="ml-8" 
          v-bind="attrs"
          v-on="on"
          @click="fechas.estatus = true"
        ><v-icon>mdi-filter</v-icon></v-btn>
      </template>
      <span>Agregar filtro</span>
    </v-tooltip>

	</div>
</template>


<script>
import axios from 'axios';

	export default {
		props:[
			'cantNuevos',
			'prospectosBasura',
			'paramResagados',
			'prospectosCerrados',
			'paramCerrados',
			'buenos',
			'paramBuenos',
			'malos',
			'paramMalos',
			'fechas',
	  ],
	}
</script>
