<template> 
	<v-container fluid>
	  <v-row>
	  	<v-col cols="12" class="pa-0">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title class="pb-0">
	  		    <span class="text-subtitle-1">Historial pagos</span>
	  		    <v-spacer></v-spacer>
	  		  </v-card-title>

	  		  <v-card-text class="pt-0">
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="6" class="pt-0">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
						<v-data-table v-if="[28,334].includes(getdatosUsuario.iderp)" :headers="headers2" :items="grupos" class="elevation-0" :search="search" :page.sync="page" @page-count="pageCount = $event" dense>
							<template v-slot:item.imprimir="{ item }">
								<v-chip small color="grey" dark @click="imprimirReciboPago( item )" class="mr-2"><v-icon>mdi-magnify</v-icon></v-chip>
							</template>

							<template v-slot:item.reenviarcorreo="{ item }">
								<v-chip small color="green" dark @click="dialogConfirmaCorreo=true" class="mr-2"><v-icon>mdi-mail</v-icon></v-chip>
							</template>

							<template v-slot:item.eliminar="{ item }">
								<v-chip small color="red" dark @click="eliminarPago(item)" class="mr-2"><v-icon>mdi-delete</v-icon></v-chip>
							</template>

							<template v-slot:no-data>
								<v-btn color="primary" @click="initialize" small>
								Actualizar
								</v-btn>
							</template>
						</v-data-table>
						<v-data-table v-else :headers="headers" :items="grupos" class="elevation-0" :search="search" :page.sync="page" @page-count="pageCount = $event" dense>
							<template v-slot:item.imprimir="{ item }">
								<v-chip small color="grey" dark @click="imprimirReciboPago( item )" class="mr-2"><v-icon>mdi-magnify</v-icon></v-chip>
							</template>

							<template v-slot:item.reenviarcorreo="{ item }">
								<v-chip small color="green" dark @click="dialogConfirmaCorreo=true" class="mr-2"><v-icon>mdi-mail</v-icon></v-chip>
							</template>

							<template v-slot:no-data>
								<v-btn color="primary" @click="initialize" small>
								Actualizar
								</v-btn>
							</template>
						</v-data-table>
						<br> <br>
						<h3>Pagos Eliminados</h3>
						<v-data-table :headers="headers3" :items="pagoseliminados" class="elevation-0" :search="search" :page.sync="page" @page-count="pageCount = $event" dense>
							<template v-slot:no-data>
								<v-btn color="primary" @click="initialize" small>
								Actualizar
								</v-btn>
							</template>
						</v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <v-dialog v-model="dialogPago" persistent :overlay="false" max-width="700px">
	    <v-card class="shadowCard" v-if="datosReciboPago">
  			<v-card-title primary-title>
  			  Folio de pago: {{ folio }}
  			  <v-spacer></v-spacer>

  			  <v-btn
            color="primary"
            dark
            class="text-capitalize"
            @click="imprimirReciboPago( pago )"
            small
            tile
          >
            <v-icon left small>mdi-refresh</v-icon>
            Actualizar
          </v-btn>

  			</v-card-title>

      	<v-list class="transparent" dense>
		      <v-list-item>
		        <v-list-item-title>
		        	Alumno:
		        </v-list-item-title>

		        <v-list-item-subtitle class="text-left">
		          {{ datosReciboPago.alumno }}
		        </v-list-item-subtitle>
		      </v-list-item>

		      <v-list-item>
		        <v-list-item-title>
		        	Matricula:
		        </v-list-item-title>

		        <v-list-item-subtitle class="text-left">
		          {{ datosReciboPago.matricula }}
		        </v-list-item-subtitle>
		      </v-list-item>

		      <v-list-item>
		        <v-list-item-title>
		        	Plantel:
		        </v-list-item-title>

		        <v-list-item-subtitle class="text-left">
		          {{ datosReciboPago.plantel }}
		        </v-list-item-subtitle>
		      </v-list-item>

		      <v-list-item>
		        <v-list-item-title>
		        	Ciclo:
		        </v-list-item-title>

		        <v-list-item-subtitle class="text-left">
		          {{ datosReciboPago.ciclo }}
		        </v-list-item-subtitle>
		      </v-list-item>

		      <v-list-item>
		        <v-list-item-title>
		        	Curso:
		        </v-list-item-title>

		        <v-list-item-subtitle class="text-left">
		          {{ datosReciboPago.curso }}
		        </v-list-item-subtitle>
		      </v-list-item>

		      <v-list-item>
		        <v-list-item-title>
		        	Cantidad Recibida:
		        </v-list-item-title>

		        <v-list-item-subtitle class="text-left">
		          $ {{ datosReciboPago.monto_pagado }}
		        </v-list-item-subtitle>
		      </v-list-item>

		      <v-list-item>
		        <v-list-item-title>
		        	Cantidad en Letra:
		        </v-list-item-title>

		        <v-list-item-subtitle class="text-left">
		          {{ pago_letra }}
		        </v-list-item-subtitle>
		      </v-list-item>

		      <v-list-item>
		        <v-list-item-title>
		        	Monto de Descuento por Pronto Pago:
		        </v-list-item-title>

		        <v-list-item-subtitle class="text-left">
		          {{ datosReciboPago.descuento }}
		        </v-list-item-subtitle>
		      </v-list-item>

		      <v-list-item>
		        <v-list-item-title>
		        	Adeudo del Ciclo:
		        </v-list-item-title>

		        <v-list-item-subtitle class="text-left">
		          {{ datosReciboPago.adeudo }}
		        </v-list-item-subtitle>
		      </v-list-item>

		      <v-list-item>
		        <v-list-item-title>
		        	Recibió:
		        </v-list-item-title>

		        <v-list-item-subtitle class="text-left">
		          {{ datosReciboPago.nombre_completo }}
		        </v-list-item-subtitle>
		      </v-list-item>

		      <v-list-item>
		        <v-list-item-title>
		        	Teléfono:
		        </v-list-item-title>

		        <v-list-item-subtitle class="text-left">
		         	<v-text-field
		         	  label="WhatsApp"
		         	  hide-details
		         	  type="number"
		         	  v-model="nuevoTelefono"
		         	  single-line
		         	  outlined
		         	  dense
		         	></v-text-field>
		        </v-list-item-subtitle>
		      </v-list-item>
		    </v-list>

		    <v-card-actions>
		    	<v-btn 
		      	color="black" 
		      	dark 
		      	@click="dialogPago = false"
		      	small
		      	tile
		      >
		      	<v-icon left small>mdi-cancel</v-icon>
		    		Cancelar
		    	</v-btn>

		    	<v-spacer></v-spacer>
		      <v-btn 
		      	color="grey" 
		      	dark 
		      	@click="generarRecibo()"
		      	small
		      	tile
		      >
		      	<v-icon left small>mdi-printer</v-icon>
		    		Imprimir Recibo
		    	</v-btn>
		      
		      <v-btn 
		      	color="green" 
		      	dark 
		      	@click="enviarPorWhatsApp()"
		      	small
		      	tile
		      >
		      	<v-icon left small>mdi-whatsapp</v-icon>
		    		Enviar a WhatsApp
		    	</v-btn>
		    
		    </v-card-actions>
		  </v-card>
	  </v-dialog>

	  <!-- Dialgoo para reenviar correo -->
	  <!-- DIALOGO PARA CONFIRMAR EL CORREO DEL ALUMNO -->
    <v-dialog v-model="dialogConfirmaCorreo" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h6">
          Enviar Correo de Bienvenida
        </v-card-title>
        <v-card-text>
          <v-text-field
            name="name"
            label="label"
            id="id"
            v-model="editedItem.email"
            filled
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            @click="dialogConfirmaCorreo = false"
            rounded
            dark
            small
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            @click="reenviarCorreo()"
            rounded
            dark
            small
          >
            Actualizar y enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

	<v-dialog v-model="dialogConfirmarEliminar" max-width="500">
		<v-card>
			<v-card-title>
				Confirmar: Eliminar pago
				<v-spacer></v-spacer>
				<v-btn fab small text @click="dialogConfirmarEliminar = false" ><v-icon>mdi-close</v-icon></v-btn>
			</v-card-title>
			<v-card-text>
				Motivo por el cual se esta eliminando el pago:
				<v-textarea name="motivoEliminacion" filled v-model="motivoEliminacion" :rows="3" auto-grow class="mt-2">
				</v-textarea>
				¿Desea confirmar la eliminacion de pago?
			</v-card-text>
			<v-card-actions>
				<v-btn color="red" @click="dialogConfirmarEliminar = false" dark small> No, Cancelar </v-btn>
				<v-spacer></v-spacer>
				<v-btn color="green" @click="confirmarEliminarPago()" dark small> Si, Confirmar </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'

	const convertir = require('numero-a-letras');
	// Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'

	// Componentes
  export default {

    mixins: [ validarErrores ],

  	components:{
      Alerta,
      carga,
    },

  	props:[
			'editedItem'
	  ],
    data: () => ({

    	dialogConfirmaCorreo: false,
		dialogConfirmarEliminar:false,
		pagoAEliminar:{},
		motivoEliminacion:'',

      // tabla
			search:'',
      page: 1,
      grupos: [],
	  pagoseliminados: [],
      headers: [
        { text: 'Curso'        , value: 'curso'        },
        { text: 'Grupo'        , value: 'grupo'        },
        { text: 'Costo grupo'  , value: 'precio_nivel' },
        { text: 'Pagado'       , value: 'pagado'       },
        { text: 'Alta'         , value: 'fecha_alta'   },
        { text: 'Imprimir'     , value: 'imprimir'     },
        { text: 'Reenviar Mail', value: 'reenviarcorreo'     },
      ],
	  headers2: [
        { text: 'Curso'        , value: 'curso'        },
        { text: 'Grupo'        , value: 'grupo'        },
        { text: 'Costo grupo'  , value: 'precio_nivel' },
        { text: 'Pagado'       , value: 'pagado'       },
        { text: 'Alta'         , value: 'fecha_alta'   },
        { text: 'Imprimir'     , value: 'imprimir'     },
        { text: 'Reenviar Mail', value: 'reenviarcorreo'     },
		{ text: 'Eliminar Pago', value: 'eliminar'     },
      ],
	  headers3: [
        { text: 'Curso'        , value: 'curso'        },
        { text: 'Grupo'        , value: 'grupo'        },
        { text: 'Costo grupo'  , value: 'precio_nivel' },
        { text: 'Pagado'       , value: 'pagado'       },
        { text: 'Alta'         , value: 'fecha_alta'   },
		{ text: 'Usuario que elimino', value: 'nombre_completo'},
		{ text: 'Motivo'       , value: 'motivo_eliminacion'},
      ],

      descuento_pronto_pago:'',
      tipo_descuento: 'POR MONTO FIJO',
      valor_descuento:'',
      fecha_inicio_descuento:null,
			fecha_fin_descuento:null,

			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
      pago:null,
      datosVendedora:null,
			datosAlumno:null,
			datosReciboPago:null,
			folio: 0,
			pago_letra:'',
			url:'',
			nuevoTelefono: '',
			dialogPago: false
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
    },

    async created () {
    	console.log ( this.editedItem ) 
      await this.initialize()
    },

    methods: {
      initialize () {
        this.grupos = []
		this.pagoseliminados= []
		this.$http.get('inscripciones.ingresos.eliminados/' + this.editedItem.id_alumno).then(response=>{
        	this.pagoseliminados = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
        return this.$http.get('inscripciones.alumno.grupos/' + this.editedItem.id_alumno).then(response=>{
        	this.grupos = response.data
        }).then({
			
		}).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      imprimirReciboPago( item ){
      	this.pago   = item
      	this.cargar = true
      	this.folio  = item.id_ingreso 

      	const payload = {
      		id_usuario: this.getdatosUsuario.iderp,
      		id_pago   : item.id_ingreso
      	}

        // Aquí se envían los datos del usuario para saber que perfil tiene
        this.$http.post('whatsapp.enviarrecibo', payload ).then((response) => {

        	this.datosVendedora  = response.data.datosVendedora
					this.datosAlumno     = response.data.datosAlumno
					this.datosReciboPago = response.data.datosReciboPago
					this.nuevoTelefono   = this.datosAlumno.telefono

					this.pago_letra      = convertir.NumerosALetras( this.datosReciboPago.monto_pagado ).toUpperCase()

					this.url = 'https://schoolofenglishadmin.com/reporteshelper/repoteDePagoParcial/' + this.folio + '/' + this.pago_letra

					this.dialogPago = true

					this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      generarRecibo(){
      	this.cargar = true
      	
      	let payload = {
      		folio:             this.folio,
					alumno:            this.datosReciboPago.alumno,
					matricula:         this.datosReciboPago.matricula,
					plantel:           this.datosReciboPago.plantel,
					ciclo:             this.datosReciboPago.ciclo,
					curso:             this.datosReciboPago.curso ,
					cantidad_recibida: this.datosReciboPago.monto_pagado,
					cantidad_letra:    this.pago_letra,
					monto_descuento:   this.datosReciboPago.descuento,
					adeudo:            this.datosReciboPago.adeudo,
					vendedora:         this.datosReciboPago.nombre_completo,
					fecha_pago:        this.datosReciboPago.fecha_pago
      	}

      	this.$http.post('inscripciones.generar.recibo', payload ).then((response) => {

					this.url = axios.defaults.baseURL + 'recibo-pago/' + this.folio + '.pdf'

					window.open( this.url )

					this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      reenviarCorreo( ){
      	this.cargar = true
      	
      	let payload = {
      		email:     this.editedItem.email,
					id_alumno: this.editedItem.id_alumno,
					alumno:    this.editedItem.alumno,
					matricula: this.editedItem.matricula,
      	}

      	this.$http.post('inscripciones.correo.bienvenida', payload ).then((response) => {
					this.validarSuccess('Correo enviado correctamente')
					this.dialogConfirmaCorreo = false
					this.cargar = false
        }).catch( error =>{
					this.validarSuccess('Correo enviado correctamente')
        }).finally( () => { this.cargar = false })
      },

	  eliminarPago( item ){
		this.dialogConfirmarEliminar=true
		this.pagoAEliminar = item
		console.log(this.pagoAEliminar)
	  },

	  confirmarEliminarPago(){
		if(this.motivoEliminacion){
			this.cargar = true
			let payload = {
				idIngreso : this.pagoAEliminar.id_ingreso,
				motivo_eliminacion : this.motivoEliminacion,
				id_usuario_elimino : this.getdatosUsuario.iderp
			}
			this.dialogConfirmarEliminar = false
			this.$http.post('inscripciones.eliminar.pago', payload ).then((response) => {
						this.validarSuccess('Pago eliminado correctamente')
						this.dialogConfirmarEliminar = false
						this.initialize()
						this.motivoEliminacion = ''
			}).catch( error =>{
						this.dialogConfirmarEliminar = true
						this.validarError(error.response.data.message)
			}).finally( () => { this.cargar = false })
		} else {
			this.validarError('Motivo vacio')
		}
	  },

      enviarRecibo( item ){
      	// Convertir el monto en texto
      	const monto = convertir.NumerosALetras(item.pagado).toUpperCase()

      	// console.log( monto.replace(/\s+/g, '%20') )

      	const ruta = `https://pruebas.schoolofenglishadmin.com/reporteshelper/repoteDePagoParcial/${item.id_ingreso}/${monto}`

      	window.open( ruta )
      },
    },
  }
</script>


