<template>
  <v-container class="mt-4 ml-2">
    <v-row>
      <v-col cols="12" md="8" lg="6">
        <v-data-table
          :headers="headers"
          :items="desserts"
          sort-by="calories"
          :search="search"
          class="elevation-6"
          >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>Areas</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    rounded
                    small
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Agregar area
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" >
                          <v-text-field
                            v-model="editedItem.area"
                            label="Nombre del area"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                label="Buscar"
                outlined
                dense
                v-model="search"
              ></v-text-field>
            </v-card-text>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
  export default {
    data: () => ({
      dialog: false,
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: false,
          value: 'idareas_ticket',
        },
        { text: 'Nombre', value: 'area' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        idareas_ticket:0,
        area: '',
      },
      defaultItem: {
        idareas_ticket:0,
        area: '',
      },
      search:'',
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.$http.get('areas.all').then(response=>{
          this.desserts = response.data
        }).catch(error=>{console.log(error)})
      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          console.log(this.editedItem)
          this.$http.put('areas.update/'+this.editedItem.idareas_ticket,this.editedItem).then(response=>{
            this.initialize()
          }).catch(error=>{console.log(error)})
        } else {
          this.$http.post('areas.add',this.editedItem).then(response=>{
            this.initialize()
          }).catch(error=>{console.log(error)})
        }
        this.close()
      },
    },
  }
</script>