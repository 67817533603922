var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-5 pl-6 pr-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadowCard"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('span',{staticClass:"text-body-1"},[_vm._v("Kpi General Por Líder")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","dark":"","color":"green","tile":""},on:{"click":function($event){return _vm.exportar()}}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1),_c('v-btn',{attrs:{"color":"primary","small":"","tile":""},on:{"click":function($event){return _vm.consultar()}}},[_vm._v(" Consultar ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('v-autocomplete',{attrs:{"filled":"","dense":"","clearable":"","items":_vm.ciclos,"label":"Selecciona ciclo actual","persistent-hint":"","hide-details":"","return-object":"","item-text":"ciclo","item-value":"id_ciclo"},model:{value:(_vm.ciclo),callback:function ($$v) {_vm.ciclo=$$v},expression:"ciclo"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('v-autocomplete',{attrs:{"filled":"","dense":"","items":_vm.ciclos,"label":"Selecciona siguiente ciclo","persistent-hint":"","hide-details":"","return-object":"","item-text":"ciclo","item-value":"id_ciclo","readonly":""},model:{value:(_vm.cicloSup),callback:function ($$v) {_vm.cicloSup=$$v},expression:"cicloSup"}})],1)],1),_c('v-data-table',{staticClass:"elevation-0 mt-4",attrs:{"headers":_vm.headers,"items":_vm.resultado.riPlantelesInbi,"sort-by":"calories","hide-default-footer":"","items-per-page":20,"dense":"","mobile-breakpoint":"200","id":"kpi"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.initialize}},[_vm._v(" Actualizar ")])]},proxy:true},{key:"item.reinscribibles",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.reinscribibles))])]}},{key:"item.totalAlumnosSiguientes",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.totalAlumnosSiguientes))])]}},{key:"item.porcentajeAvance",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s((( item.totalAlumnosSiguientes / item.reinscribibles ) * 100 ).toFixed( 2 )))])]}},{key:"item.meta_alumnos",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(( item.reinscribibles * .90 ).toFixed( 0 ))+" Alumnos")])]}}])}),_c('v-data-table',{staticClass:"elevation-0 mt-4",attrs:{"headers":_vm.headers,"items":_vm.resultado.riPlantelesFast,"sort-by":"calories","hide-default-footer":"","items-per-page":20,"dense":"","mobile-breakpoint":"200","id":"kpi2"},scopedSlots:_vm._u([{key:"item.reinscribibles",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.reinscribibles))])]}},{key:"item.totalAlumnosSiguientes",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.totalAlumnosSiguientes))])]}},{key:"item.porcentajeAvance",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s((( item.totalAlumnosSiguientes / item.reinscribibles ) * 100 ).toFixed( 2 )))])]}},{key:"item.meta_alumnos",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(( item.reinscribibles * .90 ).toFixed( 0 ))+" Alumnos")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.initialize}},[_vm._v(" Actualizar ")])]},proxy:true}])})],1)],1)],1)],1),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar)?_c('carga'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }