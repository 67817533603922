<template>
  <v-container class="mt-4 ml-2 pr-6" fluid>
    <v-row>
    	<v-col cols="12">
    		<v-card class="shadowCard">
          <v-card-title primary-title class="text-subtitle-1">
            Cambios solicitados

            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            
            <v-btn color="primary" small rounded @click="getAlumnosxEscuela()" class="mr-2" >Actualizar</v-btn>
            
            <v-btn color="black" dark small rounded @click="actualizarGrupoAlumnos()">Efectuar cambios</v-btn>
          </v-card-title>

          <v-card-text>

            <v-row justify="space-between">
              <v-col cols="12" md="4" lg="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  label="Buscar"
                  v-model="search"
                  dense
                  filled
                  append-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
            </v-row>

  			    <v-data-table
  			      :headers="headers"
  			      :items="alumnos"
  			      :search="search"
  			    >
              <template v-slot:item.estatus_nombre="{ item }">
                <v-chip v-if="item.estatus_nombre =='Solicitado'"  small color="orange" dark>{{ item.estatus_nombre }}</v-chip>
                <v-chip v-if="item.estatus_nombre =='Por aceptar'" small color="blue"   dark>{{ item.estatus_nombre }}</v-chip>
                <v-chip v-if="item.estatus_nombre =='Rechazado'"   small color="black"  dark>{{ item.estatus_nombre }}</v-chip>
                <v-chip v-if="item.estatus_nombre =='Aceptado'"    small color="green"  dark>{{ item.estatus_nombre }}</v-chip>
              </template>

              <template v-slot:item.opciones="{ item }">
                <v-chip small color="orange" dark @click="verCambio(item)">Ver</v-chip>
              </template>

  			    </v-data-table>
          </v-card-text>
    		</v-card>
    	</v-col>
    </v-row>

    <v-overlay :value="overlay">
      <v-card
      	width="200"
        color="primary"
        dark
      >
        <v-card-text align="center" class="text-h6">
          Espera porfavor
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card
        color="green"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Datos grabados
        </v-card-title>
        <div class="pl-6 pb-6">
          <br/>
          NOTA: {{successMensaje}}
        </div>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogSuccess = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogError"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card
        color="error"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Error al recuperar los datos
        </v-card-title>
        <v-card-text>
          <br/>
          NOTA: {{error}}
        </v-card-text>
        <v-btn 
        	color="transparent elevation-0"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogError = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogUpdateCambio"
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h5">
          Solicitud de cambio
        </v-card-title>
        <v-card-text>
          <b>Matricula:</b> {{ alumno.matricula }}
          <br/>
          <b>Nombre:</b>    {{ alumno.alumno }}
          <br/>
          <b>Grupo:</b>     {{ alumno.grupo }}
          <br/>
          <b>Plantel:</b>   {{ alumno.plantel }}
          <br/>
          <b>Fecha de solicitud:</b>   {{ alumno.fecha_solicitud }}
          <br/>
          <b>Fecha de cambio:</b>      {{ alumno.fecha_cambio  }}
          <br/>
          <b>Fecha de aceptación:</b>  {{ alumno.fecha_aceptacion }}

          <v-divider></v-divider>
          <br/>
          <b>Cambio a:</b>
          <br/>
          <b>Grupo:</b>   {{ alumno.nuevo_grupo }}
          <br/>
          <b>Tipo de cambio:</b> {{ alumno.tipocambio }}
          <br/>
          <b>Diferencia:</b> {{ alumno.diferencia }}
          <br/>
          <br/>
          <b>Responsable:</b> {{ alumno.responsable }}
          <p 
            style="white-space: pre-line"
          >
            <b>Motivo de cambio:</b> {{ alumno.motivo }}
          </p>

          <v-checkbox
            v-if="alumno.estatus == 1"
            v-model="alumno.lms"
            label="Ya se le agrego al alumno en el LMS"
            hide-details
          ></v-checkbox>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="red"
            tile
            dark
            small
            class="mr-2"
            @click="dialogUpdateCambio = false"
          >
            Cancelar
          </v-btn>
          <v-btn color="blue" dark small @click="cargarMensajes()">Ver mensajes</v-btn>
          <v-spacer></v-spacer>

          <v-btn
            v-if="alumno.estatus == 1" 
            color="black"
            class="mr-2"
            tile
            small
            @click="rechazar()"
            dark
          >
            Rechazar
          </v-btn>

          <v-btn
            v-if="alumno.estatus == 1 || alumno.estatus == 3" 
            color="primary"
            tile
            small
            @click="validarCambio()"
          >
            Cambio Realizado
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogRechazar"
      hide-overlay
      persistent
      max-width="800"
    >
      <v-card
        style="position: relative"
      >
        <v-card-title primary-title >
          Agregar nota
        </v-card-title>
        <v-card-text class="pb-0">
          <v-textarea
          	v-model="mensajeRechazo"
          	outlined
          	dense
          	label="Escribe la razón del rechazo"
          	hide-details
          >	
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialogRechazar = false, dialogUpdateCambio = true">Cancelar</v-btn>
          <v-btn color="success" text @click="enviarMensaje()">Enviar</v-btn>
        </v-card-actions>
        <v-btn 
        	color="transparent elevation-0"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogRechazar = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogMensajes"
      hide-overlay
      persistent
      max-width="800"
    >
      <v-card
        style="position: relative"
      >
        <v-card-title primary-title >
          Agregar nota
        </v-card-title>
        <v-card-text>
        	<span v-for="(mensaje, i) in mensajes">
		        <v-row v-if="mensaje.envia == 0">
		        	<v-col cols="12">
				        <v-card class="ml-6 pa-3 mt-2" max-width="400" color="#f2f2f2" style="border-radius: 15px; border-top-left-radius: 0">
				          {{ mensaje.respuesta }}
				        </v-card>
		        	</v-col>
		        </v-row>

		        <v-row v-if="mensaje.envia == 1">
		        	<v-col cols="12" align="right">
				        <v-card class="mr-6 mt-2 pa-3" max-width="400" dark color="#0465ac" shaped style="border-radius: 15px; border-top-right-radius: 0;">
				          {{ mensaje.mensaje }}
				        </v-card>
		        	</v-col>
		        </v-row>
        	</span>
        </v-card-text>

        <v-card-text class="pb-0 pt-6">
        	<v-text-field
            v-model="mensajeRechazo"
            :append-outer-icon="mensajeRechazo ? 'mdi-send' : ''"
            outlined
            clearable
            label="Message"
            type="text"
            @click:append-outer="enviarMensaje()"
            hide-details
            dense
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialogMensajes = false, dialogUpdateCambio = true">Cancelar</v-btn>
          <v-btn color="success" text @click="enviarMensaje()">Enviar</v-btn>
        </v-card-actions>
        <v-btn 
        	color="transparent elevation-0"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogMensajes = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>


  </v-container>
</template>
<script>
import axios from 'axios';
	var moment = require('moment');
  moment.locale();
	import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'
  export default {
    data () {
      return {
        search: '',
        headers: [
          { text: 'Fecha',       value: 'fecha_solicitud' },
          { text: 'id alumno',   value: 'id_alumno' },
          { text: 'Nombre',      value: 'alumno' },
          { text: 'Matricula',   value: 'matricula' },
          { text: 'Grupo',       value: 'grupo' },
          { text: 'Nuevo Grupo', value: 'nuevo_grupo' },
          { text: 'Diferencia' , value: 'diferencia' },
          { text: 'Responsable', value: 'responsable' },
          { text: 'Diferencia' , value: 'diferencia' },

          { text: 'Estatus',     value: 'estatus_nombre' },
          { text: 'Opciones',    value: 'opciones' },
        ],
        teachers: [],

        overlay:false,
	      dialogError: false,
	      error:'',

	      ciclos:[],
	      ciclo:{},
	      idciclo:0,

        tipociclo:'',
        grupos:[],
        nuevoGrupo:{},
        tipocambios:[],
        tipoCambio:{},
        alumnos:[],

        dialogUpdateCambio: false,
        alumno:{},
        dialogSuccess:false,
        successMensaje:'',
        dialogRechazar:false,
        mensajeRechazo:'',

        dialogMensajes: false,
        mensajes:[],
        fecha:'',
        cicloInf:     null,
        ciclos:       [],

      }
    },

    async created () {
      console.clear()
      // Ahora traemos los alumnos por escuela
      await this.getAlumnosxEscuela()
      await this.getCiclos()
    },

    methods: {

      getCiclos () {
        this.overlay = true
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.overlay = false
          return
        }).catch(error=>{
          this.overlay = false
        })
      },

      actualizarGrupoAlumnos () {
        if(this.cicloInf){
          // Activamos el loader
          this.overlay = true
          // Hacemos la peticion
          this.$http.get(`lms.catalogos.gruposalumnos.cron/${ this.cicloInf.id_ciclo }/${ this.cicloInf.id_ciclo_relacionado }`).then(response=>{
            // Quitamos el loader
            this.overlay = false
          }).catch(error=>{
            this.overlay = false
            this.openDialogError(error.body.message)
          })
        }
      },

      getAlumnosxEscuela () {
      	this.overlay = true
        return this.$http.get('cambios.all').then(response=>{
        	for(const i in response.data){
        		response.data[i].fecha_solicitud = response.data[i].fecha_solicitud.substr(0,10)
        	}
          this.alumnos = response.data
          this.overlay = false
        }).catch(error=>{
          console.log(error)
          this.loading = false
        })
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportar(){
        let data = XLSX.utils.json_to_sheet(this.alumnos)
        const workbook = XLSX.utils.book_new()
        const filename = 'cambios_grupos'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });
      },

      verCambio (alumno) {
        this.alumno = alumno
        this.dialogUpdateCambio = true
      },

      validarCambio () {
        let { lms } = this.alumno
        
        if( lms ){
          lms = lms ? 1 : 0

        	this.overlay = true
          let payload = {
            idsolicitud_cambio: this.alumno.idsolicitud_cambio,
            estatus:            2,
            fecha_cambio:       (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            realizo_cambio:     this.getdatosUsuario.iderp,
  					whatsapp:           0,
  					lms,
  					zoom:               0,
  					fecha_aceptacion: 	"0000-00-00 00:00:00",
          }

          this.$http.put('cambios.update/' + payload.idsolicitud_cambio,payload).then(response=>{
            this.overlay        = false
            this.successMensaje = 'Datos grabados correctamente'
            this.dialogSuccess  = true
            this.getAlumnosxEscuela()
          }).catch(error=>{
            this.error = error.body.message
            this.dialogError = true
            this.overlay = false
          })
          this.dialogUpdateCambio = false
        }else{
          let mensaje = 'Para aceptar el cambio, necesitas validar que el alumno se encuentra en el LMS'
          this.swalError( mensaje )
        }
      },

      updateCambio () {
        this.overlay = true
        let payload = {
          idsolicitud_cambio: this.alumno.idsolicitud_cambio,
          estatus:            2
        }

        this.$http.put('cambios.update/' + payload.idsolicitud_cambio,payload).then(response=>{
          this.overlay        = false
          this.successMensaje = 'Datos grabados correctamente'
          this.dialogSuccess  = true
          this.getAlumnosxEscuela()
        }).catch(error=>{
          this.error = error.body.message
          this.dialogError = true
          this.overlay = false
        })
        this.dialogUpdateCambio = false
      },

      enviarMensaje () {
        if(this.mensajeRechazo != '' && this.mensajeRechazo != null){
        	this.overlay = true
          let payload = {
            idsolicitud_cambio: this.alumno.idsolicitud_cambio,
            mensaje:            this.mensajeRechazo,
            respuesta:          '',
            envia:              1,
          }

          this.$http.post('cambios.mensaje',payload).then(response=>{
          	this.dialogRechazar = false
          	this.dialogMensajes = false
          	this.mensajeRechazo = ''
          	this.mensajes       = []
          	this.updateCambio()
          	this.overlay = false
          }).catch(error=>{
            this.error = error.body.message
            this.dialogError = true
            this.overlay = false
          	this.dialogRechazar = false
          })
        }else{
          this.error = 'Ingresa un mensaje'
          this.dialogError = true
          this.dialogRechazar = false
        }
      },

      cargarMensajes () {
      	this.$http.get('cambios.mensaje/' + this.alumno.idsolicitud_cambio).then(response=>{
        	this.mensajes = response.data
        	this.dialogMensajes = true
        	this.dialogUpdateCambio = false
        	this.overlay = false
        }).catch(error=>{
          this.error = error.body.message
          this.dialogError = true
          this.overlay = false
        	this.dialogRechazar = false
        })
      },

      rechazar(){
        this.overlay = true
        this.$http.post('cambios.rechazar', this.alumno).then(response=>{
          this.overlay        = false
          this.successMensaje = 'Datos grabados correctamente'
          this.dialogSuccess  = true
          this.getAlumnosxEscuela()
        }).catch(error=>{
          this.error = error.body.message
          this.dialogError = true
          this.overlay = false
        })
        this.dialogUpdateCambio = false
      },

      getAhora () {
      	let fecha = new Date().toLocaleString();
    		fecha = moment(fecha).format().replace('T',' ').substr(0,19)
    		return fecha
      },

      swalError(text) {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text,
        });
      },

      swalSuccess(text){
        this.$swal({
          position: 'top-end',
          icon: 'success',
          title: text,
          showConfirmButton: false,
          timer: 1500
        })
      },
    },

    computed:{
      ...mapGetters ('login',['getdatosUsuario'])
    }
  }
</script>

<style>

  .v-menu__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-menu__content::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-button:increment,.v-menu__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-menu__content::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>