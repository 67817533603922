<template>
  <v-container class="mt-4 px-5" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <!--Botones control de la vista-->
          <v-card-title primary-title>
            Panel de calificaciones

            <v-radio-group v-model="escuela" row class="ml-2">
              <v-radio label="Fast" :value="2"></v-radio>
              <v-radio label="Inbi" :value="1"></v-radio>
            </v-radio-group>

            <v-spacer></v-spacer>

            <v-btn class="mr-2" small dark color="green" @click="exportExcel(filterAlumnos, 'PUNTOS_ALUMNOS')" tile>
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn color="orange" dark class="mr-2"  @click="subirCalifiaciones()" small v-if="[11,12,58].includes( getdatosUsuario.idpuesto )" tile>
              <v-icon left small>mdi-plus</v-icon>
              Subir datos
            </v-btn>

            <v-btn color="primary" dark class="text-capitalize" @click="consultarCalificaciones()" small tile>
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row justify="space-between">
              <v-col cols="12" md="4" lg="3" class="pb-0">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="4" lg="6" class="pb-0">
                <v-text-field
                  label="Buscar"
                  v-model="search"
                  dense
                  filled
                  append-icon="mdi-magnify"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-radio-group v-model="option" row>
                  <v-radio label="Todos" :value="1"></v-radio>
                  <v-radio label="Reprobados" :value="2"></v-radio>
                  <v-radio label="Extraordinario y Primera" :value="3"></v-radio>
                  <v-radio label="Solo aprobados" :value="4"></v-radio>
                  <v-radio label="Sin Kardex" :value="5"></v-radio>
                  <v-radio label="Sin Kardex Reprobado" :value="6"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-data-table :headers="headers" :items="filterAlumnos" class="elevation-0" :search="search" dense>
              <template v-slot:item.asistencias="{ item }">
                {{ item.asistencias + " de " + item.total_dias_laborales }}
              </template>

              <template v-slot:item.examenes="{ item }">
                {{ item.examenes + " de " + item.num_examenes }}
              </template>

              <template v-slot:item.ejercicios="{ item }">
                {{ item.ejercicios + " de " + item.num_ejercicios }}
              </template>

              <template v-slot:item.total="{ item }">
                <v-chip small :color="item.total < 70 ? 'red' : 'green'" dark>
                  {{ item.total }}
                </v-chip>
              </template>

              <template v-slot:item.estado_comentario="{ item }">
                <v-chip small color="orange" dark v-if="item.estado_comentario == 0">Posible Comentario</v-chip>

                <v-chip small color="primary" dark v-if="item.estado_comentario == 1">Aprobado</v-chip>

                <v-chip small color="black" dark v-if="item.estado_comentario == 2">Reprobado</v-chip>

                <v-chip small color="secondary" dark v-if="item.estado_comentario == 3">Examen Extraordinario</v-chip>

                <v-chip small color="black" dark v-if="item.estado_comentario == 4">Pase por comentario</v-chip>

                <v-chip small color="black" dark v-if="item.estado_comentario == 5">Reubicacion</v-chip>
              </template>

              <template v-slot:item.extra="{ item }">
                <v-chip small :color="item.extra < 70 ? 'red' : 'green'" dark v-if="item.extra">
                  {{ item.extra }}
                </v-chip>
              </template>

              <template v-slot:item.ejercicios="{ item }">
                {{ item.ejercicios + " de " + item.num_ejercicios }}
              </template>

              <template v-slot:item.posibilidad="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip small v-bind="attrs" v-on="on">
                      {{
                        parseFloat(item.total) + parseFloat(item.pts_finales)
                      }}
                    </v-chip>
                  </template>
                  <span
                    >Esta es la calificación que el alumno podría obtener si
                    contesta todos sus ejercicios o exámenes faltantes.</span
                  >
                </v-tooltip>
              </template>

              <template v-slot:item.ver="{ item }">
                <v-row>
                  <v-chip
                    color="green"
                    dark
                    small
                    @click="buscarCalificacion(item)"
                  >
                    <v-icon small> mdi-magnify </v-icon>
                  </v-chip>
                  <v-chip
                    color="orange"
                    dark
                    small
                    @click="subirCalifiacionAlumno(item)"
                    class="ms-2"
                    v-if="[11,12,58].includes( getdatosUsuario.idpuesto )"
                  >
                    <v-icon small> mdi-arrow-up-bold </v-icon>
                  </v-chip>

                </v-row>
              </template>

            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <v-dialog
      v-if="verCalificaciones"
      v-model="dialogAlumno"
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h5"> Calificaciones </v-card-title>
        <v-card-text>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="basil"
            grow
          >
            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <!-- Asistencias -->
            <v-tab-item>
              <v-card color="basil" flat>
                <v-card-title primary-title>
                  {{ calificaciones.asistencias.pts_total_asistencia }} pts de
                  {{ calificaciones.asistencias.limite }} pts
                  <v-progress-linear
                    color="orange"
                    height="25"
                    style="border-radius: 50px"
                    :value="
                      (
                        (calificaciones.asistencias.pts_total_asistencia /
                          calificaciones.asistencias.limite) *
                        100
                      ).toFixed(1)
                    "
                  >
                    <template v-slot:default="{ value }">
                      <strong
                        >{{
                          (
                            (calificaciones.asistencias.pts_total_asistencia /
                              calificaciones.asistencias.limite) *
                            100
                          ).toFixed(1)
                        }}%</strong
                      >
                    </template>
                  </v-progress-linear>
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    width="100%"
                    :headers="headersAsistencias"
                    :items="calificaciones.asistencias.desgloce"
                    :items-per-page="calificaciones.asistencias.desgloce.length"
                    class="elevation-0"
                    :mobile-breakpoint="100"
                    hide-default-footer
                    fixed-header
                    dense
                    height="400"
                  >
                    <template v-slot:item.valor_text="{ item }">
                      <v-chip small rounded :color="item.color">{{
                        item.valor_text
                      }}</v-chip>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- Ejercicios -->
            <v-tab-item>
              <v-card color="basil" flat>
                <v-card-title primary-title>
                  {{ calificaciones.ejercicios.pts_total_ejercicios }} pts de
                  {{ calificaciones.ejercicios.limite }} pts

                  <v-progress-linear
                    color="orange"
                    height="25"
                    style="border-radius: 50px"
                    :value="
                      (
                        (calificaciones.ejercicios.pts_total_ejercicios /
                          calificaciones.ejercicios.limite) *
                        100
                      ).toFixed(1)
                    "
                  >
                    <template v-slot:default="{ value }">
                      <strong
                        >{{
                          (
                            (calificaciones.ejercicios.pts_total_ejercicios /
                              calificaciones.ejercicios.limite) *
                            100
                          ).toFixed(1)
                        }}%</strong
                      >
                    </template>
                  </v-progress-linear>
                </v-card-title>

                <v-card-text>
                  <v-data-table
                    width="100%"
                    :headers="headersEjercicios"
                    :items="calificaciones.ejercicios.desgloce"
                    :items-per-page="calificaciones.ejercicios.desgloce.length"
                    class="elevation-0"
                    :mobile-breakpoint="100"
                    hide-default-footer
                    fixed-header
                    dense
                    height="400"
                  >
                    <template v-slot:item.valor_text="{ item }">
                      <v-chip small rounded :color="item.color">{{
                        item.valor_text
                      }}</v-chip>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- Examenes -->
            <v-tab-item>
              <v-card color="basil" flat>
                <v-card-title primary-title>
                  {{ calificaciones.examenes.pts_total_examenes }} pts de
                  {{ calificaciones.examenes.limite }} pts
                  <v-progress-linear
                    color="orange"
                    height="25"
                    style="border-radius: 50px"
                    :value="
                      (
                        (calificaciones.examenes.pts_total_examenes /
                          calificaciones.examenes.limite) *
                        100
                      ).toFixed(1)
                    "
                  >
                    <template v-slot:default="{ value }">
                      <strong
                        >{{
                          (
                            (calificaciones.examenes.pts_total_examenes /
                              calificaciones.examenes.limite) *
                            100
                          ).toFixed(1)
                        }}%</strong
                      >
                    </template>
                  </v-progress-linear>
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    width="100%"
                    :headers="headersEjercicios"
                    :items="calificaciones.examenes.desgloce"
                    :items-per-page="calificaciones.examenes.desgloce.length"
                    class="elevation-0"
                    :mobile-breakpoint="100"
                    hide-default-footer
                  >
                    <template v-slot:item.valor_text="{ item }">
                      <v-chip small rounded :color="item.color">{{
                        item.valor_text
                      }}</v-chip>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card color="basil" flat>
                <v-card-text align="center" class="text-center">
                  <v-progress-circular
                    align="center"
                    :rotate="90"
                    :size="220"
                    :width="25"
                    :value="calificaciones.calificacion_total"
                    color="pink"
                  >
                    <span class="text-h6"
                      >{{ calificaciones.calificacion_total }} pts de 100</span
                    >
                  </v-progress-circular>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogAlumno = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="dialogAlumno = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>

<script>
import axios from 'axios';
import XLSX from "xlsx";

import { mapGetters, mapActions } from "vuex";
var moment = require("moment");
moment.locale();

// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  components: {
    Alerta,
    carga,
  },

  mixins: [validarErrores, funcionesExcel],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    gruposAlumnos:[],
    horarios:null,
    cicloInf: 		null,

    ciclos: [],
    ciclo: null,
    idciclo: 0,
    dialogAlumno: false,
    calificaciones: {},
    verCalificaciones: false,
    tab: null,
    items: ["Participaciones", "Ejercicios", "Exámenes", "Total"],

    cardsCalificaciones: [
      {
        text: "Participaciones",
        series: [0],
        chartOptions: null,
        icono: "mdi-account",
        clase: "avataresAsistencias",
      },
      {
        text: "Ejercicios",
        series: [0],
        chartOptions: null,
        icono: "mdi-file-document",
        clase: "avataresEjercicios",
      },
      {
        text: "Exámenes",
        series: [0],
        chartOptions: null,
        icono: "mdi-file-check",
        clase: "avataresExamenes",
      },
      {
        text: "Total",
        series: [0],
        chartOptions: null,
        icono: "mdi-chart-arc",
        clase: "avataresTotal",
      },
    ],

    headersAsistencias: [
      {
        text: "Fecha",
        value: "fecha_formato",
        class: "text-uppercase grey--text text-caption",
      },
      {
        text: "Valor",
        value: "valor_text",
        class: "text-uppercase grey--text text-caption text-center",
        align: "center",
      },
    ],

    headersEjercicios: [
      {
        text: "Lección",
        value: "session",
        class: "text-uppercase grey--text text-caption",
      },
      {
        text: "Calificación",
        value: "calificacion",
        class: "text-uppercase grey--text text-caption text-center",
        align: "center",
      },
    ],

    loading: false,
    search: "",
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Matricula"      , value: "matricula" },
      { text: "Alumno"         , value: "nombre" },
      { text: "Grupo"          , value: "grupo" },
      { text: "Total"          , value: "total" },
      { text: "Extraordinario" , value: "extra" },
      { text: "1era"           , value: "primera" },
      { text: "2da"            , value: "segunda" },
      { text: "Estado"         , value: "estado_comentario" },
      { text: "Pases en extra" , value: "pases_extra", width: "5%" },
      { text: "Ultima conexión", value: "ultimo_movimiento"},
      { text: "Acciones"      , value: "ver"},
      // { text: "Asistencias", value: "asistencias" },
      // { text: "Examenes", value: "examenes" },
      // { text: "Actividades", value: "ejercicios" },
      // { text: "Pts por obtener", value: "pts_finales" },
      // { text: "Posible calif.", value: "posibilidad" },
    ],
    alumnos: [],
    escuela: 1,
    option: 1,
  }),

  watch: {
    ciclo() {
      if (this.ciclo) {
        // ahora que ya tenemos el ciclo, mandamos a llamar a la funcion de traer los datos del ciclo
        this.consultarCalificaciones();
      }
    },

    escuela(){
      if (this.ciclo && this.escuela) {
        this.consultarCalificaciones();
      }
    }
  },

  computed: {
    ...mapGetters("login", [
      "getdatosUsuario",
      "getLogeado",
      "getEscuela",
      "drawer2",
      "getEstatusLlamada",
      "getNuevosProspectos",
    ]),

    filterAlumnos() {
      if (this.option == 2) {
        // Alumnos sin primera oportunidad
        return this.alumnos.filter((el) => {
          if (
            (el.primera < 70 && el.segunda < 70 && !el.grupo.match("CERTI")) ||
            (el.posibilidad < 80 && !el.grupo.match("CERTI"))
          ) {
            return true;
          } else {
          }
        });
      } else if( this.option == 3) {
        return this.alumnos.filter((el) => { return el.extra || el.total >= 69 })
      } else if( this.option == 4) {
        return this.alumnos.filter((el) => { return el.extra >= 70 || el.total >= 69 })
      } else if( this.option == 5) {
        // Alumnos sin primera oportunidad
        return this.alumnos.filter( el => !el.primera && !el.grupo.match('CERTI') && ( el.total >= 69 || el.extra >= 70 )  );
      } else if( this.option == 6) {
        // Alumnos sin primera oportunidad
        return this.alumnos.filter( el => !el.primera && !el.grupo.match('CERTI') && ( el.total < 69 && el.extra )  );
      }else{
        return this.alumnos;
      }
    },
  },

  async created() {
    await this.initialize()
  },

  methods: {
    initialize() {
       this.getCiclos();
    },

    getCiclos() {
      this.cargar = true;
      this.ciclos = [];
      this.planteles = [];
      this.$http.get("kpi.ciclos.all").then((response) => {
        for (const i in response.data) {
          if (!(response.data[i].ciclo.search("FE") != -1)) {
            if (response.data[i].ciclo.search("CICLO") != -1) {
              this.ciclos.push(response.data[i]);
            }
          }
        }
        this.cargar = false;
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => {
        this.cargar = false;
      });
    },

    consultarCalificaciones(){
      if(this.ciclo && this.escuela){
        this.cargar = true;
        this.alumnos = [];
        const payload = {
          idCiclo: this.escuela == 1 ? this.ciclo.id_ciclo : this.ciclo.id_ciclo_relacionado,
          escuela: this.escuela,
        };
        this.$http.post("riesgo.puntos", payload).then((response) => {
            let respuesta = response.data; //.filter( el => { return !el.grupo.match('KIDS') })
  
            const planteles = this.getdatosUsuario.planteles.map((registro) => {
              return registro.idplantel;
            });
  
            if ([18, 19].includes(this.getdatosUsuario.idpuesto) && this.getdatosUsuario.iderp != 7 ) {
              respuesta = response.data.filter((el) => {
                return planteles.includes(el.id_plantel);
              });
            }
            this.calcularCalificaciones2(respuesta);
            
            //this.calcularCalificaciones(respuesta);
          }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });
      }
    },

    calcularCalificaciones2(value){
      for (const i in value){
        value[i].escuela = this.escuela;
        value[i].total = value[i].total.toFixed(2)
      }
      this.alumnos = value;
    },

    calcularCalificaciones(value) {
      var arrayCalificaciones = value;

      let alumnos = value.map((item) => {
        return [item.nombre, item];
      });
      var alumnosArray = new Map(alumnos); // Pares de clave y valor

      let unicos = [...alumnosArray.values()]; // Conversión a un array
      for (const i in unicos) {
        var sumaCalifEjercicio = 0;
        var cantEjercicio = 0;
        var sumaCalifExamen = 0;
        var cantExamen = 0;
        var sumaTotal = 0;
        var sumaAsistencia = 0;
        var cantAsistencia = 0;
        var sumaFalta = 0;
        var sumaRetardo = 0;
        var sumaJustificada = 0;
        var califExtra = "";
        for (const j in arrayCalificaciones) {
          if (unicos[i].id_alumno == arrayCalificaciones[j].id_alumno) {
            if (
              arrayCalificaciones[j].tipo_evaluacion == 1 &&
              arrayCalificaciones[j].idCategoriaEvaluacion == 1
            ) {
              sumaCalifEjercicio =
                sumaCalifEjercicio + arrayCalificaciones[j].calificacion;
              cantEjercicio = cantEjercicio + 1;
            } else {
              if (
                (arrayCalificaciones[j].tipo_evaluacion == 2 &&
                  arrayCalificaciones[j].idCategoriaEvaluacion == 1) ||
                (arrayCalificaciones[j].tipo_evaluacion == 2 &&
                  arrayCalificaciones[j].idCategoriaEvaluacion == 2)
              ) {
                sumaCalifExamen =
                  sumaCalifExamen + arrayCalificaciones[j].calificacion;
                cantExamen = cantExamen + 1;
              } else {
                califExtra = arrayCalificaciones[j].calificacion;
              }
            }
          }
        }

        // Haciendo el calculo para los puntos obtenidos de asistencias
        sumaAsistencia = unicos[i].asistencia;
        sumaFalta = unicos[i].falta;
        sumaRetardo = unicos[i].retardo;
        sumaJustificada = unicos[i].justificada;

        var ptsAsistencia =
          unicos[i].valor_asistencia / unicos[i].total_dias_laborales;
        var sumaTotalAsistencia =
          unicos[i].asistencia * ptsAsistencia +
          unicos[i].retardo * (ptsAsistencia / 2);

        // Calulando los puntos por ejercicios
        var divEjercico = sumaCalifEjercicio / unicos[i].num_ejercicios;
        var ptsEjercicio = unicos[i].valor_ejercicios / 100;
        var ptsFinalesEjercicios = divEjercico * ptsEjercicio;

        var divExamenes = sumaCalifExamen / unicos[i].num_examenes;
        var ptsExamenes = unicos[i].valor_examenes / 100;
        var ptsFinalesExamenes = divExamenes * ptsExamenes;

        cantAsistencia = sumaAsistencia + sumaRetardo;

        // Puntos de los ejercicios
        var ejerciciosFaltante = unicos[i].num_ejercicios - cantEjercicio;
        var ptsDispoEjercicio =
          ejerciciosFaltante *
          (unicos[i].valor_ejercicios / unicos[i].num_ejercicios);

        // Pts de los examenes
        var examenesFaltante = unicos[i].num_examenes - cantExamen;
        var ptsDispoExamen =
          examenesFaltante *
          (unicos[i].valor_examenes / unicos[i].num_examenes);

        // Puntos de las asistencias
        var asistenciasFaltante =
          unicos[i].total_dias_laborales - cantAsistencia;
        var ptsDispoAsis =
          asistenciasFaltante *
          (unicos[i].valor_asistencia / unicos[i].total_dias_laborales);

        // Puntos finales
        var ptsDispoFinales = ptsDispoEjercicio + ptsDispoExamen + ptsDispoAsis;

        var payload = {
          nombre: unicos[i].nombre,
          matricula: unicos[i].matricula,
          grupo: unicos[i].grupo,
          examenes: cantExamen,
          num_examenes: unicos[i].num_examenes,
          ejercicios: cantEjercicio,
          num_ejercicios: unicos[i].num_ejercicios,
          total: (
            ptsFinalesEjercicios +
            ptsFinalesExamenes +
            sumaTotalAsistencia
          ).toFixed(2),
          asistencias: cantAsistencia,
          total_dias_laborales: unicos[i].total_dias_laborales,
          pts_finales: ptsDispoFinales.toFixed(2),
          telefono: unicos[i].telefono,
          celular: unicos[i].celular,
          extra: califExtra,
          ptsDispoEjercicio: ptsFinalesEjercicios,
          ptsDispoExamen: ptsFinalesExamenes,
          ptsDispoAsis: sumaTotalAsistencia,
          teacher: "",
          teacher2: "",
          primera: unicos[i].calificacion_final_primera_oportunidad,
          segunda: unicos[i].calificacion_final_segunda_oportunidad,
          id_curso: unicos[i].id_curso,
          id_grupo: unicos[i].id_grupo,
          id_nivel: unicos[i].id_nivel,
          id_alumno: unicos[i].id_alumno,
          escuela: this.escuela,
          estado_comentario: unicos[i].estado_comentario,
          pases_extra: unicos[i].pases_extra
        };

        this.alumnos.push(payload);
      }
      this.cargar = false;
    },

    // EXCEL
    exportar() {
      let xls = {
        data: this.alumnos,
        tHeaders: ["Codigo", "Nombre", "Sal", "Labortorio"],
        filterVal: ["codigo", "nomart", "sal", "lab"],
        nomfile: "Productos",
      };
      this.exportExcel();
    },

    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },

    exportExcel() {
      let data = XLSX.utils.json_to_sheet(this.alumnos);
      const workbook = XLSX.utils.book_new();
      const filename = "puntos_dispo_fast";
      XLSX.utils.book_append_sheet(workbook, data, filename);

      var wbout = XLSX.write(workbook, {
        bookType: "xls",
        bookSST: false,
        type: "binary",
      });

      const file = new File([new Blob([this.s2ab(wbout)])], filename + ".xls");
      console.log(file);

      let formData = new FormData();
      // //se crea el objeto y se le agrega como un apendice el archivo
      formData.append("file", file);
      // /*Initialize the form data*/

      this.$http
        .post("pdfs", formData)
        .then((response) => {
          window.location =
            axios.defaults.baseURL + "pdfs/" + filename + ".xls";
        })
        .catch((error) => {
          alert(error.body);
          console.log(error);
        });
    },

    subirCalifiaciones() {
      this.cargar = true;

      let calificaciones = this.filterAlumnos

      const payload = {
        escuela: this.escuela,
        calificaciones,
      };

      this.$http
        .post("subir.calificaciones", payload)
        .then((response) => {
          this.validarSuccess(response.data.message);

          this.consultarCalificaciones();
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    subirCalifiacionAlumno(item) {
      this.cargar = true;
      const payload = {
        escuela: this.escuela,
        id_curso: item.id_curso,
        id_alumno: item.id_alumno,
        id_grupo: item.id_grupo,
        id_nivel: item.id_nivel,
        primera: item.primera,
        segunda: item.segunda,
        total: item.total,
        extra: item.extra,
        pases_extra: item.pases_extra,
        grupo: item.grupo,
        estado_comentario: item.estado_comentario
      };
       this.$http
         .post("subir.calificacionAlumno", payload)
         .then((response) => {
           this.validarSuccess(response.data.message);

           this.consultarCalificaciones();
         })
         .catch((error) => {
           this.validarError(error);
         })
         .finally(() => {
           this.cargar = false;
         });
    },

    buscarCalificacion(alumno) {
      console.log(alumno)
      this.cargar = true;
      // Hacemos la peticios
      this.$http
        .post(`calificaciones.alumno`, alumno)
        .then((response) => {
          this.calificaciones = response.data;

          const califAsistencias = this.calificaciones.asistencias.pts_total_asistencia;
          const califAsistenciasL = this.calificaciones.asistencias.limite;
          const califEjercicios = this.calificaciones.ejercicios.pts_total_ejercicios;
          const califEjerciciosL = this.calificaciones.ejercicios.limite;
          const califExamenes = this.calificaciones.examenes.pts_total_examenes;
          const califExamenesL = this.calificaciones.examenes.limite;
          const calificacion_total = this.calificaciones.calificacion_total;

          this.verCalificaciones = true;
          this.dialogAlumno = true;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },
  },
};
</script>
