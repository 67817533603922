const teensInbi = [
	{ 
		titulo: '¿Por qué estudiar inglés?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Hoy en día el saber inglés no es un plus, sino una obligación para todos aquellos que queramos tener mejores oportunidades de vida para nuestros hijos',
			},
		]
	},

	{ 
		titulo: '¿cómo le vamos a enseñar el inglés?',
		check: false,
		subtemas:[
			{ 
				titulo: 'El método utilizado es el de inteligencias múltiples”, este método divide el aprendizaje en diferentes áreas, las cuales se aprovechan para poder lograr un aprendizaje y permanente del idioma inglés',
			},
		]
	},

	{ 
		titulo: '¿Cómo son las clases?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Son dinámicos y 100% en inglés, al final de cada clase podemos preguntas las dudas en español de ser necesario. La clave del aprendizaje es la práctica constante dentro de la clase.',
			},
		]
	},

	{ 
		titulo: '¿Cómo saben qué nivel de inglés tengo?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Contamos con un curso de inducción el cual nos sirve para normalizar y trabajar su nivel de inglés, en base a este curso podremos determinar las áreas de oportunidad para atacar dentro del curso',
			},
		]
	},

	{ 
		titulo: '¿Qué horarios tienen?',
		check: false,
		subtemas:[
			{ titulo: 'Manejamos diferentes modalidades' },
			{ 
				titulo: 'Intensivo entre semana',
				subsubtemas:[
					{ titulo: '1 hora y media por clase - 2 días a la semana'},
				]
			},

			{ 
				titulo: 'Sabatino',
				subsubtemas:[
					{ titulo: '3 horas - solo el sábado'},
				]
			},
		]
	},

	{ 
		titulo: '¿Cuánto dura el curso? ',
		check: false,
		subtemas:[
			{ titulo: 'El curso consta de 16 niveles, cada nivel consta de 4 semanas efectivas de clases esto quiere decir que pagas por clases y no por vacaciones ni festivos' },
		]
	},

	{ 
		titulo: '¿Cómo me aseguran que aprenderé inglés?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Para asegurarnos que el aprendizaje del alumno fue correctolo evaluamos por nivel',
			},
		]
	},

	{ 
		titulo: '¿Para qué edades es el curso?',
		check: false,
		subtemas:[
			{ 
				titulo: 'El curso es para niños de 8 a 12 años',
			},
		]
	},

	{ 
		titulo: '¿Cuánto cuesta el curso?',
		check: false,
		subtemas:[
			{ 
				titulo: 'Es importante mencionar que no manejamos costo de inscripción ni de material didáctico',
			},

			{ 
				titulo: 'Intesivo',
				subsubtemas:[
					{ 
						titulo: 'Se necesita una inversión de $1,185 por nivel, como descuento para su primer nivel la escuela le brindará un 20% de descuento, el costo quedaría en $945 por nivel'
					},
				]
			},

			{ 
				titulo: 'Para iniciar el curso, se debe de tomar el curso de inducción de 2 días (40 minutos por día) con un costo de $99, los cuales serán tomados en cuenta en su primer nivel',
			},

			{ 
				titulo: 'Contamos con un sistema de descuentos de pronto pago el cual permitirá mantener el mismo costo de su primer nivel',
			},
		]
	},

	{ 
		titulo: '¿Qué incluye?',
		check: false,
		subtemas:[
			{ titulo:'4 semanas efectivas de clases en vivo' },
			{ titulo:'Material didáctico que se ocupe para el correcto aprendizaje.' },
			{ titulo:'Acceso a nuestra plataforma virtual' },
			{ titulo:'Acceso a grupo WhatsApp con contenido exclusivo' },
		]
	},
	{ 
		titulo: 'Cierre',
		check: false,
		subtemas:[
			{ titulo: 'Resumen de información'},
			{ titulo: 'Pregunta de cierre'},
		]
	},
]

export { teensInbi }