<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Bonos Puntualidad</span>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="3" >
                <v-text-field
                  filled          
                  label="Fecha"
                  type="date"
                  hide-details
                  v-model="fecha"
                  dense
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>             
              
              <v-col cols="12" md="6">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="bonospuntualidad"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                >

                 <template v-slot:item.estatus_entradas_validas="{ item }">
							      <v-chip v-if="item.estatus_entradas_validas == 1" small color="blue" dark >Meta alcanzada</v-chip>
							      <v-chip v-if="item.estatus_entradas_validas == 0" small color="black" dark >Meta no alcanzada</v-chip>
							   </template>

                 <template v-slot:item.estatus_entradas_laboradas="{ item }">
							      <v-chip v-if="item.estatus_entradas_laboradas == 1" small color="blue" dark >Meta alcanzada</v-chip>
							      <v-chip v-if="item.estatus_entradas_laboradas == 0" small color="black" dark >Meta no alcanzada</v-chip>
							   </template>

                 <template v-slot:item.estatus_bonopuntualidad="{ item }">
							      <v-chip v-if="item.estatus_bonopuntualidad == 1" small color="green" dark >Aceptadas</v-chip>
                     <v-chip v-if="item.estatus_bonopuntualidad == null" small color="orange" dark >En Revisión</v-chip>
							   </template>

                  <template v-slot:item.aceptar="{ item }">
                        <v-btn
                        color="primary"
                        dark
                        small
                        @click="
                            (item_bonopuntualidad = item), (dialogAceptar = true)
                        "
                        >
                        ACEPTAR
                        </v-btn>
                  </template>

                  <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize" small>
                      Actualizar
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

     <v-dialog v-model="dialogAceptar" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Aceptar:</strong> ¿Estás seguro que deseas aceptar el bono de puntualidad?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      large
                      class="mr-2"
                      @click="dialogAceptar=false"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      large
                      @click="addNominaBonoPuntualidad()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/contrato.jpg"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

 

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,
    dialogAceptar: false,
    headers: [
      { text: "ID", align: "start", value: "id" },
      { text: "Recepcionista", value: "nombre_completo" },
      { text: "Dias Validos", value: "numero_entradas_validas" },
      { text: "Estatus Dias Validos", value: "estatus_entradas_validas" },
      { text: "Puesto", value: "puesto" },
      { text: "Monto Bono", value: "bono_puntualidad" },
      { text: "Estatus Bono", value: "estatus_bonopuntualidad" },
      { text: "Aceptar", value: "aceptar" }
    ],
    bonospuntualidad: [],
    editedIndex: -1,
    editedItem: {
      idhoras_extras: "",
      fecha: "",
      hora_inicio: "",
      hora_fin: "",
      cantidad_horas: "",
      notas: "",
      estatus: 0,
      id_creo: ""
    },
    defaultItem: {
      idhoras_extras: "",
      fecha: "",
      hora_inicio: "",
      hora_fin: "",
      cantidad_horas: "",
      notas: "",
      estatus: 0,
      id_creo: ""
    },

    dialogSuccess: false,
    overlay: false,
    dialogError: false,
    error: "",
    successMensaje: "",
    errorBack: false,
    textoCopiado: "COPIAR",
    idcurso: 0,
    info: 0,
    dialog_mensaje_rechazo: false,
    idhistorial: [],
    fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    item_bonopuntualidad: null,
    editedItem2: {
      mensaje: "",
    },
    mensajes: [],

    ciclos: [],
    ciclo: null,
    estatus: null,
    alumnos: [],
    reglas: [],
    cursosEscuela: [],
    bonospuntualidad: "",
    total_dias: "",
    // tabla
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
    formTitle() {
      return this.editedIndex === -1
        ? "Agregar Horas Extras"
        : "Editar Horas Extras";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "editedItem.escuela"(val) {
      this.cursosEscuela = this.cursos.filter((el) => {
        return el.escuela == val;
      });
    },
  },

  created() {
    this.initialize();
    // this.getCiclos();
    // this.getAlumnos();
    // this.getReglas();
  },

  methods: {
    initialize() {
      this.bonospuntualidad = [];
      this.overlay = true;
      const payload = {
      fecha:  this.fecha
      };

      this.cargar = true

      this.$http
        .post("bonospuntualidad.get.bonospuntualidad", payload)
        .then((response) => {
          this.bonospuntualidad = response.data.respuesta;
          this.total_dias =  response.data.total_dias;
          this.overlay = false;
          this.cargar = false
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    addNominaBonoPuntualidad () {
    this.cargar = true
    const payload = {
    fecha: this.fecha,
    estatus: 1,
    id_empleado: this.item_bonopuntualidad.id,
    id_categorias_encargadas: this.item_bonopuntualidad.idcategorias_encargadas,
    entradas_validas: this.item_bonopuntualidad.numero_entradas_validas,
    entradas_laboradas: this.item_bonopuntualidad.numero_entradas_laboradas,
    estatus_entradas_validas: this.item_bonopuntualidad.estatus_entradas_validas,
    estatus_entradas_laboradas: this.item_bonopuntualidad.estatus_entradas_laboradas,
    bono_puntualidad: this.item_bonopuntualidad.bono_puntualidad,
    idusuario: this.item_bonopuntualidad.id_usuario
    }
    // Lo mandapos por el EP
    this.$http.post('bonospuntualidad.add.bonospuntualidad', payload).then(response=>{
    this.validarSuccess('Datos grabados correctamente')
    this.dialogAceptar = false
    this.initialize();
    this.comision_estatus();
    this.cargar = false
    }).catch( error =>{
        this.validarError( error.response.data.message )
    }).finally( () => { this.cargar = false })
        
    },

    editItem(item) {
      this.editedIndex = this.incidencias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.horasextras.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    copiarPortapapeles() {
      navigator.clipboard
        .writeText(this.error)
        .then(() => {
          this.textoCopiado = "COPIADO";
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });
    },

    //Trae la informacion de toda la fila de alumnos en "Agregar Incidencia"
    infoAlumnos() {
      const alumnoSeleccionado = this.alumnos.find(
        (alumno) => alumno.iderp === this.editedItem.idusuarioerp
      );

      this.info = alumnoSeleccionado.tipo_curso;
     
    },

    aceptarHorasExtras() {

      this.cargar = true;
      const payload = {
        estatus: 1,
        id: this.horaextramensaje.idhoras_extras,
      };

      return this.$http
        .post("horas_extras.update.estatus", payload)
        .then((response) => {
          this.dialogAceptar = false;
          this.horaextramensaje = null;
          this.initialize();
          this.cargar = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
  },
};
</script>
