<template>
	<v-container class="mt-4 ml-2">
	  <v-row>
	  	<v-col cols="12" md="10">
			  <v-data-table
			    :headers="headers"
			    :items="frecuencias"
			    class="elevation-6"
			  >
			    <template v-slot:top>
			      <v-toolbar
			        flat
			      >
			        <v-toolbar-title>Frecuencias</v-toolbar-title>
			        <v-divider
			          class="mx-4"
			          inset
			          vertical
			        ></v-divider>
			        <v-spacer></v-spacer>
			        <v-dialog
			          v-model="dialog"
			          max-width="500px"
			        >
			          <template v-slot:activator="{ on, attrs }">
			            <v-btn
			              color="primary"
			              dark
			              class="mb-2"
			              v-bind="attrs"
			              v-on="on"
			              small
			              rounded
			            >
			              Nueva modalidad
			            </v-btn>
			          </template>
			          <v-card>
			            <v-card-title>
			              <span class="text-h5">{{ formTitle }}</span>
			            </v-card-title>

			            <v-card-text>
			              <v-container>
			                <v-row>
			                	<v-col cols="12">
			                    <v-text-field
			                      v-model="editedItem.frecuencia"
			                      label="Frecuencia"
			                      outlined
			                      hide-details
			                      dense
			                    ></v-text-field>
			                  </v-col>

			                  <v-col cols="12">
			                    <v-select
			                    	:items="[{id:1, escuela:'INBI'},{id:2, escuela:'FAST'}]"
			                      v-model="editedItem.escuela"
			                      label="Escuela"
			                      outlined
			                      hide-details
			                      dense
			                      item-text="escuela"
			                      item-value="id"
			                    ></v-select>
			                  </v-col>

			                  <v-col cols="12">
			                    <v-select
			                    	:items="cursosEscuela"
			                      v-model="editedItem.idcursos_escuela"
			                      label="Cursos"
			                      outlined
			                      hide-details
			                      dense
			                      item-text="curso"
			                      item-value="idcursos_escuela"
			                    ></v-select>
			                  </v-col>

			                  <v-col cols="12">
			                    <v-select
			                    	:items="modalidadEscuela"
			                      v-model="editedItem.idmodalidad_cursos"
			                      label="Modalidad"
			                      outlined
			                      hide-details
			                      dense
			                      item-text="modalidad"
			                      item-value="idmodalidad_cursos"
			                    ></v-select>
			                  </v-col>

			                  <v-col cols="12" sm="6">
			                  	<v-switch 
			                  		label="Activo"
			                      hide-details
			                  		v-model="editedItem.estatus">
			                  	</v-switch>
			                  </v-col>

			                  <v-col cols="12">
			                  	<v-checkbox
											      v-model="editedItem.lunes"
											      label="Lunes"
											      hide-details
											      dense
											    ></v-checkbox>
											    <v-checkbox
											      v-model="editedItem.martes"
											      label="Martes"
											      hide-details
											      dense
											    ></v-checkbox>
											    <v-checkbox
											      v-model="editedItem.miercoles"
											      label="Miercoles"
											      hide-details
											      dense
											    ></v-checkbox>
											    <v-checkbox
											      v-model="editedItem.jueves"
											      label="Jueves"
											      hide-details
											      dense
											    ></v-checkbox>
											    <v-checkbox
											      v-model="editedItem.viernes"
											      label="Viernes"
											      hide-details
											      dense
											    ></v-checkbox>
											    <v-checkbox
											      v-model="editedItem.sabado"
											      label="Sabado"
											      hide-details
											      dense
											    ></v-checkbox>
											    <v-checkbox
											      v-model="editedItem.domingo"
											      label="Domingo"
											      hide-details
											      dense
											    ></v-checkbox>

			                  </v-col>
			                </v-row>
			              </v-container>
			            </v-card-text>

			            <v-card-actions>
			              <v-spacer></v-spacer>
			              <v-btn
			                color="blue darken-1"
			                text
			                @click="close"
			              >
			                Cancelar
			              </v-btn>
			              <v-btn
			                color="blue darken-1"
			                class="elevation-6"
			                dark
			                @click="save"
			              >
			                Guardar
			              </v-btn>
			            </v-card-actions>
			          </v-card>
			        </v-dialog>

			        <v-dialog v-model="dialogDelete" max-width="500px">
			          <v-card>
					      	<v-card-text>
						      	<v-row>
						      		<v-col cols="6">
						      			<v-card class="elevation-0" height="100%" style="display: table;">
							      			<div class="text-h6" style="display: table-cell; vertical-align: middle;">
							      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar la pregunta?
								      			<v-card-actions class="pt-6">
								      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
								      			  <v-btn color="error" text large class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
								      			  <!-- Guardar la información  -->
								      			  <v-btn color="#5C5C5C" dark class="elevation-6" large @click="deleteItemConfirm()">Si</v-btn>
								      			</v-card-actions>
							      			</div>
						      			</v-card>
						      		</v-col>
						      		<v-col cols="6">
						      			<v-img src="@/assets/borrar.png"></v-img>
						      		</v-col>
							      </v-row>
					      	</v-card-text>
					      </v-card>
			        </v-dialog>
			      </v-toolbar>
			    </template>
			    
			    <!-- Valores que ocupan botons, chips, iconos, etc -->
			    <template v-slot:item.estatus="{ item }">
			      <v-chip 
			      	v-if="item.estatus == 1"
			      	small
			      	color="green"
			      	dark
			      >Activo</v-chip>

			      <v-chip 
			      	v-else
			      	small
			      	color="error"
			      	dark
			      >Inactivo</v-chip>
			    </template>

			    <template v-slot:item.escuela="{ item }">
			      <v-chip small color="colorinbi" dark v-if="item.escuela == 1">INBI</v-chip>
			      <v-chip small color="colorfast" dark v-if="item.escuela == 2">FAST</v-chip>
			    </template>

			    <template v-slot:item.actions="{ item }">
			      <v-icon
			        small
			        class="mr-2"
			        color="primary"
			        @click="editItem(item)"
			      >
			        mdi-pencil
			      </v-icon>
			      <v-icon
			        small
			        @click="deleteItem(item)"
			        color="error"
			      >
			        mdi-delete
			      </v-icon>
			    </template>

			    <template v-slot:no-data>
			      <v-btn
			        color="primary"
			        @click="initialize"
			        small
			      >
			        Actualizar
			      </v-btn>
			    </template>
			  </v-data-table>
	  	</v-col>
	  </v-row>


		<!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div style="display: table-cell; vertical-align: middle;">
		      				<strong>¡Bien!</strong> Datos grabados correctamente
			      			<v-card-actions class="pt-6">
			      				<v-spacer></v-spacer>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="green" dark class="elevation-6" large @click="dialogSuccess = false">Ok</v-btn>
			      				<v-spacer></v-spacer>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/correcto.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="300"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      persistent
      max-width="600"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div style="display: table-cell; vertical-align: middle;">
		      				<span v-if="errorBack">
		      					<strong>ERROR</strong> Da clic en "COPIAR" para guardar el error y enviarlo a sistemas
		      				</span>
		      				<span v-else>
		      					<strong>ERROR</strong> {{error}}
		      				</span>
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text large class="mr-2" @click="dialogError = false">Cerrar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="copiarPortapapeles()" v-if="errorBack">{{textoCopiado}}</v-btn>
			      			  <v-btn color="#D60F24" dark class="elevation-6" large @click="dialogError = false" v-else>Ok</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/eliminar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

	</v-container>
</template>
<script>
import axios from 'axios';
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ID'         , value: 'idfrecuencias' },
        { text: 'Frecuencia' , value: 'frecuencia'    },
        { text: 'Modalidad'  , value: 'modalidad'     },
        { text: 'Curso'      , value: 'curso'         },
        { text: 'Estatus'    , value: 'estatus'       },
        { text: 'Escuela'    , value: 'escuela'       },
        { text: 'Actions'    , value: 'actions', sortable: false },
      ],
      frecuencias: [],
      editedIndex: -1,

      editedItem: {
        idfrecuencias: '',
        modalidad: '',
        idcursos_escuela:'',
        estatus: '',
        escuela:0,
        lunes:0,
        martes:0,
        miercoles:0,
        jueves:0,
        viernes:0,
        sabado:0,
        domingo:0,
        idmodalidad_cursos:0,
      },

      defaultItem: {
        idfrecuencias: '',
        modalidad: '',
        idcursos_escuela:'',
        estatus: '',
        escuela:0,
        lunes:0,
        martes:0,
        miercoles:0,
        jueves:0,
        viernes:0,
        sabado:0,
        domingo:0,
        idmodalidad_cursos:0,
      },

      dialogSuccess:false,
      overlay: false,
      dialogError: 	false,
			error: 				'',
			successMensaje:'',
			errorBack:false,
      textoCopiado:'COPIAR',

      cursos:[],
      curso: null,
      cursosEscuela:[],
      modalidades:[],
      modalidad:null,
      modalidadEscuela:[]
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar modalidad' : 'Editar modalidad'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      "editedItem.escuela"( val){
      	this.cursosEscuela = this.cursos.filter(el=> { return el.escuela == val})
      },

      "editedItem.idcursos_escuela"( val){
      	this.modalidadEscuela = this.modalidades.filter(el=> { return el.idcursos_escuela == val})
      	console.log(this.modalidadEscuela)
      }
    },

    async created () {
      await this.initialize()
      await this.getCursos()
      await this.getModalidades()
    },

    methods: {
      initialize () {
        this.frecuencias = []
        this.overlay = true
        return this.$http.get('escuela.frecuencias.list').then(response=>{
        	this.frecuencias = response.data
        	this.overlay = false
        }).catch(error=>{
        	// INdicamos que hay un error en el back
        	if(error.body.message){
        		this.error = error.body.message
        	}else{
        		this.error = error.body
        	}
        	this.errorBack = true
        	this.overlay = false
        	this.dialogError = true
        })
      },

      getCursos(){
      	this.cursos = []
        this.overlay = true
        return this.$http.get('escuela.cursos.activos').then(response=>{
        	this.cursos = response.data
        	this.overlay = false
        }).catch(error=>{
        	// INdicamos que hay un error en el back
        	if(error.body.message){
        		this.error = error.body.message
        	}else{
        		this.error = error.body
        	}
        	this.errorBack = true
        	this.overlay = false
        	this.dialogError = true
        })
      },

      getModalidades(){
      	this.modalidades = []
        this.overlay = true
        return this.$http.get('escuela.modalidades.activos').then(response=>{
        	this.modalidades = response.data
        	this.overlay = false
        }).catch(error=>{
        	// INdicamos que hay un error en el back
        	if(error.body.message){
        		this.error = error.body.message
        	}else{
        		this.error = error.body
        	}
        	this.errorBack = true
        	this.overlay = false
        	this.dialogError = true
        })
      },

      editItem (item) {
        this.editedIndex = this.frecuencias.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.frecuencias.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        // Lo mandapos por el EP
        this.editedItem.deleted = 1
        this.$http.put('escuela.frecuencias.update/' + this.editedItem.idfrecuencias, this.editedItem).then(response=>{
        	this.dialogSuccess = true
        	this.overlay = false
        	this.closeDelete()
        	this.initialize()
        }).catch(error=>{
        	// INdicamos que hay un error en el back
        	if(error.body.message){
        		this.error = error.body.message
        	}else{
        		this.error = error.body
        	}
        	this.errorBack = true
        	this.overlay = false
        	this.dialogError = true
        })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	if(this.editedItem.frecuencia == ''){
      		this.error     		= 'Favor de llenar todos los campos'
        	this.errorBack 		= false
        	this.overlay   		= false
	        this.dialogError 	= true
	        return
      	}
        if (this.editedIndex > -1) {
          // Activamos el loader
	        this.overlay = true
	        // Lo mandapos por el EP
          this.$http.put('escuela.frecuencias.update/' + this.editedItem.idfrecuencias, this.editedItem).then(response=>{
          	this.dialogSuccess = true
          	this.initialize()
	        	this.overlay = false
	        }).catch(error=>{
	        	// INdicamos que hay un error en el back
	        	if(error.body.message){
	        		this.error = error.body.message
	        	}else{
	        		this.error = error.body
	        	}
	        	this.errorBack = true
	        	this.overlay = false
	        	this.dialogError = true
	        })

        } else {
        	// Activamos el loader
	        this.overlay = true
	        // Lo mandapos por el EP
          this.$http.post('escuela.frecuencias.add', this.editedItem).then(response=>{
          	this.dialogSuccess = true
          	this.initialize()
	        	this.overlay = false
	        }).catch(error=>{
	        	// INdicamos que hay un error en el back
	        	if(error.body.message){
	        		this.error = error.body.message
	        	}else{
	        		this.error = error.body
	        	}
	        	this.errorBack = true
	        	this.overlay = false
	        	this.dialogError = true
	        })
        }
        this.close()
      },

      copiarPortapapeles () {
        navigator.clipboard.writeText(this.error).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      }
    },
  }
</script>


