<template>
  <v-container class="mt-5 ml-2">
    <v-row>
      <v-col cols="12">
        <v-card-title primary-title>
        	<!-- Buscador -->
          <v-text-field
            dense
            outlined
            rounded
            hide-details
            label="Búscar"
            v-model="search"
            append-icon="mdi-search"
          ></v-text-field>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <!-- agregar categoria -->
          <v-dialog v-model="closeDialogDepa" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                rounded
                small
              >Nuevo departamento</v-btn>
            </template>

            <!-- Card editar el departamento -->
            <v-card>
          	  <v-card-title primary-title>
          	    Departamento
          	  </v-card-title>
              <v-card-text>
                <v-text-field 
                	v-model="editarDepa.departamento" 
                	label="Nombre"
                	outlined
                	dense
                	hide-details
                >
                </v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" small text @click="closeDialogDepa = false">Cerrar</v-btn>
                <v-btn color="blue darken-1" small text @click="addDepartamento()">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
      </v-col>

      <!-- Catalago en forma de carts -->
      <v-col cols="12" sm="6" lg="4" v-for="(a, i) in filterDepartamento" :key="i">
        <v-card class="elevation-12">
          <v-row>
            <v-col cols="12">
              <v-card-text align="center" class="pa-0 pt-2">
               <h2> <b>{{a.departamento}}</b><br/></h2>
              </v-card-text>
              <v-card-title primary-title>
                Puestos 
                <v-btn 
                	color="blue" 
                	icon 
                  @click="addPuesto(a)"
                 >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <!-- Puestos del departamento -->
              <v-data-table
                :loading="loading"
                :headers="headerPuesto"
                :items="a.puestos"
                fixed-header
                :height="300"
                hide-default-footer
                loading-text="Cargando..."
                dense
                :items-per-page="100"
                :mobile-breakpoint="250"
                >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    color="blue"
                    small
                    class="mr-2"
                    @click="editPuesto(item), idSelectCatego = item.idcatego"
                  >
                    mdi-pencil
                  </v-icon>

                  <v-icon
                    color="red"
                    small
                    @click="dialog3 = true, itemDeletePuesto = item"
                  >
                    mdi-delete
                  </v-icon>

                </template>
                </v-card-title>
              </v-data-table>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="yellow" small  @click="editarDepaF(a)" rounded>Editar<v-icon right>mdi-pencil</v-icon></v-btn>
            <v-btn color="error" small @click="closeDialogDeleteDepa = true, itemDelete = a" rounded>Eliminar<v-icon right>mdi-delete</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Eliminar una categoría -->
    <v-row justify="center">
      <v-dialog v-model="closeDialogDeleteDepa" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Eliminar departamento</v-card-title>
          <v-card-text>¿Seguro que deseas eliminar el departamento?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" small text @click="closeDialogDeleteDepa = false">Cancelar</v-btn>
            <v-btn color="green darken-1" small text @click="deleteDepa()">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Editar o agregar un puesto -->
    <v-row justify="center">
      <v-dialog v-model="closeDialogPuesto" max-width="500px">
        <v-card>
        	<v-card-title primary-title>
        	  Puesto
        	</v-card-title>
          <v-card-text>
            <!-- return-object -->
            <v-text-field 
            	v-model="editedDetallePuesto.puesto" 
            	label="Puesto"
            	dense
            	outlined
            ></v-text-field>

            <v-select
              outlined
              dense
              clearable
              v-model="nivelOrg"
              :items="nivelesOrg"
              label="Selecciona un nivel"
              persistent-hint
              item-text="nivel"
              item-value="idorganigrama_niveles"
              prepend-inner-icon="mdi-account-box"
              hide-details
              return-object
            >
            </v-select>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" rounded dark text small @click="closeDialogPuesto=false">cerrar</v-btn>
            <v-btn color="blue darken-1" rounded dark text small @click="guardarPuesto()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Elimnar un detalle -->
    <v-row justify="center">
      <v-dialog v-model="dialog3" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Eliminar Puesto</v-card-title>
          <v-card-text>¿Deseas eliminar el puesto?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" small text @click="dialog3 = false">Cancelar</v-btn>
            <v-btn color="green darken-1" small text @click="deletePuesto()">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </v-container>
</template>

<script>
import axios from 'axios';
	var moment = require('moment');
  moment.locale(); 

  export default {
    data: () => ({
    	// Datos del departamento
    	closeDialogDepa: false,
    	iddepartamento:0,
    	fehcaHoy:'',

    	closeDialogPuesto: false,

      idSelectCatego:0,
      itemDeletePuesto:'',
      metodoDescrip: 0,


      idCatego:0,
      mensaje:'',
      dialog3: false,
      dialog4:false,
      loading:true,
      idSelectArt:0,
      closeDialogDeleteDepa: false,


    	loading:true,
    	itemDelete: {},
      dialog: false,
      headerPuesto: [
        { text: 'ID'    ,   align: 'start', value: 'idpuesto'},
        { text: 'Puesto',   align: 'start', value: 'puesto'},
        { text: 'Nivel',    align: 'start', value: 'nivel'},
        { text: 'Acciones', align: 'start', value: 'actions'},
      ],
      departamentos: [],
      editedIndex: -1,
      editarDepa: {
        iddepartamento: 0,
      	departamento:'',
      	usuario_registro: 1,
      	fecha_creacion:'',
      	fecha_actualizo:'',
      	deleted: 0,
      },

      editedDetallePuesto: {
        idpuesto:0,
        iddescripcion: 0,
        puesto: '',
        usuario_registro: 1,
        fecha_creacion:'',
        fecha_actualizo:'',
        deleted: 0,
        idorganigrama_niveles:0,
        nivel:0,
      },


      defaultItem: {
        idpuesto: 0,
        iddescripcion: 0,
        puesto: '',
        usuario_registro: 1,
        fecha_creacion:'',
        fecha_actualizo:'',
        deleted: 0,
        idorganigrama_niveles:0,
        nivel:0,
      },
      search:'',
      longitud: 100,
      nivelesOrg:[],
      nivelOrg:{}
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva clasificación' : 'Editar clasificación'
      },
    },

    watch: {
      closeDialogPuesto (val) {
        val || this.close()
      },
    },

    created () {
    	// Generando la fecha de hoy
    	const fecha = new Date().toLocaleDateString("en-Es", {
				timeZone: "America/Mexico_City"
			});
    	this.fecha = moment(fecha).format().substr(0,10)
      this.initialize()
    },

    methods: {

      initialize () {
        this.getOrganigrama()
        this.departamentos = []
        this.$http.get('departamentos.all').then(response=>{
          // Traer todos los puestos
          this.$http.get('puestos.all').then(responsePuestos=>{
            // Hacemos un ciclo para obtener los datos de los departamentos
            for(const i in response.data){
              var data = {
                iddepartamento:     response.data[i].iddepartamento,
                departamento:       response.data[i].departamento,
                usuario_registro:   response.data[i].usuario_registro,
                fecha_creacion:     response.data[i].fecha_creacion.substr(0,10),
                fecha_actualizo:    response.data[i].fecha_actualizo.substr(0,10),
                deleted:            response.data[i].deleted,
                puestos:[]
              }
              // Lo agregamos a departamentos
              // Hacemos un ciclo a puestos para validar que puesto le pertenece a que departamento
              for(const j in responsePuestos.data){
                if(response.data[i].iddepartamento == responsePuestos.data[j].iddepartamento){
                  responsePuestos.data[j].fecha_creacion  = responsePuestos.data[j].fecha_creacion.substr(0,10)
                  responsePuestos.data[j].fecha_actualizo = responsePuestos.data[j].fecha_actualizo.substr(0,10)
                  data.puestos.push(responsePuestos.data[j])
                }
              }
              this.departamentos.push(data)
            }
            
            this.longitud = this.departamentos.length
            this.loading = false
            // Traer los puestos
          }).catch(error=>{console.log(error)})
        }).catch(error=>{console.log(error)})
      },

      getOrganigrama () {
        this.$http.get('usuarios.niveles.all').then(response=>{
          this.nivelesOrg = response.data
        }).catch(error=>{console.log(error);}).finally(()=>{})
      },

      // Agregar el puesto que pertenecerá a ese departamento
      addPuesto(value){
      	this.iddepartamento = value.iddepartamento
        this.metodoDescrip = 0
      	this.closeDialogPuesto = true
        // Limpiamos los datos
        this.editedDetallePuesto =  {
          idpuesto:0,
          iddescripcion: 0,
          puesto: '',
          usuario_registro: 1,
          fecha_creacion:'',
          fecha_actualizo:'',
          deleted: 0,
          idorganigrama_niveles:0,
          nivel:0,
        }
        this.nivelOrg = {idorganigrama_niveles: 0, nivel: 0}
      },

      // Editar el departamento, recibe el parametro
      editarDepaF (item) {
      	// Generamos un indexof para buscar el objeto dentro del arreglo de los departamentos
        this.editedIndex = this.departamentos.indexOf(item)
        this.editarDepa = Object.assign({}, item)
        this.closeDialogDepa = true // abrimos el cuadro de dialogo
      },

      // Eliminar el departament
      deleteDepa () {
      	// Mandmos el id del departamento a eliminar
        // Cambiamos el estatus a eliminado
        this.itemDelete.deleted = 1
        this.$http.put('departamentos.update/' + this.itemDelete.iddepartamento,this.itemDelete).then(response=>{
          this.closeDialogDeleteDepa = false
        	this.initialize()
        }).catch(error=>console.log(error))
      },

      close () {
        this.closeDialogPuesto = false
      },

      addDepartamento () {
      	// Validamos que es lo que haremos, si editar o agregar
        if (this.editedIndex > -1) {
        	// Actualizar departamento
        	this.$http.put('departamentos.update/' + this.editarDepa.iddepartamento, this.editarDepa).then(response=>{
        		// Reiniciamos
            this.closeDialogDepa = false
	        	this.initialize()
	        }).catch(error=>{console.log(error)})

	      // Agregamos el departamento
        } else {
        	// Editamos algunos campos que vamos a utilizar que son las fechas
        	this.editarDepa.fecha_creacion = this.fecha
        	this.editarDepa.fecha_actualizo = this.fecha
          this.$http.post('departamentos.add',this.editarDepa).then(response=>{
	        	// Cerramos el cuadro de dialogo
	        	this.closeDialogDepa = false
	        	this.initialize()
	        }).catch(error=>{console.log(error)})
        }
        this.close()
      },

      // Guardamos el puesto del departamento
      guardarPuesto(){
        this.editedDetallePuesto.idorganigrama_niveles = this.nivelOrg.idorganigrama_niveles
        if (this.metodoDescrip == 2) {
          this.$http.put('puestos.update/' + this.editedDetallePuesto.idpuesto, this.editedDetallePuesto).then(response=>{
            this.closeDialogPuesto = false
            this.editedDetallePuesto = this.defaultItem
            this.metodoDescrip = 0
            this.initialize()
          }).catch(error=>{console.log(error)})
        } else {
          // Le asignamos su id de departamento
          this.editedDetallePuesto.iddepartamento = this.iddepartamento
          // Actualizamos las fechas
          this.editedDetallePuesto.fecha_creacion = this.fecha
          this.editedDetallePuesto.fecha_actualizo = this.fecha
          // Agregamos
          this.$http.post('puestos.add',this.editedDetallePuesto).then(response=>{
            this.closeDialogPuesto = false
            this.editedDetallePuesto = this.defaultItem
            this.metodoDescrip = 0
            this.initialize()
          }).catch(error=>{console.log(error)})
        }
      },

      editPuesto(value){
        this.editedDetallePuesto = Object.assign({}, value)
        this.closeDialogPuesto = true
        this.nivelOrg = {idorganigrama_niveles: this.editedDetallePuesto.idorganigrama_niveles, nivel: this.editedDetallePuesto.nivel}
        this.metodoDescrip = 2
      },

      deletePuesto(){
        console.log(this.itemDeletePuesto)
        this.itemDeletePuesto.deleted = 1
        this.$http.put('puestos.update/' + this.itemDeletePuesto.idpuesto, this.itemDeletePuesto).then(response=>{
          this.dialog3 = false
          this.initialize()
        }).catch(error=>console.log(error))
      },

    },

    computed:{
      filterDepartamento: function(){
        var me = this
        if(this.departamentos){
          return this.departamentos.filter((art)=>{
            var nom = art.departamento.toLowerCase();
            return nom.match(this.search.toLowerCase());
          })
        }
      },
    }
  }
</script>
