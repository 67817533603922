<template>
  <v-container class="mt-4 px-5" fluid>
  	<v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title >
            <span class="text-subtitle-1">Comentarios Alumnos</span>

            <v-spacer></v-spacer>
            
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( filterAlumnos  , 'COMENTARIOS')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="consultar()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

          </v-card-title>
          <v-card-text>

            <v-row justify="space-between">
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-select
                  :items="planteles"
                  v-model="plantel"
                  label="Plantel"
                  item-value="idplantel"
                  item-text="plantel"
                  filled
                  dense
                  clearable
                  return-object
                  hide-details
                ></v-select>
              </v-col>
              
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  v-model="search"
                  filled
                  dense
                  label="Buscar"
                  append-icon="mdi-magnify"
                  hide-details
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="12" sm="4">
                <span># ALUMNOS POR CICLO: {{ alumnos.length }}</span> <br>
                <span># ALUMNOS SIN COMENTARIO: {{ alumnosSinComentario }}</span> <br>
                <span># ALUMNOS CON COMENTARIO RECIENTE: {{ alumnosComentarioReciente }}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="filterAlumnos"
                  dense
                  class="elevation-0"
                  :search="search"
                >
                  <template v-slot:no-data>
                    <v-btn
                      color="primary"
                      @click="consultar()"
                      small
                    >
                      Actualizar
                    </v-btn>
                  </template>
    
                  <template v-slot:item.id_unidad_negocio="{ item }">
                    <v-chip v-if="item.id_unidad_negocio == 1 "  small color="#2EDAD5"  dark> INBI  </v-chip>
                    <v-chip v-if="item.id_unidad_negocio == 2 "  small color="#000D4A"  dark> FAST  </v-chip>
                  </template>
    
                  <template v-slot:item.comentarios="{ item }">
                    <v-btn  small color="primary" tile dark @click="verComentarios( item )"> comentarios  </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            
          </v-card-text>

  			  <div class="text-center">
  		      <v-dialog
  		        v-model="dialogCarga"
  		        hide-overlay
  		        persistent
  		        width="500"
  		      >
  		        <v-card
  		          color="primary"
  		          dark
  		        >
  		          <v-card-text class="pt-2">
  		            Cargando, porfavor espera...
  		            <v-progress-linear
  		              indeterminate
  		              color="white"
  		              class="mb-0"
  		            ></v-progress-linear>
  		          </v-card-text>
  		        </v-card>
  		      </v-dialog>
  		    </div>
        </v-card>
  		</v-col>
  	</v-row>

    <v-dialog
      v-model="dialogcomentarios"
      persistent
      max-width="800"
    >
      <v-card
        style="position: relative"
      >
        <v-card-title primary-title >
          Comentarios
        </v-card-title>
        <v-card-text>
          <span v-for="(mensaje, i) in mensajes">
            <v-row v-if="mensaje.id_usuarioerp != getdatosUsuario.iderp">
              <v-col cols="12">
                <v-card class="ml-6 pa-3 mt-2" max-width="400" color="#f2f2f2" style="border-radius: 15px; border-top-left-radius: 0">
                  <v-card-text class="pa-0">
                    <div class="text-caption blue--text">{{ mensaje.usuario }}</div>
                    <div class="text-caption blue--text">{{ mensaje.perfil }}</div>
                  </v-card-text>
                  <div class="text-subtitle-1">{{ mensaje.comentario }} </div>
                  <v-card-text class="pa-0">
                    <div class="text-caption blue--text">{{ mensaje.grupo }}</div>
                    <div class="text-caption blue--text">{{ mensaje.fecha_creacion }}</div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="mensaje.id_usuarioerp == getdatosUsuario.iderp">
              <v-col cols="12" align="right">
                <v-card class="mr-6 mt-2 pa-2" max-width="400" dark color="#0465ac" shaped style="border-radius: 15px; border-top-right-radius: 0;">
                  <div class="text-subtitle-1">{{ mensaje.comentario }}</div>
                  <v-card-text class="pa-0">
                    <div class="text-caption white--text">{{ mensaje.grupo }}</div>
                    <div class="text-caption white--text">{{ mensaje.fecha_creacion }}</div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </span>
        </v-card-text>

        <v-card-text class="pb-6 pt-6">
          <v-text-field
            v-model="mensaje"
            :append-outer-icon="mensaje ? 'mdi-send' : ''"
            outlined
            clearable
            label="Escribir..."
            type="text"
            @click:append-outer="enviarMensaje()"
            hide-details
            dense
          ></v-text-field>
        </v-card-text>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogcomentarios = false, mensaje = ''"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'

	import { mapGetters, mapActions } from 'vuex'

  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      snackbar:false,
      headers: [
        { text: 'Matricula' 		, value: 'usuario' },
        { text: 'Alumno'    		, value: 'alumno' },
        { text: 'Grupo'     		, value: 'grupo' },
        { text: 'Escuela'       , value: 'id_unidad_negocio' },
        { text: 'Teacher'       , value: 'cantTeacher' },
        { text: 'Supervisora'   , value: 'cantSupervi' },
        { text: 'Recepción'     , value: 'cantRecep' },
        { text: 'Ventas'        , value: 'cantVentas' },
        { text: 'Admin'     		, value: 'cantAdmin' },
        { text: 'Comentarios'   , value: 'comentarios' },
      ],

      alumnos: [],
      ciclos:[],
      cicloInf:null,
      resultado:[],
      search:'',
      dialogCarga: false,
      dialogSuccess:false,
      overlay: false,
      dialogError: 	false,
			error: 				'',
			successMensaje:'',
			errorBack:false,
      textoCopiado:'COPIAR',

      dialogcomentarios:false,
      mensajes:[],
      mensaje:'',
      planteles:[],
      plantel:null,
      alumnosSinComentario: 0,
      alumnosComentarioReciente: 0,
    }),


    async created () {
      await this.consultar()
      await this.getSucursales()
    },

    methods: {
      consultar () {
        this.alumnosSinComentario = 0
        this.alumnosComentarioReciente = 0
      	this.cargar = true
      	this.alumnos = []
      	return this.$http.get(`operaciones.alumnos`).then(response=>{
          this.alumnos = response.data.respuestaFinal
          this.alumnosSinComentario = this.alumnos.filter(el=> el.cantTeacher == 0 && el.cantRecep == 0 && el.cantSupervi == 0 && el.cantVentas == 0 && el.cantAdmin == 0).length
          this.alumnosComentarioReciente = response.data.comentarioReciente
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getSucursales(){
        this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.all').then(response=>{
          this.planteles = response.data
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // Sirve para cargar las notas del ticket
      verComentarios ( payload ) {
        this.alumno = payload
        this.cargar  = true
        this.mensajes = []

        this.$http.get(`operaciones.comentario/${ payload.id_alumno }/${ payload.id_unidad_negocio }`).then(response=>{
          this.mensajes        		= response.data
          this.cargar         		= false
          this.dialogcomentarios  = true
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // Subir la nota del ticket
      enviarMensaje () {
        if(this.mensaje != '' && this.mensaje != null){
          // this.cargar = true
          let payload = {
            id_alumno:          this.alumno.id_alumno,
            id_usuarioerp:      this.getdatosUsuario.iderp,
            id_grupo:           this.alumno.id_grupo,
            id_ciclo:           this.alumno.id_ciclo,
            comentario:         this.mensaje,
            idperfil:           this.getdatosUsuario.idpuesto,
            unidad_negocio:     this.alumno.id_unidad_negocio,
            grupo:              this.alumno.grupo,
            perfil:             this.getdatosUsuario.puesto
          }

          this.$http.post('operaciones.comentario',payload).then(response=>{
            this.mensaje  = ''
            this.mensajes = []
            this.verComentarios( this.alumno )
            this.cargar = false
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }else{
          this.validarErrorDirecto( 'Ingresa un mensaje' )
          this.dialogRechazar = false
        }
      }, 
    },

    computed: {
    	...mapGetters('login', ['getdatosUsuario']),

      filterAlumnos () {
        if(this.alumnos){
          return this.alumnos.filter((alumno)=>{
            var id_plantel = alumno.id_plantel;
            if(this.plantel){
              if(id_plantel == this.plantel.idplantel){return true}
            }else{
              return true
            }
          })
        }
      },
    }
  }
</script>