<template>
	<div>
		<v-card>
      <v-card-title>
        <v-card width="80" class="elevation-0 transparent">
          <v-img 
          	v-if="escuela == 2"
            src="@/assets/logo_fast.png"
          ></v-img>

          <v-img 
          	v-else
            src="@/assets/logo_inbi.png"
          ></v-img>

        </v-card>
        <span class="text-subtitle-1">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="editedItem.nombre_completo"
          label="Nombre completo"
          filled
          dense
          autofocus
        ></v-text-field>

        <v-autocomplete
          filled
          dense
          clearable
          v-model="editedItem.idmedio_contacto"
          :items="medios_contacto"
          label="Selecciona una campaña"
          persistent-hint
          item-value="idmedio_contacto"
          item-text="medio"
        >
        </v-autocomplete>

        <v-checkbox v-model="editedItem.respondio" label="Respondió?" @change="respondio(item)"></v-checkbox>


        <!-- <v-autocomplete
          filled
          dense
          clearable
          v-model="editedItem.idcampanias"
          :items="campaniasFilter"
          label="Selecciona la campaña"
          persistent-hint
          item-value="idcampanias"
          item-text="campania"
        >
        </v-autocomplete>

        <v-autocomplete
          filled
          dense
          clearable
          v-model="editedItem.idfuentes"
          :items="fuentes"
          label="¿Cómo nos contactó?"
          item-value="idfuentes"
          item-text="fuente"
        >
        </v-autocomplete>

        <v-autocomplete
          filled
          dense
          clearable
          v-model="editedItem.iddetalle_fuentes"
          :items="detallesFilter"
          label="Detalle"
          persistent-hint
          item-value="iddetalle_fuentes"
          item-text="detalle_fuente"
        >
        </v-autocomplete> -->

            <!-- <v-col cols="12" class="pb-0">
              <v-select
                :items="cursos_escuela"
                v-model="editedItem.idcursos_escuela"
                item-text="curso"
                item-value="idcursos_escuela"
                label="Curso interes"
                hide-details
                filled
                dense
              ></v-select>
            </v-col> -->

          <v-select
            v-if="escuela == 1"
            :items="[{id:1, nom: 'ANAHUAC'},{id:2, nom:'Miguel Aleman'},{id:3,nom:'Pablo Livas'},{id:4,nom:'San Miguel'},{id:5,nom:'Casa blanca'},{id:6,nom:'Fresnos'},{id:17,nom:'INBI ONLINE'},{id:21,nom:'APODACA CENTRO'},{id:22,nom:'LINCOLN'},{id:23,nom:'ESCOBEDO'},{id:24,nom:'SANTA CATARINA'},{id:25,nom:'MONTEVIDEO'},{id:27,nom:'ARAGON'},{id:28,nom:'MISTERIOS'},{id:29,nom:'TRANSITO'},{id:31,nom:'CUITLAHUAC'},{id:32,nom:'APODACA MM'},{id: 10002, nom: 'EL ROSARIO'},{id: 10003, nom: 'BALBUENA'},{id: 10004, nom: 'REVOLUCION'}]"
            v-model="editedItem.id_sucursal_interes"
            item-text="nom"
            item-value="id"
            label="Sucursal de intéres"
            filled
            dense
          ></v-select>

          <v-select
            v-else
            :items="[{id:7, nom: 'FAST_APODACA'},{id:8, nom:'FAST_NORIA'},{id:9,nom:'FAST_RG'},{id:10,nom:'FAST_LV'},{id:11,nom:'FAST_ELOY'},{id:12,nom:'FAST_MTR'},{id:14,nom:'FAST_UNI'},{id:16,nom:'FAST ONLINE'}]"
            v-model="editedItem.id_sucursal_interes"
            item-text="nom"
            item-value="id"
            label="Sucursal de intéres"
            filled
            dense
          ></v-select>

            <!-- <v-col cols="12" class="pb-0">
              <v-switch label="Foraneo" v-model="editedItem.foraneo" hide-details></v-switch>
            </v-col> -->

        <v-subheader class="mb-0 pb-0 mt-6">Información de contacto</v-subheader>
        <v-text-field
          v-model="editedItem.telefono"
          label="Teléfono"
          filled
          type="number"
          dense
          @keyup.enter="buscarTelefono( editedItem.telefono )"
        ></v-text-field>

        <v-select
          :items="vendedoras_plantel"
          v-model="editedItem.sucursal"
          return-object
          item-text="nombre_plantel"
          item-value="nombre_plantel"
          label="Sucursal"
          filled
          dense
        ></v-select>

        <v-row>
          <v-col cols="12" md="6">
            <label>Horario de interés</label>
            <v-radio-group v-model="editedItem.horario_interes">
              <v-radio
                label="Mañana"
                value="Mañana"
              ></v-radio>
              <v-radio
                label="Tarde"
                value="Tarde"
              ></v-radio>
              <v-radio
                label="Noche"
                value="Noche"
              ></v-radio>
              <v-radio
                label="Fin de semana"
                value="Fin de semana"
              ></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" md="6">
            <label>Curso de interés</label>
            <v-radio-group v-model="editedItem.curso_interes">
              <v-radio
                v-for="(curso, i) in cursos_escuela"
                :key="i"
                :label="curso.curso"
                :value="curso.idcursos_escuela"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-textarea
          v-model="editedItem.nota_inicial"
          filled
          dense
          rows="3"
          label="Nota inicial"
        > 
        </v-textarea>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="black"
          dark
          @click="close"
          tile
          small
        >
          <v-icon small left>mdi-cancel</v-icon>
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          tile
          small
          @click="save"
        >
          <v-icon left small>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
	</div>
</template>
<script>
import axios from 'axios';
	export default {
		props:[
			'formTitle',
			'editedItem',
			'cursos_escuela',
			'vendedoras_plantel',
			'fuentes',
			'detallesFilter',
			'medios_contacto',
			'campaniasFilter',
			'escuela'
	  ],

	  methods:{
		  save( ){
		  	this.$emit('save', this.editedItem )
		  },

		  close( ){
		  	this.$emit('close', this.editedItem )
		  },

		  buscarTelefono( telefono ){
		  	this.$emit('buscarTelefono', telefono )
		  }

	  }
	}
</script>