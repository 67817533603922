<template>
  <v-container fluid class="mt-2 pa-6">

  	<!-- Barra superior -->
  	<v-row>
  		<v-col cols="12">
	  		<v-card class="back-dashboard-personal">
          <v-img
            class="white--text align-end"
          />
          <v-card-text class="position-relative">
          	<div class="d-flex flex-no-wrap ">
          		<v-avatar
	              size="120"
	              color="transparent"
	              class="avatar-center"
	            >
	              <v-img src="@/assets/asistente/kpi.png"></v-img>
	            </v-avatar>

              <div>
                <v-card-title
                  class="text-h5 white--text"
                >
              		<!-- Hola, {{ getdatosUsuario.nombre_usuario + " " + getdatosUsuario.apellido_usuario }}  -->
              		Hola, {{ getdatosUsuario.nombre_usuario }} !! 
              	</v-card-title>
                <v-card-subtitle class="text-h6 white--text pb-0">Bienvenido a tu panel de control</v-card-subtitle>
              </div>
            </div>
          </v-card-text>
        </v-card>
  		</v-col>
  	</v-row>

  	<!-- Desglose dle Dashboard -->
    <v-row>

      <!-- RI reingreso -->
      <v-col cols="12" md="6" lg="4" class="py-0">
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text">% RI (Reingreso)</span>
			      <v-spacer></v-spacer>
			      <v-btn icon small class="me-n3 mt-n2">
			        <v-icon>
			          mdi-download
			        </v-icon>
			      </v-btn>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
			    	<span v-if="recepcionista" >
			      	<v-row v-for="(plantel , i) in recepcionista.sucursales" :key="i">
			      		<v-col cols="8" class="pt-0">
			      			<!-- Porcentaje y meta actual -->
						      <v-subheader class="pl-0 pt-0">Actualidad</v-subheader>
			      			<div class="d-flex align-center">
						        <h1 class="text-4xl font-weight-semibold red--text">
						          %{{ plantel.riPlantel.porcentajeRi }}
						        </h1>
						      </div>
						      <span class="text-base font-weight-medium pl-3 ms-n2"> {{ plantel.riPlantel.plantel }}</span>
			      		</v-col>

			      		<!-- Meta -->
			      		<v-col cols="4" class="pt-0">
			      			<v-subheader class="pl-0">Meta</v-subheader>
					        <h1 class="text-4xl font-weight-semibold grey--text">
					          {{ getMetaRI( plantel.riPlantel.id_plantel ) }} %
					        </h1>
			      		</v-col>

			      	</v-row>
			    	</span>

			      <div class="mt-4 font-weight-medium">
			        <v-autocomplete
			          label="Personal"
			          filled
			          item-text="nombre_completo"
			          item-value="idusuario"
			          v-model="recepcionista"
			          :items="recepcionistas"
			          dense
			          hide-details
			          return-object
			        ></v-autocomplete>
			      </div>
			    </v-card-text>
			  </v-card>
  		</v-col>

  		<!-- Ventas -->
      <v-col cols="12" md="6" lg="4" class="py-0">
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text"># Ventas</span>
			      <v-spacer></v-spacer>
			      <v-btn icon small class="me-n3 mt-n2 mr-8" @click="verVentasVendedora()">
			        <v-icon>
			          mdi-information-outline
			        </v-icon>
			      </v-btn>
			      <v-btn icon small class="me-n3 mt-n2 ml-4">
			        <v-icon>
			          mdi-download
			        </v-icon>
			      </v-btn>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
			    	<span v-if="vendedora" >
			      	<v-row>
			      		<v-col cols="8" class="pt-0">
			      			<!-- Porcentaje y meta actual -->
						      <v-subheader class="pl-0 pt-0">Actualidad</v-subheader>
			      			<div class="d-flex align-center">
						        <h1 class="text-4xl font-weight-semibold red--text">
						          # {{ vendedora.totalInscritos }}
						        </h1>
						      </div>
						      <span class="text-base font-weight-medium pl-3 ms-n2"> {{  }}</span>
			      		</v-col>

			      		<!-- Meta -->
			      		<v-col cols="4" class="pt-0">
			      			<v-subheader class="pl-0">Meta</v-subheader>
					        <h1 class="text-4xl font-weight-semibold grey--text">
					          # {{ getMetaVentas }}
					        </h1>
			      		</v-col>

			      	</v-row>
			    	</span>

			      <div class="mt-4 font-weight-medium">
			        <v-autocomplete
			          label="Vendedora"
			          filled
			          item-text="vendedora"
			          item-value="id_usuario"
			          v-model="vendedora"
			          :items="ventas"
			          dense
			          hide-details
			          return-object
			        ></v-autocomplete>
			      </div>
			    </v-card-text>
			  </v-card>
  		</v-col>

  		<!-- CONTACTOS -->
      <v-col cols="12" md="6" lg="4" class="py-0">
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text"># Contactos</span>
			      <v-spacer></v-spacer>
			      <v-btn icon small class="me-n3 mt-n2">
			        <v-icon>
			          mdi-download
			        </v-icon>
			      </v-btn>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
			    	<span v-if="contactVendedora" >
			      	<v-row>
			      		<v-col cols="8" class="pt-0">
			      			<!-- Porcentaje y meta actual -->
						      <v-subheader class="pl-0 pt-0">Actualidad</v-subheader>
			      			<div class="d-flex align-center">
						        <h1 class="text-4xl font-weight-semibold red--text">
						          #{{ contactVendedora.cantidad }}
						        </h1>
						      </div>
						      <span class="text-base font-weight-medium pl-3 ms-n2"> {{  }}</span>
			      		</v-col>

			      		<!-- Meta -->
			      		<v-col cols="4" class="pt-0">
			      			<v-subheader class="pl-0">Meta</v-subheader>
					        <h1 class="text-4xl font-weight-semibold grey--text">
					          # {{ getMetaContactos }}
					        </h1>
			      		</v-col>

			      	</v-row>
			    	</span>

			      <div class="mt-4 font-weight-medium">
			        <v-autocomplete
			          label="Personal"
			          filled
			          item-text="vendedora"
			          item-value="usuario_asignado"
			          v-model="contactVendedora"
			          :items="contactosVendedora"
			          dense
			          hide-details
			          return-object
			        ></v-autocomplete>
			      </div>
			    </v-card-text>
			  </v-card>
  		</v-col>

  		<!-- PROGRESO DESARROLLADORES -->
      <v-col cols="12" md="6" lg="4" class="py-0">
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text">Desarrolladores</span>
			      <v-spacer></v-spacer>
			      <v-btn icon small class="me-n3 mt-n2 ml-4">
			        <v-icon>
			          mdi-download
			        </v-icon>
			      </v-btn>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
			    	<span v-if="desarrollador && desarrollador" >
			      	<v-row>
			      		<v-col cols="8" class="pt-0">
			      			<!-- Porcentaje y meta actual -->
						      <v-subheader class="pl-0 pt-0">Actualidad</v-subheader>
			      			<div class="d-flex align-center">
						        <h1 class="text-4xl font-weight-semibold red--text">
						          # {{ desarrollador.actividades.realizadas }} de {{ desarrollador.actividades.numero_actividades }}
						        </h1>
						      </div>
						      <span class="text-base font-weight-medium pl-3 ms-n2"> {{  }}</span>
			      		</v-col>

			      		<!-- Meta -->
			      		<v-col cols="4" class="pt-0">
			      			<v-subheader class="pl-0">Meta</v-subheader>
					        <h1 class="text-4xl font-weight-semibold grey--text">
					          {{ getMetaDesarrollador }}%
					        </h1>
			      		</v-col>

			      		<v-col cols="12" class="px-0 py-0">
			      			<v-card-text class="text-center text-h3 pt-0">
							  	  <v-progress-linear
							  	  	height="20"
							  	  	:value="((desarrollador.actividades.realizadas / desarrollador.actividades.numero_actividades) * 100)"
							  	  	rounded
							  	  	color="green"
							  	  >
							  	  	<strong
							  	  		class="text-caption white--text"
							  	  	>{{ ((desarrollador.actividades.realizadas / desarrollador.actividades.numero_actividades) * 100).toFixed(2) }}%</strong>
							  	  </v-progress-linear>
							  	</v-card-text>
			      		</v-col>

			      	</v-row>
			    	</span>

			      <div class="mt-4 font-weight-medium">
			        <v-autocomplete
			          label="Desarrollador"
			          filled
			          item-text="nombre_completo"
			          item-value="iderp"
			          v-model="desarrollador"
			          :items="desarrolladores"
			          dense
			          hide-details
			          return-object
			        ></v-autocomplete>
			      </div>
			    </v-card-text>
			  </v-card>
  		</v-col>

  		<!-- PROGRESO ADMINISTRACION -->
      <v-col cols="12" md="6" lg="4" class="py-0">
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text">Administradores</span>
			      <v-spacer></v-spacer>
			      <v-btn icon small class="me-n3 mt-n2 ml-4">
			        <v-icon>
			          mdi-download
			        </v-icon>
			      </v-btn>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
			    	<span v-if="administrador && administrador" >
			      	<v-row>
			      		<v-col cols="8" class="pt-0">
			      			<!-- Porcentaje y meta actual -->
						      <v-subheader class="pl-0 pt-0">Actualidad</v-subheader>
			      			<div class="d-flex align-center">
						        <h1 class="text-4xl font-weight-semibold red--text">
						          # {{ administrador.actividades.realizadas }} de {{ administrador.actividades.numero_actividades }}
						        </h1>
						      </div>
						      <span class="text-base font-weight-medium pl-3 ms-n2"> {{  }}</span>
			      		</v-col>

			      		<!-- Meta -->
			      		<v-col cols="4" class="pt-0">
			      			<v-subheader class="pl-0">Meta</v-subheader>
					        <h1 class="text-4xl font-weight-semibold grey--text">
					          {{ getMetaAdministrador }}%
					        </h1>
			      		</v-col>

			      		<v-col cols="12" class="px-0 py-0">
			      			<v-card-text class="text-center text-h3 pt-0">
							  	  <v-progress-linear
							  	  	height="20"
							  	  	:value="((administrador.actividades.realizadas / administrador.actividades.numero_actividades) * 100)"
							  	  	rounded
							  	  	color="green"
							  	  >
							  	  	<strong
							  	  		class="text-caption white--text"
							  	  	>{{ ((administrador.actividades.realizadas / administrador.actividades.numero_actividades) * 100).toFixed(2) }}%</strong>
							  	  </v-progress-linear>
							  	</v-card-text>
			      		</v-col>

			      	</v-row>
			    	</span>

			      <div class="mt-4 font-weight-medium">
			        <v-autocomplete
			          label="Administrador"
			          filled
			          item-text="nombre_completo"
			          item-value="iderp"
			          v-model="administrador"
			          :items="administradores"
			          dense
			          hide-details
			          return-object
			        ></v-autocomplete>
			      </div>
			    </v-card-text>
			  </v-card>
  		</v-col>

  		<!-- PROGRESO VACANTES -->
      <v-col cols="12" md="6" lg="4" class="py-0">
	  		<v-card>
			    <v-card-title class="align-start pb-0">
			      <span class="grey--text">Vacantes</span>
			      <v-spacer></v-spacer>
			      <v-btn icon small class="me-n3 mt-n2 ml-4">
			        <v-icon>
			          mdi-download
			        </v-icon>
			      </v-btn>
			    </v-card-title>

			    <!-- Comparativa del %RI del ciclo -->
			    <v-card-text class="my-3">
			    	<span v-if="vacantesDisponibles" >
			      	<v-row>
			      		<v-col cols="8" class="pt-0">
			      			<!-- Porcentaje y meta actual -->
						      <v-subheader class="pl-0 pt-0">Actualidad</v-subheader>
			      			<div class="d-flex align-center">
						        <h1 class="text-4xl font-weight-semibold red--text">
						          # {{ vacantesDisponibles.vacantes_disponibles }} de {{ vacantesDisponibles.vacantes }}
						        </h1>
						      </div>
						      <span class="text-base font-weight-medium pl-3 ms-n2"> {{  }}</span>
			      		</v-col>

			      		<!-- Meta -->
			      		<v-col cols="4" class="pt-0">
			      			<v-subheader class="pl-0">Meta</v-subheader>
					        <h1 class="text-4xl font-weight-semibold grey--text">
					          {{ getMetaVacantes }}%
					        </h1>
			      		</v-col>

			      		<v-col cols="12" class="px-0 py-0">
			      			<v-card-text class="text-center text-h3 pt-0">
							  	  <v-progress-linear
							  	  	height="20"
							  	  	:value="(100 - parseFloat(vacantesDisponibles.porcentaje))"
							  	  	rounded
							  	  	color="green"
							  	  >
							  	  	<strong
							  	  		class="text-caption white--text"
							  	  	>{{ (100 - parseFloat(vacantesDisponibles.porcentaje)).toFixed(2) }}%</strong>
							  	  </v-progress-linear>
							  	</v-card-text>
			      		</v-col>

			      	</v-row>
			    	</span>
			    </v-card-text>
			  </v-card>
  		</v-col>
    </v-row>

    <v-dialog
			v-model="overlay"
			persistent
			max-width="350px"
		>
			<v-card color="primary" dark>
				<v-card-text class="pt-4">
					Cargando...
					<v-progress-linear
						indeterminate
						color="white"
						class="mb-0"
					></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog
      v-model="dialogVentasVendedora"
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h5">
          Inscritos
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogVentasVendedora = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
        	<v-data-table
				    :headers="headersVentas"
				    :items="ventasVendedora"
				    :items-per-page="5"
				    class="elevation-0"
				  ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'

	export default {
	  data(){
	  	return{
	  		overlay: false, 

		    sucursales:[],
		    sucursal:null,

		    porcentajeRi:[],
		    contactos:[],
		    clases:[],
		    clasesTeacher:[],
		    vacantes:{},

		    // RI
		    recepcionistas:[],
		    recepcionista: null,

		    // Ventas
		    ventas:[],
		    vendedora: null,
		    ventasVendedora: [],
				dialogVentasVendedora: false,
				headersVentas: [
          { text: 'id'      , value: 'id_alumno' },
          { text: 'Nombre'  , value: 'nombre' },
          { text: 'Ciclo'   , value: 'ciclo' },
          { text: 'Fecha'   , value: 'fecha' },
        ],

        // Contactos por vendedora
        contactosVendedora:[],
        contactVendedora:null,

        // Actividades de los desarrolladores
        desarrolladores:[],
        desarrollador:null,

        // Actividades de los desarrolladores
				administradores:[],
        administrador:null,

        // Vancates
        vacantesDisponibles: null,

        // Semana
        semana:0,
        metas:[],
	  	}
	  },

	  async created(){
	  	await this.getMetas()
	  	await this.initialize()
	  },

	  computed:{
	  	...mapGetters('login',['getdatosUsuario']),

	  	filterRi(){
        if(this.sucursales){
          return this.sucursales.filter( el=> { return el.escuela == this.escuela})
        }
      },

      filterVentas(){
        if(this.ventas){
          return this.ventas.filter( el=> { return el.escuela == this.escuela})
        }
      },

      getMetaDesarrollador(){
      	if(this.metas.length){
      		const meta = this.metas.find(el=> el.tipo == 'DESARROLLADOR')
      		switch ( this.semana ){
      			case 1:
      				return meta.semana1
      			break;

      			case 2:
      				return meta.semana2
      			break;

      			case 3:
      				return meta.semana3
      			break;

      			case 4:
      				return meta.semana4
      			break;
      		}
      	}else{ return 'NA'}
      },

      getMetaVentas(){
      	if(this.metas.length){
      		const meta = this.metas.find(el=> el.tipo == 'VENTAS')
      		switch ( this.semana ){
      			case 1:
      				return meta.semana1
      			break;

      			case 2:
      				return meta.semana2
      			break;

      			case 3:
      				return meta.semana3
      			break;

      			case 4:
      				return meta.semana4
      			break;
      		}
      	}else{ return 'NA'}
      },

      getMetaContactos(){
      	if(this.metas.length){
      		const meta = this.metas.find(el=> el.tipo == 'CONTACTOS')
      		switch ( this.semana ){
      			case 1:
      				return meta.semana1
      			break;

      			case 2:
      				return meta.semana2
      			break;

      			case 3:
      				return meta.semana3
      			break;

      			case 4:
      				return meta.semana4
      			break;
      		}
      	}else{ return 'NA'}
      },

      getMetaAdministrador(){
      	if(this.metas.length){
      		const meta = this.metas.find(el=> el.tipo == 'ADMINISTRACION')
      		switch ( this.semana ){
      			case 1:
      				return meta.semana1
      			break;

      			case 2:
      				return meta.semana2
      			break;

      			case 3:
      				return meta.semana3
      			break;

      			case 4:
      				return meta.semana4
      			break;
      		}
      	}else{ return 'NA'}
      },

      getMetaVacantes(){
      	if(this.metas.length){
      		const meta = this.metas.find(el=> el.tipo == 'VACANTES')
      		switch ( this.semana ){
      			case 1:
      				return meta.semana1
      			break;

      			case 2:
      				return meta.semana2
      			break;

      			case 3:
      				return meta.semana3
      			break;

      			case 4:
      				return meta.semana4
      			break;
      		}
      	}else{ return 'NA'}
      }

	  },

	  methods:{
	  	getMetas() {
	  		this.metas = []
        return this.$http.get('metas.semana.list').then((response) => {
          this.metas = response.data
          this.overlay = false
        }).catch(error=>{
          this.overlay = false
        }).finally(()=>{
        	this.overlay = false
        })
      },

	  	initialize() {
	  		this.overlay = true
        this.$http.get('data.dashboard.personal').then((response) => {
          this.recepcionistas       = response.data.recepcionistas
          this.ventas               = response.data.ventas
          this.contactosVendedora   = response.data.contactosVendedora
          this.desarrolladores      = response.data.desarrolladores
          this.administradores      = response.data.administradores
          this.vacantesDisponibles  = response.data.vacantesDisponibles
          this.semana               = response.data.semana
          console.log(this.recepcionistas)
          this.overlay = false
        }).catch(error=>{
          this.overlay = false
        }).finally(()=>{
        	this.overlay = false
        })
      },

      verVentasVendedora(){
      	if(this.vendedora){
      		this.ventasVendedora = this.vendedora.inscritos
      		this.dialogVentasVendedora = true
      	}
      },

      getMetaRI( id_plantel ){
      	if(this.metas.length){
      		const meta = this.metas.find(el=> el.tipo == 'RI' && el.id_plantel == id_plantel)
      		console.log(meta, id_plantel)
      		switch ( this.semana ){
      			case 1:
      				return meta.semana1
      			break;

      			case 2:
      				return meta.semana2
      			break;

      			case 3:
      				return meta.semana3
      			break;

      			case 4:
      				return meta.semana4
      			break;
      		}
      	}else{ return 'NA'}
      },

	  },

	}
</script>
<style type="text/css">
	.back-dashboard-personal{
		background-image: linear-gradient( 68.4deg,  rgba(5,222,250,1) -0.4%, rgba(99,251,215,1) 100.2% );

		border-radius: 20px !important;
	}
</style>
<style lang="scss" scoped>
	.greeting-card {
	  position: relative;
	  .greeting-card-bg {
	    position: absolute;
	    bottom: 0;
	    right: 0;
	  }
	  .greeting-card-trophy {
	    position: absolute;
	    bottom: 10%;
	    right: 8%;
	  }
	}
	// rtl
	.v-application {
	  &.v-application--is-rtl {
	    .greeting-card-bg {
	      right: initial;
	      left: 0;
	      transform: rotateY(180deg);
	    }
	    .greeting-card-trophy {
	      left: 8%;
	      right: initial;
	    }
	  }
	}
</style>