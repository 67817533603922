import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';

export default {

	data: () =>({
    conteoAlertaTiempo: 0,
    tiempoTotalSegundos:0,
		tiempo:'00:00',
    sec: 0,
    min: 0,
    hrs: 0,
    t: null,
    alertaTiempo: false,
    showTimer: false,
	}),

	methods: {
		iniciarCronometro() {
        this.t = setTimeout(this.add, 1000);
      },

      add() {
        this.tick();
        this.tiempo = /*(this.hrs > 9 ? this.hrs : "0" + this.hrs) 
               + ":" +*/ (this.min > 9 ? this.min : "0" + this.min)
               + ":" + (this.sec > 9 ? this.sec : "0" + this.sec);
        // Acumulamos el tiempo
        this.tiempoTotalSegundos ++
        // Verificamos si es divisible entre 5 segundos
        if(this.tiempoTotalSegundos%300 == 0){
          // Acvtivamos el color rojo
          this.alertaTiempo = true
        }
        // Verificamos en que conteo va 
        if(this.conteoAlertaTiempo == 5){
          // Reiniciamos alerta y conteo
          this.alertaTiempo = false
          this.conteoAlertaTiempo = 0
        }
        // Mientras esta actica la alerta se usa el acumulador, si no esta activa, no se debe acumular
        if(this.alertaTiempo) { this.conteoAlertaTiempo++ }

        this.iniciarCronometro();
      },

      tick(){
        this.sec++;
        if (this.sec >= 60) {
            this.sec = 0;
            this.min++;
            if (this.min >= 60) {
                this.min = 0;
                this.hrs++;
            }
        }
      },

      resetCronometro(){
        this.conteoAlertaTiempo  = 0
        this.tiempoTotalSegundos = 0
        this.tiempo              = '00:00'
        this.sec                 = 0
        this.min                 = 0
        this.hrs                 = 0
        this.alertaTiempo        = false
        this.showTimer           = false
        clearTimeout(this.t);
      },

  }
}