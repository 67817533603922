<template>
  <!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="verVentana"
    persistent
    max-width="1000"
  >
  	<v-card>
      <!-- PAGO OFICIAL -->
      <v-card-title>
        <span class="text-body-1"><b>Reporte seguimiento</b></span>
        <v-spacer></v-spacer>
        <v-btn
          @click="modal = false, modal2 = false, this.alertaSeguimiento.estatus = false"
          color="black"
          dark
          tile
          small
          class="text-capitalize"
        >
        	<v-icon left small>mdi-cancel</v-icon>
          Cancelar
        </v-btn>
      </v-card-title>
      <v-card-text >

        <v-alert
          prominent
          type="error"
        >
          <v-row align="center">
            <v-col class="grow">
              REPORTE DE SEGUIMIENTO
            </v-col>
            <v-col class="shrink">
            </v-col>
          </v-row>
        </v-alert>


        <v-subheader class="pl-0 black--text text-h6">Seguimiento NI</v-subheader>
        <v-data-table
        	v-if="escuelaMostrar == 2"
          :headers="headersReporte"
          :items="reporteFast"
          class="elevation-0"
          dense
          hide-default-footer
        >

          <template v-slot:item.vendedora="{ item }" >
            <span class="text-h6 black--text">
              {{ item.vendedora }}
            </span>
          </template>

          <template v-slot:item.sinseguimiento="{ item }" >
            <span class="text-h6 black--text">
              {{ item.sinseguimiento }}
            </span>
          </template>

          <template v-slot:item.contador="{ item }" >
            <span class="text-h6 black--text">
              {{ item.contador }}
            </span>
          </template>

        </v-data-table>

        <v-data-table
        	v-if="escuelaMostrar == 1"
          :headers="headersReporte"
          :items="reporteInbi"
          class="elevation-0"
          dense
          hide-default-footer
        >
          <template v-slot:item.vendedora="{ item }" >
            <span class="text-h6 black--text">
              {{ item.vendedora }}
            </span>
          </template>

          <template v-slot:item.sinseguimiento="{ item }" >
            <span class="text-h6 black--text">
              {{ item.sinseguimiento }}
            </span>
          </template>

          <template v-slot:item.contador="{ item }" >
            <span class="text-h6 black--text">
              {{ item.contador }}
            </span>
          </template>

        </v-data-table>

        <br/>
        <br/>

        <label class="text-h5 black--text">Seguimiento RI</label>
        <div class="text-h6 black--text">
          <b>Total: </b> {{ alumnos.length }}
          <br/>
          <b>Sin seguimiento: </b> {{ alumnos.filter( el => { return el.seguimiento_whatsApp > 3 }).length }}
          <br/>
          <br/>
        </div>


      </v-card-text>
    </v-card>

    <v-card class="shadowCard" v-if="dialogRepote">

    	<v-card-title class="text-subtitle-1">
    	  <span class="mr-2">Seguimiento NI</span>
        <v-spacer></v-spacer>

        
        <v-btn 
          class="mr-2" 
          small dark color="green"
          @click="exportExcel( filterProspectos  , 'SEGUIMIENTO_NI')"
          tile
        >
          <v-icon small>mdi-microsoft-excel</v-icon>
        </v-btn>

        <v-btn 
          v-if="getdatosUsuario.idpuesto != 18"
          class="mr-2" 
          small dark color="red"
          tile
          @click="dialogRepote = true"
        >
          <v-icon left small>mdi-file-document-multiple</v-icon>
          Reporte
        </v-btn>


        <v-btn
          color="primary"
          dark
          class="text-capitalize"
          @click="initialize()"
          small
          tile
        >
          <v-icon left small>mdi-refresh</v-icon>
          Actualizar
        </v-btn>


    	</v-card-title>


      <!-- Buscador y filtros -->
      <v-card-text>
        <v-row >
          <v-col cols="12" md="6" lg="4" v-if="getdatosUsuario.idpuesto != 18" class="pb-0">
            <v-autocomplete
              filled
              dense
              clearable
              v-model="vendedoraFiltro"
              :items="vendedoras"
              label="Selecciona la vendedora"
              persistent-hint
              hide-details
              item-text="nombre"
              item-value="iderp"
            >
            </v-autocomplete>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="12" md="6" lg="4" class="pb-0">
            <v-text-field
              v-model="search"
              dense
              filled
              label="Buscar"
              append-icon="mdi-magnify"
            ></v-text-field>  
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Tablas -->
      <v-card-text class="pt-0">

        <div class="text-h6">
          <b>Total: </b> {{ filterProspectos.length }}
          <br/>
          <b>Sin seguimiento: </b> {{ filterProspectos.filter( el => { return el.seguimiento_whatsApp > 3 }).length }}
          <br/>
          
        </div>

        <v-data-table
          :headers="headers"
          :items="filterProspectos"
          class="elevation-0"
          :search="search"
          dense
        >
          <!-- Datos dinámicos de las tablas -->
          <template v-slot:item.folio="{ item }">
            <span>
            	<strong>[ {{ item.folio }} ]</strong>
            	<br/>
            	{{ item.nombre_completo }}
            	<br/>
            	{{ item.whatsapp }}
            </span>
          </template>

          <template v-slot:item.idetapa="{ item }">
            <!-- chip para prospectos atrasados -->
            <v-tooltip bottom color="red" v-if="item.idetapa == 1">
              <template v-slot:activator="{ on, attrs }">
                <v-chip small  color="red" dark  v-bind="attrs" v-on="on">Atrasado</v-chip>
              </template>
              <span>El proespecto esta esperando que se contacten con el</span>
            </v-tooltip>

            <!-- chip para prospectos nuevos -->
            <v-tooltip bottom color="green" v-if="item.idetapa == 2">
              <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 2">
                <v-chip small  color="green"  dark  v-if="item.idetapa == 2" v-bind="attrs" v-on="on">Nuevo</v-chip>
              </template>
              <span>Prospecto llego hoy y no tiene información</span>
            </v-tooltip>

            <!-- chip para prospectos en inducción -->
            <v-tooltip bottom color="pink" v-if="item.idetapa == 3">
              <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 3">
                <v-chip small  color="pink"   dark  v-if="item.idetapa == 3" v-bind="attrs" v-on="on">
                	<v-icon v-if="item.dia_induccion == 1" color="yellow" left>mdi-bell</v-icon>Inducción
                </v-chip>
              </template>
              <span>El prospecto se encuentra en inducción</span>
            </v-tooltip>

            <!-- chip para prospectos en seguimiento con información -->
            <v-tooltip bottom color="orange" v-if="item.idetapa == 4">
              <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 4">
                <v-chip small  color="orange" dark  v-if="item.idetapa == 4" v-bind="attrs" v-on="on">Con Info</v-chip>
              </template>
              <span>Al prospecto ya se le brindo información</span> 
            </v-tooltip>

            <!-- chip para prospectos con información -->
            <v-tooltip bottom color="brown" v-if="item.idetapa == 5">
              <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 5">
                <v-chip small  color="brown"  dark  v-if="item.idetapa == 5" v-bind="attrs" v-on="on">Sin Info</v-chip>
              </template>
              <span>Al prospecto no se le ha podido brindar información</span>
            </v-tooltip>
          </template>

          <template v-slot:item.conmutador="{ item }">
            <!-- chip para prospectos atrasados -->
            <v-tooltip bottom :color=" item.conmutador.length ? 'green' : 'red' " >
              <template v-slot:activator="{ on, attrs }">
                <v-chip 
                	small 
                	:color=" item.conmutador.length ? 'green' : 'red' "
                	dark 
                	v-bind="attrs" 
                	v-on="on" 
                >
                  {{ item.conmutador.length ? 'SI' : 'NO' }}
                </v-chip>
              </template>
              <span v-if="item.conmutador.length">
                <span v-for="( mensaje, i ) in item.conmutador" :key="i">
                  {{ mensaje.mensaje }} 
                  <br/>
                </span> 
              </span>

              <span v-else>
                Sin llamadas en el conmutador.
              </span>

            </v-tooltip>
          </template>

          <template v-slot:item.llamadas_intentos="{ item }">
            <!-- chip para prospectos atrasados -->
            <v-tooltip bottom :color=" item.conmutador.length ? 'green' : 'red' " >
              <template v-slot:activator="{ on, attrs }">
                <v-chip 
                	small 
                	:color=" item.conmutador.length ? 'green' : 'red' "
                	dark 
                	v-bind="attrs" 
                	v-on="on" 
                >
                  {{ item.conmutador.length ? 'SI' : 'NO' }}
                </v-chip>
              </template>
              <span>
              	LLamadas > 3 min: {{ item.mastresmin }}
								<br/>
								contestadas:      {{ item.contestadas }}
								<br/>
								rechazadas:       {{ item.rechazadas }}
								<br/>
								ocupadas:         {{ item.ocupadas }}
								<br/>
								fallidas:         {{ item.fallidas }}
              </span>

            </v-tooltip>
          </template>

          <template v-slot:item.seguimiento_whatsApp="{ item }">
          	<v-tooltip bottom 
          		:color="item.seguimiento_whatsApp > 3 || !item.ultimo_mensaje ? 'red' : 'green' "
          	>
				      <template v-slot:activator="{ on, attrs }">
		            <v-chip
                	:color="item.seguimiento_whatsApp > 3 || !item.ultimo_mensaje ? 'red' : 'green' "
                	dark
		              dark
		              small
				          v-bind="attrs"
				          v-on="on"
		            >
                	{{ item.seguimiento_whatsApp > 3 || !item.ultimo_mensaje ? 'NO' : 'SI' }}
		            </v-chip>
				      </template>

				      <span v-if="item.seguimiento_whatsApp > 3 && item.ultimo_mensaje">
				      	Al alumno no se le ha brindado ningúna información por whatsApp desde hace {{ item.seguimiento_whatsApp }} días
				      </span>

				      <span v-if="item.seguimiento_whatsApp <= 3 && item.ultimo_mensaje ">
				      	Seguimiento correcto del alumno
				      </span>

				      <br v-if="item.ultimo_mensaje"/>

				      <span>
				      	{{ item.ultimo_mensaje ? 'último mensaje: ' + item.ultimo_mensaje : 'No tiene mensajes'}}
				      </span>
				    </v-tooltip>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Actualizar
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>   
    </v-card>

    <!-- Dialog de carga -->
    <!-- <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/> -->
    <!-- <carga                v-if="cargar"/> -->

    <v-dialog
      v-model="dialogRepote"
      persistent
      max-width="600"
    >
      <v-card>
        <!-- PAGO OFICIAL -->
        <v-card-title>
          <span class="text-h6"><b>Reporte seguimiento</b></span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text >
          <v-subheader class="pl-0 black--text" v-if="escuelaMostrar == 2">FAST</v-subheader>
          <v-data-table
          	v-if="escuelaMostrar == 2"
            :headers="headersReporte"
            :items="reporteFast"
            class="elevation-0"
            dense
            hide-default-footer
          >
          </v-data-table>

          <v-subheader class="pl-0 black--text">INBI</v-subheader>
          <v-data-table
          	v-if="escuelaMostrar == 1"
            :headers="headersReporte"
            :items="reporteInbi"
            class="elevation-0"
            dense
            hide-default-footer
          >
          </v-data-table>

        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="dialogRepote = false"
            rounded
            color="red"
            dark
            class="text-capitalize"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>

      <!-- Dialog de carga -->
      <Alerta               v-if="respuestaAlerta"          :parametros="parametros"/>
      <carga                v-if="cargar"/>
    </v-dialog>

  </v-dialog>
</template>


<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

  import { headerAdmin, headerVend, headerSeguimiento } from '@/views/crm-prospectos/prospectos/headerTablas'
  import { mapGetters, mapActions }  from 'vuex';
  // Mixins
  import funciones       from '@/mixins/funcionesProspectos'
  import catalagos       from '@/mixins/catalagos'
  import funcionesExcel  from '@/mixins/funcionesExcel'

  var moment  = require('moment');
  moment.locale(); 

	export default {
		props:[
			'alertaSeguimiento'
	  ],

    components:{
      Alerta,
      carga,
    },

    mixins: [funciones,catalagos,funcionesExcel,validarErrores],

    data: () => ({
      // herramientas visuales
      estatusFiltro:6,

      // Tabla
      headers: [],

      // Prospectos
      prospectos:     [], //arreglo
      
      vendedoras:[],
      vendedoras_plantel:[],
      vendedora:null,
      nombreVendedora:'',

      vendedoraFiltro:null,
     
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search:'',

      headersReporte: [
        { text: 'Vendedora'        , value: 'vendedora'      },
        { text: 'Sin seguimeinto'  , value: 'sinseguimiento' },
        { text: 'Total'            , value: 'contador'       },
      ],
      reporteFast:[],
      reporteInbi:[],
      dialogRepote: false,
      modal: false,
      modal2: false,
      escuelaMostrar: 0,
      alumnos: [],
      
    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),

      filterProspectos(){
      	if(this.vendedoraFiltro){
      		return this.prospectos.filter( el => { return el.usuario_asignado == this.vendedoraFiltro })
	      }else{ return this.prospectos }
      },

      verVentana(){
      	if( this.modal && this.modal2 ){
      		return true
      	}else{ return false }
      }
    
    },

    async created() {
      // Agregamos los header de la tabla
      this.headers = headerSeguimiento
      await this.getVendedoras()
      await this.initialize();

      if( [0,1,2,3,4,5,6,21,17,22,23].includes( this.getdatosUsuario.id_plantel ) ){
        this.escuelaMostrar = 1
      }else{
        this.escuelaMostrar = 2
      }

    },


    methods: {
      ...mapActions('login', ['cambiarDrawer','cambiarEstatusLlamada','addTareas','traerProspectosNuevos']),

      // Inicio de todo
      initialize() {
        // Abrimos modal de carga
        this.cargar = true
        // Limpiamos los prospectos
        this.prospectos  = []

        // Ver el tipo de usuario que entrara
        let tipo = 0 // null
        if(this.getdatosUsuario.idpuesto == 18){
          tipo = 1 // vendedora
        }else{
          tipo = 2 // Director
        }
        
        this.$http.get('prospectos.seguimiento/' + tipo + '/' + this.getdatosUsuario.iderp).then((response) => {
          // Filtramos los que necesitamos, depende si es vendedora o administrador
          this.prospectos  = response.data.nuevos
          this.reporteFast = response.data.arregloVendedorasFast
          this.reporteInbi = response.data.arregloVendedorasInbi
          this.modal        = true
          // this.dialogRepote = true
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })


      	const payload = {
      		cicloFast: 251,
					cicloInbi: 252,
      	}

        this.alumnos = []

        // Primero traemos los que faltan por entrar a la plataforma
        this.$http.post('riesgo.seguimiento.ri', payload).then(response=>{
          // Los guardamos
    			const planteles = this.getdatosUsuario.planteles.map((registro)=>{ return registro.idplantel })

        	this.alumnos = response.data.alumnosERPVIEJO.filter( el => { return planteles.includes(el.id_plantel) })
        	this.modal2 = true
        	// this.reporteFast = response.data.arregloPlantelesFast
					// this.reporteInbi = response.data.arregloPlantelesINBI

          this.cargar = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },
    },
  };
</script>
