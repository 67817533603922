<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-toolbar
            flat
          >
            <v-toolbar-title>NI alumnos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()" tile>
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn color="primary" @click="consultar()" small tile>Consultar</v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  clearable
                  v-model="fechaini"
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha final"
                  filled
                  dense
                  clearable
                  v-model="fechafin"
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          

          <v-card-text class="pt-0">
            <v-row align="center">
              <v-col cols="12" md="5" lg="3" v-if="visibleFast">
                <v-card color="blue darken-4 shadowCard">
                  <v-card-title primary-title class="py-1">
                    <div class="white--text">Inscritos Fast: {{ dataTablaFastDos.length }}</div>
                  </v-card-title>
                </v-card>
              </v-col>

              <v-col cols="12" md="5" lg="3" v-if="visibleInbi">
                <v-card color="blue lighten-1 shadowCard">
                  <v-card-title primary-title class="py-1">
                    <div class="white--text">Inscritos Inbi: {{ dataTablaInbiDos.length }}</div>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

        	<v-card class="shadowCard" v-if="visibleFast">
        		<v-card-title primary-title>
        		  Fast
              <v-spacer></v-spacer>
              <v-text-field
                name="name"
                label="Buscar"
                id="id"
                dense
                filled
                v-model="searchFast"
                append-icon="mdi-magnify"
              ></v-text-field>
        		</v-card-title>
        	  <v-card-text>
  		      	<v-data-table
  		          :headers="headersDos"
  		          :items="dataTablaFastDos"
  		          class="elevation-0"
  		          dense
  		          mobile-breakpoint="200"
                :search="searchFast"
  		        >
  		          <template v-slot:no-data>
  		            <v-btn
  		              color="primary"
  		              @click="initialize"
  		              small
  		            >
  		              Actualizar
  		            </v-btn>
  		          </template>
  		        </v-data-table>
        	  </v-card-text>
        	</v-card>

          <v-divider></v-divider>

          <v-card class="shadowCard" v-if="visibleInbi">
            <v-card-title primary-title>
              Inbi
              <v-spacer></v-spacer>
              <v-text-field
                name="name"
                label="Buscar"
                id="id"
                dense
                filled
                v-model="searchInbi"
                append-icon="mdi-magnify"
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headersDos"
                :items="dataTablaInbiDos"
                class="elevation-0"
                dense
                mobile-breakpoint="200"
                :search="searchInbi"
              >
                <template v-slot:no-data>
                  <v-btn
                    color="primary"
                    @click="initialize"
                    small
                  >
                    Actualizar
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import validarErrores  from '@/mixins/validarErrores'

  import Vue from 'vue'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],
    
    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      snackbar:false,
      headers: [
        { text: 'Sucursal'                 , value: 'plantel' },
        { text: 'Alumnos dia anterior'     , value: 'alumnodia' },
        // { text: 'Hoy'                      , value: 'alumnoDiaHoy' },
        { text: 'Acumulado semana'         , value: 'alumnosemana' },
        { text: 'Acumulado'                , value: 'totalAcumulado' },
      ],

      headersDos: [
        { text: 'ID'         , value: 'id_alumno' },
        { text: 'Nombre'     , value: 'alumno' },
        { text: 'Sucursal'   , value: 'plantel' },
        { text: 'Vendedora'  , value: 'vendedora' },
        { text: 'Grupo'      , value: 'grupo' },
        // { text: 'Ciclo'      , value: 'ciclo' },
        { text: 'Fecha pago' , value: 'fecha_pago' },
        { text: 'Fecha folio', value: 'fecha_folio' },
        { text: 'Días'       , value: 'dias' },
        { text: 'Creó'       , value: 'usuario' },
        // { text: 'Medio'   , value: 'medio' },
        { text: '1er P'      , value: 'primer_pago' },
        { text: 'Total'      , value: 'pagado' },
        // { text: 'Campaña'    , value: 'campania' },
      ],
      desserts: [],
      ciclos:[],
      cicloInf:null,
      cicloSup:null,
      plantel:null,
      cicloAnterior:[],
      cicloSiguiente:[],

      dataTablaFastDos:[],
      dataTablaInbiDos:[],

      dialogCarga:false,
      fast:{},
      inbi:{},

      fechaini:null,
      fechafin:null,
      totalFast:0,
      totalInbi:0,

      visibleFast: true,
      visibleInbi: true,
      searchFast:'',
      searchInbi:'',
    }),

    watch: {
      cicloInf () {
        if(this.cicloInf){
          var num_search = parseInt(this.cicloInf.ciclo.substr(5,3)) + 1
          for(const i in this.ciclos){
            var cadena_search = this.ciclos[i].ciclo.substr(0,8)
            if (cadena_search.search(num_search) != -1) {
              this.cicloSup = this.ciclos[i]
            }
          }
        }
      }
    },

    created () {
      if(this.getdatosUsuario.idpuesto == 18 ){
        this.visibleFast =  this.getEscuela == 1 ? false : true 
        this.visibleInbi =  this.getEscuela == 1 ? true  : false 
      }

      if( this.getdatosUsuario.iderp == 568 ){
        this.visibleFast =  true 
        this.visibleInbi =  true
      }

      this.initialize()
    },

    computed:{
      ...mapGetters('login', [ 'getdatosUsuario', 'getEscuela' ])
    },

    methods: {
      initialize () {
        this.cargar = true
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
        
      },

      consultar () {
        if(this.cicloInf != null && this.fechafin == null && this.fechaini == null){
          this.getCiclo()
        }else if(this.cicloInf != null && this.fechafin != null && this.fechaini != null){
          this.getCicloRango()
        }else if(this.cicloInf != null && this.fechaini != null && this.fechafin == null){
          this.getCicloDia()
        }else if(this.cicloInf == null && this.fechaini != null && this.fechafin != null){
          this.getRango()
        }else if(this.cicloInf == null && this.fechaini != null && this.fechafin == null){
          this.getDia()
        }else{
          this.text = 'Selecciona los ciclos porfavor'
          this.snackbar = true
        }

      },

      getDia () {
        this.dataTablaFastUno = []
        this.dataTablaInbiUno = []
        this.dialogCarga = true

        var payload = {
          fechaini: this.fechaini,
          fechafin: this.fechafin
        }

        this.$http.post('kpi.inscripciones.alumnos.dia.fast',payload).then(response=>{
          // Guardar los datos 
          this.dataTablaFastDos = response.data
          this.$http.post('kpi.inscripciones.alumnos.dia.inbi',payload).then(response=>{
            // Guardar los datos 
            this.dataTablaInbiDos = response.data
            this.cargar = false
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getRango () {
        this.dataTablaFastUno = []
        this.dataTablaInbiUno = []
        this.dialogCarga = true

        var payload = {
          fechaini: this.fechaini,
          fechafin: this.fechafin
        }

        this.$http.post('kpi.inscripciones.alumnos.rango.fast',payload).then(response=>{
          // Guardar los datos 
          this.dataTablaFastDos = response.data
          this.$http.post('kpi.inscripciones.alumnos.rango.inbi',payload).then(response=>{
            // Guardar los datos 
            this.dataTablaInbiDos = response.data
            this.cargar = false
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      getCiclo () {
        this.cargar = true
      	this.fast = {}
      	this.inbi = {}
        this.$http.post('kpi.inscripciones.general', this.cicloInf).then(response=>{
          this.dataTablaFastDos = response.data.fast
        	this.dataTablaInbiDos = response.data.inbi
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCicloRango () {
        this.cargar = true
        this.dataTablaFastUno = []
        this.dataTablaInbiUno = []
        this.dialogCarga = true
        this.fast = {}
        this.inbi = {}

        var payload = {
          ciclo: this.cicloInf,
          fechaini: this.fechaini,
          fechafin: this.fechafin
        }

        this.$http.post('kpi.inscripciones.alumnos.ciclo.rango',payload).then(response=>{
          // Guardar los datos 
          this.dataTablaFastDos = response.data.fast
          this.dataTablaInbiDos = response.data.inbi
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCicloDia () {
        this.cargar = true
        this.dataTablaFastUno = []
        this.dataTablaInbiUno = []
        this.dialogCarga = true
        this.fast = {}
        this.inbi = {}

        var payload = {
          ciclo: this.cicloInf,
          fechaini: this.fechaini,
          fechafin: this.fechaini
        }

        this.$http.post('kpi.inscripciones.alumnos.ciclo.rango',payload).then(response=>{
          // Guardar los datos 
          this.dataTablaFastDos = response.data.fast
          this.dataTablaInbiDos = response.data.inbi
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      exportar(){
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let resultado = this.dataTablaFastDos.concat(this.dataTablaInbiDos)

        let data = XLSX.utils.json_to_sheet(resultado)
        const workbook = XLSX.utils.book_new()
        const filename = 'KPI'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },      
    },
  }
</script>