<template>
  <v-container class="mt-4 ml-2">
    <v-row>
      <v-col cols="12">
	      <v-card>
	      	<v-card-title primary-title>
		        <v-toolbar-title>Alumnos Reprobados</v-toolbar-title>
		        <v-divider
		          class="mx-4"
		          inset
		          vertical
		        ></v-divider>
		        <v-spacer></v-spacer>
		        <v-btn color="primary" @click="getGruposAlumnos()" small class="mr-2">Consultar</v-btn>
	      	</v-card-title>
	        <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  outlined
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
	        	<!-- Menu superior de tabs -->
            <v-row>
	            <v-col cols="12" lg="12">
	              <v-card class="elevation-0">
	                <v-card-title primary-title class="pt-0 ">
                    <v-text-field
                      v-model="search1"
                      outlined
                      dense
                      label="Buscar"
                      hide-details
                    >
                    </v-text-field>
	                </v-card-title>

	                <v-card-text>	
	                	<v-data-table
                      :search="search1"
									    dense
									    :headers="headers"
									    :items="alumnosFast"
									    item-key="id"
									    class="elevation-1"
									  >
             
                      <template v-slot:item.id_unidad_negocio="{ item }">
                        <v-chip v-if="item.id_unidad_negocio == 1" small rounded color="blue lighten-2" dark >INBI</v-chip>
                        <v-chip v-else small rounded color="blue darken-2" dark >FAST</v-chip>
                      </template> 

                      <template v-slot:item.notas="{ item }">
                        <v-chip small rounded color="primary" dark @click="cargarNotas(item)">Comentarios</v-chip>
                      </template> 

                    </v-data-table>
	                </v-card-text>	
	              </v-card>
	            </v-col>
            </v-row>
	        </v-card-text>
	      </v-card>		
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogMensajes"
      persistent
      max-width="800"
    >
      <v-card
        style="position: relative"
      >
        <v-card-title primary-title >
          Notas
        </v-card-title>
        <v-card-text>
          <span v-for="(mensaje, i) in mensajes">
            <v-row v-if="mensaje.id_usuario != getdatosUsuario.iderp">
              <v-col cols="12">
                <v-card class="ml-6 pa-3 mt-2" max-width="400" color="#f2f2f2" style="border-radius: 15px; border-top-left-radius: 0">
                	<v-card-actions class="pa-0">
                    <div class="text-caption blue--text">{{ mensaje.usuario }}</div>
                  </v-card-actions>
                  <div class="text-subtitle-1">{{ mensaje.nota }} </div>
                  <v-card-actions class="pa-0">
                    <div class="text-caption blue--text">{{ mensaje.fecha_creacion }}</div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="mensaje.id_usuario == getdatosUsuario.iderp">
              <v-col cols="12" align="right">
                <v-card class="mr-6 mt-2 pa-3" max-width="400" dark color="#0465ac" shaped style="border-radius: 15px; border-top-right-radius: 0;">
                  <div class="text-subtitle-1">{{ mensaje.nota }}</div>
                  <v-card-actions class="pa-0">
                  	<v-spacer></v-spacer>
                    <div class="text-caption white--text">{{ mensaje.fecha_creacion }}</div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </span>
        </v-card-text>

        <v-card-text class="pb-6 pt-6">
          <v-text-field
            v-model="mensaje"
            :append-outer-icon="mensaje ? 'mdi-send' : ''"
            outlined
            clearable
            label="¿Por qué razón el usuario debería pasar?"
            type="text"
            @click:append-outer="enviarMensaje()"
            hide-details
            dense
          ></v-text-field>
        </v-card-text>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogMensajes = false, mensaje = ''"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-h6" style="display: table-cell; vertical-align: middle;">
                  <strong>¡Bien!</strong> Datos grabados correctamente
                  <v-card-actions class="pt-6">
                    <v-spacer></v-spacer>
                    <!-- Guardar la información  -->
                    <v-btn color="green" dark class="elevation-6" large @click="dialogSuccess = false">Ok</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/correcto.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
        width="300"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-h6" style="display: table-cell; vertical-align: middle;">
                  <span v-if="errorBack">
                    <strong>ERROR</strong> Da clic en "COPIAR" para guardar el error y enviarlo a sistemas
                  </span>
                  <span v-else>
                    <strong>ERROR</strong> {{error}}
                  </span>
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text large class="mr-2" @click="dialogError = false">Cerrar</v-btn>
                    <!-- Guardar la información  -->
                    <v-btn color="#D60F24" dark class="elevation-6" large @click="copiarPortapapeles()" v-if="errorBack">{{textoCopiado}}</v-btn>
                    <v-btn color="#D60F24" dark class="elevation-6" large @click="dialogError = false" v-else>Ok</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/eliminar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
  import XLSX from 'xlsx'

  export default {
    data: () => ({
      search1:'',
      search2:'',
    	row:null,
    	tab: {name:'GruposAlumnos FAST'   , icon:'mdi-file' }, 
      items: [
        {name:'GruposAlumnos FAST'    , icon:'mdi-file' }, 
        {name:'GruposAlumnos INBI'    , icon:'mdi-file' }, 
      ],

      headers: [
        { text: 'id_grupo'   , value: 'id_grupo' },
        { text: 'grupo'      , value: 'grupo' },
        { text: 'id usuario' , value: 'id_alumno' },
        { text: 'Alumno'     , value: 'alumno' },
        { text: 'Escuela'    , value: 'id_unidad_negocio' },
        { text: 'Comentarios', value: 'notas' },
      ],

      gruposAlumnos:[],
			cicloInf: 		null,
      ciclos:  			[],
      alumnosFast:  [],
      alumnosInbi:  [],
      dialogMensajes:false,
      mensajes: [],
      mensaje:'',
      alumno: null,

      gruposTeacher:[],

      dialogSuccess:false,
      overlay: false,
      dialogError:  false,
      error:        '',
      successMensaje:'',
      errorBack:false,
      textoCopiado:'COPIAR',
    }),

    watch: {
      cicloInf () {
        if(this.cicloInf){
          this.getGruposAlumnos()
        }
      },
    },

   
    created () {
      this.initialize()
    },

    methods: {
    	initialize () {
      	this.overlay = true
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError('Error al recuperar los ciclos')
        })
      },

      async getGruposAlumnos () {
      	await this.getGruposTeacher()
      	if(this.cicloInf){
	      	// vaciamos el horario
	      	this.alumnosFast = []
	      	// Activamos el loader
	    		this.overlay = true
	    		// Hacemos la peticios
	    		this.$http.get(`operaciones.reprobados.list/${ this.cicloInf.id_ciclo }/${ this.cicloInf.id_ciclo_relacionado }`).then(response=>{
	    			// Guardamos la respuesta
	    			console.log(this.gruposTeacher)
            for(const i in response.data){
              const existe = this.gruposTeacher.find(el=> el.id_grupo == response.data[i].id_grupo && el.id_unidad_negocio == response.data[i].id_unidad_negocio)
              if(existe){
                this.alumnosFast.push(response.data[i])
              }
            }
	          // Quitamos el loader
	          this.overlay = false
	        }).catch(error=>{
	        	this.overlay = false
	        	this.openDialogError(error.body.message)
	        })
      	}else{
      		this.openDialogError('Favor de seleccionar un ciclo')
      	}
      },

      getGruposTeacher (){
      	this.gruposTeacher = []
      	this.overlay = true
      	let payload = {
      		email: this.getdatosUsuario.email,
      		inbi:  this.cicloInf.id_ciclo,
      		fast:  this.cicloInf.id_ciclo_relacionado
      	}
      	return this.$http.post(`operaciones.reprobados.teachers`,payload).then(response=>{
    			this.gruposTeacher = response.data
          // Quitamos el loader
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError(error.body.message)
        })
      },

      getGruposAlumnosERP () {
      	// Activamos el loader
    		this.overlay = true
    		// Hacemos la peticion
    		this.$http.get(`lms.catalogos.gruposalumnos.cron/${ this.cicloInf.id_ciclo }/${ this.cicloInf.id_ciclo_relacionado }`).then(response=>{
    			// Mandamos a llamar a la funcion principal para que cargue los gruposAlumnos
          this.getGruposAlumnos()
          // Quitamos el loader
          this.overlay = false
        }).catch(error=>{
        	this.overlay = false
        	this.openDialogError(error.body.message)
        })
      },

      openDialogError (mensaje) {
      	this.error       = mensaje
      	this.dialogError = true
      },

      openDialogSuccess ( mensaje ){
      	this.successMensaje = mensaje
      	this.dialogSuccess  = true
      },

      // Sirve para cargar las notas del ticket
      cargarNotas ( payload ) {
        this.alumno = payload
        this.overlay  = true
        this.mensajes = []

        this.$http.post('operaciones.reprobados.notas', payload).then(response=>{
          this.mensajes        = response.data
          this.overlay         = false
          this.dialogMensajes  = true
        }).catch(error=>{
          this.error = error.body
          this.dialogError     = true
          this.overlay         = false
          this.dialogRechazar  = false
          this.dialogHistorial = false
          this.dialog          = false
        })
      },

      // Subir la nota del ticket
      enviarMensaje () {
        if(this.mensaje != '' && this.mensaje != null){
          this.overlay = true
          let payload = {
            id_usuario:         this.getdatosUsuario.iderp,
            id_grupo:           this.alumno.id_grupo,
            id_alumno:          this.alumno.id_alumno,
            id_unidad_negocio:  this.alumno.id_unidad_negocio,
            nota:               this.mensaje,
            tipo_usuario:       2,
          }

          this.$http.post('operaciones.reprobados.notas.add',payload).then(response=>{
            this.mensaje  = ''
            this.mensajes = []
            this.cargarNotas( this.alumno )
            this.overlay = false
          }).catch(error=>{
            this.error = error.body.message
            this.dialogError = true
            this.overlay = false
            this.dialogRechazar = false
          })
        }else{
          this.error = 'Ingresa un mensaje'
          this.dialogError = true
          this.dialogRechazar = false
        }
      }, 

      copiarPortapapeles () {
        navigator.clipboard.writeText(this.error).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      }

    },

    computed:{
    	...mapGetters('login',['getdatosUsuario'])
    }
  }
</script>

<style>
	.v-snack__content{
		padding: 0px !important;
	}
</style>