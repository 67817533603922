<template>
  <v-container class="mt-4 ml-2">
    <v-row>
      <v-col cols="12">
	      <v-card class="shadowCard">
	      	<v-card-title class="text-subtitle-1">
		        Grupo Teacher
		        <v-spacer></v-spacer>
		        <v-btn color="primary" @click="getGruposTeachers()" small class="mr-2">Consultar</v-btn>
		        <v-btn color="secondary" @click="getGruposTeachersERP()" small>Cargar del ERP</v-btn>
	      	</v-card-title>
	        <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
	        	<!-- Menu superior de tabs -->

		        <v-tabs
		        	v-if="gruposTeachers"
			        dense
			        class="mt-4"
			        v-model="tab"
			        background-color="white"
			      >
			        <v-tabs-slider color="primary"></v-tabs-slider>


			        <v-tab
			          v-for="(item,i) in items"
			          :key="i"
			        >
			          <v-icon v-text="item.icon" left></v-icon>{{ item.name }}
			        </v-tab>


			        <!-- Apartado de las secciones de los tabs -->
			        <v-tabs-items v-model="tab">
			          <v-tab-item>
			           <v-card class="elevation-0">

                    <v-row>
                      <v-col cols="3" md="3" lg="3">
                        <v-autocomplete
                          label="Grupo/Curso"
                          id="id"
                          filled
                          dense
                          hide-details
                          v-model="cursoFAST"
                          :items="[
                            { id: 'LUNES A VIERNES', nombre: 'Lunes a Viernes'  },
                            { id: 'LUNES MIERCOLES Y VIERNES', nombre: 'Lunes, Miercoles y Viernes' },       
                            { id: 'SABATINO', nombre: 'Sabatino' },    
                            { id: 'DOMINICAL', nombre: 'Dominical' },        
                            { id: 'CERTIFICACION', nombre: 'Certificacion' },    
                            { id: 'INDUCCION', nombre: 'Induccion' }, 
                          ]"
                          item-text="nombre"
                          item-value="id"
                          clearable
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="search1"
                          label="Buscar"
                          append-icon="mdi-magnify"
                          filled
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                      
		                <v-card-text>	
		                	<v-data-table
                        :search="search1"
										    dense
										    :headers="headers"
										    :items="filterGruposFAST"
										    item-key="id"
										    class="elevation-0"
										  >
                        <template v-slot:item.curso="props">
                          <v-edit-dialog
                            :return-value.sync="props.item.curso"
                            @save="save( 2, props.item )"
                            large
                            cancel-text="Cancelar"
                            save-text="Guardar"
                          >
                            {{ props.item.curso }}
                            <template v-slot:input>
                              <v-autocomplete
                                filled
                                dense
                                clearable
                                v-model="props.item.id_curso"
                                :items="cursosFAST"
                                label="Selecciona el curso"
                                persistent-hint
                                item-text="nombre"
                                item-value="id"
                                class="mt-4"
                              >
                              </v-autocomplete>
                            </template>
                          </v-edit-dialog>
                        </template>
                      </v-data-table>
		                </v-card-text>	
		              </v-card>
			          </v-tab-item>
			        

			          <!-- horarios de inbi -->
			          <v-tab-item>

                  <v-card class="elevation-0">

                    <v-row>
                      <v-col cols="3" md="3" lg="3">
                        <v-autocomplete
                          label="Grupo/Curso"
                          id="id"
                          filled
                          dense
                          hide-details
                          v-model="cursoINBI"
                          :items="[
                            { id: 'LUNES A VIERNES', nombre: 'Lunes a Viernes'  },
                            { id: 'LUNES MIERCOLES Y VIERNES', nombre: 'Lunes, Miercoles y Viernes' },       
                            { id: 'SABATINO', nombre: 'Sabatino' },    
                            { id: 'DOMINICAL', nombre: 'Dominical' },        
                            { id: 'CERTIFICACION', nombre: 'Certificacion' },    
                            { id: 'INDUCCION', nombre: 'Induccion' }, 
                            { id: 'TEENS SABATINO', nombre: 'TEENS Sabatino' }, 
                            { id: 'TEENS LUN Y MIE', nombre: 'TEENS Lunes y Miercoles' }, 
                            { id: 'TEENS MAR Y JUE', nombre: 'TEENS Martes y Jueves' }
                          ]"
                          item-text="nombre"
                          item-value="id"
                          clearable
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="search2"
                          label="Buscar"
                          append-icon="mdi-magnify"
                          filled
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                      
                    <v-card-text> 
                      <v-data-table
                        :search="search2"
                        dense
                        :headers="headers"
                        :items="filterGruposINBI"
                        item-key="id"
                        class="elevation-0"
                      >
                        <template v-slot:item.curso="props">
                          <v-edit-dialog
                            :return-value.sync="props.item.curso"
                            @save="save( 1, props.item )"
                            large
                            cancel-text="Cancelar"
                            save-text="Guardar"
                          >
                            {{ props.item.curso }}
                            <template v-slot:input>
                              <v-autocomplete
                                filled
                                dense
                                clearable
                                v-model="props.item.id_curso"
                                :items="cursosINBI"
                                label="Selecciona el curso"
                                persistent-hint
                                item-text="nombre"
                                item-value="id"
                                class="mt-4"
                              >
                              </v-autocomplete>
                            </template>
                          </v-edit-dialog>
                        </template>
                      </v-data-table>
                    </v-card-text>  
                  </v-card>
			          </v-tab-item>


			          <!-- Resultados -->
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">

			              	
			              </v-card>
			            </v-col>
			          </v-tab-item>

			        </v-tabs-items>
			      </v-tabs>
	        </v-card-text>
	      </v-card>		
      </v-col>
    </v-row>
   
    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search1:'',
      search2:'',
    	row:null,
      cursoINBI: null,
      cursoFAST: null,
    	tab: {name:'GRUPOS FAST'   , icon:'mdi-file' }, 
      items: [
        {name:'GRUPOS FAST'    , icon:'mdi-file' }, 
        {name:'GRUPOS INBI'    , icon:'mdi-file' }, 
      ],

      headers: [
        { text: 'id'        , value: 'id_grupo' },
        { text: 'editado'   , value: 'editado' },
        { text: 'grupo'     , value: 'grupo' },
        { text: 'curso'     , value: 'curso' },
        { text: 'nivel'     , value: 'id_nivel' },
        { text: 'id1'		    , value: 'id_teacher' },
        { text: 'teacher1'  , value: 'teacher' },
        { text: 'id2'       , value: 'id_teacher_2' },
        { text: 'teacher2'  , value: 'teacher2' },
      ],

      gruposTeachers:[],
			horarios:null,
			cicloInf: 		null,
      ciclos:  			[],
      cursosFAST: [],
      cursosINBI: [],
    }),

    watch: {
      cicloInf () {
        if(this.cicloInf){
          this.getGruposTeachers()
        }
      },
    },

   
    async created () {
      await this.initialize()
      await this.getCursosLMS()
    },

    methods: {
    	initialize () {
      	this.cargar = true
        this.ciclos = []
        return this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCursosLMS() {
        this.cargar = true
        this.cursosFAST = []
        this.cursosINBI = []

        return this.$http.get('lms.cursos.configurar').then(response=>{
          this.cursosFAST = response.data.cursosFAST
          this.cursosINBI = response.data.cursosINBI
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getGruposTeachers () {
      	if(this.cicloInf){
	      	// vaciamos el horario
	      	this.gruposTeachers = null
	      	// Activamos el loader
	    		this.cargar = true
	    		// Hacemos la peticios
	    		this.$http.get(`lms.catalogos.gruposteachers.all/${ this.cicloInf.id_ciclo }/${ this.cicloInf.id_ciclo_relacionado }`).then(response=>{
	    			// Guardamos la respuesta
	          this.gruposTeachers = response.data
            console.log('INFO',this.gruposTeachers)
	          // Quitamos el loader
	          this.cargar = false
	        }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
      	}else{
      		this.validarErrorDirecto('Favor de seleccionar un ciclo')
      	}
      },

      getGruposTeachersERP () {
      	// Activamos el loader
    		this.cargar = true
    		// Hacemos la peticion
    		this.$http.get(`lms.catalogos.gruposteachers.cron/${ this.cicloInf.id_ciclo }/${ this.cicloInf.id_ciclo_relacionado }`).then(response=>{
    			// Mandamos a llamar a la funcion principal para que cargue los gruposTeachers
          this.getGruposTeachers()
          // Quitamos el loader
          this.cargar = false
        }).catch(error=>{
        	this.cargar = false
        	this.validarErrorDirecto(error.body.message)
        })
      },

      save ( escuela, value ) {

        const cursos = escuela == 2 ? this.cursosFAST : this.cursosINBI

        const existeCurso = cursos.find( el => el.id == value.id_curso )
        if( !existeCurso ){ this.validarErrorDirecto( 'El curso que elegiste no existe' ) }

        const payload = { ...value, ...existeCurso, escuela }
        console.log('1',value)
        console.log('2',existeCurso)
        console.log('3',escuela)
        // Activamos el loader
        this.cargar = true
        // Hacemos la peticion
        this.$http.post(`lms.configurar.grupo`, payload ).then(response=>{
          this.validarSuccess('Configuración correcta del grupo')
          // Mandamos a llamar a la funcion principal para que cargue los gruposTeachers
          this.getGruposTeachers()
          // Quitamos el loader
          this.cargar = false
        }).catch(error=>{
          this.cargar = false
          this.validarErrorDirecto(error.body.message)
        })

      },

    },

    computed:{
    	...mapGetters('login',['getdatosUsuario']),

      filterGruposINBI() {
        if ((this.cursoINBI  && this.cursoINBI != 'LUNES A VIERNES' && this.cursoINBI != 'LUNES MIERCOLES Y VIERNES' && this.cursoINBI != 'TEENS MAR Y JUE' && this.cursoINBI != 'TEENS LUN Y MIE' && this.cursoINBI != 'SABATINO')) {        
          return this.gruposTeachers.gruposTeachersInbi.filter((el) => {
             return el.grupo.includes(this.cursoINBI);
          });   
        } 
        else if ((this.cursoINBI  && this.cursoINBI == 'SABATINO')) {        
          return this.gruposTeachers.gruposTeachersInbi.filter((el) => {
             return el.grupo.includes(this.cursoINBI) &&
                    !el.grupo.includes('TEENS')
          });
        }
        else if ((this.cursoINBI  && this.cursoINBI == 'TEENS LUN Y MIE')) {        
          return this.gruposTeachers.gruposTeachersInbi.filter((el) => {
             return el.grupo.includes(this.cursoINBI) ||
                    el.grupo.includes('LUN Y MIE')
          });
        }
        else if ((this.cursoINBI  && this.cursoINBI == 'TEENS MAR Y JUE')) {        
          return this.gruposTeachers.gruposTeachersInbi.filter((el) => {
             return el.grupo.includes(this.cursoINBI) ||
                    el.grupo.includes('MARTES Y JUEVES') ||
                    el.grupo.includes('MAR Y JUE')
          });
        }
        else if ((this.cursoINBI  && this.cursoINBI == 'LUNES MIERCOLES Y VIERNES')) {        
          return this.gruposTeachers.gruposTeachersInbi.filter((el) => {
             return el.grupo.includes(this.cursoINBI) ||
                    el.grupo.includes('LUN MIE VIE')
          });
        }
        else if ((this.cursoINBI && this.cursoINBI == 'LUNES A VIERNES')) {        
          return this.gruposTeachers.gruposTeachersInbi.filter((el) => {
            return  !el.grupo.includes('LUNES MIERCOLES Y VIERNES') &&
                    !el.grupo.includes('SABATINO') &&
                    !el.grupo.includes('DOMINICAL') &&
                    !el.grupo.includes('CERTIFICACION') &&
                    !el.grupo.includes('INDUCCION') &&
                    !el.grupo.includes('TEENS SABATINO') &&
                    !el.grupo.includes('TEENS LUN Y MIE') &&
                    !el.grupo.includes('TEENS MAR Y JUE') &&
                    !el.grupo.includes('MAR Y JUE') &&
                    !el.grupo.includes('LUN MIE VIE') &&
                    !el.grupo.includes('MARTES Y JUEVES') &&
                    !el.grupo.includes('LUN Y MIE')
          });
        }
        else {
          return  this.gruposTeachers.gruposTeachersInbi;
        }
      },

      filterGruposFAST() {
        if ((this.cursoFAST && this.cursoFAST != 'LUNES A VIERNES')) {        
          return this.gruposTeachers.gruposTeachersFast.filter((el) => {
            return el.grupo.includes(this.cursoFAST);
          });
        } else if ((this.cursoFAST && this.cursoFAST == 'LUNES A VIERNES')) {        
          return this.gruposTeachers.gruposTeachersFast.filter((el) => {
            return  !el.grupo.includes('SABATINO') &&
                    !el.grupo.includes('DOMINICAL') &&
                    !el.grupo.includes('CERTIFICACION') &&
                    !el.grupo.includes('INDUCCION') &&
                    !el.grupo.includes('LUNES MIERCOLES Y VIERNES')
          });
        }
        else {
          return this.gruposTeachers.gruposTeachersFast;
        }
      },

    }
  }
</script>

<style>
	.v-snack__content{
		padding: 0px !important;
	}
</style>