<template>
	<v-dialog
    v-model="abrirDialogProspecto.estatus"
    max-width="600px"
  >
		<v-card>
      <v-card-title>
        <v-card width="80" class="elevation-0 transparent">
          <v-img 
          	v-if="getEscuela == 2"
            src="@/assets/logo_fast.png"
          ></v-img>

          <v-img 
          	v-else
            src="@/assets/logo_inbi.png"
          ></v-img>

        </v-card>
        <v-spacer></v-spacer>
        <span class="text-subtitle-1 font-weight-bold text-center">
          Nuevo prospecto
        </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>

            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="editedItem.nombre_completo"
                label="Nombre completo"
                filled
                hide-details
                dense
                autofocus
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.idmedio_contacto"
                :items="medios_contacto"
                label="¿Cómo nos conocio?"
                persistent-hint
                item-value="idmedio_contacto"
                item-text="medio"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.idfuentes"
                :items="fuentes"
                label="¿Cómo nos contactó?"
                item-value="idfuentes"
                item-text="fuente"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.iddetalle_fuentes"
                :items="detallesFilter"
                label="Detalle"
                persistent-hint
                item-value="iddetalle_fuentes"
                item-text="detalle_fuente"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-select
                :items="cursos_escuela"
                v-model="editedItem.idcursos_escuela"
                item-text="curso"
                item-value="idcursos_escuela"
                label="Curso interes"
                hide-details
                filled
                dense
              ></v-select>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-switch label="Foraneo" v-model="editedItem.foraneo" hide-details></v-switch>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-subheader class="mb-0 pb-0 mt-0">Información de contacto</v-subheader>
            </v-col>


            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="editedItem.telefono"
                label="Teléfono"
                filled
                hide-details
                type="number"
                dense
                @keyup.enter="buscarTelefono( editedItem.telefono )"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-select
                :items="vendedoras_plantel"
                v-model="editedItem.sucursal"
                return-object
                item-text="nombre_plantel"
                item-value="nombre_plantel"
                label="Sucursal"
                hide-details
                filled
                dense
              ></v-select>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-textarea
                v-model="editedItem.nota_inicial"
                filled
                dense
                rows="3"
                hide-details
                label="Nota inicial"
              > 
              </v-textarea>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="red"
          dark
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          class="elevation-6"
          dark
          @click="save"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-dialog>
</template>

<script>
import axios from 'axios';
  import { mapGetters, mapActions } from 'vuex';
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import nuevo_lead            from '@/components/leads/nuevo_lead.vue';
  import nuevo_prospecto       from '@/components/leads/nuevo_prospecto.vue';
  import validar_escuela       from '@/components/leads/validar_escuela.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import mixinLeads            from '@/mixins/mixinLeads'

  export default {
    components:{
      Alerta,
      carga,
      nuevo_lead,
      nuevo_prospecto,
      validar_escuela,
    },

    props:[
			'abrirDialogProspecto',
	  ],

    mixins: [ validarErrores, mixinLeads ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      valid: true,
      nameRules: [
        v => !!v || 'Teléfono es requerido',
        v => (v && !(v < 1000000000 )) || 'No son 10 números',
      ],


      ///usuarios
      dialog: false,
      // Modales
      dialogDelete: false,
      dialogNuevoProspecto: false,
      dialogValidarEscuela: false,
      dialogEditar:false,

      
      prospectos: [], //arreglo
      editedIndex: -1,
      editedItem: {
        nombre_completo:'',
        idleds:'',
        escuela: 2,
        iddetalle_fuentes:0,
        idfuentes: 0,
        fuente: '',
        estatus: '',
        foraneo: 0,
        idcursos_escuela: 0,
        telefono:'',
        sucursal: null,
        nota_inicial:'El prospecto desea información',
        idmedio_contacto:0,
        idcampanias:0,
        id_sucursal_interes:0,
        curso_interes: 0,
        horario_interes:'',
      },
      defaultItem: {
        nombre_completo:'',
        idfuentes: '',
        idleds:'',
        escuela: 2,
        iddetalle_fuentes:0,
        fuente: 0,
        estatus: '',
        foraneo: 0,
        idcursos_escuela: 0,
        telefono:'',
        sucursal: null,
        nota_inicial:'El prospecto desea información',
        idmedio_contacto:0,
        idcampanias:0,
        id_sucursal_interes:0,
        curso_interes: 0,
        horario_interes:'',
      },
      // Datos del prospecto
      prospecto: {
        //agrego cada campo a utlizar
        idprospectos: 0,
        nombre_completo: '',
        telefono:'',
        correo: '',
        idetapa:0,
        sucursal_interes:0,
        como_nos_conocio:0,
        ciclo_interes: 0,
        curso_interes:0,
        usuario_asignado:0,
        usuario_creo:0,
        nota_inicial:'',
        folio:'',
        idleds:0,
        id_sucursal_interes:0,
        curso_interes: 0,
        horario_interes:'',
      },

      etapas:[],
      etapa:null,
      sucursales:[],
      sucursal:null,
      vendedoras:[],
      vendedoras_plantel:[],
      vendedora:null,
      campanias:[],
      campania:null,
     
      escuela: 2,

      sucursales:[],
      sucursal:null,
      vendedoras:[],
      vendedoras_plantel:[],
      vendedora:null,
      campanias:[],
      campania:null,

      fechaini:null,
      fechafin:null,

      cursos_escuela:[],
      medios_contacto:[],

      searchTelefono:'',
      // Consultas extras
      detalles:[],
      fuentes:[]

    }),

    watch:{
      nuevaSucursal(){
        this.nombreVendedora = this.nuevaSucursal.nombre
      }
    },

    computed: {
      filterSucursales(){
        if(this.sucursales){
          return this.sucursales.filter( el=> { return el.escuela == this.escuela})
        }
      },

      filterVendedoras(){
        return this.vendedoras.filter( el=> { return el.escuela == this.escuela})
      },

      detallesFilter(){
        return this.detalles.filter(el => { return el.idfuentes == this.editedItem.idfuentes })
      },

      campaniasFilter(){
        return this.campanias.filter(el => { return el.idmedio_contacto == this.editedItem.idmedio_contacto })
      },

      ...mapGetters('login',['getdatosUsuario','getLogeado', 'getEscuela','getEstatusLlamada','getTareas','getNuevosProspectos']),
      ...mapGetters('tema', ['getTema']),
      ...mapGetters('Carrito',['getCarrito']),
    },

    async created() {
      this.cargar = true
      await this.getSucursales()
      await this.getVendedoras()

      await this.getFuntes()
      await this.getDetallesFuente()
      await this.getCampanias()
      await this.getCursosEscuela()
      await this.getComoNosConocio()
      this.dialogNuevoProspecto = true

      this.cargar = false
    },

    methods: {
      getComoNosConocio () {
	      this.medios_contacto = []
	      this.cargar = true
	      return this.$http.get('mediocontacto.activas').then(response=>{
	      	this.medios_contacto = response.data
	      	this.cargar      = false
	      }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
	    },

	    getFuntes () {
	      this.fuentes = []
	      this.cargar = true
	      return this.$http.get('fuentes.activos').then(response=>{
	      	this.fuentes = response.data.filter( el => { return el.vendedora == 1 })
	      	this.cargar      = false
	      }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
	    },
	    
	    getDetallesFuente () {
	      this.detalles = []
	      this.cargar = true
	      return this.$http.get('fuentes.detalle.activos').then(response=>{
	      	this.detalles = response.data
	      	this.cargar      = false
	      }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
	    },

	    // Obtener las campañas
	    getCampanias(){
	      this.cargar = true
	      this.campanias  = []
	      return this.$http.get('campanias.activos').then((response) => {
	        // Llenamos la etapa incial
	        this.campanias = response.data
	        this.campania  = this.campanias[0]
	        this.cargar      = false
	      }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
	    },

	    // obtener las vendedoras
	    getVendedoras() {
	      this.cargar = true
	      this.vendedoras  = []
	      return this.$http.get('vendedoras.list').then((response) => {
	        // Llenamos la etapa incial
          console.log( 'vendedoras',response.data )
	        this.vendedoras = response.data
	        this.vendedoras_plantel = this.vendedoras.filter(el=> { return el.escuela == this.getEscuela })
	        this.vendedoras_plantel.push({idplantel: 0, plantel:'SIN PLANTEL', nombre:'NA', nombre_plantel: 'SIN PLANTEL'})
	        this.cargar      = false
	      }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
	    },

	    getCursosEscuela() {
	      this.cargar = true
	      this.cursos_escuela  = []
	      return this.$http.get('escuela.cursos.activos').then((response) => {
	        // Llenamos la etapa incial
	        this.cursos_escuela = response.data.filter( el=> { return el.escuela == this.getEscuela  })
	        this.cargar      = false
	      }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
	    },

	    /****************************/
	    /****   	FUNCIONES      ****/
	    /****************************/

	    preLlenarDatos ( item ) {
	    	this.prospecto.nombre_completo = item.nombre_completo
	    	this.prospecto.idleds          = item.idleds
	    	this.dialogNuevoProspecto      = true
	    },
	    
	    addContacto ( lead , idleds ) {
	      console.log( lead )
	      // Sacamos a la vendedora
	      let vendedora = 0
        if(this.editedItem.sucursal.idplantel != 0){
          vendedora = this.editedItem.sucursal
        }

        this.editedItem.escuela = this.getEscuela
	      console.log( 0, vendedora, idleds )
	      //recibe los campos del form
	      //guardo en una variable los datos a guardar id = this.editedItem.idcurso;
	      var payload = {
	        nombre_completo:  lead.nombre_completo,
	        telefono:         lead.telefono,
	        correo:           '',
	        sucursal_interes  : this.editedItem.sucursal.idplantel ? this.editedItem.sucursal.idplantel : 0,
          sucursal_interes_2: this.editedItem.id_sucursal_interes,
	        usuario_creo:     this.getdatosUsuario.iderp,
	        usuario_asignado: vendedora == 0 ? vendedora : vendedora.iderp,
	        nota_inicial:     lead.nota_inicial,
	        folio:            '',
	        acronimo:         this.editedItem.sucursal.idplantel == 0 ? '' : this.editedItem.sucursal.acronimo,
	        escuela:          this.getEscuela,
	        idpuesto:         this.getdatosUsuario.idpuesto,
	        como_llego:       0,
	        idleds:           idleds,
          curso_interes:    this.editedItem.curso_interes,
          horario_interes: this.editedItem.horario_interes,
	      }
	      this.cargar = true

	      this.$http.post('prospectos.add', payload).then(response  => {
	        this.cargar = false
	        this.editedItem = Object.assign({}, this.defaultItem)
	        this.editedItem.sucursal  = null
	        this.anuncio   = null
	        this.vendedora = null
	        this.validarSuccess( 'Contacto generado correctamente, FOLIO: ' + response.data.folio )
	        this.cargar      = false
	      }).catch( error =>{
	        this.eliminarLead( idleds )
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
	    },

	    eliminarLead( idleds ){
	      this.$http.delete('leds.delete/' + idleds ).then(response  => {
	        this.initialize();
	      }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
	    },

			save ( item ) {
	    	if(!this.editedItem.idfuentes){
	    		return this.validarErrorDirecto( 'Favor de llenar todos los campos' )
	    	}

	      // Ponerle pendiente a su nombre completo
	      if(this.editedItem.nombre_completo == ''){ this.editedItem.nombre_completo = 'Pendiente' }

	    	// Activamos el loader
	      this.cargar = true
	      // Lo mandapos por el EP
        this.editedItem.escuela = this.getEscuela
	      this.$http.post('leds.add', this.editedItem).then(response=>{
	        if( this.editedItem.telefono ){
	          this.addContacto( this.editedItem , response.data.id )
	        }else{
	        	this.validarSuccess( 'Lead agregado correctamente ')
	        }
	      	this.cargar      = false
	      }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
	    },

	    deleteItemConfirm () {
	      // Lo mandapos por el EP
	      this.editedItem.deleted = 1
	      this.cargar = true
        this.editedItem.escuela = this.getEscuela
	      this.$http.put('fuentes.update/' + this.editedItem.idfuentes, this.editedItem).then(response=>{
	      	this.dialogSuccess = true
	      	this.cargar = false
	      	this.closeDelete()
	        this.cargar      = false
	      }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
	    },

	    getSucursales() {
        this.cargar = true
        this.sucursales  = []
        return this.$http.get('planteles.all').then((response) => {
          // Llenamos la etapa incial
          this.sucursales = response.data
          this.sucursal   = response.data[0]
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

	    buscarTelefono( value ){
	      if( value ||  this.searchTelefono){
	        this.cargar = true

	        const payload = {
	          telefono: value ? value : this.searchTelefono,
	          escuela: this.escuela
	        }

	        this.$http.post('leds.telefono', payload).then( response => {
	          this.validarSuccess( response.data.message )
	        }).catch( error => {
	          this.cargar = false
	          alert(`No existe :p`)
	        }).finally( ()=> { this.cargar = false })
	      }
	    },

	    cerrarActualizar( ){
	      this.editedItem    = Object.assign({}, this.defaultItem)
	      this.dialogEditar  = false
	    },

	    close(){
	    	this.abrirDialogProspecto.estatus = false
	    }

    },
  };
</script>
<style scoped>

  #back{
    background-color: #EEEEEE;
  }

  #menuable__content__active::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  #menuable__content__active::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  #menuable__content__active::-webkit-scrollbar:vertical{
    width: 6px !important;
  }

  #menuable__content__active::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  #menuable__content__active::-webkit-scrollbar:horizontal{
    height: 6px !important;
  }

  #menuable__content__active::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

</style>

