<template>
  <v-container class="mt-5 ml-2 pr-6" fluid>
    <v-row>
    	<v-col cols="12">
    		<v-card>
			    <v-data-table
			      :headers="headers"
			      :items="contratos"
			      :search="search"
			      dense
			      fixed-header
			    >
			    	<template v-slot:top>
				      <v-toolbar
				        flat
				      >
				        <v-toolbar-title>Contratos</v-toolbar-title>
				        <v-divider
				          class="mx-4"
				          inset
				          vertical
				        ></v-divider>
				        <v-spacer></v-spacer>
				        <v-btn 
			            class="mr-2" 
			            small dark color="green"
			            @click="exportar()">
			            <v-icon>mdi-microsoft-excel</v-icon>
			          </v-btn>
				        <v-btn color="primary"   small rounded @click="getContratos()" class="mr-2">Actualizar</v-btn>
				        <v-btn color="secondary" small rounded @click="diagloAddContrato = true">Agregar Contrato</v-btn>
				      </v-toolbar>
	            <v-card-text>
	              <v-row>
	                <v-col cols="12">
	                	<v-text-field
						          label="Buscar"
						          v-model="search"
						          dense
						          hide-details
						          outlined
						        ></v-text-field>
	                </v-col>
	              </v-row>
	            </v-card-text>
				    </template>

				    <template v-slot:item.estatus="{ item }">
				      <v-chip small dark color="primary" v-if="item.estatus==0">Entregado</v-chip>
				      <v-chip small dark color="secondary" v-else @click="abrirContrato(item.folio)">Firmado</v-chip>
				    </template>

            <template v-slot:item.clip="{ item }">
              <v-chip small dark color="secondary" @click="copiarPortapapeles(item.folio)"><v-icon>mdi-content-copy</v-icon></v-chip>
            </template>

			    </v-data-table>
    		</v-card>
    	</v-col>
    </v-row>

    <!-- Dialog add contrato -->

    <v-dialog
      v-model="diagloAddContrato"
      hide-overlay
      max-width="600"
    >
      <v-card
        style="position: relative"
      >
        <v-card-title primary-title>
          Nuevo contrato
        </v-card-title>
        <v-card-text>
	    		<v-autocomplete
	    			outlined
	    			dense
	    			clearable
		        v-model="usuario"
		        :items="usuarios"
		        label="Selecciona un usuario"
		        persistent-hint
		        prepend-inner-icon="mdi-account"
		        hide-details
		        return-object
		        item-text="nombre_completo"
		        item-value="id_usuario"
		      >
		      </v-autocomplete>
        </v-card-text>
        <v-card-actions>
        	<v-spacer></v-spacer>
        	<v-btn color="secondary" text class="mr-2" small @click="diagloAddContrato = false">Cancelar</v-btn>
          <v-btn color="primary"  small @click="generarSolicitud()">Solicitar Firma de contrato</v-btn>
        </v-card-actions>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="diagloAddContrato = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="300"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card
        color="green"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Datos grabados
        </v-card-title>
        <div class="pl-6 pb-6">
          <br/>
          NOTA: {{successMensaje}}
        </div>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogSuccess = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
	  
    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card
        color="error"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Error al recuperar los datos
        </v-card-title>
        <v-card-text>
          <br/>
          NOTA: {{error}}
        </v-card-text>
        <v-btn 
        	color="transparent elevation-0"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogError = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import axios from 'axios';
	import XLSX from 'xlsx'
  export default {
    data () {
      return {
        dialogError: 	false,
				dialogSuccess:false,
				error: 				'',
				successMensaje:'',
				overlay: 			false,

        search: '',
        headers: [
          { text: 'Id'                , value: 'idfolios_contratos' },
          { text: 'Folio'             , value: 'folio' },
          { text: 'Nombre'            , value: 'nombre_completo' },
          { text: 'Registro'          , value: 'fecha_creacion' },
          { text: 'Copiar'            , value: 'clip' },
          { text: 'Estatus'           , value: 'estatus' },
        ],
        contratos: [],

        overlay:false,
	      dialogError: false,
	      error:'',

        diagloAddContrato: false,
        usuario: null,
      	usuarios: [],
      }
    },

    async created () {
    	await this.getContratos()
    	await this.getUsuarios()
    },

    methods: {
    	getUsuarios () {
    		this.usuarios = []
    		this.$http.get('usuarios.all.erp').then(responseUsuarios=>{
    			for(const i in responseUsuarios.body){
    				responseUsuarios.body[i].nombre_completo = responseUsuarios.body[i].nombre_completo.toUpperCase() 
    			}
    			this.usuarios = responseUsuarios.body
    		}).catch(error=>{
    			this.overlay = false
        	this.openDialogError(error.body.message)
    		})
      },

      abrirContrato ( folio ) {
        window.open(axios.defaults.baseURL + 'contratos/' + folio + '.pdf')
      },

    	getContratos () {
        this.overlay = true
    		this.contratos = []
    		this.$http.get('operaciones.contrato.all').then(response=>{
    			this.contratos = response.data
    			this.overlay = false
    		}).catch(error=>{
        	this.overlay = false
        	this.openDialogError(error.body.message)
    		})
      },

      generarSolicitud () {
      	if(this.usuario){
	      	this.$http.post('operaciones.contrato.add', this.usuario).then(response=>{
	        	this.overlay = false
	        	this.diagloAddContrato = false
	      		this.getContratos()
	      		this.openDialogSuccess('Contrato generado correctamente, Buen día!') 
	        }).catch(error=>{
	        	this.error = error.body.message
	        	this.dialogError = true
	        	this.overlay = false
	        })
      	}else{

      	}
      },

    	openDialogError (mensaje) {
      	this.error       = mensaje
      	this.dialogError = true
      },

      openDialogSuccess ( mensaje ){
      	this.successMensaje = mensaje
      	this.dialogSuccess  = true
      },

      update ( item ) {
      	this.$http.post('kpi.clases.hora.dia.update', item).then(response=>{
        	this.overlay = false
      		this.getGrupos()
        }).catch(error=>{
        	this.error = error.body.message
        	this.dialogError = true
        	this.overlay = false
        })
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportar(){
        let data = XLSX.utils.json_to_sheet(this.teachers)
        const workbook = XLSX.utils.book_new()
        const filename = 'clase_iniciada_fast'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });

      },


      copiarPortapapeles (folio) {
        let clip = ''
        if(process.env.NODE_ENV == 'development'){
          clip = 'http://localhost:8080/#/contrato/' + folio
        }else{
          clip = 'https://kpi.schoolofenglishadmin.com/#/contrato/' + folio
        }

        navigator.clipboard.writeText(clip).then(() => {
          console.log("Text copied to clipboard...")
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      }
    }
  }
</script>