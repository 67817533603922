var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"transparent elevation-0"},[_c('v-card-text',[_c('v-data-table',{staticClass:"shadowCard",attrs:{"headers":_vm.headers,"items":_vm.tablaPagosSucursal,"dense":"","mobile-breakpoint":"200","items-per-page":_vm.tablaPagosSucursal.length,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',{attrs:{"primary-title":""}},[_c('label',{staticClass:"text-subtitle-1 black--text"},[_vm._v("Pagos recibidos por mi sucursal")]),_c('v-spacer')],1)]},proxy:true},{key:"item.plantel",fn:function(ref){
var item = ref.item;
return [(
              item.id_plantel == _vm.plantel ||
              item.id_plantel == 1000
            )?_c('div',{staticClass:"green white--text pr-2"},[_vm._v(" "+_vm._s(item.plantel)+" ")]):_c('div',{staticClass:"pr-2"},[_vm._v(_vm._s(item.plantel))])]}},{key:"item.total",fn:function(ref){
            var item = ref.item;
return [(
              item.id_plantel == _vm.plantel ||
              item.id_plantel == 1000
            )?_c('div',{staticClass:"green white--text pr-2"},[_vm._v(" "+_vm._s(item.total)+" ")]):_c('div',{staticClass:"pr-2"},[_vm._v(_vm._s(item.total))])]}},{key:"item.efectivo",fn:function(ref){
            var item = ref.item;
return [(
              item.id_plantel == _vm.plantel ||
              item.id_plantel == 1000
            )?_c('div',{staticClass:"green white--text pr-2"},[_vm._v(" "+_vm._s(item.efectivo)+" ")]):_c('div',{staticClass:"pr-2"},[_vm._v(_vm._s(item.efectivo))])]}},{key:"item.transferencia",fn:function(ref){
            var item = ref.item;
return [(
              item.id_plantel == _vm.plantel ||
              item.id_plantel == 1000
            )?_c('div',{staticClass:"green white--text pr-2"},[_vm._v(" "+_vm._s(item.transferencia)+" ")]):_c('div',{staticClass:"pr-2"},[_vm._v(_vm._s(item.transferencia))])]}},{key:"item.depositos",fn:function(ref){
            var item = ref.item;
return [(
              item.id_plantel == _vm.plantel ||
              item.id_plantel == 1000
            )?_c('div',{staticClass:"green white--text pr-2"},[_vm._v(" "+_vm._s(item.depositos)+" ")]):_c('div',{staticClass:"pr-2"},[_vm._v(_vm._s(item.depositos))])]}},{key:"item.tarjeta",fn:function(ref){
            var item = ref.item;
return [(
              item.id_plantel == _vm.plantel ||
              item.id_plantel == 1000
            )?_c('div',{staticClass:"green white--text pr-2"},[_vm._v(" "+_vm._s(item.tarjeta)+" ")]):_c('div',{staticClass:"pr-2"},[_vm._v(_vm._s(item.tarjeta))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }