import Vue from 'vue'
import store from '@/store'
import {mapGetters, mapActions}    from 'vuex'

import { io }         from "socket.io-client";
import axios from 'axios';

var QRCode = require('qrcode')

export default {
	data () {
		return {
			socket:null,
      socketKPI: null,
      mensajeProceso:'',
      alertaSinTeacher:{
        estatus: false,
        mensaje: '',
      },
      notificaciones:[],
		}
	},
	computed: {
		...mapGetters('login',[ 'getdatosUsuario' ]),
	},

	mounted() {

    if( this.getdatosUsuario.url_servidor  ){

      console.log('SOCKET 1')
      let bloques = this.getdatosUsuario.url_servidor.split('/')

      let rutaSocket = bloques[ bloques.length - 2 ]

      console.log( rutaSocket )

      const url = this.getdatosUsuario.url_servidor.replace(`${rutaSocket}/`, '')
      // Modo servidor
      const path = `/ws${rutaSocket}/socket.io`

      this.socket = io(url, {
        transports: ["websocket"], path
      });

      // Modo local
      // this.socket = io(`ws://localhost:3008/`, {
      //   transports: ["websocket"], path
      // });

      this.socket.on("connect", ( ) => {
        // Conectar cocket
        console.log('estoy conectado al socket, enserio')

        // Mandar a decirle quién se conecto
        this.socket.emit('iniciarSesionWha', this.getdatosUsuario )
      });

      this.socket.on("disconnect", () => {
        console.log('Desconectado del socket');
      });

      // Generar el QR de la recepcionista
      this.socket.on('QR-generado', ( body ) => {

      	if( body.from == this.getdatosUsuario.whatsappservidor ){
	      	// Generamos el QR y lo agregamos al canvas
	        QRCode.toCanvas(document.getElementById('canvas'), body.qr  , (error) => {
	          if (error) console.error(error)
	          console.log('success!');
	        	// Dejamos de cargar
	          this.cargar = false
	        })
      	}
      })

      // Indicamos si la session esta activa o no
      this.socket.on('session-activa', ( body ) => {

      	if( this.getdatosUsuario.whatsappservidor == body  ){
        	this.estatusWhats = 1
          if( this.estatusWhats == 1 ){ this.cargar = false }
      	}
      })

      // Indicamos si la session esta activa o no
      this.socket.on('estatus-whatsapp', ( body ) => {

        if( this.getdatosUsuario.whatsappservidor == body.from  ){
          const canvasElement = document.getElementById('canvas')
          const context = canvasElement.getContext('2d');
          // Limpiar el contenido del canvas
          context.clearRect(0, 0, canvasElement.width, canvasElement.height);

          this.mensajeProceso = body.mensaje
          this.estatusWhats   = body.estatus ? body.estatus : 0

          if( this.estatusWhats == 1 ){ this.cargar = false }
        }
      })

      // Validamos que la sesión este inactiva
      this.socket.on('session-inactiva', ( body ) => {
      	if( this.getdatosUsuario.whatsappservidor == body  ){
          const canvasElement = document.getElementById('canvas')
          const context = canvasElement.getContext('2d');
          // Limpiar el contenido del canvas
          context.clearRect(0, 0, canvasElement.width, canvasElement.height);

        	this.estatusWhats = 2
      	}
      })
    }

    let url = axios.defaults.baseURL.replace('kpi/', '')

    console.log('SOCKET 2')
    console.log( url )
    // Modo servidor
    const path = '/ws/socket.io'
    
    if( !this.socketKPI ){
      this.socketKPI = io(url, {
        transports: ["websocket"], path
      });

      this.socketKPI.on("connect", ( ) => {
        // Conectar cocket
        console.log('estoy conectado al socket del KPI')
      });

      // Generar el QR de la recepcionista
      this.socketKPI.on('falta-teacher', ( body ) => {
        
        // Verificamos que el puesto pueda tener esas alertas
        if( [57,18,50,42,19, 11, 43 ].includes( this.getdatosUsuario.idpuesto ) ){

          const planteles = this.getdatosUsuario.planteles.map((registro)=>{ return registro.idplantel })

          // Comparamos si el usuario tiene asignados esos planteles para poder ver esa alerta
          if( planteles.includes( body.id_plantel )){
            this.alertaSinTeacher.estatus = true
            this.alertaSinTeacher.mensaje = body.mensaje
            this.alertaSinTeacher.type    = 'success'
            this.getNotificaciones()
          }
        }
      })


      // TICKETS
      // Generar el QR de la recepcionista
      this.socketKPI.on('respuesta-ticket', ( body ) => {

        console.log( body)
        if( this.getdatosUsuario.iderp == body.id_usuario ){
          this.alertaSinTeacher.estatus = true
          this.alertaSinTeacher.mensaje = body.mensaje
          this.alertaSinTeacher.type    = body.type ? body.type : 'success'

        }
      })

      this.socketKPI.on("disconnect", () => {
        console.log('Desconectado del socket');
      });
    }

  },

  methods: {
    validarEstatus () {
      this.mensajeProceso = ''

    	// Validamos la información del usuario y que tenga su WhatsAppservidor
    	if( !this.getdatosUsuario.whatsappservidor ){ return this.validarErrorDirecto('FAVOR DE INGRESAR UN TELÉFONO') }

    	// Cargamos y abrimos un cuadro de dialogo 
      this.cargar = true

    	// Creamos el payload que vamos a enviar
      const payload = {
      	from: this.getdatosUsuario.whatsappservidor
      }

      // Genero la ruta oficial para hacer la petición
      this.url_servidor = this.getdatosUsuario.url_servidor + 'whatsapp.activo'

      // Hago la petición
      return this.$http.post(this.url_servidor, payload).then(response=>{
      	this.cargar = false
      	this.dialog = true
      }).catch( error =>{
      	this.estatusWhats   = 3
      	this.dialog         = true
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    // Función para escanear el código QR
		escanerQR () {

    	// Validamos la información del usuario y que tenga su WhatsAppservidor
    	if( !this.getdatosUsuario.whatsappservidor ){ return this.validarErrorDirecto('FAVOR DE INGRESAR UN TELÉFONO') }

    	// Formamos el payload para cargar el usuario
      const payload = {
      	from: this.getdatosUsuario.whatsappservidor
      }
      
      this.cargar = true
    	this.dialog = false

      // Genero la ruta oficial para hacer la petición

      const path = 'whatsapp.inicializar'
      this.url_servidor = this.getdatosUsuario.url_servidor + path

      // Realizo la petición
      return this.$http.post(this.url_servidor, payload).then(response=>{
      	this.dialog = true
      }).catch( error =>{
      	this.dialog = true
        this.validarError( error.response.data.message )
      })
    },

    // Detruir la sesión de wpp por si hay algún error en todo esto
		cerrarSesion () {
    	// Validamos la información del usuario y que tenga su WhatsAppservidor
    	if( !this.getdatosUsuario.whatsappservidor ){ return this.validarErrorDirecto('FAVOR DE INGRESAR UN TELÉFONO') }

      this.cargar = true
    	this.dialog = false

    	// Formamos el payload para cargar el usuario
      const payload = {
      	from: this.getdatosUsuario.whatsappservidor
      }

      // Genero la ruta oficial para hacer la petición
      this.url_servidor = this.getdatosUsuario.url_servidor + 'whatsapp.destruir'
      
      // Realizo la petición
      return this.$http.post(this.url_servidor, payload).then(response=>{
      	// Indico que ya no hay una sessión
      	this.estatusWhats = 2
      	this.cargar = false
      	this.dialog = true
      }).catch( error =>{
      	this.dialog = true
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    
    getNotificaciones(){
      // Realizo la petición
      return this.$http.get('notificaciones/' + this.getdatosUsuario.iderp ).then(response=>{
        // Indico que ya no hay una sessión
        this.notificaciones = response.data
        console.log( 'notificaciones', this.notificaciones )
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    }

  },
}