<template>
  <v-container class="mt-5 ml-2">
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title class="text-subtitle-1">
            NI por Vendedora
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn color="primary" @click="consultar()" small>Consultar</v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  clearable
                  v-model="fechaini"
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha final"
                  filled
                  dense
                  clearable
                  v-model="fechafin"
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center">
            	<v-col cols="12" md="5" lg="3">
            		<v-card color="shadowCard blue darken-4">
            		  <v-card-title primary-title class="py-1">
            		    <div class="white--text">Inscritos Fast: {{totalFast}}</div>
            		  </v-card-title>
            		</v-card>
            	</v-col>

              <v-col cols="12" md="5" lg="3">
                <v-card color="shadowCard blue lighten-1">
                  <v-card-title primary-title class="py-1">
                    <div class="white--text">Inscritos Inbi: {{totalInbi}}</div>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="12">
              	<v-card class="shadowCard">
              		<v-card-title primary-title class="py-1">
              		  Inscritos Fast
              		</v-card-title>
              	  <v-card-text>
        		      	<v-data-table
                      :headers="headers"
                      :items="tablaFast"
                      class="elevation-0"
                      dense
                      mobile-breakpoint="200"
                      :items-per-page="tablaFast.length"
                      hide-default-footer
                    >
                      <template v-slot:item.con_adeudo="{ item }">
                        <v-chip
                          small
                          color="primary"
                          @click="abrirDialog( item.con_adeudoA )"
                        >
                          {{ item.con_adeudo }}
                        </v-chip>
                      </template>

                      <template v-slot:item.sin_adeudo="{ item }">
                        <v-chip
                          small
                          color="primary"
                          @click="abrirDialog( item.sin_adeudoA )"
                        >
                          {{ item.sin_adeudo }}
                        </v-chip>
                      </template>

                      <template v-slot:item.completos="{ item }">
                        <v-chip
                          small
                          color="primary"
                          @click="abrirDialog( item.completosA )"
                        >
                          {{ item.completos }}
                        </v-chip>
                      </template>

                      <template v-slot:item.total="{ item }">
                        <v-chip
                          small
                          color="primary"
                          @click="abrirDialog( item.totalA )"
                        >
                          {{ item.total }}
                        </v-chip>
                      </template>
                    </v-data-table>
              	  </v-card-text>
              	</v-card>
              </v-col>

              <v-col cols="12">
              	<v-card class="shadowCard">
              		<v-card-title primary-title class="py-1">
              		  Inscritos Inbi
              		</v-card-title>
              	  <v-card-text>
        		      	<v-data-table
                      :headers="headers"
                      :items="tablaInbi"
                      class="elevation-0"
                      dense
                      mobile-breakpoint="200"
                      :items-per-page="tablaInbi.length"
                      hide-default-footer
                    >
                      <template v-slot:item.con_adeudo="{ item }">
                        <v-chip
                          small
                          color="primary"
                          @click="abrirDialog( item.con_adeudoA )"
                        >
                          {{ item.con_adeudo }}
                        </v-chip>
                      </template>

                      <template v-slot:item.sin_adeudo="{ item }">
                        <v-chip
                          small
                          color="primary"
                          @click="abrirDialog( item.sin_adeudoA )"
                        >
                          {{ item.sin_adeudo }}
                        </v-chip>
                      </template>

                      <template v-slot:item.completos="{ item }">
                        <v-chip
                          small
                          color="primary"
                          @click="abrirDialog( item.completosA )"
                        >
                          {{ item.completos }}
                        </v-chip>
                      </template>


                      <template v-slot:item.total="{ item }">
                        <v-chip
                          small
                          color="primary"
                          @click="abrirDialog( item.totalA )"
                        >
                          {{ item.total }}
                        </v-chip>
                      </template>
                    </v-data-table>
                    </v-data-table>
              	  </v-card-text>
              	</v-card>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogAlumnos"
      hide-overlay
      transition="dialog-bottom-transition"
      max-width="1200"
    >
      <v-card>
        <v-card-title primary-title>
          Alumnos
          <v-spacer></v-spacer>

          <v-btn 
            class="mr-2" 
            small dark color="green"
            @click="exportarAlumnos( alumnos , 'ALUMNOS_NI' )"
            v-if="![18,40,19,17,53,52,57].includes( getdatosUsuario.idpuesto )"
          >
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>

        </v-card-title>
        <!-- Datos principales del usuario -->
        <v-card-text>
          <v-row justify="end">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="alumnoni"
                filled
                dense
                single-line
                rounded
                append-icon="mdi-magnify"
                label="Búscar"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            :headers="![18,40,19,17,53,52,57].includes( getdatosUsuario.idpuesto ) ? headersDos : headersTres"
            :items="alumnos"
            class="elevation-0"
            dense
            :search="alumnoni"
            mobile-breakpoint="200"
          >
            <template v-slot:item.diploma="{ item }">
              <v-chip
                v-if="item.diploma != 'SIN DIPLOMA'"
                small
                color="primary"
                @click="verDiploma( item.diploma )"
              >
                VER
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogAlumnos = false"
            small
            color="green"
            dark
          >
            Cerrar
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
      

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  import XLSX from 'xlsx'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialogAlumnos:false,

      snackbar:false,
      headers: [
        { text: 'Vendedora'    , value: 'vendedora'  },
        { text: 'Con adeudo'   , value: 'con_adeudo' },
        { text: 'I s/adeudo'   , value: 'sin_adeudo' },
        { text: 'I completo'   , value: 'completos'  },
        { text: 'Total'        , value: 'total'      },
      ],

      headersDos: [
        { text: 'id'          , value: 'matricula' },
        { text: 'Nombre'      , value: 'nombre'    },
        { text: 'Grupo'       , value: 'grupo'     },
        { text: 'Adeudo'      , value: 'adeudo'    },
        { text: 'Total'       , value: 'pagado'    },
        { text: 'Vendedora'   , value: 'vendedora' },
        { text: 'N Indu'      , value: 'nivel'     },
        { text: 'Nombre Indu' , value: 'nombre_i'  },
        { text: 'Diploma'     , value: 'diploma'   },
      ],

      headersTres: [
        { text: 'id'          , value: 'matricula' },
        { text: 'Nombre'      , value: 'nombre'    },
        { text: 'Grupo'       , value: 'grupo'     },
        { text: 'Adeudo'      , value: 'adeudo'    },
        { text: 'Total'       , value: 'pagado'    },
      ],

      desserts: [],

      tablaFast:[],
      tablaInbi:[],
      alumnos:[],

      fast:{},
      inbi:{},

      fechaini:null,
      fechafin:null,
      totalInbi:0,
      totalFast:0,
      nuevasMatriculas:[],


      desserts: [],
      ciclos:[],
      cicloInf:null,
      cicloSup:null,
      plantel:null,
      cicloAnterior:[],
      cicloSiguiente:[],

      dataTablaFastUno:[],
      dataTablaInbiUno:[],
      dataTablaFastDos:[],
      dataTablaInbiDos:[],

      text:'',
      alumnoni:'',

    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    watch: {
      cicloInf () {
        if(this.cicloInf){
          var num_search = parseInt(this.cicloInf.ciclo.substr(5,3)) + 1
          for(const i in this.ciclos){
            var cadena_search = this.ciclos[i].ciclo.substr(0,8)
            if (cadena_search.search(num_search) != -1) {
              this.cicloSup = this.ciclos[i]
            }
          }

          if(this.cicloSup){
            // this.consultar()
          }
        }
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.cargar    = true
        this.ciclos    = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      consultar () {
        if(this.cicloInf && !this.fechafin && !this.fechaini){
          this.fechafin = null
          this.fechaini = null
          this.getCiclo()
        }else if(!this.cicloInf && this.fechafin && this.fechaini){
          this.getRango()
        }else{
          this.text = 'Selecciona los ciclos porfavor'
          this.snackbar = true
        }
      },

      getRango () {
        this.verGrafica = false
        this.dataTablaFastUno = []
        this.dataTablaInbiUno = []
        this.cargar = true

        var payload = {
          fechaini: this.fechaini,
          fechafin: this.fechafin ? this.fechafin : this.fechaini
        }

        this.$http.post('nuevas.matriculas',payload).then(response=>{
          // Guardar los datos 
          this.tablaFast        = response.data.vendedorasFast
          this.tablaInbi        = response.data.vendedorasInbi
          this.totalFast        = response.data.totalFast 
          this.totalInbi        = response.data.totalInbi
          this.nuevasMatriculas = response.data.nuevasMatriculas

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      getCiclo () {
        this.verGrafica = false
        this.dataTablaFastUno = []
        this.dataTablaInbiUno = []
        this.cargar = true

        this.$http.post('nuevas.matriculas.ciclo',this.cicloInf).then(response=>{
          // Guardar los datos 
          this.tablaFast        = response.data.vendedorasFast
          this.tablaInbi        = response.data.vendedorasInbi
          this.totalFast        = response.data.totalFast 
          this.totalInbi        = response.data.totalInbi
          this.nuevasMatriculas = response.data.nuevasMatriculas

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      abrirDialog( value ){
        this.alumnos = value
        this.dialogAlumnos = true
      },

      exportar(){
        this.exportarData()
      },

      s2ab2(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportarData(){
      	var arrayEspacios = this.dataTablaFastUno
      	var arregloInbiTablaDos = {
        	id_plantel    :'',
        	plantel    		:'',
        	alumnodia    	:'',
					alumnosemana	:'',
					totalAcumulado:'',
        }
        arrayEspacios.push(arregloInbiTablaDos)
        arrayEspacios.push(arregloInbiTablaDos)
        arrayEspacios.push(arregloInbiTablaDos)

        // titulos
        var arregloInbiTablaDos = {
        	id_plantel    :'id_plantel',
        	plantel    		:'plantel',
        	alumnodia    	:'alumnodia',
					alumnosemana	:'alumnosemana',
					totalAcumulado:'totalAcumulado',
        }
        arrayEspacios.push(arregloInbiTablaDos)

      	var arrayConcat = arrayEspacios.concat(this.dataTablaInbiUno);

        let data = XLSX.utils.json_to_sheet(arrayConcat)
        const workbook = XLSX.utils.book_new()
        const filename = 'KPI'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab2(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
      		this.dataTablaFastUno.splice(this.dataTablaFastUno.length-4,4)
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      exportarAlumnos( value, filename ){

        let alumnos = value.map(( { matricula, nombre, grupo, adeudo, pagado, vendedora, nivel, nombre_i, diploma }) => ({ matricula, nombre, grupo, adeudo, pagado, vendedora, nivel, nombre_i, diploma }))

        this.exportExcel( alumnos, filename) 
      },

      verDiploma( ruta ){
        window.open ( ruta )
      }

      
    },
  }
</script>