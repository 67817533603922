<template>
	<div>
	  <span v-for="(evento, i) in eventosTimeline">
	    <v-tooltip bottom :color="evento.color" >
	      <template v-slot:activator="{ on, attrs }">
	        <v-btn
	          dark
	          fab 
	          x-small
	          class="mr-2"
	          :color="evento.color" 
	          v-bind="attrs"
	          v-on="on"
	        >
	          <v-icon v-text="evento.icono"></v-icon>
	        </v-btn>
	      </template>
	      <span>{{ evento.fecha_creacion }}<br/>{{ evento.evento }}</span>
	    </v-tooltip>
	  </span>
	</div>
</template>


<script>
import axios from 'axios';
	export default {
		props:[
			'eventosTimeline'
	  ],
	}
</script>