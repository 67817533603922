<template>
  <v-container class="mt-4 ml-2">

	  <v-row justify="center">
	    <v-col cols="12">
	    	<v-card class="shadowCard">
	    	  <v-card-title class="text-subtitle-1">
	    	    Cargar archivo del conmutador
	    	    <v-spacer></v-spacer>
            <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" class="mr-2"/>
	        	<!-- <v-btn  
	        		v-if="tableData.length"
	        		color="success" 
	        		dark  
	        		small
	        		class="ml-4"
	            @click="procesarInformacion()"
	          > Guardar </v-btn>  -->

	         	<v-btn  
	        		v-if="tableData.length"
	        		color="success" 
	        		dark  
	        		small
	        		class="ml-4"
	            @click="relacionarInformacion()"
	          > Guardar </v-btn> 

	    	  </v-card-title>

	        <v-col cols="12">
	          <v-card flat>
	            <v-alert outlined type="warning"  prominent v-if="!tableData.length && !reportes.length">
	              NO SE HA CARGADO NINGUN DOCUMENTO.
	            </v-alert>

	            <v-card v-if="tableData.length">
	              <v-data-table
	                dense
	                :headers="tableHeader"
	                :items="tableData"
	                item-key="name"
	                :page.sync="page"
							    @page-count="pageCount = $event"
	                fixed-header
	                hide-default-footer
	                :height="tamanioPantalla"
                  locale="es"
	              >
	              </v-data-table>
	            </v-card>
	            <div class="text-center pt-2" v-if="tableData.length">
	              <v-pagination v-model="page" :length="pageCount"></v-pagination>
	            </div>
	          </v-card>
	        </v-col>
	    	</v-card>
	    </v-col>
	  </v-row>

     <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
	</v-container>
</template>

<script>
import axios from 'axios';
  import UploadExcelComponent from '@/components/UploadExcel.vue'

	import { mapGetters} from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'
  import filtrosRolTeachers    from '@/mixins/filtrosRolTeachers'


  export default {

  	components:{
      Alerta,
      carga,
    	UploadExcelComponent
    },

    mixins: [ validarErrores, funcionesExcel, filtrosRolTeachers ],

	  data() {
	    return {
	    	// Alertas
	    	parametros:{
	        dialogError: false,
	        mensaje: '',
	        color: ''
	      },

	      respuestaAlerta:false,
	      loader: true,
	      cargar: false,

	      page: 1,
	      pageCount: 0,
	      itemsPerPage: 20,

	      tableHeader: [ 
		      { text: 'Fecha'         , value: 'fecha' },
					{ text: 'Descripción'   , value: 'descripcion' },
					{ text: 'Referencia'    , value: 'referencia'},
					{ text: 'Abonos'        , value: 'abonos'},
					{ text: 'Existe'        , value: 'existe'},
	      ],   

	      tableData: [],  // DATOS A MOSTRAR 
	      reportes : [],  // TABLA REPORTES

	      alerta: { activo: false, texto:'', color:'error'},
	      overlay: false,
	      
	      codigos:[],


	      textoRelacion: `hey, banco ¡TRANSFERENCIA ACEPTADA! 01/nov/2022, 10:02H $699.00 Fast English Banregio BANREGIO-*001-5 Descripción Reyna Guadalupe Tamez Rodriguez Desde CUENTA HEY-*001-6 Tipo de transferencia Banregio ACTIVAR CUENTA`,
	      monto: 699
	    }
	  },

	  async created(){
	  },

	  computed:{
	    ...mapGetters('login',['getdatosUsuario']),
	    tamanioPantalla () {
	      return this.$vuetify.breakpoint.height -380
	    },
	  },

	  methods: {

	    beforeUpload(file) {
	      const isLt1M = file.size / 1024 / 1024 < 1
	      if (isLt1M) {
	        return true
	      }
	      this.$message({
	        message: 'Please do not upload files larger than 1m in size.',
	        type: 'warning'
	      })

	      return false
	    },

	    handleSuccess({results, header }) {
	      this.obtenerDatos(results);
	    },

	    obtenerDatos (results){
	      var me = this
	      let Temporal  =  results.map(obtenerValoresPresupuesto)
	      let value2 = {}
	      function obtenerValoresPresupuesto(value, index, array) {
	        if (value['Fecha'] !== null ) {
	          value.fecha = me.excelDateToJSDate( value['Fecha'] )
	        }

	        if (value['Descripción'] !== null ) {
	          value.descripcion = value['Descripción']
	        }  

	        if (value['Referencia'] !== null ) {
	          value.referencia = value['Referencia']
	        }
	        
	        if (value['Abonos'] !== null ) {
	          value.abonos = value['Abonos']
	        }

	        value.existe = 'NO'

	        return value 
	      }

	      this.tableData = Temporal
	    },

	    excelDateToJSDate(serial) {

	    	if( serial ){

	    		/*
						Calcular la fecha
	    		*/
				  var utc_days  = Math.floor(serial - (25567 + 1));
				  var utc_value = utc_days * 86400;                                        
				  var date_info = new Date(utc_value * 1000 );

				  const fecha2 = (new Date(date_info - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
				  const desgloseFecha = fecha2.split('-')

				  /*
						CALCULAR LA HORA
				  */
				  var fractional_day = serial - Math.floor(serial) + 0.0000001;
				  var total_seconds = Math.floor(86400 * fractional_day);
				  var seconds = total_seconds % 60;
				  total_seconds -= seconds;

				  var hours = Math.floor(total_seconds / (60 * 60));
				  var minutes = Math.floor(total_seconds / 60) % 60;

				  let fecha = (desgloseFecha[0] +'-'+ desgloseFecha[1] +'-'+  desgloseFecha[2] +' '+ hours +':'+ (minutes < 10 ? ('0' + minutes) : minutes)) +':'+  (seconds < 10 ? ('0' + seconds) : seconds)

			  	return fecha
	    	}

			  return null
			},

	    procesarInformacion( ){
	    	this.cargar = true
    		this.$http.post('conmutador.cargar.datos', this.tableData ).then(response=>{
    			this.tableData = []
    			this.validarSuccess( response.data.message )
    			this.cargar = false
    		}).catch(error=>{ 
    			this.validarError( error.response.data.message )
    		}).finally( () =>{ this.cargar = false } )
	    },

	    relacionarInformacion( ){


	    	let tabla = Array.from(this.tableData)
	    	this.tableData = []

	    	for( const i in tabla ){
	    		const { descripcion, referencia, abonos } = tabla[i]

	    		// Validar que existan los datos

	    		// BANREGIOS
	    		/*
						1-. Referencia, hay que eliminar el _ para poder buscar la referencia directa
						2-. Descripción
							- Separar por :
							- Separar por .
							- No separar
	    		*/

	    		const similitud1 = referencia.replace(/[_]/g,'')

	    		if( this.textoRelacion.match( similitud1 ) ){
	    			console.log( 'SII')
	    			tabla[i].existe = 'SI'
	    		}

	    		if( descripcion.indexOf(".") >= 0 ){
	    			const secciones = descripcion.split('.')
	    		}

	    		if( descripcion.indexOf(":") >= 0 ){
	    			const secciones = descripcion.split(':')
	    		}

	    	}

	    	this.tableData = tabla
	    }

	  }
	}
</script>