<template>
  <v-container class="mt-4 ml-2">
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title class="text-subtitle-1">
            Reasignar alumno/vendedora

            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6" lg="5">
                <v-text-field
                  label="Buscar Alumno"
                  dense
                  v-model="search"
                  filled
                  append-icon="mdi-magnify"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <!--Lista de alumnos-->
            <v-data-table
              :headers="headers"
              :items="alumnos"
              class="elevation-0"
              :search="search"
              dense
            >
              <template v-slot:item.ver="{ item }">
                <v-icon small class="mr-2" @click="alumno = item, dialogVendedora = true"> mdi-pencil  </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        
      </v-col>
    </v-row>


    <v-dialog
      v-model="dialogVendedora"
      max-width="500px"
    >
      
      <!-- Lista de vendedoras -->
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Reasignar alumno/vendedora</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" >
                <v-autocomplete
                  :items="usuarios"
                  v-model="usuario"
                  label="Selecciona vendedora"
                  item-value="id_usuario"
                  item-text="nombre_completo"
                  filled
                  dense
                ></v-autocomplete>
              </v-col>                     
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="red"
            dark
            small
            rounded
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
    
          <v-btn 
            color="primary"
            dark
            small
            rounded 
            @click=" actualizar()"
          > Guardar </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>


<script>
import axios from 'axios';

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      //datos que se desplegaran en las 2 tablas
      alumnos:[],
      usuarios:[],
      usuario: null,
      dialogVendedora: false,

      headers: [
        { text: 'Id'              , value: 'matricula' },
        { text: 'Alumno'          , value: 'nombre_alumno' },
        { text: 'Vendedora Actual', value: 'nombre_vendedora' },
        { text: 'Reasignar a...'  , value: 'ver', sortable: false },
      ],

      search:'',

      
      
    }),

    //Computed
    computed: {
    },

    watch: {
      dialogVendedora (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    //Methods
    methods: {
      
      //Obtener alumnos y usuarios 
      initialize () {
        this.cargar = true

        this.$http.get('cambiar.vendedora.obtener.alumno').then(response=>{
          this.cargar = false
          this.alumnos = response.data
        }).catch(error=>{console.log(error)})

        this.$http.get('cambiar.vendedora.obtener.vendedora').then(response=>{
          this.usuarios = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
 
      },

      //Cerrar Dialog Vendedora
      close () {
        this.dialogVendedora = false        
      },

      //Modificar este para ver si funciona, esta traido de ActivarProspectos
      actualizar(){
        this.alumno.id_usuario_ultimo_cambio = this.usuario
        this.cargar = true
        return this.$http.post('cambiar.vendedora.updatealumnovendedora', this.alumno).then( response=> {
          this.dialogVendedora = false
          this.cargar = false
          this.alumno = null
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },
    },
  }
</script>