<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" class="ma-0 pa-0">
				<v-card class="elevation-0 transparent">
					<v-card-text >
		      	<v-row>
		      		<v-btn
								color="primary"
								dark
								@click="inciarNominaSabtina()"
								small
								tile
							>
								Actualizar
							</v-btn>
		      		<v-spacer></v-spacer>
			      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
			          <v-text-field
			            v-model="search"
			            dense
			            filled
			            single-line
			            label="Buscar"
			            append-icon="mdi-magnify"
			          ></v-text-field>  
			        </v-col>
		      	</v-row>

		      	<v-row>
		      		<v-col cols="12" class="black--text">
		      			<b>% aceptación Quincena:</b> {{ porcentajeAceptacionQ }}
		      			<br/>
		      			<b>% aceptación Bono:</b> {{ porcentajeAceptacionB }}
		      			<br/>
		      			<b>% aceptación Sabatina:</b> {{ porcentajeAceptacionA }}
		      		</v-col>
		      	</v-row>

		        <v-tabs
		          v-model="tab"
		          align-with-title
		        >
		          <v-tabs-slider></v-tabs-slider>
		          <v-tab v-if="liberar">Nómina</v-tab>
		        </v-tabs>

		        <v-tabs-items v-model="tab">

		        	<!-- Nómina -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text>
				          	<v-radio-group
								      v-model="opcionFiltro"
								      row
								      dense
								    >
								      <v-radio
								        label="Pendiente"
								        :value="0"
								      ></v-radio>
								      <v-radio
								        label="Aceptados"
								        :value="1"
								      ></v-radio>
								      <v-radio
								        label="Rechazados"
								        :value="2"
								      ></v-radio>
								      <v-radio
								        label="Todos"
								        :value="3"
								      ></v-radio>

								    </v-radio-group>

								    <v-spacer></v-spacer>
						        <v-data-table
						          :headers="headersNomina"
						          :items="filterTeachers"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        	<template v-slot:item.aceptado="{ item }">
			            			<v-chip v-if="(!item.aceptado)    " small color="grey"  dark >Pendiente</v-chip>
			            			<v-chip v-if="(item.aceptado == 1)" small color="green" dark >Aceptada</v-chip>
			            			<v-chip v-if="(item.aceptado == 2)" small color="red"   dark >Rechazada</v-chip>
			            		</template>

			            		<template v-slot:item.hsbc="{ item }">
			            			<v-chip v-if="(item.hsbc == 1)" small color="green" dark >Si</v-chip>
			            			<v-chip v-if="(item.hsbc == 2)" small color="red"   dark >No</v-chip>
			            		</template>

			            		<template v-slot:item.pago_quincena="{ item }">
			            			<v-chip 
			            				v-if="item.estatus_quincena" 
			            				:color="item.estatus_quincena == 1 ? 'green' : 'red'" 
			            				dark
			            				small 
			            				@click="verComentarios( item.comentarios_quincena, 2 , item )"
			            			>
			            				{{ item.estatus_quincena == 1 ? 'Aceptado' : 'Rechazado' }}
			            			</v-chip>
			            			
			            			<v-chip v-else color="grey" small dark @click="verComentarios( item.comentarios_quincena, 2 , item )">
			            				Pendiente
			            			</v-chip>
			            		</template>

			            		<template v-slot:item.PAGO_SABADO="{ item }">
			            			<v-chip 
			            				v-if="item.estatus_sabatina" 
			            				:color="item.estatus_sabatina == 1 ? 'green' : 'red'" 
			            				dark
			            				small 
			            				@click="verComentarios( item.comentarios_sabatina, 1 , item )"
			            			>
			            				{{ item.estatus_sabatina == 1 ? 'Aceptado' : 'Rechazado' }}
			            			</v-chip>
			            			
			            			<v-chip v-else color="grey" small dark @click="verComentarios( item.comentarios_sabatina, 1 , item )">
			            				Pendiente
			            			</v-chip>
			            		</template>

			            		<template v-slot:item.bono_teacher="{ item }">
			            			<v-chip 
			            				v-if="item.estatus_bono" 
			            				:color="item.estatus_bono == 1 ? 'green' : 'red'" 
			            				dark
			            				small 
			            				@click="verComentarios( item.comentarios_bono, 3 , item )"
			            			>
			            				{{ item.estatus_bono == 1 ? 'Aceptado' : 'Rechazado' }}
			            			</v-chip>
			            			
			            			<v-chip v-else color="grey" small dark @click="verComentarios( item.comentarios_bono, 3 , item )">Pendiente</v-chip>
			            		</template>

						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				    </v-tabs-items>

		      </v-card-text>
				</v-card> 
			</v-col>
		</v-row>

		<!-- Dialog de carga -->
		<Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
		<carga                v-if="cargar"/>

	</v-container>
</template>

<script>
	import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
	import Alerta                from '@/components/alertas/Alerta.vue'
	import carga                 from '@/components/alertas/carga.vue';

	import validarErrores       from '@/mixins/validarErrores'
	import XLSX                 from 'xlsx'
	import funcionesExcel       from '@/mixins/funcionesExcel'
	import mixinNominaSabatina  from '@/mixins/mixinNominaSabatina'
	import mixinNominaQuincena  from '@/mixins/mixinNominaQuincena'

	export default {
		components:{
			Alerta,
			carga,
		},

		mixins: [ validarErrores, funcionesExcel, mixinNominaSabatina, mixinNominaQuincena ],

		props:[
			'selectedHeaders',
			'ciclo',
		],

		data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      opcionFiltro: 3,

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      teacherActivos:[],

      teachersBonos: [],

      teachers:[],
      teachersQ:[],

      headersNomina: [
        { text: 'id'                , value: 'id_teacher'           },
        { text: 'Teacher'           , value: 'teacher'              },  
        { text: 'Plantel'           , value: 'plantel'              },  
	      { text: 'existeCategoria'   , value: 'existeCategoria'      },  
      ],

      headers3: [
        { text: 'valor hora' , value: 'valor_hora'    },
        { text: '# horas'    , value: 'numero_horas'  },  
        { text: 'Costo'      , value: 'costo'         },  
      ],

      headers2: [
        { text: 'id'              ,  value: 'id_teacher'         },
        { text: 'Teacher'         ,  value: 'teacher'            },  
        { text: 'H_SABADO_FAST'   ,  value: 'HORAS_SABADO_FAST'  },  
        { text: 'H_SABADO_INBI'   ,  value: 'HORAS_SABADO_INBI'  },  
        { text: 'H_SABADO_TEENS'  ,  value: 'HORAS_SABADO_TEENS' },  
        { text: 'TOTAL_HORAS'     ,  value: 'TOTAL_HORAS'        },  
        { text: 'Costo H'         ,  value: 'categoria'          },  
      ],

      headers4: [
        { text: 'valor hora' , value: 'valor_hora'    },
        { text: '# horas'    , value: 'numero_horas'  },  
        { text: 'Costo'      , value: 'costo'         },  
        { text: 'Diferencia' , value: 'diferencia'    },  
        { text: 'Motivo'     , value: 'motivo'        },  
      ],

      headers2Q: [
        { text: 'id'              ,  value: 'id_teacher'               },
        { text: 'Teacher'         ,  value: 'teacher'                  },  
        { text: 'H_LV_FAST'       ,  value: 'HORAS_ENTRE_SEMANA_FAST'  },  
        { text: 'H_D_FAST'        ,  value: 'HORAS_DOMINGO_FAST'       },  
        { text: 'H_ONL_TEENS'     ,  value: 'HORAS_LMV_FAST'           },  
        { text: 'H_LV_INBI'       ,  value: 'HORAS_ENTRE_SEMANA_INBI'  },  
        { text: 'H_D_INBI'        ,  value: 'HORAS_DOMINGO_INBI'       },  
        { text: 'H_ONL_TEENS'     ,  value: 'HORAS_LMV_INBI'           },
        { text: 'TOTAL_HORAS'     ,  value: 'HORAS_TOTALES'            },  
        { text: 'Costo H LV'      ,  value: 'categoria_lv'             }, 
        { text: 'Costo H D'       ,  value: 'categoria_d'              }, 
      ],

      headersBono: [
        { text: 'id'              , value: 'id_teacher'   },
        { text: 'Teacher'         , value: 'teacher'      },
        { text: 'PAGO Anterior'   , value: 'bono_anterior', align: 'end' } ,
        { text: 'PAGO BONO'       , value: 'bono_teacher', align: 'end' },  
        { text: 'diferencia'      , value: 'diferencia', align: 'end' }  
      ],

			search:'',
      gruposActivos:[],

      tab: null,

      tabla1Comparativa: [],
      tabla2Comparativa: [],

      tabla1ComparativaQ: [],
      tabla2ComparativaQ: [],

      categorias1:[],
      categorias2:[],
      categorias1Q:[],
      categorias2Q:[],


      costoHora1: 0,
      costoHora2: 0,
      horasImpartidas: 0,
      horasImpartidasAnt: 0,

      costoHora1Q: 0,
      costoHora2Q: 0,
      horasImpartidasQ: 0,
      horasImpartidasAntQ: 0,

      gruposQuincena: [],
      cicloAnt: null,
      comentariosT:[],
      dialogVerComentario: false,
      comentarios:[],
      porcentajeAceptacionQ:0,
			porcentajeAceptacionB:0,
			respuesta:'',
			gruposQuincenaAnt:[],

			gruposAllBono:[],
			tipo_nomina: 0,
			renglonNomina: null,
			rebajes: [],
			bonosTeachers:[],

			liberar: false,
			maestros: []
		}),

		computed: {

			...mapGetters( 'login' ,['getdatosUsuario']),

			porcentajeAceptacionA( ){
				return  ((this.teachers.filter( el => el.estatus_sabatina == 1 ).length / this.teachers.filter( el => el.PAGO_SABADO != '$0.00' ).length ) *100).toFixed(2)
			},

			filterTeachers( ){

				let grupos = this.teachers

				let planteles = []

				// MOISES LARA PEREZ
        if( [1593].includes( this.getdatosUsuario.iderp ) ){
          planteles = [7,11,8,10]
        }

        // XCARET CORONA SALINAS
        if( [886].includes( this.getdatosUsuario.iderp ) ){
          planteles = [14, 9, 12, 16]
        }

        // CARLOS EDUARDO DE LEON HERNANDEZ
      	if( [1177].includes( this.getdatosUsuario.iderp ) ){
      	  planteles = [1,22,23,24,29]
      	}

        // JESUS OSWALDO MONTALVO LUNA
      	if( [1336].includes( this.getdatosUsuario.iderp ) ){
      	  planteles = [2,3,5]
      	}

        // MARISOL CASTILLO ALVARADO
        if( [1127].includes( this.getdatosUsuario.iderp ) ){
          planteles = [6,4,32,21]
        }

        // Saul Dario Guzman Ortiz
        if( [1552].includes( this.getdatosUsuario.iderp ) ){
          planteles = [25,28,31,,27,17]
        }


      	grupos = grupos.filter( el => planteles.includes( el.id_plantel ) )


				if( ![3,4].includes(this.opcionFiltro) ){


					if( this.opcionFiltro == 2 ){

						// return this.teachers.filter( el => el.estatus_quincena == 2 )
						// return this.teachers.filter( el => el.estatus_quincena == 2 || el.estatus_bono == 2 )
						return grupos.filter( el => el.estatus_sabatina == 2 )

					}else if( this.opcionFiltro == 1 ){

						// return grupos.filter( el => el.estatus_quincena == 1 && el.estatus_bono == 1 )
						// return grupos.filter( el => el.estatus_quincena == 1 && el.pago_quincena != '$0.00' )
						return grupos.filter( el => el.estatus_sabatina == 1 )

					}else{

						// return grupos.filter( el => el.estatus_quincena == 0  )
						// return grupos.filter( el => el.estatus_quincena == 0 || el.estatus_bono == 0 )
						return grupos.filter( el => el.estatus_sabatina == 0 )
					
					}

				}else{

					if( this.opcionFiltro == 3 ){

						return grupos

					}else{

						return grupos.filter( el => !el.existeCategoria )

					}

				}



			},


			tablaBonos( ){

				let bonos = this.teachers.filter( el => el.bono_teacher != '$0.00' ) 

	    	let bonos_anteriores = [
	    		{ id_teacher: 1582, monto: '$350'},
					{ id_teacher: 1546, monto: '$350'},
					{ id_teacher: 1544, monto: '$350'},
					{ id_teacher: 1154, monto: '$350'},
					{ id_teacher: 998 , monto: '$500'},
					{ id_teacher: 1608, monto: '$350'},
					{ id_teacher: 464 , monto: '$350'},
					{ id_teacher: 1380, monto: '$500'},
					{ id_teacher: 1580, monto: '$500'},
					{ id_teacher: 1308, monto: '$500'},
					{ id_teacher: 545 , monto: '$500'},
					{ id_teacher: 1510, monto: '$350'},
					{ id_teacher: 813 , monto: '$500'},
					{ id_teacher: 1439, monto: '$350'},
					{ id_teacher: 987 , monto: '$350'},
					{ id_teacher: 671 , monto: '$350'},
					{ id_teacher: 976 , monto: '$350'},
					{ id_teacher: 1310, monto: '$350'},
					{ id_teacher: 1421, monto: '$350'},
					{ id_teacher: 1569, monto: '$350'},
					{ id_teacher: 1600, monto: '$500'},
					{ id_teacher: 1589, monto: '$500'},
	    	]

	    	for( const i in bonos ){

	    		const { id_teacher, bono_teacher } = bonos[i]

	    		const existeAnterior = bonos_anteriores.find( el => el.id_teacher == id_teacher )

	    		let bono_anterior_monto = existeAnterior ? parseFloat( existeAnterior.monto.replace('$','').replace(',','') ) : 0

	    		bonos[i].bono_teacher = bono_teacher ? bono_teacher : this.format( 0 )

	    		let nuevo_monto = bono_teacher ? parseFloat( bono_teacher.replace('$','').replace(',','') ) : 0

	    		let diferencia = nuevo_monto - bono_anterior_monto 

	    		bonos[i]['bono_anterior'] = existeAnterior ? existeAnterior.monto : '$0.00'
	    		bonos[i]['diferencia'] = this.format( diferencia )
 
	    	}

	    	bonos.push({
	    		id_teacher: 100000,
	        teacher   : 'TOTAL',
	        bono_anterior      : this.format( bonos.map(item => parseFloat( item.bono_anterior.replace('$','').replace(',','') ) ).reduce((prev, curr) => prev + curr, 0) ),
	        bono_teacher       : this.format( bonos.map(item => parseFloat( item.bono_teacher.replace('$','').replace(',','') ) ).reduce((prev, curr) => prev + curr, 0) ),
	        diferencia         : this.format( bonos.map(item => parseFloat( item.diferencia.replace('$','').replace(',','') ) ).reduce((prev, curr) => prev + curr, 0) ),
	    	})


				return  bonos
			}

		},

		watch: {

			selectedHeaders( ){
				this.headersNomina = [
	        { text: 'id'              , value: 'id_teacher'      },
	        { text: 'Teacher'         , value: 'teacher'         },  
	        { text: 'Plantel'         , value: 'plantel'         },  
	        { text: 'existeCategoria' , value: 'existeCategoria' },  
	        { text: 'PAGO_SABADO'     , value: 'PAGO_SABADO'     },  
	      ]

				if( [1,3].includes( this.selectedHeaders ) ){
					this.headersNomina.push({
	        	text: 'PAGO QUINCENA', 
	        	value: 'pago_quincena',
	        	align: 'end'
					})
				}


				if( [1].includes( this.selectedHeaders ) ){
					this.headersNomina.push({
	        	text: 'PAGO BONO', 
	        	value: 'bono_teacher',
	        	align: 'end'
					})
				}
			}
			
		},

		async created () {


			this.headersNomina = [
        { text: 'id'              , value: 'id_teacher'      },
        { text: 'Teacher'         , value: 'teacher'         },  
        { text: 'Plantel'         , value: 'plantel'         },  
	      { text: 'existeCategoria' , value: 'existeCategoria' },  
        { text: 'PAGO_SABADO'     , value: 'PAGO_SABADO'     },  
      ]

			if( this.selectedHeaders == 1 ){
				this.headersNomina.push({
        	text: 'PAGO QUINCENA', 
        	value: 'pago_quincena',
        	align: 'end'
				})

				this.headersNomina.push({
        	text: 'PAGO BONO', 
        	value: 'bono_teacher',
        	align: 'end'
				})
			}

			if( this.selectedHeaders == 3 ){
				this.headersNomina.push({
        	text: 'PAGO QUINCENA', 
        	value: 'pago_quincena',
        	align: 'end'
				})
			}

			// Depende de la semana, son los campos que se van a llenar o a agregar en los headers de la tabla 1

			await this.getTeachersSinCategoria( )
			await this.inciarNominaSabtina()

		},

		methods:{

			getTeachersSinCategoria( ){
				this.liberar = false
				this.$http.get('teachers.sin.categoria').then(response=>{

					this.liberar  = response.data.length > 0 ? false : true
					this.maestros = response.data.map(( registro ) => { return registro.nombre_completo })
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })	
			},

			verComentarios( comentarios, tipo_nomina , item ){
				return 
				this.renglonNomina = item
				this.comentarios = comentarios
				this.tipo_nomina = tipo_nomina
				this.dialogVerComentario = true
			},

			exportar( ){
        this.exportExcel( this.filterTeachers  , 'teachers')
			},

			responder ( ) {
				this.cargar = true
				const payload = {
					id_usuario : this.getdatosUsuario.iderp,  
					id_teacher : this.renglonNomina.id_teacher,  
					estatus    : this.getdatosUsuario.iderp == 334 ? 0 : 2,
					id_ciclo   : this.ciclo.id_ciclo,
					semana     : this.selectedHeaders,
					motivo     : this.respuesta,
					tipo_nomina: this.tipo_nomina,
				}

				this.$http.post('teachers.estatus.responder', payload).then(response=>{
					this.validarSuccess('Datos grabados correctamente')
					this.respuesta    = ''
					this.dialogVerComentario = false
					this.cargar       = false
					this.initialize()
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })	
			},
    }
	}
</script>




