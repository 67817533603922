<template>
  <v-app>

    <!-- Araña para halloween -->
    <!-- <div id="other">
      <div id="spider1"> 
 
        <div id="eye">
          <p id="pupil"></p>
        </div>
   
        <div id="leg">
          <p></p>
          <p></p>
        </div>
   
        <div id="leg2">
          <p></p>
          <p></p>
        </div>
   
        <div id="leg3">
          <p></p>
          <p></p>
        </div>
   
        <div id="leg4">
          <p></p>
          <p></p>
        </div>
   
        <div id="leg5">
          <p></p>
          <p></p>
        </div>
   
        <div id="leg6">
          <p></p>
          <p></p>
        </div>

        <div id="string"></div>
      </div>
    </div> -->

    <!-- Barra principal -->
    <v-app-bar app color="white" v-if="getLogeado" class ="ma-5 mt-3" style="border-radius: 10px;" >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <div v-if="[1].includes( getdatosUsuario.iderp )" class="ml-4 mr-10">
        Reporte Semanal
        <br/>
        <span class="font-weight-bold text-h6">{{ encabezados.fecha_inicio_format }}</span> 
        <span class="font-weight-bold mx-4">al</span> 
        <span class="font-weight-bold text-h6">{{ encabezados.fecha_final_format }}</span>
      </div>
      <div v-if="[1].includes( getdatosUsuario.iderp )" class="ml-4 mr-10">
        {{ ciclo }}
        <br/>
        <b>Insc. Fast:</b> {{ totalFast }}
        <br/>
        <b>Insc. Inbi:</b> {{ totalInbi }}
      </div>

      <div v-if="[1].includes( getdatosUsuario.iderp )" class="mr-10">
        <v-data-table
          :headers="headers6"
          :items="datosTablaHeaders5"
          class="elevation-0"
          dense
          mobile-breakpoint="200"
          hide-default-footer
          :items-per-page="datosTablaHeaders5.length"
          hide-default-header
        >
        </v-data-table>
      </div>


      {{ temperatura }} °C
      <img :src="iconClima" width="50" class="px-2" />
      <span>{{ descripClima }}</span>

      <v-badge 
        :color="notificaciones.filter( el => el.visto == 0 ).length ? 'error' : 'transparent' " 
        :content="notificaciones.filter( el => el.visto == 0 ).length"  
        overlap class="ml-4"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon fab small v-bind="attrs" v-on="on">
              <v-icon color="primary">mdi-bell</v-icon>
            </v-btn>
          </template>
          <v-card class="mx-auto overflow-y-auto" style="max-height: 400px;" max-width="300" tile id="idScrollApp">
            <v-list three-line nav>
              <template v-for="(noti, i) in notificaciones" type="button">
                <v-list-item :key="i" nav link @click="verNotificacion( noti )">
                  <v-list-item-content>
                    <v-list-item-title >
                      <v-icon :color="noti.visto == 0 ? 'red' : 'grey' ">mdi-circle-medium</v-icon>
                      {{ noti.titulo }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-html="noti.mensaje"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-list>
          </v-card>
        </v-menu>
      </v-badge>
      
      <v-badge
        bordered
        color="error"
        overlap
        :content="actividadesHoy"
        v-if="getdatosUsuario && [11,18,31,12,58,43,73,25,48,49,74,62,54,70,75].includes(getdatosUsuario.idpuesto) && ![1].includes( getdatosUsuario.iderp )"
      >
        <!-- <v-btn text icon class="ml-2" v-if="getdatosUsuario && [18].includes(getdatosUsuario.idpuesto)" @click.stop="getTareasProgramadas()" small><v-icon>mdi-calendar-check</v-icon></v-btn>
        <v-btn text icon class="ml-2" v-if="getdatosUsuario && [11,31].includes(getdatosUsuario.idpuesto)" @click.stop="getTareasProgramadasFormulario()" small><v-icon>mdi-calendar-check</v-icon></v-btn> -->
        <v-btn 
          text 
          icon 
          color="orange"
          class="ml-2" @click="dialogCalendario.estatus = true" small
        >
          <v-icon>mdi-calendar-check</v-icon>
        </v-btn>
      </v-badge> 


      <v-badge 
        color="error" 
        :content="getCarrito.length" 
        overlap 
        v-if="getCarrito.length > 0 && carrito_activo && ![1].includes( getdatosUsuario.iderp )" 
        class="ml-4"
      >
        <v-btn depressed fab small @click.stop="ver_carrito()">
          <v-icon color="primary">mdi-shopping</v-icon>
        </v-btn>
      </v-badge>

      <v-btn  depressed fab small v-else-if="carrito_activo" @click.stop="ver_carrito()" class="ml-4">
        <v-icon color="primary">mdi-shopping</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Menu lateral izquierdo -->
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      app
      v-if="getLogeado && !getEstatusLlamada"
      class="elevation-3 pb-5"
    >
      <!-- src="@/assets/images/fondo/fondo_lateral.png" -->
      <!-- Datos del empleado -->
      <v-list-item class="pt-4 px-1" >
        <v-list-item-content>
          <v-img src="@/assets/images/logos/logo.png" v-if="!mini"></v-img>
          <v-avatar
            size="40"
            v-else="mini"
            color="white"
          >
            <img src="@/assets/images/logos/logo_redondo.png">
          </v-avatar>
        </v-list-item-content>

        <v-btn icon @click.stop="mini = !mini" color="primary">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img
            :src="avatar"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content v-if="getdatosUsuario">
          <v-list-item-title>
            {{ getdatosUsuario.nombre_usuario + " " + getdatosUsuario.apellido_usuario }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ getdatosUsuario.puesto }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="getdatosUsuario.plantel">{{ getdatosUsuario.plantel }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <div :class="`text-center text-body-1 ${ escuela == 1 ? 'colorinbi': 'colorfast' } white--text mb-3`" v-if="!mini">
        <span>{{ escuela == 1 ? 'INBI SCHOOL': 'FAST ENGLISH' }}</span>
      </div>

      <v-autocomplete
        v-if="!mini"
        outlined
        dense
        clearable
        v-model="search"
        :items="menuBusqueda"
        label="Búscar"
        persistent-hint
        item-text="title"
        item-value="path"
        class="pa-1"
        rounded
        hide-details
      >
      </v-autocomplete>

      <v-list nav dense class="mr-0">
        <v-list-group
          v-for="item in menu"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <!-- TITULO EN GENERAL -->
          <template v-slot:activator>
            <v-list-item-content> 
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <!-- LISTA DE MENUS -->
          <v-list dense nav>

            <!-- MENU DIRECTO -->
            <v-list-item
              v-for="cat in item.items"
              :key="cat.title"
              :to="{ name: cat.path }"
              :color="`${ cat.color ? 'white' : 'primary' }`"
              :class="` ${ cat.color ? cat.color : 'cyan lighten-5' }`"
              v-if="!cat.items"
            >
              <v-list-item-icon >
                <v-icon color="primary">mdi mdi-circle-medium theme--light</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="cat.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
              v-for="cat in item.items"
              :key="cat.title"
              v-model="cat.active"
              :prepend-icon="cat.action"
              no-action
              v-if="cat.items"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="cat.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="child in cat.items"
                :key="child.title"
                :to="{ name: child.path }"
                :color="`${ child.color ? 'white' : 'primary' }`"
                :class="`pl-2 ${ child.color ? child.color : 'cyan lighten-5' }`"
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi mdi-circle-medium theme--light</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-list-group>
      </v-list>

      <v-list dense nav class="mr-0">
        <v-list-item :to="{ name:'DirectorioGeneral' }" class="teal accent-3 white--text" dark>
          <v-list-item-icon>
            <v-icon>mdi-account-box</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Directorio General</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list dense nav class="mr-0">
        <v-list-item :to="{ name:'PreguntasUsuario' }" class="pink lighten-2 white--text" dark>
          <v-list-item-icon>
            <v-icon>mdi-help</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Preguntas Frecuentes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- Preguntas frecuentes, solo para teachers -->
      <v-list dense nav class="mr-0" v-if="getdatosUsuario && getdatosUsuario.idpuesto == 10" >
        <v-list-item :to="{ name:'ManualesTeachers' }" class="blue lighten-2" dark>
          <v-list-item-icon>
            <v-icon>mdi-book</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manuales</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- Preguntas frecuentes, solo para recepcionistas -->
      <v-list dense nav class="mr-0" v-else-if="getdatosUsuario && getdatosUsuario.idpuesto == 19" >
        <v-list-item :to="{ name:'ManualesRecepcionista' }" class="blue lighten-2" dark>
          <v-list-item-icon>
            <v-icon>mdi-book</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manuales</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- Preguntas frecuentes, solo para recepcionistas -->
      <v-list dense nav class="mr-0" v-else-if="getdatosUsuario && [18,19,11].includes( getdatosUsuario.idpuesto ) " >
        <v-list-item :to="{ name:'ReglamentoTeachers' }" class="orange" dark>
          <v-list-item-icon>
            <v-icon>mdi-book</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reglamento Teacher</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      
      <!-- Manuales en general -->
      <v-list dense nav class="mr-0" v-else>
        <v-list-item :to="{ name:'Manuales' }" class="blue lighten-2" dark>
          <v-list-item-icon>
            <v-icon>mdi-book</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manuales</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- REGLAMENTO -->
      <v-list dense nav class="mr-0">
        <v-list-item :to="{ name:'Reglamento' }" class="teal accent-4 white--text" dark>
          <v-list-item-icon>
            <v-icon>mdi-book</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reglamento</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- Preguntas frecuentes, solo para recepcionistas -->
      <v-list dense nav class="mr-0" v-if="getdatosUsuario && [10,11].includes( getdatosUsuario.idpuesto ) " >
        <v-list-item :to="{ name:'Calendario' }" class="purple" dark>
          <v-list-item-icon>
            <v-icon>mdi-book</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Calendario</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      
      <!-- REGLAMENTO -->
      <v-list dense nav class="mr-0">
        <v-list-item @click="salir" class="red white--text" dark>
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

    <!-- Menu lateral derecho -->
    <v-navigation-drawer
      v-model="drawer2"
      v-if="tareas_activas"
      right
      temporary
      app
      class="elevation-3 mt-3 pb-5"
      style="border-radius: 10px;"
    >
      <template v-slot:prepend>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img
              :src="avatar"
            ></v-img>
          </v-list-item-avatar>

          <v-list-item-content v-if="getdatosUsuario">
            <v-list-item-title>
              {{ getdatosUsuario.nombre_usuario + " " + getdatosUsuario.apellido_usuario }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ getdatosUsuario.puesto }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="getdatosUsuario.plantel">{{ getdatosUsuario.plantel }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>
      <v-subheader><strong>Hoy  | {{countTareas}} | </strong></v-subheader>
      <div v-for="(tarea, i) in tareas_programadas" :key="i" v-if="tarea.hoy == 1">
        <v-card class="elevation-0">
          <v-card-text class="pt-0">
            <div class="text-body-1">
              <strong>{{ tarea.folio }}</strong><br/>
              {{ tarea.nombre_completo }}<br/>
              <v-icon :color="tarea.cumplida == 1 ? 'red' : 'green'">mdi mdi-circle-medium theme--light</v-icon>
              {{ tarea.motivo }}<br/>
            </div>
            <div class="text-caption">{{ tarea.fecha + ' ' + tarea.tiempo }}</div>

            <v-btn color="primary" block small class="text-capitalize" @click="verProspecto(tarea)">Realizar</v-btn>
            <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </div>
      <v-divider></v-divider>
      <v-subheader><strong>Restantes</strong> </v-subheader>
      <div v-for="(tarea, i) in tareas_programadas" :key="i" v-if="tarea.hoy == 0">
        <v-card class="elevation-0">
          <v-card-text class="pt-0">
            <div class="text-body-1">
              <strong>{{ tarea.folio }}</strong><br/>
              {{ tarea.nombre_completo }}<br/>
              <v-icon :color="tarea.cumplida == 1 ? 'red' : 'green'">mdi mdi-circle-medium theme--light</v-icon>
              {{ tarea.motivo }}<br/>
            </div>
            <div class="text-caption">{{ tarea.fecha + ' ' + tarea.tiempo }}</div>

            <v-btn color="primary" block small class="text-capitalize" @click="verProspecto(tarea)">Realizar</v-btn>
            <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </div>
    </v-navigation-drawer>

    <!-- Menu tienda -->
    <v-navigation-drawer right temporary app v-model="$store.state.Carrito.carritovisible"  width="400px" v-if="carrito_activo">
      <div class="pa-2">
        <CARRITO/>
      </div> 
    </v-navigation-drawer> 

    <!-- Boton de navegación para prospectos -->
    <v-speed-dial
      v-if="getdatosUsuario && [43,18,45, 73].includes(getdatosUsuario.idpuesto)"
      v-model="fab"
      fixed
      fab
      bottom
      right
    >
      <template v-slot:activator>
        <v-badge
          bordered
          color="green"
          overlap
          :content="getNuevosProspectos"
        >
          <v-btn
            v-model="fab"
            color="blue darken-2"
            dark
            fab
          >
            <v-icon>
              mdi-account-circle
            </v-icon>
          </v-btn>
        </v-badge>
        
      </template>
      <!-- Nuevo prospecto -->
      <v-btn
        fab
        dark
        small
        color="indigo"
        :to="{name:'Prospectos'}"
      >
        <v-icon>mdi-account-plus</v-icon>
      </v-btn>
      
      <v-badge
        bordered
        color="error"
        overlap
        :content="countTareas"
        v-if="getdatosUsuario && [11,18,67,73].includes( getdatosUsuario.idpuesto )"
      >
        <v-btn 
          fab 
          small 
          dark 
          color="error" 
          @click.stop="getTareasProgramadas()"
        >
          <v-icon>mdi-calendar-check</v-icon>
        </v-btn>
      </v-badge>
    </v-speed-dial>

    <!-- BTN para verificar el estatus de WhatsApp -->
    <v-btn
      v-if="getdatosUsuario && getdatosUsuario.whatsappservidor "
      fixed
      fab
      bottom
      right
      :color="estatusWhats == 1 ? 'white' : 'red' "
      dark
      fab
      @click="abrirDialogWhatsApp = true"
      style="margin-right: 65px;"
    >
      <v-icon color="black">
        mdi-qrcode
      </v-icon>
    </v-btn>

    <!-- Botón para agregar un nuevo prospecto para la vendedora y recepcionista -->
    <v-btn
      v-if="getdatosUsuario && [19,18,73].includes(getdatosUsuario.idpuesto)"
      fixed
      fab
      bottom
      right
      color="green"
      fab
      dark
      @click="abrirDialogProspecto.estatus = true"
      style="margin-right: 130px;"
    >
      <v-icon>
        mdi-account-plus
      </v-icon>
    </v-btn>

    <!-- Agregar un nuevo registro -->
    <v-dialog
      v-model="abrirDialogWhatsApp"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span v-if="estatusWhats == 0 " class="text-h6"><b>Ver estatus</b></span>
          <span v-if="estatusWhats == 1 " class="text-h6"><v-icon small left color="green">mdi-circle</v-icon> <b>CONECTADO </b></span>
          <span v-if="estatusWhats == 2 " class="text-h6"><v-icon small left color="orange">mdi-circle</v-icon> <b>NO ACTIVA </b></span>
          <span v-if="estatusWhats == 3 " class="text-h6"><v-icon small left color="red">mdi-circle</v-icon> <b>NO EXISTE SESIÓN </b></span>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            dark
            @click="abrirDialogWhatsApp = false"
            :loading="cargar"
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>



        <v-card-text>

          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
            v-if="mensajeProceso"
          >
            <v-col
              class="text-subtitle-1 text-center black--text pa-0"
              cols="12"
            >
              {{ mensajeProceso }}
            </v-col>
            <v-col cols="6" class="mt-0 pt-0" v-if="estatusWhats != 1">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col cols="12" class="mt-0 pt-0">
              <canvas id="canvas"></canvas>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="info"
            dark
            @click="validarEstatus()"
            :loading="cargar"
            tile
            small
          >
            <v-icon left small>mdi-information-outline</v-icon>
            Estatus
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            color="green"
            dark
            @click="escanerQR()"
            :loading="cargar"
            tile
            small
            v-if="[2,3].includes( estatusWhats )"
          >
            <v-icon left small>mdi-play-circle-outline</v-icon>
            Inicializar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertaSeguimiento   v-if="alertaSeguimiento.estatus"       :alertaSeguimiento="alertaSeguimiento"/>
    <NuevoProspecto      v-if="abrirDialogProspecto.estatus"    :abrirDialogProspecto="abrirDialogProspecto"/>

    <FaltaraTeacher
      v-if="alertaSinTeacher.estatus"
      :alertaSinTeacher="alertaSinTeacher"
    />

    <dialogNotificacion
      v-if="dialogNotifica.estatus"
      :dialogNotifica="dialogNotifica"
      @getNotificaciones="getNotificaciones"
    />

    <MisTickets 
      v-if="paramsTickets.estatus"
      :paramsTickets="paramsTickets"
    />

    <MisComisiones
      v-if="paramsComisiones.estatus"
      :paramsComisiones="paramsComisiones"
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <Calendario v-if="dialogCalendario.estatus" :dialogCalendario="dialogCalendario"/>

    <v-dialog
      v-model="dialogCumple"
      max-width="600px"
      transition="dialog-transition"
    >

      <v-card>
        <v-card-title primary-title>
        </v-card-title>
        <v-card-text class="black--text">
          <v-img v-if="cumple && cumple.cumpleAnios" src="@/assets/cumple.jpg" height="350" 
            aspect-ratio="2" contain align="left"
          />

          <div v-if="cumple && cumple.cumpleAnios" class="text-h6 mt-8" align="center">
            ¡Feliz cumpleaños a, <span class="text-h4">{{ cumple.nombre_completo }}</span> 🎉! 
            <br/>Del departamento de {{ cumple.departamento }}
            <br/>
            <br/>
            Espero que este día esté lleno de alegría, risas y momentos especiales rodeada de tus seres queridos
            <br/>
            Que cumplas muchos años más llenos de salud y mucho éxito!🥳🎂

            <br/>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn 
            color="red"
            absolute
            top
            right
            icon
            small 
            @click="dialogCumple = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
  import { mapGetters, mapActions }    from "vuex";
  import { Device } from '@capacitor/device';       // Angel Rodríguez

  var moment              = require('moment');

  import CARRITO          from '@/components/Carrito.vue'

  import metodos          from '@/mixins/metodos.js';
  import validarErrores   from '@/mixins/validarErrores'
  import mixinApp         from '@/mixins/mixinApp'
  import mixinReporteApp  from '@/mixins/mixinReporteApp'
  import mixinEstatusWha  from '@/mixins/mixinEstatusWha'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import Calendario            from '@/components/Calendario.vue';

  import MisComisiones         from '@/components/nomina/MisComisiones.vue'
  import MisTickets            from '@/components/tickets/MisTickets.vue'

  import AlertaSeguimiento     from '@/components/alertas/AlertaSeguimiento.vue';
  import NuevoProspecto        from '@/components/prospectos/NuevoProspecto.vue';

  import FaltaraTeacher        from '@/components/alertas/FaltaraTeacher.vue';
  import dialogNotificacion    from '@/components/alertas/dialogNotificacion.vue';

  var QRCode = require('qrcode')

  export default {
    mixins:[ metodos, mixinApp, validarErrores, mixinEstatusWha, mixinReporteApp ],

    name: "App",

    components:{
      CARRITO,
      Alerta,
      carga,
      AlertaSeguimiento,
      NuevoProspecto,
      FaltaraTeacher,
      dialogNotificacion,
      Calendario,
      MisComisiones,
      MisTickets
    },

    data: () => ({

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      radio:1,
      drawer: true,
      drawer2:false,
      mini:false,
      menu: [],
      menuTienda:[],
      active:false,
      ciaws:'',
      logo:'',
      menuTicket:[],
      avatar:'',
      // descripClima:'',
      // temperatura: 0,
      // iconClima:'',
      estatusLlamada: false,

      escuela: null,

      carrito_activo: false,
      tareas_activas: false,

      permisos:[],
      fab:null,
      search:'',
      menuBusqueda:[],
      estatusWhats:false,
      abrirDialogWhatsApp:false,

      deviceInfo: {},  //Angel Rodriguez
      batteryInfo: {},  //Angel Rodriguez

      timeAlerta: null,
      alertaSeguimiento:{
        estatus: false,
      },

      abrirDialogProspecto:{
        estatus: false
      },

      dialogNotifica: {
        estatus: false,
        notificacion: null
      },

      dialogCalendario:{
        estatus: false 
      },

      actividadesHoy: 0,
      calendario: [],
      disponibilidad_completa: 1,

      paramsComisiones:{
        estatus: false
      },

      paramsTickets:{
        estatus: false
      },

      cumple: null,
      dialogCumple: false
    }),

    watch: {
      getEstatusLlamada (val) {
        this.estatusLlamada = val
      },

      getdatosUsuario () {
        this.getPermisos()
      },

      search( val ){
        if( val ){ this.$router.push({ name: val }) }
      }
    },

    async created(){

      await this.getTareasProgramadas()

      await this.getTareasProgramadasFormulario()

      await this.initReloj()

      await this.getClima()

      await this.getPermisos()
      
      // await this.logDeviceInfo();    //Angel Rodriguez

      // await this.logBatteryInfo();   //Angel Rodriguez

      await this.getNotificaciones();

      await this.getCalendario()

      this.nuevosProspectos();

      this.obtenerEstatusLlamada()

      this.quienCumpleAnios( )

      if( this.getdatosUsuario ){
        this.avatar  = "https://ui-avatars.com/api/?background=1976d2&color=fff&rounded=true&format=svg&name=" + this.getdatosUsuario.nombre_completo + this.getdatosUsuario.apellido;

        if( [0,1,2,3,4,5,6,21,17,22,23,24,25,26,27,28,29,30,31,32,33,34].includes( this.getdatosUsuario.id_plantel ) ){
          this.cambiarEscuela(1)
          this.$vuetify.theme.themes.light.primary    = '#44A7FF'
          this.$vuetify.theme.themes.light.secondary  = '#D25AF3'
        }else{
          this.cambiarEscuela(2)
          this.$vuetify.theme.themes.light.secondary = '#AF0010'
          this.$vuetify.theme.themes.light.primary   = '#000B58'
        }
      }
      
      this.escuela = this.getEscuela

      Notification.requestPermission().then((result)=> {
        // console.log('Permiso',result)
      })

      this.getComisionesVendedora( )
      this.getTicketsPorUsuario( )

      // if( [17,19].includes( this.getdatosUsuario.idpuesto ) ){
      //   clearTimeout(this.timeAlerta);
      //   this.cronometroSeguimiento( )
      // }


    },

    methods:{
      ...mapActions('login', ['salirLogin','cambiarEscuela','abrirProspecto','cambiarEstatusLlamada','addTareas','traerProspectosNuevos']),

      nuevosProspectos(){
        // Mostrar el contador solamente
        this.traerProspectosNuevos( this.getdatosUsuario.iderp )
      },

      quienCumpleAnios( ){
        this.$http.get('cumple.anios').then((response) => {

          this.cumple = response.data

          if( this.cumple && this.cumple.cumpleAnios ){ this.dialogCumple = true }
          // this.estatusLlamada = response.data.estatus
          // this.cambiarEstatusLlamada(response.data.estatus)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      obtenerEstatusLlamada(){
        this.$http.get('prospectos.llamadas/' + this.getdatosUsuario.iderp).then((response) => {
          this.estatusLlamada = response.data.estatus
          this.cambiarEstatusLlamada(response.data.estatus)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPermisos( ){
        this.menu = [] 
        return this.$http.get('permisos.puestos.get/' + this.getdatosUsuario.idpuesto + '/' + this.getdatosUsuario.iderp ).then((response) => {

          this.disponibilidad_completa = response.data.disponibilidad_completa

          if( this.disponibilidad_completa == 0 ){
            this.$router.push({ name: 'Perfil'})
          }else{
            this.menu           = response.data.getMenusId
            if( [17,18,19,28,45,53,31,11,41,39,68,88].includes( this.getdatosUsuario.idpuesto ) ){
              this.carrito_activo = true
              this.tareas_activas = true
            }
            
            if( this.getdatosUsuario.iderp == 1449 ){

              let menu2 = this.menu.find( el => el.idmenu == 6 )
              let menu3 = this.menu.find( el => el.idmenu == 8 )
              let menu4 = this.menu.find( el => el.idmenu == 2 )

              let menu1 = this.menu.find( el => el.idmenu == 10 )
              let menu5 = this.menu.find( el => el.idmenu == 7 )

              let menu = this.menu.find( el => el.idmenu == 13 )

              let submenus = menu.items.filter( el => [167,185 ].includes( el.idactions ) ) 

              menu.items = submenus
              this.menu = [menu]
              this.menu.push( menu1 )
              this.menu.push(menu2)
              this.menu.push(menu3)
              this.menu.push(menu4)
              this.menu.push(menu5)

            }

            this.menuBusqueda = []
            for( const i in this.menu ){
              const { items, title, path } = this.menu[i] 
              if( items.length ){
                for( const j in items ){
                  if( items[j].items ){
                    for( const k in items[j].items ){
                      this.menuBusqueda.push({ title: title + ' / ' + items[j].title + ' / ' + items[j].items[k].title, path: items[j].items[k].path })
                    }
                  }else{
                    this.menuBusqueda.push({ title: title + ' / ' + items[j].title, path: items[j].path })
                  }
                }
              }
            }
          }

          // this.menu = this.menu.filter( el => el.idmenu == 5 )

        }).catch( error =>{
          this.validarErrorDirecto( 'No tienes ningún permiso, lo sentimos :(' )
        }).finally( () => { this.cargar = false })
      },


      
      salir(){
        this.salirLogin()
        // Mando a llamar la accion salir
        this.$router.push({name: 'Login'})
      },

      verProspecto (idprospecto) {
        this.abrirProspecto(idprospecto).then(response=>{
          if(this.$route.name != 'VerProspecto'){
            this.$router.push({name: 'VerProspecto'})
          }
        })
      },

      getCalendario( ){
        this.cargar = true
        // Obtener todos los ciclos
        this.$http.get('micalendario/' + this.getdatosUsuario.iderp ).then(response=>{
          for( const i in response.data ){ response.data[i]['notificada'] = false }
          this.calendario = response.data
          this.actividadesHoy = response.data.filter( el => el.hoy == 1 ).length
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async logDeviceInfo() {
        this.deviceInfo = await Device.getInfo();            //Angel Rodriguez
      },

      async logBatteryInfo() {
        this.batteryInfo = await Device.getBatteryInfo();    //Angel Rodriguez
      },

      cronometroSeguimiento( ){
        const momentoActual = new Date()
        const horaLocal   = momentoActual.getHours()
        const minutoLocal = momentoActual.getMinutes()
        const segundoLocal = momentoActual.getSeconds()
        const horaSistema = moment(horaLocal + ':' + minutoLocal + ':00','HH:mm:ss')

        this.timeAlerta = setTimeout(this.cronometroSeguimiento, 1000);

        if( minutoLocal == 0 && segundoLocal == 0 ){ this.alertaSeguimiento.estatus = true ; }
      },

      verNotificacion( item ){
        this.dialogNotifica.notificacion = item
        this.dialogNotifica.estatus = true
      }

    },

    computed:{
      ...mapGetters('login',['getdatosUsuario','getLogeado', 'getEscuela','getEstatusLlamada','getTareas','getNuevosProspectos']),
      ...mapGetters('tema', ['getTema']),
      ...mapGetters('Carrito',['getCarrito']),
    },
  };
</script>
<style>

  main{
    background: #f8f9fa ;
  }

  .v-navigation-drawer__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-navigation-drawer__content::-webkit-scrollbar:vertical {
    width: 8px !important;
  }

  .v-navigation-drawer__content::-webkit-scrollbar:vertical{
    width: 8px !important;
  }

  .v-navigation-drawer__content::-webkit-scrollbar-button:increment,.v-navigation-drawer__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-navigation-drawer__content::-webkit-scrollbar:horizontal{
    height: 8px !important;
  }

  .v-navigation-drawer__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  html{
    background: #f8f9fa;
  }

  .v-main__wrap{
    background: #f8f9fa !important;
  }

  .v-application--is-ltr .v-list-item__icon:first-child{
    margin-right: 8px !important;
  }

  .v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
    margin-left: 6px !important;
  }


  .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    align-self: center;
    margin: 0;
    min-width: 0px !important;
    justify-content: flex-end;
  }

  .shadowCard{
    border-radius: 8px !important; 
    box-shadow: 0 20px 27px 0 rgba(0,0,0,0.2)  !important;
  }

  .btn-login{
    background: rgb(0,26,252);
    background: linear-gradient(90deg, rgba(0,26,252,1) 0%, rgba(0,255,252,1) 100%);
  }

  .subrayado {
    text-decoration: underline;
  }


  #idScrollApp::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > #idScrollApp::-webkit-scrollbar:vertical {
    width: 8px !important;
  }

  #idScrollApp::-webkit-scrollbar:vertical{
    width: 8px !important;
  }

  #idScrollApp::-webkit-scrollbar-button:increment,#idScrollApp::-webkit-scrollbar-button{
    display: none;
  }

  #idScrollApp::-webkit-scrollbar:horizontal{
    height: 8px !important;
  }

  #idScrollApp::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  /*
spider
*/
 
@media screen and (min-width: 880px) {
#letters{  
  font-size: 50px;
  position: absolute;
  top: 250px;
  left: 500px;
  width: 350px;  
  -webkit-animation: change 5s linear infinite;
  -moz-animation: change 5s linear infinite;
  -ms-animation: change 5s linear infinite;
  -webkit-animation-delay: 2700ms;
}
}
@-webkit-keyframes change{
  0%{
    opacity: 1;
  }25%{
    opacity: 0;
  }50%{
    opacity: 0;
  }100%{
    opacity: 1;
  }
}
@-moz-keyframes change{
  0%{
    opacity: 1;
  }25%{
    opacity: 0;
  }50%{
    opacity: 0;
  }100%{
    opacity: 1;
  }
}
 
 
@media screen and (min-width: 880px) {
  #spider1 {
    border: 3px solid black;
    width: 100px;
    height: 75px;
    border-radius: 50% 50%;
    box-shadow: inset 0px 0px 70px 0px black;
    position: absolute;
    top: -150px;
    left: 109px;
    z-index: 98;
    -webkit-animation: down 5s linear 1;
    -moz-animation: down 5s linear 1;
    -ms-animation: down 5s linear 1;
    animation-delay: 8s;
  }
}
@-webkit-keyframes down{
  0%{
    top: -350px;    
  }50%{
    top: 350px;
  }
  100%{
    top: -150px;
  }
}
@-moz-keyframes down{
  0%{
    top: -350px;    
  }
  25%{
    top:200px;    
  }
  50%{
    top:400px;    
  }
  100%{
    top:-700px;    
  }
}
@media screen and (min-width: 880px) {
#eye{
  border: 4px solid black;
  width: 40px;
  height: 30px;
  background: white;
  border-radius: 50% 50%;
  position: absolute;
  top: -1px;
  left: 3px;
}
#eye:after{
  content: "";
  display: block;
  border: 4px solid black;
  width: 40px;
  height: 30px;
  background: white;
  border-radius: 50% 50%;
  position: absolute;
  top: -5px;
  left: 50px;
}
#pupil{
  border: 5px solid black;
  width: 2px;
  height: 2px;
  border-radius: 100%;
 background: black;
  position: absolute;
  top: -5px;
  left: 22px;
  box-shadow: 40px 1px 0px 0px black;
  z-index: 999;
  -webkit-animation: move 2s linear infinite;
  -moz-animation: move 2s linear infinite;
  -ms-animation: move 2s linear infinite;
}
}
@-webkit-keyframes move{
  0%{
    left: 22px;
  }
  25%{
    left: 37px;
  }
  50%{
    left: 10px;
  }
  75%{
    left: 15px;
  }100%{
    left: 22px;
  }
}
@-moz-keyframes move{
  0%{
    left: 22px;
  }
  25%{
    left: 37px;
  }
  50%{
    left: 10px;
  }
  75%{
    left: 15px;
  }100%{
    left: 22px;
  }
}
/*LEGS!!*/
@media screen and (min-width: 880px) {
#leg{
  -webkit-transform: rotate(-50deg);
  -moz-transform: rotate(-50deg);
  -ms-transform: rotate(-50deg);
  position: absolute;
  top: -14px;
  left: 94px;
  z-index: -998;
}
#leg p:nth-child(1){
  border: 6px solid black;
  border-bottom: transparent;
  border-left: transparent;  
  border-radius: 5px;
  width: 40px;
  height: 10px;
}
#leg p:nth-child(2){  
  border-top: 4px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 60px solid black;
  border-right: transparent;
  -webkit-transform: rotate(86deg);
  -moz-transform: rotate(86deg);
  -ms-transform: rotate(86deg);
  position: absolute;
  top: 30px;
  left: 13px;
}
#leg2{
  -webkit-transform: rotate(-8deg);
  -moz-transform: rotate(-8deg);
  -ms-transform: rotate(-8deg);
  position: absolute;
  top: 17px;
  left: 101px;
 
}
#leg2 p:nth-child(1){
  border: 6px solid black;
  border-bottom: transparent;
  border-left: transparent;  
  border-radius: 5px;
  width: 40px;
  height: 10px;
}
#leg2 p:nth-child(2){  
  border-top: 4px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 60px solid black;
  border-right: transparent;
  -webkit-transform: rotate(86deg);
  -moz-transform: rotate(86deg);
  -ms-transform: rotate(86deg);
  position: absolute;
  top: 30px;
  left: 13px;
}
#leg3{
  -webkit-transform: rotate(13deg);
  -moz-transform: rotate(13deg);
  -ms-transform: rotate(13deg);
  position: absolute;
  top: 45px;
  left: 91px;
 
}
#leg3 p:nth-child(1){
  border: 6px solid black;
  border-bottom: transparent;
  border-left: transparent;  
  border-radius: 5px;
  width: 40px;
  height: 10px;
}
#leg3 p:nth-child(2){
 
  border-top: 4px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 60px solid black;
  border-right: transparent;
  -webkit-transform: rotate(86deg);
  -moz-transform: rotate(86deg);
  -ms-transform: rotate(86deg);
  position: absolute;
  top: 30px;
  left: 13px;
}
 
 
#leg4{
  -webkit-transform: rotatey(180deg)
    rotatez(-50deg);
  -moz-transform: rotatey(180deg)
    rotatez(-50deg);
  -ms-transform: rotatey(180deg)
    rotatez(-50deg);
  position: absolute;
  top: -14px;
  left: -33px;
  z-index: -998;
}
#leg4 p:nth-child(1){
  border: 6px solid black;
  border-bottom: transparent;
  border-left: transparent;  
  border-radius: 5px;
  width: 40px;
  height: 10px;
}
#leg4 p:nth-child(2){  
  border-top: 4px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 60px solid black;
  border-right: transparent;
  -webkit-transform: rotate(86deg);
  -moz-transform: rotate(86deg);
  -ms-transform: rotate(86deg);
  position: absolute;
  top: 30px;
  left: 13px;
}
#leg5{
  -webkit-transform: rotatey(180deg)
    rotatez(-20deg);
  -moz-transform: rotatey(180deg)
    rotatez(-24deg);
  -ms-transform: rotatey(180deg)
    rotatez(-24deg);
  position: absolute;
  top: 13px;
  left: -47px;
  z-index: -998;
}
#leg5 p:nth-child(1){
  border: 6px solid black;
  border-bottom: transparent;
  border-left: transparent;  
  border-radius: 5px;
  width: 40px;
  height: 10px;
}
#leg5 p:nth-child(2){  
  border-top: 4px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 60px solid black;
  border-right: transparent;
  -webkit-transform: rotate(86deg);
  -moz-transform: rotate(86deg);
  -ms-transform: rotate(86deg);
  position: absolute;
  top: 30px;
  left: 13px;
}
 
#leg6{
  -webkit-transform: rotatey(180deg)
    rotatez(15deg);
  -moz-transform: rotatey(180deg)
    rotatez(-50deg);
  -ms-transform: rotatey(180deg)
    rotatez(-50deg);
  position: absolute;
  top: 42px;
  left: -38px;
 
}
#leg6 p:nth-child(1){
  border: 6px solid black;
  border-bottom: transparent;
  border-left: transparent;  
  border-radius: 5px;
  width: 40px;
  height: 10px;
}
#leg6 p:nth-child(2){
 
  border-top: 4px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 60px solid black;
  border-right: transparent;
  -webkit-transform: rotate(86deg);
  -moz-transform: rotate(86deg);
  -ms-transform: rotate(86deg);
  position: absolute;
  top: 30px;
  left: 13px;
}
#string{
  border: 2px solid black;
  height: 430px;
  width: 0px;
  position: absolute;
  top: -432px;
  left: 47px;
  -webkit-animation: down2 5s linear infinite;
  -webkit-animation-delay: 2000ms;
  position: absolute;
 
}
}

</style>
