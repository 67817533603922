var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-4 ml-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8"}},[_c('v-data-table',{staticClass:"elevation-6",attrs:{"headers":_vm.headers,"items":_vm.desserts,"sort-by":"calories","search":_vm.search},scopedSlots:_vm._u([{key:"item.activo",fn:function(ref){
var item = ref.item;
return [(item.activo)?_c('v-chip',{attrs:{"dense":"","small":"","color":"green"}}):_c('v-chip',{attrs:{"dense":"","small":"","color":"red"}})]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Auxiliares")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"rounded":"","small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Agregar auxiliar ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.usuarios,"label":"Selecciona usuario","return-object":"","item-value":"id_usuario","item-text":"nombre_completo"},model:{value:(_vm.usuario),callback:function ($$v) {_vm.usuario=$$v},expression:"usuario"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.areas,"label":"Selecciona area","return-object":"","item-value":"idareas_ticket","item-text":"area"},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1)],1),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Buscar","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.getAuxiliares()}}},[_vm._v(" Reset ")])]},proxy:true}])})],1)],1),_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.overlay),callback:function ($$v) {_vm.overlay=$$v},expression:"overlay"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Espera por favor "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }